import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';

import { useApiEinrichtungItem } from 'api/hooks/useApiClient';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { ContentContainer } from 'layout/container/ContentContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { withAuthorization } from 'navigation/withAuthorization';
import { PflegeeinrichtungBenutzerErstellen } from 'pages/Verwaltung/Pflegeeinrichtungen/Uebersicht/Benutzer/PflegeeinrichtungBenutzerErstellen';
import { PflegeeinrichtungBenutzerListHeader } from 'pages/Verwaltung/Pflegeeinrichtungen/Uebersicht/Benutzer/PflegeeinrichtungBenutzerListHeader';
import { VerwaltungConfig } from 'pages/Verwaltung/VerwaltungConfig';

import { PflegeeinrichtungBenutzerList } from './PflegeeinrichtungBenutzerList';

const PflegeeinrichtungBenutzer = () => {
    const { id: einrichtungId } = useParams();
    const [serverDataChanged, setServerDataChanged] = useState(0);
    const { data: einrichtung, isLoading } = useApiEinrichtungItem(einrichtungId, { refresh: serverDataChanged });

    const onComplete = useCallback(() => {
        setServerDataChanged((prev) => prev + 1);
    }, []);

    return (
        <ContentContainer title={`Benutzer der Einrichtung ${einrichtung?.name}`}>
            <AppLoaderContainer isLoading={isLoading}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <PflegeeinrichtungBenutzerListHeader einrichtung={einrichtung} />
                    </Grid>
                    <Grid item xs={12}>
                        <PflegeeinrichtungBenutzerErstellen einrichtung={einrichtung} onComplete={onComplete} />
                    </Grid>
                    <Grid item xs={12}>
                        {einrichtung && (
                            <PflegeeinrichtungBenutzerList
                                einrichtung={einrichtung}
                                serverDataChanged={serverDataChanged}
                                onComplete={onComplete}
                            />
                        )}
                    </Grid>
                </Grid>
            </AppLoaderContainer>
        </ContentContainer>
    );
};

export const PflegeeinrichtungBenutzerPage = withAuthorization(
    withSideBar(PflegeeinrichtungBenutzer, VerwaltungConfig.menu),
    VerwaltungConfig.roles.pfelgeeinrichtung
);
