import React from 'react';
import { ControlProps } from '@jsonforms/core';
import { JsonFormsDispatch, withJsonFormsControlProps } from '@jsonforms/react';
import { Grid, styled, Table, TableBody, TableContainer, Typography } from '@mui/material';

import { renderers } from 'forms/renderers';
import { Schema, UiSchemaType } from 'forms/types/UiSchemaTypes';
import { getFieldSchemaByScope } from 'forms/utils/SchemaUtils';

interface SummaryTableUiSchema {
    columns: number;
    scope: string;
    label: string;
    schemaScope: string;
    entries: UiSchemaType[];
}

interface SummaryTableInterface extends Omit<ControlProps, 'uischema' | 'schema'> {
    uischema: SummaryTableUiSchema;
    schema: Schema;
}

export const SummaryTableComponent: React.FC<SummaryTableInterface> = ({ uischema, path, schema }) => {
    return (
        <Grid container spacing={1} data-testid={'SummaryTable'}>
            <Grid item xs={12}>
                <Typography variant={'h3'} style={{ fontWeight: 800 }}>
                    {uischema.label}
                </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
                <TableContainer>
                    <StyledSummaryTableComponent role={'presentation'}>
                        <TableBody>
                            {schema &&
                                uischema.entries
                                    ?.filter((row) => getFieldSchemaByScope(row.scope, schema))
                                    .map((row, index) => (
                                        <JsonFormsDispatch
                                            key={index}
                                            renderers={renderers}
                                            schema={schema}
                                            uischema={row}
                                            path={path}
                                            enabled
                                            visible
                                        />
                                    ))}
                        </TableBody>
                    </StyledSummaryTableComponent>
                </TableContainer>
            </Grid>
        </Grid>
    );
};

const StyledSummaryTableComponent = styled(Table)(({ theme }) => ({
    '& td': {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.grey.A200,
    },
}));

// @ts-ignore
export const SummaryTable = withJsonFormsControlProps(SummaryTableComponent);
