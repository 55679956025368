import React from 'react';
import { FormLabel, Grid, styled, Typography } from '@mui/material';

import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { ErrorAlert } from 'forms/controls/components/ErrorAlert';
import { formatDateToString, formatDezimal } from 'forms/utils/formatter';
import { Verwaltung } from 'pages/Verwaltung/components/LayoutComponents';

import { useAnerkannteFinanzierungData } from './hooks/useAnerkannteFinanzierungData';

interface AnerkannteFinanzierungType {
    id: string;
}

const StyleCategoryHeadline = styled(Typography)(() => ({
    fontWeight: 'bold',
    marginBottom: 24,
    marginTop: 24,
}));

export const AnerkannteFinanzierung: React.FC<AnerkannteFinanzierungType> = ({ id }) => {
    const { data, error, isLoading } = useAnerkannteFinanzierungData(id);

    return (
        <AppLoaderContainer isLoading={Boolean(isLoading)} minHeight={300}>
            {error && <ErrorAlert errors={[{ message: 'Einrichtung konnte nicht geladen werden' }]} />}

            {!error && (
                <>
                    <Verwaltung.Container>
                        <Verwaltung.Group title={'Anerkanntes Fremdkapital'}>
                            {data.fremdkapital.length === 0 && (
                                <Grid container>
                                    <Grid item xs={12}>
                                        <FormLabel component={'legend'}>Keine Einträge vorhanden.</FormLabel>
                                    </Grid>
                                </Grid>
                            )}

                            {data.fremdkapital.map((value, index) => (
                                <Grid container key={index}>
                                    <Grid item xs={12}>
                                        <StyleCategoryHeadline variant={'h3'}>
                                            Darlehen {index + 1}
                                        </StyleCategoryHeadline>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Verwaltung.InfoField label={'Darlehen'} value={value.name} />
                                        <Verwaltung.InfoField
                                            label={'Höhe anerkanntes FK'}
                                            value={formatDezimal(value.betrag, '€')}
                                            textAlign={'right'}
                                        />
                                        <Verwaltung.InfoField label={'Finanzierung für'} value={value.massnahme} />
                                    </Grid>
                                </Grid>
                            ))}
                        </Verwaltung.Group>
                    </Verwaltung.Container>
                    <Verwaltung.Container>
                        <Verwaltung.Group title={'Anerkanntes Eigenkapital'}>
                            {data.eigenkapital.length === 0 && (
                                <Grid container>
                                    <Grid item xs={12}>
                                        <FormLabel component={'legend'}>Keine Einträge vorhanden.</FormLabel>
                                    </Grid>
                                </Grid>
                            )}
                            {data.eigenkapital.map((value, index) => (
                                <Grid container key={index}>
                                    <Grid item xs={12}>
                                        <StyleCategoryHeadline variant={'h3'}>
                                            Eigenkapital {index + 1}
                                        </StyleCategoryHeadline>
                                    </Grid>
                                    <Verwaltung.InfoField
                                        label={'Höhe anerkanntes EK'}
                                        value={formatDezimal(value.betrag, '€')}
                                        textAlign={'right'}
                                    />
                                    <Verwaltung.InfoField label={'Zuordnung zu Maßnahme'} value={value.massnahme} />
                                    <Verwaltung.InfoField
                                        label={'Tilgung bis'}
                                        value={formatDateToString(value.faelligkeit)}
                                        textAlign={'right'}
                                    />
                                </Grid>
                            ))}
                        </Verwaltung.Group>
                    </Verwaltung.Container>
                </>
            )}
        </AppLoaderContainer>
    );
};
