import { Navigate, Route, Routes } from 'react-router-dom';

import { Page } from 'layout/Page';
import { FestsetzungsDetailsPage } from 'pages/Antraege/Festsetzung/Details/FestsetzungsDetailsPage';
import { FestsetzungsAntragPage } from 'pages/Antraege/Festsetzung/FestsetzungsAntrag';
import { FestsetzungsAntragListPage } from 'pages/Antraege/Festsetzung/FestsetzungsAntragList';
import { FeststellungDetailsPage } from 'pages/Antraege/Feststellung/Details/FeststellungDetailsPage';
import { FeststellungsAntragPage } from 'pages/Antraege/Feststellung/FeststellungsAntrag';
import { FeststellungsAntragListPage } from 'pages/Antraege/Feststellung/FeststellungsAntragList';
import { InvestorenmodellDetailsPage } from 'pages/Antraege/Investorenmodell/Details/InvestorenmodellDetailsPage';
import { InvestorenmodellAntragPage } from 'pages/Antraege/Investorenmodell/InvestorenmodellAntrag';
import { InvestorenmodellListPage } from 'pages/Antraege/Investorenmodell/InvestorenmodellList';
import { MeldungP29Page } from 'pages/Antraege/MeldungP29/MeldungP29';
import { MeldungenP29Page } from 'pages/Antraege/MeldungP29/MeldungP29List';
import { FestsetzungBearbeitenPage } from 'pages/Aufgaben/FestsetzungBearbeiten';
import { FestsetzungFreigabeAbgelehntPage } from 'pages/Aufgaben/FestsetzungFreigabeAbgelehnt';
import { FestsetzungPruefenPage } from 'pages/Aufgaben/FestsetzungPruefen';
import { FestsetzungZurueckgezogenPage } from 'pages/Aufgaben/FestsetzungZurueckgezogen';
import { FeststellungBearbeitenPage } from 'pages/Aufgaben/FeststellungBearbeiten';
import { FeststellungFreigabeAbgelehntPage } from 'pages/Aufgaben/FeststellungFreigabeAbgelehnt';
import { FeststellungPruefenPage } from 'pages/Aufgaben/FeststellungPruefen';
import { FeststellungZurueckgezogenPage } from 'pages/Aufgaben/FeststellungZurueckgezogen';
import { MeldungP29BearbeitenPage } from 'pages/Aufgaben/MeldungP29';
import { P8Abs11BearbeitenPage } from 'pages/Aufgaben/P8Abs11Bearbeiten';
import { P8Abs11FreigabeAbgelehntPage } from 'pages/Aufgaben/P8Abs11FreigabeAbgelehnt';
import { P8Abs11PruefenPage } from 'pages/Aufgaben/P8Abs11Pruefen';
import { P8Abs11ZurueckgezogenPage } from 'pages/Aufgaben/P8Abs11Zurueckgezogen';
import { BarrierefreiheitPage } from 'pages/Barrierefreiheit';
import { DashboardPage } from 'pages/Dashboard';
import { DatenschutzPage } from 'pages/Datenschutz';
import { Error404Page } from 'pages/Error404';
import { AngemessenheitsgrenzeAbstimmungsbescheidAb01072008Page } from 'pages/ExterneQuellen/Angemessenheitsgrenze/AbstimmungsbescheidAb01072008';
import { AngemessenheitsgrenzeAbstimmungsbescheidVor01072008Page } from 'pages/ExterneQuellen/Angemessenheitsgrenze/AbstimmungsbescheidVor01072008';
import { AngemessenheitsgrenzeAllgemeineVereinbarungPage } from 'pages/ExterneQuellen/Angemessenheitsgrenze/AllgemeineVereinbarung';
import { AngemessenheitsgrenzeVor1983Page } from 'pages/ExterneQuellen/Angemessenheitsgrenze/AngemessenheitsgrenzeVor1983Page';
import { AngemessenheitsgrenzeApgDvoMitKuechePage } from 'pages/ExterneQuellen/Angemessenheitsgrenze/ApgDvoMitKuechePage';
import { AngemessenheitsgrenzeApgDvoTeilstationaerPage } from 'pages/ExterneQuellen/Angemessenheitsgrenze/ApgDvoTeilstationaer';
import { AngemessenheitsgrenzeApgDvoVollstationaerPage } from 'pages/ExterneQuellen/Angemessenheitsgrenze/ApgDvoVollstationaer';
import { AngemessenheitsgrenzeFestgesetzterInstandhaltungswertProQmPage } from 'pages/ExterneQuellen/Angemessenheitsgrenze/FestgesetzterInstandhaltungswertProQm';
import { AngemessenheitsgrenzeGesbervo1996TeilstationaerPage } from 'pages/ExterneQuellen/Angemessenheitsgrenze/Gesbervo1996Teilstationaer';
import { AngemessenheitsgrenzeGesbervo1996VollstationaerPage } from 'pages/ExterneQuellen/Angemessenheitsgrenze/Gesbervo1996Vollstationaer';
import { AngemessenheitsgrenzeGesbervo2003TeilstationaerPage } from 'pages/ExterneQuellen/Angemessenheitsgrenze/Gesbervo2003Teilstationaer';
import { AngemessenheitsgrenzeGesbervo2003VollstationaerPage } from 'pages/ExterneQuellen/Angemessenheitsgrenze/Gesbervo2003Vollstationaer';
import { AngemessenheitsgrenzeGesbervo2008VollstationaerPage } from 'pages/ExterneQuellen/Angemessenheitsgrenze/Gesbervo2008Vollstationaer';
import { Baupreisindex2010Page } from 'pages/ExterneQuellen/Baupreisindex/Baupreisindex2010';
import { Baupreisindex2015Page } from 'pages/ExterneQuellen/Baupreisindex/Baupreisindex2015';
import { EigenkapitalzinsenGemaessBundesbankPage } from 'pages/ExterneQuellen/EigenkapitalzinsenGemaessBundesbank';
import { EigenkapitalzinsP56ApgDvoPage } from 'pages/ExterneQuellen/EigenkapitalzinsP56ApgDvo';
import { FremdkapitalzinsenSud119Page } from 'pages/ExterneQuellen/FremdkapitalzinsenSud119';
import { HilfePage } from 'pages/Hilfe';
import { HomePage } from 'pages/Home';
import { ImpressumPage } from 'pages/Impressum';
import { LoginPage } from 'pages/Login';
import { BenutzerdatenPage } from 'pages/Profil/Benutzerdaten/';
import { EmailBestaetigenPage } from 'pages/Profil/Benutzerdaten/EmailBestaetigen';
import { PasswortResetPage } from 'pages/Profil/Benutzerdaten/PasswortReset';
import { StatistikPage } from 'pages/Statistik';
import { BenutzerPage } from 'pages/Verwaltung/Benutzer';
import { MassenmailPage } from 'pages/Verwaltung/Massenmail';
import { AntragshistorieFestsetzungPage } from 'pages/Verwaltung/Pflegeeinrichtungen/Antragshistorie/AntragshistorieFestsetzung';
import { AntragshistorieFeststellungPage } from 'pages/Verwaltung/Pflegeeinrichtungen/Antragshistorie/AntragshistorieFeststellung';
import { AntragshistorieInvestorenmodellPage } from 'pages/Verwaltung/Pflegeeinrichtungen/Antragshistorie/AntragshistorieInvestorenmodell';
import { PflegeeinrichtungenDetailPage } from 'pages/Verwaltung/Pflegeeinrichtungen/Detailseite';
import { PflegeeinrichtungenPage } from 'pages/Verwaltung/Pflegeeinrichtungen/Uebersicht';
import { PflegeeinrichtungBenutzerPage } from 'pages/Verwaltung/Pflegeeinrichtungen/Uebersicht/Benutzer';
import { TraegerPage } from 'pages/Verwaltung/Traeger';
import { TraegerEinrichtungenPage } from 'pages/Verwaltung/Traeger/Einrichtungen';
import { TraegerverbandPage } from 'pages/Verwaltung/Traegerverband';
import { TraegerverbandBenutzerPage } from 'pages/Verwaltung/Traegerverband/Benutzer';
import { TraegerverbandEinrichtungenPage } from 'pages/Verwaltung/Traegerverband/Einrichtungen';
import { TraegerverbandTraegerPage } from 'pages/Verwaltung/Traegerverband/Traeger';
import { ZustaendigkeitenPage } from 'pages/Verwaltung/Zustaendigkeiten';

import {
    ACTION_EDIT,
    PATH_2010,
    PATH_2015,
    PATH_ABSTIMMUNGSBESCHEID_AB_01_07_2008,
    PATH_ABSTIMMUNGSBESCHEID_VOR_01_07_2008,
    PATH_ALLGEMEINE_VEREINBARUNG,
    PATH_ANGEMESSENHEITSGRENZE,
    PATH_ANTRAEGE,
    PATH_ANTRAEGE_ANTRAGSVERSIONEN,
    PATH_ANTRAEGE_FESTSETZUNG,
    PATH_ANTRAEGE_FESTSTELLUNG,
    PATH_ANTRAEGE_INVESTOREN_MODELL,
    PATH_ANTRAEGE_MELDUNG_P29,
    PATH_APG_DVO_MIT_KUECHE,
    PATH_APG_DVO_TEILSTATIONAER,
    PATH_APG_DVO_VOLLSTATIONAER,
    PATH_AUFGABEN,
    PATH_AUFGABEN_FESTSETZUNG_BEARBEITEN,
    PATH_AUFGABEN_FESTSETZUNG_FREIGABE_ABGELEHNT,
    PATH_AUFGABEN_FESTSETZUNG_PRUEFEN,
    PATH_AUFGABEN_FESTSETZUNG_ZURUECKGEZOGEN,
    PATH_AUFGABEN_FESTSTELLUNG_BEARBEITEN,
    PATH_AUFGABEN_FESTSTELLUNG_FREIGABE_ABGELEHNT,
    PATH_AUFGABEN_FESTSTELLUNG_PRUEFEN,
    PATH_AUFGABEN_FESTSTELLUNG_ZURUECKGEZOGEN,
    PATH_AUFGABEN_MELDUNG_P29,
    PATH_AUFGABEN_P8ABS11_BEARBEITEN,
    PATH_AUFGABEN_P8ABS11_FREIGABE_ABGELEHNT,
    PATH_AUFGABEN_P8ABS11_PRUEFEN,
    PATH_AUFGABEN_P8ABS11_ZURUECKGEZOGEN,
    PATH_BARRIEREFREIHEIT,
    PATH_BAUPREISINDEX,
    PATH_DASHBOARD,
    PATH_DATENSCHUTZ,
    PATH_EIGENKAPITALZINS_P5_6_APG_DVO,
    PATH_EIGENKAPITALZINSEN_GEMAESS_BUNDESBANK,
    PATH_EXTERNE_QUELLEN,
    PATH_FESTGESETZTER_INSTANDHALTUNGSWERT_PRO_QM,
    PATH_FREMDKAPITALZINSEN_SUD_119,
    PATH_GESBERVO_1996_TEILSTATIONAER,
    PATH_GESBERVO_1996_VOLLSTATIONAER,
    PATH_GESBERVO_2003_TEILSTATIONAER,
    PATH_GESBERVO_2003_VOLLSTATIONAER,
    PATH_GESBERVO_2008_VOLLSTATIONAER,
    PATH_HILFE,
    PATH_HOME,
    PATH_IMPRESSUM,
    PATH_LOGIN,
    PATH_PROFIL,
    PATH_PROFIL_BENUTZERDATEN,
    PATH_PROFIL_EMAIL_CONFIRM,
    PATH_PROFIL_PASSWORT_RESET,
    PATH_STATISTIK,
    PATH_VERWALTUNG,
    PATH_VERWALTUNG_BENUTZER,
    PATH_VERWALTUNG_EINRICHTUNGEN,
    PATH_VERWALTUNG_MASSENMAIL,
    PATH_VERWALTUNG_PFLEGEEINRICHTUNGEN,
    PATH_VERWALTUNG_PFLEGEEINRICHTUNGEN_ANTRAGSHISTORIE_FESTSETZUNG,
    PATH_VERWALTUNG_PFLEGEEINRICHTUNGEN_ANTRAGSHISTORIE_FESTSTELLUNG,
    PATH_VERWALTUNG_PFLEGEEINRICHTUNGEN_ANTRAGSHISTORIE_INVESTORENMODELL,
    PATH_VERWALTUNG_TRAEGER,
    PATH_VERWALTUNG_TRAEGERVERBAND,
    PATH_VERWALTUNG_ZUSTAENDIGKEITEN,
    PATH_VERWALTUNG_ZUSTAENDIGKEITEN_EINRICHTUNGEN,
    PATH_VOR_1983,
    useAntragFirstStep,
} from './Paths';

export const InvestRoutes = () => {
    const { feststellungFirstStep, festsetzungFirstStep, investorenmodellFirstStep, meldungP29FirstStep } =
        useAntragFirstStep();

    return (
        <Routes>
            <Route path={PATH_HOME} element={<Page />}>
                <Route index element={<HomePage />} />
                <Route path={PATH_LOGIN} element={<LoginPage />} />
                <Route path={PATH_HILFE} element={<HilfePage />} />
                <Route path={PATH_DATENSCHUTZ} element={<DatenschutzPage />} />
                <Route path={PATH_IMPRESSUM} element={<ImpressumPage />} />
                <Route path={PATH_BARRIEREFREIHEIT} element={<BarrierefreiheitPage />} />
                <Route path={PATH_DASHBOARD} element={<DashboardPage />} />
                <Route path={PATH_PROFIL}>
                    <Route path={PATH_PROFIL_BENUTZERDATEN}>
                        <Route index element={<BenutzerdatenPage />} />
                        <Route path={PATH_PROFIL_EMAIL_CONFIRM} element={<EmailBestaetigenPage />} />
                        <Route path={PATH_PROFIL_PASSWORT_RESET} element={<PasswortResetPage />} />
                    </Route>
                </Route>
                <Route path={PATH_AUFGABEN}>
                    <Route path={PATH_AUFGABEN_FESTSTELLUNG_BEARBEITEN} element={<FeststellungBearbeitenPage />} />
                    <Route
                        path={PATH_AUFGABEN_FESTSTELLUNG_FREIGABE_ABGELEHNT}
                        element={<FeststellungFreigabeAbgelehntPage />}
                    />
                    <Route path={PATH_AUFGABEN_FESTSTELLUNG_PRUEFEN} element={<FeststellungPruefenPage />} />
                    <Route
                        path={PATH_AUFGABEN_FESTSTELLUNG_ZURUECKGEZOGEN}
                        element={<FeststellungZurueckgezogenPage />}
                    />

                    <Route path={PATH_AUFGABEN_FESTSETZUNG_BEARBEITEN} element={<FestsetzungBearbeitenPage />} />
                    <Route
                        path={PATH_AUFGABEN_FESTSETZUNG_FREIGABE_ABGELEHNT}
                        element={<FestsetzungFreigabeAbgelehntPage />}
                    />
                    <Route path={PATH_AUFGABEN_FESTSETZUNG_PRUEFEN} element={<FestsetzungPruefenPage />} />
                    <Route
                        path={PATH_AUFGABEN_FESTSETZUNG_ZURUECKGEZOGEN}
                        element={<FestsetzungZurueckgezogenPage />}
                    />

                    <Route path={PATH_AUFGABEN_P8ABS11_BEARBEITEN} element={<P8Abs11BearbeitenPage />} />
                    <Route path={PATH_AUFGABEN_P8ABS11_FREIGABE_ABGELEHNT} element={<P8Abs11FreigabeAbgelehntPage />} />
                    <Route path={PATH_AUFGABEN_P8ABS11_PRUEFEN} element={<P8Abs11PruefenPage />} />
                    <Route path={PATH_AUFGABEN_P8ABS11_ZURUECKGEZOGEN} element={<P8Abs11ZurueckgezogenPage />} />

                    <Route path={PATH_AUFGABEN_MELDUNG_P29} element={<MeldungP29BearbeitenPage />} />
                </Route>
                <Route path={PATH_ANTRAEGE}>
                    <Route path={PATH_ANTRAEGE_FESTSTELLUNG}>
                        <Route index element={<FeststellungsAntragListPage />} />
                        <Route path={':id'}>
                            <Route index element={<Navigate to={PATH_ANTRAEGE_ANTRAGSVERSIONEN} replace />} />
                            <Route path={':step'} element={<FeststellungDetailsPage />} />
                        </Route>
                        <Route path={ACTION_EDIT}>
                            <Route path={':id'}>
                                <Route index element={<Navigate to={feststellungFirstStep} replace />} />
                                <Route path={':step'} element={<FeststellungsAntragPage />} />
                            </Route>
                        </Route>
                    </Route>
                    <Route path={PATH_ANTRAEGE_FESTSETZUNG}>
                        <Route index element={<FestsetzungsAntragListPage />} />
                        <Route path={':id'}>
                            <Route index element={<Navigate to={PATH_ANTRAEGE_ANTRAGSVERSIONEN} replace />} />
                            <Route path={':step'} element={<FestsetzungsDetailsPage />} />
                        </Route>
                        <Route path={ACTION_EDIT}>
                            <Route path={':id'}>
                                <Route index element={<Navigate to={festsetzungFirstStep} replace />} />
                                <Route path={':step'} element={<FestsetzungsAntragPage />} />
                            </Route>
                        </Route>
                    </Route>
                    <Route path={PATH_ANTRAEGE_INVESTOREN_MODELL}>
                        <Route index element={<InvestorenmodellListPage />} />
                        <Route path={':id'}>
                            <Route index element={<Navigate to={PATH_ANTRAEGE_ANTRAGSVERSIONEN} replace />} />
                            <Route path={':step'} element={<InvestorenmodellDetailsPage />} />
                        </Route>
                        <Route path={ACTION_EDIT}>
                            <Route path={':id'}>
                                <Route index element={<Navigate to={investorenmodellFirstStep} replace />} />
                                <Route path={':step'} element={<InvestorenmodellAntragPage />} />
                            </Route>
                        </Route>
                    </Route>
                    <Route path={PATH_ANTRAEGE_MELDUNG_P29}>
                        <Route index element={<MeldungenP29Page />} />
                        <Route path={ACTION_EDIT}>
                            <Route path={':id'}>
                                <Route index element={<Navigate to={meldungP29FirstStep} replace />} />
                                <Route path={':step'} element={<MeldungP29Page />} />
                            </Route>
                        </Route>
                    </Route>
                </Route>
                <Route path={PATH_VERWALTUNG}>
                    <Route path={PATH_VERWALTUNG_PFLEGEEINRICHTUNGEN}>
                        <Route index element={<PflegeeinrichtungenPage />} />
                        <Route path={':id'}>
                            <Route
                                path={PATH_VERWALTUNG_PFLEGEEINRICHTUNGEN_ANTRAGSHISTORIE_FESTSTELLUNG}
                                element={<AntragshistorieFeststellungPage />}
                            />
                            <Route
                                path={PATH_VERWALTUNG_PFLEGEEINRICHTUNGEN_ANTRAGSHISTORIE_FESTSETZUNG}
                                element={<AntragshistorieFestsetzungPage />}
                            />{' '}
                            <Route
                                path={PATH_VERWALTUNG_PFLEGEEINRICHTUNGEN_ANTRAGSHISTORIE_INVESTORENMODELL}
                                element={<AntragshistorieInvestorenmodellPage />}
                            />
                            <Route path={PATH_VERWALTUNG_BENUTZER} element={<PflegeeinrichtungBenutzerPage />} />
                            <Route path={':step'} element={<PflegeeinrichtungenDetailPage />} />
                        </Route>
                    </Route>
                    <Route path={PATH_VERWALTUNG_TRAEGER}>
                        <Route index element={<TraegerPage />} />
                        <Route path={':id'}>
                            <Route path={PATH_VERWALTUNG_EINRICHTUNGEN} element={<TraegerEinrichtungenPage />} />
                        </Route>
                    </Route>
                    <Route path={PATH_VERWALTUNG_TRAEGERVERBAND}>
                        <Route index element={<TraegerverbandPage />} />
                        <Route path={':id'}>
                            <Route path={PATH_VERWALTUNG_BENUTZER} element={<TraegerverbandBenutzerPage />} />
                            <Route path={PATH_VERWALTUNG_TRAEGER} element={<TraegerverbandTraegerPage />} />
                            <Route path={PATH_VERWALTUNG_EINRICHTUNGEN} element={<TraegerverbandEinrichtungenPage />} />
                        </Route>
                    </Route>
                    <Route path={'benutzer'} element={<BenutzerPage />} />

                    <Route path={PATH_VERWALTUNG_ZUSTAENDIGKEITEN}>
                        <Route
                            index
                            element={<Navigate to={PATH_VERWALTUNG_ZUSTAENDIGKEITEN_EINRICHTUNGEN} replace />}
                        />
                        <Route path={':step'} element={<ZustaendigkeitenPage />} />
                    </Route>

                    <Route path={PATH_VERWALTUNG_MASSENMAIL} element={<MassenmailPage />} />
                </Route>
                <Route path={PATH_EXTERNE_QUELLEN}>
                    <Route path={PATH_BAUPREISINDEX}>
                        <Route path={PATH_2010} element={<Baupreisindex2010Page />} />
                        <Route path={PATH_2015} element={<Baupreisindex2015Page />} />
                    </Route>
                    <Route path={PATH_EIGENKAPITALZINS_P5_6_APG_DVO} element={<EigenkapitalzinsP56ApgDvoPage />} />
                    <Route path={PATH_FREMDKAPITALZINSEN_SUD_119} element={<FremdkapitalzinsenSud119Page />} />
                    <Route
                        path={PATH_EIGENKAPITALZINSEN_GEMAESS_BUNDESBANK}
                        element={<EigenkapitalzinsenGemaessBundesbankPage />}
                    />
                    <Route path={PATH_ANGEMESSENHEITSGRENZE}>
                        <Route
                            path={PATH_FESTGESETZTER_INSTANDHALTUNGSWERT_PRO_QM}
                            element={<AngemessenheitsgrenzeFestgesetzterInstandhaltungswertProQmPage />}
                        />
                        <Route path={PATH_VOR_1983} element={<AngemessenheitsgrenzeVor1983Page />} />
                        <Route
                            path={PATH_ALLGEMEINE_VEREINBARUNG}
                            element={<AngemessenheitsgrenzeAllgemeineVereinbarungPage />}
                        />
                        <Route
                            path={PATH_GESBERVO_1996_VOLLSTATIONAER}
                            element={<AngemessenheitsgrenzeGesbervo1996VollstationaerPage />}
                        />
                        <Route
                            path={PATH_GESBERVO_2003_VOLLSTATIONAER}
                            element={<AngemessenheitsgrenzeGesbervo2003VollstationaerPage />}
                        />
                        <Route
                            path={PATH_GESBERVO_2008_VOLLSTATIONAER}
                            element={<AngemessenheitsgrenzeGesbervo2008VollstationaerPage />}
                        />
                        <Route
                            path={PATH_ABSTIMMUNGSBESCHEID_VOR_01_07_2008}
                            element={<AngemessenheitsgrenzeAbstimmungsbescheidVor01072008Page />}
                        />
                        <Route
                            path={PATH_ABSTIMMUNGSBESCHEID_AB_01_07_2008}
                            element={<AngemessenheitsgrenzeAbstimmungsbescheidAb01072008Page />}
                        />
                        <Route
                            path={PATH_APG_DVO_VOLLSTATIONAER}
                            element={<AngemessenheitsgrenzeApgDvoVollstationaerPage />}
                        />
                        <Route path={PATH_APG_DVO_MIT_KUECHE} element={<AngemessenheitsgrenzeApgDvoMitKuechePage />} />
                        <Route
                            path={PATH_GESBERVO_1996_TEILSTATIONAER}
                            element={<AngemessenheitsgrenzeGesbervo1996TeilstationaerPage />}
                        />
                        <Route
                            path={PATH_GESBERVO_2003_TEILSTATIONAER}
                            element={<AngemessenheitsgrenzeGesbervo2003TeilstationaerPage />}
                        />
                        <Route
                            path={PATH_APG_DVO_TEILSTATIONAER}
                            element={<AngemessenheitsgrenzeApgDvoTeilstationaerPage />}
                        />
                    </Route>
                </Route>
                <Route path={PATH_STATISTIK} element={<StatistikPage />} />
                <Route path="*" element={<Error404Page />} />
            </Route>
        </Routes>
    );
};
