import React, { ReactElement } from 'react';

import { EinrichtungResponse } from 'api/types';
import { DataTableHydraServerside } from 'components/DataTableHydra/DataTableHydraServerside';
import { RechteBearbeitenDialog } from 'pages/Verwaltung/Pflegeeinrichtungen/Uebersicht/Benutzer/RechteBearbeiten/RechteBearbeitenDialog';
import { usePflegeeinrichtungBenutzerListAction } from 'pages/Verwaltung/Pflegeeinrichtungen/Uebersicht/Benutzer/usePflegeeinrichtungBenutzerListAction';

import { usePflegeeinrichtungBenutzerListColumns } from './usePflegeeinrichtungBenutzerListColumns';
import { usePflegeeinrichtungBenutzerListData } from './usePflegeeinrichtungBenutzerListData';

type PflegeeinrichtungBenutzerListProps = {
    einrichtung: EinrichtungResponse;
    serverDataChanged?: number;
    onComplete: () => void;
};

export const PflegeeinrichtungBenutzerList = ({
    einrichtung,
    serverDataChanged,
    onComplete,
}: PflegeeinrichtungBenutzerListProps): ReactElement => {
    const { handleAction, selectedBenutzer, ...dialogProps } = usePflegeeinrichtungBenutzerListAction(onComplete);
    const getData = usePflegeeinrichtungBenutzerListData(einrichtung.id);
    const getColumns = usePflegeeinrichtungBenutzerListColumns(einrichtung, handleAction);

    return (
        <>
            <DataTableHydraServerside
                getData={getData}
                getColumns={getColumns}
                serverDataChanged={serverDataChanged}
                initialOptions={{
                    filter: false,
                    sort: false,
                    viewColumns: false,
                }}
            />
            <RechteBearbeitenDialog einrichtung={einrichtung} benutzer={selectedBenutzer} {...dialogProps} />
        </>
    );
};
