import * as React from 'react';
import { ChangeEvent, useCallback } from 'react';
import { FormControl, TextField } from '@mui/material';

import { ErrorList } from 'forms/controls/components/ErrorList';
import { ReadonlyValueText } from 'forms/controls/components/ReadonlyValueText';
import { useIsDataChanged } from 'forms/hooks/useIsDataChanged';
import { useScrollInView } from 'forms/hooks/useScrollInView';
import { useTempControlData } from 'forms/hooks/useTempControlData';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';

import { RowControl } from './RowControl';

const TextControlComponent: React.FC<CustomControlProps<string>> = ({
    path,
    label,
    required,
    data,
    handleChange,
    hasErrors,
    errors,
    disabled,
    readonly,
    showFieldNumberLabels,
    gridLayout,
    uischema,
    config,
    onTouched,
    schema,
    id,
}) => {
    const eventToData = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => (e.target.value === '' ? undefined : e.target.value),
        []
    );

    const { tempData, handleValueChange, handleOnBlur } = useTempControlData(
        data,
        path,
        errors,
        handleChange,
        eventToData,
        onTouched,
        {
            validateOnBlur: true,
            submitOnChange: uischema.options?.submitOnChange,
        }
    );
    const ref = useScrollInView<HTMLDivElement>();
    const isDataChanged = useIsDataChanged(data, schema);
    return (
        <RowControl
            name={path}
            label={label}
            required={required}
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={gridLayout}
            controlOnly={uischema.controlOnly}
            hasChangedData={isDataChanged}
            labelForId={id}
            config={config}
            paths={[path]}
        >
            <FormControl fullWidth>
                {readonly ? (
                    <ReadonlyValueText path={path} hasErrors={hasErrors} errors={errors} text={data || '-'} />
                ) : (
                    <TextField
                        id={id}
                        fullWidth
                        required={required}
                        value={tempData ?? ''}
                        error={hasErrors}
                        onBlur={handleOnBlur}
                        disabled={disabled}
                        onChange={handleValueChange}
                        ref={ref}
                        autoFocus={
                            uischema.options?.focus || (uischema.options?.firstControl && config.autoFocusFirstControl)
                        }
                        inputProps={{
                            'data-cy': `form_${path}`,
                        }}
                        helperText={hasErrors ? <ErrorList errors={errors} /> : ' '}
                    />
                )}
            </FormControl>
        </RowControl>
    );
};

export const TextControl = withCustomControlProps(TextControlComponent);
