import { notes } from 'forms/AntragForm/ui-schemas/groups/notes';

export const uiSchema = {
    type: 'CustomVerticalLayout',
    elements: [
        {
            type: 'Group',
            elements: [
                {
                    type: 'DocumentTableControl',
                    gridWidth: 8,
                    styles: {
                        marginBottom: 32,
                    },
                    columns: [
                        {
                            header: 'Schritt',
                            key: 'step',
                            styles: {
                                textAlign: 'left',
                                verticalAlign: 'top',
                            },
                        },
                        {
                            header: 'Bezeichnung',
                            key: 'description',
                            styles: {
                                textAlign: 'left',
                                verticalAlign: 'top',
                            },
                        },
                        {
                            header: 'Datei',
                            key: 'fileData',
                            styles: {
                                textAlign: 'left',
                                verticalAlign: 'top',
                            },
                        },
                        {
                            header: 'Hochzuladendes Dokument',
                            key: 'fileDescription',
                            styles: {
                                textAlign: 'right',
                                verticalAlign: 'top',
                            },
                        },
                    ],
                    entries: [
                        {
                            scope: '#/properties/mietvertraege',
                            uploads: [
                                {
                                    step: 'Miete',
                                    description: '{#/properties/uuid}',
                                    file: '#/properties/field951a',
                                    fileDescription: 'Mietvertrag',
                                },
                                {
                                    step: 'Miete',
                                    description: '{#/properties/uuid}',
                                    file: '#/properties/field951d',
                                    fileDescription: 'Nachweis aktuelle Miethöhe',
                                },
                            ],
                        },
                        {
                            scope: '#/properties/',
                            uploads: [
                                {
                                    step: 'Erbpacht',
                                    description: '',
                                    file: '#/properties/field952d',
                                    fileDescription: 'Nachweis aktuelle Erbpachthöhe',
                                },
                            ],
                        },
                        {
                            scope: '#/properties/aufwandSalg',
                            uploads: [
                                {
                                    step: 'Virtuelles Konto',
                                    description: 'SALG, {#/properties/uuid}',
                                    file: '#/properties/field520d',
                                    fileDescription: 'Nachweis für tatsächlichen Aufwand SALG',
                                },
                            ],
                        },
                        {
                            scope: '#/properties/aufwandInstandhaltung',
                            uploads: [
                                {
                                    step: 'Virtuelles Konto',
                                    description: 'Instandhaltung, {#/properties/uuid}',
                                    file: '#/properties/field530d',
                                    fileDescription: 'Nachweis für tatsächliche Instandhaltungsaufwendungen',
                                },
                            ],
                        },
                        {
                            scope: '#/properties/fremdkapital',
                            uploads: [
                                {
                                    step: 'Finanzierungen',
                                    description: 'Fremdkapital\n{#/properties/uuid}',
                                    file: '#/properties/field270d',
                                    fileDescription: 'Darlehensvertrag',
                                },
                                {
                                    step: 'Finanzierungen',
                                    description: 'Fremdkapital\n{#/properties/uuid}',
                                    file: '#/properties/field540d',
                                    fileDescription: 'Zins- und Tilgungsplan',
                                },
                                {
                                    step: 'Finanzierungen',
                                    description: 'Fremdkapital\n{#/properties/uuid}',
                                    file: '#/properties/field540e',
                                    fileDescription: 'Änderungsvereinbarung',
                                },
                            ],
                        },
                        {
                            scope: '#/properties/leasing',
                            uploads: [
                                {
                                    step: 'Finanzierungen',
                                    description: 'Leasing\n{#/properties/uuid}',
                                    file: '#/properties/field541d',
                                    fileDescription: 'Nachweis Finanzierungsaufwendungen (Leasing)',
                                },
                            ],
                        },
                        {
                            scope: '#/properties/',
                            uploads: [
                                {
                                    step: 'Belegung',
                                    description: 'Vollstationär',
                                    file: '#/properties/field570d',
                                    fileDescription: 'Nachweis Forderungsausfälle',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            type: 'Group',
            label: 'Weitere Dokumente',
            elements: [
                {
                    type: 'Control',
                    scope: '#/properties/dokumente',
                    options: {
                        noLabel: true,
                        multiline: true,
                    },
                },
            ],
        },
        notes('notizDokumente'),
    ],
};
