import { ReactElement } from 'react';
import { Typography } from '@mui/material';

import { AntragType } from 'api/antragTypes';
import { DataTableHydraServerside } from 'components/DataTableHydra/DataTableHydraServerside';
import { ROLES } from 'constants/roles';
import { ContentContainer } from 'layout/container/ContentContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { withAuthorization } from 'navigation/withAuthorization';
import { menuAntraege } from 'pages/Antraege/AntragConfig';
import { useAntragFilterOptions } from 'pages/Antraege/components/useAntragFilterOptions';
import { ZurueckziehenAkzeptierenDialog } from 'pages/Antraege/components/ZurueckziehenAkzeptierenDialog';
import { ZurueckziehenBeantragenDialog } from 'pages/Antraege/components/ZurueckziehenBeantragenDialog';
import { useInvestorenmodellAntragListAction } from 'pages/Antraege/Investorenmodell/useInvestorenmodellAntragListAction';
import { useInvestorenmodellListColumns } from 'pages/Antraege/Investorenmodell/useInvestorenmodellListColumns';
import { useInvestorenmodellListData } from 'pages/Antraege/Investorenmodell/useInvestorenmodellListData';

const InvestorenmodellList = (): ReactElement => {
    const getData = useInvestorenmodellListData();
    const {
        handleAction,
        serverDataChanged,
        zurueckziehenBeantragen,
        zurueckziehenBeantragenFinishAction,
        zurueckziehenAkzeptieren,
        zurueckziehenAkzeptierenFinishAction,
    } = useInvestorenmodellAntragListAction(AntragType.INVESTOREN_MODELL);
    const filterOptions = useAntragFilterOptions(AntragType.INVESTOREN_MODELL);
    const getColumns = useInvestorenmodellListColumns(handleAction, filterOptions);

    return (
        <ContentContainer title="Anträge nach § 8 Abs. 11">
            <Typography variant="h1">Anträge nach § 8 Abs. 11 </Typography>
            <DataTableHydraServerside
                getColumns={getColumns}
                getData={getData}
                serverDataChanged={serverDataChanged}
                initialOptions={{
                    searchPlaceholder: 'Suche nach Einrichtungsname, Aktenzeichen oder NRW-Schlüssel',
                    sortOrder: {
                        name: 'zuletztEingereichtAt',
                        direction: 'desc',
                    },
                }}
            />
            <ZurueckziehenBeantragenDialog
                antrag={zurueckziehenBeantragen}
                onClose={zurueckziehenBeantragenFinishAction}
                antragType={AntragType.INVESTOREN_MODELL}
            />

            <ZurueckziehenAkzeptierenDialog
                antrag={zurueckziehenAkzeptieren}
                onClose={zurueckziehenAkzeptierenFinishAction}
                antragType={AntragType.INVESTOREN_MODELL}
            />
        </ContentContainer>
    );
};

export const InvestorenmodellListPage = withAuthorization(withSideBar(InvestorenmodellList, menuAntraege), [
    ROLES.FACHADMIN,
    ROLES.SUPPORT,
    ROLES.LV,
    ROLES.ANBIETER,
]);
