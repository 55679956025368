import { ReactElement } from 'react';

import { DataTableHydraServerside } from 'components/DataTableHydra/DataTableHydraServerside';
import { LvBenutzerOptions } from 'components/DataTableHydra/hooks/useLvBenutzerOptions';
import { usePflegeeinrichtungenListAction } from 'pages/Verwaltung/Pflegeeinrichtungen/Uebersicht/usePflegeeinrichtungenListAction';
import { usePflegeeinrichtungListColumns } from 'pages/Verwaltung/Pflegeeinrichtungen/Uebersicht/usePflegeeinrichtungListColumns';
import { usePflegeeinrichtungListData } from 'pages/Verwaltung/Pflegeeinrichtungen/Uebersicht/usePflegeeinrichtungListData';

import { usePflegeeinrichtungFilterOptions } from './usePflegeeinrichtungFilterOptions';

interface PflegeeinrichtungListProps {
    zustandigerBenutzerOptions: LvBenutzerOptions;
}

export const PflegeeinrichtungList = ({ zustandigerBenutzerOptions }: PflegeeinrichtungListProps): ReactElement => {
    const { handleAction } = usePflegeeinrichtungenListAction();
    const getData = usePflegeeinrichtungListData();
    const filterOptions = usePflegeeinrichtungFilterOptions();
    const getColumns = usePflegeeinrichtungListColumns(zustandigerBenutzerOptions, handleAction, filterOptions);
    return (
        <DataTableHydraServerside
            getColumns={getColumns}
            getData={getData}
            initialOptions={{
                viewColumns: false,
                sortOrder: {
                    name: 'aktenzeichen',
                    direction: 'desc',
                },
            }}
        />
    );
};
