import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { groupList } from 'forms/AntragForm/ui-schemas/elements/groupList';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';

export const uiSchema = verticalLayout([
    group(
        [
            groupList(
                '#/properties/field160',
                ['#/properties/mietvertraege'],
                [control('field160'), control('field174'), control('field1704'), control('field1806c')],
                {
                    vertical: true,
                }
            ),
        ],
        {
            noSeparator: true,
        }
    ),
    group(
        [control('field1806b'), control('field1806'), control('field1807'), control('field1808'), control('field1809')],
        {}
    ),
]);
