import React, { ReactElement, useState } from 'react';
import { JsonForms } from '@jsonforms/react';
import { Grid } from '@mui/material';
import { isEqual } from 'lodash';

import { backendApiService } from 'api/ApiService';
import { AntragstellerType, BenutzerResponse, EinrichtungResponse } from 'api/types';
import { Dialog } from 'components/Dialog/Dialog';
import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { FormButton } from 'forms/components/FormButton';
import { renderers } from 'forms/renderers';
import { useLoadingActions } from 'forms/state/useLoadingState';
import { useMessageActions } from 'forms/state/useMessages';
import { FormStateChange, Schema, UiSchemaType } from 'forms/types/UiSchemaTypes';
import { errorMessage, successMessage } from 'forms/utils/MessageUtils';

type RechteBearbeitenDialogProps = {
    einrichtung: EinrichtungResponse;
    benutzer: BenutzerResponse | undefined;
    onClose: () => void;
    onComplete: () => void;
};

export const RechteBearbeitenDialog = ({
    einrichtung,
    benutzer,
    onClose,
    onComplete,
}: RechteBearbeitenDialogProps): ReactElement => {
    return (
        <Dialog open={Boolean(benutzer)} title="Einrichtungbenutzer Rechte anpassen" onClose={onClose}>
            {benutzer && (
                <RechteBearbeitenDialogContent
                    benutzer={benutzer}
                    einrichtung={einrichtung}
                    onClose={onClose}
                    onComplete={onComplete}
                />
            )}
        </Dialog>
    );
};

type RechteBearbeitenDialogContentProps = {
    einrichtung: EinrichtungResponse;
    benutzer: BenutzerResponse;
    onClose: () => void;
    onComplete: () => void;
};

const RechteBearbeitenDialogContent = ({
    einrichtung,
    benutzer,
    onClose,
    onComplete,
}: RechteBearbeitenDialogContentProps): ReactElement => {
    const einrichtungBenutzer = einrichtung.einrichtungBenutzer.find((b) => b.benutzer.id === benutzer.id);
    const { showLoading, hideLoading } = useLoadingActions();
    const { addMessage } = useMessageActions();
    const [data, setData] = useState({
        isAntragsteller: Boolean(einrichtungBenutzer?.isAntragsteller)
            ? AntragstellerType.SCHREIBEND
            : AntragstellerType.LESEND,
    });

    const onChange = ({ data: formData }: FormStateChange) => {
        if (isEqual(data, formData)) return;
        setData(formData);
    };

    const onSubmit = async () => {
        if (!einrichtungBenutzer?.id) return;
        try {
            showLoading('Einrichtungbenutzerrechte werden bearbeitet...');
            await backendApiService.putEinrichtungBenutzer(
                einrichtungBenutzer.id,
                einrichtung.id,
                benutzer.id,
                data.isAntragsteller === AntragstellerType.SCHREIBEND
            );
            addMessage(bearbeitenSuccessMessage());
        } catch (e) {
            addMessage(bearbeitenErrorMessage());
            console.error(e);
        } finally {
            onComplete();
            hideLoading();
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <JsonForms data={data} onChange={onChange} schema={schema} uischema={uischema} renderers={renderers} />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={1} justifyContent="flex-end">
                    <Grid item>
                        <FormButton type="button" variant="outlined" color="primary" onClick={onClose}>
                            Abbrechen
                        </FormButton>
                    </Grid>
                    <Grid item>
                        <FormButton type="submit" variant="contained" color="primary" onClick={onSubmit}>
                            Bearbeiten
                        </FormButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

const schema: Schema = {
    type: 'object',
    required: ['isAntragsteller'],
    properties: {
        isAntragsteller: {
            $id: 'isAntragsteller',
            title: 'Berechtigung',
            type: 'string',
            oneOf: [
                {
                    const: AntragstellerType.LESEND,
                    title: 'Lesend',
                },
                {
                    const: AntragstellerType.SCHREIBEND,
                    title: 'Schreibend',
                },
            ],
            // @ts-ignore
            custom: {
                block_prefixes: ['choice', 'radios'],
            },
        },
    },
};

const uischema: UiSchemaType = control('isAntragsteller');

const bearbeitenSuccessMessage = () =>
    successMessage('Benutzerrechte erfolgreich bearbeitet.', { autoCloseSeconds: 8 });
const bearbeitenErrorMessage = () =>
    errorMessage('Benutzerrechte konnten nicht bearbeitet werden.', { autoCloseSeconds: 8 });
