import { FileUploadResponse } from 'api/responses/FileUploadResponse';
import {
    DocumentUploadUiSchema,
    ScopedDocumentUploadUiSchema,
} from 'forms/controls/DocumentTable/DocumentTableControl';
import { useUuidMapping, UuidMappingFunction } from 'forms/hooks/useUuidValue';
import { DocumentTableType } from 'forms/types/UiSchemaTypes';
import { getScopedValue, replaceScopeTemplates } from 'utilities/ScopeUtils';

export interface DocumentTableRow {
    step: string;
    description: string;
    fileData: FileUploadResponse[];
    fileDescription: string;
}

export const useDocumentTableData = (data: any, uischema: DocumentTableType): DocumentTableRow[] => {
    const { entries } = uischema;

    const uuidMapping = useUuidMapping();

    if (!entries || entries.length === 0) {
        return [];
    }
    return entries.flatMap((entry: any) => toTableDataRows(entry, data, uuidMapping));
};

const toTableDataRows = (
    scopedUiSchema: ScopedDocumentUploadUiSchema,
    data: any,
    uuidMapping: UuidMappingFunction,
    scopedDescription?: string
): DocumentTableRow[] => {
    const scopeValue = getScopedValue(scopedUiSchema.scope, data);

    return (Array.isArray(scopeValue) ? scopeValue : [scopeValue])
        .flatMap((scopedValueItem) =>
            scopedUiSchema.uploads.flatMap((upload) => {
                if ((upload as ScopedDocumentUploadUiSchema).scope) {
                    return toTableDataRows(
                        upload as ScopedDocumentUploadUiSchema,
                        scopedValueItem,
                        uuidMapping,
                        concatDescriptions(
                            scopedDescription,
                            replaceScopeTemplates(scopedUiSchema.description, data, uuidMapping)
                        )
                    );
                }

                return toTableDataRow(
                    upload as DocumentUploadUiSchema,
                    scopedValueItem,
                    uuidMapping,
                    concatDescriptions(
                        scopedDescription,
                        replaceScopeTemplates(scopedUiSchema.description, data, uuidMapping)
                    )
                );
            })
        )
        .filter((tableData) => tableData) as DocumentTableRow[];
};

const toTableDataRow = (
    uploadUiSchema: DocumentUploadUiSchema,
    data: any,
    uuidMapping: UuidMappingFunction,
    scopedDescription?: string
): DocumentTableRow | undefined => {
    const fieldValue = getScopedValue(uploadUiSchema.file, data);
    if (!fieldValue || fieldValue.length === 0) {
        return undefined;
    }

    return {
        step: uploadUiSchema.step,
        description: concatDescriptions(
            scopedDescription,
            replaceScopeTemplates(uploadUiSchema.description, data, uuidMapping)
        ),
        fileData: Array.isArray(fieldValue) ? fieldValue : [fieldValue],
        fileDescription: uploadUiSchema.fileDescription,
    };
};

const concatDescriptions = (...descriptions: (string | undefined)[]) => {
    return descriptions.filter((d) => d !== undefined && d !== '').join('\n');
};
