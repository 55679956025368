import { useCallback, useState } from 'react';

import { JwtPayload } from './useAuthContextJwtToken';

type UseAuthContextImpersonateResult = {
    isImpersonated: boolean;
    getImpersonatedBenutzer: () => JwtPayload | undefined;
    setImpersonatedBenutzer: (impersonatedBenutzer: JwtPayload | undefined) => void;
};

export const useAuthContextImpersonate = (): UseAuthContextImpersonateResult => {
    const [impersonatedBenutzer, setImpersonatedBenutzer] = useState<JwtPayload | undefined>();
    const isImpersonated = Boolean(impersonatedBenutzer);

    const getImpersonatedBenutzer = useCallback((): JwtPayload | undefined => {
        return impersonatedBenutzer;
    }, [impersonatedBenutzer]);

    return { isImpersonated, getImpersonatedBenutzer, setImpersonatedBenutzer };
};
