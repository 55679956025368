import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, styled, Typography } from '@mui/material';

import { EinrichtungListItemResponse, EinrichtungResponse } from 'api/types';
import { PflegeEinrichtungDropdown } from 'components/PflegeEinrichtungDropdown';
import { InvestPathBuilder } from 'navigation/Paths';

type PflegeeinrichtungBenutzerListHeaderProps = {
    einrichtung: EinrichtungResponse | undefined;
};

export const PflegeeinrichtungBenutzerListHeader = ({
    einrichtung,
}: PflegeeinrichtungBenutzerListHeaderProps): ReactElement => {
    const navigate = useNavigate();

    const onChange = (value: EinrichtungListItemResponse | undefined) => {
        if (!value) return;
        navigate(InvestPathBuilder.home.verwaltung.pflegeeinrichtungen.target(value.id, 'benutzer'));
    };

    return (
        <Grid container spacing={2}>
            <Grid item>
                <PflegeeinrichtungHeaderTypography variant="h1">
                    Benutzer der Einrichtung
                </PflegeeinrichtungHeaderTypography>
            </Grid>
            <Grid item xs={4}>
                <PflegeEinrichtungDropdown
                    value={einrichtung}
                    onChange={onChange}
                    label="Einrichtung wechseln"
                    variant="h1"
                />
            </Grid>
        </Grid>
    );
};

const PflegeeinrichtungHeaderTypography = styled(Typography)(() => ({
    marginTop: 11,
}));
