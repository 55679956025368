import { useNavigate } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';

import { useAuthHelper } from 'api/auth/useAuthHelper';
import { ContentContainer } from 'layout/container/ContentContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { InvestPathBuilder } from 'navigation/Paths';

const Error404 = () => {
    const { isSignedIn } = useAuthHelper();
    const navigate = useNavigate();

    const onBack = () => {
        navigate(-1);
    };

    const onNavigateToStart = () => {
        navigate(isSignedIn() ? InvestPathBuilder.home.dashboard.path : InvestPathBuilder.home.path);
    };

    return (
        <ContentContainer title="Fehler 404">
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h1">Fehler 404 - Seite nicht gefunden</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography paragraph>
                                Der aufgerufene Inhalt kann nicht angezeigt werden. Entweder existiert die angeforderte
                                Seite nicht, oder Sie haben keine Berechtigung die Inhalte zu sehen.
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Button onClick={onBack}>Zurück</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" color="primary" onClick={onNavigateToStart}>
                                Weiter zur Startseite
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ContentContainer>
    );
};

export const Error404Page = withSideBar(Error404, []);
