export const finanzierungen_table = {
    type: 'SummaryTable',
    label: 'Finanzierungen',
    columns: 3,
    scope: '#/properties/finanzierungen',
    entries: [
        {
            type: 'SubTable',
            label: 'Eigenkapital',
            level: 0,
            scope: '#/properties/eigenkapital',
            entries: [
                {
                    type: 'FieldRow',
                    scope: '#/properties/field251',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field260',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field261',
                },
            ],
        },
        {
            type: 'SubTable',
            label: 'Fremdkapital',
            level: 0,
            scope: '#/properties/fremdkapital',
            entries: [
                {
                    type: 'FieldRow',
                    scope: '#/properties/field270',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field270d',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field271',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field272',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field272a',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field273',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field273c',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field274',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field275',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field276',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field277',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field540d',
                },
                {
                    type: 'SubTable',
                    label: 'Finanzierte Maßnahmen',
                    level: 1,
                    scope: '#/properties/',
                    entries: [
                        {
                            type: 'SubTable',
                            label: '{#/properties/field251}',
                            scope: '#/properties/fremdkapitalAnteile',
                            level: 2,
                            entries: [
                                {
                                    type: 'FieldRow',
                                    scope: '#/properties/field251',
                                },
                                {
                                    type: 'FieldRow',
                                    scope: '#/properties/field273a',
                                },
                            ],
                        },
                    ],
                },
                {
                    type: 'SubTable',
                    label: 'Aufwände',
                    level: 1,
                    scope: '#/properties/',
                    entries: [
                        {
                            type: 'SubTable',
                            scope: '#/properties/aufwandFremdkapital',
                            label: '{#/properties/jahr}',
                            level: 2,
                            entries: [
                                {
                                    type: 'FieldRow',
                                    scope: '#/properties/field540',
                                },
                            ],
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field251',
                        },
                    ],
                },
            ],
        },
        {
            type: 'SubTable',
            label: 'Leasing',
            level: 0,
            scope: '#/properties/leasing',
            entries: [
                {
                    type: 'FieldRow',
                    scope: '#/properties/field1427',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field541d',
                },
                {
                    type: 'SubTable',
                    label: 'Aufwände',
                    level: 1,
                    scope: '#/properties/',
                    entries: [
                        {
                            type: 'SubTable',
                            scope: '#/properties/aufwandLeasing',
                            label: '{#/properties/jahr}',
                            level: 2,
                            entries: [
                                {
                                    type: 'FieldRow',
                                    scope: '#/properties/field541',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            type: 'NotesRow',
            scope: '#/properties/notizFinanzierungen',
        },
    ],
};
