import React, { ReactNode } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { DesktopDatePicker, DesktopDatePickerProps } from '@mui/x-date-pickers';

type DatePickerProps = Omit<DesktopDatePickerProps<Date, Date>, 'renderInput' | 'inputFormat'> & {
    error?: boolean;
    onBlur?: () => void;
    helperText?: string | ReactNode;
    fullWidth?: boolean;
    inputProps?: TextFieldProps['inputProps'];
};

export const DatePickerYear: React.FC<DatePickerProps> = ({
    error = false,
    fullWidth,
    onBlur,
    helperText,
    inputProps,
    ...props
}) => {
    return (
        <DesktopDatePicker
            inputFormat="yyyy"
            mask="____"
            renderInput={(textFieldProps) => (
                <TextField
                    {...textFieldProps}
                    error={error}
                    helperText={helperText}
                    onBlur={onBlur}
                    fullWidth={fullWidth}
                    inputProps={{
                        ...inputProps,
                        ...textFieldProps.inputProps,
                        placeholder: 'jjjj',
                    }}
                />
            )}
            {...props}
        />
    );
};
