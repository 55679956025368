import React, { ReactElement } from 'react';
import { styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { AntragResponse, AntragVersionResponse } from 'api/types';
import { BESCHEID_DOKUMENT_LABELS } from 'constants/labels';
import { UploadedDocument } from 'forms/controls/components/UploadedDocument';
import { useAntragBescheidung } from 'pages/Antraege/components/useAntragBescheidung';

interface AntragVersionBescheideProps {
    antrag: AntragResponse;
    version: AntragVersionResponse | null;
}

export const AntragDetailsVersionBescheide = ({ version, antrag }: AntragVersionBescheideProps): ReactElement => {
    const bescheidung = useAntragBescheidung(version, antrag);

    return (
        <StyledTableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Dateityp</TableCell>
                        <TableCell>Dateiname</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!bescheidung && (
                        <TableRow>
                            <TableCell colSpan={2} data-testid="BescheideEmptyMessage">
                                Keine Bescheide vorhanden.
                            </TableCell>
                        </TableRow>
                    )}
                    {bescheidung?.dokumente &&
                        bescheidung.dokumente.map((dokument, index) => {
                            const file = dokument.upload ?? dokument.generiertesDokument;
                            return (
                                <TableRow key={dokument.id} data-testid="BescheideRow">
                                    <TableCell data-testid="BescheideRowTyp">
                                        {dokument.typ ? BESCHEID_DOKUMENT_LABELS[dokument.typ] : '-'}
                                    </TableCell>
                                    <TableCell data-testid="BescheideRowFile">
                                        {file ? <UploadedDocument key={index} fileData={file} readonly /> : '-'}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </Table>
        </StyledTableContainer>
    );
};

const StyledTableContainer = styled(TableContainer)(() => ({
    maxHeight: 402,
}));
