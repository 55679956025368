import React, { ChangeEvent, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, FormHelperText } from '@mui/material';
import { isEqual } from 'lodash';

import { ErrorList } from 'forms/controls/components/ErrorList';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';

import { RowControl } from './RowControl';

const CheckboxMultipleControlComponent: React.FC<CustomControlProps<string[]>> = ({
    path,
    label,
    data,
    handleChange,
    schema,
    config,
    onTouched,
    hasErrors,
    errors,
    required,
    showFieldNumberLabels,
    gridLayout,
}) => {
    const [values, setValues] = useState<string[]>(data);
    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setValues([...values, event.target.value]);
        } else {
            setValues(values.filter((value) => value !== event.target.value));
        }
    };

    useEffect(() => {
        if (isEqual(values, data)) {
            return;
        }

        handleChange(path, values);
    }, [handleChange, path, values, data]);

    return (
        <RowControl
            name={path}
            label={label}
            required={required}
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={gridLayout}
            config={config}
            paths={[path]}
        >
            {schema.anyOf &&
                schema.anyOf.map((option, index) => {
                    return (
                        <FormControlLabel
                            key={index}
                            control={
                                <Checkbox
                                    value={option.const}
                                    onBlur={onTouched}
                                    onChange={(event) => onChange(event)}
                                />
                            }
                            label={option.title}
                        />
                    );
                })}

            <FormHelperText>{hasErrors ? <ErrorList errors={errors} /> : ' '}</FormHelperText>
        </RowControl>
    );
};

export const CheckboxMultipleControl = withCustomControlProps(CheckboxMultipleControlComponent);
