import { useEffect, useState } from 'react';

export interface ApiHandlingInterface {
    endpoint: any;
    data: any;
    resetData: any;
    setModal: (state: boolean) => void;
    setResetData: (data: any) => void;
    converter: (data: any) => any;
    setErrors: (data: string[]) => void;
}

export const useFormModalApiHandling = ({
    endpoint,
    data,
    resetData,
    setModal,
    setResetData,
    converter,
    setErrors,
}: ApiHandlingInterface): {
    refreshToken: number;
    setOnSubmit: (state: boolean) => void;
} => {
    const [isPending, setPending] = useState<boolean>(false);
    const [onSubmit, setOnSubmit] = useState<boolean>(false);
    const [refreshToken, setRefreshToken] = useState<number>(0);

    useEffect(() => {
        if (isPending || !onSubmit) {
            return;
        }

        setPending(true);
        setErrors([]);

        endpoint(converter(data))
            .then(() => {
                setResetData(resetData);
                setModal(false);
                setErrors([]);
                setOnSubmit(false);
                setRefreshToken(new Date().getTime());
            })
            .catch((error: any) => {
                const errorList = error.response?.data?.violations?.map((violation: any) => violation.message);

                return setErrors(errorList || ['Es ist ein Fehler aufgetreten:']);
            })
            .finally(() => {
                setPending(false);
                setOnSubmit(false);
            });
    }, [endpoint, data, isPending, onSubmit, resetData, setModal, setResetData, converter, setErrors]);

    return { refreshToken, setOnSubmit };
};
