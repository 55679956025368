import { range } from 'lodash';
import { MUIDataTableColumnOptions } from 'mui-datatables';

import { CheckboxesFilter } from 'components/DataTableHydra/Filters/CheckboxesFilter';

export const JahrFilterOptions = (columnLabel: string, filter?: string[]): MUIDataTableColumnOptions => ({
    customFilterListOptions: {
        render: (value) => value.map((v: string) => `${columnLabel}: ${v}`),
    },
    filterOptions: {
        names: filter ? filter : range(2015, new Date().getFullYear() + 2).map((jahr) => String(jahr)),
        display: (filterList, onChange, index, column, filterData) => (
            <CheckboxesFilter
                index={index}
                column={column}
                filterData={filterData[index]}
                filterValue={filterList[index]}
                onChange={onChange}
            />
        ),
    },
    filterType: 'custom',
});
