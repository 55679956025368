import React, { ChangeEvent, ReactElement } from 'react';
import Info from '@mui/icons-material/Info';
import { Checkbox, FormControl, Popper, styled, TableCell, TableRow, Tooltip, Typography } from '@mui/material';

import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';
import { TenorType } from 'forms/types/UiSchemaTypes';
import { getFieldFromScope } from 'utilities';

type TenorProps = CustomControlProps<boolean, TenorType>;

export const Tenor = ({ data, uischema, schema, handleChange, disabled, path }: TenorProps): ReactElement => {
    const field = getFieldFromScope(uischema.scope);

    const onChange = (_: ChangeEvent<HTMLInputElement>, newValue: boolean) => {
        if (data === newValue) return;
        handleChange(path, newValue, true);
    };

    const changedFields = schema.custom?.tenor_fields ?? [];
    return (
        <TableRow>
            <TableCell>{field?.substring(field.length - 1, field.length)}</TableCell>
            <TableCell>{schema.title}</TableCell>
            <TableCell>
                <Typography>
                    {changedFields.map((changedField, index) => {
                        const [changedFieldNumber, ...rest] = changedField.split(':');
                        const changedFieldInfo = rest.join(':');
                        return (
                            <span
                                key={changedFieldNumber}
                                style={{
                                    alignItems: 'flex-end',
                                    display: 'inline-flex',
                                }}
                            >
                                {index > 0 && ', '}[{changedFieldNumber}]
                                <Tooltip title={changedFieldInfo} PopperComponent={TooltipPopper}>
                                    <InfoIcon aria-label="Information" />
                                </Tooltip>
                            </span>
                        );
                    })}
                </Typography>
            </TableCell>
            <TableCell>
                <FormControl disabled={disabled}>
                    <Checkbox disabled={disabled} checked={data ?? false} onChange={onChange} />
                </FormControl>
            </TableCell>
        </TableRow>
    );
};

const InfoIcon = styled(Info)(() => ({
    color: '#006ECB',
    marginLeft: '4px',
}));

const TooltipPopper = styled(Popper)(({ theme }) => ({
    '& .MuiTooltip-tooltip': {
        ...theme.typography.body1,
        color: 'black',
        backgroundColor: 'white',
        boxShadow: theme.shadows['8'],
        padding: theme.spacing(2),
    },
}));

export const TenorControl = withCustomControlProps(Tenor);
