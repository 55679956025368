import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { backendApiService } from 'api/ApiService';
import {
    EinrichtungJsonldEinrichtungRead,
    FestsetzungsantragJsonldFestsetzungsantragList,
    FeststellungsantragJsonldFeststellungsantragList,
} from 'api/client';
import {
    FestsetzungsAntragCollectionResponse,
    FeststellungsAntragCollectionResponse,
    InvestorenmodellCollectionResponse,
} from 'api/types';
import { ACTIONS } from 'constants/antragActions';
import { STATUS } from 'constants/antragStatus';
import { useLoadingActions } from 'forms/state/useLoadingState';
import { InvestPathBuilder } from 'navigation/Paths';

interface UseAntragHelperResult {
    lastFeststellungsantrag: FeststellungsantragJsonldFeststellungsantragList | undefined;
    lastFestsetzungsantrag: FestsetzungsantragJsonldFestsetzungsantragList | undefined;
    lastInvestorenmodell: InvestorenmodellCollectionResponse | undefined;
    allowFeststellungsantrag: boolean;
    allowFestsetzungsantrag: boolean;
    allowInvestorenmodell: boolean;
    goToFeststellungsantrag: () => void;
    goToFestsetzungsantrag: () => void;
    goToInvestorenmodell: () => void;
}

export const useAntragHelper = (
    einrichtung: EinrichtungJsonldEinrichtungRead | undefined,
    feststellungsantraege: FeststellungsAntragCollectionResponse[] | undefined = undefined,
    festsetzungsantraege: FestsetzungsAntragCollectionResponse[] | undefined = undefined,
    investorenmodelle: InvestorenmodellCollectionResponse[] | undefined = undefined
): UseAntragHelperResult => {
    const navigate = useNavigate();
    const { showLoading, hideLoading } = useLoadingActions();

    const nichtAbgebrocheneFeststellungsantraege = useMemo(
        () =>
            feststellungsantraege
                ? feststellungsantraege.filter(
                      (feststellungsantrag) => STATUS.ABGEBROCHEN !== feststellungsantrag.status
                  )
                : [],
        [feststellungsantraege]
    );

    const lastFeststellungsantrag = useMemo(
        () =>
            nichtAbgebrocheneFeststellungsantraege.length
                ? nichtAbgebrocheneFeststellungsantraege.reduce((prev, current) =>
                      ((prev ? prev.id : 0) as number) > ((current ? current.id : 0) as number) ? prev : current
                  )
                : undefined,
        [nichtAbgebrocheneFeststellungsantraege]
    );

    const nichtAbgebrocheneFestsetzungsantraege = useMemo(
        () =>
            festsetzungsantraege
                ? festsetzungsantraege.filter((festsetzungsantrag) => STATUS.ABGEBROCHEN !== festsetzungsantrag.status)
                : [],
        [festsetzungsantraege]
    );

    const lastFestsetzungsantrag = useMemo(
        () =>
            nichtAbgebrocheneFestsetzungsantraege.length
                ? nichtAbgebrocheneFestsetzungsantraege.reduce((prev, current) =>
                      ((prev ? prev.id : 0) as number) > ((current ? current.id : 0) as number) ? prev : current
                  )
                : undefined,
        [nichtAbgebrocheneFestsetzungsantraege]
    );

    const nichtAbgebrocheneInvestorenmodelle = useMemo(
        () =>
            investorenmodelle
                ? investorenmodelle.filter((festsetzungsantrag) => STATUS.ABGEBROCHEN !== festsetzungsantrag.status)
                : [],
        [investorenmodelle]
    );

    const lastInvestorenmodell = useMemo(
        () =>
            nichtAbgebrocheneInvestorenmodelle.length
                ? nichtAbgebrocheneInvestorenmodelle.reduce((prev, current) =>
                      ((prev ? prev.id : 0) as number) > ((current ? current.id : 0) as number) ? prev : current
                  )
                : undefined,
        [nichtAbgebrocheneInvestorenmodelle]
    );

    const allowFeststellungsantrag = useMemo(
        () =>
            Boolean(
                (feststellungsantraege && feststellungsantraege.length === 0) ||
                    (lastFeststellungsantrag &&
                        (lastFeststellungsantrag.allowedWorkflowActions?.includes(ACTIONS.EDIT) ||
                            lastFeststellungsantrag.allowedWorkflowActions?.includes(ACTIONS.FOLGEANTRAG)))
            ),
        [feststellungsantraege, lastFeststellungsantrag]
    );

    const allowFestsetzungsantrag = useMemo(
        () =>
            Boolean(
                feststellungsantraege &&
                    feststellungsantraege.length !== 0 &&
                    ((festsetzungsantraege && festsetzungsantraege.length === 0) ||
                        (lastFestsetzungsantrag &&
                            (lastFestsetzungsantrag.allowedWorkflowActions?.includes(ACTIONS.EDIT) ||
                                lastFestsetzungsantrag.allowedWorkflowActions?.includes(ACTIONS.FOLGEANTRAG))))
            ),
        [feststellungsantraege, festsetzungsantraege, lastFestsetzungsantrag]
    );

    const allowInvestorenmodell = useMemo(
        () =>
            Boolean(
                (investorenmodelle && investorenmodelle.length === 0) ||
                    (lastInvestorenmodell &&
                        (lastInvestorenmodell.allowedWorkflowActions?.includes(ACTIONS.EDIT) ||
                            lastInvestorenmodell.allowedWorkflowActions?.includes(ACTIONS.FOLGEANTRAG)))
            ),
        [investorenmodelle, lastInvestorenmodell]
    );

    const goToFeststellungsantrag = useCallback(async (): Promise<void> => {
        if (!einrichtung) {
            return;
        }

        if (!lastFeststellungsantrag || isWorkflowAllowed(lastFeststellungsantrag, ACTIONS.FOLGEANTRAG)) {
            showLoading('Feststellungantrag wird erstellt...');
            const res = await backendApiService.postFeststellungsantrag({ einrichtung: String(einrichtung.id) });
            navigate(InvestPathBuilder.home.antraege.feststellung.edit(res.id));
            hideLoading();
            return;
        }

        if (lastFeststellungsantrag && isWorkflowAllowed(lastFeststellungsantrag, ACTIONS.EDIT)) {
            showLoading('Feststellungantrag wird geladen...');
            navigate(InvestPathBuilder.home.antraege.feststellung.edit(String(lastFeststellungsantrag.id)));
            hideLoading();
            return;
        }
    }, [einrichtung, hideLoading, lastFeststellungsantrag, navigate, showLoading]);

    const goToFestsetzungsantrag = useCallback(async (): Promise<void> => {
        if (!einrichtung) {
            return;
        }

        if (!lastFestsetzungsantrag || isWorkflowAllowed(lastFestsetzungsantrag, ACTIONS.FOLGEANTRAG)) {
            showLoading('Festsetzungsantrag wird erstellt...');
            const res = await backendApiService.postFestsetzungsantrag({ einrichtung: String(einrichtung.id) });
            navigate(InvestPathBuilder.home.antraege.festsetzung.edit(res.id));
            hideLoading();
            return;
        }

        if (lastFestsetzungsantrag && isWorkflowAllowed(lastFestsetzungsantrag, ACTIONS.EDIT)) {
            showLoading('Festsetzungsantrag wird geladen...');
            navigate(InvestPathBuilder.home.antraege.festsetzung.edit(lastFestsetzungsantrag.id));
            hideLoading();
            return;
        }
    }, [einrichtung, hideLoading, lastFestsetzungsantrag, navigate, showLoading]);

    const goToInvestorenmodell = useCallback(async (): Promise<void> => {
        if (!einrichtung) {
            return;
        }

        if (!lastInvestorenmodell || isWorkflowAllowed(lastInvestorenmodell, ACTIONS.FOLGEANTRAG)) {
            showLoading('Antrag nach § 8 Abs. 11 wird erstellt...');
            const res = await backendApiService.postInvestorenmodell({ einrichtung: String(einrichtung.id) });
            navigate(InvestPathBuilder.home.antraege.investorenmodell.edit(res.id));
            hideLoading();
            return;
        }

        if (lastInvestorenmodell && isWorkflowAllowed(lastInvestorenmodell, ACTIONS.EDIT)) {
            showLoading('Antrag nach § 8 Abs. 11 wird geladen...');
            navigate(InvestPathBuilder.home.antraege.investorenmodell.edit(lastInvestorenmodell.id));
            hideLoading();
            return;
        }
    }, [einrichtung, hideLoading, lastInvestorenmodell, navigate, showLoading]);

    return {
        lastFeststellungsantrag,
        lastFestsetzungsantrag,
        lastInvestorenmodell,
        allowFeststellungsantrag,
        allowFestsetzungsantrag,
        allowInvestorenmodell,
        goToFeststellungsantrag,
        goToFestsetzungsantrag,
        goToInvestorenmodell,
    };
};

const isWorkflowAllowed = (
    antrag:
        | FeststellungsAntragCollectionResponse
        | FestsetzungsAntragCollectionResponse
        | InvestorenmodellCollectionResponse,
    action: ACTIONS
): boolean => {
    return Boolean(antrag?.allowedWorkflowActions?.includes(action));
};
