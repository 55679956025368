import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { addMinutes } from 'date-fns';

import { useAuth } from 'api/AuthContext';

const MAX_PAGE_TIME_IN_MINUTES = 45;

const initialState = () => addMinutes(Date.now(), MAX_PAGE_TIME_IN_MINUTES).getTime();

type UseSecondsLeftResult = {
    secondsLeft: number;
    reset: () => void;
};

export const useSecondsLeft = (): UseSecondsLeftResult => {
    const { jwt } = useAuth();
    const { pathname } = useLocation();
    const [expiry, setExpiry] = useState(initialState);
    const [secondsLeft, setSecondsLeft] = useState<number>(0);

    useEffect(() => {
        setExpiry(initialState);
    }, [pathname]);

    useEffect(() => {
        const id = setInterval(() => {
            setSecondsLeft(Number(((expiry - Date.now()) / 1000).toFixed()));
        }, 1000);
        return () => clearInterval(id);
    }, [expiry]);

    const reset = useCallback(() => setExpiry(initialState()), []);

    return { secondsLeft: jwt.isLoggedIn ? secondsLeft : 0, reset };
};
