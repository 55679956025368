import React, { ReactElement, useState } from 'react';
import CheckCircle from '@mui/icons-material/CheckCircle';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SendIcon from '@mui/icons-material/Send';
import { Button, CircularProgress, styled, TableCell, TableRow, Typography } from '@mui/material';

import { BACKEND_API_BASE_URL } from 'api/ApiBaseService';
import { backendApiService } from 'api/ApiService';
import { ACTIONS } from 'constants/antragActions';
import { BESCHEID_DOKUMENT, BESCHEID_DOKUMENT_TYPEN } from 'constants/bescheidDokument';
import { BESCHEID_VERSAND } from 'constants/bescheidVersand';
import { BESCHEID_DOKUMENT_LABELS } from 'constants/labels';
import { Link } from 'elements/Link';
import { LinkEinrichtung } from 'elements/LinkEinrichtung';
import { useConfirmationDialogActions } from 'forms/state/useConfirmationDialogState';
import { useMessageActions } from 'forms/state/useMessages';
import { errorMessage } from 'forms/utils/MessageUtils';
import { DashboardLvBescheidVersandRowDatum } from 'pages/Dashboard/Landschaftsverband/DashboardLvBescheidVersandRowDatum';
import { Bescheidung } from 'pages/Dashboard/Landschaftsverband/useDashboardLvAntraege';
import { getAbsoluteBackendPath } from 'utilities/Utils';

// eslint-disable-next-line
type DashboardLvBescheidVersandRowProps = {
    bescheidung: Bescheidung;
    refresh: () => void;
};

export const DashboardLvBescheidVersandRow: React.FC<DashboardLvBescheidVersandRowProps> = ({
    bescheidung,
    refresh,
}) => {
    const [isSending, setSending] = useState(false);
    const [showLinks, setShowLinks] = useState(false);
    const { addMessage } = useMessageActions();
    const { showConfirmation, closeConfirmation } = useConfirmationDialogActions();

    const getDokumentByTyp = (bescheidung: Bescheidung, bescheidDokumentTyp: string) => {
        return bescheidung.dokumente.find((d) => {
            return bescheidDokumentTyp === d.dokumentTyp;
        });
    };

    const renderDokumentLink = (bescheidung: Bescheidung, bescheidDokumentTyp: string): ReactElement | null => {
        const versand = bescheidung.versand[bescheidDokumentTyp];
        const dokument = getDokumentByTyp(bescheidung, bescheidDokumentTyp);

        if (BESCHEID_DOKUMENT.ZUSAMMENFASSUNG === bescheidDokumentTyp || BESCHEID_VERSAND.SYSTEM === versand) {
            if (bescheidung.gesendetAt) {
                return (
                    // TODO: Sinnvolles Error handling für den Fall das keine contentUrl vorhanden ist.
                    <Link target="_blank" href={dokument ? BACKEND_API_BASE_URL + dokument.contentUrl : '#'}>
                        {BESCHEID_DOKUMENT_LABELS[bescheidDokumentTyp]}
                    </Link>
                );
            } else {
                return (
                    <Link
                        target="_blank"
                        href={getAbsoluteBackendPath(
                            `files/${bescheidung.antragTyp}/${bescheidung.antragId}/dokument/${bescheidDokumentTyp}/pdf`
                        )}
                    >
                        {BESCHEID_DOKUMENT_LABELS[bescheidDokumentTyp]}
                    </Link>
                );
            }
        } else if (BESCHEID_VERSAND.MANUELL === versand) {
            return (
                // TODO: Sinnvolles Error handling für den Fall das keine contentUrl vorhanden ist.
                <Link target="_blank" href={dokument ? getAbsoluteBackendPath(dokument.contentUrl) : '#'}>
                    {BESCHEID_DOKUMENT_LABELS[bescheidDokumentTyp]}
                </Link>
            );
        }

        return null;
    };

    const links = BESCHEID_DOKUMENT_TYPEN.map((bescheidDokumentTyp) =>
        renderDokumentLink(bescheidung, bescheidDokumentTyp)
    ).filter((e) => !!e);

    const send = async () => {
        try {
            setSending(true);
            closeConfirmation();
            switch (bescheidung.antragTyp) {
                case 'feststellungsantrag':
                    await backendApiService.workflowFeststellungsantragItem(bescheidung.antragId, ACTIONS.BESCHEIDEN);
                    break;
                case 'festsetzungsantrag':
                    await backendApiService.workflowFestsetzungsantragItem(bescheidung.antragId, ACTIONS.BESCHEIDEN);
                    break;
                case 'investorenmodell':
                    await backendApiService.workflowInvestorenmodellItem(bescheidung.antragId, ACTIONS.BESCHEIDEN);
                    break;
            }
            refresh();
        } catch (e) {
            addMessage(createErrorMessage());
        } finally {
            setSending(false);
        }
    };

    const onSend = () => {
        showConfirmation({
            confirmAction: send,
            alertText:
                'Sind Sie sicher, dass Sie den Bescheid und die dazugehörigen Dokumente dem Antragssteller zugänglich machen wollen? Dieser Schritt kann nicht rückgängig gemacht werden.',
            denyLabel: 'Abbrechen',
            confirmLabel: 'Bescheid versenden',
            denyAction: closeConfirmation,
        });
    };

    const isPostalischUnsent = bescheidung.postalischerVersand && !bescheidung.gesendetAt;
    return (
        <TableRow>
            {isPostalischUnsent && (
                <TableCell colSpan={2}>
                    <Typography>{bescheidung.antragTitle}</Typography>
                    <LinkEinrichtung id={bescheidung.einrichtungId} name={bescheidung.einrichtungName} />
                    <DashboardLvBescheidVersandRowDatum bescheidung={bescheidung} refresh={refresh} />
                </TableCell>
            )}

            {!isPostalischUnsent && (
                <>
                    <TableCell>
                        <Typography>{bescheidung.antragTitle}</Typography>
                        <LinkEinrichtung id={bescheidung.einrichtungId} name={bescheidung.einrichtungName} />
                        {showLinks && (
                            <StyledLinkList>
                                {links.map((link, index) => {
                                    return (
                                        <>
                                            {index > 0 && ' | '}
                                            {link}
                                        </>
                                    );
                                })}
                            </StyledLinkList>
                        )}
                    </TableCell>
                    <StyledTableCell align={'right'} width={100}>
                        {isSending && <CircularProgress size={17} />}
                        {bescheidung.gesendetAt && !isSending && (
                            <StyledButton disabled>
                                <CheckCircle />
                            </StyledButton>
                        )}
                        {!bescheidung.gesendetAt && !isSending && (
                            <StyledButton onClick={onSend}>
                                <SendIcon />
                            </StyledButton>
                        )}
                        {showLinks ? (
                            <StyledButton onClick={() => setShowLinks(false)}>
                                <ExpandLess />
                            </StyledButton>
                        ) : (
                            <StyledButton onClick={() => setShowLinks(true)}>
                                <ExpandMore />
                            </StyledButton>
                        )}
                    </StyledTableCell>
                </>
            )}
        </TableRow>
    );
};

const StyledButton = styled(Button)(
    () => `
    min-width: 30px;
    padding: 0;
`
);

const StyledTableCell = styled(TableCell)(
    () => `
    vertical-align: top;
`
);

const StyledLinkList = styled(Typography)(
    () => `
    font-size: small;
`
);
const createErrorMessage = () => errorMessage('Antrag konnte nicht versandt werden.', { autoCloseSeconds: 8 });
