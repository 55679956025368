import { withJsonFormsLayoutProps } from '@jsonforms/react';
import { Divider, styled } from '@mui/material';

export const SeparatorControl = withJsonFormsLayoutProps(({ visible }) => {
    if (!visible) {
        return <></>;
    }
    return <Separator aria-hidden={true} />;
});

const Separator = styled(Divider)(({ theme }) => ({
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
}));
