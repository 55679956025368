import { parseISO } from 'date-fns';
import format from 'date-fns/format';

export const formatDate = (date?: string): string | undefined => {
    if (!date?.length) return undefined;
    const _date = new Date(date);
    if (isNaN(_date.getTime())) return undefined;

    return format(_date, 'dd.MM.yyyy');
};

export const formatDateToString = (
    date: Date | string | null | undefined,
    formatString: string = 'dd.MM.yyyy'
): string => {
    if (!date) return '-';
    const value = typeof date === 'string' ? parseISO(date) : date;
    return format(value, formatString);
};

export const formatDezimal = (value?: string | number | null, unit?: string, scale?: number): string | undefined => {
    if (value === undefined || value === null) {
        return '-';
    }
    const val = parseFloat(value as string);
    if (isNaN(val)) return String(value);
    return `${val.toLocaleString('de', { minimumFractionDigits: scale ?? 2 })}&nbsp;${unit || ''}`;
};

export const formatInteger = (value?: string | number, unit?: string): string | undefined => {
    if (value === undefined || value === null) {
        return '-';
    }
    const val = parseFloat(value as string);
    if (isNaN(val)) return undefined;
    return `${val.toLocaleString('de')} ${unit || ''}`;
};

export const formatBoolean = (value?: boolean): string => {
    return value === undefined ? '-' : value ? 'Ja' : 'Nein';
};
