import { GroupControlType } from 'forms/types/UiSchemaTypes';

export const flaechenreduzierungen = {
    type: 'Group',
    label: 'Flächenreduzierung',
    scope: '#/properties/flaechenreduzierungenLalg',
    elements: [
        {
            type: 'Control',
            scope: '#/properties/field0523',
        },
        {
            type: 'FormExtendButton',
            label: 'Flächenreduzierung hinzufügen',
            scope: '#/properties/flaechenreduzierungenLalg',
            uiSchema: {
                type: 'CustomVerticalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/field0510',
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/field0511',
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/field0512',
                    },
                ],
            },
        },
        {
            type: 'Separator',
        },
        {
            type: 'Control',
            scope: '#/properties/field0513',
        },
    ],
} as GroupControlType;
