import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/AntragForm/ui-schemas/elements/customVerticalLayout';
import { formDialog } from 'forms/AntragForm/ui-schemas/elements/formDialog';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { labeled } from 'forms/AntragForm/ui-schemas/elements/labeled';
import { listControlWithModalForm } from 'forms/AntragForm/ui-schemas/elements/listControlWithModalForm';
import { tableColumn, tableColumnDate, tableColumnDecimal } from 'forms/AntragForm/ui-schemas/elements/tableColumn';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';

export const uiSchema = verticalLayout([
    group([control('field1787'), control('field1788'), control('field1788a')]),
    group(
        [
            listControlWithModalForm(
                ['#/properties/modernisierungP8Abs6'],
                [
                    tableColumn('Maßnahme', {
                        scope: '#/properties/massnahmeUuid',
                    }),
                    tableColumnDate('Inbetriebnahme', {
                        scope: '#/properties/field202',
                    }),
                    tableColumnDecimal('Anerkannter Betrag', {
                        scope: '#/properties/field0803',
                    }),
                ],
                [],
                [],
                [],
                [
                    formDialog(
                        'Neue Modernisierung',
                        'Modernisierung',
                        labeled({ labelScope: 'massnahmeUuid' }),
                        customVerticalLayout([
                            group([
                                control('field202'),
                                control('field0803'),
                                control('field1790a'),
                                control('field1790b'),
                                control('field1790c'),
                                control('field0805'),
                                control('field1792a'),
                                control('field1792'),
                                control('field1793'),
                                control('field1794'),
                                control('field1795'),
                                control('field1796a'),
                                {
                                    type: 'ControlsOfElements',
                                    elementScope: '#/properties/finanzierungsbasisFiktivePlatzzahlerweiterung',
                                    controlScope: '#/properties/field1796b',
                                },
                                control('field1796'),
                                control('field1797a'),
                                {
                                    type: 'ControlsOfElements',
                                    elementScope: '#/properties/finanzierungsbasisFiktivePlatzzahlerweiterung',
                                    controlScope: '#/properties/field1797b',
                                },
                                control('field1797c'),
                                control('field1797'),
                                control('field1798'),
                                control('field1799'),
                                control('field1800'),
                                control('field1801'),
                                control('field1802'),
                                control('field1803'),
                            ]),
                        ]),
                        { scope: '#/properties/modernisierungP8Abs6' }
                    ),
                ]
            ),
        ],
        { noSeparator: true },
        'Vermietermodernisierung'
    ),
    group(
        [
            {
                type: 'HighlightedArea',
                elements: [control('field1804'), control('field1805')],
            },
        ],
        { noSeparator: true }
    ),
]);
