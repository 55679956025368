import React, { useEffect } from 'react';
import { isEqual } from 'lodash';
import create from 'zustand';

import { MenuInterface } from 'layout/HeaderConfig';

interface SideBarState {
    menus: MenuInterface[];
    setMenus: (menus: MenuInterface[]) => void;
}

export const useSideBar = create<SideBarState>((set, get) => ({
    menus: [],
    setMenus: (menus: MenuInterface[]) => {
        if (isEqual(get().menus, menus)) {
            return;
        }
        set({ menus });
    },
}));

export const useSideBarMenuConfig = (menus: MenuInterface[] = []) => {
    const setMenus = useSideBar((s) => s.setMenus);
    useEffect(() => {
        if (!menus) {
            return;
        }
        setMenus(menus);
    }, [menus, setMenus]);
};

export const withSideBar = (Component: React.FC, menus: MenuInterface[] = []): React.FC => {
    return () => {
        useSideBarMenuConfig(menus);
        return <Component />;
    };
};
