import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/AntragForm/ui-schemas/elements/customVerticalLayout';
import { groupList } from 'forms/AntragForm/ui-schemas/elements/groupList';
import { highlightedArea } from 'forms/AntragForm/ui-schemas/elements/highlightedArea';

export const uiSchema = customVerticalLayout([
    groupList(
        '#/properties/jahr',
        ['#/properties/aufwandInstandhaltung'],
        [control('field530')],
        { vertical: true },
        {
            fieldnumber: 1,
            label: 3,
            input: 3,
        }
    ),
    control('field5506'),
    control('field5507'),
    control('field5508'),
    control('field5509'),
    control('field5510'),
    control('field5505'),
    control('field5505kv'),
    control('field5308c'),
    control('field5308g'),
    control('field5504'),
    control('field5504kv'),
    highlightedArea([control('field5511')]),
]);
