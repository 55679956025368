import React from 'react';
import { LayoutProps, rankWith } from '@jsonforms/core';
import { withJsonFormsLayoutProps } from '@jsonforms/react';
import { Grid } from '@mui/material';

import { GroupListItemControl } from 'forms/controls/GroupList/GroupListItem';
import { ControlsOfElementsType } from 'forms/types/UiSchemaTypes';

import { useControlsOfElements } from './hooks/useControlsOfElements';

interface ControlOfElementsProps extends Omit<LayoutProps, 'uischema'> {
    uischema: ControlsOfElementsType;
}

const ControlsOfElementsComponent: React.FC<ControlOfElementsProps> = ({ schema, uischema, data, config }) => {
    const groupItems = useControlsOfElements(data, schema, uischema.elementScope);
    return (
        <Grid container>
            {groupItems.map((group, i) => {
                return (
                    <GroupListItemControl
                        key={i}
                        group={group}
                        uischema={{
                            type: 'Control',
                            scope: uischema.controlScope,
                        }}
                        vertical
                        config={config}
                    />
                );
            })}
        </Grid>
    );
};

// @ts-ignore
export const ControlsOfElements = withJsonFormsLayoutProps(ControlsOfElementsComponent);

export const ControlsOfElementsTester = rankWith(1, (uischema) => {
    return uischema.type === 'ControlsOfElements';
});
