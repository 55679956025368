import { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { EinrichtungJsonldEinrichtungZustaendigerBenutzer } from 'api/client';
import { useApiEinrichtungItem } from 'api/hooks/useApiClient';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { FormButton } from 'forms/components/FormButton';
import { useMessageActions } from 'forms/state/useMessages';
import { errorMessage, successMessage } from 'forms/utils/MessageUtils';
import {
    FormEinrichtung,
    ZustaendigkeitEinrichtungFormDataInterface,
    ZustaendigkeitEinrichtungFormErrorsInterface,
} from 'pages/Verwaltung/Zustaendigkeiten/Einrichtung/FormEinrichtung';

interface ZustaendigkeitEinrichtungDialogPropsInterface {
    handleEinrichtungZustaendigkeitSaved?(): void;

    handleExit?(): void;

    einrichtungId: string;
}

export const DialogEinrichtung = ({
    handleEinrichtungZustaendigkeitSaved,
    handleExit,
    einrichtungId,
}: ZustaendigkeitEinrichtungDialogPropsInterface) => {
    const { isLoading, data: einrichtung } = useApiEinrichtungItem(einrichtungId);
    const [errors, setErrors] = useState<ZustaendigkeitEinrichtungFormErrorsInterface | undefined>();
    const [showState, setShowState] = useState<boolean>(true);
    const [isSubmitting, setSubmitting] = useState<boolean>(false);
    const [valid, setValid] = useState<boolean>(false);
    const [submit, setSubmit] = useState<boolean>(false);
    const { addMessage } = useMessageActions();

    const handleCloseButtonClick = () => {
        setShowState(false);
    };

    const handleSubmitOnClick = () => setSubmit(true);

    const handleSubmit = (formData: ZustaendigkeitEinrichtungFormDataInterface) => {
        if (isSubmitting) {
            return;
        }

        setSubmitting(true);
        setErrors(undefined);
        setSubmit(false);

        const data: EinrichtungJsonldEinrichtungZustaendigerBenutzer = {
            zustaendigerBenutzer: String(formData?.zustaendigerBenutzer?.id),
        };

        backendApiService
            .putEinrichtungZustaendigerBeutzer(einrichtungId, data)
            .then(() => {
                addMessage(
                    successMessage('Die Zuständigkeit wurde geändert.', {
                        autoCloseSeconds: 8,
                    })
                );
                setSubmitting(false);
                if (handleEinrichtungZustaendigkeitSaved) {
                    handleEinrichtungZustaendigkeitSaved();
                }
                setShowState(false);
            })
            .catch(() => {
                addMessage(
                    errorMessage('Es ist ein Fehler aufgetreten.', {
                        autoCloseSeconds: 8,
                    })
                );
                setSubmitting(false);
            });
    };

    return (
        <Dialog
            open={showState}
            fullWidth={true}
            maxWidth="md"
            TransitionProps={{
                onExit: handleExit,
            }}
        >
            <DialogTitle>Zuständigkeit ändern</DialogTitle>
            <AppLoaderContainer isLoading={isLoading || isSubmitting}>
                <DialogContent>
                    <Grid container component={'dl'}>
                        <Grid item md={3} component={'dt'} alignItems={'flex-start'}>
                            Einrichtung
                        </Grid>
                        <Grid item md={9} component={'dd'} justifyContent={'flex-start'}>
                            {einrichtung?.name}
                        </Grid>
                    </Grid>
                    <FormEinrichtung
                        handleSubmit={handleSubmit}
                        isSubmitting={isSubmitting}
                        errors={errors}
                        einrichtung={einrichtung}
                        setValid={setValid}
                        submit={submit}
                    />
                </DialogContent>
                <DialogActions>
                    <FormButton type="button" onClick={handleCloseButtonClick} disabled={isSubmitting}>
                        Schließen
                    </FormButton>
                    <FormButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={handleSubmitOnClick}
                        disabled={isSubmitting || !valid}
                    >
                        Speichern
                    </FormButton>
                </DialogActions>
            </AppLoaderContainer>
        </Dialog>
    );
};
