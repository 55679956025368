export const uiSchema = {
    type: 'CustomVerticalLayout',
    elements: [
        {
            type: 'Group',
            elements: [
                {
                    type: 'Control',
                    scope: '#/properties/field231',
                },
                {
                    type: 'Separator',
                },
                {
                    type: 'FormExtendButton',
                    label: 'Ausnahmegenehmigung hinzufügen',
                    scope: '#/properties/ausnahmegenehmigungenP10Abs3Satz2Ziffer2Apg',
                    uiSchema: {
                        type: 'CustomVerticalLayout',
                        elements: [
                            {
                                type: 'Control',
                                scope: '#/properties/field233',
                                options: {
                                    autofocus: true,
                                },
                            },
                            {
                                type: 'Control',
                                scope: '#/properties/field234',
                            },
                            {
                                type: 'Control',
                                scope: '#/properties/field233d',
                            },
                        ],
                    },
                },
                {
                    type: 'Separator',
                    rule: {
                        effect: 'SHOW',
                        condition: {
                            scope: '#/properties/field231',
                            schema: {
                                const: true,
                            },
                        },
                    },
                },
                {
                    type: 'Control',
                    scope: '#/properties/field0401',
                },
                {
                    type: 'Control',
                    scope: '#/properties/field0402',
                },
                {
                    type: 'Control',
                    scope: '#/properties/field0403',
                },
                {
                    type: 'Control',
                    scope: '#/properties/field0404',
                },
            ],
        },
    ],
};
