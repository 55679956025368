import { ReactElement } from 'react';
import { MenuItem } from '@mui/material';

import { AntragResponse, AntragVersionCollectionResponse } from 'api/types';
import { ActionDropdown } from 'components/DataTableHydra/ActionDropdown/ActionDropdown';
import { AntragVersionTableActions } from 'constants/antragVersionTableActions';

interface AntragVersionListActionsProps {
    antrag: AntragResponse;
    antragVersion: AntragVersionCollectionResponse;
    handleAction: (
        action: AntragVersionTableActions,
        antragVersion: AntragVersionCollectionResponse,
        antrag: AntragResponse
    ) => void;
}

export const AntragDetailsVersionListActions = ({
    antrag,
    antragVersion,
    handleAction,
}: AntragVersionListActionsProps): ReactElement => {
    return (
        <ActionDropdown>
            <MenuItem onClick={() => handleAction(AntragVersionTableActions.ANSEHEN, antragVersion, antrag)}>
                Ansehen
            </MenuItem>
            <MenuItem onClick={() => handleAction(AntragVersionTableActions.DOKUMENTE, antragVersion, antrag)}>
                Dokumente
            </MenuItem>
            <MenuItem onClick={() => handleAction(AntragVersionTableActions.BESCHEIDE, antragVersion, antrag)}>
                Bescheide
            </MenuItem>
        </ActionDropdown>
    );
};
