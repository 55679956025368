import { ReactElement } from 'react';
import { JsonForms } from '@jsonforms/react';
import { Grid, Typography } from '@mui/material';

import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { FormButton } from 'forms/components/FormButton';
import { renderers } from 'forms/renderers';
import { uiSchema } from 'pages/Login/formSchemas';
import {
    useBenutzerdatenCompletionForm,
    useBenutzerdatenCompletionFormSchema,
} from 'pages/Login/hooks/useBenutzerdatenCompletionForm';

interface CompleteBenutzerdatenFormProps {
    benutzerId: number | undefined;
}

export const CompleteBenutzerdatenForm = ({ benutzerId }: CompleteBenutzerdatenFormProps): ReactElement => {
    const { isLoading, isValid, onSubmit, ...formProps } = useBenutzerdatenCompletionForm(benutzerId);
    const schema = useBenutzerdatenCompletionFormSchema();

    return (
        <AppLoaderContainer isLoading={isLoading}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography>
                        Um Ihren Benutzer in PfAD.invest zu registrieren, ergänzen Sie bitte Ihre Benutzerdaten.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <JsonForms schema={schema} uischema={uiSchema} renderers={renderers} {...formProps} />
                </Grid>
                <Grid item xs={12} container justifyContent="flex-end">
                    <FormButton color="primary" variant="contained" onClick={onSubmit} disabled={!isValid}>
                        Profildaten speichern
                    </FormButton>
                </Grid>
            </Grid>
        </AppLoaderContainer>
    );
};
