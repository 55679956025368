import { Components, createTheme, ThemeOptions } from '@mui/material';

import '@fontsource/open-sans';
import 'mui-datatables';
import { mixins } from './mixins';
import { palette } from './palette';
import { typography } from './typography';

const overrides: Components = {
    MuiCssBaseline: {
        styleOverrides: {
            address: {
                fontStyle: 'normal',
            },
            ul: {
                paddingLeft: 20,
            },
            ol: {
                paddingLeft: 20,
            },
            dl: {
                display: 'grid',
                gridTemplateColumns: '25% auto',
            },
            dt: {
                fontWeight: 'bold',
                gridColumn: 1,
            },
            dd: {
                gridColumn: 2,
            },
            a: {
                color: '#233755',
                cursor: 'pointer',
                textDecoration: 'underline',
                '&:visited': {
                    color: '#2b1c44',
                },
                '&.MuiListItemButton-root:visited': {
                    color: 'inherit',
                },
            },
            'a.arrowLink': {
                '&::before': {
                    verticalAlign: 'text-bottom',
                    content: '"» "',
                },
            },
        },
    },
    MuiDialog: {
        styleOverrides: {
            paperScrollPaper: {
                maxHeight: 'calc(100% - 152px)',
            },
        },
    },
    MuiInputAdornment: {
        styleOverrides: {
            root: {
                '& .MuiIconButton-root': {
                    padding: 6,
                },
            },
        },
    },
    MuiSvgIcon: {
        styleOverrides: {
            root: {
                color: '#999',
                '.MuiFormControl-root &': {
                    color: '#006ECB',
                },
                '.Mui-disabled &': {
                    color: '#999',
                },
            },
        },
    },
    MuiDialogActions: {
        styleOverrides: {
            root: {
                margin: 10,
            },
        },
    },
    MuiFormControl: {
        styleOverrides: {
            root: {
                borderRadius: 4,
            },
        },
    },
    MuiOutlinedInput: {
        styleOverrides: {
            root: {
                borderRadius: 4,
            },
        },
    },
    MuiInputBase: {
        styleOverrides: {
            root: {
                backgroundColor: '#fff',
            },
        },
    },
    MuiMenuItem: {
        styleOverrides: {
            root: {
                color: '#000',
                '&:focus': {
                    borderColor: '#006ECB',
                },
            },
        },
    },
    MuiFormLabel: {
        styleOverrides: {
            root: {
                'legend&': {
                    marginTop: 9,
                    color: '#333',
                    lineHeight: 1.4,
                },
            },
        },
    },
    MuiAlert: {
        styleOverrides: {
            root: {
                marginBottom: 15,
            },
            standardInfo: {
                backgroundColor: '#EAEFF6',
            },
            message: {
                width: '100%',
            },
        },
    },
    MuiTabs: {
        styleOverrides: {
            root: {
                borderBottom: '1px solid #006ECB',
                fontWeight: 'bold',
                color: '#006ECB',
                '& .Mui-selected': {
                    color: '#006ECB',
                },
                '& .Mui-disabled': {
                    color: '#AAA',
                },
            },
            indicator: {
                height: '4px',
                backgroundColor: '#006ECB',
            },
        },
    },
    MuiTab: {
        styleOverrides: {
            root: {
                fontWeight: 'bold',
                '&selected': {
                    color: '#006ECB',
                },
            },
        },
    },
    MuiTableSortLabel: {
        styleOverrides: {
            root: {
                width: '18px',
            },
        },
    },
    MuiTableRow: {
        styleOverrides: {
            root: {
                '&$hover:hover': {
                    backgroundColor: '#EAEFF6',
                },
            },
        },
    },
    MuiTableCell: {
        styleOverrides: {
            footer: {
                border: 0,
            },
            head: {
                fontWeight: 'bolder',
            },
        },
    },
    //@ts-ignore
    MUIDataTableHeadCell: {
        styleOverrides: {
            data: {
                fontWeight: 'bolder',
            },
            sortAction: {
                lineHeight: '1.25rem',
            },
        },
    },
    MUIDataTableFilter: {
        styleOverrides: {
            header: {},
            root: {
                '& .MuiGrid-root': {
                    alignItems: 'normal',
                },
                '& .MuiAutocomplete-root': {
                    marginTop: '15px',
                },
            },
        },
    },
    MUIDataTable: {
        styleOverrides: {
            paper: {
                boxShadow: 'none',
            },
            root: {
                overflow: 'hidden',
            },
            responsiveBase: {
                overflow: 'visible',
            },
        },
    },
    MuiLink: {
        styleOverrides: {
            root: {
                color: '#233755',
            },
        },
        defaultProps: {
            underline: 'always',
        },
    },
    MuiTextField: {
        defaultProps: {
            variant: 'outlined',
            size: 'small',
        },
    },
    MuiSelect: {
        defaultProps: {
            variant: 'outlined',
        },
    },
};

export const themeOptions: ThemeOptions = {
    components: {
        ...overrides,
    },
    typography: {
        ...typography,
        button: {
            textTransform: 'none', // keine Ahnung warum das nicht innerhalb der importierten typography funktioniert
            fontSize: 16,
        },
    },
    palette: {
        ...palette,
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1450,
            xl: 1450,
        },
    },
    mixins: {
        ...mixins,
    },
    shape: {
        borderRadius: 0,
    },
    zIndex: {
        drawer: 1000,
        modal: 1001,
        snackbar: 1002,
        tooltip: 1003,
    },
};

export const theme = createTheme({ ...themeOptions });
