import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/AntragForm/ui-schemas/elements/customVerticalLayout';
import { formDialog } from 'forms/AntragForm/ui-schemas/elements/formDialog';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { highlightedArea } from 'forms/AntragForm/ui-schemas/elements/highlightedArea';
import { listControlWithModalForm } from 'forms/AntragForm/ui-schemas/elements/listControlWithModalForm';
import { tableColumn, tableColumnDate, tableColumnDecimal } from 'forms/AntragForm/ui-schemas/elements/tableColumn';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';
import { gepooltePlaetze } from 'forms/AntragForm/ui-schemas/groups/gepooltePlaetze';
import { zuschuesse } from 'forms/AntragForm/ui-schemas/groups/zuschuesse';

import { flaechenreduzierungen } from './aufwandLalg/flaechenreduzierungen';
import { platzzahlreduzierungen } from './aufwandLalg/platzzahlreduzierungen';
import { verteilzeitraume } from './aufwandLalg/verteilzeitraume';

export const uiSchema = verticalLayout([
    group([control('field140'), control('field0500a'), control('field0500b'), control('field0500bKv')]),
    group(
        [
            listControlWithModalForm(
                ['#/properties/massnahmenAufwandLalg'],
                [
                    tableColumn('Art', {
                        scope: '#/properties/field200',
                    }),
                    tableColumn('Maßnahme', {
                        scope: '#/properties/field201',
                    }),
                    tableColumnDate('Inbetriebnahme', {
                        scopes: ['#/properties/field202', '#/properties/field207'],
                    }),
                    tableColumnDecimal('Anerkannter Betrag', {
                        scope: '#/properties/field226',
                    }),
                    tableColumnDecimal('Prozentualer Anteil', {
                        scope: '#/properties/field220pa',
                    }),
                    tableColumnDecimal('Gesamtplatzzahl', {
                        scope: '#/properties/field204a',
                    }),
                    {
                        type: 'Control',
                        label: 'Bearbeitet',
                        scope: '#/properties/aufwandLalgBearbeitet',
                        styles: {
                            textAlign: 'right',
                        },
                    },
                ],
                ['Maßnahme hinzufügen'],
                ['Maßnahme löschen'],
                ['Wollen Sie diese Maßnahme mit allen Angaben wirklich aus Ihrem Antrag löschen?'],
                [
                    formDialog(
                        'Neue Maßnahme',
                        'Maßnahme',
                        { labelScope: '#/properties/field200' },
                        customVerticalLayout([
                            group([
                                control('field200'),
                                control('field201'),
                                control('field202'),
                                control('field0534'),
                                control('field203'),
                                control('field204'),
                                control('field205'),
                                control('field206'),
                                control('field290'),
                                control('field210'),
                                control('field246'),
                                control('field220'),
                                control('field220d'),
                                control('field221'),
                                control('field222'),
                                control('field223'),
                                control('field224'),
                                control('field225'),
                                control('field227'),
                                control('field211'),
                                control('field226'),
                                control('field232d'),
                            ]),
                            gepooltePlaetze,
                            zuschuesse(),
                            group([
                                control('field0503'),
                                control('field247'),
                                control('field213'),
                                control('field207'),
                            ]),
                            verteilzeitraume,
                            group([control('field0509')]),
                            flaechenreduzierungen,
                            platzzahlreduzierungen,
                            group([
                                highlightedArea([control('field0519'), control('field0520')]),

                                control('field0530'),
                                control('field0531'),
                                control('field0532'),
                                control('field0533'),
                                control('field230'),
                                control('field232'),
                            ]),
                        ]),
                        {}
                    ),
                ],
                {}
            ),
        ],
        {},
        'Maßnahmen'
    ),
    group([control('field0521'), control('field0522'), control('field0524')], {}, 'Gesamtsummen'),
]);
