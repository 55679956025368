import * as React from 'react';
import { ChangeEvent, useCallback } from 'react';
import { Box, FormControl, FormHelperText, styled, TextField } from '@mui/material';

import { ErrorList } from 'forms/controls/components/ErrorList';
import { ReadonlyValueText } from 'forms/controls/components/ReadonlyValueText';
import { useScrollInView } from 'forms/hooks/useScrollInView';
import { useTempControlData } from 'forms/hooks/useTempControlData';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';
import { GridLayoutConfig } from 'forms/types/UiSchemaTypes';

import { RowControl } from './RowControl';

const PlaintextMehrzeiligerTextControlComponent: React.FC<CustomControlProps<string>> = ({
    path,
    label,
    required,
    data,
    handleChange,
    config,
    uischema,
    readonly,
    hasErrors,
    errors,
    showFieldNumberLabels,
    gridLayout,
    onTouched,
    id,
}) => {
    const eventToData = useCallback((e: ChangeEvent<HTMLInputElement>) => e.target.value, []);
    const { tempData, handleValueChange, handleOnBlur } = useTempControlData(
        data,
        path,
        errors,
        handleChange,
        eventToData,
        onTouched,
        {
            submitOnChange: true,
        }
    );
    const ref = useScrollInView<HTMLDivElement>();

    const defaultGrid: GridLayoutConfig = {
        fieldnumber: 1,
        label: 3,
        input: 8,
    };
    const grid = { ...defaultGrid, ...gridLayout };

    return (
        <RowControl
            name={path}
            label={uischema?.options?.noLabel === true ? undefined : label}
            required={required}
            multiline={uischema?.options?.multiline}
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={grid}
            labelForId={id}
            config={config}
            paths={[path]}
        >
            <FormControl fullWidth ref={ref} error={hasErrors}>
                {readonly ? (
                    <ReadonlyContainer>
                        <ReadonlyValueText path={path} hasErrors={hasErrors} errors={errors} text={data || '-'} />
                    </ReadonlyContainer>
                ) : (
                    <>
                        <TextField
                            id={id}
                            fullWidth
                            value={tempData}
                            onBlur={handleOnBlur}
                            onChange={handleValueChange}
                            multiline={true}
                            rows={5}
                        />
                        <FormHelperText>{hasErrors ? <ErrorList errors={errors} /> : ' '}</FormHelperText>
                    </>
                )}
            </FormControl>
        </RowControl>
    );
};

const ReadonlyContainer = styled(Box)(({ theme }) => ({
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 4,
    borderColor: theme.palette.divider,
    margin: -8,
    padding: 6,
}));

export const PlaintextMehrzeiligerTextControl = withCustomControlProps(PlaintextMehrzeiligerTextControlComponent);
