import { ReactElement, useRef } from 'react';
import debounceRender from 'react-debounce-render';
import { useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';
import { MUIDataTableOptions } from 'mui-datatables';

import { AppLoaderContainer } from 'components/AppLoaderContainer';

import { DataTableController, DataTableHydra } from './DataTableHydra';
import { DataTableHydraFilterButtons } from './DataTableHydraFilterButtons';
import { GetColumns } from './hooks/useTableColumns';
import { GetData } from './hooks/useTableData';
import { useTableParameters } from './hooks/useTableParameters';

interface ServersideDataTableHydraProps<Data> {
    getColumns: GetColumns<Data>;
    getData: GetData<Data>;
    initialOptions?: MUIDataTableOptions;
    loadingContext?: boolean;
    serverDataChanged?: number;
    nameOverride?: string;
}

export const DataTableHydraServerside = <Data,>({
    getColumns,
    getData,
    initialOptions,
    loadingContext,
    serverDataChanged,
    nameOverride,
}: ServersideDataTableHydraProps<Data>): ReactElement => {
    const location = useLocation();
    const tableRef = useRef<DataTableController>();
    const { isLoading, data, columns, options, filterButtonProps } = useTableParameters(
        nameOverride || location.pathname,
        initialOptions,
        getColumns,
        getData,
        [serverDataChanged]
    );

    return (
        <AppLoaderContainer isLoading={!data || !!loadingContext}>
            <Grid container spacing={2}>
                <Grid item xs={12} container justifyContent="flex-end">
                    <DataTableHydraFilterButtons {...filterButtonProps} tableRef={tableRef} />
                </Grid>
                <Grid item xs={12}>
                    <DataTable
                        data={data}
                        isLoading={isLoading}
                        columns={columns}
                        options={options}
                        tableRef={tableRef}
                    />
                </Grid>
            </Grid>
        </AppLoaderContainer>
    );
};

const DataTable = debounceRender(DataTableHydra, 100);
