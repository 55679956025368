import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';
import { notes } from 'forms/AntragForm/ui-schemas/groups/notes';

export const uiSchema = verticalLayout([
    group(
        [
            {
                type: 'LabelRowControl',
                text: 'Wird der Antrag nicht von der Trägerin / dem Träger der Pflegeinrichtung, sondern durch einen Bevollmächtigten gestellt, bitten wir um die Vorlage der entsprechenden Vollmacht.',
            },
            control('field410'),
            control('field410d'),
        ],
        {},
        'Vollmacht'
    ),

    group(
        [
            {
                type: 'PDFDownloadControl',
                text: 'Zusammenfassung öffnen',
            },
            {
                type: 'LabelRowControl',
                text: 'Im Antrag sind die Aufwendungen gemäß § 9 Abs. 1 Nr. 2 APG DVO als Gesamtsummen zu erfassen. Alle Angaben im Antrag müssen durch entsprechende Belege bzw. Nachweise für den Landschaftsverband nachprüfbar sein.',
            },
            {
                type: 'LabelRowControl',
                text: 'Die Landschaftsverbände behalten sich stichprobenhaft Prüfungen der entsprechenden Belege vor (s. hierzu § 9 Abs. 3 i.V.m. Abs. 4 APG DVO).',
            },
            control('field411'),
            control('field412'),
        ],
        {},
        'Richtigkeit der Daten'
    ),
    notes('notizAbsenden'),
]);
