import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/AntragForm/ui-schemas/elements/customVerticalLayout';
import { description } from 'forms/AntragForm/ui-schemas/elements/description';
import { formExtendButton } from 'forms/AntragForm/ui-schemas/elements/formExtendButton';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { info } from 'forms/AntragForm/ui-schemas/elements/info';
import { inlineRowControl } from 'forms/AntragForm/ui-schemas/elements/inlineRowControl';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';
import { notes } from 'forms/AntragForm/ui-schemas/groups/notes';

export const uiSchema = verticalLayout([
    group(
        [
            info(
                'Gemäß § 12 Abs. 4 APG DVO ist eine sachgerechte Differenzierung nach den Unterschieden des Raumangebotes zulässig. Bitte tragen Sie hier die von Ihnen gewünschte Differenzierung ein.'
            ),
        ],
        {}
    ),
    group([control('field551')], {}, 'Standard-Einzelzimmer'),
    group([control('field552'), control('field555')], {}, 'Standard-Doppelzimmer'),
    group(
        [
            formExtendButton(
                customVerticalLayout([
                    inlineRowControl(
                        [
                            control('field550', {
                                controlOnly: true,
                                options: { firstControl: true },
                            }),
                            control('field550Art', { controlOnly: true }),
                        ],
                        '#/properties/field550',
                        {}
                    ),
                    control('field553'),
                    inlineRowControl(
                        [
                            control('field554', {
                                controlOnly: true,
                                gridSize: 3,
                            }),
                            description('+/- Differenz zum Standard-Einzelzimmer'),
                        ],
                        '#/properties/field554',
                        {
                            gridLayout: {
                                fieldnumber: 1,
                                label: 3,
                                input: 6,
                            },
                        }
                    ),
                ]),
                '#/properties/zimmerkategorien',
                'Zimmerart hinzufügen'
            ),
        ],
        {},
        'Weitere Zimmer'
    ),
    group([control('field790')]),
    notes('notizZimmerkategorien'),
]);
