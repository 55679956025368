import { useMemo } from 'react';

import { AntragType } from 'api/antragTypes';
import {
    FestsetzungsAntragCollectionResponse,
    FeststellungsAntragCollectionResponse,
    InvestorenmodellCollectionResponse,
    MeldungP29CollectionResponse,
} from 'api/types';
import { ACTIONS } from 'constants/antragActions';
import { ANTRAG_STATUS_LABELS } from 'constants/labels';
import { InvestPathBuilder } from 'navigation/Paths';
import { useAnbieterDashboardState } from 'pages/Dashboard/Anbieter/useAnbieterDashboardState';

export interface AktivitaetenTableAction {
    key: string;
    title: string;
    url: 'ViewZusammenfassungPDF' | 'ZurueckziehenBeantragen' | string;
}

type FeststellungAktivitaetenTableData = {
    antrag: FeststellungsAntragCollectionResponse;
    typ: AntragType.FESTSTELLUNG;
};

type FestsetzungAktivitaetenTableData = {
    antrag: FestsetzungsAntragCollectionResponse;
    typ: AntragType.FESTSETZUNG;
};

type InvestorenmodellAktivitaetenTableData = {
    antrag: InvestorenmodellCollectionResponse;
    typ: AntragType.INVESTOREN_MODELL;
};
type MeldungP29AktivitaetenTableData = {
    antrag: MeldungP29CollectionResponse;
    typ: AntragType.MELDUNG_P29;
};

export type AktivitaetenTableData = (
    | FestsetzungAktivitaetenTableData
    | FeststellungAktivitaetenTableData
    | InvestorenmodellAktivitaetenTableData
    | MeldungP29AktivitaetenTableData
) & {
    id: number;
    title: string;
    typ: string;
    zum: string | null;
    status: string | null;
    actions: AktivitaetenTableAction[];
};

export const useAktivitaetenTableData = (
    feststellungsantraege: FeststellungsAntragCollectionResponse[],
    festsetzungsantraege: FestsetzungsAntragCollectionResponse[],
    investorenmodelle: InvestorenmodellCollectionResponse[],
    meldungenP29: MeldungP29CollectionResponse[]
): AktivitaetenTableData[] | undefined => {
    const { isAntragSteller } = useAnbieterDashboardState();

    return useMemo(() => {
        const aktivitaetenTableData: AktivitaetenTableData[] = [];

        feststellungsantraege.forEach((feststellungsantrag) => {
            const actions: AktivitaetenTableAction[] = [];

            actions.push({
                key: ACTIONS.VIEW,
                title: 'Ansehen',
                url: InvestPathBuilder.home.antraege.feststellung.view(feststellungsantrag.id),
            });

            if (isAntragSteller && feststellungsantrag.allowedWorkflowActions?.includes(ACTIONS.EDIT)) {
                actions.push({
                    key: ACTIONS.EDIT,
                    title: 'Bearbeiten',
                    url: InvestPathBuilder.home.antraege.feststellung.edit(feststellungsantrag.id),
                });
            }

            if (isAntragSteller && feststellungsantrag.allowedWorkflowActions?.includes(ACTIONS.DELETE)) {
                actions.push({
                    key: ACTIONS.DELETE,
                    title: 'Löschen',
                    url: 'Loeschen',
                });
            }

            if (isAntragSteller && feststellungsantrag.allowedWorkflowActions?.includes(ACTIONS.ABBRECHEN)) {
                actions.push({
                    key: ACTIONS.ABBRECHEN,
                    title: 'Abbrechen',
                    url: 'Abbrechen',
                });
            }

            if (isAntragSteller && feststellungsantrag.allowedWorkflowActions?.includes(ACTIONS.ZURUECKZIEHEN)) {
                actions.push({
                    key: ACTIONS.ZURUECKZIEHEN,
                    title: 'Zurückziehen',
                    url: 'Zurueckziehen',
                });
            }

            if (
                isAntragSteller &&
                feststellungsantrag.allowedWorkflowActions?.includes(ACTIONS.ZURUECKZIEHEN_BEANTRAGEN)
            ) {
                actions.push({
                    key: ACTIONS.ZURUECKZIEHEN_BEANTRAGEN,
                    title: 'Zurückziehen',
                    url: 'ZurueckziehenBeantragen',
                });
            }

            aktivitaetenTableData.push({
                id: feststellungsantrag.id,
                antrag: feststellungsantrag,
                typ: AntragType.FESTSTELLUNG,
                title: 'Feststellung',
                zum: feststellungsantrag.data?.field102 || null,
                status: feststellungsantrag.status ? ANTRAG_STATUS_LABELS[feststellungsantrag.status] : null,
                actions: actions,
            });
        });

        festsetzungsantraege.forEach((festsetzungsantrag) => {
            const actions: AktivitaetenTableAction[] = [];
            actions.push({
                key: ACTIONS.VIEW,
                title: 'Ansehen',
                url: InvestPathBuilder.home.antraege.festsetzung.view(festsetzungsantrag.id),
            });

            if (isAntragSteller && festsetzungsantrag.allowedWorkflowActions?.includes(ACTIONS.EDIT)) {
                actions.push({
                    key: ACTIONS.EDIT,
                    title: 'Bearbeiten',
                    url: InvestPathBuilder.home.antraege.festsetzung.edit(festsetzungsantrag.id),
                });
            }

            if (isAntragSteller && festsetzungsantrag.allowedWorkflowActions?.includes(ACTIONS.DELETE)) {
                actions.push({
                    key: ACTIONS.DELETE,
                    title: 'Löschen',
                    url: 'Loeschen',
                });
            }

            if (isAntragSteller && festsetzungsantrag.allowedWorkflowActions?.includes(ACTIONS.ABBRECHEN)) {
                actions.push({
                    key: ACTIONS.ABBRECHEN,
                    title: 'Abbrechen',
                    url: 'Abbrechen',
                });
            }

            if (isAntragSteller && festsetzungsantrag.allowedWorkflowActions?.includes(ACTIONS.ZURUECKZIEHEN)) {
                actions.push({
                    key: ACTIONS.ZURUECKZIEHEN,
                    title: 'Zurückziehen',
                    url: 'Zurueckziehen',
                });
            }
            if (
                isAntragSteller &&
                festsetzungsantrag.allowedWorkflowActions?.includes(ACTIONS.ZURUECKZIEHEN_BEANTRAGEN)
            ) {
                actions.push({
                    key: ACTIONS.ZURUECKZIEHEN_BEANTRAGEN,
                    title: 'Zurückziehen',
                    url: 'ZurueckziehenBeantragen',
                });
            }
            aktivitaetenTableData.push({
                id: festsetzungsantrag.id,
                antrag: festsetzungsantrag,
                typ: AntragType.FESTSETZUNG,
                title: 'Festsetzung',
                zum: festsetzungsantrag.data?.field500 || null,
                status: festsetzungsantrag.status ? ANTRAG_STATUS_LABELS[festsetzungsantrag.status] : null,
                actions: actions,
            });
        });

        investorenmodelle.forEach((investorenmodell) => {
            const actions: AktivitaetenTableAction[] = [];
            actions.push({
                key: ACTIONS.VIEW,
                title: 'Ansehen',
                url: InvestPathBuilder.home.antraege.investorenmodell.view(investorenmodell.id),
            });

            if (isAntragSteller && investorenmodell.allowedWorkflowActions?.includes(ACTIONS.EDIT)) {
                actions.push({
                    key: ACTIONS.EDIT,
                    title: 'Bearbeiten',
                    url: InvestPathBuilder.home.antraege.investorenmodell.edit(investorenmodell.id),
                });
            }

            if (isAntragSteller && investorenmodell.allowedWorkflowActions?.includes(ACTIONS.DELETE)) {
                actions.push({
                    key: ACTIONS.DELETE,
                    title: 'Löschen',
                    url: 'Loeschen',
                });
            }

            if (isAntragSteller && investorenmodell.allowedWorkflowActions?.includes(ACTIONS.ZURUECKZIEHEN)) {
                actions.push({
                    key: ACTIONS.ZURUECKZIEHEN,
                    title: 'Zurückziehen',
                    url: 'Zurueckziehen',
                });
            }

            if (isAntragSteller && investorenmodell.allowedWorkflowActions?.includes(ACTIONS.ABBRECHEN)) {
                actions.push({
                    key: ACTIONS.ABBRECHEN,
                    title: 'Abbrechen',
                    url: 'Abbrechen',
                });
            }

            if (
                isAntragSteller &&
                investorenmodell.allowedWorkflowActions?.includes(ACTIONS.ZURUECKZIEHEN_BEANTRAGEN)
            ) {
                actions.push({
                    key: ACTIONS.ZURUECKZIEHEN_BEANTRAGEN,
                    title: 'Zurückziehen',
                    url: 'ZurueckziehenBeantragen',
                });
            }
            aktivitaetenTableData.push({
                id: investorenmodell.id,
                antrag: investorenmodell,
                typ: AntragType.INVESTOREN_MODELL,
                title: 'Antrag nach § 8 Abs. 11',
                zum: investorenmodell.festsetzungsantrag?.data?.field500 || null,
                status: investorenmodell.status ? ANTRAG_STATUS_LABELS[investorenmodell.status] : null,
                actions: actions,
            });
        });

        meldungenP29.forEach((meldungP29) => {
            const actions: AktivitaetenTableAction[] = [];
            actions.push({
                key: ACTIONS.VIEW,
                title: 'Ansehen',
                url: 'ViewZusammenfassungPDF',
            });
            if (isAntragSteller && meldungP29.allowedWorkflowActions?.includes(ACTIONS.EDIT)) {
                actions.push({
                    key: ACTIONS.EDIT,
                    title: 'Bearbeiten',
                    url: InvestPathBuilder.home.antraege.meldungen_p29.edit(meldungP29.id),
                });
            }
            if (isAntragSteller && meldungP29.allowedWorkflowActions?.includes(ACTIONS.DELETE)) {
                actions.push({
                    key: ACTIONS.DELETE,
                    title: 'Löschen',
                    url: 'Loeschen',
                });
            }
            aktivitaetenTableData.push({
                id: meldungP29.id,
                antrag: meldungP29,
                title: 'Meldung nach § 29',
                typ: AntragType.MELDUNG_P29,
                zum: meldungP29.data?.fieldM2901 || null,
                status: meldungP29.status ? ANTRAG_STATUS_LABELS[meldungP29.status] : null,
                actions: actions,
            });
        });

        aktivitaetenTableData.sort((a, b) => {
            return (a?.zum || '-1') > (b?.zum || '-1') ? -1 : 1;
        });

        return aktivitaetenTableData;
    }, [festsetzungsantraege, feststellungsantraege, investorenmodelle, meldungenP29, isAntragSteller]);
};
