import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AntragType } from 'api/antragTypes';
import { ACTIONS } from 'constants/antragActions';
import { useConfirmationDialogActions } from 'forms/state/useConfirmationDialogState';
import { openZusammenfassung } from 'navigation/PDFs';
import { determineAntragStatusAlertText } from 'pages/Antraege/antragStatusAenderung';

export type AntragListActionItem = {
    id: number;
    status?: string;
    allowedWorkflowActions?: string[];
    letztesZurueckziehen?: AntragZurueckziehen | null;
};
export type AntragZurueckziehen = {
    id: number;
    erlaeuterung: string | null;
    createdBy: { vorname: string; nachname: string };
    createdAt: string;
};

type UseAntragListActionsResult<T> = {
    serverDataChanged: number;
    handleAction: AntragListAction<T>;
    zurueckziehenBeantragen: T | undefined;
    zurueckziehenBeantragenFinishAction: (isConfirmed: boolean) => void;
    zurueckziehenAkzeptieren: T | undefined;
    zurueckziehenAkzeptierenFinishAction: (isConfirmed: boolean) => void;
};

type AntragPathBuilder = {
    view: (id: number) => string;
    edit: (id: number) => string;
};

type GetWorkflowAntragItem<T> = (id: number, action: string) => Promise<T>;

export type AntragListAction<T> = (antrag: T, action: string) => void;

export const useAntragListActions = <T extends AntragListActionItem, S>(
    pathBuilder: AntragPathBuilder,
    workflowAntragItem: GetWorkflowAntragItem<S>,
    type: AntragType
): UseAntragListActionsResult<T> => {
    const navigate = useNavigate();
    const [serverDataChanged, setServerDataChanged] = useState(0);
    const { showConfirmation, closeConfirmation } = useConfirmationDialogActions();
    const [zurueckziehenBeantragen, setZurueckziehenBeantragen] = useState<T | undefined>(undefined);
    const [zurueckziehenAkzeptieren, setZurueckziehenAkzeptieren] = useState<T | undefined>(undefined);

    const zurueckziehenBeantragenFinishAction = (isConfirmed: boolean) => {
        setZurueckziehenBeantragen(undefined);
        if (isConfirmed) setServerDataChanged((prevState) => prevState + 1);
    };
    const zurueckziehenAkzeptierenFinishAction = (isConfirmed: boolean) => {
        setZurueckziehenAkzeptieren(undefined);
        if (isConfirmed) setServerDataChanged((prevState) => prevState + 1);
    };

    const handleAction: AntragListAction<T> = useCallback(
        (antrag, action) => {
            const handleView = () => {
                if (type === AntragType.MELDUNG_P29) {
                    openZusammenfassung(type, antrag);
                } else {
                    navigate(pathBuilder.view(antrag.id));
                }
            };

            const handleEdit = () => navigate(pathBuilder.edit(antrag.id));

            const alertText = determineAntragStatusAlertText(antrag.status, action);
            const confirmLabel = 'Fortfahren';
            const denyLabel = 'Abbrechen';

            const handleAufheben = () => {
                showConfirmation({
                    alertText,
                    confirmLabel,
                    denyLabel,
                    confirmAction: async () => {
                        closeConfirmation();
                        await workflowAntragItem(antrag.id, action);
                        setServerDataChanged((prevState) => prevState + 1);
                    },
                    denyAction: closeConfirmation,
                });
            };

            const handleWorkflowChange = () => {
                showConfirmation({
                    alertText,
                    confirmLabel,
                    denyLabel,
                    confirmAction: async () => {
                        closeConfirmation();
                        await workflowAntragItem(antrag.id, action);
                        navigate(pathBuilder.edit(antrag.id));
                    },
                    denyAction: closeConfirmation,
                });
            };

            const handleZurKenntnisNehmen = () => {
                showConfirmation({
                    alertText,
                    confirmLabel,
                    denyLabel,
                    confirmAction: async () => {
                        closeConfirmation();
                        await workflowAntragItem(antrag.id, action);
                        setServerDataChanged((prevState) => prevState + 1);
                    },
                    denyAction: closeConfirmation,
                });
            };

            const handleZurueckziehenBeantragen = () => {
                setZurueckziehenBeantragen(antrag);
            };

            const handleZurueckziehenAkzeptieren = () => {
                setZurueckziehenAkzeptieren(antrag);
            };

            switch (action) {
                case ACTIONS.VIEW:
                    handleView();
                    break;
                case ACTIONS.EDIT:
                    handleEdit();
                    break;
                case ACTIONS.ABBRECHEN:
                case ACTIONS.AUFHEBEN:
                    handleAufheben();
                    break;
                case ACTIONS.ZUR_KENNTNIS_NEHMEN:
                    handleZurKenntnisNehmen();
                    break;
                case ACTIONS.BEARBEITUNG_STARTEN:
                case ACTIONS.BESCHEID_AENDERN:
                case ACTIONS.PRUEFEN:
                    handleWorkflowChange();
                    break;
                case ACTIONS.ZURUECKZIEHEN_BEANTRAGEN:
                    handleZurueckziehenBeantragen();
                    break;
                case ACTIONS.ZURUECKZIEHEN_AKZEPTIEREN:
                    handleZurueckziehenAkzeptieren();
                    break;
            }
        },
        [navigate, showConfirmation, closeConfirmation, pathBuilder, workflowAntragItem, type]
    );

    return {
        serverDataChanged,
        handleAction,
        zurueckziehenBeantragen,
        zurueckziehenBeantragenFinishAction,
        zurueckziehenAkzeptieren,
        zurueckziehenAkzeptierenFinishAction,
    };
};
