import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/AntragForm/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { groupForm } from 'forms/AntragForm/ui-schemas/elements/groupForm';
import { inlineGroupRowControl } from 'forms/AntragForm/ui-schemas/elements/inlineGroupRowControl';

export const uiSchema = customVerticalLayout([
    group([
        control('field950'),
        control('field5105a'),
        control('field5105b'),
        control('field5105c'),
        control('field5106'),
        control('field5194'),
        control('field5195'),
        control('field5196'),
        control('field1107'),
        control('field1108'),
        control('field1109a'),
        control('field1109b'),
        control('field1109c'),
        control('field5191'),
    ]),
    group(
        [
            control('field2002'),
            groupForm('#/properties/belegungSeparateKzp', 'Jahr {#/properties/jahr}', [
                inlineGroupRowControl('belegungSeparateKzp', 'field564'),
                inlineGroupRowControl('belegungSeparateKzp', 'field564a'),
                inlineGroupRowControl('belegungSeparateKzp', 'field564b'),
            ]),
            control('field5119aKzp'),
            control('field5122aKzp'),
            control('field5114Kzp'),
            control('field5194Kzp'),
            control('field5195Kzp'),
            control('field2003'),
            control('field2004'),
            control('field5114aKzp'),
        ],
        {},
        'Separate Kurzzeitpflege',
        '#/properties/field2002'
    ),
]);
