import { FormState, FormStateValue, Schema } from 'forms/types/UiSchemaTypes';

import { auswahlValueZuLabel } from './auswahlValueZuLabel';
import { formatBoolean, formatDate, formatDezimal, formatInteger } from './formatter';

export const buildPath = (parent?: string, ...paths: (string | number | undefined)[]) =>
    [parent, ...paths].filter((p) => p !== undefined && p !== '').join('.');

export const getParentSchemaOfField = (fieldScope: string | undefined, schema: Schema): Schema => {
    if (!fieldScope || fieldScope === '#/properties/') {
        return schema;
    }
    const scope = fieldScope.split(/\//g);
    scope.splice(0, 1);
    scope.reverse();
    scope.splice(0, 2);
    scope.reverse();
    return getPropValueByStackedPropsArray(schema, scope);
};

export const getElementSchemaFromCollection = (collectionSchema: Schema, field: string): Schema | undefined => {
    if (!collectionSchema.properties) {
        return undefined;
    }
    const firstItemKey = Object.keys(collectionSchema.properties!).find((k) => k.startsWith('i_'));
    if (!firstItemKey) {
        return undefined;
    }
    const firstItemProps = collectionSchema.properties[firstItemKey]?.properties;
    return firstItemProps && (firstItemProps[field] as Schema);
};

export const isCollection = (schema: Schema | undefined) =>
    Boolean(schema?.custom?.block_prefixes?.includes('collection'));

export const getFieldSchemaByScope = (fieldScope: string | undefined, schema: Schema): Schema => {
    if (!fieldScope || fieldScope === '#/properties/') {
        return schema;
    }

    const scope = fieldScope.split(/\//g);
    scope.splice(0, 1);
    if (isCollection(schema)) {
        // @ts-ignore
        return getPropValueByStackedPropsArray(schema?.properties?.i_, scope);
    }
    return getPropValueByStackedPropsArray(schema, scope);
};

const getPropValueByStackedPropsArray = (obj: Schema | undefined, propsArray: string[]): Schema => {
    return propsArray.reduce((currentObject, propKey) => {
        if (currentObject && isCollection(currentObject)) {
            const firstItem = Object.keys(currentObject).find((k) => k.startsWith('i_'));
            return firstItem && currentObject[firstItem];
        }
        return currentObject && currentObject[propKey];
    }, obj) as Schema;
};

export const determineSchemaForElement = (data: FormState, schema: Schema, index: number): Schema =>
    determineSchemaByUUID(data[index].uuid, schema);

export const determineSchemaByUUID = (uuid: string | undefined, schema: Schema): Schema => {
    if (!schema.properties) {
        return schema;
    }
    return (schema.properties[`i_${uuid}`] || schema.properties?.i_) as Schema;
};

export const isNumeric = (schema: Schema): boolean => {
    const numberTypes = ['number', 'integer', 'datum', 'date'];
    if (!schema || !schema.type) {
        return false;
    }
    if (Array.isArray(schema.type)) {
        return schema.type.some((t) => numberTypes.includes(t));
    }
    return Boolean(
        numberTypes.includes(schema.type!) || schema.custom?.block_prefixes.some((b) => numberTypes.includes(b))
    );
};

export const formatFieldValue = (
    value: FormStateValue,
    fieldSchema?: Schema,
    format?: string,
    unit?: string
): string => {
    const auswahlValue = fieldSchema?.oneOf?.find((o) => o.const === value)?.title;
    if (auswahlValue) {
        return auswahlValue;
    }
    const NULL_VALUE = '-';

    if (fieldSchema?.custom?.block_prefixes.includes('betrag')) {
        return formatDezimal(value, '€') as string;
    }
    if (fieldSchema?.custom?.block_prefixes.includes('m2')) {
        return formatDezimal(value, 'm²') as string;
    }
    if (fieldSchema?.custom?.block_prefixes.includes('prozent')) {
        return formatDezimal(value, '%') as string;
    }
    if (fieldSchema?.custom?.block_prefixes.includes('dezimalzahl')) {
        return formatDezimal(value) as string;
    }
    if (fieldSchema?.custom?.block_prefixes.includes('jahr')) {
        return value;
    }
    if (fieldSchema?.custom?.block_prefixes.includes('ganzzahl')) {
        return formatInteger(value) as string;
    }
    if (fieldSchema?.custom?.block_prefixes.includes('ja_nein')) {
        return formatBoolean(value);
    }

    switch (fieldSchema?.format) {
        case 'date':
            return formatDate(value) || NULL_VALUE;
    }

    switch (format) {
        case 'boolean':
            return formatBoolean(value) || NULL_VALUE;
        case 'date':
            return formatDate(value) || NULL_VALUE;
        case 'currency':
            return formatDezimal(value, unit) || NULL_VALUE;
        case 'integer':
            return formatInteger(value) || NULL_VALUE;
        case 'auswahl':
            return fieldSchema ? auswahlValueZuLabel(value, fieldSchema) : NULL_VALUE;
        default:
            return value;
    }
};

export const cleanUpData = (data: FormState | FormState[], schema: Schema): FormState | [] => {
    const { properties } = schema;
    if (!properties || !data) {
        return data;
    }
    let cleanedUpData = Object.keys(properties)
        .filter((k) => k !== 'prototype')
        .reduce(
            (newData, key) => {
                const elemSchema = properties[key] as Schema;
                if (key.startsWith('i_')) {
                    const uuid = key.substring(2);
                    // @ts-ignore
                    const index = data.findIndex((d) => d.uuid === uuid)!;
                    if (index < 0) {
                        return newData;
                    }
                    // @ts-ignore
                    return [...(newData as []), cleanUpData(data[index], elemSchema)].sort(
                        (a, b) =>
                            data.indexOf((d: FormState) => d === a || d.uuid === a.uuid) -
                            data.indexOf((d: FormState) => d === b || d.uuid === b.uuid)
                    );
                }
                return {
                    ...newData,
                    // @ts-ignore
                    [key]: elemSchema.properties ? cleanUpData(data[key], elemSchema) : data && data[key],
                };
            },
            schema.custom?.block_prefixes.includes('collection') ? [] : {}
        );
    if (Array.isArray(cleanedUpData) && Array.isArray(data)) {
        cleanedUpData = [...cleanedUpData, ...data.filter((elem) => !elem.uuid)];
    }
    return cleanedUpData;
};

export const removeSchemaProperty = (schema: Schema, name: string) => {
    if (!schema.properties) return schema;
    delete schema.properties[name];
    schema.required = schema.required?.filter((req) => req !== name) ?? [];
};
