import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';

export const uiSchema = verticalLayout([
    group(
        [
            control('field1709'),
            control('field1710'),
            control('field1710a'),
            control('field290'),
            control('field1711a'),
            control('field1711b'),
            control('field1712'),
            control('field1711'),
            control('field1713'),
        ],
        {},
        'Fiktive Mietvergleichsberechnung'
    ),
    group(
        [
            {
                type: 'FormExtendButton',
                label: 'Zuschuss hinzufügen',
                scope: '#/properties/zuschuesseFiktiveErstinbetriebnahme',
                uiSchema: {
                    type: 'CustomVerticalLayout',
                    elements: [
                        control('field1714', {
                            options: { firstControl: true },
                        }),
                        control('field1715'),
                    ],
                },
            },
        ],
        {},
        'Zuschüsse'
    ),
    group([control('field1714a'), control('field1716')]),
    group(
        [
            {
                type: 'FormExtendButton',
                label: 'Plazzahlreduzierung hinzufügen',
                scope: '#/properties/relevantePlatzzahlreduzierungenFiktiveErstinbetriebnahme',
                uiSchema: {
                    type: 'CustomVerticalLayout',
                    elements: [
                        control('field1717a', {
                            options: { firstControl: true },
                        }),
                        control('field1718a'),
                    ],
                },
            },
        ],
        {},
        'Relevante Platzzahlreduzierungen (ohne Dortmunder Modell)'
    ),
    group([control('field1719a'), control('field1720a'), control('field1721a')]),
    group(
        [
            {
                type: 'FormExtendButton',
                label: 'Plazzahlreduzierung hinzufügen',
                scope: '#/properties/zusaetzlichePlatzzahlreduzierungenFiktiveErstinbetriebnahme',
                uiSchema: {
                    type: 'CustomVerticalLayout',
                    elements: [
                        control('field1917a', {
                            options: { firstControl: true },
                        }),
                        control('field1918a'),
                        control('field1919a'),
                        control('field1920a'),
                    ],
                },
            },
        ],
        {},
        'Zusätzliche Platzzahlreduzierungen (ohne Dortmunder Modell)'
    ),
    group([control('field1921a'), control('field1922a')]),
    group([control('field1923a'), control('field1722')]),
]);
