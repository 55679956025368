import { Grid, styled, Typography } from '@mui/material';

import { GridLayoutConfig } from 'forms/types/UiSchemaTypes';

import { FormStateInfo } from './FormStateInfo';

interface PasswordStrengthListProps {
    label: string;
    requiredLength: number;
    validLength: boolean;
    hasNumber: boolean;
    upperCase: boolean;
    lowerCase: boolean;
    specialChar: boolean;
    gridLayout?: GridLayoutConfig;
}

export const StyledGrid = styled(Grid)(
    ({ theme }) => `
    margin-bottom: ${theme.spacing(2)};

  & h4 {
      margin: ${theme.spacing(1.5)}  0;
  }
`
);

export const PasswordStrengthList: React.FC<PasswordStrengthListProps> = ({
    label,
    requiredLength,
    validLength,
    hasNumber,
    upperCase,
    lowerCase,
    specialChar,
    gridLayout,
}) => {
    return (
        <StyledGrid container spacing={2}>
            <Grid item xs={gridLayout?.label || 2} />
            <Grid item xs={gridLayout?.input || 4}>
                <Typography variant={'h4'}>{label}</Typography>
                <FormStateInfo label={'Kleinbuchstaben'} state={lowerCase} />
                <FormStateInfo label={'Großbuchstaben'} state={upperCase} />
                <FormStateInfo label={'Zahlen'} state={hasNumber} />
                <FormStateInfo label={'Sonderzeichen'} state={specialChar} />
                <FormStateInfo label={`mindestens ${requiredLength} Zeichen`} state={validLength} />
            </Grid>
        </StyledGrid>
    );
};
