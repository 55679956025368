import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/AntragForm/ui-schemas/elements/customVerticalLayout';
import { description } from 'forms/AntragForm/ui-schemas/elements/description';
import { formExtendButton } from 'forms/AntragForm/ui-schemas/elements/formExtendButton';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { inlineRowControl } from 'forms/AntragForm/ui-schemas/elements/inlineRowControl';

export const uiSchema = customVerticalLayout([
    group([control('field110a'), control('field551'), control('field552'), control('field555'), control('field1133')]),
    group(
        [
            formExtendButton(
                customVerticalLayout([
                    control('field1196'),
                    inlineRowControl(
                        [control('field550', { controlOnly: true }), control('field550Art', { controlOnly: true })],
                        '#/properties/field550',
                        {}
                    ),
                    control('field553'),
                    inlineRowControl(
                        [
                            control('field554', {
                                controlOnly: true,
                                gridSize: 3,
                            }),
                            description('+/- Differenz zum Standard-Einzelzimmer'),
                        ],
                        '#/properties/field554',
                        {
                            gridLayout: {
                                fieldnumber: 1,
                                label: 3,
                                input: 6,
                            },
                        }
                    ),
                    control('field1155'),
                    control('field1156'),
                ]),
                '#/properties/zimmerkategorien',
                'Zimmerart hinzufügen'
            ),
        ],
        {},
        'Weitere Zimmer'
    ),
    group([control('field110b')]),
]);
