import { useCallback, useEffect, useRef, useState } from 'react';
import { Generate } from '@jsonforms/core';
import { isEqual } from 'lodash';

import { AntragType } from 'api/antragTypes';
import { useAuthHelper } from 'api/auth/useAuthHelper';
import { ROLES } from 'constants/roles';
import { Schema, UiSchemaType } from 'forms/types/UiSchemaTypes';
import { getFieldSchemaByScope } from 'forms/utils/SchemaUtils';

export const useStepUISchema = (schema: Schema | undefined, antragType: AntragType, step = 'basisdaten') => {
    const [uiSchema, setUiSchema] = useState<UiSchemaType | undefined>();
    const prevSchemaRef = useRef<Schema>();

    const { hasRole } = useAuthHelper();

    const loadUISchema = useCallback(
        (step: string) => {
            const roleDir = hasRole(ROLES.LV) ? 'lv' : 'anbieter';
            try {
                return require(`../ui-schemas/${antragType.toLowerCase()}/${roleDir}/${step}.ts`)?.uiSchema;
            } catch (e) {
                console.warn(e);
            }
            return undefined;
        },
        [hasRole, antragType]
    );

    useEffect(() => {
        if (!schema || typeof schema !== 'object' || !step) {
            setUiSchema(undefined);
            return;
        }
        if (isEqual(schema, prevSchemaRef.current)) {
            return;
        }
        prevSchemaRef.current = schema;
        const loadedUiSchema = loadUISchema(step);
        setUiSchema(
            loadedUiSchema ? prepareUISchema(loadedUiSchema, schema) : (Generate.uiSchema(schema!) as UiSchemaType)
        );
    }, [step, schema, loadUISchema]);

    return uiSchema;
};

export const prepareUISchema = (uiSchema: UiSchemaType, schema: Schema): UiSchemaType => {
    if (uiSchema.elements && Array.isArray(uiSchema.elements)) {
        return {
            ...uiSchema,
            elements: deleteMissingFields(uiSchema.elements, schema),
        };
    }
    return uiSchema;
};

const deleteMissingFields = (elements: UiSchemaType[], schema: Schema): any[] => {
    const ems = [];
    for (const element of elements) {
        const fieldSchema = getFieldSchemaByScope(element.scope, schema);
        switch (element.type) {
            case 'FormExtendButton':
            case 'ListControlWithModalForm':
                if (fieldSchema) {
                    if (Array.isArray(element.elements)) {
                        ems.push(prepareUISchema(element, fieldSchema));
                    } else {
                        ems.push(element);
                    }
                }
                break;
            default:
                if (fieldSchema) {
                    if (Array.isArray(element.elements)) {
                        ems.push(prepareUISchema(element, schema));
                    } else {
                        ems.push(element);
                    }
                }
        }
    }
    return ems;
};
