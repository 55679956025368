import React from 'react';
import { LayoutProps } from '@jsonforms/core';
import { withJsonFormsLayoutProps } from '@jsonforms/react';
import { Grid } from '@mui/material';

import { STATUS } from 'constants/antragStatus';
import { useFormTableData } from 'forms/AntragForm/hooks/useFormTableData';
import { useFormTableSchema } from 'forms/AntragForm/hooks/useFormTableSchema';
import { FormAddButton } from 'forms/controls/components/AddLink';
import { FormDialog } from 'forms/controls/components/FormDialog';
import { FormTable } from 'forms/controls/components/FormTable';
import { useDisabled } from 'forms/hooks/useDisabled';
import { useJsonFormsState } from 'forms/hooks/useJsonFormsState';
import { FormStateProvider } from 'forms/state/FormStateProvider';
import { useFormState } from 'forms/state/useFormState';
import { ListControlWithModalFormType, Schema } from 'forms/types/UiSchemaTypes';

import { useDependentFields } from './hooks/useDependentFields';
import { useFormAddButtons } from './hooks/useFormAddButtons';
import { useModalFormControlData } from './hooks/useModalFormControlData';

type ListeMitModalFormularControlProps = Omit<LayoutProps, 'uischema' | 'schema'> & {
    uischema: ListControlWithModalFormType;
    schema: Schema;
};

export const ListeMitModalFormularControlComponent: React.FC<ListeMitModalFormularControlProps> = ({
    uischema,
    schema,
    data,
    path,
}) => {
    const disabled = useDisabled();
    const { config } = useJsonFormsState();
    const tableData = useFormTableData(data, uischema, config.errors);
    const tableSchema = useFormTableSchema(schema, uischema.scopes);
    const dependentFields = useDependentFields(schema, uischema.scopes);
    const { antragStatus } = useFormState();

    const { dialogFormData, prepareModal, clearModal } = useModalFormControlData(config, uischema, schema);

    const onView = (index: number) => {
        onEdit(index, true);
    };

    const onEdit = (index: number, readonly = false) => prepareModal({ ...tableData[index], readonly, action: null });

    const onNew = (scope: string) => prepareModal({ scope, isNew: true, action: null });

    const onSave = () => {
        config.reloadData && config.reloadData(dependentFields);
        clearModal();
    };

    const onDelete = (index: number) => {
        config.deleteObjectTypeItem && config.deleteObjectTypeItem(tableData[index].uuid);
    };

    const onCustomAction = (index: number, action: string) => {
        prepareModal({ ...tableData[index], action });
    };

    const addButtons = useFormAddButtons(uischema.scopes, uischema.addButtonLabels, schema);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <FormTable
                    data={tableData}
                    path={path}
                    config={config}
                    schema={tableSchema}
                    uischema={uischema}
                    onView={onView}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    onCustomAction={onCustomAction}
                    deleteAlertText={(index) =>
                        uischema.deleteAlertTexts[uischema.scopes.indexOf(tableData[index].scope)] ||
                        uischema.deleteAlertTexts[0]
                    }
                    deleteConfirmationLabel={(index) =>
                        uischema.deleteConfirmationLabels[uischema.scopes.indexOf(tableData[index].scope)] ||
                        uischema.deleteConfirmationLabels[0]
                    }
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12}>
                {STATUS.IN_PRUEFUNG !== antragStatus
                    ? addButtons.map((addButton, i) => (
                          <FormAddButton
                              key={i}
                              data-cy={'FormAddButton'}
                              disabled={disabled}
                              label={addButton.label}
                              onClick={() => onNew(addButton.scope)}
                          />
                      ))
                    : null}
                <FormStateProvider initialValue={{ antragStatus }}>
                    <FormDialog dialogData={dialogFormData} onSave={onSave} onClose={clearModal} />
                </FormStateProvider>
            </Grid>
        </Grid>
    );
};

// @ts-ignore
export const ListeMitModalFormularControl = withJsonFormsLayoutProps(ListeMitModalFormularControlComponent);
