import { Button, styled } from '@mui/material';

export const FormButton = styled(Button)(
    ({ theme }) => `
    border-radius: 4px;

    .MuiSvgIcon-root {
        fill: #fff;
        margin-right: ${theme.spacing(1)}
    }
`
);
