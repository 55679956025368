import { ReadonlyValueText } from 'forms/controls/components/ReadonlyValueText';
import { formatDateToString } from 'forms/utils/formatter';

interface DatumProps {
    datum: Date;
}

const Datum = ({ datum }: DatumProps) => (
    <ReadonlyValueText path={'datum'} hasErrors={false} errors={[]} text={formatDateToString(datum)} />
);

export const BaseDatum = Datum;
