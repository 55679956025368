import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/AntragForm/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';

export const uiSchema = customVerticalLayout([
    group(
        [control('field1301'), control('field1302'), control('field5304'), control('field5305'), control('field5308')],
        {}
    ),
]);
