import { Schema } from 'forms/types/UiSchemaTypes';

export const benutzerBearbeitenSchema: Schema = {
    type: 'object',
    required: ['email', 'nachname', 'vorname', 'rolle', 'landschaftsverband', 'traegerverband', 'traeger'],
    properties: {
        email: {
            $id: 'email',
            type: 'string',
            title: 'E-Mail',
            // @ts-ignore
            custom: {
                disabled: true,
            },
        },
        nachname: { $id: 'nachname', type: 'string', title: 'Name' },
        vorname: { $id: 'vorname', type: 'string', title: 'Vorname' },
        rolle: {
            $id: 'rolle',
            type: 'string',
            title: 'Rolle',
        },
        landschaftsverband: {
            $id: 'landschaftsverband',
            type: 'object',
            title: 'Landschaftsverband',
            // @ts-ignore
            custom: {
                block_prefixes: ['landschaftsverband_autocomplete'],
            },
        },
        traegerverband: {
            $id: 'traegerverband',
            type: 'object',
            title: 'Trägerverband',
            // @ts-ignore
            custom: {
                block_prefixes: ['traegerverband_autocomplete'],
            },
        },
        traeger: {
            $id: 'traeger',
            type: 'object',
            title: 'Träger',
            // @ts-ignore
            custom: {
                block_prefixes: ['traeger_autocomplete'],
            },
        },
    },
};
