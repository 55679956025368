import { useState } from 'react';
import { LabelElement, OwnPropsOfRenderer, RankedTester, rankWith, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsLayoutProps } from '@jsonforms/react';
import { GetApp } from '@mui/icons-material';
import { Alert, Button, Grid, styled, Typography } from '@mui/material';

import { Dialog } from 'components/Dialog/Dialog';

export const PDFDownloadTester: RankedTester = rankWith(1, uiTypeIs('PDFDownloadControl'));

export const PDFDownloadControlComponent = ({ uischema }: OwnPropsOfRenderer) => {
    const labelElement: LabelElement = uischema as LabelElement;
    const [showInfo, setShowInfo] = useState(false);
    return (
        <Grid item xs={12} md={7}>
            <Wrapper>
                <Typography>
                    {labelElement.text !== undefined && labelElement.text !== null && labelElement.text}
                </Typography>
                <DownloadButton variant="outlined" onClick={() => setShowInfo(true)} startIcon={<DownloadButtonIcon />}>
                    PDF
                </DownloadButton>
            </Wrapper>
            <Dialog
                open={showInfo}
                onClose={() => setShowInfo(false)}
                title="Zusammenfassung"
                actions={
                    <Button color="primary" variant="contained" onClick={() => setShowInfo(false)}>
                        OK
                    </Button>
                }
            >
                <Alert severity="info">Der Download steht in Kürze zur Verfügung.</Alert>
            </Dialog>
        </Grid>
    );
};

export const PDFDownloadControl = withJsonFormsLayoutProps(PDFDownloadControlComponent);

const DownloadButton = styled(Button)(() => ({
    marginLeft: 32,
    borderRadius: 8,
    borderColor: '#006ECB',
    color: '#006ECB',
}));

const DownloadButtonIcon = styled(GetApp)(() => ({
    color: '#006ECB',
}));

const Wrapper = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
}));
