import { rankWith, Tester } from '@jsonforms/core';
import { JsonSchema } from '@jsonforms/core/src/models/jsonSchema';
import { UISchemaElement } from '@jsonforms/core/src/models/uischema';

import { getFieldSchemaByScope } from 'forms/utils/SchemaUtils';

export const isUpload: Tester = (uischema: UISchemaElement | any, schema: JsonSchema | any): boolean => {
    if (uischema.type !== 'Control') return false;

    const field = getFieldSchemaByScope(uischema.scope, schema);
    return (((field as any | undefined)?.custom?.block_prefixes || []) as Array<string>).includes('upload');
};

export const UploadTester = rankWith(10000, isUpload);
