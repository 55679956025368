export const virtuellesKonto_table = {
    type: 'SummaryTable',
    label: 'Virtuelles Konto',
    columns: 3,
    scope: '#/properties/virtuellesKonto',
    entries: [
        {
            type: 'FieldRow',
            scope: '#/properties/field1065',
        },
        {
            type: 'SubTable',
            label: 'Aufwände für das sonstige Anlagevermögen',
            level: 0,
            scope: '#/properties/',
            entries: [
                {
                    type: 'SubTable',
                    scope: '#/properties/aufwandSalg',
                    label: '{#/properties/jahr}',
                    level: 1,
                    entries: [
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field520',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field520d',
                        },
                    ],
                },
            ],
        },
        {
            type: 'SubTable',
            label: 'Instandhaltungsaufwendungen',
            level: 0,
            scope: '#/properties/',
            entries: [
                {
                    type: 'SubTable',
                    scope: '#/properties/aufwandInstandhaltung',
                    label: '{#/properties/jahr}',
                    level: 1,
                    entries: [
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field530',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field530d',
                        },
                    ],
                },
            ],
        },
        {
            type: 'NotesRow',
            scope: '#/properties/notizVirtuellesKonto',
        },
    ],
};
