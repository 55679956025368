import { useCallback, useState } from 'react';

import { ZustaendigkeitResponse } from 'api/types';
import {
    ZustaendigkeitenRegionTableAction,
    ZustaendigkeitenRegionTableActions,
} from 'constants/zustaendigkeitenRegionActions';

export const useZustaendigkeitenRegionListAction = () => {
    const [zustaendigkeit, setZustaendigkeit] = useState<ZustaendigkeitResponse | null>(null);

    const closeAction = () => {
        setZustaendigkeit(null);
    };

    const handleAction: ZustaendigkeitenRegionTableAction = useCallback(async (action, zustaendigkeit) => {
        const id = zustaendigkeit.id;
        if (!id) return;
        switch (action) {
            case ZustaendigkeitenRegionTableActions.BEARBEITEN:
                setZustaendigkeit(zustaendigkeit);
                break;
        }
    }, []);

    return { zustaendigkeit, closeAction, handleAction };
};
