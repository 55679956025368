import React, { ReactElement, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';

import { useApiAngemessenheitsgrenzeCollection } from 'api/hooks/useApiClient';
import { UpdateableFormTable } from 'components/FormModal/UpdateableFormTable';
import { ROLES } from 'constants/roles';
import { DatumString } from 'elements/DatumString';
import { DezimalzahlEuro as Betrag } from 'elements/DezimalzahlEuro';
import { FormState, Schema } from 'forms/types/UiSchemaTypes';
import { formatDateToString } from 'forms/utils/formatter';
import { ContentContainer } from 'layout/container/ContentContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { ExterneQuellenLocationState } from 'pages/ExterneQuellen/ExterneQuellenConfig';

const ApgDvoMitKuechePage: React.FC = (): ReactElement => {
    const typ = 'APG_DVO_MIT_KUECHE';
    const location = useLocation();
    const { state } = location as ExterneQuellenLocationState;

    const formDataInitial = useMemo(() => {
        return {
            jahr: new Date().getFullYear(),
            value: 0,
            erlassVom: formatDateToString(new Date(), 'yyyy-MM-dd'),
            typ,
        };
    }, []);

    const convertFormData = useCallback((formData: FormState) => {
        formData.value = '' + formData.value;
        return formData;
    }, []);

    const columns: MUIDataTableColumnDef[] = [
        {
            name: 'jahr',
            label: 'Jahr',
        },
        {
            name: 'value',
            label: 'Angemessenheitsgrenze je qm NRF',
            options: {
                customBodyRender: Betrag,
            },
        },
        {
            name: 'erlassVom',
            label: 'Erlass vom',
            options: {
                customBodyRender: DatumString,
            },
        },
    ];

    const options: MUIDataTableOptions = {
        sortOrder: {
            name: 'jahr',
            direction: 'desc',
        },
        filter: false,
    };

    const schema: Schema = {
        required: ['jahr', 'value', 'erlassVom'],
        properties: {
            jahr: {
                type: 'integer',
                title: 'Jahr',
                // @ts-ignore
                custom: {
                    block_prefixes: ['jahr'],
                },
            },
            value: {
                type: 'number',
                title: 'Angemessenheitsgrenze je qm NRF',
                // @ts-ignore
                custom: {
                    block_prefixes: ['betrag'],
                },
            },
            erlassVom: {
                type: 'string',
                format: 'date',
                title: 'Erlass vom',
            },
        },
    };

    const content = {
        headline: 'Angemessenheitsgrenze APG DVO mit Küche',
    };

    const table = {
        columns,
        options,
        callback: useApiAngemessenheitsgrenzeCollection,
        callbackType: typ,
    };

    const form = {
        title: 'Angemessenheitsgrenze APG DVO mit Küche erstellen',
        resetData: formDataInitial,
        converter: convertFormData,
        schema,
        endpoint: 'postAngemessenheitsgrenze',
        role: [ROLES.FACHADMIN, ROLES.MINISTERIUM],
        openModal: state ? state.openModal : undefined,
    };

    return (
        <ContentContainer title="Angemessenheitsgrenze APG DVO mit Küche">
            <UpdateableFormTable form={form} table={table} content={content} />
        </ContentContainer>
    );
};

export const AngemessenheitsgrenzeApgDvoMitKuechePage = withSideBar(ApgDvoMitKuechePage, []);
