import * as React from 'react';
import { ChangeEvent, useCallback } from 'react';
import { FormControl } from '@mui/material';

import { FileUploadResponse } from 'api/responses/FileUploadResponse';
import { FileUploadButton } from 'forms/controls/components/FileUploadButton';
import { ReadonlyValueText } from 'forms/controls/components/ReadonlyValueText';
import { UploadedDocument } from 'forms/controls/components/UploadedDocument';
import { useFileUpload } from 'forms/hooks/useFileUpload';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';
import { Schema } from 'forms/types/UiSchemaTypes';
import { UploadValue } from 'forms/types/UploadValueType';

import { RowControl } from './RowControl';

const MehrfachUploadControlComponent: React.FC<CustomControlProps<UploadValue[]>> = ({
    path,
    label,
    required,
    data,
    handleChange,
    hasErrors,
    errors,
    readonly,
    disabled,
    showFieldNumberLabels,
    gridLayout,
    uischema,
    config,
}) => {
    const onUpload = useCallback(
        (responses: FileUploadResponse[]) => {
            const newFiles: UploadValue[] = responses.map((r) => ({
                uploadId: r.id,
                originalName: r.originalName,
                contentUrl: r.contentUrl,
            }));
            handleChange(path, [...(data || []), ...newFiles], true);
        },
        [handleChange, path, data]
    );

    const onRemove = useCallback(
        (id: number) => {
            const newFiles = [...(data || []).filter((r) => r.uploadId !== id)];
            handleChange(path, newFiles, true);
        },
        [handleChange, path, data]
    );

    const { uploadErrors, uploadFiles, isUploading, removeFile } = useFileUpload(onUpload, onRemove);

    const handleValueChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            if (!e.target.files?.length) return;
            uploadFiles(e.target.files);
        },
        [uploadFiles]
    );

    return (
        <RowControl
            name={path}
            label={label}
            required={required}
            showFieldNumberLabels={(uischema as Schema).showFieldNumberLabels ?? showFieldNumberLabels}
            gridLayout={gridLayout}
            config={config}
            paths={[path]}
        >
            <FormControl fullWidth>
                {!readonly && (
                    <FileUploadButton
                        isUploadingFile={isUploading}
                        hasErrors={hasErrors}
                        uploadErrors={uploadErrors}
                        errors={errors}
                        disabled={disabled}
                        onChange={handleValueChange}
                        multiple
                    />
                )}
                {data
                    ?.filter((file) => file.originalName)
                    .map((file, i) => (
                        <UploadedDocument
                            key={i}
                            fileData={file}
                            onRemove={() => removeFile(file.uploadId)}
                            disabled={disabled}
                            readonly={readonly}
                        />
                    ))}
                {readonly && (!data || data.length === 0) && (
                    <ReadonlyValueText path={path} hasErrors={hasErrors} errors={errors} text={'-'} />
                )}
            </FormControl>
        </RowControl>
    );
};

export const MehrfachUploadControl = withCustomControlProps(MehrfachUploadControlComponent);
