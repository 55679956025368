import { GroupControlType } from 'forms/types/UiSchemaTypes';

export const notes = (field: string): GroupControlType => ({
    type: 'Group',
    label: 'Notizen',
    elements: [
        {
            type: 'Control',
            scope: `#/properties/${field}`,
            options: {
                noLabel: true,
                multiline: true,
            },
        },
    ],
});
