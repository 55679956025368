import { LALG_TYP } from 'constants/lalgTyp';
import { notes } from 'forms/AntragForm/ui-schemas/groups/notes';

export const uiSchema = {
    type: 'CustomVerticalLayout',
    elements: [
        {
            type: 'Group',
            elements: [
                {
                    type: 'Info',
                    message:
                        'Für den Fall, dass Sie einen Wechsel der Vergleichsberechnung beantragen möchten, müssen Sie einen neuen Feststellungsantrag stellen.<br /><br />Bitte beachten Sie, dass die konkrete Vergleichsberechnung gem. § 8 Abs. 2 Sätze 5 und 6 APG DVO verpflichtend ist, sofern<ul><li>sich die langfristigen Anlagegüter (z.B. Gebäude) zum 1. Februar 2014 in Ihrem Eigentum befanden und sie diese nach dem Stichtag verkauft und anschließend zurückgemietet haben.</li><li>oder wenn der Verkauf schon vor dem 1. Februar 2014 stattgefunden hat und die Anerkennung des Mietverhältnisses nicht schon bis zum 1. November 2014 geltend gemacht wurde.</li><li>oder wenn der ursprüngliche Träger das Eigentum behält, aber die Trägerschaft auf eine andere natürliche oder juristische Person übergegangen ist.</li></ul>',
                    rule: {
                        effect: 'SHOW',
                        condition: {
                            scope: '#/properties/field140',
                            schema: {
                                enum: [LALG_TYP.MIETE, LALG_TYP.MIETE_UND_EIGENTUM],
                            },
                        },
                    },
                },
                {
                    type: 'Control',
                    scope: '#/properties/field542',
                },
                {
                    type: 'Control',
                    scope: '#/properties/field500',
                },
                {
                    type: 'Control',
                    scope: '#/properties/field502',
                },
            ],
        },
        notes('notizBasisdaten'),
    ],
};
