import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, CardContent, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';

import { MassenmailResponse } from 'api/types';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { formatDateToString } from 'forms/utils/formatter';
import { InvestPathBuilder } from 'navigation/Paths';

interface DashboardMagsMassenmailsProps {
    massenmails: MassenmailResponse[] | undefined;
    isLoading: boolean;
}

export const DashboardMagsMailings = ({ massenmails, isLoading }: DashboardMagsMassenmailsProps): ReactElement => {
    const navigate = useNavigate();

    return (
        <>
            <CardContent>
                <Typography variant="h1" component="h3">
                    Mailings
                </Typography>
                <Typography>Die letzten 5 aus PfAD.invest versendeten Mailings:</Typography>
            </CardContent>
            <AppLoaderContainer isLoading={isLoading}>
                {massenmails && (
                    <CardContent>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Datum</TableCell>
                                    <TableCell>Betreff</TableCell>
                                    <TableCell>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {massenmails.map((massenmail) => (
                                    <TableRow key={massenmail.id}>
                                        <TableCell>{formatDateToString(massenmail.createdAt)}</TableCell>
                                        <TableCell>{massenmail.betreff}</TableCell>
                                        <TableCell>
                                            {`${massenmail.countEmpfaenger} von ${massenmail.countEmpfaengerGesendet} gesendet`}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </CardContent>
                )}

                {(!massenmails || !massenmails.length) && (
                    <CardContent>
                        <Typography align={'center'}>Keine Mailings gefunden</Typography>
                    </CardContent>
                )}

                <CardContent
                    style={{
                        textAlign: 'right',
                    }}
                >
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={() => navigate(InvestPathBuilder.home.verwaltung.massenmail.path)}
                    >
                        Alle Mailings anzeigen
                    </Button>
                </CardContent>
            </AppLoaderContainer>
        </>
    );
};
