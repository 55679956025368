import { useState } from 'react';

import { PHONE_NUMBER_REGEX } from 'forms/hooks/useFormValidation';
import { FormConfig } from 'forms/types/UiSchemaTypes';
import { buildPath } from 'forms/utils/SchemaUtils';

export interface useErrorsProps {
    config?: Pick<FormConfig, 'path' | 'errors'>;
    path?: string;
    jsonFormError?: string | ComponentError[];
    immediately?: boolean;
}

export const useErrors = ({ config, path, jsonFormError, immediately }: useErrorsProps) => {
    const [touched, setTouched] = useState(false);
    if (!touched && !immediately) {
        return { hasErrors: false, errors: [], setTouched };
    }
    const componentPath = buildPath(config?.path, path);
    if (!componentPath) {
        return {
            hasErrors: Boolean(config?.errors && Object.keys(config.errors).length > 0),
            errors:
                (config?.errors &&
                    Object.keys(config.errors)
                        .flatMap((k) => config.errors?.[k] ?? [])
                        .map((e) => ({
                            message: e,
                            path: componentPath,
                        }))) ||
                [],
            setTouched,
        };
    }

    const jsonFormComponentError = componentErrorFromJsonFormsError(jsonFormError, componentPath);
    const errors: ComponentError[] =
        config?.errors?.[componentPath]?.map((e) => ({
            message: e,
            path: componentPath,
        })) || [];

    jsonFormComponentError.forEach((error) => errors.push(error));

    return { hasErrors: errors.length > 0, errors, setTouched };
};

export interface ComponentError {
    message: string;
    path?: string;
}

export const REQUIRED_ERROR_MESSAGE = 'Eingabe erforderlich';

export const errorTextTranslations: Record<string, string> = {
    'is a required property': REQUIRED_ERROR_MESSAGE,
    'should match format "date"': 'Invalides Datum-Format',
    'should be integer': 'Eingabe ist keine ganze Zahl',
    'should NOT be shorter than 3 characters': 'Bitte geben Sie mindestens 3 Zeichen ein',
    'should NOT have fewer than 1 items': 'Mindestens ein Eintrag erforderlich.',
    'must NOT have fewer than 1 items': 'Mindestens ein Eintrag erforderlich.',
    [`must match pattern "${PHONE_NUMBER_REGEX}"`]: 'Muss eine valide Telefon- oder Faxnummer sein.',
    'should be object': '',
    'should be a number': '',
    'should be boolean': '',
};

const componentErrorFromJsonFormsError = (
    errorText: string | ComponentError[] | undefined,
    path: string
): ComponentError[] => {
    if (!errorText) {
        return [];
    }

    if (typeof errorText === 'string') {
        const message = errorTextTranslations[errorText];
        return message ? [{ message, path }] : [];
    }

    return errorText;
};
