import { GridSize } from '@mui/material';

import { GridLayoutConfig, InlineGroupRowActionOptions, InlineGroupRowControlType } from 'forms/types/UiSchemaTypes';

export const inlineGroupRowControl = (
    collectionScope: string,
    rowScope: string,
    { gridLayout, gridSize, action }: InlineGroupRowControlOptions = {}
): InlineGroupRowControlType => ({
    type: 'InlineGroupRowControl',
    collectionScope: `#/properties/${collectionScope}`,
    rowScope: `#/properties/${rowScope}`,
    gridLayout,
    gridSize,
    action,
});

type InlineGroupRowControlOptions = {
    gridLayout?: GridLayoutConfig;
    gridSize?: GridSize;
    action?: InlineGroupRowActionOptions;
};
