import { useEffect, useState } from 'react';

import { EMAIL_REGEX } from 'forms/hooks/useFormValidation';

export const useEmailValidation = (
    email = '',
    confirmEmail = ''
): [valid: boolean, match: boolean, hasValidationError: boolean, hasMatchingError: boolean] => {
    const [valid, setValid] = useState(false);
    const [match, setMatch] = useState(false);
    const [validationError, setValidationError] = useState(false);
    const [matchingError, setVMatchingError] = useState(false);

    useEffect(() => {
        if (email) {
            setValid(RegExp(EMAIL_REGEX).test(email));
        }

        if (email && confirmEmail) {
            setMatch(Boolean(email && email === confirmEmail));
        }
    }, [email, confirmEmail]);

    useEffect(() => {
        if (email && !valid) {
            setValidationError(true);
        } else {
            setValidationError(false);
        }

        if (confirmEmail && !match) {
            setVMatchingError(true);
        } else {
            setVMatchingError(false);
        }
    }, [valid, match, email, confirmEmail]);

    return [valid, match, validationError, matchingError];
};
