import { AxiosError } from 'axios';

import { useApiEinrichtungItem } from 'api/hooks/useApiClient';

export type AnnerkannteFinanzierungFremdkapital = {
    name: string | null;
    betrag: string | null;
    massnahme: string | null;
};

export type AnnerkannteFinanzierungEigenkapital = {
    faelligkeit: string | null;
    betrag: string | null;
    massnahme: string | null;
};

type UseAnerkannteFinanzierungDataResult = {
    data: {
        fremdkapital: AnnerkannteFinanzierungFremdkapital[];
        eigenkapital: AnnerkannteFinanzierungEigenkapital[];
    };
    error: AxiosError | undefined;
    isLoading?: boolean;
};

export const useAnerkannteFinanzierungData = (id: string): UseAnerkannteFinanzierungDataResult => {
    const { data, error, isLoading } = useApiEinrichtungItem(id);

    const festsetzungsantrag = data?.letzterBeschiedenerFestsetzungsantrag;

    const fremdkapital = (festsetzungsantrag?.data?.fremdkapital ?? []).flatMap((d) =>
        (d.fremdkapitalAnteile ?? []).map((anteil) => {
            const field251 = anteil.field251;
            const uuidMap = festsetzungsantrag?.uuidMap as Record<string, string> | undefined;
            const title = field251 ? uuidMap?.[field251] : null;
            return {
                name: d.field270 ?? null,
                betrag: d.field1455 ?? null,
                massnahme: title ?? 'Virtuelles Konto',
            };
        })
    );

    const eigenkapital = (festsetzungsantrag?.data?.eigenkapital || []).map((eigenkapital) => {
        const field251 = eigenkapital.field251;
        const uuidMap = festsetzungsantrag?.uuidMap as Record<string, string> | undefined;
        const title = field251 ? uuidMap?.[field251] : null;
        return {
            betrag: eigenkapital.field1415 ?? null,
            faelligkeit: eigenkapital.field5458 ?? null,
            massnahme: title ?? 'Virtuelles Konto',
        };
    });

    return {
        data: {
            fremdkapital,
            eigenkapital,
        },
        error,
        isLoading,
    };
};
