import React from 'react';

import { InvestPathBuilder } from 'navigation/Paths';

import { Link } from './Link';

interface EinrichtungLinkProps {
    id: number;
    name: string;
}

export const LinkEinrichtung: React.FC<EinrichtungLinkProps> = ({ id, name }) => {
    return <Link to={InvestPathBuilder.home.verwaltung.pflegeeinrichtungen.einrichtung(id!)}>{name}</Link>;
};
