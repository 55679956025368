import { useState } from 'react';
import { AxiosError } from 'axios';
import { isEqual } from 'lodash';

import { backendApiService } from 'api/ApiService';
import { BenutzerByEmailResponse, EinrichtungResponse } from 'api/types';
import { ROLLEN_ANBIETER } from 'constants/roles';
import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { ComponentError } from 'forms/hooks/useErrors';
import { useFormValidation } from 'forms/hooks/useFormValidation';
import { useLayoutWithJsonFormsState } from 'forms/hooks/useJsonFormsState';
import { useMessageActions } from 'forms/state/useMessages';
import { FormConfig, FormLayoutConfig, FormStateChange, Schema, UiSchemaType } from 'forms/types/UiSchemaTypes';
import { errorMessage } from 'forms/utils/MessageUtils';

type CreateBenutzerEmailStepFormData = {
    email: string;
};

export type CreateBenutzerFormStepEmailOnComplete = (benutzer: BenutzerByEmailResponse | null, email: string) => void;

type UseCreateBenutzerEmailStepFormResult = {
    error: ComponentError | null;
    onSubmit: () => Promise<void>;
    isValid: boolean;
    isSubmitting: boolean;

    data: CreateBenutzerEmailStepFormData;
    schema: Schema;
    uischema: UiSchemaType;
    config: FormConfig;
    onChange: (state: FormStateChange) => void;
};

export const useCreateBenutzerEmailStepForm = (
    einrichtung: EinrichtungResponse | null,
    onComplete: CreateBenutzerFormStepEmailOnComplete
): UseCreateBenutzerEmailStepFormResult => {
    const { addMessage } = useMessageActions();
    const config = useLayoutWithJsonFormsState(configLayout);
    const [isSubmitting, setSubmitting] = useState(false);
    const [error, setError] = useState<ComponentError | null>(null);
    const [data, setData] = useState<CreateBenutzerEmailStepFormData>({ email: '' });
    const { valid: isValid } = useFormValidation(schema, data, config);

    const onSubmit = async () => {
        if (isSubmitting || !data.email) return;

        setError(null);
        setSubmitting(true);

        try {
            const benutzer = await backendApiService.getBenutzerByEmail(data.email);
            if (!einrichtung) {
                return setError({
                    path: 'email',
                    message:
                        'Dieser Benutzer konnte nicht angelegt werden. Die E-Mail-Adresse ist bereits im System vorhanden. Geben Sie eine andere Adresse an.',
                });
            }
            if (einrichtung && !ROLLEN_ANBIETER.includes(benutzer.rolle ?? '')) {
                return setError({
                    path: 'email',
                    message:
                        'Dieser Benutzer kann nicht zur Einrichtung hinzugefügt werden. Bitte wenden Sie sich an den Support.',
                });
            }
            if (einrichtung && benutzer.einrichtungIds?.includes(String(einrichtung.id))) {
                return setError({
                    path: 'email',
                    message:
                        'Dieser Benutzer ist der Einrichtung bereits zugewiesen. Es wurden keine Änderungen vorgenommen.',
                });
            }
            onComplete(benutzer, data.email);
        } catch (e) {
            const error = e as AxiosError;
            switch (error.response?.status) {
                case 404:
                    onComplete(null, data.email);
                    break;
                case 422:
                    setError({ path: 'email', message: error.response.data.violations[0].message });
                    break;
                default:
                    addMessage(createErrorMessage());
            }
        } finally {
            setSubmitting(false);
        }
    };

    const onChange = ({ data: formData }: FormStateChange) => {
        if (isEqual(data, formData)) return;
        setData(formData);
    };

    return {
        data,
        schema,
        uischema,
        config,
        error,
        onSubmit,
        onChange,
        isSubmitting,
        isValid,
    };
};

const configLayout: FormLayoutConfig = {
    gridLayout: {
        label: 3,
        input: 6,
    },
};

const schema: Schema = {
    type: 'object',
    required: ['email'],
    properties: {
        email: {
            $id: 'email',
            type: 'string',
            title: 'E-Mail',
            // @ts-ignore
            custom: {
                block_prefixes: ['email'],
            },
        },
    },
};

const uischema: UiSchemaType = control('email', { options: { submitOnChange: true, focus: true } });

const createErrorMessage = () =>
    errorMessage('Es ist ein Fehler aufgetreten.', {
        autoCloseSeconds: 8,
        closable: true,
    });
