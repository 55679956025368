import { ReactElement } from 'react';

import { DataTableHydraServerside } from 'components/DataTableHydra/DataTableHydraServerside';
import { useTraegerListColumns } from 'pages/Verwaltung/Traeger/useTraegerListColumns';
import { useTraegerListData } from 'pages/Verwaltung/Traeger/useTraegerListData';

export const TraegerList = (): ReactElement => {
    const getData = useTraegerListData();
    const getColumns = useTraegerListColumns();
    return (
        <DataTableHydraServerside
            getColumns={getColumns}
            getData={getData}
            initialOptions={{
                viewColumns: false,
                sortOrder: {
                    name: 'nrwKey',
                    direction: 'desc',
                },
            }}
        />
    );
};
