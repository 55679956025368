import React from 'react';
import { TableCell } from '@mui/material';

import { TableCellValue } from 'forms/controls/SummaryTable/FieldRow';
import { useDataFromScopes } from 'forms/hooks/useDataFromScopes';
import { FormConfig, Schema, TableColumn } from 'forms/types/UiSchemaTypes';
import { isNumeric } from 'forms/utils/SchemaUtils';

interface FormTableCellProps {
    column: TableColumn;
    schema: Schema;
    rowData: any;
    config: FormConfig;
    path: string;
}

export const FormTableCell: React.FC<FormTableCellProps> = ({ column, schema, rowData, config, path }) => {
    const { value, colSchema } = useDataFromScopes(schema, rowData, column.scopes ? column.scopes : [column.scope!]);
    return (
        <TableCell style={column.styles}>
            <TableCellValue
                data={value}
                path={path}
                schema={colSchema! as Schema}
                column={column}
                config={config}
                textAlign={column.styles?.textAlign || (isNumeric(colSchema! as Schema) ? 'right' : 'left')}
            />
        </TableCell>
    );
};
