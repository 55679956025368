import React, { ReactElement, ReactNode } from 'react';

import { EinrichtungListResponse } from 'api/types';
import { Link } from 'elements/Link';
import { InvestPathBuilder, PflegeeinrichtungTargets } from 'navigation/Paths';

type PflegeeinrichtungListLinkProps = {
    einrichtungId: number;
    target: PflegeeinrichtungTargets;
    children: ReactNode;
};

export const PflegeeinrichtungListLink = ({
    einrichtungId,
    target,
    children,
}: PflegeeinrichtungListLinkProps): ReactElement => {
    const to = InvestPathBuilder.home.verwaltung.pflegeeinrichtungen.target(einrichtungId, target);
    return <Link to={to}>{children}</Link>;
};

export const einrichtungListLinkRender = (
    data: EinrichtungListResponse,
    index: number,
    target: PflegeeinrichtungTargets
): ReactNode => {
    const row = data['hydra:member'][index];
    const id = row.id;
    const fieldTarget = target === 'benutzer' ? 'einrichtungBenutzer' : target;
    const targetValue = row[fieldTarget];
    if (!id || !targetValue) return <>0</>;
    return targetValue.length > 0 ? (
        <PflegeeinrichtungListLink einrichtungId={id} target={target}>
            {targetValue.length}
        </PflegeeinrichtungListLink>
    ) : (
        <>0</>
    );
};
