import { useCallback } from 'react';

export enum KEY {
    SPACE = 'SPACE',
    ENTER = 'ENTER',
    TAB = 'TAB',
    ESCAPE = 'ESCAPE',
}

export const useKeyDownCallback = (callback: (key: KEY, e: any) => void, executeOnKeys?: KEY[]) => {
    const onKeyDown = useCallback(
        (e: { code: string }) => {
            if (!e.code) {
                return;
            }
            // @ts-ignore
            const key = KEY[e.code.toUpperCase()] || e.code.toUpperCase();
            if (executeOnKeys) {
                executeOnKeys.includes(key) && callback(key, e);
            } else {
                callback(key, e);
            }
        },
        [callback, executeOnKeys]
    );

    return onKeyDown;
};
