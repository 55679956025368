import { useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { AntragResponse, AntragVersionCollectionListResponse } from 'api/types';
import { GetColumns } from 'components/DataTableHydra/hooks/useTableColumns';
import { AntragVersionTableAction } from 'constants/antragVersionTableActions';
import { ANTRAG_VERSIONEN_TYP_LABELS } from 'constants/labels';
import { formatDateToString } from 'forms/utils/formatter';
import { AntragDetailsVersionListActions } from 'pages/Antraege/components/AntragDetailsVersionListActions';
import { rightAlignedColumn } from 'utilities/DataTableHydraUtils';

export const useAntragVersionListColumns = (
    handleAction: AntragVersionTableAction,
    antrag: AntragResponse
): GetColumns<AntragVersionCollectionListResponse> => {
    return useCallback(
        (data) => {
            if (!data) return [];

            const typ: MUIDataTableColumnDef = {
                name: 'typ',
                label: 'Typ',
                options: {
                    sort: false,
                    customBodyRender: (value: string): string => {
                        return ANTRAG_VERSIONEN_TYP_LABELS[value];
                    },
                },
            };

            const antragModifiedAt: MUIDataTableColumnDef = {
                name: 'antragModifiedAt',
                label: 'Datum',
                options: {
                    customBodyRender: (value: string): string => {
                        return value ? `${formatDateToString(value, 'dd.MM.yyyy - HH:mm')} Uhr` : '-';
                    },
                },
            };

            const actions: MUIDataTableColumnDef = {
                name: 'actions',
                label: 'Aktion',
                options: {
                    sort: false,
                    ...rightAlignedColumn,
                    customBodyRenderLite: (index: number) => (
                        <AntragDetailsVersionListActions
                            antrag={antrag}
                            handleAction={handleAction}
                            antragVersion={data['hydra:member'][index]}
                        />
                    ),
                },
            };

            const columns: MUIDataTableColumnDef[] = [typ, antragModifiedAt, actions];

            return columns;
        },
        [antrag, handleAction]
    );
};
