import React, { ReactElement } from 'react';
import { styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { AntragResponse, AntragVersionResponse } from 'api/types';
import { NewLineText } from 'elements/NewLineText';
import { UploadedDocument } from 'forms/controls/components/UploadedDocument';
import { useDocumentTableData } from 'forms/controls/DocumentTable/hooks/useDocumentTableData';
import { DocumentTableType } from 'forms/types/UiSchemaTypes';

interface AntragDetailsVersionDokumenteProps {
    antrag: AntragResponse;
    version: AntragVersionResponse | null;
}

export const AntragDetailsVersionDokumente = ({
    antrag,
    version,
}: AntragDetailsVersionDokumenteProps): ReactElement => {
    const data = version ? version.data : antrag.data;
    const tableData = useDocumentTableData(data, documentTableUiSchema);

    return (
        <StyledTableContainer>
            <Table data-cy="Table" stickyHeader>
                <TableHead>
                    <TableRow>
                        {documentTableUiSchema.columns.map((column, i) => (
                            <TableCell key={i} style={column.styles}>
                                {column.header}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData.length === 0 && (
                        <TableRow>
                            <TableCell colSpan={4}>Keine Dokumente vorhanden.</TableCell>
                        </TableRow>
                    )}
                    {tableData.map((row, i) => {
                        return (
                            <TableRow key={i}>
                                {documentTableUiSchema.columns.map((column) => (
                                    <TableCell key={column.key} style={column?.styles}>
                                        {column.key === 'fileData' ? (
                                            row.fileData.map((file, i) => (
                                                <UploadedDocument
                                                    key={i}
                                                    fileData={file}
                                                    readonly
                                                    style={{
                                                        ...column?.styles,
                                                        display: 'block',
                                                    }}
                                                />
                                            ))
                                        ) : (
                                            <NewLineText text={row[column.key]} />
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </StyledTableContainer>
    );
};

const StyledTableContainer = styled(TableContainer)(() => ({
    maxHeight: 402,
}));

const documentTableUiSchema: DocumentTableType = {
    type: 'DocumentTableControl',
    gridWidth: 8,
    styles: {
        marginBottom: 32,
    },
    columns: [
        {
            header: 'Schritt',
            key: 'step',
            styles: {
                textAlign: 'left',
                verticalAlign: 'top',
            },
        },
        {
            header: 'Bezeichnung',
            key: 'description',
            styles: {
                textAlign: 'left',
                verticalAlign: 'top',
            },
        },
        {
            header: 'Datei',
            key: 'fileData',
            styles: {
                textAlign: 'left',
                verticalAlign: 'top',
            },
        },
        {
            header: 'Hochzuladendes Dokument',
            key: 'fileDescription',
            styles: {
                textAlign: 'right',
                verticalAlign: 'top',
            },
        },
    ],
    entries: [
        {
            scope: '#/properties/',
            uploads: [
                {
                    step: 'Basisdaten',
                    description: 'Basisdaten',
                    file: '#/properties/field103d',
                    fileDescription: 'Versorgungsvertrag',
                },
                {
                    step: 'NRF & Grundstück',
                    description: 'Nettoraumfläche und Grundstück',
                    file: '#/properties/field123d',
                    fileDescription: 'Erbpachtvertrag',
                },
                {
                    step: 'NRF & Grundstück',
                    description: 'Zuordnung des Anlagevermögens',
                    file: '#/properties/field161d',
                    fileDescription: 'Mietvertrag',
                },
                {
                    step: 'Ausnahmen',
                    description: 'Ausnahmegenehmigungen',
                    file: '#/properties/field141d',
                    fileDescription: 'Bescheinigung nach § 9 Abs. 2 PfG NW bzw. § 11 Abs. 3 APG NRW',
                },
            ],
        },
        {
            scope: '#/properties/ausnahmegenehmigungenP10Abs3Satz2Ziffer2Apg',
            uploads: [
                {
                    step: 'Ausnahmen',
                    description: '{#/properties/field234}',
                    file: '#/properties/field233d',
                    fileDescription: 'Ausnahmegenehmigung',
                },
            ],
        },
        {
            scope: '#/properties/massnahmen',
            uploads: [
                {
                    step: 'Maßnahmen',
                    description: '{#/properties/field201}',
                    file: '#/properties/field220d',
                    fileDescription: 'Testat für tatsächlicher Aufwand LALG',
                },
                {
                    step: 'Maßnahmen',
                    description: '{#/properties/field201}',
                    file: '#/properties/field232d',
                    fileDescription: 'Abstimmungsbescheinigung nach § 10 Abs. 3 APG DVO',
                },
                {
                    scope: '#/properties/zuschuesse',
                    description: '{#/properties/field201}',
                    uploads: [
                        {
                            step: 'Maßnahmen',
                            description: 'Zuschuss von {#/properties/field240}',
                            file: '#/properties/field242d',
                            fileDescription: 'Zuwendungsbescheid',
                        },
                    ],
                },
            ],
        },
        {
            scope: '#/properties/salgImEigentum',
            uploads: [
                {
                    step: 'SALG',
                    description: '{#/properties/field300}',
                    file: '#/properties/field302d',
                    fileDescription: 'Testat Anschaffungskosten',
                },
                {
                    scope: '#/properties/zuschuesse',
                    description: '{#/properties/field300}',
                    uploads: [
                        {
                            step: 'SALG',
                            description: 'Zuschuss von {#/properties/field240}',
                            file: '#/properties/field242d',
                            fileDescription: 'Zuwendungsbescheid ',
                        },
                    ],
                },
            ],
        },
        {
            scope: '#/properties/salgImLeasing',
            uploads: [
                {
                    step: 'SALG',
                    description: '{#/properties/field300}\n{#/properties/field301}',
                    file: '#/properties/field303d',
                    fileDescription: 'Testat marktüblicher Kaufpreis',
                },
                {
                    step: 'SALG',
                    description: '{#/properties/field300}\n{#/properties/field301}',
                    file: '#/properties/field304d',
                    fileDescription: 'Leasingvertrag',
                },
            ],
        },
        {
            scope: '#/properties/',
            uploads: [
                {
                    step: 'Dokumente',
                    description: '-',
                    file: '#/properties/dokumente',
                    fileDescription: 'Weitere Dokumente',
                },
                {
                    step: 'Antrag absenden',
                    description: '-',
                    file: '#/properties/field410d',
                    fileDescription: 'Vollmacht',
                },
            ],
        },
    ],
};
