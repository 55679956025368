import { GroupControlType } from 'forms/types/UiSchemaTypes';

export const gepooltePlaetze = {
    type: 'Group',
    label: 'Gepoolte Plätze',
    scope: '#/properties/gepooltePlaetze',
    elements: [
        {
            type: 'FormExtendButton',
            label: 'Weitere Pflegeeinrichtung hinzufügen',
            scope: '#/properties/gepooltePlaetze',
            uiSchema: {
                type: 'CustomVerticalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/field237',
                        options: {
                            firstControl: true,
                        },
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/field238',
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/field239',
                    },
                ],
            },
        },
    ],
} as GroupControlType;
