import React, { ReactElement } from 'react';
import { Button, styled } from '@mui/material';

import { transientOptions } from 'theme/utils';

import { DataTableController } from './DataTableHydra';
import { FilterButtonProps } from './hooks/useTableFilters';

type DataTableHydraFilterButtonsProps = FilterButtonProps & {
    tableRef?: React.RefObject<DataTableController | null | undefined>;
};

export const DataTableHydraFilterButtons = ({
    saveFilters,
    deleteFilters,
    isFilterDirty,
    isFilterSaved,
    tableRef,
}: DataTableHydraFilterButtonsProps): ReactElement => {
    const onSave = () => saveFilters();

    const onDelete = async () => {
        tableRef?.current?.resetFilters?.();
        await deleteFilters();
    };

    return (
        <>
            {isFilterDirty && (
                <Button variant="outlined" color="primary" onClick={onSave}>
                    {isFilterSaved ? 'Filter aktualisieren' : 'Filter speichern'}
                </Button>
            )}

            {isFilterSaved && (
                <DeleteButton onClick={onDelete} $hasNeighbor={isFilterDirty}>
                    Filter löschen
                </DeleteButton>
            )}
        </>
    );
};

const DeleteButton = styled(
    Button,
    transientOptions
)<{ $hasNeighbor: boolean }>(({ theme, $hasNeighbor }) => ({
    marginLeft: $hasNeighbor ? theme.spacing(1) : 0,
}));
