import { collectionGroups } from 'forms/AntragForm/ui-schemas/elements/collectionGroups';
import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';

export const uiSchema = verticalLayout([
    group([
        control('field1736'),
        collectionGroups('platzzahlreduzierungenFiktivesSalg', [control('field1737'), control('field1738')]),
        control('field1739'),
        control('field1740'),
        control('field1741'),
        control('field1742'),
        control('field1743'),
        control('field1744'),
    ]),
]);
