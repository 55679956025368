import { useEffect, useState } from 'react';

export const usePasswordValidation = (
    password = '',
    confirmPassword = '',
    requiredLength = 8
): [
    validLength: boolean,
    hasNumber: boolean,
    upperCase: boolean,
    lowerCase: boolean,
    specialChar: boolean,
    match: boolean,
    valid: boolean
] => {
    const [validLength, setValidLength] = useState(false);
    const [hasNumber, setHasNumber] = useState(false);
    const [upperCase, setUpperCase] = useState(false);
    const [lowerCase, setLowerCase] = useState(false);
    const [specialChar, setSpecialChar] = useState(false);
    const [match, setMatch] = useState(false);
    const [valid, setValid] = useState(false);

    useEffect(() => {
        setValidLength(password.length >= requiredLength);

        setHasNumber(/\d/.test(password));

        setLowerCase(password.toUpperCase() !== password);

        setUpperCase(password.toLowerCase() !== password);

        setSpecialChar(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(password));

        setMatch(Boolean(password && password === confirmPassword));
    }, [password, confirmPassword, requiredLength]);

    useEffect(() => {
        setValid(Boolean(validLength && hasNumber && upperCase && lowerCase && specialChar && match));
    }, [validLength, hasNumber, upperCase, lowerCase, specialChar, match]);

    return [validLength, hasNumber, upperCase, lowerCase, specialChar, match, valid];
};
