import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/AntragForm/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { Schema } from 'forms/types/UiSchemaTypes';

export const uiSchema = customVerticalLayout([
    group(
        [
            group([control('titel'), control('nachname'), control('vorname')], {
                noSeparator: true,
            }),
            control('telefon'),
            control('fax'),
        ],
        {},
        'Benutzerdaten'
    ),
    group([control('email'), control('password')], {}, 'Login'),
]);

export const schema: Schema = {
    required: ['titel', 'nachname', 'vorname', 'password', 'telefon', 'fax'],
    properties: {
        titel: {
            type: 'string',
        },
        nachname: {
            type: 'string',
        },
        vorname: {
            type: 'string',
        },
        email: {
            type: 'string',
            // @ts-ignore
            custom: {
                disabled: true,
            },
        },
        password: {
            type: 'string',
            // @ts-ignore
            custom: {
                block_prefixes: ['password_widget'],
            },
        },
        telefon: {
            type: 'string',
        },
        fax: {
            type: 'string',
        },
    },
};
