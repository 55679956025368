import React, { SyntheticEvent } from 'react';
import { TabList } from '@mui/lab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';

export interface TabConfig {
    label: string;
    value: string;
    component: React.ReactElement;
}

interface TabNavigationProps {
    tabs: TabConfig[];
    defaultTabIndex?: number;
    fullContentWidth?: boolean;
}

export const TabNavigation: React.FC<TabNavigationProps> = ({ tabs, defaultTabIndex = 0, fullContentWidth }) => {
    const style = fullContentWidth
        ? {
              paddingLeft: '0',
              paddingRight: '0',
          }
        : {};

    const [currentTab, setCurrentTab] = React.useState(tabs[defaultTabIndex].value);

    const handleChangeTab = (_: SyntheticEvent, newTab: React.SetStateAction<string>) => {
        setCurrentTab(newTab);
    };

    return (
        <TabContext value={currentTab}>
            <TabList onChange={handleChangeTab}>
                {tabs.map((tab) => (
                    <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
            </TabList>
            {tabs.map((tab) => (
                <TabPanel key={tab.value} value={tab.value} style={style}>
                    {tab.component}
                </TabPanel>
            ))}
        </TabContext>
    );
};
