import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { TraegerListResponse } from 'api/types';
import { GetData } from 'components/DataTableHydra/hooks/useTableData';
import { sortBy } from 'components/DataTableHydra/tableUtils';

export const useTraegerListData = (): GetData<TraegerListResponse> => {
    return useCallback(({ page, rowsPerPage, searchText, sortOrder, filters }) => {
        return backendApiService.getTraegerCollection({
            page,
            itemsPerPage: rowsPerPage,
            qSearch: searchText ? [searchText] : undefined,
            art2: filters ? filters['art'] || undefined : undefined,
            orderByNrwKey: sortBy(sortOrder, 'nrwKey'),
            orderByName: sortBy(sortOrder, 'name'),
            orderByArt: sortBy(sortOrder, 'art'),
            orderByTraegerverbandName: sortBy(sortOrder, 'traegerverband'),
        });
    }, []);
};
