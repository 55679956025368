export const uiSchema = {
    type: 'CustomVerticalLayout',
    spacing: 6,
    elements: [
        {
            type: 'SummaryTable',
            label: 'Basisdaten',
            columns: 3,
            scope: '#/properties/basisdaten',
            entries: [
                {
                    type: 'FieldRow',
                    scope: '#/properties/field1052',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field500',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field450',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field451',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field452',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field457',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field457d',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field458',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field459',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field459d',
                },
                {
                    type: 'NotesRow',
                    scope: '#/properties/notizBasisdaten',
                },
            ],
        },
        {
            type: 'SummaryTable',
            label: 'Darlehen',
            columns: 3,
            scope: '#/properties/darlehen',
            entries: [
                {
                    type: 'SubTable',
                    label: '{#/properties/field453a}',
                    scope: '#/properties/darlehen',
                    entries: [
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field453a',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field453',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field453da',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field453db',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field454',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field453dc',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field455',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field456',
                        },
                    ],
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field5606',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field5607',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field5608',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field5609',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field5610',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field5631',
                },
                {
                    type: 'NotesRow',
                    scope: '#/properties/notizDarlehen',
                },
            ],
        },
        {
            type: 'SummaryTable',
            label: 'Eigenkapital',
            columns: 3,
            scope: '#/properties/eigenkapital',
            entries: [
                {
                    type: 'FieldRow',
                    scope: '#/properties/field5611',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field5612',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field5613',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field5614',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field5615',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field5616',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field5617',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field5630',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field5618',
                },
                {
                    type: 'SubTable',
                    label: 'Kapitalbasis',
                    level: 0,
                    scope: '#/properties/',
                    entries: [
                        {
                            type: 'SubTable',
                            level: 1,
                            label: 'Monat {#/properties/monat}',
                            scope: '#/properties/kapitalBasis',
                            entries: [
                                {
                                    type: 'FieldRow',
                                    scope: '#/properties/field5619',
                                },
                                {
                                    type: 'FieldRow',
                                    scope: '#/properties/field5620',
                                },
                            ],
                        },
                    ],
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field5621',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field5622',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field5624',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field5623',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field1776',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field5625',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field5626',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field5627',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field2022',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field1821',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field5628',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field5629',
                },
            ],
        },
    ],
};
