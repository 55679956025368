import React from 'react';
import { Close } from '@mui/icons-material';
import { Box, IconButton, IconButtonProps, Tooltip } from '@mui/material';

interface CloseButtonProps extends IconButtonProps {
    disabled?: boolean;
    tooltip?: string;
    onClick: () => void;
    containerClassName?: string;
}

export const CloseButton: React.FC<CloseButtonProps> = ({
    onClick,
    tooltip = 'Schließen',
    disabled = false,
    containerClassName,
    ...other
}) => {
    return (
        <Box className={containerClassName}>
            <Tooltip title={tooltip}>
                <span>
                    <IconButton
                        size="large"
                        onClick={onClick}
                        disabled={disabled}
                        {...other}
                        aria-label={tooltip}
                        aria-disabled={disabled}
                    >
                        <Close fontSize={other.size === 'small' ? 'small' : 'medium'} />
                    </IconButton>
                </span>
            </Tooltip>
        </Box>
    );
};
