export const erbpacht_table = {
    type: 'SummaryTable',
    label: 'Erbpacht',
    columns: 3,
    scope: '#/properties/erbpacht',
    entries: [
        {
            type: 'FieldRow',
            scope: '#/properties/field952',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/field952d',
        },
        {
            type: 'NotesRow',
            scope: '#/properties/notizErbpacht',
        },
    ],
};
