import { ReactElement, ReactNode } from 'react';
import { Container, styled } from '@mui/material';

import { useDocumentTitle } from 'utilities/hooks';

type ContentContainerProps = {
    title?: string;
    children: ReactNode;
};

export const ContentContainer = ({ title, children }: ContentContainerProps): ReactElement => {
    useDocumentTitle(title);

    return <StyledContainer>{children}</StyledContainer>;
};

const StyledContainer = styled(Container)(() => ({
    position: 'relative',
    paddingTop: 61,
    '& h1': {
        marginBottom: '2rem',
    },
    '.ContentContainer--loggedIn &': {
        marginLeft: 0,
        marginRight: 0,
    },
}));
