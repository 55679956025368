import React, { ReactElement, ReactNode } from 'react';

import { TraegerListResponse } from 'api/types';
import { Link } from 'elements/Link';
import { InvestPathBuilder, TraegerTargets } from 'navigation/Paths';

type TraegerListLinkProps = {
    traegerId: number;
    target: TraegerTargets;
    children: ReactNode;
};

export const TraegerListLink = ({ traegerId, target, children }: TraegerListLinkProps): ReactElement => {
    const to = InvestPathBuilder.home.verwaltung.traeger.target(traegerId, target);
    return <Link to={to}>{children}</Link>;
};

export const traegerListLinkRender = (data: TraegerListResponse, index: number, target: TraegerTargets): ReactNode => {
    const row = data['hydra:member'][index];
    const id = row.id;
    const targetValue = row[target];
    if (!id || !targetValue) return <>0</>;
    return targetValue.length > 0 ? (
        <TraegerListLink traegerId={id} target={target}>
            {targetValue.length}
        </TraegerListLink>
    ) : (
        <>0</>
    );
};
