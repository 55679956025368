import { ZustaendigkeitResponse } from 'api/types';

export enum ZustaendigkeitenRegionTableActions {
    BEARBEITEN = 'BEARBEITEN',
}

export type ZustaendigkeitenRegionTableAction = (
    action: ZustaendigkeitenRegionTableActions,
    zustaendigkeit: ZustaendigkeitResponse
) => Promise<void>;
