import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import jwtDecode, { JwtPayload as DecodedJwtPayload } from 'jwt-decode';

import { backendApiContextsService } from 'api/ApiContextsService';
import { backendApiService } from 'api/ApiService';
import { sessionService } from 'api/SessionService';
import { InvestPathBuilder } from 'navigation/Paths';

export interface JwtPayload extends DecodedJwtPayload {
    roles?: string[];
    vorname?: string;
    nachname?: string;
    username?: string;
    benutzerId?: number;
    rolle?: string;
    landschaftsverbandKuerzel?: string;
}

export interface JwtState {
    token?: string;
    refreshToken?: string;
    payload?: JwtPayload;
    timestamp?: number;
    isLoggedIn: boolean;
}

export type HandleJwtToken = (token: string, refreshToken: string, recovered?: boolean) => Promise<void>;
export type Logout = () => Promise<void>;

type UseAuthContextHandleJwtTokenResult = {
    jwt: JwtState;
    handleJwtToken: HandleJwtToken;
    logout: Logout;
};

export const useAuthContextJwtToken = (clearLandschaftsverbandView: () => void): UseAuthContextHandleJwtTokenResult => {
    const navigate = useNavigate();
    const [jwt, setJwt] = useState<JwtState>({ isLoggedIn: false });

    const handleJwtToken = useCallback(
        async (token: string | undefined, refreshToken: string | undefined, recovered?: boolean): Promise<void> => {
            backendApiService.setJwtToken(token, refreshToken);
            backendApiContextsService.setJwtToken(token, refreshToken);

            const payload: JwtPayload | undefined = token ? jwtDecode(token) : undefined;

            setJwt({
                token,
                refreshToken,
                payload,
                timestamp: new Date().getTime(),
                isLoggedIn: !!payload?.username,
            });

            if (token && refreshToken && recovered !== true) {
                sessionService.setToken(token, refreshToken);
            }

            if (!token || !refreshToken) {
                clearLandschaftsverbandView();
                navigate(InvestPathBuilder.home.path);
                sessionService.invalidate();
            }
        },
        [clearLandschaftsverbandView, navigate]
    );

    const logout: Logout = useCallback(() => handleJwtToken(undefined, undefined), [handleJwtToken]);

    return { jwt, handleJwtToken, logout };
};
