import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';

export const uiSchema = verticalLayout([
    group([
        control('field101'),
        control('field0102'),
        control('field110a'),
        control('field113'),
        control('field110b'),
        control('field0106'),
        control('field0107'),
        control('field0109'),
        control('field0116'),
    ]),
]);
