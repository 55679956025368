// actionKey "null" represents "Ansehen", "Neu" & "Bearbeiten"

import { useMemo } from 'react';

import { Schema, UiSchemaType } from 'forms/types/UiSchemaTypes';

export const filterElementsByAction = (elements: UiSchemaType[], actionKey: string | null): UiSchemaType[] =>
    // @ts-ignore
    elements
        .filter(
            (element) =>
                (element.actionKey === undefined && element.actionKeys === undefined) ||
                (element.actionKey !== undefined && element.actionKey === actionKey) ||
                (element.actionKeys !== undefined && element.actionKeys.includes(actionKey))
        )
        .map((element) =>
            element.elements
                ? {
                      ...element,
                      elements: filterElementsByAction(element.elements, actionKey),
                  }
                : element
        );

const CONTROL_TYPES = [
    'Control',
    'FormExtendButton',
    'ListControlWithModalForm',
    'SummaryTable',
    'DataGrid',
    'CollectionGroups',
    'LabelRowControl',
    'DocumentTableControl',
    'Group',
    'GroupForm',
    'InlineGroupRowControl',
    'NestedGroup',
    'HighlightedArea',
];

export const hasControls = (uiSchema: Schema): boolean => {
    const entries = uiSchema?.elements || uiSchema?.entries || [];
    if (!Array.isArray(entries)) return false;
    return entries.some((e: UiSchemaType) => CONTROL_TYPES.includes(e.type) || hasControls(e));
};

export const useControlVisibility = (uiSchema: Schema) => {
    return useMemo(() => hasControls(uiSchema), [uiSchema]);
};
