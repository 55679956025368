import { useState } from 'react';
import { JsonFormsCore } from '@jsonforms/core';
import { isEqual } from 'lodash';

import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/AntragForm/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { useLayoutWithJsonFormsState } from 'forms/hooks/useJsonFormsState';
import { FormLayoutConfig, Schema, UiSchemaType } from 'forms/types/UiSchemaTypes';

type UseZurueckziehenAkzeptierenDialogResult = {
    data: { entscheidung: boolean; erlaeuterungEntscheidung: string };
    onChange: (data: Pick<JsonFormsCore, 'data' | 'errors'>) => void;
    schema: Schema;
    uiSchema: UiSchemaType;
    config: FormLayoutConfig;
};

export const useZurueckziehenAkzeptierenDialog = (
    entscheidung: boolean,
    title: string
): UseZurueckziehenAkzeptierenDialogResult => {
    const [data, setData] = useState({ entscheidung, erlaeuterungEntscheidung: '' });
    const config = useLayoutWithJsonFormsState({
        gridLayout: {
            label: 0,
            input: 12,
        },
    });

    const schema: Schema = {
        type: 'object',
        properties: {
            erlaeuterungEntscheidung: {
                type: 'string',
                title,
                // @ts-ignore
                custom: {
                    block_prefixes: ['plain_textarea'],
                },
            },
        },
        required: [],
    };

    const uiSchema = customVerticalLayout([group([control('erlaeuterungEntscheidung')])]);

    const onChange = ({ data: newData }: Pick<JsonFormsCore, 'data' | 'errors'>) => {
        if (isEqual(newData, data)) return;
        setData(newData);
    };

    return {
        data,
        onChange,
        schema,
        uiSchema,
        config,
    };
};
