import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';

export const uiSchema = verticalLayout([
    group([
        control('field1777'),
        control('field1778'),
        control('field1778a'),
        control('field1779'),
        control('field1780'),
        control('field124'),
        control('field0301'),
        control('field130'),
        control('field1784'),
        control('field1785a'),
        control('field1785'),
    ]),
]);
