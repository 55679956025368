import { useEffect, useReducer } from 'react';
import { AxiosError, AxiosRequestConfig } from 'axios';

import { AntragType } from 'api/antragTypes';
import { backendApiService } from 'api/ApiService';
import { BenutzerTablePreference } from 'api/client';
import {
    AllowedCreateRollenResponse,
    AngemessenheitsGrenzeListResponse,
    AntragDiffResponse,
    AntragVersionCollectionListResponse,
    AntragVersionResponse,
    BaupreisIndexListResponse,
    BenutzerAufgabenResponse,
    BenutzerListResponse,
    BenutzerProfileResponse,
    BenutzerResponse,
    BenutzerTablePreferenceResponse,
    EigenkapitalZinsBundesbankListResponse,
    EigenkapitalZinsListResponse,
    EinrichtungAnbieterPermissionsResponse,
    EinrichtungListResponse,
    EinrichtungResponse,
    FestsetzungsAntragCollectionListResponse,
    FestsetzungsAntragResponse,
    FeststellungsAntragDiffResponse,
    FeststellungsAntragResponse,
    FeststellungsAntragVersionCollectionListResponse,
    FeststellungsAntragVersionResponse,
    FremdkapitalZinsListResponse,
    GetAntragVersionenOptions,
    GetApiAngemessenheitsgrenzeCollectionOptions,
    GetApiBaupreisCollectionOptions,
    GetApiEigenkapitalzinsenBundesbankCollectionOptions,
    GetApiEigenkapitalzinsP5Abs6APGDVOCollectionOptions,
    GetApiFremdkapitalzinsenBundesbankCollectionOptions,
    GetEinrichtungListOptions,
    GetFestsetzungsAntragOptions,
    GetFeststellungsAntragOptions,
    GetInvestorenmodellOptions,
    GetMeldungP29AntragOptions,
    GetZustaendigkeitListOptions,
    InvestorenmodellCollectionListResponse,
    InvestorenmodellResponse,
    LandschaftsverbandListResponse,
    LandschaftsverbandResponse,
    LvAufgabenResponse,
    MassenmailListResponse,
    MassenmailResponse,
    MeldungP29CollectionListResponse,
    TraegerResponse,
    TraegerverbandResponse,
    ZustaendigkeitListResponse,
    ZustaendigkeitResponse,
} from 'api/types';

const API_ACTION_LOAD = 'LOAD';
const API_ACTION_SUCCESS = 'LOADED';
const API_ACTION_ERROR = 'ERROR';

type ApiAction<T> = { type: 'LOADED'; payload: T } | { type: 'ERROR'; payload: AxiosError } | { type: 'LOAD' };
type ApiReducer<T> = (state: ApiState<T>, action: ApiAction<T>) => ApiState<T>;
type ApiState<T> = {
    isLoading: boolean | undefined;
    error: AxiosError | undefined;
    data: T | undefined;
};

const apiStateInitial: ApiState<never> = {
    isLoading: undefined,
    error: undefined,
    data: undefined,
};

export type ApiOptions = {
    refresh?: number;
    condition?: boolean;
};

const defaultApiOptions: ApiOptions = {
    refresh: undefined,
    condition: undefined,
};

const getApiReducer = <T>(): ApiReducer<T> => {
    return (state, action) => {
        switch (action.type) {
            case API_ACTION_LOAD:
                return {
                    ...state,
                    isLoading: true,
                };
            case API_ACTION_SUCCESS:
                return {
                    isLoading: false,
                    data: action.payload,
                    error: undefined,
                };
            case API_ACTION_ERROR:
                return {
                    data: undefined,
                    isLoading: false,
                    error: action.payload,
                };
        }
    };
};

const reduce = <T>(promise: Promise<T>, dispatch: (p: ApiAction<T>) => void) => {
    dispatch({ type: API_ACTION_LOAD });
    promise
        .then((data) => dispatch({ type: API_ACTION_SUCCESS, payload: data }))
        .catch((err) => dispatch({ type: API_ACTION_ERROR, payload: err }));
};

export const useApiLandschaftsverbandCollection = (
    page?: number,
    itemsPerPage?: number,
    kuerzel?: string,
    kuerzel2?: string[]
): ApiState<LandschaftsverbandListResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<LandschaftsverbandListResponse>(), apiStateInitial);

    useEffect(() => {
        reduce(backendApiService.getLandschaftsverbandCollection(page, itemsPerPage, kuerzel, kuerzel2), dispatch);
    }, [page, itemsPerPage, kuerzel, kuerzel2]);

    return state;
};

export const useApiEinrichtungItem = (
    id: string | number | undefined,
    { refresh }: ApiOptions = defaultApiOptions
): ApiState<EinrichtungResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<EinrichtungResponse>(), apiStateInitial);

    useEffect(() => {
        reduce(backendApiService.getEinrichtungItem(String(id)), dispatch);
    }, [id, refresh]);

    return state;
};

export const useApiZustaendigkeitItem = (id: string): ApiState<ZustaendigkeitResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<ZustaendigkeitResponse>(), apiStateInitial);

    useEffect(() => {
        reduce(backendApiService.getZustaendigkeitItem(id), dispatch);
    }, [id]);

    return state;
};

export const useApiBaupreisindexCollection = ({
    page,
    itemsPerPage,
    typ,
    refreshToken,
}: GetApiBaupreisCollectionOptions): ApiState<BaupreisIndexListResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<BaupreisIndexListResponse>(), apiStateInitial);

    useEffect(() => {
        reduce(backendApiService.getBaupreisindexCollection(page, itemsPerPage, typ), dispatch);
    }, [page, itemsPerPage, typ, refreshToken]);

    return state;
};

export const useApiEigenkapitalzinsP5Abs6APGDVOCollection = ({
    page,
    itemsPerPage,
    options,
    refreshToken,
}: GetApiEigenkapitalzinsP5Abs6APGDVOCollectionOptions): ApiState<EigenkapitalZinsListResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<EigenkapitalZinsListResponse>(), apiStateInitial);

    useEffect(() => {
        reduce(backendApiService.getEigenkapitalzinsP5Abs6APGDVOCollection(page, itemsPerPage, options), dispatch);
    }, [page, itemsPerPage, options, refreshToken]);

    return state;
};

export const useApiFremdkapitalzinsenBundesbankCollection = ({
    page,
    itemsPerPage,
    options,
    refreshToken,
}: GetApiFremdkapitalzinsenBundesbankCollectionOptions): ApiState<FremdkapitalZinsListResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<FremdkapitalZinsListResponse>(), apiStateInitial);

    useEffect(() => {
        reduce(backendApiService.getFremdkapitalzinsenBundesbankCollection(page, itemsPerPage, options), dispatch);
    }, [page, itemsPerPage, options, refreshToken]);

    return state;
};

export const useApiEigenkapitalzinsenBundesbankCollection = ({
    page,
    itemsPerPage,
    options,
    refreshToken,
}: GetApiEigenkapitalzinsenBundesbankCollectionOptions): ApiState<EigenkapitalZinsBundesbankListResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<EigenkapitalZinsBundesbankListResponse>(), apiStateInitial);

    useEffect(() => {
        reduce(backendApiService.getEigenkapitalzinsenBundesbankCollection(page, itemsPerPage, options), dispatch);
    }, [page, itemsPerPage, options, refreshToken]);

    return state;
};

export const useApiAngemessenheitsgrenzeCollection = ({
    page,
    itemsPerPage,
    typ,
    typ2,
    refreshToken,
}: GetApiAngemessenheitsgrenzeCollectionOptions): ApiState<AngemessenheitsGrenzeListResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<AngemessenheitsGrenzeListResponse>(), apiStateInitial);

    useEffect(() => {
        reduce(backendApiService.getAngemessenheitsgrenzeCollection(page, itemsPerPage, typ, typ2), dispatch);
    }, [page, itemsPerPage, typ, typ2, refreshToken]);

    return state;
};

export const useApiTraegerItem = (id: string | number | undefined): ApiState<TraegerResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<TraegerResponse>(), apiStateInitial);

    useEffect(() => {
        if (!id) return;
        reduce(backendApiService.getTraegerItem(String(id)), dispatch);
    }, [id]);

    return state;
};

export const useApiEinrichtungCollection = (
    {
        page,
        itemsPerPage,
        id,
        id2,
        art,
        art2,
        status,
        status2,
        traeger,
        traeger2,
        traegerverband,
        traegerverband2,
        qSearch,
        orderByAktenzeichen,
        orderByNrwKey,
        orderByName,
        orderByOrt,
        orderByArt,
        orderByStatus,
        orderByZustaendigerBenutzer,
        zustaendigerBenutzer,
        anbieterBenutzer,
        createdAtBefore,
        createdAtStrictlyBefore,
        createdAtAfter,
        createdAtStrictlyAfter,
        options,
    }: GetEinrichtungListOptions,
    { refresh, condition }: ApiOptions = defaultApiOptions
): ApiState<EinrichtungListResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<EinrichtungListResponse>(), apiStateInitial);

    useEffect(() => {
        if (condition === false) return;
        reduce(
            backendApiService.getEinrichtungCollection({
                page,
                itemsPerPage,
                id,
                id2,
                art,
                art2,
                status,
                status2,
                traeger,
                traeger2,
                traegerverband,
                traegerverband2,
                qSearch,
                orderByAktenzeichen,
                orderByNrwKey,
                orderByName,
                orderByOrt,
                orderByArt,
                orderByStatus,
                orderByZustaendigerBenutzer,
                zustaendigerBenutzer,
                anbieterBenutzer,
                createdAtBefore,
                createdAtStrictlyBefore,
                createdAtAfter,
                createdAtStrictlyAfter,
                options,
            }),
            dispatch
        );
    }, [
        page,
        itemsPerPage,
        id,
        id2,
        art,
        art2,
        status,
        status2,
        traeger,
        traeger2,
        traegerverband,
        traegerverband2,
        qSearch,
        orderByAktenzeichen,
        orderByNrwKey,
        orderByName,
        orderByOrt,
        orderByArt,
        orderByStatus,
        orderByZustaendigerBenutzer,
        zustaendigerBenutzer,
        anbieterBenutzer,
        createdAtBefore,
        createdAtStrictlyBefore,
        createdAtAfter,
        createdAtStrictlyAfter,
        options,
        refresh,
        condition,
    ]);

    return state;
};

export const useApiZustaendigkeitCollection = (
    { page, itemsPerPage, qSearch, benutzer, benutzer2, options }: GetZustaendigkeitListOptions,
    { refresh }: ApiOptions = defaultApiOptions
): ApiState<ZustaendigkeitListResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<ZustaendigkeitListResponse>(), apiStateInitial);

    useEffect(() => {
        reduce(
            backendApiService.getZustaendigkeitCollection({
                page,
                itemsPerPage,
                qSearch,
                benutzer,
                benutzer2,
                options,
            }),
            dispatch
        );
    }, [page, itemsPerPage, qSearch, benutzer, benutzer2, options, refresh]);

    return state;
};

export const useApiInvestorenmodellCollection = (
    {
        page,
        itemsPerPage,
        qSearch,
        zustaendigerBenutzer,
        status,
        status2,
        einrichtung,
        einrichtung2,
        berechnungsart,
        antragJahr,
        aufgabe,
        orderZuletztEingereichtAt,
        orderEinrichtungAktenzeichen,
        orderEinrichtungName,
        orderStatus,
        orderEinrichtungZustaendigerBenutzerName,
        orderFestsetzungsantragField500,
        orderFestsetzungsantragFeststellungsantragBerechnungsart,
        options,
    }: GetInvestorenmodellOptions,
    { refresh, condition }: ApiOptions = defaultApiOptions
): ApiState<InvestorenmodellCollectionListResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<InvestorenmodellCollectionListResponse>(), apiStateInitial);

    useEffect(() => {
        if (condition === false) return;
        reduce(
            backendApiService.getInvestorenmodellCollection({
                page,
                itemsPerPage,
                qSearch,
                zustaendigerBenutzer,
                status,
                status2,
                einrichtung,
                einrichtung2,
                berechnungsart,
                antragJahr,
                aufgabe,
                orderZuletztEingereichtAt,
                orderEinrichtungAktenzeichen,
                orderEinrichtungName,
                orderStatus,
                orderEinrichtungZustaendigerBenutzerName,
                orderFestsetzungsantragField500,
                orderFestsetzungsantragFeststellungsantragBerechnungsart,
                options,
            }),
            dispatch
        );
    }, [
        page,
        itemsPerPage,
        qSearch,
        zustaendigerBenutzer,
        status,
        status2,
        einrichtung,
        einrichtung2,
        berechnungsart,
        antragJahr,
        aufgabe,
        orderZuletztEingereichtAt,
        orderEinrichtungAktenzeichen,
        orderEinrichtungName,
        orderStatus,
        orderEinrichtungZustaendigerBenutzerName,
        orderFestsetzungsantragField500,
        orderFestsetzungsantragFeststellungsantragBerechnungsart,
        options,
        refresh,
        condition,
    ]);

    return state;
};

export const useApiMeldungP29Collection = (
    {
        page,
        itemsPerPage,
        qSearch,
        zustaendigerBenutzer,
        status,
        status2,
        einrichtung,
        einrichtung2,
        meldedatum,
        orderEingereichtAt,
        orderEinrichtungAktenzeichen,
        orderEinrichtungName,
        orderStatus,
        orderGueltigAb,
        orderMeldedatum,
        orderEinrichtungZustaendigerBenutzerName,
        aufgabe,
        options,
    }: GetMeldungP29AntragOptions,
    { refresh, condition }: ApiOptions = defaultApiOptions
): ApiState<MeldungP29CollectionListResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<MeldungP29CollectionListResponse>(), apiStateInitial);

    useEffect(() => {
        if (!condition) return;
        reduce(
            backendApiService.getMeldungP29Collection({
                page,
                itemsPerPage,
                qSearch,
                zustaendigerBenutzer,
                status,
                status2,
                einrichtung,
                einrichtung2,
                meldedatum,
                orderEingereichtAt,
                orderEinrichtungAktenzeichen,
                orderEinrichtungName,
                orderStatus,
                orderGueltigAb,
                orderMeldedatum,
                orderEinrichtungZustaendigerBenutzerName,
                aufgabe,
                options,
            }),
            dispatch
        );
    }, [
        page,
        itemsPerPage,
        qSearch,
        zustaendigerBenutzer,
        status,
        status2,
        einrichtung,
        einrichtung2,
        meldedatum,
        orderEingereichtAt,
        orderEinrichtungAktenzeichen,
        orderEinrichtungName,
        orderStatus,
        orderGueltigAb,
        orderMeldedatum,
        orderEinrichtungZustaendigerBenutzerName,
        aufgabe,
        options,
        refresh,
        condition,
    ]);

    return state;
};
export const useApiFeststellungsAntragCollection = (
    {
        page,
        itemsPerPage,
        qSearch,
        zustaendigerBenutzer,
        status,
        status2,
        einrichtung,
        einrichtung2,
        aufgabe,
        orderZuletztEingereichtAt,
        orderField102,
        orderEinrichtungAktenzeichen,
        orderBerechnungsart,
        orderEinrichtungName,
        orderStatus,
        orderEinrichtungZustaendigerBenutzer,
        berechnungsart,
        antragJahr,
        options,
    }: GetFeststellungsAntragOptions,
    { refresh, condition }: ApiOptions = defaultApiOptions
): ApiState<FestsetzungsAntragCollectionListResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<FestsetzungsAntragCollectionListResponse>(), apiStateInitial);

    useEffect(() => {
        if (condition === false) return;
        reduce(
            backendApiService.getFeststellungsantragCollection({
                page,
                itemsPerPage,
                qSearch,
                zustaendigerBenutzer,
                status,
                status2,
                einrichtung,
                einrichtung2,
                aufgabe,
                orderZuletztEingereichtAt,
                orderField102,
                orderEinrichtungAktenzeichen,
                orderBerechnungsart,
                orderEinrichtungName,
                orderStatus,
                orderEinrichtungZustaendigerBenutzer,
                berechnungsart,
                antragJahr,
                options,
            }),
            dispatch
        );
    }, [
        page,
        itemsPerPage,
        qSearch,
        zustaendigerBenutzer,
        status,
        status2,
        einrichtung,
        einrichtung2,
        aufgabe,
        orderZuletztEingereichtAt,
        orderField102,
        orderEinrichtungAktenzeichen,
        orderBerechnungsart,
        orderEinrichtungName,
        orderStatus,
        orderEinrichtungZustaendigerBenutzer,
        berechnungsart,
        antragJahr,
        options,
        refresh,
        condition,
    ]);

    return state;
};

export const useApiFestsetzungsAntragCollection = (
    {
        page,
        itemsPerPage,
        qSearch,
        zustaendigerBenutzer,
        status,
        status2,
        einrichtung,
        einrichtung2,
        aufgabe,
        options,
    }: GetFestsetzungsAntragOptions,
    { refresh, condition }: ApiOptions = defaultApiOptions
): ApiState<FestsetzungsAntragCollectionListResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<FestsetzungsAntragCollectionListResponse>(), apiStateInitial);

    useEffect(() => {
        if (condition === false) return;
        reduce(
            backendApiService.getFestsetzungsantragCollection({
                page,
                itemsPerPage,
                qSearch,
                zustaendigerBenutzer,
                status,
                einrichtung,
                einrichtung2,
                aufgabe,
                options,
            }),
            dispatch
        );
    }, [
        page,
        itemsPerPage,
        qSearch,
        zustaendigerBenutzer,
        status,
        status2,
        einrichtung,
        einrichtung2,
        aufgabe,
        options,
        refresh,
        condition,
    ]);

    return state;
};

export const useApiCheckChangeToken = (token?: string, options?: AxiosRequestConfig): ApiState<BenutzerResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<BenutzerResponse>(), apiStateInitial);

    useEffect(() => {
        reduce(backendApiService.checkChangeToken({ token: token }, options), dispatch);
    }, [token, options]);

    return state;
};

export const useApiMassenmail = (id: string, options?: AxiosRequestConfig): ApiState<MassenmailResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<MassenmailResponse>(), apiStateInitial);

    useEffect(() => {
        reduce(backendApiService.getMassenmail(id, options), dispatch);
    }, [id, options]);

    return state;
};

export const useApiMassenmailCollection = (
    page?: number,
    itemsPerPage?: number,
    options?: AxiosRequestConfig
): ApiState<MassenmailListResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<MassenmailListResponse>(), apiStateInitial);

    useEffect(() => {
        reduce(backendApiService.getMassenmailCollection(page, itemsPerPage, options), dispatch);
    }, [page, itemsPerPage, options]);

    return state;
};

export const useApiBenutzerCollection = (
    page?: number,
    itemsPerPage?: number,
    id?: number,
    id2?: number[],
    rolle?: string,
    rolle2?: string[],
    landschaftsverband?: string,
    landschaftsverband2?: string[],
    qSearch?: string[],
    qImpersonateSearch?: string[],
    options?: AxiosRequestConfig
): ApiState<BenutzerListResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<BenutzerListResponse>(), apiStateInitial);

    useEffect(() => {
        reduce(
            backendApiService.getBenutzerCollection({
                page,
                itemsPerPage,
                id,
                id2,
                rolle,
                rolle2,
                landschaftsverband,
                landschaftsverband2,
                qSearch,
                qImpersonateSearch,
                options,
            }),
            dispatch
        );
    }, [
        page,
        itemsPerPage,
        id,
        id2,
        rolle,
        rolle2,
        landschaftsverband,
        landschaftsverband2,
        qSearch,
        qImpersonateSearch,
        options,
    ]);

    return state;
};

export const useApiBenutzerTablePreference = (name: string): ApiState<BenutzerTablePreference> => {
    const [state, dispatch] = useReducer(getApiReducer<BenutzerTablePreferenceResponse>(), apiStateInitial);

    useEffect(() => {
        reduce(backendApiService.getBenutzerTablePreference(name), dispatch);
    }, [name]);

    return state;
};

export const useApiProfile = (): ApiState<BenutzerProfileResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<BenutzerProfileResponse>(), apiStateInitial);

    useEffect(() => {
        reduce(backendApiService.getProfile(), dispatch);
    }, []);

    return state;
};

export const useApiAllowedCreateRollen = (): ApiState<AllowedCreateRollenResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<AllowedCreateRollenResponse>(), apiStateInitial);

    useEffect(() => {
        reduce(backendApiService.getAllowedCreateRollen(), dispatch);
    }, []);

    return state;
};

export const useApiLandschaftsverbandItem = (id: string | number | undefined): ApiState<LandschaftsverbandResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<LandschaftsverbandResponse>(), apiStateInitial);

    useEffect(() => {
        if (!id) return;
        reduce(backendApiService.getLandschaftsverbandItem(String(id)), dispatch);
    }, [id]);

    return state;
};

export const useApiTraegerverbandItem = (id: string | number | undefined): ApiState<TraegerverbandResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<TraegerverbandResponse>(), apiStateInitial);

    useEffect(() => {
        if (!id) return;
        reduce(backendApiService.getTraegerverbandItem(String(id)), dispatch);
    }, [id]);

    return state;
};

export const useApiFeststellungsantragItem = (
    id: string | number | undefined
): ApiState<FeststellungsAntragResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<FeststellungsAntragResponse>(), apiStateInitial);

    useEffect(() => {
        if (!id) return;
        reduce(backendApiService.getFeststellungsantragItem(id), dispatch);
    }, [id]);

    return state;
};

export const useApiFeststellungsAntragVersion = (
    id: number | string | undefined
): ApiState<FeststellungsAntragVersionResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<FeststellungsAntragVersionResponse>(), apiStateInitial);

    useEffect(() => {
        if (!id) return;
        reduce(backendApiService.getFeststellungsantragVersionItem(id), dispatch);
    }, [id]);

    return state;
};

export const useApiFeststellungsAntragVersions = (
    id: number | string | undefined
): ApiState<FeststellungsAntragVersionCollectionListResponse> => {
    const [state, dispatch] = useReducer(
        getApiReducer<FeststellungsAntragVersionCollectionListResponse>(),
        apiStateInitial
    );

    useEffect(() => {
        if (!id) return;
        reduce(
            backendApiService.getFeststellungsantragVersionen({
                page: 1,
                itemsPerPage: 999,
                antrag: String(id),
                orderByAntragModifiedAt: 'desc',
            }),
            dispatch
        );
    }, [id]);

    return state;
};

export const useApiFeststellungsAntragDiff = (
    id: number | string | undefined,
    v2: number | string | undefined,
    v1?: number | string | undefined
): ApiState<FeststellungsAntragDiffResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<FeststellungsAntragDiffResponse>(), apiStateInitial);

    useEffect(() => {
        if (!id || !v2) return;
        reduce(backendApiService.getFeststellungsantragDiff(id, v2, v1), dispatch);
    }, [id, v2, v1]);

    return state;
};

export const useApiFestsetzungsAntragItem = (id: string | number | undefined): ApiState<FestsetzungsAntragResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<FestsetzungsAntragResponse>(), apiStateInitial);

    useEffect(() => {
        if (!id) return;
        reduce(backendApiService.getFestsetzungsantragItem(id), dispatch);
    }, [id]);

    return state;
};

export const useApiInvestorenmodellItem = (id: string | number | undefined): ApiState<InvestorenmodellResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<InvestorenmodellResponse>(), apiStateInitial);

    useEffect(() => {
        if (!id) return;
        reduce(backendApiService.getInvestorenmodellItem(id), dispatch);
    }, [id]);

    return state;
};

export const useApiEinrichtungAnbieterPermissions = (id: number | string | undefined) => {
    const [state, dispatch] = useReducer(getApiReducer<EinrichtungAnbieterPermissionsResponse>(), apiStateInitial);

    useEffect(() => {
        if (!id) return;
        reduce(backendApiService.getEinrichtungAnbieterPermissions(id), dispatch);
    }, [id]);

    return state;
};

export const useApiBenutzerAufgaben = ({
    condition,
}: ApiOptions = defaultApiOptions): ApiState<BenutzerAufgabenResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<BenutzerAufgabenResponse>(), apiStateInitial);

    useEffect(() => {
        if (condition === false) return;
        reduce(backendApiService.getBenutzerAufgabenItem(), dispatch);
    }, [condition]);

    return state;
};

export const useApiLvAufgaben = ({ condition }: ApiOptions = defaultApiOptions): ApiState<LvAufgabenResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<LvAufgabenResponse>(), apiStateInitial);

    useEffect(() => {
        if (condition === false) return;
        reduce(backendApiService.getLvAufgabenItem(), dispatch);
    }, [condition]);

    return state;
};

export const useApiAntragVersions = (
    id: number | string | undefined,
    type: AntragType
): ApiState<AntragVersionCollectionListResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<AntragVersionCollectionListResponse>(), apiStateInitial);

    useEffect(() => {
        if (!id) return;
        const params: GetAntragVersionenOptions = {
            page: 1,
            itemsPerPage: 999,
            antrag: String(id),
            orderByAntragModifiedAt: 'desc',
        };

        switch (type) {
            case AntragType.FESTSTELLUNG:
                reduce(backendApiService.getFeststellungsantragVersionen(params), dispatch);
                break;
            case AntragType.FESTSETZUNG:
                reduce(backendApiService.getFestsetzungsantragVersionen(params), dispatch);
                break;
            case AntragType.INVESTOREN_MODELL:
                reduce(backendApiService.getInvestorenmodellVersionen(params), dispatch);
                break;
        }
    }, [type, id]);

    return state;
};

export const useApiAntragVersion = (
    id: number | string | undefined,
    type: AntragType
): ApiState<AntragVersionResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<AntragVersionResponse>(), apiStateInitial);

    useEffect(() => {
        if (!id) return;

        switch (type) {
            case AntragType.FESTSTELLUNG:
                reduce(backendApiService.getFeststellungsantragVersionItem(id), dispatch);
                break;
            case AntragType.FESTSETZUNG:
                reduce(backendApiService.getFestsetzungsantragVersionItem(id), dispatch);
                break;
            case AntragType.INVESTOREN_MODELL:
                reduce(backendApiService.getInvestorenmodellVersionItem(id), dispatch);
                break;
        }
    }, [id, type]);

    return state;
};

export const useApiAntragDiff = (
    id: number | string | undefined,
    type: AntragType,
    v2: number | string | undefined,
    v1?: number | string | undefined
): ApiState<AntragDiffResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<AntragDiffResponse>(), apiStateInitial);

    useEffect(() => {
        if (!id || !v2) return;

        switch (type) {
            case AntragType.FESTSTELLUNG:
                reduce(backendApiService.getFeststellungsantragDiff(id, v2, v1), dispatch);
                break;
            case AntragType.FESTSETZUNG:
                reduce(backendApiService.getFestsetzungsantragDiff(id, v2, v1), dispatch);
                break;
            case AntragType.INVESTOREN_MODELL:
                reduce(backendApiService.getInvestorenmodellDiff(id, v2, v1), dispatch);
                break;
        }
    }, [id, type, v2, v1]);

    return state;
};
