import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';

import { AntragType } from 'api/antragTypes';
import { useApiFestsetzungsAntragItem } from 'api/hooks/useApiClient';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { ROLES } from 'constants/roles';
import { ContentContainer } from 'layout/container/ContentContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { withAuthorization } from 'navigation/withAuthorization';
import { menuAntraege } from 'pages/Antraege/AntragConfig';
import { AntragDetailsContent } from 'pages/Antraege/components/AntragDetailsContent';
import { AntragDetailsHeader } from 'pages/Antraege/components/AntragDetailsHeader';

const FestsetzungsDetails = (): ReactElement => {
    const { data: antrag, isLoading } = useFestsetzungsAntrag();
    return (
        <ContentContainer title="Festsetzungsantrag Details">
            <AppLoaderContainer isLoading={isLoading}>
                {antrag && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h1">Antrag auf Festsetzung</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <AntragDetailsHeader antrag={antrag} />
                        </Grid>
                        <Grid item xs={12}>
                            <AntragDetailsContent type={AntragType.FESTSETZUNG} antrag={antrag} />
                        </Grid>
                    </Grid>
                )}
            </AppLoaderContainer>
        </ContentContainer>
    );
};

export const useFestsetzungsAntrag = () => {
    const { id } = useParams();
    return useApiFestsetzungsAntragItem(id);
};

export const FestsetzungsDetailsPage = withAuthorization(
    withSideBar(FestsetzungsDetails, menuAntraege),
    [ROLES.FACHADMIN, ROLES.SUPPORT, ROLES.LV, ROLES.ANBIETER] // TODO: ROLES.ANBIETER entfernen
);
