import { customVerticalLayout } from 'forms/AntragForm/ui-schemas/elements/customVerticalLayout';
import { formDialog } from 'forms/AntragForm/ui-schemas/elements/formDialog';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { listControlWithModalForm } from 'forms/AntragForm/ui-schemas/elements/listControlWithModalForm';
import { tableColumn, tableColumnDate, tableColumnDecimal } from 'forms/AntragForm/ui-schemas/elements/tableColumn';

export const uiSchema = customVerticalLayout([
    group(
        [
            listControlWithModalForm(
                ['#/properties/aufwandLalg'],
                [
                    tableColumn('Maßnahme', {
                        scope: '#/properties/field201',
                    }),
                    tableColumnDate('Inbetriebnahme', {
                        scope: '#/properties/field202',
                    }),
                    tableColumnDecimal('Anerkannter Betrag', {
                        scope: '#/properties/field226',
                    }),
                    tableColumnDate('Ende aktueller Verteilungszeitraum', {
                        scope: '#/properties/field0520',
                    }),
                ],
                [],
                [],
                [],
                [
                    formDialog(
                        'Neue Maßnahme',
                        'Maßnahme',
                        { labelScope: '#/properties/field201' },
                        customVerticalLayout([
                            group([
                                {
                                    type: 'Control',
                                    scope: '#/properties/field201',
                                },
                                {
                                    type: 'Control',
                                    scope: '#/properties/field0534',
                                },
                                {
                                    type: 'Control',
                                    scope: '#/properties/field0519',
                                },
                                {
                                    type: 'Control',
                                    scope: '#/properties/field0520',
                                },
                                {
                                    type: 'Control',
                                    scope: '#/properties/field1203',
                                },
                                {
                                    type: 'Control',
                                    scope: '#/properties/field5204',
                                },
                            ]),
                        ]),
                        {
                            scope: '#/properties/aufwandLalg',
                        }
                    ),
                ],
                { viewOnly: true, options: { noSeparator: true } }
            ),
        ],
        { noSeparator: true }
    ),
    group(
        [
            {
                type: 'HighlightedArea',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/field5205',
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/field5205kv',
                    },
                ],
            },
        ],
        { noSeparator: true }
    ),
]);
