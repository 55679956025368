import { useCallback, useState } from 'react';

import { AntragType } from 'api/antragTypes';
import { AntragVersionCollectionResponse } from 'api/types';
import { AntragVersionTableAction, AntragVersionTableActions } from 'constants/antragVersionTableActions';
import { openZusammenfassung } from 'navigation/PDFs';

export const useAntragVersionListAction = (type: AntragType) => {
    const [version, setVersion] = useState<AntragVersionCollectionResponse | null>(null);
    const [initialTab, setInitialTab] = useState(0);

    const closeAction = () => {
        setInitialTab(0);
        setVersion(null);
    };

    const handleAction: AntragVersionTableAction = useCallback(
        async (action, antragVersion, antrag) => {
            if (!antragVersion) return;
            switch (action) {
                case AntragVersionTableActions.ANSEHEN:
                    openZusammenfassung(type, antrag, antragVersion);
                    break;
                case AntragVersionTableActions.DOKUMENTE:
                    setInitialTab(0);
                    setVersion(antragVersion);
                    break;
                case AntragVersionTableActions.BESCHEIDE:
                    setInitialTab(1);
                    setVersion(antragVersion);
                    break;
            }
        },
        [type]
    );

    return { initialTab, feststellungVersion: version, closeAction, handleAction };
};
