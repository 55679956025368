import { DependencyList } from 'react';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';

import { useTableUrlParams } from 'components/DataTableHydra/hooks/useTableUrlParams';

import { GetColumns, useTableColumns } from './useTableColumns';
import { GetData, useTableData } from './useTableData';
import { FilterButtonProps, useTableFilters } from './useTableFilters';
import { useTableOptions } from './useTableOptions';

interface UseTableParametersResult<Data = any> {
    isLoading: boolean;
    data: Data | undefined;
    options: MUIDataTableOptions;
    columns: MUIDataTableColumnDef[];
    filterButtonProps: FilterButtonProps;
}

export const useTableParameters = <Data = any>(
    name: string,
    initialOptions: MUIDataTableOptions | undefined,
    getColumns: GetColumns<Data>,
    getData: GetData<Data>,
    deps: DependencyList = []
): UseTableParametersResult<Data> => {
    const { filters, initialFilters, onFiltersChanged, updateFilters, ...filterButtonProps } = useTableFilters(
        name,
        getColumns
    );

    const {
        options,
        searchText,
        sortOrder,
        page,
        rowsPerPage,
        updateSortOrder,
        updateSearchText,
        updatePage,
        updateRowsPerPage,
    } = useTableOptions(initialOptions, onFiltersChanged);

    const { isLoading, data } = useTableData<Data>(options, filters, getData, deps);

    const columns = useTableColumns(data, filters, getColumns);

    useTableUrlParams(
        filters,
        sortOrder,
        searchText,
        page,
        rowsPerPage,
        updateFilters,
        updateSortOrder,
        updateSearchText,
        updatePage,
        updateRowsPerPage,
        initialOptions,
        initialFilters
    );

    return { isLoading, data, options, columns, filterButtonProps };
};
