import { AntragType, MeldungP29API } from 'api/antragTypes';
import { backendApiService } from 'api/ApiService';
import { ROLES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { InvestPathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { menuAntraege, menuMeldungP29 } from 'pages/Antraege/AntragConfig';
import { AntragPage } from 'pages/Antraege/components/AntragPage';

const antragApi: MeldungP29API = {
    submit: backendApiService.postMeldungP29Json.bind(backendApiService),
    deleteObjectTypeItem: backendApiService.deleteMeldungP29ObjectTypeItem.bind(backendApiService),
    getFormData: backendApiService.getMeldungP29Json.bind(backendApiService),
    performWorkflowAction: backendApiService.workflowMeldungP29Item.bind(backendApiService),
    getAntrag: backendApiService.getMeldungP29Item.bind(backendApiService),
    submitGegenpruefungErlaeuterung: async () => undefined,
};

const MeldungP29 = () => {
    return (
        <AntragPage
            api={antragApi}
            stepPathBuilder={InvestPathBuilder.home.antraege.meldungen_p29.edit}
            type={AntragType.MELDUNG_P29}
            header={'Meldung nach § 29'}
            menuBuilder={menuMeldungP29}
        />
    );
};

export const MeldungP29Page = withAuthorization(withSideBar(MeldungP29, menuAntraege), [
    ROLES.FACHADMIN,
    ROLES.SUPPORT,
    ROLES.LV,
    ROLES.ANBIETER,
]);
