import { useCallback, useEffect, useState } from 'react';

import { AntragType } from 'api/antragTypes';
import { backendApiService } from 'api/ApiService';
import { AntragFilterOptionResponse } from 'api/types';
import { LvBenutzerOptions } from 'components/DataTableHydra/hooks/useLvBenutzerOptions';

export interface AntragFilterOptions {
    antragJahr: string[];
    zustaendigerBenutzer: LvBenutzerOptions;
}

export const useAntragFilterOptions = (type: AntragType): AntragFilterOptions | undefined => {
    const [filter, setFilter] = useState<AntragFilterOptions>();

    const loadFilters = useCallback(async () => {
        try {
            let response: AntragFilterOptionResponse;
            switch (type) {
                case AntragType.FESTSTELLUNG:
                    response = await backendApiService.getFeststellungsantragFilter();
                    break;
                case AntragType.FESTSETZUNG:
                    response = await backendApiService.getFestsetzungsantragFilter();
                    break;
                case AntragType.INVESTOREN_MODELL:
                    response = await backendApiService.getInvestorenmodellFilter();
                    break;
                case AntragType.MELDUNG_P29:
                    response = await backendApiService.getMeldungP29Filter();
                    break;
            }

            const filters = response.filters.reduce(
                (aggregate, current) => ({ ...aggregate, [current.field]: current.values }),
                { antragJahr: [], zustaendigerBenutzer: {} }
            );

            setFilter(filters);
        } catch (e) {
            console.error(e);
        }
    }, [type]);

    useEffect(() => {
        loadFilters();
    }, [loadFilters]);

    return filter;
};
