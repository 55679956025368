import React from 'react';

import { useApiLandschaftsverbandCollection } from 'api/hooks/useApiClient';
import { AppLoaderContainer } from 'components/AppLoaderContainer';

import { Adresse } from './Adresse';

export const AdresseLandschaftsverband: React.FC<{ kuerzel: string }> = ({ kuerzel }) => {
    const { data } = useApiLandschaftsverbandCollection(1, 1, kuerzel);

    const lv = data && data['hydra:member'].length ? data['hydra:member'][0] : undefined;

    return (
        <AppLoaderContainer isLoading={!lv}>
            {lv && (
                <Adresse
                    name={
                        <strong>
                            {lv.name} ({lv.kuerzel})
                        </strong>
                    }
                    strasse={lv.strasse}
                    hausnummer={lv.hausnummer}
                    plz={lv.plz}
                    ort={lv.ort}
                />
            )}
        </AppLoaderContainer>
    );
};
