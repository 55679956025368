import { useEffect } from 'react';

import { ComponentError } from 'forms/hooks/useErrors';
import { errorMessage } from 'forms/utils/MessageUtils';
import { useDeepEqualMemo } from 'utilities/hooks';

import { useMessageActions } from './useMessages';

export const useErrorMessages = (errors: ComponentError[], loadingError?: string) => {
    const deepErrors = useDeepEqualMemo(errors);
    const { closeMessage, addMessage } = useMessageActions();

    useEffect(() => {
        if (!deepErrors || deepErrors.length === 0) {
            return;
        }
        const uuids = deepErrors.map((e) => addMessage(errorMessage(e.message)));
        return () => uuids.forEach(closeMessage);
    }, [deepErrors, addMessage, closeMessage]);

    useEffect(() => {
        if (loadingError) {
            const uuid = addMessage(errorMessage(loadingError));
            return () => closeMessage(uuid);
        }
    }, [loadingError, addMessage, closeMessage]);
};
