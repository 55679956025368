import React from 'react';
import { TableCell, TableRow } from '@mui/material';

import { useAuthHelper } from 'api/auth/useAuthHelper';
import { STATUS } from 'constants/antragStatus';
import { ROLES } from 'constants/roles';
import { MissingRequiredFieldsIcon } from 'forms/controls/components/MissingRequiredFieldsIcon';
import { useCollectionItemActions } from 'forms/hooks/useCollectionItemActions';
import { useFormState } from 'forms/state/useFormState';
import { FormConfig, Schema, TableColumn } from 'forms/types/UiSchemaTypes';

import { FormTableActions } from './FormTableActions';
import { FormTableCell } from './FormTableCell';

interface FormTableRowProps {
    index: number;
    rowData: any;
    path: string;
    config: FormConfig;
    schema: Schema;
    columns: TableColumn[];
    onView: (index: number) => void;
    onEdit: (index: number) => void;
    onDelete: (index: number) => void;
    onCustomAction: (index: number, key: string) => void;
    disabled?: boolean;
    deleteAlertText: (index: number) => string;
    deleteConfirmationLabel: (index: number) => string;
    viewOnly?: boolean;
}

export const FormTableRow: React.FC<FormTableRowProps> = ({
    columns,
    rowData,
    schema,
    viewOnly,
    path,
    config,
    ...actionProps
}) => {
    const actions = useCollectionItemActions(schema.custom?.actions);
    const { antragStatus } = useFormState();
    const { hasRole } = useAuthHelper();

    return (
        <TableRow>
            <TableCell>{rowData.hasErrors && <MissingRequiredFieldsIcon />}</TableCell>
            {columns.map((col, i) => (
                <FormTableCell key={i} column={col} schema={schema} rowData={rowData} path={path} config={config} />
            ))}
            <TableCell>
                {STATUS.IN_PRUEFUNG === antragStatus ? (
                    <FormTableActions
                        viewable={true}
                        editable={false}
                        deletable={false}
                        actions={[]}
                        {...actionProps}
                    />
                ) : (
                    <FormTableActions
                        viewable={rowData.beschieden || viewOnly}
                        editable={(!rowData.beschieden || hasRole(ROLES.LV)) && !viewOnly}
                        deletable={
                            (!rowData.beschieden || hasRole(ROLES.LV)) &&
                            !viewOnly &&
                            schema.custom?.deleteable !== false
                        }
                        actions={actions}
                        {...actionProps}
                    />
                )}
            </TableCell>
        </TableRow>
    );
};
