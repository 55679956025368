import { ReactElement } from 'react';
import { MenuItem } from '@mui/material';

import { useAuthHelper } from 'api/auth/useAuthHelper';
import { BenutzerResponse } from 'api/types';
import { ActionDropdown } from 'components/DataTableHydra/ActionDropdown/ActionDropdown';
import { BenutzerTableActions } from 'constants/benutzerActions';
import { ROLES } from 'constants/roles';

interface BenutzerListActionsProps {
    allowBenutzerBearbeiten: boolean;
    allowBenutzerDeaktivieren: boolean;
    benutzer: BenutzerResponse;
    handleAction: (action: BenutzerTableActions, benutzer: BenutzerResponse) => void;
}

export const BenutzerListActions = ({
    allowBenutzerBearbeiten,
    allowBenutzerDeaktivieren,
    benutzer,
    handleAction,
}: BenutzerListActionsProps): ReactElement => {
    const { hasRole, getCurrentBenutzer } = useAuthHelper();
    const isDeactivated = Boolean(benutzer.deaktiviertAt);

    if (getCurrentBenutzer()?.benutzerId === benutzer.id) return <></>;
    if (hasRole(ROLES.LV_ADMIN) && !benutzer.roles?.includes(ROLES.LV)) return <></>;
    return (
        <ActionDropdown>
            {allowBenutzerBearbeiten && (
                <MenuItem onClick={() => handleAction(BenutzerTableActions.BENUTZER_BEARBEITEN, benutzer)}>
                    Bearbeiten
                </MenuItem>
            )}
            {allowBenutzerDeaktivieren && (
                <MenuItem onClick={() => handleAction(BenutzerTableActions.BENUTZER_TOGGLE_AKTIVIEREN, benutzer)}>
                    {isDeactivated ? 'Aktivieren' : 'Deaktivieren'}
                </MenuItem>
            )}
        </ActionDropdown>
    );
};
