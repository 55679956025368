declare module '@mui/material/styles/createMixins' {
    interface Mixins {
        header: {
            height: number;
        };
        footer: {
            height: number;
        };
        sidebar: {
            width: number;
        };
        document: {
            width: number;
        };
        content: {
            width: number;
        };
    }

    interface MixinsOptions {
        header?: {
            height?: number;
        };
        footer?: {
            height?: number;
        };
        sidebar?: {
            width?: number;
        };
        document?: {
            width?: number;
        };
        content?: {
            width?: number;
        };
    }
}

export const mixins = {
    header: {
        height: 54,
    },
    footer: {
        height: 45,
    },
    sidebar: {
        width: 240,
    },
    content: {
        width: 1180 - 240,
    },
    document: {
        width: 1180,
    },
};
