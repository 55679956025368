import React, { useEffect, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Alert, Typography } from '@mui/material';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';

import { useAuthHelper } from 'api/auth/useAuthHelper';
import { DataTableHydra } from 'components/DataTableHydra/DataTableHydra';
import { FormButton } from 'forms/components/FormButton';
import { FormState, Schema } from 'forms/types/UiSchemaTypes';

import { FormModal } from './FormModal';

interface FormProps {
    title: string;
    resetData: FormState;
    converter: (data: FormState) => FormState;
    schema: Schema;
    endpoint: string;
    role?: string | string[];
    openModal?: boolean | null;
}

interface TableProps {
    columns: MUIDataTableColumnDef[];
    options: MUIDataTableOptions;
    callback: any;
    callbackType?: number | string;
}

interface ContentProps {
    headline: string;
    info?: string;
}

interface UpdateableFormTableProps {
    form?: FormProps;
    table: TableProps;
    content: ContentProps;
}

export const UpdateableFormTable: React.FC<UpdateableFormTableProps> = ({ form, table, content }) => {
    const { hasRole } = useAuthHelper();

    const [modal, setModal] = useState(form?.openModal || false);
    const [formData, setFormData] = useState<FormState | undefined>(undefined);
    const [refreshToken, setRefreshToken] = useState(0);

    useEffect(() => {
        if (form) {
            setFormData(form.resetData);
        }
    }, [form]);

    const { isLoading, data } = table.callback({
        typ: table.callbackType,
        refreshToken: refreshToken,
    });

    const openModal = () => setModal(true);

    return (
        <>
            <Typography variant="h1">{content.headline}</Typography>

            {form && hasRole(form.role) && (
                <>
                    <p>
                        <FormButton variant="contained" color="primary" onClick={openModal}>
                            <AddCircleIcon /> Neu hinzufügen
                        </FormButton>
                    </p>
                    <FormModal
                        open={modal}
                        form={form}
                        data={formData}
                        onChange={setFormData}
                        setModal={setModal}
                        setRefreshToken={setRefreshToken}
                    />
                </>
            )}

            {content.info && <Alert severity="info">{content.info}</Alert>}

            <DataTableHydra data={data} isLoading={isLoading} columns={table.columns} options={table.options} />
        </>
    );
};
