import { AntragType } from 'api/antragTypes';
import { AntragVersionCollectionResponse } from 'api/types';
import { BESCHEID_DOKUMENT } from 'constants/bescheidDokument';
import { determinePathForAntragType } from 'navigation/Paths';
import { getAbsoluteBackendPath } from 'utilities/Utils';

export const openZusammenfassung = (type: AntragType, antrag: Antrag, version?: AntragVersionCollectionResponse) =>
    openPDF(type, antrag, BESCHEID_DOKUMENT.ZUSAMMENFASSUNG, version);

type Antrag = { id: number | string };
const openPDF = (
    type: AntragType,
    antrag: Antrag,
    docType: BESCHEID_DOKUMENT,
    version: AntragVersionCollectionResponse | undefined
) => {
    const versionQuery = version && version.typ !== 'CURRENT' ? `?versionId=${version.id}` : '';
    window.open(
        getAbsoluteBackendPath(
            `files/${determinePathForAntragType(type)}/${String(antrag.id)}/dokument/${docType}/pdf${versionQuery}`
        ),
        '_blank'
    );
};
