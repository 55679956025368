import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import MaterialLink, { LinkProps } from '@mui/material/Link';

interface LinkPropsInterface extends LinkProps {
    to?: string;
}

export const Link = React.forwardRef<HTMLAnchorElement, LinkPropsInterface>(
    ({ href, to, children, ...props }: LinkPropsInterface, ref) => {
        if (to) {
            return (
                <MaterialLink ref={ref} component={RouterLink} to={to} href={href} {...props}>
                    {children}
                </MaterialLink>
            );
        }

        return (
            <MaterialLink ref={ref} href={href} {...props}>
                {children || href?.replace(/^https?:\/\//, '')}
            </MaterialLink>
        );
    }
);
