import { useCallback, useMemo, useRef, useState } from 'react';

import { backendApiService } from 'api/ApiService';
import { useAuth } from 'api/AuthContext';
import { EinrichtungResponse } from 'api/types';
import { ROLES } from 'constants/roles';

import { JwtPayload } from './useAuthContextJwtToken';

interface AuthHelperInterface {
    refreshJwtToken: () => Promise<void>;
    refreshInProgress: boolean;
    getCurrentBenutzer: () => JwtPayload | undefined;
    hasRole: (roles?: string[] | string) => boolean;
    hasRolle: (...rollen: string[]) => boolean;
    getRoles: () => string[];
    expiresIn: () => number | undefined;
    isAntragsteller: (einrichtung: EinrichtungResponse) => Promise<boolean>;
    isImpersonated: () => boolean;
    isSignedIn: () => boolean;
    toggleLandschaftsverbandView: () => void;
    isLandschaftsverbandView: boolean;
}

export const useAuthHelper = (): AuthHelperInterface => {
    const { jwt, handleJwtToken, getImpersonatedBenutzer, setLandschaftsverbandView, isLandschaftsverbandView } =
        useAuth();
    const impersonatedBenutzer = getImpersonatedBenutzer();

    const refreshInProgress = useRef<boolean>(false);

    const refreshJwtToken = useCallback(async (): Promise<void> => {
        if (!jwt.refreshToken) return Promise.resolve();
        try {
            refreshInProgress.current = true;
            const response = await backendApiService.getRefreshToken();
            await handleJwtToken(response.token, response.refresh_token);
        } finally {
            refreshInProgress.current = false;
        }
    }, [jwt, handleJwtToken]);

    const getCurrentBenutzer = useMemo(() => {
        return (): JwtPayload | undefined => {
            return impersonatedBenutzer ? impersonatedBenutzer : jwt.payload;
        };
    }, [impersonatedBenutzer, jwt]);

    const hasRole = useCallback(
        (roles?: string[] | string): boolean => {
            const currentBenutzer = getCurrentBenutzer();
            if (!currentBenutzer?.roles) {
                return false;
            }

            if (undefined === roles) {
                return true;
            }

            if ('string' === typeof roles) {
                if (ROLES.USER === roles && jwt.isLoggedIn) {
                    return true;
                }

                return -1 !== currentBenutzer.roles.indexOf(roles);
            }

            return roles.some((role) => hasRole(role));
        },
        [getCurrentBenutzer, jwt]
    );

    const isAntragsteller = useCallback(
        (einrichtung: EinrichtungResponse): Promise<boolean> => {
            if (hasRole(ROLES.ANBIETER)) {
                const currentBenutzer = getCurrentBenutzer();
                return backendApiService
                    .getEinrichtungBenutzerCollection(
                        1,
                        1,
                        String(currentBenutzer?.benutzerId),
                        undefined,
                        String(einrichtung.id)
                    )
                    .then((response) => {
                        return response['hydra:member']?.length ? response['hydra:member'][0].isAntragsteller : false;
                    });
            } else {
                return new Promise<boolean>((resolve) => {
                    resolve(false);
                });
            }
        },
        [getCurrentBenutzer, hasRole]
    );

    const getRoles = (): string[] => {
        const currentBenutzer = getCurrentBenutzer();

        if (!jwt.isLoggedIn) {
            return [];
        }

        if (!currentBenutzer?.roles) {
            return [];
        }

        return currentBenutzer.roles;
    };

    const expiresIn = useCallback(
        (): number | undefined =>
            undefined !== jwt.payload?.exp ? jwt.payload?.exp - new Date().getTime() / 1000 : undefined,
        [jwt.payload]
    );

    const isImpersonated = (): boolean => {
        return impersonatedBenutzer !== undefined;
    };

    const isSignedIn = (): boolean => {
        return getCurrentBenutzer() !== undefined;
    };

    const hasRolle = (...rollen: string[]): boolean => {
        const rolle = jwt.payload?.rolle;
        return Boolean(rolle && rollen.includes(rolle));
    };

    const toggleLandschaftsverbandView = () => {
        setLandschaftsverbandView(!isLandschaftsverbandView);
    };

    return {
        refreshJwtToken,
        refreshInProgress: refreshInProgress.current,
        getCurrentBenutzer,
        hasRole,
        hasRolle,
        getRoles,
        expiresIn,
        isAntragsteller,
        isImpersonated,
        isSignedIn,
        toggleLandschaftsverbandView,
        isLandschaftsverbandView,
    };
};

export const useCurrentBenutzerId = (): number[] => {
    const { getCurrentBenutzer } = useAuthHelper();
    const [benutzerId] = useState(() => {
        const id = getCurrentBenutzer()?.benutzerId;
        return id ? [id] : [];
    });
    return benutzerId;
};
