import { ROLES } from 'constants/roles';
import { MenuInterface } from 'layout/HeaderConfig';
import { InvestPathBuilder } from 'navigation/Paths';

export const menuAntraege: MenuInterface[] = [
    {
        path: InvestPathBuilder.home.antraege.feststellung.path,
        name: 'Anträge auf Feststellung',
    },
    {
        path: InvestPathBuilder.home.antraege.festsetzung.path,
        name: 'Anträge auf Festsetzung',
    },
    {
        path: InvestPathBuilder.home.antraege.investorenmodell.path,
        name: 'Anträge nach § 8 Abs. 11',
    },
    {
        path: InvestPathBuilder.home.antraege.meldungen_p29.path,
        name: 'Meldungen nach § 29',
    },
];

export const menuFeststellungsantrag = (antragMenus: MenuInterface[]): MenuInterface[] => [
    menuAntraege[0],
    {
        name: 'Feststellungsantrag',
        expanded: true,
        menus: antragMenus,
    },
    menuAntraege[1],
    menuAntraege[2],
    menuAntraege[3],
];

export const defaultMenusForFeststellungsantrag = (id: string): MenuInterface[] => [
    {
        path: InvestPathBuilder.home.antraege.feststellung.edit(id, 'basisdaten'),
        name: 'Basisdaten',
        disabled: true,
        roles: [ROLES.ANBIETER],
    },
    {
        path: InvestPathBuilder.home.antraege.feststellung.edit(id, 'nrfUndGrundstueck'),
        name: 'NRF & Grundstück',
        disabled: true,
        roles: [ROLES.ANBIETER],
    },
    {
        path: InvestPathBuilder.home.antraege.feststellung.edit(id, 'ausnahmen'),
        name: 'Ausnahmen',
        disabled: true,
        roles: [ROLES.ANBIETER],
    },
    {
        path: InvestPathBuilder.home.antraege.feststellung.edit(id, 'massnahmen'),
        name: 'Maßnahmen',
        disabled: true,
        roles: [ROLES.ANBIETER],
    },
    {
        path: InvestPathBuilder.home.antraege.feststellung.edit(id, 'salg'),
        name: 'SALG',
        disabled: true,
        roles: [ROLES.ANBIETER],
    },
    {
        path: InvestPathBuilder.home.antraege.feststellung.edit(id, 'dokumente'),
        name: 'Dokumente',
        disabled: true,
        roles: [ROLES.ANBIETER],
    },
    {
        path: InvestPathBuilder.home.antraege.feststellung.edit(id, 'zusammenfassung'),
        name: 'Zusammenfassung',
        disabled: true,
        roles: [ROLES.ANBIETER],
    },
    {
        path: InvestPathBuilder.home.antraege.feststellung.edit(id, 'absenden'),
        name: 'Absenden',
        disabled: true,
        roles: [ROLES.ANBIETER],
    },

    {
        path: InvestPathBuilder.home.antraege.feststellung.edit(id, 'antragsdaten'),
        name: 'Antragsdaten',
        disabled: true,
        roles: [ROLES.LV],
    },
    {
        path: InvestPathBuilder.home.antraege.feststellung.edit(id, 'basisdaten'),
        name: 'Basisdaten',
        disabled: true,
        roles: [ROLES.LV],
    },
    {
        path: InvestPathBuilder.home.antraege.feststellung.edit(id, 'platzzahlen'),
        name: 'Platzzahlen',
        disabled: true,
        roles: [ROLES.LV],
    },
    {
        path: InvestPathBuilder.home.antraege.feststellung.edit(id, 'nrfLv'),
        name: 'NRF',
        disabled: true,
        roles: [ROLES.LV],
    },
    {
        path: InvestPathBuilder.home.antraege.feststellung.edit(id, 'grundstueckLv'),
        name: 'Grundstück',
        disabled: true,
        roles: [ROLES.LV],
    },
    {
        path: InvestPathBuilder.home.antraege.feststellung.edit(id, 'ausnahmeregelungenLv'),
        name: 'Ausnahmeregelungen',
        disabled: true,
        roles: [ROLES.LV],
    },
    {
        path: InvestPathBuilder.home.antraege.feststellung.edit(id, 'aufwandLalgLv'),
        name: 'Aufwand LALG',
        disabled: true,
        roles: [ROLES.LV],
    },
];

export const menuFestsetzungsantrag = (antragMenus: MenuInterface[]): MenuInterface[] => [
    menuAntraege[0],
    menuAntraege[1],
    {
        name: 'Festsetzungsantrag',
        expanded: true,
        menus: antragMenus,
    },
    menuAntraege[2],
    menuAntraege[3],
];

export const defaultFestsetzungsantragSteps = (id: string): MenuInterface[] => [
    {
        path: InvestPathBuilder.home.antraege.festsetzung.edit(id, 'basisdaten'),
        name: 'Basisdaten',
        disabled: true,
        roles: [ROLES.ANBIETER],
    },
];

export const menuInvestorenmodell = (antragMenus: MenuInterface[]): MenuInterface[] => [
    menuAntraege[0],
    menuAntraege[1],
    menuAntraege[2],
    {
        name: 'Antrag nach § 8 Abs. 11',
        expanded: true,
        menus: antragMenus,
    },
    menuAntraege[3],
];

export const menuMeldungP29 = (antragMenus: MenuInterface[]): MenuInterface[] => [
    ...menuAntraege,
    {
        name: 'Meldung nach § 29',
        expanded: true,
        menus: antragMenus,
    },
];

export const defaultInvestorenmodellSteps = (id: string): MenuInterface[] => [
    {
        path: InvestPathBuilder.home.antraege.investorenmodell.edit(id, 'basisdaten'),
        name: 'Basisdaten',
        disabled: true,
        roles: [ROLES.ANBIETER],
    },
    {
        path: InvestPathBuilder.home.antraege.investorenmodell.edit(id, 'darlehen'),
        name: 'Darlehen',
        disabled: true,
        roles: [ROLES.ANBIETER],
    },
    {
        path: InvestPathBuilder.home.antraege.investorenmodell.edit(id, 'dokumente'),
        name: 'Dokumente',
        disabled: true,
        roles: [ROLES.ANBIETER],
    },
    {
        path: InvestPathBuilder.home.antraege.investorenmodell.edit(id, 'zusammenfassung'),
        name: 'Zusammenfassung',
        disabled: true,
        roles: [ROLES.ANBIETER],
    },
    {
        path: InvestPathBuilder.home.antraege.investorenmodell.edit(id, 'absenden'),
        name: 'Absenden',
        disabled: true,
        roles: [ROLES.ANBIETER],
    },
    {
        path: InvestPathBuilder.home.antraege.investorenmodell.edit(id, 'antragsdaten'),
        name: 'Zusammenfassung',
        disabled: true,
        roles: [ROLES.LV],
    },
    {
        path: InvestPathBuilder.home.antraege.investorenmodell.edit(id, 'basisdaten'),
        name: 'Basisdaten',
        disabled: true,
        roles: [ROLES.LV],
    },
    {
        path: InvestPathBuilder.home.antraege.investorenmodell.edit(id, 'darlehen'),
        name: 'Darlehen',
        disabled: true,
        roles: [ROLES.LV],
    },
    {
        path: InvestPathBuilder.home.antraege.investorenmodell.edit(id, 'eigenkapital'),
        name: 'Eigenkapital',
        disabled: true,
        roles: [ROLES.LV],
    },
    {
        path: InvestPathBuilder.home.antraege.investorenmodell.edit(id, 'gesamtberechnung'),
        name: 'Gesamtberechnung',
        disabled: true,
        roles: [ROLES.LV],
    },
    {
        path: InvestPathBuilder.home.antraege.investorenmodell.edit(id, 'dokumente'),
        name: 'Dokumente',
        disabled: true,
        roles: [ROLES.LV],
    },
    {
        path: InvestPathBuilder.home.antraege.investorenmodell.edit(id, 'bescheiderteilung'),
        name: 'Bescheiderteilung',
        disabled: true,
        roles: [ROLES.LV],
    },
];
