import { PHONE_NUMBER_REGEX } from 'forms/hooks/useFormValidation';
import { Schema } from 'forms/types/UiSchemaTypes';

export const profilSchema: Schema = {
    $id: 'Benutzer',
    type: 'object',
    required: ['vorname', 'nachname', 'telefon', 'fax'],
    properties: {
        password: {
            $id: 'password',
            type: 'string',
            title: 'Passwort',
            //@ts-ignore
            custom: {
                block_prefixes: ['password_widget'],
            },
        },
        anrede: {
            $id: 'anrede',
            type: 'string',
            title: 'Titel',
        },
        vorname: {
            $id: 'vorname',
            type: 'string',
            title: 'Vorname',
        },
        nachname: {
            $id: 'nachname',
            type: 'string',
            title: 'Nachname',
        },
        email: {
            $id: 'email',
            type: 'string',
            title: 'E-Mail',
            pattern: '',
            //@ts-ignore
            custom: {
                block_prefixes: ['email_profile'],
                disabled: true,
            },
        },
        telefon: {
            $id: 'telefon',
            type: 'string',
            title: 'Telefon',
            pattern: PHONE_NUMBER_REGEX,
        },
        fax: {
            $id: 'fax',
            type: 'string',
            title: 'Fax',
            pattern: PHONE_NUMBER_REGEX,
        },
    },
};
