import { customVerticalLayout } from 'forms/AntragForm/ui-schemas/elements/customVerticalLayout';
import { formDialog } from 'forms/AntragForm/ui-schemas/elements/formDialog';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { info } from 'forms/AntragForm/ui-schemas/elements/info';
import { labeled } from 'forms/AntragForm/ui-schemas/elements/labeled';
import { UiSchemaType } from 'forms/types/UiSchemaTypes';
import { filterElementsByAction } from 'forms/utils/UiSchemaUtils';

export enum FINANZIERUNGS_ACTIONS {
    UMSCHULDUNG = 'Umschuldung',
    AENDERUNG_ZINSBINDUNG = 'AenderungZinsbindung',
}

const fremdkapitalElements: UiSchemaType[] = [
    {
        type: 'Group',
        elements: [
            {
                type: 'Control',
                scope: '#/properties/field273',
            },
        ],
    },
    {
        type: 'Group',
        elements: [
            {
                type: 'FormExtendButton',
                label: 'Finanzierung weiterer Maßnahme aus diesem Nominalkapital hinzufügen',
                scope: '#/properties/fremdkapitalAnteile',
                uiSchema: {
                    type: 'CustomVerticalLayout',
                    elements: [
                        {
                            type: 'Control',
                            scope: '#/properties/field251',
                            options: {
                                firstControl: true,
                            },
                        },
                        {
                            type: 'Control',
                            scope: '#/properties/field273a',
                        },
                    ],
                },
            },
        ],
    },
    {
        type: 'Group',
        elements: [
            {
                type: 'Control',
                scope: '#/properties/field273c',
            },
            {
                type: 'Control',
                scope: '#/properties/field270',
            },
            info('Das Datum des Darlehensvertrags gibt an, wann der Vertrag geschlossen wurde.'),
            {
                type: 'Control',
                scope: '#/properties/field271',
            },
            {
                type: 'Control',
                scope: '#/properties/field270d',
            },
            {
                type: 'Control',
                scope: '#/properties/field272',
            },
            {
                type: 'Control',
                scope: '#/properties/field272a',
            },
            {
                type: 'Control',
                scope: '#/properties/field274',
            },
            {
                type: 'Control',
                scope: '#/properties/field275',
            },
            info('Der Beginn des Darlehensvertrags gibt an, ab wann der Darlehensbetrag bereitgestellt wurde.'),
            {
                type: 'Control',
                scope: '#/properties/field276',
            },
            {
                type: 'Control',
                scope: '#/properties/field277',
            },
            {
                type: 'Control',
                scope: '#/properties/field281',
            },
            {
                type: 'Control',
                scope: '#/properties/field282',
            },
        ],
    },
    {
        type: 'Group',
        elements: [
            {
                actionKeys: [null, FINANZIERUNGS_ACTIONS.AENDERUNG_ZINSBINDUNG],
                // @ts-ignore
                type: 'Info',
                message:
                    'Bitte geben Sie nur die Finanzierungsaufwendungen an, die in den Monaten des Festsetzungszeitraumes tatsächlich gezahlt werden.',
                styles: {
                    marginBottom: 32,
                },
                rule: {
                    // @ts-ignore
                    effect: 'SHOW',
                    condition: {
                        // @ts-ignore
                        scope: '#/properties/fremdkapitalEndetInFestsetzungszeitraum',
                        schema: { const: true },
                    },
                },
            },
            {
                // @ts-ignore
                type: 'ControlsOfElements',
                elementScope: '#/properties/aufwandFremdkapital',
                controlScope: '#/properties/field540',
            },
            {
                // @ts-ignore
                type: 'Control',
                scope: '#/properties/field540d',
            },
            {
                // @ts-ignore
                type: 'Control',
                scope: '#/properties/field540e',
            },
        ],
    },
    {
        actionKey: FINANZIERUNGS_ACTIONS.UMSCHULDUNG,
        type: 'Info',
        message:
            'Bitte denken Sie daran, die neue Finanzierung  (Eigenkapital oder Fremdkapital) zur Umschuldung zu erfassen.',
    },
];

export const modalFremdkapital = (actionKey: string | null = null, editDescription?: string) =>
    formDialog(
        'Neue Finanzierung Fremdkapital',
        `Finanzierung Fremdkapital ${editDescription ? '- ' + editDescription : ''}`,
        labeled({ labelScope: '#/properties/field270' }),
        customVerticalLayout(filterElementsByAction(fremdkapitalElements, actionKey)),
        {
            scope: '#/properties/fremdkapital',
            actionKey,
        }
    );

export const modalEigenkapital = formDialog(
    'Neue Finanzierung Eigenkapital',
    'Finanzierung Eigenkapital',
    labeled({ labelScope: '#/properties/field251' }),
    customVerticalLayout([
        {
            type: 'Group',
            elements: [
                {
                    type: 'Control',
                    scope: '#/properties/field251',
                },
                {
                    type: 'Control',
                    scope: '#/properties/field260',
                },
                {
                    type: 'Control',
                    scope: '#/properties/field261',
                },
            ],
        },
    ]),
    { scope: '#/properties/eigenkapital', actionKey: null }
);

export const modalLeasing = formDialog(
    'Neue Finanzierung Leasing',
    'Finanzierung Leasing',
    labeled({ labelScope: '#/properties/field1427' }),
    customVerticalLayout([
        group([
            {
                type: 'Control',
                scope: '#/properties/field1427',
            },
            {
                type: 'ControlsOfElements',
                elementScope: '#/properties/aufwandLeasing',
                controlScope: '#/properties/field541',
            },
            {
                type: 'Control',
                scope: '#/properties/field541d',
            },
        ]),
    ]),
    {
        scope: '#/properties/leasing',
        actionKey: null,
    }
);
