import React, { ElementType } from 'react';
import { Close } from '@mui/icons-material';
import { Alert, Box, Button, IconButton, styled, Tooltip, Typography } from '@mui/material';

import { Message } from 'forms/state/useMessages';

export const MessageAlert: React.FC<{
    message: Message;
    closeMessage: (uuid: string) => void;
}> = ({ message, closeMessage }) => {
    return (
        <Alert severity={message.type}>
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                }}
            >
                <Box>
                    <MessageTypography color={message.type === 'error' ? 'error' : 'textPrimary'} component="p">
                        <span dangerouslySetInnerHTML={{ __html: message.text }} />
                    </MessageTypography>
                </Box>

                {message.action && (
                    <Button variant="outlined" size="small" onClick={() => message.action?.actionMethod(message.uuid)}>
                        {message.action.actionTitle}
                    </Button>
                )}

                {message.closable && (
                    <Tooltip title="Schließen">
                        <IconButton
                            size="small"
                            onClick={() => closeMessage(message.uuid)}
                            aria-label="Nachricht verbergen"
                        >
                            <Close fontSize="small" />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
        </Alert>
    );
};

const MessageTypography = styled(Typography)<{ component?: ElementType }>(() => ({
    whiteSpace: 'pre-wrap',
}));
