import { useCallback } from 'react';
import { Typography } from '@mui/material';

import { AntragType } from 'api/antragTypes';
import { backendApiService } from 'api/ApiService';
import { useAuthHelper } from 'api/auth/useAuthHelper';
import { MeldungP29CollectionListResponse } from 'api/types';
import { useColumnsMeldungP29 } from 'components/DataTableHydra/Columns/MeldungP29';
import { DataTableHydraServerside } from 'components/DataTableHydra/DataTableHydraServerside';
import { GetData } from 'components/DataTableHydra/hooks/useTableData';
import { ROLES } from 'constants/roles';
import { ContentContainer } from 'layout/container/ContentContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { withAuthorization } from 'navigation/withAuthorization';
import { useAntragFilterOptions } from 'pages/Antraege/components/useAntragFilterOptions';
import { useMeldungP29ListActions } from 'pages/Antraege/MeldungP29/useMeldungP29ListActions';

import { AUFGABEN, AufgabenMenu } from './config';

const MeldungP29Bearbeiten = () => {
    const { handleAction, serverDataChanged } = useMeldungP29ListActions(AntragType.MELDUNG_P29);
    const getData = useMeldungP29AufgabenData();
    const filterOptions = useAntragFilterOptions(AntragType.MELDUNG_P29);
    const getColumns = useColumnsMeldungP29(handleAction, filterOptions);

    return (
        <ContentContainer title="Meldungen nach § 29 APG DVO zur Kenntnis nehmen">
            <Typography variant="h1">Meldungen nach § 29 APG DVO zur Kenntnis nehmen</Typography>

            <DataTableHydraServerside
                getColumns={getColumns}
                getData={getData}
                initialOptions={{
                    filter: false,
                    searchPlaceholder: 'Suche nach Einrichtungsname, Aktenzeichen oder NRW-Schlüssel',
                    sortOrder: {
                        name: 'zuletztEingereichtAt',
                        direction: 'desc',
                    },
                }}
                serverDataChanged={serverDataChanged}
            />
        </ContentContainer>
    );
};

const useMeldungP29AufgabenData = (): GetData<MeldungP29CollectionListResponse> => {
    const { getCurrentBenutzer, isLandschaftsverbandView } = useAuthHelper();
    const benutzerId = getCurrentBenutzer()?.benutzerId;
    return useCallback(
        ({ page, rowsPerPage, searchText, sortOrder }) => {
            return backendApiService.getMeldungP29Collection({
                page,
                itemsPerPage: rowsPerPage,
                qSearch: searchText ? [searchText] : undefined,
                zustaendigerBenutzer: !isLandschaftsverbandView && benutzerId ? [benutzerId] : undefined,
                aufgabe: AUFGABEN.BEARBEITEN,
                orderEinrichtungAktenzeichen: sortOrder?.name === 'aktenzeichen' ? sortOrder.direction : undefined,
                orderEinrichtungName: sortOrder?.name === 'einrichtung' ? sortOrder.direction : undefined,
                orderStatus: sortOrder?.name === 'status' ? sortOrder.direction : undefined,
                orderMeldedatum: sortOrder?.name === 'meldedatum' ? sortOrder.direction : undefined,
                orderGueltigAb: sortOrder?.name === 'gueltigAb' ? sortOrder.direction : undefined,
            });
        },
        [isLandschaftsverbandView, benutzerId]
    );
};

export const MeldungP29BearbeitenPage = withAuthorization(withSideBar(MeldungP29Bearbeiten, AufgabenMenu), [
    ROLES.FACHADMIN,
    ROLES.SUPPORT,
    ROLES.LV,
]);
