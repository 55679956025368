import React, { useEffect, useState } from 'react';
import { JsonForms } from '@jsonforms/react';
import { Grid, Typography } from '@mui/material';
import { isEqual } from 'lodash';

import { backendApiService } from 'api/ApiService';
import { BenutzerCredentialChangeRequestInput } from 'api/client';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { Dialog } from 'components/Dialog/Dialog';
import { SupportInfo } from 'elements/SupportInfo';
import { FormButton } from 'forms/components/FormButton';
import { Pflichtfeld } from 'forms/components/Pflichtfeld';
import { useFormValidation } from 'forms/hooks/useFormValidation';
import { renderers } from 'forms/renderers';
import { useMessageActions } from 'forms/state/useMessages';
import { FormLayoutConfig, FormStateChange, Schema } from 'forms/types/UiSchemaTypes';
import { errorMessage } from 'forms/utils/MessageUtils';

interface PasswordResetProps {
    open: boolean;
    onClose: () => void;
}

const defaultFormData = {
    email: '',
};

export const PasswordResetRequest = ({ open, onClose }: PasswordResetProps) => {
    const { addMessage } = useMessageActions();
    const [isSubmitting, setSubmitting] = useState<boolean>(false);
    const [dailogTitle, setDailogTitle] = useState<string>('Passwort zurücksetzen');
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [formData, setFormData] = useState<BenutzerCredentialChangeRequestInput>(defaultFormData);

    useEffect(() => {
        setShowDialog(open);
    }, [open, setShowDialog]);

    const closeModal = () => {
        onClose();
        setShowDialog(false);
        setFormData(defaultFormData);
        setTimeout(() => {
            setSuccess(false);
        });
    };

    const handleChange = (data: FormStateChange) => {
        if (!isEqual(formData, data.data)) {
            setFormData(data.data);
        }
    };

    const handleSave = async () => {
        if (isSubmitting) return;
        try {
            setSubmitting(true);
            await backendApiService.resetPasswordRequest(formData as BenutzerCredentialChangeRequestInput);
            setSuccess(true);
            setDailogTitle('E-Mail erfolgreich versandt');
        } catch (e) {
            setSuccess(false);
            addMessage(errorMessage('E-Mail konnte nicht versandt werden.', { autoCloseSeconds: 8 }));
        } finally {
            setSubmitting(false);
        }
    };

    const configLayout: FormLayoutConfig = {
        gridLayout: {
            label: 2,
            input: 4,
        },
    };

    const schema: Schema = {
        required: ['email'],
        properties: {
            email: {
                $id: 'email',
                type: 'string',
                title: 'E-Mail-Adresse',
            },
        },
    };

    const uiSchema = {
        type: 'CustomVerticalLayout',
        elements: [
            {
                type: 'Group',
                options: {
                    noSeparator: true,
                },
                gridLayout: {
                    label: 3,
                    input: 5,
                },
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/email',
                        options: {
                            focus: true,
                        },
                    },
                ],
            },
        ],
    };

    const { valid } = useFormValidation(schema, formData);

    return (
        <Dialog
            title={dailogTitle}
            open={showDialog}
            onClose={closeModal}
            wrapActions
            actions={
                <>
                    <Grid container spacing={1} direction={'row-reverse'}>
                        {!success && (
                            <Grid item>
                                <FormButton variant="contained" color="primary" onClick={handleSave} disabled={!valid}>
                                    Passwort zurücksetzen
                                </FormButton>
                            </Grid>
                        )}

                        <Grid item>
                            <FormButton variant="outlined" color="primary" onClick={closeModal}>
                                Schließen
                            </FormButton>
                        </Grid>
                    </Grid>
                    <SupportInfo />
                </>
            }
        >
            <>
                <AppLoaderContainer isLoading={isSubmitting}>
                    {success && (
                        <>
                            <Typography paragraph>
                                Sofern ihr Benutzer aktiv ist und die eingegebene E-Mail-Adresse in unserem System
                                vorhanden ist, erhalten Sie in den nächsten Minuten eine E-Mail mit weiteren
                                Instruktionen.
                            </Typography>

                            <Typography paragraph>Bitte prüfen Sie auch Ihren SPAM-Ordner.</Typography>
                        </>
                    )}

                    {!success && (
                        <>
                            <Grid container>
                                <Grid item xs={10}>
                                    <Typography paragraph>
                                        Geben Sie hier Ihre E-Mail-Adresse an, mit der Sie im System registriert sind.
                                        Wir senden Ihnen anschließend eine E-Mail mit einem Link zu, mit dem Sie Ihr
                                        Passwort neu setzen können.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Pflichtfeld>Pflichtfeld *</Pflichtfeld>
                            <JsonForms
                                data={formData}
                                schema={schema}
                                uischema={uiSchema}
                                onChange={handleChange}
                                renderers={renderers}
                                config={configLayout}
                            />
                        </>
                    )}
                </AppLoaderContainer>
            </>
        </Dialog>
    );
};
