import { Navigate } from 'react-router-dom';
import { Typography } from '@mui/material';

import { useAuthHelper } from 'api/auth/useAuthHelper';
import { ROLES } from 'constants/roles';
import { ContentContainer } from 'layout/container/ContentContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { InvestPathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

import { DashboardAnbieterPage } from './Anbieter/DashboardAnbieter';
import { DashboardLandschaftsverbandPage } from './Landschaftsverband/DashboardLv';
import { DashboardMagsPage } from './Ministerium/DashboardMags';

const Index = () => {
    const { hasRole } = useAuthHelper();

    switch (true) {
        case hasRole(ROLES.ANBIETER):
            return <DashboardAnbieterPage />;
        case hasRole(ROLES.LV):
            return <DashboardLandschaftsverbandPage />;
        case hasRole(ROLES.MINISTERIUM):
            return <DashboardMagsPage />;
        case hasRole(ROLES.SUPPORT):
            return <Navigate to={InvestPathBuilder.home.verwaltung.benutzer.path} replace />;
        default:
            return (
                <ContentContainer title="Dashboard">
                    <Typography component={'h1'}>TODO</Typography>
                </ContentContainer>
            );
    }
};

export const DashboardPage = withAuthorization(withSideBar(Index, []), [ROLES.USER]);
