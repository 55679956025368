import * as React from 'react';
import { createRef, CSSProperties, MutableRefObject, useEffect } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { IconButton, styled, Tooltip } from '@mui/material';

import { BACKEND_API_BASE_URL } from 'api/ApiService';
import { getFileExtension, getReadableFileSize } from 'utilities/Utils';

export type UploadedDocumentFile = {
    contentUrl?: string | null;
    originalName?: string | null;
    fileSize?: string | null;
    mimeType?: string | null;
};

interface UploadedDocumentProps {
    fileData: UploadedDocumentFile;
    onRemove?: () => void;
    disabled?: boolean;
    readonly?: boolean;
    style?: CSSProperties;
    autoFocus?: MutableRefObject<boolean>;
}

export const UploadedDocument: React.FC<UploadedDocumentProps> = ({
    fileData,
    onRemove,
    disabled = false,
    readonly = false,
    style,
    autoFocus,
}) => {
    const ref = createRef<HTMLAnchorElement>();
    useEffect(() => {
        if (autoFocus?.current) {
            ref.current?.focus();
            autoFocus.current = false;
        }
    }, [ref, autoFocus]);
    return (
        <ValueContainer style={style}>
            <LinkText
                href={BACKEND_API_BASE_URL + fileData?.contentUrl}
                target="_blank"
                rel="noreferrer"
                download={fileData?.originalName}
                ref={ref}
                aria-label={`${fileData?.originalName} (${getReadableFileSize(
                    Number(fileData?.fileSize)
                )}, ${getFileExtension(fileData?.mimeType)})`}
            >
                {fileData?.originalName}
            </LinkText>

            {!readonly && (
                <Tooltip title="Dokument entfernen">
                    <IconButton
                        size="small"
                        component="a"
                        onClick={onRemove}
                        disabled={disabled}
                        aria-label="Dokument entfernen"
                        aria-disabled={disabled}
                    >
                        <DeleteForeverIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            )}
        </ValueContainer>
    );
};

const ValueContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    minHeight: 40,
}));

const LinkText = styled('a')(() => ({
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
}));
