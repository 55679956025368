import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';

export const uiSchema = verticalLayout([
    group(
        [
            control('field121'),
            control('field1501'),
            control('field1501kv'),
            control('field1503'),
            control('field5504'),
            control('field5504kv'),
        ],
        {}
    ),
]);
