import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { isEqual } from 'lodash';

import { Step } from 'layout/HeaderConfig';

export const useAntragNavigation = (
    sidebarSteps: Step<string>[] | undefined,
    steps: Step<string>[] | undefined,
    stepPathBuilder: (id: string, step?: string) => string,
    backUrl?: string
) => {
    const navigate = useNavigate();
    const { id, step: currentStep } = useParams();
    const [activeSteps, setActiveSteps] = useState<Step<string>[]>([]);
    const [parentStepOfTab, setParentStepOfTab] = useState<Step<string>>();

    useEffect(() => {
        setParentStepOfTab(
            (steps || []).find((s) => s.children?.length && s.children.find((c) => c.step === currentStep))
        );
    }, [steps, currentStep]);

    useEffect(() => {
        const newActiveSteps =
            steps?.flatMap((s) => (s.children?.length ? s.children : [s])).filter((s) => s.state === 'active') || [];

        if (isEqual(activeSteps, newActiveSteps)) {
            return;
        }
        setActiveSteps(newActiveSteps);
    }, [steps, activeSteps]);

    useEffect(() => {
        if (!id || activeSteps.length === 0) return;
        if (!activeSteps.find((s) => s.step === currentStep)) {
            navigate(stepPathBuilder(id));
        }
    }, [activeSteps, currentStep, navigate, id, stepPathBuilder]);

    return useMemo(() => {
        const getActiveStepIndex = (): number => {
            return activeSteps.findIndex((s) => s.step === currentStep);
        };

        const determineNextStep = () => {
            return activeSteps[getActiveStepIndex() + 1]?.step;
        };

        const determinePrevStep = () => {
            return activeSteps[getActiveStepIndex() - 1]?.step;
        };

        const navigateToStep = (step: string) => {
            if (!id) return;
            return navigate(stepPathBuilder(id, step));
        };

        const handleGoNext = () => {
            const newStep = determineNextStep();
            newStep ? navigateToStep(newStep) : navigate('/start');
        };

        const handleGoBack = () => {
            const newStep = determinePrevStep();
            newStep ? navigateToStep(newStep) : navigate(backUrl || '/start');
        };

        const hasNextStep = Boolean(determineNextStep());

        return {
            handleGoNext,
            handleGoBack,
            hasNextStep,
            isTabNavigation: Boolean(parentStepOfTab),
            tabs: parentStepOfTab?.children || [],
        };
    }, [activeSteps, id, navigate, currentStep, stepPathBuilder, parentStepOfTab, backUrl]);
};
