import React from 'react';

import { useAuthHelper } from 'api/auth/useAuthHelper';
import { ROLES } from 'constants/roles';
import { Error404Page } from 'pages/Error404';

export const withAuthorization = (Component: React.FC, roles: string[] = []): React.FC => {
    return (props) => {
        const { hasRole } = useAuthHelper();
        const accessDenied = roles.length && !hasRole(roles);
        if (accessDenied || hasRole(ROLES.PASSWORD_RESET)) {
            return <Error404Page />;
        }
        return <Component {...props} />;
    };
};
