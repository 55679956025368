import React from 'react';
import { LayoutProps, rankWith, uiTypeIs } from '@jsonforms/core';
import { JsonFormsDispatch, withJsonFormsLayoutProps } from '@jsonforms/react';
import { Grid } from '@mui/material';

const CustomHorizontalLayoutComponent: React.FC<LayoutProps> = ({ uischema, ...others }) => {
    return (
        <Grid container spacing={(uischema as any).spacing || 2} style={(uischema as any).style}>
            {(uischema as any).elements?.map((element: any, i: number) => (
                <Grid key={i} item xs={12} md={(uischema as any).gridSize || 6}>
                    <JsonFormsDispatch {...others} uischema={element} />
                </Grid>
            ))}
        </Grid>
    );
};

export const CustomHorizontalLayout = withJsonFormsLayoutProps(CustomHorizontalLayoutComponent);

export const CustomHorizontalLayoutTester = rankWith(1000, (uischema, schema, rootSchema) => {
    return (
        uiTypeIs('CustomHorizontalLayout')(uischema, schema, rootSchema) ||
        uiTypeIs('HorizontalLayout')(uischema, schema, rootSchema)
    );
});
