import { useEffect, useRef } from 'react';

import { backendApiService } from 'api/ApiService';
import { useAuth } from 'api/AuthContext';

import { useAuthHelper } from './useAuthHelper';

export const useSessionRefresh = () => {
    const { jwt } = useAuth();
    const { refreshJwtToken } = useAuthHelper();
    const interceptorId = useRef<number | null>(null);

    useEffect(() => {
        interceptorId.current = backendApiService.setRequestInterceptor(interceptorId.current, refreshJwtToken);
    }, [refreshJwtToken]);

    useEffect(() => {
        if (interceptorId.current && !jwt.isLoggedIn) {
            backendApiService.removeRequestInterceptor(interceptorId.current);
        }
    }, [interceptorId, jwt]);
};
