import { useEffect } from 'react';

import { useAuth } from 'api/AuthContext';
import { useMessageActions } from 'forms/state/useMessages';
import { errorMessage, successMessage, warningMessage } from 'forms/utils/MessageUtils';

import { useSecondsLeft } from './SecondsLeft';

const WARNING_OFFSET_IN_MINUTES = 5;

export const SessionTimeout = () => {
    const { secondsLeft, reset } = useSecondsLeft();
    useSessionTimeoutWarning(secondsLeft, reset);
    useSessionExpiredMessage(secondsLeft);

    return <></>;
};

const useSessionExpiredMessage = (secondsLeft: number) => {
    const { logout } = useAuth();
    const { addMessage, closeMessage } = useMessageActions();

    useEffect(() => {
        if (secondsLeft < 0) {
            logout();
            const id = addMessage(errorMessage('Sitzung abgelaufen', { closable: true }));
            return () => closeMessage(id);
        }
    }, [secondsLeft, logout, addMessage, closeMessage]);
};

const useSessionTimeoutWarning = (secondsLeft: number, reset: () => void) => {
    const { addMessage, closeMessage } = useMessageActions();

    useEffect(() => {
        let messageId: string;
        if (secondsLeft < WARNING_OFFSET_IN_MINUTES * 60 && secondsLeft > 0) {
            messageId = addMessage(
                warningMessage(`Sitzung läuft aus in ${secondsLeft.toFixed()} Sekunden`, {
                    action: {
                        actionTitle: 'Weiterarbeiten',
                        actionMethod: (uuid) => {
                            closeMessage(uuid);
                            reset();
                            addMessage(successMessage('Sitzung aktualisiert', { autoCloseSeconds: 3 }));
                        },
                    },
                })
            );
        }
        return () => {
            messageId && closeMessage(messageId);
        };
    }, [secondsLeft, addMessage, closeMessage, reset]);
};
