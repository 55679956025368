import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';

export const uiSchema = verticalLayout([
    group([
        control('field1052'),
        control('field500'),
        control('field450'),
        control('field451'),
        control('field462'),
        control('field458'),
        control('field459'),
        control('field5601'),
        control('field1011'),
    ]),
]);
