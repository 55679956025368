import { Done, ErrorOutline } from '@mui/icons-material';
import { Box, styled, Typography } from '@mui/material';

const StyledBox = styled(Box)(
    ({ theme }) => `
   display: flex;
   align-items: center;
   margin-bottom: ${theme.spacing(1)};

   & p {
    margin-left: ${theme.spacing(1)};
   }
`
);

interface PasswordStateInfoProps {
    label: string;
    state: boolean;
}

export const FormStateInfo: React.FC<PasswordStateInfoProps> = ({ label, state }) => {
    return (
        <StyledBox>
            {state ? (
                <>
                    <Done style={{ color: 'green' }} fontSize={'small'} />
                    <Typography style={{ color: 'green' }}>{label}</Typography>
                </>
            ) : (
                <>
                    <ErrorOutline color={'error'} fontSize={'small'} />
                    <Typography color={'error'}>{label}</Typography>
                </>
            )}
        </StyledBox>
    );
};
