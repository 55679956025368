import { Alert } from '@mui/material';

import { LinkSupport } from './LinkSupport';

export const SupportInfo = () => {
    return (
        <Alert severity={'info'} style={{ marginTop: '16px' }}>
            Sollten Sie Hilfe benötigen, wenden Sie sich bitte an unseren <LinkSupport />.
        </Alert>
    );
};
