import { AxiosRequestConfig } from 'axios';

import { STATUS } from 'constants/antragStatus';
import { ANTRAG_VERSIONEN_TYP } from 'constants/antragVersionenTyp';

import {
    AbstractGegenpruefungJsonldInvestorenmodellRead,
    AbstractZurueckziehenJsonldFestsetzungsantragList,
    AbstractZurueckziehenJsonldFeststellungsantragList,
    AbstractZurueckziehenJsonldInvestorenmodellList,
    AngemessenheitsgrenzeJsonld,
    BaupreisindexJsonld,
    BenutzerAufgabenOutputJsonldBenutzerAufgaben,
    BenutzerAufgabenOutputJsonldBenutzerLvAufgaben,
    BenutzerBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger,
    BenutzerBenutzerGetAllowedCreateRollenOutputJsonldBenutzerGetAllowedCreateRollen,
    BenutzerBenutzerGetByEmailOutputJsonldBenutzerGetByEmail,
    BenutzerJsonldBenutzerCreate,
    BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles,
    BenutzerJsonldProfileRead,
    BenutzerTablePreferenceJsonld,
    EigenkapitalzinsenBundesbankJsonld,
    EigenkapitalzinsP5Abs6APGDVOJsonld,
    EinrichtungBenutzerJsonldEinrichtungBenutzerCreate,
    EinrichtungBenutzerJsonldEinrichtungBenutzerRead,
    EinrichtungBenutzerJsonldEinrichtungRead,
    EinrichtungCollectionFiltersOutputJsonldCollectionFilters,
    EinrichtungEinrichtungAntragPermissionsOutputJsonldEinrichtungAntragAnbieterPermissions,
    EinrichtungJsonldEinrichtungList,
    EinrichtungJsonldEinrichtungRead,
    FestsetzungsantragFestsetzungsantragJsonJsonldAntragJsonForm,
    FestsetzungsantragFormDiffJsonldAntragFormDiff,
    FestsetzungsantragGegenpruefungGegenpruefungEdit,
    FestsetzungsantragGegenpruefungJsonld,
    FestsetzungsantragJsonldFestsetzungsantragList,
    FestsetzungsantragJsonldFestsetzungsantragRead,
    FestsetzungsantragVersionJsonldFestsetzungsantragVersionRead,
    FeststellungsantragBescheidungFeststellungsantragRead,
    FeststellungsantragFeststellungsantragJsonJsonldAntragJsonForm,
    FeststellungsantragFormDiffJsonldAntragFormDiff,
    FeststellungsantragGegenpruefungGegenpruefungEdit,
    FeststellungsantragGegenpruefungJsonld,
    FeststellungsantragJsonldFeststellungsantragCreate,
    FeststellungsantragJsonldFeststellungsantragList,
    FeststellungsantragJsonldFeststellungsantragRead,
    FeststellungsantragVersionJsonldFeststellungsantragVersionList,
    FeststellungsantragVersionJsonldFeststellungsantragVersionRead,
    FeststellungsantragZurueckziehenZurueckziehenSetEntscheidung,
    FremdkapitalzinsenBundesbankJsonld,
    InvestorenmodellFormDiffAntragFormDiff,
    InvestorenmodellGegenpruefungGegenpruefungEdit,
    InvestorenmodellInvestorenmodellJsonJsonldAntragJsonForm,
    InvestorenmodellJsonldInvestorenmodellCreate,
    InvestorenmodellJsonldInvestorenmodellList,
    InvestorenmodellJsonldInvestorenmodellRead,
    InvestorenmodellVersionJsonldInvestorenmodellVersionRead,
    LandschaftsverbandLandschaftsverbandRead,
    MassenmailJsonldMassenmailRead,
    MeldungP29JsonldMeldungP29Create,
    MeldungP29JsonldMeldungP29List,
    MeldungP29JsonldMeldungP29Read,
    MeldungP29MeldungP29JsonJsonldAntragJsonForm,
    RuhendstellungJsonldEinrichtungRead,
    RuhendstellungRuhendstellungCreate,
    Token as ApiToken,
    TraegerJsonldTraegerRead,
    TraegerverbandJsonldTraegerverbandRead,
    ZustaendigkeitJsonldZustaendigkeitRead,
} from './client';
import { InlineResponse } from './responses/InlineResponse';

export type GetFestsetzungsAntragOptions = {
    page?: number;
    itemsPerPage?: number;
    qSearch?: string[];
    zustaendigerBenutzer?: number[];
    status?: string;
    status2?: string[];
    einrichtung?: string | number;
    einrichtung2?: string[];
    aufgabe?: string;
    orderZuletztEingereichtAt?: 'asc' | 'desc';
    orderField500?: 'asc' | 'desc';
    orderEinrichtungAktenzeichen?: 'asc' | 'desc';
    orderFeststellungsantragBerechnungsart?: 'asc' | 'desc';
    orderEinrichtungName?: 'asc' | 'desc';
    orderStatus?: 'asc' | 'desc';
    orderEinrichtungZustaendigerBenutzer?: 'asc' | 'desc';
    berechnungsart?: string[];
    antragJahr?: string[];
    options?: AxiosRequestConfig;
};

export type GetFeststellungsAntragOptions = {
    page?: number;
    itemsPerPage?: number;
    qSearch?: string[];
    zustaendigerBenutzer?: number[];
    status?: string;
    status2?: string[];
    einrichtung?: string | number;
    einrichtung2?: string[];
    aufgabe?: string;
    orderZuletztEingereichtAt?: 'asc' | 'desc';
    orderField102?: 'asc' | 'desc';
    orderEinrichtungAktenzeichen?: 'asc' | 'desc';
    orderBerechnungsart?: 'asc' | 'desc';
    orderEinrichtungName?: 'asc' | 'desc';
    orderStatus?: 'asc' | 'desc';
    orderEinrichtungZustaendigerBenutzer?: 'asc' | 'desc';
    berechnungsart?: string[];
    antragJahr?: string[];
    options?: AxiosRequestConfig;
};

export type GetMeldungP29AntragOptions = {
    page?: number;
    itemsPerPage?: number;
    qSearch?: Array<string>;
    zustaendigerBenutzer?: Array<number>;
    status?: string;
    status2?: Array<string>;
    einrichtung?: string | number;
    einrichtung2?: Array<string>;
    meldedatum?: Array<string>;
    orderEingereichtAt?: 'asc' | 'desc';
    orderEinrichtungAktenzeichen?: 'asc' | 'desc';
    orderEinrichtungName?: 'asc' | 'desc';
    orderStatus?: 'asc' | 'desc';
    orderMeldedatum?: 'asc' | 'desc';
    orderGueltigAb?: 'asc' | 'desc';
    orderEinrichtungZustaendigerBenutzerName?: 'asc' | 'desc';
    aufgabe?: string;
    options?: AxiosRequestConfig;
};

export type GetApiBaupreisCollectionOptions = {
    page?: number;
    itemsPerPage?: number;
    typ?: number;
    refreshToken?: number;
};

export type GetApiEigenkapitalzinsP5Abs6APGDVOCollectionOptions = {
    page?: number;
    itemsPerPage?: number;
    options?: AxiosRequestConfig;
    refreshToken?: number;
};

export type GetApiFremdkapitalzinsenBundesbankCollectionOptions = {
    page?: number;
    itemsPerPage?: number;
    options?: AxiosRequestConfig;
    refreshToken?: number;
};

export type GetApiEigenkapitalzinsenBundesbankCollectionOptions = {
    page?: number;
    itemsPerPage?: number;
    options?: AxiosRequestConfig;
    refreshToken?: number;
};

export type GetApiAngemessenheitsgrenzeCollectionOptions = {
    page?: number;
    itemsPerPage?: number;
    typ?: string;
    typ2?: string[];
    refreshToken?: number;
};

export type GetBenutzerListOptions = {
    page?: number;
    itemsPerPage?: number;
    existsDeaktiviertAt?: boolean;
    id?: number;
    id2?: number[];
    rolle?: string;
    rolle2?: string[];
    landschaftsverband?: string;
    landschaftsverband2?: string[];
    einrichtung?: string;
    einrichtung2?: string[];
    traegerverband?: string;
    traegerverband2?: string[];
    qSearch?: string[];
    qImpersonateSearch?: string[];
    orderByName?: 'asc' | 'desc';
    orderByDeaktiviertAt?: 'asc' | 'desc';
    options?: AxiosRequestConfig;
};

export type GetTraegerverbandListOptions = {
    page?: number;
    itemsPerPage?: number;
    id?: number;
    id2?: number[];
    qSearch?: string[];
    options?: AxiosRequestConfig;
};

export type GetTraegerListOptions = {
    page?: number;
    itemsPerPage?: number;
    id?: number;
    id2?: number[];
    art?: string;
    art2?: string[];
    traegerverband?: string;
    traegerverband2?: string[];
    qSearch?: string[];
    orderByNrwKey?: 'asc' | 'desc';
    orderByName?: 'asc' | 'desc';
    orderByArt?: 'asc' | 'desc';
    orderByTraegerverbandName?: 'asc' | 'desc';
    options?: AxiosRequestConfig;
};

export type GetZustaendigkeitListOptions = {
    page?: number;
    itemsPerPage?: number;
    qSearch?: Array<string>;
    benutzer?: string;
    benutzer2?: Array<string>;
    kreis?: string;
    kreis2?: Array<string>;
    orderRegion?: 'asc' | 'desc';
    orderKreis?: 'asc' | 'desc';
    orderOrt?: 'asc' | 'desc';
    orderEinrichtungArt?: 'asc' | 'desc';
    orderBenutzerName?: 'asc' | 'desc';
    options?: AxiosRequestConfig;
};

export type GetEinrichtungListOptions = {
    page?: number;
    itemsPerPage?: number;
    id?: number;
    id2?: number[];
    art?: string;
    art2?: string[];
    status?: string;
    status2?: string[];
    traeger?: string;
    traeger2?: string[];
    traegerverband?: string;
    traegerverband2?: string[];
    qSearch?: string[];
    orderByAktenzeichen?: 'asc' | 'desc';
    orderByNrwKey?: 'asc' | 'desc';
    orderByName?: 'asc' | 'desc';
    orderByOrt?: 'asc' | 'desc';
    orderByArt?: 'asc' | 'desc';
    orderByStatus?: 'asc' | 'desc';
    orderByZustaendigerBenutzer?: 'asc' | 'desc';
    zustaendigerBenutzer?: number[];
    anbieterBenutzer?: number[];
    createdAtBefore?: string;
    createdAtStrictlyBefore?: string;
    createdAtAfter?: string;
    createdAtStrictlyAfter?: string;
    options?: AxiosRequestConfig;
};

export type GetAntragVersionenOptions = {
    page?: number;
    itemsPerPage?: number;
    antrag?: string;
    antrag2?: string[];
    orderByAntragModifiedAt?: 'asc' | 'desc';
    options?: AxiosRequestConfig;
};

export type GetInvestorenmodellOptions = {
    page?: number;
    itemsPerPage?: number;
    qSearch?: Array<string>;
    zustaendigerBenutzer?: Array<number>;
    status?: string;
    status2?: Array<string>;
    einrichtung?: string | number;
    einrichtung2?: Array<string>;
    berechnungsart?: Array<string>;
    antragJahr?: Array<string>;
    aufgabe?: string;
    orderZuletztEingereichtAt?: 'asc' | 'desc';
    orderEinrichtungAktenzeichen?: 'asc' | 'desc';
    orderEinrichtungName?: 'asc' | 'desc';
    orderStatus?: 'asc' | 'desc';
    orderEinrichtungZustaendigerBenutzerName?: 'asc' | 'desc';
    orderFestsetzungsantragField500?: 'asc' | 'desc';
    orderFestsetzungsantragFeststellungsantragBerechnungsart?: 'asc' | 'desc';
    options?: AxiosRequestConfig;
};

export type EnsureId = {
    id: number;
};

export type EnsureCreatedAt = {
    createdAt: string;
};

export type EnsureModifiedAt = {
    modifiedAt: string;
};

export type HinweiseAusMigration = {
    [key: string]: { nachricht: string; details?: Record<string, number | null> }[];
};

export interface WithHinweiseAusMigration {
    hinweiseAusMigration?: HinweiseAusMigration | [] | null;
}

export interface WithGegenpruefung<T extends GegenpruefungResponse> {
    letzteGegenpruefung?: T;
}

export interface WithZurueckziehen<T extends ZurueckziehenResponse> {
    letztesZurueckziehen?: T | null;
}

export interface WithEinrichtung {
    einrichtung: EinrichtungResponse;
}

export interface WithEinrichtungBenutzer {
    einrichtungBenutzer: EinrichtungResponseBenutzerResponse[];
}

export interface WithStatus {
    status: STATUS;
}

export interface WithVersionTyp {
    typ: ANTRAG_VERSIONEN_TYP;
}

export interface WithCreated {
    createdBy: { vorname: string; nachname: string };
    createdAt: string;
}

type WithCollectionFilterValues = {
    field: string;
    values: string[] | { [key: string]: string };
};

export interface WithFilters {
    filters: Array<WithCollectionFilterValues>;
}

// Responses
export type Token = Required<ApiToken>;

export type BenutzerProfileResponse = BenutzerJsonldProfileRead;
export type BenutzerResponse = BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles & EnsureId;
export type BenutzerByEmailResponse = BenutzerBenutzerGetByEmailOutputJsonldBenutzerGetByEmail & EnsureId;
export type BenutzerListResponse = InlineResponse<BenutzerResponse>;
export type AllowedCreateRollenResponse =
    BenutzerBenutzerGetAllowedCreateRollenOutputJsonldBenutzerGetAllowedCreateRollen;
export type BenutzerTablePreferenceResponse = BenutzerTablePreferenceJsonld & EnsureId;

export type FeststellungsAntragResponse = FeststellungsantragJsonldFeststellungsantragRead &
    WithGegenpruefung<FeststellungsAntragGegenpruefungResponse> &
    WithStatus &
    WithHinweiseAusMigration &
    WithZurueckziehen<FeststellungsAntragZurueckziehenResponse> &
    EnsureModifiedAt &
    EnsureId;
export type FeststellungsAntragCollectionResponse = FeststellungsantragJsonldFeststellungsantragList &
    WithZurueckziehen<FeststellungsAntragZurueckziehenResponse> &
    EnsureId;
export type FeststellungsAntragCollectionListResponse = InlineResponse<FeststellungsAntragCollectionResponse>;
export type FeststellungsAntragFormResponse = FeststellungsantragFeststellungsantragJsonJsonldAntragJsonForm & EnsureId;
export type FeststellungsAntragGegenpruefungResponse = FeststellungsantragGegenpruefungJsonld & EnsureId;
export type FeststellungsAntragVersionResponse = FeststellungsantragVersionJsonldFeststellungsantragVersionRead;
export type FeststellungsAntragVersionCollectionResponse =
    FeststellungsantragVersionJsonldFeststellungsantragVersionList & WithVersionTyp & WithStatus & EnsureId;
export type FeststellungsAntragZurueckziehenResponse = AbstractZurueckziehenJsonldFeststellungsantragList &
    WithCreated &
    EnsureId;

export type FeststellungsAntragVersionCollectionListResponse =
    InlineResponse<FeststellungsAntragVersionCollectionResponse>;
export type FeststellungsAntragBescheidungResponse = FeststellungsantragBescheidungFeststellungsantragRead & EnsureId;
export type FeststellungsAntragDiffResponse = FeststellungsantragFormDiffJsonldAntragFormDiff;

export type FestsetzungsAntragResponse = FestsetzungsantragJsonldFestsetzungsantragRead &
    WithGegenpruefung<FestsetzungsAntragGegenpruefungResponse> &
    WithStatus &
    WithHinweiseAusMigration &
    EnsureModifiedAt &
    WithZurueckziehen<FestsetzungsAntragZurueckziehenResponse> &
    EnsureId;
export type FestsetzungsAntragCollectionResponse = FestsetzungsantragJsonldFestsetzungsantragList &
    WithZurueckziehen<FestsetzungsAntragZurueckziehenResponse> &
    EnsureId;
export type FestsetzungsAntragCollectionListResponse = InlineResponse<FestsetzungsAntragCollectionResponse>;
export type FestsetzungsAntragFormResponse = FestsetzungsantragFestsetzungsantragJsonJsonldAntragJsonForm & EnsureId;
export type FestsetzungsAntragGegenpruefungResponse = FestsetzungsantragGegenpruefungJsonld & EnsureId;
export type FestsetzungsAntragVersionResponse = FestsetzungsantragVersionJsonldFestsetzungsantragVersionRead;
export type FestsetzungsAntragVersionCollectionResponse = FestsetzungsantragVersionJsonldFestsetzungsantragVersionRead &
    WithVersionTyp &
    WithStatus &
    EnsureId;
export type FestsetzungsAntragVersionCollectionListResponse =
    InlineResponse<FestsetzungsAntragVersionCollectionResponse>;
export type FestsetzungsAntragDiffResponse = FestsetzungsantragFormDiffJsonldAntragFormDiff;
export type FestsetzungsAntragZurueckziehenResponse = AbstractZurueckziehenJsonldFestsetzungsantragList &
    WithCreated &
    EnsureId;

export type InvestorenmodellResponse = InvestorenmodellJsonldInvestorenmodellRead &
    WithGegenpruefung<InvestorenmodellGegenpruefungResponse> &
    WithStatus &
    WithHinweiseAusMigration &
    EnsureModifiedAt &
    EnsureId;
export type InvestorenmodellCollectionResponse = InvestorenmodellJsonldInvestorenmodellList &
    WithZurueckziehen<InvestorenmodellZurueckziehenResponse> &
    EnsureId;
export type InvestorenmodellCollectionListResponse = InlineResponse<InvestorenmodellCollectionResponse>;
export type InvestorenmodellFormResponse = InvestorenmodellInvestorenmodellJsonJsonldAntragJsonForm & EnsureId;
export type InvestorenmodellGegenpruefungResponse = AbstractGegenpruefungJsonldInvestorenmodellRead & EnsureId;
export type InvestorenmodellVersionResponse = InvestorenmodellVersionJsonldInvestorenmodellVersionRead;
export type InvestorenmodellVersionCollectionResponse = InvestorenmodellVersionJsonldInvestorenmodellVersionRead &
    WithVersionTyp &
    WithStatus &
    EnsureId;
export type InvestorenmodellVersionCollectionListResponse = InlineResponse<InvestorenmodellVersionCollectionResponse>;
export type InvestorenmodellDiffResponse = InvestorenmodellFormDiffAntragFormDiff;
export type InvestorenmodellZurueckziehenResponse = AbstractZurueckziehenJsonldInvestorenmodellList &
    WithCreated &
    EnsureId;

export type MeldungP29Response = MeldungP29JsonldMeldungP29Read & WithStatus & EnsureId;
export type MeldungP29CollectionResponse = MeldungP29JsonldMeldungP29List & WithStatus & EnsureId;
export type MeldungP29CollectionListResponse = InlineResponse<MeldungP29CollectionResponse>;
export type MeldungP29FormResponse = MeldungP29MeldungP29JsonJsonldAntragJsonForm & EnsureId;

export type EinrichtungResponse = EinrichtungJsonldEinrichtungRead & WithEinrichtungBenutzer & EnsureId;
export type EinrichtungListItemResponse = EinrichtungJsonldEinrichtungList & EnsureId;
export type EinrichtungListResponse = InlineResponse<EinrichtungListItemResponse>;
export type EinrichtungResponseBenutzerResponse = EinrichtungBenutzerJsonldEinrichtungRead & EnsureId;
export type EinrichtungBenutzerResponse = EinrichtungBenutzerJsonldEinrichtungBenutzerRead & EnsureId;
export type EinrichtungBenutzerListResponse = InlineResponse<EinrichtungBenutzerResponse>;
export type EinrichtungAnbieterPermissionsResponse =
    EinrichtungEinrichtungAntragPermissionsOutputJsonldEinrichtungAntragAnbieterPermissions;
export type EinrichtungFilterResponse = InlineResponse<EinrichtungCollectionFiltersOutputJsonldCollectionFilters> &
    WithFilters;

export type RuhendstellungResponse = RuhendstellungJsonldEinrichtungRead;
export const RuhendstellungGrund = {
    Schliessung: 'SCHLIESSUNG',
    Verzicht: 'VERZICHT',
} as const;
export type RuhendstellungGrundType = typeof RuhendstellungGrund[keyof typeof RuhendstellungGrund];

export enum AntragstellerType {
    LESEND = 'LESEND',
    SCHREIBEND = 'SCHREIBEND',
}

export type TraegerverbandResponse = TraegerverbandJsonldTraegerverbandRead & EnsureId;
export type TraegerverbandListResponse = InlineResponse<TraegerverbandResponse>;

export type TraegerResponse = TraegerJsonldTraegerRead & EnsureId;
export type TraegerListResponse = InlineResponse<TraegerResponse>;

export type LandschaftsverbandResponse = LandschaftsverbandLandschaftsverbandRead & EnsureId;
export type LandschaftsverbandListResponse = InlineResponse<LandschaftsverbandResponse>;

export type ZustaendigkeitResponse = ZustaendigkeitJsonldZustaendigkeitRead;
export type ZustaendigkeitListResponse = InlineResponse<ZustaendigkeitResponse>;

export type BaupreisIndexResponse = BaupreisindexJsonld;
export type BaupreisIndexListResponse = InlineResponse<BaupreisIndexResponse>;
export type EigenkapitalZinsResponse = EigenkapitalzinsP5Abs6APGDVOJsonld;
export type EigenkapitalZinsListResponse = InlineResponse<EigenkapitalZinsResponse>;
export type EigenkapitalZinsBundesbankResponse = EigenkapitalzinsenBundesbankJsonld;
export type EigenkapitalZinsBundesbankListResponse = InlineResponse<EigenkapitalZinsBundesbankResponse>;
export type FremdkapitalZinsResponse = FremdkapitalzinsenBundesbankJsonld;
export type FremdkapitalZinsListResponse = InlineResponse<FremdkapitalZinsResponse>;
export type AngemessenheitsGrenzeResponse = AngemessenheitsgrenzeJsonld;
export type AngemessenheitsGrenzeListResponse = InlineResponse<AngemessenheitsGrenzeResponse>;

export type MassenmailResponse = MassenmailJsonldMassenmailRead & EnsureId & EnsureCreatedAt;
export type MassenmailListResponse = InlineResponse<MassenmailResponse>;

export type GegenpruefungResponse = FestsetzungsAntragGegenpruefungResponse | FeststellungsAntragGegenpruefungResponse;
export type ZurueckziehenResponse =
    | FeststellungsAntragZurueckziehenResponse
    | FestsetzungsAntragZurueckziehenResponse
    | InvestorenmodellZurueckziehenResponse;

export type AufgabenResponse = BenutzerAufgabenResponse | LvAufgabenResponse;
export type BenutzerAufgabenResponse = BenutzerAufgabenOutputJsonldBenutzerAufgaben;
export type LvAufgabenResponse = BenutzerAufgabenOutputJsonldBenutzerLvAufgaben;

export type AntragResponse = FeststellungsAntragResponse | FestsetzungsAntragResponse | InvestorenmodellResponse;
export type AntragVersionResponse =
    | FeststellungsAntragVersionResponse
    | FestsetzungsAntragVersionResponse
    | InvestorenmodellVersionResponse;
export type AntragVersionCollectionResponse =
    | FeststellungsAntragVersionCollectionResponse
    | FestsetzungsAntragVersionCollectionResponse;
export type AntragVersionCollectionListResponse =
    | FeststellungsAntragVersionCollectionListResponse
    | FestsetzungsAntragVersionCollectionListResponse;
export type AntragBescheidungResponse = FeststellungsAntragBescheidungResponse;
export type AntragDiffResponse =
    | FeststellungsantragFormDiffJsonldAntragFormDiff
    | FestsetzungsantragFormDiffJsonldAntragFormDiff
    | InvestorenmodellFormDiffAntragFormDiff;

export type AntragFilterOptionResponse = WithFilters;

// Requests
export type BenutzerRequest = BenutzerJsonldBenutzerCreate;
export type EinrichtungBenutzerRequest = EinrichtungBenutzerJsonldEinrichtungBenutzerCreate;
export type FeststellungsAntragRequest = FeststellungsantragJsonldFeststellungsantragCreate;
export type InvestorenmodellRequest = InvestorenmodellJsonldInvestorenmodellCreate;
export type FeststellungsAntragGegenpruefungRequest = FeststellungsantragGegenpruefungGegenpruefungEdit;
export type FestsetzungsAntragGegenpruefungRequest = FestsetzungsantragGegenpruefungGegenpruefungEdit;
export type InvestorenmodellGegenpruefungRequest = InvestorenmodellGegenpruefungGegenpruefungEdit;
export type PutBenutzer =
    BenutzerBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger;
export type AntragZurueckziehenEntscheidungRequest = FeststellungsantragZurueckziehenZurueckziehenSetEntscheidung;
export type MeldungP29Request = MeldungP29JsonldMeldungP29Create;
export type RuhendstellungRequest = RuhendstellungRuhendstellungCreate;
