import React from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';

import { useApiTraegerItem } from 'api/hooks/useApiClient';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { ContentContainer } from 'layout/container/ContentContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { withAuthorization } from 'navigation/withAuthorization';
import { TraegerEinrichtungenList } from 'pages/Verwaltung/Traeger/Einrichtungen/TraegerEinrichtungenList';
import { VerwaltungConfig } from 'pages/Verwaltung/VerwaltungConfig';

const TraegerverbandEinrichtungen: React.FC = () => {
    const { id: traegerId } = useParams();
    const { data: traeger, isLoading } = useApiTraegerItem(traegerId);
    const title = `Einrichtungen des Trägers ${traeger?.name}`;
    return (
        <ContentContainer title={title}>
            {traegerId && (
                <AppLoaderContainer isLoading={isLoading}>
                    <Typography variant="h1">{title}</Typography>
                    <TraegerEinrichtungenList id={traegerId} />
                </AppLoaderContainer>
            )}
        </ContentContainer>
    );
};

export const TraegerEinrichtungenPage = withAuthorization(
    withSideBar(TraegerverbandEinrichtungen, VerwaltungConfig.menu),
    VerwaltungConfig.roles.traegerverband
);
