import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';

export const uiSchema = verticalLayout(
    [
        {
            type: 'SummaryTable',
            label: 'Basisdaten',
            columns: 3,
            scope: '#/properties/basisdaten',
            entries: [
                {
                    type: 'FieldRow',
                    scope: '#/properties/field1052',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field500',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field450',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field451',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field452',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field457',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field457d',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field458',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field459',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field459d',
                },
                {
                    type: 'NotesRow',
                    scope: '#/properties/notizBasisdaten',
                },
            ],
        },
        {
            type: 'SummaryTable',
            label: 'Darlehen',
            columns: 3,
            scope: '#/properties/darlehen',
            entries: [
                {
                    type: 'SubTable',
                    label: '{#/properties/field453a}',
                    scope: '#/properties/darlehen',
                    entries: [
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field453a',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field453',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field453da',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field453db',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field454',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field453dc',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field455',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field456',
                        },
                    ],
                },
                {
                    type: 'NotesRow',
                    scope: '#/properties/notizDarlehen',
                },
            ],
        },
    ],
    6
);
