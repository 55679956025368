import React, { ReactNode } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { DesktopDatePicker, DesktopDatePickerProps } from '@mui/x-date-pickers';

type DatePickerProps = Omit<DesktopDatePickerProps<Date | string, Date>, 'renderInput' | 'inputFormat'> & {
    error?: boolean;
    onBlur?: () => void;
    helperText?: string | ReactNode;
    fullWidth?: boolean;
    size?: TextFieldProps['size'];
    inputProps?: TextFieldProps['inputProps'];
};

export const DatePicker: React.FC<DatePickerProps> = ({
    error = false,
    onBlur,
    helperText,
    inputProps,
    fullWidth,
    size,
    ...props
}) => {
    return (
        <DesktopDatePicker
            {...props}
            inputFormat="dd.MM.yyyy"
            mask="__.__.____"
            renderInput={(textFieldProps) => {
                return (
                    <TextField
                        {...textFieldProps}
                        error={error}
                        helperText={helperText}
                        onBlur={onBlur}
                        fullWidth={fullWidth}
                        size={size}
                        inputProps={{
                            ...inputProps,
                            ...textFieldProps.inputProps,
                            placeholder: 'tt.mm.jjjj',
                        }}
                    />
                );
            }}
        />
    );
};
