import { Typography } from '@mui/material';

import { AntragType } from 'api/antragTypes';
import { useColumnsFeststellung } from 'components/DataTableHydra/Columns/Feststellung';
import { DataTableHydraServerside } from 'components/DataTableHydra/DataTableHydraServerside';
import { ROLES } from 'constants/roles';
import { ContentContainer } from 'layout/container/ContentContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { withAuthorization } from 'navigation/withAuthorization';
import { menuAntraege } from 'pages/Antraege/AntragConfig';
import { useAntragFilterOptions } from 'pages/Antraege/components/useAntragFilterOptions';
import { ZurueckziehenAkzeptierenDialog } from 'pages/Antraege/components/ZurueckziehenAkzeptierenDialog';
import { ZurueckziehenBeantragenDialog } from 'pages/Antraege/components/ZurueckziehenBeantragenDialog';
import { useFeststellungsAntragListActions } from 'pages/Antraege/Feststellung/useFeststellungsAntragListActions';
import { useFeststellungsAntragListData } from 'pages/Antraege/Feststellung/useFeststellungsAntragListData';

const FeststellungsAntragList = () => {
    const getData = useFeststellungsAntragListData();
    const {
        handleAction,
        serverDataChanged,
        zurueckziehenBeantragen,
        zurueckziehenBeantragenFinishAction,
        zurueckziehenAkzeptieren,
        zurueckziehenAkzeptierenFinishAction,
    } = useFeststellungsAntragListActions(AntragType.FESTSTELLUNG);
    const filterOptions = useAntragFilterOptions(AntragType.FESTSTELLUNG);
    const columnsFeststellung = useColumnsFeststellung(handleAction, filterOptions);
    return (
        <ContentContainer title="Feststellungsanträge">
            <Typography variant="h1">Anträge auf Feststellung</Typography>
            <DataTableHydraServerside
                getColumns={columnsFeststellung}
                getData={getData}
                initialOptions={{
                    searchPlaceholder: 'Suche nach Einrichtungsname, Aktenzeichen oder NRW-Schlüssel',
                    sortOrder: {
                        name: 'zuletztEingereichtAt',
                        direction: 'desc',
                    },
                }}
                serverDataChanged={serverDataChanged}
            />

            <ZurueckziehenBeantragenDialog
                antrag={zurueckziehenBeantragen}
                onClose={zurueckziehenBeantragenFinishAction}
                antragType={AntragType.FESTSTELLUNG}
            />

            <ZurueckziehenAkzeptierenDialog
                antrag={zurueckziehenAkzeptieren}
                onClose={zurueckziehenAkzeptierenFinishAction}
                antragType={AntragType.FESTSTELLUNG}
            />
        </ContentContainer>
    );
};

export const FeststellungsAntragListPage = withAuthorization(
    withSideBar(FeststellungsAntragList, menuAntraege),
    [ROLES.FACHADMIN, ROLES.SUPPORT, ROLES.LV, ROLES.ANBIETER] // TODO: ROLES.ANBIETER entfernen
);
