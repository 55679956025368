import { AntragType } from 'api/antragTypes';
import { backendApiService } from 'api/ApiService';
import { InvestPathBuilder } from 'navigation/Paths';
import { useAntragListActions } from 'pages/Antraege/components/useAntragListActions';

export const useInvestorenmodellAntragListAction = (type: AntragType) => {
    return useAntragListActions(
        InvestPathBuilder.home.antraege.investorenmodell,
        backendApiService.workflowInvestorenmodellItem.bind(backendApiService),
        type
    );
};
