import React, { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useState } from 'react';

import { UuidMappingFunction } from 'forms/hooks/useUuidValue';

export interface FormStateData {
    isPersisting: boolean;
    isSubmitting: boolean;
    isLoading: boolean;
    loadingError: string | undefined;
    antragId: number | undefined;
    sofortigerFestsetzungsantragId: number | undefined;
    antragStatus: string | undefined;
    einrichtungId: number | undefined;
    uuidMap: { [key: string]: string } | undefined;
}

export interface FormStateActions {
    submitStart: (persist?: boolean) => void;
    submitEnd: () => void;

    loadingStart: () => void;
    loadingEnd: () => void;

    setLoadingError: (error: string | undefined) => void;

    setEinrichtungId: (einrichtungId: number | undefined) => void;
    setAntragId: (antragId: number | undefined) => void;
    setFestsetzungsantragEntwurfId: (festsetzungsantragEntwurfId: number | undefined) => void;
    setAntragStatus: (antragId: string | undefined) => void;
    setUuidMap: (uuidMap: { [key: string]: string } | undefined) => void;
    mapUuid: UuidMappingFunction;
}

export const FormStateContext = createContext<{
    formStateData: FormStateData;
    setFormStateData: Dispatch<SetStateAction<FormStateData>>;
}>({
    formStateData: {
        isPersisting: false,
        isSubmitting: false,
        isLoading: false,
        loadingError: undefined,
        antragId: undefined,
        sofortigerFestsetzungsantragId: undefined,
        antragStatus: undefined,
        einrichtungId: undefined,
        uuidMap: undefined,
    },
    setFormStateData: (prev) => prev,
});

export interface FormStateProviderProps {
    initialValue?: Partial<FormStateData>;
    children?: ReactNode;
}

export const FormStateProvider: React.FC<FormStateProviderProps> = ({ children, initialValue = {} }) => {
    const [formStateData, setFormStateData] = useState<FormStateData>({
        isPersisting: false,
        isSubmitting: false,
        isLoading: false,
        loadingError: undefined,
        einrichtungId: undefined,
        antragId: undefined,
        sofortigerFestsetzungsantragId: undefined,
        antragStatus: initialValue.antragStatus,
        uuidMap: initialValue.uuidMap,
    });

    const ignoreKeys = useCallback((e: KeyboardEvent) => {
        e.preventDefault && e.preventDefault();
        e.stopPropagation && e.stopPropagation();
        return false;
    }, []);

    useEffect(() => {
        if (formStateData.isSubmitting) {
            window.addEventListener('keydown', ignoreKeys);
        } else {
            window.removeEventListener('keydown', ignoreKeys);
        }
        return () => {
            window.removeEventListener('keydown', ignoreKeys);
        };
    }, [formStateData.isSubmitting, ignoreKeys]);

    return (
        <FormStateContext.Provider value={{ formStateData, setFormStateData }}>{children}</FormStateContext.Provider>
    );
};
