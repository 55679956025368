import { ReactElement } from 'react';
import { Chip, MenuItem, styled } from '@mui/material';

import { EinrichtungJsonldEinrichtungList } from 'api/client';
import { ActionDropdown } from 'components/DataTableHydra/ActionDropdown/ActionDropdown';
import { EinrichtungTableActions } from 'constants/einrichtungActions';

interface PflegeeinrichtungListActionsProps {
    einrichtung: EinrichtungJsonldEinrichtungList;
    handleAction: (action: EinrichtungTableActions, einrichtung: EinrichtungJsonldEinrichtungList) => void;
}

export const PflegeeinrichtungListActions = ({
    einrichtung,
    handleAction,
}: PflegeeinrichtungListActionsProps): ReactElement => {
    const { anzahlFeststellungen, anzahlFestsetzungen, anzahlInvestorenmodelle } = einrichtung;

    if (!anzahlFeststellungen && !anzahlFestsetzungen && !anzahlInvestorenmodelle) {
        return <></>;
    }

    return (
        <ActionDropdown>
            {!!anzahlFeststellungen && anzahlFeststellungen > 0 && (
                <MenuItem
                    onClick={() => handleAction(EinrichtungTableActions.ANTRAGSHISTORIE_FESTSTELLUNG, einrichtung)}
                >
                    {`Antragshistorie Feststellung`}
                    <StyledChip label={anzahlFeststellungen} />
                </MenuItem>
            )}
            {!!anzahlFestsetzungen && anzahlFestsetzungen > 0 && (
                <MenuItem
                    onClick={() => handleAction(EinrichtungTableActions.ANTRAGSHISTORIE_FESTSETZUNG, einrichtung)}
                >
                    {`Antragshistorie Festsetzung`}
                    <StyledChip label={anzahlFestsetzungen} />
                </MenuItem>
            )}
            {!!anzahlInvestorenmodelle && anzahlInvestorenmodelle > 0 && (
                <MenuItem
                    onClick={() => handleAction(EinrichtungTableActions.ANTRAGSHISTORIE_INVESTORENMODELL, einrichtung)}
                >
                    {`Antragshistorie Antrag nach § 8 Abs. 11`}
                    <StyledChip label={anzahlInvestorenmodelle} />
                </MenuItem>
            )}
        </ActionDropdown>
    );
};

const StyledChip = styled(Chip)(() => ({
    height: 'auto',
    marginLeft: '12px',
}));
