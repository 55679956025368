import React, { useState } from 'react';

import { DataTableHydraServerside } from 'components/DataTableHydra/DataTableHydraServerside';
import { useLvBenutzerOptions } from 'components/DataTableHydra/hooks/useLvBenutzerOptions';
import { DialogEinrichtung } from 'pages/Verwaltung/Zustaendigkeiten/Einrichtung/DialogEinrichtung';

import { useZustaendigkeitenEinrichtungListAction } from './useZustaendigkeitenEinrichtungListAction';
import { useZustaendigkeitenEinrichtungListColumns } from './useZustaendigkeitenEinrichtungListColumns';
import { useZustaendigkeitenEinrichtungListData } from './useZustaendigkeitenEinrichtungListData';

export const ZustaendigkeitenEinrichtungList = () => {
    const { isLoading: isLoadingLvBenutzer, options: zustandigerBenutzerOptions } = useLvBenutzerOptions();
    const { einrichtung, closeAction, handleAction } = useZustaendigkeitenEinrichtungListAction();
    const getData = useZustaendigkeitenEinrichtungListData();
    const getColumns = useZustaendigkeitenEinrichtungListColumns(zustandigerBenutzerOptions, handleAction);
    const [serverDataChanged, setServerDataChanged] = useState(0);

    const onComplete = () => setServerDataChanged((prev) => prev + 1);

    return (
        <>
            <DataTableHydraServerside
                loadingContext={isLoadingLvBenutzer}
                getData={getData}
                getColumns={getColumns}
                serverDataChanged={serverDataChanged}
                initialOptions={{
                    viewColumns: false,
                    sortOrder: {
                        name: 'aktenzeichen',
                        direction: 'desc',
                    },
                    filter: false,
                }}
            />
            {einrichtung && (
                <DialogEinrichtung
                    einrichtungId={String(einrichtung.id)}
                    handleEinrichtungZustaendigkeitSaved={onComplete}
                    handleExit={closeAction}
                />
            )}
        </>
    );
};
