import { ROLES } from 'constants/roles';
import { MenuInterface } from 'layout/HeaderConfig';
import { InvestPathBuilder } from 'navigation/Paths';

const roles = {
    pfelgeeinrichtung: [ROLES.FACHADMIN, ROLES.SUPPORT, ROLES.LV, ROLES.ANBIETER],
    massenmail: [ROLES.FACHADMIN, ROLES.SUPPORT, ROLES.MINISTERIUM, ROLES.LV],
    zustaendigkeit: [ROLES.FACHADMIN, ROLES.SUPPORT, ROLES.LV],
    traeger: [ROLES.FACHADMIN, ROLES.SUPPORT, ROLES.LV],
    traegerverband: [ROLES.FACHADMIN, ROLES.SUPPORT, ROLES.LV],
    benutzer: [ROLES.FACHADMIN, ROLES.SUPPORT, ROLES.MINISTERIUM, ROLES.LV, ROLES.ANBIETER],
};

const menu: MenuInterface[] = [
    {
        path: InvestPathBuilder.home.verwaltung.pflegeeinrichtungen.path,
        name: 'Pflegeeinrichtungen',
        roles: roles.pfelgeeinrichtung,
        /*menus: [
              {
                path: '#Benutzer',
                name: 'Benutzer',
                menus: [
                  {
                    path: '#Benutzer anlegen',
                    name: 'Benutzer anlegen',
                  },
                  {
                    path: '#Benutzer zuordnen',
                    name: 'Benutzer zuordnen',
                  },
                ],
              },
            ],*/
    },
    {
        path: InvestPathBuilder.home.verwaltung.traeger.path,
        name: 'Träger',
        roles: roles.traeger,
    },
    {
        path: InvestPathBuilder.home.verwaltung.traegerverband.path,
        name: 'Trägerverband',
        roles: roles.traegerverband,
    },
    {
        path: InvestPathBuilder.home.verwaltung.benutzer.path,
        name: 'Benutzer',
        roles: roles.benutzer,
    },
    {
        path: InvestPathBuilder.home.verwaltung.zustaendigkeiten.path,
        name: 'Zuständigkeiten',
        roles: roles.zustaendigkeit,
    },
    {
        path: InvestPathBuilder.home.verwaltung.massenmail.path,
        name: 'Mailings',
        roles: roles.massenmail,
    },
];

export const VerwaltungConfig = {
    roles,
    menu,
};
