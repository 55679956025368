import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Grid } from '@mui/material';

import { InvestPathBuilder } from 'navigation/Paths';

interface LoginLinkCheckErrorProps {
    error: string;
}

export const LoginLinkCheckError = ({ error }: LoginLinkCheckErrorProps): ReactElement => {
    const navigate = useNavigate();

    const onNavigateToStart = () => {
        navigate(InvestPathBuilder.home.path);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
            </Grid>
            <Grid item xs={12} container justifyContent="flex-end">
                <Button variant="contained" color="primary" onClick={onNavigateToStart}>
                    Weiter zur Startseite
                </Button>
            </Grid>
        </Grid>
    );
};
