import React, { useEffect, useState } from 'react';
import { JsonForms } from '@jsonforms/react';
import { Alert, Grid, Typography } from '@mui/material';
import _, { identity } from 'lodash';

import { backendApiService } from 'api/ApiService';
import { useAuthHelper } from 'api/auth/useAuthHelper';
import { BenutzerCredentialChangeRequestInput } from 'api/client';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { Dialog } from 'components/Dialog/Dialog';
import { SupportInfo } from 'elements/SupportInfo';
import { FormButton } from 'forms/components/FormButton';
import { Pflichtfeld } from 'forms/components/Pflichtfeld';
import { useFormValidation } from 'forms/hooks/useFormValidation';
import { useLayoutWithJsonFormsState } from 'forms/hooks/useJsonFormsState';
import { renderers } from 'forms/renderers';
import { FormLayoutConfig, FormStateChange, Schema } from 'forms/types/UiSchemaTypes';

type FormDataType = {
    currentEmail: string | undefined;
    email: string | undefined;
    password?: string | undefined;
};

const formData: FormDataType = {
    currentEmail: '',
    email: '',
    password: '',
};

const configLayout: FormLayoutConfig = {
    gridLayout: {
        label: 2,
        input: 4,
    },
};

const uiSchema = {
    type: 'CustomVerticalLayout',
    elements: [
        {
            type: 'Group',
            options: {
                noSeparator: true,
            },
            gridLayout: {
                label: 3,
                input: 5,
            },
            elements: [
                {
                    type: 'Control',
                    scope: '#/properties/currentEmail',
                },
                {
                    type: 'Control',
                    scope: '#/properties/email',
                    options: {
                        focus: true,
                    },
                },
            ],
        },
        {
            type: 'Group',
            options: {
                noSeparator: true,
            },
            elements: [
                {
                    type: 'LabelRowControl',
                    text: 'Bitte bestätigen Sie die Änderung Ihrer E-Mail-Adresse durch Eingabe Ihres PfAD.invest Passworts (Ihr Login-Passwort)',
                },
            ],
        },
        {
            type: 'Group',
            gridLayout: {
                label: 3,
                input: 5,
            },
            options: {
                noSeparator: true,
            },
            elements: [
                {
                    type: 'Control',
                    scope: '#/properties/password',
                },
            ],
        },
    ],
};

const schema: Schema = {
    type: 'object',
    properties: {
        currentEmail: {
            type: 'string',
            title: 'Aktuelle E-Mail-Adresse',
            // @ts-ignore
            custom: {
                disabled: true,
            },
        },
        email: {
            type: 'string',
            title: 'Neue E-Mail-Adresse',
            // @ts-ignore
            custom: {
                block_prefixes: ['email_change_widget'],
                required: true,
            },
        },
        password: {
            type: 'string',
            title: 'Passwort',
            // @ts-ignore
            custom: {
                block_prefixes: ['password', 'small'],
                required: true,
            },
        },
    },
    required: ['email', 'password'],
};

interface EmailChangeProps {
    open: boolean;
    onClose: () => void;
}

export const EmailChange: React.FC<EmailChangeProps> = ({ open, onClose }) => {
    const { getCurrentBenutzer } = useAuthHelper();
    const benutzer = getCurrentBenutzer();
    const [data, setData] = useState(formData);
    const config = useLayoutWithJsonFormsState(configLayout);
    const [isSubmitting, setSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const { valid } = useFormValidation(schema, data);
    const [isLoading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        if (benutzer && !data.currentEmail) {
            setData((prevState) => {
                const newData = {
                    ...prevState,
                    currentEmail: benutzer?.username,
                };

                return _.pickBy(newData, identity) as FormDataType;
            });
        }
    }, [data, benutzer]);

    useEffect(() => {
        if (data) {
            setLoading(false);
        }
    }, [data]);

    const closeModal = () => {
        onClose();
        setTimeout(() => {
            setSuccess(false);
        });
    };

    const handleChange = (changeData: FormStateChange) => {
        setData(changeData.data);
    };

    const handleSave = () => {
        if (isSubmitting) {
            return;
        }

        setSubmitting(true);
        setErrorMessage(undefined);

        backendApiService
            .editEmail({
                email: data.email,
                password: data.password,
            } as BenutzerCredentialChangeRequestInput)
            .then(() => {
                // TODO: res.status checken!
                setSuccess(true);
            })
            .catch((error) => {
                switch (error.response.status) {
                    case 403:
                        setErrorMessage('Das eingegebene Passwort ist nicht korrekt.');
                        break;
                    case 409:
                        setErrorMessage('Die eingegebene E-Mail-Adresse ist bereits vergeben.');
                        break;
                    default:
                        setErrorMessage('Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.');
                }
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <Dialog
            open={open}
            title={'E-Mail-Adresse ändern'}
            onClose={closeModal}
            wrapActions
            actions={
                <>
                    <Grid container spacing={1} direction={'row-reverse'}>
                        {!success && (
                            <Grid item>
                                <FormButton
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSave}
                                    disabled={!valid}
                                    data-cy={'FormDialog-saveButton'}
                                >
                                    E-Mail-Adresse ändern
                                </FormButton>
                            </Grid>
                        )}

                        <Grid item>
                            <FormButton
                                variant="outlined"
                                color="primary"
                                onClick={closeModal}
                                data-cy={'FormDialog-closeeButton'}
                            >
                                Schließen
                            </FormButton>
                        </Grid>
                    </Grid>
                    <SupportInfo />
                </>
            }
        >
            <>
                {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

                <AppLoaderContainer isLoading={isLoading}>
                    {success && (
                        <Typography paragraph>
                            Um Ihre E-Mail-Adresse zu ändern, klicken Sie bitte innerhalb von 24 Stunden auf den
                            Bestätigungslink, den wir an Ihre neue E-Mail-Adresse
                        </Typography>
                    )}

                    {!success && (
                        <>
                            <Grid container>
                                <Grid item xs={10}>
                                    <Typography paragraph>
                                        Ihre E-Mail-Adresse ist zeitgleich Ihr Benutzername in PfAD.invest. Um eine
                                        andere E-Mail-Adresse für Ihren Account zu nutzen, müssen Sie Ihre neue
                                        E-Mail-Adresse zunächst bestätigen. Um die neue E-Mail-Adresse zu bestätigen,
                                        klicken Sie auf den Bestätigungslink, den wir an Ihre neue E-Mail-Adresse
                                        schicken.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Alert severity={'warning'}>
                                WICHTIG: Erst nach erfolgreicher Bestätigung Ihrer neuen E-Mail-Adresse wird diese als
                                Benutzername in PfAD.invest akzeptiert. Ihre aktuelle E-Mail-Adresse ist bis zur
                                Bestätigung aktiv.
                            </Alert>
                            <Pflichtfeld>Pflichtfeld *</Pflichtfeld>
                            <JsonForms
                                data={data}
                                schema={schema}
                                uischema={uiSchema}
                                onChange={handleChange}
                                renderers={renderers}
                                config={config}
                            />
                        </>
                    )}
                </AppLoaderContainer>
            </>
        </Dialog>
    );
};
