import { GridLayoutConfig, InlineRowControlType } from 'forms/types/UiSchemaTypes';

export const inlineRowControl = (
    elements: any,
    scope?: string,
    options: {
        gridLayout?: GridLayoutConfig;
        grid?: boolean;
    } = {}
): InlineRowControlType => ({
    type: 'InlineRowControl',
    scope,
    elements,
    ...options,
});
