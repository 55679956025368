import * as React from 'react';
import { ChangeEvent, useCallback } from 'react';
import { FormControl, InputAdornment, TextField } from '@mui/material';

import { DezimalFormat } from 'forms/components/NumberFormats';
import { ErrorList } from 'forms/controls/components/ErrorList';
import { ReadonlyValueText } from 'forms/controls/components/ReadonlyValueText';
import { useIsDataChanged } from 'forms/hooks/useIsDataChanged';
import { useScrollInView } from 'forms/hooks/useScrollInView';
import { useTempControlData } from 'forms/hooks/useTempControlData';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';
import { formatDezimal } from 'forms/utils/formatter';
import { stringToNumber } from 'utilities/Utils';

import { RowControl } from './RowControl';

const DezimalzahlControlComponent: React.FC<CustomControlProps<number | undefined>> = ({
    path,
    label,
    required,
    data,
    handleChange,
    hasErrors,
    errors,
    unit,
    disabled,
    readonly,
    formula,
    schema,
    uischema,
    showFieldNumberLabels,
    gridLayout,
    onTouched,
    id,
    config,
}) => {
    const eventToData = useCallback((e: ChangeEvent<HTMLInputElement>) => stringToNumber(e.target.value), []);
    const { tempData, handleValueChange, handleOnBlur } = useTempControlData(
        data,
        path,
        errors,
        handleChange,
        eventToData,
        onTouched,
        {
            validateOnBlur: true,
        }
    );
    const isDataChanged = useIsDataChanged(data, schema);

    const ref = useScrollInView<HTMLDivElement>();

    return (
        <RowControl
            name={path}
            label={label}
            required={required}
            formula={formula}
            controlOnly={uischema.controlOnly}
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={gridLayout}
            hasChangedData={isDataChanged}
            labelForId={id}
            config={config}
            paths={[path]}
        >
            {readonly ? (
                <ReadonlyValueText
                    path={path}
                    errors={errors}
                    textAlign={'right'}
                    hasErrors={hasErrors}
                    text={formatDezimal(data, unit, schema.custom?.scale) || '-'}
                />
            ) : (
                <FormControl fullWidth>
                    <TextField
                        id={id}
                        ref={ref}
                        fullWidth
                        error={hasErrors}
                        disabled={disabled}
                        onBlur={handleOnBlur}
                        value={tempData ?? ''}
                        onChange={handleValueChange}
                        helperText={hasErrors ? <ErrorList errors={errors} /> : ' '}
                        InputProps={{
                            inputComponent: DezimalFormat as any,
                            endAdornment: unit && <InputAdornment position="end">{unit}</InputAdornment>,
                        }}
                        inputProps={{
                            style: { textAlign: 'right' },
                            min: schema.custom?.min,
                            max: schema.custom?.max,
                            scale: schema.custom?.scale,
                            'data-cy': `form_${path}`,
                        }}
                    />
                </FormControl>
            )}
        </RowControl>
    );
};

export const DezimalzahlControl = withCustomControlProps(DezimalzahlControlComponent);
