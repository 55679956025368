import { MUISortOptions } from 'mui-datatables';

import { GetFestsetzungsAntragOptions } from 'api/types';
import { TableFilters } from 'components/DataTableHydra/hooks/useTableFilters';
import { sortBy, zustaendigerBenutzerFilter } from 'components/DataTableHydra/tableUtils';

type GetFestsetzungSortParameters = Pick<
    GetFestsetzungsAntragOptions,
    | 'orderField500'
    | 'orderFeststellungsantragBerechnungsart'
    | 'orderZuletztEingereichtAt'
    | 'orderEinrichtungAktenzeichen'
    | 'orderEinrichtungZustaendigerBenutzer'
    | 'orderStatus'
    | 'orderEinrichtungName'
>;

type GetFestsetzungFilterParameters = Pick<
    GetFestsetzungsAntragOptions,
    'zustaendigerBenutzer' | 'status2' | 'berechnungsart' | 'antragJahr'
>;

export const getFestsetzungSortParameters = (sortOrder: MUISortOptions | undefined): GetFestsetzungSortParameters => {
    return {
        orderZuletztEingereichtAt: sortBy(sortOrder, 'zuletztEingereichtAt'),
        orderField500: sortBy(sortOrder, 'jahr'),
        orderEinrichtungAktenzeichen: sortBy(sortOrder, 'aktenzeichen'),
        orderFeststellungsantragBerechnungsart: sortBy(sortOrder, 'berechnungsart'),
        orderEinrichtungName: sortBy(sortOrder, 'einrichtung'),
        orderStatus: sortBy(sortOrder, 'status'),
        orderEinrichtungZustaendigerBenutzer: sortBy(sortOrder, 'zustaendigerBenutzer'),
    };
};

export const getFestsetzungFilterParameters = (filters: TableFilters | undefined): GetFestsetzungFilterParameters => {
    return {
        zustaendigerBenutzer: zustaendigerBenutzerFilter(filters),
        status2: filters?.['status'],
        berechnungsart: filters?.['berechnungsart'],
        antragJahr: filters?.['jahr'],
    };
};
