import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { AntragBase, AntragEinrichtungBase } from 'api/antragTypes';
import { useHasRole } from 'api/auth/useHasRole';
import { Benutzer } from 'components/AntragInfoBox/components/BenutzerDialog';
import { ROLES } from 'constants/roles';

interface InfoBoxData {
    einrichtung?: AntragEinrichtungBase;
    ansprechpartner?: Benutzer | undefined;
}

export type AntragGetter = (id: string | number) => Promise<AntragBase>;

export const useAntragInfoBoxData = (getAntragById: AntragGetter): InfoBoxData => {
    const { id } = useParams();
    const [infoBoxData, setInfoBoxData] = useState<InfoBoxData>({});

    const [isAnbieter, isLV] = useHasRole(ROLES.ANBIETER, ROLES.LV);
    const determineAnsprechpartner = useCallback(
        (antrag: AntragBase): Benutzer | undefined => {
            if (isAnbieter) return antrag.einrichtung?.zustaendigerBenutzer || undefined;
            if (isLV) return antrag.zuletztEingereichtBy || antrag.eingereichtBy || undefined;
            return undefined;
        },
        [isAnbieter, isLV]
    );

    useEffect(() => {
        if (id) {
            getAntragById(id)
                .then((antrag) => {
                    setInfoBoxData({
                        einrichtung: antrag.einrichtung,
                        ansprechpartner: determineAnsprechpartner(antrag),
                    });
                })
                .catch(console.error);
        }
    }, [id, getAntragById, determineAnsprechpartner]);

    return infoBoxData ?? {};
};
