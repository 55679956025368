import React, { useEffect, useState } from 'react';
import { JsonForms } from '@jsonforms/react';
import { Alert, FormControl } from '@mui/material';

import { backendApiService, PostMassenmailRequest } from 'api/ApiService';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { FormButton } from 'forms/components/FormButton';
import { Pflichtfeld } from 'forms/components/Pflichtfeld';
import { useFormValidation } from 'forms/hooks/useFormValidation';
import { useLayoutWithJsonFormsState } from 'forms/hooks/useJsonFormsState';
import { useSchemaFromJsonLdContext } from 'forms/hooks/useSchemaFromJsonLdContext';
import { renderers } from 'forms/renderers';
import { FormLayoutConfig, FormStateChange } from 'forms/types/UiSchemaTypes';
import uiSchema from 'pages/Verwaltung/Massenmail/ui-schema.json';

interface MassenmailFormPropsInterface {
    onSubmit(): void;

    onChange(data: FormDataType, valid: boolean): void;

    submitButtonRef: React.Ref<HTMLButtonElement>;
    children?: React.ReactNode;
}

export type FormDataType = {
    empfaengerGruppe: string | undefined;
    betreff: string | undefined;
    inhalt: string | undefined;
    einrichtungsart: [];
    einrichtungstyp: [];
    pflegewohngeld: [];
    uploads?: [];
};

const formData: FormDataType = {
    empfaengerGruppe: undefined,
    betreff: undefined,
    inhalt: undefined,
    einrichtungsart: [],
    einrichtungstyp: [],
    pflegewohngeld: [],
    uploads: [],
};

export const Form = ({ onSubmit, onChange, submitButtonRef }: MassenmailFormPropsInterface) => {
    const configLayout: FormLayoutConfig = {
        gridLayout: {
            label: 3,
            input: 8,
        },
    };

    const config = useLayoutWithJsonFormsState(configLayout);

    const [data, setData] = useState<FormDataType>(formData);
    const [isSubmitting, setSubmitting] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const { schema } = useSchemaFromJsonLdContext('Massenmail', data);
    const { valid } = useFormValidation(schema, data);

    useEffect(() => {
        onChange(data, valid);
    }, [onChange, data, valid]);

    const handleSubmit = () => {
        if (isSubmitting) {
            return;
        }

        setSubmitting(true);
        setErrorMessage(undefined);

        backendApiService
            .postMassenmail({
                ...data,
            } as PostMassenmailRequest)
            .then(() => {
                setData(formData);
                onSubmit();
            })
            .catch((error) => {
                console.error(error);
                setErrorMessage('Hinzufügen fehlgeschlagen.');
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const handleChange = (data: FormStateChange) => {
        setData(data.data);
        onChange(data.data, valid);
    };

    return (
        <AppLoaderContainer isLoading={!schema}>
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            {schema && (
                <>
                    <Pflichtfeld>* Pflichtfeld</Pflichtfeld>
                    <JsonForms
                        schema={schema}
                        uischema={uiSchema}
                        data={data}
                        renderers={renderers}
                        onChange={handleChange}
                        config={config}
                    />
                    <FormControl margin="normal">
                        <FormButton
                            ref={submitButtonRef}
                            type="submit"
                            onClick={handleSubmit}
                            style={{ display: 'none' }}
                        >
                            Jetzt versenden
                        </FormButton>
                    </FormControl>
                </>
            )}
        </AppLoaderContainer>
    );
};
