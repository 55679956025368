import React, { ReactElement, SyntheticEvent, useMemo, useState } from 'react';
import { Autocomplete, styled, TextField, TypographyVariant } from '@mui/material';

import { useApiEinrichtungCollection } from 'api/hooks/useApiClient';
import { EinrichtungListItemResponse, EinrichtungResponse } from 'api/types';
import { STATUS as EinrichtungStatus } from 'constants/einrichtungStatus';
import { transientOptions } from 'theme/utils';
import { useDebounce } from 'utilities/hooks';

type PflegeeinrichtungDropdownProps = {
    value: EinrichtungResponse | undefined;
    onChange: (einrichtung: EinrichtungListItemResponse | undefined) => void;
    label?: string;
    placeholder?: string;
    variant?: TypographyVariant;
};

export const PflegeEinrichtungDropdown = ({
    value,
    onChange,
    label,
    placeholder,
    variant = 'body1',
}: PflegeeinrichtungDropdownProps): ReactElement => {
    const [query, setQuery] = useState('');
    const debouncedQuery = useDebounce(query, 400);
    const qSearch = useMemo(() => (debouncedQuery.length > 2 ? [debouncedQuery] : undefined), [debouncedQuery]);
    const { data: einrichtungen, isLoading } = useApiEinrichtungCollection({
        page: 1,
        itemsPerPage: 10,
        qSearch,
        status2: einrichtungStatus,
    });

    const onInputChange = (event: SyntheticEvent, value: string) => {
        setQuery(value);
    };

    const onChangeBase = (e: SyntheticEvent, newValue: EinrichtungListItemResponse | undefined) => {
        onChange(newValue);
    };

    return (
        <Autocomplete
            inputValue={query}
            onInputChange={onInputChange}
            value={value}
            loading={isLoading}
            options={einrichtungen ? einrichtungen['hydra:member'] : []}
            onChange={onChangeBase}
            getOptionLabel={(einrichtung) => einrichtung.name || ''}
            isOptionEqualToValue={(einrichtung, currentValue) => einrichtung.id === currentValue?.id}
            renderInput={(params) => (
                <PflegeeinrichtungDropdownTextfield
                    {...params}
                    $size={variant}
                    placeholder={placeholder}
                    label={label}
                    fullWidth
                />
            )}
            disableClearable
        />
    );
};

const PflegeeinrichtungDropdownTextfield = styled(
    TextField,
    transientOptions
)<{ $size: TypographyVariant }>(({ theme, $size }) => ({
    '& input': {
        ...theme.typography[$size],
    },
}));

const einrichtungStatus = [
    EinrichtungStatus.AKTIV,
    EinrichtungStatus.RUHEND_VERZICHT_AUF_PFLEGEWOHNGELD,
    EinrichtungStatus.REGISTRIERUNG,
];
