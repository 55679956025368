import { DezimalNumberProps, Dezimalzahl } from './Dezimalzahl';

export type BetragProps = DezimalNumberProps & {
    unit: string;
};

export const DezimalzahlUnit = ({ value, unit }: BetragProps) => (
    <>
        <Dezimalzahl value={value} /> {unit}
    </>
);
