/* tslint:disable */
/* eslint-disable */
/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface AbstractBescheidDokumentFestsetzungsantragList
 */
export interface AbstractBescheidDokumentFestsetzungsantragList {
    /**
     *
     * @type {number}
     * @memberof AbstractBescheidDokumentFestsetzungsantragList
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidDokumentFestsetzungsantragList
     */
    typ?: string | null;
    /**
     *
     * @type {UploadFestsetzungsantragList}
     * @memberof AbstractBescheidDokumentFestsetzungsantragList
     */
    upload?: UploadFestsetzungsantragList | null;
    /**
     *
     * @type {GeneriertesDokumentFestsetzungsantragList}
     * @memberof AbstractBescheidDokumentFestsetzungsantragList
     */
    generiertesDokument?: GeneriertesDokumentFestsetzungsantragList | null;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidDokumentFestsetzungsantragList
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface AbstractBescheidDokumentFestsetzungsantragRead
 */
export interface AbstractBescheidDokumentFestsetzungsantragRead {
    /**
     *
     * @type {number}
     * @memberof AbstractBescheidDokumentFestsetzungsantragRead
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidDokumentFestsetzungsantragRead
     */
    typ?: string | null;
    /**
     *
     * @type {UploadFestsetzungsantragRead}
     * @memberof AbstractBescheidDokumentFestsetzungsantragRead
     */
    upload?: UploadFestsetzungsantragRead | null;
    /**
     *
     * @type {GeneriertesDokumentFestsetzungsantragRead}
     * @memberof AbstractBescheidDokumentFestsetzungsantragRead
     */
    generiertesDokument?: GeneriertesDokumentFestsetzungsantragRead | null;
}
/**
 *
 * @export
 * @interface AbstractBescheidDokumentFeststellungsantragList
 */
export interface AbstractBescheidDokumentFeststellungsantragList {
    /**
     *
     * @type {number}
     * @memberof AbstractBescheidDokumentFeststellungsantragList
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidDokumentFeststellungsantragList
     */
    typ?: string | null;
    /**
     *
     * @type {UploadFeststellungsantragList}
     * @memberof AbstractBescheidDokumentFeststellungsantragList
     */
    upload?: UploadFeststellungsantragList | null;
    /**
     *
     * @type {GeneriertesDokumentFeststellungsantragList}
     * @memberof AbstractBescheidDokumentFeststellungsantragList
     */
    generiertesDokument?: GeneriertesDokumentFeststellungsantragList | null;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidDokumentFeststellungsantragList
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface AbstractBescheidDokumentFeststellungsantragRead
 */
export interface AbstractBescheidDokumentFeststellungsantragRead {
    /**
     *
     * @type {number}
     * @memberof AbstractBescheidDokumentFeststellungsantragRead
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidDokumentFeststellungsantragRead
     */
    typ?: string | null;
    /**
     *
     * @type {UploadFeststellungsantragRead}
     * @memberof AbstractBescheidDokumentFeststellungsantragRead
     */
    upload?: UploadFeststellungsantragRead | null;
    /**
     *
     * @type {GeneriertesDokumentFeststellungsantragRead}
     * @memberof AbstractBescheidDokumentFeststellungsantragRead
     */
    generiertesDokument?: GeneriertesDokumentFeststellungsantragRead | null;
}
/**
 *
 * @export
 * @interface AbstractBescheidDokumentInvestorenmodellList
 */
export interface AbstractBescheidDokumentInvestorenmodellList {
    /**
     *
     * @type {number}
     * @memberof AbstractBescheidDokumentInvestorenmodellList
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidDokumentInvestorenmodellList
     */
    typ?: string | null;
    /**
     *
     * @type {UploadInvestorenmodellList}
     * @memberof AbstractBescheidDokumentInvestorenmodellList
     */
    upload?: UploadInvestorenmodellList | null;
    /**
     *
     * @type {GeneriertesDokumentInvestorenmodellList}
     * @memberof AbstractBescheidDokumentInvestorenmodellList
     */
    generiertesDokument?: GeneriertesDokumentInvestorenmodellList | null;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidDokumentInvestorenmodellList
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface AbstractBescheidDokumentInvestorenmodellRead
 */
export interface AbstractBescheidDokumentInvestorenmodellRead {
    /**
     *
     * @type {number}
     * @memberof AbstractBescheidDokumentInvestorenmodellRead
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidDokumentInvestorenmodellRead
     */
    typ?: string | null;
    /**
     *
     * @type {UploadInvestorenmodellRead}
     * @memberof AbstractBescheidDokumentInvestorenmodellRead
     */
    upload?: UploadInvestorenmodellRead | null;
    /**
     *
     * @type {GeneriertesDokumentInvestorenmodellRead}
     * @memberof AbstractBescheidDokumentInvestorenmodellRead
     */
    generiertesDokument?: GeneriertesDokumentInvestorenmodellRead | null;
}
/**
 *
 * @export
 * @interface AbstractBescheidDokumentJsonldFestsetzungsantragList
 */
export interface AbstractBescheidDokumentJsonldFestsetzungsantragList {
    /**
     *
     * @type {string | any}
     * @memberof AbstractBescheidDokumentJsonldFestsetzungsantragList
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidDokumentJsonldFestsetzungsantragList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidDokumentJsonldFestsetzungsantragList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof AbstractBescheidDokumentJsonldFestsetzungsantragList
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidDokumentJsonldFestsetzungsantragList
     */
    typ?: string | null;
    /**
     *
     * @type {UploadJsonldFestsetzungsantragList}
     * @memberof AbstractBescheidDokumentJsonldFestsetzungsantragList
     */
    upload?: UploadJsonldFestsetzungsantragList | null;
    /**
     *
     * @type {GeneriertesDokumentJsonldFestsetzungsantragList}
     * @memberof AbstractBescheidDokumentJsonldFestsetzungsantragList
     */
    generiertesDokument?: GeneriertesDokumentJsonldFestsetzungsantragList | null;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidDokumentJsonldFestsetzungsantragList
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface AbstractBescheidDokumentJsonldFestsetzungsantragRead
 */
export interface AbstractBescheidDokumentJsonldFestsetzungsantragRead {
    /**
     *
     * @type {string | any}
     * @memberof AbstractBescheidDokumentJsonldFestsetzungsantragRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidDokumentJsonldFestsetzungsantragRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidDokumentJsonldFestsetzungsantragRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof AbstractBescheidDokumentJsonldFestsetzungsantragRead
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidDokumentJsonldFestsetzungsantragRead
     */
    typ?: string | null;
    /**
     *
     * @type {UploadJsonldFestsetzungsantragRead}
     * @memberof AbstractBescheidDokumentJsonldFestsetzungsantragRead
     */
    upload?: UploadJsonldFestsetzungsantragRead | null;
    /**
     *
     * @type {GeneriertesDokumentJsonldFestsetzungsantragRead}
     * @memberof AbstractBescheidDokumentJsonldFestsetzungsantragRead
     */
    generiertesDokument?: GeneriertesDokumentJsonldFestsetzungsantragRead | null;
}
/**
 *
 * @export
 * @interface AbstractBescheidDokumentJsonldFeststellungsantragList
 */
export interface AbstractBescheidDokumentJsonldFeststellungsantragList {
    /**
     *
     * @type {string | any}
     * @memberof AbstractBescheidDokumentJsonldFeststellungsantragList
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidDokumentJsonldFeststellungsantragList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidDokumentJsonldFeststellungsantragList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof AbstractBescheidDokumentJsonldFeststellungsantragList
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidDokumentJsonldFeststellungsantragList
     */
    typ?: string | null;
    /**
     *
     * @type {UploadJsonldFeststellungsantragList}
     * @memberof AbstractBescheidDokumentJsonldFeststellungsantragList
     */
    upload?: UploadJsonldFeststellungsantragList | null;
    /**
     *
     * @type {GeneriertesDokumentJsonldFeststellungsantragList}
     * @memberof AbstractBescheidDokumentJsonldFeststellungsantragList
     */
    generiertesDokument?: GeneriertesDokumentJsonldFeststellungsantragList | null;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidDokumentJsonldFeststellungsantragList
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface AbstractBescheidDokumentJsonldFeststellungsantragRead
 */
export interface AbstractBescheidDokumentJsonldFeststellungsantragRead {
    /**
     *
     * @type {string | any}
     * @memberof AbstractBescheidDokumentJsonldFeststellungsantragRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidDokumentJsonldFeststellungsantragRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidDokumentJsonldFeststellungsantragRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof AbstractBescheidDokumentJsonldFeststellungsantragRead
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidDokumentJsonldFeststellungsantragRead
     */
    typ?: string | null;
    /**
     *
     * @type {UploadJsonldFeststellungsantragRead}
     * @memberof AbstractBescheidDokumentJsonldFeststellungsantragRead
     */
    upload?: UploadJsonldFeststellungsantragRead | null;
    /**
     *
     * @type {GeneriertesDokumentJsonldFeststellungsantragRead}
     * @memberof AbstractBescheidDokumentJsonldFeststellungsantragRead
     */
    generiertesDokument?: GeneriertesDokumentJsonldFeststellungsantragRead | null;
}
/**
 *
 * @export
 * @interface AbstractBescheidDokumentJsonldInvestorenmodellList
 */
export interface AbstractBescheidDokumentJsonldInvestorenmodellList {
    /**
     *
     * @type {string | any}
     * @memberof AbstractBescheidDokumentJsonldInvestorenmodellList
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidDokumentJsonldInvestorenmodellList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidDokumentJsonldInvestorenmodellList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof AbstractBescheidDokumentJsonldInvestorenmodellList
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidDokumentJsonldInvestorenmodellList
     */
    typ?: string | null;
    /**
     *
     * @type {UploadJsonldInvestorenmodellList}
     * @memberof AbstractBescheidDokumentJsonldInvestorenmodellList
     */
    upload?: UploadJsonldInvestorenmodellList | null;
    /**
     *
     * @type {GeneriertesDokumentJsonldInvestorenmodellList}
     * @memberof AbstractBescheidDokumentJsonldInvestorenmodellList
     */
    generiertesDokument?: GeneriertesDokumentJsonldInvestorenmodellList | null;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidDokumentJsonldInvestorenmodellList
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface AbstractBescheidDokumentJsonldInvestorenmodellRead
 */
export interface AbstractBescheidDokumentJsonldInvestorenmodellRead {
    /**
     *
     * @type {string | any}
     * @memberof AbstractBescheidDokumentJsonldInvestorenmodellRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidDokumentJsonldInvestorenmodellRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidDokumentJsonldInvestorenmodellRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof AbstractBescheidDokumentJsonldInvestorenmodellRead
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidDokumentJsonldInvestorenmodellRead
     */
    typ?: string | null;
    /**
     *
     * @type {UploadJsonldInvestorenmodellRead}
     * @memberof AbstractBescheidDokumentJsonldInvestorenmodellRead
     */
    upload?: UploadJsonldInvestorenmodellRead | null;
    /**
     *
     * @type {GeneriertesDokumentJsonldInvestorenmodellRead}
     * @memberof AbstractBescheidDokumentJsonldInvestorenmodellRead
     */
    generiertesDokument?: GeneriertesDokumentJsonldInvestorenmodellRead | null;
}
/**
 *
 * @export
 * @interface AbstractBescheidungEinrichtungRead
 */
export interface AbstractBescheidungEinrichtungRead {
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidungEinrichtungRead
     */
    gesendetAt: string | null;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidungEinrichtungRead
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface AbstractBescheidungFestsetzungsantragList
 */
export interface AbstractBescheidungFestsetzungsantragList {
    /**
     *
     * @type {number}
     * @memberof AbstractBescheidungFestsetzungsantragList
     */
    id?: number | null;
    /**
     *
     * @type {Array<AbstractBescheidDokumentFestsetzungsantragList>}
     * @memberof AbstractBescheidungFestsetzungsantragList
     */
    dokumente?: Array<AbstractBescheidDokumentFestsetzungsantragList>;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof AbstractBescheidungFestsetzungsantragList
     */
    versand?: { [key: string]: string };
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidungFestsetzungsantragList
     */
    gesendetAt: string | null;
    /**
     *
     * @type {boolean}
     * @memberof AbstractBescheidungFestsetzungsantragList
     */
    postalischerVersand?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidungFestsetzungsantragList
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerFestsetzungsantragList}
     * @memberof AbstractBescheidungFestsetzungsantragList
     */
    createdBy?: BenutzerFestsetzungsantragList | null;
}
/**
 *
 * @export
 * @interface AbstractBescheidungFestsetzungsantragRead
 */
export interface AbstractBescheidungFestsetzungsantragRead {
    /**
     *
     * @type {number}
     * @memberof AbstractBescheidungFestsetzungsantragRead
     */
    id?: number | null;
    /**
     *
     * @type {Array<AbstractBescheidDokumentFestsetzungsantragRead>}
     * @memberof AbstractBescheidungFestsetzungsantragRead
     */
    dokumente?: Array<AbstractBescheidDokumentFestsetzungsantragRead>;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidungFestsetzungsantragRead
     */
    gesendetAt: string | null;
}
/**
 *
 * @export
 * @interface AbstractBescheidungFeststellungsantragList
 */
export interface AbstractBescheidungFeststellungsantragList {
    /**
     *
     * @type {number}
     * @memberof AbstractBescheidungFeststellungsantragList
     */
    id?: number | null;
    /**
     *
     * @type {Array<AbstractBescheidDokumentFeststellungsantragList>}
     * @memberof AbstractBescheidungFeststellungsantragList
     */
    dokumente?: Array<AbstractBescheidDokumentFeststellungsantragList>;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof AbstractBescheidungFeststellungsantragList
     */
    versand?: { [key: string]: string };
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidungFeststellungsantragList
     */
    gesendetAt: string | null;
    /**
     *
     * @type {boolean}
     * @memberof AbstractBescheidungFeststellungsantragList
     */
    postalischerVersand?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidungFeststellungsantragList
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerFeststellungsantragList}
     * @memberof AbstractBescheidungFeststellungsantragList
     */
    createdBy?: BenutzerFeststellungsantragList | null;
}
/**
 *
 * @export
 * @interface AbstractBescheidungFeststellungsantragRead
 */
export interface AbstractBescheidungFeststellungsantragRead {
    /**
     *
     * @type {number}
     * @memberof AbstractBescheidungFeststellungsantragRead
     */
    id?: number | null;
    /**
     *
     * @type {Array<AbstractBescheidDokumentFeststellungsantragRead>}
     * @memberof AbstractBescheidungFeststellungsantragRead
     */
    dokumente?: Array<AbstractBescheidDokumentFeststellungsantragRead>;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidungFeststellungsantragRead
     */
    gesendetAt: string | null;
}
/**
 *
 * @export
 * @interface AbstractBescheidungInvestorenmodellList
 */
export interface AbstractBescheidungInvestorenmodellList {
    /**
     *
     * @type {number}
     * @memberof AbstractBescheidungInvestorenmodellList
     */
    id?: number | null;
    /**
     *
     * @type {Array<AbstractBescheidDokumentInvestorenmodellList>}
     * @memberof AbstractBescheidungInvestorenmodellList
     */
    dokumente?: Array<AbstractBescheidDokumentInvestorenmodellList>;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof AbstractBescheidungInvestorenmodellList
     */
    versand?: { [key: string]: string };
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidungInvestorenmodellList
     */
    gesendetAt: string | null;
    /**
     *
     * @type {boolean}
     * @memberof AbstractBescheidungInvestorenmodellList
     */
    postalischerVersand?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidungInvestorenmodellList
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerInvestorenmodellList}
     * @memberof AbstractBescheidungInvestorenmodellList
     */
    createdBy?: BenutzerInvestorenmodellList | null;
}
/**
 *
 * @export
 * @interface AbstractBescheidungInvestorenmodellRead
 */
export interface AbstractBescheidungInvestorenmodellRead {
    /**
     *
     * @type {number}
     * @memberof AbstractBescheidungInvestorenmodellRead
     */
    id?: number | null;
    /**
     *
     * @type {Array<AbstractBescheidDokumentInvestorenmodellRead>}
     * @memberof AbstractBescheidungInvestorenmodellRead
     */
    dokumente?: Array<AbstractBescheidDokumentInvestorenmodellRead>;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidungInvestorenmodellRead
     */
    gesendetAt: string | null;
}
/**
 *
 * @export
 * @interface AbstractBescheidungJsonldEinrichtungRead
 */
export interface AbstractBescheidungJsonldEinrichtungRead {
    /**
     *
     * @type {string | any}
     * @memberof AbstractBescheidungJsonldEinrichtungRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidungJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidungJsonldEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidungJsonldEinrichtungRead
     */
    gesendetAt: string | null;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidungJsonldEinrichtungRead
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface AbstractBescheidungJsonldFestsetzungsantragList
 */
export interface AbstractBescheidungJsonldFestsetzungsantragList {
    /**
     *
     * @type {string | any}
     * @memberof AbstractBescheidungJsonldFestsetzungsantragList
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidungJsonldFestsetzungsantragList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidungJsonldFestsetzungsantragList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof AbstractBescheidungJsonldFestsetzungsantragList
     */
    id?: number | null;
    /**
     *
     * @type {Array<AbstractBescheidDokumentJsonldFestsetzungsantragList>}
     * @memberof AbstractBescheidungJsonldFestsetzungsantragList
     */
    dokumente?: Array<AbstractBescheidDokumentJsonldFestsetzungsantragList>;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof AbstractBescheidungJsonldFestsetzungsantragList
     */
    versand?: { [key: string]: string };
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidungJsonldFestsetzungsantragList
     */
    gesendetAt: string | null;
    /**
     *
     * @type {boolean}
     * @memberof AbstractBescheidungJsonldFestsetzungsantragList
     */
    postalischerVersand?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidungJsonldFestsetzungsantragList
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerJsonldFestsetzungsantragList}
     * @memberof AbstractBescheidungJsonldFestsetzungsantragList
     */
    createdBy?: BenutzerJsonldFestsetzungsantragList | null;
}
/**
 *
 * @export
 * @interface AbstractBescheidungJsonldFestsetzungsantragRead
 */
export interface AbstractBescheidungJsonldFestsetzungsantragRead {
    /**
     *
     * @type {string | any}
     * @memberof AbstractBescheidungJsonldFestsetzungsantragRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidungJsonldFestsetzungsantragRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidungJsonldFestsetzungsantragRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof AbstractBescheidungJsonldFestsetzungsantragRead
     */
    id?: number | null;
    /**
     *
     * @type {Array<AbstractBescheidDokumentJsonldFestsetzungsantragRead>}
     * @memberof AbstractBescheidungJsonldFestsetzungsantragRead
     */
    dokumente?: Array<AbstractBescheidDokumentJsonldFestsetzungsantragRead>;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidungJsonldFestsetzungsantragRead
     */
    gesendetAt: string | null;
}
/**
 *
 * @export
 * @interface AbstractBescheidungJsonldFeststellungsantragList
 */
export interface AbstractBescheidungJsonldFeststellungsantragList {
    /**
     *
     * @type {string | any}
     * @memberof AbstractBescheidungJsonldFeststellungsantragList
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidungJsonldFeststellungsantragList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidungJsonldFeststellungsantragList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof AbstractBescheidungJsonldFeststellungsantragList
     */
    id?: number | null;
    /**
     *
     * @type {Array<AbstractBescheidDokumentJsonldFeststellungsantragList>}
     * @memberof AbstractBescheidungJsonldFeststellungsantragList
     */
    dokumente?: Array<AbstractBescheidDokumentJsonldFeststellungsantragList>;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof AbstractBescheidungJsonldFeststellungsantragList
     */
    versand?: { [key: string]: string };
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidungJsonldFeststellungsantragList
     */
    gesendetAt: string | null;
    /**
     *
     * @type {boolean}
     * @memberof AbstractBescheidungJsonldFeststellungsantragList
     */
    postalischerVersand?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidungJsonldFeststellungsantragList
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerJsonldFeststellungsantragList}
     * @memberof AbstractBescheidungJsonldFeststellungsantragList
     */
    createdBy?: BenutzerJsonldFeststellungsantragList | null;
}
/**
 *
 * @export
 * @interface AbstractBescheidungJsonldFeststellungsantragRead
 */
export interface AbstractBescheidungJsonldFeststellungsantragRead {
    /**
     *
     * @type {string | any}
     * @memberof AbstractBescheidungJsonldFeststellungsantragRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidungJsonldFeststellungsantragRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidungJsonldFeststellungsantragRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof AbstractBescheidungJsonldFeststellungsantragRead
     */
    id?: number | null;
    /**
     *
     * @type {Array<AbstractBescheidDokumentJsonldFeststellungsantragRead>}
     * @memberof AbstractBescheidungJsonldFeststellungsantragRead
     */
    dokumente?: Array<AbstractBescheidDokumentJsonldFeststellungsantragRead>;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidungJsonldFeststellungsantragRead
     */
    gesendetAt: string | null;
}
/**
 *
 * @export
 * @interface AbstractBescheidungJsonldInvestorenmodellList
 */
export interface AbstractBescheidungJsonldInvestorenmodellList {
    /**
     *
     * @type {string | any}
     * @memberof AbstractBescheidungJsonldInvestorenmodellList
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidungJsonldInvestorenmodellList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidungJsonldInvestorenmodellList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof AbstractBescheidungJsonldInvestorenmodellList
     */
    id?: number | null;
    /**
     *
     * @type {Array<AbstractBescheidDokumentJsonldInvestorenmodellList>}
     * @memberof AbstractBescheidungJsonldInvestorenmodellList
     */
    dokumente?: Array<AbstractBescheidDokumentJsonldInvestorenmodellList>;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof AbstractBescheidungJsonldInvestorenmodellList
     */
    versand?: { [key: string]: string };
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidungJsonldInvestorenmodellList
     */
    gesendetAt: string | null;
    /**
     *
     * @type {boolean}
     * @memberof AbstractBescheidungJsonldInvestorenmodellList
     */
    postalischerVersand?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidungJsonldInvestorenmodellList
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerJsonldInvestorenmodellList}
     * @memberof AbstractBescheidungJsonldInvestorenmodellList
     */
    createdBy?: BenutzerJsonldInvestorenmodellList | null;
}
/**
 *
 * @export
 * @interface AbstractBescheidungJsonldInvestorenmodellRead
 */
export interface AbstractBescheidungJsonldInvestorenmodellRead {
    /**
     *
     * @type {string | any}
     * @memberof AbstractBescheidungJsonldInvestorenmodellRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidungJsonldInvestorenmodellRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidungJsonldInvestorenmodellRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof AbstractBescheidungJsonldInvestorenmodellRead
     */
    id?: number | null;
    /**
     *
     * @type {Array<AbstractBescheidDokumentJsonldInvestorenmodellRead>}
     * @memberof AbstractBescheidungJsonldInvestorenmodellRead
     */
    dokumente?: Array<AbstractBescheidDokumentJsonldInvestorenmodellRead>;
    /**
     *
     * @type {string}
     * @memberof AbstractBescheidungJsonldInvestorenmodellRead
     */
    gesendetAt: string | null;
}
/**
 *
 * @export
 * @interface AbstractGegenpruefungFestsetzungsantragRead
 */
export interface AbstractGegenpruefungFestsetzungsantragRead {
    /**
     *
     * @type {number}
     * @memberof AbstractGegenpruefungFestsetzungsantragRead
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof AbstractGegenpruefungFestsetzungsantragRead
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof AbstractGegenpruefungFestsetzungsantragRead
     */
    erlaeuterung?: string | null;
}
/**
 *
 * @export
 * @interface AbstractGegenpruefungFeststellungsantragRead
 */
export interface AbstractGegenpruefungFeststellungsantragRead {
    /**
     *
     * @type {number}
     * @memberof AbstractGegenpruefungFeststellungsantragRead
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof AbstractGegenpruefungFeststellungsantragRead
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof AbstractGegenpruefungFeststellungsantragRead
     */
    erlaeuterung?: string | null;
}
/**
 *
 * @export
 * @interface AbstractGegenpruefungInvestorenmodellRead
 */
export interface AbstractGegenpruefungInvestorenmodellRead {
    /**
     *
     * @type {number}
     * @memberof AbstractGegenpruefungInvestorenmodellRead
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof AbstractGegenpruefungInvestorenmodellRead
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof AbstractGegenpruefungInvestorenmodellRead
     */
    erlaeuterung?: string | null;
}
/**
 *
 * @export
 * @interface AbstractGegenpruefungJsonldFestsetzungsantragRead
 */
export interface AbstractGegenpruefungJsonldFestsetzungsantragRead {
    /**
     *
     * @type {string | any}
     * @memberof AbstractGegenpruefungJsonldFestsetzungsantragRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof AbstractGegenpruefungJsonldFestsetzungsantragRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AbstractGegenpruefungJsonldFestsetzungsantragRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof AbstractGegenpruefungJsonldFestsetzungsantragRead
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof AbstractGegenpruefungJsonldFestsetzungsantragRead
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof AbstractGegenpruefungJsonldFestsetzungsantragRead
     */
    erlaeuterung?: string | null;
}
/**
 *
 * @export
 * @interface AbstractGegenpruefungJsonldFeststellungsantragRead
 */
export interface AbstractGegenpruefungJsonldFeststellungsantragRead {
    /**
     *
     * @type {string | any}
     * @memberof AbstractGegenpruefungJsonldFeststellungsantragRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof AbstractGegenpruefungJsonldFeststellungsantragRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AbstractGegenpruefungJsonldFeststellungsantragRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof AbstractGegenpruefungJsonldFeststellungsantragRead
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof AbstractGegenpruefungJsonldFeststellungsantragRead
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof AbstractGegenpruefungJsonldFeststellungsantragRead
     */
    erlaeuterung?: string | null;
}
/**
 *
 * @export
 * @interface AbstractGegenpruefungJsonldInvestorenmodellRead
 */
export interface AbstractGegenpruefungJsonldInvestorenmodellRead {
    /**
     *
     * @type {string | any}
     * @memberof AbstractGegenpruefungJsonldInvestorenmodellRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof AbstractGegenpruefungJsonldInvestorenmodellRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AbstractGegenpruefungJsonldInvestorenmodellRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof AbstractGegenpruefungJsonldInvestorenmodellRead
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof AbstractGegenpruefungJsonldInvestorenmodellRead
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof AbstractGegenpruefungJsonldInvestorenmodellRead
     */
    erlaeuterung?: string | null;
}
/**
 *
 * @export
 * @interface AbstractZurueckziehenFestsetzungsantragList
 */
export interface AbstractZurueckziehenFestsetzungsantragList {
    /**
     *
     * @type {number}
     * @memberof AbstractZurueckziehenFestsetzungsantragList
     */
    id: number | null;
    /**
     *
     * @type {string}
     * @memberof AbstractZurueckziehenFestsetzungsantragList
     */
    erlaeuterung: string | null;
    /**
     *
     * @type {BenutzerFestsetzungsantragList}
     * @memberof AbstractZurueckziehenFestsetzungsantragList
     */
    entschiedenBy?: BenutzerFestsetzungsantragList | null;
    /**
     *
     * @type {string}
     * @memberof AbstractZurueckziehenFestsetzungsantragList
     */
    entschiedenAt?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof AbstractZurueckziehenFestsetzungsantragList
     */
    entscheidung?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof AbstractZurueckziehenFestsetzungsantragList
     */
    erlaeuterungEntscheidung?: string | null;
    /**
     *
     * @type {string}
     * @memberof AbstractZurueckziehenFestsetzungsantragList
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerFestsetzungsantragList}
     * @memberof AbstractZurueckziehenFestsetzungsantragList
     */
    createdBy?: BenutzerFestsetzungsantragList | null;
}
/**
 *
 * @export
 * @interface AbstractZurueckziehenFeststellungsantragList
 */
export interface AbstractZurueckziehenFeststellungsantragList {
    /**
     *
     * @type {number}
     * @memberof AbstractZurueckziehenFeststellungsantragList
     */
    id: number | null;
    /**
     *
     * @type {string}
     * @memberof AbstractZurueckziehenFeststellungsantragList
     */
    erlaeuterung: string | null;
    /**
     *
     * @type {BenutzerFeststellungsantragList}
     * @memberof AbstractZurueckziehenFeststellungsantragList
     */
    entschiedenBy?: BenutzerFeststellungsantragList | null;
    /**
     *
     * @type {string}
     * @memberof AbstractZurueckziehenFeststellungsantragList
     */
    entschiedenAt?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof AbstractZurueckziehenFeststellungsantragList
     */
    entscheidung?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof AbstractZurueckziehenFeststellungsantragList
     */
    erlaeuterungEntscheidung?: string | null;
    /**
     *
     * @type {string}
     * @memberof AbstractZurueckziehenFeststellungsantragList
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerFeststellungsantragList}
     * @memberof AbstractZurueckziehenFeststellungsantragList
     */
    createdBy?: BenutzerFeststellungsantragList | null;
}
/**
 *
 * @export
 * @interface AbstractZurueckziehenInvestorenmodellList
 */
export interface AbstractZurueckziehenInvestorenmodellList {
    /**
     *
     * @type {number}
     * @memberof AbstractZurueckziehenInvestorenmodellList
     */
    id: number | null;
    /**
     *
     * @type {string}
     * @memberof AbstractZurueckziehenInvestorenmodellList
     */
    erlaeuterung: string | null;
    /**
     *
     * @type {BenutzerInvestorenmodellList}
     * @memberof AbstractZurueckziehenInvestorenmodellList
     */
    entschiedenBy?: BenutzerInvestorenmodellList | null;
    /**
     *
     * @type {string}
     * @memberof AbstractZurueckziehenInvestorenmodellList
     */
    entschiedenAt?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof AbstractZurueckziehenInvestorenmodellList
     */
    entscheidung?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof AbstractZurueckziehenInvestorenmodellList
     */
    erlaeuterungEntscheidung?: string | null;
    /**
     *
     * @type {string}
     * @memberof AbstractZurueckziehenInvestorenmodellList
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerInvestorenmodellList}
     * @memberof AbstractZurueckziehenInvestorenmodellList
     */
    createdBy?: BenutzerInvestorenmodellList | null;
}
/**
 *
 * @export
 * @interface AbstractZurueckziehenJsonldFestsetzungsantragList
 */
export interface AbstractZurueckziehenJsonldFestsetzungsantragList {
    /**
     *
     * @type {string | any}
     * @memberof AbstractZurueckziehenJsonldFestsetzungsantragList
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof AbstractZurueckziehenJsonldFestsetzungsantragList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AbstractZurueckziehenJsonldFestsetzungsantragList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof AbstractZurueckziehenJsonldFestsetzungsantragList
     */
    id: number | null;
    /**
     *
     * @type {string}
     * @memberof AbstractZurueckziehenJsonldFestsetzungsantragList
     */
    erlaeuterung: string | null;
    /**
     *
     * @type {BenutzerJsonldFestsetzungsantragList}
     * @memberof AbstractZurueckziehenJsonldFestsetzungsantragList
     */
    entschiedenBy?: BenutzerJsonldFestsetzungsantragList | null;
    /**
     *
     * @type {string}
     * @memberof AbstractZurueckziehenJsonldFestsetzungsantragList
     */
    entschiedenAt?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof AbstractZurueckziehenJsonldFestsetzungsantragList
     */
    entscheidung?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof AbstractZurueckziehenJsonldFestsetzungsantragList
     */
    erlaeuterungEntscheidung?: string | null;
    /**
     *
     * @type {string}
     * @memberof AbstractZurueckziehenJsonldFestsetzungsantragList
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerJsonldFestsetzungsantragList}
     * @memberof AbstractZurueckziehenJsonldFestsetzungsantragList
     */
    createdBy?: BenutzerJsonldFestsetzungsantragList | null;
}
/**
 *
 * @export
 * @interface AbstractZurueckziehenJsonldFeststellungsantragList
 */
export interface AbstractZurueckziehenJsonldFeststellungsantragList {
    /**
     *
     * @type {string | any}
     * @memberof AbstractZurueckziehenJsonldFeststellungsantragList
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof AbstractZurueckziehenJsonldFeststellungsantragList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AbstractZurueckziehenJsonldFeststellungsantragList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof AbstractZurueckziehenJsonldFeststellungsantragList
     */
    id: number | null;
    /**
     *
     * @type {string}
     * @memberof AbstractZurueckziehenJsonldFeststellungsantragList
     */
    erlaeuterung: string | null;
    /**
     *
     * @type {BenutzerJsonldFeststellungsantragList}
     * @memberof AbstractZurueckziehenJsonldFeststellungsantragList
     */
    entschiedenBy?: BenutzerJsonldFeststellungsantragList | null;
    /**
     *
     * @type {string}
     * @memberof AbstractZurueckziehenJsonldFeststellungsantragList
     */
    entschiedenAt?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof AbstractZurueckziehenJsonldFeststellungsantragList
     */
    entscheidung?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof AbstractZurueckziehenJsonldFeststellungsantragList
     */
    erlaeuterungEntscheidung?: string | null;
    /**
     *
     * @type {string}
     * @memberof AbstractZurueckziehenJsonldFeststellungsantragList
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerJsonldFeststellungsantragList}
     * @memberof AbstractZurueckziehenJsonldFeststellungsantragList
     */
    createdBy?: BenutzerJsonldFeststellungsantragList | null;
}
/**
 *
 * @export
 * @interface AbstractZurueckziehenJsonldInvestorenmodellList
 */
export interface AbstractZurueckziehenJsonldInvestorenmodellList {
    /**
     *
     * @type {string | any}
     * @memberof AbstractZurueckziehenJsonldInvestorenmodellList
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof AbstractZurueckziehenJsonldInvestorenmodellList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AbstractZurueckziehenJsonldInvestorenmodellList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof AbstractZurueckziehenJsonldInvestorenmodellList
     */
    id: number | null;
    /**
     *
     * @type {string}
     * @memberof AbstractZurueckziehenJsonldInvestorenmodellList
     */
    erlaeuterung: string | null;
    /**
     *
     * @type {BenutzerJsonldInvestorenmodellList}
     * @memberof AbstractZurueckziehenJsonldInvestorenmodellList
     */
    entschiedenBy?: BenutzerJsonldInvestorenmodellList | null;
    /**
     *
     * @type {string}
     * @memberof AbstractZurueckziehenJsonldInvestorenmodellList
     */
    entschiedenAt?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof AbstractZurueckziehenJsonldInvestorenmodellList
     */
    entscheidung?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof AbstractZurueckziehenJsonldInvestorenmodellList
     */
    erlaeuterungEntscheidung?: string | null;
    /**
     *
     * @type {string}
     * @memberof AbstractZurueckziehenJsonldInvestorenmodellList
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerJsonldInvestorenmodellList}
     * @memberof AbstractZurueckziehenJsonldInvestorenmodellList
     */
    createdBy?: BenutzerJsonldInvestorenmodellList | null;
}
/**
 *
 * @export
 * @interface Angemessenheitsgrenze
 */
export interface Angemessenheitsgrenze {
    /**
     *
     * @type {string}
     * @memberof Angemessenheitsgrenze
     */
    typ?: string;
    /**
     *
     * @type {number}
     * @memberof Angemessenheitsgrenze
     */
    jahr: number;
    /**
     *
     * @type {string}
     * @memberof Angemessenheitsgrenze
     */
    erlassVom?: string | null;
    /**
     *
     * @type {number}
     * @memberof Angemessenheitsgrenze
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof Angemessenheitsgrenze
     */
    value: string;
    /**
     *
     * @type {string}
     * @memberof Angemessenheitsgrenze
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof Angemessenheitsgrenze
     */
    modifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof Angemessenheitsgrenze
     */
    modifiedBy?: string | null;
}
/**
 *
 * @export
 * @interface AngemessenheitsgrenzeJsonld
 */
export interface AngemessenheitsgrenzeJsonld {
    /**
     *
     * @type {string | any}
     * @memberof AngemessenheitsgrenzeJsonld
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof AngemessenheitsgrenzeJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AngemessenheitsgrenzeJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof AngemessenheitsgrenzeJsonld
     */
    typ?: string;
    /**
     *
     * @type {number}
     * @memberof AngemessenheitsgrenzeJsonld
     */
    jahr: number;
    /**
     *
     * @type {string}
     * @memberof AngemessenheitsgrenzeJsonld
     */
    erlassVom?: string | null;
    /**
     *
     * @type {number}
     * @memberof AngemessenheitsgrenzeJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof AngemessenheitsgrenzeJsonld
     */
    value: string;
    /**
     *
     * @type {string}
     * @memberof AngemessenheitsgrenzeJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof AngemessenheitsgrenzeJsonld
     */
    modifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof AngemessenheitsgrenzeJsonld
     */
    modifiedBy?: string | null;
}
/**
 *
 * @export
 * @interface AusnahmegenehmigungP10Abs3Satz2Ziffer2ApgFeststellungsantragRead
 */
export interface AusnahmegenehmigungP10Abs3Satz2Ziffer2ApgFeststellungsantragRead {
    /**
     *
     * @type {string}
     * @memberof AusnahmegenehmigungP10Abs3Satz2Ziffer2ApgFeststellungsantragRead
     */
    field234?: string | null;
    /**
     *
     * @type {DokumentFeststellungsantragRead}
     * @memberof AusnahmegenehmigungP10Abs3Satz2Ziffer2ApgFeststellungsantragRead
     */
    field233d?: DokumentFeststellungsantragRead | null;
}
/**
 *
 * @export
 * @interface AusnahmegenehmigungP10Abs3Satz2Ziffer2ApgFeststellungsantragVersionRead
 */
export interface AusnahmegenehmigungP10Abs3Satz2Ziffer2ApgFeststellungsantragVersionRead {
    /**
     *
     * @type {string}
     * @memberof AusnahmegenehmigungP10Abs3Satz2Ziffer2ApgFeststellungsantragVersionRead
     */
    field234?: string | null;
    /**
     *
     * @type {DokumentFeststellungsantragVersionRead}
     * @memberof AusnahmegenehmigungP10Abs3Satz2Ziffer2ApgFeststellungsantragVersionRead
     */
    field233d?: DokumentFeststellungsantragVersionRead | null;
}
/**
 *
 * @export
 * @interface AusnahmegenehmigungP10Abs3Satz2Ziffer2ApgJsonldFeststellungsantragRead
 */
export interface AusnahmegenehmigungP10Abs3Satz2Ziffer2ApgJsonldFeststellungsantragRead {
    /**
     *
     * @type {string | any}
     * @memberof AusnahmegenehmigungP10Abs3Satz2Ziffer2ApgJsonldFeststellungsantragRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof AusnahmegenehmigungP10Abs3Satz2Ziffer2ApgJsonldFeststellungsantragRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AusnahmegenehmigungP10Abs3Satz2Ziffer2ApgJsonldFeststellungsantragRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof AusnahmegenehmigungP10Abs3Satz2Ziffer2ApgJsonldFeststellungsantragRead
     */
    field234?: string | null;
    /**
     *
     * @type {DokumentJsonldFeststellungsantragRead}
     * @memberof AusnahmegenehmigungP10Abs3Satz2Ziffer2ApgJsonldFeststellungsantragRead
     */
    field233d?: DokumentJsonldFeststellungsantragRead | null;
}
/**
 *
 * @export
 * @interface AusnahmegenehmigungP10Abs3Satz2Ziffer2ApgJsonldFeststellungsantragVersionRead
 */
export interface AusnahmegenehmigungP10Abs3Satz2Ziffer2ApgJsonldFeststellungsantragVersionRead {
    /**
     *
     * @type {string | any}
     * @memberof AusnahmegenehmigungP10Abs3Satz2Ziffer2ApgJsonldFeststellungsantragVersionRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof AusnahmegenehmigungP10Abs3Satz2Ziffer2ApgJsonldFeststellungsantragVersionRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AusnahmegenehmigungP10Abs3Satz2Ziffer2ApgJsonldFeststellungsantragVersionRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof AusnahmegenehmigungP10Abs3Satz2Ziffer2ApgJsonldFeststellungsantragVersionRead
     */
    field234?: string | null;
    /**
     *
     * @type {DokumentJsonldFeststellungsantragVersionRead}
     * @memberof AusnahmegenehmigungP10Abs3Satz2Ziffer2ApgJsonldFeststellungsantragVersionRead
     */
    field233d?: DokumentJsonldFeststellungsantragVersionRead | null;
}
/**
 *
 * @export
 * @interface Baupreisindex
 */
export interface Baupreisindex {
    /**
     *
     * @type {number}
     * @memberof Baupreisindex
     */
    basisjahr: number;
    /**
     *
     * @type {number}
     * @memberof Baupreisindex
     */
    jahr: number;
    /**
     *
     * @type {string}
     * @memberof Baupreisindex
     */
    value: string;
    /**
     *
     * @type {number}
     * @memberof Baupreisindex
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof Baupreisindex
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof Baupreisindex
     */
    modifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof Baupreisindex
     */
    modifiedBy?: string | null;
}
/**
 *
 * @export
 * @interface BaupreisindexJsonld
 */
export interface BaupreisindexJsonld {
    /**
     *
     * @type {string | any}
     * @memberof BaupreisindexJsonld
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof BaupreisindexJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BaupreisindexJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BaupreisindexJsonld
     */
    basisjahr: number;
    /**
     *
     * @type {number}
     * @memberof BaupreisindexJsonld
     */
    jahr: number;
    /**
     *
     * @type {string}
     * @memberof BaupreisindexJsonld
     */
    value: string;
    /**
     *
     * @type {number}
     * @memberof BaupreisindexJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BaupreisindexJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BaupreisindexJsonld
     */
    modifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof BaupreisindexJsonld
     */
    modifiedBy?: string | null;
}
/**
 *
 * @export
 * @interface BenutzerAufgabenOutputBenutzerAufgaben
 */
export interface BenutzerAufgabenOutputBenutzerAufgaben {
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputBenutzerAufgaben
     */
    feststellungBearbeiten?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputBenutzerAufgaben
     */
    festsetzungBearbeiten?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputBenutzerAufgaben
     */
    p8Abs11Bearbeiten?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputBenutzerAufgaben
     */
    feststellungPruefen?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputBenutzerAufgaben
     */
    festsetzungPruefen?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputBenutzerAufgaben
     */
    p8Abs11Pruefen?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputBenutzerAufgaben
     */
    feststellungFreigabeAbgelehnt?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputBenutzerAufgaben
     */
    festsetzungFreigabeAbgelehnt?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputBenutzerAufgaben
     */
    p8Abs11FreigabeAbgelehnt?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputBenutzerAufgaben
     */
    feststellungZurueckgezogen?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputBenutzerAufgaben
     */
    festsetzungZurueckgezogen?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputBenutzerAufgaben
     */
    p8Abs11Zurueckgezogen?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputBenutzerAufgaben
     */
    meldungenP29?: number | null;
}
/**
 *
 * @export
 * @interface BenutzerAufgabenOutputBenutzerLvAufgaben
 */
export interface BenutzerAufgabenOutputBenutzerLvAufgaben {
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputBenutzerLvAufgaben
     */
    feststellungBearbeiten?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputBenutzerLvAufgaben
     */
    festsetzungBearbeiten?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputBenutzerLvAufgaben
     */
    p8Abs11Bearbeiten?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputBenutzerLvAufgaben
     */
    feststellungPruefen?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputBenutzerLvAufgaben
     */
    festsetzungPruefen?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputBenutzerLvAufgaben
     */
    p8Abs11Pruefen?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputBenutzerLvAufgaben
     */
    feststellungFreigabeAbgelehnt?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputBenutzerLvAufgaben
     */
    festsetzungFreigabeAbgelehnt?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputBenutzerLvAufgaben
     */
    p8Abs11FreigabeAbgelehnt?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputBenutzerLvAufgaben
     */
    feststellungZurueckgezogen?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputBenutzerLvAufgaben
     */
    festsetzungZurueckgezogen?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputBenutzerLvAufgaben
     */
    p8Abs11Zurueckgezogen?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputBenutzerLvAufgaben
     */
    meldungenP29?: number | null;
}
/**
 *
 * @export
 * @interface BenutzerAufgabenOutputJsonldBenutzerAufgaben
 */
export interface BenutzerAufgabenOutputJsonldBenutzerAufgaben {
    /**
     *
     * @type {string | any}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerAufgaben
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerAufgaben
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerAufgaben
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerAufgaben
     */
    feststellungBearbeiten?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerAufgaben
     */
    festsetzungBearbeiten?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerAufgaben
     */
    p8Abs11Bearbeiten?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerAufgaben
     */
    feststellungPruefen?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerAufgaben
     */
    festsetzungPruefen?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerAufgaben
     */
    p8Abs11Pruefen?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerAufgaben
     */
    feststellungFreigabeAbgelehnt?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerAufgaben
     */
    festsetzungFreigabeAbgelehnt?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerAufgaben
     */
    p8Abs11FreigabeAbgelehnt?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerAufgaben
     */
    feststellungZurueckgezogen?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerAufgaben
     */
    festsetzungZurueckgezogen?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerAufgaben
     */
    p8Abs11Zurueckgezogen?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerAufgaben
     */
    meldungenP29?: number | null;
}
/**
 *
 * @export
 * @interface BenutzerAufgabenOutputJsonldBenutzerLvAufgaben
 */
export interface BenutzerAufgabenOutputJsonldBenutzerLvAufgaben {
    /**
     *
     * @type {string | any}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerLvAufgaben
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerLvAufgaben
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerLvAufgaben
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerLvAufgaben
     */
    feststellungBearbeiten?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerLvAufgaben
     */
    festsetzungBearbeiten?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerLvAufgaben
     */
    p8Abs11Bearbeiten?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerLvAufgaben
     */
    feststellungPruefen?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerLvAufgaben
     */
    festsetzungPruefen?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerLvAufgaben
     */
    p8Abs11Pruefen?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerLvAufgaben
     */
    feststellungFreigabeAbgelehnt?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerLvAufgaben
     */
    festsetzungFreigabeAbgelehnt?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerLvAufgaben
     */
    p8Abs11FreigabeAbgelehnt?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerLvAufgaben
     */
    feststellungZurueckgezogen?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerLvAufgaben
     */
    festsetzungZurueckgezogen?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerLvAufgaben
     */
    p8Abs11Zurueckgezogen?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerLvAufgaben
     */
    meldungenP29?: number | null;
}
/**
 *
 * @export
 * @interface BenutzerBenutzerCreate
 */
export interface BenutzerBenutzerCreate {
    /**
     *
     * @type {number}
     * @memberof BenutzerBenutzerCreate
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerCreate
     */
    rolle: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerCreate
     */
    anrede?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerCreate
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerCreate
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerCreate
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerCreate
     */
    landschaftsverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerCreate
     */
    traegerverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerCreate
     */
    traeger?: string | null;
}
/**
 *
 * @export
 * @interface BenutzerBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
 */
export interface BenutzerBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger {
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    rolle: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    password?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    plainPassword?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    anrede?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    telefon: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    fax: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    landschaftsverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    traegerverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    traeger?: string | null;
}
/**
 *
 * @export
 * @interface BenutzerBenutzerGetAllowedCreateRollenOutputBenutzerGetAllowedCreateRollen
 */
export interface BenutzerBenutzerGetAllowedCreateRollenOutputBenutzerGetAllowedCreateRollen {
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerGetAllowedCreateRollenOutputBenutzerGetAllowedCreateRollen
     */
    rollen?: string;
}
/**
 *
 * @export
 * @interface BenutzerBenutzerGetAllowedCreateRollenOutputJsonldBenutzerGetAllowedCreateRollen
 */
export interface BenutzerBenutzerGetAllowedCreateRollenOutputJsonldBenutzerGetAllowedCreateRollen {
    /**
     *
     * @type {string | any}
     * @memberof BenutzerBenutzerGetAllowedCreateRollenOutputJsonldBenutzerGetAllowedCreateRollen
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerGetAllowedCreateRollenOutputJsonldBenutzerGetAllowedCreateRollen
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerGetAllowedCreateRollenOutputJsonldBenutzerGetAllowedCreateRollen
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerGetAllowedCreateRollenOutputJsonldBenutzerGetAllowedCreateRollen
     */
    rollen?: string;
}
/**
 *
 * @export
 * @interface BenutzerBenutzerGetByEmailOutputBenutzerGetByEmail
 */
export interface BenutzerBenutzerGetByEmailOutputBenutzerGetByEmail {
    /**
     *
     * @type {number}
     * @memberof BenutzerBenutzerGetByEmailOutputBenutzerGetByEmail
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerGetByEmailOutputBenutzerGetByEmail
     */
    rolle?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerGetByEmailOutputBenutzerGetByEmail
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerGetByEmailOutputBenutzerGetByEmail
     */
    vorname?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerGetByEmailOutputBenutzerGetByEmail
     */
    nachname?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerGetByEmailOutputBenutzerGetByEmail
     */
    einrichtungIds?: string | null;
}
/**
 *
 * @export
 * @interface BenutzerBenutzerGetByEmailOutputJsonldBenutzerGetByEmail
 */
export interface BenutzerBenutzerGetByEmailOutputJsonldBenutzerGetByEmail {
    /**
     *
     * @type {string | any}
     * @memberof BenutzerBenutzerGetByEmailOutputJsonldBenutzerGetByEmail
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerGetByEmailOutputJsonldBenutzerGetByEmail
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerGetByEmailOutputJsonldBenutzerGetByEmail
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BenutzerBenutzerGetByEmailOutputJsonldBenutzerGetByEmail
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerGetByEmailOutputJsonldBenutzerGetByEmail
     */
    rolle?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerGetByEmailOutputJsonldBenutzerGetByEmail
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerGetByEmailOutputJsonldBenutzerGetByEmail
     */
    vorname?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerGetByEmailOutputJsonldBenutzerGetByEmail
     */
    nachname?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerGetByEmailOutputJsonldBenutzerGetByEmail
     */
    einrichtungIds?: string | null;
}
/**
 *
 * @export
 * @interface BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
 */
export interface BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles {
    /**
     *
     * @type {number}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    id?: number;
    /**
     *
     * @type {Array<string>}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    roles?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    rolle: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    anrede?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    deaktiviertAt?: string | null;
    /**
     *
     * @type {LandschaftsverbandBenutzerReadBenutzerReadEmailBenutzerReadRoles}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    landschaftsverband?: LandschaftsverbandBenutzerReadBenutzerReadEmailBenutzerReadRoles | null;
    /**
     *
     * @type {TraegerverbandBenutzerReadBenutzerReadEmailBenutzerReadRoles}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    traegerverband?: TraegerverbandBenutzerReadBenutzerReadEmailBenutzerReadRoles | null;
    /**
     *
     * @type {TraegerBenutzerReadBenutzerReadEmailBenutzerReadRoles}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    traeger?: TraegerBenutzerReadBenutzerReadEmailBenutzerReadRoles | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    organisationName?: string | null;
    /**
     *
     * @type {DeaktiviertByBenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    deaktiviertByBenutzer?: DeaktiviertByBenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles | null;
}
/**
 *
 * @export
 * @interface BenutzerCredentialChangeConfirmInput
 */
export interface BenutzerCredentialChangeConfirmInput {
    /**
     *
     * @type {string}
     * @memberof BenutzerCredentialChangeConfirmInput
     */
    token?: string;
}
/**
 *
 * @export
 * @interface BenutzerCredentialChangeRequestInput
 */
export interface BenutzerCredentialChangeRequestInput {
    /**
     *
     * @type {string}
     * @memberof BenutzerCredentialChangeRequestInput
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerCredentialChangeRequestInput
     */
    password?: string;
}
/**
 *
 * @export
 * @interface BenutzerEinrichtungBenutzerRead
 */
export interface BenutzerEinrichtungBenutzerRead {
    /**
     *
     * @type {number}
     * @memberof BenutzerEinrichtungBenutzerRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerEinrichtungBenutzerRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerEinrichtungBenutzerRead
     */
    nachname: string;
}
/**
 *
 * @export
 * @interface BenutzerEinrichtungList
 */
export interface BenutzerEinrichtungList {
    /**
     *
     * @type {number}
     * @memberof BenutzerEinrichtungList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerEinrichtungList
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerEinrichtungList
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerEinrichtungList
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerEinrichtungList
     */
    modifiedAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerEinrichtungRead
 */
export interface BenutzerEinrichtungRead {
    /**
     *
     * @type {number}
     * @memberof BenutzerEinrichtungRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerEinrichtungRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerEinrichtungRead
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerEinrichtungRead
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerEinrichtungRead
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerEinrichtungRead
     */
    modifiedAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerEmailListEmailReadEmail
 */
export interface BenutzerEmailListEmailReadEmail {
    /**
     *
     * @type {number}
     * @memberof BenutzerEmailListEmailReadEmail
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerEmailListEmailReadEmail
     */
    rolle: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerEmailListEmailReadEmail
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerEmailListEmailReadEmail
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerEmailListEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerEmailReadEmailReadEmail
 */
export interface BenutzerEmailReadEmailReadEmail {
    /**
     *
     * @type {number}
     * @memberof BenutzerEmailReadEmailReadEmail
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerEmailReadEmailReadEmail
     */
    rolle: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerEmailReadEmailReadEmail
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerEmailReadEmailReadEmail
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerEmailReadEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerFestsetzungsantragList
 */
export interface BenutzerFestsetzungsantragList {
    /**
     *
     * @type {string}
     * @memberof BenutzerFestsetzungsantragList
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerFestsetzungsantragList
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerFestsetzungsantragList
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerFestsetzungsantragRead
 */
export interface BenutzerFestsetzungsantragRead {
    /**
     *
     * @type {number}
     * @memberof BenutzerFestsetzungsantragRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerFestsetzungsantragRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerFestsetzungsantragRead
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerFestsetzungsantragRead
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerFestsetzungsantragRead
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFestsetzungsantragRead
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFestsetzungsantragRead
     */
    modifiedAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerFestsetzungsantragVersionRead
 */
export interface BenutzerFestsetzungsantragVersionRead {
    /**
     *
     * @type {string}
     * @memberof BenutzerFestsetzungsantragVersionRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerFestsetzungsantragVersionRead
     */
    nachname: string;
}
/**
 *
 * @export
 * @interface BenutzerFeststellungsantragList
 */
export interface BenutzerFeststellungsantragList {
    /**
     *
     * @type {string}
     * @memberof BenutzerFeststellungsantragList
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerFeststellungsantragList
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerFeststellungsantragList
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerFeststellungsantragRead
 */
export interface BenutzerFeststellungsantragRead {
    /**
     *
     * @type {number}
     * @memberof BenutzerFeststellungsantragRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerFeststellungsantragRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerFeststellungsantragRead
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerFeststellungsantragRead
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerFeststellungsantragRead
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFeststellungsantragRead
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFeststellungsantragRead
     */
    modifiedAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerFeststellungsantragVersionRead
 */
export interface BenutzerFeststellungsantragVersionRead {
    /**
     *
     * @type {string}
     * @memberof BenutzerFeststellungsantragVersionRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerFeststellungsantragVersionRead
     */
    nachname: string;
}
/**
 *
 * @export
 * @interface BenutzerInvestorenmodellList
 */
export interface BenutzerInvestorenmodellList {
    /**
     *
     * @type {string}
     * @memberof BenutzerInvestorenmodellList
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerInvestorenmodellList
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerInvestorenmodellList
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerInvestorenmodellRead
 */
export interface BenutzerInvestorenmodellRead {
    /**
     *
     * @type {number}
     * @memberof BenutzerInvestorenmodellRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerInvestorenmodellRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerInvestorenmodellRead
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerInvestorenmodellRead
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerInvestorenmodellRead
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerInvestorenmodellRead
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerInvestorenmodellRead
     */
    modifiedAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerInvestorenmodellVersionRead
 */
export interface BenutzerInvestorenmodellVersionRead {
    /**
     *
     * @type {string}
     * @memberof BenutzerInvestorenmodellVersionRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerInvestorenmodellVersionRead
     */
    nachname: string;
}
/**
 *
 * @export
 * @interface BenutzerJsonldBenutzerCreate
 */
export interface BenutzerJsonldBenutzerCreate {
    /**
     *
     * @type {string | any}
     * @memberof BenutzerJsonldBenutzerCreate
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerCreate
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerCreate
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BenutzerJsonldBenutzerCreate
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerCreate
     */
    rolle: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerCreate
     */
    anrede?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerCreate
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerCreate
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerCreate
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerCreate
     */
    landschaftsverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerCreate
     */
    traegerverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerCreate
     */
    traeger?: string | null;
}
/**
 *
 * @export
 * @interface BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
 */
export interface BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger {
    /**
     *
     * @type {string | any}
     * @memberof BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    rolle: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    password?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    plainPassword?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    anrede?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    telefon: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    fax: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    landschaftsverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    traegerverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    traeger?: string | null;
}
/**
 *
 * @export
 * @interface BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
 */
export interface BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles {
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@type'?: string;
    /**
     *
     * @type {string | any}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@context'?: string | any;
    /**
     *
     * @type {number}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    id?: number;
    /**
     *
     * @type {Array<string>}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    roles?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    rolle: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    anrede?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    deaktiviertAt?: string | null;
    /**
     *
     * @type {LandschaftsverbandJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    landschaftsverband?: LandschaftsverbandJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles | null;
    /**
     *
     * @type {TraegerverbandJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    traegerverband?: TraegerverbandJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles | null;
    /**
     *
     * @type {TraegerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    traeger?: TraegerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    organisationName?: string | null;
    /**
     *
     * @type {DeaktiviertByBenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    deaktiviertByBenutzer?: DeaktiviertByBenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles | null;
}
/**
 *
 * @export
 * @interface BenutzerJsonldEinrichtungBenutzerRead
 */
export interface BenutzerJsonldEinrichtungBenutzerRead {
    /**
     *
     * @type {string | any}
     * @memberof BenutzerJsonldEinrichtungBenutzerRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungBenutzerRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungBenutzerRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BenutzerJsonldEinrichtungBenutzerRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungBenutzerRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungBenutzerRead
     */
    nachname: string;
}
/**
 *
 * @export
 * @interface BenutzerJsonldEinrichtungList
 */
export interface BenutzerJsonldEinrichtungList {
    /**
     *
     * @type {string | any}
     * @memberof BenutzerJsonldEinrichtungList
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BenutzerJsonldEinrichtungList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungList
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungList
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungList
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungList
     */
    modifiedAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerJsonldEinrichtungRead
 */
export interface BenutzerJsonldEinrichtungRead {
    /**
     *
     * @type {string | any}
     * @memberof BenutzerJsonldEinrichtungRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BenutzerJsonldEinrichtungRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungRead
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungRead
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungRead
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungRead
     */
    modifiedAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerJsonldEmailListEmailReadEmail
 */
export interface BenutzerJsonldEmailListEmailReadEmail {
    /**
     *
     * @type {string | any}
     * @memberof BenutzerJsonldEmailListEmailReadEmail
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEmailListEmailReadEmail
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEmailListEmailReadEmail
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BenutzerJsonldEmailListEmailReadEmail
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEmailListEmailReadEmail
     */
    rolle: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEmailListEmailReadEmail
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEmailListEmailReadEmail
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEmailListEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerJsonldEmailReadEmailReadEmail
 */
export interface BenutzerJsonldEmailReadEmailReadEmail {
    /**
     *
     * @type {string | any}
     * @memberof BenutzerJsonldEmailReadEmailReadEmail
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEmailReadEmailReadEmail
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEmailReadEmailReadEmail
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BenutzerJsonldEmailReadEmailReadEmail
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEmailReadEmailReadEmail
     */
    rolle: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEmailReadEmailReadEmail
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEmailReadEmailReadEmail
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEmailReadEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerJsonldFestsetzungsantragList
 */
export interface BenutzerJsonldFestsetzungsantragList {
    /**
     *
     * @type {string | any}
     * @memberof BenutzerJsonldFestsetzungsantragList
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFestsetzungsantragList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFestsetzungsantragList
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFestsetzungsantragList
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFestsetzungsantragList
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFestsetzungsantragList
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerJsonldFestsetzungsantragRead
 */
export interface BenutzerJsonldFestsetzungsantragRead {
    /**
     *
     * @type {string | any}
     * @memberof BenutzerJsonldFestsetzungsantragRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFestsetzungsantragRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFestsetzungsantragRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BenutzerJsonldFestsetzungsantragRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFestsetzungsantragRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFestsetzungsantragRead
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFestsetzungsantragRead
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFestsetzungsantragRead
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFestsetzungsantragRead
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFestsetzungsantragRead
     */
    modifiedAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerJsonldFestsetzungsantragVersionRead
 */
export interface BenutzerJsonldFestsetzungsantragVersionRead {
    /**
     *
     * @type {string | any}
     * @memberof BenutzerJsonldFestsetzungsantragVersionRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFestsetzungsantragVersionRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFestsetzungsantragVersionRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFestsetzungsantragVersionRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFestsetzungsantragVersionRead
     */
    nachname: string;
}
/**
 *
 * @export
 * @interface BenutzerJsonldFeststellungsantragList
 */
export interface BenutzerJsonldFeststellungsantragList {
    /**
     *
     * @type {string | any}
     * @memberof BenutzerJsonldFeststellungsantragList
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFeststellungsantragList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFeststellungsantragList
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFeststellungsantragList
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFeststellungsantragList
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFeststellungsantragList
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerJsonldFeststellungsantragRead
 */
export interface BenutzerJsonldFeststellungsantragRead {
    /**
     *
     * @type {string | any}
     * @memberof BenutzerJsonldFeststellungsantragRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFeststellungsantragRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFeststellungsantragRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BenutzerJsonldFeststellungsantragRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFeststellungsantragRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFeststellungsantragRead
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFeststellungsantragRead
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFeststellungsantragRead
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFeststellungsantragRead
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFeststellungsantragRead
     */
    modifiedAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerJsonldFeststellungsantragVersionRead
 */
export interface BenutzerJsonldFeststellungsantragVersionRead {
    /**
     *
     * @type {string | any}
     * @memberof BenutzerJsonldFeststellungsantragVersionRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFeststellungsantragVersionRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFeststellungsantragVersionRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFeststellungsantragVersionRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFeststellungsantragVersionRead
     */
    nachname: string;
}
/**
 *
 * @export
 * @interface BenutzerJsonldInvestorenmodellList
 */
export interface BenutzerJsonldInvestorenmodellList {
    /**
     *
     * @type {string | any}
     * @memberof BenutzerJsonldInvestorenmodellList
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldInvestorenmodellList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldInvestorenmodellList
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldInvestorenmodellList
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldInvestorenmodellList
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldInvestorenmodellList
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerJsonldInvestorenmodellRead
 */
export interface BenutzerJsonldInvestorenmodellRead {
    /**
     *
     * @type {string | any}
     * @memberof BenutzerJsonldInvestorenmodellRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldInvestorenmodellRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldInvestorenmodellRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BenutzerJsonldInvestorenmodellRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldInvestorenmodellRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldInvestorenmodellRead
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldInvestorenmodellRead
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldInvestorenmodellRead
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldInvestorenmodellRead
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldInvestorenmodellRead
     */
    modifiedAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerJsonldInvestorenmodellVersionRead
 */
export interface BenutzerJsonldInvestorenmodellVersionRead {
    /**
     *
     * @type {string | any}
     * @memberof BenutzerJsonldInvestorenmodellVersionRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldInvestorenmodellVersionRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldInvestorenmodellVersionRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldInvestorenmodellVersionRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldInvestorenmodellVersionRead
     */
    nachname: string;
}
/**
 *
 * @export
 * @interface BenutzerJsonldMassenmailList
 */
export interface BenutzerJsonldMassenmailList {
    /**
     *
     * @type {string | any}
     * @memberof BenutzerJsonldMassenmailList
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMassenmailList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMassenmailList
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMassenmailList
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMassenmailList
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMassenmailList
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerJsonldMassenmailRead
 */
export interface BenutzerJsonldMassenmailRead {
    /**
     *
     * @type {string | any}
     * @memberof BenutzerJsonldMassenmailRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMassenmailRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMassenmailRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMassenmailRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMassenmailRead
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMassenmailRead
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMassenmailRead
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerJsonldMeldungP29Read
 */
export interface BenutzerJsonldMeldungP29Read {
    /**
     *
     * @type {string | any}
     * @memberof BenutzerJsonldMeldungP29Read
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMeldungP29Read
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMeldungP29Read
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BenutzerJsonldMeldungP29Read
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMeldungP29Read
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMeldungP29Read
     */
    nachname: string;
}
/**
 *
 * @export
 * @interface BenutzerJsonldProfileEdit
 */
export interface BenutzerJsonldProfileEdit {
    /**
     *
     * @type {string | any}
     * @memberof BenutzerJsonldProfileEdit
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldProfileEdit
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldProfileEdit
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldProfileEdit
     */
    plainPassword?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldProfileEdit
     */
    anrede?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldProfileEdit
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldProfileEdit
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldProfileEdit
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldProfileEdit
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldProfileEdit
     */
    fax?: string | null;
}
/**
 *
 * @export
 * @interface BenutzerJsonldProfileRead
 */
export interface BenutzerJsonldProfileRead {
    /**
     *
     * @type {string | any}
     * @memberof BenutzerJsonldProfileRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldProfileRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldProfileRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldProfileRead
     */
    anrede?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldProfileRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldProfileRead
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldProfileRead
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldProfileRead
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldProfileRead
     */
    fax?: string | null;
}
/**
 *
 * @export
 * @interface BenutzerJsonldZustaendigkeitRead
 */
export interface BenutzerJsonldZustaendigkeitRead {
    /**
     *
     * @type {string | any}
     * @memberof BenutzerJsonldZustaendigkeitRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldZustaendigkeitRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldZustaendigkeitRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BenutzerJsonldZustaendigkeitRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldZustaendigkeitRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldZustaendigkeitRead
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldZustaendigkeitRead
     */
    email: string;
}
/**
 *
 * @export
 * @interface BenutzerMassenmailList
 */
export interface BenutzerMassenmailList {
    /**
     *
     * @type {string}
     * @memberof BenutzerMassenmailList
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerMassenmailList
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerMassenmailList
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerMassenmailRead
 */
export interface BenutzerMassenmailRead {
    /**
     *
     * @type {string}
     * @memberof BenutzerMassenmailRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerMassenmailRead
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerMassenmailRead
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerMassenmailRead
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerMeldungP29Read
 */
export interface BenutzerMeldungP29Read {
    /**
     *
     * @type {number}
     * @memberof BenutzerMeldungP29Read
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerMeldungP29Read
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerMeldungP29Read
     */
    nachname: string;
}
/**
 *
 * @export
 * @interface BenutzerProfileEdit
 */
export interface BenutzerProfileEdit {
    /**
     *
     * @type {string}
     * @memberof BenutzerProfileEdit
     */
    plainPassword?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerProfileEdit
     */
    anrede?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerProfileEdit
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerProfileEdit
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerProfileEdit
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerProfileEdit
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerProfileEdit
     */
    fax?: string | null;
}
/**
 *
 * @export
 * @interface BenutzerProfileRead
 */
export interface BenutzerProfileRead {
    /**
     *
     * @type {string}
     * @memberof BenutzerProfileRead
     */
    anrede?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerProfileRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerProfileRead
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerProfileRead
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerProfileRead
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerProfileRead
     */
    fax?: string | null;
}
/**
 *
 * @export
 * @interface BenutzerTablePreference
 */
export interface BenutzerTablePreference {
    /**
     *
     * @type {number}
     * @memberof BenutzerTablePreference
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerTablePreference
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerTablePreference
     */
    benutzer?: string | null;
    /**
     *
     * @type {object}
     * @memberof BenutzerTablePreference
     */
    value?: object;
}
/**
 *
 * @export
 * @interface BenutzerTablePreferenceJsonld
 */
export interface BenutzerTablePreferenceJsonld {
    /**
     *
     * @type {string | any}
     * @memberof BenutzerTablePreferenceJsonld
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof BenutzerTablePreferenceJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerTablePreferenceJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BenutzerTablePreferenceJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerTablePreferenceJsonld
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerTablePreferenceJsonld
     */
    benutzer?: string | null;
    /**
     *
     * @type {object}
     * @memberof BenutzerTablePreferenceJsonld
     */
    value?: object;
}
/**
 *
 * @export
 * @interface BenutzerZustaendigkeitRead
 */
export interface BenutzerZustaendigkeitRead {
    /**
     *
     * @type {number}
     * @memberof BenutzerZustaendigkeitRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerZustaendigkeitRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerZustaendigkeitRead
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerZustaendigkeitRead
     */
    email: string;
}
/**
 *
 * @export
 * @interface CollectionFilterCollectionFilters
 */
export interface CollectionFilterCollectionFilters {
    /**
     *
     * @type {string}
     * @memberof CollectionFilterCollectionFilters
     */
    field?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof CollectionFilterCollectionFilters
     */
    values?: Array<string>;
}
/**
 *
 * @export
 * @interface CollectionFilterJsonldCollectionFilters
 */
export interface CollectionFilterJsonldCollectionFilters {
    /**
     *
     * @type {string | any}
     * @memberof CollectionFilterJsonldCollectionFilters
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof CollectionFilterJsonldCollectionFilters
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof CollectionFilterJsonldCollectionFilters
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof CollectionFilterJsonldCollectionFilters
     */
    field?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof CollectionFilterJsonldCollectionFilters
     */
    values?: Array<string>;
}
/**
 *
 * @export
 * @interface Credentials
 */
export interface Credentials {
    /**
     *
     * @type {string}
     * @memberof Credentials
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof Credentials
     */
    password?: string;
}
/**
 *
 * @export
 * @interface DateiInterface
 */
export interface DateiInterface {
    /**
     *
     * @type {number}
     * @memberof DateiInterface
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof DateiInterface
     */
    fileName?: string | null;
    /**
     *
     * @type {string}
     * @memberof DateiInterface
     */
    originalName?: string | null;
    /**
     *
     * @type {number}
     * @memberof DateiInterface
     */
    fileSize?: number | null;
    /**
     *
     * @type {string}
     * @memberof DateiInterface
     */
    mimeType?: string | null;
    /**
     *
     * @type {string}
     * @memberof DateiInterface
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof DateiInterface
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface DateiInterfaceJsonld
 */
export interface DateiInterfaceJsonld {
    /**
     *
     * @type {string | any}
     * @memberof DateiInterfaceJsonld
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof DateiInterfaceJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof DateiInterfaceJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof DateiInterfaceJsonld
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof DateiInterfaceJsonld
     */
    fileName?: string | null;
    /**
     *
     * @type {string}
     * @memberof DateiInterfaceJsonld
     */
    originalName?: string | null;
    /**
     *
     * @type {number}
     * @memberof DateiInterfaceJsonld
     */
    fileSize?: number | null;
    /**
     *
     * @type {string}
     * @memberof DateiInterfaceJsonld
     */
    mimeType?: string | null;
    /**
     *
     * @type {string}
     * @memberof DateiInterfaceJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof DateiInterfaceJsonld
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface DeaktiviertByBenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
 */
export interface DeaktiviertByBenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles {
    /**
     *
     * @type {string}
     * @memberof DeaktiviertByBenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    vorname?: string | null;
    /**
     *
     * @type {string}
     * @memberof DeaktiviertByBenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    nachname?: string | null;
}
/**
 *
 * @export
 * @interface DeaktiviertByBenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
 */
export interface DeaktiviertByBenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles {
    /**
     *
     * @type {string | any}
     * @memberof DeaktiviertByBenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof DeaktiviertByBenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof DeaktiviertByBenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof DeaktiviertByBenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    vorname?: string | null;
    /**
     *
     * @type {string}
     * @memberof DeaktiviertByBenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    nachname?: string | null;
}
/**
 *
 * @export
 * @interface DokumentFeststellungsantragRead
 */
export interface DokumentFeststellungsantragRead {
    /**
     *
     * @type {number}
     * @memberof DokumentFeststellungsantragRead
     */
    uploadId?: number | null;
    /**
     *
     * @type {string}
     * @memberof DokumentFeststellungsantragRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof DokumentFeststellungsantragRead
     */
    originalName?: string | null;
    /**
     *
     * @type {number}
     * @memberof DokumentFeststellungsantragRead
     */
    fileSize?: number | null;
    /**
     *
     * @type {string}
     * @memberof DokumentFeststellungsantragRead
     */
    mimeType?: string | null;
}
/**
 *
 * @export
 * @interface DokumentFeststellungsantragVersionRead
 */
export interface DokumentFeststellungsantragVersionRead {
    /**
     *
     * @type {number}
     * @memberof DokumentFeststellungsantragVersionRead
     */
    uploadId?: number | null;
    /**
     *
     * @type {string}
     * @memberof DokumentFeststellungsantragVersionRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof DokumentFeststellungsantragVersionRead
     */
    originalName?: string | null;
    /**
     *
     * @type {number}
     * @memberof DokumentFeststellungsantragVersionRead
     */
    fileSize?: number | null;
    /**
     *
     * @type {string}
     * @memberof DokumentFeststellungsantragVersionRead
     */
    mimeType?: string | null;
}
/**
 *
 * @export
 * @interface DokumentJsonldFeststellungsantragRead
 */
export interface DokumentJsonldFeststellungsantragRead {
    /**
     *
     * @type {string | any}
     * @memberof DokumentJsonldFeststellungsantragRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof DokumentJsonldFeststellungsantragRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof DokumentJsonldFeststellungsantragRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof DokumentJsonldFeststellungsantragRead
     */
    uploadId?: number | null;
    /**
     *
     * @type {string}
     * @memberof DokumentJsonldFeststellungsantragRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof DokumentJsonldFeststellungsantragRead
     */
    originalName?: string | null;
    /**
     *
     * @type {number}
     * @memberof DokumentJsonldFeststellungsantragRead
     */
    fileSize?: number | null;
    /**
     *
     * @type {string}
     * @memberof DokumentJsonldFeststellungsantragRead
     */
    mimeType?: string | null;
}
/**
 *
 * @export
 * @interface DokumentJsonldFeststellungsantragVersionRead
 */
export interface DokumentJsonldFeststellungsantragVersionRead {
    /**
     *
     * @type {string | any}
     * @memberof DokumentJsonldFeststellungsantragVersionRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof DokumentJsonldFeststellungsantragVersionRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof DokumentJsonldFeststellungsantragVersionRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof DokumentJsonldFeststellungsantragVersionRead
     */
    uploadId?: number | null;
    /**
     *
     * @type {string}
     * @memberof DokumentJsonldFeststellungsantragVersionRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof DokumentJsonldFeststellungsantragVersionRead
     */
    originalName?: string | null;
    /**
     *
     * @type {number}
     * @memberof DokumentJsonldFeststellungsantragVersionRead
     */
    fileSize?: number | null;
    /**
     *
     * @type {string}
     * @memberof DokumentJsonldFeststellungsantragVersionRead
     */
    mimeType?: string | null;
}
/**
 *
 * @export
 * @interface EigenkapitalEinrichtungRead
 */
export interface EigenkapitalEinrichtungRead {
    /**
     *
     * @type {string}
     * @memberof EigenkapitalEinrichtungRead
     */
    field251?: string | null;
    /**
     *
     * @type {string}
     * @memberof EigenkapitalEinrichtungRead
     */
    field1415?: string | null;
    /**
     *
     * @type {string}
     * @memberof EigenkapitalEinrichtungRead
     */
    field5458?: string | null;
}
/**
 *
 * @export
 * @interface EigenkapitalJsonldEinrichtungRead
 */
export interface EigenkapitalJsonldEinrichtungRead {
    /**
     *
     * @type {string | any}
     * @memberof EigenkapitalJsonldEinrichtungRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof EigenkapitalJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EigenkapitalJsonldEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EigenkapitalJsonldEinrichtungRead
     */
    field251?: string | null;
    /**
     *
     * @type {string}
     * @memberof EigenkapitalJsonldEinrichtungRead
     */
    field1415?: string | null;
    /**
     *
     * @type {string}
     * @memberof EigenkapitalJsonldEinrichtungRead
     */
    field5458?: string | null;
}
/**
 *
 * @export
 * @interface EigenkapitalzinsP5Abs6APGDVO
 */
export interface EigenkapitalzinsP5Abs6APGDVO {
    /**
     *
     * @type {string}
     * @memberof EigenkapitalzinsP5Abs6APGDVO
     */
    gueltigBis: string;
    /**
     *
     * @type {number}
     * @memberof EigenkapitalzinsP5Abs6APGDVO
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EigenkapitalzinsP5Abs6APGDVO
     */
    value: string;
    /**
     *
     * @type {string}
     * @memberof EigenkapitalzinsP5Abs6APGDVO
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EigenkapitalzinsP5Abs6APGDVO
     */
    modifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof EigenkapitalzinsP5Abs6APGDVO
     */
    modifiedBy?: string | null;
}
/**
 *
 * @export
 * @interface EigenkapitalzinsP5Abs6APGDVOJsonld
 */
export interface EigenkapitalzinsP5Abs6APGDVOJsonld {
    /**
     *
     * @type {string | any}
     * @memberof EigenkapitalzinsP5Abs6APGDVOJsonld
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof EigenkapitalzinsP5Abs6APGDVOJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EigenkapitalzinsP5Abs6APGDVOJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EigenkapitalzinsP5Abs6APGDVOJsonld
     */
    gueltigBis: string;
    /**
     *
     * @type {number}
     * @memberof EigenkapitalzinsP5Abs6APGDVOJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EigenkapitalzinsP5Abs6APGDVOJsonld
     */
    value: string;
    /**
     *
     * @type {string}
     * @memberof EigenkapitalzinsP5Abs6APGDVOJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EigenkapitalzinsP5Abs6APGDVOJsonld
     */
    modifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof EigenkapitalzinsP5Abs6APGDVOJsonld
     */
    modifiedBy?: string | null;
}
/**
 *
 * @export
 * @interface EigenkapitalzinsenBundesbank
 */
export interface EigenkapitalzinsenBundesbank {
    /**
     *
     * @type {string}
     * @memberof EigenkapitalzinsenBundesbank
     */
    monat: string;
    /**
     *
     * @type {number}
     * @memberof EigenkapitalzinsenBundesbank
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EigenkapitalzinsenBundesbank
     */
    value: string;
    /**
     *
     * @type {string}
     * @memberof EigenkapitalzinsenBundesbank
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EigenkapitalzinsenBundesbank
     */
    modifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof EigenkapitalzinsenBundesbank
     */
    modifiedBy?: string | null;
}
/**
 *
 * @export
 * @interface EigenkapitalzinsenBundesbankJsonld
 */
export interface EigenkapitalzinsenBundesbankJsonld {
    /**
     *
     * @type {string}
     * @memberof EigenkapitalzinsenBundesbankJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EigenkapitalzinsenBundesbankJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {string | any}
     * @memberof EigenkapitalzinsenBundesbankJsonld
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof EigenkapitalzinsenBundesbankJsonld
     */
    monat: string;
    /**
     *
     * @type {number}
     * @memberof EigenkapitalzinsenBundesbankJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EigenkapitalzinsenBundesbankJsonld
     */
    value: string;
    /**
     *
     * @type {string}
     * @memberof EigenkapitalzinsenBundesbankJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EigenkapitalzinsenBundesbankJsonld
     */
    modifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof EigenkapitalzinsenBundesbankJsonld
     */
    modifiedBy?: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungAntragPermissionEinrichtungAntragAnbieterPermissions
 */
export interface EinrichtungAntragPermissionEinrichtungAntragAnbieterPermissions {
    /**
     *
     * @type {string}
     * @memberof EinrichtungAntragPermissionEinrichtungAntragAnbieterPermissions
     */
    antragClass?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungAntragPermissionEinrichtungAntragAnbieterPermissions
     */
    id?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungAntragPermissionEinrichtungAntragAnbieterPermissions
     */
    allow?: boolean | null;
}
/**
 *
 * @export
 * @interface EinrichtungAntragPermissionJsonldEinrichtungAntragAnbieterPermissions
 */
export interface EinrichtungAntragPermissionJsonldEinrichtungAntragAnbieterPermissions {
    /**
     *
     * @type {string | any}
     * @memberof EinrichtungAntragPermissionJsonldEinrichtungAntragAnbieterPermissions
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof EinrichtungAntragPermissionJsonldEinrichtungAntragAnbieterPermissions
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungAntragPermissionJsonldEinrichtungAntragAnbieterPermissions
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungAntragPermissionJsonldEinrichtungAntragAnbieterPermissions
     */
    antragClass?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungAntragPermissionJsonldEinrichtungAntragAnbieterPermissions
     */
    id?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungAntragPermissionJsonldEinrichtungAntragAnbieterPermissions
     */
    allow?: boolean | null;
}
/**
 *
 * @export
 * @interface EinrichtungBenutzerEinrichtungBenutzerCreate
 */
export interface EinrichtungBenutzerEinrichtungBenutzerCreate {
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerEinrichtungBenutzerCreate
     */
    einrichtung: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerEinrichtungBenutzerCreate
     */
    benutzer: string;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungBenutzerEinrichtungBenutzerCreate
     */
    isAntragsteller: boolean;
}
/**
 *
 * @export
 * @interface EinrichtungBenutzerEinrichtungBenutzerRead
 */
export interface EinrichtungBenutzerEinrichtungBenutzerRead {
    /**
     *
     * @type {number}
     * @memberof EinrichtungBenutzerEinrichtungBenutzerRead
     */
    id?: number;
    /**
     *
     * @type {EinrichtungEinrichtungBenutzerRead}
     * @memberof EinrichtungBenutzerEinrichtungBenutzerRead
     */
    einrichtung: EinrichtungEinrichtungBenutzerRead;
    /**
     *
     * @type {BenutzerEinrichtungBenutzerRead}
     * @memberof EinrichtungBenutzerEinrichtungBenutzerRead
     */
    benutzer: BenutzerEinrichtungBenutzerRead;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungBenutzerEinrichtungBenutzerRead
     */
    isAntragsteller: boolean;
}
/**
 *
 * @export
 * @interface EinrichtungBenutzerEinrichtungList
 */
export interface EinrichtungBenutzerEinrichtungList {
    /**
     *
     * @type {number}
     * @memberof EinrichtungBenutzerEinrichtungList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerEinrichtungList
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerEinrichtungList
     */
    modifiedAt?: string;
}
/**
 *
 * @export
 * @interface EinrichtungBenutzerEinrichtungRead
 */
export interface EinrichtungBenutzerEinrichtungRead {
    /**
     *
     * @type {number}
     * @memberof EinrichtungBenutzerEinrichtungRead
     */
    id?: number;
    /**
     *
     * @type {BenutzerEinrichtungRead}
     * @memberof EinrichtungBenutzerEinrichtungRead
     */
    benutzer: BenutzerEinrichtungRead;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungBenutzerEinrichtungRead
     */
    isAntragsteller: boolean;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerEinrichtungRead
     */
    modifiedAt?: string;
}
/**
 *
 * @export
 * @interface EinrichtungBenutzerJsonldEinrichtungBenutzerCreate
 */
export interface EinrichtungBenutzerJsonldEinrichtungBenutzerCreate {
    /**
     *
     * @type {string | any}
     * @memberof EinrichtungBenutzerJsonldEinrichtungBenutzerCreate
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerJsonldEinrichtungBenutzerCreate
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerJsonldEinrichtungBenutzerCreate
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerJsonldEinrichtungBenutzerCreate
     */
    einrichtung: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerJsonldEinrichtungBenutzerCreate
     */
    benutzer: string;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungBenutzerJsonldEinrichtungBenutzerCreate
     */
    isAntragsteller: boolean;
}
/**
 *
 * @export
 * @interface EinrichtungBenutzerJsonldEinrichtungBenutzerRead
 */
export interface EinrichtungBenutzerJsonldEinrichtungBenutzerRead {
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerJsonldEinrichtungBenutzerRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerJsonldEinrichtungBenutzerRead
     */
    '@type'?: string;
    /**
     *
     * @type {string | any}
     * @memberof EinrichtungBenutzerJsonldEinrichtungBenutzerRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {number}
     * @memberof EinrichtungBenutzerJsonldEinrichtungBenutzerRead
     */
    id?: number;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungBenutzerRead}
     * @memberof EinrichtungBenutzerJsonldEinrichtungBenutzerRead
     */
    einrichtung: EinrichtungJsonldEinrichtungBenutzerRead;
    /**
     *
     * @type {BenutzerJsonldEinrichtungBenutzerRead}
     * @memberof EinrichtungBenutzerJsonldEinrichtungBenutzerRead
     */
    benutzer: BenutzerJsonldEinrichtungBenutzerRead;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungBenutzerJsonldEinrichtungBenutzerRead
     */
    isAntragsteller: boolean;
}
/**
 *
 * @export
 * @interface EinrichtungBenutzerJsonldEinrichtungList
 */
export interface EinrichtungBenutzerJsonldEinrichtungList {
    /**
     *
     * @type {string | any}
     * @memberof EinrichtungBenutzerJsonldEinrichtungList
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerJsonldEinrichtungList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerJsonldEinrichtungList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungBenutzerJsonldEinrichtungList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerJsonldEinrichtungList
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerJsonldEinrichtungList
     */
    modifiedAt?: string;
}
/**
 *
 * @export
 * @interface EinrichtungBenutzerJsonldEinrichtungRead
 */
export interface EinrichtungBenutzerJsonldEinrichtungRead {
    /**
     *
     * @type {string | any}
     * @memberof EinrichtungBenutzerJsonldEinrichtungRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerJsonldEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungBenutzerJsonldEinrichtungRead
     */
    id?: number;
    /**
     *
     * @type {BenutzerJsonldEinrichtungRead}
     * @memberof EinrichtungBenutzerJsonldEinrichtungRead
     */
    benutzer: BenutzerJsonldEinrichtungRead;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungBenutzerJsonldEinrichtungRead
     */
    isAntragsteller: boolean;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerJsonldEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerJsonldEinrichtungRead
     */
    modifiedAt?: string;
}
/**
 *
 * @export
 * @interface EinrichtungCollectionFiltersOutputCollectionFilters
 */
export interface EinrichtungCollectionFiltersOutputCollectionFilters {
    /**
     *
     * @type {Array<CollectionFilterCollectionFilters>}
     * @memberof EinrichtungCollectionFiltersOutputCollectionFilters
     */
    filters?: Array<CollectionFilterCollectionFilters>;
}
/**
 *
 * @export
 * @interface EinrichtungCollectionFiltersOutputJsonldCollectionFilters
 */
export interface EinrichtungCollectionFiltersOutputJsonldCollectionFilters {
    /**
     *
     * @type {string | any}
     * @memberof EinrichtungCollectionFiltersOutputJsonldCollectionFilters
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof EinrichtungCollectionFiltersOutputJsonldCollectionFilters
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungCollectionFiltersOutputJsonldCollectionFilters
     */
    '@type'?: string;
    /**
     *
     * @type {Array<CollectionFilterJsonldCollectionFilters>}
     * @memberof EinrichtungCollectionFiltersOutputJsonldCollectionFilters
     */
    filters?: Array<CollectionFilterJsonldCollectionFilters>;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungAntragPermissionsOutputEinrichtungAntragAnbieterPermissions
 */
export interface EinrichtungEinrichtungAntragPermissionsOutputEinrichtungAntragAnbieterPermissions {
    /**
     *
     * @type {Array<EinrichtungAntragPermissionEinrichtungAntragAnbieterPermissions>}
     * @memberof EinrichtungEinrichtungAntragPermissionsOutputEinrichtungAntragAnbieterPermissions
     */
    permissions?: Array<EinrichtungAntragPermissionEinrichtungAntragAnbieterPermissions>;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungAntragPermissionsOutputJsonldEinrichtungAntragAnbieterPermissions
 */
export interface EinrichtungEinrichtungAntragPermissionsOutputJsonldEinrichtungAntragAnbieterPermissions {
    /**
     *
     * @type {string | any}
     * @memberof EinrichtungEinrichtungAntragPermissionsOutputJsonldEinrichtungAntragAnbieterPermissions
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungAntragPermissionsOutputJsonldEinrichtungAntragAnbieterPermissions
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungAntragPermissionsOutputJsonldEinrichtungAntragAnbieterPermissions
     */
    '@type'?: string;
    /**
     *
     * @type {Array<EinrichtungAntragPermissionJsonldEinrichtungAntragAnbieterPermissions>}
     * @memberof EinrichtungEinrichtungAntragPermissionsOutputJsonldEinrichtungAntragAnbieterPermissions
     */
    permissions?: Array<EinrichtungAntragPermissionJsonldEinrichtungAntragAnbieterPermissions>;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungBenutzerRead
 */
export interface EinrichtungEinrichtungBenutzerRead {
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungBenutzerRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungBenutzerRead
     */
    nrwKey: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungBenutzerRead
     */
    name: string;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungEdit
 */
export interface EinrichtungEinrichtungEdit {
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungEdit
     */
    aktenzeichen: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungExistsOutputEinrichtungExists
 */
export interface EinrichtungEinrichtungExistsOutputEinrichtungExists {
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungEinrichtungExistsOutputEinrichtungExists
     */
    exists?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungEinrichtungExistsOutputEinrichtungExists
     */
    pflegewohngeld?: boolean | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungExistsOutputJsonldEinrichtungExists
 */
export interface EinrichtungEinrichtungExistsOutputJsonldEinrichtungExists {
    /**
     *
     * @type {string | any}
     * @memberof EinrichtungEinrichtungExistsOutputJsonldEinrichtungExists
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungExistsOutputJsonldEinrichtungExists
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungExistsOutputJsonldEinrichtungExists
     */
    '@type'?: string;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungEinrichtungExistsOutputJsonldEinrichtungExists
     */
    exists?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungEinrichtungExistsOutputJsonldEinrichtungExists
     */
    pflegewohngeld?: boolean | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungExistsOutputJsonldEinrichtungIsAntragsteller
 */
export interface EinrichtungEinrichtungExistsOutputJsonldEinrichtungIsAntragsteller {
    /**
     *
     * @type {string | any}
     * @memberof EinrichtungEinrichtungExistsOutputJsonldEinrichtungIsAntragsteller
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungExistsOutputJsonldEinrichtungIsAntragsteller
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungExistsOutputJsonldEinrichtungIsAntragsteller
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungList
 */
export interface EinrichtungEinrichtungList {
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungList
     */
    id?: number;
    /**
     *
     * @type {BenutzerEinrichtungList}
     * @memberof EinrichtungEinrichtungList
     */
    zustaendigerBenutzer?: BenutzerEinrichtungList | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungList
     */
    aktenzeichen: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungList
     */
    art: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungList
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungList
     */
    erstinbetriebnahme: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungList
     */
    ort: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungList
     */
    status: string;
    /**
     *
     * @type {Array<EinrichtungBenutzerEinrichtungList>}
     * @memberof EinrichtungEinrichtungList
     */
    einrichtungBenutzer?: Array<EinrichtungBenutzerEinrichtungList>;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungList
     */
    anzahlFeststellungen?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungList
     */
    anzahlFestsetzungen?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungList
     */
    anzahlInvestorenmodelle?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungList
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungList
     */
    modifiedAt?: string;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungRead
 */
export interface EinrichtungEinrichtungRead {
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungRead
     */
    id?: number;
    /**
     *
     * @type {BenutzerEinrichtungRead}
     * @memberof EinrichtungEinrichtungRead
     */
    zustaendigerBenutzer?: BenutzerEinrichtungRead | null;
    /**
     *
     * @type {LandschaftsverbandEinrichtungRead}
     * @memberof EinrichtungEinrichtungRead
     */
    landschaftsverband?: LandschaftsverbandEinrichtungRead;
    /**
     *
     * @type {TraegerverbandEinrichtungRead}
     * @memberof EinrichtungEinrichtungRead
     */
    traegerverband?: TraegerverbandEinrichtungRead | null;
    /**
     *
     * @type {TraegerEinrichtungRead}
     * @memberof EinrichtungEinrichtungRead
     */
    traeger?: TraegerEinrichtungRead | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungRead
     */
    nrwKey: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungRead
     */
    aktenzeichen: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungRead
     */
    art: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungRead
     */
    erstinbetriebnahme: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungRead
     */
    strasse: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungRead
     */
    hausnummer: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungRead
     */
    plz: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungRead
     */
    ort: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungRead
     */
    email: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungRead
     */
    telefon: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungRead
     */
    status: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungRead
     */
    versorgungsvertrag?: string | null;
    /**
     *
     * @type {Array<EinrichtungBenutzerEinrichtungRead>}
     * @memberof EinrichtungEinrichtungRead
     */
    einrichtungBenutzer?: Array<EinrichtungBenutzerEinrichtungRead>;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {FeststellungsantragEinrichtungRead}
     * @memberof EinrichtungEinrichtungRead
     */
    letzterBeschiedenerFeststellungsantrag?: FeststellungsantragEinrichtungRead | null;
    /**
     *
     * @type {FestsetzungsantragEinrichtungRead}
     * @memberof EinrichtungEinrichtungRead
     */
    letzterBeschiedenerFestsetzungsantrag?: FestsetzungsantragEinrichtungRead | null;
    /**
     *
     * @type {RuhendstellungEinrichtungRead}
     * @memberof EinrichtungEinrichtungRead
     */
    letzteRuhendstellung?: RuhendstellungEinrichtungRead | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungWrite
 */
export interface EinrichtungEinrichtungWrite {
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungWrite
     */
    aktenzeichen: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungZustaendigerBenutzer
 */
export interface EinrichtungEinrichtungZustaendigerBenutzer {
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungZustaendigerBenutzer
     */
    zustaendigerBenutzer?: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungEmailListEmailReadEmail
 */
export interface EinrichtungEmailListEmailReadEmail {
    /**
     *
     * @type {string}
     * @memberof EinrichtungEmailListEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EinrichtungEmailReadEmailReadEmail
 */
export interface EinrichtungEmailReadEmailReadEmail {
    /**
     *
     * @type {number}
     * @memberof EinrichtungEmailReadEmailReadEmail
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEmailReadEmailReadEmail
     */
    nrwKey: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEmailReadEmailReadEmail
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEmailReadEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EinrichtungFestsetzungsantragList
 */
export interface EinrichtungFestsetzungsantragList {
    /**
     *
     * @type {number}
     * @memberof EinrichtungFestsetzungsantragList
     */
    id?: number;
    /**
     *
     * @type {BenutzerFestsetzungsantragList}
     * @memberof EinrichtungFestsetzungsantragList
     */
    zustaendigerBenutzer?: BenutzerFestsetzungsantragList | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungFestsetzungsantragList
     */
    nrwKey: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungFestsetzungsantragList
     */
    aktenzeichen: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungFestsetzungsantragList
     */
    art: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungFestsetzungsantragList
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungFestsetzungsantragList
     */
    ort: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungFestsetzungsantragList
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EinrichtungFestsetzungsantragRead
 */
export interface EinrichtungFestsetzungsantragRead {
    /**
     *
     * @type {number}
     * @memberof EinrichtungFestsetzungsantragRead
     */
    id?: number;
    /**
     *
     * @type {BenutzerFestsetzungsantragRead}
     * @memberof EinrichtungFestsetzungsantragRead
     */
    zustaendigerBenutzer?: BenutzerFestsetzungsantragRead | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungFestsetzungsantragRead
     */
    nrwKey: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungFestsetzungsantragRead
     */
    aktenzeichen: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungFestsetzungsantragRead
     */
    art: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungFestsetzungsantragRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungFestsetzungsantragRead
     */
    erstinbetriebnahme: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungFestsetzungsantragRead
     */
    strasse: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungFestsetzungsantragRead
     */
    hausnummer: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungFestsetzungsantragRead
     */
    plz: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungFestsetzungsantragRead
     */
    ort: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungFestsetzungsantragRead
     */
    modifiedAt?: string;
}
/**
 *
 * @export
 * @interface EinrichtungFeststellungsantragList
 */
export interface EinrichtungFeststellungsantragList {
    /**
     *
     * @type {number}
     * @memberof EinrichtungFeststellungsantragList
     */
    id?: number;
    /**
     *
     * @type {BenutzerFeststellungsantragList}
     * @memberof EinrichtungFeststellungsantragList
     */
    zustaendigerBenutzer?: BenutzerFeststellungsantragList | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungFeststellungsantragList
     */
    nrwKey: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungFeststellungsantragList
     */
    aktenzeichen: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungFeststellungsantragList
     */
    art: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungFeststellungsantragList
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungFeststellungsantragList
     */
    ort: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungFeststellungsantragList
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EinrichtungFeststellungsantragRead
 */
export interface EinrichtungFeststellungsantragRead {
    /**
     *
     * @type {number}
     * @memberof EinrichtungFeststellungsantragRead
     */
    id?: number;
    /**
     *
     * @type {BenutzerFeststellungsantragRead}
     * @memberof EinrichtungFeststellungsantragRead
     */
    zustaendigerBenutzer?: BenutzerFeststellungsantragRead | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungFeststellungsantragRead
     */
    nrwKey: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungFeststellungsantragRead
     */
    aktenzeichen: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungFeststellungsantragRead
     */
    art: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungFeststellungsantragRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungFeststellungsantragRead
     */
    erstinbetriebnahme: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungFeststellungsantragRead
     */
    strasse: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungFeststellungsantragRead
     */
    hausnummer: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungFeststellungsantragRead
     */
    plz: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungFeststellungsantragRead
     */
    ort: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungFeststellungsantragRead
     */
    modifiedAt?: string;
}
/**
 *
 * @export
 * @interface EinrichtungInvestorenmodellList
 */
export interface EinrichtungInvestorenmodellList {
    /**
     *
     * @type {number}
     * @memberof EinrichtungInvestorenmodellList
     */
    id?: number;
    /**
     *
     * @type {BenutzerInvestorenmodellList}
     * @memberof EinrichtungInvestorenmodellList
     */
    zustaendigerBenutzer?: BenutzerInvestorenmodellList | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungInvestorenmodellList
     */
    nrwKey: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungInvestorenmodellList
     */
    aktenzeichen: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungInvestorenmodellList
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungInvestorenmodellList
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EinrichtungInvestorenmodellRead
 */
export interface EinrichtungInvestorenmodellRead {
    /**
     *
     * @type {number}
     * @memberof EinrichtungInvestorenmodellRead
     */
    id?: number;
    /**
     *
     * @type {BenutzerInvestorenmodellRead}
     * @memberof EinrichtungInvestorenmodellRead
     */
    zustaendigerBenutzer?: BenutzerInvestorenmodellRead | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungInvestorenmodellRead
     */
    nrwKey: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungInvestorenmodellRead
     */
    aktenzeichen: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungInvestorenmodellRead
     */
    art: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungInvestorenmodellRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungInvestorenmodellRead
     */
    erstinbetriebnahme: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungInvestorenmodellRead
     */
    strasse: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungInvestorenmodellRead
     */
    hausnummer: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungInvestorenmodellRead
     */
    plz: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungInvestorenmodellRead
     */
    ort: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungInvestorenmodellRead
     */
    modifiedAt?: string;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldEinrichtungBenutzerRead
 */
export interface EinrichtungJsonldEinrichtungBenutzerRead {
    /**
     *
     * @type {string | any}
     * @memberof EinrichtungJsonldEinrichtungBenutzerRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungBenutzerRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungBenutzerRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungJsonldEinrichtungBenutzerRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungBenutzerRead
     */
    nrwKey: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungBenutzerRead
     */
    name: string;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldEinrichtungList
 */
export interface EinrichtungJsonldEinrichtungList {
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungJsonldEinrichtungList
     */
    id?: number;
    /**
     *
     * @type {BenutzerJsonldEinrichtungList}
     * @memberof EinrichtungJsonldEinrichtungList
     */
    zustaendigerBenutzer?: BenutzerJsonldEinrichtungList | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungList
     */
    aktenzeichen: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungList
     */
    art: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungList
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungList
     */
    erstinbetriebnahme: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungList
     */
    ort: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungList
     */
    status: string;
    /**
     *
     * @type {Array<EinrichtungBenutzerJsonldEinrichtungList>}
     * @memberof EinrichtungJsonldEinrichtungList
     */
    einrichtungBenutzer?: Array<EinrichtungBenutzerJsonldEinrichtungList>;
    /**
     *
     * @type {number}
     * @memberof EinrichtungJsonldEinrichtungList
     */
    anzahlFeststellungen?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungJsonldEinrichtungList
     */
    anzahlFestsetzungen?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungJsonldEinrichtungList
     */
    anzahlInvestorenmodelle?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungList
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungList
     */
    modifiedAt?: string;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldEinrichtungRead
 */
export interface EinrichtungJsonldEinrichtungRead {
    /**
     *
     * @type {string | any}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    id?: number;
    /**
     *
     * @type {BenutzerJsonldEinrichtungRead}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    zustaendigerBenutzer?: BenutzerJsonldEinrichtungRead | null;
    /**
     *
     * @type {LandschaftsverbandJsonldEinrichtungRead}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    landschaftsverband?: LandschaftsverbandJsonldEinrichtungRead;
    /**
     *
     * @type {TraegerverbandJsonldEinrichtungRead}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    traegerverband?: TraegerverbandJsonldEinrichtungRead | null;
    /**
     *
     * @type {TraegerJsonldEinrichtungRead}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    traeger?: TraegerJsonldEinrichtungRead | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    nrwKey: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    aktenzeichen: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    art: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    erstinbetriebnahme: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    strasse: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    hausnummer: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    plz: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    ort: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    email: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    telefon: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    status: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    versorgungsvertrag?: string | null;
    /**
     *
     * @type {Array<EinrichtungBenutzerJsonldEinrichtungRead>}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    einrichtungBenutzer?: Array<EinrichtungBenutzerJsonldEinrichtungRead>;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {FeststellungsantragJsonldEinrichtungRead}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    letzterBeschiedenerFeststellungsantrag?: FeststellungsantragJsonldEinrichtungRead | null;
    /**
     *
     * @type {FestsetzungsantragJsonldEinrichtungRead}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    letzterBeschiedenerFestsetzungsantrag?: FestsetzungsantragJsonldEinrichtungRead | null;
    /**
     *
     * @type {RuhendstellungJsonldEinrichtungRead}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    letzteRuhendstellung?: RuhendstellungJsonldEinrichtungRead | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldEinrichtungWrite
 */
export interface EinrichtungJsonldEinrichtungWrite {
    /**
     *
     * @type {string | any}
     * @memberof EinrichtungJsonldEinrichtungWrite
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungWrite
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungWrite
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungWrite
     */
    aktenzeichen: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldEinrichtungZustaendigerBenutzer
 */
export interface EinrichtungJsonldEinrichtungZustaendigerBenutzer {
    /**
     *
     * @type {string | any}
     * @memberof EinrichtungJsonldEinrichtungZustaendigerBenutzer
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungZustaendigerBenutzer
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungZustaendigerBenutzer
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungZustaendigerBenutzer
     */
    zustaendigerBenutzer?: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldEmailListEmailReadEmail
 */
export interface EinrichtungJsonldEmailListEmailReadEmail {
    /**
     *
     * @type {string | any}
     * @memberof EinrichtungJsonldEmailListEmailReadEmail
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEmailListEmailReadEmail
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEmailListEmailReadEmail
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEmailListEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldEmailReadEmailReadEmail
 */
export interface EinrichtungJsonldEmailReadEmailReadEmail {
    /**
     *
     * @type {string | any}
     * @memberof EinrichtungJsonldEmailReadEmailReadEmail
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEmailReadEmailReadEmail
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEmailReadEmailReadEmail
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungJsonldEmailReadEmailReadEmail
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEmailReadEmailReadEmail
     */
    nrwKey: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEmailReadEmailReadEmail
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEmailReadEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldFestsetzungsantragList
 */
export interface EinrichtungJsonldFestsetzungsantragList {
    /**
     *
     * @type {string | any}
     * @memberof EinrichtungJsonldFestsetzungsantragList
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFestsetzungsantragList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFestsetzungsantragList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungJsonldFestsetzungsantragList
     */
    id?: number;
    /**
     *
     * @type {BenutzerJsonldFestsetzungsantragList}
     * @memberof EinrichtungJsonldFestsetzungsantragList
     */
    zustaendigerBenutzer?: BenutzerJsonldFestsetzungsantragList | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFestsetzungsantragList
     */
    nrwKey: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFestsetzungsantragList
     */
    aktenzeichen: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFestsetzungsantragList
     */
    art: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFestsetzungsantragList
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFestsetzungsantragList
     */
    ort: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFestsetzungsantragList
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldFestsetzungsantragRead
 */
export interface EinrichtungJsonldFestsetzungsantragRead {
    /**
     *
     * @type {string | any}
     * @memberof EinrichtungJsonldFestsetzungsantragRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFestsetzungsantragRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFestsetzungsantragRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungJsonldFestsetzungsantragRead
     */
    id?: number;
    /**
     *
     * @type {BenutzerJsonldFestsetzungsantragRead}
     * @memberof EinrichtungJsonldFestsetzungsantragRead
     */
    zustaendigerBenutzer?: BenutzerJsonldFestsetzungsantragRead | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFestsetzungsantragRead
     */
    nrwKey: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFestsetzungsantragRead
     */
    aktenzeichen: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFestsetzungsantragRead
     */
    art: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFestsetzungsantragRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFestsetzungsantragRead
     */
    erstinbetriebnahme: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFestsetzungsantragRead
     */
    strasse: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFestsetzungsantragRead
     */
    hausnummer: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFestsetzungsantragRead
     */
    plz: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFestsetzungsantragRead
     */
    ort: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFestsetzungsantragRead
     */
    modifiedAt?: string;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldFeststellungsantragList
 */
export interface EinrichtungJsonldFeststellungsantragList {
    /**
     *
     * @type {string | any}
     * @memberof EinrichtungJsonldFeststellungsantragList
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFeststellungsantragList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFeststellungsantragList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungJsonldFeststellungsantragList
     */
    id?: number;
    /**
     *
     * @type {BenutzerJsonldFeststellungsantragList}
     * @memberof EinrichtungJsonldFeststellungsantragList
     */
    zustaendigerBenutzer?: BenutzerJsonldFeststellungsantragList | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFeststellungsantragList
     */
    nrwKey: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFeststellungsantragList
     */
    aktenzeichen: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFeststellungsantragList
     */
    art: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFeststellungsantragList
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFeststellungsantragList
     */
    ort: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFeststellungsantragList
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldFeststellungsantragRead
 */
export interface EinrichtungJsonldFeststellungsantragRead {
    /**
     *
     * @type {string | any}
     * @memberof EinrichtungJsonldFeststellungsantragRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFeststellungsantragRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFeststellungsantragRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungJsonldFeststellungsantragRead
     */
    id?: number;
    /**
     *
     * @type {BenutzerJsonldFeststellungsantragRead}
     * @memberof EinrichtungJsonldFeststellungsantragRead
     */
    zustaendigerBenutzer?: BenutzerJsonldFeststellungsantragRead | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFeststellungsantragRead
     */
    nrwKey: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFeststellungsantragRead
     */
    aktenzeichen: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFeststellungsantragRead
     */
    art: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFeststellungsantragRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFeststellungsantragRead
     */
    erstinbetriebnahme: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFeststellungsantragRead
     */
    strasse: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFeststellungsantragRead
     */
    hausnummer: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFeststellungsantragRead
     */
    plz: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFeststellungsantragRead
     */
    ort: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldFeststellungsantragRead
     */
    modifiedAt?: string;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldInvestorenmodellList
 */
export interface EinrichtungJsonldInvestorenmodellList {
    /**
     *
     * @type {string | any}
     * @memberof EinrichtungJsonldInvestorenmodellList
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldInvestorenmodellList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldInvestorenmodellList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungJsonldInvestorenmodellList
     */
    id?: number;
    /**
     *
     * @type {BenutzerJsonldInvestorenmodellList}
     * @memberof EinrichtungJsonldInvestorenmodellList
     */
    zustaendigerBenutzer?: BenutzerJsonldInvestorenmodellList | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldInvestorenmodellList
     */
    nrwKey: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldInvestorenmodellList
     */
    aktenzeichen: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldInvestorenmodellList
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldInvestorenmodellList
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldInvestorenmodellRead
 */
export interface EinrichtungJsonldInvestorenmodellRead {
    /**
     *
     * @type {string | any}
     * @memberof EinrichtungJsonldInvestorenmodellRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldInvestorenmodellRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldInvestorenmodellRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungJsonldInvestorenmodellRead
     */
    id?: number;
    /**
     *
     * @type {BenutzerJsonldInvestorenmodellRead}
     * @memberof EinrichtungJsonldInvestorenmodellRead
     */
    zustaendigerBenutzer?: BenutzerJsonldInvestorenmodellRead | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldInvestorenmodellRead
     */
    nrwKey: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldInvestorenmodellRead
     */
    aktenzeichen: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldInvestorenmodellRead
     */
    art: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldInvestorenmodellRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldInvestorenmodellRead
     */
    erstinbetriebnahme: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldInvestorenmodellRead
     */
    strasse: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldInvestorenmodellRead
     */
    hausnummer: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldInvestorenmodellRead
     */
    plz: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldInvestorenmodellRead
     */
    ort: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldInvestorenmodellRead
     */
    modifiedAt?: string;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldMeldungP29List
 */
export interface EinrichtungJsonldMeldungP29List {
    /**
     *
     * @type {string | any}
     * @memberof EinrichtungJsonldMeldungP29List
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldMeldungP29List
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldMeldungP29List
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungJsonldMeldungP29List
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldMeldungP29List
     */
    zustaendigerBenutzer?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldMeldungP29List
     */
    nrwKey: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldMeldungP29List
     */
    aktenzeichen: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldMeldungP29List
     */
    name: string;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldMeldungP29Read
 */
export interface EinrichtungJsonldMeldungP29Read {
    /**
     *
     * @type {string | any}
     * @memberof EinrichtungJsonldMeldungP29Read
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldMeldungP29Read
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldMeldungP29Read
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungJsonldMeldungP29Read
     */
    id?: number;
    /**
     *
     * @type {BenutzerJsonldMeldungP29Read}
     * @memberof EinrichtungJsonldMeldungP29Read
     */
    zustaendigerBenutzer?: BenutzerJsonldMeldungP29Read | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldMeldungP29Read
     */
    nrwKey: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldMeldungP29Read
     */
    aktenzeichen: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldMeldungP29Read
     */
    art: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldMeldungP29Read
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldMeldungP29Read
     */
    erstinbetriebnahme: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldMeldungP29Read
     */
    strasse: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldMeldungP29Read
     */
    hausnummer: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldMeldungP29Read
     */
    plz: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldMeldungP29Read
     */
    ort: string;
}
/**
 *
 * @export
 * @interface EinrichtungMeldungP29List
 */
export interface EinrichtungMeldungP29List {
    /**
     *
     * @type {number}
     * @memberof EinrichtungMeldungP29List
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungMeldungP29List
     */
    zustaendigerBenutzer?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungMeldungP29List
     */
    nrwKey: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungMeldungP29List
     */
    aktenzeichen: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungMeldungP29List
     */
    name: string;
}
/**
 *
 * @export
 * @interface EinrichtungMeldungP29Read
 */
export interface EinrichtungMeldungP29Read {
    /**
     *
     * @type {number}
     * @memberof EinrichtungMeldungP29Read
     */
    id?: number;
    /**
     *
     * @type {BenutzerMeldungP29Read}
     * @memberof EinrichtungMeldungP29Read
     */
    zustaendigerBenutzer?: BenutzerMeldungP29Read | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungMeldungP29Read
     */
    nrwKey: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungMeldungP29Read
     */
    aktenzeichen: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungMeldungP29Read
     */
    art: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungMeldungP29Read
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungMeldungP29Read
     */
    erstinbetriebnahme: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungMeldungP29Read
     */
    strasse: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungMeldungP29Read
     */
    hausnummer: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungMeldungP29Read
     */
    plz: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungMeldungP29Read
     */
    ort: string;
}
/**
 *
 * @export
 * @interface EmailEmailListEmailReadEmail
 */
export interface EmailEmailListEmailReadEmail {
    /**
     *
     * @type {number}
     * @memberof EmailEmailListEmailReadEmail
     */
    id?: number;
    /**
     *
     * @type {BenutzerEmailListEmailReadEmail}
     * @memberof EmailEmailListEmailReadEmail
     */
    empfaenger?: BenutzerEmailListEmailReadEmail | null;
    /**
     *
     * @type {EinrichtungEmailListEmailReadEmail}
     * @memberof EmailEmailListEmailReadEmail
     */
    einrichtung?: EinrichtungEmailListEmailReadEmail;
    /**
     *
     * @type {FeststellungsantragEmailListEmailReadEmail}
     * @memberof EmailEmailListEmailReadEmail
     */
    feststellungsantrag?: FeststellungsantragEmailListEmailReadEmail | null;
    /**
     *
     * @type {FestsetzungsantragEmailListEmailReadEmail}
     * @memberof EmailEmailListEmailReadEmail
     */
    festsetzungsantrag?: FestsetzungsantragEmailListEmailReadEmail | null;
    /**
     *
     * @type {InvestorenmodellEmailListEmailReadEmail}
     * @memberof EmailEmailListEmailReadEmail
     */
    investorenmodell?: InvestorenmodellEmailListEmailReadEmail | null;
    /**
     *
     * @type {MeldungP29EmailListEmailReadEmail}
     * @memberof EmailEmailListEmailReadEmail
     */
    meldungP29?: MeldungP29EmailListEmailReadEmail | null;
    /**
     *
     * @type {string}
     * @memberof EmailEmailListEmailReadEmail
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof EmailEmailListEmailReadEmail
     */
    betreff?: string;
    /**
     *
     * @type {string}
     * @memberof EmailEmailListEmailReadEmail
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerEmailListEmailReadEmail}
     * @memberof EmailEmailListEmailReadEmail
     */
    createdBy?: BenutzerEmailListEmailReadEmail | null;
}
/**
 *
 * @export
 * @interface EmailEmailReadEmailReadEmail
 */
export interface EmailEmailReadEmailReadEmail {
    /**
     *
     * @type {number}
     * @memberof EmailEmailReadEmailReadEmail
     */
    id?: number;
    /**
     *
     * @type {BenutzerEmailReadEmailReadEmail}
     * @memberof EmailEmailReadEmailReadEmail
     */
    empfaenger?: BenutzerEmailReadEmailReadEmail | null;
    /**
     *
     * @type {EinrichtungEmailReadEmailReadEmail}
     * @memberof EmailEmailReadEmailReadEmail
     */
    einrichtung?: EinrichtungEmailReadEmailReadEmail;
    /**
     *
     * @type {FeststellungsantragEmailReadEmailReadEmail}
     * @memberof EmailEmailReadEmailReadEmail
     */
    feststellungsantrag?: FeststellungsantragEmailReadEmailReadEmail | null;
    /**
     *
     * @type {FestsetzungsantragEmailReadEmailReadEmail}
     * @memberof EmailEmailReadEmailReadEmail
     */
    festsetzungsantrag?: FestsetzungsantragEmailReadEmailReadEmail | null;
    /**
     *
     * @type {InvestorenmodellEmailReadEmailReadEmail}
     * @memberof EmailEmailReadEmailReadEmail
     */
    investorenmodell?: InvestorenmodellEmailReadEmailReadEmail | null;
    /**
     *
     * @type {MeldungP29EmailReadEmailReadEmail}
     * @memberof EmailEmailReadEmailReadEmail
     */
    meldungP29?: MeldungP29EmailReadEmailReadEmail | null;
    /**
     *
     * @type {string}
     * @memberof EmailEmailReadEmailReadEmail
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof EmailEmailReadEmailReadEmail
     */
    betreff?: string;
    /**
     *
     * @type {string}
     * @memberof EmailEmailReadEmailReadEmail
     */
    text?: string;
    /**
     *
     * @type {Array<UploadEmailReadEmailReadEmail>}
     * @memberof EmailEmailReadEmailReadEmail
     */
    uploads?: Array<UploadEmailReadEmailReadEmail>;
    /**
     *
     * @type {string}
     * @memberof EmailEmailReadEmailReadEmail
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerEmailReadEmailReadEmail}
     * @memberof EmailEmailReadEmailReadEmail
     */
    createdBy?: BenutzerEmailReadEmailReadEmail | null;
}
/**
 *
 * @export
 * @interface EmailJsonldEmailListEmailReadEmail
 */
export interface EmailJsonldEmailListEmailReadEmail {
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailListEmailReadEmail
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailListEmailReadEmail
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EmailJsonldEmailListEmailReadEmail
     */
    id?: number;
    /**
     *
     * @type {BenutzerJsonldEmailListEmailReadEmail}
     * @memberof EmailJsonldEmailListEmailReadEmail
     */
    empfaenger?: BenutzerJsonldEmailListEmailReadEmail | null;
    /**
     *
     * @type {EinrichtungJsonldEmailListEmailReadEmail}
     * @memberof EmailJsonldEmailListEmailReadEmail
     */
    einrichtung?: EinrichtungJsonldEmailListEmailReadEmail;
    /**
     *
     * @type {FeststellungsantragJsonldEmailListEmailReadEmail}
     * @memberof EmailJsonldEmailListEmailReadEmail
     */
    feststellungsantrag?: FeststellungsantragJsonldEmailListEmailReadEmail | null;
    /**
     *
     * @type {FestsetzungsantragJsonldEmailListEmailReadEmail}
     * @memberof EmailJsonldEmailListEmailReadEmail
     */
    festsetzungsantrag?: FestsetzungsantragJsonldEmailListEmailReadEmail | null;
    /**
     *
     * @type {InvestorenmodellJsonldEmailListEmailReadEmail}
     * @memberof EmailJsonldEmailListEmailReadEmail
     */
    investorenmodell?: InvestorenmodellJsonldEmailListEmailReadEmail | null;
    /**
     *
     * @type {MeldungP29JsonldEmailListEmailReadEmail}
     * @memberof EmailJsonldEmailListEmailReadEmail
     */
    meldungP29?: MeldungP29JsonldEmailListEmailReadEmail | null;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailListEmailReadEmail
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailListEmailReadEmail
     */
    betreff?: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailListEmailReadEmail
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerJsonldEmailListEmailReadEmail}
     * @memberof EmailJsonldEmailListEmailReadEmail
     */
    createdBy?: BenutzerJsonldEmailListEmailReadEmail | null;
}
/**
 *
 * @export
 * @interface EmailJsonldEmailReadEmailReadEmail
 */
export interface EmailJsonldEmailReadEmailReadEmail {
    /**
     *
     * @type {string | any}
     * @memberof EmailJsonldEmailReadEmailReadEmail
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmail
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmail
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EmailJsonldEmailReadEmailReadEmail
     */
    id?: number;
    /**
     *
     * @type {BenutzerJsonldEmailReadEmailReadEmail}
     * @memberof EmailJsonldEmailReadEmailReadEmail
     */
    empfaenger?: BenutzerJsonldEmailReadEmailReadEmail | null;
    /**
     *
     * @type {EinrichtungJsonldEmailReadEmailReadEmail}
     * @memberof EmailJsonldEmailReadEmailReadEmail
     */
    einrichtung?: EinrichtungJsonldEmailReadEmailReadEmail;
    /**
     *
     * @type {FeststellungsantragJsonldEmailReadEmailReadEmail}
     * @memberof EmailJsonldEmailReadEmailReadEmail
     */
    feststellungsantrag?: FeststellungsantragJsonldEmailReadEmailReadEmail | null;
    /**
     *
     * @type {FestsetzungsantragJsonldEmailReadEmailReadEmail}
     * @memberof EmailJsonldEmailReadEmailReadEmail
     */
    festsetzungsantrag?: FestsetzungsantragJsonldEmailReadEmailReadEmail | null;
    /**
     *
     * @type {InvestorenmodellJsonldEmailReadEmailReadEmail}
     * @memberof EmailJsonldEmailReadEmailReadEmail
     */
    investorenmodell?: InvestorenmodellJsonldEmailReadEmailReadEmail | null;
    /**
     *
     * @type {MeldungP29JsonldEmailReadEmailReadEmail}
     * @memberof EmailJsonldEmailReadEmailReadEmail
     */
    meldungP29?: MeldungP29JsonldEmailReadEmailReadEmail | null;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmail
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmail
     */
    betreff?: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmail
     */
    text?: string;
    /**
     *
     * @type {Array<UploadJsonldEmailReadEmailReadEmail>}
     * @memberof EmailJsonldEmailReadEmailReadEmail
     */
    uploads?: Array<UploadJsonldEmailReadEmailReadEmail>;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmail
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerJsonldEmailReadEmailReadEmail}
     * @memberof EmailJsonldEmailReadEmailReadEmail
     */
    createdBy?: BenutzerJsonldEmailReadEmailReadEmail | null;
}
/**
 *
 * @export
 * @interface FestsetzungsantragBescheidDokument
 */
export interface FestsetzungsantragBescheidDokument {
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidDokument
     */
    bescheidung?: string;
    /**
     *
     * @type {number}
     * @memberof FestsetzungsantragBescheidDokument
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidDokument
     */
    typ?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidDokument
     */
    upload?: string | null;
    /**
     *
     * @type {GeneriertesDokument}
     * @memberof FestsetzungsantragBescheidDokument
     */
    generiertesDokument?: GeneriertesDokument | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidDokument
     */
    token?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidDokument
     */
    tokenCreatedAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidDokument
     */
    tokenUsedAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidDokument
     */
    createdAt?: string;
    /**
     *
     * @type {DateiInterface}
     * @memberof FestsetzungsantragBescheidDokument
     */
    datei?: DateiInterface;
}
/**
 *
 * @export
 * @interface FestsetzungsantragBescheidDokumentFestsetzungsantragVersionRead
 */
export interface FestsetzungsantragBescheidDokumentFestsetzungsantragVersionRead {
    /**
     *
     * @type {number}
     * @memberof FestsetzungsantragBescheidDokumentFestsetzungsantragVersionRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidDokumentFestsetzungsantragVersionRead
     */
    typ?: string;
    /**
     *
     * @type {UploadFestsetzungsantragVersionRead}
     * @memberof FestsetzungsantragBescheidDokumentFestsetzungsantragVersionRead
     */
    upload?: UploadFestsetzungsantragVersionRead | null;
    /**
     *
     * @type {GeneriertesDokumentFestsetzungsantragVersionRead}
     * @memberof FestsetzungsantragBescheidDokumentFestsetzungsantragVersionRead
     */
    generiertesDokument?: GeneriertesDokumentFestsetzungsantragVersionRead | null;
}
/**
 *
 * @export
 * @interface FestsetzungsantragBescheidDokumentJsonld
 */
export interface FestsetzungsantragBescheidDokumentJsonld {
    /**
     *
     * @type {string | any}
     * @memberof FestsetzungsantragBescheidDokumentJsonld
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidDokumentJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidDokumentJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidDokumentJsonld
     */
    bescheidung?: string;
    /**
     *
     * @type {number}
     * @memberof FestsetzungsantragBescheidDokumentJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidDokumentJsonld
     */
    typ?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidDokumentJsonld
     */
    upload?: string | null;
    /**
     *
     * @type {GeneriertesDokumentJsonld}
     * @memberof FestsetzungsantragBescheidDokumentJsonld
     */
    generiertesDokument?: GeneriertesDokumentJsonld | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidDokumentJsonld
     */
    token?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidDokumentJsonld
     */
    tokenCreatedAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidDokumentJsonld
     */
    tokenUsedAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidDokumentJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {DateiInterfaceJsonld}
     * @memberof FestsetzungsantragBescheidDokumentJsonld
     */
    datei?: DateiInterfaceJsonld;
}
/**
 *
 * @export
 * @interface FestsetzungsantragBescheidDokumentJsonldFestsetzungsantragVersionRead
 */
export interface FestsetzungsantragBescheidDokumentJsonldFestsetzungsantragVersionRead {
    /**
     *
     * @type {string | any}
     * @memberof FestsetzungsantragBescheidDokumentJsonldFestsetzungsantragVersionRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidDokumentJsonldFestsetzungsantragVersionRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidDokumentJsonldFestsetzungsantragVersionRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof FestsetzungsantragBescheidDokumentJsonldFestsetzungsantragVersionRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidDokumentJsonldFestsetzungsantragVersionRead
     */
    typ?: string;
    /**
     *
     * @type {UploadJsonldFestsetzungsantragVersionRead}
     * @memberof FestsetzungsantragBescheidDokumentJsonldFestsetzungsantragVersionRead
     */
    upload?: UploadJsonldFestsetzungsantragVersionRead | null;
    /**
     *
     * @type {GeneriertesDokumentJsonldFestsetzungsantragVersionRead}
     * @memberof FestsetzungsantragBescheidDokumentJsonldFestsetzungsantragVersionRead
     */
    generiertesDokument?: GeneriertesDokumentJsonldFestsetzungsantragVersionRead | null;
}
/**
 *
 * @export
 * @interface FestsetzungsantragBescheidung
 */
export interface FestsetzungsantragBescheidung {
    /**
     *
     * @type {Array<FestsetzungsantragBescheidDokument>}
     * @memberof FestsetzungsantragBescheidung
     */
    dokumente?: Array<FestsetzungsantragBescheidDokument>;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidung
     */
    antrag?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidung
     */
    gegenpruefung?: string | null;
    /**
     *
     * @type {number}
     * @memberof FestsetzungsantragBescheidung
     */
    id?: number;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof FestsetzungsantragBescheidung
     */
    versand?: { [key: string]: string };
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidung
     */
    gesendetAt: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidung
     */
    gesendetBy?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof FestsetzungsantragBescheidung
     */
    postalischerVersand?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidung
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidung
     */
    createdBy?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidung
     */
    versandByTyp?: string;
}
/**
 *
 * @export
 * @interface FestsetzungsantragBescheidungBescheidungSetGesendetAt
 */
export interface FestsetzungsantragBescheidungBescheidungSetGesendetAt {
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidungBescheidungSetGesendetAt
     */
    gesendetAt: string | null;
}
/**
 *
 * @export
 * @interface FestsetzungsantragBescheidungFestsetzungsantragVersionRead
 */
export interface FestsetzungsantragBescheidungFestsetzungsantragVersionRead {
    /**
     *
     * @type {Array<FestsetzungsantragBescheidDokumentFestsetzungsantragVersionRead>}
     * @memberof FestsetzungsantragBescheidungFestsetzungsantragVersionRead
     */
    dokumente?: Array<FestsetzungsantragBescheidDokumentFestsetzungsantragVersionRead>;
    /**
     *
     * @type {FestsetzungsantragGegenpruefungFestsetzungsantragVersionRead}
     * @memberof FestsetzungsantragBescheidungFestsetzungsantragVersionRead
     */
    gegenpruefung?: FestsetzungsantragGegenpruefungFestsetzungsantragVersionRead | null;
    /**
     *
     * @type {number}
     * @memberof FestsetzungsantragBescheidungFestsetzungsantragVersionRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidungFestsetzungsantragVersionRead
     */
    gesendetAt: string | null;
    /**
     *
     * @type {BenutzerFestsetzungsantragVersionRead}
     * @memberof FestsetzungsantragBescheidungFestsetzungsantragVersionRead
     */
    gesendetBy?: BenutzerFestsetzungsantragVersionRead | null;
}
/**
 *
 * @export
 * @interface FestsetzungsantragBescheidungJsonld
 */
export interface FestsetzungsantragBescheidungJsonld {
    /**
     *
     * @type {string | any}
     * @memberof FestsetzungsantragBescheidungJsonld
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidungJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidungJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {Array<FestsetzungsantragBescheidDokumentJsonld>}
     * @memberof FestsetzungsantragBescheidungJsonld
     */
    dokumente?: Array<FestsetzungsantragBescheidDokumentJsonld>;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidungJsonld
     */
    antrag?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidungJsonld
     */
    gegenpruefung?: string | null;
    /**
     *
     * @type {number}
     * @memberof FestsetzungsantragBescheidungJsonld
     */
    id?: number;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof FestsetzungsantragBescheidungJsonld
     */
    versand?: { [key: string]: string };
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidungJsonld
     */
    gesendetAt: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidungJsonld
     */
    gesendetBy?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof FestsetzungsantragBescheidungJsonld
     */
    postalischerVersand?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidungJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidungJsonld
     */
    createdBy?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidungJsonld
     */
    versandByTyp?: string;
}
/**
 *
 * @export
 * @interface FestsetzungsantragBescheidungJsonldFestsetzungsantragVersionRead
 */
export interface FestsetzungsantragBescheidungJsonldFestsetzungsantragVersionRead {
    /**
     *
     * @type {string | any}
     * @memberof FestsetzungsantragBescheidungJsonldFestsetzungsantragVersionRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidungJsonldFestsetzungsantragVersionRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidungJsonldFestsetzungsantragVersionRead
     */
    '@type'?: string;
    /**
     *
     * @type {Array<FestsetzungsantragBescheidDokumentJsonldFestsetzungsantragVersionRead>}
     * @memberof FestsetzungsantragBescheidungJsonldFestsetzungsantragVersionRead
     */
    dokumente?: Array<FestsetzungsantragBescheidDokumentJsonldFestsetzungsantragVersionRead>;
    /**
     *
     * @type {FestsetzungsantragGegenpruefungJsonldFestsetzungsantragVersionRead}
     * @memberof FestsetzungsantragBescheidungJsonldFestsetzungsantragVersionRead
     */
    gegenpruefung?: FestsetzungsantragGegenpruefungJsonldFestsetzungsantragVersionRead | null;
    /**
     *
     * @type {number}
     * @memberof FestsetzungsantragBescheidungJsonldFestsetzungsantragVersionRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragBescheidungJsonldFestsetzungsantragVersionRead
     */
    gesendetAt: string | null;
    /**
     *
     * @type {BenutzerJsonldFestsetzungsantragVersionRead}
     * @memberof FestsetzungsantragBescheidungJsonldFestsetzungsantragVersionRead
     */
    gesendetBy?: BenutzerJsonldFestsetzungsantragVersionRead | null;
}
/**
 *
 * @export
 * @interface FestsetzungsantragCollectionFiltersOutputCollectionFilters
 */
export interface FestsetzungsantragCollectionFiltersOutputCollectionFilters {
    /**
     *
     * @type {Array<CollectionFilterCollectionFilters>}
     * @memberof FestsetzungsantragCollectionFiltersOutputCollectionFilters
     */
    filters?: Array<CollectionFilterCollectionFilters>;
}
/**
 *
 * @export
 * @interface FestsetzungsantragCollectionFiltersOutputJsonldCollectionFilters
 */
export interface FestsetzungsantragCollectionFiltersOutputJsonldCollectionFilters {
    /**
     *
     * @type {string | any}
     * @memberof FestsetzungsantragCollectionFiltersOutputJsonldCollectionFilters
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragCollectionFiltersOutputJsonldCollectionFilters
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragCollectionFiltersOutputJsonldCollectionFilters
     */
    '@type'?: string;
    /**
     *
     * @type {Array<CollectionFilterJsonldCollectionFilters>}
     * @memberof FestsetzungsantragCollectionFiltersOutputJsonldCollectionFilters
     */
    filters?: Array<CollectionFilterJsonldCollectionFilters>;
}
/**
 *
 * @export
 * @interface FestsetzungsantragDataEinrichtungRead
 */
export interface FestsetzungsantragDataEinrichtungRead {
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataEinrichtungRead
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataEinrichtungRead
     */
    field500?: string | null;
    /**
     *
     * @type {Array<EigenkapitalEinrichtungRead>}
     * @memberof FestsetzungsantragDataEinrichtungRead
     */
    eigenkapital?: Array<EigenkapitalEinrichtungRead> | null;
    /**
     *
     * @type {Array<FremdkapitalEinrichtungRead>}
     * @memberof FestsetzungsantragDataEinrichtungRead
     */
    fremdkapital?: Array<FremdkapitalEinrichtungRead> | null;
    /**
     *
     * @type {Array<ZimmerkategorieEinrichtungRead>}
     * @memberof FestsetzungsantragDataEinrichtungRead
     */
    zimmerkategorien?: Array<ZimmerkategorieEinrichtungRead> | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataEinrichtungRead
     */
    field1051?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataEinrichtungRead
     */
    field1054?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataEinrichtungRead
     */
    field5111?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataEinrichtungRead
     */
    field5129?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataEinrichtungRead
     */
    field2005?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataEinrichtungRead
     */
    field5171?: string | null;
}
/**
 *
 * @export
 * @interface FestsetzungsantragDataFestsetzungsantragList
 */
export interface FestsetzungsantragDataFestsetzungsantragList {
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataFestsetzungsantragList
     */
    field542?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataFestsetzungsantragList
     */
    field500?: string | null;
    /**
     *
     * @type {Array<ZimmerkategorieFestsetzungsantragList>}
     * @memberof FestsetzungsantragDataFestsetzungsantragList
     */
    zimmerkategorien?: Array<ZimmerkategorieFestsetzungsantragList> | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataFestsetzungsantragList
     */
    field1051?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataFestsetzungsantragList
     */
    field1054?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataFestsetzungsantragList
     */
    field5111?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataFestsetzungsantragList
     */
    field5129?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataFestsetzungsantragList
     */
    field2005?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataFestsetzungsantragList
     */
    field5171?: string | null;
}
/**
 *
 * @export
 * @interface FestsetzungsantragDataInvestorenmodellList
 */
export interface FestsetzungsantragDataInvestorenmodellList {
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataInvestorenmodellList
     */
    field500?: string | null;
}
/**
 *
 * @export
 * @interface FestsetzungsantragDataJsonldEinrichtungRead
 */
export interface FestsetzungsantragDataJsonldEinrichtungRead {
    /**
     *
     * @type {string | any}
     * @memberof FestsetzungsantragDataJsonldEinrichtungRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataJsonldEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataJsonldEinrichtungRead
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataJsonldEinrichtungRead
     */
    field500?: string | null;
    /**
     *
     * @type {Array<EigenkapitalJsonldEinrichtungRead>}
     * @memberof FestsetzungsantragDataJsonldEinrichtungRead
     */
    eigenkapital?: Array<EigenkapitalJsonldEinrichtungRead> | null;
    /**
     *
     * @type {Array<FremdkapitalJsonldEinrichtungRead>}
     * @memberof FestsetzungsantragDataJsonldEinrichtungRead
     */
    fremdkapital?: Array<FremdkapitalJsonldEinrichtungRead> | null;
    /**
     *
     * @type {Array<ZimmerkategorieJsonldEinrichtungRead>}
     * @memberof FestsetzungsantragDataJsonldEinrichtungRead
     */
    zimmerkategorien?: Array<ZimmerkategorieJsonldEinrichtungRead> | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataJsonldEinrichtungRead
     */
    field1051?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataJsonldEinrichtungRead
     */
    field1054?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataJsonldEinrichtungRead
     */
    field5111?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataJsonldEinrichtungRead
     */
    field5129?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataJsonldEinrichtungRead
     */
    field2005?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataJsonldEinrichtungRead
     */
    field5171?: string | null;
}
/**
 *
 * @export
 * @interface FestsetzungsantragDataJsonldFestsetzungsantragList
 */
export interface FestsetzungsantragDataJsonldFestsetzungsantragList {
    /**
     *
     * @type {string | any}
     * @memberof FestsetzungsantragDataJsonldFestsetzungsantragList
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataJsonldFestsetzungsantragList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataJsonldFestsetzungsantragList
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataJsonldFestsetzungsantragList
     */
    field542?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataJsonldFestsetzungsantragList
     */
    field500?: string | null;
    /**
     *
     * @type {Array<ZimmerkategorieJsonldFestsetzungsantragList>}
     * @memberof FestsetzungsantragDataJsonldFestsetzungsantragList
     */
    zimmerkategorien?: Array<ZimmerkategorieJsonldFestsetzungsantragList> | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataJsonldFestsetzungsantragList
     */
    field1051?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataJsonldFestsetzungsantragList
     */
    field1054?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataJsonldFestsetzungsantragList
     */
    field5111?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataJsonldFestsetzungsantragList
     */
    field5129?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataJsonldFestsetzungsantragList
     */
    field2005?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataJsonldFestsetzungsantragList
     */
    field5171?: string | null;
}
/**
 *
 * @export
 * @interface FestsetzungsantragDataJsonldFestsetzungsantragRead
 */
export interface FestsetzungsantragDataJsonldFestsetzungsantragRead {
    /**
     *
     * @type {string | any}
     * @memberof FestsetzungsantragDataJsonldFestsetzungsantragRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataJsonldFestsetzungsantragRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataJsonldFestsetzungsantragRead
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface FestsetzungsantragDataJsonldFestsetzungsantragVersionRead
 */
export interface FestsetzungsantragDataJsonldFestsetzungsantragVersionRead {
    /**
     *
     * @type {string | any}
     * @memberof FestsetzungsantragDataJsonldFestsetzungsantragVersionRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataJsonldFestsetzungsantragVersionRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataJsonldFestsetzungsantragVersionRead
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface FestsetzungsantragDataJsonldInvestorenmodellList
 */
export interface FestsetzungsantragDataJsonldInvestorenmodellList {
    /**
     *
     * @type {string | any}
     * @memberof FestsetzungsantragDataJsonldInvestorenmodellList
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataJsonldInvestorenmodellList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataJsonldInvestorenmodellList
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragDataJsonldInvestorenmodellList
     */
    field500?: string | null;
}
/**
 *
 * @export
 * @interface FestsetzungsantragEinrichtungRead
 */
export interface FestsetzungsantragEinrichtungRead {
    /**
     *
     * @type {FestsetzungsantragDataEinrichtungRead}
     * @memberof FestsetzungsantragEinrichtungRead
     */
    data?: FestsetzungsantragDataEinrichtungRead | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragEinrichtungRead
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragEinrichtungRead
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {BenutzerEinrichtungRead}
     * @memberof FestsetzungsantragEinrichtungRead
     */
    eingereichtBy?: BenutzerEinrichtungRead | null;
    /**
     *
     * @type {BenutzerEinrichtungRead}
     * @memberof FestsetzungsantragEinrichtungRead
     */
    zuletztEingereichtBy?: BenutzerEinrichtungRead | null;
    /**
     *
     * @type {FeststellungsantragEinrichtungRead}
     * @memberof FestsetzungsantragEinrichtungRead
     */
    feststellungsantrag?: FeststellungsantragEinrichtungRead;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof FestsetzungsantragEinrichtungRead
     */
    uuidMap?: { [key: string]: string };
    /**
     *
     * @type {AbstractBescheidungEinrichtungRead}
     * @memberof FestsetzungsantragEinrichtungRead
     */
    letzteBescheidung?: AbstractBescheidungEinrichtungRead | null;
}
/**
 *
 * @export
 * @interface FestsetzungsantragEmailListEmailReadEmail
 */
export interface FestsetzungsantragEmailListEmailReadEmail {
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragEmailListEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface FestsetzungsantragEmailReadEmailReadEmail
 */
export interface FestsetzungsantragEmailReadEmailReadEmail {
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragEmailReadEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface FestsetzungsantragFestsetzungsantragCreate
 */
export interface FestsetzungsantragFestsetzungsantragCreate {
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragFestsetzungsantragCreate
     */
    einrichtung?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragFestsetzungsantragCreate
     */
    feststellungsantrag?: string;
}
/**
 *
 * @export
 * @interface FestsetzungsantragFestsetzungsantragJsonAntragJsonForm
 */
export interface FestsetzungsantragFestsetzungsantragJsonAntragJsonForm {
    /**
     *
     * @type {object}
     * @memberof FestsetzungsantragFestsetzungsantragJsonAntragJsonForm
     */
    data?: object;
    /**
     *
     * @type {JsonSchemaAntragJsonForm}
     * @memberof FestsetzungsantragFestsetzungsantragJsonAntragJsonForm
     */
    schema?: JsonSchemaAntragJsonForm;
    /**
     *
     * @type {{ [key: string]: Array<string>; }}
     * @memberof FestsetzungsantragFestsetzungsantragJsonAntragJsonForm
     */
    errors?: { [key: string]: Array<string> };
    /**
     *
     * @type {Array<JsonFormStepAntragJsonForm>}
     * @memberof FestsetzungsantragFestsetzungsantragJsonAntragJsonForm
     */
    steps?: Array<JsonFormStepAntragJsonForm>;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragFestsetzungsantragJsonAntragJsonForm
     */
    step?: string;
    /**
     *
     * @type {number}
     * @memberof FestsetzungsantragFestsetzungsantragJsonAntragJsonForm
     */
    id?: number;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof FestsetzungsantragFestsetzungsantragJsonAntragJsonForm
     */
    uuidMap?: { [key: string]: string };
    /**
     *
     * @type {Array<string>}
     * @memberof FestsetzungsantragFestsetzungsantragJsonAntragJsonForm
     */
    allowedWorkflowActions?: Array<string>;
}
/**
 *
 * @export
 * @interface FestsetzungsantragFestsetzungsantragJsonJsonldAntragJsonForm
 */
export interface FestsetzungsantragFestsetzungsantragJsonJsonldAntragJsonForm {
    /**
     *
     * @type {string | any}
     * @memberof FestsetzungsantragFestsetzungsantragJsonJsonldAntragJsonForm
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragFestsetzungsantragJsonJsonldAntragJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragFestsetzungsantragJsonJsonldAntragJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {object}
     * @memberof FestsetzungsantragFestsetzungsantragJsonJsonldAntragJsonForm
     */
    data?: object;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonForm}
     * @memberof FestsetzungsantragFestsetzungsantragJsonJsonldAntragJsonForm
     */
    schema?: JsonSchemaJsonldAntragJsonForm;
    /**
     *
     * @type {{ [key: string]: Array<string>; }}
     * @memberof FestsetzungsantragFestsetzungsantragJsonJsonldAntragJsonForm
     */
    errors?: { [key: string]: Array<string> };
    /**
     *
     * @type {Array<JsonFormStepJsonldAntragJsonForm>}
     * @memberof FestsetzungsantragFestsetzungsantragJsonJsonldAntragJsonForm
     */
    steps?: Array<JsonFormStepJsonldAntragJsonForm>;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragFestsetzungsantragJsonJsonldAntragJsonForm
     */
    step?: string;
    /**
     *
     * @type {number}
     * @memberof FestsetzungsantragFestsetzungsantragJsonJsonldAntragJsonForm
     */
    id?: number;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof FestsetzungsantragFestsetzungsantragJsonJsonldAntragJsonForm
     */
    uuidMap?: { [key: string]: string };
    /**
     *
     * @type {Array<string>}
     * @memberof FestsetzungsantragFestsetzungsantragJsonJsonldAntragJsonForm
     */
    allowedWorkflowActions?: Array<string>;
}
/**
 *
 * @export
 * @interface FestsetzungsantragFestsetzungsantragList
 */
export interface FestsetzungsantragFestsetzungsantragList {
    /**
     *
     * @type {number}
     * @memberof FestsetzungsantragFestsetzungsantragList
     */
    id?: number;
    /**
     *
     * @type {FestsetzungsantragDataFestsetzungsantragList}
     * @memberof FestsetzungsantragFestsetzungsantragList
     */
    data?: FestsetzungsantragDataFestsetzungsantragList | null;
    /**
     *
     * @type {EinrichtungFestsetzungsantragList}
     * @memberof FestsetzungsantragFestsetzungsantragList
     */
    einrichtung?: EinrichtungFestsetzungsantragList;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragFestsetzungsantragList
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragFestsetzungsantragList
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragFestsetzungsantragList
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {FeststellungsantragFestsetzungsantragList}
     * @memberof FestsetzungsantragFestsetzungsantragList
     */
    feststellungsantrag?: FeststellungsantragFestsetzungsantragList;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragFestsetzungsantragList
     */
    field500?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof FestsetzungsantragFestsetzungsantragList
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragFestsetzungsantragList
     */
    createdAt?: string;
    /**
     *
     * @type {AbstractBescheidungFestsetzungsantragList}
     * @memberof FestsetzungsantragFestsetzungsantragList
     */
    letzteBescheidung?: AbstractBescheidungFestsetzungsantragList | null;
    /**
     *
     * @type {AbstractZurueckziehenFestsetzungsantragList}
     * @memberof FestsetzungsantragFestsetzungsantragList
     */
    letztesZurueckziehen?: AbstractZurueckziehenFestsetzungsantragList | null;
}
/**
 *
 * @export
 * @interface FestsetzungsantragFestsetzungsantragRead
 */
export interface FestsetzungsantragFestsetzungsantragRead {
    /**
     *
     * @type {number}
     * @memberof FestsetzungsantragFestsetzungsantragRead
     */
    id?: number;
    /**
     *
     * @type {object}
     * @memberof FestsetzungsantragFestsetzungsantragRead
     */
    data?: object | null;
    /**
     *
     * @type {Array<string>}
     * @memberof FestsetzungsantragFestsetzungsantragRead
     */
    hinweiseAusMigration?: Array<string> | null;
    /**
     *
     * @type {EinrichtungFestsetzungsantragRead}
     * @memberof FestsetzungsantragFestsetzungsantragRead
     */
    einrichtung?: EinrichtungFestsetzungsantragRead;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragFestsetzungsantragRead
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragFestsetzungsantragRead
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragFestsetzungsantragRead
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {BenutzerFestsetzungsantragRead}
     * @memberof FestsetzungsantragFestsetzungsantragRead
     */
    eingereichtBy?: BenutzerFestsetzungsantragRead | null;
    /**
     *
     * @type {BenutzerFestsetzungsantragRead}
     * @memberof FestsetzungsantragFestsetzungsantragRead
     */
    zuletztEingereichtBy?: BenutzerFestsetzungsantragRead | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragFestsetzungsantragRead
     */
    art?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragFestsetzungsantragRead
     */
    field500?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof FestsetzungsantragFestsetzungsantragRead
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragFestsetzungsantragRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof FestsetzungsantragFestsetzungsantragRead
     */
    uuidMap?: { [key: string]: string };
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragFestsetzungsantragRead
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragFestsetzungsantragRead
     */
    einrichtungErstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragFestsetzungsantragRead
     */
    einrichtungVersorgungsvertrag?: string | null;
    /**
     *
     * @type {AbstractGegenpruefungFestsetzungsantragRead}
     * @memberof FestsetzungsantragFestsetzungsantragRead
     */
    letzteGegenpruefung?: AbstractGegenpruefungFestsetzungsantragRead | null;
    /**
     *
     * @type {AbstractBescheidungFestsetzungsantragRead}
     * @memberof FestsetzungsantragFestsetzungsantragRead
     */
    letzteBescheidung?: AbstractBescheidungFestsetzungsantragRead | null;
}
/**
 *
 * @export
 * @interface FestsetzungsantragFestsetzungsantragVersionRead
 */
export interface FestsetzungsantragFestsetzungsantragVersionRead {
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragFestsetzungsantragVersionRead
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {BenutzerFestsetzungsantragVersionRead}
     * @memberof FestsetzungsantragFestsetzungsantragVersionRead
     */
    eingereichtBy?: BenutzerFestsetzungsantragVersionRead | null;
}
/**
 *
 * @export
 * @interface FestsetzungsantragFeststellungsantragRead
 */
export interface FestsetzungsantragFeststellungsantragRead {
    /**
     *
     * @type {number}
     * @memberof FestsetzungsantragFeststellungsantragRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragFeststellungsantragRead
     */
    modifiedAt?: string;
}
/**
 *
 * @export
 * @interface FestsetzungsantragFormDiffAntragFormDiff
 */
export interface FestsetzungsantragFormDiffAntragFormDiff {
    /**
     *
     * @type {Array<FormFieldDiffAntragFormDiff>}
     * @memberof FestsetzungsantragFormDiffAntragFormDiff
     */
    fieldDiffs?: Array<FormFieldDiffAntragFormDiff>;
}
/**
 *
 * @export
 * @interface FestsetzungsantragFormDiffJsonldAntragFormDiff
 */
export interface FestsetzungsantragFormDiffJsonldAntragFormDiff {
    /**
     *
     * @type {string | any}
     * @memberof FestsetzungsantragFormDiffJsonldAntragFormDiff
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragFormDiffJsonldAntragFormDiff
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragFormDiffJsonldAntragFormDiff
     */
    '@type'?: string;
    /**
     *
     * @type {Array<FormFieldDiffJsonldAntragFormDiff>}
     * @memberof FestsetzungsantragFormDiffJsonldAntragFormDiff
     */
    fieldDiffs?: Array<FormFieldDiffJsonldAntragFormDiff>;
}
/**
 *
 * @export
 * @interface FestsetzungsantragGegenpruefung
 */
export interface FestsetzungsantragGegenpruefung {
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragGegenpruefung
     */
    antrag?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragGegenpruefung
     */
    bescheidung?: string | null;
    /**
     *
     * @type {number}
     * @memberof FestsetzungsantragGegenpruefung
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragGegenpruefung
     */
    geprueftBy?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragGegenpruefung
     */
    geprueftAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragGegenpruefung
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragGegenpruefung
     */
    erlaeuterung?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragGegenpruefung
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragGegenpruefung
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface FestsetzungsantragGegenpruefungFestsetzungsantragVersionRead
 */
export interface FestsetzungsantragGegenpruefungFestsetzungsantragVersionRead {
    /**
     *
     * @type {BenutzerFestsetzungsantragVersionRead}
     * @memberof FestsetzungsantragGegenpruefungFestsetzungsantragVersionRead
     */
    geprueftBy?: BenutzerFestsetzungsantragVersionRead | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragGegenpruefungFestsetzungsantragVersionRead
     */
    geprueftAt?: string | null;
}
/**
 *
 * @export
 * @interface FestsetzungsantragGegenpruefungGegenpruefungEdit
 */
export interface FestsetzungsantragGegenpruefungGegenpruefungEdit {
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragGegenpruefungGegenpruefungEdit
     */
    erlaeuterung?: string | null;
}
/**
 *
 * @export
 * @interface FestsetzungsantragGegenpruefungJsonld
 */
export interface FestsetzungsantragGegenpruefungJsonld {
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragGegenpruefungJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragGegenpruefungJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {string | any}
     * @memberof FestsetzungsantragGegenpruefungJsonld
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragGegenpruefungJsonld
     */
    antrag?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragGegenpruefungJsonld
     */
    bescheidung?: string | null;
    /**
     *
     * @type {number}
     * @memberof FestsetzungsantragGegenpruefungJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragGegenpruefungJsonld
     */
    geprueftBy?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragGegenpruefungJsonld
     */
    geprueftAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragGegenpruefungJsonld
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragGegenpruefungJsonld
     */
    erlaeuterung?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragGegenpruefungJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragGegenpruefungJsonld
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface FestsetzungsantragGegenpruefungJsonldFestsetzungsantragVersionRead
 */
export interface FestsetzungsantragGegenpruefungJsonldFestsetzungsantragVersionRead {
    /**
     *
     * @type {string | any}
     * @memberof FestsetzungsantragGegenpruefungJsonldFestsetzungsantragVersionRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragGegenpruefungJsonldFestsetzungsantragVersionRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragGegenpruefungJsonldFestsetzungsantragVersionRead
     */
    '@type'?: string;
    /**
     *
     * @type {BenutzerJsonldFestsetzungsantragVersionRead}
     * @memberof FestsetzungsantragGegenpruefungJsonldFestsetzungsantragVersionRead
     */
    geprueftBy?: BenutzerJsonldFestsetzungsantragVersionRead | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragGegenpruefungJsonldFestsetzungsantragVersionRead
     */
    geprueftAt?: string | null;
}
/**
 *
 * @export
 * @interface FestsetzungsantragInvestorenmodellList
 */
export interface FestsetzungsantragInvestorenmodellList {
    /**
     *
     * @type {FestsetzungsantragDataInvestorenmodellList}
     * @memberof FestsetzungsantragInvestorenmodellList
     */
    data?: FestsetzungsantragDataInvestorenmodellList | null;
    /**
     *
     * @type {FeststellungsantragInvestorenmodellList}
     * @memberof FestsetzungsantragInvestorenmodellList
     */
    feststellungsantrag?: FeststellungsantragInvestorenmodellList;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragInvestorenmodellList
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface FestsetzungsantragJsonldEinrichtungRead
 */
export interface FestsetzungsantragJsonldEinrichtungRead {
    /**
     *
     * @type {string | any}
     * @memberof FestsetzungsantragJsonldEinrichtungRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {FestsetzungsantragDataJsonldEinrichtungRead}
     * @memberof FestsetzungsantragJsonldEinrichtungRead
     */
    data?: FestsetzungsantragDataJsonldEinrichtungRead | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldEinrichtungRead
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldEinrichtungRead
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {BenutzerJsonldEinrichtungRead}
     * @memberof FestsetzungsantragJsonldEinrichtungRead
     */
    eingereichtBy?: BenutzerJsonldEinrichtungRead | null;
    /**
     *
     * @type {BenutzerJsonldEinrichtungRead}
     * @memberof FestsetzungsantragJsonldEinrichtungRead
     */
    zuletztEingereichtBy?: BenutzerJsonldEinrichtungRead | null;
    /**
     *
     * @type {FeststellungsantragJsonldEinrichtungRead}
     * @memberof FestsetzungsantragJsonldEinrichtungRead
     */
    feststellungsantrag?: FeststellungsantragJsonldEinrichtungRead;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof FestsetzungsantragJsonldEinrichtungRead
     */
    uuidMap?: { [key: string]: string };
    /**
     *
     * @type {AbstractBescheidungJsonldEinrichtungRead}
     * @memberof FestsetzungsantragJsonldEinrichtungRead
     */
    letzteBescheidung?: AbstractBescheidungJsonldEinrichtungRead | null;
}
/**
 *
 * @export
 * @interface FestsetzungsantragJsonldEmailListEmailReadEmail
 */
export interface FestsetzungsantragJsonldEmailListEmailReadEmail {
    /**
     *
     * @type {string | any}
     * @memberof FestsetzungsantragJsonldEmailListEmailReadEmail
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldEmailListEmailReadEmail
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldEmailListEmailReadEmail
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldEmailListEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface FestsetzungsantragJsonldEmailReadEmailReadEmail
 */
export interface FestsetzungsantragJsonldEmailReadEmailReadEmail {
    /**
     *
     * @type {string | any}
     * @memberof FestsetzungsantragJsonldEmailReadEmailReadEmail
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldEmailReadEmailReadEmail
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldEmailReadEmailReadEmail
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldEmailReadEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface FestsetzungsantragJsonldFestsetzungsantragCreate
 */
export interface FestsetzungsantragJsonldFestsetzungsantragCreate {
    /**
     *
     * @type {string | any}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragCreate
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragCreate
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragCreate
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragCreate
     */
    einrichtung?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragCreate
     */
    feststellungsantrag?: string;
}
/**
 *
 * @export
 * @interface FestsetzungsantragJsonldFestsetzungsantragList
 */
export interface FestsetzungsantragJsonldFestsetzungsantragList {
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragList
     */
    id?: number;
    /**
     *
     * @type {FestsetzungsantragDataJsonldFestsetzungsantragList}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragList
     */
    data?: FestsetzungsantragDataJsonldFestsetzungsantragList | null;
    /**
     *
     * @type {EinrichtungJsonldFestsetzungsantragList}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragList
     */
    einrichtung?: EinrichtungJsonldFestsetzungsantragList;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragList
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragList
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragList
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {FeststellungsantragJsonldFestsetzungsantragList}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragList
     */
    feststellungsantrag?: FeststellungsantragJsonldFestsetzungsantragList;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragList
     */
    field500?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragList
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragList
     */
    createdAt?: string;
    /**
     *
     * @type {AbstractBescheidungJsonldFestsetzungsantragList}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragList
     */
    letzteBescheidung?: AbstractBescheidungJsonldFestsetzungsantragList | null;
    /**
     *
     * @type {AbstractZurueckziehenJsonldFestsetzungsantragList}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragList
     */
    letztesZurueckziehen?: AbstractZurueckziehenJsonldFestsetzungsantragList | null;
}
/**
 *
 * @export
 * @interface FestsetzungsantragJsonldFestsetzungsantragRead
 */
export interface FestsetzungsantragJsonldFestsetzungsantragRead {
    /**
     *
     * @type {string | any}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragRead
     */
    id?: number;
    /**
     *
     * @type {FestsetzungsantragDataJsonldFestsetzungsantragRead}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragRead
     */
    data?: FestsetzungsantragDataJsonldFestsetzungsantragRead | null;
    /**
     *
     * @type {Array<string>}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragRead
     */
    hinweiseAusMigration?: Array<string> | null;
    /**
     *
     * @type {EinrichtungJsonldFestsetzungsantragRead}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragRead
     */
    einrichtung?: EinrichtungJsonldFestsetzungsantragRead;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragRead
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragRead
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragRead
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {BenutzerJsonldFestsetzungsantragRead}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragRead
     */
    eingereichtBy?: BenutzerJsonldFestsetzungsantragRead | null;
    /**
     *
     * @type {BenutzerJsonldFestsetzungsantragRead}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragRead
     */
    zuletztEingereichtBy?: BenutzerJsonldFestsetzungsantragRead | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragRead
     */
    art?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragRead
     */
    field500?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragRead
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragRead
     */
    uuidMap?: { [key: string]: string };
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragRead
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragRead
     */
    einrichtungErstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragRead
     */
    einrichtungVersorgungsvertrag?: string | null;
    /**
     *
     * @type {AbstractGegenpruefungJsonldFestsetzungsantragRead}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragRead
     */
    letzteGegenpruefung?: AbstractGegenpruefungJsonldFestsetzungsantragRead | null;
    /**
     *
     * @type {AbstractBescheidungJsonldFestsetzungsantragRead}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragRead
     */
    letzteBescheidung?: AbstractBescheidungJsonldFestsetzungsantragRead | null;
}
/**
 *
 * @export
 * @interface FestsetzungsantragJsonldFestsetzungsantragVersionRead
 */
export interface FestsetzungsantragJsonldFestsetzungsantragVersionRead {
    /**
     *
     * @type {string | any}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragVersionRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragVersionRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragVersionRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragVersionRead
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {BenutzerJsonldFestsetzungsantragVersionRead}
     * @memberof FestsetzungsantragJsonldFestsetzungsantragVersionRead
     */
    eingereichtBy?: BenutzerJsonldFestsetzungsantragVersionRead | null;
}
/**
 *
 * @export
 * @interface FestsetzungsantragJsonldFeststellungsantragRead
 */
export interface FestsetzungsantragJsonldFeststellungsantragRead {
    /**
     *
     * @type {string | any}
     * @memberof FestsetzungsantragJsonldFeststellungsantragRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldFeststellungsantragRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldFeststellungsantragRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof FestsetzungsantragJsonldFeststellungsantragRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldFeststellungsantragRead
     */
    modifiedAt?: string;
}
/**
 *
 * @export
 * @interface FestsetzungsantragJsonldInvestorenmodellList
 */
export interface FestsetzungsantragJsonldInvestorenmodellList {
    /**
     *
     * @type {string | any}
     * @memberof FestsetzungsantragJsonldInvestorenmodellList
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldInvestorenmodellList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldInvestorenmodellList
     */
    '@type'?: string;
    /**
     *
     * @type {FestsetzungsantragDataJsonldInvestorenmodellList}
     * @memberof FestsetzungsantragJsonldInvestorenmodellList
     */
    data?: FestsetzungsantragDataJsonldInvestorenmodellList | null;
    /**
     *
     * @type {FeststellungsantragJsonldInvestorenmodellList}
     * @memberof FestsetzungsantragJsonldInvestorenmodellList
     */
    feststellungsantrag?: FeststellungsantragJsonldInvestorenmodellList;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragJsonldInvestorenmodellList
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface FestsetzungsantragVersionFestsetzungsantragVersionList
 */
export interface FestsetzungsantragVersionFestsetzungsantragVersionList {
    /**
     *
     * @type {number}
     * @memberof FestsetzungsantragVersionFestsetzungsantragVersionList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragVersionFestsetzungsantragVersionList
     */
    typ?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragVersionFestsetzungsantragVersionList
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragVersionFestsetzungsantragVersionList
     */
    antragModifiedAt: string;
}
/**
 *
 * @export
 * @interface FestsetzungsantragVersionFestsetzungsantragVersionRead
 */
export interface FestsetzungsantragVersionFestsetzungsantragVersionRead {
    /**
     *
     * @type {FestsetzungsantragFestsetzungsantragVersionRead}
     * @memberof FestsetzungsantragVersionFestsetzungsantragVersionRead
     */
    antrag?: FestsetzungsantragFestsetzungsantragVersionRead;
    /**
     *
     * @type {object}
     * @memberof FestsetzungsantragVersionFestsetzungsantragVersionRead
     */
    data?: object | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragVersionFestsetzungsantragVersionRead
     */
    antragModifiedAt: string;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof FestsetzungsantragVersionFestsetzungsantragVersionRead
     */
    uuidMap?: { [key: string]: string };
    /**
     *
     * @type {FestsetzungsantragBescheidungFestsetzungsantragVersionRead}
     * @memberof FestsetzungsantragVersionFestsetzungsantragVersionRead
     */
    bescheidung?: FestsetzungsantragBescheidungFestsetzungsantragVersionRead | null;
}
/**
 *
 * @export
 * @interface FestsetzungsantragVersionJsonldFestsetzungsantragVersionList
 */
export interface FestsetzungsantragVersionJsonldFestsetzungsantragVersionList {
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragVersionJsonldFestsetzungsantragVersionList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragVersionJsonldFestsetzungsantragVersionList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof FestsetzungsantragVersionJsonldFestsetzungsantragVersionList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragVersionJsonldFestsetzungsantragVersionList
     */
    typ?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragVersionJsonldFestsetzungsantragVersionList
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragVersionJsonldFestsetzungsantragVersionList
     */
    antragModifiedAt: string;
}
/**
 *
 * @export
 * @interface FestsetzungsantragVersionJsonldFestsetzungsantragVersionRead
 */
export interface FestsetzungsantragVersionJsonldFestsetzungsantragVersionRead {
    /**
     *
     * @type {string | any}
     * @memberof FestsetzungsantragVersionJsonldFestsetzungsantragVersionRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragVersionJsonldFestsetzungsantragVersionRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragVersionJsonldFestsetzungsantragVersionRead
     */
    '@type'?: string;
    /**
     *
     * @type {FestsetzungsantragJsonldFestsetzungsantragVersionRead}
     * @memberof FestsetzungsantragVersionJsonldFestsetzungsantragVersionRead
     */
    antrag?: FestsetzungsantragJsonldFestsetzungsantragVersionRead;
    /**
     *
     * @type {FestsetzungsantragDataJsonldFestsetzungsantragVersionRead}
     * @memberof FestsetzungsantragVersionJsonldFestsetzungsantragVersionRead
     */
    data?: FestsetzungsantragDataJsonldFestsetzungsantragVersionRead | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragVersionJsonldFestsetzungsantragVersionRead
     */
    antragModifiedAt: string;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof FestsetzungsantragVersionJsonldFestsetzungsantragVersionRead
     */
    uuidMap?: { [key: string]: string };
    /**
     *
     * @type {FestsetzungsantragBescheidungJsonldFestsetzungsantragVersionRead}
     * @memberof FestsetzungsantragVersionJsonldFestsetzungsantragVersionRead
     */
    bescheidung?: FestsetzungsantragBescheidungJsonldFestsetzungsantragVersionRead | null;
}
/**
 *
 * @export
 * @interface FestsetzungsantragZurueckziehen
 */
export interface FestsetzungsantragZurueckziehen {
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragZurueckziehen
     */
    antrag?: string;
    /**
     *
     * @type {number}
     * @memberof FestsetzungsantragZurueckziehen
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragZurueckziehen
     */
    erlaeuterung: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragZurueckziehen
     */
    entschiedenBy?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragZurueckziehen
     */
    entschiedenAt?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof FestsetzungsantragZurueckziehen
     */
    entscheidung?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragZurueckziehen
     */
    erlaeuterungEntscheidung?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragZurueckziehen
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragZurueckziehen
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface FestsetzungsantragZurueckziehenJsonld
 */
export interface FestsetzungsantragZurueckziehenJsonld {
    /**
     *
     * @type {string | any}
     * @memberof FestsetzungsantragZurueckziehenJsonld
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragZurueckziehenJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragZurueckziehenJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragZurueckziehenJsonld
     */
    antrag?: string;
    /**
     *
     * @type {number}
     * @memberof FestsetzungsantragZurueckziehenJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragZurueckziehenJsonld
     */
    erlaeuterung: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragZurueckziehenJsonld
     */
    entschiedenBy?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragZurueckziehenJsonld
     */
    entschiedenAt?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof FestsetzungsantragZurueckziehenJsonld
     */
    entscheidung?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragZurueckziehenJsonld
     */
    erlaeuterungEntscheidung?: string | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragZurueckziehenJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragZurueckziehenJsonld
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface FestsetzungsantragZurueckziehenJsonldZurueckziehenCreate
 */
export interface FestsetzungsantragZurueckziehenJsonldZurueckziehenCreate {
    /**
     *
     * @type {string | any}
     * @memberof FestsetzungsantragZurueckziehenJsonldZurueckziehenCreate
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragZurueckziehenJsonldZurueckziehenCreate
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragZurueckziehenJsonldZurueckziehenCreate
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragZurueckziehenJsonldZurueckziehenCreate
     */
    antrag?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragZurueckziehenJsonldZurueckziehenCreate
     */
    erlaeuterung: string | null;
}
/**
 *
 * @export
 * @interface FestsetzungsantragZurueckziehenZurueckziehenCreate
 */
export interface FestsetzungsantragZurueckziehenZurueckziehenCreate {
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragZurueckziehenZurueckziehenCreate
     */
    antrag?: string;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragZurueckziehenZurueckziehenCreate
     */
    erlaeuterung: string | null;
}
/**
 *
 * @export
 * @interface FestsetzungsantragZurueckziehenZurueckziehenSetEntscheidung
 */
export interface FestsetzungsantragZurueckziehenZurueckziehenSetEntscheidung {
    /**
     *
     * @type {boolean}
     * @memberof FestsetzungsantragZurueckziehenZurueckziehenSetEntscheidung
     */
    entscheidung?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof FestsetzungsantragZurueckziehenZurueckziehenSetEntscheidung
     */
    erlaeuterungEntscheidung?: string | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragBescheidDokument
 */
export interface FeststellungsantragBescheidDokument {
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidDokument
     */
    bescheidung?: string;
    /**
     *
     * @type {number}
     * @memberof FeststellungsantragBescheidDokument
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidDokument
     */
    typ?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidDokument
     */
    upload?: string | null;
    /**
     *
     * @type {GeneriertesDokument}
     * @memberof FeststellungsantragBescheidDokument
     */
    generiertesDokument?: GeneriertesDokument | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidDokument
     */
    token?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidDokument
     */
    tokenCreatedAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidDokument
     */
    tokenUsedAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidDokument
     */
    createdAt?: string;
    /**
     *
     * @type {DateiInterface}
     * @memberof FeststellungsantragBescheidDokument
     */
    datei?: DateiInterface;
}
/**
 *
 * @export
 * @interface FeststellungsantragBescheidDokumentFeststellungsantragRead
 */
export interface FeststellungsantragBescheidDokumentFeststellungsantragRead {
    /**
     *
     * @type {number}
     * @memberof FeststellungsantragBescheidDokumentFeststellungsantragRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidDokumentFeststellungsantragRead
     */
    typ?: string;
    /**
     *
     * @type {UploadFeststellungsantragRead}
     * @memberof FeststellungsantragBescheidDokumentFeststellungsantragRead
     */
    upload?: UploadFeststellungsantragRead | null;
    /**
     *
     * @type {GeneriertesDokumentFeststellungsantragRead}
     * @memberof FeststellungsantragBescheidDokumentFeststellungsantragRead
     */
    generiertesDokument?: GeneriertesDokumentFeststellungsantragRead | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragBescheidDokumentFeststellungsantragVersionRead
 */
export interface FeststellungsantragBescheidDokumentFeststellungsantragVersionRead {
    /**
     *
     * @type {number}
     * @memberof FeststellungsantragBescheidDokumentFeststellungsantragVersionRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidDokumentFeststellungsantragVersionRead
     */
    typ?: string;
    /**
     *
     * @type {UploadFeststellungsantragVersionRead}
     * @memberof FeststellungsantragBescheidDokumentFeststellungsantragVersionRead
     */
    upload?: UploadFeststellungsantragVersionRead | null;
    /**
     *
     * @type {GeneriertesDokumentFeststellungsantragVersionRead}
     * @memberof FeststellungsantragBescheidDokumentFeststellungsantragVersionRead
     */
    generiertesDokument?: GeneriertesDokumentFeststellungsantragVersionRead | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragBescheidDokumentJsonld
 */
export interface FeststellungsantragBescheidDokumentJsonld {
    /**
     *
     * @type {string | any}
     * @memberof FeststellungsantragBescheidDokumentJsonld
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidDokumentJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidDokumentJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidDokumentJsonld
     */
    bescheidung?: string;
    /**
     *
     * @type {number}
     * @memberof FeststellungsantragBescheidDokumentJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidDokumentJsonld
     */
    typ?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidDokumentJsonld
     */
    upload?: string | null;
    /**
     *
     * @type {GeneriertesDokumentJsonld}
     * @memberof FeststellungsantragBescheidDokumentJsonld
     */
    generiertesDokument?: GeneriertesDokumentJsonld | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidDokumentJsonld
     */
    token?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidDokumentJsonld
     */
    tokenCreatedAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidDokumentJsonld
     */
    tokenUsedAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidDokumentJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {DateiInterfaceJsonld}
     * @memberof FeststellungsantragBescheidDokumentJsonld
     */
    datei?: DateiInterfaceJsonld;
}
/**
 *
 * @export
 * @interface FeststellungsantragBescheidDokumentJsonldFeststellungsantragRead
 */
export interface FeststellungsantragBescheidDokumentJsonldFeststellungsantragRead {
    /**
     *
     * @type {string | any}
     * @memberof FeststellungsantragBescheidDokumentJsonldFeststellungsantragRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidDokumentJsonldFeststellungsantragRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidDokumentJsonldFeststellungsantragRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof FeststellungsantragBescheidDokumentJsonldFeststellungsantragRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidDokumentJsonldFeststellungsantragRead
     */
    typ?: string;
    /**
     *
     * @type {UploadJsonldFeststellungsantragRead}
     * @memberof FeststellungsantragBescheidDokumentJsonldFeststellungsantragRead
     */
    upload?: UploadJsonldFeststellungsantragRead | null;
    /**
     *
     * @type {GeneriertesDokumentJsonldFeststellungsantragRead}
     * @memberof FeststellungsantragBescheidDokumentJsonldFeststellungsantragRead
     */
    generiertesDokument?: GeneriertesDokumentJsonldFeststellungsantragRead | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragBescheidDokumentJsonldFeststellungsantragVersionRead
 */
export interface FeststellungsantragBescheidDokumentJsonldFeststellungsantragVersionRead {
    /**
     *
     * @type {string | any}
     * @memberof FeststellungsantragBescheidDokumentJsonldFeststellungsantragVersionRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidDokumentJsonldFeststellungsantragVersionRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidDokumentJsonldFeststellungsantragVersionRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof FeststellungsantragBescheidDokumentJsonldFeststellungsantragVersionRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidDokumentJsonldFeststellungsantragVersionRead
     */
    typ?: string;
    /**
     *
     * @type {UploadJsonldFeststellungsantragVersionRead}
     * @memberof FeststellungsantragBescheidDokumentJsonldFeststellungsantragVersionRead
     */
    upload?: UploadJsonldFeststellungsantragVersionRead | null;
    /**
     *
     * @type {GeneriertesDokumentJsonldFeststellungsantragVersionRead}
     * @memberof FeststellungsantragBescheidDokumentJsonldFeststellungsantragVersionRead
     */
    generiertesDokument?: GeneriertesDokumentJsonldFeststellungsantragVersionRead | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragBescheidung
 */
export interface FeststellungsantragBescheidung {
    /**
     *
     * @type {Array<FeststellungsantragBescheidDokument>}
     * @memberof FeststellungsantragBescheidung
     */
    dokumente?: Array<FeststellungsantragBescheidDokument>;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidung
     */
    antrag?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidung
     */
    gegenpruefung?: string | null;
    /**
     *
     * @type {Array<number>}
     * @memberof FeststellungsantragBescheidung
     */
    tenore?: Array<number> | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidung
     */
    einleitungTenor?: string | null;
    /**
     *
     * @type {number}
     * @memberof FeststellungsantragBescheidung
     */
    id?: number;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof FeststellungsantragBescheidung
     */
    versand?: { [key: string]: string };
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidung
     */
    gesendetAt: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidung
     */
    gesendetBy?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof FeststellungsantragBescheidung
     */
    postalischerVersand?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidung
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidung
     */
    createdBy?: string | null;
    /**
     *
     * @type {Array<number>}
     * @memberof FeststellungsantragBescheidung
     */
    tenor?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidung
     */
    versandByTyp?: string;
}
/**
 *
 * @export
 * @interface FeststellungsantragBescheidungBescheidungSetGesendetAt
 */
export interface FeststellungsantragBescheidungBescheidungSetGesendetAt {
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidungBescheidungSetGesendetAt
     */
    gesendetAt: string | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragBescheidungFeststellungsantragRead
 */
export interface FeststellungsantragBescheidungFeststellungsantragRead {
    /**
     *
     * @type {Array<FeststellungsantragBescheidDokumentFeststellungsantragRead>}
     * @memberof FeststellungsantragBescheidungFeststellungsantragRead
     */
    dokumente?: Array<FeststellungsantragBescheidDokumentFeststellungsantragRead>;
    /**
     *
     * @type {number}
     * @memberof FeststellungsantragBescheidungFeststellungsantragRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidungFeststellungsantragRead
     */
    gesendetAt: string | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragBescheidungFeststellungsantragVersionRead
 */
export interface FeststellungsantragBescheidungFeststellungsantragVersionRead {
    /**
     *
     * @type {Array<FeststellungsantragBescheidDokumentFeststellungsantragVersionRead>}
     * @memberof FeststellungsantragBescheidungFeststellungsantragVersionRead
     */
    dokumente?: Array<FeststellungsantragBescheidDokumentFeststellungsantragVersionRead>;
    /**
     *
     * @type {FeststellungsantragGegenpruefungFeststellungsantragVersionRead}
     * @memberof FeststellungsantragBescheidungFeststellungsantragVersionRead
     */
    gegenpruefung?: FeststellungsantragGegenpruefungFeststellungsantragVersionRead | null;
    /**
     *
     * @type {number}
     * @memberof FeststellungsantragBescheidungFeststellungsantragVersionRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidungFeststellungsantragVersionRead
     */
    gesendetAt: string | null;
    /**
     *
     * @type {BenutzerFeststellungsantragVersionRead}
     * @memberof FeststellungsantragBescheidungFeststellungsantragVersionRead
     */
    gesendetBy?: BenutzerFeststellungsantragVersionRead | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragBescheidungJsonld
 */
export interface FeststellungsantragBescheidungJsonld {
    /**
     *
     * @type {string | any}
     * @memberof FeststellungsantragBescheidungJsonld
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidungJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidungJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {Array<FeststellungsantragBescheidDokumentJsonld>}
     * @memberof FeststellungsantragBescheidungJsonld
     */
    dokumente?: Array<FeststellungsantragBescheidDokumentJsonld>;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidungJsonld
     */
    antrag?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidungJsonld
     */
    gegenpruefung?: string | null;
    /**
     *
     * @type {Array<number>}
     * @memberof FeststellungsantragBescheidungJsonld
     */
    tenore?: Array<number> | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidungJsonld
     */
    einleitungTenor?: string | null;
    /**
     *
     * @type {number}
     * @memberof FeststellungsantragBescheidungJsonld
     */
    id?: number;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof FeststellungsantragBescheidungJsonld
     */
    versand?: { [key: string]: string };
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidungJsonld
     */
    gesendetAt: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidungJsonld
     */
    gesendetBy?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof FeststellungsantragBescheidungJsonld
     */
    postalischerVersand?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidungJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidungJsonld
     */
    createdBy?: string | null;
    /**
     *
     * @type {Array<number>}
     * @memberof FeststellungsantragBescheidungJsonld
     */
    tenor?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidungJsonld
     */
    versandByTyp?: string;
}
/**
 *
 * @export
 * @interface FeststellungsantragBescheidungJsonldFeststellungsantragRead
 */
export interface FeststellungsantragBescheidungJsonldFeststellungsantragRead {
    /**
     *
     * @type {string | any}
     * @memberof FeststellungsantragBescheidungJsonldFeststellungsantragRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidungJsonldFeststellungsantragRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidungJsonldFeststellungsantragRead
     */
    '@type'?: string;
    /**
     *
     * @type {Array<FeststellungsantragBescheidDokumentJsonldFeststellungsantragRead>}
     * @memberof FeststellungsantragBescheidungJsonldFeststellungsantragRead
     */
    dokumente?: Array<FeststellungsantragBescheidDokumentJsonldFeststellungsantragRead>;
    /**
     *
     * @type {number}
     * @memberof FeststellungsantragBescheidungJsonldFeststellungsantragRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidungJsonldFeststellungsantragRead
     */
    gesendetAt: string | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragBescheidungJsonldFeststellungsantragVersionRead
 */
export interface FeststellungsantragBescheidungJsonldFeststellungsantragVersionRead {
    /**
     *
     * @type {string | any}
     * @memberof FeststellungsantragBescheidungJsonldFeststellungsantragVersionRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidungJsonldFeststellungsantragVersionRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidungJsonldFeststellungsantragVersionRead
     */
    '@type'?: string;
    /**
     *
     * @type {Array<FeststellungsantragBescheidDokumentJsonldFeststellungsantragVersionRead>}
     * @memberof FeststellungsantragBescheidungJsonldFeststellungsantragVersionRead
     */
    dokumente?: Array<FeststellungsantragBescheidDokumentJsonldFeststellungsantragVersionRead>;
    /**
     *
     * @type {FeststellungsantragGegenpruefungJsonldFeststellungsantragVersionRead}
     * @memberof FeststellungsantragBescheidungJsonldFeststellungsantragVersionRead
     */
    gegenpruefung?: FeststellungsantragGegenpruefungJsonldFeststellungsantragVersionRead | null;
    /**
     *
     * @type {number}
     * @memberof FeststellungsantragBescheidungJsonldFeststellungsantragVersionRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragBescheidungJsonldFeststellungsantragVersionRead
     */
    gesendetAt: string | null;
    /**
     *
     * @type {BenutzerJsonldFeststellungsantragVersionRead}
     * @memberof FeststellungsantragBescheidungJsonldFeststellungsantragVersionRead
     */
    gesendetBy?: BenutzerJsonldFeststellungsantragVersionRead | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragCollectionFiltersOutputCollectionFilters
 */
export interface FeststellungsantragCollectionFiltersOutputCollectionFilters {
    /**
     *
     * @type {Array<CollectionFilterCollectionFilters>}
     * @memberof FeststellungsantragCollectionFiltersOutputCollectionFilters
     */
    filters?: Array<CollectionFilterCollectionFilters>;
}
/**
 *
 * @export
 * @interface FeststellungsantragCollectionFiltersOutputJsonldCollectionFilters
 */
export interface FeststellungsantragCollectionFiltersOutputJsonldCollectionFilters {
    /**
     *
     * @type {string | any}
     * @memberof FeststellungsantragCollectionFiltersOutputJsonldCollectionFilters
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragCollectionFiltersOutputJsonldCollectionFilters
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragCollectionFiltersOutputJsonldCollectionFilters
     */
    '@type'?: string;
    /**
     *
     * @type {Array<CollectionFilterJsonldCollectionFilters>}
     * @memberof FeststellungsantragCollectionFiltersOutputJsonldCollectionFilters
     */
    filters?: Array<CollectionFilterJsonldCollectionFilters>;
}
/**
 *
 * @export
 * @interface FeststellungsantragDataEinrichtungRead
 */
export interface FeststellungsantragDataEinrichtungRead {
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataEinrichtungRead
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataEinrichtungRead
     */
    field100?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataEinrichtungRead
     */
    field102?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataEinrichtungRead
     */
    field103d?: string | null;
    /**
     *
     * @type {number}
     * @memberof FeststellungsantragDataEinrichtungRead
     */
    field110?: number | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataEinrichtungRead
     */
    field122?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataEinrichtungRead
     */
    field140?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataEinrichtungRead
     */
    field142?: string | null;
    /**
     *
     * @type {Array<MassnahmeEinrichtungRead>}
     * @memberof FeststellungsantragDataEinrichtungRead
     */
    massnahmen?: Array<MassnahmeEinrichtungRead> | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataEinrichtungRead
     */
    field121?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataEinrichtungRead
     */
    field0301?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataEinrichtungRead
     */
    field0720?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataEinrichtungRead
     */
    fieldSumme0519?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataEinrichtungRead
     */
    berechnungsart?: string | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragDataFestsetzungsantragList
 */
export interface FeststellungsantragDataFestsetzungsantragList {
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataFestsetzungsantragList
     */
    berechnungsart?: string | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragDataFeststellungsantragList
 */
export interface FeststellungsantragDataFeststellungsantragList {
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataFeststellungsantragList
     */
    field100?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataFeststellungsantragList
     */
    field101?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataFeststellungsantragList
     */
    field102?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataFeststellungsantragList
     */
    field121?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataFeststellungsantragList
     */
    field0301?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataFeststellungsantragList
     */
    field0720?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataFeststellungsantragList
     */
    fieldSumme0519?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataFeststellungsantragList
     */
    berechnungsart?: string | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragDataFeststellungsantragRead
 */
export interface FeststellungsantragDataFeststellungsantragRead {
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataFeststellungsantragRead
     */
    field100?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataFeststellungsantragRead
     */
    field101?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataFeststellungsantragRead
     */
    field103d?: string | null;
    /**
     *
     * @type {DokumentFeststellungsantragRead}
     * @memberof FeststellungsantragDataFeststellungsantragRead
     */
    field123d?: DokumentFeststellungsantragRead | null;
    /**
     *
     * @type {Array<DokumentFeststellungsantragRead>}
     * @memberof FeststellungsantragDataFeststellungsantragRead
     */
    field161d?: Array<DokumentFeststellungsantragRead> | null;
    /**
     *
     * @type {Array<DokumentFeststellungsantragRead>}
     * @memberof FeststellungsantragDataFeststellungsantragRead
     */
    field141d?: Array<DokumentFeststellungsantragRead> | null;
    /**
     *
     * @type {Array<AusnahmegenehmigungP10Abs3Satz2Ziffer2ApgFeststellungsantragRead>}
     * @memberof FeststellungsantragDataFeststellungsantragRead
     */
    ausnahmegenehmigungenP10Abs3Satz2Ziffer2Apg?: Array<AusnahmegenehmigungP10Abs3Satz2Ziffer2ApgFeststellungsantragRead> | null;
    /**
     *
     * @type {DokumentFeststellungsantragRead}
     * @memberof FeststellungsantragDataFeststellungsantragRead
     */
    field410d?: DokumentFeststellungsantragRead | null;
    /**
     *
     * @type {Array<MassnahmeFeststellungsantragRead>}
     * @memberof FeststellungsantragDataFeststellungsantragRead
     */
    massnahmen?: Array<MassnahmeFeststellungsantragRead> | null;
    /**
     *
     * @type {Array<SalgImEigentumFeststellungsantragRead>}
     * @memberof FeststellungsantragDataFeststellungsantragRead
     */
    salgImEigentum?: Array<SalgImEigentumFeststellungsantragRead> | null;
    /**
     *
     * @type {Array<SalgImLeasingFeststellungsantragRead>}
     * @memberof FeststellungsantragDataFeststellungsantragRead
     */
    salgImLeasing?: Array<SalgImLeasingFeststellungsantragRead> | null;
    /**
     *
     * @type {Array<DokumentFeststellungsantragRead>}
     * @memberof FeststellungsantragDataFeststellungsantragRead
     */
    dokumente?: Array<DokumentFeststellungsantragRead> | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragDataFeststellungsantragVersionRead
 */
export interface FeststellungsantragDataFeststellungsantragVersionRead {
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataFeststellungsantragVersionRead
     */
    field103d?: string | null;
    /**
     *
     * @type {DokumentFeststellungsantragVersionRead}
     * @memberof FeststellungsantragDataFeststellungsantragVersionRead
     */
    field123d?: DokumentFeststellungsantragVersionRead | null;
    /**
     *
     * @type {Array<DokumentFeststellungsantragVersionRead>}
     * @memberof FeststellungsantragDataFeststellungsantragVersionRead
     */
    field161d?: Array<DokumentFeststellungsantragVersionRead> | null;
    /**
     *
     * @type {Array<DokumentFeststellungsantragVersionRead>}
     * @memberof FeststellungsantragDataFeststellungsantragVersionRead
     */
    field141d?: Array<DokumentFeststellungsantragVersionRead> | null;
    /**
     *
     * @type {Array<AusnahmegenehmigungP10Abs3Satz2Ziffer2ApgFeststellungsantragVersionRead>}
     * @memberof FeststellungsantragDataFeststellungsantragVersionRead
     */
    ausnahmegenehmigungenP10Abs3Satz2Ziffer2Apg?: Array<AusnahmegenehmigungP10Abs3Satz2Ziffer2ApgFeststellungsantragVersionRead> | null;
    /**
     *
     * @type {DokumentFeststellungsantragVersionRead}
     * @memberof FeststellungsantragDataFeststellungsantragVersionRead
     */
    field410d?: DokumentFeststellungsantragVersionRead | null;
    /**
     *
     * @type {Array<MassnahmeFeststellungsantragVersionRead>}
     * @memberof FeststellungsantragDataFeststellungsantragVersionRead
     */
    massnahmen?: Array<MassnahmeFeststellungsantragVersionRead> | null;
    /**
     *
     * @type {Array<SalgImEigentumFeststellungsantragVersionRead>}
     * @memberof FeststellungsantragDataFeststellungsantragVersionRead
     */
    salgImEigentum?: Array<SalgImEigentumFeststellungsantragVersionRead> | null;
    /**
     *
     * @type {Array<SalgImLeasingFeststellungsantragVersionRead>}
     * @memberof FeststellungsantragDataFeststellungsantragVersionRead
     */
    salgImLeasing?: Array<SalgImLeasingFeststellungsantragVersionRead> | null;
    /**
     *
     * @type {Array<DokumentFeststellungsantragVersionRead>}
     * @memberof FeststellungsantragDataFeststellungsantragVersionRead
     */
    dokumente?: Array<DokumentFeststellungsantragVersionRead> | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragDataInvestorenmodellList
 */
export interface FeststellungsantragDataInvestorenmodellList {
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataInvestorenmodellList
     */
    berechnungsart?: string | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragDataJsonldEinrichtungRead
 */
export interface FeststellungsantragDataJsonldEinrichtungRead {
    /**
     *
     * @type {string | any}
     * @memberof FeststellungsantragDataJsonldEinrichtungRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldEinrichtungRead
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldEinrichtungRead
     */
    field100?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldEinrichtungRead
     */
    field102?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldEinrichtungRead
     */
    field103d?: string | null;
    /**
     *
     * @type {number}
     * @memberof FeststellungsantragDataJsonldEinrichtungRead
     */
    field110?: number | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldEinrichtungRead
     */
    field122?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldEinrichtungRead
     */
    field140?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldEinrichtungRead
     */
    field142?: string | null;
    /**
     *
     * @type {Array<MassnahmeJsonldEinrichtungRead>}
     * @memberof FeststellungsantragDataJsonldEinrichtungRead
     */
    massnahmen?: Array<MassnahmeJsonldEinrichtungRead> | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldEinrichtungRead
     */
    field121?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldEinrichtungRead
     */
    field0301?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldEinrichtungRead
     */
    field0720?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldEinrichtungRead
     */
    fieldSumme0519?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldEinrichtungRead
     */
    berechnungsart?: string | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragDataJsonldFestsetzungsantragList
 */
export interface FeststellungsantragDataJsonldFestsetzungsantragList {
    /**
     *
     * @type {string | any}
     * @memberof FeststellungsantragDataJsonldFestsetzungsantragList
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldFestsetzungsantragList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldFestsetzungsantragList
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldFestsetzungsantragList
     */
    berechnungsart?: string | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragDataJsonldFeststellungsantragList
 */
export interface FeststellungsantragDataJsonldFeststellungsantragList {
    /**
     *
     * @type {string | any}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragList
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragList
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragList
     */
    field100?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragList
     */
    field101?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragList
     */
    field102?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragList
     */
    field121?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragList
     */
    field0301?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragList
     */
    field0720?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragList
     */
    fieldSumme0519?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragList
     */
    berechnungsart?: string | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragDataJsonldFeststellungsantragRead
 */
export interface FeststellungsantragDataJsonldFeststellungsantragRead {
    /**
     *
     * @type {string | any}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragRead
     */
    field100?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragRead
     */
    field101?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragRead
     */
    field103d?: string | null;
    /**
     *
     * @type {DokumentJsonldFeststellungsantragRead}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragRead
     */
    field123d?: DokumentJsonldFeststellungsantragRead | null;
    /**
     *
     * @type {Array<DokumentJsonldFeststellungsantragRead>}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragRead
     */
    field161d?: Array<DokumentJsonldFeststellungsantragRead> | null;
    /**
     *
     * @type {Array<DokumentJsonldFeststellungsantragRead>}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragRead
     */
    field141d?: Array<DokumentJsonldFeststellungsantragRead> | null;
    /**
     *
     * @type {Array<AusnahmegenehmigungP10Abs3Satz2Ziffer2ApgJsonldFeststellungsantragRead>}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragRead
     */
    ausnahmegenehmigungenP10Abs3Satz2Ziffer2Apg?: Array<AusnahmegenehmigungP10Abs3Satz2Ziffer2ApgJsonldFeststellungsantragRead> | null;
    /**
     *
     * @type {DokumentJsonldFeststellungsantragRead}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragRead
     */
    field410d?: DokumentJsonldFeststellungsantragRead | null;
    /**
     *
     * @type {Array<MassnahmeJsonldFeststellungsantragRead>}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragRead
     */
    massnahmen?: Array<MassnahmeJsonldFeststellungsantragRead> | null;
    /**
     *
     * @type {Array<SalgImEigentumJsonldFeststellungsantragRead>}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragRead
     */
    salgImEigentum?: Array<SalgImEigentumJsonldFeststellungsantragRead> | null;
    /**
     *
     * @type {Array<SalgImLeasingJsonldFeststellungsantragRead>}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragRead
     */
    salgImLeasing?: Array<SalgImLeasingJsonldFeststellungsantragRead> | null;
    /**
     *
     * @type {Array<DokumentJsonldFeststellungsantragRead>}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragRead
     */
    dokumente?: Array<DokumentJsonldFeststellungsantragRead> | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragDataJsonldFeststellungsantragVersionRead
 */
export interface FeststellungsantragDataJsonldFeststellungsantragVersionRead {
    /**
     *
     * @type {string | any}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragVersionRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragVersionRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragVersionRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragVersionRead
     */
    field103d?: string | null;
    /**
     *
     * @type {DokumentJsonldFeststellungsantragVersionRead}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragVersionRead
     */
    field123d?: DokumentJsonldFeststellungsantragVersionRead | null;
    /**
     *
     * @type {Array<DokumentJsonldFeststellungsantragVersionRead>}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragVersionRead
     */
    field161d?: Array<DokumentJsonldFeststellungsantragVersionRead> | null;
    /**
     *
     * @type {Array<DokumentJsonldFeststellungsantragVersionRead>}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragVersionRead
     */
    field141d?: Array<DokumentJsonldFeststellungsantragVersionRead> | null;
    /**
     *
     * @type {Array<AusnahmegenehmigungP10Abs3Satz2Ziffer2ApgJsonldFeststellungsantragVersionRead>}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragVersionRead
     */
    ausnahmegenehmigungenP10Abs3Satz2Ziffer2Apg?: Array<AusnahmegenehmigungP10Abs3Satz2Ziffer2ApgJsonldFeststellungsantragVersionRead> | null;
    /**
     *
     * @type {DokumentJsonldFeststellungsantragVersionRead}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragVersionRead
     */
    field410d?: DokumentJsonldFeststellungsantragVersionRead | null;
    /**
     *
     * @type {Array<MassnahmeJsonldFeststellungsantragVersionRead>}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragVersionRead
     */
    massnahmen?: Array<MassnahmeJsonldFeststellungsantragVersionRead> | null;
    /**
     *
     * @type {Array<SalgImEigentumJsonldFeststellungsantragVersionRead>}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragVersionRead
     */
    salgImEigentum?: Array<SalgImEigentumJsonldFeststellungsantragVersionRead> | null;
    /**
     *
     * @type {Array<SalgImLeasingJsonldFeststellungsantragVersionRead>}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragVersionRead
     */
    salgImLeasing?: Array<SalgImLeasingJsonldFeststellungsantragVersionRead> | null;
    /**
     *
     * @type {Array<DokumentJsonldFeststellungsantragVersionRead>}
     * @memberof FeststellungsantragDataJsonldFeststellungsantragVersionRead
     */
    dokumente?: Array<DokumentJsonldFeststellungsantragVersionRead> | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragDataJsonldInvestorenmodellList
 */
export interface FeststellungsantragDataJsonldInvestorenmodellList {
    /**
     *
     * @type {string | any}
     * @memberof FeststellungsantragDataJsonldInvestorenmodellList
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldInvestorenmodellList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldInvestorenmodellList
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragDataJsonldInvestorenmodellList
     */
    berechnungsart?: string | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragEinrichtungRead
 */
export interface FeststellungsantragEinrichtungRead {
    /**
     *
     * @type {FeststellungsantragDataEinrichtungRead}
     * @memberof FeststellungsantragEinrichtungRead
     */
    data?: FeststellungsantragDataEinrichtungRead | null;
    /**
     *
     * @type {BenutzerEinrichtungRead}
     * @memberof FeststellungsantragEinrichtungRead
     */
    zuletztEingereichtBy?: BenutzerEinrichtungRead | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AbstractBescheidungEinrichtungRead}
     * @memberof FeststellungsantragEinrichtungRead
     */
    letzteBescheidung?: AbstractBescheidungEinrichtungRead | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragEmailListEmailReadEmail
 */
export interface FeststellungsantragEmailListEmailReadEmail {
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragEmailListEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface FeststellungsantragEmailReadEmailReadEmail
 */
export interface FeststellungsantragEmailReadEmailReadEmail {
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragEmailReadEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface FeststellungsantragFestsetzungsantragList
 */
export interface FeststellungsantragFestsetzungsantragList {
    /**
     *
     * @type {FeststellungsantragDataFestsetzungsantragList}
     * @memberof FeststellungsantragFestsetzungsantragList
     */
    data?: FeststellungsantragDataFestsetzungsantragList | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragFestsetzungsantragList
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface FeststellungsantragFeststellungsantragCreate
 */
export interface FeststellungsantragFeststellungsantragCreate {
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragFeststellungsantragCreate
     */
    einrichtung?: string;
}
/**
 *
 * @export
 * @interface FeststellungsantragFeststellungsantragJsonAntragJsonForm
 */
export interface FeststellungsantragFeststellungsantragJsonAntragJsonForm {
    /**
     *
     * @type {object}
     * @memberof FeststellungsantragFeststellungsantragJsonAntragJsonForm
     */
    data?: object;
    /**
     *
     * @type {JsonSchemaAntragJsonForm}
     * @memberof FeststellungsantragFeststellungsantragJsonAntragJsonForm
     */
    schema?: JsonSchemaAntragJsonForm;
    /**
     *
     * @type {{ [key: string]: Array<string>; }}
     * @memberof FeststellungsantragFeststellungsantragJsonAntragJsonForm
     */
    errors?: { [key: string]: Array<string> };
    /**
     *
     * @type {Array<JsonFormStepAntragJsonForm>}
     * @memberof FeststellungsantragFeststellungsantragJsonAntragJsonForm
     */
    steps?: Array<JsonFormStepAntragJsonForm>;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragFeststellungsantragJsonAntragJsonForm
     */
    step?: string;
    /**
     *
     * @type {number}
     * @memberof FeststellungsantragFeststellungsantragJsonAntragJsonForm
     */
    id?: number;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof FeststellungsantragFeststellungsantragJsonAntragJsonForm
     */
    uuidMap?: { [key: string]: string };
    /**
     *
     * @type {Array<string>}
     * @memberof FeststellungsantragFeststellungsantragJsonAntragJsonForm
     */
    allowedWorkflowActions?: Array<string>;
}
/**
 *
 * @export
 * @interface FeststellungsantragFeststellungsantragJsonJsonldAntragJsonForm
 */
export interface FeststellungsantragFeststellungsantragJsonJsonldAntragJsonForm {
    /**
     *
     * @type {string | any}
     * @memberof FeststellungsantragFeststellungsantragJsonJsonldAntragJsonForm
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragFeststellungsantragJsonJsonldAntragJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragFeststellungsantragJsonJsonldAntragJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {object}
     * @memberof FeststellungsantragFeststellungsantragJsonJsonldAntragJsonForm
     */
    data?: object;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonForm}
     * @memberof FeststellungsantragFeststellungsantragJsonJsonldAntragJsonForm
     */
    schema?: JsonSchemaJsonldAntragJsonForm;
    /**
     *
     * @type {{ [key: string]: Array<string>; }}
     * @memberof FeststellungsantragFeststellungsantragJsonJsonldAntragJsonForm
     */
    errors?: { [key: string]: Array<string> };
    /**
     *
     * @type {Array<JsonFormStepJsonldAntragJsonForm>}
     * @memberof FeststellungsantragFeststellungsantragJsonJsonldAntragJsonForm
     */
    steps?: Array<JsonFormStepJsonldAntragJsonForm>;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragFeststellungsantragJsonJsonldAntragJsonForm
     */
    step?: string;
    /**
     *
     * @type {number}
     * @memberof FeststellungsantragFeststellungsantragJsonJsonldAntragJsonForm
     */
    id?: number;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof FeststellungsantragFeststellungsantragJsonJsonldAntragJsonForm
     */
    uuidMap?: { [key: string]: string };
    /**
     *
     * @type {Array<string>}
     * @memberof FeststellungsantragFeststellungsantragJsonJsonldAntragJsonForm
     */
    allowedWorkflowActions?: Array<string>;
}
/**
 *
 * @export
 * @interface FeststellungsantragFeststellungsantragList
 */
export interface FeststellungsantragFeststellungsantragList {
    /**
     *
     * @type {number}
     * @memberof FeststellungsantragFeststellungsantragList
     */
    id?: number;
    /**
     *
     * @type {FeststellungsantragDataFeststellungsantragList}
     * @memberof FeststellungsantragFeststellungsantragList
     */
    data?: FeststellungsantragDataFeststellungsantragList | null;
    /**
     *
     * @type {EinrichtungFeststellungsantragList}
     * @memberof FeststellungsantragFeststellungsantragList
     */
    einrichtung?: EinrichtungFeststellungsantragList;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragFeststellungsantragList
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragFeststellungsantragList
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragFeststellungsantragList
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof FeststellungsantragFeststellungsantragList
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragFeststellungsantragList
     */
    createdAt?: string;
    /**
     *
     * @type {AbstractBescheidungFeststellungsantragList}
     * @memberof FeststellungsantragFeststellungsantragList
     */
    letzteBescheidung?: AbstractBescheidungFeststellungsantragList | null;
    /**
     *
     * @type {AbstractZurueckziehenFeststellungsantragList}
     * @memberof FeststellungsantragFeststellungsantragList
     */
    letztesZurueckziehen?: AbstractZurueckziehenFeststellungsantragList | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragFeststellungsantragRead
 */
export interface FeststellungsantragFeststellungsantragRead {
    /**
     *
     * @type {number}
     * @memberof FeststellungsantragFeststellungsantragRead
     */
    id?: number;
    /**
     *
     * @type {FeststellungsantragDataFeststellungsantragRead}
     * @memberof FeststellungsantragFeststellungsantragRead
     */
    data?: FeststellungsantragDataFeststellungsantragRead | null;
    /**
     *
     * @type {Array<string>}
     * @memberof FeststellungsantragFeststellungsantragRead
     */
    hinweiseAusMigration?: Array<string> | null;
    /**
     *
     * @type {EinrichtungFeststellungsantragRead}
     * @memberof FeststellungsantragFeststellungsantragRead
     */
    einrichtung?: EinrichtungFeststellungsantragRead;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragFeststellungsantragRead
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragFeststellungsantragRead
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragFeststellungsantragRead
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {BenutzerFeststellungsantragRead}
     * @memberof FeststellungsantragFeststellungsantragRead
     */
    eingereichtBy?: BenutzerFeststellungsantragRead | null;
    /**
     *
     * @type {BenutzerFeststellungsantragRead}
     * @memberof FeststellungsantragFeststellungsantragRead
     */
    zuletztEingereichtBy?: BenutzerFeststellungsantragRead | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragFeststellungsantragRead
     */
    art?: string | null;
    /**
     *
     * @type {Array<FeststellungsantragBescheidungFeststellungsantragRead>}
     * @memberof FeststellungsantragFeststellungsantragRead
     */
    bescheidungen?: Array<FeststellungsantragBescheidungFeststellungsantragRead>;
    /**
     *
     * @type {Array<string>}
     * @memberof FeststellungsantragFeststellungsantragRead
     */
    zurueckziehen?: Array<string>;
    /**
     *
     * @type {Array<FestsetzungsantragFeststellungsantragRead>}
     * @memberof FeststellungsantragFeststellungsantragRead
     */
    festsetzungsantraege?: Array<FestsetzungsantragFeststellungsantragRead>;
    /**
     *
     * @type {Array<string>}
     * @memberof FeststellungsantragFeststellungsantragRead
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragFeststellungsantragRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof FeststellungsantragFeststellungsantragRead
     */
    uuidMap?: { [key: string]: string };
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragFeststellungsantragRead
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragFeststellungsantragRead
     */
    einrichtungErstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragFeststellungsantragRead
     */
    einrichtungVersorgungsvertrag?: string | null;
    /**
     *
     * @type {AbstractGegenpruefungFeststellungsantragRead}
     * @memberof FeststellungsantragFeststellungsantragRead
     */
    letzteGegenpruefung?: AbstractGegenpruefungFeststellungsantragRead | null;
    /**
     *
     * @type {AbstractBescheidungFeststellungsantragRead}
     * @memberof FeststellungsantragFeststellungsantragRead
     */
    letzteBescheidung?: AbstractBescheidungFeststellungsantragRead | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragFeststellungsantragVersionRead
 */
export interface FeststellungsantragFeststellungsantragVersionRead {
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragFeststellungsantragVersionRead
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {BenutzerFeststellungsantragVersionRead}
     * @memberof FeststellungsantragFeststellungsantragVersionRead
     */
    eingereichtBy?: BenutzerFeststellungsantragVersionRead | null;
    /**
     *
     * @type {Array<FeststellungsantragBescheidungFeststellungsantragVersionRead>}
     * @memberof FeststellungsantragFeststellungsantragVersionRead
     */
    bescheidungen?: Array<FeststellungsantragBescheidungFeststellungsantragVersionRead>;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof FeststellungsantragFeststellungsantragVersionRead
     */
    uuidMap?: { [key: string]: string };
}
/**
 *
 * @export
 * @interface FeststellungsantragFormDiffAntragFormDiff
 */
export interface FeststellungsantragFormDiffAntragFormDiff {
    /**
     *
     * @type {Array<FormFieldDiffAntragFormDiff>}
     * @memberof FeststellungsantragFormDiffAntragFormDiff
     */
    fieldDiffs?: Array<FormFieldDiffAntragFormDiff>;
}
/**
 *
 * @export
 * @interface FeststellungsantragFormDiffJsonldAntragFormDiff
 */
export interface FeststellungsantragFormDiffJsonldAntragFormDiff {
    /**
     *
     * @type {string | any}
     * @memberof FeststellungsantragFormDiffJsonldAntragFormDiff
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragFormDiffJsonldAntragFormDiff
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragFormDiffJsonldAntragFormDiff
     */
    '@type'?: string;
    /**
     *
     * @type {Array<FormFieldDiffJsonldAntragFormDiff>}
     * @memberof FeststellungsantragFormDiffJsonldAntragFormDiff
     */
    fieldDiffs?: Array<FormFieldDiffJsonldAntragFormDiff>;
}
/**
 *
 * @export
 * @interface FeststellungsantragGegenpruefung
 */
export interface FeststellungsantragGegenpruefung {
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragGegenpruefung
     */
    antrag?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragGegenpruefung
     */
    bescheidung?: string | null;
    /**
     *
     * @type {number}
     * @memberof FeststellungsantragGegenpruefung
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragGegenpruefung
     */
    geprueftBy?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragGegenpruefung
     */
    geprueftAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragGegenpruefung
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragGegenpruefung
     */
    erlaeuterung?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragGegenpruefung
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragGegenpruefung
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragGegenpruefungFeststellungsantragVersionRead
 */
export interface FeststellungsantragGegenpruefungFeststellungsantragVersionRead {
    /**
     *
     * @type {BenutzerFeststellungsantragVersionRead}
     * @memberof FeststellungsantragGegenpruefungFeststellungsantragVersionRead
     */
    geprueftBy?: BenutzerFeststellungsantragVersionRead | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragGegenpruefungFeststellungsantragVersionRead
     */
    geprueftAt?: string | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragGegenpruefungGegenpruefungEdit
 */
export interface FeststellungsantragGegenpruefungGegenpruefungEdit {
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragGegenpruefungGegenpruefungEdit
     */
    erlaeuterung?: string | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragGegenpruefungJsonld
 */
export interface FeststellungsantragGegenpruefungJsonld {
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragGegenpruefungJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragGegenpruefungJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {string | any}
     * @memberof FeststellungsantragGegenpruefungJsonld
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragGegenpruefungJsonld
     */
    antrag?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragGegenpruefungJsonld
     */
    bescheidung?: string | null;
    /**
     *
     * @type {number}
     * @memberof FeststellungsantragGegenpruefungJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragGegenpruefungJsonld
     */
    geprueftBy?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragGegenpruefungJsonld
     */
    geprueftAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragGegenpruefungJsonld
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragGegenpruefungJsonld
     */
    erlaeuterung?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragGegenpruefungJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragGegenpruefungJsonld
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragGegenpruefungJsonldFeststellungsantragVersionRead
 */
export interface FeststellungsantragGegenpruefungJsonldFeststellungsantragVersionRead {
    /**
     *
     * @type {string | any}
     * @memberof FeststellungsantragGegenpruefungJsonldFeststellungsantragVersionRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragGegenpruefungJsonldFeststellungsantragVersionRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragGegenpruefungJsonldFeststellungsantragVersionRead
     */
    '@type'?: string;
    /**
     *
     * @type {BenutzerJsonldFeststellungsantragVersionRead}
     * @memberof FeststellungsantragGegenpruefungJsonldFeststellungsantragVersionRead
     */
    geprueftBy?: BenutzerJsonldFeststellungsantragVersionRead | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragGegenpruefungJsonldFeststellungsantragVersionRead
     */
    geprueftAt?: string | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragInvestorenmodellList
 */
export interface FeststellungsantragInvestorenmodellList {
    /**
     *
     * @type {FeststellungsantragDataInvestorenmodellList}
     * @memberof FeststellungsantragInvestorenmodellList
     */
    data?: FeststellungsantragDataInvestorenmodellList | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragInvestorenmodellList
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface FeststellungsantragJsonldEinrichtungRead
 */
export interface FeststellungsantragJsonldEinrichtungRead {
    /**
     *
     * @type {string | any}
     * @memberof FeststellungsantragJsonldEinrichtungRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {FeststellungsantragDataJsonldEinrichtungRead}
     * @memberof FeststellungsantragJsonldEinrichtungRead
     */
    data?: FeststellungsantragDataJsonldEinrichtungRead | null;
    /**
     *
     * @type {BenutzerJsonldEinrichtungRead}
     * @memberof FeststellungsantragJsonldEinrichtungRead
     */
    zuletztEingereichtBy?: BenutzerJsonldEinrichtungRead | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AbstractBescheidungJsonldEinrichtungRead}
     * @memberof FeststellungsantragJsonldEinrichtungRead
     */
    letzteBescheidung?: AbstractBescheidungJsonldEinrichtungRead | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragJsonldEmailListEmailReadEmail
 */
export interface FeststellungsantragJsonldEmailListEmailReadEmail {
    /**
     *
     * @type {string | any}
     * @memberof FeststellungsantragJsonldEmailListEmailReadEmail
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldEmailListEmailReadEmail
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldEmailListEmailReadEmail
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldEmailListEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface FeststellungsantragJsonldEmailReadEmailReadEmail
 */
export interface FeststellungsantragJsonldEmailReadEmailReadEmail {
    /**
     *
     * @type {string | any}
     * @memberof FeststellungsantragJsonldEmailReadEmailReadEmail
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldEmailReadEmailReadEmail
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldEmailReadEmailReadEmail
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldEmailReadEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface FeststellungsantragJsonldFestsetzungsantragList
 */
export interface FeststellungsantragJsonldFestsetzungsantragList {
    /**
     *
     * @type {string | any}
     * @memberof FeststellungsantragJsonldFestsetzungsantragList
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldFestsetzungsantragList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldFestsetzungsantragList
     */
    '@type'?: string;
    /**
     *
     * @type {FeststellungsantragDataJsonldFestsetzungsantragList}
     * @memberof FeststellungsantragJsonldFestsetzungsantragList
     */
    data?: FeststellungsantragDataJsonldFestsetzungsantragList | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldFestsetzungsantragList
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface FeststellungsantragJsonldFeststellungsantragCreate
 */
export interface FeststellungsantragJsonldFeststellungsantragCreate {
    /**
     *
     * @type {string | any}
     * @memberof FeststellungsantragJsonldFeststellungsantragCreate
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldFeststellungsantragCreate
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldFeststellungsantragCreate
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldFeststellungsantragCreate
     */
    einrichtung?: string;
}
/**
 *
 * @export
 * @interface FeststellungsantragJsonldFeststellungsantragList
 */
export interface FeststellungsantragJsonldFeststellungsantragList {
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldFeststellungsantragList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldFeststellungsantragList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof FeststellungsantragJsonldFeststellungsantragList
     */
    id?: number;
    /**
     *
     * @type {FeststellungsantragDataJsonldFeststellungsantragList}
     * @memberof FeststellungsantragJsonldFeststellungsantragList
     */
    data?: FeststellungsantragDataJsonldFeststellungsantragList | null;
    /**
     *
     * @type {EinrichtungJsonldFeststellungsantragList}
     * @memberof FeststellungsantragJsonldFeststellungsantragList
     */
    einrichtung?: EinrichtungJsonldFeststellungsantragList;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldFeststellungsantragList
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldFeststellungsantragList
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldFeststellungsantragList
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof FeststellungsantragJsonldFeststellungsantragList
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldFeststellungsantragList
     */
    createdAt?: string;
    /**
     *
     * @type {AbstractBescheidungJsonldFeststellungsantragList}
     * @memberof FeststellungsantragJsonldFeststellungsantragList
     */
    letzteBescheidung?: AbstractBescheidungJsonldFeststellungsantragList | null;
    /**
     *
     * @type {AbstractZurueckziehenJsonldFeststellungsantragList}
     * @memberof FeststellungsantragJsonldFeststellungsantragList
     */
    letztesZurueckziehen?: AbstractZurueckziehenJsonldFeststellungsantragList | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragJsonldFeststellungsantragRead
 */
export interface FeststellungsantragJsonldFeststellungsantragRead {
    /**
     *
     * @type {string | any}
     * @memberof FeststellungsantragJsonldFeststellungsantragRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldFeststellungsantragRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldFeststellungsantragRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof FeststellungsantragJsonldFeststellungsantragRead
     */
    id?: number;
    /**
     *
     * @type {FeststellungsantragDataJsonldFeststellungsantragRead}
     * @memberof FeststellungsantragJsonldFeststellungsantragRead
     */
    data?: FeststellungsantragDataJsonldFeststellungsantragRead | null;
    /**
     *
     * @type {Array<string>}
     * @memberof FeststellungsantragJsonldFeststellungsantragRead
     */
    hinweiseAusMigration?: Array<string> | null;
    /**
     *
     * @type {EinrichtungJsonldFeststellungsantragRead}
     * @memberof FeststellungsantragJsonldFeststellungsantragRead
     */
    einrichtung?: EinrichtungJsonldFeststellungsantragRead;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldFeststellungsantragRead
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldFeststellungsantragRead
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldFeststellungsantragRead
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {BenutzerJsonldFeststellungsantragRead}
     * @memberof FeststellungsantragJsonldFeststellungsantragRead
     */
    eingereichtBy?: BenutzerJsonldFeststellungsantragRead | null;
    /**
     *
     * @type {BenutzerJsonldFeststellungsantragRead}
     * @memberof FeststellungsantragJsonldFeststellungsantragRead
     */
    zuletztEingereichtBy?: BenutzerJsonldFeststellungsantragRead | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldFeststellungsantragRead
     */
    art?: string | null;
    /**
     *
     * @type {Array<FeststellungsantragBescheidungJsonldFeststellungsantragRead>}
     * @memberof FeststellungsantragJsonldFeststellungsantragRead
     */
    bescheidungen?: Array<FeststellungsantragBescheidungJsonldFeststellungsantragRead>;
    /**
     *
     * @type {Array<string>}
     * @memberof FeststellungsantragJsonldFeststellungsantragRead
     */
    zurueckziehen?: Array<string>;
    /**
     *
     * @type {Array<FestsetzungsantragJsonldFeststellungsantragRead>}
     * @memberof FeststellungsantragJsonldFeststellungsantragRead
     */
    festsetzungsantraege?: Array<FestsetzungsantragJsonldFeststellungsantragRead>;
    /**
     *
     * @type {Array<string>}
     * @memberof FeststellungsantragJsonldFeststellungsantragRead
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldFeststellungsantragRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof FeststellungsantragJsonldFeststellungsantragRead
     */
    uuidMap?: { [key: string]: string };
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldFeststellungsantragRead
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldFeststellungsantragRead
     */
    einrichtungErstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldFeststellungsantragRead
     */
    einrichtungVersorgungsvertrag?: string | null;
    /**
     *
     * @type {AbstractGegenpruefungJsonldFeststellungsantragRead}
     * @memberof FeststellungsantragJsonldFeststellungsantragRead
     */
    letzteGegenpruefung?: AbstractGegenpruefungJsonldFeststellungsantragRead | null;
    /**
     *
     * @type {AbstractBescheidungJsonldFeststellungsantragRead}
     * @memberof FeststellungsantragJsonldFeststellungsantragRead
     */
    letzteBescheidung?: AbstractBescheidungJsonldFeststellungsantragRead | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragJsonldFeststellungsantragVersionRead
 */
export interface FeststellungsantragJsonldFeststellungsantragVersionRead {
    /**
     *
     * @type {string | any}
     * @memberof FeststellungsantragJsonldFeststellungsantragVersionRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldFeststellungsantragVersionRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldFeststellungsantragVersionRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldFeststellungsantragVersionRead
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {BenutzerJsonldFeststellungsantragVersionRead}
     * @memberof FeststellungsantragJsonldFeststellungsantragVersionRead
     */
    eingereichtBy?: BenutzerJsonldFeststellungsantragVersionRead | null;
    /**
     *
     * @type {Array<FeststellungsantragBescheidungJsonldFeststellungsantragVersionRead>}
     * @memberof FeststellungsantragJsonldFeststellungsantragVersionRead
     */
    bescheidungen?: Array<FeststellungsantragBescheidungJsonldFeststellungsantragVersionRead>;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof FeststellungsantragJsonldFeststellungsantragVersionRead
     */
    uuidMap?: { [key: string]: string };
}
/**
 *
 * @export
 * @interface FeststellungsantragJsonldInvestorenmodellList
 */
export interface FeststellungsantragJsonldInvestorenmodellList {
    /**
     *
     * @type {string | any}
     * @memberof FeststellungsantragJsonldInvestorenmodellList
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldInvestorenmodellList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldInvestorenmodellList
     */
    '@type'?: string;
    /**
     *
     * @type {FeststellungsantragDataJsonldInvestorenmodellList}
     * @memberof FeststellungsantragJsonldInvestorenmodellList
     */
    data?: FeststellungsantragDataJsonldInvestorenmodellList | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragJsonldInvestorenmodellList
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface FeststellungsantragVersionFeststellungsantragVersionList
 */
export interface FeststellungsantragVersionFeststellungsantragVersionList {
    /**
     *
     * @type {number}
     * @memberof FeststellungsantragVersionFeststellungsantragVersionList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragVersionFeststellungsantragVersionList
     */
    typ?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragVersionFeststellungsantragVersionList
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragVersionFeststellungsantragVersionList
     */
    antragModifiedAt: string;
}
/**
 *
 * @export
 * @interface FeststellungsantragVersionFeststellungsantragVersionRead
 */
export interface FeststellungsantragVersionFeststellungsantragVersionRead {
    /**
     *
     * @type {FeststellungsantragFeststellungsantragVersionRead}
     * @memberof FeststellungsantragVersionFeststellungsantragVersionRead
     */
    antrag?: FeststellungsantragFeststellungsantragVersionRead;
    /**
     *
     * @type {FeststellungsantragDataFeststellungsantragVersionRead}
     * @memberof FeststellungsantragVersionFeststellungsantragVersionRead
     */
    data?: FeststellungsantragDataFeststellungsantragVersionRead | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragVersionFeststellungsantragVersionRead
     */
    antragModifiedAt: string;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof FeststellungsantragVersionFeststellungsantragVersionRead
     */
    uuidMap?: { [key: string]: string };
    /**
     *
     * @type {FeststellungsantragBescheidungFeststellungsantragVersionRead}
     * @memberof FeststellungsantragVersionFeststellungsantragVersionRead
     */
    bescheidung?: FeststellungsantragBescheidungFeststellungsantragVersionRead | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragVersionJsonldFeststellungsantragVersionList
 */
export interface FeststellungsantragVersionJsonldFeststellungsantragVersionList {
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragVersionJsonldFeststellungsantragVersionList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragVersionJsonldFeststellungsantragVersionList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof FeststellungsantragVersionJsonldFeststellungsantragVersionList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragVersionJsonldFeststellungsantragVersionList
     */
    typ?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragVersionJsonldFeststellungsantragVersionList
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragVersionJsonldFeststellungsantragVersionList
     */
    antragModifiedAt: string;
}
/**
 *
 * @export
 * @interface FeststellungsantragVersionJsonldFeststellungsantragVersionRead
 */
export interface FeststellungsantragVersionJsonldFeststellungsantragVersionRead {
    /**
     *
     * @type {string | any}
     * @memberof FeststellungsantragVersionJsonldFeststellungsantragVersionRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragVersionJsonldFeststellungsantragVersionRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragVersionJsonldFeststellungsantragVersionRead
     */
    '@type'?: string;
    /**
     *
     * @type {FeststellungsantragJsonldFeststellungsantragVersionRead}
     * @memberof FeststellungsantragVersionJsonldFeststellungsantragVersionRead
     */
    antrag?: FeststellungsantragJsonldFeststellungsantragVersionRead;
    /**
     *
     * @type {FeststellungsantragDataJsonldFeststellungsantragVersionRead}
     * @memberof FeststellungsantragVersionJsonldFeststellungsantragVersionRead
     */
    data?: FeststellungsantragDataJsonldFeststellungsantragVersionRead | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragVersionJsonldFeststellungsantragVersionRead
     */
    antragModifiedAt: string;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof FeststellungsantragVersionJsonldFeststellungsantragVersionRead
     */
    uuidMap?: { [key: string]: string };
    /**
     *
     * @type {FeststellungsantragBescheidungJsonldFeststellungsantragVersionRead}
     * @memberof FeststellungsantragVersionJsonldFeststellungsantragVersionRead
     */
    bescheidung?: FeststellungsantragBescheidungJsonldFeststellungsantragVersionRead | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragZurueckziehen
 */
export interface FeststellungsantragZurueckziehen {
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragZurueckziehen
     */
    antrag?: string;
    /**
     *
     * @type {number}
     * @memberof FeststellungsantragZurueckziehen
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragZurueckziehen
     */
    erlaeuterung: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragZurueckziehen
     */
    entschiedenBy?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragZurueckziehen
     */
    entschiedenAt?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof FeststellungsantragZurueckziehen
     */
    entscheidung?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragZurueckziehen
     */
    erlaeuterungEntscheidung?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragZurueckziehen
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragZurueckziehen
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragZurueckziehenJsonld
 */
export interface FeststellungsantragZurueckziehenJsonld {
    /**
     *
     * @type {string | any}
     * @memberof FeststellungsantragZurueckziehenJsonld
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragZurueckziehenJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragZurueckziehenJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragZurueckziehenJsonld
     */
    antrag?: string;
    /**
     *
     * @type {number}
     * @memberof FeststellungsantragZurueckziehenJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragZurueckziehenJsonld
     */
    erlaeuterung: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragZurueckziehenJsonld
     */
    entschiedenBy?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragZurueckziehenJsonld
     */
    entschiedenAt?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof FeststellungsantragZurueckziehenJsonld
     */
    entscheidung?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragZurueckziehenJsonld
     */
    erlaeuterungEntscheidung?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragZurueckziehenJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragZurueckziehenJsonld
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragZurueckziehenJsonldZurueckziehenCreate
 */
export interface FeststellungsantragZurueckziehenJsonldZurueckziehenCreate {
    /**
     *
     * @type {string | any}
     * @memberof FeststellungsantragZurueckziehenJsonldZurueckziehenCreate
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragZurueckziehenJsonldZurueckziehenCreate
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragZurueckziehenJsonldZurueckziehenCreate
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragZurueckziehenJsonldZurueckziehenCreate
     */
    antrag?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragZurueckziehenJsonldZurueckziehenCreate
     */
    erlaeuterung: string | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragZurueckziehenZurueckziehenCreate
 */
export interface FeststellungsantragZurueckziehenZurueckziehenCreate {
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragZurueckziehenZurueckziehenCreate
     */
    antrag?: string;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragZurueckziehenZurueckziehenCreate
     */
    erlaeuterung: string | null;
}
/**
 *
 * @export
 * @interface FeststellungsantragZurueckziehenZurueckziehenSetEntscheidung
 */
export interface FeststellungsantragZurueckziehenZurueckziehenSetEntscheidung {
    /**
     *
     * @type {boolean}
     * @memberof FeststellungsantragZurueckziehenZurueckziehenSetEntscheidung
     */
    entscheidung?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof FeststellungsantragZurueckziehenZurueckziehenSetEntscheidung
     */
    erlaeuterungEntscheidung?: string | null;
}
/**
 *
 * @export
 * @interface FormFieldDiffAntragFormDiff
 */
export interface FormFieldDiffAntragFormDiff {
    /**
     *
     * @type {string}
     * @memberof FormFieldDiffAntragFormDiff
     */
    name?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof FormFieldDiffAntragFormDiff
     */
    blockPrefixes?: Array<string> | null;
    /**
     *
     * @type {string}
     * @memberof FormFieldDiffAntragFormDiff
     */
    label?: string | null;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof FormFieldDiffAntragFormDiff
     */
    choices?: { [key: string]: string } | null;
    /**
     *
     * @type {any}
     * @memberof FormFieldDiffAntragFormDiff
     */
    v1?: any;
    /**
     *
     * @type {any}
     * @memberof FormFieldDiffAntragFormDiff
     */
    v2?: any;
}
/**
 *
 * @export
 * @interface FormFieldDiffJsonldAntragFormDiff
 */
export interface FormFieldDiffJsonldAntragFormDiff {
    /**
     *
     * @type {string | any}
     * @memberof FormFieldDiffJsonldAntragFormDiff
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FormFieldDiffJsonldAntragFormDiff
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FormFieldDiffJsonldAntragFormDiff
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof FormFieldDiffJsonldAntragFormDiff
     */
    name?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof FormFieldDiffJsonldAntragFormDiff
     */
    blockPrefixes?: Array<string> | null;
    /**
     *
     * @type {string}
     * @memberof FormFieldDiffJsonldAntragFormDiff
     */
    label?: string | null;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof FormFieldDiffJsonldAntragFormDiff
     */
    choices?: { [key: string]: string } | null;
    /**
     *
     * @type {any}
     * @memberof FormFieldDiffJsonldAntragFormDiff
     */
    v1?: any;
    /**
     *
     * @type {any}
     * @memberof FormFieldDiffJsonldAntragFormDiff
     */
    v2?: any;
}
/**
 *
 * @export
 * @interface FremdkapitalAnteilEinrichtungRead
 */
export interface FremdkapitalAnteilEinrichtungRead {
    /**
     *
     * @type {string}
     * @memberof FremdkapitalAnteilEinrichtungRead
     */
    field251?: string | null;
}
/**
 *
 * @export
 * @interface FremdkapitalAnteilJsonldEinrichtungRead
 */
export interface FremdkapitalAnteilJsonldEinrichtungRead {
    /**
     *
     * @type {string | any}
     * @memberof FremdkapitalAnteilJsonldEinrichtungRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FremdkapitalAnteilJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FremdkapitalAnteilJsonldEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof FremdkapitalAnteilJsonldEinrichtungRead
     */
    field251?: string | null;
}
/**
 *
 * @export
 * @interface FremdkapitalEinrichtungRead
 */
export interface FremdkapitalEinrichtungRead {
    /**
     *
     * @type {Array<FremdkapitalAnteilEinrichtungRead>}
     * @memberof FremdkapitalEinrichtungRead
     */
    fremdkapitalAnteile?: Array<FremdkapitalAnteilEinrichtungRead> | null;
    /**
     *
     * @type {string}
     * @memberof FremdkapitalEinrichtungRead
     */
    field270?: string | null;
    /**
     *
     * @type {string}
     * @memberof FremdkapitalEinrichtungRead
     */
    field277?: string | null;
    /**
     *
     * @type {string}
     * @memberof FremdkapitalEinrichtungRead
     */
    field1455?: string | null;
}
/**
 *
 * @export
 * @interface FremdkapitalJsonldEinrichtungRead
 */
export interface FremdkapitalJsonldEinrichtungRead {
    /**
     *
     * @type {string | any}
     * @memberof FremdkapitalJsonldEinrichtungRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FremdkapitalJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FremdkapitalJsonldEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {Array<FremdkapitalAnteilJsonldEinrichtungRead>}
     * @memberof FremdkapitalJsonldEinrichtungRead
     */
    fremdkapitalAnteile?: Array<FremdkapitalAnteilJsonldEinrichtungRead> | null;
    /**
     *
     * @type {string}
     * @memberof FremdkapitalJsonldEinrichtungRead
     */
    field270?: string | null;
    /**
     *
     * @type {string}
     * @memberof FremdkapitalJsonldEinrichtungRead
     */
    field277?: string | null;
    /**
     *
     * @type {string}
     * @memberof FremdkapitalJsonldEinrichtungRead
     */
    field1455?: string | null;
}
/**
 *
 * @export
 * @interface FremdkapitalzinsenBundesbank
 */
export interface FremdkapitalzinsenBundesbank {
    /**
     *
     * @type {string}
     * @memberof FremdkapitalzinsenBundesbank
     */
    monat: string;
    /**
     *
     * @type {number}
     * @memberof FremdkapitalzinsenBundesbank
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof FremdkapitalzinsenBundesbank
     */
    value: string;
    /**
     *
     * @type {string}
     * @memberof FremdkapitalzinsenBundesbank
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof FremdkapitalzinsenBundesbank
     */
    modifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof FremdkapitalzinsenBundesbank
     */
    modifiedBy?: string | null;
}
/**
 *
 * @export
 * @interface FremdkapitalzinsenBundesbankJsonld
 */
export interface FremdkapitalzinsenBundesbankJsonld {
    /**
     *
     * @type {string}
     * @memberof FremdkapitalzinsenBundesbankJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FremdkapitalzinsenBundesbankJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {string | any}
     * @memberof FremdkapitalzinsenBundesbankJsonld
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof FremdkapitalzinsenBundesbankJsonld
     */
    monat: string;
    /**
     *
     * @type {number}
     * @memberof FremdkapitalzinsenBundesbankJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof FremdkapitalzinsenBundesbankJsonld
     */
    value: string;
    /**
     *
     * @type {string}
     * @memberof FremdkapitalzinsenBundesbankJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof FremdkapitalzinsenBundesbankJsonld
     */
    modifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof FremdkapitalzinsenBundesbankJsonld
     */
    modifiedBy?: string | null;
}
/**
 *
 * @export
 * @interface GeneriertesDokument
 */
export interface GeneriertesDokument {
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokument
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokument
     */
    contentUrl?: string | null;
    /**
     *
     * @type {any}
     * @memberof GeneriertesDokument
     */
    file?: any | null;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokument
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokument
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokument
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokument
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokument
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokument
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface GeneriertesDokumentFestsetzungsantragList
 */
export interface GeneriertesDokumentFestsetzungsantragList {
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentFestsetzungsantragList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentFestsetzungsantragList
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentFestsetzungsantragList
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentFestsetzungsantragList
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentFestsetzungsantragList
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentFestsetzungsantragList
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentFestsetzungsantragList
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerFestsetzungsantragList}
     * @memberof GeneriertesDokumentFestsetzungsantragList
     */
    createdBy?: BenutzerFestsetzungsantragList | null;
}
/**
 *
 * @export
 * @interface GeneriertesDokumentFestsetzungsantragRead
 */
export interface GeneriertesDokumentFestsetzungsantragRead {
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentFestsetzungsantragRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentFestsetzungsantragRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentFestsetzungsantragRead
     */
    originalName?: string;
}
/**
 *
 * @export
 * @interface GeneriertesDokumentFestsetzungsantragVersionRead
 */
export interface GeneriertesDokumentFestsetzungsantragVersionRead {
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentFestsetzungsantragVersionRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentFestsetzungsantragVersionRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentFestsetzungsantragVersionRead
     */
    originalName?: string;
}
/**
 *
 * @export
 * @interface GeneriertesDokumentFeststellungsantragList
 */
export interface GeneriertesDokumentFeststellungsantragList {
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentFeststellungsantragList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentFeststellungsantragList
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentFeststellungsantragList
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentFeststellungsantragList
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentFeststellungsantragList
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentFeststellungsantragList
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentFeststellungsantragList
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerFeststellungsantragList}
     * @memberof GeneriertesDokumentFeststellungsantragList
     */
    createdBy?: BenutzerFeststellungsantragList | null;
}
/**
 *
 * @export
 * @interface GeneriertesDokumentFeststellungsantragRead
 */
export interface GeneriertesDokumentFeststellungsantragRead {
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentFeststellungsantragRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentFeststellungsantragRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentFeststellungsantragRead
     */
    originalName?: string;
}
/**
 *
 * @export
 * @interface GeneriertesDokumentFeststellungsantragVersionRead
 */
export interface GeneriertesDokumentFeststellungsantragVersionRead {
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentFeststellungsantragVersionRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentFeststellungsantragVersionRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentFeststellungsantragVersionRead
     */
    originalName?: string;
}
/**
 *
 * @export
 * @interface GeneriertesDokumentInvestorenmodellList
 */
export interface GeneriertesDokumentInvestorenmodellList {
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentInvestorenmodellList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentInvestorenmodellList
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentInvestorenmodellList
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentInvestorenmodellList
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentInvestorenmodellList
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentInvestorenmodellList
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentInvestorenmodellList
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerInvestorenmodellList}
     * @memberof GeneriertesDokumentInvestorenmodellList
     */
    createdBy?: BenutzerInvestorenmodellList | null;
}
/**
 *
 * @export
 * @interface GeneriertesDokumentInvestorenmodellRead
 */
export interface GeneriertesDokumentInvestorenmodellRead {
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentInvestorenmodellRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentInvestorenmodellRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentInvestorenmodellRead
     */
    originalName?: string;
}
/**
 *
 * @export
 * @interface GeneriertesDokumentInvestorenmodellVersionRead
 */
export interface GeneriertesDokumentInvestorenmodellVersionRead {
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentInvestorenmodellVersionRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentInvestorenmodellVersionRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentInvestorenmodellVersionRead
     */
    originalName?: string;
}
/**
 *
 * @export
 * @interface GeneriertesDokumentJsonld
 */
export interface GeneriertesDokumentJsonld {
    /**
     *
     * @type {string | any}
     * @memberof GeneriertesDokumentJsonld
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonld
     */
    contentUrl?: string | null;
    /**
     *
     * @type {any}
     * @memberof GeneriertesDokumentJsonld
     */
    file?: any | null;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonld
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonld
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentJsonld
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonld
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonld
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface GeneriertesDokumentJsonldFestsetzungsantragList
 */
export interface GeneriertesDokumentJsonldFestsetzungsantragList {
    /**
     *
     * @type {string | any}
     * @memberof GeneriertesDokumentJsonldFestsetzungsantragList
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldFestsetzungsantragList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldFestsetzungsantragList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentJsonldFestsetzungsantragList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldFestsetzungsantragList
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldFestsetzungsantragList
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldFestsetzungsantragList
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentJsonldFestsetzungsantragList
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldFestsetzungsantragList
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldFestsetzungsantragList
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerJsonldFestsetzungsantragList}
     * @memberof GeneriertesDokumentJsonldFestsetzungsantragList
     */
    createdBy?: BenutzerJsonldFestsetzungsantragList | null;
}
/**
 *
 * @export
 * @interface GeneriertesDokumentJsonldFestsetzungsantragRead
 */
export interface GeneriertesDokumentJsonldFestsetzungsantragRead {
    /**
     *
     * @type {string | any}
     * @memberof GeneriertesDokumentJsonldFestsetzungsantragRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldFestsetzungsantragRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldFestsetzungsantragRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentJsonldFestsetzungsantragRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldFestsetzungsantragRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldFestsetzungsantragRead
     */
    originalName?: string;
}
/**
 *
 * @export
 * @interface GeneriertesDokumentJsonldFestsetzungsantragVersionRead
 */
export interface GeneriertesDokumentJsonldFestsetzungsantragVersionRead {
    /**
     *
     * @type {string | any}
     * @memberof GeneriertesDokumentJsonldFestsetzungsantragVersionRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldFestsetzungsantragVersionRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldFestsetzungsantragVersionRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentJsonldFestsetzungsantragVersionRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldFestsetzungsantragVersionRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldFestsetzungsantragVersionRead
     */
    originalName?: string;
}
/**
 *
 * @export
 * @interface GeneriertesDokumentJsonldFeststellungsantragList
 */
export interface GeneriertesDokumentJsonldFeststellungsantragList {
    /**
     *
     * @type {string | any}
     * @memberof GeneriertesDokumentJsonldFeststellungsantragList
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldFeststellungsantragList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldFeststellungsantragList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentJsonldFeststellungsantragList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldFeststellungsantragList
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldFeststellungsantragList
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldFeststellungsantragList
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentJsonldFeststellungsantragList
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldFeststellungsantragList
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldFeststellungsantragList
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerJsonldFeststellungsantragList}
     * @memberof GeneriertesDokumentJsonldFeststellungsantragList
     */
    createdBy?: BenutzerJsonldFeststellungsantragList | null;
}
/**
 *
 * @export
 * @interface GeneriertesDokumentJsonldFeststellungsantragRead
 */
export interface GeneriertesDokumentJsonldFeststellungsantragRead {
    /**
     *
     * @type {string | any}
     * @memberof GeneriertesDokumentJsonldFeststellungsantragRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldFeststellungsantragRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldFeststellungsantragRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentJsonldFeststellungsantragRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldFeststellungsantragRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldFeststellungsantragRead
     */
    originalName?: string;
}
/**
 *
 * @export
 * @interface GeneriertesDokumentJsonldFeststellungsantragVersionRead
 */
export interface GeneriertesDokumentJsonldFeststellungsantragVersionRead {
    /**
     *
     * @type {string | any}
     * @memberof GeneriertesDokumentJsonldFeststellungsantragVersionRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldFeststellungsantragVersionRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldFeststellungsantragVersionRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentJsonldFeststellungsantragVersionRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldFeststellungsantragVersionRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldFeststellungsantragVersionRead
     */
    originalName?: string;
}
/**
 *
 * @export
 * @interface GeneriertesDokumentJsonldInvestorenmodellList
 */
export interface GeneriertesDokumentJsonldInvestorenmodellList {
    /**
     *
     * @type {string | any}
     * @memberof GeneriertesDokumentJsonldInvestorenmodellList
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldInvestorenmodellList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldInvestorenmodellList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentJsonldInvestorenmodellList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldInvestorenmodellList
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldInvestorenmodellList
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldInvestorenmodellList
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentJsonldInvestorenmodellList
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldInvestorenmodellList
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldInvestorenmodellList
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerJsonldInvestorenmodellList}
     * @memberof GeneriertesDokumentJsonldInvestorenmodellList
     */
    createdBy?: BenutzerJsonldInvestorenmodellList | null;
}
/**
 *
 * @export
 * @interface GeneriertesDokumentJsonldInvestorenmodellRead
 */
export interface GeneriertesDokumentJsonldInvestorenmodellRead {
    /**
     *
     * @type {string | any}
     * @memberof GeneriertesDokumentJsonldInvestorenmodellRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldInvestorenmodellRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldInvestorenmodellRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentJsonldInvestorenmodellRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldInvestorenmodellRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldInvestorenmodellRead
     */
    originalName?: string;
}
/**
 *
 * @export
 * @interface GeneriertesDokumentJsonldInvestorenmodellVersionRead
 */
export interface GeneriertesDokumentJsonldInvestorenmodellVersionRead {
    /**
     *
     * @type {string | any}
     * @memberof GeneriertesDokumentJsonldInvestorenmodellVersionRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldInvestorenmodellVersionRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldInvestorenmodellVersionRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentJsonldInvestorenmodellVersionRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldInvestorenmodellVersionRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldInvestorenmodellVersionRead
     */
    originalName?: string;
}
/**
 *
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     *
     * @type {Array<AngemessenheitsgrenzeJsonld>}
     * @memberof InlineResponse200
     */
    'hydra:member': Array<AngemessenheitsgrenzeJsonld>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse200
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse200
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse200
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     *
     * @type {Array<BaupreisindexJsonld>}
     * @memberof InlineResponse2001
     */
    'hydra:member': Array<BaupreisindexJsonld>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse2001
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse2001
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse2001
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20010
 */
export interface InlineResponse20010 {
    /**
     *
     * @type {Array<FestsetzungsantragGegenpruefungJsonld>}
     * @memberof InlineResponse20010
     */
    'hydra:member': Array<FestsetzungsantragGegenpruefungJsonld>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20010
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20010
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20010
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20011
 */
export interface InlineResponse20011 {
    /**
     *
     * @type {Array<FestsetzungsantragVersionJsonldFestsetzungsantragVersionList>}
     * @memberof InlineResponse20011
     */
    'hydra:member': Array<FestsetzungsantragVersionJsonldFestsetzungsantragVersionList>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20011
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20011
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20011
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20012
 */
export interface InlineResponse20012 {
    /**
     *
     * @type {Array<FeststellungsantragJsonldFeststellungsantragList>}
     * @memberof InlineResponse20012
     */
    'hydra:member': Array<FeststellungsantragJsonldFeststellungsantragList>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20012
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20012
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20012
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20013
 */
export interface InlineResponse20013 {
    /**
     *
     * @type {Array<FeststellungsantragGegenpruefungJsonld>}
     * @memberof InlineResponse20013
     */
    'hydra:member': Array<FeststellungsantragGegenpruefungJsonld>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20013
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20013
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20013
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20014
 */
export interface InlineResponse20014 {
    /**
     *
     * @type {Array<FeststellungsantragVersionJsonldFeststellungsantragVersionList>}
     * @memberof InlineResponse20014
     */
    'hydra:member': Array<FeststellungsantragVersionJsonldFeststellungsantragVersionList>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20014
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20014
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20014
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20015
 */
export interface InlineResponse20015 {
    /**
     *
     * @type {Array<FremdkapitalzinsenBundesbankJsonld>}
     * @memberof InlineResponse20015
     */
    'hydra:member': Array<FremdkapitalzinsenBundesbankJsonld>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20015
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20015
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20015
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20016
 */
export interface InlineResponse20016 {
    /**
     *
     * @type {Array<InvestorenmodellJsonldInvestorenmodellList>}
     * @memberof InlineResponse20016
     */
    'hydra:member': Array<InvestorenmodellJsonldInvestorenmodellList>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20016
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20016
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20016
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20017
 */
export interface InlineResponse20017 {
    /**
     *
     * @type {Array<InvestorenmodellGegenpruefungJsonld>}
     * @memberof InlineResponse20017
     */
    'hydra:member': Array<InvestorenmodellGegenpruefungJsonld>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20017
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20017
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20017
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20018
 */
export interface InlineResponse20018 {
    /**
     *
     * @type {Array<InvestorenmodellVersionJsonldInvestorenmodellVersionList>}
     * @memberof InlineResponse20018
     */
    'hydra:member': Array<InvestorenmodellVersionJsonldInvestorenmodellVersionList>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20018
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20018
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20018
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20019
 */
export interface InlineResponse20019 {
    /**
     *
     * @type {Array<LandschaftsverbandJsonldLandschaftsverbandRead>}
     * @memberof InlineResponse20019
     */
    'hydra:member': Array<LandschaftsverbandJsonldLandschaftsverbandRead>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20019
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20019
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20019
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     *
     * @type {Array<BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles>}
     * @memberof InlineResponse2002
     */
    'hydra:member': Array<BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse2002
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse2002
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse2002
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20020
 */
export interface InlineResponse20020 {
    /**
     *
     * @type {Array<MassenmailJsonldMassenmailList>}
     * @memberof InlineResponse20020
     */
    'hydra:member': Array<MassenmailJsonldMassenmailList>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20020
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20020
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20020
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20021
 */
export interface InlineResponse20021 {
    /**
     *
     * @type {Array<MeldungP29JsonldMeldungP29List>}
     * @memberof InlineResponse20021
     */
    'hydra:member': Array<MeldungP29JsonldMeldungP29List>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20021
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20021
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20021
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20022
 */
export interface InlineResponse20022 {
    /**
     *
     * @type {Array<TraegerJsonldTraegerRead>}
     * @memberof InlineResponse20022
     */
    'hydra:member': Array<TraegerJsonldTraegerRead>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20022
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20022
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20022
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20023
 */
export interface InlineResponse20023 {
    /**
     *
     * @type {Array<TraegerverbandJsonldTraegerverbandRead>}
     * @memberof InlineResponse20023
     */
    'hydra:member': Array<TraegerverbandJsonldTraegerverbandRead>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20023
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20023
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20023
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20024
 */
export interface InlineResponse20024 {
    /**
     *
     * @type {Array<ZustaendigkeitJsonldZustaendigkeitRead>}
     * @memberof InlineResponse20024
     */
    'hydra:member': Array<ZustaendigkeitJsonldZustaendigkeitRead>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20024
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20024
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20024
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
    /**
     *
     * @type {Array<BenutzerTablePreferenceJsonld>}
     * @memberof InlineResponse2003
     */
    'hydra:member': Array<BenutzerTablePreferenceJsonld>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse2003
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse2003
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse2003
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse2004
 */
export interface InlineResponse2004 {
    /**
     *
     * @type {Array<EigenkapitalzinsP5Abs6APGDVOJsonld>}
     * @memberof InlineResponse2004
     */
    'hydra:member': Array<EigenkapitalzinsP5Abs6APGDVOJsonld>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse2004
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse2004
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse2004
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse2005
 */
export interface InlineResponse2005 {
    /**
     *
     * @type {Array<EigenkapitalzinsenBundesbankJsonld>}
     * @memberof InlineResponse2005
     */
    'hydra:member': Array<EigenkapitalzinsenBundesbankJsonld>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse2005
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse2005
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse2005
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse2006
 */
export interface InlineResponse2006 {
    /**
     *
     * @type {Array<EinrichtungJsonldEinrichtungList>}
     * @memberof InlineResponse2006
     */
    'hydra:member': Array<EinrichtungJsonldEinrichtungList>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse2006
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse2006
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse2006
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse2007
 */
export interface InlineResponse2007 {
    /**
     *
     * @type {Array<EinrichtungBenutzerJsonldEinrichtungBenutzerRead>}
     * @memberof InlineResponse2007
     */
    'hydra:member': Array<EinrichtungBenutzerJsonldEinrichtungBenutzerRead>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse2007
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse2007
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse2007
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse2008
 */
export interface InlineResponse2008 {
    /**
     *
     * @type {Array<EmailJsonldEmailListEmailReadEmail>}
     * @memberof InlineResponse2008
     */
    'hydra:member': Array<EmailJsonldEmailListEmailReadEmail>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse2008
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse2008
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse2008
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse2009
 */
export interface InlineResponse2009 {
    /**
     *
     * @type {Array<FestsetzungsantragJsonldFestsetzungsantragList>}
     * @memberof InlineResponse2009
     */
    'hydra:member': Array<FestsetzungsantragJsonldFestsetzungsantragList>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse2009
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse2009
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse2009
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse200HydraSearch
 */
export interface InlineResponse200HydraSearch {
    /**
     *
     * @type {string}
     * @memberof InlineResponse200HydraSearch
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof InlineResponse200HydraSearch
     */
    'hydra:template'?: string;
    /**
     *
     * @type {string}
     * @memberof InlineResponse200HydraSearch
     */
    'hydra:variableRepresentation'?: string;
    /**
     *
     * @type {Array<InlineResponse200HydraSearchHydraMapping>}
     * @memberof InlineResponse200HydraSearch
     */
    'hydra:mapping'?: Array<InlineResponse200HydraSearchHydraMapping>;
}
/**
 *
 * @export
 * @interface InlineResponse200HydraSearchHydraMapping
 */
export interface InlineResponse200HydraSearchHydraMapping {
    /**
     *
     * @type {string}
     * @memberof InlineResponse200HydraSearchHydraMapping
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof InlineResponse200HydraSearchHydraMapping
     */
    variable?: string;
    /**
     *
     * @type {string}
     * @memberof InlineResponse200HydraSearchHydraMapping
     */
    property?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof InlineResponse200HydraSearchHydraMapping
     */
    required?: boolean;
}
/**
 *
 * @export
 * @interface InlineResponse200HydraView
 */
export interface InlineResponse200HydraView {
    /**
     *
     * @type {string}
     * @memberof InlineResponse200HydraView
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof InlineResponse200HydraView
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof InlineResponse200HydraView
     */
    'hydra:first'?: string;
    /**
     *
     * @type {string}
     * @memberof InlineResponse200HydraView
     */
    'hydra:last'?: string;
    /**
     *
     * @type {string}
     * @memberof InlineResponse200HydraView
     */
    'hydra:previous'?: string;
    /**
     *
     * @type {string}
     * @memberof InlineResponse200HydraView
     */
    'hydra:next'?: string;
}
/**
 *
 * @export
 * @interface InvestorenmodellBescheidDokument
 */
export interface InvestorenmodellBescheidDokument {
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidDokument
     */
    bescheidung?: string;
    /**
     *
     * @type {number}
     * @memberof InvestorenmodellBescheidDokument
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidDokument
     */
    typ?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidDokument
     */
    upload?: string | null;
    /**
     *
     * @type {GeneriertesDokument}
     * @memberof InvestorenmodellBescheidDokument
     */
    generiertesDokument?: GeneriertesDokument | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidDokument
     */
    token?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidDokument
     */
    tokenCreatedAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidDokument
     */
    tokenUsedAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidDokument
     */
    createdAt?: string;
    /**
     *
     * @type {DateiInterface}
     * @memberof InvestorenmodellBescheidDokument
     */
    datei?: DateiInterface;
}
/**
 *
 * @export
 * @interface InvestorenmodellBescheidDokumentInvestorenmodellVersionRead
 */
export interface InvestorenmodellBescheidDokumentInvestorenmodellVersionRead {
    /**
     *
     * @type {number}
     * @memberof InvestorenmodellBescheidDokumentInvestorenmodellVersionRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidDokumentInvestorenmodellVersionRead
     */
    typ?: string;
    /**
     *
     * @type {UploadInvestorenmodellVersionRead}
     * @memberof InvestorenmodellBescheidDokumentInvestorenmodellVersionRead
     */
    upload?: UploadInvestorenmodellVersionRead | null;
    /**
     *
     * @type {GeneriertesDokumentInvestorenmodellVersionRead}
     * @memberof InvestorenmodellBescheidDokumentInvestorenmodellVersionRead
     */
    generiertesDokument?: GeneriertesDokumentInvestorenmodellVersionRead | null;
}
/**
 *
 * @export
 * @interface InvestorenmodellBescheidDokumentJsonld
 */
export interface InvestorenmodellBescheidDokumentJsonld {
    /**
     *
     * @type {string | any}
     * @memberof InvestorenmodellBescheidDokumentJsonld
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidDokumentJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidDokumentJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidDokumentJsonld
     */
    bescheidung?: string;
    /**
     *
     * @type {number}
     * @memberof InvestorenmodellBescheidDokumentJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidDokumentJsonld
     */
    typ?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidDokumentJsonld
     */
    upload?: string | null;
    /**
     *
     * @type {GeneriertesDokumentJsonld}
     * @memberof InvestorenmodellBescheidDokumentJsonld
     */
    generiertesDokument?: GeneriertesDokumentJsonld | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidDokumentJsonld
     */
    token?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidDokumentJsonld
     */
    tokenCreatedAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidDokumentJsonld
     */
    tokenUsedAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidDokumentJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {DateiInterfaceJsonld}
     * @memberof InvestorenmodellBescheidDokumentJsonld
     */
    datei?: DateiInterfaceJsonld;
}
/**
 *
 * @export
 * @interface InvestorenmodellBescheidDokumentJsonldInvestorenmodellVersionRead
 */
export interface InvestorenmodellBescheidDokumentJsonldInvestorenmodellVersionRead {
    /**
     *
     * @type {string | any}
     * @memberof InvestorenmodellBescheidDokumentJsonldInvestorenmodellVersionRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidDokumentJsonldInvestorenmodellVersionRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidDokumentJsonldInvestorenmodellVersionRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof InvestorenmodellBescheidDokumentJsonldInvestorenmodellVersionRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidDokumentJsonldInvestorenmodellVersionRead
     */
    typ?: string;
    /**
     *
     * @type {UploadJsonldInvestorenmodellVersionRead}
     * @memberof InvestorenmodellBescheidDokumentJsonldInvestorenmodellVersionRead
     */
    upload?: UploadJsonldInvestorenmodellVersionRead | null;
    /**
     *
     * @type {GeneriertesDokumentJsonldInvestorenmodellVersionRead}
     * @memberof InvestorenmodellBescheidDokumentJsonldInvestorenmodellVersionRead
     */
    generiertesDokument?: GeneriertesDokumentJsonldInvestorenmodellVersionRead | null;
}
/**
 *
 * @export
 * @interface InvestorenmodellBescheidung
 */
export interface InvestorenmodellBescheidung {
    /**
     *
     * @type {Array<InvestorenmodellBescheidDokument>}
     * @memberof InvestorenmodellBescheidung
     */
    dokumente?: Array<InvestorenmodellBescheidDokument>;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidung
     */
    antrag?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidung
     */
    gegenpruefung?: string | null;
    /**
     *
     * @type {number}
     * @memberof InvestorenmodellBescheidung
     */
    id?: number;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof InvestorenmodellBescheidung
     */
    versand?: { [key: string]: string };
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidung
     */
    gesendetAt: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidung
     */
    gesendetBy?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof InvestorenmodellBescheidung
     */
    postalischerVersand?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidung
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidung
     */
    createdBy?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidung
     */
    versandByTyp?: string;
}
/**
 *
 * @export
 * @interface InvestorenmodellBescheidungBescheidungSetGesendetAt
 */
export interface InvestorenmodellBescheidungBescheidungSetGesendetAt {
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidungBescheidungSetGesendetAt
     */
    gesendetAt: string | null;
}
/**
 *
 * @export
 * @interface InvestorenmodellBescheidungInvestorenmodellVersionRead
 */
export interface InvestorenmodellBescheidungInvestorenmodellVersionRead {
    /**
     *
     * @type {Array<InvestorenmodellBescheidDokumentInvestorenmodellVersionRead>}
     * @memberof InvestorenmodellBescheidungInvestorenmodellVersionRead
     */
    dokumente?: Array<InvestorenmodellBescheidDokumentInvestorenmodellVersionRead>;
    /**
     *
     * @type {InvestorenmodellGegenpruefungInvestorenmodellVersionRead}
     * @memberof InvestorenmodellBescheidungInvestorenmodellVersionRead
     */
    gegenpruefung?: InvestorenmodellGegenpruefungInvestorenmodellVersionRead | null;
    /**
     *
     * @type {number}
     * @memberof InvestorenmodellBescheidungInvestorenmodellVersionRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidungInvestorenmodellVersionRead
     */
    gesendetAt: string | null;
    /**
     *
     * @type {BenutzerInvestorenmodellVersionRead}
     * @memberof InvestorenmodellBescheidungInvestorenmodellVersionRead
     */
    gesendetBy?: BenutzerInvestorenmodellVersionRead | null;
}
/**
 *
 * @export
 * @interface InvestorenmodellBescheidungJsonld
 */
export interface InvestorenmodellBescheidungJsonld {
    /**
     *
     * @type {string | any}
     * @memberof InvestorenmodellBescheidungJsonld
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidungJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidungJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {Array<InvestorenmodellBescheidDokumentJsonld>}
     * @memberof InvestorenmodellBescheidungJsonld
     */
    dokumente?: Array<InvestorenmodellBescheidDokumentJsonld>;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidungJsonld
     */
    antrag?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidungJsonld
     */
    gegenpruefung?: string | null;
    /**
     *
     * @type {number}
     * @memberof InvestorenmodellBescheidungJsonld
     */
    id?: number;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof InvestorenmodellBescheidungJsonld
     */
    versand?: { [key: string]: string };
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidungJsonld
     */
    gesendetAt: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidungJsonld
     */
    gesendetBy?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof InvestorenmodellBescheidungJsonld
     */
    postalischerVersand?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidungJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidungJsonld
     */
    createdBy?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidungJsonld
     */
    versandByTyp?: string;
}
/**
 *
 * @export
 * @interface InvestorenmodellBescheidungJsonldInvestorenmodellVersionRead
 */
export interface InvestorenmodellBescheidungJsonldInvestorenmodellVersionRead {
    /**
     *
     * @type {string | any}
     * @memberof InvestorenmodellBescheidungJsonldInvestorenmodellVersionRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidungJsonldInvestorenmodellVersionRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidungJsonldInvestorenmodellVersionRead
     */
    '@type'?: string;
    /**
     *
     * @type {Array<InvestorenmodellBescheidDokumentJsonldInvestorenmodellVersionRead>}
     * @memberof InvestorenmodellBescheidungJsonldInvestorenmodellVersionRead
     */
    dokumente?: Array<InvestorenmodellBescheidDokumentJsonldInvestorenmodellVersionRead>;
    /**
     *
     * @type {InvestorenmodellGegenpruefungJsonldInvestorenmodellVersionRead}
     * @memberof InvestorenmodellBescheidungJsonldInvestorenmodellVersionRead
     */
    gegenpruefung?: InvestorenmodellGegenpruefungJsonldInvestorenmodellVersionRead | null;
    /**
     *
     * @type {number}
     * @memberof InvestorenmodellBescheidungJsonldInvestorenmodellVersionRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellBescheidungJsonldInvestorenmodellVersionRead
     */
    gesendetAt: string | null;
    /**
     *
     * @type {BenutzerJsonldInvestorenmodellVersionRead}
     * @memberof InvestorenmodellBescheidungJsonldInvestorenmodellVersionRead
     */
    gesendetBy?: BenutzerJsonldInvestorenmodellVersionRead | null;
}
/**
 *
 * @export
 * @interface InvestorenmodellCollectionFiltersOutputCollectionFilters
 */
export interface InvestorenmodellCollectionFiltersOutputCollectionFilters {
    /**
     *
     * @type {Array<CollectionFilterCollectionFilters>}
     * @memberof InvestorenmodellCollectionFiltersOutputCollectionFilters
     */
    filters?: Array<CollectionFilterCollectionFilters>;
}
/**
 *
 * @export
 * @interface InvestorenmodellCollectionFiltersOutputJsonldCollectionFilters
 */
export interface InvestorenmodellCollectionFiltersOutputJsonldCollectionFilters {
    /**
     *
     * @type {string | any}
     * @memberof InvestorenmodellCollectionFiltersOutputJsonldCollectionFilters
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellCollectionFiltersOutputJsonldCollectionFilters
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellCollectionFiltersOutputJsonldCollectionFilters
     */
    '@type'?: string;
    /**
     *
     * @type {Array<CollectionFilterJsonldCollectionFilters>}
     * @memberof InvestorenmodellCollectionFiltersOutputJsonldCollectionFilters
     */
    filters?: Array<CollectionFilterJsonldCollectionFilters>;
}
/**
 *
 * @export
 * @interface InvestorenmodellDataJsonldInvestorenmodellList
 */
export interface InvestorenmodellDataJsonldInvestorenmodellList {
    /**
     *
     * @type {string | any}
     * @memberof InvestorenmodellDataJsonldInvestorenmodellList
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellDataJsonldInvestorenmodellList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellDataJsonldInvestorenmodellList
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface InvestorenmodellDataJsonldInvestorenmodellRead
 */
export interface InvestorenmodellDataJsonldInvestorenmodellRead {
    /**
     *
     * @type {string | any}
     * @memberof InvestorenmodellDataJsonldInvestorenmodellRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellDataJsonldInvestorenmodellRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellDataJsonldInvestorenmodellRead
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface InvestorenmodellDataJsonldInvestorenmodellVersionRead
 */
export interface InvestorenmodellDataJsonldInvestorenmodellVersionRead {
    /**
     *
     * @type {string | any}
     * @memberof InvestorenmodellDataJsonldInvestorenmodellVersionRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellDataJsonldInvestorenmodellVersionRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellDataJsonldInvestorenmodellVersionRead
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface InvestorenmodellEmailListEmailReadEmail
 */
export interface InvestorenmodellEmailListEmailReadEmail {
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellEmailListEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface InvestorenmodellEmailReadEmailReadEmail
 */
export interface InvestorenmodellEmailReadEmailReadEmail {
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellEmailReadEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface InvestorenmodellFormDiffAntragFormDiff
 */
export interface InvestorenmodellFormDiffAntragFormDiff {
    /**
     *
     * @type {Array<FormFieldDiffAntragFormDiff>}
     * @memberof InvestorenmodellFormDiffAntragFormDiff
     */
    fieldDiffs?: Array<FormFieldDiffAntragFormDiff>;
}
/**
 *
 * @export
 * @interface InvestorenmodellFormDiffJsonldAntragFormDiff
 */
export interface InvestorenmodellFormDiffJsonldAntragFormDiff {
    /**
     *
     * @type {string | any}
     * @memberof InvestorenmodellFormDiffJsonldAntragFormDiff
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellFormDiffJsonldAntragFormDiff
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellFormDiffJsonldAntragFormDiff
     */
    '@type'?: string;
    /**
     *
     * @type {Array<FormFieldDiffJsonldAntragFormDiff>}
     * @memberof InvestorenmodellFormDiffJsonldAntragFormDiff
     */
    fieldDiffs?: Array<FormFieldDiffJsonldAntragFormDiff>;
}
/**
 *
 * @export
 * @interface InvestorenmodellGegenpruefung
 */
export interface InvestorenmodellGegenpruefung {
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellGegenpruefung
     */
    antrag?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellGegenpruefung
     */
    bescheidung?: string | null;
    /**
     *
     * @type {number}
     * @memberof InvestorenmodellGegenpruefung
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellGegenpruefung
     */
    geprueftBy?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellGegenpruefung
     */
    geprueftAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellGegenpruefung
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellGegenpruefung
     */
    erlaeuterung?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellGegenpruefung
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellGegenpruefung
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface InvestorenmodellGegenpruefungGegenpruefungEdit
 */
export interface InvestorenmodellGegenpruefungGegenpruefungEdit {
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellGegenpruefungGegenpruefungEdit
     */
    erlaeuterung?: string | null;
}
/**
 *
 * @export
 * @interface InvestorenmodellGegenpruefungInvestorenmodellVersionRead
 */
export interface InvestorenmodellGegenpruefungInvestorenmodellVersionRead {
    /**
     *
     * @type {BenutzerInvestorenmodellVersionRead}
     * @memberof InvestorenmodellGegenpruefungInvestorenmodellVersionRead
     */
    geprueftBy?: BenutzerInvestorenmodellVersionRead | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellGegenpruefungInvestorenmodellVersionRead
     */
    geprueftAt?: string | null;
}
/**
 *
 * @export
 * @interface InvestorenmodellGegenpruefungJsonld
 */
export interface InvestorenmodellGegenpruefungJsonld {
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellGegenpruefungJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellGegenpruefungJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {string | any}
     * @memberof InvestorenmodellGegenpruefungJsonld
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellGegenpruefungJsonld
     */
    antrag?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellGegenpruefungJsonld
     */
    bescheidung?: string | null;
    /**
     *
     * @type {number}
     * @memberof InvestorenmodellGegenpruefungJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellGegenpruefungJsonld
     */
    geprueftBy?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellGegenpruefungJsonld
     */
    geprueftAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellGegenpruefungJsonld
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellGegenpruefungJsonld
     */
    erlaeuterung?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellGegenpruefungJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellGegenpruefungJsonld
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface InvestorenmodellGegenpruefungJsonldInvestorenmodellVersionRead
 */
export interface InvestorenmodellGegenpruefungJsonldInvestorenmodellVersionRead {
    /**
     *
     * @type {string | any}
     * @memberof InvestorenmodellGegenpruefungJsonldInvestorenmodellVersionRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellGegenpruefungJsonldInvestorenmodellVersionRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellGegenpruefungJsonldInvestorenmodellVersionRead
     */
    '@type'?: string;
    /**
     *
     * @type {BenutzerJsonldInvestorenmodellVersionRead}
     * @memberof InvestorenmodellGegenpruefungJsonldInvestorenmodellVersionRead
     */
    geprueftBy?: BenutzerJsonldInvestorenmodellVersionRead | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellGegenpruefungJsonldInvestorenmodellVersionRead
     */
    geprueftAt?: string | null;
}
/**
 *
 * @export
 * @interface InvestorenmodellInvestorenmodellCreate
 */
export interface InvestorenmodellInvestorenmodellCreate {
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellInvestorenmodellCreate
     */
    einrichtung?: string;
}
/**
 *
 * @export
 * @interface InvestorenmodellInvestorenmodellJsonAntragJsonForm
 */
export interface InvestorenmodellInvestorenmodellJsonAntragJsonForm {
    /**
     *
     * @type {object}
     * @memberof InvestorenmodellInvestorenmodellJsonAntragJsonForm
     */
    data?: object;
    /**
     *
     * @type {JsonSchemaAntragJsonForm}
     * @memberof InvestorenmodellInvestorenmodellJsonAntragJsonForm
     */
    schema?: JsonSchemaAntragJsonForm;
    /**
     *
     * @type {{ [key: string]: Array<string>; }}
     * @memberof InvestorenmodellInvestorenmodellJsonAntragJsonForm
     */
    errors?: { [key: string]: Array<string> };
    /**
     *
     * @type {Array<JsonFormStepAntragJsonForm>}
     * @memberof InvestorenmodellInvestorenmodellJsonAntragJsonForm
     */
    steps?: Array<JsonFormStepAntragJsonForm>;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellInvestorenmodellJsonAntragJsonForm
     */
    step?: string;
    /**
     *
     * @type {number}
     * @memberof InvestorenmodellInvestorenmodellJsonAntragJsonForm
     */
    id?: number;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof InvestorenmodellInvestorenmodellJsonAntragJsonForm
     */
    uuidMap?: { [key: string]: string };
    /**
     *
     * @type {Array<string>}
     * @memberof InvestorenmodellInvestorenmodellJsonAntragJsonForm
     */
    allowedWorkflowActions?: Array<string>;
}
/**
 *
 * @export
 * @interface InvestorenmodellInvestorenmodellJsonJsonldAntragJsonForm
 */
export interface InvestorenmodellInvestorenmodellJsonJsonldAntragJsonForm {
    /**
     *
     * @type {string | any}
     * @memberof InvestorenmodellInvestorenmodellJsonJsonldAntragJsonForm
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellInvestorenmodellJsonJsonldAntragJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellInvestorenmodellJsonJsonldAntragJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {object}
     * @memberof InvestorenmodellInvestorenmodellJsonJsonldAntragJsonForm
     */
    data?: object;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonForm}
     * @memberof InvestorenmodellInvestorenmodellJsonJsonldAntragJsonForm
     */
    schema?: JsonSchemaJsonldAntragJsonForm;
    /**
     *
     * @type {{ [key: string]: Array<string>; }}
     * @memberof InvestorenmodellInvestorenmodellJsonJsonldAntragJsonForm
     */
    errors?: { [key: string]: Array<string> };
    /**
     *
     * @type {Array<JsonFormStepJsonldAntragJsonForm>}
     * @memberof InvestorenmodellInvestorenmodellJsonJsonldAntragJsonForm
     */
    steps?: Array<JsonFormStepJsonldAntragJsonForm>;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellInvestorenmodellJsonJsonldAntragJsonForm
     */
    step?: string;
    /**
     *
     * @type {number}
     * @memberof InvestorenmodellInvestorenmodellJsonJsonldAntragJsonForm
     */
    id?: number;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof InvestorenmodellInvestorenmodellJsonJsonldAntragJsonForm
     */
    uuidMap?: { [key: string]: string };
    /**
     *
     * @type {Array<string>}
     * @memberof InvestorenmodellInvestorenmodellJsonJsonldAntragJsonForm
     */
    allowedWorkflowActions?: Array<string>;
}
/**
 *
 * @export
 * @interface InvestorenmodellInvestorenmodellList
 */
export interface InvestorenmodellInvestorenmodellList {
    /**
     *
     * @type {number}
     * @memberof InvestorenmodellInvestorenmodellList
     */
    id?: number;
    /**
     *
     * @type {object}
     * @memberof InvestorenmodellInvestorenmodellList
     */
    data?: object | null;
    /**
     *
     * @type {EinrichtungInvestorenmodellList}
     * @memberof InvestorenmodellInvestorenmodellList
     */
    einrichtung?: EinrichtungInvestorenmodellList;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellInvestorenmodellList
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellInvestorenmodellList
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellInvestorenmodellList
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {FestsetzungsantragInvestorenmodellList}
     * @memberof InvestorenmodellInvestorenmodellList
     */
    festsetzungsantrag?: FestsetzungsantragInvestorenmodellList;
    /**
     *
     * @type {Array<string>}
     * @memberof InvestorenmodellInvestorenmodellList
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellInvestorenmodellList
     */
    createdAt?: string;
    /**
     *
     * @type {AbstractBescheidungInvestorenmodellList}
     * @memberof InvestorenmodellInvestorenmodellList
     */
    letzteBescheidung?: AbstractBescheidungInvestorenmodellList | null;
    /**
     *
     * @type {AbstractZurueckziehenInvestorenmodellList}
     * @memberof InvestorenmodellInvestorenmodellList
     */
    letztesZurueckziehen?: AbstractZurueckziehenInvestorenmodellList | null;
}
/**
 *
 * @export
 * @interface InvestorenmodellInvestorenmodellRead
 */
export interface InvestorenmodellInvestorenmodellRead {
    /**
     *
     * @type {number}
     * @memberof InvestorenmodellInvestorenmodellRead
     */
    id?: number;
    /**
     *
     * @type {object}
     * @memberof InvestorenmodellInvestorenmodellRead
     */
    data?: object | null;
    /**
     *
     * @type {EinrichtungInvestorenmodellRead}
     * @memberof InvestorenmodellInvestorenmodellRead
     */
    einrichtung?: EinrichtungInvestorenmodellRead;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellInvestorenmodellRead
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellInvestorenmodellRead
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellInvestorenmodellRead
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {BenutzerInvestorenmodellRead}
     * @memberof InvestorenmodellInvestorenmodellRead
     */
    eingereichtBy?: BenutzerInvestorenmodellRead | null;
    /**
     *
     * @type {BenutzerInvestorenmodellRead}
     * @memberof InvestorenmodellInvestorenmodellRead
     */
    zuletztEingereichtBy?: BenutzerInvestorenmodellRead | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellInvestorenmodellRead
     */
    art?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof InvestorenmodellInvestorenmodellRead
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellInvestorenmodellRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof InvestorenmodellInvestorenmodellRead
     */
    uuidMap?: { [key: string]: string };
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellInvestorenmodellRead
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellInvestorenmodellRead
     */
    einrichtungErstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellInvestorenmodellRead
     */
    einrichtungVersorgungsvertrag?: string | null;
    /**
     *
     * @type {AbstractGegenpruefungInvestorenmodellRead}
     * @memberof InvestorenmodellInvestorenmodellRead
     */
    letzteGegenpruefung?: AbstractGegenpruefungInvestorenmodellRead | null;
    /**
     *
     * @type {AbstractBescheidungInvestorenmodellRead}
     * @memberof InvestorenmodellInvestorenmodellRead
     */
    letzteBescheidung?: AbstractBescheidungInvestorenmodellRead | null;
}
/**
 *
 * @export
 * @interface InvestorenmodellInvestorenmodellVersionRead
 */
export interface InvestorenmodellInvestorenmodellVersionRead {
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellInvestorenmodellVersionRead
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {BenutzerInvestorenmodellVersionRead}
     * @memberof InvestorenmodellInvestorenmodellVersionRead
     */
    eingereichtBy?: BenutzerInvestorenmodellVersionRead | null;
}
/**
 *
 * @export
 * @interface InvestorenmodellJsonldEmailListEmailReadEmail
 */
export interface InvestorenmodellJsonldEmailListEmailReadEmail {
    /**
     *
     * @type {string | any}
     * @memberof InvestorenmodellJsonldEmailListEmailReadEmail
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellJsonldEmailListEmailReadEmail
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellJsonldEmailListEmailReadEmail
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellJsonldEmailListEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface InvestorenmodellJsonldEmailReadEmailReadEmail
 */
export interface InvestorenmodellJsonldEmailReadEmailReadEmail {
    /**
     *
     * @type {string | any}
     * @memberof InvestorenmodellJsonldEmailReadEmailReadEmail
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellJsonldEmailReadEmailReadEmail
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellJsonldEmailReadEmailReadEmail
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellJsonldEmailReadEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface InvestorenmodellJsonldInvestorenmodellCreate
 */
export interface InvestorenmodellJsonldInvestorenmodellCreate {
    /**
     *
     * @type {string | any}
     * @memberof InvestorenmodellJsonldInvestorenmodellCreate
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellJsonldInvestorenmodellCreate
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellJsonldInvestorenmodellCreate
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellJsonldInvestorenmodellCreate
     */
    einrichtung?: string;
}
/**
 *
 * @export
 * @interface InvestorenmodellJsonldInvestorenmodellList
 */
export interface InvestorenmodellJsonldInvestorenmodellList {
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellJsonldInvestorenmodellList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellJsonldInvestorenmodellList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof InvestorenmodellJsonldInvestorenmodellList
     */
    id?: number;
    /**
     *
     * @type {InvestorenmodellDataJsonldInvestorenmodellList}
     * @memberof InvestorenmodellJsonldInvestorenmodellList
     */
    data?: InvestorenmodellDataJsonldInvestorenmodellList | null;
    /**
     *
     * @type {EinrichtungJsonldInvestorenmodellList}
     * @memberof InvestorenmodellJsonldInvestorenmodellList
     */
    einrichtung?: EinrichtungJsonldInvestorenmodellList;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellJsonldInvestorenmodellList
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellJsonldInvestorenmodellList
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellJsonldInvestorenmodellList
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {FestsetzungsantragJsonldInvestorenmodellList}
     * @memberof InvestorenmodellJsonldInvestorenmodellList
     */
    festsetzungsantrag?: FestsetzungsantragJsonldInvestorenmodellList;
    /**
     *
     * @type {Array<string>}
     * @memberof InvestorenmodellJsonldInvestorenmodellList
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellJsonldInvestorenmodellList
     */
    createdAt?: string;
    /**
     *
     * @type {AbstractBescheidungJsonldInvestorenmodellList}
     * @memberof InvestorenmodellJsonldInvestorenmodellList
     */
    letzteBescheidung?: AbstractBescheidungJsonldInvestorenmodellList | null;
    /**
     *
     * @type {AbstractZurueckziehenJsonldInvestorenmodellList}
     * @memberof InvestorenmodellJsonldInvestorenmodellList
     */
    letztesZurueckziehen?: AbstractZurueckziehenJsonldInvestorenmodellList | null;
}
/**
 *
 * @export
 * @interface InvestorenmodellJsonldInvestorenmodellRead
 */
export interface InvestorenmodellJsonldInvestorenmodellRead {
    /**
     *
     * @type {string | any}
     * @memberof InvestorenmodellJsonldInvestorenmodellRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellJsonldInvestorenmodellRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellJsonldInvestorenmodellRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof InvestorenmodellJsonldInvestorenmodellRead
     */
    id?: number;
    /**
     *
     * @type {InvestorenmodellDataJsonldInvestorenmodellRead}
     * @memberof InvestorenmodellJsonldInvestorenmodellRead
     */
    data?: InvestorenmodellDataJsonldInvestorenmodellRead | null;
    /**
     *
     * @type {EinrichtungJsonldInvestorenmodellRead}
     * @memberof InvestorenmodellJsonldInvestorenmodellRead
     */
    einrichtung?: EinrichtungJsonldInvestorenmodellRead;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellJsonldInvestorenmodellRead
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellJsonldInvestorenmodellRead
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellJsonldInvestorenmodellRead
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {BenutzerJsonldInvestorenmodellRead}
     * @memberof InvestorenmodellJsonldInvestorenmodellRead
     */
    eingereichtBy?: BenutzerJsonldInvestorenmodellRead | null;
    /**
     *
     * @type {BenutzerJsonldInvestorenmodellRead}
     * @memberof InvestorenmodellJsonldInvestorenmodellRead
     */
    zuletztEingereichtBy?: BenutzerJsonldInvestorenmodellRead | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellJsonldInvestorenmodellRead
     */
    art?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof InvestorenmodellJsonldInvestorenmodellRead
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellJsonldInvestorenmodellRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof InvestorenmodellJsonldInvestorenmodellRead
     */
    uuidMap?: { [key: string]: string };
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellJsonldInvestorenmodellRead
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellJsonldInvestorenmodellRead
     */
    einrichtungErstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellJsonldInvestorenmodellRead
     */
    einrichtungVersorgungsvertrag?: string | null;
    /**
     *
     * @type {AbstractGegenpruefungJsonldInvestorenmodellRead}
     * @memberof InvestorenmodellJsonldInvestorenmodellRead
     */
    letzteGegenpruefung?: AbstractGegenpruefungJsonldInvestorenmodellRead | null;
    /**
     *
     * @type {AbstractBescheidungJsonldInvestorenmodellRead}
     * @memberof InvestorenmodellJsonldInvestorenmodellRead
     */
    letzteBescheidung?: AbstractBescheidungJsonldInvestorenmodellRead | null;
}
/**
 *
 * @export
 * @interface InvestorenmodellJsonldInvestorenmodellVersionRead
 */
export interface InvestorenmodellJsonldInvestorenmodellVersionRead {
    /**
     *
     * @type {string | any}
     * @memberof InvestorenmodellJsonldInvestorenmodellVersionRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellJsonldInvestorenmodellVersionRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellJsonldInvestorenmodellVersionRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellJsonldInvestorenmodellVersionRead
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {BenutzerJsonldInvestorenmodellVersionRead}
     * @memberof InvestorenmodellJsonldInvestorenmodellVersionRead
     */
    eingereichtBy?: BenutzerJsonldInvestorenmodellVersionRead | null;
}
/**
 *
 * @export
 * @interface InvestorenmodellVersionInvestorenmodellVersionList
 */
export interface InvestorenmodellVersionInvestorenmodellVersionList {
    /**
     *
     * @type {number}
     * @memberof InvestorenmodellVersionInvestorenmodellVersionList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellVersionInvestorenmodellVersionList
     */
    typ?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellVersionInvestorenmodellVersionList
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellVersionInvestorenmodellVersionList
     */
    antragModifiedAt: string;
}
/**
 *
 * @export
 * @interface InvestorenmodellVersionInvestorenmodellVersionRead
 */
export interface InvestorenmodellVersionInvestorenmodellVersionRead {
    /**
     *
     * @type {InvestorenmodellInvestorenmodellVersionRead}
     * @memberof InvestorenmodellVersionInvestorenmodellVersionRead
     */
    antrag?: InvestorenmodellInvestorenmodellVersionRead;
    /**
     *
     * @type {object}
     * @memberof InvestorenmodellVersionInvestorenmodellVersionRead
     */
    data?: object | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellVersionInvestorenmodellVersionRead
     */
    antragModifiedAt: string;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof InvestorenmodellVersionInvestorenmodellVersionRead
     */
    uuidMap?: { [key: string]: string };
    /**
     *
     * @type {InvestorenmodellBescheidungInvestorenmodellVersionRead}
     * @memberof InvestorenmodellVersionInvestorenmodellVersionRead
     */
    bescheidung?: InvestorenmodellBescheidungInvestorenmodellVersionRead | null;
}
/**
 *
 * @export
 * @interface InvestorenmodellVersionJsonldInvestorenmodellVersionList
 */
export interface InvestorenmodellVersionJsonldInvestorenmodellVersionList {
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellVersionJsonldInvestorenmodellVersionList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellVersionJsonldInvestorenmodellVersionList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof InvestorenmodellVersionJsonldInvestorenmodellVersionList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellVersionJsonldInvestorenmodellVersionList
     */
    typ?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellVersionJsonldInvestorenmodellVersionList
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellVersionJsonldInvestorenmodellVersionList
     */
    antragModifiedAt: string;
}
/**
 *
 * @export
 * @interface InvestorenmodellVersionJsonldInvestorenmodellVersionRead
 */
export interface InvestorenmodellVersionJsonldInvestorenmodellVersionRead {
    /**
     *
     * @type {string | any}
     * @memberof InvestorenmodellVersionJsonldInvestorenmodellVersionRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellVersionJsonldInvestorenmodellVersionRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellVersionJsonldInvestorenmodellVersionRead
     */
    '@type'?: string;
    /**
     *
     * @type {InvestorenmodellJsonldInvestorenmodellVersionRead}
     * @memberof InvestorenmodellVersionJsonldInvestorenmodellVersionRead
     */
    antrag?: InvestorenmodellJsonldInvestorenmodellVersionRead;
    /**
     *
     * @type {InvestorenmodellDataJsonldInvestorenmodellVersionRead}
     * @memberof InvestorenmodellVersionJsonldInvestorenmodellVersionRead
     */
    data?: InvestorenmodellDataJsonldInvestorenmodellVersionRead | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellVersionJsonldInvestorenmodellVersionRead
     */
    antragModifiedAt: string;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof InvestorenmodellVersionJsonldInvestorenmodellVersionRead
     */
    uuidMap?: { [key: string]: string };
    /**
     *
     * @type {InvestorenmodellBescheidungJsonldInvestorenmodellVersionRead}
     * @memberof InvestorenmodellVersionJsonldInvestorenmodellVersionRead
     */
    bescheidung?: InvestorenmodellBescheidungJsonldInvestorenmodellVersionRead | null;
}
/**
 *
 * @export
 * @interface InvestorenmodellZurueckziehen
 */
export interface InvestorenmodellZurueckziehen {
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellZurueckziehen
     */
    antrag?: string;
    /**
     *
     * @type {number}
     * @memberof InvestorenmodellZurueckziehen
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellZurueckziehen
     */
    erlaeuterung: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellZurueckziehen
     */
    entschiedenBy?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellZurueckziehen
     */
    entschiedenAt?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof InvestorenmodellZurueckziehen
     */
    entscheidung?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellZurueckziehen
     */
    erlaeuterungEntscheidung?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellZurueckziehen
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellZurueckziehen
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface InvestorenmodellZurueckziehenJsonld
 */
export interface InvestorenmodellZurueckziehenJsonld {
    /**
     *
     * @type {string | any}
     * @memberof InvestorenmodellZurueckziehenJsonld
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellZurueckziehenJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellZurueckziehenJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellZurueckziehenJsonld
     */
    antrag?: string;
    /**
     *
     * @type {number}
     * @memberof InvestorenmodellZurueckziehenJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellZurueckziehenJsonld
     */
    erlaeuterung: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellZurueckziehenJsonld
     */
    entschiedenBy?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellZurueckziehenJsonld
     */
    entschiedenAt?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof InvestorenmodellZurueckziehenJsonld
     */
    entscheidung?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellZurueckziehenJsonld
     */
    erlaeuterungEntscheidung?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellZurueckziehenJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellZurueckziehenJsonld
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface InvestorenmodellZurueckziehenJsonldZurueckziehenCreate
 */
export interface InvestorenmodellZurueckziehenJsonldZurueckziehenCreate {
    /**
     *
     * @type {string | any}
     * @memberof InvestorenmodellZurueckziehenJsonldZurueckziehenCreate
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellZurueckziehenJsonldZurueckziehenCreate
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellZurueckziehenJsonldZurueckziehenCreate
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellZurueckziehenJsonldZurueckziehenCreate
     */
    antrag?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellZurueckziehenJsonldZurueckziehenCreate
     */
    erlaeuterung: string | null;
}
/**
 *
 * @export
 * @interface InvestorenmodellZurueckziehenZurueckziehenCreate
 */
export interface InvestorenmodellZurueckziehenZurueckziehenCreate {
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellZurueckziehenZurueckziehenCreate
     */
    antrag?: string;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellZurueckziehenZurueckziehenCreate
     */
    erlaeuterung: string | null;
}
/**
 *
 * @export
 * @interface InvestorenmodellZurueckziehenZurueckziehenSetEntscheidung
 */
export interface InvestorenmodellZurueckziehenZurueckziehenSetEntscheidung {
    /**
     *
     * @type {boolean}
     * @memberof InvestorenmodellZurueckziehenZurueckziehenSetEntscheidung
     */
    entscheidung?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof InvestorenmodellZurueckziehenZurueckziehenSetEntscheidung
     */
    erlaeuterungEntscheidung?: string | null;
}
/**
 *
 * @export
 * @interface JsonCustomSchemaAntragJsonForm
 */
export interface JsonCustomSchemaAntragJsonForm {
    /**
     *
     * @type {string}
     * @memberof JsonCustomSchemaAntragJsonForm
     */
    model_path?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonCustomSchemaAntragJsonForm
     */
    property_path?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonCustomSchemaAntragJsonForm
     */
    disabled?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonCustomSchemaAntragJsonForm
     */
    addable?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonCustomSchemaAntragJsonForm
     */
    deleteable?: boolean | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonCustomSchemaAntragJsonForm
     */
    dependent_fields?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof JsonCustomSchemaAntragJsonForm
     */
    formula?: string | null;
    /**
     *
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof JsonCustomSchemaAntragJsonForm
     */
    actions?: { [key: string]: { [key: string]: string } };
    /**
     *
     * @type {Array<string>}
     * @memberof JsonCustomSchemaAntragJsonForm
     */
    block_prefixes?: Array<string>;
    /**
     *
     * @type {number}
     * @memberof JsonCustomSchemaAntragJsonForm
     */
    min?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonCustomSchemaAntragJsonForm
     */
    max?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonCustomSchemaAntragJsonForm
     */
    scale?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonCustomSchemaAntragJsonForm
     */
    tenor_fields?: Array<string>;
    /**
     *
     * @type {any}
     * @memberof JsonCustomSchemaAntragJsonForm
     */
    previous_value?: any;
    /**
     *
     * @type {boolean}
     * @memberof JsonCustomSchemaAntragJsonForm
     */
    compare_previous_value?: boolean;
}
/**
 *
 * @export
 * @interface JsonCustomSchemaJsonldAntragJsonForm
 */
export interface JsonCustomSchemaJsonldAntragJsonForm {
    /**
     *
     * @type {string | any}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    model_path?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    property_path?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    disabled?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    addable?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    deleteable?: boolean | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    dependent_fields?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    formula?: string | null;
    /**
     *
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    actions?: { [key: string]: { [key: string]: string } };
    /**
     *
     * @type {Array<string>}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    block_prefixes?: Array<string>;
    /**
     *
     * @type {number}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    min?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    max?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    scale?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    tenor_fields?: Array<string>;
    /**
     *
     * @type {any}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    previous_value?: any;
    /**
     *
     * @type {boolean}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    compare_previous_value?: boolean;
}
/**
 *
 * @export
 * @interface JsonFormStepAntragJsonForm
 */
export interface JsonFormStepAntragJsonForm {
    /**
     *
     * @type {string}
     * @memberof JsonFormStepAntragJsonForm
     */
    step?: string;
    /**
     *
     * @type {string}
     * @memberof JsonFormStepAntragJsonForm
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof JsonFormStepAntragJsonForm
     */
    headline?: string;
    /**
     *
     * @type {string}
     * @memberof JsonFormStepAntragJsonForm
     */
    state?: string;
    /**
     *
     * @type {Array<JsonFormSubStepAntragJsonForm>}
     * @memberof JsonFormStepAntragJsonForm
     */
    children?: Array<JsonFormSubStepAntragJsonForm>;
}
/**
 *
 * @export
 * @interface JsonFormStepJsonldAntragJsonForm
 */
export interface JsonFormStepJsonldAntragJsonForm {
    /**
     *
     * @type {string | any}
     * @memberof JsonFormStepJsonldAntragJsonForm
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof JsonFormStepJsonldAntragJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof JsonFormStepJsonldAntragJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof JsonFormStepJsonldAntragJsonForm
     */
    step?: string;
    /**
     *
     * @type {string}
     * @memberof JsonFormStepJsonldAntragJsonForm
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof JsonFormStepJsonldAntragJsonForm
     */
    headline?: string;
    /**
     *
     * @type {string}
     * @memberof JsonFormStepJsonldAntragJsonForm
     */
    state?: string;
    /**
     *
     * @type {Array<JsonFormSubStepJsonldAntragJsonForm>}
     * @memberof JsonFormStepJsonldAntragJsonForm
     */
    children?: Array<JsonFormSubStepJsonldAntragJsonForm>;
}
/**
 *
 * @export
 * @interface JsonFormSubStepAntragJsonForm
 */
export interface JsonFormSubStepAntragJsonForm {
    /**
     *
     * @type {string}
     * @memberof JsonFormSubStepAntragJsonForm
     */
    step?: string;
    /**
     *
     * @type {string}
     * @memberof JsonFormSubStepAntragJsonForm
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof JsonFormSubStepAntragJsonForm
     */
    state?: string;
}
/**
 *
 * @export
 * @interface JsonFormSubStepJsonldAntragJsonForm
 */
export interface JsonFormSubStepJsonldAntragJsonForm {
    /**
     *
     * @type {string | any}
     * @memberof JsonFormSubStepJsonldAntragJsonForm
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof JsonFormSubStepJsonldAntragJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof JsonFormSubStepJsonldAntragJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof JsonFormSubStepJsonldAntragJsonForm
     */
    step?: string;
    /**
     *
     * @type {string}
     * @memberof JsonFormSubStepJsonldAntragJsonForm
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof JsonFormSubStepJsonldAntragJsonForm
     */
    state?: string;
}
/**
 *
 * @export
 * @interface JsonSchemaAntragJsonForm
 */
export interface JsonSchemaAntragJsonForm {
    /**
     *
     * @type {string}
     * @memberof JsonSchemaAntragJsonForm
     */
    schema?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaAntragJsonForm
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaAntragJsonForm
     */
    description?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaAntragJsonForm
     */
    default?: Array<string> | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAntragJsonForm
     */
    multipleOf?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAntragJsonForm
     */
    maximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAntragJsonForm
     */
    exclusiveMaximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAntragJsonForm
     */
    minimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAntragJsonForm
     */
    exclusiveMinimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAntragJsonForm
     */
    maxLength?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAntragJsonForm
     */
    minLength?: number | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaAntragJsonForm
     */
    pattern?: string | null;
    /**
     *
     * @type {JsonSchemaAntragJsonForm}
     * @memberof JsonSchemaAntragJsonForm
     */
    additionalItems?: JsonSchemaAntragJsonForm | null;
    /**
     *
     * @type {JsonSchemaAntragJsonForm}
     * @memberof JsonSchemaAntragJsonForm
     */
    items?: JsonSchemaAntragJsonForm | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAntragJsonForm
     */
    maxItems?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAntragJsonForm
     */
    minItems?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonSchemaAntragJsonForm
     */
    uniqueItems?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAntragJsonForm
     */
    maxProperties?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAntragJsonForm
     */
    minProperties?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaAntragJsonForm
     */
    required?: Array<string>;
    /**
     *
     * @type {JsonSchemaAntragJsonForm}
     * @memberof JsonSchemaAntragJsonForm
     */
    additionalProperties?: JsonSchemaAntragJsonForm | null;
    /**
     *
     * @type {Array<JsonSchemaAntragJsonForm>}
     * @memberof JsonSchemaAntragJsonForm
     */
    definitions?: Array<JsonSchemaAntragJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaAntragJsonForm>}
     * @memberof JsonSchemaAntragJsonForm
     */
    properties?: Array<JsonSchemaAntragJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaAntragJsonForm>}
     * @memberof JsonSchemaAntragJsonForm
     */
    patternProperties?: Array<JsonSchemaAntragJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaAntragJsonForm>}
     * @memberof JsonSchemaAntragJsonForm
     */
    dependencies?: Array<JsonSchemaAntragJsonForm>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaAntragJsonForm
     */
    enum?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaAntragJsonForm
     */
    enumNames?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaAntragJsonForm
     */
    type?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaAntragJsonForm
     */
    format?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaAntragJsonForm
     */
    ref?: string | null;
    /**
     *
     * @type {Array<JsonSchemaAntragJsonForm>}
     * @memberof JsonSchemaAntragJsonForm
     */
    allOf?: Array<JsonSchemaAntragJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaAntragJsonForm>}
     * @memberof JsonSchemaAntragJsonForm
     */
    anyOf?: Array<JsonSchemaAntragJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaAntragJsonForm>}
     * @memberof JsonSchemaAntragJsonForm
     */
    oneOf?: Array<JsonSchemaAntragJsonForm>;
    /**
     *
     * @type {JsonSchemaAntragJsonForm}
     * @memberof JsonSchemaAntragJsonForm
     */
    not?: JsonSchemaAntragJsonForm | null;
    /**
     *
     * @type {JsonSchemaAntragJsonForm}
     * @memberof JsonSchemaAntragJsonForm
     */
    contains?: JsonSchemaAntragJsonForm | null;
    /**
     *
     * @type {JsonSchemaAntragJsonForm}
     * @memberof JsonSchemaAntragJsonForm
     */
    propertyNames?: JsonSchemaAntragJsonForm | null;
    /**
     *
     * @type {JsonSchemaAntragJsonForm}
     * @memberof JsonSchemaAntragJsonForm
     */
    if?: JsonSchemaAntragJsonForm | null;
    /**
     *
     * @type {JsonSchemaAntragJsonForm}
     * @memberof JsonSchemaAntragJsonForm
     */
    then?: JsonSchemaAntragJsonForm | null;
    /**
     *
     * @type {JsonSchemaAntragJsonForm}
     * @memberof JsonSchemaAntragJsonForm
     */
    else?: JsonSchemaAntragJsonForm | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaAntragJsonForm
     */
    contentMediaType?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaAntragJsonForm
     */
    contentEncoding?: string | null;
    /**
     *
     * @type {JsonCustomSchemaAntragJsonForm}
     * @memberof JsonSchemaAntragJsonForm
     */
    custom?: JsonCustomSchemaAntragJsonForm | null;
}
/**
 *
 * @export
 * @interface JsonSchemaJsonldAntragJsonForm
 */
export interface JsonSchemaJsonldAntragJsonForm {
    /**
     *
     * @type {string | any}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    schema?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    description?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    default?: Array<string> | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    multipleOf?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    maximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    exclusiveMaximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    minimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    exclusiveMinimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    maxLength?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    minLength?: number | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    pattern?: string | null;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonForm}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    additionalItems?: JsonSchemaJsonldAntragJsonForm | null;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonForm}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    items?: JsonSchemaJsonldAntragJsonForm | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    maxItems?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    minItems?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    uniqueItems?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    maxProperties?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    minProperties?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    required?: Array<string>;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonForm}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    additionalProperties?: JsonSchemaJsonldAntragJsonForm | null;
    /**
     *
     * @type {Array<JsonSchemaJsonldAntragJsonForm>}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    definitions?: Array<JsonSchemaJsonldAntragJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldAntragJsonForm>}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    properties?: Array<JsonSchemaJsonldAntragJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldAntragJsonForm>}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    patternProperties?: Array<JsonSchemaJsonldAntragJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldAntragJsonForm>}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    dependencies?: Array<JsonSchemaJsonldAntragJsonForm>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    enum?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    enumNames?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    type?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    format?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    ref?: string | null;
    /**
     *
     * @type {Array<JsonSchemaJsonldAntragJsonForm>}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    allOf?: Array<JsonSchemaJsonldAntragJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldAntragJsonForm>}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    anyOf?: Array<JsonSchemaJsonldAntragJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldAntragJsonForm>}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    oneOf?: Array<JsonSchemaJsonldAntragJsonForm>;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonForm}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    not?: JsonSchemaJsonldAntragJsonForm | null;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonForm}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    contains?: JsonSchemaJsonldAntragJsonForm | null;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonForm}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    propertyNames?: JsonSchemaJsonldAntragJsonForm | null;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonForm}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    if?: JsonSchemaJsonldAntragJsonForm | null;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonForm}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    then?: JsonSchemaJsonldAntragJsonForm | null;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonForm}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    else?: JsonSchemaJsonldAntragJsonForm | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    contentMediaType?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    contentEncoding?: string | null;
    /**
     *
     * @type {JsonCustomSchemaJsonldAntragJsonForm}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    custom?: JsonCustomSchemaJsonldAntragJsonForm | null;
}
/**
 *
 * @export
 * @interface LandschaftsverbandBenutzerReadBenutzerReadEmailBenutzerReadRoles
 */
export interface LandschaftsverbandBenutzerReadBenutzerReadEmailBenutzerReadRoles {
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    kuerzel: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface LandschaftsverbandEinrichtungRead
 */
export interface LandschaftsverbandEinrichtungRead {
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandEinrichtungRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandEinrichtungRead
     */
    modifiedAt?: string;
}
/**
 *
 * @export
 * @interface LandschaftsverbandJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
 */
export interface LandschaftsverbandJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles {
    /**
     *
     * @type {string | any}
     * @memberof LandschaftsverbandJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    kuerzel: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface LandschaftsverbandJsonldEinrichtungRead
 */
export interface LandschaftsverbandJsonldEinrichtungRead {
    /**
     *
     * @type {string | any}
     * @memberof LandschaftsverbandJsonldEinrichtungRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldEinrichtungRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldEinrichtungRead
     */
    modifiedAt?: string;
}
/**
 *
 * @export
 * @interface LandschaftsverbandJsonldLandschaftsverbandRead
 */
export interface LandschaftsverbandJsonldLandschaftsverbandRead {
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldLandschaftsverbandRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldLandschaftsverbandRead
     */
    '@type'?: string;
    /**
     *
     * @type {string | any}
     * @memberof LandschaftsverbandJsonldLandschaftsverbandRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {number}
     * @memberof LandschaftsverbandJsonldLandschaftsverbandRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldLandschaftsverbandRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldLandschaftsverbandRead
     */
    kuerzel: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldLandschaftsverbandRead
     */
    strasse: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldLandschaftsverbandRead
     */
    hausnummer: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldLandschaftsverbandRead
     */
    plz: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldLandschaftsverbandRead
     */
    ort: string;
    /**
     *
     * @type {Array<string>}
     * @memberof LandschaftsverbandJsonldLandschaftsverbandRead
     */
    benutzer?: Array<string>;
}
/**
 *
 * @export
 * @interface LandschaftsverbandJsonldLandschaftsverbandWrite
 */
export interface LandschaftsverbandJsonldLandschaftsverbandWrite {
    /**
     *
     * @type {string | any}
     * @memberof LandschaftsverbandJsonldLandschaftsverbandWrite
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldLandschaftsverbandWrite
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldLandschaftsverbandWrite
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldLandschaftsverbandWrite
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldLandschaftsverbandWrite
     */
    kuerzel: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldLandschaftsverbandWrite
     */
    strasse: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldLandschaftsverbandWrite
     */
    hausnummer: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldLandschaftsverbandWrite
     */
    plz: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldLandschaftsverbandWrite
     */
    ort: string;
}
/**
 *
 * @export
 * @interface LandschaftsverbandJsonldMassenmailList
 */
export interface LandschaftsverbandJsonldMassenmailList {
    /**
     *
     * @type {string | any}
     * @memberof LandschaftsverbandJsonldMassenmailList
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldMassenmailList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldMassenmailList
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldMassenmailList
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface LandschaftsverbandJsonldMassenmailRead
 */
export interface LandschaftsverbandJsonldMassenmailRead {
    /**
     *
     * @type {string | any}
     * @memberof LandschaftsverbandJsonldMassenmailRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldMassenmailRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldMassenmailRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldMassenmailRead
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface LandschaftsverbandLandschaftsverbandRead
 */
export interface LandschaftsverbandLandschaftsverbandRead {
    /**
     *
     * @type {number}
     * @memberof LandschaftsverbandLandschaftsverbandRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandLandschaftsverbandRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandLandschaftsverbandRead
     */
    kuerzel: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandLandschaftsverbandRead
     */
    strasse: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandLandschaftsverbandRead
     */
    hausnummer: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandLandschaftsverbandRead
     */
    plz: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandLandschaftsverbandRead
     */
    ort: string;
    /**
     *
     * @type {Array<string>}
     * @memberof LandschaftsverbandLandschaftsverbandRead
     */
    benutzer?: Array<string>;
}
/**
 *
 * @export
 * @interface LandschaftsverbandLandschaftsverbandWrite
 */
export interface LandschaftsverbandLandschaftsverbandWrite {
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandLandschaftsverbandWrite
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandLandschaftsverbandWrite
     */
    kuerzel: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandLandschaftsverbandWrite
     */
    strasse: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandLandschaftsverbandWrite
     */
    hausnummer: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandLandschaftsverbandWrite
     */
    plz: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandLandschaftsverbandWrite
     */
    ort: string;
}
/**
 *
 * @export
 * @interface LandschaftsverbandMassenmailList
 */
export interface LandschaftsverbandMassenmailList {
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandMassenmailList
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface LandschaftsverbandMassenmailRead
 */
export interface LandschaftsverbandMassenmailRead {
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandMassenmailRead
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface LoginLink
 */
export interface LoginLink {
    /**
     *
     * @type {string}
     * @memberof LoginLink
     */
    email: string;
}
/**
 *
 * @export
 * @interface LoginLinkJsonld
 */
export interface LoginLinkJsonld {
    /**
     *
     * @type {string | any}
     * @memberof LoginLinkJsonld
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof LoginLinkJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LoginLinkJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof LoginLinkJsonld
     */
    email: string;
}
/**
 *
 * @export
 * @interface MassenmailEmpfaengerJsonldMassenmailRead
 */
export interface MassenmailEmpfaengerJsonldMassenmailRead {
    /**
     *
     * @type {string | any}
     * @memberof MassenmailEmpfaengerJsonldMassenmailRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof MassenmailEmpfaengerJsonldMassenmailRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailEmpfaengerJsonldMassenmailRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof MassenmailEmpfaengerJsonldMassenmailRead
     */
    id?: number;
    /**
     *
     * @type {BenutzerJsonldMassenmailRead}
     * @memberof MassenmailEmpfaengerJsonldMassenmailRead
     */
    benutzer?: BenutzerJsonldMassenmailRead;
    /**
     *
     * @type {string}
     * @memberof MassenmailEmpfaengerJsonldMassenmailRead
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailEmpfaengerJsonldMassenmailRead
     */
    gesendetAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof MassenmailEmpfaengerJsonldMassenmailRead
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface MassenmailEmpfaengerMassenmailRead
 */
export interface MassenmailEmpfaengerMassenmailRead {
    /**
     *
     * @type {number}
     * @memberof MassenmailEmpfaengerMassenmailRead
     */
    id?: number;
    /**
     *
     * @type {BenutzerMassenmailRead}
     * @memberof MassenmailEmpfaengerMassenmailRead
     */
    benutzer?: BenutzerMassenmailRead;
    /**
     *
     * @type {string}
     * @memberof MassenmailEmpfaengerMassenmailRead
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailEmpfaengerMassenmailRead
     */
    gesendetAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof MassenmailEmpfaengerMassenmailRead
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface MassenmailJsonldMassenmailList
 */
export interface MassenmailJsonldMassenmailList {
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof MassenmailJsonldMassenmailList
     */
    id?: number;
    /**
     *
     * @type {LandschaftsverbandJsonldMassenmailList}
     * @memberof MassenmailJsonldMassenmailList
     */
    landschaftsverband?: LandschaftsverbandJsonldMassenmailList | null;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailList
     */
    empfaengerGruppe?: string | null;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailList
     */
    betreff: string;
    /**
     *
     * @type {Array<string>}
     * @memberof MassenmailJsonldMassenmailList
     */
    einrichtungsart?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MassenmailJsonldMassenmailList
     */
    einrichtungstyp?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MassenmailJsonldMassenmailList
     */
    pflegewohngeld?: Array<string> | null;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailList
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerJsonldMassenmailList}
     * @memberof MassenmailJsonldMassenmailList
     */
    createdBy?: BenutzerJsonldMassenmailList | null;
    /**
     *
     * @type {number}
     * @memberof MassenmailJsonldMassenmailList
     */
    countEmpfaenger?: number;
    /**
     *
     * @type {number}
     * @memberof MassenmailJsonldMassenmailList
     */
    countEmpfaengerGesendet?: number;
}
/**
 *
 * @export
 * @interface MassenmailJsonldMassenmailRead
 */
export interface MassenmailJsonldMassenmailRead {
    /**
     *
     * @type {string | any}
     * @memberof MassenmailJsonldMassenmailRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof MassenmailJsonldMassenmailRead
     */
    id?: number;
    /**
     *
     * @type {LandschaftsverbandJsonldMassenmailRead}
     * @memberof MassenmailJsonldMassenmailRead
     */
    landschaftsverband?: LandschaftsverbandJsonldMassenmailRead | null;
    /**
     *
     * @type {Array<MassenmailEmpfaengerJsonldMassenmailRead>}
     * @memberof MassenmailJsonldMassenmailRead
     */
    empfaenger?: Array<MassenmailEmpfaengerJsonldMassenmailRead>;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailRead
     */
    empfaengerGruppe?: string | null;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailRead
     */
    betreff: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailRead
     */
    inhalt: string;
    /**
     *
     * @type {Array<string>}
     * @memberof MassenmailJsonldMassenmailRead
     */
    einrichtungsart?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MassenmailJsonldMassenmailRead
     */
    einrichtungstyp?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MassenmailJsonldMassenmailRead
     */
    pflegewohngeld?: Array<string> | null;
    /**
     *
     * @type {Array<UploadJsonldMassenmailRead>}
     * @memberof MassenmailJsonldMassenmailRead
     */
    uploads?: Array<UploadJsonldMassenmailRead>;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailRead
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerJsonldMassenmailRead}
     * @memberof MassenmailJsonldMassenmailRead
     */
    createdBy?: BenutzerJsonldMassenmailRead | null;
    /**
     *
     * @type {number}
     * @memberof MassenmailJsonldMassenmailRead
     */
    countEmpfaenger?: number;
    /**
     *
     * @type {number}
     * @memberof MassenmailJsonldMassenmailRead
     */
    countEmpfaengerGesendet?: number;
}
/**
 *
 * @export
 * @interface MassenmailJsonldMassenmailWrite
 */
export interface MassenmailJsonldMassenmailWrite {
    /**
     *
     * @type {string | any}
     * @memberof MassenmailJsonldMassenmailWrite
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailWrite
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailWrite
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailWrite
     */
    empfaengerGruppe?: string | null;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailWrite
     */
    betreff: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailWrite
     */
    inhalt: string;
    /**
     *
     * @type {Array<string>}
     * @memberof MassenmailJsonldMassenmailWrite
     */
    einrichtungsart?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MassenmailJsonldMassenmailWrite
     */
    einrichtungstyp?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MassenmailJsonldMassenmailWrite
     */
    pflegewohngeld?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MassenmailJsonldMassenmailWrite
     */
    uploads?: Array<string>;
}
/**
 *
 * @export
 * @interface MassenmailMassenmailList
 */
export interface MassenmailMassenmailList {
    /**
     *
     * @type {number}
     * @memberof MassenmailMassenmailList
     */
    id?: number;
    /**
     *
     * @type {LandschaftsverbandMassenmailList}
     * @memberof MassenmailMassenmailList
     */
    landschaftsverband?: LandschaftsverbandMassenmailList | null;
    /**
     *
     * @type {string}
     * @memberof MassenmailMassenmailList
     */
    empfaengerGruppe?: string | null;
    /**
     *
     * @type {string}
     * @memberof MassenmailMassenmailList
     */
    betreff: string;
    /**
     *
     * @type {Array<string>}
     * @memberof MassenmailMassenmailList
     */
    einrichtungsart?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MassenmailMassenmailList
     */
    einrichtungstyp?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MassenmailMassenmailList
     */
    pflegewohngeld?: Array<string> | null;
    /**
     *
     * @type {string}
     * @memberof MassenmailMassenmailList
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerMassenmailList}
     * @memberof MassenmailMassenmailList
     */
    createdBy?: BenutzerMassenmailList | null;
    /**
     *
     * @type {number}
     * @memberof MassenmailMassenmailList
     */
    countEmpfaenger?: number;
    /**
     *
     * @type {number}
     * @memberof MassenmailMassenmailList
     */
    countEmpfaengerGesendet?: number;
}
/**
 *
 * @export
 * @interface MassenmailMassenmailRead
 */
export interface MassenmailMassenmailRead {
    /**
     *
     * @type {number}
     * @memberof MassenmailMassenmailRead
     */
    id?: number;
    /**
     *
     * @type {LandschaftsverbandMassenmailRead}
     * @memberof MassenmailMassenmailRead
     */
    landschaftsverband?: LandschaftsverbandMassenmailRead | null;
    /**
     *
     * @type {Array<MassenmailEmpfaengerMassenmailRead>}
     * @memberof MassenmailMassenmailRead
     */
    empfaenger?: Array<MassenmailEmpfaengerMassenmailRead>;
    /**
     *
     * @type {string}
     * @memberof MassenmailMassenmailRead
     */
    empfaengerGruppe?: string | null;
    /**
     *
     * @type {string}
     * @memberof MassenmailMassenmailRead
     */
    betreff: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailMassenmailRead
     */
    inhalt: string;
    /**
     *
     * @type {Array<string>}
     * @memberof MassenmailMassenmailRead
     */
    einrichtungsart?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MassenmailMassenmailRead
     */
    einrichtungstyp?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MassenmailMassenmailRead
     */
    pflegewohngeld?: Array<string> | null;
    /**
     *
     * @type {Array<UploadMassenmailRead>}
     * @memberof MassenmailMassenmailRead
     */
    uploads?: Array<UploadMassenmailRead>;
    /**
     *
     * @type {string}
     * @memberof MassenmailMassenmailRead
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerMassenmailRead}
     * @memberof MassenmailMassenmailRead
     */
    createdBy?: BenutzerMassenmailRead | null;
    /**
     *
     * @type {number}
     * @memberof MassenmailMassenmailRead
     */
    countEmpfaenger?: number;
    /**
     *
     * @type {number}
     * @memberof MassenmailMassenmailRead
     */
    countEmpfaengerGesendet?: number;
}
/**
 *
 * @export
 * @interface MassenmailMassenmailWrite
 */
export interface MassenmailMassenmailWrite {
    /**
     *
     * @type {string}
     * @memberof MassenmailMassenmailWrite
     */
    empfaengerGruppe?: string | null;
    /**
     *
     * @type {string}
     * @memberof MassenmailMassenmailWrite
     */
    betreff: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailMassenmailWrite
     */
    inhalt: string;
    /**
     *
     * @type {Array<string>}
     * @memberof MassenmailMassenmailWrite
     */
    einrichtungsart?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MassenmailMassenmailWrite
     */
    einrichtungstyp?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MassenmailMassenmailWrite
     */
    pflegewohngeld?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MassenmailMassenmailWrite
     */
    uploads?: Array<string>;
}
/**
 *
 * @export
 * @interface MassnahmeEinrichtungRead
 */
export interface MassnahmeEinrichtungRead {
    /**
     *
     * @type {string}
     * @memberof MassnahmeEinrichtungRead
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof MassnahmeEinrichtungRead
     */
    field201?: string | null;
}
/**
 *
 * @export
 * @interface MassnahmeFeststellungsantragRead
 */
export interface MassnahmeFeststellungsantragRead {
    /**
     *
     * @type {string}
     * @memberof MassnahmeFeststellungsantragRead
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof MassnahmeFeststellungsantragRead
     */
    field201?: string | null;
    /**
     *
     * @type {DokumentFeststellungsantragRead}
     * @memberof MassnahmeFeststellungsantragRead
     */
    field220d?: DokumentFeststellungsantragRead | null;
    /**
     *
     * @type {DokumentFeststellungsantragRead}
     * @memberof MassnahmeFeststellungsantragRead
     */
    field232d?: DokumentFeststellungsantragRead | null;
    /**
     *
     * @type {Array<ZuschussFeststellungsantragRead>}
     * @memberof MassnahmeFeststellungsantragRead
     */
    zuschuesse?: Array<ZuschussFeststellungsantragRead> | null;
}
/**
 *
 * @export
 * @interface MassnahmeFeststellungsantragVersionRead
 */
export interface MassnahmeFeststellungsantragVersionRead {
    /**
     *
     * @type {string}
     * @memberof MassnahmeFeststellungsantragVersionRead
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof MassnahmeFeststellungsantragVersionRead
     */
    field201?: string | null;
    /**
     *
     * @type {DokumentFeststellungsantragVersionRead}
     * @memberof MassnahmeFeststellungsantragVersionRead
     */
    field220d?: DokumentFeststellungsantragVersionRead | null;
    /**
     *
     * @type {DokumentFeststellungsantragVersionRead}
     * @memberof MassnahmeFeststellungsantragVersionRead
     */
    field232d?: DokumentFeststellungsantragVersionRead | null;
    /**
     *
     * @type {Array<ZuschussFeststellungsantragVersionRead>}
     * @memberof MassnahmeFeststellungsantragVersionRead
     */
    zuschuesse?: Array<ZuschussFeststellungsantragVersionRead> | null;
}
/**
 *
 * @export
 * @interface MassnahmeJsonldEinrichtungRead
 */
export interface MassnahmeJsonldEinrichtungRead {
    /**
     *
     * @type {string | any}
     * @memberof MassnahmeJsonldEinrichtungRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof MassnahmeJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MassnahmeJsonldEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof MassnahmeJsonldEinrichtungRead
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof MassnahmeJsonldEinrichtungRead
     */
    field201?: string | null;
}
/**
 *
 * @export
 * @interface MassnahmeJsonldFeststellungsantragRead
 */
export interface MassnahmeJsonldFeststellungsantragRead {
    /**
     *
     * @type {string | any}
     * @memberof MassnahmeJsonldFeststellungsantragRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof MassnahmeJsonldFeststellungsantragRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MassnahmeJsonldFeststellungsantragRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof MassnahmeJsonldFeststellungsantragRead
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof MassnahmeJsonldFeststellungsantragRead
     */
    field201?: string | null;
    /**
     *
     * @type {DokumentJsonldFeststellungsantragRead}
     * @memberof MassnahmeJsonldFeststellungsantragRead
     */
    field220d?: DokumentJsonldFeststellungsantragRead | null;
    /**
     *
     * @type {DokumentJsonldFeststellungsantragRead}
     * @memberof MassnahmeJsonldFeststellungsantragRead
     */
    field232d?: DokumentJsonldFeststellungsantragRead | null;
    /**
     *
     * @type {Array<ZuschussJsonldFeststellungsantragRead>}
     * @memberof MassnahmeJsonldFeststellungsantragRead
     */
    zuschuesse?: Array<ZuschussJsonldFeststellungsantragRead> | null;
}
/**
 *
 * @export
 * @interface MassnahmeJsonldFeststellungsantragVersionRead
 */
export interface MassnahmeJsonldFeststellungsantragVersionRead {
    /**
     *
     * @type {string | any}
     * @memberof MassnahmeJsonldFeststellungsantragVersionRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof MassnahmeJsonldFeststellungsantragVersionRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MassnahmeJsonldFeststellungsantragVersionRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof MassnahmeJsonldFeststellungsantragVersionRead
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof MassnahmeJsonldFeststellungsantragVersionRead
     */
    field201?: string | null;
    /**
     *
     * @type {DokumentJsonldFeststellungsantragVersionRead}
     * @memberof MassnahmeJsonldFeststellungsantragVersionRead
     */
    field220d?: DokumentJsonldFeststellungsantragVersionRead | null;
    /**
     *
     * @type {DokumentJsonldFeststellungsantragVersionRead}
     * @memberof MassnahmeJsonldFeststellungsantragVersionRead
     */
    field232d?: DokumentJsonldFeststellungsantragVersionRead | null;
    /**
     *
     * @type {Array<ZuschussJsonldFeststellungsantragVersionRead>}
     * @memberof MassnahmeJsonldFeststellungsantragVersionRead
     */
    zuschuesse?: Array<ZuschussJsonldFeststellungsantragVersionRead> | null;
}
/**
 *
 * @export
 * @interface MeldungP29CollectionFiltersOutputCollectionFilters
 */
export interface MeldungP29CollectionFiltersOutputCollectionFilters {
    /**
     *
     * @type {Array<CollectionFilterCollectionFilters>}
     * @memberof MeldungP29CollectionFiltersOutputCollectionFilters
     */
    filters?: Array<CollectionFilterCollectionFilters>;
}
/**
 *
 * @export
 * @interface MeldungP29CollectionFiltersOutputJsonldCollectionFilters
 */
export interface MeldungP29CollectionFiltersOutputJsonldCollectionFilters {
    /**
     *
     * @type {string | any}
     * @memberof MeldungP29CollectionFiltersOutputJsonldCollectionFilters
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof MeldungP29CollectionFiltersOutputJsonldCollectionFilters
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungP29CollectionFiltersOutputJsonldCollectionFilters
     */
    '@type'?: string;
    /**
     *
     * @type {Array<CollectionFilterJsonldCollectionFilters>}
     * @memberof MeldungP29CollectionFiltersOutputJsonldCollectionFilters
     */
    filters?: Array<CollectionFilterJsonldCollectionFilters>;
}
/**
 *
 * @export
 * @interface MeldungP29DataJsonldMeldungP29List
 */
export interface MeldungP29DataJsonldMeldungP29List {
    /**
     *
     * @type {string | any}
     * @memberof MeldungP29DataJsonldMeldungP29List
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof MeldungP29DataJsonldMeldungP29List
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungP29DataJsonldMeldungP29List
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungP29DataJsonldMeldungP29List
     */
    fieldM2901?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungP29DataJsonldMeldungP29List
     */
    fieldM2902?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungP29DataJsonldMeldungP29List
     */
    fieldM2903?: string | null;
}
/**
 *
 * @export
 * @interface MeldungP29DataJsonldMeldungP29Read
 */
export interface MeldungP29DataJsonldMeldungP29Read {
    /**
     *
     * @type {string | any}
     * @memberof MeldungP29DataJsonldMeldungP29Read
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof MeldungP29DataJsonldMeldungP29Read
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungP29DataJsonldMeldungP29Read
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface MeldungP29DataMeldungP29List
 */
export interface MeldungP29DataMeldungP29List {
    /**
     *
     * @type {string}
     * @memberof MeldungP29DataMeldungP29List
     */
    fieldM2901?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungP29DataMeldungP29List
     */
    fieldM2902?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungP29DataMeldungP29List
     */
    fieldM2903?: string | null;
}
/**
 *
 * @export
 * @interface MeldungP29EmailListEmailReadEmail
 */
export interface MeldungP29EmailListEmailReadEmail {
    /**
     *
     * @type {string}
     * @memberof MeldungP29EmailListEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface MeldungP29EmailReadEmailReadEmail
 */
export interface MeldungP29EmailReadEmailReadEmail {
    /**
     *
     * @type {string}
     * @memberof MeldungP29EmailReadEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface MeldungP29JsonldEmailListEmailReadEmail
 */
export interface MeldungP29JsonldEmailListEmailReadEmail {
    /**
     *
     * @type {string | any}
     * @memberof MeldungP29JsonldEmailListEmailReadEmail
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof MeldungP29JsonldEmailListEmailReadEmail
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungP29JsonldEmailListEmailReadEmail
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungP29JsonldEmailListEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface MeldungP29JsonldEmailReadEmailReadEmail
 */
export interface MeldungP29JsonldEmailReadEmailReadEmail {
    /**
     *
     * @type {string | any}
     * @memberof MeldungP29JsonldEmailReadEmailReadEmail
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof MeldungP29JsonldEmailReadEmailReadEmail
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungP29JsonldEmailReadEmailReadEmail
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungP29JsonldEmailReadEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface MeldungP29JsonldMeldungP29Create
 */
export interface MeldungP29JsonldMeldungP29Create {
    /**
     *
     * @type {string | any}
     * @memberof MeldungP29JsonldMeldungP29Create
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof MeldungP29JsonldMeldungP29Create
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungP29JsonldMeldungP29Create
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungP29JsonldMeldungP29Create
     */
    einrichtung?: string;
}
/**
 *
 * @export
 * @interface MeldungP29JsonldMeldungP29List
 */
export interface MeldungP29JsonldMeldungP29List {
    /**
     *
     * @type {string}
     * @memberof MeldungP29JsonldMeldungP29List
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungP29JsonldMeldungP29List
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof MeldungP29JsonldMeldungP29List
     */
    id?: number;
    /**
     *
     * @type {MeldungP29DataJsonldMeldungP29List}
     * @memberof MeldungP29JsonldMeldungP29List
     */
    data?: MeldungP29DataJsonldMeldungP29List | null;
    /**
     *
     * @type {EinrichtungJsonldMeldungP29List}
     * @memberof MeldungP29JsonldMeldungP29List
     */
    einrichtung?: EinrichtungJsonldMeldungP29List;
    /**
     *
     * @type {string}
     * @memberof MeldungP29JsonldMeldungP29List
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungP29JsonldMeldungP29List
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungP29JsonldMeldungP29List
     */
    allowedWorkflowActions?: Array<string>;
}
/**
 *
 * @export
 * @interface MeldungP29JsonldMeldungP29Read
 */
export interface MeldungP29JsonldMeldungP29Read {
    /**
     *
     * @type {string | any}
     * @memberof MeldungP29JsonldMeldungP29Read
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof MeldungP29JsonldMeldungP29Read
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungP29JsonldMeldungP29Read
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof MeldungP29JsonldMeldungP29Read
     */
    id?: number;
    /**
     *
     * @type {MeldungP29DataJsonldMeldungP29Read}
     * @memberof MeldungP29JsonldMeldungP29Read
     */
    data?: MeldungP29DataJsonldMeldungP29Read | null;
    /**
     *
     * @type {EinrichtungJsonldMeldungP29Read}
     * @memberof MeldungP29JsonldMeldungP29Read
     */
    einrichtung?: EinrichtungJsonldMeldungP29Read;
    /**
     *
     * @type {string}
     * @memberof MeldungP29JsonldMeldungP29Read
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungP29JsonldMeldungP29Read
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {BenutzerJsonldMeldungP29Read}
     * @memberof MeldungP29JsonldMeldungP29Read
     */
    eingereichtBy?: BenutzerJsonldMeldungP29Read | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungP29JsonldMeldungP29Read
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof MeldungP29JsonldMeldungP29Read
     */
    uuidMap?: { [key: string]: string };
}
/**
 *
 * @export
 * @interface MeldungP29MeldungP29Create
 */
export interface MeldungP29MeldungP29Create {
    /**
     *
     * @type {string}
     * @memberof MeldungP29MeldungP29Create
     */
    einrichtung?: string;
}
/**
 *
 * @export
 * @interface MeldungP29MeldungP29JsonAntragJsonForm
 */
export interface MeldungP29MeldungP29JsonAntragJsonForm {
    /**
     *
     * @type {object}
     * @memberof MeldungP29MeldungP29JsonAntragJsonForm
     */
    data?: object;
    /**
     *
     * @type {JsonSchemaAntragJsonForm}
     * @memberof MeldungP29MeldungP29JsonAntragJsonForm
     */
    schema?: JsonSchemaAntragJsonForm;
    /**
     *
     * @type {{ [key: string]: Array<string>; }}
     * @memberof MeldungP29MeldungP29JsonAntragJsonForm
     */
    errors?: { [key: string]: Array<string> };
    /**
     *
     * @type {Array<JsonFormStepAntragJsonForm>}
     * @memberof MeldungP29MeldungP29JsonAntragJsonForm
     */
    steps?: Array<JsonFormStepAntragJsonForm>;
    /**
     *
     * @type {string}
     * @memberof MeldungP29MeldungP29JsonAntragJsonForm
     */
    step?: string;
    /**
     *
     * @type {number}
     * @memberof MeldungP29MeldungP29JsonAntragJsonForm
     */
    id?: number;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof MeldungP29MeldungP29JsonAntragJsonForm
     */
    uuidMap?: { [key: string]: string };
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungP29MeldungP29JsonAntragJsonForm
     */
    allowedWorkflowActions?: Array<string>;
}
/**
 *
 * @export
 * @interface MeldungP29MeldungP29JsonJsonldAntragJsonForm
 */
export interface MeldungP29MeldungP29JsonJsonldAntragJsonForm {
    /**
     *
     * @type {string | any}
     * @memberof MeldungP29MeldungP29JsonJsonldAntragJsonForm
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof MeldungP29MeldungP29JsonJsonldAntragJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungP29MeldungP29JsonJsonldAntragJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {object}
     * @memberof MeldungP29MeldungP29JsonJsonldAntragJsonForm
     */
    data?: object;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonForm}
     * @memberof MeldungP29MeldungP29JsonJsonldAntragJsonForm
     */
    schema?: JsonSchemaJsonldAntragJsonForm;
    /**
     *
     * @type {{ [key: string]: Array<string>; }}
     * @memberof MeldungP29MeldungP29JsonJsonldAntragJsonForm
     */
    errors?: { [key: string]: Array<string> };
    /**
     *
     * @type {Array<JsonFormStepJsonldAntragJsonForm>}
     * @memberof MeldungP29MeldungP29JsonJsonldAntragJsonForm
     */
    steps?: Array<JsonFormStepJsonldAntragJsonForm>;
    /**
     *
     * @type {string}
     * @memberof MeldungP29MeldungP29JsonJsonldAntragJsonForm
     */
    step?: string;
    /**
     *
     * @type {number}
     * @memberof MeldungP29MeldungP29JsonJsonldAntragJsonForm
     */
    id?: number;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof MeldungP29MeldungP29JsonJsonldAntragJsonForm
     */
    uuidMap?: { [key: string]: string };
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungP29MeldungP29JsonJsonldAntragJsonForm
     */
    allowedWorkflowActions?: Array<string>;
}
/**
 *
 * @export
 * @interface MeldungP29MeldungP29List
 */
export interface MeldungP29MeldungP29List {
    /**
     *
     * @type {number}
     * @memberof MeldungP29MeldungP29List
     */
    id?: number;
    /**
     *
     * @type {MeldungP29DataMeldungP29List}
     * @memberof MeldungP29MeldungP29List
     */
    data?: MeldungP29DataMeldungP29List | null;
    /**
     *
     * @type {EinrichtungMeldungP29List}
     * @memberof MeldungP29MeldungP29List
     */
    einrichtung?: EinrichtungMeldungP29List;
    /**
     *
     * @type {string}
     * @memberof MeldungP29MeldungP29List
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungP29MeldungP29List
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungP29MeldungP29List
     */
    allowedWorkflowActions?: Array<string>;
}
/**
 *
 * @export
 * @interface MeldungP29MeldungP29Read
 */
export interface MeldungP29MeldungP29Read {
    /**
     *
     * @type {number}
     * @memberof MeldungP29MeldungP29Read
     */
    id?: number;
    /**
     *
     * @type {object}
     * @memberof MeldungP29MeldungP29Read
     */
    data?: object | null;
    /**
     *
     * @type {EinrichtungMeldungP29Read}
     * @memberof MeldungP29MeldungP29Read
     */
    einrichtung?: EinrichtungMeldungP29Read;
    /**
     *
     * @type {string}
     * @memberof MeldungP29MeldungP29Read
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungP29MeldungP29Read
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {BenutzerMeldungP29Read}
     * @memberof MeldungP29MeldungP29Read
     */
    eingereichtBy?: BenutzerMeldungP29Read | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungP29MeldungP29Read
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof MeldungP29MeldungP29Read
     */
    uuidMap?: { [key: string]: string };
}
/**
 *
 * @export
 * @interface Registrierung
 */
export interface Registrierung {
    /**
     *
     * @type {string}
     * @memberof Registrierung
     */
    einrichtungUuid: string;
    /**
     *
     * @type {string}
     * @memberof Registrierung
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof Registrierung
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof Registrierung
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof Registrierung
     */
    rolle: string;
}
/**
 *
 * @export
 * @interface RegistrierungJsonld
 */
export interface RegistrierungJsonld {
    /**
     *
     * @type {string | any}
     * @memberof RegistrierungJsonld
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof RegistrierungJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof RegistrierungJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof RegistrierungJsonld
     */
    einrichtungUuid: string;
    /**
     *
     * @type {string}
     * @memberof RegistrierungJsonld
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof RegistrierungJsonld
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof RegistrierungJsonld
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof RegistrierungJsonld
     */
    rolle: string;
}
/**
 *
 * @export
 * @interface Ruhendstellung
 */
export interface Ruhendstellung {
    /**
     *
     * @type {number}
     * @memberof Ruhendstellung
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof Ruhendstellung
     */
    einrichtung?: string;
    /**
     *
     * @type {string}
     * @memberof Ruhendstellung
     */
    reaktiviertAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof Ruhendstellung
     */
    reaktiviertBy?: string | null;
    /**
     *
     * @type {string}
     * @memberof Ruhendstellung
     */
    geschlossenAb?: string | null;
    /**
     *
     * @type {string}
     * @memberof Ruhendstellung
     */
    grund?: string;
    /**
     *
     * @type {string}
     * @memberof Ruhendstellung
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof Ruhendstellung
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface RuhendstellungEinrichtungRead
 */
export interface RuhendstellungEinrichtungRead {
    /**
     *
     * @type {number}
     * @memberof RuhendstellungEinrichtungRead
     */
    id?: number;
    /**
     *
     * @type {EinrichtungEinrichtungRead}
     * @memberof RuhendstellungEinrichtungRead
     */
    einrichtung?: EinrichtungEinrichtungRead;
    /**
     *
     * @type {string}
     * @memberof RuhendstellungEinrichtungRead
     */
    reaktiviertAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof RuhendstellungEinrichtungRead
     */
    geschlossenAb?: string | null;
    /**
     *
     * @type {string}
     * @memberof RuhendstellungEinrichtungRead
     */
    grund?: string;
    /**
     *
     * @type {string}
     * @memberof RuhendstellungEinrichtungRead
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface RuhendstellungJsonld
 */
export interface RuhendstellungJsonld {
    /**
     *
     * @type {string | any}
     * @memberof RuhendstellungJsonld
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof RuhendstellungJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof RuhendstellungJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof RuhendstellungJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof RuhendstellungJsonld
     */
    einrichtung?: string;
    /**
     *
     * @type {string}
     * @memberof RuhendstellungJsonld
     */
    reaktiviertAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof RuhendstellungJsonld
     */
    reaktiviertBy?: string | null;
    /**
     *
     * @type {string}
     * @memberof RuhendstellungJsonld
     */
    geschlossenAb?: string | null;
    /**
     *
     * @type {string}
     * @memberof RuhendstellungJsonld
     */
    grund?: string;
    /**
     *
     * @type {string}
     * @memberof RuhendstellungJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof RuhendstellungJsonld
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface RuhendstellungJsonldEinrichtungRead
 */
export interface RuhendstellungJsonldEinrichtungRead {
    /**
     *
     * @type {string | any}
     * @memberof RuhendstellungJsonldEinrichtungRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof RuhendstellungJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof RuhendstellungJsonldEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof RuhendstellungJsonldEinrichtungRead
     */
    id?: number;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungRead}
     * @memberof RuhendstellungJsonldEinrichtungRead
     */
    einrichtung?: EinrichtungJsonldEinrichtungRead;
    /**
     *
     * @type {string}
     * @memberof RuhendstellungJsonldEinrichtungRead
     */
    reaktiviertAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof RuhendstellungJsonldEinrichtungRead
     */
    geschlossenAb?: string | null;
    /**
     *
     * @type {string}
     * @memberof RuhendstellungJsonldEinrichtungRead
     */
    grund?: string;
    /**
     *
     * @type {string}
     * @memberof RuhendstellungJsonldEinrichtungRead
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface RuhendstellungJsonldRuhendstellungCreate
 */
export interface RuhendstellungJsonldRuhendstellungCreate {
    /**
     *
     * @type {string | any}
     * @memberof RuhendstellungJsonldRuhendstellungCreate
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof RuhendstellungJsonldRuhendstellungCreate
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof RuhendstellungJsonldRuhendstellungCreate
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof RuhendstellungJsonldRuhendstellungCreate
     */
    einrichtung?: string;
    /**
     *
     * @type {string}
     * @memberof RuhendstellungJsonldRuhendstellungCreate
     */
    geschlossenAb?: string | null;
    /**
     *
     * @type {string}
     * @memberof RuhendstellungJsonldRuhendstellungCreate
     */
    grund?: string;
}
/**
 *
 * @export
 * @interface RuhendstellungRuhendstellungCreate
 */
export interface RuhendstellungRuhendstellungCreate {
    /**
     *
     * @type {string}
     * @memberof RuhendstellungRuhendstellungCreate
     */
    einrichtung?: string;
    /**
     *
     * @type {string}
     * @memberof RuhendstellungRuhendstellungCreate
     */
    geschlossenAb?: string | null;
    /**
     *
     * @type {string}
     * @memberof RuhendstellungRuhendstellungCreate
     */
    grund?: string;
}
/**
 *
 * @export
 * @interface SalgImEigentumFeststellungsantragRead
 */
export interface SalgImEigentumFeststellungsantragRead {
    /**
     *
     * @type {string}
     * @memberof SalgImEigentumFeststellungsantragRead
     */
    field300?: string | null;
    /**
     *
     * @type {DokumentFeststellungsantragRead}
     * @memberof SalgImEigentumFeststellungsantragRead
     */
    field302d?: DokumentFeststellungsantragRead | null;
    /**
     *
     * @type {Array<ZuschussFeststellungsantragRead>}
     * @memberof SalgImEigentumFeststellungsantragRead
     */
    zuschuesse?: Array<ZuschussFeststellungsantragRead> | null;
}
/**
 *
 * @export
 * @interface SalgImEigentumFeststellungsantragVersionRead
 */
export interface SalgImEigentumFeststellungsantragVersionRead {
    /**
     *
     * @type {string}
     * @memberof SalgImEigentumFeststellungsantragVersionRead
     */
    field300?: string | null;
    /**
     *
     * @type {DokumentFeststellungsantragVersionRead}
     * @memberof SalgImEigentumFeststellungsantragVersionRead
     */
    field302d?: DokumentFeststellungsantragVersionRead | null;
    /**
     *
     * @type {Array<ZuschussFeststellungsantragVersionRead>}
     * @memberof SalgImEigentumFeststellungsantragVersionRead
     */
    zuschuesse?: Array<ZuschussFeststellungsantragVersionRead> | null;
}
/**
 *
 * @export
 * @interface SalgImEigentumJsonldFeststellungsantragRead
 */
export interface SalgImEigentumJsonldFeststellungsantragRead {
    /**
     *
     * @type {string | any}
     * @memberof SalgImEigentumJsonldFeststellungsantragRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof SalgImEigentumJsonldFeststellungsantragRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof SalgImEigentumJsonldFeststellungsantragRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof SalgImEigentumJsonldFeststellungsantragRead
     */
    field300?: string | null;
    /**
     *
     * @type {DokumentJsonldFeststellungsantragRead}
     * @memberof SalgImEigentumJsonldFeststellungsantragRead
     */
    field302d?: DokumentJsonldFeststellungsantragRead | null;
    /**
     *
     * @type {Array<ZuschussJsonldFeststellungsantragRead>}
     * @memberof SalgImEigentumJsonldFeststellungsantragRead
     */
    zuschuesse?: Array<ZuschussJsonldFeststellungsantragRead> | null;
}
/**
 *
 * @export
 * @interface SalgImEigentumJsonldFeststellungsantragVersionRead
 */
export interface SalgImEigentumJsonldFeststellungsantragVersionRead {
    /**
     *
     * @type {string | any}
     * @memberof SalgImEigentumJsonldFeststellungsantragVersionRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof SalgImEigentumJsonldFeststellungsantragVersionRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof SalgImEigentumJsonldFeststellungsantragVersionRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof SalgImEigentumJsonldFeststellungsantragVersionRead
     */
    field300?: string | null;
    /**
     *
     * @type {DokumentJsonldFeststellungsantragVersionRead}
     * @memberof SalgImEigentumJsonldFeststellungsantragVersionRead
     */
    field302d?: DokumentJsonldFeststellungsantragVersionRead | null;
    /**
     *
     * @type {Array<ZuschussJsonldFeststellungsantragVersionRead>}
     * @memberof SalgImEigentumJsonldFeststellungsantragVersionRead
     */
    zuschuesse?: Array<ZuschussJsonldFeststellungsantragVersionRead> | null;
}
/**
 *
 * @export
 * @interface SalgImLeasingFeststellungsantragRead
 */
export interface SalgImLeasingFeststellungsantragRead {
    /**
     * UUID der Maßnahme.
     * @type {string}
     * @memberof SalgImLeasingFeststellungsantragRead
     */
    field300?: string | null;
    /**
     *
     * @type {DokumentFeststellungsantragRead}
     * @memberof SalgImLeasingFeststellungsantragRead
     */
    field304d?: DokumentFeststellungsantragRead | null;
    /**
     *
     * @type {DokumentFeststellungsantragRead}
     * @memberof SalgImLeasingFeststellungsantragRead
     */
    field303d?: DokumentFeststellungsantragRead | null;
}
/**
 *
 * @export
 * @interface SalgImLeasingFeststellungsantragVersionRead
 */
export interface SalgImLeasingFeststellungsantragVersionRead {
    /**
     * UUID der Maßnahme.
     * @type {string}
     * @memberof SalgImLeasingFeststellungsantragVersionRead
     */
    field300?: string | null;
    /**
     *
     * @type {DokumentFeststellungsantragVersionRead}
     * @memberof SalgImLeasingFeststellungsantragVersionRead
     */
    field304d?: DokumentFeststellungsantragVersionRead | null;
    /**
     *
     * @type {DokumentFeststellungsantragVersionRead}
     * @memberof SalgImLeasingFeststellungsantragVersionRead
     */
    field303d?: DokumentFeststellungsantragVersionRead | null;
}
/**
 *
 * @export
 * @interface SalgImLeasingJsonldFeststellungsantragRead
 */
export interface SalgImLeasingJsonldFeststellungsantragRead {
    /**
     *
     * @type {string | any}
     * @memberof SalgImLeasingJsonldFeststellungsantragRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof SalgImLeasingJsonldFeststellungsantragRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof SalgImLeasingJsonldFeststellungsantragRead
     */
    '@type'?: string;
    /**
     * UUID der Maßnahme.
     * @type {string}
     * @memberof SalgImLeasingJsonldFeststellungsantragRead
     */
    field300?: string | null;
    /**
     *
     * @type {DokumentJsonldFeststellungsantragRead}
     * @memberof SalgImLeasingJsonldFeststellungsantragRead
     */
    field304d?: DokumentJsonldFeststellungsantragRead | null;
    /**
     *
     * @type {DokumentJsonldFeststellungsantragRead}
     * @memberof SalgImLeasingJsonldFeststellungsantragRead
     */
    field303d?: DokumentJsonldFeststellungsantragRead | null;
}
/**
 *
 * @export
 * @interface SalgImLeasingJsonldFeststellungsantragVersionRead
 */
export interface SalgImLeasingJsonldFeststellungsantragVersionRead {
    /**
     *
     * @type {string | any}
     * @memberof SalgImLeasingJsonldFeststellungsantragVersionRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof SalgImLeasingJsonldFeststellungsantragVersionRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof SalgImLeasingJsonldFeststellungsantragVersionRead
     */
    '@type'?: string;
    /**
     * UUID der Maßnahme.
     * @type {string}
     * @memberof SalgImLeasingJsonldFeststellungsantragVersionRead
     */
    field300?: string | null;
    /**
     *
     * @type {DokumentJsonldFeststellungsantragVersionRead}
     * @memberof SalgImLeasingJsonldFeststellungsantragVersionRead
     */
    field304d?: DokumentJsonldFeststellungsantragVersionRead | null;
    /**
     *
     * @type {DokumentJsonldFeststellungsantragVersionRead}
     * @memberof SalgImLeasingJsonldFeststellungsantragVersionRead
     */
    field303d?: DokumentJsonldFeststellungsantragVersionRead | null;
}
/**
 *
 * @export
 * @interface Token
 */
export interface Token {
    /**
     *
     * @type {string}
     * @memberof Token
     */
    token?: string;
    /**
     *
     * @type {string}
     * @memberof Token
     */
    refresh_token?: string;
}
/**
 *
 * @export
 * @interface TokenRefresh
 */
export interface TokenRefresh {
    /**
     *
     * @type {string}
     * @memberof TokenRefresh
     */
    refresh_token?: string;
}
/**
 *
 * @export
 * @interface TraegerBenutzerReadBenutzerReadEmailBenutzerReadRoles
 */
export interface TraegerBenutzerReadBenutzerReadEmailBenutzerReadRoles {
    /**
     *
     * @type {string}
     * @memberof TraegerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface TraegerEinrichtungRead
 */
export interface TraegerEinrichtungRead {
    /**
     *
     * @type {number}
     * @memberof TraegerEinrichtungRead
     */
    id?: number;
    /**
     *
     * @type {TraegerverbandEinrichtungRead}
     * @memberof TraegerEinrichtungRead
     */
    traegerverband?: TraegerverbandEinrichtungRead | null;
    /**
     *
     * @type {string}
     * @memberof TraegerEinrichtungRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof TraegerEinrichtungRead
     */
    nrwKey: string;
    /**
     *
     * @type {string}
     * @memberof TraegerEinrichtungRead
     */
    postfach: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerEinrichtungRead
     */
    strasse: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerEinrichtungRead
     */
    hausnummer: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerEinrichtungRead
     */
    plz: string;
    /**
     *
     * @type {string}
     * @memberof TraegerEinrichtungRead
     */
    ort: string;
    /**
     *
     * @type {string}
     * @memberof TraegerEinrichtungRead
     */
    email: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerEinrichtungRead
     */
    telefon: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerEinrichtungRead
     */
    modifiedAt?: string;
}
/**
 *
 * @export
 * @interface TraegerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
 */
export interface TraegerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles {
    /**
     *
     * @type {string | any}
     * @memberof TraegerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface TraegerJsonldEinrichtungRead
 */
export interface TraegerJsonldEinrichtungRead {
    /**
     *
     * @type {string | any}
     * @memberof TraegerJsonldEinrichtungRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof TraegerJsonldEinrichtungRead
     */
    id?: number;
    /**
     *
     * @type {TraegerverbandJsonldEinrichtungRead}
     * @memberof TraegerJsonldEinrichtungRead
     */
    traegerverband?: TraegerverbandJsonldEinrichtungRead | null;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldEinrichtungRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldEinrichtungRead
     */
    nrwKey: string;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldEinrichtungRead
     */
    postfach: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldEinrichtungRead
     */
    strasse: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldEinrichtungRead
     */
    hausnummer: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldEinrichtungRead
     */
    plz: string;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldEinrichtungRead
     */
    ort: string;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldEinrichtungRead
     */
    email: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldEinrichtungRead
     */
    telefon: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldEinrichtungRead
     */
    modifiedAt?: string;
}
/**
 *
 * @export
 * @interface TraegerJsonldTraegerRead
 */
export interface TraegerJsonldTraegerRead {
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerRead
     */
    '@type'?: string;
    /**
     *
     * @type {string | any}
     * @memberof TraegerJsonldTraegerRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {number}
     * @memberof TraegerJsonldTraegerRead
     */
    id?: number;
    /**
     *
     * @type {TraegerverbandJsonldTraegerRead}
     * @memberof TraegerJsonldTraegerRead
     */
    traegerverband?: TraegerverbandJsonldTraegerRead | null;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerRead
     */
    nrwKey: string;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerRead
     */
    art: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof TraegerJsonldTraegerRead
     */
    benutzer?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof TraegerJsonldTraegerRead
     */
    einrichtungen?: Array<string>;
}
/**
 *
 * @export
 * @interface TraegerTraegerExistsOutputJsonldTraegerExists
 */
export interface TraegerTraegerExistsOutputJsonldTraegerExists {
    /**
     *
     * @type {string | any}
     * @memberof TraegerTraegerExistsOutputJsonldTraegerExists
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof TraegerTraegerExistsOutputJsonldTraegerExists
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerTraegerExistsOutputJsonldTraegerExists
     */
    '@type'?: string;
    /**
     *
     * @type {boolean}
     * @memberof TraegerTraegerExistsOutputJsonldTraegerExists
     */
    exists?: boolean;
}
/**
 *
 * @export
 * @interface TraegerTraegerExistsOutputTraegerExists
 */
export interface TraegerTraegerExistsOutputTraegerExists {
    /**
     *
     * @type {boolean}
     * @memberof TraegerTraegerExistsOutputTraegerExists
     */
    exists?: boolean;
}
/**
 *
 * @export
 * @interface TraegerTraegerRead
 */
export interface TraegerTraegerRead {
    /**
     *
     * @type {number}
     * @memberof TraegerTraegerRead
     */
    id?: number;
    /**
     *
     * @type {TraegerverbandTraegerRead}
     * @memberof TraegerTraegerRead
     */
    traegerverband?: TraegerverbandTraegerRead | null;
    /**
     *
     * @type {string}
     * @memberof TraegerTraegerRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof TraegerTraegerRead
     */
    nrwKey: string;
    /**
     *
     * @type {string}
     * @memberof TraegerTraegerRead
     */
    art: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof TraegerTraegerRead
     */
    benutzer?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof TraegerTraegerRead
     */
    einrichtungen?: Array<string>;
}
/**
 *
 * @export
 * @interface TraegerverbandBenutzerReadBenutzerReadEmailBenutzerReadRoles
 */
export interface TraegerverbandBenutzerReadBenutzerReadEmailBenutzerReadRoles {
    /**
     *
     * @type {string}
     * @memberof TraegerverbandBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface TraegerverbandEinrichtungRead
 */
export interface TraegerverbandEinrichtungRead {
    /**
     *
     * @type {number}
     * @memberof TraegerverbandEinrichtungRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandEinrichtungRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandEinrichtungRead
     */
    postfach: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandEinrichtungRead
     */
    strasse: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandEinrichtungRead
     */
    hausnummer: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandEinrichtungRead
     */
    plz: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandEinrichtungRead
     */
    ort: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandEinrichtungRead
     */
    email: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandEinrichtungRead
     */
    telefon: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandEinrichtungRead
     */
    modifiedAt?: string;
}
/**
 *
 * @export
 * @interface TraegerverbandJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
 */
export interface TraegerverbandJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles {
    /**
     *
     * @type {string | any}
     * @memberof TraegerverbandJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface TraegerverbandJsonldEinrichtungRead
 */
export interface TraegerverbandJsonldEinrichtungRead {
    /**
     *
     * @type {string | any}
     * @memberof TraegerverbandJsonldEinrichtungRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof TraegerverbandJsonldEinrichtungRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldEinrichtungRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldEinrichtungRead
     */
    postfach: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldEinrichtungRead
     */
    strasse: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldEinrichtungRead
     */
    hausnummer: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldEinrichtungRead
     */
    plz: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldEinrichtungRead
     */
    ort: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldEinrichtungRead
     */
    email: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldEinrichtungRead
     */
    telefon: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldEinrichtungRead
     */
    modifiedAt?: string;
}
/**
 *
 * @export
 * @interface TraegerverbandJsonldTraegerRead
 */
export interface TraegerverbandJsonldTraegerRead {
    /**
     *
     * @type {string | any}
     * @memberof TraegerverbandJsonldTraegerRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldTraegerRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldTraegerRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof TraegerverbandJsonldTraegerRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldTraegerRead
     */
    name: string;
}
/**
 *
 * @export
 * @interface TraegerverbandJsonldTraegerverbandRead
 */
export interface TraegerverbandJsonldTraegerverbandRead {
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldTraegerverbandRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldTraegerverbandRead
     */
    '@type'?: string;
    /**
     *
     * @type {string | any}
     * @memberof TraegerverbandJsonldTraegerverbandRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {number}
     * @memberof TraegerverbandJsonldTraegerverbandRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldTraegerverbandRead
     */
    name: string;
    /**
     *
     * @type {Array<string>}
     * @memberof TraegerverbandJsonldTraegerverbandRead
     */
    benutzer?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof TraegerverbandJsonldTraegerverbandRead
     */
    einrichtungen?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof TraegerverbandJsonldTraegerverbandRead
     */
    traeger?: Array<string>;
}
/**
 *
 * @export
 * @interface TraegerverbandTraegerRead
 */
export interface TraegerverbandTraegerRead {
    /**
     *
     * @type {number}
     * @memberof TraegerverbandTraegerRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerRead
     */
    name: string;
}
/**
 *
 * @export
 * @interface TraegerverbandTraegerverbandExistsOutputJsonldTraegerverbandExists
 */
export interface TraegerverbandTraegerverbandExistsOutputJsonldTraegerverbandExists {
    /**
     *
     * @type {string | any}
     * @memberof TraegerverbandTraegerverbandExistsOutputJsonldTraegerverbandExists
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandExistsOutputJsonldTraegerverbandExists
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandExistsOutputJsonldTraegerverbandExists
     */
    '@type'?: string;
    /**
     *
     * @type {boolean}
     * @memberof TraegerverbandTraegerverbandExistsOutputJsonldTraegerverbandExists
     */
    exists?: boolean;
}
/**
 *
 * @export
 * @interface TraegerverbandTraegerverbandExistsOutputTraegerverbandExists
 */
export interface TraegerverbandTraegerverbandExistsOutputTraegerverbandExists {
    /**
     *
     * @type {boolean}
     * @memberof TraegerverbandTraegerverbandExistsOutputTraegerverbandExists
     */
    exists?: boolean;
}
/**
 *
 * @export
 * @interface TraegerverbandTraegerverbandRead
 */
export interface TraegerverbandTraegerverbandRead {
    /**
     *
     * @type {number}
     * @memberof TraegerverbandTraegerverbandRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandRead
     */
    name: string;
    /**
     *
     * @type {Array<string>}
     * @memberof TraegerverbandTraegerverbandRead
     */
    benutzer?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof TraegerverbandTraegerverbandRead
     */
    einrichtungen?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof TraegerverbandTraegerverbandRead
     */
    traeger?: Array<string>;
}
/**
 *
 * @export
 * @interface UploadEmailReadEmailReadEmail
 */
export interface UploadEmailReadEmailReadEmail {
    /**
     *
     * @type {number}
     * @memberof UploadEmailReadEmailReadEmail
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadEmailReadEmailReadEmail
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadEmailReadEmailReadEmail
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof UploadEmailReadEmailReadEmail
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof UploadEmailReadEmailReadEmail
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof UploadEmailReadEmailReadEmail
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof UploadEmailReadEmailReadEmail
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerEmailReadEmailReadEmail}
     * @memberof UploadEmailReadEmailReadEmail
     */
    createdBy?: BenutzerEmailReadEmailReadEmail | null;
}
/**
 *
 * @export
 * @interface UploadFestsetzungsantragList
 */
export interface UploadFestsetzungsantragList {
    /**
     *
     * @type {number}
     * @memberof UploadFestsetzungsantragList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadFestsetzungsantragList
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadFestsetzungsantragList
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof UploadFestsetzungsantragList
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof UploadFestsetzungsantragList
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof UploadFestsetzungsantragList
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof UploadFestsetzungsantragList
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerFestsetzungsantragList}
     * @memberof UploadFestsetzungsantragList
     */
    createdBy?: BenutzerFestsetzungsantragList | null;
}
/**
 *
 * @export
 * @interface UploadFestsetzungsantragRead
 */
export interface UploadFestsetzungsantragRead {
    /**
     *
     * @type {number}
     * @memberof UploadFestsetzungsantragRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadFestsetzungsantragRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadFestsetzungsantragRead
     */
    originalName?: string;
}
/**
 *
 * @export
 * @interface UploadFestsetzungsantragVersionRead
 */
export interface UploadFestsetzungsantragVersionRead {
    /**
     *
     * @type {number}
     * @memberof UploadFestsetzungsantragVersionRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadFestsetzungsantragVersionRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadFestsetzungsantragVersionRead
     */
    originalName?: string;
}
/**
 *
 * @export
 * @interface UploadFeststellungsantragList
 */
export interface UploadFeststellungsantragList {
    /**
     *
     * @type {number}
     * @memberof UploadFeststellungsantragList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadFeststellungsantragList
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadFeststellungsantragList
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof UploadFeststellungsantragList
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof UploadFeststellungsantragList
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof UploadFeststellungsantragList
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof UploadFeststellungsantragList
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerFeststellungsantragList}
     * @memberof UploadFeststellungsantragList
     */
    createdBy?: BenutzerFeststellungsantragList | null;
}
/**
 *
 * @export
 * @interface UploadFeststellungsantragRead
 */
export interface UploadFeststellungsantragRead {
    /**
     *
     * @type {number}
     * @memberof UploadFeststellungsantragRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadFeststellungsantragRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadFeststellungsantragRead
     */
    originalName?: string;
}
/**
 *
 * @export
 * @interface UploadFeststellungsantragVersionRead
 */
export interface UploadFeststellungsantragVersionRead {
    /**
     *
     * @type {number}
     * @memberof UploadFeststellungsantragVersionRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadFeststellungsantragVersionRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadFeststellungsantragVersionRead
     */
    originalName?: string;
}
/**
 *
 * @export
 * @interface UploadInvestorenmodellList
 */
export interface UploadInvestorenmodellList {
    /**
     *
     * @type {number}
     * @memberof UploadInvestorenmodellList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadInvestorenmodellList
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadInvestorenmodellList
     */
    originalName?: string;
    /**
     *
     * @type {string}
     * @memberof UploadInvestorenmodellList
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerInvestorenmodellList}
     * @memberof UploadInvestorenmodellList
     */
    createdBy?: BenutzerInvestorenmodellList | null;
}
/**
 *
 * @export
 * @interface UploadInvestorenmodellRead
 */
export interface UploadInvestorenmodellRead {
    /**
     *
     * @type {number}
     * @memberof UploadInvestorenmodellRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadInvestorenmodellRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadInvestorenmodellRead
     */
    originalName?: string;
}
/**
 *
 * @export
 * @interface UploadInvestorenmodellVersionRead
 */
export interface UploadInvestorenmodellVersionRead {
    /**
     *
     * @type {number}
     * @memberof UploadInvestorenmodellVersionRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadInvestorenmodellVersionRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadInvestorenmodellVersionRead
     */
    originalName?: string;
}
/**
 *
 * @export
 * @interface UploadJsonldEmailReadEmailReadEmail
 */
export interface UploadJsonldEmailReadEmailReadEmail {
    /**
     *
     * @type {string | any}
     * @memberof UploadJsonldEmailReadEmailReadEmail
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldEmailReadEmailReadEmail
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldEmailReadEmailReadEmail
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof UploadJsonldEmailReadEmailReadEmail
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldEmailReadEmailReadEmail
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldEmailReadEmailReadEmail
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldEmailReadEmailReadEmail
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof UploadJsonldEmailReadEmailReadEmail
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldEmailReadEmailReadEmail
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldEmailReadEmailReadEmail
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerJsonldEmailReadEmailReadEmail}
     * @memberof UploadJsonldEmailReadEmailReadEmail
     */
    createdBy?: BenutzerJsonldEmailReadEmailReadEmail | null;
}
/**
 *
 * @export
 * @interface UploadJsonldFestsetzungsantragList
 */
export interface UploadJsonldFestsetzungsantragList {
    /**
     *
     * @type {string | any}
     * @memberof UploadJsonldFestsetzungsantragList
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldFestsetzungsantragList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldFestsetzungsantragList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof UploadJsonldFestsetzungsantragList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldFestsetzungsantragList
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldFestsetzungsantragList
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldFestsetzungsantragList
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof UploadJsonldFestsetzungsantragList
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldFestsetzungsantragList
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldFestsetzungsantragList
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerJsonldFestsetzungsantragList}
     * @memberof UploadJsonldFestsetzungsantragList
     */
    createdBy?: BenutzerJsonldFestsetzungsantragList | null;
}
/**
 *
 * @export
 * @interface UploadJsonldFestsetzungsantragRead
 */
export interface UploadJsonldFestsetzungsantragRead {
    /**
     *
     * @type {string | any}
     * @memberof UploadJsonldFestsetzungsantragRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldFestsetzungsantragRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldFestsetzungsantragRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof UploadJsonldFestsetzungsantragRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldFestsetzungsantragRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldFestsetzungsantragRead
     */
    originalName?: string;
}
/**
 *
 * @export
 * @interface UploadJsonldFestsetzungsantragVersionRead
 */
export interface UploadJsonldFestsetzungsantragVersionRead {
    /**
     *
     * @type {string | any}
     * @memberof UploadJsonldFestsetzungsantragVersionRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldFestsetzungsantragVersionRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldFestsetzungsantragVersionRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof UploadJsonldFestsetzungsantragVersionRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldFestsetzungsantragVersionRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldFestsetzungsantragVersionRead
     */
    originalName?: string;
}
/**
 *
 * @export
 * @interface UploadJsonldFeststellungsantragList
 */
export interface UploadJsonldFeststellungsantragList {
    /**
     *
     * @type {string | any}
     * @memberof UploadJsonldFeststellungsantragList
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldFeststellungsantragList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldFeststellungsantragList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof UploadJsonldFeststellungsantragList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldFeststellungsantragList
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldFeststellungsantragList
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldFeststellungsantragList
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof UploadJsonldFeststellungsantragList
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldFeststellungsantragList
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldFeststellungsantragList
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerJsonldFeststellungsantragList}
     * @memberof UploadJsonldFeststellungsantragList
     */
    createdBy?: BenutzerJsonldFeststellungsantragList | null;
}
/**
 *
 * @export
 * @interface UploadJsonldFeststellungsantragRead
 */
export interface UploadJsonldFeststellungsantragRead {
    /**
     *
     * @type {string | any}
     * @memberof UploadJsonldFeststellungsantragRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldFeststellungsantragRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldFeststellungsantragRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof UploadJsonldFeststellungsantragRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldFeststellungsantragRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldFeststellungsantragRead
     */
    originalName?: string;
}
/**
 *
 * @export
 * @interface UploadJsonldFeststellungsantragVersionRead
 */
export interface UploadJsonldFeststellungsantragVersionRead {
    /**
     *
     * @type {string | any}
     * @memberof UploadJsonldFeststellungsantragVersionRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldFeststellungsantragVersionRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldFeststellungsantragVersionRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof UploadJsonldFeststellungsantragVersionRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldFeststellungsantragVersionRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldFeststellungsantragVersionRead
     */
    originalName?: string;
}
/**
 *
 * @export
 * @interface UploadJsonldInvestorenmodellList
 */
export interface UploadJsonldInvestorenmodellList {
    /**
     *
     * @type {string | any}
     * @memberof UploadJsonldInvestorenmodellList
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldInvestorenmodellList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldInvestorenmodellList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof UploadJsonldInvestorenmodellList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldInvestorenmodellList
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldInvestorenmodellList
     */
    originalName?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldInvestorenmodellList
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerJsonldInvestorenmodellList}
     * @memberof UploadJsonldInvestorenmodellList
     */
    createdBy?: BenutzerJsonldInvestorenmodellList | null;
}
/**
 *
 * @export
 * @interface UploadJsonldInvestorenmodellRead
 */
export interface UploadJsonldInvestorenmodellRead {
    /**
     *
     * @type {string | any}
     * @memberof UploadJsonldInvestorenmodellRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldInvestorenmodellRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldInvestorenmodellRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof UploadJsonldInvestorenmodellRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldInvestorenmodellRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldInvestorenmodellRead
     */
    originalName?: string;
}
/**
 *
 * @export
 * @interface UploadJsonldInvestorenmodellVersionRead
 */
export interface UploadJsonldInvestorenmodellVersionRead {
    /**
     *
     * @type {string | any}
     * @memberof UploadJsonldInvestorenmodellVersionRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldInvestorenmodellVersionRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldInvestorenmodellVersionRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof UploadJsonldInvestorenmodellVersionRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldInvestorenmodellVersionRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldInvestorenmodellVersionRead
     */
    originalName?: string;
}
/**
 *
 * @export
 * @interface UploadJsonldMassenmailRead
 */
export interface UploadJsonldMassenmailRead {
    /**
     *
     * @type {string | any}
     * @memberof UploadJsonldMassenmailRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMassenmailRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMassenmailRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof UploadJsonldMassenmailRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMassenmailRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMassenmailRead
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMassenmailRead
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof UploadJsonldMassenmailRead
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMassenmailRead
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMassenmailRead
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerJsonldMassenmailRead}
     * @memberof UploadJsonldMassenmailRead
     */
    createdBy?: BenutzerJsonldMassenmailRead | null;
}
/**
 *
 * @export
 * @interface UploadJsonldUploadRead
 */
export interface UploadJsonldUploadRead {
    /**
     *
     * @type {string | any}
     * @memberof UploadJsonldUploadRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldUploadRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldUploadRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof UploadJsonldUploadRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldUploadRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldUploadRead
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldUploadRead
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof UploadJsonldUploadRead
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldUploadRead
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldUploadRead
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface UploadMassenmailRead
 */
export interface UploadMassenmailRead {
    /**
     *
     * @type {number}
     * @memberof UploadMassenmailRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadMassenmailRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadMassenmailRead
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof UploadMassenmailRead
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof UploadMassenmailRead
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof UploadMassenmailRead
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof UploadMassenmailRead
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerMassenmailRead}
     * @memberof UploadMassenmailRead
     */
    createdBy?: BenutzerMassenmailRead | null;
}
/**
 *
 * @export
 * @interface UploadUploadRead
 */
export interface UploadUploadRead {
    /**
     *
     * @type {number}
     * @memberof UploadUploadRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadUploadRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadUploadRead
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof UploadUploadRead
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof UploadUploadRead
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof UploadUploadRead
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof UploadUploadRead
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface ZimmerkategorieEinrichtungRead
 */
export interface ZimmerkategorieEinrichtungRead {
    /**
     *
     * @type {string}
     * @memberof ZimmerkategorieEinrichtungRead
     */
    field550?: string | null;
    /**
     *
     * @type {string}
     * @memberof ZimmerkategorieEinrichtungRead
     */
    field5151?: string | null;
}
/**
 *
 * @export
 * @interface ZimmerkategorieFestsetzungsantragList
 */
export interface ZimmerkategorieFestsetzungsantragList {
    /**
     *
     * @type {string}
     * @memberof ZimmerkategorieFestsetzungsantragList
     */
    field550?: string | null;
    /**
     *
     * @type {string}
     * @memberof ZimmerkategorieFestsetzungsantragList
     */
    field5151?: string | null;
}
/**
 *
 * @export
 * @interface ZimmerkategorieJsonldEinrichtungRead
 */
export interface ZimmerkategorieJsonldEinrichtungRead {
    /**
     *
     * @type {string | any}
     * @memberof ZimmerkategorieJsonldEinrichtungRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof ZimmerkategorieJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof ZimmerkategorieJsonldEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof ZimmerkategorieJsonldEinrichtungRead
     */
    field550?: string | null;
    /**
     *
     * @type {string}
     * @memberof ZimmerkategorieJsonldEinrichtungRead
     */
    field5151?: string | null;
}
/**
 *
 * @export
 * @interface ZimmerkategorieJsonldFestsetzungsantragList
 */
export interface ZimmerkategorieJsonldFestsetzungsantragList {
    /**
     *
     * @type {string | any}
     * @memberof ZimmerkategorieJsonldFestsetzungsantragList
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof ZimmerkategorieJsonldFestsetzungsantragList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof ZimmerkategorieJsonldFestsetzungsantragList
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof ZimmerkategorieJsonldFestsetzungsantragList
     */
    field550?: string | null;
    /**
     *
     * @type {string}
     * @memberof ZimmerkategorieJsonldFestsetzungsantragList
     */
    field5151?: string | null;
}
/**
 *
 * @export
 * @interface ZuschussFeststellungsantragRead
 */
export interface ZuschussFeststellungsantragRead {
    /**
     *
     * @type {string}
     * @memberof ZuschussFeststellungsantragRead
     */
    field240?: string | null;
    /**
     *
     * @type {DokumentFeststellungsantragRead}
     * @memberof ZuschussFeststellungsantragRead
     */
    field242d?: DokumentFeststellungsantragRead | null;
}
/**
 *
 * @export
 * @interface ZuschussFeststellungsantragVersionRead
 */
export interface ZuschussFeststellungsantragVersionRead {
    /**
     *
     * @type {string}
     * @memberof ZuschussFeststellungsantragVersionRead
     */
    field240?: string | null;
    /**
     *
     * @type {DokumentFeststellungsantragVersionRead}
     * @memberof ZuschussFeststellungsantragVersionRead
     */
    field242d?: DokumentFeststellungsantragVersionRead | null;
}
/**
 *
 * @export
 * @interface ZuschussJsonldFeststellungsantragRead
 */
export interface ZuschussJsonldFeststellungsantragRead {
    /**
     *
     * @type {string | any}
     * @memberof ZuschussJsonldFeststellungsantragRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof ZuschussJsonldFeststellungsantragRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof ZuschussJsonldFeststellungsantragRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof ZuschussJsonldFeststellungsantragRead
     */
    field240?: string | null;
    /**
     *
     * @type {DokumentJsonldFeststellungsantragRead}
     * @memberof ZuschussJsonldFeststellungsantragRead
     */
    field242d?: DokumentJsonldFeststellungsantragRead | null;
}
/**
 *
 * @export
 * @interface ZuschussJsonldFeststellungsantragVersionRead
 */
export interface ZuschussJsonldFeststellungsantragVersionRead {
    /**
     *
     * @type {string | any}
     * @memberof ZuschussJsonldFeststellungsantragVersionRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof ZuschussJsonldFeststellungsantragVersionRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof ZuschussJsonldFeststellungsantragVersionRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof ZuschussJsonldFeststellungsantragVersionRead
     */
    field240?: string | null;
    /**
     *
     * @type {DokumentJsonldFeststellungsantragVersionRead}
     * @memberof ZuschussJsonldFeststellungsantragVersionRead
     */
    field242d?: DokumentJsonldFeststellungsantragVersionRead | null;
}
/**
 *
 * @export
 * @interface ZustaendigkeitJsonldZustaendigkeitRead
 */
export interface ZustaendigkeitJsonldZustaendigkeitRead {
    /**
     *
     * @type {string | any}
     * @memberof ZustaendigkeitJsonldZustaendigkeitRead
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof ZustaendigkeitJsonldZustaendigkeitRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof ZustaendigkeitJsonldZustaendigkeitRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof ZustaendigkeitJsonldZustaendigkeitRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof ZustaendigkeitJsonldZustaendigkeitRead
     */
    landschaftsverband?: string | null;
    /**
     *
     * @type {BenutzerJsonldZustaendigkeitRead}
     * @memberof ZustaendigkeitJsonldZustaendigkeitRead
     */
    benutzer?: BenutzerJsonldZustaendigkeitRead | null;
    /**
     *
     * @type {string}
     * @memberof ZustaendigkeitJsonldZustaendigkeitRead
     */
    region: string;
    /**
     *
     * @type {string}
     * @memberof ZustaendigkeitJsonldZustaendigkeitRead
     */
    ort?: string;
    /**
     *
     * @type {string}
     * @memberof ZustaendigkeitJsonldZustaendigkeitRead
     */
    kreis?: string;
    /**
     *
     * @type {string}
     * @memberof ZustaendigkeitJsonldZustaendigkeitRead
     */
    einrichtungArt?: string | null;
}
/**
 *
 * @export
 * @interface ZustaendigkeitJsonldZustaendigkeitWrite
 */
export interface ZustaendigkeitJsonldZustaendigkeitWrite {
    /**
     *
     * @type {string | any}
     * @memberof ZustaendigkeitJsonldZustaendigkeitWrite
     */
    '@context'?: string | any;
    /**
     *
     * @type {string}
     * @memberof ZustaendigkeitJsonldZustaendigkeitWrite
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof ZustaendigkeitJsonldZustaendigkeitWrite
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof ZustaendigkeitJsonldZustaendigkeitWrite
     */
    benutzer?: string | null;
}
/**
 *
 * @export
 * @interface ZustaendigkeitZustaendigkeitRead
 */
export interface ZustaendigkeitZustaendigkeitRead {
    /**
     *
     * @type {number}
     * @memberof ZustaendigkeitZustaendigkeitRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof ZustaendigkeitZustaendigkeitRead
     */
    landschaftsverband?: string | null;
    /**
     *
     * @type {BenutzerZustaendigkeitRead}
     * @memberof ZustaendigkeitZustaendigkeitRead
     */
    benutzer?: BenutzerZustaendigkeitRead | null;
    /**
     *
     * @type {string}
     * @memberof ZustaendigkeitZustaendigkeitRead
     */
    region: string;
    /**
     *
     * @type {string}
     * @memberof ZustaendigkeitZustaendigkeitRead
     */
    ort?: string;
    /**
     *
     * @type {string}
     * @memberof ZustaendigkeitZustaendigkeitRead
     */
    kreis?: string;
    /**
     *
     * @type {string}
     * @memberof ZustaendigkeitZustaendigkeitRead
     */
    einrichtungArt?: string | null;
}
/**
 *
 * @export
 * @interface ZustaendigkeitZustaendigkeitWrite
 */
export interface ZustaendigkeitZustaendigkeitWrite {
    /**
     *
     * @type {string}
     * @memberof ZustaendigkeitZustaendigkeitWrite
     */
    benutzer?: string | null;
}

/**
 * AngemessenheitsgrenzeApi - axios parameter creator
 * @export
 */
export const AngemessenheitsgrenzeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of Angemessenheitsgrenze resources.
         * @summary Retrieves the collection of Angemessenheitsgrenze resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [typ]
         * @param {Array<string>} [typ2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAngemessenheitsgrenzeCollection: async (
            page?: number,
            itemsPerPage?: number,
            typ?: string,
            typ2?: Array<string>,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/angemessenheitsgrenze`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (typ !== undefined) {
                localVarQueryParameter['typ'] = typ;
            }

            if (typ2) {
                localVarQueryParameter['typ[]'] = typ2;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Angemessenheitsgrenze resource.
         * @summary Retrieves a Angemessenheitsgrenze resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAngemessenheitsgrenzeItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAngemessenheitsgrenzeItem', 'id', id);
            const localVarPath = `/api/angemessenheitsgrenze/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Angemessenheitsgrenze resource.
         * @summary Creates a Angemessenheitsgrenze resource.
         * @param {AngemessenheitsgrenzeJsonld} angemessenheitsgrenzeJsonld The new Angemessenheitsgrenze resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAngemessenheitsgrenzeCollection: async (
            angemessenheitsgrenzeJsonld: AngemessenheitsgrenzeJsonld,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'angemessenheitsgrenzeJsonld' is not null or undefined
            assertParamExists(
                'postAngemessenheitsgrenzeCollection',
                'angemessenheitsgrenzeJsonld',
                angemessenheitsgrenzeJsonld
            );
            const localVarPath = `/api/angemessenheitsgrenze`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                angemessenheitsgrenzeJsonld,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Angemessenheitsgrenze resource.
         * @summary Replaces the Angemessenheitsgrenze resource.
         * @param {string} id Resource identifier
         * @param {AngemessenheitsgrenzeJsonld} angemessenheitsgrenzeJsonld The updated Angemessenheitsgrenze resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAngemessenheitsgrenzeItem: async (
            id: string,
            angemessenheitsgrenzeJsonld: AngemessenheitsgrenzeJsonld,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putAngemessenheitsgrenzeItem', 'id', id);
            // verify required parameter 'angemessenheitsgrenzeJsonld' is not null or undefined
            assertParamExists(
                'putAngemessenheitsgrenzeItem',
                'angemessenheitsgrenzeJsonld',
                angemessenheitsgrenzeJsonld
            );
            const localVarPath = `/api/angemessenheitsgrenze/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                angemessenheitsgrenzeJsonld,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * AngemessenheitsgrenzeApi - functional programming interface
 * @export
 */
export const AngemessenheitsgrenzeApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = AngemessenheitsgrenzeApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of Angemessenheitsgrenze resources.
         * @summary Retrieves the collection of Angemessenheitsgrenze resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [typ]
         * @param {Array<string>} [typ2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAngemessenheitsgrenzeCollection(
            page?: number,
            itemsPerPage?: number,
            typ?: string,
            typ2?: Array<string>,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAngemessenheitsgrenzeCollection(
                page,
                itemsPerPage,
                typ,
                typ2,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Angemessenheitsgrenze resource.
         * @summary Retrieves a Angemessenheitsgrenze resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAngemessenheitsgrenzeItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AngemessenheitsgrenzeJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAngemessenheitsgrenzeItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Angemessenheitsgrenze resource.
         * @summary Creates a Angemessenheitsgrenze resource.
         * @param {AngemessenheitsgrenzeJsonld} angemessenheitsgrenzeJsonld The new Angemessenheitsgrenze resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAngemessenheitsgrenzeCollection(
            angemessenheitsgrenzeJsonld: AngemessenheitsgrenzeJsonld,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AngemessenheitsgrenzeJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAngemessenheitsgrenzeCollection(
                angemessenheitsgrenzeJsonld,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Angemessenheitsgrenze resource.
         * @summary Replaces the Angemessenheitsgrenze resource.
         * @param {string} id Resource identifier
         * @param {AngemessenheitsgrenzeJsonld} angemessenheitsgrenzeJsonld The updated Angemessenheitsgrenze resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAngemessenheitsgrenzeItem(
            id: string,
            angemessenheitsgrenzeJsonld: AngemessenheitsgrenzeJsonld,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AngemessenheitsgrenzeJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAngemessenheitsgrenzeItem(
                id,
                angemessenheitsgrenzeJsonld,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * AngemessenheitsgrenzeApi - factory interface
 * @export
 */
export const AngemessenheitsgrenzeApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = AngemessenheitsgrenzeApiFp(configuration);
    return {
        /**
         * Retrieves the collection of Angemessenheitsgrenze resources.
         * @summary Retrieves the collection of Angemessenheitsgrenze resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [typ]
         * @param {Array<string>} [typ2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAngemessenheitsgrenzeCollection(
            page?: number,
            itemsPerPage?: number,
            typ?: string,
            typ2?: Array<string>,
            options?: any
        ): AxiosPromise<InlineResponse200> {
            return localVarFp
                .getAngemessenheitsgrenzeCollection(page, itemsPerPage, typ, typ2, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Angemessenheitsgrenze resource.
         * @summary Retrieves a Angemessenheitsgrenze resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAngemessenheitsgrenzeItem(id: string, options?: any): AxiosPromise<AngemessenheitsgrenzeJsonld> {
            return localVarFp.getAngemessenheitsgrenzeItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Angemessenheitsgrenze resource.
         * @summary Creates a Angemessenheitsgrenze resource.
         * @param {AngemessenheitsgrenzeJsonld} angemessenheitsgrenzeJsonld The new Angemessenheitsgrenze resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAngemessenheitsgrenzeCollection(
            angemessenheitsgrenzeJsonld: AngemessenheitsgrenzeJsonld,
            options?: any
        ): AxiosPromise<AngemessenheitsgrenzeJsonld> {
            return localVarFp
                .postAngemessenheitsgrenzeCollection(angemessenheitsgrenzeJsonld, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Angemessenheitsgrenze resource.
         * @summary Replaces the Angemessenheitsgrenze resource.
         * @param {string} id Resource identifier
         * @param {AngemessenheitsgrenzeJsonld} angemessenheitsgrenzeJsonld The updated Angemessenheitsgrenze resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAngemessenheitsgrenzeItem(
            id: string,
            angemessenheitsgrenzeJsonld: AngemessenheitsgrenzeJsonld,
            options?: any
        ): AxiosPromise<AngemessenheitsgrenzeJsonld> {
            return localVarFp
                .putAngemessenheitsgrenzeItem(id, angemessenheitsgrenzeJsonld, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * AngemessenheitsgrenzeApi - object-oriented interface
 * @export
 * @class AngemessenheitsgrenzeApi
 * @extends {BaseAPI}
 */
export class AngemessenheitsgrenzeApi extends BaseAPI {
    /**
     * Retrieves the collection of Angemessenheitsgrenze resources.
     * @summary Retrieves the collection of Angemessenheitsgrenze resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [typ]
     * @param {Array<string>} [typ2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AngemessenheitsgrenzeApi
     */
    public getAngemessenheitsgrenzeCollection(
        page?: number,
        itemsPerPage?: number,
        typ?: string,
        typ2?: Array<string>,
        options?: AxiosRequestConfig
    ) {
        return AngemessenheitsgrenzeApiFp(this.configuration)
            .getAngemessenheitsgrenzeCollection(page, itemsPerPage, typ, typ2, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Angemessenheitsgrenze resource.
     * @summary Retrieves a Angemessenheitsgrenze resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AngemessenheitsgrenzeApi
     */
    public getAngemessenheitsgrenzeItem(id: string, options?: AxiosRequestConfig) {
        return AngemessenheitsgrenzeApiFp(this.configuration)
            .getAngemessenheitsgrenzeItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Angemessenheitsgrenze resource.
     * @summary Creates a Angemessenheitsgrenze resource.
     * @param {AngemessenheitsgrenzeJsonld} angemessenheitsgrenzeJsonld The new Angemessenheitsgrenze resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AngemessenheitsgrenzeApi
     */
    public postAngemessenheitsgrenzeCollection(
        angemessenheitsgrenzeJsonld: AngemessenheitsgrenzeJsonld,
        options?: AxiosRequestConfig
    ) {
        return AngemessenheitsgrenzeApiFp(this.configuration)
            .postAngemessenheitsgrenzeCollection(angemessenheitsgrenzeJsonld, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Angemessenheitsgrenze resource.
     * @summary Replaces the Angemessenheitsgrenze resource.
     * @param {string} id Resource identifier
     * @param {AngemessenheitsgrenzeJsonld} angemessenheitsgrenzeJsonld The updated Angemessenheitsgrenze resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AngemessenheitsgrenzeApi
     */
    public putAngemessenheitsgrenzeItem(
        id: string,
        angemessenheitsgrenzeJsonld: AngemessenheitsgrenzeJsonld,
        options?: AxiosRequestConfig
    ) {
        return AngemessenheitsgrenzeApiFp(this.configuration)
            .putAngemessenheitsgrenzeItem(id, angemessenheitsgrenzeJsonld, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * BaupreisindexApi - axios parameter creator
 * @export
 */
export const BaupreisindexApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of Baupreisindex resources.
         * @summary Retrieves the collection of Baupreisindex resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [basisjahr]
         * @param {Array<number>} [basisjahr2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBaupreisindexCollection: async (
            page?: number,
            itemsPerPage?: number,
            basisjahr?: number,
            basisjahr2?: Array<number>,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/baupreisindex`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (basisjahr !== undefined) {
                localVarQueryParameter['basisjahr'] = basisjahr;
            }

            if (basisjahr2) {
                localVarQueryParameter['basisjahr[]'] = basisjahr2;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Baupreisindex resource.
         * @summary Retrieves a Baupreisindex resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBaupreisindexItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBaupreisindexItem', 'id', id);
            const localVarPath = `/api/baupreisindex/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Baupreisindex resource.
         * @summary Creates a Baupreisindex resource.
         * @param {BaupreisindexJsonld} baupreisindexJsonld The new Baupreisindex resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postBaupreisindexCollection: async (
            baupreisindexJsonld: BaupreisindexJsonld,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'baupreisindexJsonld' is not null or undefined
            assertParamExists('postBaupreisindexCollection', 'baupreisindexJsonld', baupreisindexJsonld);
            const localVarPath = `/api/baupreisindex`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                baupreisindexJsonld,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Baupreisindex resource.
         * @summary Replaces the Baupreisindex resource.
         * @param {string} id Resource identifier
         * @param {BaupreisindexJsonld} baupreisindexJsonld The updated Baupreisindex resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putBaupreisindexItem: async (
            id: string,
            baupreisindexJsonld: BaupreisindexJsonld,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putBaupreisindexItem', 'id', id);
            // verify required parameter 'baupreisindexJsonld' is not null or undefined
            assertParamExists('putBaupreisindexItem', 'baupreisindexJsonld', baupreisindexJsonld);
            const localVarPath = `/api/baupreisindex/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                baupreisindexJsonld,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * BaupreisindexApi - functional programming interface
 * @export
 */
export const BaupreisindexApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = BaupreisindexApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of Baupreisindex resources.
         * @summary Retrieves the collection of Baupreisindex resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [basisjahr]
         * @param {Array<number>} [basisjahr2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBaupreisindexCollection(
            page?: number,
            itemsPerPage?: number,
            basisjahr?: number,
            basisjahr2?: Array<number>,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBaupreisindexCollection(
                page,
                itemsPerPage,
                basisjahr,
                basisjahr2,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Baupreisindex resource.
         * @summary Retrieves a Baupreisindex resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBaupreisindexItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaupreisindexJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBaupreisindexItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Baupreisindex resource.
         * @summary Creates a Baupreisindex resource.
         * @param {BaupreisindexJsonld} baupreisindexJsonld The new Baupreisindex resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postBaupreisindexCollection(
            baupreisindexJsonld: BaupreisindexJsonld,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaupreisindexJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postBaupreisindexCollection(
                baupreisindexJsonld,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Baupreisindex resource.
         * @summary Replaces the Baupreisindex resource.
         * @param {string} id Resource identifier
         * @param {BaupreisindexJsonld} baupreisindexJsonld The updated Baupreisindex resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putBaupreisindexItem(
            id: string,
            baupreisindexJsonld: BaupreisindexJsonld,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaupreisindexJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putBaupreisindexItem(
                id,
                baupreisindexJsonld,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * BaupreisindexApi - factory interface
 * @export
 */
export const BaupreisindexApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = BaupreisindexApiFp(configuration);
    return {
        /**
         * Retrieves the collection of Baupreisindex resources.
         * @summary Retrieves the collection of Baupreisindex resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [basisjahr]
         * @param {Array<number>} [basisjahr2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBaupreisindexCollection(
            page?: number,
            itemsPerPage?: number,
            basisjahr?: number,
            basisjahr2?: Array<number>,
            options?: any
        ): AxiosPromise<InlineResponse2001> {
            return localVarFp
                .getBaupreisindexCollection(page, itemsPerPage, basisjahr, basisjahr2, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Baupreisindex resource.
         * @summary Retrieves a Baupreisindex resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBaupreisindexItem(id: string, options?: any): AxiosPromise<BaupreisindexJsonld> {
            return localVarFp.getBaupreisindexItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Baupreisindex resource.
         * @summary Creates a Baupreisindex resource.
         * @param {BaupreisindexJsonld} baupreisindexJsonld The new Baupreisindex resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postBaupreisindexCollection(
            baupreisindexJsonld: BaupreisindexJsonld,
            options?: any
        ): AxiosPromise<BaupreisindexJsonld> {
            return localVarFp
                .postBaupreisindexCollection(baupreisindexJsonld, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Baupreisindex resource.
         * @summary Replaces the Baupreisindex resource.
         * @param {string} id Resource identifier
         * @param {BaupreisindexJsonld} baupreisindexJsonld The updated Baupreisindex resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putBaupreisindexItem(
            id: string,
            baupreisindexJsonld: BaupreisindexJsonld,
            options?: any
        ): AxiosPromise<BaupreisindexJsonld> {
            return localVarFp
                .putBaupreisindexItem(id, baupreisindexJsonld, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaupreisindexApi - object-oriented interface
 * @export
 * @class BaupreisindexApi
 * @extends {BaseAPI}
 */
export class BaupreisindexApi extends BaseAPI {
    /**
     * Retrieves the collection of Baupreisindex resources.
     * @summary Retrieves the collection of Baupreisindex resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {number} [basisjahr]
     * @param {Array<number>} [basisjahr2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaupreisindexApi
     */
    public getBaupreisindexCollection(
        page?: number,
        itemsPerPage?: number,
        basisjahr?: number,
        basisjahr2?: Array<number>,
        options?: AxiosRequestConfig
    ) {
        return BaupreisindexApiFp(this.configuration)
            .getBaupreisindexCollection(page, itemsPerPage, basisjahr, basisjahr2, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Baupreisindex resource.
     * @summary Retrieves a Baupreisindex resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaupreisindexApi
     */
    public getBaupreisindexItem(id: string, options?: AxiosRequestConfig) {
        return BaupreisindexApiFp(this.configuration)
            .getBaupreisindexItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Baupreisindex resource.
     * @summary Creates a Baupreisindex resource.
     * @param {BaupreisindexJsonld} baupreisindexJsonld The new Baupreisindex resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaupreisindexApi
     */
    public postBaupreisindexCollection(baupreisindexJsonld: BaupreisindexJsonld, options?: AxiosRequestConfig) {
        return BaupreisindexApiFp(this.configuration)
            .postBaupreisindexCollection(baupreisindexJsonld, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Baupreisindex resource.
     * @summary Replaces the Baupreisindex resource.
     * @param {string} id Resource identifier
     * @param {BaupreisindexJsonld} baupreisindexJsonld The updated Baupreisindex resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaupreisindexApi
     */
    public putBaupreisindexItem(id: string, baupreisindexJsonld: BaupreisindexJsonld, options?: AxiosRequestConfig) {
        return BaupreisindexApiFp(this.configuration)
            .putBaupreisindexItem(id, baupreisindexJsonld, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * BenutzerApi - axios parameter creator
 * @export
 */
export const BenutzerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a Benutzer resource.
         * @summary Retrieves a Benutzer resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aufgabenBenutzerItem: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/profile/aufgaben`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Benutzer resource.
         * @summary Updates the Benutzer resource.
         * @param {BenutzerCredentialChangeConfirmInput} benutzerCredentialChangeConfirmInput The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmChangeBenutzerItem: async (
            benutzerCredentialChangeConfirmInput: BenutzerCredentialChangeConfirmInput,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'benutzerCredentialChangeConfirmInput' is not null or undefined
            assertParamExists(
                'confirmChangeBenutzerItem',
                'benutzerCredentialChangeConfirmInput',
                benutzerCredentialChangeConfirmInput
            );
            const localVarPath = `/api/profile/confirm-change`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                benutzerCredentialChangeConfirmInput,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the Benutzer resource.
         * @summary Removes the Benutzer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBenutzerItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteBenutzerItem', 'id', id);
            const localVarPath = `/api/benutzer/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Benutzer resource.
         * @summary Updates the Benutzer resource.
         * @param {BenutzerCredentialChangeRequestInput} benutzerCredentialChangeRequestInput The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editEmailBenutzerItem: async (
            benutzerCredentialChangeRequestInput: BenutzerCredentialChangeRequestInput,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'benutzerCredentialChangeRequestInput' is not null or undefined
            assertParamExists(
                'editEmailBenutzerItem',
                'benutzerCredentialChangeRequestInput',
                benutzerCredentialChangeRequestInput
            );
            const localVarPath = `/api/profile/edit-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                benutzerCredentialChangeRequestInput,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Benutzer resource.
         * @summary Retrieves a Benutzer resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllowedCreateRollenBenutzerItem: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/profile/get-allowed-create-rollen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Benutzer resources.
         * @summary Retrieves the collection of Benutzer resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [existsDeaktiviertAt]
         * @param {number} [id]
         * @param {Array<number>} [id2]
         * @param {string} [rolle]
         * @param {Array<string>} [rolle2]
         * @param {string} [landschaftsverband]
         * @param {Array<string>} [landschaftsverband2]
         * @param {string} [einrichtungBenutzerEinrichtung]
         * @param {Array<string>} [einrichtungBenutzerEinrichtung2]
         * @param {string} [traegerverband]
         * @param {Array<string>} [traegerverband2]
         * @param {Array<string>} [qSearch]
         * @param {Array<string>} [qImpersonateSearch]
         * @param {'asc' | 'desc'} [orderNachname]
         * @param {'asc' | 'desc'} [orderDeaktiviertAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenutzerCollection: async (
            page?: number,
            itemsPerPage?: number,
            existsDeaktiviertAt?: boolean,
            id?: number,
            id2?: Array<number>,
            rolle?: string,
            rolle2?: Array<string>,
            landschaftsverband?: string,
            landschaftsverband2?: Array<string>,
            einrichtungBenutzerEinrichtung?: string,
            einrichtungBenutzerEinrichtung2?: Array<string>,
            traegerverband?: string,
            traegerverband2?: Array<string>,
            qSearch?: Array<string>,
            qImpersonateSearch?: Array<string>,
            orderNachname?: 'asc' | 'desc',
            orderDeaktiviertAt?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/benutzer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (existsDeaktiviertAt !== undefined) {
                localVarQueryParameter['exists[deaktiviertAt]'] = existsDeaktiviertAt;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (id2) {
                localVarQueryParameter['id[]'] = id2;
            }

            if (rolle !== undefined) {
                localVarQueryParameter['rolle'] = rolle;
            }

            if (rolle2) {
                localVarQueryParameter['rolle[]'] = rolle2;
            }

            if (landschaftsverband !== undefined) {
                localVarQueryParameter['landschaftsverband'] = landschaftsverband;
            }

            if (landschaftsverband2) {
                localVarQueryParameter['landschaftsverband[]'] = landschaftsverband2;
            }

            if (einrichtungBenutzerEinrichtung !== undefined) {
                localVarQueryParameter['einrichtungBenutzer.einrichtung'] = einrichtungBenutzerEinrichtung;
            }

            if (einrichtungBenutzerEinrichtung2) {
                localVarQueryParameter['einrichtungBenutzer.einrichtung[]'] = einrichtungBenutzerEinrichtung2;
            }

            if (traegerverband !== undefined) {
                localVarQueryParameter['traegerverband'] = traegerverband;
            }

            if (traegerverband2) {
                localVarQueryParameter['traegerverband[]'] = traegerverband2;
            }

            if (qSearch) {
                localVarQueryParameter['qSearch[]'] = qSearch;
            }

            if (qImpersonateSearch) {
                localVarQueryParameter['qImpersonateSearch[]'] = qImpersonateSearch;
            }

            if (orderNachname !== undefined) {
                localVarQueryParameter['order[nachname]'] = orderNachname;
            }

            if (orderDeaktiviertAt !== undefined) {
                localVarQueryParameter['order[deaktiviertAt]'] = orderDeaktiviertAt;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Benutzer resource.
         * @summary Retrieves a Benutzer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenutzerItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBenutzerItem', 'id', id);
            const localVarPath = `/api/benutzer/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Benutzer resource.
         * @summary Retrieves a Benutzer resource.
         * @param {string} [email]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByEmailBenutzerItem: async (email?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/benutzer/get-by-email/{email}`.replace(
                `{${'email'}}`,
                encodeURIComponent(String(email))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Benutzer resource.
         * @summary Retrieves a Benutzer resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lvAufgabenBenutzerItem: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/profile/lv_aufgaben`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Benutzer resource.
         * @summary Updates the Benutzer resource.
         * @param {BenutzerCredentialChangeRequestInput} benutzerCredentialChangeRequestInput The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordResetBenutzerItem: async (
            benutzerCredentialChangeRequestInput: BenutzerCredentialChangeRequestInput,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'benutzerCredentialChangeRequestInput' is not null or undefined
            assertParamExists(
                'passwordResetBenutzerItem',
                'benutzerCredentialChangeRequestInput',
                benutzerCredentialChangeRequestInput
            );
            const localVarPath = `/api/profile/password-reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                benutzerCredentialChangeRequestInput,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Benutzer resource.
         * @summary Updates the Benutzer resource.
         * @param {BenutzerCredentialChangeRequestInput} benutzerCredentialChangeRequestInput The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordResetRequestBenutzerItem: async (
            benutzerCredentialChangeRequestInput: BenutzerCredentialChangeRequestInput,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'benutzerCredentialChangeRequestInput' is not null or undefined
            assertParamExists(
                'passwordResetRequestBenutzerItem',
                'benutzerCredentialChangeRequestInput',
                benutzerCredentialChangeRequestInput
            );
            const localVarPath = `/api/profile/password-reset-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                benutzerCredentialChangeRequestInput,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Benutzer resource.
         * @summary Updates the Benutzer resource.
         * @param {string} id Resource identifier
         * @param {BenutzerProfileEdit} benutzerProfileEdit The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchBenutzerItem: async (
            id: string,
            benutzerProfileEdit: BenutzerProfileEdit,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchBenutzerItem', 'id', id);
            // verify required parameter 'benutzerProfileEdit' is not null or undefined
            assertParamExists('patchBenutzerItem', 'benutzerProfileEdit', benutzerProfileEdit);
            const localVarPath = `/api/benutzer/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                benutzerProfileEdit,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Benutzer resource.
         * @summary Creates a Benutzer resource.
         * @param {BenutzerJsonldBenutzerCreate} benutzerJsonldBenutzerCreate The new Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postBenutzerCollection: async (
            benutzerJsonldBenutzerCreate: BenutzerJsonldBenutzerCreate,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'benutzerJsonldBenutzerCreate' is not null or undefined
            assertParamExists('postBenutzerCollection', 'benutzerJsonldBenutzerCreate', benutzerJsonldBenutzerCreate);
            const localVarPath = `/api/benutzer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                benutzerJsonldBenutzerCreate,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Benutzer resource.
         * @summary Retrieves a Benutzer resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileBenutzerItem: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Benutzer resource.
         * @summary Replaces the Benutzer resource.
         * @param {string} id Resource identifier
         * @param {BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger} benutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putBenutzerItem: async (
            id: string,
            benutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger: BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putBenutzerItem', 'id', id);
            // verify required parameter 'benutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger' is not null or undefined
            assertParamExists(
                'putBenutzerItem',
                'benutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger',
                benutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
            );
            const localVarPath = `/api/benutzer/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                benutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Benutzer resource.
         * @summary Retrieves a Benutzer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleStatusBenutzerItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('toggleStatusBenutzerItem', 'id', id);
            const localVarPath = `/api/benutzer/{id}/toggle-status`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * BenutzerApi - functional programming interface
 * @export
 */
export const BenutzerApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = BenutzerApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves a Benutzer resource.
         * @summary Retrieves a Benutzer resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aufgabenBenutzerItem(
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenutzerAufgabenOutputJsonldBenutzerAufgaben>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aufgabenBenutzerItem(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Benutzer resource.
         * @summary Updates the Benutzer resource.
         * @param {BenutzerCredentialChangeConfirmInput} benutzerCredentialChangeConfirmInput The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmChangeBenutzerItem(
            benutzerCredentialChangeConfirmInput: BenutzerCredentialChangeConfirmInput,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmChangeBenutzerItem(
                benutzerCredentialChangeConfirmInput,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes the Benutzer resource.
         * @summary Removes the Benutzer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBenutzerItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBenutzerItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Benutzer resource.
         * @summary Updates the Benutzer resource.
         * @param {BenutzerCredentialChangeRequestInput} benutzerCredentialChangeRequestInput The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editEmailBenutzerItem(
            benutzerCredentialChangeRequestInput: BenutzerCredentialChangeRequestInput,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editEmailBenutzerItem(
                benutzerCredentialChangeRequestInput,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Benutzer resource.
         * @summary Retrieves a Benutzer resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllowedCreateRollenBenutzerItem(
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<BenutzerBenutzerGetAllowedCreateRollenOutputJsonldBenutzerGetAllowedCreateRollen>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllowedCreateRollenBenutzerItem(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Benutzer resources.
         * @summary Retrieves the collection of Benutzer resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [existsDeaktiviertAt]
         * @param {number} [id]
         * @param {Array<number>} [id2]
         * @param {string} [rolle]
         * @param {Array<string>} [rolle2]
         * @param {string} [landschaftsverband]
         * @param {Array<string>} [landschaftsverband2]
         * @param {string} [einrichtungBenutzerEinrichtung]
         * @param {Array<string>} [einrichtungBenutzerEinrichtung2]
         * @param {string} [traegerverband]
         * @param {Array<string>} [traegerverband2]
         * @param {Array<string>} [qSearch]
         * @param {Array<string>} [qImpersonateSearch]
         * @param {'asc' | 'desc'} [orderNachname]
         * @param {'asc' | 'desc'} [orderDeaktiviertAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBenutzerCollection(
            page?: number,
            itemsPerPage?: number,
            existsDeaktiviertAt?: boolean,
            id?: number,
            id2?: Array<number>,
            rolle?: string,
            rolle2?: Array<string>,
            landschaftsverband?: string,
            landschaftsverband2?: Array<string>,
            einrichtungBenutzerEinrichtung?: string,
            einrichtungBenutzerEinrichtung2?: Array<string>,
            traegerverband?: string,
            traegerverband2?: Array<string>,
            qSearch?: Array<string>,
            qImpersonateSearch?: Array<string>,
            orderNachname?: 'asc' | 'desc',
            orderDeaktiviertAt?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBenutzerCollection(
                page,
                itemsPerPage,
                existsDeaktiviertAt,
                id,
                id2,
                rolle,
                rolle2,
                landschaftsverband,
                landschaftsverband2,
                einrichtungBenutzerEinrichtung,
                einrichtungBenutzerEinrichtung2,
                traegerverband,
                traegerverband2,
                qSearch,
                qImpersonateSearch,
                orderNachname,
                orderDeaktiviertAt,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Benutzer resource.
         * @summary Retrieves a Benutzer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBenutzerItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBenutzerItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Benutzer resource.
         * @summary Retrieves a Benutzer resource.
         * @param {string} [email]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByEmailBenutzerItem(
            email?: string,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<BenutzerBenutzerGetByEmailOutputJsonldBenutzerGetByEmail>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByEmailBenutzerItem(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Benutzer resource.
         * @summary Retrieves a Benutzer resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lvAufgabenBenutzerItem(
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenutzerAufgabenOutputJsonldBenutzerLvAufgaben>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lvAufgabenBenutzerItem(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Benutzer resource.
         * @summary Updates the Benutzer resource.
         * @param {BenutzerCredentialChangeRequestInput} benutzerCredentialChangeRequestInput The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordResetBenutzerItem(
            benutzerCredentialChangeRequestInput: BenutzerCredentialChangeRequestInput,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordResetBenutzerItem(
                benutzerCredentialChangeRequestInput,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Benutzer resource.
         * @summary Updates the Benutzer resource.
         * @param {BenutzerCredentialChangeRequestInput} benutzerCredentialChangeRequestInput The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordResetRequestBenutzerItem(
            benutzerCredentialChangeRequestInput: BenutzerCredentialChangeRequestInput,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordResetRequestBenutzerItem(
                benutzerCredentialChangeRequestInput,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Benutzer resource.
         * @summary Updates the Benutzer resource.
         * @param {string} id Resource identifier
         * @param {BenutzerProfileEdit} benutzerProfileEdit The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchBenutzerItem(
            id: string,
            benutzerProfileEdit: BenutzerProfileEdit,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenutzerJsonldProfileEdit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchBenutzerItem(
                id,
                benutzerProfileEdit,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Benutzer resource.
         * @summary Creates a Benutzer resource.
         * @param {BenutzerJsonldBenutzerCreate} benutzerJsonldBenutzerCreate The new Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postBenutzerCollection(
            benutzerJsonldBenutzerCreate: BenutzerJsonldBenutzerCreate,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenutzerJsonldBenutzerCreate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postBenutzerCollection(
                benutzerJsonldBenutzerCreate,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Benutzer resource.
         * @summary Retrieves a Benutzer resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileBenutzerItem(
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenutzerJsonldProfileRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileBenutzerItem(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Benutzer resource.
         * @summary Replaces the Benutzer resource.
         * @param {string} id Resource identifier
         * @param {BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger} benutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putBenutzerItem(
            id: string,
            benutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger: BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putBenutzerItem(
                id,
                benutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Benutzer resource.
         * @summary Retrieves a Benutzer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async toggleStatusBenutzerItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.toggleStatusBenutzerItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * BenutzerApi - factory interface
 * @export
 */
export const BenutzerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BenutzerApiFp(configuration);
    return {
        /**
         * Retrieves a Benutzer resource.
         * @summary Retrieves a Benutzer resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aufgabenBenutzerItem(options?: any): AxiosPromise<BenutzerAufgabenOutputJsonldBenutzerAufgaben> {
            return localVarFp.aufgabenBenutzerItem(options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the Benutzer resource.
         * @summary Updates the Benutzer resource.
         * @param {BenutzerCredentialChangeConfirmInput} benutzerCredentialChangeConfirmInput The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmChangeBenutzerItem(
            benutzerCredentialChangeConfirmInput: BenutzerCredentialChangeConfirmInput,
            options?: any
        ): AxiosPromise<BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles> {
            return localVarFp
                .confirmChangeBenutzerItem(benutzerCredentialChangeConfirmInput, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Removes the Benutzer resource.
         * @summary Removes the Benutzer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBenutzerItem(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBenutzerItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the Benutzer resource.
         * @summary Updates the Benutzer resource.
         * @param {BenutzerCredentialChangeRequestInput} benutzerCredentialChangeRequestInput The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editEmailBenutzerItem(
            benutzerCredentialChangeRequestInput: BenutzerCredentialChangeRequestInput,
            options?: any
        ): AxiosPromise<BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles> {
            return localVarFp
                .editEmailBenutzerItem(benutzerCredentialChangeRequestInput, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Benutzer resource.
         * @summary Retrieves a Benutzer resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllowedCreateRollenBenutzerItem(
            options?: any
        ): AxiosPromise<BenutzerBenutzerGetAllowedCreateRollenOutputJsonldBenutzerGetAllowedCreateRollen> {
            return localVarFp.getAllowedCreateRollenBenutzerItem(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Benutzer resources.
         * @summary Retrieves the collection of Benutzer resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [existsDeaktiviertAt]
         * @param {number} [id]
         * @param {Array<number>} [id2]
         * @param {string} [rolle]
         * @param {Array<string>} [rolle2]
         * @param {string} [landschaftsverband]
         * @param {Array<string>} [landschaftsverband2]
         * @param {string} [einrichtungBenutzerEinrichtung]
         * @param {Array<string>} [einrichtungBenutzerEinrichtung2]
         * @param {string} [traegerverband]
         * @param {Array<string>} [traegerverband2]
         * @param {Array<string>} [qSearch]
         * @param {Array<string>} [qImpersonateSearch]
         * @param {'asc' | 'desc'} [orderNachname]
         * @param {'asc' | 'desc'} [orderDeaktiviertAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenutzerCollection(
            page?: number,
            itemsPerPage?: number,
            existsDeaktiviertAt?: boolean,
            id?: number,
            id2?: Array<number>,
            rolle?: string,
            rolle2?: Array<string>,
            landschaftsverband?: string,
            landschaftsverband2?: Array<string>,
            einrichtungBenutzerEinrichtung?: string,
            einrichtungBenutzerEinrichtung2?: Array<string>,
            traegerverband?: string,
            traegerverband2?: Array<string>,
            qSearch?: Array<string>,
            qImpersonateSearch?: Array<string>,
            orderNachname?: 'asc' | 'desc',
            orderDeaktiviertAt?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<InlineResponse2002> {
            return localVarFp
                .getBenutzerCollection(
                    page,
                    itemsPerPage,
                    existsDeaktiviertAt,
                    id,
                    id2,
                    rolle,
                    rolle2,
                    landschaftsverband,
                    landschaftsverband2,
                    einrichtungBenutzerEinrichtung,
                    einrichtungBenutzerEinrichtung2,
                    traegerverband,
                    traegerverband2,
                    qSearch,
                    qImpersonateSearch,
                    orderNachname,
                    orderDeaktiviertAt,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Benutzer resource.
         * @summary Retrieves a Benutzer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenutzerItem(
            id: string,
            options?: any
        ): AxiosPromise<BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles> {
            return localVarFp.getBenutzerItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Benutzer resource.
         * @summary Retrieves a Benutzer resource.
         * @param {string} [email]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByEmailBenutzerItem(
            email?: string,
            options?: any
        ): AxiosPromise<BenutzerBenutzerGetByEmailOutputJsonldBenutzerGetByEmail> {
            return localVarFp.getByEmailBenutzerItem(email, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Benutzer resource.
         * @summary Retrieves a Benutzer resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lvAufgabenBenutzerItem(options?: any): AxiosPromise<BenutzerAufgabenOutputJsonldBenutzerLvAufgaben> {
            return localVarFp.lvAufgabenBenutzerItem(options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the Benutzer resource.
         * @summary Updates the Benutzer resource.
         * @param {BenutzerCredentialChangeRequestInput} benutzerCredentialChangeRequestInput The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordResetBenutzerItem(
            benutzerCredentialChangeRequestInput: BenutzerCredentialChangeRequestInput,
            options?: any
        ): AxiosPromise<BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles> {
            return localVarFp
                .passwordResetBenutzerItem(benutzerCredentialChangeRequestInput, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Updates the Benutzer resource.
         * @summary Updates the Benutzer resource.
         * @param {BenutzerCredentialChangeRequestInput} benutzerCredentialChangeRequestInput The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordResetRequestBenutzerItem(
            benutzerCredentialChangeRequestInput: BenutzerCredentialChangeRequestInput,
            options?: any
        ): AxiosPromise<BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles> {
            return localVarFp
                .passwordResetRequestBenutzerItem(benutzerCredentialChangeRequestInput, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Updates the Benutzer resource.
         * @summary Updates the Benutzer resource.
         * @param {string} id Resource identifier
         * @param {BenutzerProfileEdit} benutzerProfileEdit The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchBenutzerItem(
            id: string,
            benutzerProfileEdit: BenutzerProfileEdit,
            options?: any
        ): AxiosPromise<BenutzerJsonldProfileEdit> {
            return localVarFp
                .patchBenutzerItem(id, benutzerProfileEdit, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Creates a Benutzer resource.
         * @summary Creates a Benutzer resource.
         * @param {BenutzerJsonldBenutzerCreate} benutzerJsonldBenutzerCreate The new Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postBenutzerCollection(
            benutzerJsonldBenutzerCreate: BenutzerJsonldBenutzerCreate,
            options?: any
        ): AxiosPromise<BenutzerJsonldBenutzerCreate> {
            return localVarFp
                .postBenutzerCollection(benutzerJsonldBenutzerCreate, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Benutzer resource.
         * @summary Retrieves a Benutzer resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileBenutzerItem(options?: any): AxiosPromise<BenutzerJsonldProfileRead> {
            return localVarFp.profileBenutzerItem(options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Benutzer resource.
         * @summary Replaces the Benutzer resource.
         * @param {string} id Resource identifier
         * @param {BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger} benutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putBenutzerItem(
            id: string,
            benutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger: BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger,
            options?: any
        ): AxiosPromise<BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger> {
            return localVarFp
                .putBenutzerItem(
                    id,
                    benutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Benutzer resource.
         * @summary Retrieves a Benutzer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleStatusBenutzerItem(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.toggleStatusBenutzerItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BenutzerApi - object-oriented interface
 * @export
 * @class BenutzerApi
 * @extends {BaseAPI}
 */
export class BenutzerApi extends BaseAPI {
    /**
     * Retrieves a Benutzer resource.
     * @summary Retrieves a Benutzer resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerApi
     */
    public aufgabenBenutzerItem(options?: AxiosRequestConfig) {
        return BenutzerApiFp(this.configuration)
            .aufgabenBenutzerItem(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Benutzer resource.
     * @summary Updates the Benutzer resource.
     * @param {BenutzerCredentialChangeConfirmInput} benutzerCredentialChangeConfirmInput The updated Benutzer resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerApi
     */
    public confirmChangeBenutzerItem(
        benutzerCredentialChangeConfirmInput: BenutzerCredentialChangeConfirmInput,
        options?: AxiosRequestConfig
    ) {
        return BenutzerApiFp(this.configuration)
            .confirmChangeBenutzerItem(benutzerCredentialChangeConfirmInput, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the Benutzer resource.
     * @summary Removes the Benutzer resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerApi
     */
    public deleteBenutzerItem(id: string, options?: AxiosRequestConfig) {
        return BenutzerApiFp(this.configuration)
            .deleteBenutzerItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Benutzer resource.
     * @summary Updates the Benutzer resource.
     * @param {BenutzerCredentialChangeRequestInput} benutzerCredentialChangeRequestInput The updated Benutzer resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerApi
     */
    public editEmailBenutzerItem(
        benutzerCredentialChangeRequestInput: BenutzerCredentialChangeRequestInput,
        options?: AxiosRequestConfig
    ) {
        return BenutzerApiFp(this.configuration)
            .editEmailBenutzerItem(benutzerCredentialChangeRequestInput, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Benutzer resource.
     * @summary Retrieves a Benutzer resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerApi
     */
    public getAllowedCreateRollenBenutzerItem(options?: AxiosRequestConfig) {
        return BenutzerApiFp(this.configuration)
            .getAllowedCreateRollenBenutzerItem(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Benutzer resources.
     * @summary Retrieves the collection of Benutzer resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [existsDeaktiviertAt]
     * @param {number} [id]
     * @param {Array<number>} [id2]
     * @param {string} [rolle]
     * @param {Array<string>} [rolle2]
     * @param {string} [landschaftsverband]
     * @param {Array<string>} [landschaftsverband2]
     * @param {string} [einrichtungBenutzerEinrichtung]
     * @param {Array<string>} [einrichtungBenutzerEinrichtung2]
     * @param {string} [traegerverband]
     * @param {Array<string>} [traegerverband2]
     * @param {Array<string>} [qSearch]
     * @param {Array<string>} [qImpersonateSearch]
     * @param {'asc' | 'desc'} [orderNachname]
     * @param {'asc' | 'desc'} [orderDeaktiviertAt]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerApi
     */
    public getBenutzerCollection(
        page?: number,
        itemsPerPage?: number,
        existsDeaktiviertAt?: boolean,
        id?: number,
        id2?: Array<number>,
        rolle?: string,
        rolle2?: Array<string>,
        landschaftsverband?: string,
        landschaftsverband2?: Array<string>,
        einrichtungBenutzerEinrichtung?: string,
        einrichtungBenutzerEinrichtung2?: Array<string>,
        traegerverband?: string,
        traegerverband2?: Array<string>,
        qSearch?: Array<string>,
        qImpersonateSearch?: Array<string>,
        orderNachname?: 'asc' | 'desc',
        orderDeaktiviertAt?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return BenutzerApiFp(this.configuration)
            .getBenutzerCollection(
                page,
                itemsPerPage,
                existsDeaktiviertAt,
                id,
                id2,
                rolle,
                rolle2,
                landschaftsverband,
                landschaftsverband2,
                einrichtungBenutzerEinrichtung,
                einrichtungBenutzerEinrichtung2,
                traegerverband,
                traegerverband2,
                qSearch,
                qImpersonateSearch,
                orderNachname,
                orderDeaktiviertAt,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Benutzer resource.
     * @summary Retrieves a Benutzer resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerApi
     */
    public getBenutzerItem(id: string, options?: AxiosRequestConfig) {
        return BenutzerApiFp(this.configuration)
            .getBenutzerItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Benutzer resource.
     * @summary Retrieves a Benutzer resource.
     * @param {string} [email]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerApi
     */
    public getByEmailBenutzerItem(email?: string, options?: AxiosRequestConfig) {
        return BenutzerApiFp(this.configuration)
            .getByEmailBenutzerItem(email, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Benutzer resource.
     * @summary Retrieves a Benutzer resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerApi
     */
    public lvAufgabenBenutzerItem(options?: AxiosRequestConfig) {
        return BenutzerApiFp(this.configuration)
            .lvAufgabenBenutzerItem(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Benutzer resource.
     * @summary Updates the Benutzer resource.
     * @param {BenutzerCredentialChangeRequestInput} benutzerCredentialChangeRequestInput The updated Benutzer resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerApi
     */
    public passwordResetBenutzerItem(
        benutzerCredentialChangeRequestInput: BenutzerCredentialChangeRequestInput,
        options?: AxiosRequestConfig
    ) {
        return BenutzerApiFp(this.configuration)
            .passwordResetBenutzerItem(benutzerCredentialChangeRequestInput, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Benutzer resource.
     * @summary Updates the Benutzer resource.
     * @param {BenutzerCredentialChangeRequestInput} benutzerCredentialChangeRequestInput The updated Benutzer resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerApi
     */
    public passwordResetRequestBenutzerItem(
        benutzerCredentialChangeRequestInput: BenutzerCredentialChangeRequestInput,
        options?: AxiosRequestConfig
    ) {
        return BenutzerApiFp(this.configuration)
            .passwordResetRequestBenutzerItem(benutzerCredentialChangeRequestInput, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Benutzer resource.
     * @summary Updates the Benutzer resource.
     * @param {string} id Resource identifier
     * @param {BenutzerProfileEdit} benutzerProfileEdit The updated Benutzer resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerApi
     */
    public patchBenutzerItem(id: string, benutzerProfileEdit: BenutzerProfileEdit, options?: AxiosRequestConfig) {
        return BenutzerApiFp(this.configuration)
            .patchBenutzerItem(id, benutzerProfileEdit, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Benutzer resource.
     * @summary Creates a Benutzer resource.
     * @param {BenutzerJsonldBenutzerCreate} benutzerJsonldBenutzerCreate The new Benutzer resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerApi
     */
    public postBenutzerCollection(
        benutzerJsonldBenutzerCreate: BenutzerJsonldBenutzerCreate,
        options?: AxiosRequestConfig
    ) {
        return BenutzerApiFp(this.configuration)
            .postBenutzerCollection(benutzerJsonldBenutzerCreate, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Benutzer resource.
     * @summary Retrieves a Benutzer resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerApi
     */
    public profileBenutzerItem(options?: AxiosRequestConfig) {
        return BenutzerApiFp(this.configuration)
            .profileBenutzerItem(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Benutzer resource.
     * @summary Replaces the Benutzer resource.
     * @param {string} id Resource identifier
     * @param {BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger} benutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger The updated Benutzer resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerApi
     */
    public putBenutzerItem(
        id: string,
        benutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger: BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger,
        options?: AxiosRequestConfig
    ) {
        return BenutzerApiFp(this.configuration)
            .putBenutzerItem(
                id,
                benutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Benutzer resource.
     * @summary Retrieves a Benutzer resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerApi
     */
    public toggleStatusBenutzerItem(id: string, options?: AxiosRequestConfig) {
        return BenutzerApiFp(this.configuration)
            .toggleStatusBenutzerItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * BenutzerTablePreferenceApi - axios parameter creator
 * @export
 */
export const BenutzerTablePreferenceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes the BenutzerTablePreference resource.
         * @summary Removes the BenutzerTablePreference resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBenutzerTablePreferenceItem: async (
            id: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteBenutzerTablePreferenceItem', 'id', id);
            const localVarPath = `/api/benutzer-table-preference/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of BenutzerTablePreference resources.
         * @summary Retrieves the collection of BenutzerTablePreference resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [name]
         * @param {Array<string>} [name2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenutzerTablePreferenceCollection: async (
            page?: number,
            itemsPerPage?: number,
            name?: string,
            name2?: Array<string>,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/benutzer-table-preference`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (name2) {
                localVarQueryParameter['name[]'] = name2;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a BenutzerTablePreference resource.
         * @summary Retrieves a BenutzerTablePreference resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenutzerTablePreferenceItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBenutzerTablePreferenceItem', 'id', id);
            const localVarPath = `/api/benutzer-table-preference/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a BenutzerTablePreference resource.
         * @summary Creates a BenutzerTablePreference resource.
         * @param {BenutzerTablePreferenceJsonld} benutzerTablePreferenceJsonld The new BenutzerTablePreference resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postBenutzerTablePreferenceCollection: async (
            benutzerTablePreferenceJsonld: BenutzerTablePreferenceJsonld,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'benutzerTablePreferenceJsonld' is not null or undefined
            assertParamExists(
                'postBenutzerTablePreferenceCollection',
                'benutzerTablePreferenceJsonld',
                benutzerTablePreferenceJsonld
            );
            const localVarPath = `/api/benutzer-table-preference`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                benutzerTablePreferenceJsonld,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the BenutzerTablePreference resource.
         * @summary Replaces the BenutzerTablePreference resource.
         * @param {string} id Resource identifier
         * @param {BenutzerTablePreferenceJsonld} benutzerTablePreferenceJsonld The updated BenutzerTablePreference resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putBenutzerTablePreferenceItem: async (
            id: string,
            benutzerTablePreferenceJsonld: BenutzerTablePreferenceJsonld,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putBenutzerTablePreferenceItem', 'id', id);
            // verify required parameter 'benutzerTablePreferenceJsonld' is not null or undefined
            assertParamExists(
                'putBenutzerTablePreferenceItem',
                'benutzerTablePreferenceJsonld',
                benutzerTablePreferenceJsonld
            );
            const localVarPath = `/api/benutzer-table-preference/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                benutzerTablePreferenceJsonld,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * BenutzerTablePreferenceApi - functional programming interface
 * @export
 */
export const BenutzerTablePreferenceApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = BenutzerTablePreferenceApiAxiosParamCreator(configuration);
    return {
        /**
         * Removes the BenutzerTablePreference resource.
         * @summary Removes the BenutzerTablePreference resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBenutzerTablePreferenceItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBenutzerTablePreferenceItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of BenutzerTablePreference resources.
         * @summary Retrieves the collection of BenutzerTablePreference resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [name]
         * @param {Array<string>} [name2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBenutzerTablePreferenceCollection(
            page?: number,
            itemsPerPage?: number,
            name?: string,
            name2?: Array<string>,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBenutzerTablePreferenceCollection(
                page,
                itemsPerPage,
                name,
                name2,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a BenutzerTablePreference resource.
         * @summary Retrieves a BenutzerTablePreference resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBenutzerTablePreferenceItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenutzerTablePreferenceJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBenutzerTablePreferenceItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a BenutzerTablePreference resource.
         * @summary Creates a BenutzerTablePreference resource.
         * @param {BenutzerTablePreferenceJsonld} benutzerTablePreferenceJsonld The new BenutzerTablePreference resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postBenutzerTablePreferenceCollection(
            benutzerTablePreferenceJsonld: BenutzerTablePreferenceJsonld,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenutzerTablePreferenceJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postBenutzerTablePreferenceCollection(
                benutzerTablePreferenceJsonld,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the BenutzerTablePreference resource.
         * @summary Replaces the BenutzerTablePreference resource.
         * @param {string} id Resource identifier
         * @param {BenutzerTablePreferenceJsonld} benutzerTablePreferenceJsonld The updated BenutzerTablePreference resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putBenutzerTablePreferenceItem(
            id: string,
            benutzerTablePreferenceJsonld: BenutzerTablePreferenceJsonld,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenutzerTablePreferenceJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putBenutzerTablePreferenceItem(
                id,
                benutzerTablePreferenceJsonld,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * BenutzerTablePreferenceApi - factory interface
 * @export
 */
export const BenutzerTablePreferenceApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = BenutzerTablePreferenceApiFp(configuration);
    return {
        /**
         * Removes the BenutzerTablePreference resource.
         * @summary Removes the BenutzerTablePreference resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBenutzerTablePreferenceItem(id: string, options?: any): AxiosPromise<void> {
            return localVarFp
                .deleteBenutzerTablePreferenceItem(id, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of BenutzerTablePreference resources.
         * @summary Retrieves the collection of BenutzerTablePreference resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [name]
         * @param {Array<string>} [name2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenutzerTablePreferenceCollection(
            page?: number,
            itemsPerPage?: number,
            name?: string,
            name2?: Array<string>,
            options?: any
        ): AxiosPromise<InlineResponse2003> {
            return localVarFp
                .getBenutzerTablePreferenceCollection(page, itemsPerPage, name, name2, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a BenutzerTablePreference resource.
         * @summary Retrieves a BenutzerTablePreference resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenutzerTablePreferenceItem(id: string, options?: any): AxiosPromise<BenutzerTablePreferenceJsonld> {
            return localVarFp.getBenutzerTablePreferenceItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a BenutzerTablePreference resource.
         * @summary Creates a BenutzerTablePreference resource.
         * @param {BenutzerTablePreferenceJsonld} benutzerTablePreferenceJsonld The new BenutzerTablePreference resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postBenutzerTablePreferenceCollection(
            benutzerTablePreferenceJsonld: BenutzerTablePreferenceJsonld,
            options?: any
        ): AxiosPromise<BenutzerTablePreferenceJsonld> {
            return localVarFp
                .postBenutzerTablePreferenceCollection(benutzerTablePreferenceJsonld, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Replaces the BenutzerTablePreference resource.
         * @summary Replaces the BenutzerTablePreference resource.
         * @param {string} id Resource identifier
         * @param {BenutzerTablePreferenceJsonld} benutzerTablePreferenceJsonld The updated BenutzerTablePreference resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putBenutzerTablePreferenceItem(
            id: string,
            benutzerTablePreferenceJsonld: BenutzerTablePreferenceJsonld,
            options?: any
        ): AxiosPromise<BenutzerTablePreferenceJsonld> {
            return localVarFp
                .putBenutzerTablePreferenceItem(id, benutzerTablePreferenceJsonld, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * BenutzerTablePreferenceApi - object-oriented interface
 * @export
 * @class BenutzerTablePreferenceApi
 * @extends {BaseAPI}
 */
export class BenutzerTablePreferenceApi extends BaseAPI {
    /**
     * Removes the BenutzerTablePreference resource.
     * @summary Removes the BenutzerTablePreference resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerTablePreferenceApi
     */
    public deleteBenutzerTablePreferenceItem(id: string, options?: AxiosRequestConfig) {
        return BenutzerTablePreferenceApiFp(this.configuration)
            .deleteBenutzerTablePreferenceItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of BenutzerTablePreference resources.
     * @summary Retrieves the collection of BenutzerTablePreference resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [name]
     * @param {Array<string>} [name2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerTablePreferenceApi
     */
    public getBenutzerTablePreferenceCollection(
        page?: number,
        itemsPerPage?: number,
        name?: string,
        name2?: Array<string>,
        options?: AxiosRequestConfig
    ) {
        return BenutzerTablePreferenceApiFp(this.configuration)
            .getBenutzerTablePreferenceCollection(page, itemsPerPage, name, name2, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a BenutzerTablePreference resource.
     * @summary Retrieves a BenutzerTablePreference resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerTablePreferenceApi
     */
    public getBenutzerTablePreferenceItem(id: string, options?: AxiosRequestConfig) {
        return BenutzerTablePreferenceApiFp(this.configuration)
            .getBenutzerTablePreferenceItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a BenutzerTablePreference resource.
     * @summary Creates a BenutzerTablePreference resource.
     * @param {BenutzerTablePreferenceJsonld} benutzerTablePreferenceJsonld The new BenutzerTablePreference resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerTablePreferenceApi
     */
    public postBenutzerTablePreferenceCollection(
        benutzerTablePreferenceJsonld: BenutzerTablePreferenceJsonld,
        options?: AxiosRequestConfig
    ) {
        return BenutzerTablePreferenceApiFp(this.configuration)
            .postBenutzerTablePreferenceCollection(benutzerTablePreferenceJsonld, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the BenutzerTablePreference resource.
     * @summary Replaces the BenutzerTablePreference resource.
     * @param {string} id Resource identifier
     * @param {BenutzerTablePreferenceJsonld} benutzerTablePreferenceJsonld The updated BenutzerTablePreference resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerTablePreferenceApi
     */
    public putBenutzerTablePreferenceItem(
        id: string,
        benutzerTablePreferenceJsonld: BenutzerTablePreferenceJsonld,
        options?: AxiosRequestConfig
    ) {
        return BenutzerTablePreferenceApiFp(this.configuration)
            .putBenutzerTablePreferenceItem(id, benutzerTablePreferenceJsonld, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * EigenkapitalzinsP5Abs6APGDVOApi - axios parameter creator
 * @export
 */
export const EigenkapitalzinsP5Abs6APGDVOApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of EigenkapitalzinsP5Abs6APGDVO resources.
         * @summary Retrieves the collection of EigenkapitalzinsP5Abs6APGDVO resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEigenkapitalzinsP5Abs6APGDVOCollection: async (
            page?: number,
            itemsPerPage?: number,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/eigenkapitalzins-p5-abs6-a-p-g-d-v-o`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a EigenkapitalzinsP5Abs6APGDVO resource.
         * @summary Retrieves a EigenkapitalzinsP5Abs6APGDVO resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEigenkapitalzinsP5Abs6APGDVOItem: async (
            id: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEigenkapitalzinsP5Abs6APGDVOItem', 'id', id);
            const localVarPath = `/api/eigenkapitalzins-p5-abs6-a-p-g-d-v-o/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a EigenkapitalzinsP5Abs6APGDVO resource.
         * @summary Creates a EigenkapitalzinsP5Abs6APGDVO resource.
         * @param {EigenkapitalzinsP5Abs6APGDVOJsonld} eigenkapitalzinsP5Abs6APGDVOJsonld The new EigenkapitalzinsP5Abs6APGDVO resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEigenkapitalzinsP5Abs6APGDVOCollection: async (
            eigenkapitalzinsP5Abs6APGDVOJsonld: EigenkapitalzinsP5Abs6APGDVOJsonld,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'eigenkapitalzinsP5Abs6APGDVOJsonld' is not null or undefined
            assertParamExists(
                'postEigenkapitalzinsP5Abs6APGDVOCollection',
                'eigenkapitalzinsP5Abs6APGDVOJsonld',
                eigenkapitalzinsP5Abs6APGDVOJsonld
            );
            const localVarPath = `/api/eigenkapitalzins-p5-abs6-a-p-g-d-v-o`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                eigenkapitalzinsP5Abs6APGDVOJsonld,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the EigenkapitalzinsP5Abs6APGDVO resource.
         * @summary Replaces the EigenkapitalzinsP5Abs6APGDVO resource.
         * @param {string} id Resource identifier
         * @param {EigenkapitalzinsP5Abs6APGDVOJsonld} eigenkapitalzinsP5Abs6APGDVOJsonld The updated EigenkapitalzinsP5Abs6APGDVO resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEigenkapitalzinsP5Abs6APGDVOItem: async (
            id: string,
            eigenkapitalzinsP5Abs6APGDVOJsonld: EigenkapitalzinsP5Abs6APGDVOJsonld,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putEigenkapitalzinsP5Abs6APGDVOItem', 'id', id);
            // verify required parameter 'eigenkapitalzinsP5Abs6APGDVOJsonld' is not null or undefined
            assertParamExists(
                'putEigenkapitalzinsP5Abs6APGDVOItem',
                'eigenkapitalzinsP5Abs6APGDVOJsonld',
                eigenkapitalzinsP5Abs6APGDVOJsonld
            );
            const localVarPath = `/api/eigenkapitalzins-p5-abs6-a-p-g-d-v-o/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                eigenkapitalzinsP5Abs6APGDVOJsonld,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * EigenkapitalzinsP5Abs6APGDVOApi - functional programming interface
 * @export
 */
export const EigenkapitalzinsP5Abs6APGDVOApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = EigenkapitalzinsP5Abs6APGDVOApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of EigenkapitalzinsP5Abs6APGDVO resources.
         * @summary Retrieves the collection of EigenkapitalzinsP5Abs6APGDVO resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEigenkapitalzinsP5Abs6APGDVOCollection(
            page?: number,
            itemsPerPage?: number,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2004>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEigenkapitalzinsP5Abs6APGDVOCollection(
                page,
                itemsPerPage,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a EigenkapitalzinsP5Abs6APGDVO resource.
         * @summary Retrieves a EigenkapitalzinsP5Abs6APGDVO resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEigenkapitalzinsP5Abs6APGDVOItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EigenkapitalzinsP5Abs6APGDVOJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEigenkapitalzinsP5Abs6APGDVOItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a EigenkapitalzinsP5Abs6APGDVO resource.
         * @summary Creates a EigenkapitalzinsP5Abs6APGDVO resource.
         * @param {EigenkapitalzinsP5Abs6APGDVOJsonld} eigenkapitalzinsP5Abs6APGDVOJsonld The new EigenkapitalzinsP5Abs6APGDVO resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postEigenkapitalzinsP5Abs6APGDVOCollection(
            eigenkapitalzinsP5Abs6APGDVOJsonld: EigenkapitalzinsP5Abs6APGDVOJsonld,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EigenkapitalzinsP5Abs6APGDVOJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postEigenkapitalzinsP5Abs6APGDVOCollection(
                eigenkapitalzinsP5Abs6APGDVOJsonld,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the EigenkapitalzinsP5Abs6APGDVO resource.
         * @summary Replaces the EigenkapitalzinsP5Abs6APGDVO resource.
         * @param {string} id Resource identifier
         * @param {EigenkapitalzinsP5Abs6APGDVOJsonld} eigenkapitalzinsP5Abs6APGDVOJsonld The updated EigenkapitalzinsP5Abs6APGDVO resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putEigenkapitalzinsP5Abs6APGDVOItem(
            id: string,
            eigenkapitalzinsP5Abs6APGDVOJsonld: EigenkapitalzinsP5Abs6APGDVOJsonld,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EigenkapitalzinsP5Abs6APGDVOJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putEigenkapitalzinsP5Abs6APGDVOItem(
                id,
                eigenkapitalzinsP5Abs6APGDVOJsonld,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * EigenkapitalzinsP5Abs6APGDVOApi - factory interface
 * @export
 */
export const EigenkapitalzinsP5Abs6APGDVOApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = EigenkapitalzinsP5Abs6APGDVOApiFp(configuration);
    return {
        /**
         * Retrieves the collection of EigenkapitalzinsP5Abs6APGDVO resources.
         * @summary Retrieves the collection of EigenkapitalzinsP5Abs6APGDVO resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEigenkapitalzinsP5Abs6APGDVOCollection(
            page?: number,
            itemsPerPage?: number,
            options?: any
        ): AxiosPromise<InlineResponse2004> {
            return localVarFp
                .getEigenkapitalzinsP5Abs6APGDVOCollection(page, itemsPerPage, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a EigenkapitalzinsP5Abs6APGDVO resource.
         * @summary Retrieves a EigenkapitalzinsP5Abs6APGDVO resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEigenkapitalzinsP5Abs6APGDVOItem(
            id: string,
            options?: any
        ): AxiosPromise<EigenkapitalzinsP5Abs6APGDVOJsonld> {
            return localVarFp
                .getEigenkapitalzinsP5Abs6APGDVOItem(id, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Creates a EigenkapitalzinsP5Abs6APGDVO resource.
         * @summary Creates a EigenkapitalzinsP5Abs6APGDVO resource.
         * @param {EigenkapitalzinsP5Abs6APGDVOJsonld} eigenkapitalzinsP5Abs6APGDVOJsonld The new EigenkapitalzinsP5Abs6APGDVO resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEigenkapitalzinsP5Abs6APGDVOCollection(
            eigenkapitalzinsP5Abs6APGDVOJsonld: EigenkapitalzinsP5Abs6APGDVOJsonld,
            options?: any
        ): AxiosPromise<EigenkapitalzinsP5Abs6APGDVOJsonld> {
            return localVarFp
                .postEigenkapitalzinsP5Abs6APGDVOCollection(eigenkapitalzinsP5Abs6APGDVOJsonld, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Replaces the EigenkapitalzinsP5Abs6APGDVO resource.
         * @summary Replaces the EigenkapitalzinsP5Abs6APGDVO resource.
         * @param {string} id Resource identifier
         * @param {EigenkapitalzinsP5Abs6APGDVOJsonld} eigenkapitalzinsP5Abs6APGDVOJsonld The updated EigenkapitalzinsP5Abs6APGDVO resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEigenkapitalzinsP5Abs6APGDVOItem(
            id: string,
            eigenkapitalzinsP5Abs6APGDVOJsonld: EigenkapitalzinsP5Abs6APGDVOJsonld,
            options?: any
        ): AxiosPromise<EigenkapitalzinsP5Abs6APGDVOJsonld> {
            return localVarFp
                .putEigenkapitalzinsP5Abs6APGDVOItem(id, eigenkapitalzinsP5Abs6APGDVOJsonld, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * EigenkapitalzinsP5Abs6APGDVOApi - object-oriented interface
 * @export
 * @class EigenkapitalzinsP5Abs6APGDVOApi
 * @extends {BaseAPI}
 */
export class EigenkapitalzinsP5Abs6APGDVOApi extends BaseAPI {
    /**
     * Retrieves the collection of EigenkapitalzinsP5Abs6APGDVO resources.
     * @summary Retrieves the collection of EigenkapitalzinsP5Abs6APGDVO resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EigenkapitalzinsP5Abs6APGDVOApi
     */
    public getEigenkapitalzinsP5Abs6APGDVOCollection(
        page?: number,
        itemsPerPage?: number,
        options?: AxiosRequestConfig
    ) {
        return EigenkapitalzinsP5Abs6APGDVOApiFp(this.configuration)
            .getEigenkapitalzinsP5Abs6APGDVOCollection(page, itemsPerPage, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a EigenkapitalzinsP5Abs6APGDVO resource.
     * @summary Retrieves a EigenkapitalzinsP5Abs6APGDVO resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EigenkapitalzinsP5Abs6APGDVOApi
     */
    public getEigenkapitalzinsP5Abs6APGDVOItem(id: string, options?: AxiosRequestConfig) {
        return EigenkapitalzinsP5Abs6APGDVOApiFp(this.configuration)
            .getEigenkapitalzinsP5Abs6APGDVOItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a EigenkapitalzinsP5Abs6APGDVO resource.
     * @summary Creates a EigenkapitalzinsP5Abs6APGDVO resource.
     * @param {EigenkapitalzinsP5Abs6APGDVOJsonld} eigenkapitalzinsP5Abs6APGDVOJsonld The new EigenkapitalzinsP5Abs6APGDVO resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EigenkapitalzinsP5Abs6APGDVOApi
     */
    public postEigenkapitalzinsP5Abs6APGDVOCollection(
        eigenkapitalzinsP5Abs6APGDVOJsonld: EigenkapitalzinsP5Abs6APGDVOJsonld,
        options?: AxiosRequestConfig
    ) {
        return EigenkapitalzinsP5Abs6APGDVOApiFp(this.configuration)
            .postEigenkapitalzinsP5Abs6APGDVOCollection(eigenkapitalzinsP5Abs6APGDVOJsonld, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the EigenkapitalzinsP5Abs6APGDVO resource.
     * @summary Replaces the EigenkapitalzinsP5Abs6APGDVO resource.
     * @param {string} id Resource identifier
     * @param {EigenkapitalzinsP5Abs6APGDVOJsonld} eigenkapitalzinsP5Abs6APGDVOJsonld The updated EigenkapitalzinsP5Abs6APGDVO resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EigenkapitalzinsP5Abs6APGDVOApi
     */
    public putEigenkapitalzinsP5Abs6APGDVOItem(
        id: string,
        eigenkapitalzinsP5Abs6APGDVOJsonld: EigenkapitalzinsP5Abs6APGDVOJsonld,
        options?: AxiosRequestConfig
    ) {
        return EigenkapitalzinsP5Abs6APGDVOApiFp(this.configuration)
            .putEigenkapitalzinsP5Abs6APGDVOItem(id, eigenkapitalzinsP5Abs6APGDVOJsonld, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * EigenkapitalzinsenBundesbankApi - axios parameter creator
 * @export
 */
export const EigenkapitalzinsenBundesbankApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of EigenkapitalzinsenBundesbank resources.
         * @summary Retrieves the collection of EigenkapitalzinsenBundesbank resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEigenkapitalzinsenBundesbankCollection: async (
            page?: number,
            itemsPerPage?: number,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/eigenkapitalzinsen-bundesbank`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a EigenkapitalzinsenBundesbank resource.
         * @summary Retrieves a EigenkapitalzinsenBundesbank resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEigenkapitalzinsenBundesbankItem: async (
            id: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEigenkapitalzinsenBundesbankItem', 'id', id);
            const localVarPath = `/api/eigenkapitalzinsen-bundesbank/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * EigenkapitalzinsenBundesbankApi - functional programming interface
 * @export
 */
export const EigenkapitalzinsenBundesbankApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = EigenkapitalzinsenBundesbankApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of EigenkapitalzinsenBundesbank resources.
         * @summary Retrieves the collection of EigenkapitalzinsenBundesbank resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEigenkapitalzinsenBundesbankCollection(
            page?: number,
            itemsPerPage?: number,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2005>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEigenkapitalzinsenBundesbankCollection(
                page,
                itemsPerPage,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a EigenkapitalzinsenBundesbank resource.
         * @summary Retrieves a EigenkapitalzinsenBundesbank resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEigenkapitalzinsenBundesbankItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EigenkapitalzinsenBundesbankJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEigenkapitalzinsenBundesbankItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * EigenkapitalzinsenBundesbankApi - factory interface
 * @export
 */
export const EigenkapitalzinsenBundesbankApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = EigenkapitalzinsenBundesbankApiFp(configuration);
    return {
        /**
         * Retrieves the collection of EigenkapitalzinsenBundesbank resources.
         * @summary Retrieves the collection of EigenkapitalzinsenBundesbank resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEigenkapitalzinsenBundesbankCollection(
            page?: number,
            itemsPerPage?: number,
            options?: any
        ): AxiosPromise<InlineResponse2005> {
            return localVarFp
                .getEigenkapitalzinsenBundesbankCollection(page, itemsPerPage, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a EigenkapitalzinsenBundesbank resource.
         * @summary Retrieves a EigenkapitalzinsenBundesbank resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEigenkapitalzinsenBundesbankItem(
            id: string,
            options?: any
        ): AxiosPromise<EigenkapitalzinsenBundesbankJsonld> {
            return localVarFp
                .getEigenkapitalzinsenBundesbankItem(id, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * EigenkapitalzinsenBundesbankApi - object-oriented interface
 * @export
 * @class EigenkapitalzinsenBundesbankApi
 * @extends {BaseAPI}
 */
export class EigenkapitalzinsenBundesbankApi extends BaseAPI {
    /**
     * Retrieves the collection of EigenkapitalzinsenBundesbank resources.
     * @summary Retrieves the collection of EigenkapitalzinsenBundesbank resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EigenkapitalzinsenBundesbankApi
     */
    public getEigenkapitalzinsenBundesbankCollection(
        page?: number,
        itemsPerPage?: number,
        options?: AxiosRequestConfig
    ) {
        return EigenkapitalzinsenBundesbankApiFp(this.configuration)
            .getEigenkapitalzinsenBundesbankCollection(page, itemsPerPage, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a EigenkapitalzinsenBundesbank resource.
     * @summary Retrieves a EigenkapitalzinsenBundesbank resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EigenkapitalzinsenBundesbankApi
     */
    public getEigenkapitalzinsenBundesbankItem(id: string, options?: AxiosRequestConfig) {
        return EigenkapitalzinsenBundesbankApiFp(this.configuration)
            .getEigenkapitalzinsenBundesbankItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * EinrichtungApi - axios parameter creator
 * @export
 */
export const EinrichtungApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a Einrichtung resource.
         * @summary Retrieves a Einrichtung resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        antragAnbieterPermissionsEinrichtungItem: async (
            id: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('antragAnbieterPermissionsEinrichtungItem', 'id', id);
            const localVarPath = `/api/einrichtung/{id}/antrag_anbieter_permissions`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Einrichtung resource.
         * @summary Retrieves a Einrichtung resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        existsEinrichtungItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('existsEinrichtungItem', 'id', id);
            const localVarPath = `/api/einrichtung/{id}/exists`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Einrichtung resource.
         * @summary Retrieves a Einrichtung resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filtersEinrichtungItem: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/filters/einrichtung`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Einrichtung resources.
         * @summary Retrieves the collection of Einrichtung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [id]
         * @param {Array<number>} [id2]
         * @param {string} [art]
         * @param {Array<string>} [art2]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [traeger]
         * @param {Array<string>} [traeger2]
         * @param {string} [traegerverband]
         * @param {Array<string>} [traegerverband2]
         * @param {Array<string>} [qSearch]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {Array<number>} [anbieterBenutzer]
         * @param {'asc' | 'desc'} [orderAktenzeichen]
         * @param {'asc' | 'desc'} [orderNrwKey]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderOrt]
         * @param {'asc' | 'desc'} [orderArt]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {string} [createdAtBefore]
         * @param {string} [createdAtStrictlyBefore]
         * @param {string} [createdAtAfter]
         * @param {string} [createdAtStrictlyAfter]
         * @param {'asc' | 'desc'} [orderZustaendigerBenutzerName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEinrichtungCollection: async (
            page?: number,
            itemsPerPage?: number,
            id?: number,
            id2?: Array<number>,
            art?: string,
            art2?: Array<string>,
            status?: string,
            status2?: Array<string>,
            traeger?: string,
            traeger2?: Array<string>,
            traegerverband?: string,
            traegerverband2?: Array<string>,
            qSearch?: Array<string>,
            zustaendigerBenutzer?: Array<number>,
            anbieterBenutzer?: Array<number>,
            orderAktenzeichen?: 'asc' | 'desc',
            orderNrwKey?: 'asc' | 'desc',
            orderName?: 'asc' | 'desc',
            orderOrt?: 'asc' | 'desc',
            orderArt?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            createdAtBefore?: string,
            createdAtStrictlyBefore?: string,
            createdAtAfter?: string,
            createdAtStrictlyAfter?: string,
            orderZustaendigerBenutzerName?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/einrichtung`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (id2) {
                localVarQueryParameter['id[]'] = id2;
            }

            if (art !== undefined) {
                localVarQueryParameter['art'] = art;
            }

            if (art2) {
                localVarQueryParameter['art[]'] = art2;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (status2) {
                localVarQueryParameter['status[]'] = status2;
            }

            if (traeger !== undefined) {
                localVarQueryParameter['traeger'] = traeger;
            }

            if (traeger2) {
                localVarQueryParameter['traeger[]'] = traeger2;
            }

            if (traegerverband !== undefined) {
                localVarQueryParameter['traegerverband'] = traegerverband;
            }

            if (traegerverband2) {
                localVarQueryParameter['traegerverband[]'] = traegerverband2;
            }

            if (qSearch) {
                localVarQueryParameter['qSearch[]'] = qSearch;
            }

            if (zustaendigerBenutzer) {
                localVarQueryParameter['zustaendigerBenutzer[]'] = zustaendigerBenutzer;
            }

            if (anbieterBenutzer) {
                localVarQueryParameter['anbieterBenutzer[]'] = anbieterBenutzer;
            }

            if (orderAktenzeichen !== undefined) {
                localVarQueryParameter['order[aktenzeichen]'] = orderAktenzeichen;
            }

            if (orderNrwKey !== undefined) {
                localVarQueryParameter['order[nrwKey]'] = orderNrwKey;
            }

            if (orderName !== undefined) {
                localVarQueryParameter['order[name]'] = orderName;
            }

            if (orderOrt !== undefined) {
                localVarQueryParameter['order[ort]'] = orderOrt;
            }

            if (orderArt !== undefined) {
                localVarQueryParameter['order[art]'] = orderArt;
            }

            if (orderStatus !== undefined) {
                localVarQueryParameter['order[status]'] = orderStatus;
            }

            if (createdAtBefore !== undefined) {
                localVarQueryParameter['createdAt[before]'] = createdAtBefore;
            }

            if (createdAtStrictlyBefore !== undefined) {
                localVarQueryParameter['createdAt[strictly_before]'] = createdAtStrictlyBefore;
            }

            if (createdAtAfter !== undefined) {
                localVarQueryParameter['createdAt[after]'] = createdAtAfter;
            }

            if (createdAtStrictlyAfter !== undefined) {
                localVarQueryParameter['createdAt[strictly_after]'] = createdAtStrictlyAfter;
            }

            if (orderZustaendigerBenutzerName !== undefined) {
                localVarQueryParameter['order[zustaendigerBenutzer.name]'] = orderZustaendigerBenutzerName;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Einrichtung resource.
         * @summary Retrieves a Einrichtung resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEinrichtungItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEinrichtungItem', 'id', id);
            const localVarPath = `/api/einrichtung/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Einrichtung resource.
         * @summary Retrieves a Einrichtung resource.
         * @param {number} benutzer
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isAntragstellerEinrichtungItem: async (
            benutzer: number,
            id: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'benutzer' is not null or undefined
            assertParamExists('isAntragstellerEinrichtungItem', 'benutzer', benutzer);
            // verify required parameter 'id' is not null or undefined
            assertParamExists('isAntragstellerEinrichtungItem', 'id', id);
            const localVarPath = `/api/einrichtung/{id}/is_antragsteller`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (benutzer !== undefined) {
                localVarQueryParameter['benutzer'] = benutzer;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Einrichtung resource.
         * @summary Updates the Einrichtung resource.
         * @param {string} id Resource identifier
         * @param {EinrichtungEinrichtungEdit} einrichtungEinrichtungEdit The updated Einrichtung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchEinrichtungItem: async (
            id: string,
            einrichtungEinrichtungEdit: EinrichtungEinrichtungEdit,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchEinrichtungItem', 'id', id);
            // verify required parameter 'einrichtungEinrichtungEdit' is not null or undefined
            assertParamExists('patchEinrichtungItem', 'einrichtungEinrichtungEdit', einrichtungEinrichtungEdit);
            const localVarPath = `/api/einrichtung/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                einrichtungEinrichtungEdit,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Einrichtung resource.
         * @summary Replaces the Einrichtung resource.
         * @param {string} id Resource identifier
         * @param {EinrichtungJsonldEinrichtungWrite} einrichtungJsonldEinrichtungWrite The updated Einrichtung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEinrichtungItem: async (
            id: string,
            einrichtungJsonldEinrichtungWrite: EinrichtungJsonldEinrichtungWrite,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putEinrichtungItem', 'id', id);
            // verify required parameter 'einrichtungJsonldEinrichtungWrite' is not null or undefined
            assertParamExists(
                'putEinrichtungItem',
                'einrichtungJsonldEinrichtungWrite',
                einrichtungJsonldEinrichtungWrite
            );
            const localVarPath = `/api/einrichtung/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                einrichtungJsonldEinrichtungWrite,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Einrichtung resource.
         * @summary Retrieves a Einrichtung resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reaktivierenEinrichtungItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reaktivierenEinrichtungItem', 'id', id);
            const localVarPath = `/api/einrichtung/{id}/reaktivieren`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Einrichtung resource.
         * @summary Retrieves a Einrichtung resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshEinrichtungItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('refreshEinrichtungItem', 'id', id);
            const localVarPath = `/api/einrichtung/{id}/refresh`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Einrichtung resource.
         * @summary Replaces the Einrichtung resource.
         * @param {string} id Resource identifier
         * @param {EinrichtungJsonldEinrichtungZustaendigerBenutzer} einrichtungJsonldEinrichtungZustaendigerBenutzer The updated Einrichtung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setZustaendigerBenutzerEinrichtungItem: async (
            id: string,
            einrichtungJsonldEinrichtungZustaendigerBenutzer: EinrichtungJsonldEinrichtungZustaendigerBenutzer,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setZustaendigerBenutzerEinrichtungItem', 'id', id);
            // verify required parameter 'einrichtungJsonldEinrichtungZustaendigerBenutzer' is not null or undefined
            assertParamExists(
                'setZustaendigerBenutzerEinrichtungItem',
                'einrichtungJsonldEinrichtungZustaendigerBenutzer',
                einrichtungJsonldEinrichtungZustaendigerBenutzer
            );
            const localVarPath = `/api/einrichtung/{id}/set_zustaendiger_benutzer`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                einrichtungJsonldEinrichtungZustaendigerBenutzer,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * EinrichtungApi - functional programming interface
 * @export
 */
export const EinrichtungApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = EinrichtungApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves a Einrichtung resource.
         * @summary Retrieves a Einrichtung resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async antragAnbieterPermissionsEinrichtungItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<EinrichtungEinrichtungAntragPermissionsOutputJsonldEinrichtungAntragAnbieterPermissions>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.antragAnbieterPermissionsEinrichtungItem(
                id,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Einrichtung resource.
         * @summary Retrieves a Einrichtung resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async existsEinrichtungItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<EinrichtungEinrichtungExistsOutputJsonldEinrichtungExists>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.existsEinrichtungItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Einrichtung resource.
         * @summary Retrieves a Einrichtung resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filtersEinrichtungItem(
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<EinrichtungCollectionFiltersOutputJsonldCollectionFilters>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filtersEinrichtungItem(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Einrichtung resources.
         * @summary Retrieves the collection of Einrichtung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [id]
         * @param {Array<number>} [id2]
         * @param {string} [art]
         * @param {Array<string>} [art2]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [traeger]
         * @param {Array<string>} [traeger2]
         * @param {string} [traegerverband]
         * @param {Array<string>} [traegerverband2]
         * @param {Array<string>} [qSearch]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {Array<number>} [anbieterBenutzer]
         * @param {'asc' | 'desc'} [orderAktenzeichen]
         * @param {'asc' | 'desc'} [orderNrwKey]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderOrt]
         * @param {'asc' | 'desc'} [orderArt]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {string} [createdAtBefore]
         * @param {string} [createdAtStrictlyBefore]
         * @param {string} [createdAtAfter]
         * @param {string} [createdAtStrictlyAfter]
         * @param {'asc' | 'desc'} [orderZustaendigerBenutzerName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEinrichtungCollection(
            page?: number,
            itemsPerPage?: number,
            id?: number,
            id2?: Array<number>,
            art?: string,
            art2?: Array<string>,
            status?: string,
            status2?: Array<string>,
            traeger?: string,
            traeger2?: Array<string>,
            traegerverband?: string,
            traegerverband2?: Array<string>,
            qSearch?: Array<string>,
            zustaendigerBenutzer?: Array<number>,
            anbieterBenutzer?: Array<number>,
            orderAktenzeichen?: 'asc' | 'desc',
            orderNrwKey?: 'asc' | 'desc',
            orderName?: 'asc' | 'desc',
            orderOrt?: 'asc' | 'desc',
            orderArt?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            createdAtBefore?: string,
            createdAtStrictlyBefore?: string,
            createdAtAfter?: string,
            createdAtStrictlyAfter?: string,
            orderZustaendigerBenutzerName?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2006>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEinrichtungCollection(
                page,
                itemsPerPage,
                id,
                id2,
                art,
                art2,
                status,
                status2,
                traeger,
                traeger2,
                traegerverband,
                traegerverband2,
                qSearch,
                zustaendigerBenutzer,
                anbieterBenutzer,
                orderAktenzeichen,
                orderNrwKey,
                orderName,
                orderOrt,
                orderArt,
                orderStatus,
                createdAtBefore,
                createdAtStrictlyBefore,
                createdAtAfter,
                createdAtStrictlyAfter,
                orderZustaendigerBenutzerName,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Einrichtung resource.
         * @summary Retrieves a Einrichtung resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEinrichtungItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EinrichtungJsonldEinrichtungRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEinrichtungItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Einrichtung resource.
         * @summary Retrieves a Einrichtung resource.
         * @param {number} benutzer
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isAntragstellerEinrichtungItem(
            benutzer: number,
            id: string,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<EinrichtungEinrichtungExistsOutputJsonldEinrichtungIsAntragsteller>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isAntragstellerEinrichtungItem(
                benutzer,
                id,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Einrichtung resource.
         * @summary Updates the Einrichtung resource.
         * @param {string} id Resource identifier
         * @param {EinrichtungEinrichtungEdit} einrichtungEinrichtungEdit The updated Einrichtung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchEinrichtungItem(
            id: string,
            einrichtungEinrichtungEdit: EinrichtungEinrichtungEdit,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchEinrichtungItem(
                id,
                einrichtungEinrichtungEdit,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Einrichtung resource.
         * @summary Replaces the Einrichtung resource.
         * @param {string} id Resource identifier
         * @param {EinrichtungJsonldEinrichtungWrite} einrichtungJsonldEinrichtungWrite The updated Einrichtung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putEinrichtungItem(
            id: string,
            einrichtungJsonldEinrichtungWrite: EinrichtungJsonldEinrichtungWrite,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EinrichtungJsonldEinrichtungRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putEinrichtungItem(
                id,
                einrichtungJsonldEinrichtungWrite,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Einrichtung resource.
         * @summary Retrieves a Einrichtung resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reaktivierenEinrichtungItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EinrichtungJsonldEinrichtungRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reaktivierenEinrichtungItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Einrichtung resource.
         * @summary Retrieves a Einrichtung resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshEinrichtungItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshEinrichtungItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Einrichtung resource.
         * @summary Replaces the Einrichtung resource.
         * @param {string} id Resource identifier
         * @param {EinrichtungJsonldEinrichtungZustaendigerBenutzer} einrichtungJsonldEinrichtungZustaendigerBenutzer The updated Einrichtung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setZustaendigerBenutzerEinrichtungItem(
            id: string,
            einrichtungJsonldEinrichtungZustaendigerBenutzer: EinrichtungJsonldEinrichtungZustaendigerBenutzer,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setZustaendigerBenutzerEinrichtungItem(
                id,
                einrichtungJsonldEinrichtungZustaendigerBenutzer,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * EinrichtungApi - factory interface
 * @export
 */
export const EinrichtungApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = EinrichtungApiFp(configuration);
    return {
        /**
         * Retrieves a Einrichtung resource.
         * @summary Retrieves a Einrichtung resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        antragAnbieterPermissionsEinrichtungItem(
            id: string,
            options?: any
        ): AxiosPromise<EinrichtungEinrichtungAntragPermissionsOutputJsonldEinrichtungAntragAnbieterPermissions> {
            return localVarFp
                .antragAnbieterPermissionsEinrichtungItem(id, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Einrichtung resource.
         * @summary Retrieves a Einrichtung resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        existsEinrichtungItem(
            id: string,
            options?: any
        ): AxiosPromise<EinrichtungEinrichtungExistsOutputJsonldEinrichtungExists> {
            return localVarFp.existsEinrichtungItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Einrichtung resource.
         * @summary Retrieves a Einrichtung resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filtersEinrichtungItem(options?: any): AxiosPromise<EinrichtungCollectionFiltersOutputJsonldCollectionFilters> {
            return localVarFp.filtersEinrichtungItem(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Einrichtung resources.
         * @summary Retrieves the collection of Einrichtung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [id]
         * @param {Array<number>} [id2]
         * @param {string} [art]
         * @param {Array<string>} [art2]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [traeger]
         * @param {Array<string>} [traeger2]
         * @param {string} [traegerverband]
         * @param {Array<string>} [traegerverband2]
         * @param {Array<string>} [qSearch]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {Array<number>} [anbieterBenutzer]
         * @param {'asc' | 'desc'} [orderAktenzeichen]
         * @param {'asc' | 'desc'} [orderNrwKey]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderOrt]
         * @param {'asc' | 'desc'} [orderArt]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {string} [createdAtBefore]
         * @param {string} [createdAtStrictlyBefore]
         * @param {string} [createdAtAfter]
         * @param {string} [createdAtStrictlyAfter]
         * @param {'asc' | 'desc'} [orderZustaendigerBenutzerName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEinrichtungCollection(
            page?: number,
            itemsPerPage?: number,
            id?: number,
            id2?: Array<number>,
            art?: string,
            art2?: Array<string>,
            status?: string,
            status2?: Array<string>,
            traeger?: string,
            traeger2?: Array<string>,
            traegerverband?: string,
            traegerverband2?: Array<string>,
            qSearch?: Array<string>,
            zustaendigerBenutzer?: Array<number>,
            anbieterBenutzer?: Array<number>,
            orderAktenzeichen?: 'asc' | 'desc',
            orderNrwKey?: 'asc' | 'desc',
            orderName?: 'asc' | 'desc',
            orderOrt?: 'asc' | 'desc',
            orderArt?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            createdAtBefore?: string,
            createdAtStrictlyBefore?: string,
            createdAtAfter?: string,
            createdAtStrictlyAfter?: string,
            orderZustaendigerBenutzerName?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<InlineResponse2006> {
            return localVarFp
                .getEinrichtungCollection(
                    page,
                    itemsPerPage,
                    id,
                    id2,
                    art,
                    art2,
                    status,
                    status2,
                    traeger,
                    traeger2,
                    traegerverband,
                    traegerverband2,
                    qSearch,
                    zustaendigerBenutzer,
                    anbieterBenutzer,
                    orderAktenzeichen,
                    orderNrwKey,
                    orderName,
                    orderOrt,
                    orderArt,
                    orderStatus,
                    createdAtBefore,
                    createdAtStrictlyBefore,
                    createdAtAfter,
                    createdAtStrictlyAfter,
                    orderZustaendigerBenutzerName,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Einrichtung resource.
         * @summary Retrieves a Einrichtung resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEinrichtungItem(id: string, options?: any): AxiosPromise<EinrichtungJsonldEinrichtungRead> {
            return localVarFp.getEinrichtungItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Einrichtung resource.
         * @summary Retrieves a Einrichtung resource.
         * @param {number} benutzer
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isAntragstellerEinrichtungItem(
            benutzer: number,
            id: string,
            options?: any
        ): AxiosPromise<EinrichtungEinrichtungExistsOutputJsonldEinrichtungIsAntragsteller> {
            return localVarFp
                .isAntragstellerEinrichtungItem(benutzer, id, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Updates the Einrichtung resource.
         * @summary Updates the Einrichtung resource.
         * @param {string} id Resource identifier
         * @param {EinrichtungEinrichtungEdit} einrichtungEinrichtungEdit The updated Einrichtung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchEinrichtungItem(
            id: string,
            einrichtungEinrichtungEdit: EinrichtungEinrichtungEdit,
            options?: any
        ): AxiosPromise<any> {
            return localVarFp
                .patchEinrichtungItem(id, einrichtungEinrichtungEdit, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Einrichtung resource.
         * @summary Replaces the Einrichtung resource.
         * @param {string} id Resource identifier
         * @param {EinrichtungJsonldEinrichtungWrite} einrichtungJsonldEinrichtungWrite The updated Einrichtung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEinrichtungItem(
            id: string,
            einrichtungJsonldEinrichtungWrite: EinrichtungJsonldEinrichtungWrite,
            options?: any
        ): AxiosPromise<EinrichtungJsonldEinrichtungRead> {
            return localVarFp
                .putEinrichtungItem(id, einrichtungJsonldEinrichtungWrite, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Einrichtung resource.
         * @summary Retrieves a Einrichtung resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reaktivierenEinrichtungItem(id: string, options?: any): AxiosPromise<EinrichtungJsonldEinrichtungRead> {
            return localVarFp.reaktivierenEinrichtungItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Einrichtung resource.
         * @summary Retrieves a Einrichtung resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshEinrichtungItem(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.refreshEinrichtungItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Einrichtung resource.
         * @summary Replaces the Einrichtung resource.
         * @param {string} id Resource identifier
         * @param {EinrichtungJsonldEinrichtungZustaendigerBenutzer} einrichtungJsonldEinrichtungZustaendigerBenutzer The updated Einrichtung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setZustaendigerBenutzerEinrichtungItem(
            id: string,
            einrichtungJsonldEinrichtungZustaendigerBenutzer: EinrichtungJsonldEinrichtungZustaendigerBenutzer,
            options?: any
        ): AxiosPromise<any> {
            return localVarFp
                .setZustaendigerBenutzerEinrichtungItem(id, einrichtungJsonldEinrichtungZustaendigerBenutzer, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * EinrichtungApi - object-oriented interface
 * @export
 * @class EinrichtungApi
 * @extends {BaseAPI}
 */
export class EinrichtungApi extends BaseAPI {
    /**
     * Retrieves a Einrichtung resource.
     * @summary Retrieves a Einrichtung resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungApi
     */
    public antragAnbieterPermissionsEinrichtungItem(id: string, options?: AxiosRequestConfig) {
        return EinrichtungApiFp(this.configuration)
            .antragAnbieterPermissionsEinrichtungItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Einrichtung resource.
     * @summary Retrieves a Einrichtung resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungApi
     */
    public existsEinrichtungItem(id: string, options?: AxiosRequestConfig) {
        return EinrichtungApiFp(this.configuration)
            .existsEinrichtungItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Einrichtung resource.
     * @summary Retrieves a Einrichtung resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungApi
     */
    public filtersEinrichtungItem(options?: AxiosRequestConfig) {
        return EinrichtungApiFp(this.configuration)
            .filtersEinrichtungItem(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Einrichtung resources.
     * @summary Retrieves the collection of Einrichtung resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {number} [id]
     * @param {Array<number>} [id2]
     * @param {string} [art]
     * @param {Array<string>} [art2]
     * @param {string} [status]
     * @param {Array<string>} [status2]
     * @param {string} [traeger]
     * @param {Array<string>} [traeger2]
     * @param {string} [traegerverband]
     * @param {Array<string>} [traegerverband2]
     * @param {Array<string>} [qSearch]
     * @param {Array<number>} [zustaendigerBenutzer]
     * @param {Array<number>} [anbieterBenutzer]
     * @param {'asc' | 'desc'} [orderAktenzeichen]
     * @param {'asc' | 'desc'} [orderNrwKey]
     * @param {'asc' | 'desc'} [orderName]
     * @param {'asc' | 'desc'} [orderOrt]
     * @param {'asc' | 'desc'} [orderArt]
     * @param {'asc' | 'desc'} [orderStatus]
     * @param {string} [createdAtBefore]
     * @param {string} [createdAtStrictlyBefore]
     * @param {string} [createdAtAfter]
     * @param {string} [createdAtStrictlyAfter]
     * @param {'asc' | 'desc'} [orderZustaendigerBenutzerName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungApi
     */
    public getEinrichtungCollection(
        page?: number,
        itemsPerPage?: number,
        id?: number,
        id2?: Array<number>,
        art?: string,
        art2?: Array<string>,
        status?: string,
        status2?: Array<string>,
        traeger?: string,
        traeger2?: Array<string>,
        traegerverband?: string,
        traegerverband2?: Array<string>,
        qSearch?: Array<string>,
        zustaendigerBenutzer?: Array<number>,
        anbieterBenutzer?: Array<number>,
        orderAktenzeichen?: 'asc' | 'desc',
        orderNrwKey?: 'asc' | 'desc',
        orderName?: 'asc' | 'desc',
        orderOrt?: 'asc' | 'desc',
        orderArt?: 'asc' | 'desc',
        orderStatus?: 'asc' | 'desc',
        createdAtBefore?: string,
        createdAtStrictlyBefore?: string,
        createdAtAfter?: string,
        createdAtStrictlyAfter?: string,
        orderZustaendigerBenutzerName?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return EinrichtungApiFp(this.configuration)
            .getEinrichtungCollection(
                page,
                itemsPerPage,
                id,
                id2,
                art,
                art2,
                status,
                status2,
                traeger,
                traeger2,
                traegerverband,
                traegerverband2,
                qSearch,
                zustaendigerBenutzer,
                anbieterBenutzer,
                orderAktenzeichen,
                orderNrwKey,
                orderName,
                orderOrt,
                orderArt,
                orderStatus,
                createdAtBefore,
                createdAtStrictlyBefore,
                createdAtAfter,
                createdAtStrictlyAfter,
                orderZustaendigerBenutzerName,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Einrichtung resource.
     * @summary Retrieves a Einrichtung resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungApi
     */
    public getEinrichtungItem(id: string, options?: AxiosRequestConfig) {
        return EinrichtungApiFp(this.configuration)
            .getEinrichtungItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Einrichtung resource.
     * @summary Retrieves a Einrichtung resource.
     * @param {number} benutzer
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungApi
     */
    public isAntragstellerEinrichtungItem(benutzer: number, id: string, options?: AxiosRequestConfig) {
        return EinrichtungApiFp(this.configuration)
            .isAntragstellerEinrichtungItem(benutzer, id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Einrichtung resource.
     * @summary Updates the Einrichtung resource.
     * @param {string} id Resource identifier
     * @param {EinrichtungEinrichtungEdit} einrichtungEinrichtungEdit The updated Einrichtung resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungApi
     */
    public patchEinrichtungItem(
        id: string,
        einrichtungEinrichtungEdit: EinrichtungEinrichtungEdit,
        options?: AxiosRequestConfig
    ) {
        return EinrichtungApiFp(this.configuration)
            .patchEinrichtungItem(id, einrichtungEinrichtungEdit, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Einrichtung resource.
     * @summary Replaces the Einrichtung resource.
     * @param {string} id Resource identifier
     * @param {EinrichtungJsonldEinrichtungWrite} einrichtungJsonldEinrichtungWrite The updated Einrichtung resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungApi
     */
    public putEinrichtungItem(
        id: string,
        einrichtungJsonldEinrichtungWrite: EinrichtungJsonldEinrichtungWrite,
        options?: AxiosRequestConfig
    ) {
        return EinrichtungApiFp(this.configuration)
            .putEinrichtungItem(id, einrichtungJsonldEinrichtungWrite, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Einrichtung resource.
     * @summary Retrieves a Einrichtung resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungApi
     */
    public reaktivierenEinrichtungItem(id: string, options?: AxiosRequestConfig) {
        return EinrichtungApiFp(this.configuration)
            .reaktivierenEinrichtungItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Einrichtung resource.
     * @summary Retrieves a Einrichtung resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungApi
     */
    public refreshEinrichtungItem(id: string, options?: AxiosRequestConfig) {
        return EinrichtungApiFp(this.configuration)
            .refreshEinrichtungItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Einrichtung resource.
     * @summary Replaces the Einrichtung resource.
     * @param {string} id Resource identifier
     * @param {EinrichtungJsonldEinrichtungZustaendigerBenutzer} einrichtungJsonldEinrichtungZustaendigerBenutzer The updated Einrichtung resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungApi
     */
    public setZustaendigerBenutzerEinrichtungItem(
        id: string,
        einrichtungJsonldEinrichtungZustaendigerBenutzer: EinrichtungJsonldEinrichtungZustaendigerBenutzer,
        options?: AxiosRequestConfig
    ) {
        return EinrichtungApiFp(this.configuration)
            .setZustaendigerBenutzerEinrichtungItem(id, einrichtungJsonldEinrichtungZustaendigerBenutzer, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * EinrichtungBenutzerApi - axios parameter creator
 * @export
 */
export const EinrichtungBenutzerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes the EinrichtungBenutzer resource.
         * @summary Removes the EinrichtungBenutzer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEinrichtungBenutzerItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteEinrichtungBenutzerItem', 'id', id);
            const localVarPath = `/api/einrichtung-benutzer/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of EinrichtungBenutzer resources.
         * @summary Retrieves the collection of EinrichtungBenutzer resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [benutzer]
         * @param {Array<string>} [benutzer2]
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEinrichtungBenutzerCollection: async (
            page?: number,
            itemsPerPage?: number,
            benutzer?: string,
            benutzer2?: Array<string>,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/einrichtung-benutzer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (benutzer !== undefined) {
                localVarQueryParameter['benutzer'] = benutzer;
            }

            if (benutzer2) {
                localVarQueryParameter['benutzer[]'] = benutzer2;
            }

            if (einrichtung !== undefined) {
                localVarQueryParameter['einrichtung'] = einrichtung;
            }

            if (einrichtung2) {
                localVarQueryParameter['einrichtung[]'] = einrichtung2;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a EinrichtungBenutzer resource.
         * @summary Retrieves a EinrichtungBenutzer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEinrichtungBenutzerItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEinrichtungBenutzerItem', 'id', id);
            const localVarPath = `/api/einrichtung-benutzer/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a EinrichtungBenutzer resource.
         * @summary Creates a EinrichtungBenutzer resource.
         * @param {EinrichtungBenutzerJsonldEinrichtungBenutzerCreate} einrichtungBenutzerJsonldEinrichtungBenutzerCreate The new EinrichtungBenutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEinrichtungBenutzerCollection: async (
            einrichtungBenutzerJsonldEinrichtungBenutzerCreate: EinrichtungBenutzerJsonldEinrichtungBenutzerCreate,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'einrichtungBenutzerJsonldEinrichtungBenutzerCreate' is not null or undefined
            assertParamExists(
                'postEinrichtungBenutzerCollection',
                'einrichtungBenutzerJsonldEinrichtungBenutzerCreate',
                einrichtungBenutzerJsonldEinrichtungBenutzerCreate
            );
            const localVarPath = `/api/einrichtung-benutzer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                einrichtungBenutzerJsonldEinrichtungBenutzerCreate,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the EinrichtungBenutzer resource.
         * @summary Replaces the EinrichtungBenutzer resource.
         * @param {string} id Resource identifier
         * @param {EinrichtungBenutzerJsonldEinrichtungBenutzerCreate} einrichtungBenutzerJsonldEinrichtungBenutzerCreate The updated EinrichtungBenutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEinrichtungBenutzerItem: async (
            id: string,
            einrichtungBenutzerJsonldEinrichtungBenutzerCreate: EinrichtungBenutzerJsonldEinrichtungBenutzerCreate,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putEinrichtungBenutzerItem', 'id', id);
            // verify required parameter 'einrichtungBenutzerJsonldEinrichtungBenutzerCreate' is not null or undefined
            assertParamExists(
                'putEinrichtungBenutzerItem',
                'einrichtungBenutzerJsonldEinrichtungBenutzerCreate',
                einrichtungBenutzerJsonldEinrichtungBenutzerCreate
            );
            const localVarPath = `/api/einrichtung-benutzer/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                einrichtungBenutzerJsonldEinrichtungBenutzerCreate,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * EinrichtungBenutzerApi - functional programming interface
 * @export
 */
export const EinrichtungBenutzerApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = EinrichtungBenutzerApiAxiosParamCreator(configuration);
    return {
        /**
         * Removes the EinrichtungBenutzer resource.
         * @summary Removes the EinrichtungBenutzer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEinrichtungBenutzerItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEinrichtungBenutzerItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of EinrichtungBenutzer resources.
         * @summary Retrieves the collection of EinrichtungBenutzer resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [benutzer]
         * @param {Array<string>} [benutzer2]
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEinrichtungBenutzerCollection(
            page?: number,
            itemsPerPage?: number,
            benutzer?: string,
            benutzer2?: Array<string>,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2007>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEinrichtungBenutzerCollection(
                page,
                itemsPerPage,
                benutzer,
                benutzer2,
                einrichtung,
                einrichtung2,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a EinrichtungBenutzer resource.
         * @summary Retrieves a EinrichtungBenutzer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEinrichtungBenutzerItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EinrichtungBenutzerJsonldEinrichtungBenutzerRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEinrichtungBenutzerItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a EinrichtungBenutzer resource.
         * @summary Creates a EinrichtungBenutzer resource.
         * @param {EinrichtungBenutzerJsonldEinrichtungBenutzerCreate} einrichtungBenutzerJsonldEinrichtungBenutzerCreate The new EinrichtungBenutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postEinrichtungBenutzerCollection(
            einrichtungBenutzerJsonldEinrichtungBenutzerCreate: EinrichtungBenutzerJsonldEinrichtungBenutzerCreate,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<EinrichtungBenutzerJsonldEinrichtungBenutzerCreate>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postEinrichtungBenutzerCollection(
                einrichtungBenutzerJsonldEinrichtungBenutzerCreate,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the EinrichtungBenutzer resource.
         * @summary Replaces the EinrichtungBenutzer resource.
         * @param {string} id Resource identifier
         * @param {EinrichtungBenutzerJsonldEinrichtungBenutzerCreate} einrichtungBenutzerJsonldEinrichtungBenutzerCreate The updated EinrichtungBenutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putEinrichtungBenutzerItem(
            id: string,
            einrichtungBenutzerJsonldEinrichtungBenutzerCreate: EinrichtungBenutzerJsonldEinrichtungBenutzerCreate,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<EinrichtungBenutzerJsonldEinrichtungBenutzerCreate>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putEinrichtungBenutzerItem(
                id,
                einrichtungBenutzerJsonldEinrichtungBenutzerCreate,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * EinrichtungBenutzerApi - factory interface
 * @export
 */
export const EinrichtungBenutzerApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = EinrichtungBenutzerApiFp(configuration);
    return {
        /**
         * Removes the EinrichtungBenutzer resource.
         * @summary Removes the EinrichtungBenutzer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEinrichtungBenutzerItem(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteEinrichtungBenutzerItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of EinrichtungBenutzer resources.
         * @summary Retrieves the collection of EinrichtungBenutzer resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [benutzer]
         * @param {Array<string>} [benutzer2]
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEinrichtungBenutzerCollection(
            page?: number,
            itemsPerPage?: number,
            benutzer?: string,
            benutzer2?: Array<string>,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            options?: any
        ): AxiosPromise<InlineResponse2007> {
            return localVarFp
                .getEinrichtungBenutzerCollection(
                    page,
                    itemsPerPage,
                    benutzer,
                    benutzer2,
                    einrichtung,
                    einrichtung2,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a EinrichtungBenutzer resource.
         * @summary Retrieves a EinrichtungBenutzer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEinrichtungBenutzerItem(
            id: string,
            options?: any
        ): AxiosPromise<EinrichtungBenutzerJsonldEinrichtungBenutzerRead> {
            return localVarFp.getEinrichtungBenutzerItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a EinrichtungBenutzer resource.
         * @summary Creates a EinrichtungBenutzer resource.
         * @param {EinrichtungBenutzerJsonldEinrichtungBenutzerCreate} einrichtungBenutzerJsonldEinrichtungBenutzerCreate The new EinrichtungBenutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEinrichtungBenutzerCollection(
            einrichtungBenutzerJsonldEinrichtungBenutzerCreate: EinrichtungBenutzerJsonldEinrichtungBenutzerCreate,
            options?: any
        ): AxiosPromise<EinrichtungBenutzerJsonldEinrichtungBenutzerCreate> {
            return localVarFp
                .postEinrichtungBenutzerCollection(einrichtungBenutzerJsonldEinrichtungBenutzerCreate, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Replaces the EinrichtungBenutzer resource.
         * @summary Replaces the EinrichtungBenutzer resource.
         * @param {string} id Resource identifier
         * @param {EinrichtungBenutzerJsonldEinrichtungBenutzerCreate} einrichtungBenutzerJsonldEinrichtungBenutzerCreate The updated EinrichtungBenutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEinrichtungBenutzerItem(
            id: string,
            einrichtungBenutzerJsonldEinrichtungBenutzerCreate: EinrichtungBenutzerJsonldEinrichtungBenutzerCreate,
            options?: any
        ): AxiosPromise<EinrichtungBenutzerJsonldEinrichtungBenutzerCreate> {
            return localVarFp
                .putEinrichtungBenutzerItem(id, einrichtungBenutzerJsonldEinrichtungBenutzerCreate, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * EinrichtungBenutzerApi - object-oriented interface
 * @export
 * @class EinrichtungBenutzerApi
 * @extends {BaseAPI}
 */
export class EinrichtungBenutzerApi extends BaseAPI {
    /**
     * Removes the EinrichtungBenutzer resource.
     * @summary Removes the EinrichtungBenutzer resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungBenutzerApi
     */
    public deleteEinrichtungBenutzerItem(id: string, options?: AxiosRequestConfig) {
        return EinrichtungBenutzerApiFp(this.configuration)
            .deleteEinrichtungBenutzerItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of EinrichtungBenutzer resources.
     * @summary Retrieves the collection of EinrichtungBenutzer resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [benutzer]
     * @param {Array<string>} [benutzer2]
     * @param {string} [einrichtung]
     * @param {Array<string>} [einrichtung2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungBenutzerApi
     */
    public getEinrichtungBenutzerCollection(
        page?: number,
        itemsPerPage?: number,
        benutzer?: string,
        benutzer2?: Array<string>,
        einrichtung?: string,
        einrichtung2?: Array<string>,
        options?: AxiosRequestConfig
    ) {
        return EinrichtungBenutzerApiFp(this.configuration)
            .getEinrichtungBenutzerCollection(
                page,
                itemsPerPage,
                benutzer,
                benutzer2,
                einrichtung,
                einrichtung2,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a EinrichtungBenutzer resource.
     * @summary Retrieves a EinrichtungBenutzer resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungBenutzerApi
     */
    public getEinrichtungBenutzerItem(id: string, options?: AxiosRequestConfig) {
        return EinrichtungBenutzerApiFp(this.configuration)
            .getEinrichtungBenutzerItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a EinrichtungBenutzer resource.
     * @summary Creates a EinrichtungBenutzer resource.
     * @param {EinrichtungBenutzerJsonldEinrichtungBenutzerCreate} einrichtungBenutzerJsonldEinrichtungBenutzerCreate The new EinrichtungBenutzer resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungBenutzerApi
     */
    public postEinrichtungBenutzerCollection(
        einrichtungBenutzerJsonldEinrichtungBenutzerCreate: EinrichtungBenutzerJsonldEinrichtungBenutzerCreate,
        options?: AxiosRequestConfig
    ) {
        return EinrichtungBenutzerApiFp(this.configuration)
            .postEinrichtungBenutzerCollection(einrichtungBenutzerJsonldEinrichtungBenutzerCreate, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the EinrichtungBenutzer resource.
     * @summary Replaces the EinrichtungBenutzer resource.
     * @param {string} id Resource identifier
     * @param {EinrichtungBenutzerJsonldEinrichtungBenutzerCreate} einrichtungBenutzerJsonldEinrichtungBenutzerCreate The updated EinrichtungBenutzer resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungBenutzerApi
     */
    public putEinrichtungBenutzerItem(
        id: string,
        einrichtungBenutzerJsonldEinrichtungBenutzerCreate: EinrichtungBenutzerJsonldEinrichtungBenutzerCreate,
        options?: AxiosRequestConfig
    ) {
        return EinrichtungBenutzerApiFp(this.configuration)
            .putEinrichtungBenutzerItem(id, einrichtungBenutzerJsonldEinrichtungBenutzerCreate, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * EmailApi - axios parameter creator
 * @export
 */
export const EmailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of Email resources.
         * @summary Retrieves the collection of Email resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailCollection: async (
            page?: number,
            itemsPerPage?: number,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Email resource.
         * @summary Retrieves a Email resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEmailItem', 'id', id);
            const localVarPath = `/api/email/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * EmailApi - functional programming interface
 * @export
 */
export const EmailApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = EmailApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of Email resources.
         * @summary Retrieves the collection of Email resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmailCollection(
            page?: number,
            itemsPerPage?: number,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2008>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmailCollection(page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Email resource.
         * @summary Retrieves a Email resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmailItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailJsonldEmailReadEmailReadEmail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmailItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * EmailApi - factory interface
 * @export
 */
export const EmailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmailApiFp(configuration);
    return {
        /**
         * Retrieves the collection of Email resources.
         * @summary Retrieves the collection of Email resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailCollection(page?: number, itemsPerPage?: number, options?: any): AxiosPromise<InlineResponse2008> {
            return localVarFp
                .getEmailCollection(page, itemsPerPage, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Email resource.
         * @summary Retrieves a Email resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailItem(id: string, options?: any): AxiosPromise<EmailJsonldEmailReadEmailReadEmail> {
            return localVarFp.getEmailItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmailApi - object-oriented interface
 * @export
 * @class EmailApi
 * @extends {BaseAPI}
 */
export class EmailApi extends BaseAPI {
    /**
     * Retrieves the collection of Email resources.
     * @summary Retrieves the collection of Email resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public getEmailCollection(page?: number, itemsPerPage?: number, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration)
            .getEmailCollection(page, itemsPerPage, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Email resource.
     * @summary Retrieves a Email resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public getEmailItem(id: string, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration)
            .getEmailItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * FestsetzungsantragApi - axios parameter creator
 * @export
 */
export const FestsetzungsantragApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes the Festsetzungsantrag resource.
         * @summary Removes the Festsetzungsantrag resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFestsetzungsantragItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFestsetzungsantragItem', 'id', id);
            const localVarPath = `/api/festsetzungsantrag/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Festsetzungsantrag resource.
         * @summary Retrieves a Festsetzungsantrag resource.
         * @param {number} v2
         * @param {string} id Resource identifier
         * @param {number} [v1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        diffFestsetzungsantragItem: async (
            v2: number,
            id: string,
            v1?: number,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'v2' is not null or undefined
            assertParamExists('diffFestsetzungsantragItem', 'v2', v2);
            // verify required parameter 'id' is not null or undefined
            assertParamExists('diffFestsetzungsantragItem', 'id', id);
            const localVarPath = `/api/festsetzungsantrag/{id}/diff`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (v1 !== undefined) {
                localVarQueryParameter['v1'] = v1;
            }

            if (v2 !== undefined) {
                localVarQueryParameter['v2'] = v2;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Festsetzungsantrag resource.
         * @summary Retrieves a Festsetzungsantrag resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filtersFestsetzungsantragItem: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/filters/festsetzungsantrag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Festsetzungsantrag resources.
         * @summary Retrieves the collection of Festsetzungsantrag resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {Array<string>} [qSearch]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {Array<string>} [berechnungsart]
         * @param {Array<string>} [antragJahr]
         * @param {string} [aufgabe]
         * @param {'asc' | 'desc'} [orderZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderEinrichtungAktenzeichen]
         * @param {'asc' | 'desc'} [orderEinrichtungName]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderEinrichtungZustaendigerBenutzerName]
         * @param {'asc' | 'desc'} [orderField500]
         * @param {'asc' | 'desc'} [orderFeststellungsantragBerechnungsart]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFestsetzungsantragCollection: async (
            page?: number,
            itemsPerPage?: number,
            qSearch?: Array<string>,
            zustaendigerBenutzer?: Array<number>,
            status?: string,
            status2?: Array<string>,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            berechnungsart?: Array<string>,
            antragJahr?: Array<string>,
            aufgabe?: string,
            orderZuletztEingereichtAt?: 'asc' | 'desc',
            orderEinrichtungAktenzeichen?: 'asc' | 'desc',
            orderEinrichtungName?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderEinrichtungZustaendigerBenutzerName?: 'asc' | 'desc',
            orderField500?: 'asc' | 'desc',
            orderFeststellungsantragBerechnungsart?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/festsetzungsantrag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (qSearch) {
                localVarQueryParameter['qSearch[]'] = qSearch;
            }

            if (zustaendigerBenutzer) {
                localVarQueryParameter['zustaendigerBenutzer[]'] = zustaendigerBenutzer;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (status2) {
                localVarQueryParameter['status[]'] = status2;
            }

            if (einrichtung !== undefined) {
                localVarQueryParameter['einrichtung'] = einrichtung;
            }

            if (einrichtung2) {
                localVarQueryParameter['einrichtung[]'] = einrichtung2;
            }

            if (berechnungsart) {
                localVarQueryParameter['berechnungsart[]'] = berechnungsart;
            }

            if (antragJahr) {
                localVarQueryParameter['antragJahr[]'] = antragJahr;
            }

            if (aufgabe !== undefined) {
                localVarQueryParameter['aufgabe'] = aufgabe;
            }

            if (orderZuletztEingereichtAt !== undefined) {
                localVarQueryParameter['order[zuletztEingereichtAt]'] = orderZuletztEingereichtAt;
            }

            if (orderEinrichtungAktenzeichen !== undefined) {
                localVarQueryParameter['order[einrichtung.aktenzeichen]'] = orderEinrichtungAktenzeichen;
            }

            if (orderEinrichtungName !== undefined) {
                localVarQueryParameter['order[einrichtung.name]'] = orderEinrichtungName;
            }

            if (orderStatus !== undefined) {
                localVarQueryParameter['order[status]'] = orderStatus;
            }

            if (orderEinrichtungZustaendigerBenutzerName !== undefined) {
                localVarQueryParameter['order[einrichtung.zustaendigerBenutzer.name]'] =
                    orderEinrichtungZustaendigerBenutzerName;
            }

            if (orderField500 !== undefined) {
                localVarQueryParameter['order[field500]'] = orderField500;
            }

            if (orderFeststellungsantragBerechnungsart !== undefined) {
                localVarQueryParameter['order[feststellungsantrag.berechnungsart]'] =
                    orderFeststellungsantragBerechnungsart;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Festsetzungsantrag resource.
         * @summary Retrieves a Festsetzungsantrag resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFestsetzungsantragItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFestsetzungsantragItem', 'id', id);
            const localVarPath = `/api/festsetzungsantrag/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the Festsetzungsantrag resource.
         * @summary Removes the Festsetzungsantrag resource.
         * @param {string} id Resource identifier
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jsonFormDeleteFestsetzungsantragItem: async (
            id: string,
            uuid?: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('jsonFormDeleteFestsetzungsantragItem', 'id', id);
            const localVarPath = `/api/festsetzungsantrag/{id}/json/{uuid}`
                .replace(`{${'uuid'}}`, encodeURIComponent(String(uuid)))
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Festsetzungsantrag resource.
         * @summary Retrieves a Festsetzungsantrag resource.
         * @param {string} id Resource identifier
         * @param {string} [step]
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jsonFormGetFestsetzungsantragItem: async (
            id: string,
            step?: string,
            objectType?: string,
            uuid?: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('jsonFormGetFestsetzungsantragItem', 'id', id);
            const localVarPath = `/api/festsetzungsantrag/{id}/json/{step}`
                .replace(`{${'step'}}`, encodeURIComponent(String(step)))
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (objectType !== undefined) {
                localVarQueryParameter['objectType'] = objectType;
            }

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Festsetzungsantrag resource.
         * @summary Updates the Festsetzungsantrag resource.
         * @param {string} id Resource identifier
         * @param {object} body The updated Festsetzungsantrag resource
         * @param {string} [step]
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {boolean} [persist]
         * @param {Array<string>} [fields]
         * @param {boolean} [recalculateFields]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jsonFormPatchFestsetzungsantragItem: async (
            id: string,
            body: object,
            step?: string,
            objectType?: string,
            uuid?: string,
            persist?: boolean,
            fields?: Array<string>,
            recalculateFields?: boolean,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('jsonFormPatchFestsetzungsantragItem', 'id', id);
            // verify required parameter 'body' is not null or undefined
            assertParamExists('jsonFormPatchFestsetzungsantragItem', 'body', body);
            const localVarPath = `/api/festsetzungsantrag/{id}/json/{step}`
                .replace(`{${'step'}}`, encodeURIComponent(String(step)))
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (objectType !== undefined) {
                localVarQueryParameter['objectType'] = objectType;
            }

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }

            if (persist !== undefined) {
                localVarQueryParameter['persist'] = persist;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (recalculateFields !== undefined) {
                localVarQueryParameter['recalculateFields'] = recalculateFields;
            }

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Festsetzungsantrag resource.
         * @summary Creates a Festsetzungsantrag resource.
         * @param {FestsetzungsantragJsonldFestsetzungsantragCreate} festsetzungsantragJsonldFestsetzungsantragCreate The new Festsetzungsantrag resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFestsetzungsantragCollection: async (
            festsetzungsantragJsonldFestsetzungsantragCreate: FestsetzungsantragJsonldFestsetzungsantragCreate,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'festsetzungsantragJsonldFestsetzungsantragCreate' is not null or undefined
            assertParamExists(
                'postFestsetzungsantragCollection',
                'festsetzungsantragJsonldFestsetzungsantragCreate',
                festsetzungsantragJsonldFestsetzungsantragCreate
            );
            const localVarPath = `/api/festsetzungsantrag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                festsetzungsantragJsonldFestsetzungsantragCreate,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Festsetzungsantrag resource.
         * @summary Retrieves a Festsetzungsantrag resource.
         * @param {number} id
         * @param {string} action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workflowFestsetzungsantragItem: async (
            id: number,
            action: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('workflowFestsetzungsantragItem', 'id', id);
            // verify required parameter 'action' is not null or undefined
            assertParamExists('workflowFestsetzungsantragItem', 'action', action);
            const localVarPath = `/api/festsetzungsantrag/{id}/workflow/{action}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'action'}}`, encodeURIComponent(String(action)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * FestsetzungsantragApi - functional programming interface
 * @export
 */
export const FestsetzungsantragApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = FestsetzungsantragApiAxiosParamCreator(configuration);
    return {
        /**
         * Removes the Festsetzungsantrag resource.
         * @summary Removes the Festsetzungsantrag resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFestsetzungsantragItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFestsetzungsantragItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Festsetzungsantrag resource.
         * @summary Retrieves a Festsetzungsantrag resource.
         * @param {number} v2
         * @param {string} id Resource identifier
         * @param {number} [v1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async diffFestsetzungsantragItem(
            v2: number,
            id: string,
            v1?: number,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FestsetzungsantragFormDiffJsonldAntragFormDiff>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.diffFestsetzungsantragItem(v2, id, v1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Festsetzungsantrag resource.
         * @summary Retrieves a Festsetzungsantrag resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filtersFestsetzungsantragItem(
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<FestsetzungsantragCollectionFiltersOutputJsonldCollectionFilters>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filtersFestsetzungsantragItem(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Festsetzungsantrag resources.
         * @summary Retrieves the collection of Festsetzungsantrag resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {Array<string>} [qSearch]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {Array<string>} [berechnungsart]
         * @param {Array<string>} [antragJahr]
         * @param {string} [aufgabe]
         * @param {'asc' | 'desc'} [orderZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderEinrichtungAktenzeichen]
         * @param {'asc' | 'desc'} [orderEinrichtungName]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderEinrichtungZustaendigerBenutzerName]
         * @param {'asc' | 'desc'} [orderField500]
         * @param {'asc' | 'desc'} [orderFeststellungsantragBerechnungsart]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFestsetzungsantragCollection(
            page?: number,
            itemsPerPage?: number,
            qSearch?: Array<string>,
            zustaendigerBenutzer?: Array<number>,
            status?: string,
            status2?: Array<string>,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            berechnungsart?: Array<string>,
            antragJahr?: Array<string>,
            aufgabe?: string,
            orderZuletztEingereichtAt?: 'asc' | 'desc',
            orderEinrichtungAktenzeichen?: 'asc' | 'desc',
            orderEinrichtungName?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderEinrichtungZustaendigerBenutzerName?: 'asc' | 'desc',
            orderField500?: 'asc' | 'desc',
            orderFeststellungsantragBerechnungsart?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2009>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFestsetzungsantragCollection(
                page,
                itemsPerPage,
                qSearch,
                zustaendigerBenutzer,
                status,
                status2,
                einrichtung,
                einrichtung2,
                berechnungsart,
                antragJahr,
                aufgabe,
                orderZuletztEingereichtAt,
                orderEinrichtungAktenzeichen,
                orderEinrichtungName,
                orderStatus,
                orderEinrichtungZustaendigerBenutzerName,
                orderField500,
                orderFeststellungsantragBerechnungsart,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Festsetzungsantrag resource.
         * @summary Retrieves a Festsetzungsantrag resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFestsetzungsantragItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FestsetzungsantragJsonldFestsetzungsantragRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFestsetzungsantragItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes the Festsetzungsantrag resource.
         * @summary Removes the Festsetzungsantrag resource.
         * @param {string} id Resource identifier
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jsonFormDeleteFestsetzungsantragItem(
            id: string,
            uuid?: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jsonFormDeleteFestsetzungsantragItem(
                id,
                uuid,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Festsetzungsantrag resource.
         * @summary Retrieves a Festsetzungsantrag resource.
         * @param {string} id Resource identifier
         * @param {string} [step]
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jsonFormGetFestsetzungsantragItem(
            id: string,
            step?: string,
            objectType?: string,
            uuid?: string,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<FestsetzungsantragFestsetzungsantragJsonJsonldAntragJsonForm>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jsonFormGetFestsetzungsantragItem(
                id,
                step,
                objectType,
                uuid,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Festsetzungsantrag resource.
         * @summary Updates the Festsetzungsantrag resource.
         * @param {string} id Resource identifier
         * @param {object} body The updated Festsetzungsantrag resource
         * @param {string} [step]
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {boolean} [persist]
         * @param {Array<string>} [fields]
         * @param {boolean} [recalculateFields]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jsonFormPatchFestsetzungsantragItem(
            id: string,
            body: object,
            step?: string,
            objectType?: string,
            uuid?: string,
            persist?: boolean,
            fields?: Array<string>,
            recalculateFields?: boolean,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<FestsetzungsantragFestsetzungsantragJsonJsonldAntragJsonForm>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jsonFormPatchFestsetzungsantragItem(
                id,
                body,
                step,
                objectType,
                uuid,
                persist,
                fields,
                recalculateFields,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Festsetzungsantrag resource.
         * @summary Creates a Festsetzungsantrag resource.
         * @param {FestsetzungsantragJsonldFestsetzungsantragCreate} festsetzungsantragJsonldFestsetzungsantragCreate The new Festsetzungsantrag resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postFestsetzungsantragCollection(
            festsetzungsantragJsonldFestsetzungsantragCreate: FestsetzungsantragJsonldFestsetzungsantragCreate,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FestsetzungsantragJsonldFestsetzungsantragRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postFestsetzungsantragCollection(
                festsetzungsantragJsonldFestsetzungsantragCreate,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Festsetzungsantrag resource.
         * @summary Retrieves a Festsetzungsantrag resource.
         * @param {number} id
         * @param {string} action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workflowFestsetzungsantragItem(
            id: number,
            action: string,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FestsetzungsantragJsonldFestsetzungsantragRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workflowFestsetzungsantragItem(
                id,
                action,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * FestsetzungsantragApi - factory interface
 * @export
 */
export const FestsetzungsantragApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = FestsetzungsantragApiFp(configuration);
    return {
        /**
         * Removes the Festsetzungsantrag resource.
         * @summary Removes the Festsetzungsantrag resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFestsetzungsantragItem(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFestsetzungsantragItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Festsetzungsantrag resource.
         * @summary Retrieves a Festsetzungsantrag resource.
         * @param {number} v2
         * @param {string} id Resource identifier
         * @param {number} [v1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        diffFestsetzungsantragItem(
            v2: number,
            id: string,
            v1?: number,
            options?: any
        ): AxiosPromise<FestsetzungsantragFormDiffJsonldAntragFormDiff> {
            return localVarFp
                .diffFestsetzungsantragItem(v2, id, v1, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Festsetzungsantrag resource.
         * @summary Retrieves a Festsetzungsantrag resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filtersFestsetzungsantragItem(
            options?: any
        ): AxiosPromise<FestsetzungsantragCollectionFiltersOutputJsonldCollectionFilters> {
            return localVarFp.filtersFestsetzungsantragItem(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Festsetzungsantrag resources.
         * @summary Retrieves the collection of Festsetzungsantrag resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {Array<string>} [qSearch]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {Array<string>} [berechnungsart]
         * @param {Array<string>} [antragJahr]
         * @param {string} [aufgabe]
         * @param {'asc' | 'desc'} [orderZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderEinrichtungAktenzeichen]
         * @param {'asc' | 'desc'} [orderEinrichtungName]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderEinrichtungZustaendigerBenutzerName]
         * @param {'asc' | 'desc'} [orderField500]
         * @param {'asc' | 'desc'} [orderFeststellungsantragBerechnungsart]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFestsetzungsantragCollection(
            page?: number,
            itemsPerPage?: number,
            qSearch?: Array<string>,
            zustaendigerBenutzer?: Array<number>,
            status?: string,
            status2?: Array<string>,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            berechnungsart?: Array<string>,
            antragJahr?: Array<string>,
            aufgabe?: string,
            orderZuletztEingereichtAt?: 'asc' | 'desc',
            orderEinrichtungAktenzeichen?: 'asc' | 'desc',
            orderEinrichtungName?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderEinrichtungZustaendigerBenutzerName?: 'asc' | 'desc',
            orderField500?: 'asc' | 'desc',
            orderFeststellungsantragBerechnungsart?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<InlineResponse2009> {
            return localVarFp
                .getFestsetzungsantragCollection(
                    page,
                    itemsPerPage,
                    qSearch,
                    zustaendigerBenutzer,
                    status,
                    status2,
                    einrichtung,
                    einrichtung2,
                    berechnungsart,
                    antragJahr,
                    aufgabe,
                    orderZuletztEingereichtAt,
                    orderEinrichtungAktenzeichen,
                    orderEinrichtungName,
                    orderStatus,
                    orderEinrichtungZustaendigerBenutzerName,
                    orderField500,
                    orderFeststellungsantragBerechnungsart,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Festsetzungsantrag resource.
         * @summary Retrieves a Festsetzungsantrag resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFestsetzungsantragItem(
            id: string,
            options?: any
        ): AxiosPromise<FestsetzungsantragJsonldFestsetzungsantragRead> {
            return localVarFp.getFestsetzungsantragItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the Festsetzungsantrag resource.
         * @summary Removes the Festsetzungsantrag resource.
         * @param {string} id Resource identifier
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jsonFormDeleteFestsetzungsantragItem(id: string, uuid?: string, options?: any): AxiosPromise<void> {
            return localVarFp
                .jsonFormDeleteFestsetzungsantragItem(id, uuid, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Festsetzungsantrag resource.
         * @summary Retrieves a Festsetzungsantrag resource.
         * @param {string} id Resource identifier
         * @param {string} [step]
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jsonFormGetFestsetzungsantragItem(
            id: string,
            step?: string,
            objectType?: string,
            uuid?: string,
            options?: any
        ): AxiosPromise<FestsetzungsantragFestsetzungsantragJsonJsonldAntragJsonForm> {
            return localVarFp
                .jsonFormGetFestsetzungsantragItem(id, step, objectType, uuid, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Updates the Festsetzungsantrag resource.
         * @summary Updates the Festsetzungsantrag resource.
         * @param {string} id Resource identifier
         * @param {object} body The updated Festsetzungsantrag resource
         * @param {string} [step]
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {boolean} [persist]
         * @param {Array<string>} [fields]
         * @param {boolean} [recalculateFields]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jsonFormPatchFestsetzungsantragItem(
            id: string,
            body: object,
            step?: string,
            objectType?: string,
            uuid?: string,
            persist?: boolean,
            fields?: Array<string>,
            recalculateFields?: boolean,
            options?: any
        ): AxiosPromise<FestsetzungsantragFestsetzungsantragJsonJsonldAntragJsonForm> {
            return localVarFp
                .jsonFormPatchFestsetzungsantragItem(
                    id,
                    body,
                    step,
                    objectType,
                    uuid,
                    persist,
                    fields,
                    recalculateFields,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Creates a Festsetzungsantrag resource.
         * @summary Creates a Festsetzungsantrag resource.
         * @param {FestsetzungsantragJsonldFestsetzungsantragCreate} festsetzungsantragJsonldFestsetzungsantragCreate The new Festsetzungsantrag resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFestsetzungsantragCollection(
            festsetzungsantragJsonldFestsetzungsantragCreate: FestsetzungsantragJsonldFestsetzungsantragCreate,
            options?: any
        ): AxiosPromise<FestsetzungsantragJsonldFestsetzungsantragRead> {
            return localVarFp
                .postFestsetzungsantragCollection(festsetzungsantragJsonldFestsetzungsantragCreate, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Festsetzungsantrag resource.
         * @summary Retrieves a Festsetzungsantrag resource.
         * @param {number} id
         * @param {string} action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workflowFestsetzungsantragItem(
            id: number,
            action: string,
            options?: any
        ): AxiosPromise<FestsetzungsantragJsonldFestsetzungsantragRead> {
            return localVarFp
                .workflowFestsetzungsantragItem(id, action, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * FestsetzungsantragApi - object-oriented interface
 * @export
 * @class FestsetzungsantragApi
 * @extends {BaseAPI}
 */
export class FestsetzungsantragApi extends BaseAPI {
    /**
     * Removes the Festsetzungsantrag resource.
     * @summary Removes the Festsetzungsantrag resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FestsetzungsantragApi
     */
    public deleteFestsetzungsantragItem(id: string, options?: AxiosRequestConfig) {
        return FestsetzungsantragApiFp(this.configuration)
            .deleteFestsetzungsantragItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Festsetzungsantrag resource.
     * @summary Retrieves a Festsetzungsantrag resource.
     * @param {number} v2
     * @param {string} id Resource identifier
     * @param {number} [v1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FestsetzungsantragApi
     */
    public diffFestsetzungsantragItem(v2: number, id: string, v1?: number, options?: AxiosRequestConfig) {
        return FestsetzungsantragApiFp(this.configuration)
            .diffFestsetzungsantragItem(v2, id, v1, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Festsetzungsantrag resource.
     * @summary Retrieves a Festsetzungsantrag resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FestsetzungsantragApi
     */
    public filtersFestsetzungsantragItem(options?: AxiosRequestConfig) {
        return FestsetzungsantragApiFp(this.configuration)
            .filtersFestsetzungsantragItem(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Festsetzungsantrag resources.
     * @summary Retrieves the collection of Festsetzungsantrag resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {Array<string>} [qSearch]
     * @param {Array<number>} [zustaendigerBenutzer]
     * @param {string} [status]
     * @param {Array<string>} [status2]
     * @param {string} [einrichtung]
     * @param {Array<string>} [einrichtung2]
     * @param {Array<string>} [berechnungsart]
     * @param {Array<string>} [antragJahr]
     * @param {string} [aufgabe]
     * @param {'asc' | 'desc'} [orderZuletztEingereichtAt]
     * @param {'asc' | 'desc'} [orderEinrichtungAktenzeichen]
     * @param {'asc' | 'desc'} [orderEinrichtungName]
     * @param {'asc' | 'desc'} [orderStatus]
     * @param {'asc' | 'desc'} [orderEinrichtungZustaendigerBenutzerName]
     * @param {'asc' | 'desc'} [orderField500]
     * @param {'asc' | 'desc'} [orderFeststellungsantragBerechnungsart]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FestsetzungsantragApi
     */
    public getFestsetzungsantragCollection(
        page?: number,
        itemsPerPage?: number,
        qSearch?: Array<string>,
        zustaendigerBenutzer?: Array<number>,
        status?: string,
        status2?: Array<string>,
        einrichtung?: string,
        einrichtung2?: Array<string>,
        berechnungsart?: Array<string>,
        antragJahr?: Array<string>,
        aufgabe?: string,
        orderZuletztEingereichtAt?: 'asc' | 'desc',
        orderEinrichtungAktenzeichen?: 'asc' | 'desc',
        orderEinrichtungName?: 'asc' | 'desc',
        orderStatus?: 'asc' | 'desc',
        orderEinrichtungZustaendigerBenutzerName?: 'asc' | 'desc',
        orderField500?: 'asc' | 'desc',
        orderFeststellungsantragBerechnungsart?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return FestsetzungsantragApiFp(this.configuration)
            .getFestsetzungsantragCollection(
                page,
                itemsPerPage,
                qSearch,
                zustaendigerBenutzer,
                status,
                status2,
                einrichtung,
                einrichtung2,
                berechnungsart,
                antragJahr,
                aufgabe,
                orderZuletztEingereichtAt,
                orderEinrichtungAktenzeichen,
                orderEinrichtungName,
                orderStatus,
                orderEinrichtungZustaendigerBenutzerName,
                orderField500,
                orderFeststellungsantragBerechnungsart,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Festsetzungsantrag resource.
     * @summary Retrieves a Festsetzungsantrag resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FestsetzungsantragApi
     */
    public getFestsetzungsantragItem(id: string, options?: AxiosRequestConfig) {
        return FestsetzungsantragApiFp(this.configuration)
            .getFestsetzungsantragItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the Festsetzungsantrag resource.
     * @summary Removes the Festsetzungsantrag resource.
     * @param {string} id Resource identifier
     * @param {string} [uuid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FestsetzungsantragApi
     */
    public jsonFormDeleteFestsetzungsantragItem(id: string, uuid?: string, options?: AxiosRequestConfig) {
        return FestsetzungsantragApiFp(this.configuration)
            .jsonFormDeleteFestsetzungsantragItem(id, uuid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Festsetzungsantrag resource.
     * @summary Retrieves a Festsetzungsantrag resource.
     * @param {string} id Resource identifier
     * @param {string} [step]
     * @param {string} [objectType]
     * @param {string} [uuid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FestsetzungsantragApi
     */
    public jsonFormGetFestsetzungsantragItem(
        id: string,
        step?: string,
        objectType?: string,
        uuid?: string,
        options?: AxiosRequestConfig
    ) {
        return FestsetzungsantragApiFp(this.configuration)
            .jsonFormGetFestsetzungsantragItem(id, step, objectType, uuid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Festsetzungsantrag resource.
     * @summary Updates the Festsetzungsantrag resource.
     * @param {string} id Resource identifier
     * @param {object} body The updated Festsetzungsantrag resource
     * @param {string} [step]
     * @param {string} [objectType]
     * @param {string} [uuid]
     * @param {boolean} [persist]
     * @param {Array<string>} [fields]
     * @param {boolean} [recalculateFields]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FestsetzungsantragApi
     */
    public jsonFormPatchFestsetzungsantragItem(
        id: string,
        body: object,
        step?: string,
        objectType?: string,
        uuid?: string,
        persist?: boolean,
        fields?: Array<string>,
        recalculateFields?: boolean,
        options?: AxiosRequestConfig
    ) {
        return FestsetzungsantragApiFp(this.configuration)
            .jsonFormPatchFestsetzungsantragItem(
                id,
                body,
                step,
                objectType,
                uuid,
                persist,
                fields,
                recalculateFields,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Festsetzungsantrag resource.
     * @summary Creates a Festsetzungsantrag resource.
     * @param {FestsetzungsantragJsonldFestsetzungsantragCreate} festsetzungsantragJsonldFestsetzungsantragCreate The new Festsetzungsantrag resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FestsetzungsantragApi
     */
    public postFestsetzungsantragCollection(
        festsetzungsantragJsonldFestsetzungsantragCreate: FestsetzungsantragJsonldFestsetzungsantragCreate,
        options?: AxiosRequestConfig
    ) {
        return FestsetzungsantragApiFp(this.configuration)
            .postFestsetzungsantragCollection(festsetzungsantragJsonldFestsetzungsantragCreate, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Festsetzungsantrag resource.
     * @summary Retrieves a Festsetzungsantrag resource.
     * @param {number} id
     * @param {string} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FestsetzungsantragApi
     */
    public workflowFestsetzungsantragItem(id: number, action: string, options?: AxiosRequestConfig) {
        return FestsetzungsantragApiFp(this.configuration)
            .workflowFestsetzungsantragItem(id, action, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * FestsetzungsantragBescheidungApi - axios parameter creator
 * @export
 */
export const FestsetzungsantragBescheidungApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a FestsetzungsantragBescheidung resource.
         * @summary Retrieves a FestsetzungsantragBescheidung resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFestsetzungsantragBescheidungItem: async (
            id: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFestsetzungsantragBescheidungItem', 'id', id);
            const localVarPath = `/api/festsetzungsantrag-bescheidung/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the FestsetzungsantragBescheidung resource.
         * @summary Updates the FestsetzungsantragBescheidung resource.
         * @param {string} id Resource identifier
         * @param {FestsetzungsantragBescheidungBescheidungSetGesendetAt} festsetzungsantragBescheidungBescheidungSetGesendetAt The updated FestsetzungsantragBescheidung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setGesendetAtFestsetzungsantragBescheidungItem: async (
            id: string,
            festsetzungsantragBescheidungBescheidungSetGesendetAt: FestsetzungsantragBescheidungBescheidungSetGesendetAt,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setGesendetAtFestsetzungsantragBescheidungItem', 'id', id);
            // verify required parameter 'festsetzungsantragBescheidungBescheidungSetGesendetAt' is not null or undefined
            assertParamExists(
                'setGesendetAtFestsetzungsantragBescheidungItem',
                'festsetzungsantragBescheidungBescheidungSetGesendetAt',
                festsetzungsantragBescheidungBescheidungSetGesendetAt
            );
            const localVarPath = `/api/festsetzungsantrag-bescheidung/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                festsetzungsantragBescheidungBescheidungSetGesendetAt,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * FestsetzungsantragBescheidungApi - functional programming interface
 * @export
 */
export const FestsetzungsantragBescheidungApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = FestsetzungsantragBescheidungApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves a FestsetzungsantragBescheidung resource.
         * @summary Retrieves a FestsetzungsantragBescheidung resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFestsetzungsantragBescheidungItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FestsetzungsantragBescheidungJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFestsetzungsantragBescheidungItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the FestsetzungsantragBescheidung resource.
         * @summary Updates the FestsetzungsantragBescheidung resource.
         * @param {string} id Resource identifier
         * @param {FestsetzungsantragBescheidungBescheidungSetGesendetAt} festsetzungsantragBescheidungBescheidungSetGesendetAt The updated FestsetzungsantragBescheidung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setGesendetAtFestsetzungsantragBescheidungItem(
            id: string,
            festsetzungsantragBescheidungBescheidungSetGesendetAt: FestsetzungsantragBescheidungBescheidungSetGesendetAt,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setGesendetAtFestsetzungsantragBescheidungItem(
                id,
                festsetzungsantragBescheidungBescheidungSetGesendetAt,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * FestsetzungsantragBescheidungApi - factory interface
 * @export
 */
export const FestsetzungsantragBescheidungApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = FestsetzungsantragBescheidungApiFp(configuration);
    return {
        /**
         * Retrieves a FestsetzungsantragBescheidung resource.
         * @summary Retrieves a FestsetzungsantragBescheidung resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFestsetzungsantragBescheidungItem(
            id: string,
            options?: any
        ): AxiosPromise<FestsetzungsantragBescheidungJsonld> {
            return localVarFp
                .getFestsetzungsantragBescheidungItem(id, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Updates the FestsetzungsantragBescheidung resource.
         * @summary Updates the FestsetzungsantragBescheidung resource.
         * @param {string} id Resource identifier
         * @param {FestsetzungsantragBescheidungBescheidungSetGesendetAt} festsetzungsantragBescheidungBescheidungSetGesendetAt The updated FestsetzungsantragBescheidung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setGesendetAtFestsetzungsantragBescheidungItem(
            id: string,
            festsetzungsantragBescheidungBescheidungSetGesendetAt: FestsetzungsantragBescheidungBescheidungSetGesendetAt,
            options?: any
        ): AxiosPromise<any> {
            return localVarFp
                .setGesendetAtFestsetzungsantragBescheidungItem(
                    id,
                    festsetzungsantragBescheidungBescheidungSetGesendetAt,
                    options
                )
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * FestsetzungsantragBescheidungApi - object-oriented interface
 * @export
 * @class FestsetzungsantragBescheidungApi
 * @extends {BaseAPI}
 */
export class FestsetzungsantragBescheidungApi extends BaseAPI {
    /**
     * Retrieves a FestsetzungsantragBescheidung resource.
     * @summary Retrieves a FestsetzungsantragBescheidung resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FestsetzungsantragBescheidungApi
     */
    public getFestsetzungsantragBescheidungItem(id: string, options?: AxiosRequestConfig) {
        return FestsetzungsantragBescheidungApiFp(this.configuration)
            .getFestsetzungsantragBescheidungItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the FestsetzungsantragBescheidung resource.
     * @summary Updates the FestsetzungsantragBescheidung resource.
     * @param {string} id Resource identifier
     * @param {FestsetzungsantragBescheidungBescheidungSetGesendetAt} festsetzungsantragBescheidungBescheidungSetGesendetAt The updated FestsetzungsantragBescheidung resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FestsetzungsantragBescheidungApi
     */
    public setGesendetAtFestsetzungsantragBescheidungItem(
        id: string,
        festsetzungsantragBescheidungBescheidungSetGesendetAt: FestsetzungsantragBescheidungBescheidungSetGesendetAt,
        options?: AxiosRequestConfig
    ) {
        return FestsetzungsantragBescheidungApiFp(this.configuration)
            .setGesendetAtFestsetzungsantragBescheidungItem(
                id,
                festsetzungsantragBescheidungBescheidungSetGesendetAt,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * FestsetzungsantragGegenpruefungApi - axios parameter creator
 * @export
 */
export const FestsetzungsantragGegenpruefungApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of FestsetzungsantragGegenpruefung resources.
         * @summary Retrieves the collection of FestsetzungsantragGegenpruefung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFestsetzungsantragGegenpruefungCollection: async (
            page?: number,
            itemsPerPage?: number,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/festsetzungsantrag-gegenpruefung`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a FestsetzungsantragGegenpruefung resource.
         * @summary Retrieves a FestsetzungsantragGegenpruefung resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFestsetzungsantragGegenpruefungItem: async (
            id: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFestsetzungsantragGegenpruefungItem', 'id', id);
            const localVarPath = `/api/festsetzungsantrag-gegenpruefung/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the FestsetzungsantragGegenpruefung resource.
         * @summary Updates the FestsetzungsantragGegenpruefung resource.
         * @param {string} id Resource identifier
         * @param {FestsetzungsantragGegenpruefungGegenpruefungEdit} festsetzungsantragGegenpruefungGegenpruefungEdit The updated FestsetzungsantragGegenpruefung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchFestsetzungsantragGegenpruefungItem: async (
            id: string,
            festsetzungsantragGegenpruefungGegenpruefungEdit: FestsetzungsantragGegenpruefungGegenpruefungEdit,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchFestsetzungsantragGegenpruefungItem', 'id', id);
            // verify required parameter 'festsetzungsantragGegenpruefungGegenpruefungEdit' is not null or undefined
            assertParamExists(
                'patchFestsetzungsantragGegenpruefungItem',
                'festsetzungsantragGegenpruefungGegenpruefungEdit',
                festsetzungsantragGegenpruefungGegenpruefungEdit
            );
            const localVarPath = `/api/festsetzungsantrag-gegenpruefung/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                festsetzungsantragGegenpruefungGegenpruefungEdit,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * FestsetzungsantragGegenpruefungApi - functional programming interface
 * @export
 */
export const FestsetzungsantragGegenpruefungApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = FestsetzungsantragGegenpruefungApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of FestsetzungsantragGegenpruefung resources.
         * @summary Retrieves the collection of FestsetzungsantragGegenpruefung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFestsetzungsantragGegenpruefungCollection(
            page?: number,
            itemsPerPage?: number,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20010>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFestsetzungsantragGegenpruefungCollection(
                page,
                itemsPerPage,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a FestsetzungsantragGegenpruefung resource.
         * @summary Retrieves a FestsetzungsantragGegenpruefung resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFestsetzungsantragGegenpruefungItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FestsetzungsantragGegenpruefungJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFestsetzungsantragGegenpruefungItem(
                id,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the FestsetzungsantragGegenpruefung resource.
         * @summary Updates the FestsetzungsantragGegenpruefung resource.
         * @param {string} id Resource identifier
         * @param {FestsetzungsantragGegenpruefungGegenpruefungEdit} festsetzungsantragGegenpruefungGegenpruefungEdit The updated FestsetzungsantragGegenpruefung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchFestsetzungsantragGegenpruefungItem(
            id: string,
            festsetzungsantragGegenpruefungGegenpruefungEdit: FestsetzungsantragGegenpruefungGegenpruefungEdit,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchFestsetzungsantragGegenpruefungItem(
                id,
                festsetzungsantragGegenpruefungGegenpruefungEdit,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * FestsetzungsantragGegenpruefungApi - factory interface
 * @export
 */
export const FestsetzungsantragGegenpruefungApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = FestsetzungsantragGegenpruefungApiFp(configuration);
    return {
        /**
         * Retrieves the collection of FestsetzungsantragGegenpruefung resources.
         * @summary Retrieves the collection of FestsetzungsantragGegenpruefung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFestsetzungsantragGegenpruefungCollection(
            page?: number,
            itemsPerPage?: number,
            options?: any
        ): AxiosPromise<InlineResponse20010> {
            return localVarFp
                .getFestsetzungsantragGegenpruefungCollection(page, itemsPerPage, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a FestsetzungsantragGegenpruefung resource.
         * @summary Retrieves a FestsetzungsantragGegenpruefung resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFestsetzungsantragGegenpruefungItem(
            id: string,
            options?: any
        ): AxiosPromise<FestsetzungsantragGegenpruefungJsonld> {
            return localVarFp
                .getFestsetzungsantragGegenpruefungItem(id, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Updates the FestsetzungsantragGegenpruefung resource.
         * @summary Updates the FestsetzungsantragGegenpruefung resource.
         * @param {string} id Resource identifier
         * @param {FestsetzungsantragGegenpruefungGegenpruefungEdit} festsetzungsantragGegenpruefungGegenpruefungEdit The updated FestsetzungsantragGegenpruefung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchFestsetzungsantragGegenpruefungItem(
            id: string,
            festsetzungsantragGegenpruefungGegenpruefungEdit: FestsetzungsantragGegenpruefungGegenpruefungEdit,
            options?: any
        ): AxiosPromise<any> {
            return localVarFp
                .patchFestsetzungsantragGegenpruefungItem(id, festsetzungsantragGegenpruefungGegenpruefungEdit, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * FestsetzungsantragGegenpruefungApi - object-oriented interface
 * @export
 * @class FestsetzungsantragGegenpruefungApi
 * @extends {BaseAPI}
 */
export class FestsetzungsantragGegenpruefungApi extends BaseAPI {
    /**
     * Retrieves the collection of FestsetzungsantragGegenpruefung resources.
     * @summary Retrieves the collection of FestsetzungsantragGegenpruefung resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FestsetzungsantragGegenpruefungApi
     */
    public getFestsetzungsantragGegenpruefungCollection(
        page?: number,
        itemsPerPage?: number,
        options?: AxiosRequestConfig
    ) {
        return FestsetzungsantragGegenpruefungApiFp(this.configuration)
            .getFestsetzungsantragGegenpruefungCollection(page, itemsPerPage, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a FestsetzungsantragGegenpruefung resource.
     * @summary Retrieves a FestsetzungsantragGegenpruefung resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FestsetzungsantragGegenpruefungApi
     */
    public getFestsetzungsantragGegenpruefungItem(id: string, options?: AxiosRequestConfig) {
        return FestsetzungsantragGegenpruefungApiFp(this.configuration)
            .getFestsetzungsantragGegenpruefungItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the FestsetzungsantragGegenpruefung resource.
     * @summary Updates the FestsetzungsantragGegenpruefung resource.
     * @param {string} id Resource identifier
     * @param {FestsetzungsantragGegenpruefungGegenpruefungEdit} festsetzungsantragGegenpruefungGegenpruefungEdit The updated FestsetzungsantragGegenpruefung resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FestsetzungsantragGegenpruefungApi
     */
    public patchFestsetzungsantragGegenpruefungItem(
        id: string,
        festsetzungsantragGegenpruefungGegenpruefungEdit: FestsetzungsantragGegenpruefungGegenpruefungEdit,
        options?: AxiosRequestConfig
    ) {
        return FestsetzungsantragGegenpruefungApiFp(this.configuration)
            .patchFestsetzungsantragGegenpruefungItem(id, festsetzungsantragGegenpruefungGegenpruefungEdit, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * FestsetzungsantragVersionApi - axios parameter creator
 * @export
 */
export const FestsetzungsantragVersionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of FestsetzungsantragVersion resources.
         * @summary Retrieves the collection of FestsetzungsantragVersion resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [antrag]
         * @param {Array<string>} [antrag2]
         * @param {'asc' | 'desc'} [orderAntragModifiedAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFestsetzungsantragVersionCollection: async (
            page?: number,
            itemsPerPage?: number,
            antrag?: string,
            antrag2?: Array<string>,
            orderAntragModifiedAt?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/festsetzungsantrag-version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (antrag !== undefined) {
                localVarQueryParameter['antrag'] = antrag;
            }

            if (antrag2) {
                localVarQueryParameter['antrag[]'] = antrag2;
            }

            if (orderAntragModifiedAt !== undefined) {
                localVarQueryParameter['order[antragModifiedAt]'] = orderAntragModifiedAt;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a FestsetzungsantragVersion resource.
         * @summary Retrieves a FestsetzungsantragVersion resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFestsetzungsantragVersionItem: async (
            id: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFestsetzungsantragVersionItem', 'id', id);
            const localVarPath = `/api/festsetzungsantrag-version/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * FestsetzungsantragVersionApi - functional programming interface
 * @export
 */
export const FestsetzungsantragVersionApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = FestsetzungsantragVersionApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of FestsetzungsantragVersion resources.
         * @summary Retrieves the collection of FestsetzungsantragVersion resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [antrag]
         * @param {Array<string>} [antrag2]
         * @param {'asc' | 'desc'} [orderAntragModifiedAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFestsetzungsantragVersionCollection(
            page?: number,
            itemsPerPage?: number,
            antrag?: string,
            antrag2?: Array<string>,
            orderAntragModifiedAt?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20011>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFestsetzungsantragVersionCollection(
                page,
                itemsPerPage,
                antrag,
                antrag2,
                orderAntragModifiedAt,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a FestsetzungsantragVersion resource.
         * @summary Retrieves a FestsetzungsantragVersion resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFestsetzungsantragVersionItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<FestsetzungsantragVersionJsonldFestsetzungsantragVersionRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFestsetzungsantragVersionItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * FestsetzungsantragVersionApi - factory interface
 * @export
 */
export const FestsetzungsantragVersionApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = FestsetzungsantragVersionApiFp(configuration);
    return {
        /**
         * Retrieves the collection of FestsetzungsantragVersion resources.
         * @summary Retrieves the collection of FestsetzungsantragVersion resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [antrag]
         * @param {Array<string>} [antrag2]
         * @param {'asc' | 'desc'} [orderAntragModifiedAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFestsetzungsantragVersionCollection(
            page?: number,
            itemsPerPage?: number,
            antrag?: string,
            antrag2?: Array<string>,
            orderAntragModifiedAt?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<InlineResponse20011> {
            return localVarFp
                .getFestsetzungsantragVersionCollection(
                    page,
                    itemsPerPage,
                    antrag,
                    antrag2,
                    orderAntragModifiedAt,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a FestsetzungsantragVersion resource.
         * @summary Retrieves a FestsetzungsantragVersion resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFestsetzungsantragVersionItem(
            id: string,
            options?: any
        ): AxiosPromise<FestsetzungsantragVersionJsonldFestsetzungsantragVersionRead> {
            return localVarFp.getFestsetzungsantragVersionItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FestsetzungsantragVersionApi - object-oriented interface
 * @export
 * @class FestsetzungsantragVersionApi
 * @extends {BaseAPI}
 */
export class FestsetzungsantragVersionApi extends BaseAPI {
    /**
     * Retrieves the collection of FestsetzungsantragVersion resources.
     * @summary Retrieves the collection of FestsetzungsantragVersion resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [antrag]
     * @param {Array<string>} [antrag2]
     * @param {'asc' | 'desc'} [orderAntragModifiedAt]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FestsetzungsantragVersionApi
     */
    public getFestsetzungsantragVersionCollection(
        page?: number,
        itemsPerPage?: number,
        antrag?: string,
        antrag2?: Array<string>,
        orderAntragModifiedAt?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return FestsetzungsantragVersionApiFp(this.configuration)
            .getFestsetzungsantragVersionCollection(page, itemsPerPage, antrag, antrag2, orderAntragModifiedAt, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a FestsetzungsantragVersion resource.
     * @summary Retrieves a FestsetzungsantragVersion resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FestsetzungsantragVersionApi
     */
    public getFestsetzungsantragVersionItem(id: string, options?: AxiosRequestConfig) {
        return FestsetzungsantragVersionApiFp(this.configuration)
            .getFestsetzungsantragVersionItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * FestsetzungsantragZurueckziehenApi - axios parameter creator
 * @export
 */
export const FestsetzungsantragZurueckziehenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a FestsetzungsantragZurueckziehen resource.
         * @summary Retrieves a FestsetzungsantragZurueckziehen resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFestsetzungsantragZurueckziehenItem: async (
            id: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFestsetzungsantragZurueckziehenItem', 'id', id);
            const localVarPath = `/api/festsetzungsantrag-zurueckziehen/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a FestsetzungsantragZurueckziehen resource.
         * @summary Creates a FestsetzungsantragZurueckziehen resource.
         * @param {FestsetzungsantragZurueckziehenJsonldZurueckziehenCreate} festsetzungsantragZurueckziehenJsonldZurueckziehenCreate The new FestsetzungsantragZurueckziehen resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFestsetzungsantragZurueckziehenCollection: async (
            festsetzungsantragZurueckziehenJsonldZurueckziehenCreate: FestsetzungsantragZurueckziehenJsonldZurueckziehenCreate,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'festsetzungsantragZurueckziehenJsonldZurueckziehenCreate' is not null or undefined
            assertParamExists(
                'postFestsetzungsantragZurueckziehenCollection',
                'festsetzungsantragZurueckziehenJsonldZurueckziehenCreate',
                festsetzungsantragZurueckziehenJsonldZurueckziehenCreate
            );
            const localVarPath = `/api/festsetzungsantrag-zurueckziehen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                festsetzungsantragZurueckziehenJsonldZurueckziehenCreate,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the FestsetzungsantragZurueckziehen resource.
         * @summary Updates the FestsetzungsantragZurueckziehen resource.
         * @param {string} id Resource identifier
         * @param {FestsetzungsantragZurueckziehenZurueckziehenSetEntscheidung} festsetzungsantragZurueckziehenZurueckziehenSetEntscheidung The updated FestsetzungsantragZurueckziehen resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEntscheidungFestsetzungsantragZurueckziehenItem: async (
            id: string,
            festsetzungsantragZurueckziehenZurueckziehenSetEntscheidung: FestsetzungsantragZurueckziehenZurueckziehenSetEntscheidung,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setEntscheidungFestsetzungsantragZurueckziehenItem', 'id', id);
            // verify required parameter 'festsetzungsantragZurueckziehenZurueckziehenSetEntscheidung' is not null or undefined
            assertParamExists(
                'setEntscheidungFestsetzungsantragZurueckziehenItem',
                'festsetzungsantragZurueckziehenZurueckziehenSetEntscheidung',
                festsetzungsantragZurueckziehenZurueckziehenSetEntscheidung
            );
            const localVarPath = `/api/festsetzungsantrag-zurueckziehen/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                festsetzungsantragZurueckziehenZurueckziehenSetEntscheidung,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * FestsetzungsantragZurueckziehenApi - functional programming interface
 * @export
 */
export const FestsetzungsantragZurueckziehenApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = FestsetzungsantragZurueckziehenApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves a FestsetzungsantragZurueckziehen resource.
         * @summary Retrieves a FestsetzungsantragZurueckziehen resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFestsetzungsantragZurueckziehenItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FestsetzungsantragZurueckziehenJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFestsetzungsantragZurueckziehenItem(
                id,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a FestsetzungsantragZurueckziehen resource.
         * @summary Creates a FestsetzungsantragZurueckziehen resource.
         * @param {FestsetzungsantragZurueckziehenJsonldZurueckziehenCreate} festsetzungsantragZurueckziehenJsonldZurueckziehenCreate The new FestsetzungsantragZurueckziehen resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postFestsetzungsantragZurueckziehenCollection(
            festsetzungsantragZurueckziehenJsonldZurueckziehenCreate: FestsetzungsantragZurueckziehenJsonldZurueckziehenCreate,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FestsetzungsantragZurueckziehenJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postFestsetzungsantragZurueckziehenCollection(
                festsetzungsantragZurueckziehenJsonldZurueckziehenCreate,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the FestsetzungsantragZurueckziehen resource.
         * @summary Updates the FestsetzungsantragZurueckziehen resource.
         * @param {string} id Resource identifier
         * @param {FestsetzungsantragZurueckziehenZurueckziehenSetEntscheidung} festsetzungsantragZurueckziehenZurueckziehenSetEntscheidung The updated FestsetzungsantragZurueckziehen resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setEntscheidungFestsetzungsantragZurueckziehenItem(
            id: string,
            festsetzungsantragZurueckziehenZurueckziehenSetEntscheidung: FestsetzungsantragZurueckziehenZurueckziehenSetEntscheidung,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.setEntscheidungFestsetzungsantragZurueckziehenItem(
                    id,
                    festsetzungsantragZurueckziehenZurueckziehenSetEntscheidung,
                    options
                );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * FestsetzungsantragZurueckziehenApi - factory interface
 * @export
 */
export const FestsetzungsantragZurueckziehenApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = FestsetzungsantragZurueckziehenApiFp(configuration);
    return {
        /**
         * Retrieves a FestsetzungsantragZurueckziehen resource.
         * @summary Retrieves a FestsetzungsantragZurueckziehen resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFestsetzungsantragZurueckziehenItem(
            id: string,
            options?: any
        ): AxiosPromise<FestsetzungsantragZurueckziehenJsonld> {
            return localVarFp
                .getFestsetzungsantragZurueckziehenItem(id, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Creates a FestsetzungsantragZurueckziehen resource.
         * @summary Creates a FestsetzungsantragZurueckziehen resource.
         * @param {FestsetzungsantragZurueckziehenJsonldZurueckziehenCreate} festsetzungsantragZurueckziehenJsonldZurueckziehenCreate The new FestsetzungsantragZurueckziehen resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFestsetzungsantragZurueckziehenCollection(
            festsetzungsantragZurueckziehenJsonldZurueckziehenCreate: FestsetzungsantragZurueckziehenJsonldZurueckziehenCreate,
            options?: any
        ): AxiosPromise<FestsetzungsantragZurueckziehenJsonld> {
            return localVarFp
                .postFestsetzungsantragZurueckziehenCollection(
                    festsetzungsantragZurueckziehenJsonldZurueckziehenCreate,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Updates the FestsetzungsantragZurueckziehen resource.
         * @summary Updates the FestsetzungsantragZurueckziehen resource.
         * @param {string} id Resource identifier
         * @param {FestsetzungsantragZurueckziehenZurueckziehenSetEntscheidung} festsetzungsantragZurueckziehenZurueckziehenSetEntscheidung The updated FestsetzungsantragZurueckziehen resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEntscheidungFestsetzungsantragZurueckziehenItem(
            id: string,
            festsetzungsantragZurueckziehenZurueckziehenSetEntscheidung: FestsetzungsantragZurueckziehenZurueckziehenSetEntscheidung,
            options?: any
        ): AxiosPromise<any> {
            return localVarFp
                .setEntscheidungFestsetzungsantragZurueckziehenItem(
                    id,
                    festsetzungsantragZurueckziehenZurueckziehenSetEntscheidung,
                    options
                )
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * FestsetzungsantragZurueckziehenApi - object-oriented interface
 * @export
 * @class FestsetzungsantragZurueckziehenApi
 * @extends {BaseAPI}
 */
export class FestsetzungsantragZurueckziehenApi extends BaseAPI {
    /**
     * Retrieves a FestsetzungsantragZurueckziehen resource.
     * @summary Retrieves a FestsetzungsantragZurueckziehen resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FestsetzungsantragZurueckziehenApi
     */
    public getFestsetzungsantragZurueckziehenItem(id: string, options?: AxiosRequestConfig) {
        return FestsetzungsantragZurueckziehenApiFp(this.configuration)
            .getFestsetzungsantragZurueckziehenItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a FestsetzungsantragZurueckziehen resource.
     * @summary Creates a FestsetzungsantragZurueckziehen resource.
     * @param {FestsetzungsantragZurueckziehenJsonldZurueckziehenCreate} festsetzungsantragZurueckziehenJsonldZurueckziehenCreate The new FestsetzungsantragZurueckziehen resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FestsetzungsantragZurueckziehenApi
     */
    public postFestsetzungsantragZurueckziehenCollection(
        festsetzungsantragZurueckziehenJsonldZurueckziehenCreate: FestsetzungsantragZurueckziehenJsonldZurueckziehenCreate,
        options?: AxiosRequestConfig
    ) {
        return FestsetzungsantragZurueckziehenApiFp(this.configuration)
            .postFestsetzungsantragZurueckziehenCollection(
                festsetzungsantragZurueckziehenJsonldZurueckziehenCreate,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the FestsetzungsantragZurueckziehen resource.
     * @summary Updates the FestsetzungsantragZurueckziehen resource.
     * @param {string} id Resource identifier
     * @param {FestsetzungsantragZurueckziehenZurueckziehenSetEntscheidung} festsetzungsantragZurueckziehenZurueckziehenSetEntscheidung The updated FestsetzungsantragZurueckziehen resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FestsetzungsantragZurueckziehenApi
     */
    public setEntscheidungFestsetzungsantragZurueckziehenItem(
        id: string,
        festsetzungsantragZurueckziehenZurueckziehenSetEntscheidung: FestsetzungsantragZurueckziehenZurueckziehenSetEntscheidung,
        options?: AxiosRequestConfig
    ) {
        return FestsetzungsantragZurueckziehenApiFp(this.configuration)
            .setEntscheidungFestsetzungsantragZurueckziehenItem(
                id,
                festsetzungsantragZurueckziehenZurueckziehenSetEntscheidung,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * FeststellungsantragApi - axios parameter creator
 * @export
 */
export const FeststellungsantragApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes the Feststellungsantrag resource.
         * @summary Removes the Feststellungsantrag resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeststellungsantragItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFeststellungsantragItem', 'id', id);
            const localVarPath = `/api/feststellungsantrag/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Feststellungsantrag resource.
         * @summary Retrieves a Feststellungsantrag resource.
         * @param {number} v2
         * @param {string} id Resource identifier
         * @param {number} [v1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        diffFeststellungsantragItem: async (
            v2: number,
            id: string,
            v1?: number,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'v2' is not null or undefined
            assertParamExists('diffFeststellungsantragItem', 'v2', v2);
            // verify required parameter 'id' is not null or undefined
            assertParamExists('diffFeststellungsantragItem', 'id', id);
            const localVarPath = `/api/feststellungsantrag/{id}/diff`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (v1 !== undefined) {
                localVarQueryParameter['v1'] = v1;
            }

            if (v2 !== undefined) {
                localVarQueryParameter['v2'] = v2;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Feststellungsantrag resource.
         * @summary Retrieves a Feststellungsantrag resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filtersFeststellungsantragItem: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/filters/feststellungsantrag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Feststellungsantrag resources.
         * @summary Retrieves the collection of Feststellungsantrag resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {Array<string>} [qSearch]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {Array<string>} [berechnungsart]
         * @param {Array<string>} [antragJahr]
         * @param {string} [aufgabe]
         * @param {'asc' | 'desc'} [orderZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderEinrichtungAktenzeichen]
         * @param {'asc' | 'desc'} [orderEinrichtungName]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderEinrichtungZustaendigerBenutzerName]
         * @param {'asc' | 'desc'} [orderField102]
         * @param {'asc' | 'desc'} [orderBerechnungsart]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeststellungsantragCollection: async (
            page?: number,
            itemsPerPage?: number,
            qSearch?: Array<string>,
            zustaendigerBenutzer?: Array<number>,
            status?: string,
            status2?: Array<string>,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            berechnungsart?: Array<string>,
            antragJahr?: Array<string>,
            aufgabe?: string,
            orderZuletztEingereichtAt?: 'asc' | 'desc',
            orderEinrichtungAktenzeichen?: 'asc' | 'desc',
            orderEinrichtungName?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderEinrichtungZustaendigerBenutzerName?: 'asc' | 'desc',
            orderField102?: 'asc' | 'desc',
            orderBerechnungsart?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/feststellungsantrag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (qSearch) {
                localVarQueryParameter['qSearch[]'] = qSearch;
            }

            if (zustaendigerBenutzer) {
                localVarQueryParameter['zustaendigerBenutzer[]'] = zustaendigerBenutzer;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (status2) {
                localVarQueryParameter['status[]'] = status2;
            }

            if (einrichtung !== undefined) {
                localVarQueryParameter['einrichtung'] = einrichtung;
            }

            if (einrichtung2) {
                localVarQueryParameter['einrichtung[]'] = einrichtung2;
            }

            if (berechnungsart) {
                localVarQueryParameter['berechnungsart[]'] = berechnungsart;
            }

            if (antragJahr) {
                localVarQueryParameter['antragJahr[]'] = antragJahr;
            }

            if (aufgabe !== undefined) {
                localVarQueryParameter['aufgabe'] = aufgabe;
            }

            if (orderZuletztEingereichtAt !== undefined) {
                localVarQueryParameter['order[zuletztEingereichtAt]'] = orderZuletztEingereichtAt;
            }

            if (orderEinrichtungAktenzeichen !== undefined) {
                localVarQueryParameter['order[einrichtung.aktenzeichen]'] = orderEinrichtungAktenzeichen;
            }

            if (orderEinrichtungName !== undefined) {
                localVarQueryParameter['order[einrichtung.name]'] = orderEinrichtungName;
            }

            if (orderStatus !== undefined) {
                localVarQueryParameter['order[status]'] = orderStatus;
            }

            if (orderEinrichtungZustaendigerBenutzerName !== undefined) {
                localVarQueryParameter['order[einrichtung.zustaendigerBenutzer.name]'] =
                    orderEinrichtungZustaendigerBenutzerName;
            }

            if (orderField102 !== undefined) {
                localVarQueryParameter['order[field102]'] = orderField102;
            }

            if (orderBerechnungsart !== undefined) {
                localVarQueryParameter['order[berechnungsart]'] = orderBerechnungsart;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Feststellungsantrag resource.
         * @summary Retrieves a Feststellungsantrag resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeststellungsantragItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFeststellungsantragItem', 'id', id);
            const localVarPath = `/api/feststellungsantrag/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the Feststellungsantrag resource.
         * @summary Removes the Feststellungsantrag resource.
         * @param {string} id Resource identifier
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jsonFormDeleteFeststellungsantragItem: async (
            id: string,
            uuid?: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('jsonFormDeleteFeststellungsantragItem', 'id', id);
            const localVarPath = `/api/feststellungsantrag/{id}/json/{uuid}`
                .replace(`{${'uuid'}}`, encodeURIComponent(String(uuid)))
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Feststellungsantrag resource.
         * @summary Retrieves a Feststellungsantrag resource.
         * @param {string} id Resource identifier
         * @param {string} [step]
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jsonFormGetFeststellungsantragItem: async (
            id: string,
            step?: string,
            objectType?: string,
            uuid?: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('jsonFormGetFeststellungsantragItem', 'id', id);
            const localVarPath = `/api/feststellungsantrag/{id}/json/{step}`
                .replace(`{${'step'}}`, encodeURIComponent(String(step)))
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (objectType !== undefined) {
                localVarQueryParameter['objectType'] = objectType;
            }

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Feststellungsantrag resource.
         * @summary Updates the Feststellungsantrag resource.
         * @param {string} id Resource identifier
         * @param {object} body The updated Feststellungsantrag resource
         * @param {string} [step]
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {boolean} [persist]
         * @param {Array<string>} [fields]
         * @param {boolean} [recalculateFields]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jsonFormPatchFeststellungsantragItem: async (
            id: string,
            body: object,
            step?: string,
            objectType?: string,
            uuid?: string,
            persist?: boolean,
            fields?: Array<string>,
            recalculateFields?: boolean,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('jsonFormPatchFeststellungsantragItem', 'id', id);
            // verify required parameter 'body' is not null or undefined
            assertParamExists('jsonFormPatchFeststellungsantragItem', 'body', body);
            const localVarPath = `/api/feststellungsantrag/{id}/json/{step}`
                .replace(`{${'step'}}`, encodeURIComponent(String(step)))
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (objectType !== undefined) {
                localVarQueryParameter['objectType'] = objectType;
            }

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }

            if (persist !== undefined) {
                localVarQueryParameter['persist'] = persist;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (recalculateFields !== undefined) {
                localVarQueryParameter['recalculateFields'] = recalculateFields;
            }

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Feststellungsantrag resource.
         * @summary Creates a Feststellungsantrag resource.
         * @param {FeststellungsantragJsonldFeststellungsantragCreate} feststellungsantragJsonldFeststellungsantragCreate The new Feststellungsantrag resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFeststellungsantragCollection: async (
            feststellungsantragJsonldFeststellungsantragCreate: FeststellungsantragJsonldFeststellungsantragCreate,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'feststellungsantragJsonldFeststellungsantragCreate' is not null or undefined
            assertParamExists(
                'postFeststellungsantragCollection',
                'feststellungsantragJsonldFeststellungsantragCreate',
                feststellungsantragJsonldFeststellungsantragCreate
            );
            const localVarPath = `/api/feststellungsantrag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                feststellungsantragJsonldFeststellungsantragCreate,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Feststellungsantrag resource.
         * @summary Retrieves a Feststellungsantrag resource.
         * @param {number} id
         * @param {string} action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workflowFeststellungsantragItem: async (
            id: number,
            action: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('workflowFeststellungsantragItem', 'id', id);
            // verify required parameter 'action' is not null or undefined
            assertParamExists('workflowFeststellungsantragItem', 'action', action);
            const localVarPath = `/api/feststellungsantrag/{id}/workflow/{action}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'action'}}`, encodeURIComponent(String(action)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * FeststellungsantragApi - functional programming interface
 * @export
 */
export const FeststellungsantragApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = FeststellungsantragApiAxiosParamCreator(configuration);
    return {
        /**
         * Removes the Feststellungsantrag resource.
         * @summary Removes the Feststellungsantrag resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFeststellungsantragItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFeststellungsantragItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Feststellungsantrag resource.
         * @summary Retrieves a Feststellungsantrag resource.
         * @param {number} v2
         * @param {string} id Resource identifier
         * @param {number} [v1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async diffFeststellungsantragItem(
            v2: number,
            id: string,
            v1?: number,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeststellungsantragFormDiffJsonldAntragFormDiff>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.diffFeststellungsantragItem(v2, id, v1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Feststellungsantrag resource.
         * @summary Retrieves a Feststellungsantrag resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filtersFeststellungsantragItem(
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<FeststellungsantragCollectionFiltersOutputJsonldCollectionFilters>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filtersFeststellungsantragItem(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Feststellungsantrag resources.
         * @summary Retrieves the collection of Feststellungsantrag resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {Array<string>} [qSearch]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {Array<string>} [berechnungsart]
         * @param {Array<string>} [antragJahr]
         * @param {string} [aufgabe]
         * @param {'asc' | 'desc'} [orderZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderEinrichtungAktenzeichen]
         * @param {'asc' | 'desc'} [orderEinrichtungName]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderEinrichtungZustaendigerBenutzerName]
         * @param {'asc' | 'desc'} [orderField102]
         * @param {'asc' | 'desc'} [orderBerechnungsart]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeststellungsantragCollection(
            page?: number,
            itemsPerPage?: number,
            qSearch?: Array<string>,
            zustaendigerBenutzer?: Array<number>,
            status?: string,
            status2?: Array<string>,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            berechnungsart?: Array<string>,
            antragJahr?: Array<string>,
            aufgabe?: string,
            orderZuletztEingereichtAt?: 'asc' | 'desc',
            orderEinrichtungAktenzeichen?: 'asc' | 'desc',
            orderEinrichtungName?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderEinrichtungZustaendigerBenutzerName?: 'asc' | 'desc',
            orderField102?: 'asc' | 'desc',
            orderBerechnungsart?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20012>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeststellungsantragCollection(
                page,
                itemsPerPage,
                qSearch,
                zustaendigerBenutzer,
                status,
                status2,
                einrichtung,
                einrichtung2,
                berechnungsart,
                antragJahr,
                aufgabe,
                orderZuletztEingereichtAt,
                orderEinrichtungAktenzeichen,
                orderEinrichtungName,
                orderStatus,
                orderEinrichtungZustaendigerBenutzerName,
                orderField102,
                orderBerechnungsart,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Feststellungsantrag resource.
         * @summary Retrieves a Feststellungsantrag resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeststellungsantragItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeststellungsantragJsonldFeststellungsantragRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeststellungsantragItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes the Feststellungsantrag resource.
         * @summary Removes the Feststellungsantrag resource.
         * @param {string} id Resource identifier
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jsonFormDeleteFeststellungsantragItem(
            id: string,
            uuid?: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jsonFormDeleteFeststellungsantragItem(
                id,
                uuid,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Feststellungsantrag resource.
         * @summary Retrieves a Feststellungsantrag resource.
         * @param {string} id Resource identifier
         * @param {string} [step]
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jsonFormGetFeststellungsantragItem(
            id: string,
            step?: string,
            objectType?: string,
            uuid?: string,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<FeststellungsantragFeststellungsantragJsonJsonldAntragJsonForm>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jsonFormGetFeststellungsantragItem(
                id,
                step,
                objectType,
                uuid,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Feststellungsantrag resource.
         * @summary Updates the Feststellungsantrag resource.
         * @param {string} id Resource identifier
         * @param {object} body The updated Feststellungsantrag resource
         * @param {string} [step]
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {boolean} [persist]
         * @param {Array<string>} [fields]
         * @param {boolean} [recalculateFields]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jsonFormPatchFeststellungsantragItem(
            id: string,
            body: object,
            step?: string,
            objectType?: string,
            uuid?: string,
            persist?: boolean,
            fields?: Array<string>,
            recalculateFields?: boolean,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<FeststellungsantragFeststellungsantragJsonJsonldAntragJsonForm>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jsonFormPatchFeststellungsantragItem(
                id,
                body,
                step,
                objectType,
                uuid,
                persist,
                fields,
                recalculateFields,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Feststellungsantrag resource.
         * @summary Creates a Feststellungsantrag resource.
         * @param {FeststellungsantragJsonldFeststellungsantragCreate} feststellungsantragJsonldFeststellungsantragCreate The new Feststellungsantrag resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postFeststellungsantragCollection(
            feststellungsantragJsonldFeststellungsantragCreate: FeststellungsantragJsonldFeststellungsantragCreate,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeststellungsantragJsonldFeststellungsantragRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postFeststellungsantragCollection(
                feststellungsantragJsonldFeststellungsantragCreate,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Feststellungsantrag resource.
         * @summary Retrieves a Feststellungsantrag resource.
         * @param {number} id
         * @param {string} action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workflowFeststellungsantragItem(
            id: number,
            action: string,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeststellungsantragJsonldFeststellungsantragRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workflowFeststellungsantragItem(
                id,
                action,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * FeststellungsantragApi - factory interface
 * @export
 */
export const FeststellungsantragApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = FeststellungsantragApiFp(configuration);
    return {
        /**
         * Removes the Feststellungsantrag resource.
         * @summary Removes the Feststellungsantrag resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeststellungsantragItem(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFeststellungsantragItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Feststellungsantrag resource.
         * @summary Retrieves a Feststellungsantrag resource.
         * @param {number} v2
         * @param {string} id Resource identifier
         * @param {number} [v1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        diffFeststellungsantragItem(
            v2: number,
            id: string,
            v1?: number,
            options?: any
        ): AxiosPromise<FeststellungsantragFormDiffJsonldAntragFormDiff> {
            return localVarFp
                .diffFeststellungsantragItem(v2, id, v1, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Feststellungsantrag resource.
         * @summary Retrieves a Feststellungsantrag resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filtersFeststellungsantragItem(
            options?: any
        ): AxiosPromise<FeststellungsantragCollectionFiltersOutputJsonldCollectionFilters> {
            return localVarFp.filtersFeststellungsantragItem(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Feststellungsantrag resources.
         * @summary Retrieves the collection of Feststellungsantrag resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {Array<string>} [qSearch]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {Array<string>} [berechnungsart]
         * @param {Array<string>} [antragJahr]
         * @param {string} [aufgabe]
         * @param {'asc' | 'desc'} [orderZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderEinrichtungAktenzeichen]
         * @param {'asc' | 'desc'} [orderEinrichtungName]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderEinrichtungZustaendigerBenutzerName]
         * @param {'asc' | 'desc'} [orderField102]
         * @param {'asc' | 'desc'} [orderBerechnungsart]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeststellungsantragCollection(
            page?: number,
            itemsPerPage?: number,
            qSearch?: Array<string>,
            zustaendigerBenutzer?: Array<number>,
            status?: string,
            status2?: Array<string>,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            berechnungsart?: Array<string>,
            antragJahr?: Array<string>,
            aufgabe?: string,
            orderZuletztEingereichtAt?: 'asc' | 'desc',
            orderEinrichtungAktenzeichen?: 'asc' | 'desc',
            orderEinrichtungName?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderEinrichtungZustaendigerBenutzerName?: 'asc' | 'desc',
            orderField102?: 'asc' | 'desc',
            orderBerechnungsart?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<InlineResponse20012> {
            return localVarFp
                .getFeststellungsantragCollection(
                    page,
                    itemsPerPage,
                    qSearch,
                    zustaendigerBenutzer,
                    status,
                    status2,
                    einrichtung,
                    einrichtung2,
                    berechnungsart,
                    antragJahr,
                    aufgabe,
                    orderZuletztEingereichtAt,
                    orderEinrichtungAktenzeichen,
                    orderEinrichtungName,
                    orderStatus,
                    orderEinrichtungZustaendigerBenutzerName,
                    orderField102,
                    orderBerechnungsart,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Feststellungsantrag resource.
         * @summary Retrieves a Feststellungsantrag resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeststellungsantragItem(
            id: string,
            options?: any
        ): AxiosPromise<FeststellungsantragJsonldFeststellungsantragRead> {
            return localVarFp.getFeststellungsantragItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the Feststellungsantrag resource.
         * @summary Removes the Feststellungsantrag resource.
         * @param {string} id Resource identifier
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jsonFormDeleteFeststellungsantragItem(id: string, uuid?: string, options?: any): AxiosPromise<void> {
            return localVarFp
                .jsonFormDeleteFeststellungsantragItem(id, uuid, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Feststellungsantrag resource.
         * @summary Retrieves a Feststellungsantrag resource.
         * @param {string} id Resource identifier
         * @param {string} [step]
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jsonFormGetFeststellungsantragItem(
            id: string,
            step?: string,
            objectType?: string,
            uuid?: string,
            options?: any
        ): AxiosPromise<FeststellungsantragFeststellungsantragJsonJsonldAntragJsonForm> {
            return localVarFp
                .jsonFormGetFeststellungsantragItem(id, step, objectType, uuid, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Updates the Feststellungsantrag resource.
         * @summary Updates the Feststellungsantrag resource.
         * @param {string} id Resource identifier
         * @param {object} body The updated Feststellungsantrag resource
         * @param {string} [step]
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {boolean} [persist]
         * @param {Array<string>} [fields]
         * @param {boolean} [recalculateFields]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jsonFormPatchFeststellungsantragItem(
            id: string,
            body: object,
            step?: string,
            objectType?: string,
            uuid?: string,
            persist?: boolean,
            fields?: Array<string>,
            recalculateFields?: boolean,
            options?: any
        ): AxiosPromise<FeststellungsantragFeststellungsantragJsonJsonldAntragJsonForm> {
            return localVarFp
                .jsonFormPatchFeststellungsantragItem(
                    id,
                    body,
                    step,
                    objectType,
                    uuid,
                    persist,
                    fields,
                    recalculateFields,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Creates a Feststellungsantrag resource.
         * @summary Creates a Feststellungsantrag resource.
         * @param {FeststellungsantragJsonldFeststellungsantragCreate} feststellungsantragJsonldFeststellungsantragCreate The new Feststellungsantrag resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFeststellungsantragCollection(
            feststellungsantragJsonldFeststellungsantragCreate: FeststellungsantragJsonldFeststellungsantragCreate,
            options?: any
        ): AxiosPromise<FeststellungsantragJsonldFeststellungsantragRead> {
            return localVarFp
                .postFeststellungsantragCollection(feststellungsantragJsonldFeststellungsantragCreate, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Feststellungsantrag resource.
         * @summary Retrieves a Feststellungsantrag resource.
         * @param {number} id
         * @param {string} action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workflowFeststellungsantragItem(
            id: number,
            action: string,
            options?: any
        ): AxiosPromise<FeststellungsantragJsonldFeststellungsantragRead> {
            return localVarFp
                .workflowFeststellungsantragItem(id, action, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeststellungsantragApi - object-oriented interface
 * @export
 * @class FeststellungsantragApi
 * @extends {BaseAPI}
 */
export class FeststellungsantragApi extends BaseAPI {
    /**
     * Removes the Feststellungsantrag resource.
     * @summary Removes the Feststellungsantrag resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeststellungsantragApi
     */
    public deleteFeststellungsantragItem(id: string, options?: AxiosRequestConfig) {
        return FeststellungsantragApiFp(this.configuration)
            .deleteFeststellungsantragItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Feststellungsantrag resource.
     * @summary Retrieves a Feststellungsantrag resource.
     * @param {number} v2
     * @param {string} id Resource identifier
     * @param {number} [v1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeststellungsantragApi
     */
    public diffFeststellungsantragItem(v2: number, id: string, v1?: number, options?: AxiosRequestConfig) {
        return FeststellungsantragApiFp(this.configuration)
            .diffFeststellungsantragItem(v2, id, v1, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Feststellungsantrag resource.
     * @summary Retrieves a Feststellungsantrag resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeststellungsantragApi
     */
    public filtersFeststellungsantragItem(options?: AxiosRequestConfig) {
        return FeststellungsantragApiFp(this.configuration)
            .filtersFeststellungsantragItem(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Feststellungsantrag resources.
     * @summary Retrieves the collection of Feststellungsantrag resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {Array<string>} [qSearch]
     * @param {Array<number>} [zustaendigerBenutzer]
     * @param {string} [status]
     * @param {Array<string>} [status2]
     * @param {string} [einrichtung]
     * @param {Array<string>} [einrichtung2]
     * @param {Array<string>} [berechnungsart]
     * @param {Array<string>} [antragJahr]
     * @param {string} [aufgabe]
     * @param {'asc' | 'desc'} [orderZuletztEingereichtAt]
     * @param {'asc' | 'desc'} [orderEinrichtungAktenzeichen]
     * @param {'asc' | 'desc'} [orderEinrichtungName]
     * @param {'asc' | 'desc'} [orderStatus]
     * @param {'asc' | 'desc'} [orderEinrichtungZustaendigerBenutzerName]
     * @param {'asc' | 'desc'} [orderField102]
     * @param {'asc' | 'desc'} [orderBerechnungsart]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeststellungsantragApi
     */
    public getFeststellungsantragCollection(
        page?: number,
        itemsPerPage?: number,
        qSearch?: Array<string>,
        zustaendigerBenutzer?: Array<number>,
        status?: string,
        status2?: Array<string>,
        einrichtung?: string,
        einrichtung2?: Array<string>,
        berechnungsart?: Array<string>,
        antragJahr?: Array<string>,
        aufgabe?: string,
        orderZuletztEingereichtAt?: 'asc' | 'desc',
        orderEinrichtungAktenzeichen?: 'asc' | 'desc',
        orderEinrichtungName?: 'asc' | 'desc',
        orderStatus?: 'asc' | 'desc',
        orderEinrichtungZustaendigerBenutzerName?: 'asc' | 'desc',
        orderField102?: 'asc' | 'desc',
        orderBerechnungsart?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return FeststellungsantragApiFp(this.configuration)
            .getFeststellungsantragCollection(
                page,
                itemsPerPage,
                qSearch,
                zustaendigerBenutzer,
                status,
                status2,
                einrichtung,
                einrichtung2,
                berechnungsart,
                antragJahr,
                aufgabe,
                orderZuletztEingereichtAt,
                orderEinrichtungAktenzeichen,
                orderEinrichtungName,
                orderStatus,
                orderEinrichtungZustaendigerBenutzerName,
                orderField102,
                orderBerechnungsart,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Feststellungsantrag resource.
     * @summary Retrieves a Feststellungsantrag resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeststellungsantragApi
     */
    public getFeststellungsantragItem(id: string, options?: AxiosRequestConfig) {
        return FeststellungsantragApiFp(this.configuration)
            .getFeststellungsantragItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the Feststellungsantrag resource.
     * @summary Removes the Feststellungsantrag resource.
     * @param {string} id Resource identifier
     * @param {string} [uuid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeststellungsantragApi
     */
    public jsonFormDeleteFeststellungsantragItem(id: string, uuid?: string, options?: AxiosRequestConfig) {
        return FeststellungsantragApiFp(this.configuration)
            .jsonFormDeleteFeststellungsantragItem(id, uuid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Feststellungsantrag resource.
     * @summary Retrieves a Feststellungsantrag resource.
     * @param {string} id Resource identifier
     * @param {string} [step]
     * @param {string} [objectType]
     * @param {string} [uuid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeststellungsantragApi
     */
    public jsonFormGetFeststellungsantragItem(
        id: string,
        step?: string,
        objectType?: string,
        uuid?: string,
        options?: AxiosRequestConfig
    ) {
        return FeststellungsantragApiFp(this.configuration)
            .jsonFormGetFeststellungsantragItem(id, step, objectType, uuid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Feststellungsantrag resource.
     * @summary Updates the Feststellungsantrag resource.
     * @param {string} id Resource identifier
     * @param {object} body The updated Feststellungsantrag resource
     * @param {string} [step]
     * @param {string} [objectType]
     * @param {string} [uuid]
     * @param {boolean} [persist]
     * @param {Array<string>} [fields]
     * @param {boolean} [recalculateFields]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeststellungsantragApi
     */
    public jsonFormPatchFeststellungsantragItem(
        id: string,
        body: object,
        step?: string,
        objectType?: string,
        uuid?: string,
        persist?: boolean,
        fields?: Array<string>,
        recalculateFields?: boolean,
        options?: AxiosRequestConfig
    ) {
        return FeststellungsantragApiFp(this.configuration)
            .jsonFormPatchFeststellungsantragItem(
                id,
                body,
                step,
                objectType,
                uuid,
                persist,
                fields,
                recalculateFields,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Feststellungsantrag resource.
     * @summary Creates a Feststellungsantrag resource.
     * @param {FeststellungsantragJsonldFeststellungsantragCreate} feststellungsantragJsonldFeststellungsantragCreate The new Feststellungsantrag resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeststellungsantragApi
     */
    public postFeststellungsantragCollection(
        feststellungsantragJsonldFeststellungsantragCreate: FeststellungsantragJsonldFeststellungsantragCreate,
        options?: AxiosRequestConfig
    ) {
        return FeststellungsantragApiFp(this.configuration)
            .postFeststellungsantragCollection(feststellungsantragJsonldFeststellungsantragCreate, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Feststellungsantrag resource.
     * @summary Retrieves a Feststellungsantrag resource.
     * @param {number} id
     * @param {string} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeststellungsantragApi
     */
    public workflowFeststellungsantragItem(id: number, action: string, options?: AxiosRequestConfig) {
        return FeststellungsantragApiFp(this.configuration)
            .workflowFeststellungsantragItem(id, action, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * FeststellungsantragBescheidungApi - axios parameter creator
 * @export
 */
export const FeststellungsantragBescheidungApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a FeststellungsantragBescheidung resource.
         * @summary Retrieves a FeststellungsantragBescheidung resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeststellungsantragBescheidungItem: async (
            id: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFeststellungsantragBescheidungItem', 'id', id);
            const localVarPath = `/api/feststellungsantrag-bescheidung/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the FeststellungsantragBescheidung resource.
         * @summary Updates the FeststellungsantragBescheidung resource.
         * @param {string} id Resource identifier
         * @param {FeststellungsantragBescheidungBescheidungSetGesendetAt} feststellungsantragBescheidungBescheidungSetGesendetAt The updated FeststellungsantragBescheidung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setGesendetAtFeststellungsantragBescheidungItem: async (
            id: string,
            feststellungsantragBescheidungBescheidungSetGesendetAt: FeststellungsantragBescheidungBescheidungSetGesendetAt,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setGesendetAtFeststellungsantragBescheidungItem', 'id', id);
            // verify required parameter 'feststellungsantragBescheidungBescheidungSetGesendetAt' is not null or undefined
            assertParamExists(
                'setGesendetAtFeststellungsantragBescheidungItem',
                'feststellungsantragBescheidungBescheidungSetGesendetAt',
                feststellungsantragBescheidungBescheidungSetGesendetAt
            );
            const localVarPath = `/api/feststellungsantrag-bescheidung/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                feststellungsantragBescheidungBescheidungSetGesendetAt,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * FeststellungsantragBescheidungApi - functional programming interface
 * @export
 */
export const FeststellungsantragBescheidungApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = FeststellungsantragBescheidungApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves a FeststellungsantragBescheidung resource.
         * @summary Retrieves a FeststellungsantragBescheidung resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeststellungsantragBescheidungItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeststellungsantragBescheidungJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeststellungsantragBescheidungItem(
                id,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the FeststellungsantragBescheidung resource.
         * @summary Updates the FeststellungsantragBescheidung resource.
         * @param {string} id Resource identifier
         * @param {FeststellungsantragBescheidungBescheidungSetGesendetAt} feststellungsantragBescheidungBescheidungSetGesendetAt The updated FeststellungsantragBescheidung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setGesendetAtFeststellungsantragBescheidungItem(
            id: string,
            feststellungsantragBescheidungBescheidungSetGesendetAt: FeststellungsantragBescheidungBescheidungSetGesendetAt,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setGesendetAtFeststellungsantragBescheidungItem(
                id,
                feststellungsantragBescheidungBescheidungSetGesendetAt,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * FeststellungsantragBescheidungApi - factory interface
 * @export
 */
export const FeststellungsantragBescheidungApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = FeststellungsantragBescheidungApiFp(configuration);
    return {
        /**
         * Retrieves a FeststellungsantragBescheidung resource.
         * @summary Retrieves a FeststellungsantragBescheidung resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeststellungsantragBescheidungItem(
            id: string,
            options?: any
        ): AxiosPromise<FeststellungsantragBescheidungJsonld> {
            return localVarFp
                .getFeststellungsantragBescheidungItem(id, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Updates the FeststellungsantragBescheidung resource.
         * @summary Updates the FeststellungsantragBescheidung resource.
         * @param {string} id Resource identifier
         * @param {FeststellungsantragBescheidungBescheidungSetGesendetAt} feststellungsantragBescheidungBescheidungSetGesendetAt The updated FeststellungsantragBescheidung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setGesendetAtFeststellungsantragBescheidungItem(
            id: string,
            feststellungsantragBescheidungBescheidungSetGesendetAt: FeststellungsantragBescheidungBescheidungSetGesendetAt,
            options?: any
        ): AxiosPromise<any> {
            return localVarFp
                .setGesendetAtFeststellungsantragBescheidungItem(
                    id,
                    feststellungsantragBescheidungBescheidungSetGesendetAt,
                    options
                )
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeststellungsantragBescheidungApi - object-oriented interface
 * @export
 * @class FeststellungsantragBescheidungApi
 * @extends {BaseAPI}
 */
export class FeststellungsantragBescheidungApi extends BaseAPI {
    /**
     * Retrieves a FeststellungsantragBescheidung resource.
     * @summary Retrieves a FeststellungsantragBescheidung resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeststellungsantragBescheidungApi
     */
    public getFeststellungsantragBescheidungItem(id: string, options?: AxiosRequestConfig) {
        return FeststellungsantragBescheidungApiFp(this.configuration)
            .getFeststellungsantragBescheidungItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the FeststellungsantragBescheidung resource.
     * @summary Updates the FeststellungsantragBescheidung resource.
     * @param {string} id Resource identifier
     * @param {FeststellungsantragBescheidungBescheidungSetGesendetAt} feststellungsantragBescheidungBescheidungSetGesendetAt The updated FeststellungsantragBescheidung resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeststellungsantragBescheidungApi
     */
    public setGesendetAtFeststellungsantragBescheidungItem(
        id: string,
        feststellungsantragBescheidungBescheidungSetGesendetAt: FeststellungsantragBescheidungBescheidungSetGesendetAt,
        options?: AxiosRequestConfig
    ) {
        return FeststellungsantragBescheidungApiFp(this.configuration)
            .setGesendetAtFeststellungsantragBescheidungItem(
                id,
                feststellungsantragBescheidungBescheidungSetGesendetAt,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * FeststellungsantragGegenpruefungApi - axios parameter creator
 * @export
 */
export const FeststellungsantragGegenpruefungApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of FeststellungsantragGegenpruefung resources.
         * @summary Retrieves the collection of FeststellungsantragGegenpruefung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeststellungsantragGegenpruefungCollection: async (
            page?: number,
            itemsPerPage?: number,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/feststellungsantrag-gegenpruefung`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a FeststellungsantragGegenpruefung resource.
         * @summary Retrieves a FeststellungsantragGegenpruefung resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeststellungsantragGegenpruefungItem: async (
            id: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFeststellungsantragGegenpruefungItem', 'id', id);
            const localVarPath = `/api/feststellungsantrag-gegenpruefung/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the FeststellungsantragGegenpruefung resource.
         * @summary Updates the FeststellungsantragGegenpruefung resource.
         * @param {string} id Resource identifier
         * @param {FeststellungsantragGegenpruefungGegenpruefungEdit} feststellungsantragGegenpruefungGegenpruefungEdit The updated FeststellungsantragGegenpruefung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchFeststellungsantragGegenpruefungItem: async (
            id: string,
            feststellungsantragGegenpruefungGegenpruefungEdit: FeststellungsantragGegenpruefungGegenpruefungEdit,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchFeststellungsantragGegenpruefungItem', 'id', id);
            // verify required parameter 'feststellungsantragGegenpruefungGegenpruefungEdit' is not null or undefined
            assertParamExists(
                'patchFeststellungsantragGegenpruefungItem',
                'feststellungsantragGegenpruefungGegenpruefungEdit',
                feststellungsantragGegenpruefungGegenpruefungEdit
            );
            const localVarPath = `/api/feststellungsantrag-gegenpruefung/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                feststellungsantragGegenpruefungGegenpruefungEdit,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * FeststellungsantragGegenpruefungApi - functional programming interface
 * @export
 */
export const FeststellungsantragGegenpruefungApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = FeststellungsantragGegenpruefungApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of FeststellungsantragGegenpruefung resources.
         * @summary Retrieves the collection of FeststellungsantragGegenpruefung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeststellungsantragGegenpruefungCollection(
            page?: number,
            itemsPerPage?: number,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20013>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeststellungsantragGegenpruefungCollection(
                page,
                itemsPerPage,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a FeststellungsantragGegenpruefung resource.
         * @summary Retrieves a FeststellungsantragGegenpruefung resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeststellungsantragGegenpruefungItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeststellungsantragGegenpruefungJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeststellungsantragGegenpruefungItem(
                id,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the FeststellungsantragGegenpruefung resource.
         * @summary Updates the FeststellungsantragGegenpruefung resource.
         * @param {string} id Resource identifier
         * @param {FeststellungsantragGegenpruefungGegenpruefungEdit} feststellungsantragGegenpruefungGegenpruefungEdit The updated FeststellungsantragGegenpruefung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchFeststellungsantragGegenpruefungItem(
            id: string,
            feststellungsantragGegenpruefungGegenpruefungEdit: FeststellungsantragGegenpruefungGegenpruefungEdit,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchFeststellungsantragGegenpruefungItem(
                id,
                feststellungsantragGegenpruefungGegenpruefungEdit,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * FeststellungsantragGegenpruefungApi - factory interface
 * @export
 */
export const FeststellungsantragGegenpruefungApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = FeststellungsantragGegenpruefungApiFp(configuration);
    return {
        /**
         * Retrieves the collection of FeststellungsantragGegenpruefung resources.
         * @summary Retrieves the collection of FeststellungsantragGegenpruefung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeststellungsantragGegenpruefungCollection(
            page?: number,
            itemsPerPage?: number,
            options?: any
        ): AxiosPromise<InlineResponse20013> {
            return localVarFp
                .getFeststellungsantragGegenpruefungCollection(page, itemsPerPage, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a FeststellungsantragGegenpruefung resource.
         * @summary Retrieves a FeststellungsantragGegenpruefung resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeststellungsantragGegenpruefungItem(
            id: string,
            options?: any
        ): AxiosPromise<FeststellungsantragGegenpruefungJsonld> {
            return localVarFp
                .getFeststellungsantragGegenpruefungItem(id, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Updates the FeststellungsantragGegenpruefung resource.
         * @summary Updates the FeststellungsantragGegenpruefung resource.
         * @param {string} id Resource identifier
         * @param {FeststellungsantragGegenpruefungGegenpruefungEdit} feststellungsantragGegenpruefungGegenpruefungEdit The updated FeststellungsantragGegenpruefung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchFeststellungsantragGegenpruefungItem(
            id: string,
            feststellungsantragGegenpruefungGegenpruefungEdit: FeststellungsantragGegenpruefungGegenpruefungEdit,
            options?: any
        ): AxiosPromise<any> {
            return localVarFp
                .patchFeststellungsantragGegenpruefungItem(
                    id,
                    feststellungsantragGegenpruefungGegenpruefungEdit,
                    options
                )
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeststellungsantragGegenpruefungApi - object-oriented interface
 * @export
 * @class FeststellungsantragGegenpruefungApi
 * @extends {BaseAPI}
 */
export class FeststellungsantragGegenpruefungApi extends BaseAPI {
    /**
     * Retrieves the collection of FeststellungsantragGegenpruefung resources.
     * @summary Retrieves the collection of FeststellungsantragGegenpruefung resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeststellungsantragGegenpruefungApi
     */
    public getFeststellungsantragGegenpruefungCollection(
        page?: number,
        itemsPerPage?: number,
        options?: AxiosRequestConfig
    ) {
        return FeststellungsantragGegenpruefungApiFp(this.configuration)
            .getFeststellungsantragGegenpruefungCollection(page, itemsPerPage, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a FeststellungsantragGegenpruefung resource.
     * @summary Retrieves a FeststellungsantragGegenpruefung resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeststellungsantragGegenpruefungApi
     */
    public getFeststellungsantragGegenpruefungItem(id: string, options?: AxiosRequestConfig) {
        return FeststellungsantragGegenpruefungApiFp(this.configuration)
            .getFeststellungsantragGegenpruefungItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the FeststellungsantragGegenpruefung resource.
     * @summary Updates the FeststellungsantragGegenpruefung resource.
     * @param {string} id Resource identifier
     * @param {FeststellungsantragGegenpruefungGegenpruefungEdit} feststellungsantragGegenpruefungGegenpruefungEdit The updated FeststellungsantragGegenpruefung resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeststellungsantragGegenpruefungApi
     */
    public patchFeststellungsantragGegenpruefungItem(
        id: string,
        feststellungsantragGegenpruefungGegenpruefungEdit: FeststellungsantragGegenpruefungGegenpruefungEdit,
        options?: AxiosRequestConfig
    ) {
        return FeststellungsantragGegenpruefungApiFp(this.configuration)
            .patchFeststellungsantragGegenpruefungItem(id, feststellungsantragGegenpruefungGegenpruefungEdit, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * FeststellungsantragVersionApi - axios parameter creator
 * @export
 */
export const FeststellungsantragVersionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of FeststellungsantragVersion resources.
         * @summary Retrieves the collection of FeststellungsantragVersion resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [antrag]
         * @param {Array<string>} [antrag2]
         * @param {'asc' | 'desc'} [orderAntragModifiedAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeststellungsantragVersionCollection: async (
            page?: number,
            itemsPerPage?: number,
            antrag?: string,
            antrag2?: Array<string>,
            orderAntragModifiedAt?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/feststellungsantrag-version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (antrag !== undefined) {
                localVarQueryParameter['antrag'] = antrag;
            }

            if (antrag2) {
                localVarQueryParameter['antrag[]'] = antrag2;
            }

            if (orderAntragModifiedAt !== undefined) {
                localVarQueryParameter['order[antragModifiedAt]'] = orderAntragModifiedAt;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a FeststellungsantragVersion resource.
         * @summary Retrieves a FeststellungsantragVersion resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeststellungsantragVersionItem: async (
            id: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFeststellungsantragVersionItem', 'id', id);
            const localVarPath = `/api/feststellungsantrag-version/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * FeststellungsantragVersionApi - functional programming interface
 * @export
 */
export const FeststellungsantragVersionApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = FeststellungsantragVersionApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of FeststellungsantragVersion resources.
         * @summary Retrieves the collection of FeststellungsantragVersion resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [antrag]
         * @param {Array<string>} [antrag2]
         * @param {'asc' | 'desc'} [orderAntragModifiedAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeststellungsantragVersionCollection(
            page?: number,
            itemsPerPage?: number,
            antrag?: string,
            antrag2?: Array<string>,
            orderAntragModifiedAt?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20014>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeststellungsantragVersionCollection(
                page,
                itemsPerPage,
                antrag,
                antrag2,
                orderAntragModifiedAt,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a FeststellungsantragVersion resource.
         * @summary Retrieves a FeststellungsantragVersion resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeststellungsantragVersionItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<FeststellungsantragVersionJsonldFeststellungsantragVersionRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeststellungsantragVersionItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * FeststellungsantragVersionApi - factory interface
 * @export
 */
export const FeststellungsantragVersionApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = FeststellungsantragVersionApiFp(configuration);
    return {
        /**
         * Retrieves the collection of FeststellungsantragVersion resources.
         * @summary Retrieves the collection of FeststellungsantragVersion resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [antrag]
         * @param {Array<string>} [antrag2]
         * @param {'asc' | 'desc'} [orderAntragModifiedAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeststellungsantragVersionCollection(
            page?: number,
            itemsPerPage?: number,
            antrag?: string,
            antrag2?: Array<string>,
            orderAntragModifiedAt?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<InlineResponse20014> {
            return localVarFp
                .getFeststellungsantragVersionCollection(
                    page,
                    itemsPerPage,
                    antrag,
                    antrag2,
                    orderAntragModifiedAt,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a FeststellungsantragVersion resource.
         * @summary Retrieves a FeststellungsantragVersion resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeststellungsantragVersionItem(
            id: string,
            options?: any
        ): AxiosPromise<FeststellungsantragVersionJsonldFeststellungsantragVersionRead> {
            return localVarFp
                .getFeststellungsantragVersionItem(id, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeststellungsantragVersionApi - object-oriented interface
 * @export
 * @class FeststellungsantragVersionApi
 * @extends {BaseAPI}
 */
export class FeststellungsantragVersionApi extends BaseAPI {
    /**
     * Retrieves the collection of FeststellungsantragVersion resources.
     * @summary Retrieves the collection of FeststellungsantragVersion resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [antrag]
     * @param {Array<string>} [antrag2]
     * @param {'asc' | 'desc'} [orderAntragModifiedAt]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeststellungsantragVersionApi
     */
    public getFeststellungsantragVersionCollection(
        page?: number,
        itemsPerPage?: number,
        antrag?: string,
        antrag2?: Array<string>,
        orderAntragModifiedAt?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return FeststellungsantragVersionApiFp(this.configuration)
            .getFeststellungsantragVersionCollection(
                page,
                itemsPerPage,
                antrag,
                antrag2,
                orderAntragModifiedAt,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a FeststellungsantragVersion resource.
     * @summary Retrieves a FeststellungsantragVersion resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeststellungsantragVersionApi
     */
    public getFeststellungsantragVersionItem(id: string, options?: AxiosRequestConfig) {
        return FeststellungsantragVersionApiFp(this.configuration)
            .getFeststellungsantragVersionItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * FeststellungsantragZurueckziehenApi - axios parameter creator
 * @export
 */
export const FeststellungsantragZurueckziehenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a FeststellungsantragZurueckziehen resource.
         * @summary Retrieves a FeststellungsantragZurueckziehen resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeststellungsantragZurueckziehenItem: async (
            id: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFeststellungsantragZurueckziehenItem', 'id', id);
            const localVarPath = `/api/feststellungsantrag-zurueckziehen/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a FeststellungsantragZurueckziehen resource.
         * @summary Creates a FeststellungsantragZurueckziehen resource.
         * @param {FeststellungsantragZurueckziehenJsonldZurueckziehenCreate} feststellungsantragZurueckziehenJsonldZurueckziehenCreate The new FeststellungsantragZurueckziehen resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFeststellungsantragZurueckziehenCollection: async (
            feststellungsantragZurueckziehenJsonldZurueckziehenCreate: FeststellungsantragZurueckziehenJsonldZurueckziehenCreate,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'feststellungsantragZurueckziehenJsonldZurueckziehenCreate' is not null or undefined
            assertParamExists(
                'postFeststellungsantragZurueckziehenCollection',
                'feststellungsantragZurueckziehenJsonldZurueckziehenCreate',
                feststellungsantragZurueckziehenJsonldZurueckziehenCreate
            );
            const localVarPath = `/api/feststellungsantrag-zurueckziehen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                feststellungsantragZurueckziehenJsonldZurueckziehenCreate,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the FeststellungsantragZurueckziehen resource.
         * @summary Updates the FeststellungsantragZurueckziehen resource.
         * @param {string} id Resource identifier
         * @param {FeststellungsantragZurueckziehenZurueckziehenSetEntscheidung} feststellungsantragZurueckziehenZurueckziehenSetEntscheidung The updated FeststellungsantragZurueckziehen resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEntscheidungFeststellungsantragZurueckziehenItem: async (
            id: string,
            feststellungsantragZurueckziehenZurueckziehenSetEntscheidung: FeststellungsantragZurueckziehenZurueckziehenSetEntscheidung,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setEntscheidungFeststellungsantragZurueckziehenItem', 'id', id);
            // verify required parameter 'feststellungsantragZurueckziehenZurueckziehenSetEntscheidung' is not null or undefined
            assertParamExists(
                'setEntscheidungFeststellungsantragZurueckziehenItem',
                'feststellungsantragZurueckziehenZurueckziehenSetEntscheidung',
                feststellungsantragZurueckziehenZurueckziehenSetEntscheidung
            );
            const localVarPath = `/api/feststellungsantrag-zurueckziehen/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                feststellungsantragZurueckziehenZurueckziehenSetEntscheidung,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * FeststellungsantragZurueckziehenApi - functional programming interface
 * @export
 */
export const FeststellungsantragZurueckziehenApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = FeststellungsantragZurueckziehenApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves a FeststellungsantragZurueckziehen resource.
         * @summary Retrieves a FeststellungsantragZurueckziehen resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeststellungsantragZurueckziehenItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeststellungsantragZurueckziehenJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeststellungsantragZurueckziehenItem(
                id,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a FeststellungsantragZurueckziehen resource.
         * @summary Creates a FeststellungsantragZurueckziehen resource.
         * @param {FeststellungsantragZurueckziehenJsonldZurueckziehenCreate} feststellungsantragZurueckziehenJsonldZurueckziehenCreate The new FeststellungsantragZurueckziehen resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postFeststellungsantragZurueckziehenCollection(
            feststellungsantragZurueckziehenJsonldZurueckziehenCreate: FeststellungsantragZurueckziehenJsonldZurueckziehenCreate,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeststellungsantragZurueckziehenJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postFeststellungsantragZurueckziehenCollection(
                feststellungsantragZurueckziehenJsonldZurueckziehenCreate,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the FeststellungsantragZurueckziehen resource.
         * @summary Updates the FeststellungsantragZurueckziehen resource.
         * @param {string} id Resource identifier
         * @param {FeststellungsantragZurueckziehenZurueckziehenSetEntscheidung} feststellungsantragZurueckziehenZurueckziehenSetEntscheidung The updated FeststellungsantragZurueckziehen resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setEntscheidungFeststellungsantragZurueckziehenItem(
            id: string,
            feststellungsantragZurueckziehenZurueckziehenSetEntscheidung: FeststellungsantragZurueckziehenZurueckziehenSetEntscheidung,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.setEntscheidungFeststellungsantragZurueckziehenItem(
                    id,
                    feststellungsantragZurueckziehenZurueckziehenSetEntscheidung,
                    options
                );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * FeststellungsantragZurueckziehenApi - factory interface
 * @export
 */
export const FeststellungsantragZurueckziehenApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = FeststellungsantragZurueckziehenApiFp(configuration);
    return {
        /**
         * Retrieves a FeststellungsantragZurueckziehen resource.
         * @summary Retrieves a FeststellungsantragZurueckziehen resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeststellungsantragZurueckziehenItem(
            id: string,
            options?: any
        ): AxiosPromise<FeststellungsantragZurueckziehenJsonld> {
            return localVarFp
                .getFeststellungsantragZurueckziehenItem(id, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Creates a FeststellungsantragZurueckziehen resource.
         * @summary Creates a FeststellungsantragZurueckziehen resource.
         * @param {FeststellungsantragZurueckziehenJsonldZurueckziehenCreate} feststellungsantragZurueckziehenJsonldZurueckziehenCreate The new FeststellungsantragZurueckziehen resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFeststellungsantragZurueckziehenCollection(
            feststellungsantragZurueckziehenJsonldZurueckziehenCreate: FeststellungsantragZurueckziehenJsonldZurueckziehenCreate,
            options?: any
        ): AxiosPromise<FeststellungsantragZurueckziehenJsonld> {
            return localVarFp
                .postFeststellungsantragZurueckziehenCollection(
                    feststellungsantragZurueckziehenJsonldZurueckziehenCreate,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Updates the FeststellungsantragZurueckziehen resource.
         * @summary Updates the FeststellungsantragZurueckziehen resource.
         * @param {string} id Resource identifier
         * @param {FeststellungsantragZurueckziehenZurueckziehenSetEntscheidung} feststellungsantragZurueckziehenZurueckziehenSetEntscheidung The updated FeststellungsantragZurueckziehen resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEntscheidungFeststellungsantragZurueckziehenItem(
            id: string,
            feststellungsantragZurueckziehenZurueckziehenSetEntscheidung: FeststellungsantragZurueckziehenZurueckziehenSetEntscheidung,
            options?: any
        ): AxiosPromise<any> {
            return localVarFp
                .setEntscheidungFeststellungsantragZurueckziehenItem(
                    id,
                    feststellungsantragZurueckziehenZurueckziehenSetEntscheidung,
                    options
                )
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeststellungsantragZurueckziehenApi - object-oriented interface
 * @export
 * @class FeststellungsantragZurueckziehenApi
 * @extends {BaseAPI}
 */
export class FeststellungsantragZurueckziehenApi extends BaseAPI {
    /**
     * Retrieves a FeststellungsantragZurueckziehen resource.
     * @summary Retrieves a FeststellungsantragZurueckziehen resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeststellungsantragZurueckziehenApi
     */
    public getFeststellungsantragZurueckziehenItem(id: string, options?: AxiosRequestConfig) {
        return FeststellungsantragZurueckziehenApiFp(this.configuration)
            .getFeststellungsantragZurueckziehenItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a FeststellungsantragZurueckziehen resource.
     * @summary Creates a FeststellungsantragZurueckziehen resource.
     * @param {FeststellungsantragZurueckziehenJsonldZurueckziehenCreate} feststellungsantragZurueckziehenJsonldZurueckziehenCreate The new FeststellungsantragZurueckziehen resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeststellungsantragZurueckziehenApi
     */
    public postFeststellungsantragZurueckziehenCollection(
        feststellungsantragZurueckziehenJsonldZurueckziehenCreate: FeststellungsantragZurueckziehenJsonldZurueckziehenCreate,
        options?: AxiosRequestConfig
    ) {
        return FeststellungsantragZurueckziehenApiFp(this.configuration)
            .postFeststellungsantragZurueckziehenCollection(
                feststellungsantragZurueckziehenJsonldZurueckziehenCreate,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the FeststellungsantragZurueckziehen resource.
     * @summary Updates the FeststellungsantragZurueckziehen resource.
     * @param {string} id Resource identifier
     * @param {FeststellungsantragZurueckziehenZurueckziehenSetEntscheidung} feststellungsantragZurueckziehenZurueckziehenSetEntscheidung The updated FeststellungsantragZurueckziehen resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeststellungsantragZurueckziehenApi
     */
    public setEntscheidungFeststellungsantragZurueckziehenItem(
        id: string,
        feststellungsantragZurueckziehenZurueckziehenSetEntscheidung: FeststellungsantragZurueckziehenZurueckziehenSetEntscheidung,
        options?: AxiosRequestConfig
    ) {
        return FeststellungsantragZurueckziehenApiFp(this.configuration)
            .setEntscheidungFeststellungsantragZurueckziehenItem(
                id,
                feststellungsantragZurueckziehenZurueckziehenSetEntscheidung,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * FremdkapitalzinsenBundesbankApi - axios parameter creator
 * @export
 */
export const FremdkapitalzinsenBundesbankApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of FremdkapitalzinsenBundesbank resources.
         * @summary Retrieves the collection of FremdkapitalzinsenBundesbank resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFremdkapitalzinsenBundesbankCollection: async (
            page?: number,
            itemsPerPage?: number,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/fremdkapitalzinsen-bundesbank`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a FremdkapitalzinsenBundesbank resource.
         * @summary Retrieves a FremdkapitalzinsenBundesbank resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFremdkapitalzinsenBundesbankItem: async (
            id: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFremdkapitalzinsenBundesbankItem', 'id', id);
            const localVarPath = `/api/fremdkapitalzinsen-bundesbank/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * FremdkapitalzinsenBundesbankApi - functional programming interface
 * @export
 */
export const FremdkapitalzinsenBundesbankApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = FremdkapitalzinsenBundesbankApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of FremdkapitalzinsenBundesbank resources.
         * @summary Retrieves the collection of FremdkapitalzinsenBundesbank resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFremdkapitalzinsenBundesbankCollection(
            page?: number,
            itemsPerPage?: number,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20015>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFremdkapitalzinsenBundesbankCollection(
                page,
                itemsPerPage,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a FremdkapitalzinsenBundesbank resource.
         * @summary Retrieves a FremdkapitalzinsenBundesbank resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFremdkapitalzinsenBundesbankItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FremdkapitalzinsenBundesbankJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFremdkapitalzinsenBundesbankItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * FremdkapitalzinsenBundesbankApi - factory interface
 * @export
 */
export const FremdkapitalzinsenBundesbankApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = FremdkapitalzinsenBundesbankApiFp(configuration);
    return {
        /**
         * Retrieves the collection of FremdkapitalzinsenBundesbank resources.
         * @summary Retrieves the collection of FremdkapitalzinsenBundesbank resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFremdkapitalzinsenBundesbankCollection(
            page?: number,
            itemsPerPage?: number,
            options?: any
        ): AxiosPromise<InlineResponse20015> {
            return localVarFp
                .getFremdkapitalzinsenBundesbankCollection(page, itemsPerPage, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a FremdkapitalzinsenBundesbank resource.
         * @summary Retrieves a FremdkapitalzinsenBundesbank resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFremdkapitalzinsenBundesbankItem(
            id: string,
            options?: any
        ): AxiosPromise<FremdkapitalzinsenBundesbankJsonld> {
            return localVarFp
                .getFremdkapitalzinsenBundesbankItem(id, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * FremdkapitalzinsenBundesbankApi - object-oriented interface
 * @export
 * @class FremdkapitalzinsenBundesbankApi
 * @extends {BaseAPI}
 */
export class FremdkapitalzinsenBundesbankApi extends BaseAPI {
    /**
     * Retrieves the collection of FremdkapitalzinsenBundesbank resources.
     * @summary Retrieves the collection of FremdkapitalzinsenBundesbank resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FremdkapitalzinsenBundesbankApi
     */
    public getFremdkapitalzinsenBundesbankCollection(
        page?: number,
        itemsPerPage?: number,
        options?: AxiosRequestConfig
    ) {
        return FremdkapitalzinsenBundesbankApiFp(this.configuration)
            .getFremdkapitalzinsenBundesbankCollection(page, itemsPerPage, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a FremdkapitalzinsenBundesbank resource.
     * @summary Retrieves a FremdkapitalzinsenBundesbank resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FremdkapitalzinsenBundesbankApi
     */
    public getFremdkapitalzinsenBundesbankItem(id: string, options?: AxiosRequestConfig) {
        return FremdkapitalzinsenBundesbankApiFp(this.configuration)
            .getFremdkapitalzinsenBundesbankItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * InvestorenmodellApi - axios parameter creator
 * @export
 */
export const InvestorenmodellApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes the Investorenmodell resource.
         * @summary Removes the Investorenmodell resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvestorenmodellItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteInvestorenmodellItem', 'id', id);
            const localVarPath = `/api/investorenmodell/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Investorenmodell resource.
         * @summary Retrieves a Investorenmodell resource.
         * @param {number} v2
         * @param {string} id Resource identifier
         * @param {number} [v1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        diffInvestorenmodellItem: async (
            v2: number,
            id: string,
            v1?: number,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'v2' is not null or undefined
            assertParamExists('diffInvestorenmodellItem', 'v2', v2);
            // verify required parameter 'id' is not null or undefined
            assertParamExists('diffInvestorenmodellItem', 'id', id);
            const localVarPath = `/api/investorenmodell/{id}/diff`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (v1 !== undefined) {
                localVarQueryParameter['v1'] = v1;
            }

            if (v2 !== undefined) {
                localVarQueryParameter['v2'] = v2;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Investorenmodell resource.
         * @summary Retrieves a Investorenmodell resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filtersInvestorenmodellItem: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/filters/investorenmodell`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Investorenmodell resources.
         * @summary Retrieves the collection of Investorenmodell resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {Array<string>} [qSearch]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {Array<string>} [berechnungsart]
         * @param {Array<string>} [antragJahr]
         * @param {string} [aufgabe]
         * @param {'asc' | 'desc'} [orderZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderEinrichtungAktenzeichen]
         * @param {'asc' | 'desc'} [orderEinrichtungName]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderEinrichtungZustaendigerBenutzerName]
         * @param {'asc' | 'desc'} [orderFestsetzungsantragField500]
         * @param {'asc' | 'desc'} [orderFestsetzungsantragFeststellungsantragBerechnungsart]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestorenmodellCollection: async (
            page?: number,
            itemsPerPage?: number,
            qSearch?: Array<string>,
            zustaendigerBenutzer?: Array<number>,
            status?: string,
            status2?: Array<string>,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            berechnungsart?: Array<string>,
            antragJahr?: Array<string>,
            aufgabe?: string,
            orderZuletztEingereichtAt?: 'asc' | 'desc',
            orderEinrichtungAktenzeichen?: 'asc' | 'desc',
            orderEinrichtungName?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderEinrichtungZustaendigerBenutzerName?: 'asc' | 'desc',
            orderFestsetzungsantragField500?: 'asc' | 'desc',
            orderFestsetzungsantragFeststellungsantragBerechnungsart?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/investorenmodell`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (qSearch) {
                localVarQueryParameter['qSearch[]'] = qSearch;
            }

            if (zustaendigerBenutzer) {
                localVarQueryParameter['zustaendigerBenutzer[]'] = zustaendigerBenutzer;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (status2) {
                localVarQueryParameter['status[]'] = status2;
            }

            if (einrichtung !== undefined) {
                localVarQueryParameter['einrichtung'] = einrichtung;
            }

            if (einrichtung2) {
                localVarQueryParameter['einrichtung[]'] = einrichtung2;
            }

            if (berechnungsart) {
                localVarQueryParameter['berechnungsart[]'] = berechnungsart;
            }

            if (antragJahr) {
                localVarQueryParameter['antragJahr[]'] = antragJahr;
            }

            if (aufgabe !== undefined) {
                localVarQueryParameter['aufgabe'] = aufgabe;
            }

            if (orderZuletztEingereichtAt !== undefined) {
                localVarQueryParameter['order[zuletztEingereichtAt]'] = orderZuletztEingereichtAt;
            }

            if (orderEinrichtungAktenzeichen !== undefined) {
                localVarQueryParameter['order[einrichtung.aktenzeichen]'] = orderEinrichtungAktenzeichen;
            }

            if (orderEinrichtungName !== undefined) {
                localVarQueryParameter['order[einrichtung.name]'] = orderEinrichtungName;
            }

            if (orderStatus !== undefined) {
                localVarQueryParameter['order[status]'] = orderStatus;
            }

            if (orderEinrichtungZustaendigerBenutzerName !== undefined) {
                localVarQueryParameter['order[einrichtung.zustaendigerBenutzer.name]'] =
                    orderEinrichtungZustaendigerBenutzerName;
            }

            if (orderFestsetzungsantragField500 !== undefined) {
                localVarQueryParameter['order[festsetzungsantrag.field500]'] = orderFestsetzungsantragField500;
            }

            if (orderFestsetzungsantragFeststellungsantragBerechnungsart !== undefined) {
                localVarQueryParameter['order[festsetzungsantrag.feststellungsantrag.berechnungsart]'] =
                    orderFestsetzungsantragFeststellungsantragBerechnungsart;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Investorenmodell resource.
         * @summary Retrieves a Investorenmodell resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestorenmodellItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInvestorenmodellItem', 'id', id);
            const localVarPath = `/api/investorenmodell/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the Investorenmodell resource.
         * @summary Removes the Investorenmodell resource.
         * @param {string} id Resource identifier
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jsonFormDeleteInvestorenmodellItem: async (
            id: string,
            uuid?: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('jsonFormDeleteInvestorenmodellItem', 'id', id);
            const localVarPath = `/api/investorenmodell/{id}/json/{uuid}`
                .replace(`{${'uuid'}}`, encodeURIComponent(String(uuid)))
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Investorenmodell resource.
         * @summary Retrieves a Investorenmodell resource.
         * @param {string} id Resource identifier
         * @param {string} [step]
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jsonFormGetInvestorenmodellItem: async (
            id: string,
            step?: string,
            objectType?: string,
            uuid?: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('jsonFormGetInvestorenmodellItem', 'id', id);
            const localVarPath = `/api/investorenmodell/{id}/json/{step}`
                .replace(`{${'step'}}`, encodeURIComponent(String(step)))
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (objectType !== undefined) {
                localVarQueryParameter['objectType'] = objectType;
            }

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Investorenmodell resource.
         * @summary Updates the Investorenmodell resource.
         * @param {string} id Resource identifier
         * @param {object} body The updated Investorenmodell resource
         * @param {string} [step]
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {boolean} [persist]
         * @param {Array<string>} [fields]
         * @param {boolean} [recalculateFields]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jsonFormPatchInvestorenmodellItem: async (
            id: string,
            body: object,
            step?: string,
            objectType?: string,
            uuid?: string,
            persist?: boolean,
            fields?: Array<string>,
            recalculateFields?: boolean,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('jsonFormPatchInvestorenmodellItem', 'id', id);
            // verify required parameter 'body' is not null or undefined
            assertParamExists('jsonFormPatchInvestorenmodellItem', 'body', body);
            const localVarPath = `/api/investorenmodell/{id}/json/{step}`
                .replace(`{${'step'}}`, encodeURIComponent(String(step)))
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (objectType !== undefined) {
                localVarQueryParameter['objectType'] = objectType;
            }

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }

            if (persist !== undefined) {
                localVarQueryParameter['persist'] = persist;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (recalculateFields !== undefined) {
                localVarQueryParameter['recalculateFields'] = recalculateFields;
            }

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Investorenmodell resource.
         * @summary Creates a Investorenmodell resource.
         * @param {InvestorenmodellJsonldInvestorenmodellCreate} investorenmodellJsonldInvestorenmodellCreate The new Investorenmodell resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInvestorenmodellCollection: async (
            investorenmodellJsonldInvestorenmodellCreate: InvestorenmodellJsonldInvestorenmodellCreate,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'investorenmodellJsonldInvestorenmodellCreate' is not null or undefined
            assertParamExists(
                'postInvestorenmodellCollection',
                'investorenmodellJsonldInvestorenmodellCreate',
                investorenmodellJsonldInvestorenmodellCreate
            );
            const localVarPath = `/api/investorenmodell`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                investorenmodellJsonldInvestorenmodellCreate,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Investorenmodell resource.
         * @summary Retrieves a Investorenmodell resource.
         * @param {number} id
         * @param {string} action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workflowInvestorenmodellItem: async (
            id: number,
            action: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('workflowInvestorenmodellItem', 'id', id);
            // verify required parameter 'action' is not null or undefined
            assertParamExists('workflowInvestorenmodellItem', 'action', action);
            const localVarPath = `/api/investorenmodell/{id}/workflow/{action}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'action'}}`, encodeURIComponent(String(action)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * InvestorenmodellApi - functional programming interface
 * @export
 */
export const InvestorenmodellApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = InvestorenmodellApiAxiosParamCreator(configuration);
    return {
        /**
         * Removes the Investorenmodell resource.
         * @summary Removes the Investorenmodell resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInvestorenmodellItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInvestorenmodellItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Investorenmodell resource.
         * @summary Retrieves a Investorenmodell resource.
         * @param {number} v2
         * @param {string} id Resource identifier
         * @param {number} [v1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async diffInvestorenmodellItem(
            v2: number,
            id: string,
            v1?: number,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestorenmodellFormDiffJsonldAntragFormDiff>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.diffInvestorenmodellItem(v2, id, v1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Investorenmodell resource.
         * @summary Retrieves a Investorenmodell resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filtersInvestorenmodellItem(
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<InvestorenmodellCollectionFiltersOutputJsonldCollectionFilters>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filtersInvestorenmodellItem(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Investorenmodell resources.
         * @summary Retrieves the collection of Investorenmodell resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {Array<string>} [qSearch]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {Array<string>} [berechnungsart]
         * @param {Array<string>} [antragJahr]
         * @param {string} [aufgabe]
         * @param {'asc' | 'desc'} [orderZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderEinrichtungAktenzeichen]
         * @param {'asc' | 'desc'} [orderEinrichtungName]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderEinrichtungZustaendigerBenutzerName]
         * @param {'asc' | 'desc'} [orderFestsetzungsantragField500]
         * @param {'asc' | 'desc'} [orderFestsetzungsantragFeststellungsantragBerechnungsart]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvestorenmodellCollection(
            page?: number,
            itemsPerPage?: number,
            qSearch?: Array<string>,
            zustaendigerBenutzer?: Array<number>,
            status?: string,
            status2?: Array<string>,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            berechnungsart?: Array<string>,
            antragJahr?: Array<string>,
            aufgabe?: string,
            orderZuletztEingereichtAt?: 'asc' | 'desc',
            orderEinrichtungAktenzeichen?: 'asc' | 'desc',
            orderEinrichtungName?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderEinrichtungZustaendigerBenutzerName?: 'asc' | 'desc',
            orderFestsetzungsantragField500?: 'asc' | 'desc',
            orderFestsetzungsantragFeststellungsantragBerechnungsart?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20016>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvestorenmodellCollection(
                page,
                itemsPerPage,
                qSearch,
                zustaendigerBenutzer,
                status,
                status2,
                einrichtung,
                einrichtung2,
                berechnungsart,
                antragJahr,
                aufgabe,
                orderZuletztEingereichtAt,
                orderEinrichtungAktenzeichen,
                orderEinrichtungName,
                orderStatus,
                orderEinrichtungZustaendigerBenutzerName,
                orderFestsetzungsantragField500,
                orderFestsetzungsantragFeststellungsantragBerechnungsart,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Investorenmodell resource.
         * @summary Retrieves a Investorenmodell resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvestorenmodellItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestorenmodellJsonldInvestorenmodellRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvestorenmodellItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes the Investorenmodell resource.
         * @summary Removes the Investorenmodell resource.
         * @param {string} id Resource identifier
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jsonFormDeleteInvestorenmodellItem(
            id: string,
            uuid?: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jsonFormDeleteInvestorenmodellItem(
                id,
                uuid,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Investorenmodell resource.
         * @summary Retrieves a Investorenmodell resource.
         * @param {string} id Resource identifier
         * @param {string} [step]
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jsonFormGetInvestorenmodellItem(
            id: string,
            step?: string,
            objectType?: string,
            uuid?: string,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<InvestorenmodellInvestorenmodellJsonJsonldAntragJsonForm>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jsonFormGetInvestorenmodellItem(
                id,
                step,
                objectType,
                uuid,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Investorenmodell resource.
         * @summary Updates the Investorenmodell resource.
         * @param {string} id Resource identifier
         * @param {object} body The updated Investorenmodell resource
         * @param {string} [step]
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {boolean} [persist]
         * @param {Array<string>} [fields]
         * @param {boolean} [recalculateFields]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jsonFormPatchInvestorenmodellItem(
            id: string,
            body: object,
            step?: string,
            objectType?: string,
            uuid?: string,
            persist?: boolean,
            fields?: Array<string>,
            recalculateFields?: boolean,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<InvestorenmodellInvestorenmodellJsonJsonldAntragJsonForm>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jsonFormPatchInvestorenmodellItem(
                id,
                body,
                step,
                objectType,
                uuid,
                persist,
                fields,
                recalculateFields,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Investorenmodell resource.
         * @summary Creates a Investorenmodell resource.
         * @param {InvestorenmodellJsonldInvestorenmodellCreate} investorenmodellJsonldInvestorenmodellCreate The new Investorenmodell resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postInvestorenmodellCollection(
            investorenmodellJsonldInvestorenmodellCreate: InvestorenmodellJsonldInvestorenmodellCreate,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestorenmodellJsonldInvestorenmodellRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postInvestorenmodellCollection(
                investorenmodellJsonldInvestorenmodellCreate,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Investorenmodell resource.
         * @summary Retrieves a Investorenmodell resource.
         * @param {number} id
         * @param {string} action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workflowInvestorenmodellItem(
            id: number,
            action: string,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestorenmodellJsonldInvestorenmodellRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workflowInvestorenmodellItem(id, action, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * InvestorenmodellApi - factory interface
 * @export
 */
export const InvestorenmodellApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = InvestorenmodellApiFp(configuration);
    return {
        /**
         * Removes the Investorenmodell resource.
         * @summary Removes the Investorenmodell resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvestorenmodellItem(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteInvestorenmodellItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Investorenmodell resource.
         * @summary Retrieves a Investorenmodell resource.
         * @param {number} v2
         * @param {string} id Resource identifier
         * @param {number} [v1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        diffInvestorenmodellItem(
            v2: number,
            id: string,
            v1?: number,
            options?: any
        ): AxiosPromise<InvestorenmodellFormDiffJsonldAntragFormDiff> {
            return localVarFp.diffInvestorenmodellItem(v2, id, v1, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Investorenmodell resource.
         * @summary Retrieves a Investorenmodell resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filtersInvestorenmodellItem(
            options?: any
        ): AxiosPromise<InvestorenmodellCollectionFiltersOutputJsonldCollectionFilters> {
            return localVarFp.filtersInvestorenmodellItem(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Investorenmodell resources.
         * @summary Retrieves the collection of Investorenmodell resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {Array<string>} [qSearch]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {Array<string>} [berechnungsart]
         * @param {Array<string>} [antragJahr]
         * @param {string} [aufgabe]
         * @param {'asc' | 'desc'} [orderZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderEinrichtungAktenzeichen]
         * @param {'asc' | 'desc'} [orderEinrichtungName]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderEinrichtungZustaendigerBenutzerName]
         * @param {'asc' | 'desc'} [orderFestsetzungsantragField500]
         * @param {'asc' | 'desc'} [orderFestsetzungsantragFeststellungsantragBerechnungsart]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestorenmodellCollection(
            page?: number,
            itemsPerPage?: number,
            qSearch?: Array<string>,
            zustaendigerBenutzer?: Array<number>,
            status?: string,
            status2?: Array<string>,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            berechnungsart?: Array<string>,
            antragJahr?: Array<string>,
            aufgabe?: string,
            orderZuletztEingereichtAt?: 'asc' | 'desc',
            orderEinrichtungAktenzeichen?: 'asc' | 'desc',
            orderEinrichtungName?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderEinrichtungZustaendigerBenutzerName?: 'asc' | 'desc',
            orderFestsetzungsantragField500?: 'asc' | 'desc',
            orderFestsetzungsantragFeststellungsantragBerechnungsart?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<InlineResponse20016> {
            return localVarFp
                .getInvestorenmodellCollection(
                    page,
                    itemsPerPage,
                    qSearch,
                    zustaendigerBenutzer,
                    status,
                    status2,
                    einrichtung,
                    einrichtung2,
                    berechnungsart,
                    antragJahr,
                    aufgabe,
                    orderZuletztEingereichtAt,
                    orderEinrichtungAktenzeichen,
                    orderEinrichtungName,
                    orderStatus,
                    orderEinrichtungZustaendigerBenutzerName,
                    orderFestsetzungsantragField500,
                    orderFestsetzungsantragFeststellungsantragBerechnungsart,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Investorenmodell resource.
         * @summary Retrieves a Investorenmodell resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestorenmodellItem(id: string, options?: any): AxiosPromise<InvestorenmodellJsonldInvestorenmodellRead> {
            return localVarFp.getInvestorenmodellItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the Investorenmodell resource.
         * @summary Removes the Investorenmodell resource.
         * @param {string} id Resource identifier
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jsonFormDeleteInvestorenmodellItem(id: string, uuid?: string, options?: any): AxiosPromise<void> {
            return localVarFp
                .jsonFormDeleteInvestorenmodellItem(id, uuid, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Investorenmodell resource.
         * @summary Retrieves a Investorenmodell resource.
         * @param {string} id Resource identifier
         * @param {string} [step]
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jsonFormGetInvestorenmodellItem(
            id: string,
            step?: string,
            objectType?: string,
            uuid?: string,
            options?: any
        ): AxiosPromise<InvestorenmodellInvestorenmodellJsonJsonldAntragJsonForm> {
            return localVarFp
                .jsonFormGetInvestorenmodellItem(id, step, objectType, uuid, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Updates the Investorenmodell resource.
         * @summary Updates the Investorenmodell resource.
         * @param {string} id Resource identifier
         * @param {object} body The updated Investorenmodell resource
         * @param {string} [step]
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {boolean} [persist]
         * @param {Array<string>} [fields]
         * @param {boolean} [recalculateFields]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jsonFormPatchInvestorenmodellItem(
            id: string,
            body: object,
            step?: string,
            objectType?: string,
            uuid?: string,
            persist?: boolean,
            fields?: Array<string>,
            recalculateFields?: boolean,
            options?: any
        ): AxiosPromise<InvestorenmodellInvestorenmodellJsonJsonldAntragJsonForm> {
            return localVarFp
                .jsonFormPatchInvestorenmodellItem(
                    id,
                    body,
                    step,
                    objectType,
                    uuid,
                    persist,
                    fields,
                    recalculateFields,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Creates a Investorenmodell resource.
         * @summary Creates a Investorenmodell resource.
         * @param {InvestorenmodellJsonldInvestorenmodellCreate} investorenmodellJsonldInvestorenmodellCreate The new Investorenmodell resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInvestorenmodellCollection(
            investorenmodellJsonldInvestorenmodellCreate: InvestorenmodellJsonldInvestorenmodellCreate,
            options?: any
        ): AxiosPromise<InvestorenmodellJsonldInvestorenmodellRead> {
            return localVarFp
                .postInvestorenmodellCollection(investorenmodellJsonldInvestorenmodellCreate, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Investorenmodell resource.
         * @summary Retrieves a Investorenmodell resource.
         * @param {number} id
         * @param {string} action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workflowInvestorenmodellItem(
            id: number,
            action: string,
            options?: any
        ): AxiosPromise<InvestorenmodellJsonldInvestorenmodellRead> {
            return localVarFp
                .workflowInvestorenmodellItem(id, action, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvestorenmodellApi - object-oriented interface
 * @export
 * @class InvestorenmodellApi
 * @extends {BaseAPI}
 */
export class InvestorenmodellApi extends BaseAPI {
    /**
     * Removes the Investorenmodell resource.
     * @summary Removes the Investorenmodell resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorenmodellApi
     */
    public deleteInvestorenmodellItem(id: string, options?: AxiosRequestConfig) {
        return InvestorenmodellApiFp(this.configuration)
            .deleteInvestorenmodellItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Investorenmodell resource.
     * @summary Retrieves a Investorenmodell resource.
     * @param {number} v2
     * @param {string} id Resource identifier
     * @param {number} [v1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorenmodellApi
     */
    public diffInvestorenmodellItem(v2: number, id: string, v1?: number, options?: AxiosRequestConfig) {
        return InvestorenmodellApiFp(this.configuration)
            .diffInvestorenmodellItem(v2, id, v1, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Investorenmodell resource.
     * @summary Retrieves a Investorenmodell resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorenmodellApi
     */
    public filtersInvestorenmodellItem(options?: AxiosRequestConfig) {
        return InvestorenmodellApiFp(this.configuration)
            .filtersInvestorenmodellItem(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Investorenmodell resources.
     * @summary Retrieves the collection of Investorenmodell resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {Array<string>} [qSearch]
     * @param {Array<number>} [zustaendigerBenutzer]
     * @param {string} [status]
     * @param {Array<string>} [status2]
     * @param {string} [einrichtung]
     * @param {Array<string>} [einrichtung2]
     * @param {Array<string>} [berechnungsart]
     * @param {Array<string>} [antragJahr]
     * @param {string} [aufgabe]
     * @param {'asc' | 'desc'} [orderZuletztEingereichtAt]
     * @param {'asc' | 'desc'} [orderEinrichtungAktenzeichen]
     * @param {'asc' | 'desc'} [orderEinrichtungName]
     * @param {'asc' | 'desc'} [orderStatus]
     * @param {'asc' | 'desc'} [orderEinrichtungZustaendigerBenutzerName]
     * @param {'asc' | 'desc'} [orderFestsetzungsantragField500]
     * @param {'asc' | 'desc'} [orderFestsetzungsantragFeststellungsantragBerechnungsart]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorenmodellApi
     */
    public getInvestorenmodellCollection(
        page?: number,
        itemsPerPage?: number,
        qSearch?: Array<string>,
        zustaendigerBenutzer?: Array<number>,
        status?: string,
        status2?: Array<string>,
        einrichtung?: string,
        einrichtung2?: Array<string>,
        berechnungsart?: Array<string>,
        antragJahr?: Array<string>,
        aufgabe?: string,
        orderZuletztEingereichtAt?: 'asc' | 'desc',
        orderEinrichtungAktenzeichen?: 'asc' | 'desc',
        orderEinrichtungName?: 'asc' | 'desc',
        orderStatus?: 'asc' | 'desc',
        orderEinrichtungZustaendigerBenutzerName?: 'asc' | 'desc',
        orderFestsetzungsantragField500?: 'asc' | 'desc',
        orderFestsetzungsantragFeststellungsantragBerechnungsart?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return InvestorenmodellApiFp(this.configuration)
            .getInvestorenmodellCollection(
                page,
                itemsPerPage,
                qSearch,
                zustaendigerBenutzer,
                status,
                status2,
                einrichtung,
                einrichtung2,
                berechnungsart,
                antragJahr,
                aufgabe,
                orderZuletztEingereichtAt,
                orderEinrichtungAktenzeichen,
                orderEinrichtungName,
                orderStatus,
                orderEinrichtungZustaendigerBenutzerName,
                orderFestsetzungsantragField500,
                orderFestsetzungsantragFeststellungsantragBerechnungsart,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Investorenmodell resource.
     * @summary Retrieves a Investorenmodell resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorenmodellApi
     */
    public getInvestorenmodellItem(id: string, options?: AxiosRequestConfig) {
        return InvestorenmodellApiFp(this.configuration)
            .getInvestorenmodellItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the Investorenmodell resource.
     * @summary Removes the Investorenmodell resource.
     * @param {string} id Resource identifier
     * @param {string} [uuid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorenmodellApi
     */
    public jsonFormDeleteInvestorenmodellItem(id: string, uuid?: string, options?: AxiosRequestConfig) {
        return InvestorenmodellApiFp(this.configuration)
            .jsonFormDeleteInvestorenmodellItem(id, uuid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Investorenmodell resource.
     * @summary Retrieves a Investorenmodell resource.
     * @param {string} id Resource identifier
     * @param {string} [step]
     * @param {string} [objectType]
     * @param {string} [uuid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorenmodellApi
     */
    public jsonFormGetInvestorenmodellItem(
        id: string,
        step?: string,
        objectType?: string,
        uuid?: string,
        options?: AxiosRequestConfig
    ) {
        return InvestorenmodellApiFp(this.configuration)
            .jsonFormGetInvestorenmodellItem(id, step, objectType, uuid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Investorenmodell resource.
     * @summary Updates the Investorenmodell resource.
     * @param {string} id Resource identifier
     * @param {object} body The updated Investorenmodell resource
     * @param {string} [step]
     * @param {string} [objectType]
     * @param {string} [uuid]
     * @param {boolean} [persist]
     * @param {Array<string>} [fields]
     * @param {boolean} [recalculateFields]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorenmodellApi
     */
    public jsonFormPatchInvestorenmodellItem(
        id: string,
        body: object,
        step?: string,
        objectType?: string,
        uuid?: string,
        persist?: boolean,
        fields?: Array<string>,
        recalculateFields?: boolean,
        options?: AxiosRequestConfig
    ) {
        return InvestorenmodellApiFp(this.configuration)
            .jsonFormPatchInvestorenmodellItem(
                id,
                body,
                step,
                objectType,
                uuid,
                persist,
                fields,
                recalculateFields,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Investorenmodell resource.
     * @summary Creates a Investorenmodell resource.
     * @param {InvestorenmodellJsonldInvestorenmodellCreate} investorenmodellJsonldInvestorenmodellCreate The new Investorenmodell resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorenmodellApi
     */
    public postInvestorenmodellCollection(
        investorenmodellJsonldInvestorenmodellCreate: InvestorenmodellJsonldInvestorenmodellCreate,
        options?: AxiosRequestConfig
    ) {
        return InvestorenmodellApiFp(this.configuration)
            .postInvestorenmodellCollection(investorenmodellJsonldInvestorenmodellCreate, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Investorenmodell resource.
     * @summary Retrieves a Investorenmodell resource.
     * @param {number} id
     * @param {string} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorenmodellApi
     */
    public workflowInvestorenmodellItem(id: number, action: string, options?: AxiosRequestConfig) {
        return InvestorenmodellApiFp(this.configuration)
            .workflowInvestorenmodellItem(id, action, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * InvestorenmodellBescheidungApi - axios parameter creator
 * @export
 */
export const InvestorenmodellBescheidungApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a InvestorenmodellBescheidung resource.
         * @summary Retrieves a InvestorenmodellBescheidung resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestorenmodellBescheidungItem: async (
            id: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInvestorenmodellBescheidungItem', 'id', id);
            const localVarPath = `/api/investorenmodell-bescheidung/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the InvestorenmodellBescheidung resource.
         * @summary Updates the InvestorenmodellBescheidung resource.
         * @param {string} id Resource identifier
         * @param {InvestorenmodellBescheidungBescheidungSetGesendetAt} investorenmodellBescheidungBescheidungSetGesendetAt The updated InvestorenmodellBescheidung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setGesendetAtInvestorenmodellBescheidungItem: async (
            id: string,
            investorenmodellBescheidungBescheidungSetGesendetAt: InvestorenmodellBescheidungBescheidungSetGesendetAt,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setGesendetAtInvestorenmodellBescheidungItem', 'id', id);
            // verify required parameter 'investorenmodellBescheidungBescheidungSetGesendetAt' is not null or undefined
            assertParamExists(
                'setGesendetAtInvestorenmodellBescheidungItem',
                'investorenmodellBescheidungBescheidungSetGesendetAt',
                investorenmodellBescheidungBescheidungSetGesendetAt
            );
            const localVarPath = `/api/investorenmodell-bescheidung/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                investorenmodellBescheidungBescheidungSetGesendetAt,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * InvestorenmodellBescheidungApi - functional programming interface
 * @export
 */
export const InvestorenmodellBescheidungApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = InvestorenmodellBescheidungApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves a InvestorenmodellBescheidung resource.
         * @summary Retrieves a InvestorenmodellBescheidung resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvestorenmodellBescheidungItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestorenmodellBescheidungJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvestorenmodellBescheidungItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the InvestorenmodellBescheidung resource.
         * @summary Updates the InvestorenmodellBescheidung resource.
         * @param {string} id Resource identifier
         * @param {InvestorenmodellBescheidungBescheidungSetGesendetAt} investorenmodellBescheidungBescheidungSetGesendetAt The updated InvestorenmodellBescheidung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setGesendetAtInvestorenmodellBescheidungItem(
            id: string,
            investorenmodellBescheidungBescheidungSetGesendetAt: InvestorenmodellBescheidungBescheidungSetGesendetAt,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setGesendetAtInvestorenmodellBescheidungItem(
                id,
                investorenmodellBescheidungBescheidungSetGesendetAt,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * InvestorenmodellBescheidungApi - factory interface
 * @export
 */
export const InvestorenmodellBescheidungApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = InvestorenmodellBescheidungApiFp(configuration);
    return {
        /**
         * Retrieves a InvestorenmodellBescheidung resource.
         * @summary Retrieves a InvestorenmodellBescheidung resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestorenmodellBescheidungItem(id: string, options?: any): AxiosPromise<InvestorenmodellBescheidungJsonld> {
            return localVarFp
                .getInvestorenmodellBescheidungItem(id, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Updates the InvestorenmodellBescheidung resource.
         * @summary Updates the InvestorenmodellBescheidung resource.
         * @param {string} id Resource identifier
         * @param {InvestorenmodellBescheidungBescheidungSetGesendetAt} investorenmodellBescheidungBescheidungSetGesendetAt The updated InvestorenmodellBescheidung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setGesendetAtInvestorenmodellBescheidungItem(
            id: string,
            investorenmodellBescheidungBescheidungSetGesendetAt: InvestorenmodellBescheidungBescheidungSetGesendetAt,
            options?: any
        ): AxiosPromise<any> {
            return localVarFp
                .setGesendetAtInvestorenmodellBescheidungItem(
                    id,
                    investorenmodellBescheidungBescheidungSetGesendetAt,
                    options
                )
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvestorenmodellBescheidungApi - object-oriented interface
 * @export
 * @class InvestorenmodellBescheidungApi
 * @extends {BaseAPI}
 */
export class InvestorenmodellBescheidungApi extends BaseAPI {
    /**
     * Retrieves a InvestorenmodellBescheidung resource.
     * @summary Retrieves a InvestorenmodellBescheidung resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorenmodellBescheidungApi
     */
    public getInvestorenmodellBescheidungItem(id: string, options?: AxiosRequestConfig) {
        return InvestorenmodellBescheidungApiFp(this.configuration)
            .getInvestorenmodellBescheidungItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the InvestorenmodellBescheidung resource.
     * @summary Updates the InvestorenmodellBescheidung resource.
     * @param {string} id Resource identifier
     * @param {InvestorenmodellBescheidungBescheidungSetGesendetAt} investorenmodellBescheidungBescheidungSetGesendetAt The updated InvestorenmodellBescheidung resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorenmodellBescheidungApi
     */
    public setGesendetAtInvestorenmodellBescheidungItem(
        id: string,
        investorenmodellBescheidungBescheidungSetGesendetAt: InvestorenmodellBescheidungBescheidungSetGesendetAt,
        options?: AxiosRequestConfig
    ) {
        return InvestorenmodellBescheidungApiFp(this.configuration)
            .setGesendetAtInvestorenmodellBescheidungItem(
                id,
                investorenmodellBescheidungBescheidungSetGesendetAt,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * InvestorenmodellGegenpruefungApi - axios parameter creator
 * @export
 */
export const InvestorenmodellGegenpruefungApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of InvestorenmodellGegenpruefung resources.
         * @summary Retrieves the collection of InvestorenmodellGegenpruefung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestorenmodellGegenpruefungCollection: async (
            page?: number,
            itemsPerPage?: number,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/investorenmodell-gegenpruefung`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a InvestorenmodellGegenpruefung resource.
         * @summary Retrieves a InvestorenmodellGegenpruefung resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestorenmodellGegenpruefungItem: async (
            id: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInvestorenmodellGegenpruefungItem', 'id', id);
            const localVarPath = `/api/investorenmodell-gegenpruefung/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the InvestorenmodellGegenpruefung resource.
         * @summary Updates the InvestorenmodellGegenpruefung resource.
         * @param {string} id Resource identifier
         * @param {InvestorenmodellGegenpruefungGegenpruefungEdit} investorenmodellGegenpruefungGegenpruefungEdit The updated InvestorenmodellGegenpruefung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchInvestorenmodellGegenpruefungItem: async (
            id: string,
            investorenmodellGegenpruefungGegenpruefungEdit: InvestorenmodellGegenpruefungGegenpruefungEdit,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchInvestorenmodellGegenpruefungItem', 'id', id);
            // verify required parameter 'investorenmodellGegenpruefungGegenpruefungEdit' is not null or undefined
            assertParamExists(
                'patchInvestorenmodellGegenpruefungItem',
                'investorenmodellGegenpruefungGegenpruefungEdit',
                investorenmodellGegenpruefungGegenpruefungEdit
            );
            const localVarPath = `/api/investorenmodell-gegenpruefung/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                investorenmodellGegenpruefungGegenpruefungEdit,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * InvestorenmodellGegenpruefungApi - functional programming interface
 * @export
 */
export const InvestorenmodellGegenpruefungApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = InvestorenmodellGegenpruefungApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of InvestorenmodellGegenpruefung resources.
         * @summary Retrieves the collection of InvestorenmodellGegenpruefung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvestorenmodellGegenpruefungCollection(
            page?: number,
            itemsPerPage?: number,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20017>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvestorenmodellGegenpruefungCollection(
                page,
                itemsPerPage,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a InvestorenmodellGegenpruefung resource.
         * @summary Retrieves a InvestorenmodellGegenpruefung resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvestorenmodellGegenpruefungItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestorenmodellGegenpruefungJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvestorenmodellGegenpruefungItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the InvestorenmodellGegenpruefung resource.
         * @summary Updates the InvestorenmodellGegenpruefung resource.
         * @param {string} id Resource identifier
         * @param {InvestorenmodellGegenpruefungGegenpruefungEdit} investorenmodellGegenpruefungGegenpruefungEdit The updated InvestorenmodellGegenpruefung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchInvestorenmodellGegenpruefungItem(
            id: string,
            investorenmodellGegenpruefungGegenpruefungEdit: InvestorenmodellGegenpruefungGegenpruefungEdit,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchInvestorenmodellGegenpruefungItem(
                id,
                investorenmodellGegenpruefungGegenpruefungEdit,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * InvestorenmodellGegenpruefungApi - factory interface
 * @export
 */
export const InvestorenmodellGegenpruefungApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = InvestorenmodellGegenpruefungApiFp(configuration);
    return {
        /**
         * Retrieves the collection of InvestorenmodellGegenpruefung resources.
         * @summary Retrieves the collection of InvestorenmodellGegenpruefung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestorenmodellGegenpruefungCollection(
            page?: number,
            itemsPerPage?: number,
            options?: any
        ): AxiosPromise<InlineResponse20017> {
            return localVarFp
                .getInvestorenmodellGegenpruefungCollection(page, itemsPerPage, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a InvestorenmodellGegenpruefung resource.
         * @summary Retrieves a InvestorenmodellGegenpruefung resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestorenmodellGegenpruefungItem(
            id: string,
            options?: any
        ): AxiosPromise<InvestorenmodellGegenpruefungJsonld> {
            return localVarFp
                .getInvestorenmodellGegenpruefungItem(id, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Updates the InvestorenmodellGegenpruefung resource.
         * @summary Updates the InvestorenmodellGegenpruefung resource.
         * @param {string} id Resource identifier
         * @param {InvestorenmodellGegenpruefungGegenpruefungEdit} investorenmodellGegenpruefungGegenpruefungEdit The updated InvestorenmodellGegenpruefung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchInvestorenmodellGegenpruefungItem(
            id: string,
            investorenmodellGegenpruefungGegenpruefungEdit: InvestorenmodellGegenpruefungGegenpruefungEdit,
            options?: any
        ): AxiosPromise<any> {
            return localVarFp
                .patchInvestorenmodellGegenpruefungItem(id, investorenmodellGegenpruefungGegenpruefungEdit, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvestorenmodellGegenpruefungApi - object-oriented interface
 * @export
 * @class InvestorenmodellGegenpruefungApi
 * @extends {BaseAPI}
 */
export class InvestorenmodellGegenpruefungApi extends BaseAPI {
    /**
     * Retrieves the collection of InvestorenmodellGegenpruefung resources.
     * @summary Retrieves the collection of InvestorenmodellGegenpruefung resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorenmodellGegenpruefungApi
     */
    public getInvestorenmodellGegenpruefungCollection(
        page?: number,
        itemsPerPage?: number,
        options?: AxiosRequestConfig
    ) {
        return InvestorenmodellGegenpruefungApiFp(this.configuration)
            .getInvestorenmodellGegenpruefungCollection(page, itemsPerPage, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a InvestorenmodellGegenpruefung resource.
     * @summary Retrieves a InvestorenmodellGegenpruefung resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorenmodellGegenpruefungApi
     */
    public getInvestorenmodellGegenpruefungItem(id: string, options?: AxiosRequestConfig) {
        return InvestorenmodellGegenpruefungApiFp(this.configuration)
            .getInvestorenmodellGegenpruefungItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the InvestorenmodellGegenpruefung resource.
     * @summary Updates the InvestorenmodellGegenpruefung resource.
     * @param {string} id Resource identifier
     * @param {InvestorenmodellGegenpruefungGegenpruefungEdit} investorenmodellGegenpruefungGegenpruefungEdit The updated InvestorenmodellGegenpruefung resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorenmodellGegenpruefungApi
     */
    public patchInvestorenmodellGegenpruefungItem(
        id: string,
        investorenmodellGegenpruefungGegenpruefungEdit: InvestorenmodellGegenpruefungGegenpruefungEdit,
        options?: AxiosRequestConfig
    ) {
        return InvestorenmodellGegenpruefungApiFp(this.configuration)
            .patchInvestorenmodellGegenpruefungItem(id, investorenmodellGegenpruefungGegenpruefungEdit, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * InvestorenmodellVersionApi - axios parameter creator
 * @export
 */
export const InvestorenmodellVersionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of InvestorenmodellVersion resources.
         * @summary Retrieves the collection of InvestorenmodellVersion resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [antrag]
         * @param {Array<string>} [antrag2]
         * @param {'asc' | 'desc'} [orderAntragModifiedAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestorenmodellVersionCollection: async (
            page?: number,
            itemsPerPage?: number,
            antrag?: string,
            antrag2?: Array<string>,
            orderAntragModifiedAt?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/investorenmodell-version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (antrag !== undefined) {
                localVarQueryParameter['antrag'] = antrag;
            }

            if (antrag2) {
                localVarQueryParameter['antrag[]'] = antrag2;
            }

            if (orderAntragModifiedAt !== undefined) {
                localVarQueryParameter['order[antragModifiedAt]'] = orderAntragModifiedAt;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a InvestorenmodellVersion resource.
         * @summary Retrieves a InvestorenmodellVersion resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestorenmodellVersionItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInvestorenmodellVersionItem', 'id', id);
            const localVarPath = `/api/investorenmodell-version/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * InvestorenmodellVersionApi - functional programming interface
 * @export
 */
export const InvestorenmodellVersionApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = InvestorenmodellVersionApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of InvestorenmodellVersion resources.
         * @summary Retrieves the collection of InvestorenmodellVersion resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [antrag]
         * @param {Array<string>} [antrag2]
         * @param {'asc' | 'desc'} [orderAntragModifiedAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvestorenmodellVersionCollection(
            page?: number,
            itemsPerPage?: number,
            antrag?: string,
            antrag2?: Array<string>,
            orderAntragModifiedAt?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20018>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvestorenmodellVersionCollection(
                page,
                itemsPerPage,
                antrag,
                antrag2,
                orderAntragModifiedAt,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a InvestorenmodellVersion resource.
         * @summary Retrieves a InvestorenmodellVersion resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvestorenmodellVersionItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<InvestorenmodellVersionJsonldInvestorenmodellVersionRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvestorenmodellVersionItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * InvestorenmodellVersionApi - factory interface
 * @export
 */
export const InvestorenmodellVersionApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = InvestorenmodellVersionApiFp(configuration);
    return {
        /**
         * Retrieves the collection of InvestorenmodellVersion resources.
         * @summary Retrieves the collection of InvestorenmodellVersion resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [antrag]
         * @param {Array<string>} [antrag2]
         * @param {'asc' | 'desc'} [orderAntragModifiedAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestorenmodellVersionCollection(
            page?: number,
            itemsPerPage?: number,
            antrag?: string,
            antrag2?: Array<string>,
            orderAntragModifiedAt?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<InlineResponse20018> {
            return localVarFp
                .getInvestorenmodellVersionCollection(
                    page,
                    itemsPerPage,
                    antrag,
                    antrag2,
                    orderAntragModifiedAt,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a InvestorenmodellVersion resource.
         * @summary Retrieves a InvestorenmodellVersion resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestorenmodellVersionItem(
            id: string,
            options?: any
        ): AxiosPromise<InvestorenmodellVersionJsonldInvestorenmodellVersionRead> {
            return localVarFp.getInvestorenmodellVersionItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvestorenmodellVersionApi - object-oriented interface
 * @export
 * @class InvestorenmodellVersionApi
 * @extends {BaseAPI}
 */
export class InvestorenmodellVersionApi extends BaseAPI {
    /**
     * Retrieves the collection of InvestorenmodellVersion resources.
     * @summary Retrieves the collection of InvestorenmodellVersion resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [antrag]
     * @param {Array<string>} [antrag2]
     * @param {'asc' | 'desc'} [orderAntragModifiedAt]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorenmodellVersionApi
     */
    public getInvestorenmodellVersionCollection(
        page?: number,
        itemsPerPage?: number,
        antrag?: string,
        antrag2?: Array<string>,
        orderAntragModifiedAt?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return InvestorenmodellVersionApiFp(this.configuration)
            .getInvestorenmodellVersionCollection(page, itemsPerPage, antrag, antrag2, orderAntragModifiedAt, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a InvestorenmodellVersion resource.
     * @summary Retrieves a InvestorenmodellVersion resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorenmodellVersionApi
     */
    public getInvestorenmodellVersionItem(id: string, options?: AxiosRequestConfig) {
        return InvestorenmodellVersionApiFp(this.configuration)
            .getInvestorenmodellVersionItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * InvestorenmodellZurueckziehenApi - axios parameter creator
 * @export
 */
export const InvestorenmodellZurueckziehenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a InvestorenmodellZurueckziehen resource.
         * @summary Retrieves a InvestorenmodellZurueckziehen resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestorenmodellZurueckziehenItem: async (
            id: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInvestorenmodellZurueckziehenItem', 'id', id);
            const localVarPath = `/api/investorenmodell-zurueckziehen/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a InvestorenmodellZurueckziehen resource.
         * @summary Creates a InvestorenmodellZurueckziehen resource.
         * @param {InvestorenmodellZurueckziehenJsonldZurueckziehenCreate} investorenmodellZurueckziehenJsonldZurueckziehenCreate The new InvestorenmodellZurueckziehen resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInvestorenmodellZurueckziehenCollection: async (
            investorenmodellZurueckziehenJsonldZurueckziehenCreate: InvestorenmodellZurueckziehenJsonldZurueckziehenCreate,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'investorenmodellZurueckziehenJsonldZurueckziehenCreate' is not null or undefined
            assertParamExists(
                'postInvestorenmodellZurueckziehenCollection',
                'investorenmodellZurueckziehenJsonldZurueckziehenCreate',
                investorenmodellZurueckziehenJsonldZurueckziehenCreate
            );
            const localVarPath = `/api/investorenmodell-zurueckziehen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                investorenmodellZurueckziehenJsonldZurueckziehenCreate,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the InvestorenmodellZurueckziehen resource.
         * @summary Updates the InvestorenmodellZurueckziehen resource.
         * @param {string} id Resource identifier
         * @param {InvestorenmodellZurueckziehenZurueckziehenSetEntscheidung} investorenmodellZurueckziehenZurueckziehenSetEntscheidung The updated InvestorenmodellZurueckziehen resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEntscheidungInvestorenmodellZurueckziehenItem: async (
            id: string,
            investorenmodellZurueckziehenZurueckziehenSetEntscheidung: InvestorenmodellZurueckziehenZurueckziehenSetEntscheidung,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setEntscheidungInvestorenmodellZurueckziehenItem', 'id', id);
            // verify required parameter 'investorenmodellZurueckziehenZurueckziehenSetEntscheidung' is not null or undefined
            assertParamExists(
                'setEntscheidungInvestorenmodellZurueckziehenItem',
                'investorenmodellZurueckziehenZurueckziehenSetEntscheidung',
                investorenmodellZurueckziehenZurueckziehenSetEntscheidung
            );
            const localVarPath = `/api/investorenmodell-zurueckziehen/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                investorenmodellZurueckziehenZurueckziehenSetEntscheidung,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * InvestorenmodellZurueckziehenApi - functional programming interface
 * @export
 */
export const InvestorenmodellZurueckziehenApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = InvestorenmodellZurueckziehenApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves a InvestorenmodellZurueckziehen resource.
         * @summary Retrieves a InvestorenmodellZurueckziehen resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvestorenmodellZurueckziehenItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestorenmodellZurueckziehenJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvestorenmodellZurueckziehenItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a InvestorenmodellZurueckziehen resource.
         * @summary Creates a InvestorenmodellZurueckziehen resource.
         * @param {InvestorenmodellZurueckziehenJsonldZurueckziehenCreate} investorenmodellZurueckziehenJsonldZurueckziehenCreate The new InvestorenmodellZurueckziehen resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postInvestorenmodellZurueckziehenCollection(
            investorenmodellZurueckziehenJsonldZurueckziehenCreate: InvestorenmodellZurueckziehenJsonldZurueckziehenCreate,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestorenmodellZurueckziehenJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postInvestorenmodellZurueckziehenCollection(
                investorenmodellZurueckziehenJsonldZurueckziehenCreate,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the InvestorenmodellZurueckziehen resource.
         * @summary Updates the InvestorenmodellZurueckziehen resource.
         * @param {string} id Resource identifier
         * @param {InvestorenmodellZurueckziehenZurueckziehenSetEntscheidung} investorenmodellZurueckziehenZurueckziehenSetEntscheidung The updated InvestorenmodellZurueckziehen resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setEntscheidungInvestorenmodellZurueckziehenItem(
            id: string,
            investorenmodellZurueckziehenZurueckziehenSetEntscheidung: InvestorenmodellZurueckziehenZurueckziehenSetEntscheidung,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setEntscheidungInvestorenmodellZurueckziehenItem(
                id,
                investorenmodellZurueckziehenZurueckziehenSetEntscheidung,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * InvestorenmodellZurueckziehenApi - factory interface
 * @export
 */
export const InvestorenmodellZurueckziehenApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = InvestorenmodellZurueckziehenApiFp(configuration);
    return {
        /**
         * Retrieves a InvestorenmodellZurueckziehen resource.
         * @summary Retrieves a InvestorenmodellZurueckziehen resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestorenmodellZurueckziehenItem(
            id: string,
            options?: any
        ): AxiosPromise<InvestorenmodellZurueckziehenJsonld> {
            return localVarFp
                .getInvestorenmodellZurueckziehenItem(id, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Creates a InvestorenmodellZurueckziehen resource.
         * @summary Creates a InvestorenmodellZurueckziehen resource.
         * @param {InvestorenmodellZurueckziehenJsonldZurueckziehenCreate} investorenmodellZurueckziehenJsonldZurueckziehenCreate The new InvestorenmodellZurueckziehen resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInvestorenmodellZurueckziehenCollection(
            investorenmodellZurueckziehenJsonldZurueckziehenCreate: InvestorenmodellZurueckziehenJsonldZurueckziehenCreate,
            options?: any
        ): AxiosPromise<InvestorenmodellZurueckziehenJsonld> {
            return localVarFp
                .postInvestorenmodellZurueckziehenCollection(
                    investorenmodellZurueckziehenJsonldZurueckziehenCreate,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Updates the InvestorenmodellZurueckziehen resource.
         * @summary Updates the InvestorenmodellZurueckziehen resource.
         * @param {string} id Resource identifier
         * @param {InvestorenmodellZurueckziehenZurueckziehenSetEntscheidung} investorenmodellZurueckziehenZurueckziehenSetEntscheidung The updated InvestorenmodellZurueckziehen resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEntscheidungInvestorenmodellZurueckziehenItem(
            id: string,
            investorenmodellZurueckziehenZurueckziehenSetEntscheidung: InvestorenmodellZurueckziehenZurueckziehenSetEntscheidung,
            options?: any
        ): AxiosPromise<any> {
            return localVarFp
                .setEntscheidungInvestorenmodellZurueckziehenItem(
                    id,
                    investorenmodellZurueckziehenZurueckziehenSetEntscheidung,
                    options
                )
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvestorenmodellZurueckziehenApi - object-oriented interface
 * @export
 * @class InvestorenmodellZurueckziehenApi
 * @extends {BaseAPI}
 */
export class InvestorenmodellZurueckziehenApi extends BaseAPI {
    /**
     * Retrieves a InvestorenmodellZurueckziehen resource.
     * @summary Retrieves a InvestorenmodellZurueckziehen resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorenmodellZurueckziehenApi
     */
    public getInvestorenmodellZurueckziehenItem(id: string, options?: AxiosRequestConfig) {
        return InvestorenmodellZurueckziehenApiFp(this.configuration)
            .getInvestorenmodellZurueckziehenItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a InvestorenmodellZurueckziehen resource.
     * @summary Creates a InvestorenmodellZurueckziehen resource.
     * @param {InvestorenmodellZurueckziehenJsonldZurueckziehenCreate} investorenmodellZurueckziehenJsonldZurueckziehenCreate The new InvestorenmodellZurueckziehen resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorenmodellZurueckziehenApi
     */
    public postInvestorenmodellZurueckziehenCollection(
        investorenmodellZurueckziehenJsonldZurueckziehenCreate: InvestorenmodellZurueckziehenJsonldZurueckziehenCreate,
        options?: AxiosRequestConfig
    ) {
        return InvestorenmodellZurueckziehenApiFp(this.configuration)
            .postInvestorenmodellZurueckziehenCollection(
                investorenmodellZurueckziehenJsonldZurueckziehenCreate,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the InvestorenmodellZurueckziehen resource.
     * @summary Updates the InvestorenmodellZurueckziehen resource.
     * @param {string} id Resource identifier
     * @param {InvestorenmodellZurueckziehenZurueckziehenSetEntscheidung} investorenmodellZurueckziehenZurueckziehenSetEntscheidung The updated InvestorenmodellZurueckziehen resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorenmodellZurueckziehenApi
     */
    public setEntscheidungInvestorenmodellZurueckziehenItem(
        id: string,
        investorenmodellZurueckziehenZurueckziehenSetEntscheidung: InvestorenmodellZurueckziehenZurueckziehenSetEntscheidung,
        options?: AxiosRequestConfig
    ) {
        return InvestorenmodellZurueckziehenApiFp(this.configuration)
            .setEntscheidungInvestorenmodellZurueckziehenItem(
                id,
                investorenmodellZurueckziehenZurueckziehenSetEntscheidung,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * LandschaftsverbandApi - axios parameter creator
 * @export
 */
export const LandschaftsverbandApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of Landschaftsverband resources.
         * @summary Retrieves the collection of Landschaftsverband resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [kuerzel]
         * @param {Array<string>} [kuerzel2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLandschaftsverbandCollection: async (
            page?: number,
            itemsPerPage?: number,
            kuerzel?: string,
            kuerzel2?: Array<string>,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/landschaftsverband`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (kuerzel !== undefined) {
                localVarQueryParameter['kuerzel'] = kuerzel;
            }

            if (kuerzel2) {
                localVarQueryParameter['kuerzel[]'] = kuerzel2;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Landschaftsverband resource.
         * @summary Retrieves a Landschaftsverband resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLandschaftsverbandItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLandschaftsverbandItem', 'id', id);
            const localVarPath = `/api/landschaftsverband/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Landschaftsverband resource.
         * @summary Replaces the Landschaftsverband resource.
         * @param {string} id Resource identifier
         * @param {LandschaftsverbandJsonldLandschaftsverbandWrite} landschaftsverbandJsonldLandschaftsverbandWrite The updated Landschaftsverband resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLandschaftsverbandItem: async (
            id: string,
            landschaftsverbandJsonldLandschaftsverbandWrite: LandschaftsverbandJsonldLandschaftsverbandWrite,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putLandschaftsverbandItem', 'id', id);
            // verify required parameter 'landschaftsverbandJsonldLandschaftsverbandWrite' is not null or undefined
            assertParamExists(
                'putLandschaftsverbandItem',
                'landschaftsverbandJsonldLandschaftsverbandWrite',
                landschaftsverbandJsonldLandschaftsverbandWrite
            );
            const localVarPath = `/api/landschaftsverband/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                landschaftsverbandJsonldLandschaftsverbandWrite,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * LandschaftsverbandApi - functional programming interface
 * @export
 */
export const LandschaftsverbandApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = LandschaftsverbandApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of Landschaftsverband resources.
         * @summary Retrieves the collection of Landschaftsverband resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [kuerzel]
         * @param {Array<string>} [kuerzel2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLandschaftsverbandCollection(
            page?: number,
            itemsPerPage?: number,
            kuerzel?: string,
            kuerzel2?: Array<string>,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20019>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLandschaftsverbandCollection(
                page,
                itemsPerPage,
                kuerzel,
                kuerzel2,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Landschaftsverband resource.
         * @summary Retrieves a Landschaftsverband resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLandschaftsverbandItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LandschaftsverbandJsonldLandschaftsverbandRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLandschaftsverbandItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Landschaftsverband resource.
         * @summary Replaces the Landschaftsverband resource.
         * @param {string} id Resource identifier
         * @param {LandschaftsverbandJsonldLandschaftsverbandWrite} landschaftsverbandJsonldLandschaftsverbandWrite The updated Landschaftsverband resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putLandschaftsverbandItem(
            id: string,
            landschaftsverbandJsonldLandschaftsverbandWrite: LandschaftsverbandJsonldLandschaftsverbandWrite,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LandschaftsverbandJsonldLandschaftsverbandRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putLandschaftsverbandItem(
                id,
                landschaftsverbandJsonldLandschaftsverbandWrite,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * LandschaftsverbandApi - factory interface
 * @export
 */
export const LandschaftsverbandApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = LandschaftsverbandApiFp(configuration);
    return {
        /**
         * Retrieves the collection of Landschaftsverband resources.
         * @summary Retrieves the collection of Landschaftsverband resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [kuerzel]
         * @param {Array<string>} [kuerzel2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLandschaftsverbandCollection(
            page?: number,
            itemsPerPage?: number,
            kuerzel?: string,
            kuerzel2?: Array<string>,
            options?: any
        ): AxiosPromise<InlineResponse20019> {
            return localVarFp
                .getLandschaftsverbandCollection(page, itemsPerPage, kuerzel, kuerzel2, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Landschaftsverband resource.
         * @summary Retrieves a Landschaftsverband resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLandschaftsverbandItem(
            id: string,
            options?: any
        ): AxiosPromise<LandschaftsverbandJsonldLandschaftsverbandRead> {
            return localVarFp.getLandschaftsverbandItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Landschaftsverband resource.
         * @summary Replaces the Landschaftsverband resource.
         * @param {string} id Resource identifier
         * @param {LandschaftsverbandJsonldLandschaftsverbandWrite} landschaftsverbandJsonldLandschaftsverbandWrite The updated Landschaftsverband resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLandschaftsverbandItem(
            id: string,
            landschaftsverbandJsonldLandschaftsverbandWrite: LandschaftsverbandJsonldLandschaftsverbandWrite,
            options?: any
        ): AxiosPromise<LandschaftsverbandJsonldLandschaftsverbandRead> {
            return localVarFp
                .putLandschaftsverbandItem(id, landschaftsverbandJsonldLandschaftsverbandWrite, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * LandschaftsverbandApi - object-oriented interface
 * @export
 * @class LandschaftsverbandApi
 * @extends {BaseAPI}
 */
export class LandschaftsverbandApi extends BaseAPI {
    /**
     * Retrieves the collection of Landschaftsverband resources.
     * @summary Retrieves the collection of Landschaftsverband resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [kuerzel]
     * @param {Array<string>} [kuerzel2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LandschaftsverbandApi
     */
    public getLandschaftsverbandCollection(
        page?: number,
        itemsPerPage?: number,
        kuerzel?: string,
        kuerzel2?: Array<string>,
        options?: AxiosRequestConfig
    ) {
        return LandschaftsverbandApiFp(this.configuration)
            .getLandschaftsverbandCollection(page, itemsPerPage, kuerzel, kuerzel2, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Landschaftsverband resource.
     * @summary Retrieves a Landschaftsverband resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LandschaftsverbandApi
     */
    public getLandschaftsverbandItem(id: string, options?: AxiosRequestConfig) {
        return LandschaftsverbandApiFp(this.configuration)
            .getLandschaftsverbandItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Landschaftsverband resource.
     * @summary Replaces the Landschaftsverband resource.
     * @param {string} id Resource identifier
     * @param {LandschaftsverbandJsonldLandschaftsverbandWrite} landschaftsverbandJsonldLandschaftsverbandWrite The updated Landschaftsverband resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LandschaftsverbandApi
     */
    public putLandschaftsverbandItem(
        id: string,
        landschaftsverbandJsonldLandschaftsverbandWrite: LandschaftsverbandJsonldLandschaftsverbandWrite,
        options?: AxiosRequestConfig
    ) {
        return LandschaftsverbandApiFp(this.configuration)
            .putLandschaftsverbandItem(id, landschaftsverbandJsonldLandschaftsverbandWrite, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * LoginLinkApi - axios parameter creator
 * @export
 */
export const LoginLinkApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes the LoginLink resource.
         * @summary Removes the LoginLink resource.
         * @param {string} email Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLoginLinkItem: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('deleteLoginLinkItem', 'email', email);
            const localVarPath = `/api/login-link/{email}`.replace(`{${'email'}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Validates login link by query parameters
         * @summary Validates login link by query parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginLinkCheckItem: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/login-link-check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a LoginLink resource.
         * @summary Retrieves a LoginLink resource.
         * @param {string} email Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginLinkItem: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('getLoginLinkItem', 'email', email);
            const localVarPath = `/api/login-link/{email}`.replace(`{${'email'}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the LoginLink resource.
         * @summary Updates the LoginLink resource.
         * @param {string} email Resource identifier
         * @param {LoginLink} loginLink The updated LoginLink resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchLoginLinkItem: async (
            email: string,
            loginLink: LoginLink,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('patchLoginLinkItem', 'email', email);
            // verify required parameter 'loginLink' is not null or undefined
            assertParamExists('patchLoginLinkItem', 'loginLink', loginLink);
            const localVarPath = `/api/login-link/{email}`.replace(`{${'email'}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(loginLink, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a LoginLink resource.
         * @summary Creates a LoginLink resource.
         * @param {LoginLinkJsonld} loginLinkJsonld The new LoginLink resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLoginLinkCollection: async (
            loginLinkJsonld: LoginLinkJsonld,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'loginLinkJsonld' is not null or undefined
            assertParamExists('postLoginLinkCollection', 'loginLinkJsonld', loginLinkJsonld);
            const localVarPath = `/api/login-link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(loginLinkJsonld, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the LoginLink resource.
         * @summary Replaces the LoginLink resource.
         * @param {string} email Resource identifier
         * @param {LoginLinkJsonld} loginLinkJsonld The updated LoginLink resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLoginLinkItem: async (
            email: string,
            loginLinkJsonld: LoginLinkJsonld,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('putLoginLinkItem', 'email', email);
            // verify required parameter 'loginLinkJsonld' is not null or undefined
            assertParamExists('putLoginLinkItem', 'loginLinkJsonld', loginLinkJsonld);
            const localVarPath = `/api/login-link/{email}`.replace(`{${'email'}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(loginLinkJsonld, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * LoginLinkApi - functional programming interface
 * @export
 */
export const LoginLinkApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = LoginLinkApiAxiosParamCreator(configuration);
    return {
        /**
         * Removes the LoginLink resource.
         * @summary Removes the LoginLink resource.
         * @param {string} email Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLoginLinkItem(
            email: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLoginLinkItem(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Validates login link by query parameters
         * @summary Validates login link by query parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoginLinkCheckItem(
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoginLinkCheckItem(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a LoginLink resource.
         * @summary Retrieves a LoginLink resource.
         * @param {string} email Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoginLinkItem(
            email: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginLinkJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoginLinkItem(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the LoginLink resource.
         * @summary Updates the LoginLink resource.
         * @param {string} email Resource identifier
         * @param {LoginLink} loginLink The updated LoginLink resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchLoginLinkItem(
            email: string,
            loginLink: LoginLink,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginLinkJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchLoginLinkItem(email, loginLink, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a LoginLink resource.
         * @summary Creates a LoginLink resource.
         * @param {LoginLinkJsonld} loginLinkJsonld The new LoginLink resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postLoginLinkCollection(
            loginLinkJsonld: LoginLinkJsonld,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postLoginLinkCollection(loginLinkJsonld, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the LoginLink resource.
         * @summary Replaces the LoginLink resource.
         * @param {string} email Resource identifier
         * @param {LoginLinkJsonld} loginLinkJsonld The updated LoginLink resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putLoginLinkItem(
            email: string,
            loginLinkJsonld: LoginLinkJsonld,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginLinkJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putLoginLinkItem(email, loginLinkJsonld, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * LoginLinkApi - factory interface
 * @export
 */
export const LoginLinkApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoginLinkApiFp(configuration);
    return {
        /**
         * Removes the LoginLink resource.
         * @summary Removes the LoginLink resource.
         * @param {string} email Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLoginLinkItem(email: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteLoginLinkItem(email, options).then((request) => request(axios, basePath));
        },
        /**
         * Validates login link by query parameters
         * @summary Validates login link by query parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginLinkCheckItem(options?: any): AxiosPromise<Token> {
            return localVarFp.getLoginLinkCheckItem(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a LoginLink resource.
         * @summary Retrieves a LoginLink resource.
         * @param {string} email Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginLinkItem(email: string, options?: any): AxiosPromise<LoginLinkJsonld> {
            return localVarFp.getLoginLinkItem(email, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the LoginLink resource.
         * @summary Updates the LoginLink resource.
         * @param {string} email Resource identifier
         * @param {LoginLink} loginLink The updated LoginLink resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchLoginLinkItem(email: string, loginLink: LoginLink, options?: any): AxiosPromise<LoginLinkJsonld> {
            return localVarFp.patchLoginLinkItem(email, loginLink, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a LoginLink resource.
         * @summary Creates a LoginLink resource.
         * @param {LoginLinkJsonld} loginLinkJsonld The new LoginLink resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLoginLinkCollection(loginLinkJsonld: LoginLinkJsonld, options?: any): AxiosPromise<any> {
            return localVarFp
                .postLoginLinkCollection(loginLinkJsonld, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Replaces the LoginLink resource.
         * @summary Replaces the LoginLink resource.
         * @param {string} email Resource identifier
         * @param {LoginLinkJsonld} loginLinkJsonld The updated LoginLink resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLoginLinkItem(
            email: string,
            loginLinkJsonld: LoginLinkJsonld,
            options?: any
        ): AxiosPromise<LoginLinkJsonld> {
            return localVarFp
                .putLoginLinkItem(email, loginLinkJsonld, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoginLinkApi - object-oriented interface
 * @export
 * @class LoginLinkApi
 * @extends {BaseAPI}
 */
export class LoginLinkApi extends BaseAPI {
    /**
     * Removes the LoginLink resource.
     * @summary Removes the LoginLink resource.
     * @param {string} email Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginLinkApi
     */
    public deleteLoginLinkItem(email: string, options?: AxiosRequestConfig) {
        return LoginLinkApiFp(this.configuration)
            .deleteLoginLinkItem(email, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Validates login link by query parameters
     * @summary Validates login link by query parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginLinkApi
     */
    public getLoginLinkCheckItem(options?: AxiosRequestConfig) {
        return LoginLinkApiFp(this.configuration)
            .getLoginLinkCheckItem(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a LoginLink resource.
     * @summary Retrieves a LoginLink resource.
     * @param {string} email Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginLinkApi
     */
    public getLoginLinkItem(email: string, options?: AxiosRequestConfig) {
        return LoginLinkApiFp(this.configuration)
            .getLoginLinkItem(email, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the LoginLink resource.
     * @summary Updates the LoginLink resource.
     * @param {string} email Resource identifier
     * @param {LoginLink} loginLink The updated LoginLink resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginLinkApi
     */
    public patchLoginLinkItem(email: string, loginLink: LoginLink, options?: AxiosRequestConfig) {
        return LoginLinkApiFp(this.configuration)
            .patchLoginLinkItem(email, loginLink, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a LoginLink resource.
     * @summary Creates a LoginLink resource.
     * @param {LoginLinkJsonld} loginLinkJsonld The new LoginLink resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginLinkApi
     */
    public postLoginLinkCollection(loginLinkJsonld: LoginLinkJsonld, options?: AxiosRequestConfig) {
        return LoginLinkApiFp(this.configuration)
            .postLoginLinkCollection(loginLinkJsonld, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the LoginLink resource.
     * @summary Replaces the LoginLink resource.
     * @param {string} email Resource identifier
     * @param {LoginLinkJsonld} loginLinkJsonld The updated LoginLink resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginLinkApi
     */
    public putLoginLinkItem(email: string, loginLinkJsonld: LoginLinkJsonld, options?: AxiosRequestConfig) {
        return LoginLinkApiFp(this.configuration)
            .putLoginLinkItem(email, loginLinkJsonld, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * MassenmailApi - axios parameter creator
 * @export
 */
export const MassenmailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of Massenmail resources.
         * @summary Retrieves the collection of Massenmail resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMassenmailCollection: async (
            page?: number,
            itemsPerPage?: number,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/massenmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Massenmail resource.
         * @summary Retrieves a Massenmail resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMassenmailItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMassenmailItem', 'id', id);
            const localVarPath = `/api/massenmail/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Massenmail resource.
         * @summary Creates a Massenmail resource.
         * @param {MassenmailJsonldMassenmailWrite} massenmailJsonldMassenmailWrite The new Massenmail resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMassenmailCollection: async (
            massenmailJsonldMassenmailWrite: MassenmailJsonldMassenmailWrite,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'massenmailJsonldMassenmailWrite' is not null or undefined
            assertParamExists(
                'postMassenmailCollection',
                'massenmailJsonldMassenmailWrite',
                massenmailJsonldMassenmailWrite
            );
            const localVarPath = `/api/massenmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                massenmailJsonldMassenmailWrite,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * MassenmailApi - functional programming interface
 * @export
 */
export const MassenmailApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = MassenmailApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of Massenmail resources.
         * @summary Retrieves the collection of Massenmail resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMassenmailCollection(
            page?: number,
            itemsPerPage?: number,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20020>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMassenmailCollection(
                page,
                itemsPerPage,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Massenmail resource.
         * @summary Retrieves a Massenmail resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMassenmailItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MassenmailJsonldMassenmailRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMassenmailItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Massenmail resource.
         * @summary Creates a Massenmail resource.
         * @param {MassenmailJsonldMassenmailWrite} massenmailJsonldMassenmailWrite The new Massenmail resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postMassenmailCollection(
            massenmailJsonldMassenmailWrite: MassenmailJsonldMassenmailWrite,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MassenmailJsonldMassenmailRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postMassenmailCollection(
                massenmailJsonldMassenmailWrite,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * MassenmailApi - factory interface
 * @export
 */
export const MassenmailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MassenmailApiFp(configuration);
    return {
        /**
         * Retrieves the collection of Massenmail resources.
         * @summary Retrieves the collection of Massenmail resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMassenmailCollection(
            page?: number,
            itemsPerPage?: number,
            options?: any
        ): AxiosPromise<InlineResponse20020> {
            return localVarFp
                .getMassenmailCollection(page, itemsPerPage, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Massenmail resource.
         * @summary Retrieves a Massenmail resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMassenmailItem(id: string, options?: any): AxiosPromise<MassenmailJsonldMassenmailRead> {
            return localVarFp.getMassenmailItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Massenmail resource.
         * @summary Creates a Massenmail resource.
         * @param {MassenmailJsonldMassenmailWrite} massenmailJsonldMassenmailWrite The new Massenmail resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMassenmailCollection(
            massenmailJsonldMassenmailWrite: MassenmailJsonldMassenmailWrite,
            options?: any
        ): AxiosPromise<MassenmailJsonldMassenmailRead> {
            return localVarFp
                .postMassenmailCollection(massenmailJsonldMassenmailWrite, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * MassenmailApi - object-oriented interface
 * @export
 * @class MassenmailApi
 * @extends {BaseAPI}
 */
export class MassenmailApi extends BaseAPI {
    /**
     * Retrieves the collection of Massenmail resources.
     * @summary Retrieves the collection of Massenmail resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MassenmailApi
     */
    public getMassenmailCollection(page?: number, itemsPerPage?: number, options?: AxiosRequestConfig) {
        return MassenmailApiFp(this.configuration)
            .getMassenmailCollection(page, itemsPerPage, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Massenmail resource.
     * @summary Retrieves a Massenmail resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MassenmailApi
     */
    public getMassenmailItem(id: string, options?: AxiosRequestConfig) {
        return MassenmailApiFp(this.configuration)
            .getMassenmailItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Massenmail resource.
     * @summary Creates a Massenmail resource.
     * @param {MassenmailJsonldMassenmailWrite} massenmailJsonldMassenmailWrite The new Massenmail resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MassenmailApi
     */
    public postMassenmailCollection(
        massenmailJsonldMassenmailWrite: MassenmailJsonldMassenmailWrite,
        options?: AxiosRequestConfig
    ) {
        return MassenmailApiFp(this.configuration)
            .postMassenmailCollection(massenmailJsonldMassenmailWrite, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * MeldungP29Api - axios parameter creator
 * @export
 */
export const MeldungP29ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes the MeldungP29 resource.
         * @summary Removes the MeldungP29 resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMeldungP29Item: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMeldungP29Item', 'id', id);
            const localVarPath = `/api/meldung-p29/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a MeldungP29 resource.
         * @summary Retrieves a MeldungP29 resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filtersMeldungP29Item: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/filters/meldungP29`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of MeldungP29 resources.
         * @summary Retrieves the collection of MeldungP29 resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {Array<string>} [qSearch]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {'asc' | 'desc'} [orderEingereichtAt]
         * @param {'asc' | 'desc'} [orderEinrichtungAktenzeichen]
         * @param {'asc' | 'desc'} [orderEinrichtungName]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderFieldM2901]
         * @param {'asc' | 'desc'} [orderFieldM2902]
         * @param {'asc' | 'desc'} [orderEinrichtungZustaendigerBenutzerName]
         * @param {Array<string>} [meldedatum]
         * @param {string} [aufgabe]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeldungP29Collection: async (
            page?: number,
            itemsPerPage?: number,
            qSearch?: Array<string>,
            zustaendigerBenutzer?: Array<number>,
            status?: string,
            status2?: Array<string>,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            orderEingereichtAt?: 'asc' | 'desc',
            orderEinrichtungAktenzeichen?: 'asc' | 'desc',
            orderEinrichtungName?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderFieldM2901?: 'asc' | 'desc',
            orderFieldM2902?: 'asc' | 'desc',
            orderEinrichtungZustaendigerBenutzerName?: 'asc' | 'desc',
            meldedatum?: Array<string>,
            aufgabe?: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/meldung-p29`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (qSearch) {
                localVarQueryParameter['qSearch[]'] = qSearch;
            }

            if (zustaendigerBenutzer) {
                localVarQueryParameter['zustaendigerBenutzer[]'] = zustaendigerBenutzer;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (status2) {
                localVarQueryParameter['status[]'] = status2;
            }

            if (einrichtung !== undefined) {
                localVarQueryParameter['einrichtung'] = einrichtung;
            }

            if (einrichtung2) {
                localVarQueryParameter['einrichtung[]'] = einrichtung2;
            }

            if (orderEingereichtAt !== undefined) {
                localVarQueryParameter['order[eingereichtAt]'] = orderEingereichtAt;
            }

            if (orderEinrichtungAktenzeichen !== undefined) {
                localVarQueryParameter['order[einrichtung.aktenzeichen]'] = orderEinrichtungAktenzeichen;
            }

            if (orderEinrichtungName !== undefined) {
                localVarQueryParameter['order[einrichtung.name]'] = orderEinrichtungName;
            }

            if (orderStatus !== undefined) {
                localVarQueryParameter['order[status]'] = orderStatus;
            }

            if (orderFieldM2901 !== undefined) {
                localVarQueryParameter['order[fieldM2901]'] = orderFieldM2901;
            }

            if (orderFieldM2902 !== undefined) {
                localVarQueryParameter['order[fieldM2902]'] = orderFieldM2902;
            }

            if (orderEinrichtungZustaendigerBenutzerName !== undefined) {
                localVarQueryParameter['order[einrichtung.zustaendigerBenutzer.name]'] =
                    orderEinrichtungZustaendigerBenutzerName;
            }

            if (meldedatum) {
                localVarQueryParameter['meldedatum[]'] = meldedatum;
            }

            if (aufgabe !== undefined) {
                localVarQueryParameter['aufgabe'] = aufgabe;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a MeldungP29 resource.
         * @summary Retrieves a MeldungP29 resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeldungP29Item: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMeldungP29Item', 'id', id);
            const localVarPath = `/api/meldung-p29/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the MeldungP29 resource.
         * @summary Removes the MeldungP29 resource.
         * @param {string} id Resource identifier
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jsonFormDeleteMeldungP29Item: async (
            id: string,
            uuid?: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('jsonFormDeleteMeldungP29Item', 'id', id);
            const localVarPath = `/api/meldungP29/{id}/json/{uuid}`
                .replace(`{${'uuid'}}`, encodeURIComponent(String(uuid)))
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a MeldungP29 resource.
         * @summary Retrieves a MeldungP29 resource.
         * @param {string} id Resource identifier
         * @param {string} [step]
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jsonFormGetMeldungP29Item: async (
            id: string,
            step?: string,
            objectType?: string,
            uuid?: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('jsonFormGetMeldungP29Item', 'id', id);
            const localVarPath = `/api/meldungP29/{id}/json/{step}`
                .replace(`{${'step'}}`, encodeURIComponent(String(step)))
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (objectType !== undefined) {
                localVarQueryParameter['objectType'] = objectType;
            }

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the MeldungP29 resource.
         * @summary Updates the MeldungP29 resource.
         * @param {string} id Resource identifier
         * @param {object} body The updated MeldungP29 resource
         * @param {string} [step]
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {boolean} [persist]
         * @param {Array<string>} [fields]
         * @param {boolean} [recalculateFields]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jsonFormPatchMeldungP29Item: async (
            id: string,
            body: object,
            step?: string,
            objectType?: string,
            uuid?: string,
            persist?: boolean,
            fields?: Array<string>,
            recalculateFields?: boolean,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('jsonFormPatchMeldungP29Item', 'id', id);
            // verify required parameter 'body' is not null or undefined
            assertParamExists('jsonFormPatchMeldungP29Item', 'body', body);
            const localVarPath = `/api/meldungP29/{id}/json/{step}`
                .replace(`{${'step'}}`, encodeURIComponent(String(step)))
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (objectType !== undefined) {
                localVarQueryParameter['objectType'] = objectType;
            }

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }

            if (persist !== undefined) {
                localVarQueryParameter['persist'] = persist;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (recalculateFields !== undefined) {
                localVarQueryParameter['recalculateFields'] = recalculateFields;
            }

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a MeldungP29 resource.
         * @summary Creates a MeldungP29 resource.
         * @param {MeldungP29JsonldMeldungP29Create} meldungP29JsonldMeldungP29Create The new MeldungP29 resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMeldungP29Collection: async (
            meldungP29JsonldMeldungP29Create: MeldungP29JsonldMeldungP29Create,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'meldungP29JsonldMeldungP29Create' is not null or undefined
            assertParamExists(
                'postMeldungP29Collection',
                'meldungP29JsonldMeldungP29Create',
                meldungP29JsonldMeldungP29Create
            );
            const localVarPath = `/api/meldung-p29`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                meldungP29JsonldMeldungP29Create,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a MeldungP29 resource.
         * @summary Retrieves a MeldungP29 resource.
         * @param {number} id
         * @param {string} action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workflowMeldungP29Item: async (
            id: number,
            action: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('workflowMeldungP29Item', 'id', id);
            // verify required parameter 'action' is not null or undefined
            assertParamExists('workflowMeldungP29Item', 'action', action);
            const localVarPath = `/api/meldungP29/{id}/workflow/{action}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'action'}}`, encodeURIComponent(String(action)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * MeldungP29Api - functional programming interface
 * @export
 */
export const MeldungP29ApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = MeldungP29ApiAxiosParamCreator(configuration);
    return {
        /**
         * Removes the MeldungP29 resource.
         * @summary Removes the MeldungP29 resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMeldungP29Item(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMeldungP29Item(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a MeldungP29 resource.
         * @summary Retrieves a MeldungP29 resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filtersMeldungP29Item(
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<MeldungP29CollectionFiltersOutputJsonldCollectionFilters>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filtersMeldungP29Item(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of MeldungP29 resources.
         * @summary Retrieves the collection of MeldungP29 resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {Array<string>} [qSearch]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {'asc' | 'desc'} [orderEingereichtAt]
         * @param {'asc' | 'desc'} [orderEinrichtungAktenzeichen]
         * @param {'asc' | 'desc'} [orderEinrichtungName]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderFieldM2901]
         * @param {'asc' | 'desc'} [orderFieldM2902]
         * @param {'asc' | 'desc'} [orderEinrichtungZustaendigerBenutzerName]
         * @param {Array<string>} [meldedatum]
         * @param {string} [aufgabe]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeldungP29Collection(
            page?: number,
            itemsPerPage?: number,
            qSearch?: Array<string>,
            zustaendigerBenutzer?: Array<number>,
            status?: string,
            status2?: Array<string>,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            orderEingereichtAt?: 'asc' | 'desc',
            orderEinrichtungAktenzeichen?: 'asc' | 'desc',
            orderEinrichtungName?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderFieldM2901?: 'asc' | 'desc',
            orderFieldM2902?: 'asc' | 'desc',
            orderEinrichtungZustaendigerBenutzerName?: 'asc' | 'desc',
            meldedatum?: Array<string>,
            aufgabe?: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20021>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeldungP29Collection(
                page,
                itemsPerPage,
                qSearch,
                zustaendigerBenutzer,
                status,
                status2,
                einrichtung,
                einrichtung2,
                orderEingereichtAt,
                orderEinrichtungAktenzeichen,
                orderEinrichtungName,
                orderStatus,
                orderFieldM2901,
                orderFieldM2902,
                orderEinrichtungZustaendigerBenutzerName,
                meldedatum,
                aufgabe,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a MeldungP29 resource.
         * @summary Retrieves a MeldungP29 resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeldungP29Item(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeldungP29JsonldMeldungP29Read>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeldungP29Item(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes the MeldungP29 resource.
         * @summary Removes the MeldungP29 resource.
         * @param {string} id Resource identifier
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jsonFormDeleteMeldungP29Item(
            id: string,
            uuid?: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jsonFormDeleteMeldungP29Item(id, uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a MeldungP29 resource.
         * @summary Retrieves a MeldungP29 resource.
         * @param {string} id Resource identifier
         * @param {string} [step]
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jsonFormGetMeldungP29Item(
            id: string,
            step?: string,
            objectType?: string,
            uuid?: string,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeldungP29MeldungP29JsonJsonldAntragJsonForm>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jsonFormGetMeldungP29Item(
                id,
                step,
                objectType,
                uuid,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the MeldungP29 resource.
         * @summary Updates the MeldungP29 resource.
         * @param {string} id Resource identifier
         * @param {object} body The updated MeldungP29 resource
         * @param {string} [step]
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {boolean} [persist]
         * @param {Array<string>} [fields]
         * @param {boolean} [recalculateFields]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jsonFormPatchMeldungP29Item(
            id: string,
            body: object,
            step?: string,
            objectType?: string,
            uuid?: string,
            persist?: boolean,
            fields?: Array<string>,
            recalculateFields?: boolean,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeldungP29MeldungP29JsonJsonldAntragJsonForm>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jsonFormPatchMeldungP29Item(
                id,
                body,
                step,
                objectType,
                uuid,
                persist,
                fields,
                recalculateFields,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a MeldungP29 resource.
         * @summary Creates a MeldungP29 resource.
         * @param {MeldungP29JsonldMeldungP29Create} meldungP29JsonldMeldungP29Create The new MeldungP29 resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postMeldungP29Collection(
            meldungP29JsonldMeldungP29Create: MeldungP29JsonldMeldungP29Create,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeldungP29JsonldMeldungP29Read>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postMeldungP29Collection(
                meldungP29JsonldMeldungP29Create,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a MeldungP29 resource.
         * @summary Retrieves a MeldungP29 resource.
         * @param {number} id
         * @param {string} action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workflowMeldungP29Item(
            id: number,
            action: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeldungP29JsonldMeldungP29Read>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workflowMeldungP29Item(id, action, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * MeldungP29Api - factory interface
 * @export
 */
export const MeldungP29ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MeldungP29ApiFp(configuration);
    return {
        /**
         * Removes the MeldungP29 resource.
         * @summary Removes the MeldungP29 resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMeldungP29Item(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMeldungP29Item(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a MeldungP29 resource.
         * @summary Retrieves a MeldungP29 resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filtersMeldungP29Item(options?: any): AxiosPromise<MeldungP29CollectionFiltersOutputJsonldCollectionFilters> {
            return localVarFp.filtersMeldungP29Item(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of MeldungP29 resources.
         * @summary Retrieves the collection of MeldungP29 resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {Array<string>} [qSearch]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {'asc' | 'desc'} [orderEingereichtAt]
         * @param {'asc' | 'desc'} [orderEinrichtungAktenzeichen]
         * @param {'asc' | 'desc'} [orderEinrichtungName]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderFieldM2901]
         * @param {'asc' | 'desc'} [orderFieldM2902]
         * @param {'asc' | 'desc'} [orderEinrichtungZustaendigerBenutzerName]
         * @param {Array<string>} [meldedatum]
         * @param {string} [aufgabe]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeldungP29Collection(
            page?: number,
            itemsPerPage?: number,
            qSearch?: Array<string>,
            zustaendigerBenutzer?: Array<number>,
            status?: string,
            status2?: Array<string>,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            orderEingereichtAt?: 'asc' | 'desc',
            orderEinrichtungAktenzeichen?: 'asc' | 'desc',
            orderEinrichtungName?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderFieldM2901?: 'asc' | 'desc',
            orderFieldM2902?: 'asc' | 'desc',
            orderEinrichtungZustaendigerBenutzerName?: 'asc' | 'desc',
            meldedatum?: Array<string>,
            aufgabe?: string,
            options?: any
        ): AxiosPromise<InlineResponse20021> {
            return localVarFp
                .getMeldungP29Collection(
                    page,
                    itemsPerPage,
                    qSearch,
                    zustaendigerBenutzer,
                    status,
                    status2,
                    einrichtung,
                    einrichtung2,
                    orderEingereichtAt,
                    orderEinrichtungAktenzeichen,
                    orderEinrichtungName,
                    orderStatus,
                    orderFieldM2901,
                    orderFieldM2902,
                    orderEinrichtungZustaendigerBenutzerName,
                    meldedatum,
                    aufgabe,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a MeldungP29 resource.
         * @summary Retrieves a MeldungP29 resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeldungP29Item(id: string, options?: any): AxiosPromise<MeldungP29JsonldMeldungP29Read> {
            return localVarFp.getMeldungP29Item(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the MeldungP29 resource.
         * @summary Removes the MeldungP29 resource.
         * @param {string} id Resource identifier
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jsonFormDeleteMeldungP29Item(id: string, uuid?: string, options?: any): AxiosPromise<void> {
            return localVarFp
                .jsonFormDeleteMeldungP29Item(id, uuid, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a MeldungP29 resource.
         * @summary Retrieves a MeldungP29 resource.
         * @param {string} id Resource identifier
         * @param {string} [step]
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jsonFormGetMeldungP29Item(
            id: string,
            step?: string,
            objectType?: string,
            uuid?: string,
            options?: any
        ): AxiosPromise<MeldungP29MeldungP29JsonJsonldAntragJsonForm> {
            return localVarFp
                .jsonFormGetMeldungP29Item(id, step, objectType, uuid, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Updates the MeldungP29 resource.
         * @summary Updates the MeldungP29 resource.
         * @param {string} id Resource identifier
         * @param {object} body The updated MeldungP29 resource
         * @param {string} [step]
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {boolean} [persist]
         * @param {Array<string>} [fields]
         * @param {boolean} [recalculateFields]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jsonFormPatchMeldungP29Item(
            id: string,
            body: object,
            step?: string,
            objectType?: string,
            uuid?: string,
            persist?: boolean,
            fields?: Array<string>,
            recalculateFields?: boolean,
            options?: any
        ): AxiosPromise<MeldungP29MeldungP29JsonJsonldAntragJsonForm> {
            return localVarFp
                .jsonFormPatchMeldungP29Item(
                    id,
                    body,
                    step,
                    objectType,
                    uuid,
                    persist,
                    fields,
                    recalculateFields,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Creates a MeldungP29 resource.
         * @summary Creates a MeldungP29 resource.
         * @param {MeldungP29JsonldMeldungP29Create} meldungP29JsonldMeldungP29Create The new MeldungP29 resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMeldungP29Collection(
            meldungP29JsonldMeldungP29Create: MeldungP29JsonldMeldungP29Create,
            options?: any
        ): AxiosPromise<MeldungP29JsonldMeldungP29Read> {
            return localVarFp
                .postMeldungP29Collection(meldungP29JsonldMeldungP29Create, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a MeldungP29 resource.
         * @summary Retrieves a MeldungP29 resource.
         * @param {number} id
         * @param {string} action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workflowMeldungP29Item(
            id: number,
            action: string,
            options?: any
        ): AxiosPromise<MeldungP29JsonldMeldungP29Read> {
            return localVarFp.workflowMeldungP29Item(id, action, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MeldungP29Api - object-oriented interface
 * @export
 * @class MeldungP29Api
 * @extends {BaseAPI}
 */
export class MeldungP29Api extends BaseAPI {
    /**
     * Removes the MeldungP29 resource.
     * @summary Removes the MeldungP29 resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungP29Api
     */
    public deleteMeldungP29Item(id: string, options?: AxiosRequestConfig) {
        return MeldungP29ApiFp(this.configuration)
            .deleteMeldungP29Item(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a MeldungP29 resource.
     * @summary Retrieves a MeldungP29 resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungP29Api
     */
    public filtersMeldungP29Item(options?: AxiosRequestConfig) {
        return MeldungP29ApiFp(this.configuration)
            .filtersMeldungP29Item(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of MeldungP29 resources.
     * @summary Retrieves the collection of MeldungP29 resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {Array<string>} [qSearch]
     * @param {Array<number>} [zustaendigerBenutzer]
     * @param {string} [status]
     * @param {Array<string>} [status2]
     * @param {string} [einrichtung]
     * @param {Array<string>} [einrichtung2]
     * @param {'asc' | 'desc'} [orderEingereichtAt]
     * @param {'asc' | 'desc'} [orderEinrichtungAktenzeichen]
     * @param {'asc' | 'desc'} [orderEinrichtungName]
     * @param {'asc' | 'desc'} [orderStatus]
     * @param {'asc' | 'desc'} [orderFieldM2901]
     * @param {'asc' | 'desc'} [orderFieldM2902]
     * @param {'asc' | 'desc'} [orderEinrichtungZustaendigerBenutzerName]
     * @param {Array<string>} [meldedatum]
     * @param {string} [aufgabe]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungP29Api
     */
    public getMeldungP29Collection(
        page?: number,
        itemsPerPage?: number,
        qSearch?: Array<string>,
        zustaendigerBenutzer?: Array<number>,
        status?: string,
        status2?: Array<string>,
        einrichtung?: string,
        einrichtung2?: Array<string>,
        orderEingereichtAt?: 'asc' | 'desc',
        orderEinrichtungAktenzeichen?: 'asc' | 'desc',
        orderEinrichtungName?: 'asc' | 'desc',
        orderStatus?: 'asc' | 'desc',
        orderFieldM2901?: 'asc' | 'desc',
        orderFieldM2902?: 'asc' | 'desc',
        orderEinrichtungZustaendigerBenutzerName?: 'asc' | 'desc',
        meldedatum?: Array<string>,
        aufgabe?: string,
        options?: AxiosRequestConfig
    ) {
        return MeldungP29ApiFp(this.configuration)
            .getMeldungP29Collection(
                page,
                itemsPerPage,
                qSearch,
                zustaendigerBenutzer,
                status,
                status2,
                einrichtung,
                einrichtung2,
                orderEingereichtAt,
                orderEinrichtungAktenzeichen,
                orderEinrichtungName,
                orderStatus,
                orderFieldM2901,
                orderFieldM2902,
                orderEinrichtungZustaendigerBenutzerName,
                meldedatum,
                aufgabe,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a MeldungP29 resource.
     * @summary Retrieves a MeldungP29 resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungP29Api
     */
    public getMeldungP29Item(id: string, options?: AxiosRequestConfig) {
        return MeldungP29ApiFp(this.configuration)
            .getMeldungP29Item(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the MeldungP29 resource.
     * @summary Removes the MeldungP29 resource.
     * @param {string} id Resource identifier
     * @param {string} [uuid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungP29Api
     */
    public jsonFormDeleteMeldungP29Item(id: string, uuid?: string, options?: AxiosRequestConfig) {
        return MeldungP29ApiFp(this.configuration)
            .jsonFormDeleteMeldungP29Item(id, uuid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a MeldungP29 resource.
     * @summary Retrieves a MeldungP29 resource.
     * @param {string} id Resource identifier
     * @param {string} [step]
     * @param {string} [objectType]
     * @param {string} [uuid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungP29Api
     */
    public jsonFormGetMeldungP29Item(
        id: string,
        step?: string,
        objectType?: string,
        uuid?: string,
        options?: AxiosRequestConfig
    ) {
        return MeldungP29ApiFp(this.configuration)
            .jsonFormGetMeldungP29Item(id, step, objectType, uuid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the MeldungP29 resource.
     * @summary Updates the MeldungP29 resource.
     * @param {string} id Resource identifier
     * @param {object} body The updated MeldungP29 resource
     * @param {string} [step]
     * @param {string} [objectType]
     * @param {string} [uuid]
     * @param {boolean} [persist]
     * @param {Array<string>} [fields]
     * @param {boolean} [recalculateFields]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungP29Api
     */
    public jsonFormPatchMeldungP29Item(
        id: string,
        body: object,
        step?: string,
        objectType?: string,
        uuid?: string,
        persist?: boolean,
        fields?: Array<string>,
        recalculateFields?: boolean,
        options?: AxiosRequestConfig
    ) {
        return MeldungP29ApiFp(this.configuration)
            .jsonFormPatchMeldungP29Item(id, body, step, objectType, uuid, persist, fields, recalculateFields, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a MeldungP29 resource.
     * @summary Creates a MeldungP29 resource.
     * @param {MeldungP29JsonldMeldungP29Create} meldungP29JsonldMeldungP29Create The new MeldungP29 resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungP29Api
     */
    public postMeldungP29Collection(
        meldungP29JsonldMeldungP29Create: MeldungP29JsonldMeldungP29Create,
        options?: AxiosRequestConfig
    ) {
        return MeldungP29ApiFp(this.configuration)
            .postMeldungP29Collection(meldungP29JsonldMeldungP29Create, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a MeldungP29 resource.
     * @summary Retrieves a MeldungP29 resource.
     * @param {number} id
     * @param {string} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungP29Api
     */
    public workflowMeldungP29Item(id: number, action: string, options?: AxiosRequestConfig) {
        return MeldungP29ApiFp(this.configuration)
            .workflowMeldungP29Item(id, action, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * RegistrierungApi - axios parameter creator
 * @export
 */
export const RegistrierungApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a Registrierung resource.
         * @summary Creates a Registrierung resource.
         * @param {RegistrierungJsonld} registrierungJsonld The new Registrierung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRegistrierungCollection: async (
            registrierungJsonld: RegistrierungJsonld,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'registrierungJsonld' is not null or undefined
            assertParamExists('postRegistrierungCollection', 'registrierungJsonld', registrierungJsonld);
            const localVarPath = `/api/registrierung`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                registrierungJsonld,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * RegistrierungApi - functional programming interface
 * @export
 */
export const RegistrierungApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = RegistrierungApiAxiosParamCreator(configuration);
    return {
        /**
         * Creates a Registrierung resource.
         * @summary Creates a Registrierung resource.
         * @param {RegistrierungJsonld} registrierungJsonld The new Registrierung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRegistrierungCollection(
            registrierungJsonld: RegistrierungJsonld,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRegistrierungCollection(
                registrierungJsonld,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * RegistrierungApi - factory interface
 * @export
 */
export const RegistrierungApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = RegistrierungApiFp(configuration);
    return {
        /**
         * Creates a Registrierung resource.
         * @summary Creates a Registrierung resource.
         * @param {RegistrierungJsonld} registrierungJsonld The new Registrierung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRegistrierungCollection(registrierungJsonld: RegistrierungJsonld, options?: any): AxiosPromise<any> {
            return localVarFp
                .postRegistrierungCollection(registrierungJsonld, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * RegistrierungApi - object-oriented interface
 * @export
 * @class RegistrierungApi
 * @extends {BaseAPI}
 */
export class RegistrierungApi extends BaseAPI {
    /**
     * Creates a Registrierung resource.
     * @summary Creates a Registrierung resource.
     * @param {RegistrierungJsonld} registrierungJsonld The new Registrierung resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrierungApi
     */
    public postRegistrierungCollection(registrierungJsonld: RegistrierungJsonld, options?: AxiosRequestConfig) {
        return RegistrierungApiFp(this.configuration)
            .postRegistrierungCollection(registrierungJsonld, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * RuhendstellungApi - axios parameter creator
 * @export
 */
export const RuhendstellungApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a Ruhendstellung resource.
         * @summary Retrieves a Ruhendstellung resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRuhendstellungItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRuhendstellungItem', 'id', id);
            const localVarPath = `/api/ruhendstellung/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Ruhendstellung resource.
         * @summary Creates a Ruhendstellung resource.
         * @param {RuhendstellungJsonldRuhendstellungCreate} ruhendstellungJsonldRuhendstellungCreate The new Ruhendstellung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRuhendstellungCollection: async (
            ruhendstellungJsonldRuhendstellungCreate: RuhendstellungJsonldRuhendstellungCreate,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'ruhendstellungJsonldRuhendstellungCreate' is not null or undefined
            assertParamExists(
                'postRuhendstellungCollection',
                'ruhendstellungJsonldRuhendstellungCreate',
                ruhendstellungJsonldRuhendstellungCreate
            );
            const localVarPath = `/api/ruhendstellung`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                ruhendstellungJsonldRuhendstellungCreate,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * RuhendstellungApi - functional programming interface
 * @export
 */
export const RuhendstellungApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = RuhendstellungApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves a Ruhendstellung resource.
         * @summary Retrieves a Ruhendstellung resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRuhendstellungItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RuhendstellungJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRuhendstellungItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Ruhendstellung resource.
         * @summary Creates a Ruhendstellung resource.
         * @param {RuhendstellungJsonldRuhendstellungCreate} ruhendstellungJsonldRuhendstellungCreate The new Ruhendstellung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRuhendstellungCollection(
            ruhendstellungJsonldRuhendstellungCreate: RuhendstellungJsonldRuhendstellungCreate,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRuhendstellungCollection(
                ruhendstellungJsonldRuhendstellungCreate,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * RuhendstellungApi - factory interface
 * @export
 */
export const RuhendstellungApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = RuhendstellungApiFp(configuration);
    return {
        /**
         * Retrieves a Ruhendstellung resource.
         * @summary Retrieves a Ruhendstellung resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRuhendstellungItem(id: string, options?: any): AxiosPromise<RuhendstellungJsonld> {
            return localVarFp.getRuhendstellungItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Ruhendstellung resource.
         * @summary Creates a Ruhendstellung resource.
         * @param {RuhendstellungJsonldRuhendstellungCreate} ruhendstellungJsonldRuhendstellungCreate The new Ruhendstellung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRuhendstellungCollection(
            ruhendstellungJsonldRuhendstellungCreate: RuhendstellungJsonldRuhendstellungCreate,
            options?: any
        ): AxiosPromise<any> {
            return localVarFp
                .postRuhendstellungCollection(ruhendstellungJsonldRuhendstellungCreate, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * RuhendstellungApi - object-oriented interface
 * @export
 * @class RuhendstellungApi
 * @extends {BaseAPI}
 */
export class RuhendstellungApi extends BaseAPI {
    /**
     * Retrieves a Ruhendstellung resource.
     * @summary Retrieves a Ruhendstellung resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuhendstellungApi
     */
    public getRuhendstellungItem(id: string, options?: AxiosRequestConfig) {
        return RuhendstellungApiFp(this.configuration)
            .getRuhendstellungItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Ruhendstellung resource.
     * @summary Creates a Ruhendstellung resource.
     * @param {RuhendstellungJsonldRuhendstellungCreate} ruhendstellungJsonldRuhendstellungCreate The new Ruhendstellung resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuhendstellungApi
     */
    public postRuhendstellungCollection(
        ruhendstellungJsonldRuhendstellungCreate: RuhendstellungJsonldRuhendstellungCreate,
        options?: AxiosRequestConfig
    ) {
        return RuhendstellungApiFp(this.configuration)
            .postRuhendstellungCollection(ruhendstellungJsonldRuhendstellungCreate, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * SessionApi - axios parameter creator
 * @export
 */
export const SessionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get JWT token from Session.
         * @summary Get JWT token from Session.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionGetToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/session/get_token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Invalidate Session.
         * @summary Invalidate Session.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionInvalidate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/session/invalidate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set JWT token in Session.
         * @summary Set JWT token in Session.
         * @param {Token} [token]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionSetToken: async (token?: Token, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/session/set_token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(token, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * SessionApi - functional programming interface
 * @export
 */
export const SessionApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = SessionApiAxiosParamCreator(configuration);
    return {
        /**
         * Get JWT token from Session.
         * @summary Get JWT token from Session.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sessionGetToken(
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sessionGetToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Invalidate Session.
         * @summary Invalidate Session.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sessionInvalidate(
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sessionInvalidate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Set JWT token in Session.
         * @summary Set JWT token in Session.
         * @param {Token} [token]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sessionSetToken(
            token?: Token,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sessionSetToken(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * SessionApi - factory interface
 * @export
 */
export const SessionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SessionApiFp(configuration);
    return {
        /**
         * Get JWT token from Session.
         * @summary Get JWT token from Session.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionGetToken(options?: any): AxiosPromise<Token> {
            return localVarFp.sessionGetToken(options).then((request) => request(axios, basePath));
        },
        /**
         * Invalidate Session.
         * @summary Invalidate Session.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionInvalidate(options?: any): AxiosPromise<void> {
            return localVarFp.sessionInvalidate(options).then((request) => request(axios, basePath));
        },
        /**
         * Set JWT token in Session.
         * @summary Set JWT token in Session.
         * @param {Token} [token]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionSetToken(token?: Token, options?: any): AxiosPromise<void> {
            return localVarFp.sessionSetToken(token, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SessionApi - object-oriented interface
 * @export
 * @class SessionApi
 * @extends {BaseAPI}
 */
export class SessionApi extends BaseAPI {
    /**
     * Get JWT token from Session.
     * @summary Get JWT token from Session.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public sessionGetToken(options?: AxiosRequestConfig) {
        return SessionApiFp(this.configuration)
            .sessionGetToken(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Invalidate Session.
     * @summary Invalidate Session.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public sessionInvalidate(options?: AxiosRequestConfig) {
        return SessionApiFp(this.configuration)
            .sessionInvalidate(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Set JWT token in Session.
     * @summary Set JWT token in Session.
     * @param {Token} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public sessionSetToken(token?: Token, options?: AxiosRequestConfig) {
        return SessionApiFp(this.configuration)
            .sessionSetToken(token, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * TokenApi - axios parameter creator
 * @export
 */
export const TokenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Generate new JWT Token
         * @summary Get JWT token to login.
         * @param {Credentials} [credentials]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCredentialsItem: async (
            credentials?: Credentials,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(credentials, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * TokenApi - functional programming interface
 * @export
 */
export const TokenApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = TokenApiAxiosParamCreator(configuration);
    return {
        /**
         * Generate new JWT Token
         * @summary Get JWT token to login.
         * @param {Credentials} [credentials]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCredentialsItem(
            credentials?: Credentials,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCredentialsItem(credentials, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * TokenApi - factory interface
 * @export
 */
export const TokenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TokenApiFp(configuration);
    return {
        /**
         * Generate new JWT Token
         * @summary Get JWT token to login.
         * @param {Credentials} [credentials]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCredentialsItem(credentials?: Credentials, options?: any): AxiosPromise<Token> {
            return localVarFp.postCredentialsItem(credentials, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TokenApi - object-oriented interface
 * @export
 * @class TokenApi
 * @extends {BaseAPI}
 */
export class TokenApi extends BaseAPI {
    /**
     * Generate new JWT Token
     * @summary Get JWT token to login.
     * @param {Credentials} [credentials]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public postCredentialsItem(credentials?: Credentials, options?: AxiosRequestConfig) {
        return TokenApiFp(this.configuration)
            .postCredentialsItem(credentials, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * TokenRefreshApi - axios parameter creator
 * @export
 */
export const TokenRefreshApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Generate fresh JWT Token based on refresh token
         * @summary Get fresh JWT token to login.
         * @param {TokenRefresh} [tokenRefresh]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRefreshTokenItem: async (
            tokenRefresh?: TokenRefresh,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/token-refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(tokenRefresh, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * TokenRefreshApi - functional programming interface
 * @export
 */
export const TokenRefreshApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = TokenRefreshApiAxiosParamCreator(configuration);
    return {
        /**
         * Generate fresh JWT Token based on refresh token
         * @summary Get fresh JWT token to login.
         * @param {TokenRefresh} [tokenRefresh]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRefreshTokenItem(
            tokenRefresh?: TokenRefresh,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRefreshTokenItem(tokenRefresh, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * TokenRefreshApi - factory interface
 * @export
 */
export const TokenRefreshApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = TokenRefreshApiFp(configuration);
    return {
        /**
         * Generate fresh JWT Token based on refresh token
         * @summary Get fresh JWT token to login.
         * @param {TokenRefresh} [tokenRefresh]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRefreshTokenItem(tokenRefresh?: TokenRefresh, options?: any): AxiosPromise<Token> {
            return localVarFp.postRefreshTokenItem(tokenRefresh, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TokenRefreshApi - object-oriented interface
 * @export
 * @class TokenRefreshApi
 * @extends {BaseAPI}
 */
export class TokenRefreshApi extends BaseAPI {
    /**
     * Generate fresh JWT Token based on refresh token
     * @summary Get fresh JWT token to login.
     * @param {TokenRefresh} [tokenRefresh]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenRefreshApi
     */
    public postRefreshTokenItem(tokenRefresh?: TokenRefresh, options?: AxiosRequestConfig) {
        return TokenRefreshApiFp(this.configuration)
            .postRefreshTokenItem(tokenRefresh, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * TraegerApi - axios parameter creator
 * @export
 */
export const TraegerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a Traeger resource.
         * @summary Retrieves a Traeger resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        existsTraegerItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('existsTraegerItem', 'id', id);
            const localVarPath = `/api/traeger/{id}/exists`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Traeger resources.
         * @summary Retrieves the collection of Traeger resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [id]
         * @param {Array<number>} [id2]
         * @param {string} [art]
         * @param {Array<string>} [art2]
         * @param {string} [traegerverband]
         * @param {Array<string>} [traegerverband2]
         * @param {Array<string>} [qSearch]
         * @param {'asc' | 'desc'} [orderNrwKey]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderTraegerverbandName]
         * @param {'asc' | 'desc'} [orderArt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTraegerCollection: async (
            page?: number,
            itemsPerPage?: number,
            id?: number,
            id2?: Array<number>,
            art?: string,
            art2?: Array<string>,
            traegerverband?: string,
            traegerverband2?: Array<string>,
            qSearch?: Array<string>,
            orderNrwKey?: 'asc' | 'desc',
            orderName?: 'asc' | 'desc',
            orderTraegerverbandName?: 'asc' | 'desc',
            orderArt?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/traeger`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (id2) {
                localVarQueryParameter['id[]'] = id2;
            }

            if (art !== undefined) {
                localVarQueryParameter['art'] = art;
            }

            if (art2) {
                localVarQueryParameter['art[]'] = art2;
            }

            if (traegerverband !== undefined) {
                localVarQueryParameter['traegerverband'] = traegerverband;
            }

            if (traegerverband2) {
                localVarQueryParameter['traegerverband[]'] = traegerverband2;
            }

            if (qSearch) {
                localVarQueryParameter['qSearch[]'] = qSearch;
            }

            if (orderNrwKey !== undefined) {
                localVarQueryParameter['order[nrwKey]'] = orderNrwKey;
            }

            if (orderName !== undefined) {
                localVarQueryParameter['order[name]'] = orderName;
            }

            if (orderTraegerverbandName !== undefined) {
                localVarQueryParameter['order[traegerverband.name]'] = orderTraegerverbandName;
            }

            if (orderArt !== undefined) {
                localVarQueryParameter['order[art]'] = orderArt;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Traeger resource.
         * @summary Retrieves a Traeger resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTraegerItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTraegerItem', 'id', id);
            const localVarPath = `/api/traeger/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Traeger resource.
         * @summary Retrieves a Traeger resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshTraegerItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('refreshTraegerItem', 'id', id);
            const localVarPath = `/api/traeger/{id}/refresh`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * TraegerApi - functional programming interface
 * @export
 */
export const TraegerApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = TraegerApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves a Traeger resource.
         * @summary Retrieves a Traeger resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async existsTraegerItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TraegerTraegerExistsOutputJsonldTraegerExists>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.existsTraegerItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Traeger resources.
         * @summary Retrieves the collection of Traeger resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [id]
         * @param {Array<number>} [id2]
         * @param {string} [art]
         * @param {Array<string>} [art2]
         * @param {string} [traegerverband]
         * @param {Array<string>} [traegerverband2]
         * @param {Array<string>} [qSearch]
         * @param {'asc' | 'desc'} [orderNrwKey]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderTraegerverbandName]
         * @param {'asc' | 'desc'} [orderArt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTraegerCollection(
            page?: number,
            itemsPerPage?: number,
            id?: number,
            id2?: Array<number>,
            art?: string,
            art2?: Array<string>,
            traegerverband?: string,
            traegerverband2?: Array<string>,
            qSearch?: Array<string>,
            orderNrwKey?: 'asc' | 'desc',
            orderName?: 'asc' | 'desc',
            orderTraegerverbandName?: 'asc' | 'desc',
            orderArt?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20022>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTraegerCollection(
                page,
                itemsPerPage,
                id,
                id2,
                art,
                art2,
                traegerverband,
                traegerverband2,
                qSearch,
                orderNrwKey,
                orderName,
                orderTraegerverbandName,
                orderArt,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Traeger resource.
         * @summary Retrieves a Traeger resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTraegerItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TraegerJsonldTraegerRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTraegerItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Traeger resource.
         * @summary Retrieves a Traeger resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshTraegerItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshTraegerItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * TraegerApi - factory interface
 * @export
 */
export const TraegerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TraegerApiFp(configuration);
    return {
        /**
         * Retrieves a Traeger resource.
         * @summary Retrieves a Traeger resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        existsTraegerItem(id: string, options?: any): AxiosPromise<TraegerTraegerExistsOutputJsonldTraegerExists> {
            return localVarFp.existsTraegerItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Traeger resources.
         * @summary Retrieves the collection of Traeger resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [id]
         * @param {Array<number>} [id2]
         * @param {string} [art]
         * @param {Array<string>} [art2]
         * @param {string} [traegerverband]
         * @param {Array<string>} [traegerverband2]
         * @param {Array<string>} [qSearch]
         * @param {'asc' | 'desc'} [orderNrwKey]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderTraegerverbandName]
         * @param {'asc' | 'desc'} [orderArt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTraegerCollection(
            page?: number,
            itemsPerPage?: number,
            id?: number,
            id2?: Array<number>,
            art?: string,
            art2?: Array<string>,
            traegerverband?: string,
            traegerverband2?: Array<string>,
            qSearch?: Array<string>,
            orderNrwKey?: 'asc' | 'desc',
            orderName?: 'asc' | 'desc',
            orderTraegerverbandName?: 'asc' | 'desc',
            orderArt?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<InlineResponse20022> {
            return localVarFp
                .getTraegerCollection(
                    page,
                    itemsPerPage,
                    id,
                    id2,
                    art,
                    art2,
                    traegerverband,
                    traegerverband2,
                    qSearch,
                    orderNrwKey,
                    orderName,
                    orderTraegerverbandName,
                    orderArt,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Traeger resource.
         * @summary Retrieves a Traeger resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTraegerItem(id: string, options?: any): AxiosPromise<TraegerJsonldTraegerRead> {
            return localVarFp.getTraegerItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Traeger resource.
         * @summary Retrieves a Traeger resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshTraegerItem(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.refreshTraegerItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TraegerApi - object-oriented interface
 * @export
 * @class TraegerApi
 * @extends {BaseAPI}
 */
export class TraegerApi extends BaseAPI {
    /**
     * Retrieves a Traeger resource.
     * @summary Retrieves a Traeger resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TraegerApi
     */
    public existsTraegerItem(id: string, options?: AxiosRequestConfig) {
        return TraegerApiFp(this.configuration)
            .existsTraegerItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Traeger resources.
     * @summary Retrieves the collection of Traeger resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {number} [id]
     * @param {Array<number>} [id2]
     * @param {string} [art]
     * @param {Array<string>} [art2]
     * @param {string} [traegerverband]
     * @param {Array<string>} [traegerverband2]
     * @param {Array<string>} [qSearch]
     * @param {'asc' | 'desc'} [orderNrwKey]
     * @param {'asc' | 'desc'} [orderName]
     * @param {'asc' | 'desc'} [orderTraegerverbandName]
     * @param {'asc' | 'desc'} [orderArt]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TraegerApi
     */
    public getTraegerCollection(
        page?: number,
        itemsPerPage?: number,
        id?: number,
        id2?: Array<number>,
        art?: string,
        art2?: Array<string>,
        traegerverband?: string,
        traegerverband2?: Array<string>,
        qSearch?: Array<string>,
        orderNrwKey?: 'asc' | 'desc',
        orderName?: 'asc' | 'desc',
        orderTraegerverbandName?: 'asc' | 'desc',
        orderArt?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return TraegerApiFp(this.configuration)
            .getTraegerCollection(
                page,
                itemsPerPage,
                id,
                id2,
                art,
                art2,
                traegerverband,
                traegerverband2,
                qSearch,
                orderNrwKey,
                orderName,
                orderTraegerverbandName,
                orderArt,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Traeger resource.
     * @summary Retrieves a Traeger resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TraegerApi
     */
    public getTraegerItem(id: string, options?: AxiosRequestConfig) {
        return TraegerApiFp(this.configuration)
            .getTraegerItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Traeger resource.
     * @summary Retrieves a Traeger resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TraegerApi
     */
    public refreshTraegerItem(id: string, options?: AxiosRequestConfig) {
        return TraegerApiFp(this.configuration)
            .refreshTraegerItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * TraegerverbandApi - axios parameter creator
 * @export
 */
export const TraegerverbandApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a Traegerverband resource.
         * @summary Retrieves a Traegerverband resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        existsTraegerverbandItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('existsTraegerverbandItem', 'id', id);
            const localVarPath = `/api/traegerverband/{id}/exists`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Traegerverband resources.
         * @summary Retrieves the collection of Traegerverband resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [id]
         * @param {Array<number>} [id2]
         * @param {Array<string>} [qSearch]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTraegerverbandCollection: async (
            page?: number,
            itemsPerPage?: number,
            id?: number,
            id2?: Array<number>,
            qSearch?: Array<string>,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/traegerverband`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (id2) {
                localVarQueryParameter['id[]'] = id2;
            }

            if (qSearch) {
                localVarQueryParameter['qSearch[]'] = qSearch;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Traegerverband resource.
         * @summary Retrieves a Traegerverband resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTraegerverbandItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTraegerverbandItem', 'id', id);
            const localVarPath = `/api/traegerverband/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Traegerverband resource.
         * @summary Retrieves a Traegerverband resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshTraegerverbandItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('refreshTraegerverbandItem', 'id', id);
            const localVarPath = `/api/traegerverband/{id}/refresh`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * TraegerverbandApi - functional programming interface
 * @export
 */
export const TraegerverbandApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = TraegerverbandApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves a Traegerverband resource.
         * @summary Retrieves a Traegerverband resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async existsTraegerverbandItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<TraegerverbandTraegerverbandExistsOutputJsonldTraegerverbandExists>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.existsTraegerverbandItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Traegerverband resources.
         * @summary Retrieves the collection of Traegerverband resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [id]
         * @param {Array<number>} [id2]
         * @param {Array<string>} [qSearch]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTraegerverbandCollection(
            page?: number,
            itemsPerPage?: number,
            id?: number,
            id2?: Array<number>,
            qSearch?: Array<string>,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20023>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTraegerverbandCollection(
                page,
                itemsPerPage,
                id,
                id2,
                qSearch,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Traegerverband resource.
         * @summary Retrieves a Traegerverband resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTraegerverbandItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TraegerverbandJsonldTraegerverbandRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTraegerverbandItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Traegerverband resource.
         * @summary Retrieves a Traegerverband resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshTraegerverbandItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshTraegerverbandItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * TraegerverbandApi - factory interface
 * @export
 */
export const TraegerverbandApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = TraegerverbandApiFp(configuration);
    return {
        /**
         * Retrieves a Traegerverband resource.
         * @summary Retrieves a Traegerverband resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        existsTraegerverbandItem(
            id: string,
            options?: any
        ): AxiosPromise<TraegerverbandTraegerverbandExistsOutputJsonldTraegerverbandExists> {
            return localVarFp.existsTraegerverbandItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Traegerverband resources.
         * @summary Retrieves the collection of Traegerverband resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [id]
         * @param {Array<number>} [id2]
         * @param {Array<string>} [qSearch]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTraegerverbandCollection(
            page?: number,
            itemsPerPage?: number,
            id?: number,
            id2?: Array<number>,
            qSearch?: Array<string>,
            options?: any
        ): AxiosPromise<InlineResponse20023> {
            return localVarFp
                .getTraegerverbandCollection(page, itemsPerPage, id, id2, qSearch, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Traegerverband resource.
         * @summary Retrieves a Traegerverband resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTraegerverbandItem(id: string, options?: any): AxiosPromise<TraegerverbandJsonldTraegerverbandRead> {
            return localVarFp.getTraegerverbandItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Traegerverband resource.
         * @summary Retrieves a Traegerverband resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshTraegerverbandItem(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.refreshTraegerverbandItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TraegerverbandApi - object-oriented interface
 * @export
 * @class TraegerverbandApi
 * @extends {BaseAPI}
 */
export class TraegerverbandApi extends BaseAPI {
    /**
     * Retrieves a Traegerverband resource.
     * @summary Retrieves a Traegerverband resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TraegerverbandApi
     */
    public existsTraegerverbandItem(id: string, options?: AxiosRequestConfig) {
        return TraegerverbandApiFp(this.configuration)
            .existsTraegerverbandItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Traegerverband resources.
     * @summary Retrieves the collection of Traegerverband resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {number} [id]
     * @param {Array<number>} [id2]
     * @param {Array<string>} [qSearch]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TraegerverbandApi
     */
    public getTraegerverbandCollection(
        page?: number,
        itemsPerPage?: number,
        id?: number,
        id2?: Array<number>,
        qSearch?: Array<string>,
        options?: AxiosRequestConfig
    ) {
        return TraegerverbandApiFp(this.configuration)
            .getTraegerverbandCollection(page, itemsPerPage, id, id2, qSearch, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Traegerverband resource.
     * @summary Retrieves a Traegerverband resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TraegerverbandApi
     */
    public getTraegerverbandItem(id: string, options?: AxiosRequestConfig) {
        return TraegerverbandApiFp(this.configuration)
            .getTraegerverbandItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Traegerverband resource.
     * @summary Retrieves a Traegerverband resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TraegerverbandApi
     */
    public refreshTraegerverbandItem(id: string, options?: AxiosRequestConfig) {
        return TraegerverbandApiFp(this.configuration)
            .refreshTraegerverbandItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * UploadApi - axios parameter creator
 * @export
 */
export const UploadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a Upload resource.
         * @summary Retrieves a Upload resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUploadItem', 'id', id);
            const localVarPath = `/api/upload/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Upload resource.
         * @summary Creates a Upload resource.
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUploadCollection: async (file?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * UploadApi - functional programming interface
 * @export
 */
export const UploadApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = UploadApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves a Upload resource.
         * @summary Retrieves a Upload resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUploadItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadJsonldUploadRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUploadItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Upload resource.
         * @summary Creates a Upload resource.
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUploadCollection(
            file?: any,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadJsonldUploadRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUploadCollection(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * UploadApi - factory interface
 * @export
 */
export const UploadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UploadApiFp(configuration);
    return {
        /**
         * Retrieves a Upload resource.
         * @summary Retrieves a Upload resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadItem(id: string, options?: any): AxiosPromise<UploadJsonldUploadRead> {
            return localVarFp.getUploadItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Upload resource.
         * @summary Creates a Upload resource.
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUploadCollection(file?: any, options?: any): AxiosPromise<UploadJsonldUploadRead> {
            return localVarFp.postUploadCollection(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UploadApi - object-oriented interface
 * @export
 * @class UploadApi
 * @extends {BaseAPI}
 */
export class UploadApi extends BaseAPI {
    /**
     * Retrieves a Upload resource.
     * @summary Retrieves a Upload resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public getUploadItem(id: string, options?: AxiosRequestConfig) {
        return UploadApiFp(this.configuration)
            .getUploadItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Upload resource.
     * @summary Creates a Upload resource.
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public postUploadCollection(file?: any, options?: AxiosRequestConfig) {
        return UploadApiFp(this.configuration)
            .postUploadCollection(file, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * ZustaendigkeitApi - axios parameter creator
 * @export
 */
export const ZustaendigkeitApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of Zustaendigkeit resources.
         * @summary Retrieves the collection of Zustaendigkeit resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {Array<string>} [qSearch]
         * @param {string} [benutzer]
         * @param {Array<string>} [benutzer2]
         * @param {string} [kreis]
         * @param {Array<string>} [kreis2]
         * @param {'asc' | 'desc'} [orderRegion]
         * @param {'asc' | 'desc'} [orderKreis]
         * @param {'asc' | 'desc'} [orderOrt]
         * @param {'asc' | 'desc'} [orderEinrichtungArt]
         * @param {'asc' | 'desc'} [orderBenutzerName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZustaendigkeitCollection: async (
            page?: number,
            itemsPerPage?: number,
            qSearch?: Array<string>,
            benutzer?: string,
            benutzer2?: Array<string>,
            kreis?: string,
            kreis2?: Array<string>,
            orderRegion?: 'asc' | 'desc',
            orderKreis?: 'asc' | 'desc',
            orderOrt?: 'asc' | 'desc',
            orderEinrichtungArt?: 'asc' | 'desc',
            orderBenutzerName?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/zustaendigkeit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (qSearch) {
                localVarQueryParameter['qSearch[]'] = qSearch;
            }

            if (benutzer !== undefined) {
                localVarQueryParameter['benutzer'] = benutzer;
            }

            if (benutzer2) {
                localVarQueryParameter['benutzer[]'] = benutzer2;
            }

            if (kreis !== undefined) {
                localVarQueryParameter['kreis'] = kreis;
            }

            if (kreis2) {
                localVarQueryParameter['kreis[]'] = kreis2;
            }

            if (orderRegion !== undefined) {
                localVarQueryParameter['order[region]'] = orderRegion;
            }

            if (orderKreis !== undefined) {
                localVarQueryParameter['order[kreis]'] = orderKreis;
            }

            if (orderOrt !== undefined) {
                localVarQueryParameter['order[ort]'] = orderOrt;
            }

            if (orderEinrichtungArt !== undefined) {
                localVarQueryParameter['order[einrichtungArt]'] = orderEinrichtungArt;
            }

            if (orderBenutzerName !== undefined) {
                localVarQueryParameter['order[benutzer.name]'] = orderBenutzerName;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Zustaendigkeit resource.
         * @summary Retrieves a Zustaendigkeit resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZustaendigkeitItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getZustaendigkeitItem', 'id', id);
            const localVarPath = `/api/zustaendigkeit/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Zustaendigkeit resource.
         * @summary Creates a Zustaendigkeit resource.
         * @param {ZustaendigkeitJsonldZustaendigkeitWrite} zustaendigkeitJsonldZustaendigkeitWrite The new Zustaendigkeit resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postZustaendigkeitCollection: async (
            zustaendigkeitJsonldZustaendigkeitWrite: ZustaendigkeitJsonldZustaendigkeitWrite,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'zustaendigkeitJsonldZustaendigkeitWrite' is not null or undefined
            assertParamExists(
                'postZustaendigkeitCollection',
                'zustaendigkeitJsonldZustaendigkeitWrite',
                zustaendigkeitJsonldZustaendigkeitWrite
            );
            const localVarPath = `/api/zustaendigkeit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                zustaendigkeitJsonldZustaendigkeitWrite,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Zustaendigkeit resource.
         * @summary Replaces the Zustaendigkeit resource.
         * @param {string} id Resource identifier
         * @param {ZustaendigkeitJsonldZustaendigkeitWrite} zustaendigkeitJsonldZustaendigkeitWrite The updated Zustaendigkeit resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putZustaendigkeitItem: async (
            id: string,
            zustaendigkeitJsonldZustaendigkeitWrite: ZustaendigkeitJsonldZustaendigkeitWrite,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putZustaendigkeitItem', 'id', id);
            // verify required parameter 'zustaendigkeitJsonldZustaendigkeitWrite' is not null or undefined
            assertParamExists(
                'putZustaendigkeitItem',
                'zustaendigkeitJsonldZustaendigkeitWrite',
                zustaendigkeitJsonldZustaendigkeitWrite
            );
            const localVarPath = `/api/zustaendigkeit/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                zustaendigkeitJsonldZustaendigkeitWrite,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * ZustaendigkeitApi - functional programming interface
 * @export
 */
export const ZustaendigkeitApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = ZustaendigkeitApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of Zustaendigkeit resources.
         * @summary Retrieves the collection of Zustaendigkeit resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {Array<string>} [qSearch]
         * @param {string} [benutzer]
         * @param {Array<string>} [benutzer2]
         * @param {string} [kreis]
         * @param {Array<string>} [kreis2]
         * @param {'asc' | 'desc'} [orderRegion]
         * @param {'asc' | 'desc'} [orderKreis]
         * @param {'asc' | 'desc'} [orderOrt]
         * @param {'asc' | 'desc'} [orderEinrichtungArt]
         * @param {'asc' | 'desc'} [orderBenutzerName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getZustaendigkeitCollection(
            page?: number,
            itemsPerPage?: number,
            qSearch?: Array<string>,
            benutzer?: string,
            benutzer2?: Array<string>,
            kreis?: string,
            kreis2?: Array<string>,
            orderRegion?: 'asc' | 'desc',
            orderKreis?: 'asc' | 'desc',
            orderOrt?: 'asc' | 'desc',
            orderEinrichtungArt?: 'asc' | 'desc',
            orderBenutzerName?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20024>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getZustaendigkeitCollection(
                page,
                itemsPerPage,
                qSearch,
                benutzer,
                benutzer2,
                kreis,
                kreis2,
                orderRegion,
                orderKreis,
                orderOrt,
                orderEinrichtungArt,
                orderBenutzerName,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Zustaendigkeit resource.
         * @summary Retrieves a Zustaendigkeit resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getZustaendigkeitItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZustaendigkeitJsonldZustaendigkeitRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getZustaendigkeitItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Zustaendigkeit resource.
         * @summary Creates a Zustaendigkeit resource.
         * @param {ZustaendigkeitJsonldZustaendigkeitWrite} zustaendigkeitJsonldZustaendigkeitWrite The new Zustaendigkeit resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postZustaendigkeitCollection(
            zustaendigkeitJsonldZustaendigkeitWrite: ZustaendigkeitJsonldZustaendigkeitWrite,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZustaendigkeitJsonldZustaendigkeitRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postZustaendigkeitCollection(
                zustaendigkeitJsonldZustaendigkeitWrite,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Zustaendigkeit resource.
         * @summary Replaces the Zustaendigkeit resource.
         * @param {string} id Resource identifier
         * @param {ZustaendigkeitJsonldZustaendigkeitWrite} zustaendigkeitJsonldZustaendigkeitWrite The updated Zustaendigkeit resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putZustaendigkeitItem(
            id: string,
            zustaendigkeitJsonldZustaendigkeitWrite: ZustaendigkeitJsonldZustaendigkeitWrite,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZustaendigkeitJsonldZustaendigkeitRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putZustaendigkeitItem(
                id,
                zustaendigkeitJsonldZustaendigkeitWrite,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * ZustaendigkeitApi - factory interface
 * @export
 */
export const ZustaendigkeitApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = ZustaendigkeitApiFp(configuration);
    return {
        /**
         * Retrieves the collection of Zustaendigkeit resources.
         * @summary Retrieves the collection of Zustaendigkeit resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {Array<string>} [qSearch]
         * @param {string} [benutzer]
         * @param {Array<string>} [benutzer2]
         * @param {string} [kreis]
         * @param {Array<string>} [kreis2]
         * @param {'asc' | 'desc'} [orderRegion]
         * @param {'asc' | 'desc'} [orderKreis]
         * @param {'asc' | 'desc'} [orderOrt]
         * @param {'asc' | 'desc'} [orderEinrichtungArt]
         * @param {'asc' | 'desc'} [orderBenutzerName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZustaendigkeitCollection(
            page?: number,
            itemsPerPage?: number,
            qSearch?: Array<string>,
            benutzer?: string,
            benutzer2?: Array<string>,
            kreis?: string,
            kreis2?: Array<string>,
            orderRegion?: 'asc' | 'desc',
            orderKreis?: 'asc' | 'desc',
            orderOrt?: 'asc' | 'desc',
            orderEinrichtungArt?: 'asc' | 'desc',
            orderBenutzerName?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<InlineResponse20024> {
            return localVarFp
                .getZustaendigkeitCollection(
                    page,
                    itemsPerPage,
                    qSearch,
                    benutzer,
                    benutzer2,
                    kreis,
                    kreis2,
                    orderRegion,
                    orderKreis,
                    orderOrt,
                    orderEinrichtungArt,
                    orderBenutzerName,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Zustaendigkeit resource.
         * @summary Retrieves a Zustaendigkeit resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZustaendigkeitItem(id: string, options?: any): AxiosPromise<ZustaendigkeitJsonldZustaendigkeitRead> {
            return localVarFp.getZustaendigkeitItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Zustaendigkeit resource.
         * @summary Creates a Zustaendigkeit resource.
         * @param {ZustaendigkeitJsonldZustaendigkeitWrite} zustaendigkeitJsonldZustaendigkeitWrite The new Zustaendigkeit resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postZustaendigkeitCollection(
            zustaendigkeitJsonldZustaendigkeitWrite: ZustaendigkeitJsonldZustaendigkeitWrite,
            options?: any
        ): AxiosPromise<ZustaendigkeitJsonldZustaendigkeitRead> {
            return localVarFp
                .postZustaendigkeitCollection(zustaendigkeitJsonldZustaendigkeitWrite, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Zustaendigkeit resource.
         * @summary Replaces the Zustaendigkeit resource.
         * @param {string} id Resource identifier
         * @param {ZustaendigkeitJsonldZustaendigkeitWrite} zustaendigkeitJsonldZustaendigkeitWrite The updated Zustaendigkeit resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putZustaendigkeitItem(
            id: string,
            zustaendigkeitJsonldZustaendigkeitWrite: ZustaendigkeitJsonldZustaendigkeitWrite,
            options?: any
        ): AxiosPromise<ZustaendigkeitJsonldZustaendigkeitRead> {
            return localVarFp
                .putZustaendigkeitItem(id, zustaendigkeitJsonldZustaendigkeitWrite, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * ZustaendigkeitApi - object-oriented interface
 * @export
 * @class ZustaendigkeitApi
 * @extends {BaseAPI}
 */
export class ZustaendigkeitApi extends BaseAPI {
    /**
     * Retrieves the collection of Zustaendigkeit resources.
     * @summary Retrieves the collection of Zustaendigkeit resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {Array<string>} [qSearch]
     * @param {string} [benutzer]
     * @param {Array<string>} [benutzer2]
     * @param {string} [kreis]
     * @param {Array<string>} [kreis2]
     * @param {'asc' | 'desc'} [orderRegion]
     * @param {'asc' | 'desc'} [orderKreis]
     * @param {'asc' | 'desc'} [orderOrt]
     * @param {'asc' | 'desc'} [orderEinrichtungArt]
     * @param {'asc' | 'desc'} [orderBenutzerName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZustaendigkeitApi
     */
    public getZustaendigkeitCollection(
        page?: number,
        itemsPerPage?: number,
        qSearch?: Array<string>,
        benutzer?: string,
        benutzer2?: Array<string>,
        kreis?: string,
        kreis2?: Array<string>,
        orderRegion?: 'asc' | 'desc',
        orderKreis?: 'asc' | 'desc',
        orderOrt?: 'asc' | 'desc',
        orderEinrichtungArt?: 'asc' | 'desc',
        orderBenutzerName?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return ZustaendigkeitApiFp(this.configuration)
            .getZustaendigkeitCollection(
                page,
                itemsPerPage,
                qSearch,
                benutzer,
                benutzer2,
                kreis,
                kreis2,
                orderRegion,
                orderKreis,
                orderOrt,
                orderEinrichtungArt,
                orderBenutzerName,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Zustaendigkeit resource.
     * @summary Retrieves a Zustaendigkeit resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZustaendigkeitApi
     */
    public getZustaendigkeitItem(id: string, options?: AxiosRequestConfig) {
        return ZustaendigkeitApiFp(this.configuration)
            .getZustaendigkeitItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Zustaendigkeit resource.
     * @summary Creates a Zustaendigkeit resource.
     * @param {ZustaendigkeitJsonldZustaendigkeitWrite} zustaendigkeitJsonldZustaendigkeitWrite The new Zustaendigkeit resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZustaendigkeitApi
     */
    public postZustaendigkeitCollection(
        zustaendigkeitJsonldZustaendigkeitWrite: ZustaendigkeitJsonldZustaendigkeitWrite,
        options?: AxiosRequestConfig
    ) {
        return ZustaendigkeitApiFp(this.configuration)
            .postZustaendigkeitCollection(zustaendigkeitJsonldZustaendigkeitWrite, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Zustaendigkeit resource.
     * @summary Replaces the Zustaendigkeit resource.
     * @param {string} id Resource identifier
     * @param {ZustaendigkeitJsonldZustaendigkeitWrite} zustaendigkeitJsonldZustaendigkeitWrite The updated Zustaendigkeit resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZustaendigkeitApi
     */
    public putZustaendigkeitItem(
        id: string,
        zustaendigkeitJsonldZustaendigkeitWrite: ZustaendigkeitJsonldZustaendigkeitWrite,
        options?: AxiosRequestConfig
    ) {
        return ZustaendigkeitApiFp(this.configuration)
            .putZustaendigkeitItem(id, zustaendigkeitJsonldZustaendigkeitWrite, options)
            .then((request) => request(this.axios, this.basePath));
    }
}
