import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { highlightedArea } from 'forms/AntragForm/ui-schemas/elements/highlightedArea';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';

export const uiSchema = verticalLayout([
    group(
        [control('field5410'), control('field5410kv'), control('field5411'), control('field5411kv')],
        {},
        'Gesamtsumme Fremdkapital',
        '#/properties/field5410'
    ),
    group(
        [
            control('field5423'),
            control('field5423kv'),
            control('field5424'),
            control('field5424kv'),
            control('field1425'),
        ],
        {},
        'Gesamtsumme Eigenkapital',
        '#/properties/field5423'
    ),
    group(
        [control('field5432'), control('field5432kv'), control('field5433'), control('field5433kv')],
        {},
        'Gesamtsumme Leasing',
        '#/properties/field5432'
    ),
    group(
        [highlightedArea([control('field5437'), control('field5437kv'), control('field5437a')])],
        {},
        'Gesamtsummen Finanzierungskosten FK EK und Leasing'
    ),
]);
