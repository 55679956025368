import React, { ReactElement, ReactNode } from 'react';

import { TraegerverbandListResponse } from 'api/types';
import { Link } from 'elements/Link';
import { InvestPathBuilder, TraegerverbandTargets } from 'navigation/Paths';

type TraegerverbandListLinkProps = {
    traegerVerbandId: number;
    target: TraegerverbandTargets;
    children: ReactNode;
};

export const TraegerverbandListLink = ({
    traegerVerbandId,
    target,
    children,
}: TraegerverbandListLinkProps): ReactElement => {
    const to = InvestPathBuilder.home.verwaltung.traegerverband.target(traegerVerbandId, target);
    return <Link to={to}>{children}</Link>;
};

export const traegerverbandListLinkRender = (
    data: TraegerverbandListResponse,
    index: number,
    target: TraegerverbandTargets
): ReactNode => {
    const row = data['hydra:member'][index];
    const id = row.id;
    const targetValue = row[target];
    if (!id || !targetValue) return <>0</>;
    return targetValue.length > 0 ? (
        <TraegerverbandListLink traegerVerbandId={id} target={target}>
            {targetValue.length}
        </TraegerverbandListLink>
    ) : (
        <>0</>
    );
};
