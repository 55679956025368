import { EinrichtungJsonldEinrichtungList } from 'api/client';

export enum ZustaendigkeitenEinrichtungTableActions {
    BEARBEITEN = 'BEARBEITEN',
}

export type ZustaendigkeitenEinrichtungTableAction = (
    action: ZustaendigkeitenEinrichtungTableActions,
    einrichtung: EinrichtungJsonldEinrichtungList
) => Promise<void>;
