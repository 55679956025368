import React, { useMemo } from 'react';

import { AntragType } from 'api/antragTypes';
import { AntragResponse } from 'api/types';
import { Step } from 'layout/HeaderConfig';
import { AntragDetailsChangeList } from 'pages/Antraege/components/AntragDetailsChangeList';
import { AntragDetailsVersionList } from 'pages/Antraege/components/AntragDetailsVersionList';

export const useAntragDetailsTabConfig = (type: AntragType, antrag: AntragResponse): Step<string>[] => {
    return useMemo(() => {
        return [
            {
                title: 'Antragsversionen',
                step: 'antragsversionen',
                component: <AntragDetailsVersionList type={type} antrag={antrag} />,
                state: 'active',
            },
            {
                title: 'Änderungen',
                step: 'aenderungen',
                component: <AntragDetailsChangeList type={type} antrag={antrag} />,
                state: 'active',
            },
        ];
    }, [type, antrag]);
};
