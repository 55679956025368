import { MenuInterface } from 'layout/HeaderConfig';
import { InvestPathBuilder } from 'navigation/Paths';

export interface ExterneQuellenLocationState {
    state: {
        openModal: boolean;
    } | null;
}

const externeQuellen = InvestPathBuilder.home.externeQuellen;

export const ExterneQuellenConfig: { menu: MenuInterface[] } = {
    menu: [
        {
            name: 'Baupreisindex',
            collapse: true,
            menus: [
                {
                    path: externeQuellen.baupreisindex._2010.path,
                    name: 'Baupreisindex 2010',
                },
                {
                    path: externeQuellen.baupreisindex._2015.path,
                    name: 'Baupreisindex 2015',
                },
            ],
        },
        {
            path: externeQuellen.eigenkapitalzinsP56ApgDvo.path,
            name: 'Eigenkapitalzins § 5 Abs. 6 APG DVO',
            divide: true,
        },
        {
            path: externeQuellen.fremdkapitalzinsenSud119.path,
            name: 'Fremdkapitalzinsen (SUD 119)',
        },
        {
            path: externeQuellen.eigenkapitalzinsBundesbank.path,
            name: 'Eigenkapitalzinsen gemäß Bundesbank',
        },
        {
            path: externeQuellen.angemessenheitsgrenze.festgesetzterInstandhaltungswert.path,
            name: 'Instandhaltungswert pro qm',
        },
        {
            name: 'Angemessenheitsgrenzen vollstationär',
            divide: true,
            collapse: true,
            menus: [
                {
                    path: externeQuellen.angemessenheitsgrenze.vor1983.path,
                    name: 'Vor 1983',
                },
                {
                    path: externeQuellen.angemessenheitsgrenze.allgemeineVereinbarung.path,
                    name: 'Allgemeine Vereinbarung',
                },
                {
                    path: externeQuellen.angemessenheitsgrenze.gesbervo1996Vollstationaer.path,
                    name: 'GesBerVO 1996',
                },
                {
                    path: externeQuellen.angemessenheitsgrenze.gesbervo2003Vollstationaer.path,
                    name: 'GesBerVO 2003',
                },
                {
                    path: externeQuellen.angemessenheitsgrenze.gesbervo2008Vollstationaer.path,
                    name: 'GesBerVO 2008',
                },
                {
                    path: externeQuellen.angemessenheitsgrenze.abstimmungsbescheidVor2008.path,
                    name: 'Abstimmung vor 01.07.2008',
                },
                {
                    path: externeQuellen.angemessenheitsgrenze.abstimmungsbescheidAb2008.path,
                    name: 'Abstimmung ab 01.07.2008',
                },
                {
                    path: externeQuellen.angemessenheitsgrenze.apgDvoVollstationaer.path,
                    name: 'APG DVO',
                },
                {
                    path: externeQuellen.angemessenheitsgrenze.apgDvoMitKueche.path,
                    name: 'APG DVO mit Küche',
                },
            ],
        },
        {
            name: 'Angemessenheitsgrenzen teilstationär',
            divide: true,
            collapse: true,
            menus: [
                {
                    path: externeQuellen.angemessenheitsgrenze.gesbervo1996Teilstationaer.path,
                    name: 'GesBerVO 1996',
                },
                {
                    path: externeQuellen.angemessenheitsgrenze.gesbervo2003Teilstationaer.path,
                    name: 'GesBerVO 2003',
                },
                {
                    path: externeQuellen.angemessenheitsgrenze.apgDvoTeilstationaer.path,
                    name: 'APG DVO',
                },
            ],
        },
    ],
};
