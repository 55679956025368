import * as React from 'react';
import { ChangeEvent, useCallback, useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { FormControl, OutlinedInput } from '@mui/material';

import { useFocus } from 'forms/hooks/useFocus';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';

import { RowControl } from './RowControl';

const PasswordControlComponent: React.FC<CustomControlProps<string>> = ({
    path,
    label,
    required,
    data,
    handleChange,
    hasErrors,
    disabled,
    onTouched,
    schema,
    showFieldNumberLabels,
    gridLayout,
    id,
    config,
}) => {
    const [show, setShow] = useState<boolean>(false);
    const { ref, setFocus } = useFocus();

    const handleValueChange = useCallback(
        ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
            handleChange(path, value === '' ? undefined : value);
        },
        [handleChange, path]
    );

    const handleToggleShow = () => {
        setShow(!show);
        setFocus();
    };

    const autoComplete = schema?.custom?.block_prefixes?.includes('current_password');

    return (
        <RowControl
            name={path}
            label={label}
            required={required}
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={gridLayout}
            labelForId={id}
            config={config}
            paths={[path]}
        >
            <FormControl fullWidth>
                <OutlinedInput
                    id={id}
                    fullWidth
                    autoComplete={autoComplete ? 'current-password' : undefined}
                    placeholder="Passwort eingeben ..."
                    ref={ref}
                    type={!show ? 'password' : 'text'}
                    required={required}
                    value={data}
                    error={hasErrors}
                    disabled={disabled}
                    onBlur={onTouched}
                    margin={'dense'}
                    onChange={handleValueChange}
                    inputProps={{ 'data-cy': `form_${path}` }}
                    endAdornment={
                        !show ? (
                            <VisibilityIcon onClick={handleToggleShow} />
                        ) : (
                            <VisibilityOffIcon onClick={handleToggleShow} />
                        )
                    }
                />
            </FormControl>
        </RowControl>
    );
};

export const PasswordControl = withCustomControlProps(PasswordControlComponent);
