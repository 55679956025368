import React, { useState } from 'react';
import { Button, Grid, styled } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { useAuthHelper } from 'api/auth/useAuthHelper';
import { EinrichtungResponse, RuhendstellungGrundType } from 'api/types';
import { STATUS as EINRICHTUNG_STATUS } from 'constants/einrichtungStatus';
import { EINRICHTUNG_STATUS_LABELS } from 'constants/labels';
import { ROLES } from 'constants/roles';
import { useConfirmationDialogActions } from 'forms/state/useConfirmationDialogState';
import { useLoadingActions } from 'forms/state/useLoadingState';
import { useMessageActions } from 'forms/state/useMessages';
import { errorMessage, successMessage } from 'forms/utils/MessageUtils';

import { RuhendstellungDialog } from './RuhendstellungDialog';

interface RuhendstellungProps {
    einrichtung: EinrichtungResponse;
    refreshEinrichtung: () => void;
}

export const Ruhendstellung: React.FC<RuhendstellungProps> = ({ einrichtung, refreshEinrichtung }) => {
    const [isOpen, setOpen] = useState(false);
    const { hasRole } = useAuthHelper();
    const { addMessage } = useMessageActions();
    const { showConfirmation, closeConfirmation } = useConfirmationDialogActions();
    const { showLoading, hideLoading } = useLoadingActions();

    const handleRuhendstellung = async (grund: RuhendstellungGrundType, geschlossenAb: string | null) => {
        try {
            showLoading('Einrichtung wird ruhend gestellt.');
            await backendApiService.postRuhendstellung({
                einrichtung: String(einrichtung.id),
                geschlossenAb,
                grund,
            });
            refreshEinrichtung();
            setOpen(false);
            addMessage(successMessage('Einrichtung ruhend gestellt.', { autoCloseSeconds: 8 }));
        } catch (e) {
            console.error(e);
            addMessage(errorMessage('Ruhendstellung fehlgeschlagen.', { autoCloseSeconds: 8 }));
        } finally {
            hideLoading();
        }
    };

    const handleReopen = () => {
        showConfirmation({
            alertText: `Sind Sie sicher, dass Sie den Status der Einrichtung von '${
                EINRICHTUNG_STATUS_LABELS[EINRICHTUNG_STATUS.RUHEND_VERZICHT_AUF_PFLEGEWOHNGELD]
            }' in '${EINRICHTUNG_STATUS_LABELS[EINRICHTUNG_STATUS.AKTIV]}' ändern möchten?`,
            confirmLabel: 'Einrichtung aktivieren',
            denyLabel: 'Abbrechen',
            confirmAction: async () => {
                await backendApiService.postEinrichtungReaktivieren(einrichtung.id);
                refreshEinrichtung();
                closeConfirmation();
            },
            denyAction: closeConfirmation,
        });
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {einrichtung.status === EINRICHTUNG_STATUS.AKTIV && hasRole(ROLES.LV_ADMIN) && (
                    <>
                        <PaddedButton variant="contained" color="primary" onClick={() => setOpen(true)}>
                            Einrichtung ruhend stellen
                        </PaddedButton>
                        <RuhendstellungDialog
                            open={isOpen}
                            closeModal={() => setOpen(false)}
                            handleSave={handleRuhendstellung}
                        />
                    </>
                )}

                {einrichtung.status === EINRICHTUNG_STATUS.RUHEND_VERZICHT_AUF_PFLEGEWOHNGELD &&
                    hasRole(ROLES.LV_ADMIN) && (
                        <>
                            <PaddedButton variant="contained" color="primary" onClick={() => setOpen(true)}>
                                Einrichtung schließen
                            </PaddedButton>
                            <RuhendstellungDialog
                                open={isOpen}
                                closeModal={() => setOpen(false)}
                                handleSave={handleRuhendstellung}
                                close
                            />
                        </>
                    )}

                {einrichtung.status !== EINRICHTUNG_STATUS.AKTIV && hasRole(ROLES.LV_ADMIN) && (
                    <PaddedButton variant="contained" color="primary" onClick={handleReopen}>
                        Einrichtung aktivieren
                    </PaddedButton>
                )}
            </Grid>
        </Grid>
    );
};

const PaddedButton = styled(Button)(({ theme }) => ({
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
}));
