import { useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { useAuthHelper } from 'api/auth/useAuthHelper';
import { InvestorenmodellCollectionListResponse, InvestorenmodellCollectionResponse } from 'api/types';
import { ActionDropdownAntrag } from 'components/DataTableHydra/ActionDropdown/ActionDropdownAntrag';
import { JahrFilterOptions } from 'components/DataTableHydra/Filters/JahrFilterOptions';
import { MieteEigentumFilterOptions } from 'components/DataTableHydra/Filters/MieteEigentumFilterOptions';
import { StatusFilterOptions } from 'components/DataTableHydra/Filters/StatusFilterOptions';
import { ZustaendigerBenutzerFilterOptions } from 'components/DataTableHydra/Filters/ZustaendigerBenutzerFilterOptions';
import { useLvBenutzerOptions } from 'components/DataTableHydra/hooks/useLvBenutzerOptions';
import { GetColumns } from 'components/DataTableHydra/hooks/useTableColumns';
import { TABLE_ACTIONS } from 'constants/antragActions';
import { ANTRAG_STATUS_LABELS } from 'constants/labels';
import { ROLES } from 'constants/roles';
import { LinkEinrichtung } from 'elements/LinkEinrichtung';
import { formatDateToString } from 'forms/utils/formatter';
import { AntragFilterOptions } from 'pages/Antraege/components/useAntragFilterOptions';
import { AntragListAction } from 'pages/Antraege/components/useAntragListActions';
import { displayBenutzerName } from 'utilities';

export const useInvestorenmodellListColumns = (
    handleAction: AntragListAction<InvestorenmodellCollectionResponse>,
    filterOptions: AntragFilterOptions | undefined
): GetColumns<InvestorenmodellCollectionListResponse> => {
    const { hasRole } = useAuthHelper();
    const { options: zustandigerBenutzerOptions } = useLvBenutzerOptions();
    return useCallback(
        (data) => {
            if (!data) return [];

            const datum: MUIDataTableColumnDef = {
                name: 'zuletztEingereichtAt',
                label: 'Antragsdatum',
                options: {
                    filter: false,
                    customBodyRender: (zuletztEingereichtAt: string | undefined) => {
                        return zuletztEingereichtAt ? formatDateToString(new Date(zuletztEingereichtAt)) : '-';
                    },
                },
            };

            const investorenmodell: MUIDataTableColumnDef = {
                name: 'jahr',
                label: 'Festsetzung',
                options: {
                    ...JahrFilterOptions('Festsetzung', filterOptions?.antragJahr),
                    customBodyRenderLite: (dataIndex: number) => {
                        const value = data['hydra:member'][dataIndex].festsetzungsantrag?.data?.field500;
                        return value ? formatDateToString(new Date(value)) : '-';
                    },
                },
            };

            const aktenzeichen: MUIDataTableColumnDef = {
                name: 'aktenzeichen',
                label: 'Aktenzeichen',
                options: {
                    filter: false,
                    customBodyRenderLite: (dataIndex: number) => {
                        return data['hydra:member'][dataIndex].einrichtung?.aktenzeichen || '-';
                    },
                },
            };

            const berechnung: MUIDataTableColumnDef = {
                name: 'berechnungsart',
                label: 'Miete oder Eigentum',
                options: {
                    ...MieteEigentumFilterOptions,
                    customBodyRenderLite: (dataIndex: number) => {
                        return (
                            data['hydra:member'][dataIndex].festsetzungsantrag?.feststellungsantrag?.data
                                ?.berechnungsart || '-'
                        );
                    },
                },
            };

            const einrichtung: MUIDataTableColumnDef = {
                name: 'einrichtung',
                label: 'Name der PE',
                options: {
                    filter: false,
                    customBodyRenderLite: (dataIndex: number) => {
                        const { id, name } = data['hydra:member'][dataIndex].einrichtung ?? {};
                        if (!id || !name) return <></>;
                        return <LinkEinrichtung id={id} name={name} />;
                    },
                },
            };

            const status: MUIDataTableColumnDef = {
                name: 'status',
                label: 'Status',
                options: {
                    ...StatusFilterOptions(hasRole(ROLES.LV)),
                    customBodyRender: (status: string): string => {
                        return ANTRAG_STATUS_LABELS[status] || status;
                    },
                },
            };

            const zustaendigerBenutzer: MUIDataTableColumnDef = {
                name: 'zustaendigerBenutzer ',
                label: 'Zuständig',
                options: {
                    ...ZustaendigerBenutzerFilterOptions(
                        zustandigerBenutzerOptions,
                        filterOptions?.zustaendigerBenutzer
                    ),
                    customBodyRenderLite: (dataIndex: number) => {
                        const benutzer = data['hydra:member'][dataIndex].einrichtung?.zustaendigerBenutzer;
                        return displayBenutzerName(benutzer) ?? '';
                    },
                },
            };

            const actions: MUIDataTableColumnDef = {
                name: 'actions',
                label: 'Aktion',
                options: {
                    sort: false,
                    filter: false,
                    viewColumns: false,
                    customBodyRenderLite: (dataIndex: number) => {
                        const rowData = data['hydra:member'][dataIndex];
                        return (
                            Boolean(rowData.allowedWorkflowActions?.length) && (
                                <ActionDropdownAntrag
                                    antrag={rowData}
                                    actions={TABLE_ACTIONS}
                                    handleAction={handleAction}
                                />
                            )
                        );
                    },
                },
            };

            return [
                datum,
                investorenmodell,
                aktenzeichen,
                berechnung,
                einrichtung,
                status,
                zustaendigerBenutzer,
                actions,
            ];
        },
        [hasRole, zustandigerBenutzerOptions, handleAction, filterOptions]
    );
};
