export const basisdaten_table = {
    type: 'SummaryTable',
    label: 'Basisdaten',
    columns: 3,
    scope: '#/properties/basisdaten',
    entries: [
        {
            type: 'FieldRow',
            scope: '#/properties/field542',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/field500',
        },
        {
            type: 'NotesRow',
            scope: '#/properties/notizBasisdaten',
        },
    ],
};
