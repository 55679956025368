import { materialRenderers } from '@jsonforms/material-renderers';

import { Description, DescriptionTester } from 'forms/controls/components/Description';
import {
    ControlGroupsOfElements,
    ControlGroupsOfElementsTester,
} from 'forms/controls/ControlsOfElements/ControlGroupsOfElements';
import { ControlsOfElements, ControlsOfElementsTester } from 'forms/controls/ControlsOfElements/ControlsOfElements';
import { CustomHorizontalLayout, CustomHorizontalLayoutTester } from 'forms/controls/layout/CustomHorizontalLayout';
import { CustomVerticalLayout } from 'forms/controls/layout/CustomVerticalLayout';
import { HighlightedArea, HighlightedAreaTester } from 'forms/controls/layout/HighlightedArea';
import { TextAreaEinzeiligControl } from 'forms/controls/TextAreaEinzeiligControl';
import { TextAreaEinzeiligTester } from 'forms/tester/TextAreaEinzeiligTester';

import { AccordionControl } from './controls/AccordionControl';
import { AuswahlControl } from './controls/AuswahlControl';
import { BenutzerAutocompleteControl } from './controls/BenutzerAutocompleteControl';
import {
    BescheidDokumentGenerierenLinkControl,
    BescheidDokumentGenerierenLinkTester,
} from './controls/BescheidDokumentGenerierenLinkControl';
import { CheckboxControl } from './controls/CheckboxControl';
import { CheckboxMultipleControl } from './controls/CheckboxMultipleControl';
import { CollectionGroups, CollectionGroupsTester } from './controls/CollectionGroups/CollectionGroups';
import { DataGrid, DataGridTester } from './controls/DataGrid/DataGrid';
import { DateControl } from './controls/DateControl';
import { DezimalzahlControl } from './controls/DezimalzahlControl';
import { DocumentTableControl } from './controls/DocumentTable/DocumentTableControl';
import { DownloadLinkControl } from './controls/DownloadLinkControl';
import { EinrichtungAutocompleteControl } from './controls/EinrichtungAutocompleteControl';
import { EmailChangeControl } from './controls/EmailChange/EmailChangeControl';
import { FallbackControl } from './controls/FallbackControl';
import { FormularerweiterungButtonControl } from './controls/FormularerweiterungButton/FormularerweiterungButtonControl';
import { GanzzahlControl } from './controls/GanzzahlControl';
import { GroupControl } from './controls/GroupControl';
import { GroupForm, GroupFormTester } from './controls/GroupForm/GroupForm';
import { InlineGroupRowControl, InlineGroupRowControlTester } from './controls/GroupForm/InlineGroupRowControl';
import { NestedGroup, NestedGroupTester } from './controls/GroupForm/NestedGroup';
import { GroupList, GroupListTester } from './controls/GroupList/GroupList';
import { ImpersonateAutocompleteControl } from './controls/ImpersonateAutocompleteControl';
import { InlineRowControl } from './controls/InlineRowControl';
import { JahrControl } from './controls/JahrControl';
import { JaNeinControl } from './controls/JaNeinControl';
import { LabelRowControlComponent, LabelRowControlTester } from './controls/LabelRowControl';
import { LandschaftsverbandAutocompleteControl } from './controls/LandschaftsverbandAutocompleteControl';
import { ListeMitModalFormularControl } from './controls/ListeMitModalFormular/ListeMitModalFormularControl';
import { LoginEmailControl } from './controls/LoginEmailControl';
import { LoginPasswordControl } from './controls/LoginPasswordControl';
import { MehrfachUploadControl } from './controls/MehrfachUploadControl';
import { MehrzeiligerTextControl } from './controls/MehrzeiligerTextControl';
import { NachrichtControl } from './controls/NachrichtControl';
import { PasswordControl } from './controls/PasswordControl';
import { PasswordValidationControl } from './controls/PasswordValidation/PasswordValidationControl';
import { PDFDownloadControlComponent, PDFDownloadTester } from './controls/PDFDownloadControl';
import { PlaintextMehrzeiligerTextControl } from './controls/PlaintextMehrzeiligerTextControl';
import { ProfileEmailControl } from './controls/ProfileEmailControl';
import { RadioAuswahlControl } from './controls/RadioAuswahlControl';
import { SeparatorControl } from './controls/SeparatorControl';
import { FieldRow } from './controls/SummaryTable/FieldRow';
import { MultiFieldRow } from './controls/SummaryTable/MultiFiedRow';
import { NotesRow } from './controls/SummaryTable/NotesRow';
import { SubTable } from './controls/SummaryTable/SubTable';
import { SummaryTable } from './controls/SummaryTable/SummaryTable';
import { TenorControl } from './controls/TenorTable/Tenor';
import { TenorTable } from './controls/TenorTable/TenorTable';
import { TextControl } from './controls/TextControl';
import { TraegerAutocompleteControl } from './controls/TraegerAutocompleteControl';
import { TraegerverbandAutocompleteControl } from './controls/TraegerverbandAutocompleteControl';
import { UploadControl } from './controls/UploadControl';
import { AccordionTester } from './tester/AccordionTester';
import { AuswahlTester } from './tester/AuswahlTester';
import { BenutzerAutocompleteTester } from './tester/BenutzerAutocompleteTester';
import { CheckboxMultipleTester } from './tester/CheckboxMultipleTester';
import { CheckboxTester } from './tester/CheckboxTester';
import { CustomVerticalLayoutTester } from './tester/CustomVerticalLayoutTester';
import { DateTester } from './tester/DateTester';
import { DezimalzahlTester } from './tester/DezimalzahlTester';
import { DocumentTableControlTester } from './tester/DocumentTableControlTester';
import { DownloadLinkTester } from './tester/DownloadLinkTester';
import { EinrichtungAutocompleteTester } from './tester/EinrichtungAutocompleteTester';
import { EmailChangeControlTester } from './tester/EmailChangeControlTester';
import { FallbackTester } from './tester/FallbackTester';
import { FieldRowTester } from './tester/FieldRowTester';
import { FormularerweiterungButtonTester } from './tester/FormularerweiterungButtonTester';
import { GanzzahlTester } from './tester/GanzzahlTester';
import { GroupTester } from './tester/GroupTester';
import { ImpersonateAutocompleteTester } from './tester/ImpersonateAutocompleteTester';
import { InlineRowControlTester } from './tester/InlineRowControlTester';
import { JahrTester } from './tester/JahrTester';
import { JaNeinTester } from './tester/JaNeinTester';
import { LandschaftsverbandAutocompleteTester } from './tester/LandschaftsverbandAutocompleteTester';
import { ListeMitModalFormularTester } from './tester/ListeMitModalFormularTester';
import { LoginEmailTester } from './tester/LoginEmailTester';
import { LoginPasswordTester } from './tester/LoginPasswordTester';
import { MehrfachUploadTester } from './tester/MehrfachUploadTester';
import { MehrzeiligerTextTester } from './tester/MehrzeiligerTextTester';
import { MultiFieldRowTester } from './tester/MultiFieldRowTester';
import { NachrichtTester } from './tester/NachrichtTester';
import { NotesRowTester } from './tester/NotesRowTester';
import { PasswordTester } from './tester/PasswordTester';
import { PasswordValidationControlTester } from './tester/PasswordValidationTester';
import { PlaintextMehrzeiligerTextTester } from './tester/PlaintextMehrzeiligerTextTester';
import { ProfileEmailControlTester } from './tester/ProfileEmailControlTester';
import { RadioAuswahlTester } from './tester/RadioAuswahlTester';
import { SeparatorTester } from './tester/SeparatorTester';
import { SubTableTester } from './tester/SubTableTester';
import { SummaryTableTester } from './tester/SummaryTableTester';
import { TenorTableTester } from './tester/TenorTableTester';
import { TenorTester } from './tester/TenorTester';
import { TextTester } from './tester/TextTester';
import { TraegerAutocompleteTester } from './tester/TraegerAutocompleteTester';
import { TraegerverbandAutocompleteTester } from './tester/TraegerverbandAutocompleteTester';
import { UploadTester } from './tester/UploadTester';

export const renderers = [
    ...materialRenderers,
    { tester: GroupTester, renderer: GroupControl },
    { tester: TextTester, renderer: TextControl },
    { tester: MehrzeiligerTextTester, renderer: MehrzeiligerTextControl },
    { tester: PlaintextMehrzeiligerTextTester, renderer: PlaintextMehrzeiligerTextControl },
    { tester: DateTester, renderer: DateControl },
    { tester: JahrTester, renderer: JahrControl },
    { tester: JaNeinTester, renderer: JaNeinControl },
    { tester: RadioAuswahlTester, renderer: RadioAuswahlControl },
    { tester: DownloadLinkTester, renderer: DownloadLinkControl },
    { tester: UploadTester, renderer: UploadControl },
    { tester: MehrfachUploadTester, renderer: MehrfachUploadControl },
    { tester: AuswahlTester, renderer: AuswahlControl },
    { tester: GanzzahlTester, renderer: GanzzahlControl },
    { tester: DezimalzahlTester, renderer: DezimalzahlControl },
    { tester: SeparatorTester, renderer: SeparatorControl },
    { tester: NachrichtTester, renderer: NachrichtControl },
    { tester: FallbackTester, renderer: FallbackControl },
    { tester: CheckboxTester, renderer: CheckboxControl },
    { tester: CheckboxMultipleTester, renderer: CheckboxMultipleControl },
    { tester: PasswordTester, renderer: PasswordControl },
    {
        tester: FormularerweiterungButtonTester,
        renderer: FormularerweiterungButtonControl,
    },
    {
        tester: ListeMitModalFormularTester,
        renderer: ListeMitModalFormularControl,
    },
    {
        tester: InlineRowControlTester,
        renderer: InlineRowControl,
    },
    {
        tester: DocumentTableControlTester,
        renderer: DocumentTableControl,
    },
    {
        tester: SummaryTableTester,
        renderer: SummaryTable,
    },
    {
        tester: FieldRowTester,
        renderer: FieldRow,
    },
    {
        tester: SubTableTester,
        renderer: SubTable,
    },
    {
        tester: NotesRowTester,
        renderer: NotesRow,
    },
    {
        tester: CustomVerticalLayoutTester,
        renderer: CustomVerticalLayout,
    },
    {
        tester: MultiFieldRowTester,
        renderer: MultiFieldRow,
    },
    {
        tester: LabelRowControlTester,
        renderer: LabelRowControlComponent,
    },
    {
        tester: PDFDownloadTester,
        renderer: PDFDownloadControlComponent,
    },
    {
        tester: CustomVerticalLayoutTester,
        renderer: CustomVerticalLayout,
    },
    {
        tester: BenutzerAutocompleteTester,
        renderer: BenutzerAutocompleteControl,
    },
    {
        tester: EinrichtungAutocompleteTester,
        renderer: EinrichtungAutocompleteControl,
    },
    {
        tester: ImpersonateAutocompleteTester,
        renderer: ImpersonateAutocompleteControl,
    },
    {
        tester: LandschaftsverbandAutocompleteTester,
        renderer: LandschaftsverbandAutocompleteControl,
    },
    { tester: TraegerAutocompleteTester, renderer: TraegerAutocompleteControl },
    {
        tester: TraegerverbandAutocompleteTester,
        renderer: TraegerverbandAutocompleteControl,
    },
    { tester: LoginEmailTester, renderer: LoginEmailControl },
    { tester: LoginPasswordTester, renderer: LoginPasswordControl },
    {
        tester: HighlightedAreaTester,
        renderer: HighlightedArea,
    },
    {
        tester: GroupListTester,
        renderer: GroupList,
    },
    {
        tester: DescriptionTester,
        renderer: Description,
    },
    {
        tester: ControlsOfElementsTester,
        renderer: ControlsOfElements,
    },
    {
        tester: ControlGroupsOfElementsTester,
        renderer: ControlGroupsOfElements,
    },
    {
        tester: GroupFormTester,
        renderer: GroupForm,
    },
    {
        tester: InlineGroupRowControlTester,
        renderer: InlineGroupRowControl,
    },
    {
        tester: NestedGroupTester,
        renderer: NestedGroup,
    },
    {
        tester: CustomHorizontalLayoutTester,
        renderer: CustomHorizontalLayout,
    },
    {
        tester: PasswordValidationControlTester,
        renderer: PasswordValidationControl,
    },
    {
        tester: ProfileEmailControlTester,
        renderer: ProfileEmailControl,
    },
    {
        tester: EmailChangeControlTester,
        renderer: EmailChangeControl,
    },
    {
        tester: BescheidDokumentGenerierenLinkTester,
        renderer: BescheidDokumentGenerierenLinkControl,
    },
    {
        tester: AccordionTester,
        renderer: AccordionControl,
    },
    {
        tester: DataGridTester,
        renderer: DataGrid,
    },
    {
        tester: CollectionGroupsTester,
        renderer: CollectionGroups,
    },
    {
        tester: TenorTableTester,
        renderer: TenorTable,
    },
    {
        tester: TenorTester,
        renderer: TenorControl,
    },
    {
        tester: TextAreaEinzeiligTester,
        renderer: TextAreaEinzeiligControl,
    },
];
