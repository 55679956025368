import React, { ReactElement } from 'react';

import { DataTableHydraServerside } from 'components/DataTableHydra/DataTableHydraServerside';
import { useTraegerverbandEinrichtungenListColumns } from 'pages/Verwaltung/Traegerverband/Einrichtungen/useTraegerverbandEinrichtungenListColumns';
import { useTraegerverbandEinrichtungenListData } from 'pages/Verwaltung/Traegerverband/Einrichtungen/useTraegerverbandEinrichtungenListData';

type TraegerverbandEinrichtungenListProps = {
    id: string;
};

export const TraegerverbandEinrichtungenList = ({ id }: TraegerverbandEinrichtungenListProps): ReactElement => {
    const getData = useTraegerverbandEinrichtungenListData(id);
    const getColumns = useTraegerverbandEinrichtungenListColumns();

    return (
        <DataTableHydraServerside
            getData={getData}
            getColumns={getColumns}
            initialOptions={{
                filter: false,
                sort: false,
                viewColumns: false,
            }}
        />
    );
};
