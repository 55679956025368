import { useMemo } from 'react';

import { useAuthHelper } from 'api/auth/useAuthHelper';
import { EinrichtungResponse } from 'api/types';

export const useIsAntragsteller = (einrichtung: EinrichtungResponse | undefined): boolean => {
    const { getCurrentBenutzer } = useAuthHelper();
    return useMemo(() => {
        const benutzer = getCurrentBenutzer();
        const user = einrichtung?.einrichtungBenutzer?.find((eu) => eu.benutzer.id === benutzer?.benutzerId);
        return Boolean(user?.isAntragsteller);
    }, [getCurrentBenutzer, einrichtung]);
};
