import { LinkProps } from '@mui/material/Link/Link';

import { Link } from './Link';

interface EmailPropsInterface extends LinkProps {
    email: string;
}

export const LinkEmail = ({ email, children, ...props }: EmailPropsInterface) => (
    <Link {...props} href={`mailto:${email}`}>
        {children || email}
    </Link>
);
