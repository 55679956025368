import { useMemo } from 'react';
import { cloneDeep } from 'lodash';

import { useAuthHelper } from 'api/auth/useAuthHelper';
import { EinrichtungResponse } from 'api/types';
import { ROLES, ROLLEN } from 'constants/roles';
import { Schema } from 'forms/types/UiSchemaTypes';
import { removeSchemaProperty } from 'forms/utils/SchemaUtils';
import { Rolle } from 'pages/Verwaltung/Benutzer/BenutzerErstellenForm/useAllowedCreateBenutzerRollen';

export const useCreateBenutzerCreateStepFormSchema = (
    einrichtung: EinrichtungResponse | null,
    rollen: Rolle[],
    rolle: ROLLEN | null
): Schema => {
    const { hasRole } = useAuthHelper();
    return useMemo(() => {
        const schema = cloneDeep(schemaBase);
        if (!schema.properties) return schema;

        if (rollen.length > 0) {
            schema.properties.rolle.oneOf = rollen;
        }

        if (hasRole(ROLES.LV) || (rolle !== ROLLEN.LV_SACHBEARBEITER && rolle !== ROLLEN.LV_ADMIN)) {
            removeSchemaProperty(schema, 'landschaftsverband');
        }

        if (hasRole(ROLES.TRAEGERVERBAND) || rolle !== ROLLEN.TRAEGERVERBAND) {
            removeSchemaProperty(schema, 'traegerverband');
        }

        if (hasRole(ROLES.TRAEGER) || rolle !== ROLLEN.TRAEGER) {
            removeSchemaProperty(schema, 'traeger');
        }

        if (einrichtung || (rolle !== ROLLEN.EINRICHTUNG_BENUTZER && rolle !== ROLLEN.SONSTIGER_BEVOLLMAECHTIGTER)) {
            removeSchemaProperty(schema, 'einrichtungen');
        }

        if (!einrichtung && rolle !== ROLLEN.EINRICHTUNG_BENUTZER && rolle !== ROLLEN.SONSTIGER_BEVOLLMAECHTIGTER) {
            removeSchemaProperty(schema, 'isAntragsteller');
        }

        return schema;
    }, [hasRole, rolle, rollen, einrichtung]);
};

const schemaBase: Schema = {
    type: 'object',
    required: [
        'email',
        'nachname',
        'vorname',
        'rolle',
        'landschaftsverband',
        'traegerverband',
        'traeger',
        'einrichtungen',
        'isAntragsteller',
    ],
    properties: {
        email: {
            $id: 'email',
            type: 'string',
            title: 'E-Mail',
            // @ts-ignore
            custom: {
                disabled: true,
            },
        },
        nachname: { $id: 'nachname', type: 'string', title: 'Name' },
        vorname: { $id: 'vorname', type: 'string', title: 'Vorname' },
        rolle: {
            $id: 'rolle',
            type: 'string',
            title: 'Rolle',
        },
        landschaftsverband: {
            $id: 'landschaftsverband',
            type: 'object',
            title: 'Landschaftsverband',
            // @ts-ignore
            custom: {
                block_prefixes: ['landschaftsverband_autocomplete'],
            },
        },
        traegerverband: {
            $id: 'traegerverband',
            type: 'object',
            title: 'Trägerverband',
            // @ts-ignore
            custom: {
                block_prefixes: ['traegerverband_autocomplete'],
            },
        },
        traeger: {
            $id: 'traeger',
            type: 'object',
            title: 'Träger',
            // @ts-ignore
            custom: {
                block_prefixes: ['traeger_autocomplete'],
            },
        },
        einrichtungen: {
            $id: 'einrichtungen',
            type: 'array',
            title: 'Einrichtungen',
            // @ts-ignore
            custom: {
                block_prefixes: ['einrichtung_autocomplete'],
            },
        },
        isAntragsteller: {
            $id: 'isAntragsteller',
            title: 'Berechtigung',
            type: 'string',
            oneOf: [
                {
                    const: '0',
                    title: 'Lesend',
                },
                {
                    const: '1',
                    title: 'Schreibend',
                },
            ],
            // @ts-ignore
            custom: {
                block_prefixes: ['radios', 'choice'],
            },
        },
    },
};
