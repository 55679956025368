import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { gridLayoutConfig } from 'forms/AntragForm/ui-schemas/elements/gridLayoutConfig';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { groupForm } from 'forms/AntragForm/ui-schemas/elements/groupForm';
import { inlineGroupRowControl } from 'forms/AntragForm/ui-schemas/elements/inlineGroupRowControl';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';
import { notes } from 'forms/AntragForm/ui-schemas/groups/notes';

export const uiSchema = verticalLayout([
    group([control('field110')], {}, 'Platzzahlen aus dem Feststellungsverfahren'),
    group(
        [
            groupForm(
                '#/properties/belegungPlaetze',
                'Jahr {#/properties/jahr}',
                [
                    group([
                        inlineGroupRowControl('belegungPlaetze', 'field572'),
                        inlineGroupRowControl('belegungPlaetze', 'field574'),
                    ]),
                    group([
                        inlineGroupRowControl('gesamtbelegung', 'field571'),
                        inlineGroupRowControl('gesamtbelegung', 'field573'),
                    ]),
                ],
                {
                    gridLayout: gridLayoutConfig(1, 3, 6),
                }
            ),
        ],
        { noSeparator: true }
    ),
    notes('notizBelegung'),
]);
