import { GridLayoutConfig, GroupListType } from 'forms/types/UiSchemaTypes';

export const groupList = (
    groupByScope: string,
    scopes: string[],
    entries: any,
    options: {
        vertical?: boolean;
        groupLabel?: string;
    } = {},
    gridLayout?: GridLayoutConfig
): GroupListType => ({
    type: 'GroupList',
    groupByScope,
    scopes,
    entries,
    ...options,
    gridLayout,
});
