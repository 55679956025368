import { RuleEffect } from '@jsonforms/core';

import { LALG_TYP } from 'constants/lalgTyp';
import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/AntragForm/ui-schemas/elements/customVerticalLayout';
import { formExtendButton } from 'forms/AntragForm/ui-schemas/elements/formExtendButton';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { info } from 'forms/AntragForm/ui-schemas/elements/info';
import { seperator } from 'forms/AntragForm/ui-schemas/elements/seperator';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';
import { notes } from 'forms/AntragForm/ui-schemas/groups/notes';

export const uiSchema = verticalLayout([
    group([
        control('field141'),
        control('field141a'),
        control('field141d'),
        seperator('#/properties/field141'),
        control('field142'),
        info(
            'Bitte beachten Sie, dass die konkrete Vergleichsberechnung gem § 8 Abs. 2 Sätze 5 und 6 APG DVO verpflichtend ist, sofern<ul><li>sich die langfristigen Anlagegüter (z.B. Gebäude) zum 1. Februar 2014 in Ihrem Eigentum befanden und sie diese nach dem Stichtag verkauft und anschließend zurückgemietet haben</li><li>oder wenn der Verkauf schon vor dem 1. Februar 2014 stattgefunden hat und die Anerkennung des Mietverhältnisses nicht schon bis zum 1. November 2014 geltend gemacht wurde</li></ul>oder wenn der ursprüngliche Träger das Eigentum behält, aber die Trägerschaft auf eine andere natürliche oder juristische Person übergegangen ist.',
            {
                rule: {
                    effect: RuleEffect.SHOW,
                    condition: {
                        // @ts-ignore
                        scope: '#/properties/field140',
                        schema: {
                            oneOf: [
                                {
                                    const: LALG_TYP.MIETE,
                                },
                                {
                                    const: LALG_TYP.MIETE_UND_EIGENTUM,
                                },
                            ],
                        },
                    },
                },
            }
        ),
        seperator('#/properties/field142'),
        control('field143'),
        seperator('#/properties/field143'),
        control('field231'),
        control('field233'),
        control('field234'),
        control('field233d'),
        formExtendButton(
            customVerticalLayout([
                control('field233', {
                    options: {
                        firstControl: true,
                    },
                }),
                control('field234'),
                control('field233d'),
            ]),
            '#/properties/ausnahmegenehmigungenP10Abs3Satz2Ziffer2Apg',
            'Ausnahmegenehmigung hinzufügen'
        ),
    ]),
    notes('notizAusnahmen'),
]);
