import React, { useCallback, useEffect, useState } from 'react';
import { FormControl } from '@mui/material';
import { isEqual } from 'date-fns';

import { DatePickerYear } from 'forms/components/DatePickerYear';
import { ErrorList } from 'forms/controls/components/ErrorList';
import { ReadonlyValueText } from 'forms/controls/components/ReadonlyValueText';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';

import { RowControl } from './RowControl';

const data2value = (data?: number | null): Date | null => {
    if (!data) {
        return null;
    }

    const date = new Date();
    date.setFullYear(data);

    if (isNaN(date.getTime())) {
        return null;
    }

    return date;
};

const JahrControlComponent: React.FC<CustomControlProps<number>> = ({
    path,
    label,
    required,
    data,
    handleChange,
    hasErrors,
    errors,
    disabled,
    readonly,
    config,
    showFieldNumberLabels,
    gridLayout,
    id,
}) => {
    const [value, setValue] = useState<Date | null>(data2value(data));

    useEffect(
        () => {
            const date = data2value(data);

            if (!date || !value || !isEqual(date, value)) {
                setValue(date);
            }
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [data]
    );

    const handleDateChange = useCallback(
        (date?: Date | null, inputValue?: string) => {
            if (inputValue && inputValue.length < 4) return;

            if ((date instanceof Date && !isNaN(date.getTime())) || !date) {
                handleChange(path, date ? date.getFullYear() : undefined, true);
            }
        },
        [handleChange, path]
    );

    return (
        <RowControl
            name={path}
            label={label}
            required={required}
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={gridLayout}
            labelForId={id}
            config={config}
            paths={[path]}
        >
            <FormControl fullWidth>
                {readonly ? (
                    <ReadonlyValueText
                        path={path}
                        hasErrors={hasErrors}
                        errors={errors}
                        text={data ? String(data) : '-'}
                    />
                ) : (
                    <DatePickerYear
                        fullWidth
                        views={['year']}
                        error={hasErrors}
                        value={value}
                        onChange={handleDateChange}
                        disabled={disabled}
                        helperText={hasErrors ? <ErrorList errors={errors} /> : ' '}
                        inputProps={{ id, 'data-cy': `form_${path}` }}
                        componentsProps={{ actionBar: { actions: required ? [] : ['clear'] } }}
                    />
                )}
            </FormControl>
        </RowControl>
    );
};

export const JahrControl = withCustomControlProps(JahrControlComponent);
