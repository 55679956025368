import { useLocation } from 'react-router-dom';

import { useApiCheckChangeToken } from 'api/hooks/useApiClient';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { ContentContainer } from 'layout/container/ContentContainer';
import { ExceptionMessage } from 'pages/Profil/Benutzerdaten/EmailBestaetigen/components/ExceptionMessage';
import { FailureMessage } from 'pages/Profil/Benutzerdaten/EmailBestaetigen/components/FailureMessage';
import { SuccessMessage } from 'pages/Profil/Benutzerdaten/EmailBestaetigen/components/SuccessMessage';

export const EmailBestaetigenPage = () => {
    const params = new URLSearchParams(useLocation().search);
    const securityToken = params.get('t'); // token
    const email = atob(params.get('d') || ''); // displayed email

    const { isLoading, error } = useApiCheckChangeToken(securityToken || undefined);

    return (
        <ContentContainer title="Email bestätigen">
            <AppLoaderContainer isLoading={isLoading}>
                {error ? (
                    error.response?.status === 500 ? (
                        <ExceptionMessage />
                    ) : (
                        <FailureMessage />
                    )
                ) : (
                    <SuccessMessage email={email} />
                )}
            </AppLoaderContainer>
        </ContentContainer>
    );
};
