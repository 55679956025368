import { useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { TraegerverbandJsonldTraegerRead } from 'api/client';
import { TraegerListResponse } from 'api/types';
import { TraegerArtFilterOptions } from 'components/DataTableHydra/Filters/TraegerArtFilterOptions';
import { GetColumns } from 'components/DataTableHydra/hooks/useTableColumns';
import { TRAEGER_ART_LABELS } from 'constants/labels';
import { traegerListLinkRender } from 'pages/Verwaltung/Traeger/TraegerListLink';
import { rightAlignedColumn } from 'utilities/DataTableHydraUtils';

export const useTraegerListColumns = (): GetColumns<TraegerListResponse> => {
    return useCallback((data) => {
        if (!data) return [];

        const nrwKey: MUIDataTableColumnDef = {
            name: 'nrwKey',
            label: 'NRW-Schlüssel',
            options: {
                filter: false,
            },
        };

        const name: MUIDataTableColumnDef = {
            name: 'name',
            label: 'Träger',
            options: {
                filter: false,
            },
        };

        const traegerverband: MUIDataTableColumnDef = {
            name: 'traegerverband',
            label: 'Trägerverband',
            options: {
                filter: false,
                customBodyRender: (traegerverband: TraegerverbandJsonldTraegerRead) => traegerverband?.name,
            },
        };

        const einrichtungen: MUIDataTableColumnDef = {
            name: 'einrichtungen',
            label: 'Einrichtungen',
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (index: number) => traegerListLinkRender(data, index, 'einrichtungen'),
                ...rightAlignedColumn,
            },
        };

        const art: MUIDataTableColumnDef = {
            name: 'art',
            label: 'Art',
            options: {
                ...TraegerArtFilterOptions,
                customBodyRender: (value: string): string => TRAEGER_ART_LABELS[value] || value,
            },
        };

        const columns: MUIDataTableColumnDef[] = [nrwKey, name, traegerverband, einrichtungen, art];

        return columns;
    }, []);
};
