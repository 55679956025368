import { useCallback } from 'react';

import { AntragType } from 'api/antragTypes';
import { backendApiService } from 'api/ApiService';
import { InlineResponse } from 'api/responses/InlineResponse';
import {
    AntragResponse,
    AntragVersionCollectionListResponse,
    AntragVersionCollectionResponse,
    GetAntragVersionenOptions,
} from 'api/types';
import { GetData } from 'components/DataTableHydra/hooks/useTableData';
import { sortBy } from 'components/DataTableHydra/tableUtils';
import { ANTRAG_VERSIONEN_TYP } from 'constants/antragVersionenTyp';

export const useAntragVersionListData = (
    type: AntragType,
    antrag: AntragResponse
): GetData<AntragVersionCollectionListResponse> => {
    return useCallback(
        async ({ page, rowsPerPage, sortOrder }) => {
            const params: GetAntragVersionenOptions = {
                page,
                itemsPerPage: rowsPerPage,
                antrag: String(antrag.id),
                orderByAntragModifiedAt: sortBy(sortOrder, 'antragModifiedAt'),
            };
            let response: InlineResponse<AntragVersionCollectionResponse>;
            switch (type) {
                case AntragType.FESTSTELLUNG:
                    response = await backendApiService.getFeststellungsantragVersionen(params);
                    break;
                case AntragType.FESTSETZUNG:
                    response = await backendApiService.getFestsetzungsantragVersionen(params);
                    break;
                case AntragType.INVESTOREN_MODELL:
                    response = await backendApiService.getInvestorenmodellVersionen(params);
                    break;
                case AntragType.MELDUNG_P29:
                    throw Error('Not implemented.');
            }
            return { ...response, 'hydra:member': [convertToAntragVersion(antrag), ...response['hydra:member']] };
        },
        [type, antrag]
    );
};

export const convertToAntragVersion = (antrag: AntragResponse): AntragVersionCollectionResponse => {
    return {
        id: antrag.id,
        status: antrag.status,
        typ: ANTRAG_VERSIONEN_TYP.CURRENT,
        antragModifiedAt: antrag.modifiedAt,
    };
};
