import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material';

import { InvestPathBuilder } from 'navigation/Paths';

type ExteneQuelle = {
    path: string;
    name: string;
};

type UseDashboardMagsExterneQuellenResult = {
    options: ExteneQuelle[];
    selectedQuelle: ExteneQuelle | undefined;
    handleValueChange: (event: SelectChangeEvent) => void;
    onSubmit: () => void;
};

export const useDashboardMagsExterneQuellen = (): UseDashboardMagsExterneQuellenResult => {
    const navigate = useNavigate();
    const [selectedQuelle, setSelectedQuelle] = useState<ExteneQuelle | undefined>(undefined);

    const options: ExteneQuelle[] = [
        {
            path: InvestPathBuilder.home.externeQuellen.angemessenheitsgrenze.apgDvoMitKueche.path,
            name: 'Angemessenheitsgrenze APG DVO mit Küche',
        },
        {
            path: InvestPathBuilder.home.externeQuellen.angemessenheitsgrenze.apgDvoVollstationaer.path,
            name: 'Angemessenheitsgrenze APG DVO vollstationär',
        },
        {
            path: InvestPathBuilder.home.externeQuellen.angemessenheitsgrenze.apgDvoTeilstationaer.path,
            name: 'Angemessenheitsgrenze APG DVO teilstationär',
        },
        {
            path: InvestPathBuilder.home.externeQuellen.angemessenheitsgrenze.festgesetzterInstandhaltungswert.path,
            name: 'Angemessenheitsgrenze Festgesetzter Instandhaltungswert pro qm',
        },
        {
            path: InvestPathBuilder.home.externeQuellen.baupreisindex._2010.path,
            name: 'Baupreisindex 2010',
        },
        {
            path: InvestPathBuilder.home.externeQuellen.baupreisindex._2015.path,
            name: 'Baupreisindex 2015',
        },
        {
            path: InvestPathBuilder.home.externeQuellen.eigenkapitalzinsP56ApgDvo.path,
            name: 'Eigenkapitalzins § 5 Abs. 6 APG DVO',
        },
    ];

    const handleValueChange = (event: SelectChangeEvent) => {
        const newValue = options.find((quelle) => quelle.name === event.target.value);
        setSelectedQuelle(newValue);
    };

    const onSubmit = () => {
        if (!selectedQuelle) return;
        navigate(selectedQuelle.path, { state: { openModal: true } });
    };

    return {
        options,
        selectedQuelle,
        handleValueChange,
        onSubmit,
    };
};
