import { useCallback, useMemo, useState } from 'react';

import {
    useApiFestsetzungsAntragCollection,
    useApiFeststellungsAntragCollection,
    useApiInvestorenmodellCollection,
} from 'api/hooks/useApiClient';
import { AUFGABEN } from 'pages/Aufgaben/config';

type UseDashboardLvAntraegeResult = {
    bescheidungen: Bescheidung[];
    isLoading: boolean;
    refresh: () => void;
};

export const useDashboardLvAntraege = (
    isLandschaftsverbandView: boolean,
    benutzerId: number[]
): UseDashboardLvAntraegeResult => {
    const [refreshFeststellungsAntraege, setRefreshFeststellungsAntraege] = useState(0);
    const [refreshFestsetzungsAntraege, setRefreshFestsetzungsAntraege] = useState(0);
    const [refreshInvestorenmodelle, setRefreshInvestorenmodelle] = useState(0);

    const { data: feststellungsAntragData, isLoading: isFeststellungenLoading } = useApiFeststellungsAntragCollection(
        {
            page: 1,
            itemsPerPage: 999,
            zustaendigerBenutzer: !isLandschaftsverbandView && benutzerId ? benutzerId : undefined,
            aufgabe: AUFGABEN.BESCHEIDVERSAND,
        },
        { refresh: refreshFeststellungsAntraege }
    );

    const { data: festsetzungsAntragData, isLoading: isFestsetzungenLoading } = useApiFestsetzungsAntragCollection(
        {
            page: 1,
            itemsPerPage: 999,
            zustaendigerBenutzer: !isLandschaftsverbandView && benutzerId ? benutzerId : undefined,
            aufgabe: AUFGABEN.BESCHEIDVERSAND,
        },
        { refresh: refreshFestsetzungsAntraege }
    );

    const { data: investorenmodellData, isLoading: isInvestorenmodelleLoading } = useApiInvestorenmodellCollection(
        {
            page: 1,
            itemsPerPage: 999,
            zustaendigerBenutzer: !isLandschaftsverbandView && benutzerId ? benutzerId : undefined,
            aufgabe: AUFGABEN.BESCHEIDVERSAND,
        },
        { refresh: refreshInvestorenmodelle }
    );

    const bescheidungen = useMemo(() => {
        const bescheidungen: Bescheidung[] = [];
        const feststellungsantraege = feststellungsAntragData?.['hydra:member'] ?? [];
        const festsetzungsantraege = festsetzungsAntragData?.['hydra:member'] ?? [];
        const investorenmodelle = investorenmodellData?.['hydra:member'] ?? [];

        feststellungsantraege.forEach((feststellungsantrag) => {
            if (feststellungsantrag.letzteBescheidung) {
                const dokumente: BescheidDokument[] = [];
                feststellungsantrag.letzteBescheidung.dokumente!.forEach((dokument) => {
                    if (dokument.upload) {
                        dokumente.push({
                            dokumentTyp: dokument.typ!,
                            contentUrl: dokument.upload.contentUrl!,
                            originalName: dokument.upload.originalName!,
                        });
                    }

                    if (dokument.generiertesDokument) {
                        dokumente.push({
                            dokumentTyp: dokument.typ!,
                            contentUrl: dokument.generiertesDokument.contentUrl!,
                            originalName: dokument.generiertesDokument.originalName!,
                        });
                    }
                });
                bescheidungen.push({
                    bescheidungId: feststellungsantrag.letzteBescheidung.id!,
                    antragId: feststellungsantrag.id!,
                    antragTyp: 'feststellungsantrag',
                    antragTitle: 'Feststellung',
                    einrichtungName: feststellungsantrag.einrichtung!.name,
                    einrichtungId: feststellungsantrag.einrichtung!.id!,
                    gesendetAt: feststellungsantrag.letzteBescheidung!.gesendetAt!,
                    dokumente: dokumente,
                    versand: feststellungsantrag.letzteBescheidung!.versand!,
                    postalischerVersand: feststellungsantrag.letzteBescheidung.postalischerVersand ?? false,
                });
            }
        });

        festsetzungsantraege.forEach((festsetzungsantrag) => {
            if (festsetzungsantrag.letzteBescheidung) {
                const dokumente: BescheidDokument[] = [];
                festsetzungsantrag.letzteBescheidung.dokumente!.forEach((dokument) => {
                    if (dokument.upload) {
                        dokumente.push({
                            dokumentTyp: dokument.typ!,
                            contentUrl: dokument.upload.contentUrl!,
                            originalName: dokument.upload.originalName!,
                        });
                    }

                    if (dokument.generiertesDokument) {
                        dokumente.push({
                            dokumentTyp: dokument.typ!,
                            contentUrl: dokument.generiertesDokument.contentUrl!,
                            originalName: dokument.generiertesDokument.originalName!,
                        });
                    }
                });
                bescheidungen.push({
                    bescheidungId: festsetzungsantrag.letzteBescheidung.id!,
                    antragId: festsetzungsantrag.id!,
                    antragTyp: 'festsetzungsantrag',
                    antragTitle: 'Festsetzung',
                    einrichtungName: festsetzungsantrag.einrichtung!.name!,
                    einrichtungId: festsetzungsantrag.einrichtung!.id!,
                    gesendetAt: festsetzungsantrag.letzteBescheidung!.gesendetAt!,
                    dokumente: dokumente,
                    versand: festsetzungsantrag.letzteBescheidung!.versand!,
                    postalischerVersand: festsetzungsantrag.letzteBescheidung.postalischerVersand ?? false,
                });
            }
        });

        investorenmodelle.forEach((investorenmodell) => {
            if (investorenmodell.letzteBescheidung) {
                const dokumente: BescheidDokument[] = [];
                investorenmodell.letzteBescheidung.dokumente!.forEach((dokument) => {
                    if (dokument.upload) {
                        dokumente.push({
                            dokumentTyp: dokument.typ!,
                            contentUrl: dokument.upload.contentUrl!,
                            originalName: dokument.upload.originalName!,
                        });
                    }

                    if (dokument.generiertesDokument) {
                        dokumente.push({
                            dokumentTyp: dokument.typ!,
                            contentUrl: dokument.generiertesDokument.contentUrl!,
                            originalName: dokument.generiertesDokument.originalName!,
                        });
                    }
                });
                bescheidungen.push({
                    bescheidungId: investorenmodell.letzteBescheidung.id!,
                    antragId: investorenmodell.id!,
                    antragTyp: 'investorenmodell',
                    antragTitle: 'Antrag nach § 8 Abs. 11',
                    einrichtungName: investorenmodell.einrichtung!.name!,
                    einrichtungId: investorenmodell.einrichtung!.id!,
                    gesendetAt: investorenmodell.letzteBescheidung!.gesendetAt!,
                    dokumente: dokumente,
                    versand: investorenmodell.letzteBescheidung!.versand!,
                    postalischerVersand: investorenmodell.letzteBescheidung.postalischerVersand ?? false,
                });
            }
        });

        return bescheidungen;
    }, [feststellungsAntragData, festsetzungsAntragData, investorenmodellData]);

    const refresh = useCallback(() => {
        setRefreshFeststellungsAntraege((prev) => prev + 1);
        setRefreshFestsetzungsAntraege((prev) => prev + 1);
        setRefreshInvestorenmodelle((prev) => prev + 1);
    }, []);

    return {
        bescheidungen,
        isLoading: Boolean(isFeststellungenLoading || isFestsetzungenLoading || isInvestorenmodelleLoading),
        refresh,
    };
};

interface BescheidDokument {
    dokumentTyp: string;
    contentUrl: string;
    originalName: string;
}

export interface Bescheidung {
    bescheidungId: number;
    antragId: number;
    antragTyp: 'feststellungsantrag' | 'festsetzungsantrag' | 'investorenmodell';
    antragTitle: string;
    einrichtungName: string;
    einrichtungId: number;
    gesendetAt?: string;
    dokumente: BescheidDokument[];
    versand: { [key: string]: string };
    postalischerVersand: boolean;
}
