import { STATUS } from 'constants/antragStatus';
import { ANTRAG_ACTION_STATUS_LABELS, ANTRAG_STATUS_LABELS } from 'constants/labels';

export const determineAntragStatusAlertText = (status: string | undefined, action: string): string => {
    const currentStatus = status ? ANTRAG_STATUS_LABELS[status] : '';
    const nextStatus = ANTRAG_ACTION_STATUS_LABELS[action];
    if (currentStatus.length === 0) {
        return `Der Status dieses Antrags ändert sich auf "${nextStatus}". Möchten Sie fortfahren?`;
    }
    if (currentStatus === nextStatus && nextStatus === ANTRAG_STATUS_LABELS[STATUS.IN_PRUEFUNG]) {
        return `Der Antrag befindet sich bereits im Status "${currentStatus}". Wollen Sie diese Prüfung übernehmen?`;
    }
    return `Der Status dieses Antrages ändert sich von "${currentStatus}" auf "${nextStatus}". Möchten Sie fortfahren?`;
};
