declare module '@mui/material/styles/createPalette' {
    interface PaletteColor {
        lighter: string;
        darker: string;
    }

    interface PaletteOptions {
        lighter?: string;
        darker?: string;
    }

    interface TypeBackground {
        surfaceHighlighted: string;
        inputControls: string;
    }

    interface TypeText {
        link: string;
    }
}

export const palette = {
    primary: {
        lighter: '#EAEFF6',
        light: '#CCDFF2',
        main: '#5e7d9f',
        dark: '#365d87',
        darker: '#233755',
        //contrastText: '#fff',
    },
    secondary: {
        light: '#678c67',
        main: '#427041',
        dark: '#2e4e2d',
        //contrastText: '#000',
    },
    background: {
        default: '#fff',
        paper: '#fff',
        surfaceHighlighted: '#EAEFF6',
        inputControls: '#fff',
    },
    text: {
        link: '#006ECB',
    },
    error: {
        lighter: '#FAEDED',
        main: '#CC2222',
    },
};
