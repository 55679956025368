import { useCallback } from 'react';
import { Typography } from '@mui/material';

import { AntragType } from 'api/antragTypes';
import { backendApiService } from 'api/ApiService';
import { useAuthHelper } from 'api/auth/useAuthHelper';
import { InvestorenmodellCollectionListResponse } from 'api/types';
import { useColumnsInvestorenmodell } from 'components/DataTableHydra/Columns/Investorenmodell';
import { DataTableHydraServerside } from 'components/DataTableHydra/DataTableHydraServerside';
import { useLvBenutzerOptions } from 'components/DataTableHydra/hooks/useLvBenutzerOptions';
import { GetData } from 'components/DataTableHydra/hooks/useTableData';
import { sortBy } from 'components/DataTableHydra/tableUtils';
import { ROLES } from 'constants/roles';
import { ContentContainer } from 'layout/container/ContentContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { withAuthorization } from 'navigation/withAuthorization';
import { useAntragFilterOptions } from 'pages/Antraege/components/useAntragFilterOptions';
import { ZurueckziehenAkzeptierenDialog } from 'pages/Antraege/components/ZurueckziehenAkzeptierenDialog';
import { ZurueckziehenBeantragenDialog } from 'pages/Antraege/components/ZurueckziehenBeantragenDialog';
import { useInvestorenmodellAntragListAction } from 'pages/Antraege/Investorenmodell/useInvestorenmodellAntragListAction';
import { DashboardLvBenutzerToolbar } from 'pages/Dashboard/Landschaftsverband/DashboardLvBenutzerToolbar';

import { AUFGABEN, AufgabenMenu } from './config';

const P8Abs11Bearbeiten = () => {
    const { getCurrentBenutzer, isLandschaftsverbandView } = useAuthHelper();
    const benutzerId = getCurrentBenutzer()?.benutzerId;
    const { isLoading: isLoadingLvBenutzer, options: zustandigerBenutzerOptions } = useLvBenutzerOptions();

    const getData: GetData<InvestorenmodellCollectionListResponse> = useCallback(
        ({ page, rowsPerPage, searchText, sortOrder }) => {
            return backendApiService.getInvestorenmodellCollection({
                page,
                itemsPerPage: rowsPerPage,
                qSearch: searchText ? [searchText] : undefined,
                aufgabe: AUFGABEN.BEARBEITEN,
                zustaendigerBenutzer: !isLandschaftsverbandView && benutzerId ? [benutzerId] : undefined,
                orderStatus: sortBy(sortOrder, 'status'),
                orderEinrichtungName: sortBy(sortOrder, 'einrichtung'),
                orderZuletztEingereichtAt: sortBy(sortOrder, 'zuletztEingereichtAt'),
                orderEinrichtungAktenzeichen: sortBy(sortOrder, 'aktenzeichen'),
                orderFestsetzungsantragField500: sortBy(sortOrder, 'jahr'),
                orderEinrichtungZustaendigerBenutzerName: sortBy(sortOrder, 'zustaendigerBenutzer'),
                orderFestsetzungsantragFeststellungsantragBerechnungsart: sortBy(sortOrder, 'berechnungsart'),
            });
        },
        [benutzerId, isLandschaftsverbandView]
    );

    const isLoading = undefined === zustandigerBenutzerOptions || isLoadingLvBenutzer;

    const {
        handleAction,
        serverDataChanged,
        zurueckziehenBeantragen,
        zurueckziehenBeantragenFinishAction,
        zurueckziehenAkzeptieren,
        zurueckziehenAkzeptierenFinishAction,
    } = useInvestorenmodellAntragListAction(AntragType.INVESTOREN_MODELL);
    const filterOptions = useAntragFilterOptions(AntragType.INVESTOREN_MODELL);
    const columnsInvestorenmodell = useColumnsInvestorenmodell(handleAction, filterOptions);

    return (
        <ContentContainer title="Anträge auf §8 Abs. 11 (Bearbeitung)">
            <Typography variant="h1">Anträge auf §8 Abs. 11 (Bearbeitung)</Typography>
            <DashboardLvBenutzerToolbar />
            <DataTableHydraServerside
                loadingContext={isLoading}
                getColumns={columnsInvestorenmodell}
                getData={getData}
                initialOptions={{
                    filter: false,
                    searchPlaceholder: 'Suche nach Einrichtungsname, Aktenzeichen oder NRW-Schlüssel',
                    sortOrder: {
                        name: 'zuletztEingereichtAt',
                        direction: 'desc',
                    },
                }}
                serverDataChanged={serverDataChanged}
            />
            <ZurueckziehenBeantragenDialog
                antrag={zurueckziehenBeantragen}
                onClose={zurueckziehenBeantragenFinishAction}
                antragType={AntragType.INVESTOREN_MODELL}
            />
            <ZurueckziehenAkzeptierenDialog
                antrag={zurueckziehenAkzeptieren}
                onClose={zurueckziehenAkzeptierenFinishAction}
                antragType={AntragType.INVESTOREN_MODELL}
            />
        </ContentContainer>
    );
};

export const P8Abs11BearbeitenPage = withAuthorization(withSideBar(P8Abs11Bearbeiten, AufgabenMenu), [
    ROLES.FACHADMIN,
    ROLES.SUPPORT,
    ROLES.LV,
]);
