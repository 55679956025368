import React, { ReactElement } from 'react';
import Edit from '@mui/icons-material/Edit';
import { Button, Tooltip } from '@mui/material';

import { ZustaendigkeitResponse } from 'api/types';
import { ZustaendigkeitenRegionTableActions } from 'constants/zustaendigkeitenRegionActions';

interface ZustaendigkeitenRegionListActionsProps {
    zustaendigkeit: ZustaendigkeitResponse;
    handleAction: (action: ZustaendigkeitenRegionTableActions, zustaendigkeit: ZustaendigkeitResponse) => void;
}

export const ZustaendigkeitenRegionListActions = ({
    zustaendigkeit,
    handleAction,
}: ZustaendigkeitenRegionListActionsProps): ReactElement => {
    return (
        <Tooltip title="Zuständigkeit ändern">
            <Button
                aria-haspopup="dialog"
                variant="text"
                onClick={() => handleAction(ZustaendigkeitenRegionTableActions.BEARBEITEN, zustaendigkeit)}
            >
                <Edit />
            </Button>
        </Tooltip>
    );
};
