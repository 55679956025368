export const belegungTeilstationaer_table = {
    type: 'SummaryTable',
    label: 'Belegung Teilstationär',
    columns: 3,
    scope: '#/properties/belegungTeilstationaer',
    entries: [
        {
            type: 'SubTable',
            label: 'Platzzahlen aus dem Feststellungsverfahren',
            level: 0,
            scope: '#/properties/',
            entries: [
                {
                    type: 'FieldRow',
                    scope: '#/properties/field110',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field950',
                },
            ],
        },
        {
            type: 'SubTable',
            label: 'Plätze',
            level: 0,
            scope: '#/properties/',
            entries: [
                {
                    type: 'SubTable',
                    label: '{#/properties/jahr}',
                    level: 1,
                    scope: '#/properties/belegungPlaetze',
                    entries: [
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field572',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field574',
                        },
                    ],
                },
            ],
        },

        {
            type: 'SubTable',
            label: 'Gesamtbelegung',
            level: 0,
            scope: '#/properties/',
            entries: [
                {
                    type: 'SubTable',
                    label: '{#/properties/jahr}',
                    level: 1,
                    scope: '#/properties/gesamtbelegung',
                    entries: [
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field571',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field573',
                        },
                    ],
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field570d',
                },
            ],
        },

        {
            type: 'NotesRow',
            scope: '#/properties/notizBelegung',
        },
    ],
};
