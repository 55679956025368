import React from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';

import { useApiTraegerverbandItem } from 'api/hooks/useApiClient';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { ContentContainer } from 'layout/container/ContentContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { withAuthorization } from 'navigation/withAuthorization';
import { TraegerverbandBenutzerList } from 'pages/Verwaltung/Traegerverband/Benutzer/TraegerverbandBenutzerList';
import { VerwaltungConfig } from 'pages/Verwaltung/VerwaltungConfig';

const TraegerverbandBenutzer: React.FC = () => {
    const { id: traegerverbandId } = useParams();
    const { data: traegerverband, isLoading } = useApiTraegerverbandItem(traegerverbandId);
    const title = `Benutzer des Trägerverbands ${traegerverband?.name}`;
    return (
        <ContentContainer title={title}>
            {traegerverbandId && (
                <AppLoaderContainer isLoading={isLoading}>
                    <Typography variant="h1">{title}</Typography>
                    <TraegerverbandBenutzerList id={traegerverbandId} />
                </AppLoaderContainer>
            )}
        </ContentContainer>
    );
};

export const TraegerverbandBenutzerPage = withAuthorization(
    withSideBar(TraegerverbandBenutzer, VerwaltungConfig.menu),
    VerwaltungConfig.roles.traegerverband
);
