import React from 'react';
import { Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';

import { AppLoaderContainer } from 'components/AppLoaderContainer';

import { CloseButton } from './CloseButton';

interface DialogProps {
    open: boolean;
    onClose: () => void;
    title?: string | React.ReactNode;
    subTitle?: string;
    subTitleComponent?: React.ReactNode;
    actions?: React.ReactElement;
    closeAction?: React.ReactElement;
    wrapActions?: boolean;

    [key: string]: any;
}

export const Dialog: React.FC<DialogProps> = ({
    open,
    onClose,
    title,
    subTitle,
    subTitleComponent,
    actions,
    closeAction = <CloseButton onClick={onClose} />,
    children,
    wrapActions,
    maxWidth = 'md',
    ...others
}) => {
    return (
        <MuiDialog open={open} onClose={onClose} fullWidth maxWidth={maxWidth} disableEnforceFocus={true} {...others}>
            {title && (
                <DialogTitle component="div">
                    <Grid container alignItems={'center'}>
                        <Grid item xs={10}>
                            <Typography variant="h2">{title}</Typography>
                            <Typography variant="subtitle1">{subTitle}</Typography>
                        </Grid>
                        <Grid item xs={2} container alignItems="center" justifyContent="flex-end">
                            {closeAction}
                        </Grid>
                        <Grid item xs={12}>
                            {subTitleComponent}
                        </Grid>
                    </Grid>
                </DialogTitle>
            )}
            <DialogContent>
                <AppLoaderContainer isLoading={!children}>{children}</AppLoaderContainer>
            </DialogContent>
            {actions && <DialogActions style={wrapActions ? { display: 'block' } : {}}>{actions}</DialogActions>}
        </MuiDialog>
    );
};
