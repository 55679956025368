import * as React from 'react';
import { ChangeEvent, useCallback, useMemo, useRef } from 'react';
import { FormControl, FormControlLabel, FormHelperText, RadioGroup, styled } from '@mui/material';

import { RadioWithFocus } from 'elements/RadioWithFocus';
import { ErrorList } from 'forms/controls/components/ErrorList';
import { ReadonlyValueText } from 'forms/controls/components/ReadonlyValueText';
import { useFocus } from 'forms/hooks/useFocus';
import { useIsDataChanged } from 'forms/hooks/useIsDataChanged';
import { useScrollInView } from 'forms/hooks/useScrollInView';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';

import { RowControl } from './RowControl';

const RadioAuswahlControlComponent: React.FC<CustomControlProps<string>> = ({
    path,
    label,
    required,
    data,
    handleChange,
    schema,
    hasErrors,
    errors,
    disabled,
    readonly,
    formula,
    showFieldNumberLabels,
    gridLayout,
    config,
    uischema,
}) => {
    const { ref, requestFocusAfterValidate } = useFocus<HTMLDivElement>();
    const elementRef = useScrollInView(ref);
    const keyDownRef = useRef<boolean>();
    const isDataChanged = useIsDataChanged(data, schema);

    const handleValueChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            handleChange(path, e.target.value, true);
            if (keyDownRef.current) {
                requestFocusAfterValidate();
                keyDownRef.current = false;
            }
        },
        [handleChange, path, requestFocusAfterValidate]
    );

    const Choices = useMemo(
        () =>
            (schema.oneOf || []).map((entry, index: number) => (
                <FormControlLabel
                    key={`${index}#${entry.const}`}
                    value={entry.const}
                    control={
                        <RadioWithFocus
                            inputProps={
                                {
                                    'data-cy': `form_${path}_${entry.const}`,
                                } as any
                            }
                        />
                    }
                    label={entry.title}
                />
            )),
        [schema.oneOf, path]
    );

    const readonlyValueText = useMemo(() => {
        if (!readonly) return '';
        const selectedValue = data || schema.default || '';
        if (!selectedValue?.length) return '-';
        return (schema.oneOf || []).find((entry) => entry.const === selectedValue)?.title || selectedValue;
    }, [readonly, data, schema.default, schema.oneOf]);

    return (
        <RowControl
            name={path}
            label={label}
            required={required}
            formula={formula}
            fullValueWidth={!readonly}
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={gridLayout}
            controlOnly={uischema.controlOnly}
            hasChangedData={isDataChanged}
            config={config}
            paths={[path]}
        >
            {readonly ? (
                <ReadonlyValueText path={path} hasErrors={hasErrors} errors={errors} text={readonlyValueText} />
            ) : (
                <FormControl fullWidth disabled={disabled}>
                    <ChoicesGroup
                        value={data || schema.default || ''}
                        onChange={handleValueChange}
                        onKeyDown={() => (keyDownRef.current = true)}
                        ref={elementRef}
                    >
                        {Choices}
                    </ChoicesGroup>
                    <FormHelperText>{hasErrors ? <ErrorList errors={errors} /> : ' '}</FormHelperText>
                </FormControl>
            )}
        </RowControl>
    );
};

const ChoicesGroup = styled(RadioGroup)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
}));

export const RadioAuswahlControl = withCustomControlProps(RadioAuswahlControlComponent);
