import { ListControlWithModalFormType } from 'forms/types/UiSchemaTypes';
import { getFieldFromScope } from 'utilities';

export const useFormTableData = (
    data: any,
    uischema: ListControlWithModalFormType,
    errors: { [key: string]: string[] } | undefined
): (any & { scope: string })[] =>
    data
        ? uischema.scopes.flatMap((scope, scopeIndex) =>
              (data[getFieldFromScope(scope)!] || []).map((d: any, dataIndex: number) => ({
                  ...d,
                  scope,
                  type: (uischema.types || [])[scopeIndex],
                  hasErrors: checkForErrors(scope, dataIndex, errors),
              }))
          )
        : [];

const checkForErrors = (scope: string, index: number, errors: { [key: string]: string[] } | undefined) => {
    if (!errors) return false;

    const matches = Object.keys(errors).filter((element) => element.includes(`${getFieldFromScope(scope)}.${index}.`));

    return matches.length > 0;
};
