import React from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';

import { useApiTraegerverbandItem } from 'api/hooks/useApiClient';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { ContentContainer } from 'layout/container/ContentContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { withAuthorization } from 'navigation/withAuthorization';
import { TraegerverbandTraegerList } from 'pages/Verwaltung/Traegerverband/Traeger/TraegerverbandTraegerList';
import { VerwaltungConfig } from 'pages/Verwaltung/VerwaltungConfig';

const TraegerverbandTraeger: React.FC = () => {
    const { id: traegerverbandId } = useParams();
    const { data: traegerverband, isLoading } = useApiTraegerverbandItem(traegerverbandId);
    return (
        <ContentContainer title={`Träger des Trägerverbands ${traegerverband?.name}`}>
            {traegerverbandId && (
                <AppLoaderContainer isLoading={isLoading}>
                    <Typography variant="h1">Träger des Trägerverbands {traegerverband?.name}</Typography>
                    <TraegerverbandTraegerList id={traegerverbandId} />
                </AppLoaderContainer>
            )}
        </ContentContainer>
    );
};

export const TraegerverbandTraegerPage = withAuthorization(
    withSideBar(TraegerverbandTraeger, VerwaltungConfig.menu),
    VerwaltungConfig.roles.traegerverband
);
