import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import deLocale from 'date-fns/locale/de';

import { AuthProvider } from 'api/AuthContext';
import { LoaderApp } from 'components/AppLoaderContainer';
import { ConfirmationDialog } from 'components/Dialog/ConfirmationDialog';
import { LoadingBackdrop } from 'components/LoadingBackdrop';
import { FormStateProvider } from 'forms/state/FormStateProvider';
import { MessageStack } from 'layout/messages/MessageStack';
import { InvestRoutes } from 'navigation/InvestRoutes';
import { theme } from 'theme/theme';
import { SessionExtend } from 'utilities/SessionExtend/SessionExtend';

export const App = () => {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
                        <CssBaseline />

                        <React.Suspense fallback={<LoaderApp />}>
                            <AuthProvider>
                                <FormStateProvider>
                                    <SessionExtend />
                                    <InvestRoutes />
                                </FormStateProvider>
                                <ConfirmationDialog />
                            </AuthProvider>
                            <MessageStack />
                        </React.Suspense>
                    </LocalizationProvider>
                </BrowserRouter>
                <LoadingBackdrop />
            </ThemeProvider>
        </StyledEngineProvider>
    );
};
