import React, { useState } from 'react';
import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from '@mui/material';
import { isValid } from 'date-fns';
import formatDate from 'date-fns/format';

import { RuhendstellungGrund, RuhendstellungGrundType } from 'api/types';
import { Dialog } from 'components/Dialog/Dialog';
import { RUHENDSTELLUNG_GRUENDE_LABELS } from 'constants/labels';
import { SupportInfo } from 'elements/SupportInfo';
import { DatePicker } from 'forms/components/DatePicker';
import { FormButton } from 'forms/components/FormButton';
import { Pflichtfeld } from 'forms/components/Pflichtfeld';

interface RuhendstellungDialogProps {
    open: boolean;
    closeModal: () => void;
    handleSave: (grund: RuhendstellungGrundType, geschlossenAb: string | null) => void;
    close?: boolean;
}

type RuhendstellungState = {
    grund: RuhendstellungGrundType | null;
    geschlossenAb: Date | null;
};

export const RuhendstellungDialog: React.FC<RuhendstellungDialogProps> = ({
    open,
    closeModal,
    handleSave,
    close = false,
}) => {
    const [{ grund, geschlossenAb }, setRuhendstellung] = useState<RuhendstellungState>({
        grund: close ? RuhendstellungGrund.Schliessung : null,
        geschlossenAb: null,
    });

    const handleClick = () => {
        const date = geschlossenAb && isValid(geschlossenAb) ? formatDate(geschlossenAb, 'yyyy-MM-dd') : null;
        if (grund) handleSave(grund, date);
    };

    const handleGrundChange = (event: SelectChangeEvent) => {
        const grund = event.target.value as RuhendstellungGrundType;
        setRuhendstellung((prev) => ({
            grund,
            geschlossenAb: grund === RuhendstellungGrund.Schliessung ? prev.geschlossenAb : null,
        }));
    };

    const handleGeschlossenAbChange = (date: Date | null) => {
        setRuhendstellung((prev) => ({ ...prev, geschlossenAb: date }));
    };

    const isGeschlossenAbInvalid = Boolean(geschlossenAb && !isValid(geschlossenAb));
    const isGrundInvalid = Boolean(!grund);
    const isInvalid = isGrundInvalid || isGeschlossenAbInvalid;
    return (
        <Dialog
            title="Einrichtung ruhend stellen"
            open={open}
            onClose={closeModal}
            wrapActions
            actions={
                <>
                    <Grid container spacing={1} direction="row-reverse">
                        <Grid item>
                            <FormButton variant="contained" color="primary" onClick={handleClick} disabled={isInvalid}>
                                {close ? 'Einrichtung schließen' : 'Einrichtung ruhend stellen'}
                            </FormButton>
                        </Grid>

                        <Grid item>
                            <FormButton variant="outlined" color="primary" onClick={closeModal}>
                                Abbrechen
                            </FormButton>
                        </Grid>
                    </Grid>
                    <SupportInfo />
                </>
            }
        >
            <Grid container spacing={2}>
                {close ? (
                    <>
                        <Grid item xs={12}>
                            <Typography>Sind Sie sicher, dass Sie die Einrichtung schließen möchten?</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                value={RUHENDSTELLUNG_GRUENDE_LABELS[RuhendstellungGrund.Schliessung]}
                                label="Grund"
                                size="medium"
                                fullWidth
                                disabled
                            />
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid item xs={12}>
                            <Typography>
                                Bitte geben Sie den Grund für die Ruhendstellung der Einrichtung an:
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Pflichtfeld>* Pflichtfeld</Pflichtfeld>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth required>
                                <InputLabel>Grund</InputLabel>
                                <Select value={grund ?? ''} onChange={handleGrundChange} label="Grund">
                                    <MenuItem selected value="">
                                        <em>- Bitte wählen -</em>
                                    </MenuItem>
                                    {Object.values(RuhendstellungGrund).map((grund) => (
                                        <MenuItem selected value={grund} key={grund}>
                                            {RUHENDSTELLUNG_GRUENDE_LABELS[grund]}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </>
                )}

                <Grid item xs={12} md={6}>
                    {grund === RuhendstellungGrund.Schliessung && (
                        <DatePicker
                            value={geschlossenAb}
                            onChange={handleGeschlossenAbChange}
                            size="medium"
                            label="Schließdatum"
                            inputProps={{ style: { textAlign: 'right' } }}
                            fullWidth
                        />
                    )}
                </Grid>
            </Grid>
        </Dialog>
    );
};
