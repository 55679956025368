import { ReactElement } from 'react';

import { AntragType, InvestorenmodellAPI } from 'api/antragTypes';
import { backendApiService } from 'api/ApiService';
import { ROLES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { InvestPathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { defaultInvestorenmodellSteps, menuInvestorenmodell } from 'pages/Antraege/AntragConfig';
import { AntragPage } from 'pages/Antraege/components/AntragPage';

const antragApi: InvestorenmodellAPI = {
    submit: backendApiService.postInvestorenmodellJson.bind(backendApiService),
    deleteObjectTypeItem: backendApiService.deleteInvestorenmodellObjectTypeItem.bind(backendApiService),
    getFormData: backendApiService.getInvestorenmodellJson.bind(backendApiService),
    performWorkflowAction: backendApiService.workflowInvestorenmodellItem.bind(backendApiService),
    getAntrag: backendApiService.getInvestorenmodellItem.bind(backendApiService),
    submitGegenpruefungErlaeuterung: backendApiService.patchInvestorenmodellGegenpruefung.bind(backendApiService),
};

const InvestorenmodellAntrag = (): ReactElement => {
    return (
        <AntragPage
            api={antragApi}
            stepPathBuilder={InvestPathBuilder.home.antraege.investorenmodell.edit}
            type={AntragType.INVESTOREN_MODELL}
            header={'Antrag nach § 8 Abs. 11'}
            menuBuilder={menuInvestorenmodell}
        />
    );
};

export const InvestorenmodellAntragPage = withAuthorization(
    withSideBar(InvestorenmodellAntrag, menuInvestorenmodell(defaultInvestorenmodellSteps(''))),
    [ROLES.FACHADMIN, ROLES.SUPPORT, ROLES.LV, ROLES.ANBIETER]
);
