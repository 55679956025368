import { ACTIONS } from 'constants/antragActions';

export const useAllowedWorkflowActions = (allowedWorkflowActions?: ACTIONS[]) => {
    if (!allowedWorkflowActions) {
        return {
            einreichenIsAllowed: false,
            editIsAllowed: false,
            zurPruefungFreigebenIsAllowed: false,
            ablehnenIsAllowed: false,
            freigebenIsAllowed: false,
            sofortigeFestsetzungIsAllowed: false,
        };
    }
    return {
        einreichenIsAllowed: allowedWorkflowActions.includes(ACTIONS.EINREICHEN),
        editIsAllowed: allowedWorkflowActions.includes(ACTIONS.EDIT),
        zurPruefungFreigebenIsAllowed: allowedWorkflowActions.includes(ACTIONS.ZUR_PRUEFUNG_FREIGEBEN),
        ablehnenIsAllowed: allowedWorkflowActions.includes(ACTIONS.ABLEHNEN),
        freigebenIsAllowed: allowedWorkflowActions.includes(ACTIONS.FREIGEBEN),
        sofortigeFestsetzungIsAllowed: allowedWorkflowActions.includes(ACTIONS.SOFORTIGE_FESTSETZUNG),
    };
};
