import React, { ReactElement, useState } from 'react';

import { BenutzerByEmailResponse, EinrichtungResponse } from 'api/types';
import { Dialog } from 'components/Dialog/Dialog';
import { SupportInfo } from 'elements/SupportInfo';
import { CreateBenutzerFormStepAssign } from 'pages/Verwaltung/Benutzer/BenutzerErstellenForm/CreateBenutzerFormStepAssign';
import { CreateBenutzerFormStepCreate } from 'pages/Verwaltung/Benutzer/BenutzerErstellenForm/CreateBenutzerFormStepCreate';
import { CreateBenutzerFormStepEmail } from 'pages/Verwaltung/Benutzer/BenutzerErstellenForm/CreateBenutzerFormStepEmail';
import { CreateBenutzerFormStepNotFound } from 'pages/Verwaltung/Benutzer/BenutzerErstellenForm/CreateBenutzerFormStepNotFound';
import { CreateBenutzerFormStepEmailOnComplete } from 'pages/Verwaltung/Benutzer/BenutzerErstellenForm/useCreateBenutzerEmailStepForm';

type BenutzerErstellenFormDialogProps = {
    einrichtung: EinrichtungResponse | null;
    onClose: () => void;
    onComplete?: () => void;
};

export const CreateBenutzerFormDialog = ({
    einrichtung,
    onClose,
    onComplete,
}: BenutzerErstellenFormDialogProps): ReactElement => {
    const [formState, setFormState] = useState<CreateBenutzerFormState>({ step: 'email' });

    const onStepEmailComplete: CreateBenutzerFormStepEmailOnComplete = (benutzer, email) => {
        setFormState(
            !einrichtung
                ? { step: 'create', email, einrichtung }
                : benutzer
                ? { step: 'assign', benutzer, einrichtung }
                : { step: 'notFound', email }
        );
    };

    const onStepNotFoundComplete = () => {
        setFormState((prev) => {
            if (prev.step !== 'notFound') return prev;
            return { step: 'create', email: prev.email, einrichtung };
        });
    };

    const onCompleteBase = () => {
        onClose();
        onComplete?.();
    };

    return (
        <Dialog title="Benutzer hinzufügen" open onClose={onClose} maxWidth="md" fullWidth>
            {formState.step === 'email' && (
                <CreateBenutzerFormStepEmail
                    einrichtung={einrichtung}
                    onClose={onClose}
                    onComplete={onStepEmailComplete}
                />
            )}
            {formState.step === 'notFound' && (
                <CreateBenutzerFormStepNotFound
                    email={formState.email}
                    onClose={onClose}
                    onComplete={onStepNotFoundComplete}
                />
            )}
            {formState.step === 'assign' && (
                <CreateBenutzerFormStepAssign
                    einrichtung={formState.einrichtung}
                    benutzer={formState.benutzer}
                    onClose={onClose}
                    onComplete={onCompleteBase}
                />
            )}
            {formState.step === 'create' && (
                <CreateBenutzerFormStepCreate
                    email={formState.email}
                    einrichtung={formState.einrichtung}
                    onClose={onClose}
                    onComplete={onCompleteBase}
                />
            )}
            <SupportInfo />
        </Dialog>
    );
};

type CreateBenutzerFormStateEmail = {
    step: 'email';
};

type CreateBenutzerFormStateNotFound = {
    step: 'notFound';
    email: string;
};

type CreateBenutzerFormStateAssign = {
    step: 'assign';
    benutzer: BenutzerByEmailResponse;
    einrichtung: EinrichtungResponse;
};

type CreateBenutzerFormStateCreate = {
    step: 'create';
    email: string;
    einrichtung: EinrichtungResponse | null;
};

type CreateBenutzerFormState =
    | CreateBenutzerFormStateEmail
    | CreateBenutzerFormStateNotFound
    | CreateBenutzerFormStateAssign
    | CreateBenutzerFormStateCreate;
