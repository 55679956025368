import React, { useState } from 'react';

import { useAuthHelper } from 'api/auth/useAuthHelper';
import { DataTableHydraServerside } from 'components/DataTableHydra/DataTableHydraServerside';
import { DialogRegion } from 'pages/Verwaltung/Zustaendigkeiten/Bereich/DialogRegion';

import { useZustaendigkeitenRegionListAction } from './useZustaendigkeitenRegionListAction';
import { useZustaendigkeitenRegionListColumns } from './useZustaendigkeitenRegionListColumns';
import { useZustaendigkeitenRegionListData } from './useZustaendigkeitenRegionListData';

export const ZustaendigkeitenRegionList = () => {
    const { getCurrentBenutzer, hasRole } = useAuthHelper();
    const getData = useZustaendigkeitenRegionListData();
    const { zustaendigkeit, closeAction, handleAction } = useZustaendigkeitenRegionListAction();
    const getColumns = useZustaendigkeitenRegionListColumns(getCurrentBenutzer, hasRole, handleAction);
    const [serverDataChanged, setServerDataChanged] = useState(0);

    const onComplete = () => setServerDataChanged((prev) => prev + 1);

    return (
        <>
            <DataTableHydraServerside
                getData={getData}
                getColumns={getColumns}
                serverDataChanged={serverDataChanged}
                initialOptions={{
                    viewColumns: false,
                    sortOrder: {
                        name: 'region',
                        direction: 'asc',
                    },
                    filter: false,
                }}
            />
            {zustaendigkeit && (
                <DialogRegion
                    zustaendigkeitId={String(zustaendigkeit.id)}
                    handleZustaendigkeitSaved={onComplete}
                    handleExit={closeAction}
                />
            )}
        </>
    );
};
