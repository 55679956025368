import React, { ReactElement } from 'react';
import { Grid } from '@mui/material';

import { FormButton } from 'forms/components/FormButton';

type CreateBenutzerFormStepNotFoundProps = {
    email: string | undefined;
    onClose: () => void;
    onComplete: () => void;
};

export const CreateBenutzerFormStepNotFound = ({
    email,
    onClose,
    onComplete,
}: CreateBenutzerFormStepNotFoundProps): ReactElement => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                Der Benutzer mit der E-Mail-Adresse
                <br />
                <strong>{email}</strong>
                <br />
                existiert nicht in PfAD.invest. Möchten Sie einen neuen Benutzer anlegen?
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={1} justifyContent="flex-end">
                    <Grid item>
                        <FormButton variant="outlined" onClick={onClose}>
                            Nein
                        </FormButton>
                    </Grid>
                    <Grid item>
                        <FormButton variant="contained" color="primary" onClick={onComplete}>
                            Ja
                        </FormButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
