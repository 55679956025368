import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { EinrichtungListResponse } from 'api/types';
import { GetData } from 'components/DataTableHydra/hooks/useTableData';

export const useTraegerverbandEinrichtungenListData = (id: string): GetData<EinrichtungListResponse> => {
    return useCallback(
        ({ page, rowsPerPage, searchText }) => {
            return backendApiService.getEinrichtungCollection({
                page,
                itemsPerPage: rowsPerPage,
                traegerverband: id,
                qSearch: searchText ? [searchText] : undefined,
            });
        },
        [id]
    );
};
