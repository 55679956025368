import React from 'react';

import { ACTIONS } from 'constants/antragActions';
import { ANTRAG_ACTION_STATUS_LABELS, ANTRAG_STATUS_LABELS } from 'constants/labels';
import { FormButton } from 'forms/components/FormButton';
import { useConfirmationDialogActions } from 'forms/state/useConfirmationDialogState';
import { useFormState } from 'forms/state/useFormState';

interface FormSaveButtonInterface {
    onZurPruefungFreigeben: () => void;
    disabled: boolean;
}

export const FormZurPruefungFreigebenButton: React.FC<FormSaveButtonInterface> = ({
    onZurPruefungFreigeben,
    disabled,
}) => {
    const { antragStatus } = useFormState();
    const { showConfirmation, closeConfirmation } = useConfirmationDialogActions();

    const onZurPruefungFreigebenBase = () => {
        showConfirmation({
            alertText: `Sie sind dabei Ihren Antrag zur Prüfung freizugeben. Der Status dieses Antrages ändert sich von '${
                ANTRAG_STATUS_LABELS[antragStatus!]
            }' auf '${ANTRAG_ACTION_STATUS_LABELS[ACTIONS.ZUR_PRUEFUNG_FREIGEBEN]}'. Möchten Sie fortfahren?`,
            confirmLabel: 'Antrag zur Prüfung freigeben',
            denyLabel: 'Abbrechen',
            denyAction: closeConfirmation,
            confirmAction: () => {
                closeConfirmation();
                return onZurPruefungFreigeben();
            },
        });
    };

    return (
        <FormButton
            type="submit"
            variant="contained"
            color="primary"
            onClick={onZurPruefungFreigebenBase}
            disabled={disabled}
            data-cy={'FormZurPruefungFreigebenButton'}
        >
            Zur Prüfung freigeben
        </FormButton>
    );
};
