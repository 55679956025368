import * as React from 'react';
import { LayoutProps, RankedTester, rankWith } from '@jsonforms/core';
import { withJsonFormsLayoutProps } from '@jsonforms/react';
import { Hidden, styled, Typography } from '@mui/material';

import { DescriptionType } from 'forms/types/UiSchemaTypes';

interface DescriptionComponentType extends Omit<LayoutProps, 'uischema'> {
    uischema: DescriptionType;
}

export const DescriptionComponent: React.FC<DescriptionComponentType> = ({ uischema, visible = true }) => {
    return (
        <Hidden xsUp={!visible}>
            <DescriptionTypography>
                {uischema.text !== undefined && uischema.text !== null && uischema.text}
            </DescriptionTypography>
        </Hidden>
    );
};

// @ts-ignore
export const Description = withJsonFormsLayoutProps(DescriptionComponent);

const DescriptionTypography = styled(Typography)(() => ({
    marginTop: 9,
    lineHeight: 1.4,
}));

export const DescriptionTester: RankedTester = rankWith(1, (uischema) => uischema.type === 'Description');
