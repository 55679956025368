import React from 'react';
import { MenuItem, styled } from '@mui/material';

import { ActionDropdown as DataTableHydraActionDropdown } from 'components/DataTableHydra/ActionDropdown/ActionDropdown';
import { AntragActions } from 'constants/antragActions';
import { ANTRAG_ACTION_LABELS } from 'constants/labels';
import { AntragListAction, AntragListActionItem } from 'pages/Antraege/components/useAntragListActions';

interface AntragActionsDropdownProps<T> {
    antrag: T;
    actions: AntragActions;
    handleAction: AntragListAction<T>;
}

export const ActionDropdownAntrag = <T extends AntragListActionItem>({
    antrag,
    actions,
    handleAction,
}: AntragActionsDropdownProps<T>) => {
    const allowedActions = antrag.allowedWorkflowActions ?? [];
    const displayedActions = Object.keys(actions).filter((action) => allowedActions.includes(action));

    if (displayedActions.length === 0) {
        return <></>;
    }

    return (
        <DataTableHydraActionDropdown>
            {displayedActions.map((action, index) => {
                const nextAction = displayedActions[index + 1];
                const isDivider = Boolean(nextAction && actions[action] !== actions[nextAction]);
                return (
                    <AntragMenuItem key={action} onClick={() => handleAction(antrag, action)} divider={isDivider}>
                        {ANTRAG_ACTION_LABELS[action]}
                    </AntragMenuItem>
                );
            })}
        </DataTableHydraActionDropdown>
    );
};

const AntragMenuItem = styled(MenuItem)(({ theme }) => ({
    '&:focus': { borderColor: theme.palette.divider },
}));
