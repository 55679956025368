import create from 'zustand';

type LoadingState = {
    isLoading: boolean;
    message: string | undefined;
    showLoading: (message?: string) => void;
    hideLoading: () => void;
};

export const useLoadingState = create<LoadingState>((set) => ({
    isLoading: false,
    message: undefined,
    showLoading: (message) => set({ isLoading: true, message }),
    hideLoading: () => set({ isLoading: false, message: undefined }),
}));

export const useLoadingActions = (): Pick<LoadingState, 'showLoading' | 'hideLoading'> => {
    return useLoadingState(({ showLoading, hideLoading }) => ({ showLoading, hideLoading }));
};
