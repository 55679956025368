import { Grid, styled } from '@mui/material';

import { Link } from 'elements/Link';
import { SessionTimeout } from 'layout/components/SessionTimeout';
import { InvestPathBuilder } from 'navigation/Paths';

export const Footer = () => {
    return (
        <footer role="contentinfo">
            <Root container>
                <PfadInvest item xs={12} xl={5}>
                    PfAD.invest | © 2010-2022 d-NRW AöR / publicplan GmbH
                </PfadInvest>
                <Session item xs={12} xl={2}>
                    <SessionTimeout />
                </Session>
                <Links item xs={12} xl={5}>
                    <nav aria-label="footer">
                        <ALink to={InvestPathBuilder.home.datenschutz.path}>Datenschutzhinweise</ALink>{' '}
                        <ALink to={InvestPathBuilder.home.impressum.path}>Impressum</ALink>{' '}
                        <ALink to={InvestPathBuilder.home.barrierefreiheit.path}>Barrierefreiheit</ALink>
                    </nav>
                </Links>
            </Root>
        </footer>
    );
};

const Root = styled(Grid)(({ theme }) => ({
    paddingLeft: 15,
    backgroundColor: '#4d4d4d',
    color: theme.palette.common.white,
    lineHeight: '45px',
    zIndex: theme.zIndex.drawer + 1,
    [theme.breakpoints.down(`md`)]: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.mixins.sidebar.width,
    },
    [theme.breakpoints.down(`sm`)]: {
        paddingLeft: 15,
    },
}));

const PfadInvest = styled(Grid)(({ theme }) => ({
    order: 1,
    [theme.breakpoints.down(`md`)]: {
        order: 3,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    [theme.breakpoints.down(`sm`)]: {
        justifyContent: 'flex-start',
    },
}));

const ALink = styled(Link)(({ theme }) => ({
    color: theme.palette.common.white,
    paddingRight: 15,
    '&:visited': {
        color: '#dac5fa',
    },
}));

const Session = styled(Grid)(({ theme }) => ({
    order: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down(`md`)]: {
        order: 1,
    },
    [theme.breakpoints.down(`sm`)]: {
        justifyContent: 'flex-start',
    },
}));

const Links = styled(Grid)(({ theme }) => ({
    order: 3,
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down(`md`)]: {
        order: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    [theme.breakpoints.down(`sm`)]: {
        justifyContent: 'flex-start',
    },
}));
