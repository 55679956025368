import { ReactElement } from 'react';

import { EinrichtungBenutzerEinrichtungRead } from 'api/client';
import { BenutzerResponse } from 'api/types';

interface BenutzerListAntragstellerProps {
    benutzer: BenutzerResponse;
    einrichtungBenutzer: EinrichtungBenutzerEinrichtungRead[];
}

export const BenutzerListAntragsteller = ({
    benutzer,
    einrichtungBenutzer,
}: BenutzerListAntragstellerProps): ReactElement => {
    const user = einrichtungBenutzer.find((eu) => eu.benutzer.id === benutzer.id);
    const isAntragsteller = user?.isAntragsteller;
    return <>{isAntragsteller ? 'Ja' : 'Nein'}</>;
};
