import { AntragType } from 'api/antragTypes';
import { RuhendstellungGrund } from 'api/types';
import { ANTRAG_ART } from 'constants/antragArt';
import { ROLLEN } from 'constants/roles';

import { ACTIONS } from './antragActions';
import { STATUS } from './antragStatus';
import { ANTRAG_VERSIONEN_TYP } from './antragVersionenTyp';
import { BESCHEID_DOKUMENT } from './bescheidDokument';
import { EinrichtungTableActions as EINRICHTUNG_ACTIONS } from './einrichtungActions';
import { EINRICHTUNG_ARTEN } from './einrichtungArten';
import { STATUS as EINRICHTUNG_STATUS } from './einrichtungStatus';
import { GRUNDSTUECK_TYP } from './grundstueckTyp';
import { LALG_TYP } from './lalgTyp';
import { VERGLEICHSBERECHNUNGS_ARTEN } from './vergleichsberechnungArten';

export interface LabelsInterface {
    [key: string]: string;
}

export const ROLLEN_LABELS: LabelsInterface = {
    [ROLLEN.FACHADMIN]: 'Fachadmin',
    [ROLLEN.SUPPORT]: 'Support',
    [ROLLEN.MINISTERIUM]: 'MAGS',
    [ROLLEN.LV_SACHBEARBEITER]: 'LV',
    [ROLLEN.LV_ADMIN]: 'LV Admin',
    [ROLLEN.TRAEGERVERBAND]: 'Mitarbeiter/in des Trägerverbands',
    [ROLLEN.TRAEGER]: 'Mitarbeiter/in des Trägers',
    [ROLLEN.EINRICHTUNG_BENUTZER]: 'Mitarbeiter/in der Pflegeeinrichtung',
    [ROLLEN.SONSTIGER_BEVOLLMAECHTIGTER]: 'Sonstige/r Bevollmächtigte/r',
};

export const EINRICHTUNG_ART_LABELS: LabelsInterface = {
    [EINRICHTUNG_ARTEN.VOLLSTATIONAER]: 'Vollstationär',
    [EINRICHTUNG_ARTEN.TEILSTATIONAER]: 'Teilstationär',
};

export const ANTRAG_STATUS_LABELS: LabelsInterface = {
    [STATUS.ENTWURF]: 'Entwurf',
    [STATUS.EINGEREICHT]: 'Eingereicht',
    [STATUS.ZURUECKGEZOGEN]: 'Zurückgezogen',
    [STATUS.ZURUECKZIEHEN_BEANTRAGT]: 'Zurückziehen beantragt',
    [STATUS.ABGEBROCHEN]: 'Abgebrochen',
    [STATUS.IN_BEARBEITUNG]: 'In Bearbeitung',
    [STATUS.ZUR_PRUEFUNG_FREIGEGEBEN]: 'Zur Prüfung freigegeben',
    [STATUS.IN_PRUEFUNG]: 'In Prüfung',
    [STATUS.FREIGEGEBEN]: 'Freigegeben',
    [STATUS.BESCHIEDEN]: 'Beschieden',
    [STATUS.AUFGEHOBEN]: 'Aufgehoben',
    [STATUS.ZUR_KENNTNIS_GENOMMEN]: 'Zur Kenntnis genommen',
};

export const ANTRAG_ACTION_STATUS_LABELS: LabelsInterface = {
    [ACTIONS.EINREICHEN]: 'Eingereicht',
    [ACTIONS.ABBRECHEN]: 'Abgebrochen',
    [ACTIONS.AUFHEBEN]: 'Aufgehoben',
    [ACTIONS.BEARBEITUNG_STARTEN]: 'In Bearbeitung',
    [ACTIONS.ZUR_PRUEFUNG_FREIGEBEN]: 'Zur Prüfung freigegeben',
    [ACTIONS.PRUEFEN]: 'In Prüfung',
    [ACTIONS.FREIGEBEN]: 'Freigegeben',
    [ACTIONS.BESCHEIDEN]: 'Beschieden',
    [ACTIONS.BESCHEID_AENDERN]: 'In Bearbeitung',
    [ACTIONS.ABLEHNEN]: 'Abgelehnt',
    [ACTIONS.ZURUECKZIEHEN]: 'Zurückgezogen',
    [ACTIONS.ZURUECKWEISEN]: 'Abgelehnt',
    [ACTIONS.ZURUECKZIEHEN_BEANTRAGEN]: 'Zurückziehen beantragt',
    [ACTIONS.ZURUECKZIEHEN_AKZEPTIEREN]: 'Zurückgezogen',
    [ACTIONS.ZURUECKZIEHEN_ABLEHNEN]: 'In Bearbeitung',
    [ACTIONS.ZUR_KENNTNIS_NEHMEN]: 'Zur Kenntnis genommen',
};

export const ANTRAG_ACTION_LABELS: LabelsInterface = {
    [ACTIONS.VIEW]: 'Ansehen',
    [ACTIONS.EDIT]: 'Bearbeiten',
    [ACTIONS.EINREICHEN]: 'Einreichen',
    [ACTIONS.ZURUECKWEISEN]: 'Zurückweisen',
    [ACTIONS.ABBRECHEN]: 'Abbrechen',
    [ACTIONS.BEARBEITUNG_STARTEN]: 'Bearbeitung starten',
    [ACTIONS.ZUR_PRUEFUNG_FREIGEBEN]: 'Zur Prüfung freigeben',
    [ACTIONS.PRUEFEN]: 'Prüfen',
    [ACTIONS.ABLEHNEN]: 'Ablehnen',
    [ACTIONS.FREIGEBEN]: 'Freigeben',
    [ACTIONS.BESCHEIDEN]: 'Bescheiden',
    [ACTIONS.BESCHEID_AENDERN]: 'Bescheid ändern',
    [ACTIONS.AUFHEBEN]: 'Aufheben',
    [ACTIONS.FOLGEANTRAG]: 'Folgeantrag',
    [ACTIONS.ZURUECKZIEHEN_BEANTRAGEN]: 'Zurückziehen',
    [ACTIONS.ZURUECKZIEHEN_AKZEPTIEREN]: 'Zurückziehen',
    [ACTIONS.ZUR_KENNTNIS_NEHMEN]: 'Zur Kenntnis nehmen',
};

export const EINRICHTUNG_STATUS_LABELS: LabelsInterface = {
    [EINRICHTUNG_STATUS.AKTIV]: 'Aktiv',
    [EINRICHTUNG_STATUS.RUHEND_VERZICHT_AUF_PFLEGEWOHNGELD]: 'Ruhend (Verzicht auf Pflegewohngeld)',
    [EINRICHTUNG_STATUS.RUHEND_GESCHLOSSEN]: 'Ruhend (Einrichtung geschlossen)',
};

export const EINRICHTUNG_GRUNDSTUECK_TYP_LABELS: LabelsInterface = {
    [GRUNDSTUECK_TYP.MIETE]: 'Miete',
    [GRUNDSTUECK_TYP.EIGENTUM]: 'Eigentum',
    [GRUNDSTUECK_TYP.ERBPACHT]: 'Erbpacht',
};

export const EINRICHTUNG_LALG_TYP_LABELS: LabelsInterface = {
    [LALG_TYP.MIETE]: 'Miete',
    [LALG_TYP.EIGENTUM]: 'Eigentum',
    [LALG_TYP.MIETE_UND_EIGENTUM]: 'Miete und Eigentum',
};

export const EINRICHTUNG_VERGLEICHSBERECHNUNGS_ARTEN_LABELS: LabelsInterface = {
    [VERGLEICHSBERECHNUNGS_ARTEN.KONKRET]: 'Konkret',
    [VERGLEICHSBERECHNUNGS_ARTEN.FIKTIV]: 'Fiktiv',
};

export const EINRICHTUNG_ART_SHORT_LABELS: LabelsInterface = {
    [EINRICHTUNG_ARTEN.VOLLSTATIONAER_DAUERPFLEGE]: 'VD',
    [EINRICHTUNG_ARTEN.VOLLSTATIONAER_SOLITAERE_KURZZEITPFLEGE]: 'VSK',
    [EINRICHTUNG_ARTEN.TEILSTATIONAER_TAGESPFLEGE]: 'TT',
    [EINRICHTUNG_ARTEN.TEILSTATIONAER_NACHTPFLEGE]: 'TN',
};

export const EINRICHTUNG_ART_FILTER_LABELS: LabelsInterface = {
    [EINRICHTUNG_ARTEN.VOLLSTATIONAER_DAUERPFLEGE]: 'Vollstationäre Dauerpflege (VD)',
    [EINRICHTUNG_ARTEN.VOLLSTATIONAER_SOLITAERE_KURZZEITPFLEGE]: 'Vollstationäre Solitäre Kurzzeitpflege (VSK)',
    [EINRICHTUNG_ARTEN.TEILSTATIONAER_TAGESPFLEGE]: 'Teilstationäre Tagespflege (TT)',
    [EINRICHTUNG_ARTEN.TEILSTATIONAER_NACHTPFLEGE]: 'Teilstationäre Nachtpflege (TN)',
};

export const EINRICHTUNG_ACTION_LABELS: LabelsInterface = {
    [EINRICHTUNG_ACTIONS.ANTRAGSHISTORIE_FESTSTELLUNG]: 'Antragshistorie Feststellung',
    [EINRICHTUNG_ACTIONS.ANTRAGSHISTORIE_FESTSETZUNG]: 'Antragshistorie Feststellung',
    [EINRICHTUNG_ACTIONS.BENUTZER]: 'Benutzer',
};

export const TRAEGER_ART_LABELS: LabelsInterface = {
    FREI: 'Frei',
    PRIVAT: 'Privat',
    KOMMUNAL: 'Kommunal',
};

export const RUHENDSTELLUNG_GRUENDE_LABELS: LabelsInterface = {
    [RuhendstellungGrund.Schliessung]: 'Schließung der Einrichtung',
    [RuhendstellungGrund.Verzicht]: 'Verzicht auf Pflegewohngeld',
};

export const BESCHEID_DOKUMENT_LABELS: LabelsInterface = {
    [BESCHEID_DOKUMENT.BESCHEID]: 'Bescheid',
    [BESCHEID_DOKUMENT.ABLEHNUNGSBESCHEID]: 'Ablehnungsbescheid',
    [BESCHEID_DOKUMENT.ANLAGE_BERECHNUNG_SALG]: 'Anlage Berechnung SALG',
    [BESCHEID_DOKUMENT.ANLAGE_RESTWERTERMITTLUNG]: 'Anlage Restwertermittlung',
    [BESCHEID_DOKUMENT.ANLAGE_AENDERUNG_STAMM_ANTRAGSDATEN]: 'Anlage Änderung Stamm- und Antragsdaten',
    [BESCHEID_DOKUMENT.ZUSAMMENFASSUNG]: 'Zusammenfassung',
};

export const ANTRAG_ART_LABELS: LabelsInterface = {
    [ANTRAG_ART.ERSTANTRAG]: 'Erstantrag',
    [ANTRAG_ART.FOLGEANTRAG]: 'Folgeantrag',
};

export const ANTRAG_VERSIONEN_TYP_LABELS: LabelsInterface = {
    [ANTRAG_VERSIONEN_TYP.CURRENT]: 'Aktueller Antrag',
    [ANTRAG_VERSIONEN_TYP.LV]: 'LV Antragsversion',
    [ANTRAG_VERSIONEN_TYP.EINRICHTUNG]: 'PE Antragsversion',
};

export const ANTRAG_TYP_LABELS: LabelsInterface = {
    [AntragType.FESTSTELLUNG]: 'Feststellungsantrag',
    [AntragType.FESTSETZUNG]: 'Festsetzungsantrag',
    [AntragType.INVESTOREN_MODELL]: 'Antrag nach § 8 Abs. 11',
};
