import React from 'react';
import { Button, CardContent, MenuItem, Select, Typography } from '@mui/material';

import { useDashboardMagsExterneQuellen } from './useDashboardMagsExterneQuellen';

export const DashboardMagsExterneQuellen = () => {
    const { options, selectedQuelle, handleValueChange, onSubmit } = useDashboardMagsExterneQuellen();

    return (
        <>
            <CardContent>
                <Typography variant="h1" component="h3">
                    Datenquelle ergänzen
                </Typography>
                <Typography>Setzen sie einen neuen Wert für eine Datenquelle:</Typography>
            </CardContent>

            <CardContent>
                <Select fullWidth value={selectedQuelle?.name || ''} displayEmpty onChange={handleValueChange}>
                    <MenuItem selected value="">
                        <em>- Bitte wählen -</em>
                    </MenuItem>
                    {options.map((quelle) => (
                        <MenuItem selected value={quelle.name} key={quelle.name}>
                            {quelle.name}
                        </MenuItem>
                    ))}
                </Select>
            </CardContent>
            <CardContent
                sx={{
                    textAlign: 'right',
                }}
            >
                <Button type="button" variant="contained" color="primary" disabled={!selectedQuelle} onClick={onSubmit}>
                    Neu hinzufügen
                </Button>
            </CardContent>
        </>
    );
};
