import React, { useEffect, useState } from 'react';
import { CardContent, Grid } from '@mui/material';

import { useCurrentBenutzerId } from 'api/auth/useAuthHelper';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { DashboardAnbieterAktivitaeten } from 'pages/Dashboard/Anbieter/DashboardAnbieterAktivitaeten';
import { DashboardAnbieterAssistent } from 'pages/Dashboard/Anbieter/DashboardAnbieterAssistent';
import { DashboardAnbieterDirekteinstieg } from 'pages/Dashboard/Anbieter/DashboardAnbieterDirekteinstieg';
import { DashboardAnbieterEinrichtung } from 'pages/Dashboard/Anbieter/DashboardAnbieterEinrichtung';
import { DashboardAnbieterHints } from 'pages/Dashboard/Anbieter/DashboardAnbieterHints';
import { useAnbieterDashboardState } from 'pages/Dashboard/Anbieter/useAnbieterDashboardState';
import { useAntragHelper } from 'pages/Dashboard/Anbieter/useAntragHelper';
import { useDashboardAntraege } from 'pages/Dashboard/Anbieter/useDashboardAntraege';

import { DashboardAnbieterMeldungP29Assistent } from './DashboardAnbieterMeldungP29Assistent';
import { DashboardAnbieterTeilnahme } from './DashboardAnbieterTeilnahme';

const DashboardAnbieter: React.FC = () => {
    const [benutzerId] = useCurrentBenutzerId();
    const { einrichtung, isAntragSteller, setBenutzerId } = useAnbieterDashboardState();
    const [serverDataChanged, setServerDataChanged] = useState(0);

    const {
        feststellungen,
        festsetzungen,
        investorenmodelle,
        meldungenP29,
        isFeststellungenLoading,
        isFestsetzungenLoading,
        isInvestorenmodelleLoading,
        isMeldungenP29Loading,
    } = useDashboardAntraege(einrichtung?.id, serverDataChanged);

    useEffect(() => {
        setBenutzerId(benutzerId);
    }, [benutzerId, setBenutzerId]);

    const {
        lastFeststellungsantrag,
        lastFestsetzungsantrag,
        allowFeststellungsantrag,
        allowFestsetzungsantrag,
        allowInvestorenmodell,
    } = useAntragHelper(einrichtung, feststellungen, festsetzungen, investorenmodelle);

    const isEinrichtungRuhend = einrichtung && einrichtung.status === 'RUHEND_VERZICHT_AUF_PFLEGEWOHNGELD';
    const isLoading = isFeststellungenLoading || isFestsetzungenLoading || isInvestorenmodelleLoading;
    const isFeststellungenAvailable = Boolean(feststellungen?.length);
    const isFestsetzungenAvailable = Boolean(festsetzungen?.length);
    const isInvestorenmodelleAvailable = Boolean(investorenmodelle?.length);
    const isAntragAvailable =
        isFeststellungenAvailable || isFestsetzungenAvailable || isInvestorenmodelleAvailable || isMeldungenP29Loading;
    const isLastAntragAvailable = Boolean(lastFeststellungsantrag || lastFestsetzungsantrag);
    const isAntragAllowed = allowFeststellungsantrag || allowFestsetzungsantrag;
    const isAntragAssistentAllowed =
        einrichtung && !isEinrichtungRuhend && isAntragSteller && lastFeststellungsantrag && isAntragAllowed;
    const isDirektEinstiegAllowed =
        einrichtung && !isEinrichtungRuhend && isAntragSteller && (isAntragAllowed || allowInvestorenmodell);

    return (
        <ContentContainer title="Dashboard">
            <Grid container spacing={5}>
                <Grid item md={7}>
                    <StyledContainer>
                        <CardContent>
                            <AppLoaderContainer isLoading={isLoading}>
                                <DashboardAnbieterHints
                                    isAntragAvailable={isAntragAvailable}
                                    isLastAntragAvailable={isLastAntragAvailable}
                                    isAntragAllowed={isAntragAllowed}
                                />
                                {isEinrichtungRuhend && (
                                    <DashboardAnbieterMeldungP29Assistent meldungenP29={meldungenP29} />
                                )}
                                {isAntragAssistentAllowed && (
                                    <DashboardAnbieterAssistent
                                        feststellungen={feststellungen}
                                        festsetzungen={festsetzungen}
                                    />
                                )}
                                {isDirektEinstiegAllowed && (
                                    <DashboardAnbieterDirekteinstieg
                                        feststellungsantraege={feststellungen}
                                        festsetzungsantraege={festsetzungen}
                                        investorenmodelle={investorenmodelle}
                                    />
                                )}
                                {isEinrichtungRuhend && <DashboardAnbieterTeilnahme />}
                            </AppLoaderContainer>
                        </CardContent>
                    </StyledContainer>
                </Grid>
                <Grid item md={5}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <DashboardAnbieterEinrichtung />
                        </Grid>
                        {einrichtung && (
                            <Grid item xs={12}>
                                <DashboardAnbieterAktivitaeten
                                    isLoading={isLoading}
                                    feststellungsantraege={feststellungen}
                                    festsetzungsantraege={festsetzungen}
                                    investorenmodelle={investorenmodelle}
                                    meldungenP29={meldungenP29}
                                    setServerDataChanged={setServerDataChanged}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </ContentContainer>
    );
};

export const DashboardAnbieterPage = withSideBar(DashboardAnbieter, []);
