export const belegungVollstationaer_table = {
    type: 'SummaryTable',
    label: 'Belegung Vollstationär',
    columns: 3,
    scope: '#/properties/belegungVollstationaer',
    entries: [
        {
            type: 'SubTable',
            label: 'Platzzahlen aus dem Feststellungsverfahren',
            level: 0,
            scope: '#/properties/',
            entries: [
                {
                    type: 'FieldRow',
                    scope: '#/properties/field110',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field950',
                },
            ],
        },
        {
            type: 'SubTable',
            label: 'Standard Einzelzimmer',
            level: 0,
            scope: '#/properties/',
            entries: [
                {
                    type: 'SubTable',
                    label: '{#/properties/jahr}',
                    level: 1,
                    scope: '#/properties/belegungStandardEinzelzimmer',
                    entries: [
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field561',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field561a',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field570',
                        },
                    ],
                },
            ],
        },
        {
            type: 'SubTable',
            label: 'Standard Doppelzimmer',
            level: 0,
            scope: '#/properties/',
            entries: [
                {
                    type: 'SubTable',
                    level: 1,
                    label: '{#/properties/jahr}',
                    scope: '#/properties/belegungStandardDoppelzimmer',
                    entries: [
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field562',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field562a',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field570',
                        },
                    ],
                },
            ],
        },
        {
            type: 'SubTable',
            label: '{#/properties/field550}',
            scope: '#/properties/zimmerkategorien',
            entries: [
                {
                    type: 'SubTable',
                    label: '{#/properties/jahr}',
                    level: 1,
                    scope: '#/properties/belegungSonderkategorie',
                    entries: [
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field563',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field563a',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field570',
                        },
                    ],
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field570d',
                },
            ],
        },
        {
            type: 'SubTable',
            label: 'Gesamtbelegung',
            level: 0,
            scope: '#/properties/',
            entries: [
                {
                    type: 'SubTable',
                    label: '{#/properties/jahr}',
                    level: 1,
                    scope: '#/properties/gesamtbelegung',
                    entries: [
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field571',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field573',
                        },
                    ],
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field570d',
                },
            ],
        },
        {
            type: 'SubTable',
            label: 'Separate Kurzzeitpflege',
            level: 0,
            scope: '#/properties/',
            entries: [
                {
                    type: 'SubTable',
                    label: '{#/properties/jahr}',
                    level: 1,
                    scope: '#/properties/belegungSeparateKzp',
                    entries: [
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field564',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field564a',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field564b',
                        },
                    ],
                },
            ],
        },

        {
            type: 'NotesRow',
            scope: '#/properties/notizBelegung',
        },
    ],
};
