export const typography = {
    fontFamily: ['Open Sans', 'Arial', 'sans-serif'].join(','),

    h1: {
        fontSize: 24,
        fontWeight: 700,
    },
    h2: {
        fontSize: 18,
        fontWeight: 700,
    },
    h3: {
        fontSize: 16,
    },
    h4: {
        fontSize: 16,
    },
    h5: {
        fontSize: 16,
    },
    h6: {
        fontSize: 16,
    },
    body1: {
        fontSize: 16,
    },
    body2: {
        fontSize: 16,
    },
};
