import { ChangeEvent, FocusEvent, useCallback, useEffect, useState } from 'react';
import { isEqual } from 'lodash';

import { ComponentError, REQUIRED_ERROR_MESSAGE } from './useErrors';

export const useTempControlData = <T>(
    data: T,
    path: string,
    errors: ComponentError[],
    handleChange: (path: string, data: T, validate?: boolean) => void,
    eventToData: (e: ChangeEvent<any>) => T,
    onTouched: () => void,
    {
        validateOnBlur,
        submitOnChange,
    }: {
        validateOnBlur?: boolean;
        submitOnChange?: boolean;
    } = {
        validateOnBlur: true,
        submitOnChange: false,
    }
) => {
    const [tempData, setTempData] = useState(data);

    const toValue = useCallback(
        (e: ChangeEvent<any> | FocusEvent<any> | string): T => {
            return typeof e === 'string' ? (e as unknown as T) : eventToData(e);
        },
        [eventToData]
    );

    const handleValueChange = (e: ChangeEvent<HTMLInputElement | any> | string) => {
        const value = toValue(e);
        setTempData(value);
        if (submitOnChange) {
            handleChange(path, value);
        }
    };

    const handleOnBlur = (e?: FocusEvent<any> | string) => {
        onTouched();
        const currValue = e ? toValue(e) ?? tempData : tempData;
        if (isEqual(tempData, data) && !e) return;
        handleChange(path, currValue, validateOnBlur);
    };

    useEffect(() => {
        setTempData((prev) => (isEqual(data, prev) ? prev : data));
    }, [data]);

    return {
        tempData,
        handleValueChange,
        handleOnBlur,
    };
};
