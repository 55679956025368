import { useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { useAuthHelper } from 'api/auth/useAuthHelper';
import { FestsetzungsAntragCollectionListResponse, FestsetzungsAntragCollectionResponse } from 'api/types';
import { ActionDropdownAntrag } from 'components/DataTableHydra/ActionDropdown/ActionDropdownAntrag';
import { JahrFilterOptions } from 'components/DataTableHydra/Filters/JahrFilterOptions';
import { MieteEigentumFilterOptions } from 'components/DataTableHydra/Filters/MieteEigentumFilterOptions';
import { StatusFilterOptions } from 'components/DataTableHydra/Filters/StatusFilterOptions';
import { ZustaendigerBenutzerFilterOptions } from 'components/DataTableHydra/Filters/ZustaendigerBenutzerFilterOptions';
import { useLvBenutzerOptions } from 'components/DataTableHydra/hooks/useLvBenutzerOptions';
import { TABLE_ACTIONS } from 'constants/antragActions';
import { ANTRAG_STATUS_LABELS } from 'constants/labels';
import { ROLES } from 'constants/roles';
import { LinkEinrichtung } from 'elements/LinkEinrichtung';
import { formatDateToString } from 'forms/utils/formatter';
import { AntragFilterOptions } from 'pages/Antraege/components/useAntragFilterOptions';
import { AntragListAction } from 'pages/Antraege/components/useAntragListActions';
import { displayBenutzerName } from 'utilities';

export const useColumnsFestsetzung = (
    handleAction: AntragListAction<FestsetzungsAntragCollectionResponse>,
    filterOptions: AntragFilterOptions | undefined
): ((data: FestsetzungsAntragCollectionListResponse | undefined) => MUIDataTableColumnDef[]) => {
    const { hasRole } = useAuthHelper();
    const { options: zustandigerBenutzerOptions } = useLvBenutzerOptions();

    return useCallback(
        (data) => {
            if (!data) return [];
            return [
                {
                    name: 'zuletztEingereichtAt',
                    label: 'Antragsdatum',
                    options: {
                        filter: false,
                        customBodyRender: (zuletztEingereichtAt: string | undefined) =>
                            zuletztEingereichtAt ? formatDateToString(new Date(zuletztEingereichtAt)) : '-',
                    },
                },
                {
                    name: 'jahr',
                    label: 'Festsetzung',
                    options: {
                        ...JahrFilterOptions('Festsetzung', filterOptions?.antragJahr),
                        customBodyRenderLite: (dataIndex: number) =>
                            data['hydra:member'][dataIndex].data?.field500
                                ? formatDateToString(new Date(data['hydra:member'][dataIndex].data!.field500!))
                                : '-',
                    },
                },
                {
                    name: 'aktenzeichen',
                    label: 'Aktenzeichen',
                    options: {
                        filter: false,
                        customBodyRenderLite: (dataIndex: number) =>
                            data['hydra:member'][dataIndex].einrichtung?.aktenzeichen || '-',
                    },
                },
                {
                    name: 'berechnungsart',
                    label: 'Miete oder Eigentum',
                    options: {
                        ...MieteEigentumFilterOptions,
                        customBodyRenderLite: (dataIndex: number) =>
                            data['hydra:member'][dataIndex].feststellungsantrag?.data?.berechnungsart || '-',
                    },
                },
                {
                    name: 'einrichtung',
                    label: 'Name der PE',
                    options: {
                        filter: false,
                        customBodyRenderLite: (dataIndex: number) => (
                            <LinkEinrichtung
                                id={data['hydra:member'][dataIndex].einrichtung?.id!}
                                name={data['hydra:member'][dataIndex].einrichtung?.name!}
                            />
                        ),
                    },
                },
                {
                    name: 'status',
                    label: 'Status',
                    options: {
                        ...StatusFilterOptions(hasRole(ROLES.LV)),
                        customBodyRender: (status: string): string => ANTRAG_STATUS_LABELS[status] || status,
                    },
                },
                {
                    name: 'zustaendigerBenutzer',
                    label: 'Zuständig',
                    options: {
                        ...ZustaendigerBenutzerFilterOptions(
                            zustandigerBenutzerOptions,
                            filterOptions?.zustaendigerBenutzer
                        ),
                        customBodyRenderLite: (dataIndex: number) => {
                            const benutzer = data['hydra:member'][dataIndex].einrichtung?.zustaendigerBenutzer;
                            return displayBenutzerName(benutzer) ?? '';
                        },
                    },
                },
                {
                    name: 'actions',
                    label: 'Aktion',
                    options: {
                        sort: false,
                        filter: false,
                        viewColumns: false,
                        customBodyRenderLite: (dataIndex: number) => {
                            const rowData = data['hydra:member'][dataIndex];
                            return (
                                Boolean(rowData.allowedWorkflowActions?.length) && (
                                    <ActionDropdownAntrag
                                        antrag={rowData}
                                        actions={TABLE_ACTIONS}
                                        handleAction={handleAction}
                                    />
                                )
                            );
                        },
                    },
                },
            ];
        },
        [handleAction, hasRole, zustandigerBenutzerOptions, filterOptions]
    );
};
