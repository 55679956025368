import React, { useRef } from 'react';
import { Grid } from '@mui/material';
import { isEqual } from 'lodash';

import { STATUS } from 'constants/antragStatus';
import { FormAddButton } from 'forms/controls/components/AddLink';
import { ErrorList } from 'forms/controls/components/ErrorList';
import { SubFormCard } from 'forms/controls/components/SubFormCard';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';
import { useFormState } from 'forms/state/useFormState';
import { FormExtendButtonType, FormStateChange } from 'forms/types/UiSchemaTypes';
import { buildPath, determineSchemaForElement } from 'forms/utils/SchemaUtils';

type FormularerweiterungButtonControlProps = Omit<CustomControlProps<Array<any>>, 'uischema'> & {
    uischema: FormExtendButtonType;
};

const FormularerweiterungButtonControlComponent: React.FC<FormularerweiterungButtonControlProps> = ({
    uischema,
    schema,
    data,
    handleChange,
    path,
    hasErrors,
    errors,
    disabled,
    readonly,
    required,
    config,
}) => {
    const isNew = useRef<boolean>();
    const { antragStatus } = useFormState();

    const onChange = (state: FormStateChange, index: number) => {
        const newData = data.map((em, idx) => {
            return idx === index ? state.data : em;
        });
        if (!isEqual(newData, data)) {
            handleChange(path, newData);
        }
    };

    const onAdd = () => {
        handleChange(path, [...(data || []), {}], true);
        isNew.current = true;
    };

    const onClose = (index: number) =>
        handleChange(
            path,
            data.filter((_, idx) => index !== idx),
            true
        );

    return (
        <Grid container spacing={1}>
            {hasErrors && (
                <Grid item xs={12}>
                    <ErrorList errors={errors} />{' '}
                </Grid>
            )}
            {data?.map((subformData, index) => (
                <Grid item xs={12} key={index}>
                    <SubFormCard
                        onClose={
                            (required && data?.length === 1) || !schema.custom?.deleteable
                                ? undefined
                                : () => onClose(index)
                        }
                        schema={determineSchemaForElement(data, schema, index)}
                        data={Array.isArray(subformData) ? {} : subformData}
                        config={{
                            ...config,
                            path: buildPath(config.path, path, index),
                            autoFocusFirstControl: isNew.current && data.length - 1 === index,
                        }}
                        onChange={(state) => onChange(state, index)}
                        uiSchema={uischema.uiSchema}
                    />
                </Grid>
            ))}
            {STATUS.IN_PRUEFUNG !== antragStatus && schema.custom?.addable && (
                <Grid item xs={12}>
                    <FormAddButton
                        label={uischema.label}
                        onClick={onAdd}
                        disabled={disabled || readonly}
                        data-cy={'FormAddButton'}
                    />
                </Grid>
            )}
        </Grid>
    );
};

// @ts-ignore
export const FormularerweiterungButtonControl = withCustomControlProps(FormularerweiterungButtonControlComponent, {
    showErrorsImmediately: true,
});
