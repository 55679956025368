import React from 'react';
import { ControlProps } from '@jsonforms/core';
import { JsonFormsDispatch, withJsonFormsControlProps } from '@jsonforms/react';
import { TableCell, TableRow, Typography, useTheme } from '@mui/material';

import { useUuidValue } from 'forms/hooks/useUuidValue';
import { renderers } from 'forms/renderers';
import { Schema, UiSchemaType } from 'forms/types/UiSchemaTypes';
import { determineSchemaByUUID, getFieldSchemaByScope } from 'forms/utils/SchemaUtils';
import { getFieldFromScope } from 'utilities';
import { replaceScopeTemplates } from 'utilities/ScopeUtils';

interface SubTableUiSchema {
    columns: number;
    label: string;
    entries: UiSchemaType[];
    level?: number;
}

interface SubTableInterface extends Omit<ControlProps, 'uischema'> {
    uischema: SubTableUiSchema;
}

const SubTableComponent: React.FC<SubTableInterface> = ({ uischema, path, schema, data }) => {
    const theme = useTheme();

    const headerStyle = {
        fontWeight: 600,
        paddingLeft: theme.spacing((uischema.level ?? 0) * 6),
    };

    if (!data) return null;

    if (Array.isArray(data)) {
        return (
            <>
                {data.map((d, dataIndex) => (
                    <React.Fragment key={dataIndex}>
                        <SubTableHeader
                            key={`${dataIndex}-header`}
                            label={uischema.label}
                            data={d}
                            style={headerStyle}
                        />
                        {uischema.entries
                            .filter((row) =>
                                getFieldSchemaByScope(row.scope, determineSchemaByUUID(d.uuid, schema as Schema))
                            )
                            .map((row, index) => (
                                <JsonFormsDispatch
                                    key={`${dataIndex}-${index}`}
                                    renderers={renderers}
                                    schema={determineSchemaByUUID(d.uuid, schema as Schema)}
                                    uischema={row}
                                    path={`${path}.${dataIndex}`}
                                    enabled
                                    visible
                                />
                            ))}
                    </React.Fragment>
                ))}
            </>
        );
    }

    return (
        <>
            <TableRow key={'label'}>
                <TableCell colSpan={3}>
                    <Typography variant={'h4'} style={headerStyle}>
                        {replaceScopeTemplates(uischema.label, data)}
                    </Typography>
                </TableCell>
            </TableRow>
            {uischema.entries
                .filter((row) => {
                    return (schema as Schema)[getFieldFromScope(row.scope)!];
                })
                .map((row, index) => (
                    <JsonFormsDispatch
                        key={index}
                        renderers={renderers}
                        schema={{ properties: schema as any }}
                        uischema={row}
                        path={path}
                        enabled
                        visible
                    />
                ))}
        </>
    );
};

interface SubTableHeaderType {
    label: string;
    data: FormData;
    style: any;
}

const SubTableHeader: React.FC<SubTableHeaderType> = ({ label, data, style }) => {
    const headerText = useUuidValue(replaceScopeTemplates(label, data));
    if (!headerText) {
        return <></>;
    }
    return (
        <TableRow>
            <TableCell colSpan={3}>
                <Typography variant={'h4'} style={style}>
                    {headerText}
                </Typography>
            </TableCell>
        </TableRow>
    );
};

// @ts-ignore
export const SubTable = withJsonFormsControlProps(SubTableComponent);
