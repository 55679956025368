import { Launch, PictureAsPdf } from '@mui/icons-material';
import { LinkProps, styled } from '@mui/material';

import { Link } from './Link';

type LinkExternProps = LinkProps & {
    type?: 'site' | 'pdf';
};

export const LinkExtern = ({ type = 'site', target, rel, ...props }: LinkExternProps) => (
    <>
        {type === 'site' && <LinkExternIcon />}
        {type === 'pdf' && <LinkExternPdfIcon />}{' '}
        <Link {...props} target={target || '_blank'} rel={rel || 'noreferrer'} />
    </>
);

const LinkExternIcon = styled(Launch)(({ theme }) => ({
    verticalAlign: 'middle',
    fontSize: theme.typography.body1.fontSize,
}));

const LinkExternPdfIcon = styled(PictureAsPdf)(({ theme }) => ({
    verticalAlign: 'middle',
    fontSize: theme.typography.body1.fontSize,
}));
