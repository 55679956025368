import React, { useMemo } from 'react';

import { AntragResponse, AntragVersionResponse } from 'api/types';
import { TabConfig } from 'layout/menu/TabNavigation';
import { AntragDetailsVersionBescheide } from 'pages/Antraege/components/AntragDetailsVersionBescheide';
import { AntragDetailsVersionDokumente } from 'pages/Antraege/components/AntragDetailsVersionDokumente';

export const useAntragVersionDetailsTabConfig = (
    version: AntragVersionResponse | null,
    antrag: AntragResponse
): TabConfig[] => {
    return useMemo(() => {
        return [
            {
                label: 'Dokumente',
                value: 'dokumente',
                // @ts-ignore TODO
                component: <AntragDetailsVersionDokumente version={version} antrag={antrag} />,
            },
            {
                label: 'Bescheide',
                value: 'bescheide',
                component: <AntragDetailsVersionBescheide version={version} antrag={antrag} />,
            },
        ];
    }, [version, antrag]);
};
