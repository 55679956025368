import React, { ReactElement } from 'react';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';

import { useApiFremdkapitalzinsenBundesbankCollection } from 'api/hooks/useApiClient';
import { UpdateableFormTable } from 'components/FormModal/UpdateableFormTable';
import { DezimalzahlProzent as Prozent } from 'elements/DezimalzahlProzent';
import { ContentContainer } from 'layout/container/ContentContainer';
import { withSideBar } from 'layout/hooks/useSideBar';

const FremdkapitalzinsenSud119: React.FC = (): ReactElement => {
    const columns: MUIDataTableColumnDef[] = [
        { name: 'monat', label: 'Zeit' },
        {
            name: 'value',
            label: 'Fremdkapitalzinsen (SUD 119)',
            options: {
                customBodyRender: Prozent,
            },
        },
    ];

    const options: MUIDataTableOptions = {
        sortOrder: {
            name: 'monat',
            direction: 'desc',
        },
        filter: false,
    };

    const content = {
        headline: 'Fremdkapitalzinsen (SUD 119)',
    };

    const table = {
        columns,
        options,
        callback: useApiFremdkapitalzinsenBundesbankCollection,
        callbackType: undefined,
    };

    return (
        <ContentContainer title="Fremdkapitalzinsen (SUD 119)">
            <UpdateableFormTable content={content} table={table} />
        </ContentContainer>
    );
};

export const FremdkapitalzinsenSud119Page = withSideBar(FremdkapitalzinsenSud119, []);
