import React, { ReactElement, useState } from 'react';
import { CircularProgress, Grid, styled } from '@mui/material';
import { isValid } from 'date-fns';

import { backendApiService } from 'api/ApiService';
import { DatePicker } from 'forms/components/DatePicker';
import { FormButton } from 'forms/components/FormButton';
import { useConfirmationDialogActions } from 'forms/state/useConfirmationDialogState';
import { useMessageActions } from 'forms/state/useMessages';
import { formatDateToString } from 'forms/utils/formatter';
import { errorMessage } from 'forms/utils/MessageUtils';
import { Bescheidung } from 'pages/Dashboard/Landschaftsverband/useDashboardLvAntraege';

type DashboardLvBescheidVersandRowDatumProps = {
    bescheidung: Bescheidung;
    refresh: () => void;
};

export const DashboardLvBescheidVersandRowDatum = ({
    bescheidung,
    refresh,
}: DashboardLvBescheidVersandRowDatumProps): ReactElement => {
    const { addMessage } = useMessageActions();
    const { showConfirmation, closeConfirmation } = useConfirmationDialogActions();
    const [isLoading, setLoading] = useState(false);
    const [date, setDate] = useState<Date | null>(null);
    const isDateValid = isValid(date);

    const submitDate = async () => {
        if (!date) return;
        try {
            setLoading(true);
            const { bescheidungId, antragTyp } = bescheidung;
            switch (antragTyp) {
                case 'feststellungsantrag':
                    await backendApiService.patchFeststellungsAntragGesendetAt(bescheidungId, date.toISOString());
                    break;
                case 'festsetzungsantrag':
                    await backendApiService.patchFestsetzungsAntragGesendetAt(bescheidungId, date.toISOString());
                    break;
                case 'investorenmodell':
                    await backendApiService.patchInvestorenmodellGesendetAt(bescheidungId, date.toISOString());
                    break;
            }
            refresh();
            showConfirmation({
                alertText:
                    'Bescheiddatum erfolgreich gesetzt. Es wurde kein automatisierter Versand gewählt. <b>Der Antragsteller wird <u>nicht</u> über die Bescheidung informiert.</b> Bitte stellen Sie sicher, dass der gesamte Bescheid dem Antragsteller zugänglich gemacht wird.',
                confirmLabel: 'Verstanden',
                confirmAction: closeConfirmation,
                denyAction: undefined,
            });
        } catch (e) {
            addMessage(createErrorMessage());
        } finally {
            setLoading(false);
        }
    };

    const onBescheidDatumSubmit = async () => {
        showConfirmation({
            confirmAction: async () => {
                closeConfirmation();
                await submitDate();
            },
            alertText: `Wollen Sie folgendes Datum <b>${formatDateToString(date)}</b> für diesen Bescheid setzen?`,
            denyLabel: 'Abbrechen',
            confirmLabel: 'Datum setzen',
            denyAction: closeConfirmation,
        });
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <DatePicker
                    value={date}
                    onChange={setDate}
                    error={Boolean(date && !isDateValid)}
                    disabled={isLoading}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <SubmitDateButton
                    onClick={onBescheidDatumSubmit}
                    variant="contained"
                    disabled={isLoading || !isDateValid}
                >
                    {isLoading ? <CircularProgress size={28} /> : 'Bescheiddatum setzen'}
                </SubmitDateButton>
            </Grid>
        </Grid>
    );
};

const SubmitDateButton = styled(FormButton)(() => ({
    width: '100%',
}));

const createErrorMessage = () => errorMessage('Bescheiddatum konnte nicht gesetzt werden.', { autoCloseSeconds: 8 });
