import { useState } from 'react';
import { isEqual } from 'lodash';

import { backendApiService } from 'api/ApiService';
import { AntragstellerType, EinrichtungBenutzerRequest } from 'api/types';
import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { useFormValidation } from 'forms/hooks/useFormValidation';
import { useLayoutWithJsonFormsState } from 'forms/hooks/useJsonFormsState';
import { useMessageActions } from 'forms/state/useMessages';
import { FormConfig, FormLayoutConfig, FormStateChange, Schema, UiSchemaType } from 'forms/types/UiSchemaTypes';
import { errorMessage, successMessage } from 'forms/utils/MessageUtils';

type CreateBenutzerAssignStepFormData = {
    isAntragsteller: AntragstellerType | undefined;
};

type UseCreateBenutzerAssignStepFormResult = {
    onSubmit: () => Promise<void>;
    isValid: boolean;
    isSubmitting: boolean;

    data: CreateBenutzerAssignStepFormData;
    schema: Schema;
    uischema: UiSchemaType;
    config: FormConfig;
    onChange: (state: FormStateChange) => void;
};

export type CreateBenutzerFormStepAssignOnComplete = () => void;

export const useCreateBenutzerAssignStepForm = (
    benutzerId: number,
    einrichtungId: number,
    onComplete: CreateBenutzerFormStepAssignOnComplete
): UseCreateBenutzerAssignStepFormResult => {
    const { addMessage } = useMessageActions();
    const config = useLayoutWithJsonFormsState(configLayout);
    const [isSubmitting, setSubmitting] = useState(false);
    const [data, setData] = useState<CreateBenutzerAssignStepFormData>({ isAntragsteller: undefined });
    const { valid: isValid } = useFormValidation(schema, data, config);

    const onSubmit = async () => {
        if (isSubmitting || data.isAntragsteller === undefined) return;
        setSubmitting(true);
        try {
            const request: EinrichtungBenutzerRequest = {
                benutzer: String(benutzerId),
                einrichtung: String(einrichtungId),
                isAntragsteller: data.isAntragsteller === AntragstellerType.SCHREIBEND,
            };
            await backendApiService.postEinrichtungBenutzer(request);
            addMessage(createSuccessMessage());
            onComplete();
        } catch (e) {
            addMessage(createErrorMessage());
        } finally {
            setSubmitting(false);
        }
    };

    const onChange = ({ data: formData }: FormStateChange) => {
        if (isEqual(data, formData)) return;
        setData(formData);
    };

    return {
        data,
        onChange,
        onSubmit,
        isValid,
        isSubmitting,
        schema,
        uischema,
        config,
    };
};

const configLayout: FormLayoutConfig = {
    gridLayout: {
        label: 3,
        input: 6,
    },
};

const schema: Schema = {
    type: 'object',
    required: ['isAntragsteller'],
    properties: {
        isAntragsteller: {
            $id: 'isAntragsteller',
            title: 'Berechtigung',
            type: 'string',
            oneOf: [
                {
                    const: AntragstellerType.LESEND,
                    title: 'Lesend',
                },
                {
                    const: AntragstellerType.SCHREIBEND,
                    title: 'Schreibend',
                },
            ],
            // @ts-ignore
            custom: {
                block_prefixes: ['choice', 'radios'],
            },
        },
    },
};

const uischema: UiSchemaType = control('isAntragsteller');

const createSuccessMessage = () =>
    successMessage('Der Benutzer wurde der Einrichtung zugewiesen.', {
        autoCloseSeconds: 8,
        closable: true,
    });

const createErrorMessage = () =>
    errorMessage('Es ist ein Fehler aufgetreten.', {
        autoCloseSeconds: 8,
        closable: true,
    });
