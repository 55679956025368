const KONTAKT_DUESSELDORF = 'Düsseldorf';

//export const KONTAKT_PFADINV = 'PfAD.invest';

export const KONTAKT_PFADINV_URL = 'www.pfadinvest.mags.nrw.de';
export const KONTAKT_PFADINV_TEL = '+49 231 / 222 438 88';
export const KONTAKT_PFADINV_EMAIL = 'pfadinvest@d-nrw.de';
export const KONTAKT_PFADINV_HANDBUECHER = 'https://www.d-nrw.de/pfadinvest/';

export const KONTAKT_MAGS = 'Ministerium für Arbeit, Gesundheit und Soziales des Landes Nordrhein-Westfalen';
export const KONTAKT_MAGS_DATENSCHUTZ = 'Behördliche Datenschutzbeauftragte, MR‘in Petra Bühler';
export const KONTAKT_MAGS_STR = 'Fürstenwall';
export const KONTAKT_MAGS_HNR = '25';
export const KONTAKT_MAGS_PLZ = '40219';
export const KONTAKT_MAGS_STADT = KONTAKT_DUESSELDORF;
export const KONTAKT_MAGS_TEL = '+49 211 / 855 - 5';
export const KONTAKT_MAGS_FAX_IMPRESSUM = '+49 211 / 855 - 3211';
export const KONTAKT_MAGS_FAX_DATENSCHUTZ = '+49 211 / 855 - 3683';
export const KONTAKT_MAGS_EMAIL_POSTSTELLE = 'poststelle@mags.nrw.de';
export const KONTAKT_MAGS_EMAIL_DATENSCHUTZ = 'datenschutz@mags.nrw.de';

export const KONTAKT_BARR_UEBERWACHUNG_EMAIL =
    'ueberwachungsstelle-nrw@it.nrw.de?subject=Anfrage%20zum%20Thema%20Barrierefreiheit';
export const KONTAKT_BARR_UEBERWACHUNG_URL =
    'https://www.mags.nrw/ueberwachungsstelle-barrierefreie-informationstechnik';
export const KONTAKT_BARR_OMBUD_URL = 'https://www.mags.nrw/ombudsstelle-barrierefreie-informationstechnik';
export const KONTAKT_BARR_OMBUD_EMAIL =
    'ombudsstelle-barrierefreie-it@mags.nrw.de?subject=Anfrage%20zum%20Thema%20Barrierefreiheit%20%C3%BCber%20mags.nrw';
export const KONTAKT_BARR_OMBUD_TEL = '+49 211 / 855 - 3451';

export const KONTAKT_DNRW = 'd-NRW AöR';
export const KONTAKT_DNRW_STR = 'Rheinische Str.';
export const KONTAKT_DNRW_HNR = '1';
export const KONTAKT_DNRW_PLZ = '44137';
export const KONTAKT_DNRW_STADT = 'Dortmund';
export const KONTAKT_DNRW_ANSPRECHPARTNER = 'Anstalt öffentlichen Rechts';
export const KONTAKT_DNRW_URL = 'https://www.d-nrw.de';

export const KONTAKT_PUBLIC_PLAN = 'publicplan GmbH';
export const KONTAKT_PUBLIC_PLAN_STR = 'Kennedydamm';
export const KONTAKT_PUBLIC_PLAN_HNR = '24';
export const KONTAKT_PUBLIC_PLAN_PLZ = '40476';
export const KONTAKT_PUBLIC_PLAN_STADT = KONTAKT_DUESSELDORF;
export const KONTAKT_PUBLIC_PLAN_URL = 'https://www.publicplan.de';

export const KONTAKT_ITNRW = 'Landesbetrieb Information und Technik Nordrhein-Westfalen (IT.NRW)';
export const KONTAKT_ITNRW_STR = 'Mauerstraße';
export const KONTAKT_ITNRW_HNR = '51';
export const KONTAKT_ITNRW_PLZ = '40476';
export const KONTAKT_ITNRW_STADT = KONTAKT_DUESSELDORF;

export const KONTAKT_LDI = 'Landesbeauftragte für Datenschutz und Informationsfreiheit Nordrhein-Westfalen';
export const KONTAKT_LDI_STR = 'Postfach';
export const KONTAKT_LDI_HNR = '20 04 44';
export const KONTAKT_LDI_PLZ = '40102';
export const KONTAKT_LDI_STADT = KONTAKT_DUESSELDORF;
export const KONTAKT_LDI_TEL = '+49 211 / 38424 - 0';
export const KONTAKT_LDI_FAX = '+49 211 / 38424 - 10';
export const KONTAKT_LDI_EMAIL = 'poststelle@ldi.nrw.de';
export const KONTAKT_LDI_URL = 'www.ldi.nrw.de';

// Bezirksregierung Münster
export const KONTAKT_BRMS_EMAIL = 'poststelle@brms.nrw.de';
