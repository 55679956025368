import { useCallback, useState } from 'react';

import { backendApiService } from 'api/ApiService';
import { BenutzerResponse } from 'api/types';
import { BenutzerTableAction, BenutzerTableActions } from 'constants/benutzerActions';
import { useMessageActions } from 'forms/state/useMessages';
import { errorMessage, successMessage } from 'forms/utils/MessageUtils';

interface UseBenutzerListActionResult {
    bearbeitenBenutzer: BenutzerResponse | undefined;
    finishAction: () => void;
    serverDataChanged: number;
    handleAction: BenutzerTableAction;
}

export const useBenutzerListAction = (): UseBenutzerListActionResult => {
    const { addMessage } = useMessageActions();
    const [serverDataChanged, setServerDataChanged] = useState(0);
    const [bearbeitenBenutzer, setBearbeitenBenutzer] = useState<BenutzerResponse | undefined>(undefined);

    const finishAction = () => {
        setBearbeitenBenutzer(undefined);
        setServerDataChanged((prev) => prev + 1);
    };

    const handleAction: BenutzerTableAction = useCallback(
        async (action, benutzer) => {
            const id = benutzer.id;
            if (!id) return;
            switch (action) {
                case BenutzerTableActions.BENUTZER_BEARBEITEN:
                    setBearbeitenBenutzer(benutzer);
                    return;
                case BenutzerTableActions.BENUTZER_TOGGLE_AKTIVIEREN:
                    return backendApiService
                        .patchToggleBenutzerStatus(id)
                        .then(() => setServerDataChanged((prev) => prev + 1))
                        .then(() => {
                            addMessage(createSuccessMessage(!benutzer.deaktiviertAt));
                        })
                        .catch(() => {
                            addMessage(createErrorMessage(!benutzer.deaktiviertAt));
                        });
            }
        },
        [addMessage]
    );
    return {
        bearbeitenBenutzer,
        finishAction,
        handleAction,
        serverDataChanged,
    };
};

const createSuccessMessage = (isActive: boolean) =>
    successMessage(isActive ? 'Benutzer erfolgreich deaktiviert.' : 'Benutzer erfolgreich aktiviert.', {
        autoCloseSeconds: 8,
        closable: true,
    });

const createErrorMessage = (isActive: boolean) =>
    errorMessage(isActive ? 'Benutzer konnte nicht deaktiviert werden.' : 'Benutzer konnte nicht aktiviert werden.', {
        autoCloseSeconds: 8,
        closable: true,
    });
