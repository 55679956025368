import { accordion } from 'forms/AntragForm/ui-schemas/elements/accordion';
import { collectionGroups } from 'forms/AntragForm/ui-schemas/elements/collectionGroups';
import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { dataGrid } from 'forms/AntragForm/ui-schemas/elements/dataGrid';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { highlightedArea } from 'forms/AntragForm/ui-schemas/elements/highlightedArea';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';

export const uiSchema = verticalLayout([
    group([control('field1766a'), control('field1766'), control('field1767'), control('field1768')]),
    accordion(
        [
            dataGrid(
                'eigenkapitalBasisFiktiveErstinbetriebnahme',
                [
                    {
                        scope: '#/properties/jahr',
                        valueLabel: 'Jahr {value}',
                        textAlign: 'left',
                    },
                    {
                        label: '1766 Eigenkapitalbasis',
                        scope: '#/properties/field1766x',
                        format: 'currency',
                        unit: '€',
                        highlighted: true,
                        textAlign: 'right',
                        styles: {
                            textAlign: 'right',
                            paddingRight: 16,
                        },
                    },
                    {
                        label: '1769 Zinsbetrag',
                        scope: '#/properties/field1769x',
                        format: 'currency',
                        unit: '€',
                        styles: {
                            textAlign: 'right',
                        },
                    },
                ],
                { containerGridSize: 7 }
            ),
        ],
        'Darlehensbasis und Zinsen je Jahr'
    ),
    group([control('field1769g'), control('field1769')]),
    collectionGroups(
        'fiktivePlatzzahlerweiterungen',
        [
            group([control('field1770a'), control('field1770'), control('field1771'), control('field1772')]),
            accordion(
                [
                    dataGrid(
                        'eigenkapitalBasis',
                        [
                            {
                                scope: '#/properties/jahr',
                                valueLabel: 'Jahr {value}',
                                textAlign: 'left',
                            },
                            {
                                label: '1770 Eigenkapitalbasis',
                                scope: '#/properties/field1770x',
                                format: 'currency',
                                unit: '€',
                                highlighted: true,
                                textAlign: 'right',
                                styles: {
                                    textAlign: 'right',
                                    paddingRight: 16,
                                },
                            },
                            {
                                label: '1773 Zinsbetrag',
                                scope: '#/properties/field1773x',
                                format: 'currency',
                                unit: '€',
                                styles: {
                                    textAlign: 'right',
                                },
                            },
                        ],
                        { containerGridSize: 7 }
                    ),
                ],
                'Darlehensbasis und Zinsen je Jahr'
            ),
            group([control('field1773g'), control('field1773')], {
                noSeparator: true,
            }),
        ],
        undefined,
        'massnahmeUuid',
        'Finanzierung Platzzahlerweiterung (EK) - {value}'
    ),

    group([highlightedArea([control('field1774')])]),
]);
