import React, { ReactElement } from 'react';
import { Typography } from '@mui/material';

import { FestsetzungsAntragCollectionResponse, FeststellungsAntragCollectionResponse } from 'api/types';
import { StyledContainer } from 'layout/container/StyledContainer';
import { DashboardAnbieterAssistentSteps } from 'pages/Dashboard/Anbieter/DashboardAnbieterAssistentSteps';
import { useAnbieterDashboardState } from 'pages/Dashboard/Anbieter/useAnbieterDashboardState';
import { useAntragHelper } from 'pages/Dashboard/Anbieter/useAntragHelper';

type DashboardAnbieterAssistentProps = {
    feststellungen: FeststellungsAntragCollectionResponse[];
    festsetzungen: FestsetzungsAntragCollectionResponse[];
};

export const DashboardAnbieterAssistent = ({
    feststellungen,
    festsetzungen,
}: DashboardAnbieterAssistentProps): ReactElement => {
    const { einrichtung } = useAnbieterDashboardState();
    const { allowFeststellungsantrag, allowFestsetzungsantrag } = useAntragHelper(
        einrichtung,
        feststellungen,
        festsetzungen
    );

    if (!einrichtung) return <></>;
    if (!allowFeststellungsantrag && !allowFestsetzungsantrag) return <></>;
    return (
        <>
            <Typography paragraph>
                Sie haben im Folgenden die Möglichkeit die Feststellung und die Festsetzung gemäß den Vorschriften des
                APG NRW und der APG DVO NRW für Ihre Pflegeeinrichtung zu beantragen.
            </Typography>
            <Typography paragraph>
                Unser <strong>Dialogassistent</strong> wird Sie zu dem für Sie relevanten Verfahren leiten:
            </Typography>
            <Typography variant="h1" component="h2">
                Dialogassistent
            </Typography>
            <StyledContainer backgroundVariant="primary" paddingVariant="lg" style={{ marginBottom: '21.5px' }}>
                <DashboardAnbieterAssistentSteps
                    einrichtung={einrichtung}
                    feststellungsantraege={feststellungen}
                    festsetzungsantraege={festsetzungen}
                />
            </StyledContainer>
        </>
    );
};
