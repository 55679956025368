import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { BenutzerListResponse } from 'api/types';
import { GetData } from 'components/DataTableHydra/hooks/useTableData';

export const usePflegeeinrichtungBenutzerListData = (id: number): GetData<BenutzerListResponse> => {
    return useCallback(
        ({ page, rowsPerPage, searchText }) => {
            return backendApiService.getBenutzerCollection({
                page,
                itemsPerPage: rowsPerPage,
                einrichtung: String(id),
                qSearch: searchText ? [searchText] : undefined,
            });
        },
        [id]
    );
};
