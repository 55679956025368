import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/AntragForm/ui-schemas/elements/customVerticalLayout';
import { formDialog } from 'forms/AntragForm/ui-schemas/elements/formDialog';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { listControlWithModalForm } from 'forms/AntragForm/ui-schemas/elements/listControlWithModalForm';
import { tableColumn, tableColumnDate, tableColumnDecimal } from 'forms/AntragForm/ui-schemas/elements/tableColumn';

export const uiSchema = customVerticalLayout([
    group([
        listControlWithModalForm(
            ['#/properties/mietvertraege'],
            [
                tableColumn('Mietvertrag für', {
                    scope: '#/properties/field160',
                }),
                tableColumnDate('Datum', {
                    scope: '#/properties/field161',
                }),
                tableColumnDecimal('Platzzahl', {
                    scope: '#/properties/field163',
                }),
                tableColumn('SALG', { scope: '#/properties/field165' }),
                tableColumnDecimal('Jährlich beantragte Miete', {
                    scope: '#/properties/field1703',
                }),
                tableColumnDate('Ende', { scope: '#/properties/field166' }, { nullValue: 'unbefristet' }),
                {
                    type: 'Control',
                    label: 'Bearbeitet',
                    scope: '#/properties/grunddatenMieteBearbeitet',
                    styles: {
                        textAlign: 'right',
                    },
                },
            ],
            ['Mietvertrag hinzufügen'],
            ['Mietvertrag löschen'],
            ['Wollen Sie diesen Mietvertrag wirklich löschen?'],
            [
                formDialog(
                    'Neuer Mietvertrag',
                    'Mietvertrag',
                    {
                        labelScope: '#/properties/field160',
                    },
                    customVerticalLayout([
                        group([
                            control('field160'),
                            control('field161'),
                            {
                                type: 'InlineRowControl',
                                scope: '#/properties/field166',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/field166',
                                        controlOnly: true,
                                    },
                                    {
                                        type: 'Control',
                                        scope: '#/properties/field166Unbefristet',
                                        controlOnly: true,
                                    },
                                ],
                            },
                            control('field162'),
                            control('field163'),
                            control('field951a'),
                            control('field165'),
                            control('field542'),
                            control('field172'),
                            control('field174'),
                            control('field1704a'),
                            control('field951'),
                            control('field1703'),
                            control('field1703b'),
                            control('field1703a'),
                        ]),
                    ]),
                    { scope: '#/properties/mietvertraege' }
                ),
            ]
        ),
    ]),
    group(
        [
            control('field1705'),
            control('field1706'),
            control('field1706a'),
            control('field1707'),
            control('field1708'),
            control('field2023'),
        ],
        {},
        'Gesamtbetrachtung Mietverträge'
    ),
]);
