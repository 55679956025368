export const zimmerkategorien_table = {
    type: 'SummaryTable',
    label: 'Zimmerkategorien',
    columns: 3,
    scope: '#/properties/zimmerkategorien',
    entries: [
        {
            type: 'SubTable',
            label: 'Standard-Einzelzimmer',
            level: 0,
            scope: '#/properties/',
            entries: [
                {
                    type: 'FieldRow',
                    scope: '#/properties/field551',
                },
            ],
        },
        {
            type: 'SubTable',
            label: 'Standard-Doppelzimmer',
            level: 0,
            scope: '#/properties/',
            entries: [
                {
                    type: 'FieldRow',
                    scope: '#/properties/field552',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field555',
                },
            ],
        },
        {
            type: 'SubTable',
            label: 'Weitere Zimmer',
            level: 0,
            scope: '#/properties/zimmerkategorien',
            entries: [
                {
                    type: 'SubTable',
                    label: '{#/properties/field550} ({#/properties/field550Art})',
                    level: 1,
                    scope: '#/properties/',
                    entries: [
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field553',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field554',
                        },
                    ],
                },
            ],
        },
        {
            type: 'SubTable',
            label: 'Gesamt',
            level: 0,
            scope: '#/properties/',
            entries: [
                {
                    type: 'FieldRow',
                    scope: '#/properties/field790',
                },
            ],
        },
        {
            type: 'NotesRow',
            scope: '#/properties/notizZimmerkategorien',
        },
    ],
};
