import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { JsonForms } from '@jsonforms/react';
import { Alert } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { useAuth } from 'api/AuthContext';
import { customVerticalLayout } from 'forms/AntragForm/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { FormButton } from 'forms/components/FormButton';
import { Pflichtfeld } from 'forms/components/Pflichtfeld';
import { renderers } from 'forms/renderers';
import { FormStateChange, Schema } from 'forms/types/UiSchemaTypes';
import { PATH_DASHBOARD } from 'navigation/Paths';

interface LoginFormInterface {
    email: string;
    password: string;
}

export const LoginForm = () => {
    const { handleJwtToken } = useAuth();
    const navigate = useNavigate();
    const [isSubmitting, setSubmitting] = useState<boolean>(false);
    const [formData, setFormData] = useState<LoginFormInterface>({ email: '', password: '' });
    const [error, setError] = useState<string | undefined>();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setSubmitting(true);
        setError(undefined);

        backendApiService
            .getToken(formData)
            .then(async (response) => {
                await handleJwtToken(response.token, response.refresh_token);
                navigate(PATH_DASHBOARD);
            })
            .catch((error) => {
                setFormData({ ...formData, password: '' });

                switch (error.response.data.message) {
                    case 'User is deactivated.':
                        setError('Der Benutzer wurde deaktiviert. Bitte kontaktieren Sie Ihren Administrator.');
                        break;
                    default:
                        setError('Login fehlgeschlagen.');
                }
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const handleChange = (state: FormStateChange) => {
        setFormData(state.data);
    };

    const schema: Schema = {
        required: ['email', 'password'],
        properties: {
            email: {
                $id: 'loginEmail',
                type: 'string',
                title: 'Email',
                // @ts-ignore
                custom: {
                    block_prefixes: ['login_email'],
                },
            },
            password: {
                $id: 'loginPassword',
                type: 'string',
                title: 'Passwort',
                // @ts-ignore
                custom: {
                    block_prefixes: ['login_password'],
                },
            },
        },
    };

    const uiSchema = customVerticalLayout([
        group([
            {
                type: 'Control',
                scope: '#/properties/email',
                label: false,
                options: {
                    focus: true,
                },
            },
            {
                type: 'Control',
                scope: '#/properties/password',
                label: false,
            },
        ]),
    ]);

    return (
        <>
            {error && <Alert severity="error">{error}</Alert>}
            <Pflichtfeld>* Pflichtfeld</Pflichtfeld>
            <form onSubmit={handleSubmit}>
                <JsonForms
                    data={formData}
                    schema={schema}
                    uischema={uiSchema}
                    renderers={renderers}
                    onChange={handleChange}
                />
                <FormButton
                    disabled={isSubmitting}
                    type="submit"
                    color="primary"
                    variant="contained"
                    size="large"
                    fullWidth
                >
                    Jetzt anmelden
                </FormButton>
            </form>
        </>
    );
};
