import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { JsonFormsCore } from '@jsonforms/core';
import { isEqual } from 'lodash';

import { backendApiService } from 'api/ApiService';
import { useAuthHelper } from 'api/auth/useAuthHelper';
import { BenutzerProfileEdit } from 'api/client';
import { useApiProfile } from 'api/hooks/useApiClient';
import { ROLLEN } from 'constants/roles';
import { useMessageActions } from 'forms/state/useMessages';
import { Schema } from 'forms/types/UiSchemaTypes';
import { errorMessage, successMessage } from 'forms/utils/MessageUtils';
import { InvestPathBuilder } from 'navigation/Paths';
import { schema } from 'pages/Login/formSchemas';

type Benutzerdaten = BenutzerProfileEdit & {
    titel: string | undefined;
    password: string | undefined;
};

export interface UseBenutzerdatenCompletionFormResult {
    data: Benutzerdaten;
    onSubmit: () => Promise<void>;
    onChange: (data: Pick<JsonFormsCore, 'data' | 'errors'>) => void;
    isLoading: boolean;
    isValid: boolean;
}

export const useBenutzerdatenCompletionForm = (
    benutzerId: number | undefined
): UseBenutzerdatenCompletionFormResult => {
    const navigate = useNavigate();
    const { addMessage } = useMessageActions();
    const { refreshJwtToken } = useAuthHelper();
    const { data: profile, isLoading } = useApiProfile();
    const [data, setData] = useState(initialBenutzerDaten);
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        if (!profile) return;
        setData({
            ...profile,
            titel: profile.anrede || '',
            password: undefined,
        });
    }, [profile]);

    const onSubmit = (): Promise<void> => {
        const id = String(benutzerId);
        const { password, titel, ...profileData } = data;
        const request: BenutzerProfileEdit = {
            ...profileData,
            anrede: titel,
            plainPassword: password,
        };
        return backendApiService
            .patchProfile(id, request)
            .then(() => addMessage(createSuccessMessage()))
            .then(() => refreshJwtToken())
            .then(() => navigate(InvestPathBuilder.home.dashboard.path))
            .catch(() => {
                addMessage(createErrorMessage());
            });
    };

    const onChange = ({ data: newData, errors }: Pick<JsonFormsCore, 'data' | 'errors'>) => {
        if (isEqual(newData, data)) return;
        setData(newData);
        setIsValid(errors?.length === 0);
    };

    return {
        data,
        onChange,
        onSubmit,
        isLoading: Boolean(isLoading),
        isValid,
    };
};

export const useBenutzerdatenCompletionFormSchema = (): Schema => {
    const { hasRolle } = useAuthHelper();
    return useMemo(() => {
        const isLv = hasRolle(ROLLEN.LV_SACHBEARBEITER, ROLLEN.LV_ADMIN);
        if (isLv) return schema;
        const required = schema.required?.filter((it) => it !== 'fax') || [];
        return { ...schema, required } as Schema;
    }, [hasRolle]);
};

const createSuccessMessage = () =>
    successMessage('Benutzerdaten aktualisiert.', {
        autoCloseSeconds: 10,
        closable: true,
    });

const createErrorMessage = () =>
    errorMessage('Aktualisierung der Benutzerdaten fehlgeschlagen.', {
        autoCloseSeconds: 10,
        closable: true,
    });

const initialBenutzerDaten: Benutzerdaten = {
    titel: '',
    vorname: '',
    nachname: '',
    telefon: '',
    fax: '',
    email: '',
    password: undefined,
};
