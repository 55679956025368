import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { FormControl, FormHelperText, MenuItem, Select } from '@mui/material';

import { ErrorList } from 'forms/controls/components/ErrorList';
import { ReadonlyValueText } from 'forms/controls/components/ReadonlyValueText';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';

import { RowControl } from './RowControl';

const AuswahlControlComponent: React.FC<CustomControlProps<string>> = ({
    path,
    label,
    required,
    data,
    handleChange,
    schema,
    hasErrors,
    errors,
    disabled,
    readonly,
    formula,
    onTouched,
    showFieldNumberLabels,
    gridLayout,
    uischema,
    config,
    id,
}) => {
    const handleValueChange = useCallback(
        (e: any) => {
            handleChange(path, e.target.value, true);
        },
        [handleChange, path]
    );

    const MenuItems = useMemo(
        () =>
            (schema.oneOf || []).map((entry, index: number) => (
                <MenuItem key={`${index}#${entry.const}`} value={entry.const}>
                    {entry.title}
                </MenuItem>
            )),
        [schema.oneOf]
    );

    return (
        <RowControl
            name={path}
            label={label}
            required={required}
            formula={formula}
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={gridLayout}
            controlOnly={uischema.controlOnly}
            labelForId={id}
            config={config}
            paths={[path]}
        >
            {readonly ? (
                <ReadonlyValueText
                    path={path}
                    hasErrors={hasErrors}
                    errors={errors}
                    text={(data && (schema.oneOf?.find((e) => e.const === data)?.title || data)) || '-'}
                />
            ) : (
                <FormControl fullWidth size={'small'}>
                    <Select
                        id={id}
                        fullWidth
                        error={hasErrors}
                        displayEmpty
                        onBlur={onTouched}
                        disabled={disabled}
                        required={required}
                        value={data || ''}
                        onChange={handleValueChange}
                        inputProps={{ 'data-cy': `form_${path}` }}
                        autoFocus={uischema.options?.firstControl && config.autoFocusFirstControl}
                        title={(data && (schema.oneOf?.find((e) => e.const === data)?.title || data)) || undefined}
                    >
                        {(!required || !data) && (
                            <MenuItem selected value="">
                                {' '}
                                <em>- Bitte wählen -</em>{' '}
                            </MenuItem>
                        )}
                        {MenuItems}
                    </Select>

                    <FormHelperText>{hasErrors ? <ErrorList errors={errors} /> : ' '}</FormHelperText>
                </FormControl>
            )}
        </RowControl>
    );
};

export const AuswahlControl = withCustomControlProps(AuswahlControlComponent);
