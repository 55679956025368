import React from 'react';
import { Grid } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { useAuthHelper } from 'api/auth/useAuthHelper';
import { EinrichtungResponse } from 'api/types';
import {
    EINRICHTUNG_GRUNDSTUECK_TYP_LABELS,
    EINRICHTUNG_LALG_TYP_LABELS,
    EINRICHTUNG_VERGLEICHSBERECHNUNGS_ARTEN_LABELS,
    RUHENDSTELLUNG_GRUENDE_LABELS,
} from 'constants/labels';
import { ROLES } from 'constants/roles';
import { useMessageActions } from 'forms/state/useMessages';
import { formatDateToString } from 'forms/utils/formatter';
import { errorMessage, successMessage } from 'forms/utils/MessageUtils';
import { Verwaltung } from 'pages/Verwaltung/components/LayoutComponents';

import { AktenzeichenInputField } from './components/AktenzeichenInputField';
import { Ruhendstellung } from './components/Ruhendstellung';

export interface StammdatenType {
    id: string;
    einrichtung: EinrichtungResponse | undefined;
    refreshEinrichtung: () => void;
    ohnePfwg?: boolean;
}

export const Stammdaten: React.FC<StammdatenType> = ({ id, refreshEinrichtung, einrichtung, ohnePfwg }) => {
    const { addMessage } = useMessageActions();
    const { hasRole } = useAuthHelper();

    const handleAktenzeichenSubmit = async (value: string) => {
        try {
            await backendApiService.patchEinrichtung(id, { aktenzeichen: value });
            refreshEinrichtung();
            addMessage(successMessage('Aktenzeichen erfolgreich gespeichert.', { autoCloseSeconds: 8 }));
        } catch (e) {
            addMessage(errorMessage('Aktenzeichen konnte nicht gespeichert werden.', { autoCloseSeconds: 8 }));
            console.error(e);
        }
    };

    if (!einrichtung) return null;

    return (
        <>
            <Verwaltung.Container>
                <Verwaltung.Group>
                    <Verwaltung.InfoField label={'Name'} value={einrichtung.name} />
                    <Verwaltung.InfoField label={'NRW-Schlüssel'} value={einrichtung.nrwKey} />
                    <AktenzeichenInputField
                        label={'Aktenzeichen *'}
                        value={einrichtung.aktenzeichen}
                        onSubmit={handleAktenzeichenSubmit}
                    />
                    <Verwaltung.InfoField label={'Träger'} value={einrichtung.traeger?.name} />
                    <Verwaltung.InfoField label={'Trägerverband'} value={einrichtung.traegerverband?.name} />
                </Verwaltung.Group>
                <Verwaltung.Group>
                    <Verwaltung.InfoField label={'Straße'} value={einrichtung.strasse} />
                    <Verwaltung.InfoField label={'Ort'} value={`${einrichtung.plz} ${einrichtung.ort}`} />
                </Verwaltung.Group>
            </Verwaltung.Container>

            <Verwaltung.Container>
                {!ohnePfwg && (
                    <Verwaltung.Group title={'Ansprechpartner Antragsstellung'}>
                        <Verwaltung.InfoField
                            label={'Name'}
                            value={
                                einrichtung.letzterBeschiedenerFeststellungsantrag?.zuletztEingereichtBy?.vorname &&
                                einrichtung.letzterBeschiedenerFeststellungsantrag.zuletztEingereichtBy.nachname
                                    ? `${einrichtung.letzterBeschiedenerFeststellungsantrag.zuletztEingereichtBy.vorname} ${einrichtung.letzterBeschiedenerFeststellungsantrag.zuletztEingereichtBy.nachname}`
                                    : undefined
                            }
                        />
                        <Verwaltung.InfoField
                            label={'Telefon'}
                            value={einrichtung.letzterBeschiedenerFeststellungsantrag?.zuletztEingereichtBy?.telefon}
                        />
                        <Verwaltung.InfoField
                            label={'E-Mail'}
                            value={einrichtung.letzterBeschiedenerFeststellungsantrag?.zuletztEingereichtBy?.email}
                        />
                    </Verwaltung.Group>
                )}
                <Verwaltung.Group title={'Zuständiger LV Sachbearbieter'}>
                    <Verwaltung.InfoField
                        label={'Name'}
                        value={
                            einrichtung.zustaendigerBenutzer?.vorname && einrichtung.zustaendigerBenutzer?.nachname
                                ? `${einrichtung.zustaendigerBenutzer.vorname} ${einrichtung.zustaendigerBenutzer.nachname}`
                                : undefined
                        }
                    />
                    <Verwaltung.InfoField label={'Telefon'} value={einrichtung.zustaendigerBenutzer?.telefon} />
                    <Verwaltung.InfoField label={'E-Mail'} value={einrichtung.zustaendigerBenutzer?.email} />
                </Verwaltung.Group>
            </Verwaltung.Container>

            {!ohnePfwg && (
                <Verwaltung.Container>
                    <Verwaltung.Group title={'Basisdaten'}>
                        <Verwaltung.InfoField
                            label={'Erstinbetriebnahme'}
                            value={formatDateToString(einrichtung.erstinbetriebnahme)}
                        />
                        <Verwaltung.InfoField
                            label={'Aktuelle Platzzahl'}
                            value={einrichtung.letzterBeschiedenerFeststellungsantrag?.data?.field110}
                        />
                        <Verwaltung.InfoField
                            label={'Grundstück in'}
                            value={
                                einrichtung.letzterBeschiedenerFeststellungsantrag?.data?.field122 &&
                                EINRICHTUNG_GRUNDSTUECK_TYP_LABELS[
                                    einrichtung.letzterBeschiedenerFeststellungsantrag.data.field122
                                ]
                            }
                        />
                        <Verwaltung.InfoField
                            label={'LALG überwiegend in'}
                            value={
                                einrichtung.letzterBeschiedenerFeststellungsantrag?.data?.field140 &&
                                EINRICHTUNG_LALG_TYP_LABELS[
                                    einrichtung.letzterBeschiedenerFeststellungsantrag.data.field140
                                ]
                            }
                        />
                        <Verwaltung.InfoField
                            label={'Vergleichsberechnung'}
                            value={
                                einrichtung.letzterBeschiedenerFeststellungsantrag?.data?.field142 &&
                                EINRICHTUNG_VERGLEICHSBERECHNUNGS_ARTEN_LABELS[
                                    einrichtung.letzterBeschiedenerFeststellungsantrag.data.field142
                                ]
                            }
                        />
                        <Verwaltung.InfoField label={'Versorgungsvertrag'} value={''} />
                        <Verwaltung.InfoField
                            label={'Registrierung in PfAD.invest'}
                            value={formatDateToString(einrichtung.createdAt)}
                        />
                        <Verwaltung.InfoField
                            label={'Ruhendgestellt am'}
                            value={formatDateToString(einrichtung.letzteRuhendstellung?.createdAt)}
                        />
                        <Verwaltung.InfoField
                            label={'Grund für Ruhendstellung'}
                            value={
                                einrichtung.letzteRuhendstellung?.grund &&
                                RUHENDSTELLUNG_GRUENDE_LABELS[einrichtung.letzteRuhendstellung?.grund]
                            }
                        />
                        <Verwaltung.InfoField
                            label={'Reaktiviert am'}
                            value={formatDateToString(einrichtung.letzteRuhendstellung?.reaktiviertAt)}
                        />
                        <Verwaltung.InfoField
                            label={'Geschlossen am'}
                            value={formatDateToString(einrichtung.letzteRuhendstellung?.geschlossenAb)}
                        />
                    </Verwaltung.Group>
                </Verwaltung.Container>
            )}
            {hasRole([ROLES.LV_ADMIN, ROLES.SUPPORT]) && (
                <Grid container spacing={2}>
                    <Grid item>
                        <Ruhendstellung refreshEinrichtung={refreshEinrichtung} einrichtung={einrichtung} />
                    </Grid>
                </Grid>
            )}
        </>
    );
};
