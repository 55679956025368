import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Step } from 'layout/HeaderConfig';

export const useCurrentParentStep = (steps?: Step<string>[]): Step<string> | undefined => {
    const { step } = useParams();
    return useMemo(
        () => steps?.find((s) => s.step === step || s.children?.find((c) => c.step === step)),
        [steps, step]
    );
};
