import { EinrichtungJsonldEinrichtungList } from 'api/client';

export enum EinrichtungTableActions {
    ANTRAGSHISTORIE_FESTSTELLUNG = 'ANTRAGSHISTORIE_FESTSTELLUNG',
    ANTRAGSHISTORIE_FESTSETZUNG = 'ANTRAGSHISTORIE_FESTSETZUNG',
    ANTRAGSHISTORIE_INVESTORENMODELL = 'ANTRAGSHISTORIE_INVESTORENMODELL',
    BENUTZER = 'BENUTZER',
}

export type EinrichtungTableAction = (
    action: EinrichtungTableActions,
    einrichtung: EinrichtungJsonldEinrichtungList
) => Promise<void>;
