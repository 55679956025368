import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';

import {
    AntragBase,
    AntragType,
    FestsetzungsAntragAPI,
    FeststellungsAntragAPI,
    InvestorenmodellAPI,
    MeldungP29API,
} from 'api/antragTypes';
import { GegenpruefungResponse } from 'api/types';
import { AntragInfoBox } from 'components/AntragInfoBox/AntragInfoBox';
import { STATUS } from 'constants/antragStatus';
import { STATUS as GEGENPRUEFUNG_STATUS } from 'constants/gegenpruefungStatus';
import { ANTRAG_ACTION_LABELS } from 'constants/labels';
import { AntragForm } from 'forms/AntragForm/AntragForm';
import { useStepInfo } from 'forms/AntragForm/hooks/useStepInfo';
import { StyledGroupHeader } from 'forms/controls/GroupControl';
import { useErrors } from 'forms/hooks/useErrors';
import { useFormHandling } from 'forms/hooks/useFormHandling';
import { useErrorMessages } from 'forms/state/useErrorMessages';
import { FormConfig, FormState, FormStateConfigPerformWorkflowAction } from 'forms/types/UiSchemaTypes';
import { cleanUpData } from 'forms/utils/SchemaUtils';
import { ContentContainer } from 'layout/container/ContentContainer';
import { MenuInterface } from 'layout/HeaderConfig';
import { useCurrentParentStep } from 'navigation/hooks/useCurrentStep';
import { TabRouterNavigation } from 'navigation/TabRouterNavigation';
import { AntragSideBar } from 'pages/Antraege/components/AntragSideBar';
import { useMigrationProblems } from 'pages/Antraege/components/useMigrationProblems';

import { GegenpruefungErlaeuterung } from './GegenpruefungErlaeuterung';
import { useAntragAPI } from './useAntragAPI';
import { useAntragNavigation } from './useAntragNavigation';

type AntragPagePropsBase = {
    type: AntragType;
    stepPathBuilder: (id: string, step?: string) => string;
    header: string;
    menuBuilder: (menus: MenuInterface[]) => MenuInterface[];
};

type FeststellungsAntragPageProps<AntragResponse extends AntragBase> = AntragPagePropsBase & {
    type: AntragType.FESTSTELLUNG;
    api: FeststellungsAntragAPI<AntragResponse>;
};

type FestsetzungsAntragPageProps<AntragResponse extends AntragBase> = AntragPagePropsBase & {
    type: AntragType.FESTSETZUNG;
    api: FestsetzungsAntragAPI<AntragResponse>;
};

type InvestorenmodellPageProps<AntragResponse extends AntragBase> = AntragPagePropsBase & {
    type: AntragType.INVESTOREN_MODELL;
    api: InvestorenmodellAPI<AntragResponse>;
};

type MeldungP29PageProps<AntragResponse extends AntragBase> = AntragPagePropsBase & {
    type: AntragType.MELDUNG_P29;
    api: MeldungP29API<AntragResponse>;
};

type AntragPageProps<AntragResponse extends AntragBase> =
    | FestsetzungsAntragPageProps<AntragResponse>
    | FeststellungsAntragPageProps<AntragResponse>
    | InvestorenmodellPageProps<AntragResponse>
    | MeldungP29PageProps<AntragResponse>;

export const AntragPage = <Antrag extends AntragBase>({
    api,
    stepPathBuilder,
    type,
    header,
    menuBuilder,
}: AntragPageProps<Antrag>): ReactElement => {
    const { id, step } = useParams();
    const [gegenpruefung, setGegenpruefung] = useState<GegenpruefungResponse | null>(null);
    const { setMigrationProblems } = useMigrationProblems();
    const stepRef = useRef<string>();
    const { isZusammenfassung } = useStepInfo(type);

    const antragAPI = useAntragAPI(api);
    const {
        data,
        schema,
        sidebarSteps,
        steps,
        requestValidate,
        requestPersist,
        loadingError,
        isLoading,
        formErrors,
        submit,
        allowedWorkflowActions,
        onChange,
        validate,
        setLoadingError,
        reloadData,
        deleteObjectTypeItem,
        clear,
        setAntragId,
        setSofortigerFestsetzungsantragId,
        setAntragStatus,
        setEinrichtungId,
    } = useFormHandling(antragAPI);

    const { handleGoBack, handleGoNext, hasNextStep, isTabNavigation, tabs } = useAntragNavigation(
        sidebarSteps,
        steps,
        stepPathBuilder
    );

    useEffect(() => {
        setAntragId(Number(id));
    }, [id, setAntragId]);

    useEffect(() => {
        if (id) {
            api.getAntrag(id)
                .then((antrag) => {
                    setAntragStatus(antrag.status);
                    setEinrichtungId(antrag.einrichtung?.id);
                    setGegenpruefung(antrag.letzteGegenpruefung ?? null);
                    setMigrationProblems(antrag.hinweiseAusMigration ?? null);
                    if (
                        type === AntragType.FESTSTELLUNG &&
                        antrag.status &&
                        ([STATUS.ENTWURF, STATUS.ZURUECKGEZOGEN] as string[]).includes(antrag.status)
                    ) {
                        setSofortigerFestsetzungsantragId(
                            antrag.festsetzungsantraege?.length ? antrag.festsetzungsantraege[0].id : undefined
                        );
                    } else {
                        setSofortigerFestsetzungsantragId(undefined);
                    }
                })
                .catch(console.error);
        }
    }, [id, api, setAntragStatus, setMigrationProblems, setEinrichtungId, setSofortigerFestsetzungsantragId, type]);

    useEffect(() => {
        if (stepRef.current === step) {
            return;
        }
        clear();
        stepRef.current = step;
        window.scrollTo(0, 0);
    }, [step, clear]);

    const currentParentStep = useCurrentParentStep(steps);

    const performWorkflowAction: FormStateConfigPerformWorkflowAction<Antrag> = useCallback(
        async (action, navigate) => {
            if (!id) return;

            try {
                const response = await api.performWorkflowAction(id, action);
                if (navigate) handleGoNext();
                return response;
            } catch (e) {
                console.error(e);
                setLoadingError(ANTRAG_ACTION_LABELS[action] + ' fehlgeschlagen.');
                return;
            }
        },
        [id, setLoadingError, api, handleGoNext]
    );

    const handleSubmit = useCallback(
        async (
            persist: boolean,
            modelPaths?: string[],
            navigate = true,
            action?: string,
            recalculateFields?: boolean
        ) => {
            if (isZusammenfassung) {
                handleGoNext();
                return;
            }

            const rsp = await submit(
                cleanUpData(data, schema),
                persist,
                modelPaths,
                undefined,
                undefined,
                recalculateFields
            );

            if (!rsp || ('errors' in rsp && !rsp.errors)) {
                return;
            }

            const errors = [...(Object.keys(rsp.errors ?? {}).map((k) => (rsp.errors ?? {})[k]) || [])];

            if (action && errors.length === 0) {
                const success = await performWorkflowAction(action, false);
                if (!success) {
                    return;
                }
            }

            if (navigate && errors.length === 0 && persist) {
                handleGoNext();
            }
        },
        [submit, handleGoNext, data, isZusammenfassung, schema, performWorkflowAction]
    );

    const config: FormConfig = useMemo(() => {
        return {
            validate,
            reloadData,
            requestPersist,
            requestValidate,
            deleteObjectTypeItem,
            performWorkflowAction,
            errors: formErrors,
            submit: handleSubmit,
            loadFormData: antragAPI.getFormDataAPI,
            submitFormData: antragAPI.submitAPI,
            showFieldNumberLabels: true,
        };
    }, [
        formErrors,
        validate,
        requestValidate,
        requestPersist,
        handleSubmit,
        performWorkflowAction,
        deleteObjectTypeItem,
        antragAPI.getFormDataAPI,
        antragAPI.submitAPI,
        reloadData,
    ]);

    const { errors } = useErrors({
        config,
        path: 'data',
        immediately: true,
    });

    useErrorMessages(errors, loadingError);

    const antragForm = (
        <AntragForm
            data={data}
            errors={formErrors}
            currentStep={step}
            schema={schema}
            allowedWorkflowActions={allowedWorkflowActions}
            onSubmit={handleSubmit}
            onGoNext={() => handleGoNext()}
            onGoBack={() => handleGoBack()}
            onChange={({ data }) => onChange(data)}
            hasNextStep={hasNextStep}
            validate={validate}
            requestValidate={requestValidate}
            isLoading={isLoading}
            config={config}
            type={type}
        />
    );

    return (
        <>
            <ContentContainer title={determineTitle(type)}>
                <AntragSideBar steps={sidebarSteps} stepPathBuilder={stepPathBuilder} menuBuilder={menuBuilder} />
                <Typography style={{ marginBottom: 64 }} variant="h1">
                    {header}
                </Typography>
                <StyledGroupHeader header={currentParentStep?.headline} />
                {!loadingError && (
                    <>
                        <AntragInfoBox antragGetter={api.getAntrag} />
                        {isTabNavigation && id && (
                            <TabRouterNavigation tabs={tabs} buildNewPath={(newTab) => stepPathBuilder(id, newTab)}>
                                {antragForm}
                            </TabRouterNavigation>
                        )}

                        {!isTabNavigation && antragForm}
                    </>
                )}
            </ContentContainer>
            {gegenpruefung &&
                (GEGENPRUEFUNG_STATUS.ABGELEHNT === gegenpruefung.status ||
                    GEGENPRUEFUNG_STATUS.IN_PRUEFUNG === gegenpruefung.status) && (
                    <GegenpruefungErlaeuterung
                        gegenpruefung={gegenpruefung}
                        submit={(gegenpruefungId: number, data: FormState) => {
                            if (gegenpruefung && gegenpruefung.erlaeuterung !== data.erlaeuterung) {
                                api.submitGegenpruefungErlaeuterung(gegenpruefungId, data).then(() => true);
                                gegenpruefung.erlaeuterung = data.erlaeuterung;
                            }
                        }}
                    />
                )}
        </>
    );
};

const determineTitle = (type: AntragType): string => {
    switch (type) {
        case AntragType.FESTSTELLUNG:
            return 'Feststellungsantrag';
        case AntragType.FESTSETZUNG:
            return 'Festsetzungsantrag';
        case AntragType.INVESTOREN_MODELL:
            return 'Antrag nach $ 8 Abs. 11';
        case AntragType.MELDUNG_P29:
            return 'Meldung nach $ 29';
    }
};
