import React from 'react';

export const NewLineText: React.FC<{ text: string }> = ({ text }) => {
    return (
        <>
            {text.split('\n').map((l, i) => (
                <div key={i}>{l}</div>
            ))}
        </>
    );
};
