import IconExpandLess from '@mui/icons-material/ExpandLess';
import IconExpandMore from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material';

interface ExpandIconProps {
    open: boolean;
    unstyled?: boolean;
    color?: string;
    size?: 'inherit' | 'large' | 'medium' | 'small';
}

export const IconExpand = ({ open, unstyled, color, size = 'medium' }: ExpandIconProps) => {
    const StyledIconExpandMore = styled(IconExpandMore)(
        () => `
        margin-right: 0;
        fill: ${color};
    `
    );

    const StyledIconExpandLess = styled(IconExpandLess)(
        () => `
        margin-right: 0;
        fill: ${color};
    `
    );

    if (!open) {
        return unstyled ? <IconExpandMore fontSize={size} /> : <StyledIconExpandMore fontSize={size} />;
    }

    return unstyled ? <IconExpandLess fontSize={size} /> : <StyledIconExpandLess fontSize={size} />;
};
