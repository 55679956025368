import React, { useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { BenutzerJsonldEinrichtungRead } from 'api/client';
import { EinrichtungListResponse } from 'api/types';
import { GetColumns } from 'components/DataTableHydra/hooks/useTableColumns';
import { EINRICHTUNG_ART_SHORT_LABELS, EINRICHTUNG_STATUS_LABELS } from 'constants/labels';
import { LinkEinrichtung } from 'elements/LinkEinrichtung';
import { einrichtungListLinkRender } from 'pages/Verwaltung/Pflegeeinrichtungen/Uebersicht/PflegeeinrichtungListLink';
import { DisplayBenutzer, displayBenutzerName } from 'utilities';
import { rightAlignedColumn } from 'utilities/DataTableHydraUtils';

export const useTraegerEinrichtungenListColumns = (): GetColumns<EinrichtungListResponse> => {
    return useCallback((data) => {
        if (!data) return [];

        const aktenzeichen: MUIDataTableColumnDef = {
            name: 'aktenzeichen',
            label: 'Aktenzeichen',
        };

        const name: MUIDataTableColumnDef = {
            name: 'name',
            label: 'Name der PE',
            options: {
                customBodyRenderLite: (dataIndex: number) => (
                    <LinkEinrichtung
                        id={data['hydra:member'][dataIndex].id}
                        name={data['hydra:member'][dataIndex].name}
                    />
                ),
            },
        };

        const ort: MUIDataTableColumnDef = {
            name: 'ort',
            label: 'Ort',
        };

        const art: MUIDataTableColumnDef = {
            name: 'art',
            label: 'Art',
            options: {
                customBodyRender: (value: string): string => EINRICHTUNG_ART_SHORT_LABELS[value] || value,
            },
        };

        const status: MUIDataTableColumnDef = {
            name: 'status',
            label: 'Status',
            options: {
                customBodyRender: (value: string): string => EINRICHTUNG_STATUS_LABELS[value] || value,
            },
        };

        const zustaendigerBenutzer: MUIDataTableColumnDef = {
            name: 'zustaendigerBenutzer',
            label: 'Zuständig',
            options: {
                customBodyRender: (benutzer: BenutzerJsonldEinrichtungRead) =>
                    displayBenutzerName(benutzer as DisplayBenutzer),
            },
        };

        const einrichtungBenutzer: MUIDataTableColumnDef = {
            name: 'einrichtungBenutzer',
            label: 'Nutzer',
            options: {
                customBodyRenderLite: (index) => einrichtungListLinkRender(data, index, 'benutzer'),
                ...rightAlignedColumn,
            },
        };

        return [aktenzeichen, name, art, ort, status, zustaendigerBenutzer, einrichtungBenutzer];
    }, []);
};
