import * as React from 'react';
import { useCallback } from 'react';
import { CancelToken } from 'axios';

import { backendApiService } from 'api/ApiService';
import { BenutzerJsonldZustaendigkeitRead } from 'api/client';
import { SelectOption, Typeahead } from 'forms/components/Typeahead';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';

import { RowControl } from './RowControl';

const BenutzerAutocompleteControlComponent: React.FC<CustomControlProps<SelectOption | null>> = ({
    data,
    path,
    label,
    required,
    handleChange,
    schema,
    config,
    showFieldNumberLabels,
    gridLayout,
    id,
}) => {
    const handleValueChange = useCallback(
        (id: SelectOption | null) => {
            handleChange(path, id, true);
        },
        [handleChange, path]
    );

    const getData = (value: string, cancelToken: CancelToken): Promise<any> => {
        return backendApiService.getBenutzerCollection({
            page: 1,
            itemsPerPage: 10,
            rolle2: schema?.custom?.rollen,
            landschaftsverband: schema?.custom?.landschaftsverband,
            qSearch: [value],
            options: { cancelToken: cancelToken },
        });
    };

    const getOption = (item: BenutzerJsonldZustaendigkeitRead): SelectOption => {
        return {
            id: item.id as number,
            name: `${item.vorname} ${item.nachname}`,
        };
    };

    return (
        <RowControl
            name={path}
            label={label}
            required={required}
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={gridLayout}
            labelForId={id}
            config={config}
            paths={[path]}
        >
            <Typeahead
                id={id}
                data={data}
                schema={schema}
                path={path}
                multiple={false}
                getData={getData}
                getOption={getOption}
                onChange={handleValueChange}
            />
        </RowControl>
    );
};

export const BenutzerAutocompleteControl = withCustomControlProps(BenutzerAutocompleteControlComponent);
