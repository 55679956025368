import { rankWith, Tester } from '@jsonforms/core';
import { JsonSchema } from '@jsonforms/core/src/models/jsonSchema';
import { UISchemaElement } from '@jsonforms/core/src/models/uischema';

import { getFieldSchemaByScope } from 'forms/utils/SchemaUtils';

export const isTextAreaEinzeilig: Tester = (uischema: UISchemaElement | any, schema: JsonSchema | any): boolean => {
    if (uischema?.type !== 'Control') return false;

    const field = getFieldSchemaByScope(uischema.scope, schema);

    return (
        (field as any)?.type.toLowerCase() === 'string' &&
        (field as any)?.custom?.block_prefixes?.includes('freitext_area_einzeilig')
    );
};

export const TextAreaEinzeiligTester = rankWith(5000, isTextAreaEinzeilig);
