import { ReactElement } from 'react';
import { Person } from '@mui/icons-material';
import { styled, Tooltip } from '@mui/material';

import { DeaktiviertByBenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles } from 'api/client';
import { BenutzerResponse } from 'api/types';
import { formatDateToString } from 'forms/utils/formatter';
import { displayBenutzerName } from 'utilities';

interface BenutzerListStatusIconProps {
    benutzer: BenutzerResponse;
}

export const BenutzerListStatusIcon = ({
    benutzer: { deaktiviertAt, deaktiviertByBenutzer, createdAt },
}: BenutzerListStatusIconProps): ReactElement => {
    if (deaktiviertAt && deaktiviertByBenutzer) {
        const date = formatDateToString(deaktiviertAt);
        const name = displayDeaktiviertByBenutzerName(deaktiviertByBenutzer);
        const tooltip = `Am ${date} ${name ? `von ${name} ` : ''}deaktiviert.`;
        return (
            <Tooltip title={tooltip}>
                <StatusDeactivated />
            </Tooltip>
        );
    }

    const date = formatDateToString(createdAt);
    const tooltip = `Am ${date} erstellt.`;
    return (
        <Tooltip title={tooltip}>
            <StatusActive />
        </Tooltip>
    );
};

const StatusActive = styled(Person)(() => ({
    color: 'green',
}));

const StatusDeactivated = Person;

const displayDeaktiviertByBenutzerName = ({
    vorname,
    nachname,
}: DeaktiviertByBenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles): string | null => {
    if (!vorname || !nachname) return null;
    return displayBenutzerName({ vorname, nachname });
};
