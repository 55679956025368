import React, { SyntheticEvent, useEffect, useState } from 'react';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { CardActions, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';

import { EinrichtungJsonldEinrichtungRead } from 'api/client';
import {
    EinrichtungResponse,
    FestsetzungsAntragCollectionResponse,
    FeststellungsAntragCollectionResponse,
} from 'api/types';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { ACTIONS } from 'constants/antragActions';
import { FormButton } from 'forms/components/FormButton';
import { ErrorList } from 'forms/controls/components/ErrorList';
import { ComponentError } from 'forms/hooks/useErrors';
import { FormStateChange } from 'forms/types/UiSchemaTypes';
import {
    AntragsAssistentFormDataInterface,
    DashboardAnbieterAssistentStepsForm,
} from 'pages/Dashboard/Anbieter/DashboardAnbieterAssistentStepsForm';
import { useAntragHelper } from 'pages/Dashboard/Anbieter/useAntragHelper';
import { displayBenutzerName } from 'utilities';

interface AntragsassistentPropsInterface {
    einrichtung: EinrichtungResponse;
    feststellungsantraege: FeststellungsAntragCollectionResponse[] | undefined;
    festsetzungsantraege: FestsetzungsAntragCollectionResponse[] | undefined;
}

interface Questions {
    [key: string]: React.ReactNode;
}

const steps = [
    'platzzahl',
    'nettoraumflaeche',
    'grunstuecksflaeche',
    'modernisierung',
    'salg',
    'eigentumsverhaeltnisse',
];

const questions: Questions = {
    platzzahl: (
        <>
            <Typography paragraph>
                Hat sich die Platzzahl Ihrer Einrichtung im Vergleich zur bestehenden Feststellung in mindestens einer
                der folgenden Arten geändert:
            </Typography>
            <ol type="a">
                <li>Erhöhung oder Reduzierung der Platzzahl</li>
                <li>Erhöhung oder Reduzierung der eingestreuten Kurzzeitpflegeplätze</li>
                <li>Erhöhung oder Reduzierung der separaten Kurzzeitpflegeplätze</li>
            </ol>
        </>
    ),
    nettoraumflaeche: (
        <Typography paragraph>
            Hat sich die tatsächliche Nettoraumfläche Ihrer Einrichtung zur bestehenden Feststellung geändert?
        </Typography>
    ),
    grunstuecksflaeche: (
        <Typography paragraph>
            Hat sich die tatsächliche Grundstücksfläche der Einrichtung und/oder der Grundstückseigentümer verändert
            (z.B. Erbpacht)?
        </Typography>
    ),
    modernisierung: (
        <Typography paragraph>
            Haben Sie als Eigentümer eine neue Modernisierungsmaßnahme (Umbau) durchgeführt, für die Sie Aufwand
            beantragen möchten? Haben Sie als Mieter eine Modernisierung nach § 8 Absatz 12 APG DVO durchgeführt oder
            hat Ihr Vermieter eine gesetzlich-zwingende Modernisierung nach § 8 Absatz 6 durchgeführt? (Hinweis: es sind
            nur neue Maßnahmen gemeint, die nicht von der bestehenden Feststellung umfasst sind)
        </Typography>
    ),
    salg: (
        <Typography paragraph>
            Haben Sie den Bestand an sonstigen Anlagegütern durch Ersatzbeschaffungen im Sinne des § 4 Absatz 7 APG DVO
            erweitert? (Hinweis: gemeint sind hier keine Ersatzbeschaffungen für bereits vorhandenes sonstiges
            Anlagevermögen oder Bestandsplätze, sondern ausschließlich Aufwendungen nach § 4 Absatz 7, insbesondere bei
            Ausweitung der Platzzahl oder veränderter gesetzlicher Vorgaben)
        </Typography>
    ),
    eigentumsverhaeltnisse: (
        <Typography paragraph>
            Haben sich die Eigentumsverhältnisse des langfristigen Anlagevermögens geändert (z.B. Wechsel in Miete oder
            Eigentum)?
        </Typography>
    ),
};

export const DashboardAnbieterAssistentSteps: React.FC<AntragsassistentPropsInterface> = ({
    einrichtung,
    feststellungsantraege,
    festsetzungsantraege,
}) => {
    const { lastFeststellungsantrag, lastFestsetzungsantrag, goToFeststellungsantrag, goToFestsetzungsantrag } =
        useAntragHelper(einrichtung, feststellungsantraege, festsetzungsantraege);
    const [formData, setFormData] = useState<AntragsAssistentFormDataInterface | null>(null);
    const [step, setStep] = useState<string>(steps[0]);
    const [currentEinrichtung, setCurrentEinrichtung] = useState<EinrichtungJsonldEinrichtungRead>(einrichtung);
    const [errors, setErrors] = useState<ComponentError[]>([]);
    const [message, setMessage] = useState<string | undefined>();
    const [antragstyp, setAntragstyp] = useState<string | undefined>();

    useEffect(() => {
        if (einrichtung !== currentEinrichtung) {
            setStep(steps[0]);
            setCurrentEinrichtung(einrichtung);
            setErrors([]);
        }
    }, [einrichtung, currentEinrichtung]);

    const handleChange = (state: FormStateChange) => {
        setFormData(state.data);
    };

    const handleBackButtonOnClick = () => {
        if (antragstyp) {
            setAntragstyp(undefined);
            setMessage(undefined);
        } else {
            const previousStep = steps[steps.indexOf(step) - 1];
            if (previousStep) {
                setStep(previousStep);
                setErrors([]);
                setAntragstyp(undefined);
                setMessage(undefined);
            }
        }
    };

    const handleZumAntragButtonClick = () => {
        switch (antragstyp) {
            case 'feststellungsantrag':
                goToFeststellungsantrag();
                break;
            case 'festsetzungsantrag':
                goToFestsetzungsantrag();
                break;
        }
    };

    const handleSubmit = () => {
        setErrors([]);
        setAntragstyp(undefined);
        setMessage(undefined);

        const ansprechpartnerInfo = determineAnsprechpartnerInfo(einrichtung);

        if (!formData) {
            setErrors([
                {
                    path: 'answer',
                    message: `Bitte beantworten Sie die Frage.`,
                },
            ]);
        } else if (formData.answer) {
            if (
                lastFeststellungsantrag &&
                !lastFeststellungsantrag.allowedWorkflowActions?.includes(ACTIONS.FOLGEANTRAG) &&
                !lastFeststellungsantrag.allowedWorkflowActions?.includes(ACTIONS.EDIT)
            ) {
                setErrors([
                    {
                        path: 'answer',
                        message: `Ihr Feststellungsantrag ist beim Landschaftsverband
                        aktuell in Bearbeitung. Bitte warten Sie auf Ihren Bescheid oder
                        nehmen Sie Kontakt mit Ihrem zuständigen Sachbearbeiter auf. ${ansprechpartnerInfo}`,
                    },
                ]);
            } else {
                setAntragstyp('feststellungsantrag');
                setMessage(
                    `Da Sie eine Änderung in den Berechnungsgrundlagen Ihrer Einrichtung angegeben haben,
                    können Sie diese im Folgenden nach § 11 Absatz 3 APG DVO in einem neuen Feststellungsantrag
                    beantragen. Im Anschluss können Sie die Festsetzung für den entsprechenden Zeitraum beantragen.`
                );
            }
        } else {
            const nextStep = steps[steps.indexOf(step) + 1];
            if (nextStep) {
                setStep(nextStep);
                setFormData(null);
            } else {
                if (
                    lastFestsetzungsantrag &&
                    !lastFestsetzungsantrag.allowedWorkflowActions?.includes(ACTIONS.FOLGEANTRAG) &&
                    !lastFestsetzungsantrag.allowedWorkflowActions?.includes(ACTIONS.EDIT)
                ) {
                    setErrors([
                        {
                            path: 'answer',
                            message: `Ihr Festsetzungsantrag ist beim Landschaftsverband aktuell in Bearbeitung.
                            Bitte warten Sie auf Ihren Bescheid oder nehmen Sie Kontakt mit Ihrem
                            zuständigen Sachbearbeiter auf. ${ansprechpartnerInfo}`,
                        },
                    ]);
                } else {
                    setAntragstyp('festsetzungsantrag');
                    setMessage(
                        `Da Sie alle 6 Fragen mit „nein“ beantwortet haben, ist es ausreichend, wenn Sie einen
                        Festsetzungsantrag stellen. Dieser wird Ihnen im Folgenden angezeigt. Es werden die prospektiv
                        im neuen Festsetzungszeitraum anfallenden Aufwendungen abgefragt. Hierin können Sie zudem
                        mögliche Änderungen in der Finanzierung, den Zimmerkategorien oder den Öffnungstagen
                        (bei Tagespflegen) erfassen.`
                    );
                }
            }
        }
    };

    const handleChangeStep = (event: SyntheticEvent, newStep: string) => {
        setStep(newStep);
        setErrors([]);
        setAntragstyp(undefined);
        setMessage(undefined);
    };

    return (
        <div id="antragsassistent">
            <AppLoaderContainer isLoading={!feststellungsantraege || !festsetzungsantraege}>
                <TabContext value={step}>
                    <TabList onChange={handleChangeStep} variant="fullWidth">
                        {steps.map((value, index) => (
                            <Tab
                                key={value}
                                style={{ minWidth: 50 }}
                                label={'Frage ' + (index + 1)}
                                value={value}
                                disabled={steps.indexOf(step) < index}
                            />
                        ))}
                    </TabList>
                    <TabPanel value={step}>
                        {!antragstyp ? (
                            <>
                                {questions[step]}
                                <DashboardAnbieterAssistentStepsForm
                                    step={step}
                                    onChange={handleChange}
                                    formData={formData}
                                />
                            </>
                        ) : (
                            <Typography paragraph color="primary">
                                {message}
                            </Typography>
                        )}
                        <ErrorList errors={errors} />
                    </TabPanel>
                </TabContext>
                <CardActions>
                    {(step !== steps[0] || antragstyp) && (
                        <FormButton type="button" variant="outlined" onClick={handleBackButtonOnClick}>
                            Zurück
                        </FormButton>
                    )}
                    <FormButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={!antragstyp ? handleSubmit : handleZumAntragButtonClick}
                    >
                        {!antragstyp ? 'Weiter' : 'Zum Antrag'}
                    </FormButton>
                </CardActions>
            </AppLoaderContainer>
        </div>
    );
};

const determineAnsprechpartnerInfo = ({ zustaendigerBenutzer, landschaftsverband }: EinrichtungResponse): string => {
    const lvName = landschaftsverband?.name;
    const phone = zustaendigerBenutzer?.telefon;
    const email = zustaendigerBenutzer?.email;
    const name = displayBenutzerName(zustaendigerBenutzer);

    const part1 = lvName
        ? `Für Rückfragen steht Ihnen Ihr Ansprechpartner des ${lvName} zur Verfügung.`
        : 'Für Rückfragen steht Ihnen Ihr Ansprechpartner zur Verfügung.';

    const part2 = zustaendigerBenutzer ? ` Name: ${name} | Tel.: ${phone} | E-Mail: ${email}` : '';

    return part1 + part2;
};
