import React from 'react';

import { InvestPathBuilder } from 'navigation/Paths';

import { Link } from './Link';

export const LinkSupport: React.FC = () => {
    return (
        <Link to={InvestPathBuilder.home.hilfe.path} target={'_blank'}>
            Support
        </Link>
    );
};
