import React from 'react';
import { Grid, GridSize, Typography } from '@mui/material';

import { NoSeparatorGroup } from 'forms/controls/GroupControl';
import { useJsonFormsState } from 'forms/hooks/useJsonFormsState';
import { GridLayoutConfig } from 'forms/types/UiSchemaTypes';

import { useGroupFormHeaders } from './hooks/useGroupFormHeaders';
import { HighlightedGrid } from './InlineGroupRowControl';

interface GroupFormHeaderComponentType {
    collectionScope: string;
    header: string | undefined;
    data: any;
    gridLayout?: GridLayoutConfig;
    noSpacing?: boolean;
}

export const GroupFormHeaderComponent: React.FC<GroupFormHeaderComponentType> = ({
    header,
    collectionScope,
    data,
    gridLayout,
}) => {
    const { config } = useJsonFormsState();
    const groupFormHeaders = useGroupFormHeaders(data, collectionScope, header);
    const gridLayoutConfig = gridLayout || config.gridLayout;
    return (
        <NoSeparatorGroup>
            <Grid container spacing={1} data-cy={'GroupFormHeader'}>
                <Grid item xs={gridLayoutConfig?.fieldnumber || 1} />
                <Grid item xs={gridLayoutConfig?.label || 3} />
                <Grid item xs={gridLayoutConfig?.input || 3}>
                    <Grid container spacing={2}>
                        {groupFormHeaders.map((header, i) => {
                            const GridItem = i % 2 === 0 ? Grid : HighlightedGrid;

                            return (
                                <GridItem item xs={(12 / groupFormHeaders.length) as GridSize} key={i}>
                                    <Typography variant="h3" align="center">
                                        {header}
                                    </Typography>
                                </GridItem>
                            );
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </NoSeparatorGroup>
    );
};
