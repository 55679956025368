import { RefObject, useCallback, useEffect, useRef } from 'react';

export const useScrollInView = <T extends HTMLElement>(ref?: RefObject<T>) => {
    const localRef = useRef<T>(null);

    const getRef = useCallback((): RefObject<T> => {
        return ref || localRef;
    }, [ref]);

    const createObserver = useCallback(
        (rootMargin: string) => {
            const observer = new IntersectionObserver(
                ([entry]) => {
                    if (getRef().current) {
                        observer.unobserve(getRef().current!);
                    }
                    if (entry.isIntersecting) {
                        return;
                    }
                    getRef().current?.scrollIntoView({
                        block: 'center',
                        behavior: 'smooth',
                    });
                },
                {
                    rootMargin,
                }
            );
            return observer;
        },
        [getRef]
    );

    const scrollIntoView = useCallback(
        (rootMargin: string) => {
            const bottomObserver = createObserver(rootMargin);
            if (getRef().current) {
                bottomObserver.observe(getRef().current!);
            }
        },
        [getRef, createObserver]
    );

    const getRefElementAndOffset = useCallback((): {
        refElement?: HTMLElement;
        rootMargin?: string;
    } => {
        if (!getRef().current) {
            return {};
        }
        const inputRef =
            getRef().current?.tagName?.toLowerCase() === 'input'
                ? getRef().current
                : getRef().current?.getElementsByTagName('input')[0];

        const textareaRef =
            getRef().current?.tagName?.toLowerCase() === 'textarea'
                ? getRef().current
                : getRef().current?.getElementsByTagName('textarea')[0];
        return (
            (inputRef && { refElement: inputRef, rootMargin: '-300px' }) ||
            (textareaRef && {
                refElement: textareaRef,
                rootMargin: '-300px',
            }) ||
            {}
        );
    }, [getRef]);

    useEffect(() => {
        const { refElement, rootMargin } = getRefElementAndOffset();
        if (!refElement || !rootMargin) {
            return;
        }
        const callback = () => scrollIntoView(rootMargin);
        refElement?.addEventListener('focus', callback);
        return () => refElement?.removeEventListener('focus', callback);
    }, [scrollIntoView, getRefElementAndOffset]);

    return getRef();
};
