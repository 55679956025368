import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { noop } from 'lodash';

import {
    AngemessenheitsgrenzeApi,
    AngemessenheitsgrenzeJsonld,
    BaupreisindexApi,
    BaupreisindexJsonld,
    BenutzerApi,
    BenutzerBenutzerGetAllowedCreateRollenOutputJsonldBenutzerGetAllowedCreateRollen,
    BenutzerCredentialChangeConfirmInput,
    BenutzerCredentialChangeRequestInput,
    BenutzerJsonldBenutzerCreate,
    BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles,
    BenutzerProfileEdit,
    BenutzerTablePreference,
    BenutzerTablePreferenceApi,
    Credentials,
    EigenkapitalzinsenBundesbankApi,
    EigenkapitalzinsP5Abs6APGDVOApi,
    EigenkapitalzinsP5Abs6APGDVOJsonld,
    EinrichtungApi,
    EinrichtungBenutzerApi,
    EinrichtungEinrichtungEdit,
    EinrichtungJsonldEinrichtungRead,
    EinrichtungJsonldEinrichtungZustaendigerBenutzer,
    FestsetzungsantragApi,
    FestsetzungsantragBescheidungApi,
    FestsetzungsantragGegenpruefungApi,
    FestsetzungsantragGegenpruefungGegenpruefungEdit,
    FestsetzungsantragJsonldFestsetzungsantragCreate,
    FestsetzungsantragVersionApi,
    FestsetzungsantragZurueckziehenApi,
    FeststellungsantragApi,
    FeststellungsantragBescheidungApi,
    FeststellungsantragGegenpruefungApi,
    FeststellungsantragGegenpruefungGegenpruefungEdit,
    FeststellungsantragVersionApi,
    FeststellungsantragZurueckziehenApi,
    FremdkapitalzinsenBundesbankApi,
    InvestorenmodellApi,
    InvestorenmodellBescheidungApi,
    InvestorenmodellGegenpruefungApi,
    InvestorenmodellVersionApi,
    InvestorenmodellZurueckziehenApi,
    LandschaftsverbandApi,
    LoginLinkApi,
    MassenmailApi,
    MassenmailJsonldMassenmailRead,
    MassenmailJsonldMassenmailWrite,
    MeldungP29Api,
    RuhendstellungApi,
    RuhendstellungJsonldRuhendstellungCreate,
    TokenApi,
    TokenRefreshApi,
    TraegerApi,
    TraegerverbandApi,
    ZustaendigkeitApi,
    ZustaendigkeitJsonldZustaendigkeitRead,
    ZustaendigkeitJsonldZustaendigkeitWrite,
} from 'api/client';
import { FileUploadResult } from 'api/responses/FileUploadResponse';
import {
    InlineResponseAngemessenheitsgrenzeJsonld,
    InlineResponseBaupreisindexJsonld,
    InlineResponseEigenkapitalzinsenBundesbankJsonld,
    InlineResponseEigenkapitalzinsP5Abs6APGDVOJsonld,
    InlineResponseFremdkapitalzinsenBundesbankJsonld,
    InlineResponseZustaendigkeitJsonldZustaendigkeitRead,
} from 'api/responses/InlineResponse';
import {
    URL_BENUTZER,
    URL_EINRICHTUNG,
    URL_FESTSTELLUNGSANTRAG,
    URL_LANDSCHAFTSVERBAND,
    URL_TRAEGER,
    URL_TRAEGERVERBAND,
    URL_UPLOAD,
} from 'api/routes';
import {
    AntragFilterOptionResponse,
    AntragZurueckziehenEntscheidungRequest,
    BenutzerAufgabenResponse,
    BenutzerByEmailResponse,
    BenutzerListResponse,
    BenutzerProfileResponse,
    BenutzerResponse,
    BenutzerTablePreferenceResponse,
    EinrichtungAnbieterPermissionsResponse,
    EinrichtungBenutzerListResponse,
    EinrichtungBenutzerRequest,
    EinrichtungFilterResponse,
    EinrichtungListResponse,
    EinrichtungResponse,
    FestsetzungsAntragCollectionListResponse,
    FestsetzungsAntragDiffResponse,
    FestsetzungsAntragFormResponse,
    FestsetzungsAntragGegenpruefungResponse,
    FestsetzungsAntragResponse,
    FestsetzungsAntragVersionCollectionListResponse,
    FestsetzungsAntragVersionResponse,
    FeststellungsAntragCollectionListResponse,
    FeststellungsAntragDiffResponse,
    FeststellungsAntragFormResponse,
    FeststellungsAntragGegenpruefungResponse,
    FeststellungsAntragRequest,
    FeststellungsAntragResponse,
    FeststellungsAntragVersionCollectionListResponse,
    FeststellungsAntragVersionResponse,
    GetAntragVersionenOptions,
    GetBenutzerListOptions,
    GetEinrichtungListOptions,
    GetFestsetzungsAntragOptions,
    GetFeststellungsAntragOptions,
    GetInvestorenmodellOptions,
    GetMeldungP29AntragOptions,
    GetTraegerListOptions,
    GetTraegerverbandListOptions,
    GetZustaendigkeitListOptions,
    InvestorenmodellCollectionListResponse,
    InvestorenmodellDiffResponse,
    InvestorenmodellFormResponse,
    InvestorenmodellGegenpruefungRequest,
    InvestorenmodellGegenpruefungResponse,
    InvestorenmodellRequest,
    InvestorenmodellResponse,
    InvestorenmodellVersionCollectionListResponse,
    InvestorenmodellVersionResponse,
    LandschaftsverbandListResponse,
    LandschaftsverbandResponse,
    LvAufgabenResponse,
    MassenmailListResponse,
    MassenmailResponse,
    MeldungP29CollectionListResponse,
    MeldungP29FormResponse,
    MeldungP29Request,
    MeldungP29Response,
    PutBenutzer,
    RuhendstellungRequest,
    Token,
    TraegerListResponse,
    TraegerResponse,
    TraegerverbandListResponse,
    TraegerverbandResponse,
} from 'api/types';
import { toIriId } from 'utilities';

export const BACKEND_API_BASE_URL = process.env.REACT_APP_API_URL as string;

const createAxiosClient = () =>
    axios.create({
        baseURL: BACKEND_API_BASE_URL,
    });

export class ApiService {
    private readonly client: AxiosInstance;
    private jwtToken?: string;
    private jwtRefreshToken?: string;

    constructor() {
        this.client = createAxiosClient();
    }

    public setRequestInterceptor(id: number | null, refreshJwtToken: () => Promise<void>): number {
        if (id) this.client.interceptors.request.eject(id);
        return this.client.interceptors.response.use(
            (res) => res,
            async (error: AxiosError) => {
                if (
                    error.response?.status === 401 &&
                    // @ts-ignore
                    !error.config._retry &&
                    !error.config.url?.includes('/api/token-refresh')
                ) {
                    // @ts-ignore
                    error.config._retry = true;
                    await refreshJwtToken();
                    error.config.headers['X-Authorization'] = `Bearer ${this.jwtToken}`;
                    return this.client(error.config);
                } else {
                    return Promise.reject(error);
                }
            }
        );
    }

    public removeRequestInterceptor(id: number) {
        this.client.interceptors.request.eject(id);
    }

    public setImpersonateUser(email?: string): void {
        if (email) {
            this.client.defaults.headers['x-switch-user'] = email;
        } else {
            delete this.client.defaults.headers['x-switch-user'];
        }
    }

    public setJwtToken(jwtToken?: string, jwtRefreshToken?: string): void {
        this.jwtToken = jwtToken;
        this.jwtRefreshToken = jwtRefreshToken;
        this.client.defaults.headers['X-Authorization'] = jwtToken ? `Bearer ${jwtToken}` : undefined;
    }

    public getToken(credentials: Credentials): Promise<Token> {
        return new TokenApi(undefined, '', this.client)
            .postCredentialsItem(credentials)
            .then((res) => res.data as Token);
    }

    public getRefreshToken(): Promise<Token> {
        return new TokenRefreshApi(undefined, '', this.client)
            .postRefreshTokenItem({ refresh_token: this.jwtRefreshToken })
            .then((res) => res.data as Token);
    }

    public getBenutzerByEmail(email: string): Promise<BenutzerByEmailResponse> {
        return new BenutzerApi(undefined, '', this.client)
            .getByEmailBenutzerItem(email)
            .then((res) => res.data as BenutzerByEmailResponse);
    }

    public getEinrichtungBenutzerCollection(
        page?: number,
        itemsPerPage?: number,
        benutzer?: string,
        benutzer2?: string[],
        einrichtung?: string,
        einrichtung2?: string[],
        options?: AxiosRequestConfig
    ): Promise<EinrichtungBenutzerListResponse> {
        return new EinrichtungBenutzerApi(undefined, '', this.client)
            .getEinrichtungBenutzerCollection(
                page,
                itemsPerPage || 9999,
                benutzer,
                benutzer2,
                einrichtung,
                einrichtung2,
                options
            )
            .then((res) => res.data as EinrichtungBenutzerListResponse);
    }

    public getAllowedCreateRollen(): Promise<BenutzerBenutzerGetAllowedCreateRollenOutputJsonldBenutzerGetAllowedCreateRollen> {
        return new BenutzerApi(undefined, '', this.client).getAllowedCreateRollenBenutzerItem().then((res) => res.data);
    }

    public putEinrichtungZustaendigerBeutzer(
        id: string,
        data: EinrichtungJsonldEinrichtungZustaendigerBenutzer,
        options?: AxiosRequestConfig
    ): Promise<EinrichtungJsonldEinrichtungRead> {
        data.zustaendigerBenutzer = data.zustaendigerBenutzer ? URL_BENUTZER + '/' + data.zustaendigerBenutzer : null;
        return new EinrichtungApi(undefined, '', this.client)
            .setZustaendigerBenutzerEinrichtungItem(id, data, options)
            .then((res) => res.data);
    }

    public putZustaendigkeit(
        id: string,
        data: ZustaendigkeitJsonldZustaendigkeitWrite,
        options?: AxiosRequestConfig
    ): Promise<ZustaendigkeitJsonldZustaendigkeitRead> {
        data.benutzer = data.benutzer ? URL_BENUTZER + '/' + data.benutzer : null;
        return new ZustaendigkeitApi(undefined, '', this.client)
            .putZustaendigkeitItem(id, data, options)
            .then((res) => res.data);
    }

    public async postBenutzer(data: BenutzerJsonldBenutzerCreate, options?: AxiosRequestConfig): Promise<number> {
        data.landschaftsverband = data.landschaftsverband
            ? URL_LANDSCHAFTSVERBAND + '/' + data.landschaftsverband
            : null;
        data.traegerverband = data.traegerverband ? URL_TRAEGERVERBAND + '/' + data.traegerverband : null;
        data.traeger = data.traeger ? URL_TRAEGER + '/' + data.traeger : null;
        const response = await new BenutzerApi(undefined, '', this.client).postBenutzerCollection(data, options);
        return response.data.id as number;
    }

    public async postEinrichtungBenutzer(
        data: EinrichtungBenutzerRequest,
        options?: AxiosRequestConfig
    ): Promise<void> {
        data.benutzer = URL_BENUTZER + '/' + data.benutzer;
        data.einrichtung = URL_EINRICHTUNG + '/' + data.einrichtung;
        await new EinrichtungBenutzerApi(undefined, '', this.client).postEinrichtungBenutzerCollection(data, options);
    }

    public getLoginLinkCheck(user: string, expires: number, hash: string): Promise<Token> {
        return new LoginLinkApi(undefined, '', this.client)
            .getLoginLinkCheckItem({ params: { user, expires, hash } })
            .then((res) => res.data as Token);
    }

    public getLandschaftsverbandCollection(
        page?: number,
        itemsPerPage?: number,
        kuerzel?: string,
        kuerzel2?: string[],
        options?: AxiosRequestConfig
    ): Promise<LandschaftsverbandListResponse> {
        return new LandschaftsverbandApi(undefined, '', this.client)
            .getLandschaftsverbandCollection(page, itemsPerPage || 9999, kuerzel, kuerzel2, options)
            .then((res) => res.data as LandschaftsverbandListResponse);
    }

    public getLandschaftsverbandItem(id: string): Promise<LandschaftsverbandResponse> {
        return new LandschaftsverbandApi(undefined, '', this.client)
            .getLandschaftsverbandItem(id)
            .then((res) => res.data as LandschaftsverbandResponse);
    }

    public getBenutzerItem(id: string): Promise<BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles> {
        return new BenutzerApi(undefined, '', this.client).getBenutzerItem(id).then((res) => res.data);
    }

    public getBaupreisindexCollection(
        page?: number,
        itemsPerPage?: number,
        basisJahr?: number,
        basisJahr2?: number[],
        options?: AxiosRequestConfig
    ): Promise<InlineResponseBaupreisindexJsonld> {
        return new BaupreisindexApi(undefined, '', this.client)
            .getBaupreisindexCollection(page, itemsPerPage || 9999, basisJahr, basisJahr2, options)
            .then((res) => res.data);
    }

    public getBenutzerAufgabenItem(): Promise<BenutzerAufgabenResponse> {
        return new BenutzerApi(undefined, '', this.client).aufgabenBenutzerItem().then((res) => res.data);
    }

    public getLvAufgabenItem(): Promise<LvAufgabenResponse> {
        return new BenutzerApi(undefined, '', this.client).lvAufgabenBenutzerItem().then((res) => res.data);
    }

    public postBaupreisindex(data: BaupreisindexJsonld, options?: AxiosRequestConfig): Promise<BaupreisindexJsonld> {
        return new BaupreisindexApi(undefined, '', this.client)
            .postBaupreisindexCollection(data, options)
            .then((res) => res.data);
    }

    public getEigenkapitalzinsP5Abs6APGDVOCollection(
        page?: number,
        itemsPerPage?: number,
        options?: AxiosRequestConfig
    ): Promise<InlineResponseEigenkapitalzinsP5Abs6APGDVOJsonld> {
        return new EigenkapitalzinsP5Abs6APGDVOApi(undefined, '', this.client)
            .getEigenkapitalzinsP5Abs6APGDVOCollection(page, itemsPerPage || 9999, options)
            .then((res) => res.data);
    }

    public postEigenkapitalzinsP5Abs6APGDVOCollection(
        data: EigenkapitalzinsP5Abs6APGDVOJsonld,
        options?: AxiosRequestConfig
    ): Promise<EigenkapitalzinsP5Abs6APGDVOJsonld> {
        return new EigenkapitalzinsP5Abs6APGDVOApi(undefined, '', this.client)
            .postEigenkapitalzinsP5Abs6APGDVOCollection(data, options)
            .then((res) => res.data);
    }

    public getFremdkapitalzinsenBundesbankCollection(
        page?: number,
        itemsPerPage?: number,
        options?: AxiosRequestConfig
    ): Promise<InlineResponseFremdkapitalzinsenBundesbankJsonld> {
        return new FremdkapitalzinsenBundesbankApi(undefined, '', this.client)
            .getFremdkapitalzinsenBundesbankCollection(page, itemsPerPage || 9999, options)
            .then((res) => res.data);
    }

    public getEigenkapitalzinsenBundesbankCollection(
        page?: number,
        itemsPerPage?: number,
        options?: AxiosRequestConfig
    ): Promise<InlineResponseEigenkapitalzinsenBundesbankJsonld> {
        return new EigenkapitalzinsenBundesbankApi(undefined, '', this.client)
            .getEigenkapitalzinsenBundesbankCollection(page, itemsPerPage || 9999, options)
            .then((res) => res.data);
    }

    public getAngemessenheitsgrenzeCollection(
        page?: number,
        itemsPerPage?: number,
        typ?: string,
        typ2?: string[]
    ): Promise<InlineResponseAngemessenheitsgrenzeJsonld> {
        return new AngemessenheitsgrenzeApi(undefined, '', this.client)
            .getAngemessenheitsgrenzeCollection(page, itemsPerPage || 9999, typ, typ2)
            .then((res) => res.data);
    }

    public postAngemessenheitsgrenze(
        data: AngemessenheitsgrenzeJsonld,
        options?: AxiosRequestConfig
    ): Promise<AngemessenheitsgrenzeJsonld> {
        return new AngemessenheitsgrenzeApi(undefined, '', this.client)
            .postAngemessenheitsgrenzeCollection(data, options)
            .then((res) => res.data);
    }

    public async postFeststellungsantrag(
        data: FeststellungsAntragRequest,
        options?: AxiosRequestConfig
    ): Promise<FestsetzungsAntragResponse> {
        data.einrichtung = data.einrichtung ? URL_EINRICHTUNG + '/' + data.einrichtung : undefined;
        return new FeststellungsantragApi(undefined, '', this.client)
            .postFeststellungsantragCollection(data, options)
            .then((res) => res.data as FestsetzungsAntragResponse);
    }

    public postFestsetzungsantrag(
        data: FestsetzungsantragJsonldFestsetzungsantragCreate,
        options?: AxiosRequestConfig
    ): Promise<FestsetzungsAntragResponse> {
        data.einrichtung = data.einrichtung ? URL_EINRICHTUNG + '/' + data.einrichtung : undefined;
        data.feststellungsantrag = data.feststellungsantrag
            ? URL_FESTSTELLUNGSANTRAG + '/' + data.feststellungsantrag
            : undefined;
        return new FestsetzungsantragApi(undefined, '', this.client)
            .postFestsetzungsantragCollection(data, options)
            .then((res) => res.data as FestsetzungsAntragResponse);
    }

    public getBenutzerCollection({
        page,
        itemsPerPage,
        existsDeaktiviertAt,
        id,
        id2,
        rolle,
        rolle2,
        landschaftsverband,
        landschaftsverband2,
        einrichtung,
        einrichtung2,
        traegerverband,
        traegerverband2,
        qSearch,
        qImpersonateSearch,
        options,
        orderByName,
        orderByDeaktiviertAt,
    }: GetBenutzerListOptions): Promise<BenutzerListResponse> {
        return new BenutzerApi(undefined, '', this.client)
            .getBenutzerCollection(
                page,
                itemsPerPage || 9999,
                existsDeaktiviertAt,
                id,
                id2,
                rolle,
                rolle2,
                landschaftsverband,
                landschaftsverband2,
                einrichtung,
                einrichtung2,
                traegerverband,
                traegerverband2,
                qSearch,
                qImpersonateSearch,
                orderByName,
                orderByDeaktiviertAt,
                options
            )
            .then((res) => res.data as BenutzerListResponse);
    }

    public getTraegerverbandCollection({
        page,
        itemsPerPage,
        id,
        id2,
        qSearch,
        options,
    }: GetTraegerverbandListOptions): Promise<TraegerverbandListResponse> {
        return new TraegerverbandApi(undefined, '', this.client)
            .getTraegerverbandCollection(page, itemsPerPage || 9999, id, id2, qSearch, options)
            .then((res) => res.data as TraegerverbandListResponse);
    }

    public getTraegerverbandItem(id: string): Promise<TraegerverbandResponse> {
        return new TraegerverbandApi(undefined, '', this.client)
            .getTraegerverbandItem(id)
            .then((res) => res.data as TraegerResponse);
    }

    public getTraegerItem(id: string): Promise<TraegerResponse> {
        return new TraegerApi(undefined, '', this.client).getTraegerItem(id).then((res) => res.data as TraegerResponse);
    }

    public getTraegerCollection({
        page,
        itemsPerPage,
        id,
        id2,
        art,
        art2,
        traegerverband,
        traegerverband2,
        qSearch,
        orderByNrwKey,
        orderByName,
        orderByArt,
        orderByTraegerverbandName,
        options,
    }: GetTraegerListOptions): Promise<TraegerListResponse> {
        return new TraegerApi(undefined, '', this.client)
            .getTraegerCollection(
                page,
                itemsPerPage || 9999,
                id,
                id2,
                art,
                art2,
                traegerverband,
                traegerverband2,
                qSearch,
                orderByNrwKey,
                orderByName,
                orderByTraegerverbandName,
                orderByArt,
                options
            )
            .then((res) => res.data as TraegerListResponse);
    }

    public getZustaendigkeitItem(id: string): Promise<ZustaendigkeitJsonldZustaendigkeitRead> {
        return new ZustaendigkeitApi(undefined, '', this.client).getZustaendigkeitItem(id).then((res) => res.data);
    }

    public getEinrichtungItem(id: string): Promise<EinrichtungResponse> {
        return new EinrichtungApi(undefined, '', this.client)
            .getEinrichtungItem(id)
            .then((res) => res.data as EinrichtungResponse);
    }

    public getEinrichtungCollection({
        page,
        itemsPerPage,
        id,
        id2,
        art,
        art2,
        status,
        status2,
        traeger,
        traeger2,
        traegerverband,
        traegerverband2,
        qSearch,
        orderByAktenzeichen,
        orderByNrwKey,
        orderByName,
        orderByOrt,
        orderByArt,
        orderByStatus,
        orderByZustaendigerBenutzer,
        zustaendigerBenutzer,
        anbieterBenutzer,
        createdAtBefore,
        createdAtStrictlyBefore,
        createdAtAfter,
        createdAtStrictlyAfter,
        options,
    }: GetEinrichtungListOptions): Promise<EinrichtungListResponse> {
        return new EinrichtungApi(undefined, '', this.client)
            .getEinrichtungCollection(
                page,
                itemsPerPage || 9999,
                id,
                id2,
                art,
                art2,
                status,
                status2,
                traeger,
                traeger2,
                traegerverband,
                traegerverband2,
                qSearch,
                zustaendigerBenutzer,
                anbieterBenutzer,
                orderByAktenzeichen,
                orderByNrwKey,
                orderByName,
                orderByOrt,
                orderByArt,
                orderByStatus,
                createdAtBefore,
                createdAtStrictlyBefore,
                createdAtAfter,
                createdAtStrictlyAfter,
                orderByZustaendigerBenutzer,
                options
            )
            .then((res) => res.data as EinrichtungListResponse);
    }

    public getZustaendigkeitCollection({
        page,
        itemsPerPage,
        qSearch,
        benutzer,
        benutzer2,
        kreis,
        kreis2,
        orderRegion,
        orderKreis,
        orderOrt,
        orderEinrichtungArt,
        orderBenutzerName,
        options,
    }: GetZustaendigkeitListOptions): Promise<InlineResponseZustaendigkeitJsonldZustaendigkeitRead> {
        return new ZustaendigkeitApi(undefined, '', this.client)
            .getZustaendigkeitCollection(
                page,
                itemsPerPage || 9999,
                qSearch,
                benutzer,
                benutzer2,
                kreis,
                kreis2,
                orderRegion,
                orderKreis,
                orderOrt,
                orderEinrichtungArt,
                orderBenutzerName,
                options
            )
            .then((res) => res.data);
    }

    public getEinrichtungBenutzer(
        page?: number,
        itemsPerPage?: number,
        benutzer?: string,
        benutzer2?: string[],
        einrichtung?: string,
        einrichtung2?: string[],
        options?: AxiosRequestConfig
    ): Promise<EinrichtungBenutzerListResponse> {
        return new EinrichtungBenutzerApi(undefined, '', this.client)
            .getEinrichtungBenutzerCollection(
                page,
                itemsPerPage || 9999,
                benutzer,
                benutzer2,
                einrichtung,
                einrichtung2,
                options
            )
            .then((res) => res.data as EinrichtungBenutzerListResponse);
    }

    public workflowFeststellungsantragItem(
        id: number | string,
        action: string,
        options?: AxiosRequestConfig
    ): Promise<FeststellungsAntragResponse> {
        return new FeststellungsantragApi(undefined, '', this.client)
            .workflowFeststellungsantragItem(Number(id), action, options)
            .then((res) => res.data as FeststellungsAntragResponse);
    }

    public getFeststellungsantragItem(id: string | number): Promise<FeststellungsAntragResponse> {
        return new FeststellungsantragApi(undefined, '', this.client)
            .getFeststellungsantragItem(String(id))
            .then((res) => res.data as FeststellungsAntragResponse);
    }

    public getFeststellungsantragCollection({
        page,
        itemsPerPage,
        qSearch,
        zustaendigerBenutzer,
        status,
        status2,
        einrichtung,
        einrichtung2,
        berechnungsart,
        antragJahr,
        aufgabe,
        orderZuletztEingereichtAt,
        orderEinrichtungAktenzeichen,
        orderEinrichtungName,
        orderStatus,
        orderEinrichtungZustaendigerBenutzer,
        orderField102,
        orderBerechnungsart,
        options,
    }: GetFeststellungsAntragOptions): Promise<FeststellungsAntragCollectionListResponse> {
        return new FeststellungsantragApi(undefined, '', this.client)
            .getFeststellungsantragCollection(
                page,
                itemsPerPage || 9999,
                qSearch,
                zustaendigerBenutzer,
                status,
                status2,
                einrichtung ? String(einrichtung) : undefined,
                einrichtung2,
                berechnungsart,
                antragJahr,
                aufgabe,
                orderZuletztEingereichtAt,
                orderEinrichtungAktenzeichen,
                orderEinrichtungName,
                orderStatus,
                orderEinrichtungZustaendigerBenutzer,
                orderField102,
                orderBerechnungsart,
                options
            )
            .then((res) => res.data as FeststellungsAntragCollectionListResponse);
    }

    public deleteFeststellungsantragItem(id: string | number): Promise<void> {
        return new FeststellungsantragApi(undefined, '', this.client)
            .deleteFeststellungsantragItem(String(id))
            .then(noop);
    }

    public getFestsetzungsantragCollection({
        page,
        itemsPerPage,
        qSearch,
        zustaendigerBenutzer,
        status,
        status2,
        einrichtung,
        einrichtung2,
        aufgabe,
        orderZuletztEingereichtAt,
        orderField500,
        orderEinrichtungAktenzeichen,
        orderFeststellungsantragBerechnungsart,
        orderEinrichtungName,
        orderStatus,
        orderEinrichtungZustaendigerBenutzer,
        berechnungsart,
        antragJahr,
        options,
    }: GetFestsetzungsAntragOptions): Promise<FestsetzungsAntragCollectionListResponse> {
        return new FestsetzungsantragApi(undefined, '', this.client)
            .getFestsetzungsantragCollection(
                page,
                itemsPerPage || 9999,
                qSearch,
                zustaendigerBenutzer,
                status,
                status2,
                einrichtung ? String(einrichtung) : undefined,
                einrichtung2,
                berechnungsart,
                antragJahr,
                aufgabe,
                orderZuletztEingereichtAt,
                orderEinrichtungAktenzeichen,
                orderEinrichtungName,
                orderStatus,
                orderEinrichtungZustaendigerBenutzer,
                orderField500,
                orderFeststellungsantragBerechnungsart,
                options
            )
            .then((res) => res.data as FestsetzungsAntragCollectionListResponse);
    }

    public workflowFestsetzungsantragItem(
        id: number | string,
        action: string,
        options?: AxiosRequestConfig
    ): Promise<FestsetzungsAntragResponse> {
        return new FestsetzungsantragApi(undefined, '', this.client)
            .workflowFestsetzungsantragItem(Number(id), action, options)
            .then((res) => res.data as FestsetzungsAntragResponse);
    }

    public getFestsetzungsantragItem(id: string | number): Promise<FestsetzungsAntragResponse> {
        return new FestsetzungsantragApi(undefined, '', this.client)
            .getFestsetzungsantragItem(String(id))
            .then((res) => res.data as FestsetzungsAntragResponse);
    }

    public deleteFestsetzungsantragItem(id: string | number): Promise<void> {
        return new FestsetzungsantragApi(undefined, '', this.client)
            .deleteFestsetzungsantragItem(String(id))
            .then(noop);
    }

    public getFestsetzungsantragJson(
        id: string,
        step: string,
        objectType?: string,
        uuid?: string,
        options?: AxiosRequestConfig
    ): Promise<FestsetzungsAntragFormResponse> {
        return new FestsetzungsantragApi(undefined, '', this.client)
            .jsonFormGetFestsetzungsantragItem(id, step, objectType, uuid, {
                ...options,
                headers: { Accept: 'application/json', ...options?.headers },
            })
            .then((res) => res.data as FestsetzungsAntragFormResponse);
    }

    public getFeststellungsantragJson(
        id: string,
        step: string,
        objectType?: string,
        uuid?: string,
        options?: AxiosRequestConfig
    ): Promise<FeststellungsAntragFormResponse> {
        return new FeststellungsantragApi(undefined, '', this.client)
            .jsonFormGetFeststellungsantragItem(id, step, objectType, uuid, {
                ...options,
                headers: { Accept: 'application/json', ...options?.headers },
            })
            .then((res) => res.data as FeststellungsAntragFormResponse);
    }

    public deleteFeststellungsantragObjectTypeItem(
        id: string,
        uuid: string,
        options?: AxiosRequestConfig
    ): Promise<void> {
        return new FeststellungsantragApi(undefined, '', this.client)
            .jsonFormDeleteFeststellungsantragItem(id, uuid, {
                ...options,
                headers: { Accept: 'application/json', ...options?.headers },
            })
            .then((res) => res.data);
    }

    public deleteFestsetzungsantragObjectTypeItem(
        id: string,
        uuid: string,
        options?: AxiosRequestConfig
    ): Promise<void> {
        return new FestsetzungsantragApi(undefined, '', this.client)
            .jsonFormDeleteFestsetzungsantragItem(id, uuid, {
                ...options,
                headers: { Accept: 'application/json', ...options?.headers },
            })
            .then((res) => res.data);
    }

    public postFeststellungsantragJson(
        id: string,
        step: string,
        data: object,
        objectType?: string,
        uuid?: string,
        persist?: boolean,
        fields?: string[],
        recalculateFields?: boolean,
        options?: AxiosRequestConfig
    ): Promise<FeststellungsAntragFormResponse> {
        return new FeststellungsantragApi(undefined, '', this.client)
            .jsonFormPatchFeststellungsantragItem(
                id,
                data,
                step,
                objectType,
                uuid,
                persist,
                fields,
                recalculateFields,
                {
                    ...options,
                    headers: {
                        Accept: 'application/json',
                        ...options?.headers,
                    },
                }
            )
            .then((res) => res.data as FeststellungsAntragFormResponse);
    }

    public postFestsetzungsantragJson(
        id: string,
        step: string,
        data: object,
        objectType?: string,
        uuid?: string,
        persist?: boolean,
        fields?: string[],
        recalculateFields?: boolean,
        options?: AxiosRequestConfig
    ): Promise<FestsetzungsAntragFormResponse> {
        return new FestsetzungsantragApi(undefined, '', this.client)
            .jsonFormPatchFestsetzungsantragItem(id, data, step, objectType, uuid, persist, fields, recalculateFields, {
                ...options,
                headers: {
                    Accept: 'application/json',
                    ...options?.headers,
                },
            })
            .then((res) => res.data as FestsetzungsAntragFormResponse);
    }

    public getMeldungP29Collection({
        page,
        itemsPerPage,
        qSearch,
        zustaendigerBenutzer,
        status,
        status2,
        einrichtung,
        einrichtung2,
        meldedatum,
        orderEingereichtAt,
        orderEinrichtungAktenzeichen,
        orderEinrichtungName,
        orderStatus,
        orderGueltigAb,
        orderMeldedatum,
        orderEinrichtungZustaendigerBenutzerName,
        aufgabe,
        options,
    }: GetMeldungP29AntragOptions): Promise<MeldungP29CollectionListResponse> {
        return new MeldungP29Api(undefined, '', this.client)
            .getMeldungP29Collection(
                page,
                itemsPerPage,
                qSearch,
                zustaendigerBenutzer,
                status,
                status2,
                einrichtung ? String(einrichtung) : undefined,
                einrichtung2,
                orderEingereichtAt,
                orderEinrichtungAktenzeichen,
                orderEinrichtungName,
                orderStatus,
                orderMeldedatum,
                orderGueltigAb,
                orderEinrichtungZustaendigerBenutzerName,
                meldedatum,
                aufgabe,
                options
            )
            .then((res) => res.data as MeldungP29CollectionListResponse);
    }

    public getMeldungP29Item(id: string | number): Promise<MeldungP29Response> {
        return new MeldungP29Api(undefined, '', this.client)
            .getMeldungP29Item(String(id))
            .then((res) => res.data as MeldungP29Response);
    }

    public deleteMeldungP29Item(id: string | number): Promise<void> {
        return new MeldungP29Api(undefined, '', this.client).deleteMeldungP29Item(String(id)).then(noop);
    }

    public workflowMeldungP29Item(
        id: number | string,
        action: string,
        options?: AxiosRequestConfig
    ): Promise<MeldungP29Response> {
        return new MeldungP29Api(undefined, '', this.client)
            .workflowMeldungP29Item(Number(id), action, options)
            .then((res) => res.data as MeldungP29Response);
    }

    public postMeldungP29Json(
        id: string,
        step: string,
        data: object,
        objectType?: string,
        uuid?: string,
        persist?: boolean,
        fields?: string[],
        recalculateFields?: boolean,
        options?: AxiosRequestConfig
    ): Promise<MeldungP29FormResponse> {
        return new MeldungP29Api(undefined, '', this.client)
            .jsonFormPatchMeldungP29Item(id, data, step, objectType, uuid, persist, fields, recalculateFields, {
                ...options,
                headers: {
                    Accept: 'application/json',
                    ...options?.headers,
                },
            })
            .then((res) => res.data as MeldungP29FormResponse);
    }

    public postMeldungP29(einrichtungId: string, options?: AxiosRequestConfig): Promise<MeldungP29Response> {
        const request: MeldungP29Request = { einrichtung: toIriId(URL_EINRICHTUNG, einrichtungId) };
        return new MeldungP29Api(undefined, '', this.client)
            .postMeldungP29Collection(request, options)
            .then((res) => res.data as MeldungP29Response);
    }

    public deleteMeldungP29ObjectTypeItem(id: string, uuid: string, options?: AxiosRequestConfig): Promise<void> {
        return new MeldungP29Api(undefined, '', this.client)
            .jsonFormDeleteMeldungP29Item(id, uuid, {
                ...options,
                headers: { Accept: 'application/json', ...options?.headers },
            })
            .then((res) => res.data);
    }

    public getMeldungP29Json(
        id: string,
        step: string,
        objectType?: string,
        uuid?: string,
        options?: AxiosRequestConfig
    ): Promise<MeldungP29FormResponse> {
        return new MeldungP29Api(undefined, '', this.client)
            .jsonFormGetMeldungP29Item(id, step, objectType, uuid, {
                ...options,
                headers: { Accept: 'application/json', ...options?.headers },
            })
            .then((res) => res.data as MeldungP29FormResponse);
    }

    public getFeststellungsantragFilter(options?: AxiosRequestConfig): Promise<AntragFilterOptionResponse> {
        return new FeststellungsantragApi(undefined, '', this.client)
            .filtersFeststellungsantragItem({
                ...options,
                headers: { Accept: 'application/json', ...options?.headers },
            })
            .then((res) => res.data as AntragFilterOptionResponse);
    }

    public getFestsetzungsantragFilter(options?: AxiosRequestConfig): Promise<AntragFilterOptionResponse> {
        return new FestsetzungsantragApi(undefined, '', this.client)
            .filtersFestsetzungsantragItem({
                ...options,
                headers: { Accept: 'application/json', ...options?.headers },
            })
            .then((res) => res.data as AntragFilterOptionResponse);
    }

    public getInvestorenmodellFilter(options?: AxiosRequestConfig): Promise<AntragFilterOptionResponse> {
        return new InvestorenmodellApi(undefined, '', this.client)
            .filtersInvestorenmodellItem({
                ...options,
                headers: { Accept: 'application/json', ...options?.headers },
            })
            .then((res) => res.data as AntragFilterOptionResponse);
    }

    public getMeldungP29Filter(options?: AxiosRequestConfig): Promise<AntragFilterOptionResponse> {
        return new MeldungP29Api(undefined, '', this.client)
            .filtersMeldungP29Item({
                ...options,
                headers: { Accept: 'application/json', ...options?.headers },
            })
            .then((res) => res.data as AntragFilterOptionResponse);
    }

    public getEinrichtungFilter(options?: AxiosRequestConfig): Promise<EinrichtungFilterResponse> {
        return new EinrichtungApi(undefined, '', this.client)
            .filtersEinrichtungItem({
                ...options,
                headers: { Accept: 'application/json', ...options?.headers },
            })
            .then((res) => res.data as EinrichtungFilterResponse);
    }

    public postFile(file: File, config?: AxiosRequestConfig): Promise<FileUploadResult> {
        const formData = new FormData();
        formData.append('file', file);

        return this.client
            .post(URL_UPLOAD, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                ...(config || {}),
            })
            .then((response) => response.data)
            .catch((e) => ({ fileName: file.name, error: e }));
    }

    public getFile(fileId: number, config?: AxiosRequestConfig): Promise<FileUploadResult> {
        return this.client
            .get(`${URL_UPLOAD}/${fileId}`, config)
            .then((response) => response.data)
            .catch((e) => ({ fileId, error: e }));
    }

    public getMassenmailCollection(
        page?: number,
        itemsPerPage?: number,
        options?: AxiosRequestConfig
    ): Promise<MassenmailListResponse> {
        return new MassenmailApi(undefined, '', this.client)
            .getMassenmailCollection(page, itemsPerPage || 9999, options)
            .then((res) => res.data as MassenmailListResponse);
    }

    public getMassenmail(id: string, options?: AxiosRequestConfig): Promise<MassenmailResponse> {
        return new MassenmailApi(undefined, '', this.client)
            .getMassenmailItem(id, options)
            .then((res) => res.data as MassenmailResponse);
    }

    public postMassenmail(
        data: PostMassenmailRequest,
        options?: AxiosRequestConfig
    ): Promise<MassenmailJsonldMassenmailRead> {
        let uploadIris: string[] = [];

        if (data.uploads?.length) {
            uploadIris = data.uploads.map((upload) => URL_UPLOAD + '/' + upload.uploadId);
        }

        const postData: MassenmailJsonldMassenmailWrite = Object.assign({}, data, {
            uploads: uploadIris,
        });

        return new MassenmailApi(undefined, '', this.client)
            .postMassenmailCollection(postData, options)
            .then((res) => res.data);
    }

    public getProfile(options?: AxiosRequestConfig): Promise<BenutzerProfileResponse> {
        return new BenutzerApi(undefined, '', this.client)
            .profileBenutzerItem({
                ...options,
                headers: { Accept: 'application/json', ...options?.headers },
            })
            .then((res) => res.data);
    }

    public patchProfile(
        id: string,
        data: BenutzerProfileEdit,
        options?: AxiosRequestConfig
    ): Promise<BenutzerProfileEdit> {
        return new BenutzerApi(undefined, '', this.client)
            .patchBenutzerItem(id, data, {
                ...options,
                headers: { Accept: 'application/json', ...options?.headers },
            })
            .then((res) => res.data);
    }

    public editEmail(
        data: BenutzerCredentialChangeRequestInput,
        options?: AxiosRequestConfig
    ): Promise<BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles> {
        return new BenutzerApi(undefined, '', this.client)
            .editEmailBenutzerItem(data, {
                ...options,
                headers: { Accept: 'application/json', ...options?.headers },
            })
            .then((res) => res.data);
    }

    public resetPasswordRequest(
        data: BenutzerCredentialChangeRequestInput,
        options?: AxiosRequestConfig
    ): Promise<BenutzerResponse> {
        return new BenutzerApi(undefined, '', this.client)
            .passwordResetRequestBenutzerItem(data, {
                ...options,
                headers: { Accept: 'application/json', ...options?.headers },
            })
            .then((res) => res.data as BenutzerResponse);
    }

    public resetPassword(
        data: BenutzerCredentialChangeRequestInput,
        options?: AxiosRequestConfig
    ): Promise<AxiosResponse<BenutzerResponse>> {
        return new BenutzerApi(undefined, '', this.client)
            .passwordResetBenutzerItem(data, {
                ...options,
                headers: { Accept: 'application/json', ...options?.headers },
            })
            .then((res) => res as AxiosResponse<BenutzerResponse>);
    }

    public checkChangeToken(
        data: BenutzerCredentialChangeConfirmInput,
        options?: AxiosRequestConfig
    ): Promise<BenutzerResponse> {
        return new BenutzerApi(undefined, '', this.client)
            .confirmChangeBenutzerItem(data, {
                ...options,
                headers: { Accept: 'application/json', ...options?.headers },
            })
            .then((res) => res.data as BenutzerResponse);
    }

    public patchFeststellungsantragGegenpruefung(
        id: number,
        data: FeststellungsantragGegenpruefungGegenpruefungEdit,
        options?: AxiosRequestConfig
    ): Promise<FeststellungsAntragGegenpruefungResponse> {
        return new FeststellungsantragGegenpruefungApi(undefined, '', this.client)
            .patchFeststellungsantragGegenpruefungItem(String(id), data, {
                ...options,
                headers: { Accept: 'application/json', ...options?.headers },
            })
            .then((res) => res.data);
    }

    public patchFestsetzungsantragGegenpruefung(
        id: number,
        data: FestsetzungsantragGegenpruefungGegenpruefungEdit,
        options?: AxiosRequestConfig
    ): Promise<FestsetzungsAntragGegenpruefungResponse> {
        return new FestsetzungsantragGegenpruefungApi(undefined, '', this.client)
            .patchFestsetzungsantragGegenpruefungItem(String(id), data, {
                ...options,
                headers: { Accept: 'application/json', ...options?.headers },
            })
            .then((res) => res.data);
    }

    public patchEinrichtung(
        id: string,
        data: EinrichtungEinrichtungEdit,
        options?: AxiosRequestConfig
    ): Promise<EinrichtungEinrichtungEdit> {
        return new EinrichtungApi(undefined, '', this.client)
            .patchEinrichtungItem(id, data, {
                ...options,
                headers: { Accept: 'application/json', ...options?.headers },
            })
            .then((res) => res.data);
    }

    public postRuhendstellung(
        data: RuhendstellungRequest,
        options?: AxiosRequestConfig
    ): Promise<RuhendstellungJsonldRuhendstellungCreate> {
        data.einrichtung = data.einrichtung ? toIriId(URL_EINRICHTUNG, data.einrichtung) : undefined;
        return new RuhendstellungApi(undefined, '', this.client)
            .postRuhendstellungCollection(data, {
                ...options,
                headers: { Accept: 'application/json', ...options?.headers },
            })
            .then((res) => res.data);
    }

    public postEinrichtungReaktivieren(einrichtungId: string | number): Promise<void> {
        return new EinrichtungApi(undefined, '', this.client)
            .reaktivierenEinrichtungItem(String(einrichtungId))
            .then(noop);
    }

    public async getBenutzerTablePreference(name: string): Promise<BenutzerTablePreferenceResponse> {
        return new BenutzerTablePreferenceApi(undefined, '', this.client)
            .getBenutzerTablePreferenceCollection(undefined, undefined, name)
            .then((res) => res.data?.['hydra:member']?.[0] as BenutzerTablePreferenceResponse);
    }

    public async postBenutzerTablePreference(data: BenutzerTablePreference): Promise<BenutzerTablePreference> {
        return new BenutzerTablePreferenceApi(undefined, '', this.client)
            .postBenutzerTablePreferenceCollection(data)
            .then((res) => res.data as BenutzerTablePreference);
    }

    public async putBenutzerTablePreference(
        id: string,
        data: BenutzerTablePreference
    ): Promise<BenutzerTablePreference> {
        return new BenutzerTablePreferenceApi(undefined, '', this.client)
            .putBenutzerTablePreferenceItem(id, data)
            .then((res) => res.data as BenutzerTablePreference);
    }

    public async deleteBenutzerTablePreference(id: string): Promise<void> {
        return new BenutzerTablePreferenceApi(undefined, '', this.client)
            .deleteBenutzerTablePreferenceItem(id)
            .then((res) => res.data);
    }

    public async patchToggleBenutzerStatus(id: number): Promise<void> {
        return new BenutzerApi(undefined, '', this.client).toggleStatusBenutzerItem(String(id)).then((res) => res.data);
    }

    public async putBenutzer(id: string, data: PutBenutzer): Promise<PutBenutzer> {
        data.landschaftsverband = toIriId(URL_LANDSCHAFTSVERBAND, data.landschaftsverband);
        data.traegerverband = toIriId(URL_TRAEGERVERBAND, data.traegerverband);
        data.traeger = toIriId(URL_TRAEGER, data.traeger);
        return new BenutzerApi(undefined, '', this.client).putBenutzerItem(id, data).then((res) => res.data);
    }

    public getFeststellungsantragVersionen({
        page,
        itemsPerPage,
        antrag,
        antrag2,
        orderByAntragModifiedAt,
        options,
    }: GetAntragVersionenOptions): Promise<FeststellungsAntragVersionCollectionListResponse> {
        return new FeststellungsantragVersionApi(undefined, '', this.client)
            .getFeststellungsantragVersionCollection(
                page,
                itemsPerPage,
                antrag,
                antrag2,
                orderByAntragModifiedAt,
                options
            )
            .then((res) => res.data as FeststellungsAntragVersionCollectionListResponse);
    }

    public getFeststellungsantragVersionItem(id: number | string): Promise<FeststellungsAntragVersionResponse> {
        return new FeststellungsantragVersionApi(undefined, '', this.client)
            .getFeststellungsantragVersionItem(String(id))
            .then((res) => res.data);
    }

    public getFeststellungsantragDiff(
        id: number | string,
        v2: number | string,
        v1?: number | string
    ): Promise<FeststellungsAntragDiffResponse> {
        return new FeststellungsantragApi(undefined, '', this.client)
            .diffFeststellungsantragItem(Number(v2), String(id), v1 ? Number(v1) : undefined, {
                headers: { Accept: 'application/json' },
            })
            .then((res) => res.data);
    }

    public getFestsetzungsantragVersionen({
        page,
        itemsPerPage,
        antrag,
        antrag2,
        orderByAntragModifiedAt,
        options,
    }: GetAntragVersionenOptions): Promise<FestsetzungsAntragVersionCollectionListResponse> {
        return new FestsetzungsantragVersionApi(undefined, '', this.client)
            .getFestsetzungsantragVersionCollection(
                page,
                itemsPerPage,
                antrag,
                antrag2,
                orderByAntragModifiedAt,
                options
            )
            .then((res) => res.data as FestsetzungsAntragVersionCollectionListResponse);
    }

    public getFestsetzungsantragVersionItem(id: number | string): Promise<FestsetzungsAntragVersionResponse> {
        return new FestsetzungsantragVersionApi(undefined, '', this.client)
            .getFestsetzungsantragVersionItem(String(id))
            .then((res) => res.data);
    }

    public getFestsetzungsantragDiff(
        id: number | string,
        v2: number | string,
        v1?: number | string
    ): Promise<FestsetzungsAntragDiffResponse> {
        return new FestsetzungsantragApi(undefined, '', this.client)
            .diffFestsetzungsantragItem(Number(v2), String(id), v1 ? Number(v1) : undefined, {
                headers: { Accept: 'application/json' },
            })
            .then((res) => res.data);
    }

    public getInvestorenmodellCollection({
        page,
        itemsPerPage,
        qSearch,
        zustaendigerBenutzer,
        status,
        status2,
        einrichtung,
        einrichtung2,
        berechnungsart,
        antragJahr,
        aufgabe,
        orderZuletztEingereichtAt,
        orderEinrichtungAktenzeichen,
        orderEinrichtungName,
        orderStatus,
        orderEinrichtungZustaendigerBenutzerName,
        orderFestsetzungsantragField500,
        orderFestsetzungsantragFeststellungsantragBerechnungsart,
        options,
    }: GetInvestorenmodellOptions): Promise<InvestorenmodellCollectionListResponse> {
        return new InvestorenmodellApi(undefined, '', this.client)
            .getInvestorenmodellCollection(
                page,
                itemsPerPage,
                qSearch,
                zustaendigerBenutzer,
                status,
                status2,
                einrichtung ? String(einrichtung) : undefined,
                einrichtung2,
                berechnungsart,
                antragJahr,
                aufgabe,
                orderZuletztEingereichtAt,
                orderEinrichtungAktenzeichen,
                orderEinrichtungName,
                orderStatus,
                orderEinrichtungZustaendigerBenutzerName,
                orderFestsetzungsantragField500,
                orderFestsetzungsantragFeststellungsantragBerechnungsart,
                options
            )
            .then((res) => res.data as InvestorenmodellCollectionListResponse);
    }

    public getInvestorenmodellItem(id: string | number): Promise<InvestorenmodellResponse> {
        return new InvestorenmodellApi(undefined, '', this.client)
            .getInvestorenmodellItem(String(id))
            .then((res) => res.data as InvestorenmodellResponse);
    }

    public deleteInvestorenmodellItem(id: string | number): Promise<void> {
        return new InvestorenmodellApi(undefined, '', this.client).deleteInvestorenmodellItem(String(id)).then(noop);
    }

    public async postInvestorenmodell(
        request: InvestorenmodellRequest,
        options?: AxiosRequestConfig
    ): Promise<InvestorenmodellResponse> {
        request.einrichtung = request.einrichtung ? URL_EINRICHTUNG + '/' + request.einrichtung : undefined;
        return new InvestorenmodellApi(undefined, '', this.client)
            .postInvestorenmodellCollection(request, options)
            .then((res) => res.data as InvestorenmodellResponse);
    }

    public getInvestorenmodellJson(
        id: string,
        step: string,
        objectType?: string,
        uuid?: string,
        options?: AxiosRequestConfig
    ): Promise<InvestorenmodellFormResponse> {
        return new InvestorenmodellApi(undefined, '', this.client)
            .jsonFormGetInvestorenmodellItem(id, step, objectType, uuid, {
                ...options,
                headers: { Accept: 'application/json', ...options?.headers },
            })
            .then((res) => res.data as InvestorenmodellFormResponse);
    }

    public deleteInvestorenmodellObjectTypeItem(id: string, uuid: string, options?: AxiosRequestConfig): Promise<void> {
        return new InvestorenmodellApi(undefined, '', this.client)
            .jsonFormDeleteInvestorenmodellItem(id, uuid, {
                ...options,
                headers: { Accept: 'application/json', ...options?.headers },
            })
            .then((res) => res.data);
    }

    public postInvestorenmodellJson(
        id: string,
        step: string,
        data: object,
        objectType?: string,
        uuid?: string,
        persist?: boolean,
        fields?: string[],
        recalculateFields?: boolean,
        options?: AxiosRequestConfig
    ): Promise<InvestorenmodellFormResponse> {
        return new InvestorenmodellApi(undefined, '', this.client)
            .jsonFormPatchInvestorenmodellItem(id, data, step, objectType, uuid, persist, fields, recalculateFields, {
                ...options,
                headers: {
                    Accept: 'application/json',
                    ...options?.headers,
                },
            })
            .then((res) => res.data as InvestorenmodellFormResponse);
    }

    public workflowInvestorenmodellItem(
        id: number | string,
        action: string,
        options?: AxiosRequestConfig
    ): Promise<InvestorenmodellResponse> {
        return new InvestorenmodellApi(undefined, '', this.client)
            .workflowInvestorenmodellItem(Number(id), action, options)
            .then((res) => res.data as InvestorenmodellResponse);
    }

    public patchInvestorenmodellGegenpruefung(
        id: number | string,
        request: InvestorenmodellGegenpruefungRequest,
        options?: AxiosRequestConfig
    ): Promise<InvestorenmodellGegenpruefungResponse> {
        return new InvestorenmodellGegenpruefungApi(undefined, '', this.client)
            .patchInvestorenmodellGegenpruefungItem(String(id), request, {
                ...options,
                headers: { Accept: 'application/json', ...options?.headers },
            })
            .then((res) => res.data);
    }

    public getInvestorenmodellVersionen({
        page,
        itemsPerPage,
        antrag,
        antrag2,
        orderByAntragModifiedAt,
        options,
    }: GetAntragVersionenOptions): Promise<InvestorenmodellVersionCollectionListResponse> {
        return new InvestorenmodellVersionApi(undefined, '', this.client)
            .getInvestorenmodellVersionCollection(page, itemsPerPage, antrag, antrag2, orderByAntragModifiedAt, options)
            .then((res) => res.data as InvestorenmodellVersionCollectionListResponse);
    }

    public getInvestorenmodellVersionItem(id: number | string): Promise<InvestorenmodellVersionResponse> {
        return new InvestorenmodellVersionApi(undefined, '', this.client)
            .getInvestorenmodellVersionItem(String(id))
            .then((res) => res.data);
    }

    public getInvestorenmodellDiff(
        id: number | string,
        v2: number | string,
        v1?: number | string
    ): Promise<InvestorenmodellDiffResponse> {
        return new InvestorenmodellApi(undefined, '', this.client)
            .diffInvestorenmodellItem(Number(v2), String(id), v1 ? Number(v1) : undefined, {
                headers: { Accept: 'application/json' },
            })
            .then((res) => res.data);
    }

    public getEinrichtungAnbieterPermissions(id: number | string): Promise<EinrichtungAnbieterPermissionsResponse> {
        return new EinrichtungApi(undefined, '', this.client)
            .antragAnbieterPermissionsEinrichtungItem(String(id), {
                headers: { Accept: 'application/json' },
            })
            .then((res) => res.data);
    }

    public patchFeststellungsAntragGesendetAt(id: string | number, gesendetAt: string): Promise<void> {
        return new FeststellungsantragBescheidungApi(undefined, '', this.client)
            .setGesendetAtFeststellungsantragBescheidungItem(String(id), { gesendetAt })
            .then(noop);
    }

    public patchFestsetzungsAntragGesendetAt(id: string | number, gesendetAt: string): Promise<void> {
        return new FestsetzungsantragBescheidungApi(undefined, '', this.client)
            .setGesendetAtFestsetzungsantragBescheidungItem(String(id), { gesendetAt })
            .then(noop);
    }

    public patchInvestorenmodellGesendetAt(id: string | number, gesendetAt: string): Promise<void> {
        return new InvestorenmodellBescheidungApi(undefined, '', this.client)
            .setGesendetAtInvestorenmodellBescheidungItem(String(id), { gesendetAt })
            .then(noop);
    }

    public postFeststellungsantragZurueckziehen(
        id: string | number,
        erlaeuterung: string | null,
        options?: AxiosRequestConfig
    ): Promise<void> {
        const antrag = toIriId('/api/feststellungsantrag', id);
        return new FeststellungsantragZurueckziehenApi(undefined, '', this.client)
            .postFeststellungsantragZurueckziehenCollection(
                { antrag, erlaeuterung },
                {
                    ...options,
                    headers: { Accept: 'application/json', ...options?.headers },
                }
            )
            .then(noop);
    }

    public postFestsetzungsantragZurueckziehen(
        id: string | number,
        erlaeuterung: string | null,
        options?: AxiosRequestConfig
    ): Promise<void> {
        const antrag = toIriId('/api/festsetzungsantrag', id);
        return new FestsetzungsantragZurueckziehenApi(undefined, '', this.client)
            .postFestsetzungsantragZurueckziehenCollection(
                { antrag, erlaeuterung },
                {
                    ...options,
                    headers: { Accept: 'application/json', ...options?.headers },
                }
            )
            .then(noop);
    }

    public postInvestorenmodellZurueckziehen(
        id: string | number,
        erlaeuterung: string | null,
        options?: AxiosRequestConfig
    ): Promise<void> {
        const antrag = toIriId('/api/investorenmodell', id);
        return new InvestorenmodellZurueckziehenApi(undefined, '', this.client)
            .postInvestorenmodellZurueckziehenCollection(
                { antrag, erlaeuterung },
                {
                    ...options,
                    headers: { Accept: 'application/json', ...options?.headers },
                }
            )
            .then(noop);
    }

    public patchFeststellungsantragZurueckziehen(
        id: string | number,
        data: AntragZurueckziehenEntscheidungRequest,
        options?: AxiosRequestConfig
    ): Promise<void> {
        return new FeststellungsantragZurueckziehenApi(undefined, '', this.client)
            .setEntscheidungFeststellungsantragZurueckziehenItem(String(id), data, {
                ...options,
                headers: { Accept: 'application/json', ...options?.headers },
            })
            .then((res) => res.data);
    }

    public patchFestsetzungsantragZurueckziehen(
        id: string | number,
        data: AntragZurueckziehenEntscheidungRequest,
        options?: AxiosRequestConfig
    ): Promise<void> {
        return new FestsetzungsantragZurueckziehenApi(undefined, '', this.client)
            .setEntscheidungFestsetzungsantragZurueckziehenItem(String(id), data, {
                ...options,
                headers: { Accept: 'application/json', ...options?.headers },
            })
            .then((res) => res.data);
    }

    public patchInvestorenmodellZurueckziehen(
        id: string | number,
        data: AntragZurueckziehenEntscheidungRequest,
        options?: AxiosRequestConfig
    ): Promise<void> {
        return new InvestorenmodellZurueckziehenApi(undefined, '', this.client)
            .setEntscheidungInvestorenmodellZurueckziehenItem(String(id), data, {
                ...options,
                headers: { Accept: 'application/json', ...options?.headers },
            })
            .then((res) => res.data);
    }

    public deleteEinrichtungBenutzer(id: string | number): Promise<void> {
        return new EinrichtungBenutzerApi(undefined, '', this.client)
            .deleteEinrichtungBenutzerItem(String(id))
            .then(noop);
    }

    public putEinrichtungBenutzer(
        id: string | number,
        einrichtungId: string | number,
        benutzerId: string | number,
        isAntragsteller: boolean
    ): Promise<void> {
        return new EinrichtungBenutzerApi(undefined, '', this.client)
            .putEinrichtungBenutzerItem(String(id), {
                einrichtung: toIriId(URL_EINRICHTUNG, einrichtungId),
                benutzer: toIriId(URL_BENUTZER, benutzerId),
                isAntragsteller,
            })
            .then(noop);
    }
}

export const backendApiService = new ApiService();

export type UploadType = {
    uploadId: string;
};

export type PostMassenmailRequest = Omit<MassenmailJsonldMassenmailWrite, 'uploads'> & {
    uploads?: UploadType[];
};
