import React from 'react';
import { CardContent, Table, TableBody, Typography } from '@mui/material';

import { useAuthHelper } from 'api/auth/useAuthHelper';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { DashboardLvBescheidVersandRow } from 'pages/Dashboard/Landschaftsverband/DashboardLvBescheidVersandRow';
import { useDashboardLvAntraege } from 'pages/Dashboard/Landschaftsverband/useDashboardLvAntraege';

interface DashboardLvBescheidVersandProps {
    benutzerId: number[];
}

export const DashboardLvBescheidVersand: React.FC<DashboardLvBescheidVersandProps> = ({ benutzerId }) => {
    const { isLandschaftsverbandView } = useAuthHelper();

    const { bescheidungen, isLoading, refresh } = useDashboardLvAntraege(isLandschaftsverbandView, benutzerId);

    return (
        <>
            <CardContent>
                <Typography variant="h1" component="h2">
                    Bescheidversand
                </Typography>
            </CardContent>
            <AppLoaderContainer isLoading={isLoading}>
                {bescheidungen && (
                    <Table size="small">
                        <TableBody>
                            {bescheidungen.map((bescheidung) => (
                                <DashboardLvBescheidVersandRow
                                    key={bescheidung.bescheidungId}
                                    bescheidung={bescheidung}
                                    refresh={refresh}
                                />
                            ))}
                        </TableBody>
                    </Table>
                )}

                {(!bescheidungen || !bescheidungen?.length) && (
                    <CardContent>
                        <Typography align={'center'}>Keine Bescheide gefunden</Typography>
                    </CardContent>
                )}
            </AppLoaderContainer>
        </>
    );
};
