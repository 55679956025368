import { Dispatch, SetStateAction, useCallback } from 'react';

import { AntragType } from 'api/antragTypes';
import { backendApiService } from 'api/ApiService';
import { useConfirmationDialogActions } from 'forms/state/useConfirmationDialogState';
import { useMessageActions } from 'forms/state/useMessages';
import { errorMessage, successMessage } from 'forms/utils/MessageUtils';

type UseAntragLoeschenResult = (id: string | number, type: AntragType) => void;

export const useAntragLoeschen = (setServerDataChanged: Dispatch<SetStateAction<number>>): UseAntragLoeschenResult => {
    const { addMessage } = useMessageActions();
    const { showConfirmation, closeConfirmation } = useConfirmationDialogActions();

    return useCallback(
        (id, type) => {
            showConfirmation({
                alertText:
                    'Sie sind dabei Ihren Antrag unwiderruflich zu löschen. Möchten Sie diesen Antrag wirklich löschen?',
                confirmLabel: 'Antrag löschen',
                denyLabel: 'Abbrechen',
                denyAction: closeConfirmation,
                confirmAction: async () => {
                    try {
                        const endpoint = determineEndpoint(type);
                        await endpoint(id);
                        setServerDataChanged((prev) => prev + 1);
                        addMessage(createSuccessMessage());
                    } catch (e) {
                        console.error(e);
                        addMessage(createErrorMessage());
                    } finally {
                        closeConfirmation();
                    }
                },
            });
        },
        [addMessage, closeConfirmation, setServerDataChanged, showConfirmation]
    );
};

const determineEndpoint = (type: AntragType): ((id: string | number) => Promise<void>) => {
    switch (type) {
        case AntragType.FESTSTELLUNG:
            return backendApiService.deleteFeststellungsantragItem.bind(backendApiService);
        case AntragType.FESTSETZUNG:
            return backendApiService.deleteFestsetzungsantragItem.bind(backendApiService);
        case AntragType.INVESTOREN_MODELL:
            return backendApiService.deleteInvestorenmodellItem.bind(backendApiService);
        case AntragType.MELDUNG_P29:
            return backendApiService.deleteMeldungP29Item.bind(backendApiService);
    }
};

const createSuccessMessage = () =>
    successMessage('Antrag erfolgreich gelöscht.', {
        autoCloseSeconds: 8,
        closable: true,
    });

const createErrorMessage = () =>
    errorMessage('Antrag konnte nicht gelöscht werden.', {
        autoCloseSeconds: 8,
        closable: true,
    });
