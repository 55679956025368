import React from 'react';
import { LayoutProps, RankedTester, rankWith } from '@jsonforms/core';
import { withJsonFormsLayoutProps } from '@jsonforms/react';

import { FormConfig, GridLayoutConfig, GroupListType, Schema, UiSchemaType } from 'forms/types/UiSchemaTypes';

import { GroupListItem } from './GroupListItem';
import { useGroupListData } from './hooks/useGroupListData';

interface GroupListComponentType extends Omit<LayoutProps, 'uischema'> {
    uischema: GroupListType;
}

export type GroupType = {
    path: string;
    schema: Schema;
    uuid: string;
    [key: string]: any;
};

export interface GroupListDataType {
    key?: string;
    title: string;
    controls: GroupType[];
}

const GroupListControl: React.FC<GroupListComponentType> = ({ data, schema, uischema, config }) => {
    const groups = useGroupListData(data, schema, uischema);
    return <GroupListComponent groups={groups} {...uischema} config={config} />;
};

export const GroupListComponent: React.FC<{
    groups: GroupListDataType[];
    entries: UiSchemaType[];
    gridLayout?: GridLayoutConfig;
    config: FormConfig;
    vertical?: boolean;
}> = ({ groups, entries, gridLayout, config, vertical }) => (
    <>
        {groups.map((group, index) => (
            <GroupListItem
                key={index}
                group={group}
                elements={entries}
                gridLayout={gridLayout}
                config={config}
                vertical={vertical}
                separator
            />
        ))}
    </>
);

// @ts-ignore
export const GroupList = withJsonFormsLayoutProps(GroupListControl);

export const GroupListTester: RankedTester = rankWith(1, (uischema) => uischema.type === 'GroupList');
