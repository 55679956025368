import { accordion } from 'forms/AntragForm/ui-schemas/elements/accordion';
import { collectionGroups } from 'forms/AntragForm/ui-schemas/elements/collectionGroups';
import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { dataGrid } from 'forms/AntragForm/ui-schemas/elements/dataGrid';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { highlightedArea } from 'forms/AntragForm/ui-schemas/elements/highlightedArea';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';

export const uiSchema = verticalLayout([
    group([control('field1751')]),
    group(
        [
            {
                type: 'FormExtendButton',
                scope: '#/properties/zuschuesseFiktiveErstinbetriebnahme',
                label: '',
                uiSchema: {
                    type: 'CustomVerticalLayout',
                    elements: [
                        {
                            type: 'Control',
                            scope: '#/properties/field1714',
                        },
                        {
                            type: 'Control',
                            scope: '#/properties/field1715',
                        },
                    ],
                },
            },
        ],
        { noSeparator: true },
        'Zuschüsse Erstinbetriebnahme'
    ),
    group([
        control('field1752a'),
        control('field1754a'),
        control('field1754b'),
        control('field1754c'),
        control('field1754'),
        control('field1755'),
        control('field1756a'),
        control('field1756b'),
        control('field1756f'),
        control('field1756c'),
        control('field1756d'),
        control('field1756e'),
        control('field1756'),
    ]),
    accordion(
        [
            dataGrid(
                'fremdkapitalBasisFiktiveErstinbetriebnahme',
                [
                    {
                        scope: '#/properties/jahr',
                        valueLabel: 'Jahr {value}',
                        textAlign: 'left',
                    },
                    {
                        scope: '#/properties/field1754x',
                        label: '1754 Darlehensbasis',
                        format: 'currency',
                        unit: '€',
                        textAlign: 'right',
                        styles: {
                            textAlign: 'right',
                            paddingRight: 16,
                        },
                        highlighted: true,
                    },
                    {
                        scope: '#/properties/field1757x',
                        label: '1757 Zinsbetrag',
                        format: 'currency',
                        unit: '€',
                        styles: {
                            textAlign: 'right',
                        },
                    },
                ],
                { containerGridSize: 7 }
            ),
        ],
        'Darlehensbasis und Zinsen je Jahr'
    ),
    group([control('field1757g'), control('field1757')]),
    collectionGroups(
        'fiktivePlatzzahlerweiterungen',
        [
            group([control('field1758')]),
            group(
                [
                    {
                        type: 'FormExtendButton',
                        scope: '#/properties/zuschuesse',
                        label: '',
                        uiSchema: {
                            type: 'CustomVerticalLayout',
                            elements: [
                                {
                                    type: 'Control',
                                    scope: '#/properties/field1729',
                                },
                                {
                                    type: 'Control',
                                    scope: '#/properties/field1730',
                                },
                            ],
                        },
                    },
                ],
                { noSeparator: true },
                'Zuschüsse'
            ),
            group([
                control('field1759a'),
                control('field1761a'),
                control('field1761b'),
                control('field1761c'),
                control('field1761'),
                control('field1762'),
                control('field1763a'),
                control('field1763b'),
                control('field1763f'),
                control('field1763c'),
                control('field1763d'),
                control('field1763e'),
                control('field1763'),
            ]),
            accordion(
                [
                    dataGrid(
                        'fremdkapitalBasis',
                        [
                            {
                                scope: '#/properties/jahr',
                                textAlign: 'left',
                                valueLabel: 'Jahr {value}',
                            },
                            {
                                scope: '#/properties/field1761x',
                                label: '1761 Darlehnsbasis',
                                format: 'currency',
                                unit: '€',
                                textAlign: 'right',
                                highlighted: true,
                                styles: {
                                    textAlign: 'right',
                                    paddingRight: 16,
                                },
                            },
                            {
                                scope: '#/properties/field1764x',
                                label: '1764 Zinsbetrag',
                                format: 'currency',
                                unit: '€',
                                textAlign: 'right',
                                styles: {
                                    textAlign: 'right',
                                },
                            },
                        ],
                        { containerGridSize: 7 }
                    ),
                ],
                'Darlehensbasis und Zinsen je Jahr'
            ),
            group([control('field1764g'), control('field1764')], {
                noSeparator: true,
            }),
        ],
        undefined,
        '#/properties/massnahmeUuid',
        'Finanzierung Platzzahlerweiterung (FK) - {value}'
    ),

    group([highlightedArea([control('field1765')])]),
]);
