import { useEffect, useState } from 'react';
import { JsonForms } from '@jsonforms/react';
import { isEqual } from 'lodash';

import { EinrichtungResponse } from 'api/types';
import { ROLLEN } from 'constants/roles';
import { SelectOption } from 'forms/components/Typeahead';
import { useFormValidation } from 'forms/hooks/useFormValidation';
import { useLayoutWithJsonFormsState } from 'forms/hooks/useJsonFormsState';
import { renderers } from 'forms/renderers';
import { FormLayoutConfig, FormStateChange, Schema } from 'forms/types/UiSchemaTypes';
import { getIriId } from 'utilities';

interface ZustaendigkeitEinrichtungFormPropsInterface {
    handleSubmit(formData: ZustaendigkeitEinrichtungFormDataInterface): void;

    isSubmitting: boolean;
    errors?: ZustaendigkeitEinrichtungFormErrorsInterface | null | undefined;
    einrichtung: EinrichtungResponse | undefined;
    submit: boolean;

    setValid(valid: boolean): void;
}

export interface ZustaendigkeitEinrichtungFormDataInterface {
    zustaendigerBenutzer: SelectOption | undefined;
}

export interface ZustaendigkeitEinrichtungFormErrorsInterface {
    zustaendigerBenutzer: object | undefined;
}

export const FormEinrichtung = ({
    handleSubmit,
    isSubmitting,
    einrichtung,
    submit,
    setValid,
}: ZustaendigkeitEinrichtungFormPropsInterface) => {
    const [formData, setFormData] = useState<ZustaendigkeitEinrichtungFormDataInterface>(
        {} as ZustaendigkeitEinrichtungFormDataInterface
    );
    const formSchema: Schema = {
        type: 'object',
        required: ['zustaendigerBenutzer'],
        properties: {
            zustaendigerBenutzer: {
                $id: 'zustaendigerBenutzer',
                type: 'object',
                title: 'Benutzer zuweisen',
                // @ts-ignore
                custom: {
                    block_prefixes: ['benutzer_autocomplete'],
                    rollen: [ROLLEN.LV_ADMIN, ROLLEN.LV_SACHBEARBEITER],
                    landschaftsverband: getIriId(einrichtung?.landschaftsverband?.['@id']),
                },
            },
        },
    };

    const handleChange = (state: FormStateChange) => {
        if (!isEqual(state.data, formData)) {
            setFormData(state.data);
        }
    };

    useEffect(() => {
        if (submit) {
            handleSubmit(formData);
        }
    }, [submit, handleSubmit, isSubmitting, formData]);

    const configLayout: FormLayoutConfig = {
        gridLayout: {
            label: 3,
            input: 4,
        },
    };

    const config = useLayoutWithJsonFormsState(configLayout);

    const { valid } = useFormValidation(formSchema, formData, undefined);

    useEffect(() => {
        setValid(valid);
    }, [valid, setValid]);

    return (
        <>
            <JsonForms
                data={formData}
                schema={formSchema}
                onChange={handleChange}
                renderers={renderers}
                config={config}
            />
        </>
    );
};
