import { collectionGroups } from 'forms/AntragForm/ui-schemas/elements/collectionGroups';
import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';

export const uiSchema = verticalLayout([
    group([
        control('field5511'),
        control('field5101'),
        control('field5101a'),
        control('field5101b'),
        control('field5109'),
    ]),
    group([control('field5111'), control('field5112')]),
    group([control('field5129'), control('field5130')]),
    collectionGroups('ikSatzZimmerkategorien', [control('field5151'), control('field5152')]),
    group([control('field2005'), control('field2006')]),
    group([control('field5171')]),
    group([control('field1198'), control('field1193'), control('field1194')]),
]);
