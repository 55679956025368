import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { JsonForms } from '@jsonforms/react';
import { Grid } from '@mui/material';
import { isEqual } from 'lodash';

import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { CloseButton } from 'components/Dialog/CloseButton';
import { Dialog } from 'components/Dialog/Dialog';
import { prepareUISchema } from 'forms/AntragForm/hooks/useStepUISchema';
import { FormButton } from 'forms/components/FormButton';
import { Pflichtfeld } from 'forms/components/Pflichtfeld';
import { useDisabled } from 'forms/hooks/useDisabled';
import { useErrors } from 'forms/hooks/useErrors';
import { useFormHandling } from 'forms/hooks/useFormHandling';
import { useFormValidation } from 'forms/hooks/useFormValidation';
import { useLabel } from 'forms/hooks/useLabel';
import { useReadonly } from 'forms/hooks/useReadonly';
import { renderers } from 'forms/renderers';
import { useConfirmationDialogActions } from 'forms/state/useConfirmationDialogState';
import { useErrorMessages } from 'forms/state/useErrorMessages';
import { useFormState } from 'forms/state/useFormState';
import { FormConfig, FormState, LabeledType, Schema, UiSchemaType } from 'forms/types/UiSchemaTypes';
import { getObjectTypeAction } from 'utilities';

export interface FormDialogData {
    title?: string;
    subTitle?: LabeledType;
    scope: string;
    uuid?: string;
    config?: FormConfig;
    schema?: Schema | undefined;
    uiSchema?: UiSchemaType | undefined;
    readonly?: boolean;
    action?: string | null;
    isNew?: boolean;
}

interface FormDialogProps {
    dialogData: FormDialogData;
    onSave: () => void;
    onClose: () => void;
}

export const FormDialog: React.FC<FormDialogProps> = ({
    dialogData: { title, subTitle, uiSchema, config, scope, uuid, action, readonly: readonlyView },
    onSave,
    onClose,
}) => {
    const { showConfirmation, closeConfirmation } = useConfirmationDialogActions();
    const api = useMemo(
        () => ({
            getFormDataAPI: config?.loadFormData
                ? () => config.loadFormData!(getObjectTypeAction(scope, action), uuid)
                : undefined,
            submitAPI: config?.submitFormData
                ? (formData: FormState, persist?: boolean, modelPaths?: string[]) =>
                      config.submitFormData!(formData, persist, modelPaths, getObjectTypeAction(scope, action), uuid)
                : undefined,
        }),
        [action, config, scope, uuid]
    );

    const { data, schema, onChange, requestPersist, requestValidate, submit, validate, isLoading, clear, formErrors } =
        useFormHandling(api);

    const [formUiSchema, setFormUiSchema] = useState<UiSchemaType | undefined>();
    const [isDirty, setDirty] = useState(false);
    const subTitleText = useLabel(data, schema, subTitle);
    const { loadingError } = useFormState();

    const disabled = useDisabled();
    const readonly = useReadonly(schema, config) || readonlyView;

    useEffect(() => {
        if (uiSchema && schema) {
            if (isEqual(formUiSchema, prepareUISchema(uiSchema, schema))) {
                return;
            }
            setFormUiSchema(prepareUISchema(uiSchema, schema));
        }
    }, [uiSchema, schema, formUiSchema, action]);

    const onDataChange = (newData: FormState) => {
        if (!isEqual(newData, data)) {
            setDirty(true);
            onChange(newData);
        }
    };

    const dialogConfig: FormConfig = useMemo(
        () => ({
            submitFormData: api.submitAPI,
            loadFormData: api.getFormDataAPI,
            requestValidate,
            requestPersist,
            submit: (
                persist: boolean,
                paths?: string[],
                navigate?: boolean,
                action?: string,
                recalculateFields?: boolean
            ) => submit(data, persist, paths, undefined, undefined, recalculateFields),
            validate,
            errors: formErrors,
            showFieldNumberLabels: true,
            readonly,
        }),
        [api, requestValidate, requestPersist, submit, validate, data, formErrors, readonly]
    );
    const { errors } = useErrors({
        config: dialogConfig,
        immediately: true,
        path: 'data',
    });
    useErrorMessages(errors, loadingError);
    const { valid } = useFormValidation(schema, data, dialogConfig);

    const onDialogSave = useCallback(() => {
        dialogConfig.submit(true).then(onSave);
    }, [onSave, dialogConfig]);

    const onDialogClose = () => {
        const onCloseBase = () => {
            onClose();
            setDirty(false);
            clear();
        };

        if (isDirty) {
            showConfirmation({
                alertText:
                    'Einige Änderungen sind noch nicht gespeichert und gehen beim Schließen des Dialogs verloren. Sind Sie sicher?',
                confirmAction: () => {
                    closeConfirmation();
                    onCloseBase();
                },
                denyAction: closeConfirmation,
            });
        } else {
            onCloseBase();
        }
    };

    return (
        <Dialog
            open={Boolean(scope)}
            onClose={onDialogClose}
            title={title}
            subTitle={subTitleText}
            closeAction={<CloseButton onClick={onDialogClose} disabled={disabled} />}
            maxWidth={'lg'}
            data-cy={'FormDialog'}
            actions={
                <Grid container direction={'row-reverse'} spacing={1}>
                    {/* reversed order (Tab order) */}
                    {!readonly && (
                        <Grid item>
                            <FormButton
                                variant="contained"
                                color={'primary'}
                                onClick={onDialogSave}
                                disabled={!valid || disabled}
                                data-cy={'FormDialog-saveButton'}
                            >
                                Speichern
                            </FormButton>
                        </Grid>
                    )}
                    <Grid item>
                        <FormButton variant="outlined" color="primary" onClick={onDialogClose} disabled={disabled}>
                            Schließen
                        </FormButton>
                    </Grid>
                </Grid>
            }
        >
            <AppLoaderContainer isLoading={isLoading}>
                {data && (
                    <>
                        <Pflichtfeld>* Pflichtfeld</Pflichtfeld>
                        <JsonForms
                            schema={schema}
                            uischema={formUiSchema}
                            data={data}
                            renderers={renderers}
                            onChange={(state) => onDataChange(state.data)}
                            config={dialogConfig}
                            readonly={readonly}
                        />
                    </>
                )}
            </AppLoaderContainer>
        </Dialog>
    );
};
