import React, { useRef } from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import AddComment from '@mui/icons-material/AddComment';
import { Paper, styled, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { GegenpruefungResponse } from 'api/types';
import { CloseButton } from 'components/Dialog/CloseButton';
import { STATUS as GEGENPRUEFUNG_STATUS } from 'constants/gegenpruefungStatus';
import { FormButton } from 'forms/components/FormButton';
import { HtmlEditor } from 'forms/components/HtmlEditor';
import { FormState } from 'forms/types/UiSchemaTypes';

interface GegenpruefungErlaeuterungType {
    gegenpruefung: GegenpruefungResponse;
    submit: (id: number, data: FormState) => void;
}

const StyledPaper = styled(Paper)(
    `
    position: fixed;
    background-color: lightgrey;
    padding: 10px;
    z-index: 1012;
    top: 0;
    left: 0;

    & h2 {
        cursor: move;
        margin-bottom: 7px;
    }
    `
);

const StyledFormButton = styled(FormButton)(
    ({ theme }) =>
        `
    position: fixed;
    top: ${theme.mixins.header.height + 10}px;
    right: 0;
    width: 128px;
    border-radius: 50%;
    padding: 0;
    height: 128px;
    transform: translate(50%, -50%);

    & svg {
        margin-top: 52px;
        margin-right: 48px !important;
    }
    `
);

const StyledReadOnlyContainer = styled('div')(
    () => `
        background: white;
        padding: 10px 20px;
        width: 500px;
        min-width: 500px;
        overflow-y: auto;
        resize: both;
        max-height: 80vh;
    `
);

const useStyles = makeStyles(() => ({
    closeButton: {
        float: 'right',
        marginTop: '-12px',
        marginRight: '-12px',
    },
}));

export const GegenpruefungErlaeuterung: React.FC<GegenpruefungErlaeuterungType> = ({ gegenpruefung, submit }) => {
    const initialX = window.innerWidth - 570;
    const initialY = 110;
    const [open, setOpen] = React.useState<boolean>(false);
    const [x, setX] = React.useState<number>(initialX);
    const [y, setY] = React.useState<number>(initialY);
    const paperRef = useRef<HTMLDivElement>(null);
    const classes = useStyles();

    const handleClickGegenpruefungErlaeterung = () => {
        if (!open) {
            setX(initialX);
            setY(initialY);
        }
        setOpen(!open);
    };

    const onDrag = (e: DraggableEvent, data: DraggableData) => {
        setX(data.x);
        setY(data.y);
    };

    return (
        <>
            <StyledFormButton variant="contained" color="primary" onClick={handleClickGegenpruefungErlaeterung}>
                <AddComment fontSize={'large'} />
            </StyledFormButton>
            {open && (
                <Draggable
                    handle={'#gegenpruefung-erlaeuterung-handle'}
                    bounds={'#Content'}
                    position={{ x: x, y: y }}
                    onDrag={onDrag}
                >
                    <StyledPaper ref={paperRef}>
                        <Typography variant="h2" id="gegenpruefung-erlaeuterung-handle">
                            Erläuterungen zur Vier-Augen-Prüfung
                            <CloseButton onClick={() => setOpen(false)} containerClassName={classes.closeButton} />
                        </Typography>
                        {gegenpruefung.status === GEGENPRUEFUNG_STATUS.IN_PRUEFUNG ? (
                            <HtmlEditor
                                value={gegenpruefung.erlaeuterung ?? undefined}
                                onBlur={(value) =>
                                    submit(gegenpruefung.id, {
                                        erlaeuterung: value,
                                    })
                                }
                                resizeable={true}
                            />
                        ) : (
                            <StyledReadOnlyContainer
                                dangerouslySetInnerHTML={{ __html: String(gegenpruefung.erlaeuterung) }}
                            />
                        )}
                    </StyledPaper>
                </Draggable>
            )}
        </>
    );
};
