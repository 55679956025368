import { MUIDataTableColumnOptions } from 'mui-datatables';

import { CheckboxesFilter } from 'components/DataTableHydra/Filters/CheckboxesFilter';
import { EINRICHTUNG_ART_FILTER_LABELS } from 'constants/labels';

export const EinrichtungArtFilterOptions: MUIDataTableColumnOptions = {
    customFilterListOptions: {
        render: (value) => value.map((v: string) => `Art: ${EINRICHTUNG_ART_FILTER_LABELS[v]}`),
    },
    filterOptions: {
        names: Object.keys(EINRICHTUNG_ART_FILTER_LABELS),
        display: (filterList, onChange, index, column, filterData) => (
            <CheckboxesFilter
                index={index}
                column={column}
                filterData={filterData[index]}
                filterValue={filterList[index]}
                filterLabels={EINRICHTUNG_ART_FILTER_LABELS}
                onChange={onChange}
            />
        ),
    },
    filterType: 'custom',
};
