import React, { useRef } from 'react';
import { CKEditor } from 'ckeditor4-react';

interface HtmlEditorProps {
    value?: string | undefined;
    disabled?: boolean;
    onBlur?: (value: string) => void;
    onChange?: (value: string) => void;
    resizeable?: boolean;
    id?: string;
}

export const HtmlEditor: React.FC<HtmlEditorProps> = ({
    value,
    disabled = false,
    onBlur,
    onChange,
    resizeable = false,
    id,
    ...other
}) => {
    const ref = useRef<HTMLDivElement>(null);
    return (
        <div id={id} ref={ref} {...other}>
            <CKEditor
                editorUrl={process.env.PUBLIC_URL + '/ckeditor/ckeditor.js'}
                readOnly={disabled}
                config={{
                    toolbarGroups: [
                        { name: 'clipboard', groups: ['undo'] },
                        { name: 'styles', groups: ['styles'] },
                        {
                            name: 'basicstyles',
                            groups: ['basicstyles', 'cleanup'],
                        },
                        {
                            name: 'paragraph',
                            groups: ['list', 'align'],
                        },
                    ],
                    removeButtons:
                        'Subscript,Superscript,Anchor,Table,SpecialChar,Image,Maximize,Source,Blockquote,Styles,About,HorizontalRule,Cut,Copy,Paste,PasteText,PasteFromWord',
                    autoGrow_minHeight: 300,
                    autoGrow_minWidth: 300,
                    resize_dir: 'both',
                    extraPlugins: 'justify',
                    removePlugins: 'elementspath',
                    resize_enabled: resizeable,
                }}
                initData={value}
                onBlur={(e) => onBlur && onBlur(trim(e.editor.getData()))}
                onChange={(e) => onChange && onChange(trim(e.editor.getData()))}
                onInstanceReady={(e) => {
                    e.editor.dataProcessor.writer.setRules('p', {
                        breakAfterClose: false,
                    });
                }}
                onFocus={() =>
                    ref?.current?.scrollIntoView({
                        block: 'center',
                        behavior: 'smooth',
                    })
                }
            />
        </div>
    );
};

const trim = (value: string) => (value.endsWith('\n') ? value.substr(0, value.length - 1) : value);
