import React from 'react';
import { LayoutProps, rankWith, UISchemaElement } from '@jsonforms/core';
import { withJsonFormsLayoutProps } from '@jsonforms/react';

import { GroupListItem } from 'forms/controls/GroupList/GroupListItem';
import { GridLayoutConfig, UiSchemaType } from 'forms/types/UiSchemaTypes';

import { useControlGroupsOfElements } from './hooks/useControlGroupsOfElements';

export type ControlGroupsOfElementsUISchema = UISchemaElement & {
    labelScope: string;
    scope: string;
    entries: UiSchemaType[];
    gridLayout?: GridLayoutConfig;
};

interface ControlGroupsOfElementsType extends Omit<LayoutProps, 'uischema'> {
    uischema: ControlGroupsOfElementsUISchema;
}

const ControlGroupsOfElementsComponent: React.FC<ControlGroupsOfElementsType> = ({
    schema,
    path,
    uischema,
    data,
    config,
}) => {
    const groups = useControlGroupsOfElements(data, schema, uischema.scope, uischema.labelScope, path);
    return (
        <>
            {groups.map((group, index) => (
                <GroupListItem
                    key={index}
                    elements={uischema.entries}
                    group={group}
                    gridLayout={uischema.gridLayout}
                    config={config}
                />
            ))}
        </>
    );
};
// @ts-ignore
export const ControlGroupsOfElements = withJsonFormsLayoutProps(ControlGroupsOfElementsComponent);

export const ControlGroupsOfElementsTester = rankWith(1, (uischema) => {
    return uischema.type === 'ControlGroupsOfElements';
});
