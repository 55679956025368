import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/AntragForm/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { groupForm } from 'forms/AntragForm/ui-schemas/elements/groupForm';
import { inlineGroupRowControl } from 'forms/AntragForm/ui-schemas/elements/inlineGroupRowControl';

export const uiSchema = customVerticalLayout([
    group([
        control('field110'),
        groupForm(
            '#/properties/belegungPlaetze',
            'Jahr {#/properties/jahr}',
            [
                inlineGroupRowControl('belegungPlaetze', 'field572', {
                    gridSize: 3,
                    action: { label: 'Zeile nullen', value: 0 },
                }),
                inlineGroupRowControl('belegungPlaetze', 'field574', {
                    gridSize: 3,
                    action: { label: 'Zeile nullen', value: 0 },
                }),
                inlineGroupRowControl('gesamtbelegung', 'field571', {
                    gridSize: 3,
                    action: { label: 'Zeile nullen', value: 0 },
                }),
                inlineGroupRowControl('gesamtbelegung', 'field573', {
                    gridSize: 3,
                    action: { label: 'Zeile nullen', value: 0 },
                }),
            ],
            {
                gridLayout: {
                    fieldnumber: 1,
                    label: 3,
                    input: 6,
                },
            }
        ),
    ]),
    group([
        control('field5175'),
        control('field5184a'),
        control('field5187a'),
        control('field5176'),
        control('field1109a'),
        control('field1109b'),
        control('field1109c'),
        control('field5194'),
        control('field5197'),
        control('field1177'),
        control('field1178'),
        control('field5192'),
    ]),
]);
