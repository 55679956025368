import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';

export const uiSchema = verticalLayout([
    group(
        [
            {
                type: 'FormExtendButton',
                label: 'Zuschuss hinzufügen',
                scope: '#/properties/zuschuesseFiktivesSalg',
                uiSchema: {
                    type: 'CustomVerticalLayout',
                    elements: [
                        control('field1745', {
                            options: { firstControl: true },
                        }),
                        control('field1746'),
                        control('field1747'),
                        control('field1748'),
                    ],
                },
            },
        ],
        {}
    ),
    group([control('field1749'), control('field1750')]),
]);
