import React from 'react';
import { FormLabel, Grid, MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { FestsetzungsAntragCollectionResponse, FeststellungsAntragCollectionResponse } from 'api/types';
import { formatDateToString } from 'forms/utils/formatter';

type AntragshistorieFeststellungSelectProps = {
    type: 'Feststellung';
    value: FeststellungsAntragCollectionResponse | null;
    onChange: (value: FeststellungsAntragCollectionResponse | null) => void;
    options: FeststellungsAntragCollectionResponse[];
};

type AntragshistorieFestsetzungSelectProps = {
    type: 'Festsetzung';
    value: FestsetzungsAntragCollectionResponse | null;
    onChange: (value: FestsetzungsAntragCollectionResponse | null) => void;
    options: FestsetzungsAntragCollectionResponse[];
};

type AntragshistorieSelectProps = AntragshistorieFeststellungSelectProps | AntragshistorieFestsetzungSelectProps;

export const AntragshistorieSelect = ({ type, value, onChange, options }: AntragshistorieSelectProps) => {
    const handleValueChange = (event: SelectChangeEvent<number>) => {
        const selectedId = event.target.value;

        onChange(
            (options as (FestsetzungsAntragCollectionResponse | FeststellungsAntragCollectionResponse)[]).find(
                (antrag) => antrag.id === selectedId
            ) ?? null
        );
    };

    return (
        <Grid container>
            <Grid item xs={5} md={3}>
                <FormLabel component={'legend'}>Historie {type}</FormLabel>
            </Grid>
            <Grid item xs={7} md={7}>
                <Select margin={'dense'} value={value?.id ?? -1} onChange={handleValueChange} displayEmpty fullWidth>
                    <MenuItem selected value={-1}>
                        <em>- Bitte wählen -</em>
                    </MenuItem>
                    {options.map((antrag) => (
                        <MenuItem selected value={antrag.id} key={antrag.id}>
                            {`Antrag vom ${formatDateToString(antrag.eingereichtAt)} auf ${type} zum ${
                                type === 'Feststellung'
                                    ? formatDateToString(
                                          (antrag as FeststellungsAntragCollectionResponse)?.data?.field102
                                      )
                                    : formatDateToString(
                                          (antrag as FestsetzungsAntragCollectionResponse)?.data?.field500
                                      )
                            }`}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
        </Grid>
    );
};
