import React from 'react';
import { Button, Grid } from '@mui/material';

import { Step } from 'layout/HeaderConfig';
import { useAntragNavigation } from 'pages/Antraege/components/useAntragNavigation';

export const NavigationControls: React.FC<{
    steps: Step<string>[];
    backUrl: string;
    stepPathBuilder: (id: string, step?: string) => string;
    sidebarSteps?: Step<string>[];
    position?: 'left' | 'right';
}> = ({ sidebarSteps, steps, stepPathBuilder, backUrl, position = 'left' }) => {
    const { handleGoNext, hasNextStep, handleGoBack } = useAntragNavigation(
        sidebarSteps,
        steps,
        stepPathBuilder,
        backUrl
    );
    return (
        <Grid container spacing={2} item xs={12} justifyContent={position === 'left' ? 'flex-start' : 'flex-end'}>
            <Grid item>
                <Button variant={'outlined'} onClick={handleGoBack}>
                    {'zurück'}
                </Button>
            </Grid>
            <Grid item>
                {hasNextStep && (
                    <Button variant={'contained'} color={'primary'} onClick={handleGoNext}>
                        {'weiter'}
                    </Button>
                )}
            </Grid>
        </Grid>
    );
};
