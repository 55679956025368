import React from 'react';

import { useApiEinrichtungItem } from 'api/hooks/useApiClient';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { ErrorAlert } from 'forms/controls/components/ErrorAlert';
import { formatDateToString, formatDezimal } from 'forms/utils/formatter';
import { Verwaltung } from 'pages/Verwaltung/components/LayoutComponents';
import { toYear } from 'utilities/DateUtils';

interface AktuelleBescheideType {
    id: string;
}

export const AktuelleBescheide: React.FC<AktuelleBescheideType> = ({ id }) => {
    const { data, error, isLoading } = useApiEinrichtungItem(id);
    const feststellungsantrag = data?.letzterBeschiedenerFeststellungsantrag;
    const festsetzungsantrag = data?.letzterBeschiedenerFestsetzungsantrag;

    return (
        <AppLoaderContainer isLoading={Boolean(isLoading)} minHeight={300}>
            {error && <ErrorAlert errors={[{ message: 'Einrichtung konnte nicht geladen werden' }]} />}
            {!error && (
                <Verwaltung.Container>
                    <Verwaltung.Group title={'Zuletzt/insgesamt beschiedene Werte Feststellung'}>
                        <Verwaltung.InfoField
                            label={'Bescheid vom'}
                            value={formatDateToString(feststellungsantrag?.letzteBescheidung?.gesendetAt)}
                            textAlign={'right'}
                        />
                        <Verwaltung.InfoField
                            label={'Folgefeststellung zum'}
                            value={formatDateToString(feststellungsantrag?.data?.field102)}
                            textAlign={'right'}
                        />
                        <Verwaltung.InfoField
                            label={'Restwert zu anerkanntem Aufwand für Maßnahmen nach dem 02.11.2014'}
                            value={formatDezimal(feststellungsantrag?.data?.fieldSumme0519, '€')}
                            textAlign={'right'}
                        />
                        <Verwaltung.InfoField
                            label={'SALG'}
                            value={formatDezimal(feststellungsantrag?.data?.field0720, '€')}
                            textAlign={'right'}
                        />
                        <Verwaltung.InfoField
                            label={'Grundstück'}
                            value={formatDezimal(feststellungsantrag?.data?.field0301, 'm²')}
                            textAlign={'right'}
                        />
                        <Verwaltung.InfoField
                            label={'NRF'}
                            value={formatDezimal(feststellungsantrag?.data?.field121, 'm²')}
                            textAlign={'right'}
                        />
                    </Verwaltung.Group>
                    <Verwaltung.Group title={'Aktueller Festsetzungszeitraum'}>
                        <Verwaltung.InfoField
                            label={'Wirkungszeitraum'}
                            value={`${toYear(festsetzungsantrag?.data?.field500)} - ${toYear(
                                festsetzungsantrag?.data?.field1054
                            )}`}
                            textAlign={'right'}
                        />

                        <Verwaltung.InfoField
                            label={'Datum'}
                            value={formatDateToString(
                                festsetzungsantrag?.feststellungsantrag?.letzteBescheidung?.gesendetAt
                            )}
                            textAlign={'right'}
                        />

                        <Verwaltung.InfoField
                            label={'Eigentum/Miete'}
                            value={festsetzungsantrag?.feststellungsantrag?.data?.berechnungsart}
                            textAlign={'right'}
                        />

                        <Verwaltung.InfoField
                            label={'Bescheid vom'}
                            value={formatDateToString(festsetzungsantrag?.letzteBescheidung?.gesendetAt)}
                            textAlign={'right'}
                        />

                        <Verwaltung.InfoField
                            label={'Höhe Investkosten EZ'}
                            value={formatDezimal(
                                festsetzungsantrag?.data?.field5111 || festsetzungsantrag?.data?.field5171,
                                '€'
                            )}
                            textAlign={'right'}
                        />

                        <Verwaltung.InfoField
                            label={'Höhe Investkosten DZ'}
                            value={formatDezimal(festsetzungsantrag?.data?.field5129, '€')}
                            textAlign={'right'}
                        />

                        {festsetzungsantrag?.data?.zimmerkategorien?.map((zimmerkategorie) => (
                            <Verwaltung.InfoField
                                key={zimmerkategorie.field550}
                                label={`Höhe Investkosten ${zimmerkategorie.field550}`}
                                value={formatDezimal(zimmerkategorie.field5151, '€')}
                                textAlign={'right'}
                            />
                        ))}
                    </Verwaltung.Group>
                </Verwaltung.Container>
            )}
        </AppLoaderContainer>
    );
};
