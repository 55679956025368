import React from 'react';
import HtmlParser from 'react-html-parser';
import { Checkbox, Grid, styled, TableCell, TableRow, Typography } from '@mui/material';
import { Property } from 'csstype';

import { FormulaPopover } from 'forms/AntragForm/components/FormulaPopover';
import { MissingRequiredFieldsIcon } from 'forms/controls/components/MissingRequiredFieldsIcon';
import { UploadedDocument } from 'forms/controls/components/UploadedDocument';
import { useErrorNavigate } from 'forms/controls/SummaryTable/useErrorNavigate';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';
import { FormConfig, FormStateValue, Schema, TableColumn } from 'forms/types/UiSchemaTypes';
import { formatFieldValue, isNumeric } from 'forms/utils/SchemaUtils';
import { transientOptions } from 'theme/utils';
import { getFieldNumber } from 'utilities/ScopeUtils';

type FieldRowInterface = CustomControlProps;

const FieldRowControl: React.FC<FieldRowInterface> = ({ data, path, schema, label, config, hasErrors }) => {
    return (
        <FieldRowComponent
            field={getFieldNumber(path) || ''}
            label={label}
            path={path}
            value={config.mapUuid ? config.mapUuid(data) || data : data}
            valueSchema={schema as Schema}
            error={Boolean(hasErrors)}
            config={config}
        />
    );
};

interface FieldRowComponentInterface {
    field: string;
    label: string;
    path: string;
    value: FormStateValue;
    valueSchema: Schema;
    showValueLabel?: boolean;
    error?: boolean;
    config: FormConfig;
}

export const FieldRowComponent: React.FC<FieldRowComponentInterface> = ({
    field,
    label,
    path,
    value,
    valueSchema,
    showValueLabel = false,
    error = false,
    config,
}) => {
    const navigate = useErrorNavigate(path);
    return (
        <ZusammenfassungTableRow $error={error} data-cy={`FieldRow-${field}`}>
            <FieldCell>
                {error && <ErrorIcon onClick={navigate} />}
                {field}
            </FieldCell>
            <TableCell>{label}</TableCell>
            <ValueCell>
                <TableCellValue
                    data={value}
                    path={path}
                    config={config}
                    schema={valueSchema}
                    showLabel={showValueLabel}
                    textAlign={isNumeric(valueSchema) ? 'right' : 'left'}
                />
            </ValueCell>
        </ZusammenfassungTableRow>
    );
};

export const TableCellValue = ({
    data,
    path,
    config,
    schema,
    showLabel = false,
    column,
    textAlign = 'left',
}: {
    data: FormStateValue;
    path: string;
    config: FormConfig;
    schema: Schema;
    showLabel?: boolean;
    column?: TableColumn;
    textAlign?: Property.TextAlign;
}) => {
    if (data === undefined || data === null) {
        return (
            <Grid container spacing={1}>
                <Grid item xs={11} container justifyContent={textAlign === 'right' ? 'flex-end' : 'flex-start'}>
                    <Typography style={{ textAlign }}>{column?.nullValue || '-'}</Typography>
                </Grid>
                {schema?.custom?.formula && (
                    <Grid item xs={1} container justifyContent={'center'}>
                        <FormulaPopover paths={[path]} config={config} formula={schema.custom.formula} />
                    </Grid>
                )}
            </Grid>
        );
    }

    if (schema?.custom?.block_prefixes.includes('upload')) {
        return <UploadedDocument fileData={data} readonly />;
    }
    if (schema?.custom?.block_prefixes.includes('uploads')) {
        return data?.map((d: FormStateValue) => <UploadedDocument key={d.uuid} fileData={d} readonly />) || <></>;
    }
    if (schema?.custom?.block_prefixes.includes('checkbox') && !schema?.custom?.block_prefixes.includes('ja_nein')) {
        return (
            <div style={{ display: 'inline-block' }}>
                <Checkbox style={{ padding: 0, marginRight: 8, float: 'left' }} checked={data} disabled />
                {showLabel && <Typography component={'span'}>{schema.title}</Typography>}
            </div>
        );
    }
    const value = formatFieldValue(data, schema, column?.format, column?.unit);
    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={11} container justifyContent={textAlign === 'right' ? 'flex-end' : 'flex-start'}>
                    <Typography style={{ textAlign }} component="div">
                        <div style={{ float: 'left' }}>
                            {HtmlParser(
                                value === undefined
                                    ? column?.nullValue || '-'
                                    : column?.valueLabel
                                    ? column?.valueLabel.replace('{value}', value)
                                    : value
                            )}
                        </div>
                    </Typography>
                </Grid>

                {schema?.custom?.formula && (
                    <Grid item xs={1} container justifyContent={'center'}>
                        <FormulaPopover paths={[path]} config={config} formula={schema.custom.formula} />{' '}
                    </Grid>
                )}
            </Grid>
        </>
    );
};

const FieldCell = styled(TableCell)(() => ({
    width: 110,
}));

const ValueCell = styled(TableCell)(() => ({
    width: 400,
}));

const ErrorIcon = styled(MissingRequiredFieldsIcon)(({ onClick }) => ({
    marginRight: 2,
    cursor: Boolean(onClick) ? 'pointer' : 'default',
}));

const ZusammenfassungTableRow = styled(
    TableRow,
    transientOptions
)<{ $error: boolean }>(({ theme, $error }) => ({
    backgroundColor: $error ? theme.palette.error.lighter : undefined,
}));

export const FieldRow = withCustomControlProps(FieldRowControl, { showErrorsImmediately: true });
