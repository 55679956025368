import React from 'react';
import { LayoutProps } from '@jsonforms/core';
import { JsonFormsDispatch } from '@jsonforms/react';
import { Box, Grid, styled, Typography } from '@mui/material';

import { applyGridLayout } from 'forms/AntragForm/ui-schemas/elements/gridLayoutConfig';
import { useLabel } from 'forms/hooks/useLabel';
import { withLayoutProps } from 'forms/hooks/withJsonFormProps';
import { GroupControlType, Schema } from 'forms/types/UiSchemaTypes';
import { useControlVisibility } from 'forms/utils/UiSchemaUtils';

export const StyledGroup = styled(Box)(
    ({ theme }) => `
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  padding-bottom: ${theme.spacing(1.5)};

  & h2 {
      margin: ${theme.spacing(4)}  0;
  }

  & h3 {
      margin: ${theme.spacing(2)}  0;
      font-weight: bold;
  }

  & .no-label-group {
    margin-top: ${theme.spacing(2)};
  }
`
);

export const NoSeparatorGroup = styled(StyledGroup)(
    ({ theme }) => `border-bottom: 0px; padding-bottom: ${theme.spacing(1)};`
);

interface GroupControlComponentType extends Omit<LayoutProps, 'uischema'> {
    uischema: GroupControlType;
}

const GroupControlComponent: React.FC<GroupControlComponentType> = ({
    uischema,
    schema,
    path,
    visible,
    renderers,
    data,
}) => {
    const controlVisibility = useControlVisibility(uischema);
    const label = useLabel(data, schema as Schema, uischema);
    const content = visible && controlVisibility && (
        <>
            <GroupHeader header={label} />
            <Grid container spacing={1} className={!label ? 'no-label-group' : undefined}>
                {uischema.elements.map((elem, i) => {
                    return (
                        <Grid item xs={12} key={i}>
                            <JsonFormsDispatch
                                uischema={applyGridLayout(elem, uischema.gridLayout)}
                                schema={schema}
                                path={path}
                                renderers={renderers}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </>
    );

    const styled = !uischema?.options?.unstyled;

    const noSeparator = Boolean(uischema?.options?.noSeparator);

    if (!visible || !controlVisibility) {
        return <></>;
    }

    return (
        <>
            {(noSeparator && <NoSeparatorGroup>{content}</NoSeparatorGroup>) ||
                (styled && <StyledGroup>{content}</StyledGroup>) || <Box>{content}</Box>}
        </>
    );
};

export const GroupHeader: React.FC<{
    header: string | undefined;
    className?: string;
}> = ({ header, className }) => {
    if (!header) {
        return <></>;
    }
    return (
        <Typography className={className} variant="h2">
            {header}
        </Typography>
    );
};

export const StyledGroupHeader = styled(GroupHeader)(
    ({ theme }) => `
      margin: ${theme.spacing(4)}  0;
`
);

export const GroupControl = withLayoutProps(GroupControlComponent);
