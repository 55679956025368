import { FormDialogUiSchemaType, LabeledType, UiSchemaType } from 'forms/types/UiSchemaTypes';

interface FormDialogScope {
    scope?: string;
    actionKey?: string | null;
}

export const formDialog = (
    title: string,
    editTitle: string,
    subTitle: LabeledType,
    uiSchema: UiSchemaType,
    options?: FormDialogScope
): FormDialogUiSchemaType => ({
    title,
    editTitle,
    subTitle,
    uiSchema,
    ...options,
});
