import { accordion } from 'forms/AntragForm/ui-schemas/elements/accordion';
import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { dataGrid } from 'forms/AntragForm/ui-schemas/elements/dataGrid';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';

export const uiSchema = verticalLayout([
    group([
        control('field5611'),
        control('field5612'),
        control('field5613'),
        control('field5614'),
        control('field5615'),
        control('field5616'),
        control('field5617'),
        control('field5630'),
        control('field5618'),
    ]),
    accordion(
        [
            dataGrid(
                'kapitalBasis',
                [
                    {
                        scope: '#/properties/monat',
                        valueLabel: 'Monat {value}',
                        textAlign: 'left',
                    },
                    {
                        label: '5619 Kapitalbasis',
                        scope: '#/properties/field5619',
                        format: 'currency',
                        unit: '€',
                        highlighted: true,
                        textAlign: 'right',
                        styles: {
                            textAlign: 'right',
                            paddingRight: 16,
                        },
                    },
                    {
                        label: '5620 Zinsbetrag',
                        scope: '#/properties/field5620',
                        format: 'currency',
                        unit: '€',
                        styles: {
                            textAlign: 'right',
                        },
                    },
                ],
                { containerGridSize: 7 }
            ),
        ],
        'Darlehensbasis und Zinsen je Jahr'
    ),
    group([
        control('field5621'),
        control('field5622'),
        control('field5624'),
        control('field5623'),
        control('field1776'),
        control('field5625'),
        control('field5626'),
        control('field5627'),
        control('field2022'),
        control('field1821'),
        control('field5628'),
        control('field5629'),
    ]),
]);
