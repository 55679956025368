import React, { useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { BenutzerListResponse } from 'api/types';
import { GetColumns } from 'components/DataTableHydra/hooks/useTableColumns';
import { BenutzerListStatusIcon } from 'pages/Verwaltung/Benutzer/BenutzerList/BenutzerListStatusIcon';
import { displayBenutzerName } from 'utilities';
import { centerAlignedColumn } from 'utilities/DataTableHydraUtils';

export const useTraegerverbandBenutzerListColumns = (): GetColumns<BenutzerListResponse> => {
    return useCallback((data) => {
        if (!data) return [];

        const name: MUIDataTableColumnDef = {
            name: 'name',
            label: 'Name',
            options: {
                customBodyRenderLite: (index) => {
                    return displayBenutzerName(data['hydra:member'][index]);
                },
            },
        };

        const email: MUIDataTableColumnDef = {
            name: 'email',
            label: 'E-Mail',
        };

        const telefon: MUIDataTableColumnDef = {
            name: 'telefon',
            label: 'Telefon',
        };

        const fax: MUIDataTableColumnDef = {
            name: 'fax',
            label: 'Fax',
        };

        const status: MUIDataTableColumnDef = {
            name: 'status',
            label: 'Status',
            options: {
                ...centerAlignedColumn,
                customBodyRenderLite: (index) => {
                    return <BenutzerListStatusIcon benutzer={data['hydra:member'][index]} />;
                },
            },
        };

        return [name, email, telefon, fax, status];
    }, []);
};
