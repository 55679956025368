import { Typography } from '@mui/material';

import { ContentContainer } from 'layout/container/ContentContainer';
import { withSideBar } from 'layout/hooks/useSideBar';

const Index = () => (
    <ContentContainer title="Statistik">
        <Typography variant="h1">Statistik</Typography>
    </ContentContainer>
);

export const StatistikPage = withSideBar(Index, []);
