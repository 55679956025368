import React, { ReactElement, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { useAuthHelper } from 'api/auth/useAuthHelper';
import { EinrichtungResponse } from 'api/types';
import { ROLES } from 'constants/roles';
import { FormButton } from 'forms/components/FormButton';
import { CreateBenutzerFormDialog } from 'pages/Verwaltung/Benutzer/BenutzerErstellenForm/CreateBenutzerFormDialog';
import { useIsAntragsteller } from 'pages/Verwaltung/Pflegeeinrichtungen/Uebersicht/Benutzer/useIsAntragsteller';

type BenutzerErstellenForEinrichtungDialogProps = {
    einrichtung: EinrichtungResponse | undefined;
    onComplete: () => void;
};

export const PflegeeinrichtungBenutzerErstellen = ({
    einrichtung,
    onComplete,
}: BenutzerErstellenForEinrichtungDialogProps): ReactElement => {
    const { hasRole } = useAuthHelper();
    const [isOpen, setOpen] = useState(false);
    const isAntragsteller = useIsAntragsteller(einrichtung);

    const onOpen = () => setOpen(true);
    const onClose = () => setOpen(false);

    const isBenutzerAddAllowed =
        hasRole([ROLES.FACHADMIN, ROLES.SUPPORT]) || (hasRole(ROLES.ANBIETER) && einrichtung && isAntragsteller);

    if (!isBenutzerAddAllowed) return <></>;
    return (
        <>
            <FormButton onClick={onOpen} variant="contained" color="primary">
                <AddCircleIcon />
                Benutzer zu dieser Einrichtung hinzufügen
            </FormButton>
            {isOpen && (
                <CreateBenutzerFormDialog einrichtung={einrichtung ?? null} onClose={onClose} onComplete={onComplete} />
            )}
        </>
    );
};
