import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { info } from 'forms/AntragForm/ui-schemas/elements/info';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';
import { notes } from 'forms/AntragForm/ui-schemas/groups/notes';

export const uiSchema = verticalLayout([
    group(
        [
            info(
                'Bitte geben Sie hier Einnahmen an, die Sie unabhängig von dem Betrieb der Einrichtung mit den Anlagegütern erzielen, ' +
                    'für die Sie in Ihrem Antrag Aufwendungen geltend machen. ' +
                    'Hierzu gehören z.B. Einnahmen aus der Vermietung von Flächen, die z.B. für einen Friseurbetrieb in der Einrichtung genutzt werden.'
            ),
            control('field590'),
            control('field591'),
        ],
        {}
    ),
    notes('notizErloese'),
]);
