import { ReactElement } from 'react';
import { JsonForms } from '@jsonforms/react';
import { Grid } from '@mui/material';

import { BenutzerResponse } from 'api/types';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { Dialog } from 'components/Dialog/Dialog';
import { FormButton } from 'forms/components/FormButton';
import { useLayoutWithJsonFormsState } from 'forms/hooks/useJsonFormsState';
import { renderers } from 'forms/renderers';
import { useBenutzerBearbeitenForm } from 'pages/Verwaltung/Benutzer/BenutzerBearbeitenForm/useBenutzerBearbeitenForm';

interface BenutzerBearbeitenFormProps {
    benutzer: BenutzerResponse | undefined;
    onClose: () => void;
}

export const BenutzerBearbeitenForm = ({ benutzer, onClose }: BenutzerBearbeitenFormProps): ReactElement => {
    return (
        <Dialog title="Benutzer bearbeiten" open={Boolean(benutzer)} onClose={onClose}>
            {benutzer && <BenutzerBearbeitenFormContent onClose={onClose} initialData={benutzer} />}
        </Dialog>
    );
};

interface BenutzerBearbeitenFormContentProps {
    initialData: BenutzerResponse;
    onClose: () => void;
}

const BenutzerBearbeitenFormContent = ({ initialData, onClose }: BenutzerBearbeitenFormContentProps): ReactElement => {
    const { schema, data, isValid, isLoading, onChange, onSubmit } = useBenutzerBearbeitenForm(initialData);
    const config = useLayoutWithJsonFormsState({
        gridLayout: {
            label: 3,
            input: 6,
        },
    });

    const onSave = async () => {
        await onSubmit();
        onClose();
    };

    return (
        <AppLoaderContainer isLoading={isLoading}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <JsonForms data={data} schema={schema} onChange={onChange} renderers={renderers} config={config} />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1} justifyContent="flex-end">
                        <Grid item>
                            <FormButton
                                type="button"
                                variant="outlined"
                                color="primary"
                                onClick={onClose}
                                disabled={false}
                            >
                                Zurück
                            </FormButton>
                        </Grid>
                        <Grid item>
                            <FormButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={onSave}
                                disabled={!isValid}
                            >
                                Änderungen speichern
                            </FormButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </AppLoaderContainer>
    );
};
