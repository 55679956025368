import { Typography } from '@mui/material';

import { AntragType } from 'api/antragTypes';
import { useColumnsMeldungP29 } from 'components/DataTableHydra/Columns/MeldungP29';
import { DataTableHydraServerside } from 'components/DataTableHydra/DataTableHydraServerside';
import { ROLES } from 'constants/roles';
import { ContentContainer } from 'layout/container/ContentContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { withAuthorization } from 'navigation/withAuthorization';
import { menuAntraege } from 'pages/Antraege/AntragConfig';
import { useAntragFilterOptions } from 'pages/Antraege/components/useAntragFilterOptions';

import { useMeldungP29ListActions } from './useMeldungP29ListActions';
import { useMeldungP29ListData } from './useMeldungP29ListData';

export const MeldungP29List = () => {
    const getData = useMeldungP29ListData();
    const { handleAction, serverDataChanged } = useMeldungP29ListActions(AntragType.MELDUNG_P29);
    const filterOptions = useAntragFilterOptions(AntragType.MELDUNG_P29);
    const getColumns = useColumnsMeldungP29(handleAction, filterOptions);

    return (
        <ContentContainer title="Meldungen nach § 29">
            <Typography variant="h1">Meldungen nach § 29</Typography>
            <DataTableHydraServerside
                getColumns={getColumns}
                getData={getData}
                initialOptions={{
                    searchPlaceholder: 'Suche nach Einrichtungsname, Aktenzeichen oder NRW-Schlüssel',
                    sortOrder: {
                        name: 'zuletztEingereichtAt',
                        direction: 'desc',
                    },
                }}
                serverDataChanged={serverDataChanged}
            />
        </ContentContainer>
    );
};

export const MeldungenP29Page = withAuthorization(withSideBar(MeldungP29List, menuAntraege), [
    ROLES.FACHADMIN,
    ROLES.SUPPORT,
    ROLES.LV,
    ROLES.ANBIETER,
]);
