import React from 'react';
import { styled } from '@mui/material';
import MUIDataTable, {
    MUIDataTableColumn,
    MUIDataTableColumnDef,
    MUIDataTableOptions,
    MUIDataTableTextLabels,
} from 'mui-datatables';

import { AppLoaderContainer } from 'components/AppLoaderContainer';

export interface DataTableHydraProps {
    title?: string;
    data: any;
    isLoading?: boolean;
    columns: MUIDataTableColumnDef[];
    options: MUIDataTableOptions;
    tableRef?: React.RefObject<DataTableController | null | undefined>;
}

export interface DataTableController {
    resetFilters: () => void;
}

const StyledDataTable = styled(MUIDataTable)(
    () => `
    & .MuiTableRow-root:nth-of-type(even) {
        background: #f2f2f2;
    }
    & .MuiTableRow-root:nth-of-type(even):hover {
        background: rgba(0, 0, 0, 0.04);
    }
    & .MuiTableFooter-root .MuiTableRow-root {
        background: #fff;
    }
    & .MuiTableBody-root .MuiTableRow-root {
        height: 80px;
    }

    &.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation4 { overflow-x: auto; }
`
);

export const DataTableHydra = ({ isLoading, data, columns, title, options, tableRef }: DataTableHydraProps) => {
    const textLabels: Partial<MUIDataTableTextLabels> = {
        body: {
            noMatch: isLoading ? 'Daten werden geladen...' : 'Keine Daten gefunden.',
            toolTip: 'Sortieren',
            columnHeaderTooltip: (column: MUIDataTableColumn) => `Sortierung nach ${column.label}`,
        },
        pagination: {
            next: 'Nächste Seite',
            previous: 'Vorherige Seite',
            rowsPerPage: 'Zeilen pro Seite:',
            displayRows: 'von',
        },
        toolbar: {
            search: 'Suche',
            downloadCsv: 'Download CSV',
            print: 'Drucken',
            viewColumns: 'Spalten anzeigen',
            filterTable: 'Filter Tabelle',
        },
        filter: {
            all: 'Alle',
            title: 'FILTER',
            reset: 'ZURÜCKSETZEN',
        },
        viewColumns: {
            title: 'Spalten anzeigen',
            titleAria: 'Spalten anzeigen/verbergen',
        },
        selectedRows: {
            text: 'Zeile(n) selektiert',
            delete: 'Löschen',
            deleteAria: 'Ausgewählte Zeile(n) löschen',
        },
    };

    return (
        <AppLoaderContainer isLoading={!!isLoading && undefined === data}>
            <StyledDataTable
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore Typings of mui-datatable are wrong!
                ref={tableRef}
                columns={columns}
                data={(!isLoading && data && data['hydra:member']) || []}
                title={title}
                options={{
                    selectableRows: 'none',
                    responsive: 'standard',
                    count: (data && data['hydra:totalItems']) || 0,
                    rowsPerPageOptions: [10, 25, 50],
                    textLabels,
                    ...options,
                }}
            />
        </AppLoaderContainer>
    );
};
