import React from 'react';
import { LayoutProps } from '@jsonforms/core';
import { JsonFormsDispatch, withJsonFormsLayoutProps } from '@jsonforms/react';
import { Grid, GridSize, styled } from '@mui/material';

import { prepareUISchema } from 'forms/AntragForm/hooks/useStepUISchema';
import { useJsonFormsState } from 'forms/hooks/useJsonFormsState';
import { renderers } from 'forms/renderers';
import { InlineRowControlType, Schema } from 'forms/types/UiSchemaTypes';
import { getFieldSchemaByScope, getParentSchemaOfField } from 'forms/utils/SchemaUtils';
import { getFieldFromScope } from 'utilities';

import { RowControl } from './RowControl';

interface InlineRowControlProps extends Omit<LayoutProps, 'uischema' | 'schema'> {
    uischema: InlineRowControlType;
    schema: Schema;
}

const InlineRowControlComponent: React.FC<InlineRowControlProps> = ({
    schema,
    uischema,
    path,
}: InlineRowControlProps) => {
    const { config } = useJsonFormsState();
    const field = getFieldFromScope(uischema.scope)!;
    const fieldSchema = getFieldSchemaByScope(uischema.scope, schema);
    const parentSchema = getParentSchemaOfField(uischema.scope, schema);
    const preparedUiSchema = prepareUISchema(uischema, schema);
    const childSize = (12 / (preparedUiSchema.elements?.length || 1)) as GridSize;

    return (
        <RowControl
            name={field}
            label={fieldSchema?.title}
            required={parentSchema?.required?.includes(field)}
            showFieldNumberLabels={config.showFieldNumberLabels}
            gridLayout={uischema.gridLayout || config.gridLayout}
            config={config}
            paths={[path]}
        >
            {false !== uischema.grid ? (
                <Grid container spacing={2}>
                    {preparedUiSchema.elements?.map((child, index) => (
                        <Grid item xs={12} md={child.gridSize || childSize} key={index} container>
                            <JsonFormsDispatch schema={schema} uischema={child} path={path} renderers={renderers} />
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <div>
                    {preparedUiSchema.elements?.map((child, index) => (
                        <NoGridItem>
                            <JsonFormsDispatch
                                key={index}
                                schema={schema}
                                uischema={child}
                                path={path}
                                renderers={renderers}
                            />
                        </NoGridItem>
                    ))}
                </div>
            )}
        </RowControl>
    );
};

// @ts-ignore
export const InlineRowControl = withJsonFormsLayoutProps(InlineRowControlComponent);

const NoGridItem = styled('span')(() => ({
    display: 'inline-block',
    marginRight: 5,
    marginBottom: 5,
}));
