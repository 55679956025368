import React, { ReactElement } from 'react';
import Edit from '@mui/icons-material/Edit';
import { Button, Tooltip } from '@mui/material';

import { EinrichtungJsonldEinrichtungList } from 'api/client';
import { ZustaendigkeitenEinrichtungTableActions } from 'constants/zustaendigkeitenEinrichtungActions';

interface ZustaendigkeitenEinrichtungListActionsProps {
    einrichtung: EinrichtungJsonldEinrichtungList;
    handleAction: (
        action: ZustaendigkeitenEinrichtungTableActions,
        einrichtung: EinrichtungJsonldEinrichtungList
    ) => void;
}

export const ZustaendigkeitenEinrichtungListActions = ({
    einrichtung,
    handleAction,
}: ZustaendigkeitenEinrichtungListActionsProps): ReactElement => {
    return (
        <Tooltip title="Zuständigkeit ändern">
            <Button
                aria-haspopup="dialog"
                variant="text"
                onClick={() => handleAction(ZustaendigkeitenEinrichtungTableActions.BEARBEITEN, einrichtung)}
            >
                <Edit />
            </Button>
        </Tooltip>
    );
};
