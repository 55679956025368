import * as React from 'react';
import { ChangeEvent, useCallback, useRef } from 'react';

import { FileUploadResponse } from 'api/responses/FileUploadResponse';
import { FileUploadButton } from 'forms/controls/components/FileUploadButton';
import { ReadonlyValueText } from 'forms/controls/components/ReadonlyValueText';
import { UploadedDocument } from 'forms/controls/components/UploadedDocument';
import { useFileUpload } from 'forms/hooks/useFileUpload';
import { useIsDataChanged } from 'forms/hooks/useIsDataChanged';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';
import { UploadValue } from 'forms/types/UploadValueType';

import { RowControl } from './RowControl';

const UploadComponent: React.FC<CustomControlProps<UploadValue>> = ({
    path,
    label,
    required,
    data,
    handleChange,
    handleBlur,
    hasErrors,
    errors,
    disabled,
    readonly,
    showFieldNumberLabels,
    gridLayout,
    uischema,
    config,
    schema,
}) => {
    const isDataChanged = useIsDataChanged(data, schema);
    const autoFocus = useRef(false);
    const onUpload = useCallback(
        (responses: FileUploadResponse[]) => {
            if (!responses.length) {
                return;
            }
            handleChange(
                path,
                {
                    uploadId: responses[0].id,
                    originalName: responses[0].originalName,
                    contentUrl: responses[0].contentUrl,
                },
                true
            );
        },
        [handleChange, path]
    );

    const onRemove = useCallback(() => {
        handleChange(path, null, true);
        autoFocus.current = true;
    }, [handleChange, path]);

    const { uploadErrors, removeFile, isUploading, uploadFiles } = useFileUpload(onUpload, onRemove);

    const handleValueChange = useCallback(
        async (e: ChangeEvent<HTMLInputElement>) => {
            if (!e.target.files?.length) return;
            uploadFiles(e.target.files!);
            autoFocus.current = true;
        },
        [uploadFiles]
    );
    return (
        <RowControl
            name={path}
            label={label}
            required={required}
            showFieldNumberLabels={
                (uischema as any).showFieldNumberLabels !== undefined
                    ? (uischema as any).showFieldNumberLabels
                    : showFieldNumberLabels
            }
            gridLayout={gridLayout}
            controlOnly={uischema.controlOnly}
            hasChangedData={isDataChanged}
            config={config}
            paths={[path]}
        >
            {data ? (
                <UploadedDocument
                    fileData={data}
                    onRemove={() => removeFile(data.uploadId)}
                    readonly={readonly}
                    disabled={disabled}
                    autoFocus={autoFocus}
                />
            ) : readonly ? (
                <ReadonlyValueText path={path} hasErrors={hasErrors} errors={errors} text={'-'} />
            ) : (
                <FileUploadButton
                    isUploadingFile={isUploading}
                    hasErrors={hasErrors}
                    uploadErrors={uploadErrors}
                    errors={errors}
                    disabled={disabled}
                    onBlur={handleBlur}
                    onChange={handleValueChange}
                    autoFocus={autoFocus.current}
                />
            )}
        </RowControl>
    );
};

export const UploadControl = withCustomControlProps(UploadComponent);
