import { useMemo } from 'react';
import { Typography } from '@mui/material';

import { ContentContainer } from 'layout/container/ContentContainer';
import { Step } from 'layout/HeaderConfig';
import { withSideBar } from 'layout/hooks/useSideBar';
import { NavigationControls } from 'navigation/components/NavigationControls';
import { buildPath, buildRelativePath, PATH_VERWALTUNG, PATH_VERWALTUNG_ZUSTAENDIGKEITEN } from 'navigation/Paths';
import { TabRouterNavigation } from 'navigation/TabRouterNavigation';
import { withAuthorization } from 'navigation/withAuthorization';
import { VerwaltungConfig } from 'pages/Verwaltung/VerwaltungConfig';
import { ZustaendigkeitenRegionList } from 'pages/Verwaltung/Zustaendigkeiten/Bereich/ZustaendigkeitenRegionList';
import { ZustaendigkeitenEinrichtungList } from 'pages/Verwaltung/Zustaendigkeiten/Einrichtung/ZustaendigkeitenEinrichtungList';

const Zustaendigkeiten = () => {
    const tabs: Step<string>[] = useMemo(() => {
        return [
            {
                title: 'Zuständigkeiten je Einrichtung',
                step: 'zustaendigkeit_pro_einrichtung',
                component: <ZustaendigkeitenEinrichtungList />,
                state: 'active',
            },
            {
                title: 'Zuständigkeiten je Bereich',
                step: 'zustaendigkeit_pro_bereich',
                component: <ZustaendigkeitenRegionList />,
                state: 'active',
            },
        ];
    }, []);

    const basePath = buildPath(PATH_VERWALTUNG, PATH_VERWALTUNG_ZUSTAENDIGKEITEN);

    return (
        <ContentContainer title="Zuständigkeiten">
            <Typography variant="h1">Zuständigkeiten</Typography>
            <TabRouterNavigation
                tabs={tabs}
                buildNewPath={(newTab) => {
                    return buildRelativePath(basePath, newTab);
                }}
            />
            <NavigationControls
                steps={tabs}
                backUrl={basePath}
                stepPathBuilder={(step, newTab) => buildRelativePath(basePath, step, newTab)}
                position={'right'}
            />
        </ContentContainer>
    );
};

export const ZustaendigkeitenPage = withAuthorization(
    withSideBar(Zustaendigkeiten, VerwaltungConfig.menu),
    VerwaltungConfig.roles.zustaendigkeit
);
