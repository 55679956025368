import { useEffect } from 'react';

import { AntragResponse, AntragVersionResponse } from 'api/types';
import { useFormState } from 'forms/state/useFormState';

export const useAntragDetailsUuidMap = (version: AntragVersionResponse | undefined, antrag: AntragResponse) => {
    const { setUuidMap } = useFormState();

    useEffect(() => {
        if (version) {
            setUuidMap(version.uuidMap);
        } else {
            setUuidMap(antrag.uuidMap);
        }
    }, [version, antrag, setUuidMap]);
};
