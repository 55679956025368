import { useFormState } from 'forms/state/useFormState';

export type UuidMappingFunction = (uuid?: string) => string | undefined;

export const useUuidValue = (data?: string) => {
    const mapUuid = useUuidMapping();
    return getMappedUuidValue(mapUuid, data);
};

export const getMappedUuidValue = (mapUuid: UuidMappingFunction, data?: string): string | undefined =>
    data ? mapUuid(data) || data : data;

export const useUuidMapping = (): UuidMappingFunction => {
    const { mapUuid } = useFormState();
    const defaultMapping = (data?: string) => data;

    return mapUuid || defaultMapping;
};
