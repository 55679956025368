import React, { useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { useAuthHelper } from 'api/auth/useAuthHelper';
import { BenutzerListResponse } from 'api/types';
import { GetColumns } from 'components/DataTableHydra/hooks/useTableColumns';
import { BenutzerTableAction } from 'constants/benutzerActions';
import { ROLES } from 'constants/roles';
import { BenutzerListActions } from 'pages/Verwaltung/Benutzer/BenutzerList/BenutzerListActions';
import {
    BenutzerListEinrichtungFilter,
    BenutzerListEinrichtungFilterValue,
} from 'pages/Verwaltung/Benutzer/BenutzerList/BenutzerListEinrichtungFilter';
import { BenutzerListStatusIcon } from 'pages/Verwaltung/Benutzer/BenutzerList/BenutzerListStatusIcon';
import { displayBenutzerName } from 'utilities';
import { rightAlignedColumn } from 'utilities/DataTableHydraUtils';

export const useBenutzerListColumns = (handleAction: BenutzerTableAction): GetColumns<BenutzerListResponse> => {
    const { hasRole } = useAuthHelper();
    const { allowBenutzerBearbeiten, allowBenutzerDeaktivieren, addActions } = useBenutzerListActionRights();
    return useCallback(
        (data) => {
            if (!data) return [];
            const name: MUIDataTableColumnDef = {
                name: 'name',
                label: 'Name',
                options: {
                    filter: false,
                    customBodyRenderLite: (index) => {
                        return displayBenutzerName(data['hydra:member'][index]);
                    },
                },
            };

            const organisation: MUIDataTableColumnDef = {
                name: 'organisationName',
                label: 'Organisation',
                options: { filter: false, sort: false },
            };

            const telefon: MUIDataTableColumnDef = {
                name: 'telefon',
                label: 'Telefon',
                options: { filter: false, sort: false },
            };

            const email: MUIDataTableColumnDef = {
                name: 'email',
                label: 'E-Mail',
                options: { filter: false, sort: false },
            };

            const fax: MUIDataTableColumnDef = {
                name: 'fax',
                label: 'Fax',
                options: { filter: false, sort: false },
            };

            const status: MUIDataTableColumnDef = {
                name: 'status',
                label: 'Status',
                options: {
                    filter: true,
                    filterType: 'checkbox',
                    filterList: ['Aktiv'],
                    filterOptions: { names: ['Aktiv', 'Passiv'] },
                    customFilterListOptions: { render: (v) => `Status: ${v}` },
                    customBodyRenderLite: (index) => {
                        return <BenutzerListStatusIcon benutzer={data['hydra:member'][index]} />;
                    },
                },
            };

            const einrichtungen: MUIDataTableColumnDef = {
                name: 'einrichtungen',
                label: 'Einrichtung',
                options: {
                    display: 'excluded',
                    filter: true,
                    filterType: 'custom',
                    customFilterListOptions: {
                        render: (value) => <BenutzerListEinrichtungFilterValue value={value} />,
                    },
                    filterOptions: {
                        fullWidth: true,
                        display: (options, onChange, index, column) => (
                            <BenutzerListEinrichtungFilter
                                value={options[index][0]}
                                onChange={onChange}
                                index={index}
                                column={column}
                            />
                        ),
                    },
                },
            };

            const actions: MUIDataTableColumnDef = {
                name: 'actions',
                label: 'Aktion',
                options: {
                    filter: false,
                    sort: false,
                    ...rightAlignedColumn,
                    customBodyRenderLite: (index) => {
                        return (
                            <BenutzerListActions
                                benutzer={data['hydra:member'][index]}
                                handleAction={handleAction}
                                allowBenutzerBearbeiten={allowBenutzerBearbeiten}
                                allowBenutzerDeaktivieren={allowBenutzerDeaktivieren}
                            />
                        );
                    },
                },
            };

            let columns: MUIDataTableColumnDef[];
            if (hasRole(ROLES.FACHADMIN)) {
                columns = [name, organisation, telefon, fax, email, status, einrichtungen];
            } else if (hasRole([ROLES.LV_ADMIN, ROLES.LV, ROLES.LV_SACHBEARBEITER, ROLES.MINISTERIUM])) {
                columns = [name, telefon, fax, email, status, einrichtungen];
            } else if (hasRole([ROLES.TRAEGER, ROLES.TRAEGERVERBAND])) {
                columns = [name, organisation, telefon, email, status, einrichtungen];
            } else if (hasRole(ROLES.SUPPORT)) {
                columns = [name, organisation, telefon, fax, email, status, einrichtungen];
            } else {
                columns = [name, telefon, email, status, einrichtungen];
            }

            if (addActions) {
                columns = [...columns, actions];
            }

            return columns;
        },
        [hasRole, addActions, handleAction, allowBenutzerBearbeiten, allowBenutzerDeaktivieren]
    );
};

interface DetermineActionRightsResult {
    allowBenutzerBearbeiten: boolean;
    allowBenutzerDeaktivieren: boolean;
    addActions: boolean;
}

const useBenutzerListActionRights = (): DetermineActionRightsResult => {
    const { hasRole } = useAuthHelper();
    const allowBenutzerBearbeiten = Boolean(hasRole([ROLES.FACHADMIN, ROLES.SUPPORT]) || hasRole([ROLES.LV_ADMIN]));
    const allowBenutzerDeaktivieren = Boolean(hasRole([ROLES.FACHADMIN, ROLES.SUPPORT]) || hasRole([ROLES.LV_ADMIN]));
    return {
        allowBenutzerBearbeiten,
        allowBenutzerDeaktivieren,
        addActions: allowBenutzerBearbeiten || allowBenutzerDeaktivieren,
    };
};
