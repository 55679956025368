import React, { ReactNode } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu } from '@mui/material';

interface DatatableActionDropdownInterface {
    disabled?: boolean;
    children?: ReactNode;
}

export const ActionDropdown: React.FC<DatatableActionDropdownInterface> = ({ disabled = false, children }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                size="large"
                onClick={handleClick}
                disabled={disabled}
                aria-haspopup="true"
                aria-label="Aktion auswählen"
                aria-disabled={disabled}
                data-cy={'TableActionDropdown'}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClick={handleClose}
                onClose={handleClose}
            >
                {children}
            </Menu>
        </>
    );
};
