import React, { useCallback, useEffect, useState } from 'react';
import { FormControl } from '@mui/material';
import { isEqual } from 'date-fns';
import formatDate from 'date-fns/format';

import { DatePicker } from 'forms/components/DatePicker';
import { ErrorList } from 'forms/controls/components/ErrorList';
import { ReadonlyValueText } from 'forms/controls/components/ReadonlyValueText';
import { useFocus } from 'forms/hooks/useFocus';
import { useIsDataChanged } from 'forms/hooks/useIsDataChanged';
import { useScrollInView } from 'forms/hooks/useScrollInView';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';
import { formatDateToString } from 'forms/utils/formatter';

import { RowControl } from './RowControl';

const data2value = (data?: string | null): Date | null => {
    if (!data?.length) return null;
    const date = new Date(data);
    if (isNaN(date.getTime())) return null;

    return date;
};

const DateControlComponent: React.FC<CustomControlProps<string>> = ({
    path,
    label,
    required,
    data,
    handleChange,
    handleBlur,
    hasErrors,
    errors,
    disabled,
    readonly,
    formula,
    showFieldNumberLabels,
    gridLayout,
    uischema,
    config,
    schema,
    id,
}) => {
    const isDataChanged = useIsDataChanged(data, schema);
    const [value, setValue] = useState<Date | null>(data2value(data));

    useEffect(
        () => {
            const date = data2value(data);

            if (!date || !value || !isEqual(date, value)) {
                setValue(date);
            }
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [data]
    );

    const { ref, requestFocusAfterValidate } = useFocus<HTMLInputElement>();
    useScrollInView(ref);
    const handleDateChange = useCallback(
        (date: Date | null, inputValue?: string) => {
            if (inputValue && inputValue.length < 10) return;

            if (!date || (date && !isNaN(date.getTime()))) {
                handleChange(path, (date && formatDate(date, 'yyyy-MM-dd')) || null, true);
                requestFocusAfterValidate();
            }
        },
        [handleChange, path, requestFocusAfterValidate]
    );
    return (
        <RowControl
            name={path}
            label={label}
            required={required}
            formula={formula}
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={gridLayout}
            controlOnly={uischema.controlOnly}
            hasChangedData={isDataChanged}
            labelForId={id}
            config={config}
            paths={[path]}
        >
            {readonly ? (
                <ReadonlyValueText
                    path={path}
                    text={data ? formatDateToString(new Date(data)) : '-'}
                    hasErrors={false}
                    textAlign={'right'}
                    errors={[]}
                />
            ) : (
                <FormControl fullWidth>
                    <DatePicker
                        error={hasErrors}
                        value={value}
                        onChange={handleDateChange}
                        onBlur={handleBlur}
                        disabled={disabled}
                        helperText={hasErrors ? <ErrorList errors={errors} /> : ' '}
                        inputProps={{
                            id,
                            'data-cy': `form_${path}`,
                            style: {
                                textAlign: 'right',
                            },
                            autoFocus: uischema.options?.firstControl && config.autoFocusFirstControl,
                        }}
                        inputRef={ref}
                        componentsProps={{ actionBar: { actions: required ? [] : ['clear'] } }}
                    />
                </FormControl>
            )}
        </RowControl>
    );
};

export const DateControl = withCustomControlProps(DateControlComponent);
