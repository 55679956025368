import React, { useCallback, useEffect, useState } from 'react';
import { JsonFormsCore } from '@jsonforms/core';
import { JsonForms } from '@jsonforms/react';
import { Alert } from '@mui/material';
import { isEqual } from 'lodash';

import { ApiService, backendApiService } from 'api/ApiService';
import { Dialog } from 'components/Dialog/Dialog';
import { useFormModalConfirmationDialog } from 'components/FormModal/hooks/useFormModalConfirmationDialog';
import { FormButton } from 'forms/components/FormButton';
import { useFormValidation } from 'forms/hooks/useFormValidation';
import { renderers } from 'forms/renderers';
import { useConfirmationDialogActions } from 'forms/state/useConfirmationDialogState';
import { FormConfig, FormState, UiSchemaType } from 'forms/types/UiSchemaTypes';

import { useFormModalApiHandling } from './hooks/useFormModalApiHandling';

interface FormModalProps {
    open: boolean;
    form: any;
    data: FormState | undefined;
    onChange: (data: FormState) => void;
    setModal: (data: boolean) => void;
    setRefreshToken: (data: number) => void;
    uischema?: UiSchemaType;
    config?: FormConfig;
}

export const FormModal: React.FC<FormModalProps> = ({
    open,
    form,
    data,
    onChange,
    setModal,
    setRefreshToken,
    uischema,
    config,
}) => {
    const [errors, setErrors] = useState<string[]>([]);
    const { showConfirmation, closeConfirmation } = useConfirmationDialogActions();
    const alertText = useFormModalConfirmationDialog(data);
    const { refreshToken, setOnSubmit } = useFormModalApiHandling({
        endpoint: backendApiService[form.endpoint as keyof ApiService].bind(backendApiService),
        data: data,
        resetData: form.resetData,
        setModal: setModal,
        setResetData: onChange,
        converter: form.converter,
        setErrors: setErrors,
    });

    const updateRefreshToken = useCallback(() => {
        setRefreshToken(refreshToken);
    }, [refreshToken, setRefreshToken]);

    useEffect(() => {
        updateRefreshToken();
    }, [updateRefreshToken]);

    const handleChange = (state: Pick<JsonFormsCore, 'data' | 'errors'>) => {
        if (!isEqual(state.data, data)) {
            onChange(state.data);
        }
    };

    const closeModal = () => {
        setModal(false);
        setErrors([]);
    };

    const handleSave = () => {
        showConfirmation({
            alertText,
            confirmLabel: 'Hinzufügen',
            denyLabel: 'Abbrechen',
            confirmAction: () => {
                setOnSubmit(true);
                closeConfirmation();
            },
            denyAction: closeConfirmation,
        });
    };

    const { valid } = useFormValidation(form.schema, data, config);

    return (
        <Dialog
            title={form.title}
            open={open}
            onClose={closeModal}
            actions={
                <>
                    <FormButton variant="outlined" color="primary" onClick={closeModal}>
                        Schließen
                    </FormButton>
                    <FormButton variant="contained" color="primary" onClick={handleSave} disabled={!valid}>
                        Speichern
                    </FormButton>
                </>
            }
        >
            <>
                {errors?.length > 0 && (
                    <Alert severity="error">
                        {errors.map((error) => (
                            <div>{error}</div>
                        ))}
                    </Alert>
                )}

                <JsonForms
                    data={data}
                    schema={form.schema}
                    uischema={uischema}
                    onChange={handleChange}
                    renderers={renderers}
                    config={config}
                />
            </>
        </Dialog>
    );
};
