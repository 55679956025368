import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { EinrichtungTableAction, EinrichtungTableActions } from 'constants/einrichtungActions';
import { InvestPathBuilder } from 'navigation/Paths';

export const usePflegeeinrichtungenListAction = () => {
    const navigate = useNavigate();

    const handleAction: EinrichtungTableAction = useCallback(
        async (action, einrichtung) => {
            const id = einrichtung.id;
            if (!id) return;

            switch (action) {
                case EinrichtungTableActions.ANTRAGSHISTORIE_FESTSTELLUNG:
                    navigate(
                        InvestPathBuilder.home.verwaltung.pflegeeinrichtungen.antragshistorie(
                            id,
                            'antragshistorie_feststellung'
                        )
                    );
                    break;
                case EinrichtungTableActions.ANTRAGSHISTORIE_FESTSETZUNG:
                    navigate(
                        InvestPathBuilder.home.verwaltung.pflegeeinrichtungen.antragshistorie(
                            id,
                            'antragshistorie_festsetzung'
                        )
                    );
                    break;
                case EinrichtungTableActions.ANTRAGSHISTORIE_INVESTORENMODELL:
                    navigate(
                        InvestPathBuilder.home.verwaltung.pflegeeinrichtungen.antragshistorie(
                            id,
                            'antragshistorie_investorenmodell'
                        )
                    );
                    break;
            }
        },
        [navigate]
    );

    return { handleAction };
};
