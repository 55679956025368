import React from 'react';
import { Grid, Typography } from '@mui/material';

import { formatDateToString } from 'forms/utils/formatter';
import { Verwaltung } from 'pages/Verwaltung/components/LayoutComponents';
import { DisplayBenutzer, displayBenutzerName } from 'utilities';

export type AntragDetailsVersionInfo = {
    art: string;
    eingereichtAt?: string | null;
    eingereichtBy?: DisplayBenutzer | null;
    gesendetAt?: string | null;
    gesendetBy?: DisplayBenutzer | null;
    geprueftAt?: string | null;
    geprueftBy?: DisplayBenutzer | null;
    modifiedAt?: string | null;
};

interface AntragDetailsVersionProps {
    version: AntragDetailsVersionInfo;
}

export const AntragDetailsVersion = ({ version }: AntragDetailsVersionProps) => (
    <Grid container>
        <Grid item xs={12}>
            <Typography variant="h2">
                Antragsversion vom {formatDateToString(version.modifiedAt, 'dd.MM.yyyy - HH:mm')} Uhr
            </Typography>
        </Grid>
        <Grid item xs={6}>
            <Verwaltung.InfoField
                label={'Datum der Antragstellung'}
                value={formatDateToString(version.eingereichtAt)}
            />
            <Verwaltung.InfoField label={'Antrag erstellt von'} value={displayBenutzerName(version.eingereichtBy)} />
            <Verwaltung.InfoField label={'Art des Bescheids'} value={version.art} />
            {version.gesendetAt && (
                <>
                    <Verwaltung.InfoField
                        label={'Datum der Bescheiderstellung'}
                        value={formatDateToString(version.gesendetAt)}
                    />
                    <Verwaltung.InfoField
                        label={'Bescheid erstellt von'}
                        value={displayBenutzerName(version.gesendetBy)}
                    />
                </>
            )}
            {version.geprueftAt && (
                <>
                    <Verwaltung.InfoField label={'Datum der Prüfung'} value={formatDateToString(version.geprueftAt)} />
                    <Verwaltung.InfoField
                        label={'Antrag geprüft von'}
                        value={displayBenutzerName(version.geprueftBy)}
                    />
                </>
            )}
        </Grid>
    </Grid>
);
