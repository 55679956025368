import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { MeldungP29CollectionListResponse } from 'api/types';
import { GetData } from 'components/DataTableHydra/hooks/useTableData';
import { zustaendigerBenutzerFilter } from 'components/DataTableHydra/tableUtils';

export const useMeldungP29ListData = (einrichtungId?: string): GetData<MeldungP29CollectionListResponse> => {
    return useCallback(
        ({ page, rowsPerPage, searchText, sortOrder, filters }) => {
            return backendApiService.getMeldungP29Collection({
                page,
                itemsPerPage: rowsPerPage,
                qSearch: searchText ? [searchText] : undefined,
                zustaendigerBenutzer: zustaendigerBenutzerFilter(filters),
                einrichtung: einrichtungId,
                status2: filters ? filters['status'] : undefined,
                orderEinrichtungAktenzeichen: sortOrder?.name === 'aktenzeichen' ? sortOrder.direction : undefined,
                orderEinrichtungName: sortOrder?.name === 'einrichtung' ? sortOrder.direction : undefined,
                orderStatus: sortOrder?.name === 'status' ? sortOrder.direction : undefined,
                orderMeldedatum: sortOrder?.name === 'meldedatum' ? sortOrder.direction : undefined,
                orderGueltigAb: sortOrder?.name === 'gueltigAb' ? sortOrder.direction : undefined,
                meldedatum: filters ? filters['meldedatum'] : undefined,
            });
        },
        [einrichtungId]
    );
};
