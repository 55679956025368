import axios, { AxiosInstance } from 'axios';

import { SessionApi, Token } from './client';

class SessionService {
    private readonly client: AxiosInstance;

    constructor() {
        this.client = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
        });
    }

    public setToken(token?: string, jwtRefreshToken?: string): Promise<void> {
        return new SessionApi(undefined, '', this.client)
            .sessionSetToken(
                {
                    token: token,
                    refresh_token: jwtRefreshToken,
                },
                { withCredentials: true }
            )
            .then((res) => res.data);
    }

    public getToken(): Promise<Token> {
        return new SessionApi(undefined, '', this.client)
            .sessionGetToken({ withCredentials: true })
            .then((res) => res.data);
    }

    public invalidate(): Promise<void> {
        return new SessionApi(undefined, '', this.client)
            .sessionInvalidate({ withCredentials: true })
            .then((res) => res.data);
    }
}

export const sessionService = new SessionService();
