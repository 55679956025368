import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { BenutzerListResponse } from 'api/types';
import { GetData } from 'components/DataTableHydra/hooks/useTableData';
import { TableFilters } from 'components/DataTableHydra/hooks/useTableFilters';
import { sortBy } from 'components/DataTableHydra/tableUtils';

export const useBenutzerListData = (): GetData<BenutzerListResponse> => {
    return useCallback(({ page, rowsPerPage, searchText, sortOrder, filters }) => {
        return backendApiService.getBenutzerCollection({
            page,
            itemsPerPage: rowsPerPage,
            einrichtung: determineEinrichtungsFilter(filters),
            existsDeaktiviertAt: determineStatusFilter(filters),
            qSearch: searchText ? [searchText] : undefined,
            orderByName: sortBy(sortOrder, 'name'),
            orderByDeaktiviertAt: sortBy(sortOrder, 'status'),
        });
    }, []);
};

const determineEinrichtungsFilter = (filters: TableFilters): string | undefined => {
    return filters?.['einrichtungen']?.[0];
};

const determineStatusFilter = (filters: TableFilters): boolean | undefined => {
    const filterList = filters?.['status'];
    if (filterList?.length !== 1) return undefined;
    return filterList[0] === 'Passiv';
};
