import { ReactElement } from 'react';

import { DataTableHydraServerside } from 'components/DataTableHydra/DataTableHydraServerside';
import { useTraegerverbandListColumns } from 'pages/Verwaltung/Traegerverband/useTraegerverbandListColumns';
import { useTraegerverbandListData } from 'pages/Verwaltung/Traegerverband/useTraegerverbandListData';

export const TraegerverbandList = (): ReactElement => {
    const getData = useTraegerverbandListData();
    const getColumns = useTraegerverbandListColumns();

    return (
        <DataTableHydraServerside
            getColumns={getColumns}
            getData={getData}
            initialOptions={{
                filter: false,
                sort: false,
                viewColumns: false,
            }}
        />
    );
};
