import { DataGridType, Schema, TableColumn } from 'forms/types/UiSchemaTypes';
import { determineSchemaByUUID, getFieldSchemaByScope } from 'forms/utils/SchemaUtils';
import { getFieldFromScope } from 'utilities';

export const useDataGrid = (
    data: any,
    schema: Schema,
    uiSchema: DataGridType
): { value: any; schema: Schema; column: TableColumn }[] => {
    if (!data || !Array.isArray(data)) {
        return [];
    }
    return data.flatMap((d: any) =>
        uiSchema.columns.map((c) => ({
            value: d[getFieldFromScope(c.scope)],
            schema: getFieldSchemaByScope(c.scope, determineSchemaByUUID(d.uuid, schema as Schema)),
            column: c,
        }))
    );
};
