import { BACKEND_API_BASE_URL } from 'api/ApiBaseService';

export const THOUSAND_SEPARATOR = '.';

export const stringToNumber = (value: string | undefined | null): number | undefined => {
    return value === undefined || value === null || value === '' ? undefined : parseFloat(value);
};

export const getAbsoluteBackendPath = (relativePath: string) => {
    return BACKEND_API_BASE_URL + (BACKEND_API_BASE_URL.endsWith('/') ? '' : '/') + relativePath;
};

export const getReadableFileSize = (bytes: number | undefined): string => {
    if (!bytes) return '0 Bytes';

    const sufixes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sufixes[i];
};

export const getFileExtension = (mimeType: string | null | undefined): string =>
    mimeType ? mimeType.split('/')[1] : '';
