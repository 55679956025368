import { JsonSchema4, JsonSchema7 } from '@jsonforms/core';

export const auswahlValueZuLabel = (selectedValue: string, schema: JsonSchema4 | JsonSchema7) => {
    const value = selectedValue || schema.default || '';
    if (!value?.length) return '-';

    return (
        ((schema.oneOf || []) as Array<JsonSchema4 | JsonSchema7>).find(
            (entry: JsonSchema4 | JsonSchema7) => entry.const === selectedValue
        )?.title || value
    );
};
