import { MUIDataTableColumnOptions } from 'mui-datatables';

export const rightAlignedColumn: MUIDataTableColumnOptions = {
    setCellProps: () => ({
        align: 'right',
    }),
    setCellHeaderProps: () => ({
        align: 'right',
    }),
};

export const centerAlignedColumn: MUIDataTableColumnOptions = {
    setCellProps: () => ({
        align: 'center',
    }),
    setCellHeaderProps: () => ({
        align: 'center',
    }),
};
