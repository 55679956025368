import React, { ReactNode } from 'react';
import { Divider, FormLabel, Grid, GridProps, styled, Typography } from '@mui/material';

const StyledContainerContainer = styled(Grid)(({ theme }) => ({
    '& .MuiDivider-root': {
        marginTop: theme.spacing(2),
    },
}));

const StyledGroupContainer = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(1),

    '& h2': {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    '& .Info-item': {
        minHeight: 60,
    },
}));

const Container: React.FC<{ children?: ReactNode }> = ({ children }) => {
    return (
        <StyledContainerContainer container spacing={2}>
            {children}
            <Grid item xs={12}>
                <Divider />
            </Grid>
        </StyledContainerContainer>
    );
};

interface GroupType {
    title?: string;
    children?: ReactNode;
    md?: GridProps['md'];
}

const Group: React.FC<GroupType> = ({ children, title, md = 6 }) => {
    return (
        <Grid item xs={12} md={md}>
            <StyledGroupContainer container spacing={2}>
                <StyledGroupContainer container>
                    <Grid item xs={12}>
                        {title && <Typography variant={'h2'}>{title}</Typography>}
                    </Grid>
                </StyledGroupContainer>

                {children}
            </StyledGroupContainer>
        </Grid>
    );
};

interface InfoFieldProps {
    label: string;
    value: number | string | null | undefined | React.ReactElement;
    unit?: string;
    textAlign?: 'left' | 'right';
    labelMd?: GridProps['md'];
    valueMd?: GridProps['md'];
}

const InfoField: React.FC<InfoFieldProps> = ({ label, value, textAlign = 'left', labelMd = 5, valueMd = 6 }) => (
    <Grid container>
        <Grid item xs={5} md={labelMd} className={'Info-item'}>
            <FormLabel component={'legend'}>{label}</FormLabel>
        </Grid>
        <Grid item xs={7} md={valueMd} className={'Info-item'}>
            <FormLabel style={{ textAlign }} component={'legend'}>
                {value ? value : '-'}
            </FormLabel>
        </Grid>
    </Grid>
);

export const Verwaltung = {
    Container,
    Group,
    InfoField,
};
