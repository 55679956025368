import { useEffect, useState } from 'react';
import { JsonFormsCore } from '@jsonforms/core';
import { isEqual } from 'lodash';

import { useApiLandschaftsverbandItem, useApiTraegerItem, useApiTraegerverbandItem } from 'api/hooks/useApiClient';
import { BenutzerResponse } from 'api/types';
import { SelectOption } from 'forms/components/Typeahead';
import { getIriId } from 'utilities';

interface UseBenutzerBearbeitenFormDataResult {
    data: BenutzerBearbeitenFormData;
    isValid: boolean;
    onChange: (state: Pick<JsonFormsCore, 'data' | 'errors'>) => void;
    isLoading: boolean;
}

export interface BenutzerBearbeitenFormData {
    email: string;
    nachname: string;
    vorname: string;
    rolle: string | undefined;
    landschaftsverband: SelectOption | null;
    traegerverband: SelectOption | null;
    traeger: SelectOption | null;
}

export const useBenutzerBearbeitenFormData = (initialData: BenutzerResponse): UseBenutzerBearbeitenFormDataResult => {
    const [isValid, setValid] = useState(true);
    const { data: landschaftsverband, isLoading: isLandschaftsverbandLoading } = useApiLandschaftsverbandItem(
        getIriId(initialData.landschaftsverband?.['@id'])
    );
    const { data: traeger, isLoading: isTraegerLoading } = useApiTraegerItem(getIriId(initialData.traeger?.['@id']));
    const { data: traegerverband, isLoading: isTraegerverbandLoading } = useApiTraegerverbandItem(
        getIriId(initialData.traegerverband?.['@id'])
    );

    const [data, setData] = useState<BenutzerBearbeitenFormData>(() => {
        return {
            email: initialData.email,
            nachname: initialData.nachname,
            vorname: initialData.vorname,
            rolle: initialData.rolle,
            landschaftsverband: null,
            traegerverband: null,
            traeger: null,
        };
    });

    const { rolle } = data;
    useEffect(() => {
        setData((prev) => ({
            ...prev,
            landschaftsverband: null,
            traeger: null,
            traegerverband: null,
        }));
    }, [rolle]);

    useEffect(() => {
        setData((prev) => ({
            ...prev,
            landschaftsverband: landschaftsverband
                ? {
                      id: landschaftsverband.id as number,
                      name: landschaftsverband.name,
                  }
                : null,
            traegerverband: traegerverband ? { id: traegerverband.id as number, name: traegerverband.name } : null,
            traeger: traeger ? { id: traeger.id as number, name: traeger.name } : null,
        }));
    }, [initialData.id, traeger, traegerverband, landschaftsverband]);

    const onChange = ({ data: newData, errors }: Pick<JsonFormsCore, 'data' | 'errors'>) => {
        if (isEqual(newData, data)) return;
        setData(newData);
        setValid(errors?.length === 0);
    };

    return {
        data,
        isValid,
        onChange,
        isLoading: Boolean(isLandschaftsverbandLoading || isTraegerLoading || isTraegerverbandLoading),
    };
};
