import { basisdaten_table } from './zusammenfassung/basisdaten_table';
import { belegungTeilstationaer_table } from './zusammenfassung/belegungTeilstationaer_table';
import { belegungVollstationaer_table } from './zusammenfassung/belegungVollstationaer_table';
import { erbpacht_table } from './zusammenfassung/erbpacht_table';
import { erloese_table } from './zusammenfassung/erloese_table';
import { finanzierungen_table } from './zusammenfassung/finanzierungen_table';
import { miete_table } from './zusammenfassung/miete_table';
import { virtuellesKonto_table } from './zusammenfassung/virtuellesKonto_table';
import { zimmerkategorien_table } from './zusammenfassung/zimmerkategorien_table';

export const uiSchema = {
    type: 'CustomVerticalLayout',
    spacing: 6,
    elements: [
        basisdaten_table,
        erbpacht_table,
        miete_table,
        virtuellesKonto_table,
        finanzierungen_table,
        zimmerkategorien_table,
        belegungVollstationaer_table,
        belegungTeilstationaer_table,
        erloese_table,
    ],
};
