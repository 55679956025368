import React from 'react';
import { LayoutProps, RankedTester, rankWith } from '@jsonforms/core';
import { JsonFormsDispatch, withJsonFormsLayoutProps } from '@jsonforms/react';
import { Grid } from '@mui/material';

import { applyGridLayout } from 'forms/AntragForm/ui-schemas/elements/gridLayoutConfig';
import { GroupFormType } from 'forms/types/UiSchemaTypes';

import { GroupFormHeaderComponent } from './GroupFormHeader';

interface GroupFormComponentType extends Omit<LayoutProps, 'uischema'> {
    uischema: GroupFormType;
}

const GroupFormComponent: React.FC<GroupFormComponentType> = ({ data, schema, uischema }) => {
    return (
        <Grid container>
            {uischema.header && (
                <Grid item xs={12}>
                    <GroupFormHeaderComponent data={data} {...uischema} />
                </Grid>
            )}
            <Grid item xs={12}>
                {uischema.elements.map((element, ei) => (
                    <JsonFormsDispatch
                        key={ei}
                        schema={schema}
                        uischema={applyGridLayout(element, uischema.gridLayout)}
                    />
                ))}
            </Grid>
        </Grid>
    );
};

// @ts-ignore
export const GroupForm = withJsonFormsLayoutProps(GroupFormComponent);

export const GroupFormTester: RankedTester = rankWith(1, (uischema) => uischema.type === 'GroupForm');
