import React, { ReactElement, useState } from 'react';
import { Typography } from '@mui/material';

import { ContentContainer } from 'layout/container/ContentContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { withAuthorization } from 'navigation/withAuthorization';
import { DataTable } from 'pages/Verwaltung/Massenmail/DataTable';
import { Erstellen } from 'pages/Verwaltung/Massenmail/Erstellen';
import { VerwaltungConfig } from 'pages/Verwaltung/VerwaltungConfig';

const Massenmail: React.FC = (): ReactElement => {
    const [rerender, setRerender] = useState<boolean>(false);

    const rerenderHandler = (value: boolean) => {
        setRerender(value);
    };

    return (
        <ContentContainer title="Mailings">
            <Typography variant="h1">Mailings</Typography>
            <Erstellen onAddedHandler={rerenderHandler} />
            <br />
            <br />
            <DataTable rerender={rerender} rerenderHandler={rerenderHandler} />
        </ContentContainer>
    );
};

export const MassenmailPage = withAuthorization(
    withSideBar(Massenmail, VerwaltungConfig.menu),
    VerwaltungConfig.roles.massenmail
);
