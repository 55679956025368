import React from 'react';

import { FormButton } from 'forms/components/FormButton';
import { useConfirmationDialogActions } from 'forms/state/useConfirmationDialogState';

interface MeldungAbgebenButtonProps {
    onAbsenden: () => void;
    disabled: boolean;
}

export const MeldungAbgebenButton: React.FC<MeldungAbgebenButtonProps> = ({ onAbsenden, disabled }) => {
    const { showConfirmation, closeConfirmation } = useConfirmationDialogActions();

    const onAbsendenBase = () => {
        showConfirmation({
            alertText: `Sie sind dabei Ihre Meldung nach § 29 APG DVO abzugeben. Nach dem Abgeben können Sie keine Änderungen an der Meldung mehr vornehmen. Möchten Sie fortfahren?`,
            confirmLabel: 'Meldung abgeben',
            denyLabel: 'Abbrechen',
            denyAction: closeConfirmation,
            confirmAction: () => {
                closeConfirmation();
                onAbsenden();
            },
        });
    };

    return (
        <FormButton
            type="submit"
            variant="contained"
            color="primary"
            onClick={onAbsendenBase}
            disabled={disabled}
            data-cy={'MeldungAbgebenButton'}
        >
            Meldung abgeben
        </FormButton>
    );
};
