import { useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { TraegerListResponse } from 'api/types';
import { GetColumns } from 'components/DataTableHydra/hooks/useTableColumns';
import { TRAEGER_ART_LABELS } from 'constants/labels';
import { traegerListLinkRender } from 'pages/Verwaltung/Traeger/TraegerListLink';
import { rightAlignedColumn } from 'utilities/DataTableHydraUtils';

export const useTraegerverbandTraegerListColumns = (): GetColumns<TraegerListResponse> => {
    return useCallback((data) => {
        if (!data) return [];

        const nrwKey: MUIDataTableColumnDef = {
            name: 'nrwKey',
            label: 'NRW-Schlüssel',
        };

        const name: MUIDataTableColumnDef = {
            name: 'name',
            label: 'Träger',
        };

        const einrichtungen: MUIDataTableColumnDef = {
            name: 'einrichtungen',
            label: 'Einrichtungen',
            options: {
                ...rightAlignedColumn,
                customBodyRenderLite: (index: number) => traegerListLinkRender(data, index, 'einrichtungen'),
            },
        };

        const art: MUIDataTableColumnDef = {
            name: 'art',
            label: 'Art',
            options: {
                customBodyRender: (value: string): string => TRAEGER_ART_LABELS[value] || value,
            },
        };

        return [nrwKey, name, einrichtungen, art];
    }, []);
};
