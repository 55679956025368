import React, { ReactElement } from 'react';
import { Warning } from '@mui/icons-material';
import { styled, SvgIconProps, Tooltip } from '@mui/material';

type MissingRequiredFieldsIconProps = SvgIconProps;

export const MissingRequiredFieldsIcon = (props: MissingRequiredFieldsIconProps): ReactElement => {
    const title = 'In diesem Objekt sind nicht alle Pflichtangaben gesetzt.';
    const subtitle = props.onClick ? ' Klicken Sie hier, um zum Formularschritt zu gelangen.' : '';
    return (
        <Tooltip title={`${title}${subtitle}`}>
            <ErrorIcon {...props} />
        </Tooltip>
    );
};

const ErrorIcon = styled(Warning)(({ theme }) => ({
    color: theme.palette.error.main,
    verticalAlign: 'text-bottom',
}));
