import { useCallback, useState } from 'react';

import { backendApiService } from 'api/ApiService';
import { FileUploadResponse, FileUploadResult } from 'api/responses/FileUploadResponse';

export const useFileUpload = (onUpload: (responses: FileUploadResponse[]) => void, onRemove: (id: number) => void) => {
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [errors, setErrors] = useState<string[]>([]);

    const handleUploadPromises = useCallback((promises: Promise<FileUploadResult>[]) => {
        setErrors([]);
        return Promise.all(promises).then(
            (results) =>
                results.filter((result) => {
                    if (!('error' in result)) {
                        return true;
                    }
                    const error = result.fileId
                        ? `Dokument konnte nicht geladen werden. (Id: ${result.fileId})`
                        : `Dokument konnte nicht hochgeladen werden. (${result.fileName})`;
                    setErrors((prevState) => [...prevState, error]);
                    return false;
                }) as FileUploadResponse[]
        );
    }, []);

    const uploadFiles = useCallback(
        (files: FileList) => {
            setErrors([]);
            setIsUploading(true);

            handleUploadPromises(Array.from(files).map((file) => backendApiService.postFile(file)))
                .then((responses) => onUpload(responses))

                .finally(() => setIsUploading(false));
        },
        [handleUploadPromises, onUpload]
    );

    const removeFile = useCallback(
        (id: number) => {
            setErrors([]);
            onRemove(id);
        },
        [onRemove]
    );

    return {
        isUploading,
        uploadFiles,
        uploadErrors: errors,
        removeFile,
    };
};
