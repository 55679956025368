import { Fragment, ReactElement } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { AntragType } from 'api/antragTypes';
import { useApiAntragDiff, useApiAntragVersions } from 'api/hooks/useApiClient';
import { AntragResponse, FeststellungsAntragVersionCollectionResponse } from 'api/types';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { STATUS } from 'constants/antragStatus';
import { ANTRAG_VERSIONEN_TYP } from 'constants/antragVersionenTyp';
import { ANTRAG_STATUS_LABELS } from 'constants/labels';
import { formatDateToString } from 'forms/utils/formatter';
import { useAntragDiffableVersions } from 'pages/Antraege/components/useAntragDiffableVersions';
import { convertToAntragVersion } from 'pages/Antraege/components/useAntragVersionListData';
import { getFieldNumber } from 'utilities/ScopeUtils';

import { formatChangeValue } from './formatChangeValues';

type AntragDetailsChangeListProps = {
    antrag: AntragResponse;
    type: AntragType;
};

export const AntragDetailsChangeList = ({ type, antrag }: AntragDetailsChangeListProps): ReactElement => {
    const { data: versions, isLoading: isVersionsLoading } = useApiAntragVersions(antrag.id, type);
    const { v1, v2 } = useAntragDiffableVersions(versions);
    const { data: diffs, isLoading: isDiffLoading } = useApiAntragDiff(antrag.id, type, v2?.id, v1?.id);

    const fields = diffs?.fieldDiffs ?? [];
    const isWrongStatus = [STATUS.ABGEBROCHEN, STATUS.ENTWURF, STATUS.ZURUECKGEZOGEN].includes(antrag.status);
    const isEmpty = !v2 || isWrongStatus || fields.length === 0;
    return (
        <AppLoaderContainer isLoading={isVersionsLoading || isDiffLoading}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Feld-Nr.</TableCell>
                        <TableCell>Feld</TableCell>
                        <TableCell data-testid="ChangesHeaderV2">
                            <VersionHeader v={v2} />
                        </TableCell>
                        <TableCell data-testid="ChangesHeaderV1">
                            <VersionHeader v={convertToAntragVersion(antrag)} />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isEmpty && (
                        <TableRow>
                            <TableCell colSpan={4} data-testid="ChangesEmptyMessage">
                                Keine Änderungen vorhanden.
                            </TableCell>
                        </TableRow>
                    )}
                    {!isEmpty &&
                        fields.map((diff, index) => (
                            <TableRow key={`${diff.name}-${index}`}>
                                <TableCell data-testid="ChangesRowFieldNr">{getFieldNumber(diff.name)}</TableCell>
                                <TableCell data-testid="ChangesRowField">{diff.label}</TableCell>
                                <TableCell data-testid="ChangesRowV2">{formatChangeValue(diff, 'v2')}</TableCell>
                                <TableCell data-testid="ChangesRowV1">{formatChangeValue(diff, 'v1')}</TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </AppLoaderContainer>
    );
};

interface VersionHeaderProps {
    v: FeststellungsAntragVersionCollectionResponse | null;
}

const VersionHeader = ({ v }: VersionHeaderProps): ReactElement => {
    if (!v) return <>-</>;
    const typText = determineTypText(v.typ);
    return (
        <>
            {typText}Antragsversion ({ANTRAG_STATUS_LABELS[v.status]}) vom{' '}
            {formatDateToString(v.antragModifiedAt, 'dd.MM.yyyy - HH:mm')} Uhr
        </>
    );
};

const determineTypText = (typ: ANTRAG_VERSIONEN_TYP): string => {
    switch (typ) {
        case ANTRAG_VERSIONEN_TYP.EINRICHTUNG:
            return 'PE-';
        case ANTRAG_VERSIONEN_TYP.LV:
            return 'LV-';
        default:
            return '';
    }
};
