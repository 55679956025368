import React from 'react';
import { LayoutProps } from '@jsonforms/core';
import { withJsonFormsLayoutProps } from '@jsonforms/react';

import { useDataFromScopes } from 'forms/hooks/useDataFromScopes';
import { Schema } from 'forms/types/UiSchemaTypes';
import { getFieldSchemaByScope } from 'forms/utils/SchemaUtils';
import { getFieldNumber } from 'utilities/ScopeUtils';

import { FieldRowComponent } from './FieldRow';

interface MultiFieldRowInterface extends Omit<LayoutProps, 'uischema' | 'schema'> {
    uischema: {
        labelScope: string;
        scopes: string[];
    };
    schema: Schema;
}

const MultiFieldRowComponent: React.FC<MultiFieldRowInterface> = ({
    data,
    path,
    schema,
    config,
    uischema: { scopes, labelScope },
}) => {
    const labelSchema = getFieldSchemaByScope(labelScope, schema);
    const { value, colSchema } = useDataFromScopes(schema, data, scopes);
    return (
        <FieldRowComponent
            field={getFieldNumber(labelScope) || ''}
            path={path}
            config={config}
            label={labelSchema?.title as string}
            value={value}
            valueSchema={colSchema as Schema}
            showValueLabel
        />
    );
};

// @ts-ignore
export const MultiFieldRow = withJsonFormsLayoutProps(MultiFieldRowComponent);
