import React, { useState } from 'react';

import { FeststellungsAntragCollectionResponse } from 'api/types';
import { formatDateToString, formatDezimal } from 'forms/utils/formatter';
import { Verwaltung } from 'pages/Verwaltung/components/LayoutComponents';

import { AntragshistorieSelect } from './AntragshistorieSelect';

interface AntragshistorieFeststellungProps {
    data: FeststellungsAntragCollectionResponse[];
}

export const AntragshistorieFeststellung: React.FC<AntragshistorieFeststellungProps> = ({ data }) => {
    const [selectedFeststellung, setSelectedFeststellung] = useState<FeststellungsAntragCollectionResponse | null>(
        data[data.length - 1] ?? null
    );

    return (
        <Verwaltung.Container>
            <Verwaltung.Group title={'Historische Feststellung'} md={12}>
                <AntragshistorieSelect
                    type={'Feststellung'}
                    value={selectedFeststellung}
                    onChange={setSelectedFeststellung}
                    options={data}
                />
                <Verwaltung.InfoField
                    label={'Bescheid vom'}
                    value={formatDateToString(selectedFeststellung?.letzteBescheidung?.gesendetAt)}
                    textAlign={'right'}
                    labelMd={3}
                    valueMd={3}
                />
                <Verwaltung.InfoField
                    label={'Folgefeststellung zum'}
                    value={formatDateToString(selectedFeststellung?.data?.field102)}
                    textAlign={'right'}
                    labelMd={3}
                    valueMd={3}
                />
                <Verwaltung.InfoField
                    label={'Restwert zu anerkanntem Aufwand für Maßnahmen nach dem 02.11.2014'}
                    value={formatDezimal(selectedFeststellung?.data?.fieldSumme0519, '€')}
                    textAlign={'right'}
                    labelMd={3}
                    valueMd={3}
                />
                <Verwaltung.InfoField
                    label={'SALG'}
                    value={formatDezimal(selectedFeststellung?.data?.field0720, '€')}
                    textAlign={'right'}
                    labelMd={3}
                    valueMd={3}
                />
                <Verwaltung.InfoField
                    label={'Grundstück'}
                    value={formatDezimal(selectedFeststellung?.data?.field0301, 'm²')}
                    textAlign={'right'}
                    labelMd={3}
                    valueMd={3}
                />
                <Verwaltung.InfoField
                    label={'NRF'}
                    value={formatDezimal(selectedFeststellung?.data?.field121, 'm²')}
                    textAlign={'right'}
                    labelMd={3}
                    valueMd={3}
                />
            </Verwaltung.Group>
        </Verwaltung.Container>
    );
};
