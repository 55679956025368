import React from 'react';
import { TableCell } from '@mui/material';

import { TableColumn } from 'forms/types/UiSchemaTypes';

interface FormTableCell {
    column: TableColumn;
}

export const FormTableHeaderCell: React.FC<FormTableCell> = ({ column }) => {
    return <TableCell style={{ verticalAlign: 'bottom', ...column.styles }}>{column.label}</TableCell>;
};
