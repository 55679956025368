import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';

export const uiSchema = verticalLayout([
    group([
        control('field1735a'),
        control('field1735b'),
        control('field1735c'),
        control('field0805'),
        control('field1805'),
        control('field1735'),
    ]),
]);
