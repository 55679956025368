import { Box, Container, Grid, styled, Typography } from '@mui/material';

import { AdresseLandschaftsverband } from 'components/Adresse/AdresseLandschaftsverband';
import { KONTAKT_PFADINV_EMAIL, KONTAKT_PFADINV_TEL } from 'constants/content';
import { LinkEmail } from 'elements/LinkEmail';
import { LinkExtern } from 'elements/LinkExtern';
import { LinkTel } from 'elements/LinkTel';
import { Logo } from 'layout/components/Logo';
import { withSideBar } from 'layout/hooks/useSideBar';

const KontaktInfo = styled(Typography)(() => ({
    paddingLeft: 50,
    paddingRight: 50,
})) as typeof Typography;

const KontaktInfoLvr = styled(Typography)(() => ({
    display: 'inline-block',
})) as typeof Typography;

const CenteredContainer = styled(Container)(
    () => `
    .ContentContainer--default & {
        position: relative;
        width: 100vw;
        min-height: 100%;
        height: 100%;
    }
    .ContentContainer--aside & {
        margin-top: 10%;
    }
`
);

const CenteredContent = styled(Container)(
    () => `
    .ContentContainer--default & {
        position: absolute;
        width: calc(1590px * 0.5);
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
    }
`
);

const TextBox = styled(Box)(
    () => `
    margin: 2rem 0 3rem;
`
);

const Home = () => {
    return (
        <CenteredContainer>
            <CenteredContent>
                <Typography component="div" align="center">
                    <Logo height={60} />
                </Typography>
                <Typography variant="h1" align="center">
                    Herzlich willkommen
                </Typography>

                <TextBox>
                    <Typography paragraph>
                        PfAD.invest — Digitales Fachverfahren zur Verwaltung der Refinanzierung von Investitionskosten
                        vollstationärer Pflegeeinrichtungen sowie Einrichtungen der Tages-, Nacht- und Kurzzeitpflege
                        nach der Verordnung zur Ausführung des Alten- und Pflegegesetzes (APG DVO) in NRW.
                    </Typography>

                    <Typography paragraph>
                        Sind Sie bereits für eine Einrichtung im System registriert, können Sie sich hier mit Ihren
                        Zugangsdaten anmelden.
                    </Typography>

                    <Typography paragraph>
                        Für Fragen rund um die Anwendung PfAD.invest steht Ihnen unsere Hotline zur Verfügung, die Sie
                        werktags von 9.00-17.00 Uhr unter der Rufnummer <LinkTel msisdn={KONTAKT_PFADINV_TEL} />{' '}
                        erreichen können. Sie können Ihre Anfrage auch per E-Mail an{' '}
                        <LinkEmail email={KONTAKT_PFADINV_EMAIL} /> richten.
                    </Typography>
                </TextBox>

                <Grid container>
                    <Grid item md={6}>
                        <KontaktInfo component="div" align="right">
                            <KontaktInfoLvr component="div" align="left">
                                <Typography component="div" paragraph>
                                    <AdresseLandschaftsverband kuerzel={'LVR'} />
                                </Typography>
                                <Typography paragraph>
                                    <LinkExtern
                                        type="pdf"
                                        href="https://pfadinvest-test-mags.nrw.de/files/2021-01_Arbeitsverteilung%2074.21_Kommunen.pdf"
                                    >
                                        Ansprechpartner des LVR
                                    </LinkExtern>
                                </Typography>
                            </KontaktInfoLvr>
                        </KontaktInfo>
                    </Grid>
                    <Grid item md={6}>
                        <KontaktInfo component="div">
                            <Typography component="div" paragraph>
                                <AdresseLandschaftsverband kuerzel={'LWL'} />
                            </Typography>
                            <Typography paragraph>
                                <LinkExtern
                                    type="pdf"
                                    href="https://pfadinvest-test-mags.nrw.de/files/Ratenverteilung%20MA%20LWL%202021_03.pdf"
                                >
                                    Ansprechpartner des LWL
                                </LinkExtern>
                            </Typography>
                        </KontaktInfo>
                    </Grid>
                </Grid>
            </CenteredContent>
        </CenteredContainer>
    );
};

export const HomePage = withSideBar(Home, []);
