import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/AntragForm/ui-schemas/elements/customVerticalLayout';
import { formDialog } from 'forms/AntragForm/ui-schemas/elements/formDialog';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { info } from 'forms/AntragForm/ui-schemas/elements/info';
import { labeled } from 'forms/AntragForm/ui-schemas/elements/labeled';
import { listControlWithModalForm } from 'forms/AntragForm/ui-schemas/elements/listControlWithModalForm';
import { tableColumn, tableColumnDate, tableColumnDecimal } from 'forms/AntragForm/ui-schemas/elements/tableColumn';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';
import { gepooltePlaetze } from 'forms/AntragForm/ui-schemas/groups/gepooltePlaetze';
import { notes } from 'forms/AntragForm/ui-schemas/groups/notes';
import { zuschuesse } from 'forms/AntragForm/ui-schemas/groups/zuschuesse';

export const uiSchema = verticalLayout([
    group([
        info(
            'Bitte erfassen Sie mindestens eine Maßnahme des Typs Erstinvestition, Erstinvestition - gepoolte Plätze, Umbaumaßnahme oder Ersatzneubau.'
        ),
        listControlWithModalForm(
            ['#/properties/massnahmen'],
            [
                tableColumn('Art', {
                    scope: '#/properties/field200',
                }),
                tableColumn('Maßnahme', {
                    scope: '#/properties/field201',
                }),
                tableColumnDate('Inbetriebnahme', {
                    scopes: ['#/properties/field202', '#/properties/field207'],
                }),
                tableColumnDecimal('Anschaffungskosten', {
                    scope: '#/properties/field210',
                }),
                tableColumnDecimal('Gesamtplatzzahl', {
                    scope: '#/properties/field204a',
                }),
            ],
            ['Maßnahme hinzufügen'],
            ['Maßnahme löschen'],
            ['Wollen Sie diese Maßnahme mit allen Angaben wirklich aus Ihrem Antrag löschen?'],
            [
                formDialog(
                    'Neue Maßnahme',
                    'Maßnahme',
                    labeled({
                        labelScope: '#/properties/field200',
                    }),
                    customVerticalLayout([
                        group([
                            control('field200'),
                            control('field201'),
                            control('field202'),
                            control('field207'),
                            control('field290'),
                            control('field203'),
                            control('field204'),
                            control('field205'),
                            control('field206'),
                            control('field210'),
                            control('field220'),
                            control('field220d'),
                            control('field221'),
                            control('field222'),
                            control('field223'),
                            control('field224'),
                            control('field225'),
                            control('field227'),
                            control('field232'),
                            control('field232d'),
                        ]),
                        group([control('field230')]),
                        gepooltePlaetze,
                        zuschuesse(),
                    ])
                ),
            ]
        ),
    ]),
    notes('notizMassnahmen'),
]);
