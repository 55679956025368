import { useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Grid, Typography } from '@mui/material';

import { useAuthHelper } from 'api/auth/useAuthHelper';
import { ROLES } from 'constants/roles';
import { FormButton } from 'forms/components/FormButton';
import { ContentContainer } from 'layout/container/ContentContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { withAuthorization } from 'navigation/withAuthorization';
import { CreateBenutzerFormDialog } from 'pages/Verwaltung/Benutzer/BenutzerErstellenForm/CreateBenutzerFormDialog';
import { BenutzerList } from 'pages/Verwaltung/Benutzer/BenutzerList/BenutzerList';
import { VerwaltungConfig } from 'pages/Verwaltung/VerwaltungConfig';

const Benutzer = () => {
    const { hasRole } = useAuthHelper();
    const [isOpen, setOpen] = useState(false);
    const [serverDataChanged, setServerDataChanged] = useState(0);

    const handleHinzufuegenButtonClick = () => {
        setOpen(true);
    };

    const handleExitHinzufuegenDialog = () => {
        setOpen(false);
    };

    const onComplete = () => setServerDataChanged((prev) => prev + 1);

    const isBenutzerAddAllowed =
        hasRole([ROLES.FACHADMIN, ROLES.SUPPORT]) || hasRole([ROLES.LV_ADMIN, ROLES.TRAEGERVERBAND, ROLES.TRAEGER]);

    return (
        <ContentContainer title="Benutzer">
            <Typography variant="h1">Benutzer</Typography>

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    {isBenutzerAddAllowed && (
                        <>
                            <FormButton variant="contained" color="primary" onClick={handleHinzufuegenButtonClick}>
                                <AddCircleIcon />
                                Benutzer hinzufügen
                            </FormButton>
                            {isOpen && (
                                <CreateBenutzerFormDialog
                                    einrichtung={null}
                                    onClose={handleExitHinzufuegenDialog}
                                    onComplete={onComplete}
                                />
                            )}
                        </>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <BenutzerList serverDataChanged={serverDataChanged} />
                </Grid>
            </Grid>
        </ContentContainer>
    );
};

export const BenutzerPage = withAuthorization(
    withSideBar(Benutzer, VerwaltungConfig.menu),
    VerwaltungConfig.roles.benutzer
);
