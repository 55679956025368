import { useCallback } from 'react';
import { Typography } from '@mui/material';

import { AntragType } from 'api/antragTypes';
import { backendApiService } from 'api/ApiService';
import { useAuthHelper } from 'api/auth/useAuthHelper';
import { FestsetzungsAntragCollectionListResponse } from 'api/types';
import { useColumnsFestsetzung } from 'components/DataTableHydra/Columns/Festsetzung';
import { DataTableHydraServerside } from 'components/DataTableHydra/DataTableHydraServerside';
import { useLvBenutzerOptions } from 'components/DataTableHydra/hooks/useLvBenutzerOptions';
import { GetData } from 'components/DataTableHydra/hooks/useTableData';
import { ROLES } from 'constants/roles';
import { ContentContainer } from 'layout/container/ContentContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { withAuthorization } from 'navigation/withAuthorization';
import { useAntragFilterOptions } from 'pages/Antraege/components/useAntragFilterOptions';
import { ZurueckziehenAkzeptierenDialog } from 'pages/Antraege/components/ZurueckziehenAkzeptierenDialog';
import { ZurueckziehenBeantragenDialog } from 'pages/Antraege/components/ZurueckziehenBeantragenDialog';
import { useFestsetzungsAntragListActions } from 'pages/Antraege/Festsetzung/useFestsetzungsAntragListActions';
import { DashboardLvBenutzerToolbar } from 'pages/Dashboard/Landschaftsverband/DashboardLvBenutzerToolbar';

import { AUFGABEN, AufgabenMenu } from './config';

const FestsetzungZurueckgezogen = () => {
    const { getCurrentBenutzer, isLandschaftsverbandView } = useAuthHelper();
    const benutzerId = getCurrentBenutzer()?.benutzerId;
    const { isLoading: isLoadingLvBenutzer, options: zustandigerBenutzerOptions } = useLvBenutzerOptions();

    const getData: GetData<FestsetzungsAntragCollectionListResponse> = useCallback(
        ({ page, rowsPerPage, searchText, sortOrder }) => {
            return backendApiService.getFestsetzungsantragCollection({
                page,
                itemsPerPage: rowsPerPage,
                qSearch: searchText ? [searchText] : undefined,
                zustaendigerBenutzer: !isLandschaftsverbandView && benutzerId ? [benutzerId] : undefined,
                aufgabe: AUFGABEN.ZURUECKZIEHEN_BEANTRAGT,
                orderZuletztEingereichtAt:
                    sortOrder?.name === 'zuletztEingereichtAt' ? sortOrder?.direction : undefined,
                orderField500: sortOrder?.name === 'field500' ? sortOrder?.direction : undefined,
                orderEinrichtungAktenzeichen: sortOrder?.name === 'aktenzeichen' ? sortOrder?.direction : undefined,
                orderFeststellungsantragBerechnungsart:
                    sortOrder?.name === 'berechnungsart' ? sortOrder?.direction : undefined,
                orderEinrichtungName: sortOrder?.name === 'einrichtung' ? sortOrder?.direction : undefined,
                orderStatus: sortOrder?.name === 'status' ? sortOrder?.direction : undefined,
                orderEinrichtungZustaendigerBenutzer:
                    sortOrder?.name === 'zustaendigerBenutzer' ? sortOrder?.direction : undefined,
            });
        },
        [benutzerId, isLandschaftsverbandView]
    );

    const isLoading = undefined === zustandigerBenutzerOptions || isLoadingLvBenutzer;

    const {
        handleAction,
        serverDataChanged,
        zurueckziehenBeantragen,
        zurueckziehenBeantragenFinishAction,
        zurueckziehenAkzeptieren,
        zurueckziehenAkzeptierenFinishAction,
    } = useFestsetzungsAntragListActions(AntragType.FESTSETZUNG);
    const filterOptions = useAntragFilterOptions(AntragType.FESTSETZUNG);
    const columnsFestsetzung = useColumnsFestsetzung(handleAction, filterOptions);

    return (
        <ContentContainer title="Anträge auf Festsetzung (zurückgezogen)">
            <Typography variant="h1">Anträge auf Festsetzung (zurückgezogen)</Typography>
            <DashboardLvBenutzerToolbar />
            <DataTableHydraServerside
                loadingContext={isLoading}
                getColumns={columnsFestsetzung}
                getData={getData}
                initialOptions={{
                    filter: false,
                    searchPlaceholder: 'Suche nach Einrichtungsname, Aktenzeichen oder NRW-Schlüssel',
                    sortOrder: {
                        name: 'zuletztEingereichtAt',
                        direction: 'desc',
                    },
                }}
                serverDataChanged={serverDataChanged}
            />
            <ZurueckziehenBeantragenDialog
                antrag={zurueckziehenBeantragen}
                onClose={zurueckziehenBeantragenFinishAction}
                antragType={AntragType.FESTSETZUNG}
            />
            <ZurueckziehenAkzeptierenDialog
                antrag={zurueckziehenAkzeptieren}
                onClose={zurueckziehenAkzeptierenFinishAction}
                antragType={AntragType.FESTSETZUNG}
            />
        </ContentContainer>
    );
};

export const FestsetzungZurueckgezogenPage = withAuthorization(withSideBar(FestsetzungZurueckgezogen, AufgabenMenu), [
    ROLES.FACHADMIN,
    ROLES.SUPPORT,
    ROLES.LV,
]);
