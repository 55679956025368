import React, { ReactElement, ReactNode, useRef, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { FormControl } from '@mui/material';

import { useAuthHelper } from 'api/auth/useAuthHelper';
import { Dialog } from 'components/Dialog/Dialog';
import { ROLES } from 'constants/roles';
import { FormButton } from 'forms/components/FormButton';
import { Form, FormDataType } from 'pages/Verwaltung/Massenmail/Form';

interface MassenmailErstellenProps {
    children?: ReactNode;
    onAddedHandler?: (value: boolean) => void;
}

export const Erstellen: React.FC<MassenmailErstellenProps> = ({
    onAddedHandler,
}: MassenmailErstellenProps): ReactElement => {
    const { hasRole } = useAuthHelper();

    const [modal, setModal] = useState(false);
    const [valid, setValid] = useState(false);

    const submitButtonRef = useRef<HTMLButtonElement>(null);

    const toggleModal = () => setModal(!modal);
    const handleSubmit = () => {
        if (onAddedHandler) {
            onAddedHandler(true);
        }

        setModal(false);
    };

    const handleSubmitOnClick = () => {
        submitButtonRef.current && submitButtonRef.current.click();
    };

    const onChange = (data: FormDataType, valid: boolean) => {
        setValid(valid);
    };

    const newMassenmailPermission = hasRole(ROLES.LV) || hasRole(ROLES.MINISTERIUM);

    return (
        <>
            {newMassenmailPermission && (
                <>
                    <FormControl margin="normal">
                        <FormButton
                            variant="contained"
                            color="primary"
                            onClick={toggleModal}
                            data-cy={'MassenmailErstellen--open'}
                        >
                            <AddCircleIcon /> Neues Mailing
                        </FormButton>
                    </FormControl>

                    <Dialog
                        title={'Neues Mailing erstellen'}
                        open={modal}
                        onClose={toggleModal}
                        fullWidth={true}
                        maxWidth="lg"
                        actions={
                            <>
                                <FormButton type="button" variant="outlined" color="primary" onClick={toggleModal}>
                                    Abbrechen
                                </FormButton>

                                <FormButton
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmitOnClick}
                                    disabled={!valid}
                                    data-cy={'MassenmailErstellen--send'}
                                >
                                    Jetzt versenden
                                </FormButton>
                            </>
                        }
                    >
                        <Form onSubmit={handleSubmit} onChange={onChange} submitButtonRef={submitButtonRef} />
                    </Dialog>
                </>
            )}
        </>
    );
};
