import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { MenuInterface, Step } from 'layout/HeaderConfig';

export const useAntragMenu = (
    steps: Step<string>[],
    buildPath: (id: string, step: string) => string
): MenuInterface[] => {
    const { id } = useParams();
    const mapStep = useCallback(
        (s: Step<string>): MenuInterface => ({
            name: s.title,
            path: id ? buildPath(id, s.children?.length ? s.children[0].step : s.step) : undefined,
            disabled: s.state !== 'active',
            tabs: (s.children || []).map(mapStep),
        }),
        [id, buildPath]
    );
    return useMemo(() => steps.map(mapStep), [steps, mapStep]);
};
