import React from 'react';

import { AntragType, FeststellungsAntragAPI } from 'api/antragTypes';
import { backendApiService } from 'api/ApiService';
import { ROLES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { InvestPathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { defaultMenusForFeststellungsantrag, menuFeststellungsantrag } from 'pages/Antraege/AntragConfig';
import { AntragPage } from 'pages/Antraege/components/AntragPage';

const antragApi: FeststellungsAntragAPI = {
    submit: backendApiService.postFeststellungsantragJson.bind(backendApiService),
    deleteObjectTypeItem: backendApiService.deleteFeststellungsantragObjectTypeItem.bind(backendApiService),
    getFormData: backendApiService.getFeststellungsantragJson.bind(backendApiService),
    performWorkflowAction: backendApiService.workflowFeststellungsantragItem.bind(backendApiService),
    getAntrag: backendApiService.getFeststellungsantragItem.bind(backendApiService),
    submitGegenpruefungErlaeuterung: backendApiService.patchFeststellungsantragGegenpruefung.bind(backendApiService),
};

const FeststellungsAntrag: React.FC = () => {
    return (
        <AntragPage
            api={antragApi}
            stepPathBuilder={InvestPathBuilder.home.antraege.feststellung.edit}
            type={AntragType.FESTSTELLUNG}
            header={'Feststellungsantrag'}
            menuBuilder={menuFeststellungsantrag}
        />
    );
};

export const FeststellungsAntragPage = withAuthorization(
    withSideBar(FeststellungsAntrag, menuFeststellungsantrag(defaultMenusForFeststellungsantrag(''))),
    [ROLES.FACHADMIN, ROLES.SUPPORT, ROLES.LV, ROLES.ANBIETER]
);
