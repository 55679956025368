import { Typography } from '@mui/material';

import { PFAD_WTG_BASE_URL, PFAD_WTG_REGISTRATION_URL } from 'constants/wtg';
import { LinkExtern } from 'elements/LinkExtern';

import { FaqListItem } from './FaqListItem';

interface FaqListProps {
    title: string;
}

export const FaqList = ({ title }: FaqListProps) => {
    return (
        <>
            <Typography variant="h1">{title}</Typography>

            <FaqListItem
                question={'Welche Schritte müssen für die initiale Registrierung eingehalten werden?'}
                answer={
                    <ul>
                        <li>
                            Um PfAD.invest nutzen zu können, müssen Sie sich zunächst über{' '}
                            <LinkExtern href={PFAD_WTG_REGISTRATION_URL}>PfAD.wtg</LinkExtern> registrieren und dort
                            Ihre Stammdaten eingeben.
                        </li>
                        <li>
                            Sofern Sie die Voraussetzungen für PfAD.invest erfüllen und Pflegewohngeld beantragen
                            möchten, erhalten Sie nach entsprechender Prüfung eine E-Mail mit dazugehörigem
                            Aktivierungslink für PfAD.invest.
                        </li>
                        <li>
                            Nach Ihrer Anmeldung über den Aktivierungslink und Prüfung der Stammdaten können Sie Ihre
                            Zugangsdaten (E-Mail-Adresse und Passwort) für PfAD.invest festlegen.
                        </li>
                    </ul>
                }
            />

            <FaqListItem
                question={
                    'Kann ein Antrag in PfAD.invest gestellt werden, wenn die Einrichtung bereits zuvor in PfAD.invest erfasst, aber die Registrierung in PfAD.wtg noch nicht vorgenommen wurde?'
                }
                answer={
                    <p>
                        Nein. Einrichtungen, die bisher nur in PfAD.invest registriert sind, können erst einen Antrag
                        stellen, wenn die Registrierung in{' '}
                        <LinkExtern href={PFAD_WTG_REGISTRATION_URL}>PfAD.wtg</LinkExtern> nachgeholt wurde.
                    </p>
                }
            />

            <FaqListItem
                question={'Wie können Stammdaten nach der initialen Registrierung geändert werden?'}
                answer={
                    <ul>
                        <li>
                            Eine Änderung der Stammdaten kann ausschließlich über{' '}
                            <LinkExtern href={PFAD_WTG_BASE_URL}>PfAD.wtg</LinkExtern> erfolgen.
                        </li>
                        <li>
                            Änderungen der Stammdaten können von Einrichtungsnutzern, Landschaftsverbänden, WTG-Behörden
                            und dem technischen Support vorgenommen werden.
                        </li>
                        <li>
                            Änderungen durch Einrichtungsnutzer werden jedoch erst nach einer Freigabe durch die
                            WTG-Behörde wirksam.
                        </li>
                    </ul>
                }
            />

            <FaqListItem
                question={'In welchem Abhängigkeitsverhältnis stehen Feststellungs- und Festsetzungsantrag?'}
                answer={
                    <ul>
                        <li>
                            Grundsätzlich gilt: ohne einen beschiedenen Feststellungsantrag kann kein Festsetzungsantrag
                            gestellt werden.
                        </li>
                        <li>
                            Folgender Ablauf ist standardmäßig vorgesehen:
                            <ol type={'a'}>
                                <li>
                                    Feststellungsantrag wird durch Pflegeeinrichtung gestellt und an den zuständigen
                                    Landschaftsverband übermittelt.
                                </li>
                                <li>
                                    Der zuständige Landschaftsverband bescheidet die Feststellung und versendet den
                                    dazugehörigen Bescheid.
                                </li>
                                <li>
                                    Basierend auf dem Feststellungsbescheid kann die Pflegeeinrichtung den
                                    Festsetzungsantrag stellen.
                                </li>
                                <li>Im Anschluss bescheidet der zuständige Landschaftsverband die Festsetzung.</li>
                            </ol>
                        </li>
                    </ul>
                }
            />
        </>
    );
};
