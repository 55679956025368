export const SESSION_DEBOUNCE_TIMEOUT = 10000;

export enum ROLES {
    USER = 'user', // special role - user is logged in
    PASSWORD_RESET = 'ROLE_PASSWORD_RESET',
    FACHADMIN = 'ROLE_FACHADMIN',
    SUPPORT = 'ROLE_SUPPORT',
    MINISTERIUM = 'ROLE_MINISTERIUM',
    LV = 'ROLE_LV',
    LV_SACHBEARBEITER = 'ROLE_LV_SACHBEARBEITER',
    LV_ADMIN = 'ROLE_LV_ADMIN',
    ANBIETER = 'ROLE_ANBIETER',
    TRAEGERVERBAND = 'ROLE_TRAEGERVERBAND',
    TRAEGER = 'ROLE_TRAEGER',
    EINRICHTUNG_BENUTZER = 'ROLE_EINRICHTUNG_BENUTZER',
    SONSTIGER_BEVOLLMAECHTIGTER = 'ROLE_SONSTIGER_BEVOLLMAECHTIGTER',
}

export enum ROLLEN {
    FACHADMIN = 'FACHADMIN',
    SUPPORT = 'SUPPORT',
    MINISTERIUM = 'MINISTERIUM',
    LV_SACHBEARBEITER = 'LV_SACHBEARBEITER',
    LV_ADMIN = 'LV_ADMIN',
    TRAEGERVERBAND = 'TRAEGERVERBAND',
    TRAEGER = 'TRAEGER',
    EINRICHTUNG_BENUTZER = 'EINRICHTUNG_BENUTZER',
    SONSTIGER_BEVOLLMAECHTIGTER = 'SONSTIGER_BEVOLLMAECHTIGTER',
}

export const ROLLEN_ANBIETER: string[] = [
    ROLLEN.TRAEGERVERBAND,
    ROLLEN.TRAEGER,
    ROLLEN.EINRICHTUNG_BENUTZER,
    ROLLEN.SONSTIGER_BEVOLLMAECHTIGTER,
];

export const ROLLEN_LV: string[] = [ROLLEN.LV_SACHBEARBEITER, ROLLEN.LV_ADMIN];
