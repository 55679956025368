import React, { useState } from 'react';
import Sticky from 'react-sticky-el';
import { Alert, Box, Collapse, Hidden, IconButton } from '@mui/material';

import { IconExpand } from 'elements/IconExpand';
import { ComponentError } from 'forms/hooks/useErrors';

import { ErrorList, ErrorText } from './ErrorList';

interface ErrorAlertProps {
    errors: ComponentError[];
}

export const ErrorAlert: React.FC<ErrorAlertProps> = ({ errors }) => {
    const [expanded, setExpanded] = useState(true);

    return (
        <Hidden xsUp={errors.length === 0}>
            <Sticky stickyStyle={{ zIndex: 99, top: '72px' }} topOffset={-72}>
                <Alert severity={'error'}>
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                        }}
                    >
                        <Box>
                            <Collapse in={expanded}>
                                <Box>
                                    <ErrorList errors={errors} />
                                </Box>
                            </Collapse>
                            <Collapse in={!expanded}>
                                <Box onClick={() => setExpanded(!expanded)} style={{ cursor: 'pointer' }}>
                                    <ErrorText text={`${errors.length} Fehler`} />
                                </Box>
                            </Collapse>
                        </Box>
                        {errors.length > 1 && (
                            <IconButton
                                size="small"
                                onClick={() => setExpanded(!expanded)}
                                aria-label="Fehler anzeigen"
                            >
                                <IconExpand size="small" open={expanded} />
                            </IconButton>
                        )}
                    </Box>
                </Alert>
            </Sticky>
        </Hidden>
    );
};
