import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { backendApiService } from 'api/ApiService';
import { EinrichtungResponse, MeldungP29CollectionResponse } from 'api/types';
import { ACTIONS } from 'constants/antragActions';
import { STATUS } from 'constants/antragStatus';
import { InvestPathBuilder } from 'navigation/Paths';

interface UseMeldungP29HelperResult {
    isMeldungP29Allowed: boolean;
    goToMeldungP29: () => void;
}

export const useMeldungP29Helper = (
    einrichtung: EinrichtungResponse | undefined,
    meldungenP29: MeldungP29CollectionResponse[] | undefined = undefined
): UseMeldungP29HelperResult => {
    const navigate = useNavigate();
    const einrichtungId = einrichtung?.id ? String(einrichtung.id) : undefined;

    return useMemo(() => {
        const lastMeldungP29 = determineLastMeldungP29(meldungenP29);
        const isMeldungP29Allowed =
            Boolean(!lastMeldungP29 || isWorkflowAllowed(lastMeldungP29, ACTIONS.EDIT)) ||
            lastMeldungP29?.status === STATUS.EINGEREICHT ||
            lastMeldungP29?.status === STATUS.ZUR_KENNTNIS_GENOMMEN;
        const goToMeldungP29 = async () => {
            if (!einrichtungId) return;

            if (!lastMeldungP29 || isWorkflowAllowed(lastMeldungP29, ACTIONS.FOLGEANTRAG)) {
                const res = await backendApiService.postMeldungP29(einrichtungId);
                return navigate(InvestPathBuilder.home.antraege.meldungen_p29.edit(res.id));
            }

            if (lastMeldungP29 && isWorkflowAllowed(lastMeldungP29, ACTIONS.EDIT)) {
                return navigate(InvestPathBuilder.home.antraege.meldungen_p29.edit(lastMeldungP29.id));
            }
        };
        return {
            isMeldungP29Allowed,
            goToMeldungP29,
        };
    }, [einrichtungId, meldungenP29, navigate]);
};

const determineLastMeldungP29 = (
    meldungenP29: MeldungP29CollectionResponse[] | undefined
): MeldungP29CollectionResponse | undefined => {
    if (!meldungenP29 || meldungenP29.length === 0) return undefined;
    return meldungenP29.reduce((prev, current) =>
        ((prev ? prev.id : 0) as number) > ((current ? current.id : 0) as number) ? prev : current
    );
};

const isWorkflowAllowed = (antrag: MeldungP29CollectionResponse, action: ACTIONS): boolean => {
    return Boolean(antrag?.allowedWorkflowActions?.includes(action));
};
