import React from 'react';
import { JsonForms } from '@jsonforms/react';
import { Card, CardContent, Grid, styled } from '@mui/material';

import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { CloseButton } from 'components/Dialog/CloseButton';
import { useDisabled } from 'forms/hooks/useDisabled';
import { useErrors } from 'forms/hooks/useErrors';
import { renderers } from 'forms/renderers';
import { FormStateChange, Schema, UiSchemaType } from 'forms/types/UiSchemaTypes';

import { ErrorAlert } from './ErrorAlert';

interface SubFormCardProps {
    onClose?: () => void;
    schema: Schema;
    uiSchema: UiSchemaType;
    config: any;
    data: any;
    onChange: (state: FormStateChange) => void;
}

export const SubFormCard: React.FC<SubFormCardProps> = ({ onClose, schema, uiSchema, data, onChange, config }) => {
    const { hasErrors, errors } = useErrors({ config });
    const disabled = useDisabled();
    return (
        <CardContainer variant={'outlined'} data-cy={'SubForm'}>
            <CardContent>
                <Grid container spacing={1}>
                    {onClose && (
                        <Grid container justifyContent={'flex-end'}>
                            <CloseButton
                                onClick={onClose}
                                tooltip={'Entfernen'}
                                size={'small'}
                                disabled={disabled}
                                data-cy={'SubForm-close'}
                            />
                        </Grid>
                    )}
                    {hasErrors && (
                        <Grid item xs={12}>
                            <ErrorAlert errors={errors} />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <AppLoaderContainer isLoading={!schema?.properties}>
                            <JsonForms
                                schema={schema}
                                uischema={uiSchema}
                                data={data}
                                renderers={renderers}
                                config={config}
                                onChange={onChange}
                            />
                        </AppLoaderContainer>
                    </Grid>
                </Grid>
            </CardContent>
        </CardContainer>
    );
};

const CardContainer = styled(Card)(({ theme }) => ({
    backgroundColor: theme.palette.background.surfaceHighlighted,
    color:
        theme.palette.background.surfaceHighlighted &&
        theme.palette.getContrastText(theme.palette.background.surfaceHighlighted),
}));
