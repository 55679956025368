import React, { ReactElement, useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { useApiEinrichtungItem } from 'api/hooks/useApiClient';
import { EinrichtungListItemResponse, EinrichtungResponse } from 'api/types';
import {
    LazyAutocompleteFilter,
    LazyAutocompleteFilterPropsBase,
} from 'components/DataTableHydra/Filters/LazyAutocompleteFilter';

export const BenutzerListEinrichtungFilter = (props: LazyAutocompleteFilterPropsBase): ReactElement => {
    const queryFetcher = useCallback(async (query: string): Promise<EinrichtungListItemResponse[]> => {
        return backendApiService
            .getEinrichtungCollection({ page: 1, itemsPerPage: 10, qSearch: [query] })
            .then((res) => res['hydra:member']);
    }, []);

    const idFetcher = useCallback(async (id: string | null): Promise<EinrichtungResponse | null> => {
        if (!id) return null;
        return backendApiService.getEinrichtungItem(id);
    }, []);

    return (
        <LazyAutocompleteFilter
            {...props}
            queryFetcher={queryFetcher}
            idFetcher={idFetcher}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            noOptionsText="Keine Einrichtungen gefunden."
            loadingText="Wird geladen..."
        />
    );
};

type BenutzerListEinrichtungFilterValueProps = {
    value: string;
};

export const BenutzerListEinrichtungFilterValue = ({
    value,
}: BenutzerListEinrichtungFilterValueProps): ReactElement => {
    const { data } = useApiEinrichtungItem(value);

    return <>Einrichtung: {data?.name}</>;
};
