import { collectionGroups } from 'forms/AntragForm/ui-schemas/elements/collectionGroups';
import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/AntragForm/ui-schemas/elements/customVerticalLayout';
import { gridLayoutConfig } from 'forms/AntragForm/ui-schemas/elements/gridLayoutConfig';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { groupForm } from 'forms/AntragForm/ui-schemas/elements/groupForm';
import { inlineGroupRowControl } from 'forms/AntragForm/ui-schemas/elements/inlineGroupRowControl';

export const uiSchema = customVerticalLayout([
    group(
        [
            group(
                [
                    groupForm(
                        '#/properties/belegungStandardEinzelzimmer',
                        'Jahr {#/properties/jahr}',
                        [
                            inlineGroupRowControl('belegungStandardEinzelzimmer', 'field561', {
                                action: { label: 'Zeile nullen', value: 0 },
                            }),
                            inlineGroupRowControl('belegungStandardEinzelzimmer', 'field561a', {
                                action: { label: 'Zeile nullen', value: 0 },
                            }),
                            inlineGroupRowControl('belegungStandardEinzelzimmer', 'field570', {
                                action: { label: 'Zeile nullen', value: 0 },
                            }),
                        ],
                        {
                            gridLayout: gridLayoutConfig(1, 3, 5),
                        }
                    ),
                ],
                {
                    noSeparator: true,
                }
            ),
            control('field5119a'),
            control('field5122a'),
            control('field5125a'),
            control('field1126'),
            control('field5114'),
            control('field1115'),
            control('field1116'),
            control('field5114a'),
        ],
        {},
        'Standard-Einzelzimmer',
        '#/properties/belegungStandardEinzelzimmer'
    ),
    group(
        [
            group(
                [
                    groupForm(
                        '#/properties/belegungStandardDoppelzimmer',
                        'Jahr {#/properties/jahr}',
                        [
                            inlineGroupRowControl('belegungStandardDoppelzimmer', 'field562', {
                                action: { label: 'Zeile nullen', value: 0 },
                            }),
                            inlineGroupRowControl('belegungStandardDoppelzimmer', 'field562a', {
                                action: { label: 'Zeile nullen', value: 0 },
                            }),
                            inlineGroupRowControl('belegungStandardDoppelzimmer', 'field570', {
                                action: { label: 'Zeile nullen', value: 0 },
                            }),
                        ],
                        {
                            gridLayout: gridLayoutConfig(1, 3, 5),
                        }
                    ),
                ],
                {
                    noSeparator: true,
                }
            ),
            control('field5140a'),
            control('field5143a'),
            control('field5146a'),
            control('field1147'),
            control('field5135'),
            control('field1136'),
            control('field1137'),
            control('field5135a'),
        ],
        {},
        'Standard-Doppelzimmer',
        '#/properties/belegungStandardDoppelzimmer'
    ),
    group(
        [
            collectionGroups(
                'zimmerkategorien',
                [
                    groupForm(
                        '#/zimmerkategorien/properties/belegungSonderkategorie',
                        'Jahr {#/properties/jahr}',
                        [
                            inlineGroupRowControl('belegungSonderkategorie', 'field563', {
                                action: { label: 'Zeile nullen', value: 0 },
                            }),
                            inlineGroupRowControl('belegungSonderkategorie', 'field563a', {
                                action: { label: 'Zeile nullen', value: 0 },
                            }),
                            inlineGroupRowControl('belegungSonderkategorie', 'field570', {
                                action: { label: 'Zeile nullen', value: 0 },
                            }),
                        ],
                        {
                            gridLayout: gridLayoutConfig(1, 3, 5),
                        }
                    ),
                    control('field5161a'),
                    control('field5164a'),
                    control('field5167a'),
                    control('field1168'),
                    control('field5157'),
                    control('field1158'),
                    control('field1197'),
                    control('field5157a'),
                ],
                undefined,
                '#/properties/field550',
                'Zimmerkategorie {value}'
            ),
        ],
        { noSeparator: true }
    ),
    group([
        groupForm(
            '#/properties/gesamtbelegung',
            'Jahr {#/properties/jahr}',
            [
                inlineGroupRowControl('gesamtbelegung', 'field571', {
                    action: { label: 'Zeile nullen', value: 0 },
                }),
                inlineGroupRowControl('gesamtbelegung', 'field573', {
                    action: { label: 'Zeile nullen', value: 0 },
                }),
                control('field570d', {
                    controlOnly: true,
                    gridLayout: gridLayoutConfig(1, 3, 3),
                }),
            ],
            {
                gridLayout: gridLayoutConfig(1, 3, 5),
            }
        ),
    ]),
]);
