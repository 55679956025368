import { MUIDataTableColumnOptions } from 'mui-datatables';

import { CheckboxesFilter } from 'components/DataTableHydra/Filters/CheckboxesFilter';
import { EINRICHTUNG_STATUS_LABELS } from 'constants/labels';

export const EinrichtungStatusFilterOptions: MUIDataTableColumnOptions = {
    customFilterListOptions: {
        render: (value) => value.map((v: string) => `Status: ${EINRICHTUNG_STATUS_LABELS[v]}`),
    },
    filterOptions: {
        names: Object.keys(EINRICHTUNG_STATUS_LABELS),
        display: (filterList, onChange, index, column, filterData) => (
            <CheckboxesFilter
                index={index}
                column={column}
                filterData={filterData[index]}
                filterValue={filterList[index]}
                filterLabels={EINRICHTUNG_STATUS_LABELS}
                onChange={onChange}
            />
        ),
    },
    filterType: 'custom',
};
