import { useEffect, useState } from 'react';

import { useApiAllowedCreateRollen } from 'api/hooks/useApiClient';
import { EinrichtungResponse } from 'api/types';
import { ROLLEN_LABELS } from 'constants/labels';
import { ROLLEN_ANBIETER } from 'constants/roles';
import { useMessageActions } from 'forms/state/useMessages';
import { errorMessage } from 'forms/utils/MessageUtils';

type UseAllowedCreateRollenResult = {
    rollen: Rolle[];
    isLoading: boolean;
    isError: boolean;
};

export interface Rolle {
    title: string;
    const: string;
}

export const useAllowedCreateBenutzerRollen = (
    einrichtung: EinrichtungResponse | null
): UseAllowedCreateRollenResult => {
    const { addMessage } = useMessageActions();
    const [rollen, setRollen] = useState<Rolle[]>([]);
    const { data: allowedCreateRollen, isLoading, error } = useApiAllowedCreateRollen();

    useEffect(() => {
        const allowedRollen: Rolle[] =
            allowedCreateRollen?.rollen
                ?.split(',')
                ?.filter((rolle) => !einrichtung || ROLLEN_ANBIETER.includes(rolle))
                ?.map((rolle) => ({
                    title: ROLLEN_LABELS[rolle],
                    const: rolle,
                })) ?? [];
        setRollen(allowedRollen);
    }, [einrichtung, allowedCreateRollen]);

    useEffect(() => {
        if (error) addMessage(createErrorMessage());
    }, [error, addMessage]);

    return { rollen, isLoading: Boolean(isLoading), isError: Boolean(error) };
};

const createErrorMessage = () =>
    errorMessage('Keine Berechtigung', {
        autoCloseSeconds: 8,
        closable: true,
    });
