import { GroupControlType } from 'forms/types/UiSchemaTypes';

export const platzzahlreduzierungenSalg = {
    type: 'Group',
    label: 'Platzzahlreduzierungen',
    scope: '#/properties/platzzahlreduzierungenSalg',
    elements: [
        {
            type: 'FormExtendButton',
            label: 'Platzzahlreduzierung hinzufügen',
            scope: '#/properties/platzzahlreduzierungenSalg',
            uiSchema: {
                type: 'CustomVerticalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/field0716',
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/field0717',
                    },
                ],
            },
        },
        { type: 'Separator' },
        {
            type: 'Control',
            scope: '#/properties/field0715',
        },
        {
            type: 'Control',
            scope: '#/properties/field0718',
        },
    ],
} as GroupControlType;
