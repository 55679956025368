import { useApiBenutzerCollection } from 'api/hooks/useApiClient';
import { ROLLEN_LV } from 'constants/roles';
import { displayBenutzerName } from 'utilities';

export interface LvBenutzerOptions {
    [key: string]: string;
}

interface LvBenutzerOptionsInterface {
    isLoading?: boolean;
    options: LvBenutzerOptions;
}

export const useLvBenutzerOptions = (): LvBenutzerOptionsInterface => {
    const { isLoading, data } = useApiBenutzerCollection(1, 999, undefined, undefined, undefined, ROLLEN_LV);

    const options: LvBenutzerOptions = {};

    if (data) {
        data['hydra:member'].forEach((b) => {
            options[String(b.id!)] = displayBenutzerName(b) ?? '';
        });
    }

    return { isLoading, options };
};
