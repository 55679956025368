import { FormFieldDiffJsonldAntragFormDiff } from 'api/client';
import { formatDateToString, formatDezimal, formatInteger } from 'forms/utils/formatter';

export const formatChangeValue = (
    data: FormFieldDiffJsonldAntragFormDiff,
    key: keyof FormFieldDiffJsonldAntragFormDiff
): string | undefined => {
    if (!data[key]) {
        return '-';
    }

    if (data.blockPrefixes?.includes('datum')) {
        return formatDateToString(data[key]);
    }

    if (data.blockPrefixes?.includes('ganzzahl')) {
        return formatInteger(data[key]);
    }

    if (data.blockPrefixes?.includes('prozent')) {
        return formatDezimal(data[key], '%');
    }

    if (data.blockPrefixes?.includes('m2')) {
        return formatDezimal(data[key], 'm²');
    }

    if (data.blockPrefixes?.includes('betrag')) {
        return formatDezimal(data[key], '€');
    }

    if (data.blockPrefixes?.includes('uuid_select')) {
        return data[key];
    }

    if (data.blockPrefixes?.includes('choice')) {
        return data.choices?.[data[key]];
    }

    if (data.blockPrefixes?.includes('ja_nein')) {
        return data[key] === 'true' ? 'Ja' : 'Nein';
    }

    if (data.blockPrefixes?.includes('check')) {
        return data[key] === true ? 'Ja' : 'Nein';
    }

    return data[key];
};
