import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';

export const uiSchema = verticalLayout([
    group([
        control('field101'),
        control('field100'),
        control('field1051'),
        control('field102'),
        control('field500'),
        control('field502'),
        control('field1054'),
        control('field110'),
        control('field110a'),
        control('field110b'),
        control('field140'),
        control('field1065'),
        control('field1011'),
    ]),
]);
