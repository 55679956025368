export const AntragInfoBoxConfig = {
    benutzerDialogText: 'Ihr Ansprechpartner',
};

export const BenutzerDialogConfig = {
    title: 'Ihr Ansprechpartner',
    text: false,
    confirm: 'Schließen',
    abort: false,
    content: {
        name: 'Name:',
        email: 'E-Mail:',
        telefon: 'Tel.:',
    },
};

export const EinrichtungDialogConfig = {
    text: 'Möchten Sie zur Stamm- und Basisdatenübersicht der Einrichtung wechseln?',
};
