import { AntragResponse, AntragVersionCollectionResponse } from 'api/types';

export enum AntragVersionTableActions {
    ANSEHEN = 'ANSEHEN',
    DOKUMENTE = 'DOKUMENTE',
    BESCHEIDE = 'BESCHEIDE',
}

export type AntragVersionTableAction = (
    action: AntragVersionTableActions,
    antragVersion: AntragVersionCollectionResponse,
    antrag: AntragResponse
) => Promise<void>;
