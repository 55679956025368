import React from 'react';

import { FormButton } from 'forms/components/FormButton';

interface FormZurFestsetzungButtonInterface {
    onAbsenden: () => void;
    disabled: boolean;
}

export const FormZurFestsetzungButton: React.FC<FormZurFestsetzungButtonInterface> = ({ onAbsenden, disabled }) => {
    return (
        <FormButton
            type="submit"
            variant="contained"
            color="primary"
            onClick={onAbsenden}
            disabled={disabled}
            data-cy={'FormAbsendenButton'}
        >
            Speichern und Weiter zur Festsetzung
        </FormButton>
    );
};
