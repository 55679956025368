import { useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { BenutzerJsonldEinrichtungRead } from 'api/client';
import { EinrichtungListResponse } from 'api/types';
import { ZustaendigerBenutzerFilterOptions } from 'components/DataTableHydra/Filters/ZustaendigerBenutzerFilterOptions';
import { LvBenutzerOptions } from 'components/DataTableHydra/hooks/useLvBenutzerOptions';
import { GetColumns } from 'components/DataTableHydra/hooks/useTableColumns';
import { ZustaendigkeitenEinrichtungTableAction } from 'constants/zustaendigkeitenEinrichtungActions';
import { LinkEinrichtung } from 'elements/LinkEinrichtung';
import { formatDateToString } from 'forms/utils/formatter';
import { einrichtungListLinkRender } from 'pages/Verwaltung/Pflegeeinrichtungen/Uebersicht/PflegeeinrichtungListLink';
import { DisplayBenutzer, displayBenutzerName } from 'utilities';
import { rightAlignedColumn } from 'utilities/DataTableHydraUtils';

import { ZustaendigkeitenEinrichtungListActions } from './ZustaendigkeitenEinrichtungListActions';

export const useZustaendigkeitenEinrichtungListColumns = (
    zustandigerBenutzerOptions: LvBenutzerOptions,
    handleAction: ZustaendigkeitenEinrichtungTableAction
): GetColumns<EinrichtungListResponse> => {
    return useCallback(
        (data) => {
            if (!data) return [];

            const aktenzeichen: MUIDataTableColumnDef = {
                name: 'aktenzeichen',
                label: 'Aktenzeichen',
                options: {
                    filter: false,
                },
            };

            const name: MUIDataTableColumnDef = {
                name: 'name',
                label: 'Einrichtung',
                options: {
                    filter: false,
                    customBodyRenderLite: (dataIndex: number) => (
                        <LinkEinrichtung
                            id={data['hydra:member'][dataIndex].id}
                            name={data['hydra:member'][dataIndex].name}
                        />
                    ),
                },
            };

            const erstinbetriebnahme: MUIDataTableColumnDef = {
                name: 'erstinbetriebnahme',
                label: 'Erstinb.',
                options: {
                    filter: false,
                    sort: false,
                    customBodyRenderLite: (dataIndex: number) =>
                        data?.['hydra:member']?.[dataIndex]?.erstinbetriebnahme
                            ? formatDateToString(new Date(data['hydra:member'][dataIndex].erstinbetriebnahme))
                            : '-',
                },
            };

            const einrichtungBenutzer: MUIDataTableColumnDef = {
                name: 'einrichtungBenutzer',
                label: 'Nutzer',
                options: {
                    filter: false,
                    sort: false,
                    ...rightAlignedColumn,
                    customBodyRenderLite: (index) => einrichtungListLinkRender(data, index, 'benutzer'),
                },
            };

            const zustaendigerBenutzer: MUIDataTableColumnDef = {
                name: 'zustaendigerBenutzer',
                label: 'Zuständig',
                options: {
                    ...ZustaendigerBenutzerFilterOptions(zustandigerBenutzerOptions),
                    customBodyRender: (benutzer: BenutzerJsonldEinrichtungRead) =>
                        displayBenutzerName(benutzer as DisplayBenutzer),
                },
            };

            const actions: MUIDataTableColumnDef = {
                name: 'actions',
                label: 'Aktion',
                options: {
                    filter: false,
                    sort: false,
                    viewColumns: false,
                    ...rightAlignedColumn,
                    customBodyRenderLite: (index: number) => {
                        return (
                            <ZustaendigkeitenEinrichtungListActions
                                einrichtung={data['hydra:member'][index]}
                                handleAction={handleAction}
                            />
                        );
                    },
                },
            };

            const columns: MUIDataTableColumnDef[] = [
                aktenzeichen,
                name,
                erstinbetriebnahme,
                einrichtungBenutzer,
                zustaendigerBenutzer,
                actions,
            ];

            return columns;
        },
        [handleAction, zustandigerBenutzerOptions]
    );
};
