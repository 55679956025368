import React, { ReactElement } from 'react';
import { JsonForms } from '@jsonforms/react';
import { Grid, Typography } from '@mui/material';

import { EinrichtungResponse } from 'api/types';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { FormButton } from 'forms/components/FormButton';
import { Pflichtfeld } from 'forms/components/Pflichtfeld';
import { ErrorList } from 'forms/controls/components/ErrorList';
import { renderers } from 'forms/renderers';
import {
    CreateBenutzerFormStepEmailOnComplete,
    useCreateBenutzerEmailStepForm,
} from 'pages/Verwaltung/Benutzer/BenutzerErstellenForm/useCreateBenutzerEmailStepForm';

type CreateBenutzerFormStepEmailProps = {
    einrichtung: EinrichtungResponse | null;
    onClose: () => void;
    onComplete: CreateBenutzerFormStepEmailOnComplete;
};

export const CreateBenutzerFormStepEmail = ({
    einrichtung,
    onClose,
    onComplete,
}: CreateBenutzerFormStepEmailProps): ReactElement => {
    const { error, isSubmitting, isValid, onSubmit, ...formProps } = useCreateBenutzerEmailStepForm(
        einrichtung,
        onComplete
    );

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography>
                    {einrichtung
                        ? 'Geben Sie die E-Mail-Adresse des Benutzers an, den Sie zur Einrichtung hinzufügen möchten.'
                        : 'Geben Sie die E-Mail-Adresse des Benutzers an, den Sie hinzufügen möchten.'}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Pflichtfeld>* Pflichtfeld</Pflichtfeld>
            </Grid>
            <Grid item xs={12}>
                <AppLoaderContainer isLoading={isSubmitting}>
                    <JsonForms {...formProps} renderers={renderers} />
                </AppLoaderContainer>
            </Grid>
            {error && (
                <Grid item xs={12}>
                    <ErrorList errors={[error]} />
                </Grid>
            )}
            <Grid item xs={12}>
                <Grid container spacing={1} justifyContent="flex-end">
                    <Grid item>
                        <FormButton
                            type="button"
                            variant="outlined"
                            color="primary"
                            onClick={onClose}
                            disabled={isSubmitting}
                        >
                            Schließen
                        </FormButton>
                    </Grid>
                    <Grid item>
                        <FormButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={onSubmit}
                            disabled={isSubmitting || !isValid}
                        >
                            Benutzer hinzufügen
                        </FormButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
