import React from 'react';
import { LayoutProps } from '@jsonforms/core';
import { JsonFormsDispatch } from '@jsonforms/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, styled, Typography } from '@mui/material';

import { applyGridLayout } from 'forms/AntragForm/ui-schemas/elements/gridLayoutConfig';
import { withLayoutProps } from 'forms/hooks/withJsonFormProps';
import { renderers } from 'forms/renderers';
import { AccordionType } from 'forms/types/UiSchemaTypes';
import { useControlVisibility } from 'forms/utils/UiSchemaUtils';

interface AccordionControlProps extends Omit<LayoutProps, 'uischema'> {
    uischema: AccordionType;
}

const AccordionControlComponent: React.FC<AccordionControlProps> = ({
    schema,
    uischema,
    path,
    visible,
}: AccordionControlProps) => {
    const controlVisibility = useControlVisibility(uischema);
    return visible && controlVisibility ? (
        <StyledAccordion>
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon aria-label="Anzeigen" />}>
                <Typography component={'a'} variant={'h2'}>
                    {uischema.label}
                </Typography>
            </StyledAccordionSummary>
            <ColoredAccordionDetails>
                {uischema.elements.map((elem, i) => {
                    return (
                        <Grid container spacing={2}>
                            <Grid item xs={12} key={i}>
                                <JsonFormsDispatch
                                    uischema={applyGridLayout(elem, uischema.gridLayout)}
                                    schema={schema}
                                    path={path}
                                    renderers={renderers}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider aria-hidden={true} />
                            </Grid>
                        </Grid>
                    );
                })}
            </ColoredAccordionDetails>
        </StyledAccordion>
    ) : (
        <></>
    );
};

const ColoredAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    color:
        theme.palette.background.surfaceHighlighted &&
        theme.palette.getContrastText(theme.palette.background.surfaceHighlighted),
    paddingTop: theme.spacing(2),
}));

const StyledAccordion = styled(Accordion)({
    boxShadow: 'none',
});

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    '& h2.MuiTypography-root': {
        margin: theme.spacing(1),
    },
    flexDirection: 'row-reverse',
    '& .MuiIconButton-edgeEnd': {
        marginLeft: -12,
        marginRight: 0,
    },
    '& .MuiSvgIcon-root': {
        color: theme.palette.text.link,
    },
}));

export const AccordionControl = withLayoutProps(AccordionControlComponent);
