import { useEffect, useState } from 'react';
import { JsonForms } from '@jsonforms/react';
import { isEqual } from 'lodash';

import { ZustaendigkeitJsonldZustaendigkeitRead } from 'api/client';
import { ROLLEN } from 'constants/roles';
import { SelectOption } from 'forms/components/Typeahead';
import { useFormValidation } from 'forms/hooks/useFormValidation';
import { useLayoutWithJsonFormsState } from 'forms/hooks/useJsonFormsState';
import { renderers } from 'forms/renderers';
import { FormLayoutConfig, FormStateChange, Schema } from 'forms/types/UiSchemaTypes';

interface ZustaendigkeitFormPropsInterface {
    handleSubmit(formData?: ZustaendigkeitFormDataInterface): void;

    isSubmitting: boolean;
    submit?: boolean;
    zustaendigkeit: ZustaendigkeitJsonldZustaendigkeitRead;

    setValid(valid: boolean): void;
}

export interface ZustaendigkeitFormDataInterface {
    benutzer: SelectOption | undefined;
}

export const FormRegion = ({
    handleSubmit,
    isSubmitting,
    submit,
    zustaendigkeit,
    setValid,
}: ZustaendigkeitFormPropsInterface) => {
    const [formData, setFormData] = useState<ZustaendigkeitFormDataInterface>();
    const formSchema: Schema = {
        type: 'object',
        required: ['benutzer'],
        properties: {
            benutzer: {
                $id: 'benutzer',
                type: 'object',
                title: 'Benutzer zuweisen',
                // @ts-ignore
                custom: {
                    block_prefixes: ['benutzer_autocomplete'],
                    rollen: [ROLLEN.LV_ADMIN, ROLLEN.LV_SACHBEARBEITER],
                    landschaftsverband: zustaendigkeit.landschaftsverband,
                },
            },
        },
    };

    const handleChange = (state: FormStateChange) => {
        if (!isEqual(state.data, formData)) {
            setFormData(state.data);
        }
    };

    useEffect(() => {
        if (submit) {
            handleSubmit(formData);
        }
    }, [submit, handleSubmit, isSubmitting, formData]);

    const configLayout: FormLayoutConfig = {
        gridLayout: {
            label: 3,
            input: 4,
        },
    };

    const config = useLayoutWithJsonFormsState(configLayout);

    const { valid } = useFormValidation(formSchema, formData, undefined);

    useEffect(() => {
        setValid(valid);
    }, [valid, setValid]);

    return (
        <>
            {formSchema && (
                <JsonForms
                    data={formData}
                    schema={formSchema}
                    onChange={handleChange}
                    renderers={renderers}
                    config={config}
                />
            )}
        </>
    );
};
