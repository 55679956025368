import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { FestsetzungsAntragCollectionListResponse } from 'api/types';
import { getFestsetzungFilterParameters, getFestsetzungSortParameters } from 'api/utils/festsetzungHelpers';
import { GetData } from 'components/DataTableHydra/hooks/useTableData';

export const useFestsetzungsAntragListData = (): GetData<FestsetzungsAntragCollectionListResponse> => {
    return useCallback(({ page, rowsPerPage, searchText, sortOrder, filters }) => {
        return backendApiService.getFestsetzungsantragCollection({
            page,
            itemsPerPage: rowsPerPage,
            qSearch: searchText ? [searchText] : undefined,
            ...getFestsetzungFilterParameters(filters),
            ...getFestsetzungSortParameters(sortOrder),
        });
    }, []);
};
