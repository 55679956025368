import { useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { TraegerverbandListResponse } from 'api/types';
import { GetColumns } from 'components/DataTableHydra/hooks/useTableColumns';
import { traegerverbandListLinkRender } from 'pages/Verwaltung/Traegerverband/TraegerverbandListLink';
import { rightAlignedColumn } from 'utilities/DataTableHydraUtils';

export const useTraegerverbandListColumns = (): GetColumns<TraegerverbandListResponse> => {
    return useCallback((data) => {
        if (!data) return [];

        const name: MUIDataTableColumnDef = {
            name: 'name',
            label: 'Name des Trägerverbands',
        };

        const traeger: MUIDataTableColumnDef = {
            name: 'traeger',
            label: 'Träger',
            options: {
                customBodyRenderLite: (index) => traegerverbandListLinkRender(data, index, 'traeger'),
                ...rightAlignedColumn,
            },
        };

        const einrichtungen: MUIDataTableColumnDef = {
            name: 'einrichtungen',
            label: 'Einrichtungen',
            options: {
                customBodyRenderLite: (index) => traegerverbandListLinkRender(data, index, 'einrichtungen'),
                ...rightAlignedColumn,
            },
        };

        const benutzer: MUIDataTableColumnDef = {
            name: 'benutzer',
            label: 'Nutzer',
            options: {
                customBodyRenderLite: (index) => traegerverbandListLinkRender(data, index, 'benutzer'),
                ...rightAlignedColumn,
            },
        };

        const columns: MUIDataTableColumnDef[] = [name, traeger, einrichtungen, benutzer];

        return columns;
    }, []);
};
