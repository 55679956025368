import React, { ReactElement } from 'react';
import { CardContent, Grid, styled, Typography } from '@mui/material';

import { useAuthHelper } from 'api/auth/useAuthHelper';
import { useApiMassenmailCollection } from 'api/hooks/useApiClient';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';

import { DashboardMagsExterneQuellen } from './DashboardMagsExterneQuellen';
import { DashboardMagsMailings } from './DashboardMagsMailings';

const DashboardMags = (): ReactElement => {
    const { getCurrentBenutzer } = useAuthHelper();
    const benutzer = getCurrentBenutzer();

    const { data: massenmails, isLoading: isLoadingMassenmails } = useApiMassenmailCollection(1, 5);

    return (
        <ContentContainer>
            <Grid container spacing={5}>
                <Grid item md={7}>
                    <Title>{`${benutzer?.vorname} ${benutzer?.nachname}`}</Title>
                </Grid>
            </Grid>

            <Grid container spacing={5}>
                <Grid item md={7}>
                    <StyledContainer bordered backgroundVariant="secondary">
                        <CardContent>
                            <Typography variant="h1" component="h3">
                                Auswertungen PfAD.invest
                            </Typography>
                            <Typography>Statistikmodul</Typography>
                        </CardContent>
                    </StyledContainer>
                </Grid>

                <Grid item md={5}>
                    <StyledContainer bordered backgroundVariant="secondary">
                        <DashboardMagsMailings
                            massenmails={massenmails ? massenmails['hydra:member'] : undefined}
                            isLoading={Boolean(isLoadingMassenmails)}
                        />
                    </StyledContainer>
                    <br />
                    <StyledContainer bordered backgroundVariant="secondary">
                        <DashboardMagsExterneQuellen />
                    </StyledContainer>
                </Grid>
            </Grid>
        </ContentContainer>
    );
};

const Title = styled('h1')(() => ({
    margin: '0 !important',
}));

export const DashboardMagsPage = withSideBar(DashboardMags, []);
