import { useState } from 'react';
import { JsonFormsCore } from '@jsonforms/core';
import { isEqual } from 'lodash';

import { AntragType, ZurueckziehenAntragType } from 'api/antragTypes';
import { backendApiService } from 'api/ApiService';
import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/AntragForm/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { useMessageActions } from 'forms/state/useMessages';
import { Schema } from 'forms/types/UiSchemaTypes';
import { errorMessage, successMessage } from 'forms/utils/MessageUtils';
import { AntragListActionItem } from 'pages/Antraege/components/useAntragListActions';

export interface ZurueckziehenBeantragenData {
    antrag: number;
    erlaeuterung: string | null;
}

export const useZurueckziehenBeantragenDialog = (
    initialData: AntragListActionItem,
    antragType: ZurueckziehenAntragType
) => {
    const { addMessage } = useMessageActions();
    const [data, setData] = useState<ZurueckziehenBeantragenData>(() => ({
        antrag: initialData.id,
        erlaeuterung: null,
    }));

    const onSubmit = (): Promise<void> => {
        const endpoint = determineEndpoint(antragType);
        return endpoint(data.antrag, data.erlaeuterung)
            .then(() => {
                addMessage(createSuccessMessage());
            })
            .catch((e) => {
                console.error(e);
                addMessage(createErrorMessage());
            });
    };

    const onChange = ({ data: newData }: Pick<JsonFormsCore, 'data' | 'errors'>) => {
        if (isEqual(newData, data)) return;
        setData(newData);
    };

    const schema: Schema = {
        type: 'object',
        properties: {
            erlaeuterung: {
                type: 'string',
                title: 'Bitte begründen Sie, warum der Antrag zurückgezogen werden soll.',
                // @ts-ignore
                custom: {
                    block_prefixes: ['plain_textarea'],
                },
            },
        },
        required: ['erlaeuterung'],
    };

    const uiSchema = customVerticalLayout([group([control('erlaeuterung')])]);

    return { schema, uiSchema, data, onChange, onSubmit };
};

const determineEndpoint = (
    type: ZurueckziehenAntragType
): ((id: number, erlaeuterung: string | null) => Promise<void>) => {
    switch (type) {
        case AntragType.FESTSTELLUNG:
            return backendApiService.postFeststellungsantragZurueckziehen.bind(backendApiService);
        case AntragType.FESTSETZUNG:
            return backendApiService.postFestsetzungsantragZurueckziehen.bind(backendApiService);
        case AntragType.INVESTOREN_MODELL:
            return backendApiService.postInvestorenmodellZurueckziehen.bind(backendApiService);
    }
};

const createSuccessMessage = () =>
    successMessage('Zurückziehen erfolgreich beantragt.', {
        autoCloseSeconds: 8,
        closable: true,
    });

const createErrorMessage = () =>
    errorMessage('Zurückziehen konnte nicht beantragt werden.', {
        autoCloseSeconds: 8,
        closable: true,
    });
