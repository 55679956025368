import { ChangeEvent, Fragment, ReactElement, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import globalAxios, { CancelToken } from 'axios';

import { Schema } from 'forms/types/UiSchemaTypes';

export interface SelectOption {
    id: number;
    name: string;
}

interface TypeaheadPropsBase {
    schema: Schema;
    path: string;
    error?: boolean;
    noOptionsText?: string;
    id?: string;

    getData(value: string, cancelToken: CancelToken): Promise<any>;

    getOption(item: any): SelectOption;
}

type TypeaheadMultipleProps = TypeaheadPropsBase & {
    multiple: true;
    data: SelectOption[] | null;
    onChange: (value: SelectOption[] | null) => void;
};

type TypeaheadSingleProps = TypeaheadPropsBase & {
    multiple: false;
    data: SelectOption | null;
    onChange: (value: SelectOption | null) => void;
};

type TypeaheadProps = TypeaheadSingleProps | TypeaheadMultipleProps;

export const Typeahead = ({
    id,
    data,
    schema,
    path,
    multiple,
    error,
    getData,
    getOption,
    onChange,
    noOptionsText = 'Keine Elemente gefunden.',
}: TypeaheadProps): ReactElement => {
    const [options, setOptions] = useState<SelectOption[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    let timer: any;
    const [cancelTokenSource, setCancelTokenSource] = useState(globalAxios.CancelToken.source());

    const onInputChange = (event: object, value: string) => {
        if (timer) {
            clearTimeout(timer);
        }
        if (isLoading && cancelTokenSource) {
            cancelTokenSource.cancel();
        }
        if (!value) {
            setOptions([]);
            return;
        }
        timer = setTimeout(() => {
            const newCancelTokenSource = globalAxios.CancelToken.source();
            setCancelTokenSource(newCancelTokenSource);
            setIsLoading(true);
            getData(value, newCancelTokenSource.token)
                .then((response) => {
                    setOptions(
                        response['hydra:member'].map((item: any) => {
                            return getOption(item);
                        }) as SelectOption[]
                    );
                })
                .catch(() => {}) // Nicht entfernen, wird für cancel benötigt
                .finally(() => {
                    setIsLoading(false);
                });
        }, 500);
    };

    const handleOnChange = (event: ChangeEvent<object>, newValue: any) => {
        onChange(newValue);
    };

    return (
        <Autocomplete
            id={id}
            value={data}
            multiple={multiple}
            onChange={handleOnChange}
            onInputChange={onInputChange}
            noOptionsText={noOptionsText}
            getOptionLabel={(option) => option?.name || ''}
            isOptionEqualToValue={(option, currentValue) => option.id === currentValue.id}
            options={options}
            loading={isLoading}
            renderInput={(params) => (
                <TextField
                    error={error}
                    {...params}
                    placeholder={schema.title}
                    InputProps={{
                        ...params.InputProps,
                        inputProps: {
                            ...params.inputProps,
                            'data-cy': `form_${path}`,
                        },
                        endAdornment: (
                            <Fragment>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </Fragment>
                        ),
                    }}
                />
            )}
        />
    );
};
