import React from 'react';
import { JsonForms } from '@jsonforms/react';
import { Grid, Typography } from '@mui/material';

import { NoSeparatorGroup, StyledGroup } from 'forms/controls/GroupControl';
import { useJsonFormsState } from 'forms/hooks/useJsonFormsState';
import { renderers } from 'forms/renderers';
import { FormConfig, GridLayoutConfig, UiSchemaType } from 'forms/types/UiSchemaTypes';
import { getFieldSchemaByScope } from 'forms/utils/SchemaUtils';

import { GroupListDataType, GroupType } from './GroupList';

interface GroupListItemProps {
    elements: UiSchemaType[];
    group: GroupListDataType;
    gridLayout?: GridLayoutConfig;
    vertical?: boolean;
    separator?: boolean;
    config: FormConfig;
}

export const GroupListItem: React.FC<GroupListItemProps> = ({
    elements,
    group,
    gridLayout,
    vertical = false,
    separator = false,
    config,
}) => {
    const Container = separator ? StyledGroup : NoSeparatorGroup;
    return (
        <Container data-cy={'GroupListItem'}>
            {group.title && <Typography variant={'h3'}>{group.title}</Typography>}
            <Grid container spacing={2}>
                {group.controls.map((c, ci) =>
                    elements
                        .filter((e) => getFieldSchemaByScope(e.scope, c.schema))
                        .map((element, ei) => (
                            <GroupListItemControl
                                key={`${ci}-${ei}`}
                                group={c}
                                vertical={vertical}
                                uischema={
                                    {
                                        ...element,
                                        gridLayout: element.gridLayout || gridLayout,
                                    } as UiSchemaType
                                }
                                config={config}
                            />
                        ))
                )}
            </Grid>
        </Container>
    );
};

export const GroupListItemControl: React.FC<{
    vertical?: boolean;
    group: GroupType;
    uischema: UiSchemaType;
    config: FormConfig;
}> = ({ vertical = false, group, uischema }) => {
    const { handleChange, config } = useJsonFormsState();
    return (
        <Grid item xs={vertical ? 12 : 6}>
            <JsonForms
                schema={group.schema}
                uischema={uischema}
                data={group.data || {}}
                renderers={renderers}
                config={config}
                onChange={(state) => handleChange(group.path, state.data)}
            />
        </Grid>
    );
};
