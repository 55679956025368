import { useMemo } from 'react';
import { CardContent, Grid, styled } from '@mui/material';
import { subDays } from 'date-fns';

import { useCurrentBenutzerId } from 'api/auth/useAuthHelper';
import { useApiEinrichtungCollection } from 'api/hooks/useApiClient';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { formatDateToString } from 'forms/utils/formatter';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { NEUE_EINRICHTUNG_ZEITRAUM_TAGE } from 'pages/Dashboard/config';
import { DashboardLvAufgaben } from 'pages/Dashboard/Landschaftsverband/DashboardLvAufgaben';
import { DashboardLvAufgabenInfo } from 'pages/Dashboard/Landschaftsverband/DashboardLvAufgabenInfo';
import { DashboardLvBenutzerToolbar } from 'pages/Dashboard/Landschaftsverband/DashboardLvBenutzerToolbar';
import { DashboardLvBescheidVersand } from 'pages/Dashboard/Landschaftsverband/DashboardLvBescheidVersand';
import { DashboardLvNeueEinrichtungen } from 'pages/Dashboard/Landschaftsverband/DashboardLvNeueEinrichtungen';
import { useDashboardLvAufgaben } from 'pages/Dashboard/Landschaftsverband/useDashboardLvAufgaben';
import { transientOptions } from 'theme/utils';

const DashboardLv = () => {
    const createdAfter = useMemo(() => {
        return formatDateToString(subDays(new Date(), NEUE_EINRICHTUNG_ZEITRAUM_TAGE), 'yyyy-MM-dd');
    }, []);

    const benutzerId = useCurrentBenutzerId();
    const { data: einrichtungen, isLoading: isLoadingEinrichtungen } = useApiEinrichtungCollection({
        page: 1,
        itemsPerPage: 10,
        zustaendigerBenutzer: benutzerId,
        createdAtAfter: createdAfter,
    });

    const { data: aufgaben, isLoading: isLoadingAufgaben } = useDashboardLvAufgaben();

    return (
        <ContentContainer title="Dashboard">
            <DashboardLvBenutzerToolbar showTitle />
            <Grid container spacing={5}>
                <Grid item md={7}>
                    <AufgabenContainer bordered $isLoading={isLoadingAufgaben}>
                        <AppLoaderContainer isLoading={isLoadingAufgaben}>
                            <DashboardLvAufgabenInfo aufgaben={aufgaben} />
                            <CardContent>
                                <DashboardLvAufgaben aufgaben={aufgaben} />
                            </CardContent>
                        </AppLoaderContainer>
                    </AufgabenContainer>
                </Grid>
                <Grid item md={5}>
                    <StyledContainer bordered backgroundVariant="secondary">
                        <DashboardLvBescheidVersand benutzerId={benutzerId} />
                    </StyledContainer>
                    <br />
                    <StyledContainer bordered={false} backgroundVariant="secondary">
                        <DashboardLvNeueEinrichtungen
                            einrichtungen={einrichtungen ? einrichtungen['hydra:member'] : undefined}
                            isLoading={isLoadingEinrichtungen}
                        />
                    </StyledContainer>
                </Grid>
            </Grid>
        </ContentContainer>
    );
};

const AufgabenContainer = styled(
    StyledContainer,
    transientOptions
)<{ $isLoading: boolean }>(({ theme, $isLoading }) => ({
    paddingTop: theme.spacing($isLoading ? 2 : 0),
}));

export const DashboardLandschaftsverbandPage = withSideBar(DashboardLv, []);
