import React from 'react';
import { Add } from '@mui/icons-material';
import { Button, ButtonProps, styled } from '@mui/material';

import { transientOptions } from 'theme/utils';

interface FormAddButtonProps extends ButtonProps {
    label: string;
    spacingTop?: number;
}

export const FormAddButton: React.FC<FormAddButtonProps> = ({ label, spacingTop = 1, ...props }) => {
    return (
        <LinkButton {...props} $spacingTop={spacingTop} startIcon={<LinkButtonIcon />}>
            {label}
        </LinkButton>
    );
};

const LinkButton = styled(
    Button,
    transientOptions
)<{ $spacingTop: number }>(({ theme, $spacingTop }) => ({
    color: theme.palette.text.link,
    marginTop: theme.spacing($spacingTop),
}));

const LinkButtonIcon = styled(Add)(({ theme }) => ({
    color: theme.palette.text.link,
}));
