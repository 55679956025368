import { useMemo } from 'react';

import { GroupType } from 'forms/controls/GroupList/GroupList';
import { Schema } from 'forms/types/UiSchemaTypes';
import { buildPath, determineSchemaByUUID, getFieldSchemaByScope } from 'forms/utils/SchemaUtils';
import { getFieldFromScope } from 'utilities';

export const useControlsOfElements = (data: any, schema: any, scope: string): GroupType[] => {
    return useMemo(() => buildControlGroups(data, schema, scope), [data, schema, scope]);
};

export const buildControlGroups = (data: any, schema: Schema, controlScope: string, parentPath?: string) => {
    const elements = data[getFieldFromScope(controlScope)];
    if (!Array.isArray(elements)) {
        return [];
    }
    const elementsSchema = getFieldSchemaByScope(controlScope, schema);
    return elements.map((elem, i) => ({
        uuid: elem.uuid,
        path: buildPath(parentPath, getFieldFromScope(controlScope), i),
        schema: determineSchemaByUUID(elem.uuid, elementsSchema!),
        data: elem,
    }));
};
