import { useEffect, useState } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

export type GetColumns<Data = any> = (data: Data | undefined) => MUIDataTableColumnDef[];

export const useTableColumns = <Data = any>(
    data: Data | undefined,
    filters: Record<string, any>,
    getColumns: GetColumns<Data>
): MUIDataTableColumnDef[] => {
    const [columns, setColumns] = useState(() => populateFilters(getColumns(data), filters));

    useEffect(() => {
        setColumns(populateFilters(getColumns(data), filters));
    }, [getColumns, data, filters]);

    return columns;
};

export const populateFilters = (
    columns: MUIDataTableColumnDef[],
    filters: Record<string, any>
): MUIDataTableColumnDef[] => {
    return columns.map((column) => {
        if (typeof column === 'string') {
            return column;
        } else {
            return {
                ...column,
                options: {
                    ...column.options,
                    filterList: filters[column.name] ? filters[column.name] : column.options?.filterList,
                },
            };
        }
    });
};
