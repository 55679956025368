import { ReactElement } from 'react';
import { styled } from '@mui/material';

import { AufgabenResponse } from 'api/types';
import { AufgabenList } from 'pages/Dashboard/config';

interface DashboardLvAufgabenInfoProps {
    aufgaben: AufgabenResponse | undefined;
}

export const DashboardLvAufgabenInfo = ({ aufgaben }: DashboardLvAufgabenInfoProps): ReactElement => {
    if (!aufgaben) {
        return <></>;
    }

    const aufgabenCount = AufgabenList.map((item) => {
        return aufgaben[item.apiField as keyof AufgabenResponse];
    }).reduce((agg, curr) => agg + (curr ?? 0), 0);

    return <Text>{`Sie haben ${aufgabenCount} Aufgaben`}</Text>;
};

const Text = styled('p')(() => ({
    marginTop: '16px',
    marginBottom: 0,
}));
