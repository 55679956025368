import { Box, styled } from '@mui/material';

interface StyledBoxProps {
    backgroundVariant?: 'primary' | 'secondary';
    bordered?: boolean;
    paddingVariant?: 'sm' | 'md' | 'lg';
}

const getBackgroundColor = (variant?: string) => {
    switch (variant) {
        case 'primary':
            return '#EAEFF6';
        case 'secondary':
            return '#F2F2F2';
        default:
            return 'transparent';
    }
};

const getPadding = (padding?: string) => {
    switch (padding) {
        case 'sm':
            return '10px';
        case 'md':
            return '15px';
        case 'lg':
            return '30px';
        default:
            return 0;
    }
};

export const StyledContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'backgroundVariant' && prop !== 'paddingVariant' && prop !== 'bordered',
})<StyledBoxProps>(
    (props) => `
  border-top: ${props.bordered ? '3px solid #2699FB' : 0};
  background: ${getBackgroundColor(props.backgroundVariant)};
  padding: ${getPadding(props.paddingVariant)};
  margin-top: ${props.bordered ? 0 : '3px'};

  ${
      !props.backgroundVariant
          ? `
  & .MuiCardContent-root {
    padding-right: 0;
    padding-left: 0;
  }`
          : ``
  }

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    margin: 2em 0 1em;
  }

  & h1:first-of-type,
  & h2:first-of-type,
  & h3:first-of-type,
  & h4:first-of-type,
  & h5:first-of-type,
  & h6:first-of-type {
    margin: 0em 0 1em;
  }
`
);
