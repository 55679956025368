import React, { ReactNode, SyntheticEvent, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';

import { Step } from 'layout/HeaderConfig';

interface TabRouterNavigationType {
    tabs: Step<string>[];
    buildNewPath: (newTab: string) => string;
    children?: ReactNode;
}

export const TabRouterNavigation: React.FC<TabRouterNavigationType> = ({ tabs, buildNewPath, children }) => {
    const { step } = useParams();
    const navigate = useNavigate();

    const onChange = useCallback(
        (e: SyntheticEvent, newTab: string) => navigate(buildNewPath(newTab)),
        [navigate, buildNewPath]
    );

    if (!step) return <></>;
    return (
        <TabContext value={step}>
            <TabList onChange={onChange}>
                {(tabs || []).map((tab) => (
                    <Tab key={tab.step} value={tab.step} label={tab.title} component="div" />
                ))}
            </TabList>
            {(tabs || []).map((tab) => (
                <TabPanel key={tab.step} value={tab.step}>
                    {tab.component}
                    {children}
                </TabPanel>
            ))}
        </TabContext>
    );
};
