import React from 'react';

import { AntragType } from 'api/antragTypes';
import { AntragResponse } from 'api/types';
import { NavigationControls } from 'navigation/components/NavigationControls';
import { buildPath, buildRelativePath, determinePathForAntragType, PATH_ANTRAEGE } from 'navigation/Paths';
import { TabRouterNavigation } from 'navigation/TabRouterNavigation';
import { useAntragDetailsTabConfig } from 'pages/Antraege/components/useAntragDetailsTabConfig';

interface AntragDetailsContentProps {
    type: AntragType;
    antrag: AntragResponse;
}

export const AntragDetailsContent = ({ type, antrag }: AntragDetailsContentProps) => {
    const tabs = useAntragDetailsTabConfig(type, antrag);
    const basePath = buildPath(PATH_ANTRAEGE, determinePathForAntragType(type));

    return (
        <>
            <TabRouterNavigation
                tabs={tabs}
                buildNewPath={(newTab) => buildRelativePath(basePath, antrag.id, newTab)}
            />
            <NavigationControls
                steps={tabs}
                backUrl={basePath}
                stepPathBuilder={(id, newTab) => buildRelativePath(basePath, id, newTab)}
                position={'right'}
            />
        </>
    );
};
