import { Schema } from 'forms/types/UiSchemaTypes';

const blockPrefixUnits: Record<string, string> = {
    m2: 'm²',
    betrag: '€',
    prozent: '%',
    // ...
};

export const useUnit = (schema?: Schema): string | undefined => {
    if (!schema) {
        return undefined;
    }
    if (schema.custom?.unit) {
        return schema.custom?.unit;
    }
    const key = Object.keys(blockPrefixUnits).find((key) => schema.custom?.block_prefixes?.includes(key));
    return key && blockPrefixUnits[key];
};
