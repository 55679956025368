import React from 'react';
import { LayoutProps, RankedTester, rankWith, uiTypeIs } from '@jsonforms/core';
import { Grid, Typography } from '@mui/material';

import { withLayoutProps } from 'forms/hooks/withJsonFormProps';
import { LabelRowControlType } from 'forms/types/UiSchemaTypes';

export const LabelRowControlTester: RankedTester = rankWith(1, uiTypeIs('LabelRowControl'));

interface LabelRowControlProps extends Omit<LayoutProps, 'uischema'> {
    uischema: LabelRowControlType;
}

export const LabelRowControlComponent: React.FC<LabelRowControlProps> = ({ uischema }) => {
    return uischema.text !== undefined && uischema.text !== null && uischema.text ? (
        <Grid item xs={12} md={7}>
            <Typography>
                <span style={uischema.styles} dangerouslySetInnerHTML={{ __html: uischema.text }} />
            </Typography>
        </Grid>
    ) : (
        <></>
    );
};

export const LabelRowControl = withLayoutProps(LabelRowControlComponent);
