import React from 'react';
import { LayoutProps, rankWith } from '@jsonforms/core';
import { JsonForms, withJsonFormsLayoutProps } from '@jsonforms/react';

import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { useJsonFormsState } from 'forms/hooks/useJsonFormsState';
import { useLabel } from 'forms/hooks/useLabel';
import { renderers } from 'forms/renderers';
import { CollectionGroupsType, Schema } from 'forms/types/UiSchemaTypes';
import { buildPath, determineSchemaByUUID, getFieldSchemaByScope } from 'forms/utils/SchemaUtils';
import { getFieldFromScope } from 'utilities';

interface CollectionGroupsInterface extends Omit<LayoutProps, 'uischema' | 'schema'> {
    uischema: CollectionGroupsType;
    schema: Schema;
}

const CollectionGroupsComponent: React.FC<CollectionGroupsInterface> = ({ data, uischema, schema, path }) => {
    const collection = data[getFieldFromScope(uischema.scope)];
    const collectionSchema = getFieldSchemaByScope(uischema.scope, schema);

    if (!collection || !Array.isArray(collection)) {
        console.warn(`${uischema.scope} does not exist or is not from type array`);
        return <></>;
    }

    return (
        <>
            {collection.map((item, index) => (
                <CollectionGroupItem
                    key={index}
                    item={item}
                    path={buildPath(path, getFieldFromScope(uischema.scope), index)}
                    uischema={uischema}
                    schema={collectionSchema!}
                />
            ))}
        </>
    );
};

const CollectionGroupItem: React.FC<{
    item: any;
    schema: Schema;
    uischema: CollectionGroupsType;
    path: string;
}> = ({ item, schema, uischema, path }) => {
    const { config, handleChange } = useJsonFormsState();
    const label = useLabel(item, determineSchemaByUUID(item.uuid, schema), uischema);
    return (
        <JsonForms
            schema={determineSchemaByUUID(item.uuid, schema)}
            uischema={group(uischema.entries, {}, label)}
            data={item}
            config={config}
            renderers={renderers}
            onChange={(state) => handleChange(path, state.data)}
        />
    );
};

// @ts-ignore
export const CollectionGroups = withJsonFormsLayoutProps(CollectionGroupsComponent);

export const CollectionGroupsTester = rankWith(1, (uischema) => {
    return uischema.type === 'CollectionGroups';
});
