import { useEffect, useState } from 'react';

import { FormStateValue, Schema } from 'forms/types/UiSchemaTypes';

export const useIsDataChanged = (data: FormStateValue, { custom }: Schema) => {
    const [isDataChanged, setDataChanged] = useState(false);

    useEffect(() => {
        setDataChanged(Boolean(custom && custom.compare_previous_value && data !== custom.previous_value));
    }, [data, custom]);

    return isDataChanged;
};
