import React, { ReactElement } from 'react';

import { DataTableHydraServerside } from 'components/DataTableHydra/DataTableHydraServerside';
import { useTraegerverbandBenutzerListColumns } from 'pages/Verwaltung/Traegerverband/Benutzer/useTraegerverbandBenutzerListColumns';
import { useTraegerverbandBenutzerListData } from 'pages/Verwaltung/Traegerverband/Benutzer/useTraegerverbandBenutzerListData';

type TraegerverbandBenutzerListProps = {
    id: string;
};

export const TraegerverbandBenutzerList = ({ id }: TraegerverbandBenutzerListProps): ReactElement => {
    const getData = useTraegerverbandBenutzerListData(id);
    const getColumns = useTraegerverbandBenutzerListColumns();

    return (
        <DataTableHydraServerside
            getData={getData}
            getColumns={getColumns}
            initialOptions={{
                filter: false,
                sort: false,
                viewColumns: false,
            }}
        />
    );
};
