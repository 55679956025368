export const erloese_table = {
    type: 'SummaryTable',
    label: 'Erlöse',
    columns: 3,
    scope: '#/properties/erloese',
    entries: [
        {
            type: 'FieldRow',
            scope: '#/properties/field590',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/field591',
        },
        {
            type: 'NotesRow',
            scope: '#/properties/notizErloese',
        },
    ],
};
