import { Typography } from '@mui/material';

import { ContentContainer } from 'layout/container/ContentContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { withAuthorization } from 'navigation/withAuthorization';
import { TraegerverbandList } from 'pages/Verwaltung/Traegerverband/TraegerverbandList';
import { VerwaltungConfig } from 'pages/Verwaltung/VerwaltungConfig';

const Traegerverband = () => (
    <ContentContainer title="Trägerverbände">
        <Typography variant="h1">Trägerverbände</Typography>
        <TraegerverbandList />
    </ContentContainer>
);

export const TraegerverbandPage = withAuthorization(
    withSideBar(Traegerverband, VerwaltungConfig.menu),
    VerwaltungConfig.roles.traegerverband
);
