import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { gridLayoutConfig } from 'forms/AntragForm/ui-schemas/elements/gridLayoutConfig';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { groupForm } from 'forms/AntragForm/ui-schemas/elements/groupForm';
import { info } from 'forms/AntragForm/ui-schemas/elements/info';
import { inlineGroupRowControl } from 'forms/AntragForm/ui-schemas/elements/inlineGroupRowControl';
import { nestedGroup } from 'forms/AntragForm/ui-schemas/elements/nestedGroup';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';
import { notes } from 'forms/AntragForm/ui-schemas/groups/notes';

export const uiSchema = verticalLayout([
    group([control('field110'), control('field950')], {}, 'Platzzahlen aus dem Feststellungsverfahren'),
    group(
        [
            info(
                'Bitte tragen Sie hier die tatsächlichen Belegungstage passend zu der von Ihnen gewählten Differenzierung ein. Bestandseinrichtungen sind verpflichtet, die Belegung der drei Kalenderjahre vor Antragstellung anzugeben. Liegt der Zeitpunkt der Inbetriebnahme noch keine drei Jahre zurück, können nur die vorhandenen Daten erfasst werden.'
            ),
            groupForm(
                '#/properties/belegungStandardEinzelzimmer',
                'Jahr {#/properties/jahr}',
                [
                    group([
                        inlineGroupRowControl('belegungStandardEinzelzimmer', 'field561', {
                            action: { label: 'Zeile nullen', value: 0 },
                        }),
                        inlineGroupRowControl('belegungStandardEinzelzimmer', 'field561a', {
                            action: { label: 'Zeile nullen', value: 0 },
                        }),
                        inlineGroupRowControl('belegungStandardEinzelzimmer', 'field570', {
                            action: { label: 'Zeile nullen', value: 0 },
                        }),
                    ]),
                    group([
                        inlineGroupRowControl('belegungStandardDoppelzimmer', 'field562', {
                            action: { label: 'Zeile nullen', value: 0 },
                        }),
                        inlineGroupRowControl('belegungStandardDoppelzimmer', 'field562a', {
                            action: { label: 'Zeile nullen', value: 0 },
                        }),
                        inlineGroupRowControl('belegungStandardDoppelzimmer', 'field570', {
                            action: { label: 'Zeile nullen', value: 0 },
                        }),
                    ]),
                    nestedGroup(
                        'zimmerkategorien',
                        group([
                            inlineGroupRowControl('belegungSonderkategorie', 'field563', {
                                action: { label: 'Zeile nullen', value: 0 },
                            }),
                            inlineGroupRowControl('belegungSonderkategorie', 'field563a', {
                                action: { label: 'Zeile nullen', value: 0 },
                            }),
                            inlineGroupRowControl('belegungSonderkategorie', 'field570', {
                                action: { label: 'Zeile nullen', value: 0 },
                            }),
                        ])
                    ),
                    group([
                        inlineGroupRowControl('gesamtbelegung', 'field571'),
                        inlineGroupRowControl('gesamtbelegung', 'field573'),
                        control('field570d', {
                            controlOnly: true,
                            gridLayout: gridLayoutConfig(1, 3, 3),
                        }),
                    ]),
                    group(
                        [
                            inlineGroupRowControl('belegungSeparateKzp', 'field564'),
                            inlineGroupRowControl('belegungSeparateKzp', 'field564a'),
                            inlineGroupRowControl('belegungSeparateKzp', 'field564b'),
                        ],
                        {},
                        '',
                        '"#/properties/belegungSeparateKzp'
                    ),
                ],
                {
                    gridLayout: gridLayoutConfig(1, 3, 5),
                }
            ),
        ],
        { noSeparator: true }
    ),

    notes('notizBelegung'),
]);
