import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { customHorizontalLayout } from 'forms/AntragForm/ui-schemas/elements/customHorizontalLayout';
import { customVerticalLayout } from 'forms/AntragForm/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { groupList } from 'forms/AntragForm/ui-schemas/elements/groupList';
import { highlightedArea } from 'forms/AntragForm/ui-schemas/elements/highlightedArea';

export const uiSchema = customVerticalLayout([
    group(
        [
            groupList(
                '#/properties/jahr',
                ['#/properties/aufwandSalg', '#/properties/aufwandInstandhaltung'],
                [control('field520'), control('field1309a')],
                {},
                {
                    fieldnumber: 2,
                    label: 6,
                    input: 3,
                }
            ),
            customHorizontalLayout(
                [
                    control('field1308', {
                        gridLayout: {
                            fieldnumber: 2,
                            label: 6,
                            input: 3,
                        },
                    }),
                    control('field1309', {
                        gridLayout: {
                            fieldnumber: 2,
                            label: 6,
                            input: 3,
                        },
                    }),
                ],
                {
                    style: {
                        marginTop: 8,
                    },
                }
            ),
        ],
        {}
    ),
    group([
        control('field1310'),
        control('field1306'),
        control('field1307'),
        control('field1311'),
        control('field5308'),
        control('field5308b'),
        control('field5305'),
        control('field5305kv'),
        highlightedArea([control('field5309'), control('field5310')]),
    ]),
]);
