import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { JsonFormsCore } from '@jsonforms/core';
import { JsonForms } from '@jsonforms/react';
import { Grid, styled } from '@mui/material';

import { AntragType } from 'api/antragTypes';
import { backendApiService } from 'api/ApiService';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { ACTIONS } from 'constants/antragActions';
import { FormAbsendenButton } from 'forms/AntragForm/components/FormAbsendenButton';
import { FormGegenpruefungAblehnenButton } from 'forms/AntragForm/components/FormGegenpruefungAblehnenButton';
import { FormGegenpruefungFreigebenButton } from 'forms/AntragForm/components/FormGegenpruefungFreigebenButton';
import { FormInvalidHint } from 'forms/AntragForm/components/FormInvalidHint';
import { FormSaveButton } from 'forms/AntragForm/components/FormSaveButton';
import { FormZurPruefungFreigebenButton } from 'forms/AntragForm/components/FormZurPruefungFreigebenButton';
import { MeldungAbgebenButton } from 'forms/AntragForm/components/MeldungAbgebenButton';
import { FormButton } from 'forms/components/FormButton';
import { Pflichtfeld } from 'forms/components/Pflichtfeld';
import { useDisabled } from 'forms/hooks/useDisabled';
import { useFormValidation } from 'forms/hooks/useFormValidation';
import { renderers } from 'forms/renderers';
import { useFormState } from 'forms/state/useFormState';
import { FormConfig, FormErrors, FormState, Schema } from 'forms/types/UiSchemaTypes';
import { InvestPathBuilder } from 'navigation/Paths';

import { FeststellungAbschliessenButton } from './components/FeststellungAbschliessenButton';
import { FormZurFestsetzungButton } from './components/FormZurFestsetzungButton';
import { SofortigeFestsetzungForm } from './components/SofortigeFestsetzungForm';
import { useAllowedWorkflowActions } from './hooks/useAllowedWorkflowActions';
import { useStepInfo } from './hooks/useStepInfo';
import { useStepUISchema } from './hooks/useStepUISchema';

interface DevFormProps {
    data?: FormState;
    allowedWorkflowActions?: ACTIONS[];
    errors: FormErrors;
    currentStep?: string;
    schema: Schema;
    isLoading?: boolean;

    onChange(props: Pick<JsonFormsCore, 'data'>): void;

    onSubmit(persist: boolean): Promise<void>;

    onGoNext(): void;

    onGoBack(): void;

    validate(path?: string, schema?: Schema): void;

    requestValidate(): void;

    hasNextStep: boolean;
    config: FormConfig;

    type: AntragType;
}

export const AntragForm: React.FC<DevFormProps> = ({
    data,
    currentStep,
    isLoading,
    onGoBack,
    hasNextStep,
    onChange,
    config,
    schema,
    allowedWorkflowActions,
    type,
    onGoNext,
}) => {
    const { antragId, einrichtungId, sofortigerFestsetzungsantragId } = useFormState();
    const disabled = useDisabled();
    const uiSchema = useStepUISchema(schema, type, currentStep);
    const { valid } = useFormValidation(schema, data, config);
    const {
        einreichenIsAllowed,
        editIsAllowed,
        zurPruefungFreigebenIsAllowed,
        ablehnenIsAllowed,
        freigebenIsAllowed,
        sofortigeFestsetzungIsAllowed,
    } = useAllowedWorkflowActions(allowedWorkflowActions);
    const { isZusammenfassung, isAntragsdaten, isAbsenden, isBescheiderteilung } = useStepInfo(type);
    const navigate = useNavigate();

    const onAbsenden = async () => {
        if (!sofortigeFestsetzung && !sofortigerFestsetzungsantragId) {
            return config.submit(true, ['/'], true, ACTIONS.EINREICHEN);
        } else if (type === AntragType.FESTSTELLUNG && !!sofortigerFestsetzungsantragId) {
            await config.submit(true, ['/'], false);
            navigate(InvestPathBuilder.home.antraege.festsetzung.edit(sofortigerFestsetzungsantragId));
        } else if (type === AntragType.FESTSTELLUNG) {
            await config.submit(true, ['/'], false);
            const res = await backendApiService.postFestsetzungsantrag({
                einrichtung: String(einrichtungId),
                feststellungsantrag: String(antragId),
            });
            navigate(InvestPathBuilder.home.antraege.festsetzung.edit(res.id));
        }
    };
    const onGegenpruefungFreigeben = () => config.performWorkflowAction?.(ACTIONS.FREIGEBEN, true);
    const onGegenpruefungAblehnen = () => config.performWorkflowAction?.(ACTIONS.ABLEHNEN, true);
    const onZurPruefungFreigeben = () => config.submit(true, ['/'], true, ACTIONS.ZUR_PRUEFUNG_FREIGEBEN);

    const [sofortigeFestsetzung, setSofortigeFestsetzung] = useState<boolean | undefined>(undefined);

    return (
        <AppLoaderContainer isLoading={!(data && uiSchema && schema)}>
            {!isZusammenfassung && !isAntragsdaten && <Pflichtfeld>* Pflichtfeld</Pflichtfeld>}
            <JsonForms
                schema={schema}
                uischema={uiSchema}
                data={data}
                renderers={renderers}
                onChange={onChange}
                config={config}
            />
            {type === AntragType.FESTSTELLUNG && isAbsenden && (
                <SofortigeFestsetzungForm
                    onChange={setSofortigeFestsetzung}
                    sofortigeFestsetzungIsAllowed={sofortigeFestsetzungIsAllowed}
                    festsetzungsantragEntwurfId={sofortigerFestsetzungsantragId}
                />
            )}

            <ButtonsContainer container spacing={1} direction={'row-reverse'}>
                {!valid && isZusammenfassung && (
                    <Grid item xs={12}>
                        <FormInvalidHint />
                    </Grid>
                )}

                {type === AntragType.FESTSTELLUNG && isAbsenden && !!sofortigerFestsetzungsantragId && (
                    <Grid item>
                        <FormZurFestsetzungButton onAbsenden={onAbsenden} disabled={disabled || !valid} />
                    </Grid>
                )}

                {isAbsenden && einreichenIsAllowed && (
                    <Grid item>
                        {type === AntragType.MELDUNG_P29 && (
                            <MeldungAbgebenButton onAbsenden={onAbsenden} disabled={disabled || !valid} />
                        )}

                        {type === AntragType.FESTSTELLUNG && (
                            <FeststellungAbschliessenButton
                                onAbsenden={onAbsenden}
                                disabled={disabled || !valid}
                                sofortigeFestsetzung={sofortigeFestsetzung}
                                sofortigeFestsetzungIsAllowed={sofortigeFestsetzungIsAllowed}
                            />
                        )}

                        {type !== AntragType.MELDUNG_P29 && type !== AntragType.FESTSTELLUNG && (
                            <FormAbsendenButton onAbsenden={onAbsenden} disabled={disabled || !valid} />
                        )}
                    </Grid>
                )}
                {isBescheiderteilung && (
                    <>
                        {freigebenIsAllowed && undefined !== config.performWorkflowAction && (
                            <Grid item>
                                <FormGegenpruefungFreigebenButton
                                    onGegenpruefungFreigeben={onGegenpruefungFreigeben}
                                    disabled={false}
                                />
                            </Grid>
                        )}
                        {ablehnenIsAllowed && undefined !== config.performWorkflowAction && (
                            <Grid item>
                                <FormGegenpruefungAblehnenButton
                                    onGegenpruefungAblehnen={onGegenpruefungAblehnen}
                                    disabled={false}
                                />
                            </Grid>
                        )}
                        {zurPruefungFreigebenIsAllowed && (
                            <>
                                <Grid item>
                                    <FormZurPruefungFreigebenButton
                                        onZurPruefungFreigeben={onZurPruefungFreigeben}
                                        disabled={disabled || !valid}
                                    />
                                </Grid>
                                <Grid item>
                                    <FormSaveButton
                                        onSave={() => config.submit(true, ['/'], true)}
                                        disabled={disabled || !valid}
                                        label="Bearbeitung ohne Prüfung beenden"
                                    />
                                </Grid>
                            </>
                        )}
                    </>
                )}
                {!isAbsenden && !isBescheiderteilung && (
                    <Grid item>
                        <FormSaveButton
                            onSave={() =>
                                isZusammenfassung || isAntragsdaten || !editIsAllowed ? onGoNext() : config.submit(true)
                            }
                            disabled={
                                !hasNextStep ||
                                (!isZusammenfassung && !isAntragsdaten && editIsAllowed && (disabled || !valid))
                            }
                            label={
                                isZusammenfassung || isAntragsdaten || !editIsAllowed
                                    ? 'Weiter'
                                    : 'Speichern und Weiter'
                            }
                        />
                    </Grid>
                )}

                <Grid item>
                    <FormButton
                        type="button"
                        variant="outlined"
                        color="primary"
                        onClick={onGoBack}
                        disabled={isLoading || disabled}
                    >
                        Zurück
                    </FormButton>
                </Grid>
            </ButtonsContainer>
        </AppLoaderContainer>
    );
};

const ButtonsContainer = styled(Grid)(({ theme }) => ({
    paddingTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
}));
