import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';

export const uiSchema = verticalLayout([
    group(
        [
            control('field100'),
            control('field0016'),
            control('field0012'),
            control('field102'),
            control('field090'),
            control('field141'),
            control('field0011'),
        ],
        {},
        'Daten zu grds. Feststellungsvoraussetzungen'
    ),
    group(
        [
            { type: 'Control', scope: '#/properties/field140' },
            { type: 'Control', scope: '#/properties/field161d' },
            { type: 'Control', scope: '#/properties/field142' },
            { type: 'Control', scope: '#/properties/field143' },
        ],
        {},
        'Zuordnung des Anlagevermögens'
    ),
]);
