import { useCallback } from 'react';
import { Typography } from '@mui/material';

import { AntragType } from 'api/antragTypes';
import { backendApiService } from 'api/ApiService';
import { useAuthHelper } from 'api/auth/useAuthHelper';
import { FeststellungsAntragCollectionListResponse } from 'api/types';
import { useColumnsFeststellung } from 'components/DataTableHydra/Columns/Feststellung';
import { DataTableHydraServerside } from 'components/DataTableHydra/DataTableHydraServerside';
import { useLvBenutzerOptions } from 'components/DataTableHydra/hooks/useLvBenutzerOptions';
import { GetData } from 'components/DataTableHydra/hooks/useTableData';
import { ROLES } from 'constants/roles';
import { ContentContainer } from 'layout/container/ContentContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { withAuthorization } from 'navigation/withAuthorization';
import { useAntragFilterOptions } from 'pages/Antraege/components/useAntragFilterOptions';
import { ZurueckziehenAkzeptierenDialog } from 'pages/Antraege/components/ZurueckziehenAkzeptierenDialog';
import { ZurueckziehenBeantragenDialog } from 'pages/Antraege/components/ZurueckziehenBeantragenDialog';
import { useFeststellungsAntragListActions } from 'pages/Antraege/Feststellung/useFeststellungsAntragListActions';
import { DashboardLvBenutzerToolbar } from 'pages/Dashboard/Landschaftsverband/DashboardLvBenutzerToolbar';

import { AUFGABEN, AufgabenMenu } from './config';

const FeststellungFreigabeAbgelehnt = () => {
    const { getCurrentBenutzer, isLandschaftsverbandView } = useAuthHelper();
    const benutzerId = getCurrentBenutzer()?.benutzerId;
    const { isLoading: isLoadingLvBenutzer, options: zustandigerBenutzerOptions } = useLvBenutzerOptions();

    const getData: GetData<FeststellungsAntragCollectionListResponse> = useCallback(
        ({ page, rowsPerPage, searchText, sortOrder }) => {
            return backendApiService.getFeststellungsantragCollection({
                page,
                itemsPerPage: rowsPerPage,
                qSearch: searchText ? [searchText] : undefined,
                zustaendigerBenutzer: !isLandschaftsverbandView && benutzerId ? [benutzerId] : undefined,
                aufgabe: AUFGABEN.FREIGABE_ABGELEHNT,
                orderZuletztEingereichtAt:
                    sortOrder?.name === 'zuletztEingereichtAt' ? sortOrder?.direction : undefined,
                orderField102: sortOrder?.name === 'field102' ? sortOrder?.direction : undefined,
                orderEinrichtungAktenzeichen: sortOrder?.name === 'aktenzeichen' ? sortOrder?.direction : undefined,
                orderBerechnungsart: sortOrder?.name === 'berechnungsart' ? sortOrder?.direction : undefined,
                orderEinrichtungName: sortOrder?.name === 'einrichtung' ? sortOrder?.direction : undefined,
                orderStatus: sortOrder?.name === 'status' ? sortOrder?.direction : undefined,
                orderEinrichtungZustaendigerBenutzer:
                    sortOrder?.name === 'zustaendigerBenutzer' ? sortOrder?.direction : undefined,
            });
        },
        [benutzerId, isLandschaftsverbandView]
    );

    const isLoading = undefined === zustandigerBenutzerOptions || isLoadingLvBenutzer;

    const {
        handleAction,
        serverDataChanged,
        zurueckziehenBeantragen,
        zurueckziehenBeantragenFinishAction,
        zurueckziehenAkzeptieren,
        zurueckziehenAkzeptierenFinishAction,
    } = useFeststellungsAntragListActions(AntragType.FESTSTELLUNG);
    const filterOptions = useAntragFilterOptions(AntragType.FESTSTELLUNG);
    const columnsFeststellung = useColumnsFeststellung(handleAction, filterOptions);

    return (
        <ContentContainer title="Anträge auf Feststellung (Bearbeitung)">
            <Typography variant="h1">Anträge auf Feststellung (Bearbeitung)</Typography>
            <DashboardLvBenutzerToolbar />
            <DataTableHydraServerside
                loadingContext={isLoading}
                getColumns={columnsFeststellung}
                getData={getData}
                initialOptions={{
                    filter: false,
                    searchPlaceholder: 'Suche nach Einrichtungsname, Aktenzeichen oder NRW-Schlüssel',
                    sortOrder: {
                        name: 'zuletztEingereichtAt',
                        direction: 'desc',
                    },
                }}
                serverDataChanged={serverDataChanged}
            />
            <ZurueckziehenBeantragenDialog
                antrag={zurueckziehenBeantragen}
                onClose={zurueckziehenBeantragenFinishAction}
                antragType={AntragType.FESTSTELLUNG}
            />
            <ZurueckziehenAkzeptierenDialog
                antrag={zurueckziehenAkzeptieren}
                onClose={zurueckziehenAkzeptierenFinishAction}
                antragType={AntragType.FESTSTELLUNG}
            />
        </ContentContainer>
    );
};

export const FeststellungFreigabeAbgelehntPage = withAuthorization(
    withSideBar(FeststellungFreigabeAbgelehnt, AufgabenMenu),
    [ROLES.FACHADMIN, ROLES.SUPPORT, ROLES.LV]
);
