import React from 'react';
import { Grid } from '@mui/material';

import { AntragResponse } from 'api/types';
import { ANTRAG_ART_LABELS, EINRICHTUNG_ART_FILTER_LABELS } from 'constants/labels';
import { formatDateToString } from 'forms/utils/formatter';
import { Verwaltung } from 'pages/Verwaltung/components/LayoutComponents';

interface AntragDetailsHeaderProps {
    antrag: AntragResponse;
}

export const AntragDetailsHeader = ({ antrag }: AntragDetailsHeaderProps) => (
    <Grid container>
        <Grid item xs={6}>
            <Verwaltung.InfoField label={'Pflegeeinrichtung'} value={antrag.einrichtung?.name} />
            <Verwaltung.InfoField label={'Aktenzeichen'} value={antrag.einrichtung?.aktenzeichen} />
            <Verwaltung.InfoField label={'Ort'} value={antrag.einrichtung?.ort} />
            <Verwaltung.InfoField label={'NRW-Schlüssel'} value={antrag.einrichtung?.nrwKey} />
            <Verwaltung.InfoField
                label={'Datum der Erstinbetriebnahme'}
                value={formatDateToString(antrag.einrichtungErstinbetriebnahme)}
            />
            <Verwaltung.InfoField
                label={'Art der Einrichtung'}
                value={antrag.einrichtungArt ? EINRICHTUNG_ART_FILTER_LABELS[antrag.einrichtungArt] : ''}
            />
        </Grid>
        <Grid item xs={6}>
            {antrag.art && <Verwaltung.InfoField label={'Art des Antrags'} value={ANTRAG_ART_LABELS[antrag.art]} />}
            <Verwaltung.InfoField
                label={'Ursprüngliches Antragsdatum'}
                value={formatDateToString(antrag.eingereichtAt)}
            />
            <Verwaltung.InfoField label={'Versorgungsvertrag'} value={antrag.einrichtungVersorgungsvertrag} />
        </Grid>
    </Grid>
);
