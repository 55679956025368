import { useMemo } from 'react';

import { getFieldSchemaByScope } from 'forms/utils/SchemaUtils';
import { getFieldFromScope } from 'utilities';

export const useNestedGroup = (data: any, uischema: any, schema: any): any => {
    const { collectionScope } = uischema;
    const collectionField = getFieldFromScope(collectionScope);
    const collectionData: { uuid: string; [key: string]: any }[] = data[collectionField];

    return useMemo(() => {
        const collectionSchema = getFieldSchemaByScope(collectionScope, schema);

        return {
            collectionField,
            collectionData,
            collectionSchema,
        };
    }, [collectionData, collectionField, collectionScope, schema]);
};
