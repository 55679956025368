import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { RuleEffect } from '@jsonforms/core';
import { JsonForms } from '@jsonforms/react';
import { Alert, styled } from '@mui/material';

import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/AntragForm/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { info } from 'forms/AntragForm/ui-schemas/elements/info';
import { useLayoutWithJsonFormsState } from 'forms/hooks/useJsonFormsState';
import { renderers } from 'forms/renderers';
import { FormLayoutConfig, FormStateChange, Schema } from 'forms/types/UiSchemaTypes';

type FormDataType = {
    field413: string | undefined;
};

const formData: FormDataType = {
    field413: undefined,
};

const schema: Schema = {
    $id: 'SofortigeFeststellung',
    type: 'object',
    required: ['field413'],
    properties: {
        field413: {
            type: 'boolean',
            title: 'Direkte Weiterführung zur Festsetzung',
            oneOf: [
                {
                    const: 'true',
                    title: 'Ja, auf Grundlage dieses Feststellungsantrags zusätzlich eine Festsetzung einreichen.',
                },
                {
                    const: 'false',
                    title: 'Nein, zunächst nur die Feststellung einreichen.',
                },
            ],
            //@ts-ignore
            custom: {
                block_prefixes: ['radios', 'choice'],
            },
        },
    },
};

const uiSchema = customVerticalLayout([
    group(
        [
            control('field413'),
            info(
                'Diese Feststellung wird nicht sofort, sondern gemeinsam nach Fertigstellung des Festsetzungsantrags beim Landschaftsverband eingereicht.',
                {
                    rule: {
                        effect: RuleEffect.SHOW,
                        condition: {
                            //@ts-ignore
                            scope: '#/properties/field413',
                            schema: { const: 'true' },
                        },
                    },
                }
            ),
            info(
                'Diese Feststellung kann erst nach einer Bescheidung durch den Landschaftsverband als Grundlage für einen Festsetzungsantrag genutzt werden.',
                {
                    rule: {
                        effect: RuleEffect.SHOW,
                        condition: {
                            //@ts-ignore
                            scope: '#/properties/field413',
                            schema: { const: 'false' },
                        },
                    },
                }
            ),
        ],
        {},
        'Festsetzungsantrag'
    ),
]);

interface SofortigeFestsetzungFormProps {
    onChange: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    sofortigeFestsetzungIsAllowed: boolean;
    festsetzungsantragEntwurfId: number | undefined;
}

export const SofortigeFestsetzungForm = ({
    onChange,
    sofortigeFestsetzungIsAllowed,
    festsetzungsantragEntwurfId,
}: SofortigeFestsetzungFormProps): ReactElement => {
    const [data, setData] = useState<FormDataType>(formData);

    const configLayout: FormLayoutConfig = useMemo(
        () => ({
            showFieldNumberLabels: true,
        }),
        []
    );
    const config = useLayoutWithJsonFormsState(configLayout);

    const handleChange = useCallback(
        (changeData: FormStateChange) => {
            setData(changeData.data);
            onChange(changeData.data.field413 === undefined ? undefined : changeData.data.field413 === 'true');
        },
        [onChange]
    );

    return (
        <>
            {sofortigeFestsetzungIsAllowed && (
                <JsonForms
                    schema={schema}
                    uischema={uiSchema}
                    data={data}
                    renderers={renderers}
                    onChange={handleChange}
                    config={config}
                />
            )}

            {!sofortigeFestsetzungIsAllowed && !festsetzungsantragEntwurfId && (
                <StyledAlert severity={'info'}>
                    Aktuell können Sie keinen Festsetzungsantrag basierend auf diesem Feststellungsantrag erstellen.
                    Diese Option steht Ihnen nur zur Verfügung, wenn für Ihre Einrichtung
                    <ol>
                        <li>kein Festsetzungssentwurf im System existiert.</li>
                        <li>kein Festsetzungssantrag vom Landschaftsverband aktuell bearbeitet wird.</li>
                    </ol>
                </StyledAlert>
            )}

            {!!festsetzungsantragEntwurfId && (
                <StyledAlert severity={'info'}>
                    Der Feststellungsantrag wird abgesendet, wenn Sie den Festsetzungsantrag absenden. Sie können den
                    Feststellungsantrag nicht separat absenden.
                </StyledAlert>
            )}
        </>
    );
};

const StyledAlert = styled(Alert)(({ theme }) => ({
    marginTop: theme.spacing(3),
}));
