import { GridSize } from '@mui/material';

import { GridLayoutConfig } from 'forms/types/UiSchemaTypes';

export const gridLayoutConfig = (fieldnumber: GridSize, label: GridSize, input: GridSize): GridLayoutConfig => ({
    fieldnumber,
    label,
    input,
});

export const applyGridLayout = <T>(element: T, gridLayout?: GridLayoutConfig): T => {
    if (gridLayout && !(element as any).gridLayout) return { ...element, gridLayout };
    return element;
};
