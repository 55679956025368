import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';
import { notes } from 'forms/AntragForm/ui-schemas/groups/notes';

export const uiSchema = verticalLayout([
    group([
        control('field1052'),
        control('field500'),
        control('field450'),
        control('field451'),
        control('field451d'),
        control('field462'),
        control('field452'),
        control('field457'),
        control('field457d'),
        control('field458'),
        control('field459'),
        control('field459d'),
    ]),
    notes('notizBasisdaten'),
]);
