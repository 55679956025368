import React, { useEffect } from 'react';

import { getFieldSchemaByScope } from 'forms/utils/SchemaUtils';

export const FallbackControl: React.FC<{ uischema: any; schema: any }> = ({ uischema, schema }) => {
    useEffect(() => {
        const fieldSchema = getFieldSchemaByScope(uischema.scope, schema);
        console.warn(
            `Kein Renderer für das Feld "${uischema.scope}" gefunden.`,
            !fieldSchema ? ' Kein Schema geliefert!' : fieldSchema
        );
    }, [uischema?.scope, schema]);

    return <></>;
};
