import React, { ReactNode } from 'react';
import { CircularProgress, CircularProgressProps, Grid } from '@mui/material';

export const LoaderApp: React.FC<CircularProgressProps> = (props) => (
    <CircularProgress {...props} aria-label="Wird geladen..." />
);

export const AppLoaderContainer: React.FC<{
    isLoading: boolean | undefined;
    minHeight?: number;
    children?: ReactNode;
}> = ({ isLoading, minHeight, children }) => {
    if (!isLoading) {
        return <>{children}</>;
    }
    return (
        <Grid
            container
            justifyContent={'center'}
            data-cy={'AppLoaderContainer'}
            style={{
                minHeight,
            }}
        >
            <Grid item>
                <LoaderApp />
            </Grid>
        </Grid>
    );
};
