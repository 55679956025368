import React, { ReactElement } from 'react';

import { FormAbsendenButton } from './FormAbsendenButton';
import { FormSofortigeFestsetzungButton } from './FormSofortigeFestsetzungButton';

interface FeststellungAbschliessenButtonProps {
    onAbsenden: () => void;
    disabled: boolean;
    sofortigeFestsetzung: boolean | undefined;
    sofortigeFestsetzungIsAllowed: boolean;
}

export const FeststellungAbschliessenButton = ({
    onAbsenden,
    disabled,
    sofortigeFestsetzung,
    sofortigeFestsetzungIsAllowed,
}: FeststellungAbschliessenButtonProps): ReactElement => {
    if (sofortigeFestsetzung) {
        return <FormSofortigeFestsetzungButton onAbsenden={onAbsenden} disabled={disabled} />;
    } else {
        return (
            <FormAbsendenButton
                onAbsenden={onAbsenden}
                disabled={disabled || (sofortigeFestsetzungIsAllowed && sofortigeFestsetzung === undefined)}
            />
        );
    }
};
