import React, { KeyboardEvent } from 'react';
import { Typography } from '@mui/material';

import { AntragType } from 'api/antragTypes';
import { useApiEinrichtungAnbieterPermissions } from 'api/hooks/useApiClient';
import {
    FestsetzungsAntragCollectionResponse,
    FeststellungsAntragCollectionResponse,
    InvestorenmodellCollectionResponse,
} from 'api/types';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { Link } from 'elements/Link';
import { useAnbieterDashboardState } from 'pages/Dashboard/Anbieter/useAnbieterDashboardState';
import { useAntragHelper } from 'pages/Dashboard/Anbieter/useAntragHelper';

interface DirekteinstiegPropsInterface {
    feststellungsantraege: FeststellungsAntragCollectionResponse[];
    festsetzungsantraege: FestsetzungsAntragCollectionResponse[];
    investorenmodelle: InvestorenmodellCollectionResponse[];
}

export const DashboardAnbieterDirekteinstieg: React.FC<DirekteinstiegPropsInterface> = ({
    feststellungsantraege,
    festsetzungsantraege,
    investorenmodelle,
}) => {
    const { einrichtung } = useAnbieterDashboardState();
    const { data: permissions, isLoading } = useApiEinrichtungAnbieterPermissions(einrichtung?.id);
    const {
        allowFeststellungsantrag,
        allowFestsetzungsantrag,
        allowInvestorenmodell,
        goToFeststellungsantrag,
        goToFestsetzungsantrag,
        goToInvestorenmodell,
    } = useAntragHelper(einrichtung, feststellungsantraege, festsetzungsantraege, investorenmodelle);

    const feststellungPermission = permissions?.permissions?.find(
        (permission) => permission.antragClass === 'Feststellungsantrag'
    );
    const festsetzungPermission = permissions?.permissions?.find(
        (permission) => permission.antragClass === 'Festsetzungsantrag'
    );
    const investorenmodellPermission = permissions?.permissions?.find(
        (permission) => permission.antragClass === 'Investorenmodell'
    );

    const handleOnClickFeststellungsantrag = () => {
        goToFeststellungsantrag();
    };
    const handleOnClickFestsetzungsantrag = () => {
        goToFestsetzungsantrag();
    };
    const handleOnClickInvestorenmodell = () => {
        goToInvestorenmodell();
    };
    const handleKeyboardNavigation = (e: KeyboardEvent, antragTyp: AntragType) => {
        if (e.key !== 'Enter') return;

        switch (antragTyp) {
            case AntragType.FESTSTELLUNG:
                handleOnClickFeststellungsantrag();
                break;
            case AntragType.FESTSETZUNG:
                handleOnClickFestsetzungsantrag();
                break;
            case AntragType.INVESTOREN_MODELL:
                handleOnClickInvestorenmodell();
                break;
            case AntragType.MELDUNG_P29:
                break;
        }
    };

    const isFeststellungAllowed = allowFeststellungsantrag && feststellungPermission?.allow;
    const isFestsetzungAllowed = allowFestsetzungsantrag && festsetzungPermission?.allow;
    const isInvestorenmodellAllowed = allowInvestorenmodell && investorenmodellPermission?.allow;
    const isDirekteinstiegAllowed = isFeststellungAllowed || isFestsetzungAllowed || isInvestorenmodellAllowed;

    if (!isDirekteinstiegAllowed) return <></>;

    return (
        <>
            <Typography variant="h1" component="h2">
                Direkteinstieg
            </Typography>

            <AppLoaderContainer isLoading={isLoading}>
                <div id="direkteinstieg">
                    <Typography paragraph>Direkteinstieg zum Feststellungsantrag bzw. Festsetzungsantrag:</Typography>
                    {isFeststellungAllowed && (
                        <Typography paragraph>
                            <Typography
                                className="arrowLink"
                                component={Link}
                                tabIndex={0}
                                onKeyDown={(e: KeyboardEvent) => handleKeyboardNavigation(e, AntragType.FESTSTELLUNG)}
                                onClick={handleOnClickFeststellungsantrag}
                            >
                                Zum Feststellungsantrag
                            </Typography>
                        </Typography>
                    )}
                    {isFestsetzungAllowed && (
                        <Typography paragraph>
                            <Typography
                                className="arrowLink"
                                component={Link}
                                tabIndex={0}
                                onKeyDown={(e: KeyboardEvent) => handleKeyboardNavigation(e, AntragType.FESTSETZUNG)}
                                onClick={handleOnClickFestsetzungsantrag}
                            >
                                Zum Festsetzungsantrag
                            </Typography>
                        </Typography>
                    )}
                    {isInvestorenmodellAllowed && (
                        <Typography paragraph>
                            <Typography
                                className="arrowLink"
                                component={Link}
                                tabIndex={0}
                                onKeyDown={(e: KeyboardEvent) =>
                                    handleKeyboardNavigation(e, AntragType.INVESTOREN_MODELL)
                                }
                                onClick={handleOnClickInvestorenmodell}
                            >
                                Zum Antrag nach § 8 Abs. 11
                            </Typography>
                        </Typography>
                    )}
                </div>
            </AppLoaderContainer>
        </>
    );
};
