import React from 'react';
import { Autocomplete, FormLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import { MUIDataTableColumn } from 'mui-datatables';

interface TableFilterAutocompleteProps {
    index: number;
    column: MUIDataTableColumn;
    filterData: string[];
    filterValue: string;
    filterLabels?: {
        [key: string]: string;
    };

    onChange: (val: string | string[], index: number, column: MUIDataTableColumn) => void;
}

export const AutocompleteFilter: React.FC<TableFilterAutocompleteProps> = ({
    index,
    column,
    filterData,
    filterValue,
    filterLabels,
    onChange,
}) => {
    const handleOnchange = (event: React.SyntheticEvent, newValue: string | null) => {
        onChange(newValue ? [newValue as string] : [], index, column);
    };

    return (
        <>
            <FormLabel>{column.label}</FormLabel>
            <Autocomplete
                options={filterData}
                onChange={handleOnchange}
                getOptionLabel={(value: string) => (filterLabels ? filterLabels[value] : value)}
                value={filterValue || null}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: <>{params.InputProps.endAdornment}</>,
                        }}
                    />
                )}
            />
        </>
    );
};
