import React, { useState } from 'react';

import { FestsetzungsAntragCollectionResponse } from 'api/types';
import { formatDateToString, formatDezimal } from 'forms/utils/formatter';
import { Verwaltung } from 'pages/Verwaltung/components/LayoutComponents';
import { toYear } from 'utilities/DateUtils';

import { AntragshistorieSelect } from './AntragshistorieSelect';

interface AntragshistorieFestsetzungProps {
    data: FestsetzungsAntragCollectionResponse[];
}

export const AntragshistorieFestsetzung: React.FC<AntragshistorieFestsetzungProps> = ({ data }) => {
    const [selectedFestsetzung, setSelectedFestsetzung] = useState<FestsetzungsAntragCollectionResponse | null>(
        data[data.length - 1] ?? null
    );

    return (
        <Verwaltung.Container>
            <Verwaltung.Group title={'Historische Festsetzung'} md={12}>
                <AntragshistorieSelect
                    type={'Festsetzung'}
                    value={selectedFestsetzung}
                    onChange={setSelectedFestsetzung}
                    options={data}
                />
                <Verwaltung.InfoField
                    label={'Wirkungszeitraum'}
                    value={
                        selectedFestsetzung?.data?.field500 && selectedFestsetzung?.data?.field1054
                            ? `${toYear(selectedFestsetzung?.data?.field500)} - ${toYear(
                                  selectedFestsetzung?.data?.field1054
                              )}`
                            : undefined
                    }
                    textAlign={'right'}
                    labelMd={3}
                    valueMd={3}
                />

                <Verwaltung.InfoField
                    label={'Datum'}
                    value={formatDateToString(selectedFestsetzung?.data?.field500)}
                    textAlign={'right'}
                    labelMd={3}
                    valueMd={3}
                />

                <Verwaltung.InfoField
                    label={'Eigentum/Miete'}
                    value={selectedFestsetzung?.feststellungsantrag?.data?.berechnungsart}
                    textAlign={'right'}
                    labelMd={3}
                    valueMd={3}
                />

                <Verwaltung.InfoField
                    label={'Bescheid vom'}
                    value={formatDateToString(selectedFestsetzung?.letzteBescheidung?.gesendetAt)}
                    textAlign={'right'}
                    labelMd={3}
                    valueMd={3}
                />
                <Verwaltung.InfoField
                    label={'Höhe Investkosten EZ'}
                    value={formatDezimal(
                        selectedFestsetzung?.data?.field5111 || selectedFestsetzung?.data?.field5171,
                        '€'
                    )}
                    textAlign={'right'}
                    labelMd={3}
                    valueMd={3}
                />
                <Verwaltung.InfoField
                    label={'Höhe Investkosten DZ'}
                    value={formatDezimal(selectedFestsetzung?.data?.field5129, '€')}
                    textAlign={'right'}
                    labelMd={3}
                    valueMd={3}
                />
                {selectedFestsetzung?.data?.zimmerkategorien?.map((kategorie) => (
                    <Verwaltung.InfoField
                        key={kategorie.field550}
                        label={`Höhe Investkosten ${kategorie.field550}`}
                        value={formatDezimal(kategorie.field5151, '€')}
                        textAlign={'right'}
                        labelMd={3}
                        valueMd={3}
                    />
                ))}
            </Verwaltung.Group>
        </Verwaltung.Container>
    );
};
