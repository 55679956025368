import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useHasRole } from 'api/auth/useHasRole';
import { BenutzerDialog } from 'components/AntragInfoBox/components/BenutzerDialog';
import { ROLES } from 'constants/roles';
import { Link } from 'elements/Link';
import { useConfirmationDialogActions } from 'forms/state/useConfirmationDialogState';
import { InvestPathBuilder } from 'navigation/Paths';

import { AntragInfoBoxConfig, EinrichtungDialogConfig } from './config/AntragInfoBoxConfig';
import { AntragGetter, useAntragInfoBoxData } from './hooks/useAntragInfoBoxData';

interface AntragInfoBoxProps {
    antragGetter: AntragGetter;
}

export const AntragInfoBox: FC<AntragInfoBoxProps> = ({ antragGetter }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [isAnbieter, isLV] = useHasRole(ROLES.ANBIETER, ROLES.LV);
    const { einrichtung, ansprechpartner } = useAntragInfoBoxData(antragGetter);
    const { showConfirmation, closeConfirmation } = useConfirmationDialogActions();

    const navToEinrichtung = useCallback(() => {
        closeConfirmation();
        if (!einrichtung) return;
        navigate(InvestPathBuilder.home.verwaltung.pflegeeinrichtungen.einrichtung(einrichtung.id!));
    }, [einrichtung, navigate, closeConfirmation]);

    return (
        <Container>
            {einrichtung && (
                <Address data-testid="address">
                    {einrichtung?.name && (
                        <EinrichtungName
                            onClick={() =>
                                showConfirmation({
                                    confirmAction: navToEinrichtung,
                                    alertText: EinrichtungDialogConfig.text,
                                    denyAction: closeConfirmation,
                                })
                            }
                        >
                            {einrichtung.name}
                        </EinrichtungName>
                    )}

                    {isAnbieter && einrichtung?.nrwKey && <Typography component="div">{einrichtung.nrwKey}</Typography>}
                    {isLV && einrichtung?.aktenzeichen && (
                        <Typography component="div">{einrichtung.aktenzeichen}</Typography>
                    )}
                    {einrichtung && (
                        <>
                            <Typography component="div">
                                {einrichtung.strasse} {einrichtung.hausnummer}
                            </Typography>

                            <Typography component="div">
                                {einrichtung.plz} {einrichtung.ort}
                            </Typography>
                        </>
                    )}
                </Address>
            )}

            {ansprechpartner && (
                <BenutzerDialog benutzer={ansprechpartner} className={classes.ansprechpartner}>
                    {AntragInfoBoxConfig.benutzerDialogText}
                </BenutzerDialog>
            )}
        </Container>
    );
};

const Container = styled('div')(() => ({
    position: 'absolute',
    top: '46px',
    right: '20px',
    margin: '8px',
    width: '315px',

    '& address': {
        fontStyle: 'normal !important',
    },
}));

const Address = styled('address')(() => ({
    padding: '10px 20px',
    marginBottom: '7px',
    border: '1px solid #4d4d4d',
    backgroundColor: '#EAEFF6',
}));

const EinrichtungName = styled(Link)(() => ({
    fontWeight: 'bold',
}));

const useStyles = makeStyles(() => ({
    ansprechpartner: {
        display: 'block',
        textAlign: 'right',
    },
}));
