import * as React from 'react';
import { ChangeEvent, useCallback } from 'react';
import { FormControl, FormHelperText } from '@mui/material';

import { HtmlEditor } from 'forms/components/HtmlEditor';
import { ErrorList } from 'forms/controls/components/ErrorList';
import { ReadonlyValueText } from 'forms/controls/components/ReadonlyValueText';
import { useScrollInView } from 'forms/hooks/useScrollInView';
import { useTempControlData } from 'forms/hooks/useTempControlData';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';
import { GridLayoutConfig } from 'forms/types/UiSchemaTypes';

import { RowControl } from './RowControl';

const MehrzeiligerTextControlComponent: React.FC<CustomControlProps<string>> = ({
    path,
    label,
    required,
    data,
    handleChange,
    uischema,
    readonly,
    disabled,
    hasErrors,
    errors,
    showFieldNumberLabels,
    gridLayout,
    onTouched,
    id,
    config,
}) => {
    const eventToData = useCallback((e: ChangeEvent<HTMLInputElement>) => e.target.value, []);
    const { tempData, handleValueChange, handleOnBlur } = useTempControlData(
        data,
        path,
        errors,
        handleChange,
        eventToData,
        onTouched,
        {
            submitOnChange: true,
        }
    );
    const ref = useScrollInView<HTMLDivElement>();

    const defaultGrid: GridLayoutConfig = {
        fieldnumber: 1,
        label: 3,
        input: readonly ? 3 : 8,
    };
    const grid = { ...defaultGrid, ...gridLayout };

    return (
        <RowControl
            name={path}
            label={uischema?.options?.noLabel === true ? undefined : label}
            required={required}
            multiline={uischema?.options?.multiline}
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={grid}
            labelForId={id}
            config={config}
            paths={[path]}
        >
            <FormControl fullWidth ref={ref} error={hasErrors}>
                {readonly ? (
                    <ReadonlyValueText
                        path={path}
                        hasErrors={hasErrors}
                        errors={errors}
                        text={data || '-'}
                        textAlign={'left'}
                    />
                ) : (
                    <>
                        <HtmlEditor
                            id={id}
                            value={tempData}
                            disabled={disabled}
                            onBlur={(value) => handleOnBlur(value)}
                            onChange={(value) => handleValueChange(value)}
                            data-cy={`form_${path}`}
                        />
                        <FormHelperText>{hasErrors ? <ErrorList errors={errors} /> : ' '}</FormHelperText>
                    </>
                )}
            </FormControl>
        </RowControl>
    );
};

export const MehrzeiligerTextControl = withCustomControlProps(MehrzeiligerTextControlComponent);
