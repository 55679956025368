import React, { ReactElement } from 'react';
import { JsonForms } from '@jsonforms/react';
import { Grid, Typography } from '@mui/material';

import { EinrichtungResponse } from 'api/types';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { FormButton } from 'forms/components/FormButton';
import { Pflichtfeld } from 'forms/components/Pflichtfeld';
import { renderers } from 'forms/renderers';
import { useCreateBenutzerCreateStepForm } from 'pages/Verwaltung/Benutzer/BenutzerErstellenForm/useCreateBenutzerCreateStepForm';

type CreateBenutzerFormStepCreateProps = {
    email: string;
    einrichtung: EinrichtungResponse | null;
    onClose: () => void;
    onComplete: () => void;
};

export const CreateBenutzerFormStepCreate = ({
    email,
    einrichtung,
    onClose,
    onComplete,
}: CreateBenutzerFormStepCreateProps): ReactElement => {
    const { isSubmitting, isValid, isLoading, onSubmit, ...formProps } = useCreateBenutzerCreateStepForm(
        email,
        einrichtung,
        onComplete
    );

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography>Neuen Benutzer zu PfAD.invest einladen</Typography>
            </Grid>
            <Grid item xs={12}>
                <Pflichtfeld>* Pflichtfeld</Pflichtfeld>
            </Grid>
            <Grid item xs={12}>
                <AppLoaderContainer isLoading={isSubmitting || isLoading}>
                    <JsonForms {...formProps} renderers={renderers} />
                </AppLoaderContainer>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={1} justifyContent="flex-end">
                    <Grid item>
                        <FormButton
                            type="button"
                            variant="outlined"
                            color="primary"
                            onClick={onClose}
                            disabled={isSubmitting}
                        >
                            Zurück
                        </FormButton>
                    </Grid>
                    <Grid item>
                        <FormButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={onSubmit}
                            disabled={isSubmitting || !isValid}
                        >
                            Aktivierungslink versenden
                        </FormButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
