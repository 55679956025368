import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { JsonForms } from '@jsonforms/react';
import { Grid, Typography } from '@mui/material';
import _, { identity } from 'lodash';

import { backendApiService } from 'api/ApiService';
import { useAuthHelper } from 'api/auth/useAuthHelper';
import { BenutzerProfileEdit } from 'api/client';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { FormButton } from 'forms/components/FormButton';
import { Pflichtfeld } from 'forms/components/Pflichtfeld';
import { useLayoutWithJsonFormsState } from 'forms/hooks/useJsonFormsState';
import { renderers } from 'forms/renderers';
import { useMessageActions } from 'forms/state/useMessages';
import { FormLayoutConfig, FormStateChange } from 'forms/types/UiSchemaTypes';
import { errorMessage, successMessage } from 'forms/utils/MessageUtils';
import { ContentContainer } from 'layout/container/ContentContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { withAuthorization } from 'navigation/withAuthorization';
import { profilSchema } from 'pages/Profil/Benutzerdaten/schema';
import { VerwaltungConfig } from 'pages/Verwaltung/VerwaltungConfig';

import uiSchema from './ui-schema.json';

type FormDataType = {
    anrede: string | undefined;
    vorname: string | undefined;
    nachname: string | undefined;
    telefon: string | undefined;
    fax: string | undefined;
    email: string | undefined;
    password?: string | undefined;
};

const formData: FormDataType = {
    anrede: '',
    vorname: '',
    nachname: '',
    telefon: '',
    fax: '',
    email: '',
    password: '',
};

const schema = profilSchema;
const Index = () => {
    const configLayout: FormLayoutConfig = useMemo(() => {
        return {
            gridLayout: {
                label: 2,
                input: 4,
            },
        };
    }, []);

    const { addMessage } = useMessageActions();
    const [data, setData] = useState<FormDataType>(formData);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const { getCurrentBenutzer } = useAuthHelper();
    const benutzer = getCurrentBenutzer();
    const config = useLayoutWithJsonFormsState(configLayout);
    // const { valid } = useFormValidation(schema, data);
    const [isValid, setValid] = useState(false);

    // TODO: UiSchema typisieren!
    (uiSchema.elements[1].elements[0] as any).isEditable = true;

    const loadProfileData = useCallback(() => {
        setLoading(true);
        backendApiService
            .getProfile()
            .then((response) => {
                setData((prevState: FormDataType) => {
                    const newData = {
                        ...prevState,
                        ...response,
                    };

                    return _.pickBy(newData, identity) as FormDataType;
                });
            })
            .catch(() => {
                addMessage(
                    errorMessage('Benutzerdaten konnten nicht geladen werden.', {
                        autoCloseSeconds: 4,
                    })
                );
            })
            .finally(() => {
                setLoading(false);
            });
    }, [setData, addMessage]);

    const handleSubmit = useCallback(() => {
        if (isSubmitting) {
            return;
        }

        setIsSubmitting(true);

        backendApiService
            .patchProfile(String(benutzer?.benutzerId), {
                ...data,
                ...{
                    password: undefined,
                    plainPassword: data.password,
                },
            } as BenutzerProfileEdit)
            .then(() => {
                addMessage(
                    successMessage('Benutzerdaten aktualisiert.', {
                        autoCloseSeconds: 4,
                    })
                );
            })
            .catch(() => {
                addMessage(
                    errorMessage('Aktualisierung der Benutzerdaten fehlgeschlagen.', {
                        autoCloseSeconds: 4,
                    })
                );
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    }, [isSubmitting, data, benutzer, addMessage]);

    const handleChange = useCallback((changeData: FormStateChange) => {
        setValid(!(changeData.errors?.length !== 0));
        setData(changeData.data);
    }, []);

    useEffect(() => {
        loadProfileData();
    }, [loadProfileData]);

    return (
        <ContentContainer title="Benutzerdaten">
            <Typography variant="h1">Benutzerdaten</Typography>

            <AppLoaderContainer isLoading={isLoading}>
                <Grid container direction="row-reverse" spacing={1}>
                    <Grid item xs={12}>
                        <Pflichtfeld>Pflichtfeld *</Pflichtfeld>
                        <JsonForms
                            schema={schema}
                            uischema={uiSchema}
                            data={data}
                            renderers={renderers}
                            onChange={handleChange}
                            config={config}
                        />
                    </Grid>
                    <Grid item>
                        <FormButton
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            disabled={!isValid}
                            data-cy="ProfileSaveButton"
                        >
                            Profildaten speichern
                        </FormButton>
                    </Grid>
                </Grid>
            </AppLoaderContainer>
        </ContentContainer>
    );
};

export const BenutzerdatenPage = withAuthorization(
    withSideBar(Index, VerwaltungConfig.menu),
    VerwaltungConfig.roles.benutzer
);
