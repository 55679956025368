import {
    FormDialogUiSchemaType,
    ListControlWithModalFormType,
    TableColumn,
    UiSchemaOptions,
} from 'forms/types/UiSchemaTypes';

export const listControlWithModalForm = (
    scopes: string[],
    columns: TableColumn[],
    addButtonLabels: string[],
    deleteConfirmationLabels: string[],
    deleteAlertTexts: string[],
    uiSchemas: FormDialogUiSchemaType[],
    options?: {
        viewOnly?: boolean;
        types?: string[];
        options?: UiSchemaOptions;
    }
): ListControlWithModalFormType => {
    return {
        type: 'ListControlWithModalForm',
        columns,
        uiSchemas,
        addButtonLabels,
        deleteAlertTexts,
        deleteConfirmationLabels,
        scopes,
        ...options,
    };
};
