/* tslint:disable */
/* eslint-disable */

/**
 * ACHTUNG: Manuelle Erstellung/Anpassung basierend auf openapi-generator
 */

import { Configuration } from 'api/client';
import { BaseAPI, BASE_PATH, RequestArgs } from 'api/client/base';
import {
    assertParamExists,
    createRequestFunction,
    DUMMY_BASE_URL,
    setApiKeyToObject,
    setSearchParams,
    toPathString,
} from 'api/client/common';
import { AxiosInstance, AxiosPromise, AxiosRequestConfig, default as globalAxios } from 'axios';

/**
 * ContextsApi - object-oriented interface
 * @export
 * @class ContextsApi
 * @extends {BaseAPI}
 */
export class ContextsApi extends BaseAPI {
    /**
     * Retrieves a Contexts resource.
     * @summary Retrieves a Contexts resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContextsApi
     */
    public getContext(id: string, options?: AxiosRequestConfig) {
        return ContextsApiFp(this.configuration)
            .getContext(id, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * ContextsApi - functional programming interface
 * @export
 */
export const ContextsApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = ContextsApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves a Contexts resource.
         * @summary Retrieves a Contexts resource.
         * @param {string} id Resource identifier
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContext(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContext(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * ContextsApi - axios parameter creator
 * @export
 */
const ContextsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a Contexts resource.
         * @summary Retrieves a Contexts resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContext: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getContext', 'id', id);
            const localVarPath = `/api/contexts/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
