import { useLocation } from 'react-router-dom';

import { FormState } from 'forms/types/UiSchemaTypes';
import { formatDate, formatDezimal } from 'forms/utils/formatter';
import {
    PATH_APG_DVO_MIT_KUECHE,
    PATH_APG_DVO_TEILSTATIONAER,
    PATH_APG_DVO_VOLLSTATIONAER,
    PATH_BAUPREISINDEX,
    PATH_EIGENKAPITALZINS_P5_6_APG_DVO,
    PATH_FESTGESETZTER_INSTANDHALTUNGSWERT_PRO_QM,
} from 'navigation/Paths';

export const useFormModalConfirmationDialog = (data: FormState | undefined): string => {
    const { pathname } = useLocation();
    if (!data) return '';
    if (pathname.includes(PATH_EIGENKAPITALZINS_P5_6_APG_DVO)) {
        const { gueltigBis, value } = data;
        const datum = formatDate(gueltigBis);
        const wert = formatDezimal(value, '%');
        return `Sind Sie sicher, dass Sie den neuen Eigenkapitalzins von ${wert} bis zum Datum ${datum} hinzufügen möchten?`;
    } else if (pathname.includes(PATH_BAUPREISINDEX)) {
        const { jahr, value } = data;
        const wert = formatDezimal(value, undefined, 1);
        return `Sind Sie sicher, dass Sie den neuen Baupreisindex ${wert} für das Jahr ${jahr} hinzufügen möchten?`;
    } else if (
        pathname.includes(PATH_APG_DVO_VOLLSTATIONAER) ||
        pathname.includes(PATH_APG_DVO_TEILSTATIONAER) ||
        pathname.includes(PATH_APG_DVO_MIT_KUECHE) ||
        pathname.includes(PATH_FESTGESETZTER_INSTANDHALTUNGSWERT_PRO_QM)
    ) {
        const { jahr, value } = data;
        const wert = formatDezimal(value, '€/qm');
        return `Sind Sie sicher, dass Sie die neue Angemessenheitsgrenze ${wert} für das Jahr ${jahr} hinzufügen möchten?`;
    } else {
        return 'Sind Sie sicher, dass Sie den neuen Wert hinzufügen möchten?';
    }
};
