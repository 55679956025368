import { useMemo } from 'react';

import {
    useApiFestsetzungsAntragCollection,
    useApiFeststellungsAntragCollection,
    useApiInvestorenmodellCollection,
    useApiMeldungP29Collection,
} from 'api/hooks/useApiClient';
import {
    FestsetzungsAntragCollectionResponse,
    InvestorenmodellCollectionResponse,
    MeldungP29CollectionResponse,
} from 'api/types';

type UseDashboardAntraegeResult = {
    feststellungen: FestsetzungsAntragCollectionResponse[];
    festsetzungen: FestsetzungsAntragCollectionResponse[];
    investorenmodelle: InvestorenmodellCollectionResponse[];
    meldungenP29: MeldungP29CollectionResponse[];
    isFeststellungenLoading: boolean;
    isFestsetzungenLoading: boolean;
    isInvestorenmodelleLoading: boolean;
    isMeldungenP29Loading: boolean;
};

export const useDashboardAntraege = (
    einrichtungId: number | undefined,
    serverDataChanged: number
): UseDashboardAntraegeResult => {
    const { data: feststellungsAntragData, isLoading: isFeststellungenLoading } = useApiFeststellungsAntragCollection(
        {
            page: 1,
            itemsPerPage: 9999,
            einrichtung: einrichtungId,
        },
        {
            refresh: serverDataChanged,
            condition: Boolean(einrichtungId),
        }
    );

    const { data: festsetzungsAntragData, isLoading: isFestsetzungenLoading } = useApiFestsetzungsAntragCollection(
        {
            page: 1,
            itemsPerPage: 9999,
            einrichtung: einrichtungId,
        },
        {
            refresh: serverDataChanged,
            condition: Boolean(einrichtungId),
        }
    );

    const { data: investorenmodellData, isLoading: isInvestorenmodelleLoading } = useApiInvestorenmodellCollection(
        {
            page: 1,
            itemsPerPage: 9999,
            einrichtung: einrichtungId,
        },
        {
            refresh: serverDataChanged,
            condition: Boolean(einrichtungId),
        }
    );

    const { data: meldungenP29Data, isLoading: isMeldungenP29Loading } = useApiMeldungP29Collection(
        {
            page: 1,
            itemsPerPage: 9999,
            einrichtung: einrichtungId,
        },
        {
            condition: Boolean(einrichtungId),
        }
    );

    const festsetzungen = useMemo(() => festsetzungsAntragData?.['hydra:member'] ?? [], [festsetzungsAntragData]);
    const feststellungen = useMemo(() => feststellungsAntragData?.['hydra:member'] ?? [], [feststellungsAntragData]);
    const investorenmodelle = useMemo(() => investorenmodellData?.['hydra:member'] ?? [], [investorenmodellData]);
    const meldungenP29 = useMemo(() => meldungenP29Data?.['hydra:member'] ?? [], [meldungenP29Data]);

    return {
        feststellungen,
        festsetzungen,
        investorenmodelle,
        meldungenP29,
        isFeststellungenLoading: Boolean(isFeststellungenLoading),
        isFestsetzungenLoading: Boolean(isFestsetzungenLoading),
        isInvestorenmodelleLoading: Boolean(isInvestorenmodelleLoading),
        isMeldungenP29Loading: Boolean(isMeldungenP29Loading),
    };
};
