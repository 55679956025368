import { useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { MeldungP29CollectionListResponse, MeldungP29CollectionResponse } from 'api/types';
import { ActionDropdownAntrag } from 'components/DataTableHydra/ActionDropdown/ActionDropdownAntrag';
import { GetColumns } from 'components/DataTableHydra/hooks/useTableColumns';
import { ACTIONS } from 'constants/antragActions';
import { ANTRAG_STATUS_LABELS } from 'constants/labels';
import { formatDateToString } from 'forms/utils/formatter';
import { AntragListAction } from 'pages/Antraege/components/useAntragListActions';

export const useMeldungenP29Columns = (
    handleAction: AntragListAction<MeldungP29CollectionResponse>
): GetColumns<MeldungP29CollectionListResponse> => {
    return useCallback(
        (data) => {
            if (!data) return [];

            const gueltigAb: MUIDataTableColumnDef = {
                name: 'gueltigAb',
                label: 'Vereinbarung gültig ab',
                options: {
                    customBodyRenderLite: (dataIndex: number) => {
                        const value = data['hydra:member'][dataIndex].data?.fieldM2902;
                        return value ? formatDateToString(new Date(value)) : '-';
                    },
                },
            };

            const gueltigBis: MUIDataTableColumnDef = {
                name: 'data.fieldM2903',
                label: 'Vereinbarung gültig bis',
                options: {
                    customBodyRenderLite: (dataIndex: number) => {
                        const value = data['hydra:member'][dataIndex].data?.fieldM2903;
                        return value ? formatDateToString(new Date(value)) : '-';
                    },
                },
            };

            const status: MUIDataTableColumnDef = {
                name: 'status',
                label: 'Status',
                options: {
                    customBodyRenderLite: (dataIndex: number) => {
                        const value = data['hydra:member'][dataIndex];
                        return value ? ANTRAG_STATUS_LABELS[value.status] : '-';
                    },
                },
            };

            const actions: MUIDataTableColumnDef = {
                name: 'actions',
                label: 'Aktion',
                options: {
                    customBodyRenderLite: (dataIndex: number) => {
                        const rowData = data['hydra:member'][dataIndex];
                        return (
                            <ActionDropdownAntrag
                                antrag={rowData}
                                actions={{ [ACTIONS.VIEW]: 0 }}
                                handleAction={handleAction}
                            />
                        );
                    },
                },
            };

            return [gueltigAb, gueltigBis, status, actions];
        },
        [handleAction]
    );
};
