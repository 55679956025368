import React from 'react';

import { useApiFestsetzungsAntragCollection, useApiFeststellungsAntragCollection } from 'api/hooks/useApiClient';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { STATUS } from 'constants/antragStatus';
import { ErrorAlert } from 'forms/controls/components/ErrorAlert';
import { AntragshistorieFestsetzung } from 'pages/Verwaltung/Pflegeeinrichtungen/Detailseite/Antragshistorie/AntragshistorieFestsetzung';
import { AntragshistorieFeststellung } from 'pages/Verwaltung/Pflegeeinrichtungen/Detailseite/Antragshistorie/AntragshistorieFeststellung';

interface AntragshistorieType {
    id: string;
}

export const Antragshistorie: React.FC<AntragshistorieType> = ({ id }) => {
    const {
        isLoading: isLoadingFeststellungsantragData,
        error: feststellungsantragDataError,
        data: feststellungsantragData,
    } = useApiFeststellungsAntragCollection({ status: STATUS.BESCHIEDEN, einrichtung: id });

    const {
        isLoading: isLoadingFestsetzungsantragData,
        error: festsetzungsantragDataError,
        data: festsetzungsantragData,
    } = useApiFestsetzungsAntragCollection({ status: STATUS.BESCHIEDEN, einrichtung: id });

    const feststellungsantraege = feststellungsantragData?.['hydra:member'] ?? [];
    const festsetzungsantraege = festsetzungsantragData?.['hydra:member'] ?? [];

    const error = Boolean(feststellungsantragDataError || festsetzungsantragDataError);

    return (
        <AppLoaderContainer
            isLoading={isLoadingFeststellungsantragData || isLoadingFestsetzungsantragData}
            minHeight={300}
        >
            {error && <ErrorAlert errors={[{ message: 'Einrichtung konnte nicht geladen werden' }]} />}
            {!error && (
                <>
                    <AntragshistorieFeststellung data={feststellungsantraege} />
                    <AntragshistorieFestsetzung data={festsetzungsantraege} />
                </>
            )}
        </AppLoaderContainer>
    );
};
