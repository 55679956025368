import { PFAD_WTG_BASE_URL } from 'constants/wtg';
import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { info } from 'forms/AntragForm/ui-schemas/elements/info';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';
import { notes } from 'forms/AntragForm/ui-schemas/groups/notes';

export const uiSchema = verticalLayout([
    group([
        control('field100'),
        control('field101'),
        control('field102'),
        control('field103'),
        info(
            `<b>Der hinterlegte Versorgungsvertrag ist nicht aktuell?</b><br>Der Versorgungsvertrag wird PfAD.wtg verwaltet. <a href="${PFAD_WTG_BASE_URL}" target="_blank">Bitte lassen Sie den Vertrag durch die Pflegeeinrichtung aktualisieren. Klicken Sie auf diesen Link um direkt in PfAD.wtg die Aktualisierung durchzuführen.</a> Die Bearbeitung dieses Antrags können Sie danach fortsetzen.`
        ),
        control('field103d'),
    ]),
    group(
        [control('field110a'), control('field113'), control('field110b'), control('field110')],
        {},
        'Aktuelle Platzzahlen'
    ),
    notes('notizBasisdaten'),
]);
