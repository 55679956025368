import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/AntragForm/ui-schemas/elements/customVerticalLayout';
import { formDialog } from 'forms/AntragForm/ui-schemas/elements/formDialog';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { inlineRowControl } from 'forms/AntragForm/ui-schemas/elements/inlineRowControl';
import { listControlWithModalForm } from 'forms/AntragForm/ui-schemas/elements/listControlWithModalForm';
import { tableColumn, tableColumnDecimal } from 'forms/AntragForm/ui-schemas/elements/tableColumn';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';
import { notes } from 'forms/AntragForm/ui-schemas/groups/notes';
import { zuschuesse } from 'forms/AntragForm/ui-schemas/groups/zuschuesse';

export const uiSchema = verticalLayout([
    group([
        listControlWithModalForm(
            ['#/properties/salgImEigentum', '#/properties/salgImLeasing'],
            [
                tableColumn('Art', { scope: '#/properties/type' }),
                tableColumn('Maßnahme', { scope: '#/properties/field300' }),
                tableColumnDecimal('Aufwand', {
                    scopes: ['#/properties/field302', '#/properties/field303'],
                }),
                tableColumnDecimal('Prozentualer Anteil', {
                    scopes: ['#/properties/field302pa', '#/properties/field303pa'],
                }),
            ],
            ['Anlagevermögen im Eigentum hinzufügen', 'Anlagevermögen im Leasing hinzufügen'],
            [
                'Wollen Sie dieses Anlagevermögen im Eigentum mit allen Angaben wirklich aus Ihrem Antrag löschen?',
                'Wollen Sie dieses Anlagevermögen im Leasing mit allen Angaben wirklich aus Ihrem Antrag löschen?',
            ],
            ['Anlagevermögen löschen', 'Anlagevermögen löschen'],
            [
                formDialog(
                    'Neues sonstiges Anlagevermögen im Eigentum',
                    'Sonstiges Anlagevermögen im Eigentum',
                    {},
                    customVerticalLayout([
                        group([
                            control('field300'),
                            inlineRowControl(
                                [
                                    control('field302', { controlOnly: true }),
                                    control('field302NichtBekannt', {
                                        controlOnly: true,
                                    }),
                                ],
                                '#/properties/field302'
                            ),
                            {
                                type: 'Info',
                                message:
                                    'Mir sind die tatsächlichen Aufwendungen für die v.g. Maßnahme nicht bekannt und können auch analog der Pflegebuchführungsverordnung nicht mehr nachvollzogen werden. Ich beantrage gemäß § 11 Abs. 6 Satz 3 APG DVO die Schätzung durch den Landschaftsverband.',
                                rule: {
                                    // @ts-ignore
                                    effect: 'SHOW',
                                    condition: {
                                        // @ts-ignore
                                        scope: '#/properties/field302NichtBekannt',
                                        schema: { const: true },
                                    },
                                },
                            },
                            control('field302d'),
                            control('field307'),
                        ]),
                        zuschuesse(),
                    ]),
                    { scope: '#/properties/salgImEigentum' }
                ),
                formDialog(
                    'Neues sonstiges Anlagevermögen im Leasing',
                    'Sonstiges Anlagevermögen im Leasing',
                    {},
                    customVerticalLayout([
                        group([
                            control('field300'),
                            control('field301'),
                            control('field303'),
                            control('field303d'),
                            control('field304d'),
                            control('field307'),
                        ]),
                    ]),
                    { scope: '#/properties/salgImLeasing' }
                ),
            ],
            { types: ['Eigentum', 'Leasing'] }
        ),
    ]),
    notes('notizSalg'),
]);
