import React from 'react';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, styled } from '@mui/material';
import formatDate from 'date-fns/format';

import { useAuthHelper } from 'api/auth/useAuthHelper';
import { useApiMassenmail } from 'api/hooks/useApiClient';
import { Dialog } from 'components/Dialog/Dialog';
import { ROLES } from 'constants/roles';
import { Link } from 'elements/Link';
import { getAnyOfTitel, getOneOfTitel, useSchemaFromJsonLdContext } from 'forms/hooks/useSchemaFromJsonLdContext';
import { displayBenutzerName } from 'utilities';

interface MassenmailAnsehenProps {
    open: boolean;
    handleClose: () => void;
    massenmailId: string;
}

const MassenmailAnsehenContent = styled('div')(
    () => `
    & dd {
        margin-bottom: 20px;
    }
    & ul {
        padding-left: 0px;
    }
    & li {
        list-style-type: none;
    }

    & dl:last-of-type {
         margin-top: 36px;
    }
`
);

const StyledAccordion = styled(Accordion)(
    () => `
    &::before {
        opacity: 0;
    }
    & {
        border: 1px solid rgba(0, 0, 0, 0.12);
        margin-bottom: 16px;
    }
    &.Mui-expanded:last-of-type {
        margin-bottom: 16px;
    }
`
);

export const Ansehen: React.FC<MassenmailAnsehenProps> = ({
    open,
    handleClose,
    massenmailId,
}: MassenmailAnsehenProps) => {
    const { hasRole } = useAuthHelper();

    const { data } = useApiMassenmail(massenmailId);
    const { schema } = useSchemaFromJsonLdContext('Massenmail', data);

    if (!hasRole([ROLES.LV, ROLES.MINISTERIUM])) {
        return null;
    }

    const versendete =
        undefined !== data ? data.empfaenger?.filter((empfaenger) => null !== empfaenger.gesendetAt) : undefined;

    const nichtVersendete =
        undefined !== data ? data.empfaenger?.filter((empfaenger) => null === empfaenger.gesendetAt) : undefined;

    return (
        <Dialog open={open} title="Nachrichtendetails" onClose={handleClose}>
            {data && schema && (
                <MassenmailAnsehenContent>
                    <dl>
                        <dt>Erstellt</dt>
                        <dd>{formatDate(new Date(data.createdAt), 'dd.MM.yyyy')}</dd>
                        <dt>Absender</dt>
                        <dd>{displayBenutzerName(data.createdBy)}</dd>
                        <dt>{schema.properties?.empfaengerGruppe.title}</dt>
                        <dd>{getOneOfTitel(schema, 'empfaengerGruppe', data.empfaengerGruppe)}</dd>
                        {data.empfaengerGruppe === 'CUSTOM' && (
                            <>
                                <dt>{schema.properties?.einrichtungsart.title}</dt>
                                <dd>{getAnyOfTitel(schema, 'einrichtungsart', data.einrichtungsart)}</dd>
                                <dt>{schema.properties?.einrichtungstyp.title}</dt>
                                <dd>{getAnyOfTitel(schema, 'einrichtungstyp', data.einrichtungstyp)}</dd>
                                <dt>{schema.properties?.pflegewohngeld.title}</dt>
                                <dd>{getAnyOfTitel(schema, 'pflegewohngeld', data.pflegewohngeld)}</dd>
                            </>
                        )}
                    </dl>
                    <StyledAccordion elevation={0}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <strong>{`Versendet an ${versendete?.length ?? 0} Empfänger`}</strong>
                        </AccordionSummary>
                        <AccordionDetails>
                            {versendete && versendete.length
                                ? versendete.map((empfaenger) => empfaenger.email).join('; ')
                                : 'Es wurden noch keine Mails versendet.'}
                        </AccordionDetails>
                    </StyledAccordion>
                    <StyledAccordion elevation={0}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <strong>{`Noch nicht versendet an ${nichtVersendete?.length ?? 0} Empfänger`}</strong>
                        </AccordionSummary>
                        <AccordionDetails>
                            {nichtVersendete && nichtVersendete.length
                                ? nichtVersendete.map((empfaenger) => empfaenger.email).join('; ')
                                : 'Es wurden alle Mails versendet.'}
                        </AccordionDetails>
                    </StyledAccordion>
                    <dl>
                        <dt>{schema.properties?.betreff.title}</dt>
                        <dd>{data.betreff}</dd>
                        <dt>{schema.properties?.inhalt.title}</dt>
                        <dd>
                            <div dangerouslySetInnerHTML={{ __html: data.inhalt }} />
                            {data.uploads && data.uploads.length > 0 && (
                                <>
                                    <br />
                                    <strong>Hochgeladene Dokumente</strong>
                                    <br />
                                    {data.uploads?.map((upload) => (
                                        <ul key={upload.id}>
                                            <li>
                                                <Link href={upload.contentUrl ?? ''} target="_blank">
                                                    {upload.originalName}
                                                </Link>
                                            </li>
                                        </ul>
                                    ))}
                                </>
                            )}
                        </dd>
                    </dl>
                </MassenmailAnsehenContent>
            )}
        </Dialog>
    );
};
