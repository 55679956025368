import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { AntragType } from 'api/antragTypes';

type UseStepInfoResult = {
    isAbsenden: boolean;
    isAntragsdaten: boolean;
    isZusammenfassung: boolean;
    isBescheiderteilung: boolean;
};

const defaultResult = {
    isAbsenden: false,
    isAntragsdaten: false,
    isZusammenfassung: false,
    isBescheiderteilung: false,
};

export const useStepInfo = (type: AntragType): UseStepInfoResult => {
    const { step } = useParams();

    return useMemo(() => {
        if (!step) return defaultResult;
        return {
            isAntragsdaten: step === 'antragsdaten',
            isZusammenfassung: step === 'zusammenfassung',
            isAbsenden: step === 'absenden' || type === AntragType.MELDUNG_P29,
            isBescheiderteilung: step === 'bescheiderteilung',
        };
    }, [step, type]);
};
