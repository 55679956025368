import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { info } from 'forms/AntragForm/ui-schemas/elements/info';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';
import { notes } from 'forms/AntragForm/ui-schemas/groups/notes';

export const uiSchema = verticalLayout([
    group([
        control('field120'),
        control('field122'),
        control('field123d'),
        control('field124'),
        control('field125'),
        control('field129'),
        control('field126'),
        info(
            'Sofern die Grundstücksfläche Ihrer Pflegeinrichtung die maximal anerkennungsfähige Freifläche von 50 m² je Platz unterschreitet, können die folgenden Angaben geschätzt werden.'
        ),
        control('field130'),
        control('field131'),
        control('field132'),
    ]),
    group([control('field140'), control('field161d')], {}, 'Zuordnung des Anlagevermögens'),
    notes('notizNrfUndGrundstueck'),
]);
