import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';

import { AntragType } from 'api/antragTypes';
import { backendApiService } from 'api/ApiService';
import { FeststellungsAntragCollectionListResponse } from 'api/types';
import { useColumnsFeststellung } from 'components/DataTableHydra/Columns/Feststellung';
import { DataTableHydraServerside } from 'components/DataTableHydra/DataTableHydraServerside';
import { GetData } from 'components/DataTableHydra/hooks/useTableData';
import { zustaendigerBenutzerFilter } from 'components/DataTableHydra/tableUtils';
import { ContentContainer } from 'layout/container/ContentContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { withAuthorization } from 'navigation/withAuthorization';
import { useAntragFilterOptions } from 'pages/Antraege/components/useAntragFilterOptions';
import { useFeststellungsAntragListActions } from 'pages/Antraege/Feststellung/useFeststellungsAntragListActions';
import { VerwaltungConfig } from 'pages/Verwaltung/VerwaltungConfig';

const AntragshistorieFeststellung = () => {
    const { id } = useParams();

    const getData: GetData<FeststellungsAntragCollectionListResponse> = useCallback(
        ({ page, rowsPerPage, searchText, sortOrder, filters }) => {
            return backendApiService.getFeststellungsantragCollection({
                page,
                itemsPerPage: rowsPerPage,
                qSearch: searchText ? [searchText] : undefined,
                einrichtung: id,
                berechnungsart: filters ? filters['berechnungsart'] || undefined : undefined,
                antragJahr: filters ? filters['jahr'] || undefined : undefined,
                zustaendigerBenutzer: zustaendigerBenutzerFilter(filters),
                status2: filters ? filters['status'] : undefined,
                orderZuletztEingereichtAt:
                    sortOrder?.name === 'zuletztEingereichtAt' ? sortOrder?.direction : undefined,
                orderField102: sortOrder?.name === 'field102' ? sortOrder?.direction : undefined,
                orderEinrichtungAktenzeichen:
                    sortOrder?.name === 'einrichtung.aktenzeichen' ? sortOrder?.direction : undefined,
                orderBerechnungsart: sortOrder?.name === 'berechnungsart' ? sortOrder?.direction : undefined,
                orderEinrichtungName: sortOrder?.name === 'einrichtung.name' ? sortOrder?.direction : undefined,
                orderStatus: sortOrder?.name === 'status' ? sortOrder?.direction : undefined,
                orderEinrichtungZustaendigerBenutzer:
                    sortOrder?.name === 'einrichtung.zustaendigerBenutzer.name' ? sortOrder?.direction : undefined,
            });
        },
        [id]
    );

    const { handleAction, serverDataChanged } = useFeststellungsAntragListActions(AntragType.FESTSTELLUNG);
    const filter = useAntragFilterOptions(AntragType.FESTSTELLUNG);
    const columnsFeststellung = useColumnsFeststellung(handleAction, filter);

    return (
        <ContentContainer title="Anträge auf Feststellung (Antragshistorie)">
            <Typography variant="h1">Anträge auf Feststellung (Antragshistorie)</Typography>
            <DataTableHydraServerside
                getColumns={columnsFeststellung}
                getData={getData}
                initialOptions={{
                    searchPlaceholder: 'Suche nach Einrichtungsname oder Aktenzeichen',
                    sortOrder: {
                        name: 'zuletztEingereichtAt',
                        direction: 'desc',
                    },
                }}
                serverDataChanged={serverDataChanged}
            />
        </ContentContainer>
    );
};

export const AntragshistorieFeststellungPage = withAuthorization(
    withSideBar(AntragshistorieFeststellung, VerwaltungConfig.menu),
    VerwaltungConfig.roles.pfelgeeinrichtung
);
