export const miete_table = {
    type: 'SummaryTable',
    label: 'Miete',
    columns: 3,
    scope: '#/properties/miete',
    entries: [
        {
            type: 'SubTable',
            label: 'Mietvertrag',
            level: 0,
            scope: '#/properties/mietvertraege',
            entries: [
                {
                    type: 'FieldRow',
                    scope: '#/properties/field160',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field161',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field163',
                },
                {
                    type: 'MultiFieldRow',
                    labelScope: '#/properties/field166',
                    scopes: ['#/properties/field166', '#/properties/field166Unbefristet'],
                },
            ],
        },
        {
            type: 'NotesRow',
            scope: '#/properties/notizAusnahmen',
        },
    ],
};
