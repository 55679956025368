import { useCallback, useState } from 'react';
import { ArrowBack, Menu } from '@mui/icons-material';
import AccountIcon from '@mui/icons-material/AccountCircle';
import HelpIcon from '@mui/icons-material/Help';
import {
    Box,
    BoxProps,
    Button,
    Divider,
    Hidden,
    MenuItem,
    styled,
    Toolbar as ToolbarBase,
    ToolbarProps,
} from '@mui/material';

import { useAuthHelper } from 'api/auth/useAuthHelper';
import { useAuth } from 'api/AuthContext';
import { VersionTag } from 'components/VersionTag';
import { ROLES } from 'constants/roles';
import { Link } from 'elements/Link';
import { Impersonate } from 'layout/components/Impersonate/Impersonate';
import { LoginLink } from 'layout/components/LoginLink/LoginLink';
import { Logo, LogoLink } from 'layout/components/Logo';
import { useNavigationCallback } from 'navigation/hooks/useNavigationCallback';
import { InvestPathBuilder } from 'navigation/Paths';

import { LogoWrapper } from './container/LogoWrapper';
import { HeaderConfig } from './HeaderConfig';
import { MainNavigationDrawer } from './MainNavigationDrawer';
import { Dropdown, DropdownButton } from './menu/Dropdown';
import { NavigationMenuItem } from './menu/NavigationMenuItem';

const HeaderWrapper = styled(Box)<BoxProps>(
    ({ theme }) => `
    height: ${theme.mixins.header.height}px;
    background: ${theme.palette.primary.lighter};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 200;
`
);

const Toolbar = styled(ToolbarBase)<ToolbarProps>(
    ({ theme }) => `
    padding-left: 0;
    padding-right:0;
    & .MuiSvgIcon-root {
        margin-right: 7px
    }
    background-color:${theme.palette.primary.lighter};`
);

const MainNavigation = styled(Box)<BoxProps>(
    ({ theme }) => `
    flex-grow: 1;
    height: 64px;

    & > .MuiButtonBase-root {
        height: 64px;
        text-decoration: none;
        padding: 10px 30px;
        &:hover {
            text-decoration: none;
            background-color: ${theme.palette.primary.light};
        }

        &:focus {
            background: ${theme.palette.primary.light};
        }
    }
`
);

const MetaNavigation = styled(Box)<BoxProps>(
    ({ theme }) => `
    height: 64px;

    & > .MuiButtonBase-root {
        height: 64px;
        text-decoration: none;
        padding: 10px 15px;
        &:hover, &:focus {
            text-decoration: none;
            background-color: ${theme.palette.primary.light}
        }

    }
`
);

interface HeaderWrapperProps {
    id?: string;
}

export const Header = ({ id }: HeaderWrapperProps) => {
    const { jwt, logout, isImpersonated } = useAuth();
    const { hasRole } = useAuthHelper();
    const { isLoggedIn } = jwt;
    const handleLogout = (): Promise<void> => logout();
    const [drawer, setDrawer] = useState(false);
    const closeDrawer = useCallback(() => setDrawer(false), []);
    useNavigationCallback(closeDrawer);

    const hasPasswordReset = hasRole(ROLES.PASSWORD_RESET);

    if (hasPasswordReset) {
        return (
            <HeaderWrapper id={id} component="header" role="banner">
                <Toolbar disableGutters>
                    <LogoWrapper id="LogoWrapper">
                        <LogoLink to={InvestPathBuilder.home.path} underline="none">
                            <Logo />
                        </LogoLink>
                    </LogoWrapper>
                </Toolbar>
            </HeaderWrapper>
        );
    }

    return (
        <HeaderWrapper id={id} component="header" role="banner">
            <Toolbar disableGutters>
                <LogoWrapper id="LogoWrapper">
                    <LogoLink to={InvestPathBuilder.home.path} underline="none">
                        <Logo />
                    </LogoLink>
                    {isLoggedIn && (
                        <Hidden lgUp>
                            <Button onClick={() => setDrawer(!drawer)} aria-label={'Menü öffnen'}>
                                <Menu />
                            </Button>
                        </Hidden>
                    )}
                </LogoWrapper>
                <MainNavigationDrawer
                    open={drawer}
                    header={
                        <Toolbar disableGutters>
                            <LogoWrapper id="LogoWrapper">
                                <Link to={InvestPathBuilder.home.path} underline="none">
                                    <Logo />
                                </Link>
                                <Hidden lgUp>
                                    <Button onClick={closeDrawer}>
                                        <ArrowBack />
                                    </Button>
                                </Hidden>
                            </LogoWrapper>
                        </Toolbar>
                    }
                    onClose={closeDrawer}
                />
                <MainNavigation id="MainNavigation">
                    <Hidden lgDown>
                        {isLoggedIn &&
                            HeaderConfig.areas
                                .filter((area) => hasRole(area.roles))
                                .map((area, i) => {
                                    if (!area.menu?.length) {
                                        return (
                                            <DropdownButton
                                                focusRipple={false}
                                                component={Link}
                                                to={area.path}
                                                key={i}
                                                data-cy="headerMenuButton"
                                            >
                                                {area.name}
                                            </DropdownButton>
                                        );
                                    }
                                    return (
                                        <Dropdown label={area.name} key={i} buttonProps={{ focusRipple: false }}>
                                            {area.menu
                                                .filter((menu) => hasRole(menu.roles))
                                                .map((menu, i) => (
                                                    <NavigationMenuItem key={i} {...menu} />
                                                ))}
                                        </Dropdown>
                                    );
                                })}
                    </Hidden>
                </MainNavigation>

                <MetaNavigation id="MetaNavigation">
                    <VersionTag />
                    {isLoggedIn && (hasRole(ROLES.FACHADMIN) || isImpersonated) && <Impersonate />}

                    <DropdownButton focusRipple={false} component={Link} to={InvestPathBuilder.home.hilfe.path}>
                        <HelpIcon /> <Hidden mdDown>Hilfe</Hidden>
                    </DropdownButton>

                    {isLoggedIn && (
                        <Dropdown
                            buttonProps={{ focusRipple: false }}
                            label={
                                <>
                                    <AccountIcon />
                                    <Hidden mdDown>Profil</Hidden>
                                </>
                            }
                        >
                            <MenuItem component={Link} to={InvestPathBuilder.home.profile.benutzerdaten.path}>
                                Eigene Benutzerdaten
                            </MenuItem>
                            <Divider aria-hidden={true} />
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </Dropdown>
                    )}

                    {!isLoggedIn && <LoginLink />}
                </MetaNavigation>
            </Toolbar>
        </HeaderWrapper>
    );
};
