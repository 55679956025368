import { cloneDeep } from 'lodash';

import { Schema } from 'forms/types/UiSchemaTypes';
import { getFieldSchemaByScope } from 'forms/utils/SchemaUtils';

export const useFormTableSchema = (schema: Schema, scopes: string[]): Schema => {
    if (!schema.properties) {
        return schema;
    }
    return scopes
        .map((scope) => getFieldSchemaByScope(scope, schema))
        .reduce(
            (tableSchema, collectionSchema) =>
                ({
                    ...tableSchema,
                    properties: {
                        ...tableSchema?.properties,
                        ...applyCollectionCustomPropsToElements(collectionSchema)?.properties,
                    },
                } as Schema),
            {}
        ) as Schema;
};

const applyCollectionCustomPropsToElements = (collectionSchema: Schema) => {
    if (!collectionSchema?.properties) {
        return collectionSchema;
    }
    const collectionItems = Object.keys(collectionSchema.properties);
    const appliedSchema = cloneDeep(collectionSchema);
    collectionItems.forEach((itemField: string) => {
        const fieldSchema = appliedSchema.properties?.[itemField] as Schema;
        if (!fieldSchema || !appliedSchema.properties) {
            return;
        }
        appliedSchema.properties[itemField] = {
            ...fieldSchema,
            custom: {
                ...fieldSchema.custom,
                deleteable: collectionSchema.custom?.deleteable && fieldSchema.custom?.deleteable,
            },
        } as Schema;
    });
    return appliedSchema;
};
