import { ReactElement, useState } from 'react';

import { AntragType } from 'api/antragTypes';
import { backendApiService } from 'api/ApiService';
import {
    FestsetzungsAntragCollectionResponse,
    FeststellungsAntragCollectionResponse,
    InvestorenmodellCollectionResponse,
} from 'api/types';
import { Dialog } from 'components/Dialog/Dialog';
import { ANTRAG_TYP_LABELS } from 'constants/labels';
import { useMessageActions } from 'forms/state/useMessages';
import { formatDateToString } from 'forms/utils/formatter';
import { errorMessage, successMessage } from 'forms/utils/MessageUtils';
import { ZurueckziehenAkzeptierenDialogStepAccept } from 'pages/Antraege/components/ZurueckziehenAkzeptierenDialogStepAccept';
import { ZurueckziehenAkzeptierenDialogStepDeny } from 'pages/Antraege/components/ZurueckziehenAkzeptierenDialogStepDeny';
import { ZurueckziehenAkzeptierenDialogStepInfo } from 'pages/Antraege/components/ZurueckziehenAkzeptierenDialogStepInfo';

type ZurueckziehenFeststellungAkzeptierenDialogProps = {
    antrag: FeststellungsAntragCollectionResponse | undefined;
    antragType: AntragType.FESTSTELLUNG;
    onClose: (isConfirmed: boolean) => void;
};

type ZurueckziehenFestsetzungAkzeptierenDialogProps = {
    antrag: FestsetzungsAntragCollectionResponse | undefined;
    antragType: AntragType.FESTSETZUNG;
    onClose: (isConfirmed: boolean) => void;
};

type ZurueckziehenInvestorenmodellAkzeptierenDialogProps = {
    antrag: InvestorenmodellCollectionResponse | undefined;
    antragType: AntragType.INVESTOREN_MODELL;
    onClose: (isConfirmed: boolean) => void;
};

type ZurueckziehenAkzeptierenDialogProps =
    | ZurueckziehenFeststellungAkzeptierenDialogProps
    | ZurueckziehenFestsetzungAkzeptierenDialogProps
    | ZurueckziehenInvestorenmodellAkzeptierenDialogProps;

export const ZurueckziehenAkzeptierenDialog = ({
    antrag,
    onClose,
    antragType,
}: ZurueckziehenAkzeptierenDialogProps): ReactElement => {
    const { addMessage } = useMessageActions();
    const [formState, setFormState] = useState<ZurueckziehenAkzeptierenFormState>({ step: 'info' });

    const getDate = (): string | undefined | null => {
        switch (antragType) {
            case AntragType.FESTSTELLUNG:
                return antrag?.data?.field102;
            case AntragType.FESTSETZUNG:
                return antrag?.data?.field500;
            case AntragType.INVESTOREN_MODELL:
                return antrag?.festsetzungsantrag?.data?.field500;
        }
    };

    const onStepInfoAccept = () => {
        setFormState({ step: 'accept' });
    };

    const onStepInfoDeny = () => {
        setFormState({ step: 'deny' });
    };

    const onCancel = () => {
        setFormState({ step: 'info' });
        onClose(false);
    };

    const onSubmit = async (entscheidung: boolean, erlaeuterungEntscheidung: string): Promise<void> => {
        const id = antrag?.letztesZurueckziehen?.id;
        const request = { entscheidung, erlaeuterungEntscheidung };
        if (!id) return;
        try {
            switch (antragType) {
                case AntragType.FESTSTELLUNG:
                    await backendApiService.patchFeststellungsantragZurueckziehen(id, request);
                    break;
                case AntragType.FESTSETZUNG:
                    await backendApiService.patchFestsetzungsantragZurueckziehen(id, request);
                    break;
                case AntragType.INVESTOREN_MODELL:
                    await backendApiService.patchInvestorenmodellZurueckziehen(id, request);
                    break;
            }
            setFormState({ step: 'info' });
            onClose(true);
            addMessage(createSuccessMessage(entscheidung));
        } catch (e) {
            console.error(e);
            addMessage(createErrorMessage(entscheidung));
        }
    };

    return (
        <Dialog
            title={`${ANTRAG_TYP_LABELS[antragType]} zum ${formatDateToString(getDate())} zurückziehen`}
            open={Boolean(antrag)}
            onClose={onCancel}
        >
            {antrag?.letztesZurueckziehen && (
                <>
                    {formState.step === 'info' && (
                        <ZurueckziehenAkzeptierenDialogStepInfo
                            antrag={antrag}
                            letztesZurueckziehen={antrag.letztesZurueckziehen}
                            onAccept={onStepInfoAccept}
                            onDeny={onStepInfoDeny}
                        />
                    )}
                    {formState.step === 'accept' && (
                        <ZurueckziehenAkzeptierenDialogStepAccept
                            antrag={antrag}
                            onCancel={onCancel}
                            onSubmit={onSubmit}
                        />
                    )}
                    {formState.step === 'deny' && (
                        <ZurueckziehenAkzeptierenDialogStepDeny
                            antrag={antrag}
                            onCancel={onCancel}
                            onSubmit={onSubmit}
                        />
                    )}
                </>
            )}
        </Dialog>
    );
};

type ZurueckziehenAkzeptierenFormStateInfo = {
    step: 'info';
};

type ZurueckziehenAkzeptierenFormStateAccept = {
    step: 'accept';
};

type ZurueckziehenAkzeptierenFormStateDeny = {
    step: 'deny';
};

type ZurueckziehenAkzeptierenFormState =
    | ZurueckziehenAkzeptierenFormStateInfo
    | ZurueckziehenAkzeptierenFormStateAccept
    | ZurueckziehenAkzeptierenFormStateDeny;

const createSuccessMessage = (entscheidung: boolean) =>
    successMessage(entscheidung ? 'Zurückziehen erfolgreich akzeptiert.' : 'Zurückziehen erfolgreich abgelehnt.', {
        autoCloseSeconds: 8,
        closable: true,
    });

const createErrorMessage = (entscheidung: boolean) =>
    errorMessage(
        entscheidung ? 'Zurückziehen konnte nicht akzeptiert werden.' : 'Zurückziehen konnte nicht abgelehnt werden.',
        {
            autoCloseSeconds: 8,
            closable: true,
        }
    );
