import { ControlProps, rankWith, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Grid, GridSize, styled, Typography } from '@mui/material';

import { HighlightedGrid } from 'forms/controls/GroupForm/InlineGroupRowControl';
import { TableCellValue } from 'forms/controls/SummaryTable/FieldRow';
import { DataGridType, Schema } from 'forms/types/UiSchemaTypes';
import { isNumeric } from 'forms/utils/SchemaUtils';

import { useDataGrid } from './hooks/useDataGrid';

interface DataGridInterface extends Omit<ControlProps, 'uischema'> {
    uischema: DataGridType;
}

const HeaderGridText = styled(Typography)(
    ({ theme }) => `
    font-weight: bold;
    margin-bottom: ${theme.spacing(1)};
`
);

const DataGridComponent: React.FC<DataGridInterface> = ({ uischema, schema, data, path, config }) => {
    const grids = useDataGrid(data, schema as Schema, uischema);
    if (!grids || grids.length === 0) {
        return <></>;
    }
    return (
        <Grid container spacing={1} item xs={uischema.containerGridSize || 12}>
            {uischema.columns.map((c, i) => {
                const GridItem = c.highlighted ? HighlightedGrid : Grid;
                return (
                    <GridItem key={i} item xs={(12 / uischema.columns.length) as GridSize} style={c.styles}>
                        {c.label && <HeaderGridText>{c.label}</HeaderGridText>}
                    </GridItem>
                );
            })}
            {grids.map((grid, i) => {
                const GridItem = grid.column.highlighted ? HighlightedGrid : Grid;

                return (
                    <GridItem key={i} item xs={(12 / uischema.columns.length) as GridSize} style={grid.column.styles}>
                        <TableCellValue
                            path={path}
                            config={config}
                            data={grid.value}
                            schema={grid.schema}
                            column={grid.column}
                            textAlign={grid.column.textAlign || (isNumeric(grid.schema) ? 'right' : 'left')}
                        />
                    </GridItem>
                );
            })}
        </Grid>
    );
};

// @ts-ignore
export const DataGrid = withJsonFormsControlProps(DataGridComponent);

export const DataGridTester = rankWith(1, uiTypeIs('DataGrid'));
