import { useCallback } from 'react';
import { MUISortOptions } from 'mui-datatables';

import { backendApiService } from 'api/ApiService';
import { GetInvestorenmodellOptions, InvestorenmodellCollectionListResponse } from 'api/types';
import { GetData } from 'components/DataTableHydra/hooks/useTableData';
import { TableFilters } from 'components/DataTableHydra/hooks/useTableFilters';
import { sortBy, zustaendigerBenutzerFilter } from 'components/DataTableHydra/tableUtils';

export const useInvestorenmodellListData = (): GetData<InvestorenmodellCollectionListResponse> => {
    return useCallback(({ page, rowsPerPage, searchText, filters, sortOrder }) => {
        return backendApiService.getInvestorenmodellCollection({
            page,
            itemsPerPage: rowsPerPage,
            qSearch: searchText ? [searchText] : undefined,
            ...getInvestorenmodellFilterParameters(filters),
            ...getInvestorenmodellSortParameters(sortOrder),
        });
    }, []);
};

const getInvestorenmodellSortParameters = (sortOrder: MUISortOptions | undefined): GetInvestorenmodellSortOptions => {
    return {
        orderStatus: sortBy(sortOrder, 'status'),
        orderEinrichtungName: sortBy(sortOrder, 'einrichtung'),
        orderZuletztEingereichtAt: sortBy(sortOrder, 'zuletztEingereichtAt'),
        orderEinrichtungAktenzeichen: sortBy(sortOrder, 'aktenzeichen'),
        orderFestsetzungsantragField500: sortBy(sortOrder, 'jahr'),
        orderEinrichtungZustaendigerBenutzerName: sortBy(sortOrder, 'zustaendigerBenutzer'),
        orderFestsetzungsantragFeststellungsantragBerechnungsart: sortBy(sortOrder, 'berechnungsart'),
    };
};

const getInvestorenmodellFilterParameters = (filters: TableFilters | undefined): GetInvestorenmodellFilterOptions => {
    return {
        zustaendigerBenutzer: zustaendigerBenutzerFilter(filters),
        status2: filters?.['status'],
        berechnungsart: filters?.['berechnungsart'],
        antragJahr: filters?.['jahr'],
    };
};

type GetInvestorenmodellSortOptions = Pick<
    GetInvestorenmodellOptions,
    | 'orderStatus'
    | 'orderEinrichtungName'
    | 'orderZuletztEingereichtAt'
    | 'orderEinrichtungAktenzeichen'
    | 'orderFestsetzungsantragField500'
    | 'orderEinrichtungZustaendigerBenutzerName'
    | 'orderFestsetzungsantragFeststellungsantragBerechnungsart'
>;

type GetInvestorenmodellFilterOptions = Pick<
    GetInvestorenmodellOptions,
    'zustaendigerBenutzer' | 'status2' | 'berechnungsart' | 'antragJahr'
>;
