import { Typography } from '@mui/material';

import { AdresseLandschaftsverband } from 'components/Adresse/AdresseLandschaftsverband';
import { LinkEmail } from 'elements/LinkEmail';
import { LinkTel } from 'elements/LinkTel';

export const KontaktLvr = () => {
    return (
        <>
            <Typography component="div" paragraph>
                <AdresseLandschaftsverband kuerzel={'LVR'} />
            </Typography>

            <Typography component="p" variant="h6">
                Ansprechpartner
            </Typography>
            <address>
                <Typography>
                    Frau Nicole Sträter (Tel.: <LinkTel msisdn="0251 / 591 5452" />)
                </Typography>
                <Typography>
                    Frau Maren Deventer (Tel.: <LinkTel msisdn="0251 / 591 6426" />)
                </Typography>
                <Typography>
                    E-Mail: <LinkEmail email="finabt-ref3-invest@lwl.org" />
                </Typography>
            </address>
        </>
    );
};
