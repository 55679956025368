import { Box, BoxProps, styled } from '@mui/material';

export const LogoWrapper = styled(Box)<BoxProps>(
    ({ theme }) => `
    width: ${theme.mixins.sidebar.width}px;
    padding-left: 15px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    a {
        margin-top: 10px;
        margin-right: 8px;
    }
    & > .MuiButtonBase-root {
        height: 64px;
        text-decoration: none;
        padding: 10px 30px;
        &:hover {
            text-decoration: none;
            background-color: ${theme.palette.primary.light};
        }

        &:focus {
            background: ${theme.palette.primary.light};
        }
    }
`
);
