import React, { ReactElement } from 'react';
import { JsonForms } from '@jsonforms/react';
import { Grid, Typography } from '@mui/material';

import { BenutzerByEmailResponse, EinrichtungResponse } from 'api/types';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { FormButton } from 'forms/components/FormButton';
import { renderers } from 'forms/renderers';
import {
    CreateBenutzerFormStepAssignOnComplete,
    useCreateBenutzerAssignStepForm,
} from 'pages/Verwaltung/Benutzer/BenutzerErstellenForm/useCreateBenutzerAssignStepForm';

type CreateBenutzerFormStepAssignProps = {
    einrichtung: EinrichtungResponse;
    benutzer: BenutzerByEmailResponse;
    onClose: () => void;
    onComplete: CreateBenutzerFormStepAssignOnComplete;
};

export const CreateBenutzerFormStepAssign = ({
    einrichtung,
    benutzer,
    onClose,
    onComplete,
}: CreateBenutzerFormStepAssignProps): ReactElement => {
    const { isSubmitting, isValid, onSubmit, ...formProps } = useCreateBenutzerAssignStepForm(
        benutzer.id,
        einrichtung.id,
        onComplete
    );
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography>Möchten Sie folgenden Benutzer zur Einrichtung zuweisen?</Typography>
            </Grid>
            <Grid item xs={12}>
                <AppLoaderContainer isLoading={isSubmitting}>
                    <Grid container component="dl">
                        <Grid item md={3} component="dt" alignItems="flex-start">
                            Name
                        </Grid>
                        <Grid item md={9} component="dd" justifyContent="flex-start">
                            {benutzer.vorname + ' ' + benutzer.nachname}
                        </Grid>
                        <Grid item md={3} component="dt" alignItems="flex-start">
                            E-Mail
                        </Grid>
                        <Grid item md={9} component="dd" justifyContent="flex-start">
                            {benutzer.email}
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <JsonForms {...formProps} renderers={renderers} />
                        </Grid>
                    </Grid>
                </AppLoaderContainer>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={1} justifyContent="flex-end">
                    <Grid item>
                        <FormButton
                            type="button"
                            variant="outlined"
                            color="primary"
                            onClick={onClose}
                            disabled={isSubmitting}
                        >
                            Nein
                        </FormButton>
                    </Grid>
                    <Grid item>
                        <FormButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={onSubmit}
                            disabled={isSubmitting || !isValid}
                        >
                            Zuweisen
                        </FormButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
