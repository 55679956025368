import * as React from 'react';
import { ReactNode, useMemo } from 'react';
import { FormLabel, Grid, GridSize, styled, Typography } from '@mui/material';

import { FormulaPopover } from 'forms/AntragForm/components/FormulaPopover';
import { errorTextTranslations } from 'forms/hooks/useErrors';
import { FormConfig, GridLayoutConfig } from 'forms/types/UiSchemaTypes';
import { getFieldNumber } from 'utilities/ScopeUtils';

interface RowControlProps {
    name: string;
    label?: string;
    errorText?: string;
    required?: boolean;
    formula?: string;
    multiline?: boolean;
    fullValueWidth?: boolean;
    controlOnly?: boolean;
    showFieldNumberLabels?: boolean;
    gridLayout?: GridLayoutConfig;
    children?: ReactNode;
    hasChangedData?: boolean;
    labelForId?: string;
    rowAction?: ReactNode;
    config: FormConfig;
    paths: string[];
}

export const getErrorText = (errorText?: string): string => {
    errorText = errorText?.trim();
    const tempErrorText = errorText && errorTextTranslations[errorText];
    return (typeof tempErrorText === 'string' ? tempErrorText : errorText) || '';
};

export const RowControl: React.FC<RowControlProps> = ({
    children,
    label,
    errorText,
    name,
    required,
    formula,
    multiline = false,
    controlOnly = false,
    fullValueWidth = false,
    showFieldNumberLabels,
    gridLayout,
    hasChangedData,
    labelForId,
    rowAction,
    config,
    paths,
}) => {
    const gridCols = 12;
    const defaultGrid: GridLayoutConfig = {
        fieldnumber: 1,
        label: 3,
        input: 3,
    };

    const grid = { ...defaultGrid, ...gridLayout };
    const field = getFieldNumber(name);
    const errorMessage = useMemo(() => getErrorText(errorText), [errorText]);

    if (controlOnly) {
        return <>{children}</>;
    }

    if (multiline) {
        const gridWidth: GridSize = fullValueWidth
            ? gridCols
            : ((Number(grid.input) + Number(grid.label) + (Number(grid.fieldnumber) ?? 0)) as GridSize);

        return (
            <>
                <Grid item md={gridWidth}>
                    {children}
                </Grid>
                {errorMessage && (
                    <MultiLineErrorContainer item md={gridWidth} container>
                        <ErrorMessage>{errorMessage}</ErrorMessage>
                    </MultiLineErrorContainer>
                )}
            </>
        );
    }

    if (!label) {
        return (
            <Grid container spacing={1}>
                <Grid item xs={gridCols}>
                    {children}
                </Grid>
                <Grid item xs={gridCols} />
            </Grid>
        );
    }

    const gridWidth: GridSize = fullValueWidth
        ? ((gridCols - Number(grid.input) - (Number(grid.fieldnumber) ?? 0)) as GridSize)
        : grid.input;

    const actionGridWidth = gridCols - Number(grid.input) - Number(grid.fieldnumber) - Number(grid.label);

    return (
        <Grid container spacing={1} data-cy={'RowControl'} style={hasChangedData ? { backgroundColor: '#DAFCE7' } : {}}>
            {showFieldNumberLabels && (
                <Grid item xs={grid.fieldnumber}>
                    <FormLabel component="legend" data-cy={field || label}>
                        {field}
                        {formula && (
                            <FormulaPopover config={config} paths={paths} formula={formula} fieldNumber={field} />
                        )}
                    </FormLabel>
                </Grid>
            )}

            <Grid item xs={grid.label}>
                <Label htmlFor={labelForId} data-cy={label}>
                    <span dangerouslySetInnerHTML={{ __html: label as string }} />
                    {required && '\u00A0*'}
                </Label>
            </Grid>
            <Grid item xs={gridWidth}>
                {children}
            </Grid>
            {Boolean(rowAction) && (
                <Grid item xs={actionGridWidth}>
                    {rowAction}
                </Grid>
            )}
            <Grid item xs={12} />
        </Grid>
    );
};

const MultiLineErrorContainer = styled(Grid)(() => ({
    marginTop: 3,
    marginBottom: 10,
}));

const ErrorMessage = styled(Typography)(() => ({
    color: '#cc2222',
    fontSize: 12,
    marginLeft: 5,
    marginRight: 5,
})) as typeof Typography;

const Label = styled(FormLabel)(() => ({
    display: 'block',
    marginTop: '9px',

    '& span': {
        color: '#333',
        lineHeight: 1.4,
    },
}));
