import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CardContent, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import formatDate from 'date-fns/format';

import { AbbrechenAntragType, AntragType, ZurueckziehenAntragType } from 'api/antragTypes';
import {
    FestsetzungsAntragCollectionResponse,
    InvestorenmodellCollectionResponse,
    MeldungP29CollectionResponse,
} from 'api/types';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { ActionDropdown } from 'components/DataTableHydra/ActionDropdown/ActionDropdown';
import { StyledContainer } from 'layout/container/StyledContainer';
import { openZusammenfassung } from 'navigation/PDFs';
import { useAntragAbbrechen } from 'pages/Antraege/components/useAntragAbbrechen';
import { useAntragLoeschen } from 'pages/Antraege/components/useAntragLoeschen';
import { useAntragZurueckziehen } from 'pages/Antraege/components/useAntragZurueckziehen';
import { ZurueckziehenBeantragenDialog } from 'pages/Antraege/components/ZurueckziehenBeantragenDialog';
import {
    AktivitaetenTableAction,
    AktivitaetenTableData,
    useAktivitaetenTableData,
} from 'pages/Dashboard/Anbieter/useAktivitaetenTableData';

interface AktivitaetenPropsInterface {
    isLoading: boolean;
    feststellungsantraege: FestsetzungsAntragCollectionResponse[];
    festsetzungsantraege: FestsetzungsAntragCollectionResponse[];
    investorenmodelle: InvestorenmodellCollectionResponse[];
    meldungenP29: MeldungP29CollectionResponse[];
    setServerDataChanged: Dispatch<SetStateAction<number>>;
}

export const DashboardAnbieterAktivitaeten = ({
    isLoading,
    feststellungsantraege,
    festsetzungsantraege,
    investorenmodelle,
    meldungenP29,
    setServerDataChanged,
}: AktivitaetenPropsInterface): ReactElement => {
    const navigate = useNavigate();
    const deleteAntrag = useAntragLoeschen(setServerDataChanged);
    const zurueckziehenAntrag = useAntragZurueckziehen(setServerDataChanged);
    const abbrechenAntrag = useAntragAbbrechen(setServerDataChanged);

    const aktivitaetenTableData = useAktivitaetenTableData(
        feststellungsantraege,
        festsetzungsantraege,
        investorenmodelle,
        meldungenP29
    );
    const [zurueckziehen, setZurueckziehen] = useState<AktivitaetenTableData | undefined>(undefined);

    const onCloseZurueckziehen = (isConfirmed: boolean) => {
        setZurueckziehen(undefined);
        if (isConfirmed) setServerDataChanged((prevState) => prevState + 1);
    };

    const onRowAction = async (rowData: AktivitaetenTableData, action: AktivitaetenTableAction) => {
        switch (action.url) {
            case 'ViewZusammenfassungPDF':
                openZusammenfassung(AntragType.MELDUNG_P29, rowData);
                break;
            case 'ZurueckziehenBeantragen':
                setZurueckziehen(rowData);
                break;
            case 'Loeschen':
                deleteAntrag(rowData.id, rowData.typ);
                break;
            case 'Zurueckziehen':
                zurueckziehenAntrag(rowData.id, rowData.typ as ZurueckziehenAntragType, rowData.antrag.status);
                break;
            case 'Abbrechen':
                abbrechenAntrag(rowData.id, rowData.typ as AbbrechenAntragType, rowData.antrag.status);
                break;
            default:
                navigate(action.url);
        }
    };

    return (
        <StyledContainer bordered backgroundVariant="secondary">
            <CardContent>
                <Typography variant="h1" component="h2">
                    Letzte Aktivitäten
                </Typography>
            </CardContent>
            <AppLoaderContainer isLoading={isLoading}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Typ</TableCell>
                            <TableCell>Zum</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Aktion</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {aktivitaetenTableData && aktivitaetenTableData.length ? (
                            <>
                                {aktivitaetenTableData.map((rowData) => (
                                    <TableRow key={rowData.id}>
                                        <TableCell>{rowData.title || '-'}</TableCell>
                                        <TableCell>
                                            {rowData.zum ? formatDate(new Date(rowData.zum), 'dd.MM.yyyy') : '-'}
                                        </TableCell>
                                        <TableCell>{rowData.status || '-'}</TableCell>
                                        <TableCell>
                                            <ActionDropdown>
                                                {rowData.actions.map((action) => (
                                                    <MenuItem
                                                        key={action.key}
                                                        onClick={() => onRowAction(rowData, action)}
                                                    >
                                                        {action.title}
                                                    </MenuItem>
                                                ))}
                                            </ActionDropdown>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </>
                        ) : (
                            <TableRow>
                                <TableCell colSpan={4} align="center">
                                    Keine
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                {zurueckziehen && zurueckziehen.typ !== AntragType.MELDUNG_P29 && (
                    <ZurueckziehenBeantragenDialog
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        antrag={zurueckziehen.antrag as any}
                        antragType={zurueckziehen.typ}
                        onClose={onCloseZurueckziehen}
                    />
                )}
            </AppLoaderContainer>
        </StyledContainer>
    );
};
