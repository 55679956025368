import { GroupControlType } from 'forms/types/UiSchemaTypes';

export const platzzahlreduzierungen = {
    type: 'Group',
    label: 'Platzzahlreduzierungen',
    scope: '#/properties/platzzahlreduzierungenLalg',
    elements: [
        {
            type: 'FormExtendButton',
            label: 'Platzzahlreduzierung hinzufügen',
            scope: '#/properties/platzzahlreduzierungenLalg',
            uiSchema: {
                type: 'CustomVerticalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/field0515',
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/field0516',
                    },
                ],
            },
        },
        {
            type: 'Separator',
        },
        {
            type: 'Control',
            scope: '#/properties/field0514',
        },
        {
            type: 'Control',
            scope: '#/properties/field0517',
        },
        {
            type: 'Control',
            scope: '#/properties/field0518',
        },
    ],
} as GroupControlType;
