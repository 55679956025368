import { useMemo } from 'react';

import { AntragType } from 'api/antragTypes';
import { AntragResponse, AntragVersionResponse } from 'api/types';
import { ANTRAG_ART } from 'constants/antragArt';
import { AntragDetailsVersionInfo } from 'pages/Antraege/components/AntragDetailsVersion';

export const useAntragVersionInfo = (
    type: AntragType,
    version: AntragVersionResponse | null,
    antrag: AntragResponse
): AntragDetailsVersionInfo => {
    return useMemo(() => {
        const art = determineArt(type, antrag);
        return version
            ? {
                  art,
                  eingereichtAt: version.antrag?.eingereichtAt,
                  eingereichtBy: version.antrag?.eingereichtBy,
                  gesendetAt: version.bescheidung?.gesendetAt,
                  gesendetBy: version.bescheidung?.gesendetBy,
                  geprueftAt: version?.bescheidung?.gegenpruefung?.geprueftAt,
                  geprueftBy: version?.bescheidung?.gegenpruefung?.geprueftBy,
                  modifiedAt: version?.antragModifiedAt,
              }
            : {
                  art,
                  eingereichtAt: antrag.eingereichtAt,
                  eingereichtBy: antrag.eingereichtBy,
                  modifiedAt: antrag.modifiedAt,
              };
    }, [type, version, antrag]);
};

const determineArt = (type: AntragType, antrag: AntragResponse): string => {
    switch (type) {
        case AntragType.FESTSTELLUNG:
            return antrag.art === ANTRAG_ART.FOLGEANTRAG
                ? 'Feststellungsbescheid - Folgeantrag'
                : 'Feststellungsbescheid';
        case AntragType.FESTSETZUNG:
            return antrag.art === ANTRAG_ART.FOLGEANTRAG
                ? 'Festsetzungsbescheid - Folgeantrag'
                : 'Festsetzungsbescheid';
        case AntragType.INVESTOREN_MODELL:
            return antrag.art === ANTRAG_ART.FOLGEANTRAG
                ? 'Bescheid für Antrag nach § 8 Abs. 11 - Folgeantrag'
                : 'Bescheid für Antrag nach § 8 Abs. 11';
        case AntragType.MELDUNG_P29:
            return 'Bescheid für Meldung nach $ 29';
    }
};
