export const uiSchema = {
    type: 'CustomVerticalLayout',
    spacing: 6,
    elements: [
        {
            type: 'SummaryTable',
            label: 'Basisdaten',
            columns: 3,
            scope: '#/properties/basisdaten',
            entries: [
                {
                    type: 'FieldRow',
                    scope: '#/properties/field100',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field101',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field102',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field103',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field103d',
                },
                {
                    type: 'SubTable',
                    label: 'Aktuelle Platzzahlen',
                    scope: '#/properties/',
                    entries: [
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field110',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field110a',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field110b',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field113',
                        },
                    ],
                },
                {
                    type: 'NotesRow',
                    scope: '#/properties/notizBasisdaten',
                },
            ],
        },
        {
            type: 'SummaryTable',
            label: 'Nettoraumfläche und Grundstück',
            columns: 3,
            scope: '#/properties/nrfUndGrundstueck',
            entries: [
                {
                    type: 'FieldRow',
                    scope: '#/properties/field120',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field122',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field123d',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field124',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field125',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field129',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field126',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field130',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field131',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field132',
                },
                {
                    type: 'SubTable',
                    label: 'Zuordnung des Anlagevermögens',
                    scope: '#/properties/',
                    entries: [
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field140',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field161d',
                        },
                    ],
                },
                {
                    type: 'NotesRow',
                    scope: '#/properties/notizNrfUndGrundstueck',
                },
            ],
        },
        {
            type: 'SummaryTable',
            label: 'Bescheinigungen',
            columns: 3,
            scope: '#/properties/bescheinigungen',
            entries: [
                {
                    type: 'FieldRow',
                    scope: '#/properties/field141',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field141a',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field141d',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field142',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field143',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/field231',
                },
                {
                    type: 'SubTable',
                    label: 'Ausnahmegenehmigung',
                    level: 1,
                    scope: '#/properties/ausnahmegenehmigungenP10Abs3Satz2Ziffer2Apg',
                    entries: [
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field233',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field234',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field233d',
                        },
                    ],
                },
                {
                    type: 'NotesRow',
                    scope: '#/properties/notizAusnahmen',
                },
            ],
        },
        {
            type: 'SummaryTable',
            label: 'Maßnahmen',
            columns: 3,
            scope: '#/properties/massnahmen',
            entries: [
                {
                    type: 'SubTable',
                    label: 'Maßnahme',
                    scope: '#/properties/massnahmen',
                    entries: [
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field200',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field201',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field202',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field207',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field290',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field203',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field204',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field205',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field206',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field210',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field213',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field220',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field220d',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field221',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field222',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field223',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field224',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field225',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field226',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field227',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field232',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field232d',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field230',
                        },
                        {
                            type: 'SubTable',
                            label: 'Gepoolte Plätze',
                            level: 1,
                            scope: '#/properties/gepooltePlaetze',
                            entries: [
                                {
                                    type: 'FieldRow',
                                    scope: '#/properties/field237',
                                },
                                {
                                    type: 'FieldRow',
                                    scope: '#/properties/field238',
                                },
                                {
                                    type: 'FieldRow',
                                    scope: '#/properties/field239',
                                },
                            ],
                        },
                        {
                            type: 'SubTable',
                            label: 'Zuschuss',
                            level: 1,
                            scope: '#/properties/zuschuesse',
                            entries: [
                                {
                                    type: 'FieldRow',
                                    scope: '#/properties/field240',
                                },
                                {
                                    type: 'FieldRow',
                                    scope: '#/properties/field241',
                                },
                                {
                                    type: 'FieldRow',
                                    scope: '#/properties/field242',
                                },
                                {
                                    type: 'FieldRow',
                                    scope: '#/properties/field242d',
                                },
                            ],
                        },
                    ],
                },
                {
                    type: 'NotesRow',
                    scope: '#/properties/notizMassnahmen',
                },
            ],
        },
        {
            type: 'SummaryTable',
            label: 'Sonstiges Anlagevermögen im Eigentum oder Leasing',
            columns: 3,
            scope: '#/properties/salg',
            entries: [
                {
                    type: 'SubTable',
                    label: 'SALG im Eigentum',
                    scope: '#/properties/salgImEigentum',
                    entries: [
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field300',
                        },
                        {
                            type: 'MultiFieldRow',
                            labelScope: '#/properties/field302',
                            scopes: ['#/properties/field302', '#/properties/field302NichtBekannt'],
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field302d',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field307',
                        },
                        {
                            type: 'SubTable',
                            label: 'Zuschuss',
                            level: 1,
                            scope: '#/properties/zuschuesse',
                            entries: [
                                {
                                    type: 'FieldRow',
                                    scope: '#/properties/field240',
                                },
                                {
                                    type: 'FieldRow',
                                    scope: '#/properties/field241',
                                },
                                {
                                    type: 'FieldRow',
                                    scope: '#/properties/field242',
                                },
                                {
                                    type: 'FieldRow',
                                    scope: '#/properties/field242d',
                                },
                            ],
                        },
                    ],
                },
                {
                    type: 'SubTable',
                    label: 'SALG im Leasing',
                    scope: '#/properties/salgImLeasing',
                    entries: [
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field300',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field301',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field303',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field303d',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field304d',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/field307',
                        },
                    ],
                },
                {
                    type: 'NotesRow',
                    scope: '#/properties/notizSalg',
                },
            ],
        },
    ],
};
