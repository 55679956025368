import { useCallback, useState } from 'react';

import { backendApiService } from 'api/ApiService';
import { BenutzerResponse } from 'api/types';
import { PflegeeinrichtungBenutzerTableAction, PflegeeinrichtungBenutzerTableActions } from 'constants/benutzerActions';
import { useConfirmationDialogActions } from 'forms/state/useConfirmationDialogState';
import { useLoadingActions } from 'forms/state/useLoadingState';
import { useMessageActions } from 'forms/state/useMessages';
import { errorMessage, successMessage } from 'forms/utils/MessageUtils';
import { displayBenutzerName } from 'utilities';

interface UsePflegeeinrichtungBenutzerListActionResult {
    handleAction: PflegeeinrichtungBenutzerTableAction;
    selectedBenutzer: BenutzerResponse | undefined;
    onClose: () => void;
    onComplete: () => void;
}

export const usePflegeeinrichtungBenutzerListAction = (
    onComplete: () => void
): UsePflegeeinrichtungBenutzerListActionResult => {
    const { addMessage } = useMessageActions();
    const { showLoading, hideLoading } = useLoadingActions();
    const { showConfirmation, closeConfirmation } = useConfirmationDialogActions();
    const [selectedBenutzer, setSelectedBenutzer] = useState<BenutzerResponse>();

    const removeEinrichtungBenutzer = useCallback(
        async (id: number) => {
            try {
                showLoading('Einrichtungbenutzer wird entfernt...');
                closeConfirmation();
                await backendApiService.deleteEinrichtungBenutzer(id);
                onComplete();
                hideLoading();
                addMessage(einrichtungBenutzerSuccessMessage());
            } catch (e) {
                console.error(e);
                addMessage(einrichtungBenutzerErrorMessage());
            } finally {
                hideLoading();
                closeConfirmation();
            }
        },
        [closeConfirmation, addMessage, showLoading, hideLoading, onComplete]
    );

    const onClose = useCallback(() => {
        setSelectedBenutzer(undefined);
    }, []);

    const onCompleteBase = useCallback(() => {
        setSelectedBenutzer(undefined);
        onComplete();
    }, [onComplete]);

    const handleAction: PflegeeinrichtungBenutzerTableAction = useCallback(
        async (action, benutzer, einrichtung) => {
            const id = benutzer.id;
            const einrichtungBenutzerId = einrichtung.einrichtungBenutzer?.find((b) => b.benutzer.id === id)?.id;
            if (!id || !einrichtungBenutzerId) return;
            switch (action) {
                case PflegeeinrichtungBenutzerTableActions.EINRICHTUNG_RECHTE_ANPASSEN:
                    setSelectedBenutzer(benutzer);
                    return;
                case PflegeeinrichtungBenutzerTableActions.EINRICHTUNG_BENUTZER_ENTFERNEN:
                    showConfirmation({
                        alertText: `Sie sind dabei den Benutzer <strong>${displayBenutzerName(
                            benutzer
                        )}</strong> aus der Einrichtung <strong>${
                            einrichtung.name
                        }</strong> zu entfernen. Nach dem Entfernen hat der Benutzer keinen Zugriff mehr auf die Einrichtung. Möchten Sie fortfahren?`,
                        confirmLabel: 'Benutzer entfernen',
                        denyLabel: 'Abbrechen',
                        denyAction: closeConfirmation,
                        confirmAction: async () => removeEinrichtungBenutzer(einrichtungBenutzerId),
                    });
                    return;
            }
        },
        [showConfirmation, closeConfirmation, removeEinrichtungBenutzer]
    );

    return { handleAction, selectedBenutzer, onClose, onComplete: onCompleteBase };
};

const einrichtungBenutzerSuccessMessage = () =>
    successMessage('Einrichtungbenutzer wurde erfolgreich entfernt.', { autoCloseSeconds: 8 });
const einrichtungBenutzerErrorMessage = () =>
    errorMessage('Einrichtungbenutzer konnte nicht entfernt werden.', { autoCloseSeconds: 8 });
