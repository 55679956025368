import { Dispatch, SetStateAction, useCallback } from 'react';

import { AbbrechenAntragType, AntragType, ZurueckziehenAntragType } from 'api/antragTypes';
import { backendApiService } from 'api/ApiService';
import { AntragResponse } from 'api/types';
import { ACTIONS } from 'constants/antragActions';
import { ANTRAG_ACTION_STATUS_LABELS, ANTRAG_STATUS_LABELS } from 'constants/labels';
import { useConfirmationDialogActions } from 'forms/state/useConfirmationDialogState';
import { useMessageActions } from 'forms/state/useMessages';
import { errorMessage, successMessage } from 'forms/utils/MessageUtils';

type UseAntragAbbrechenResult = (
    id: string | number,
    type: AbbrechenAntragType,
    antragStatus: string | undefined
) => void;

export const useAntragAbbrechen = (
    setServerDataChanged: Dispatch<SetStateAction<number>>
): UseAntragAbbrechenResult => {
    const { addMessage } = useMessageActions();
    const { showConfirmation, closeConfirmation } = useConfirmationDialogActions();

    return useCallback(
        (id, type, antragStatus) => {
            const current = antragStatus ? ANTRAG_STATUS_LABELS[antragStatus] : undefined;
            const next = ANTRAG_ACTION_STATUS_LABELS[ACTIONS.ABBRECHEN];
            const statusHinweis = ` Der Status dieses Antrages ändert sich von '${current}' auf '${next}'.`;

            showConfirmation({
                alertText: `Sie sind dabei Ihren Antrag abzubrechen. ${statusHinweis} Möchten Sie fortfahren?`,
                confirmLabel: 'Antrag abbrechen',
                denyLabel: 'Abbrechen',
                denyAction: closeConfirmation,
                confirmAction: async () => {
                    try {
                        const endpoint = determineEndpoint(type);
                        await endpoint(id, ACTIONS.ABBRECHEN);
                        setServerDataChanged((prev) => prev + 1);
                        addMessage(createSuccessMessage());
                    } catch (e) {
                        console.error(e);
                        addMessage(createErrorMessage());
                    } finally {
                        closeConfirmation();
                    }
                },
            });
        },
        [addMessage, closeConfirmation, setServerDataChanged, showConfirmation]
    );
};

const determineEndpoint = (
    type: ZurueckziehenAntragType
): ((id: string | number, action: string) => Promise<AntragResponse>) => {
    switch (type) {
        case AntragType.FESTSTELLUNG:
            return backendApiService.workflowFeststellungsantragItem.bind(backendApiService);
        case AntragType.FESTSETZUNG:
            return backendApiService.workflowFestsetzungsantragItem.bind(backendApiService);
        case AntragType.INVESTOREN_MODELL:
            return backendApiService.workflowInvestorenmodellItem.bind(backendApiService);
    }
};

const createSuccessMessage = () =>
    successMessage('Antrag erfolgreich abgebrochen.', {
        autoCloseSeconds: 8,
        closable: true,
    });

const createErrorMessage = () =>
    errorMessage('Antrag konnte nicht abgebrochen werden.', {
        autoCloseSeconds: 8,
        closable: true,
    });
