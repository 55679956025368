import { ReactElement } from 'react';
import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';

import { useLoadingState } from 'forms/state/useLoadingState';

export const LoadingBackdrop = (): ReactElement => {
    const { isLoading, message } = useLoadingState();
    return (
        <Backdrop
            open={isLoading}
            sx={{ backgroundColor: 'rgba(0, 0, 0, 0.7)', color: 'white', zIndex: (theme) => theme.zIndex.tooltip + 20 }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <CircularProgress color="inherit" sx={{ marginBottom: (theme) => theme.spacing(2) }} />
                {message && <Typography color="inherit">{message}</Typography>}
            </Box>
        </Backdrop>
    );
};
