import { useCallback, useEffect, useState } from 'react';

import { HinweiseAusMigration } from 'api/types';
import { useMessageActions } from 'forms/state/useMessages';
import { warningMessage } from 'forms/utils/MessageUtils';

type UseMigrationProblemsResult = {
    setMigrationProblems: (hinweise: HinweiseAusMigration | string[] | null) => void;
};

export const useMigrationProblems = (): UseMigrationProblemsResult => {
    const { addMessage, closeMessage } = useMessageActions();
    const [hinweise, setHinweise] = useState<HinweiseAusMigration | null>(null);

    useEffect(() => {
        if (!hinweise) return;
        const message = createMigrationWarningMessage(hinweise);
        const messageId = addMessage(warningMessage(message, { closable: true }));
        return () => closeMessage(messageId);
    }, [addMessage, closeMessage, hinweise]);

    const setMigrationProblems = useCallback((migrationProblems: HinweiseAusMigration | string[] | null) => {
        if (!migrationProblems) return;
        if (Array.isArray(migrationProblems)) return;
        setHinweise(migrationProblems);
    }, []);

    return { setMigrationProblems };
};

const createMigrationWarningMessage = (hinweise: HinweiseAusMigration): string => {
    const hinweisMessage = Object.entries(hinweise)
        .map(([key, hinweise]) => hinweise.map((hinweis) => `${key}: ${hinweis.nachricht}`))
        .flat(1)
        .join('\n');
    return `Es konnten nicht alle Informationen migriert werden!\nBitte beachten Sie, dass die folgenden Daten aus diesem Antrag möglicherweise nicht vollständig sind:\n${hinweisMessage}`;
};
