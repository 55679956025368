import { styled, Toolbar as ToolbarBase } from '@mui/material';

export const Toolbar = styled(ToolbarBase)(
    ({ theme }) => `
    padding-left: 0;
    padding-right:0;
    & .MuiSvgIcon-root {
        margin-right: 7px
    }
    background-color:${theme.palette.primary.lighter};`
);
