import React, { ReactElement } from 'react';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';

import { useApiAngemessenheitsgrenzeCollection } from 'api/hooks/useApiClient';
import { UpdateableFormTable } from 'components/FormModal/UpdateableFormTable';
import { DezimalzahlEuro as Betrag } from 'elements/DezimalzahlEuro';
import { ContentContainer } from 'layout/container/ContentContainer';
import { withSideBar } from 'layout/hooks/useSideBar';

const AngemessenheitsgrenzeVor1983: React.FC = (): ReactElement => {
    const typ = 'VOR_1983';

    const columns: MUIDataTableColumnDef[] = [
        {
            name: 'jahr',
            label: 'Jahr',
        },
        {
            name: 'value',
            label: 'Wert in EUR',
            options: {
                customBodyRender: Betrag,
            },
        },
    ];

    const options: MUIDataTableOptions = {
        sortOrder: {
            name: 'jahr',
            direction: 'desc',
        },
        filter: false,
    };

    const content = {
        headline: 'Angemessenheitsgrenze vor 1983',
    };

    const table = {
        columns,
        options,
        callback: useApiAngemessenheitsgrenzeCollection,
        callbackType: typ,
    };

    return (
        <ContentContainer title="Angemessenheitsgrenze vor 1983">
            <UpdateableFormTable table={table} content={content} />
        </ContentContainer>
    );
};

export const AngemessenheitsgrenzeVor1983Page = withSideBar(AngemessenheitsgrenzeVor1983, []);
