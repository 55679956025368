import React, { useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { Box, Button, Popover, styled, Tooltip, Typography } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { useAuth } from 'api/AuthContext';
import { FormButton } from 'forms/components/FormButton';
import { ImpersonateForm } from 'layout/components/Impersonate/ImpersonateForm';

export const Impersonate = () => {
    const { setImpersonatedBenutzer, getImpersonatedBenutzer } = useAuth();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const impersonatedBenutzer = getImpersonatedBenutzer();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const clearImpersonation = (): void => {
        backendApiService.setImpersonateUser(undefined);
        setImpersonatedBenutzer(undefined);
    };

    const open = !!anchorEl?.isConnected;

    if (impersonatedBenutzer) {
        return (
            <>
                <Tooltip title={`ID: ${impersonatedBenutzer.benutzerId}, E-Mail: ${impersonatedBenutzer.username}`}>
                    <ImpersonateTypography>
                        <ImpersonateIcon /> {`${impersonatedBenutzer.nachname}, ${impersonatedBenutzer.vorname}`}
                    </ImpersonateTypography>
                </Tooltip>
                <ImpersonateFormButton type="button" onClick={clearImpersonation} title="Clear Impersonation">
                    <ClearIcon />
                </ImpersonateFormButton>
            </>
        );
    }

    return (
        <>
            <ImpersonateButton data-cy="impersonate_button" onClick={handleClick}>
                <PeopleAltIcon /> Impersonate
            </ImpersonateButton>

            <Popover
                data-cy="impersonate_container"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <Box margin={2} style={{ width: '500px' }}>
                    <ImpersonateForm />
                </Box>
            </Popover>
        </>
    );
};

const ImpersonateTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.error.dark,
    display: 'inline-flex',
    verticalAlign: 'middle',
}));

const ImpersonateIcon = styled(PeopleAltIcon)(() => ({
    marginRight: '5px',
}));

const ImpersonateFormButton = styled(FormButton)(({ theme }) => ({
    color: theme.palette.error.dark,
    minWidth: 'unset',
    paddingLeft: '0px',
}));

const ImpersonateButton = styled(Button)(() => ({
    color: '#000',
}));
