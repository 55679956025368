import { AntragType, FestsetzungsAntragAPI } from 'api/antragTypes';
import { backendApiService } from 'api/ApiService';
import { ROLES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { InvestPathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { defaultFestsetzungsantragSteps, menuFestsetzungsantrag } from 'pages/Antraege/AntragConfig';
import { AntragPage } from 'pages/Antraege/components/AntragPage';

const antragApi: FestsetzungsAntragAPI = {
    submit: backendApiService.postFestsetzungsantragJson.bind(backendApiService),
    deleteObjectTypeItem: backendApiService.deleteFestsetzungsantragObjectTypeItem.bind(backendApiService),
    getFormData: backendApiService.getFestsetzungsantragJson.bind(backendApiService),
    performWorkflowAction: backendApiService.workflowFestsetzungsantragItem.bind(backendApiService),
    getAntrag: backendApiService.getFestsetzungsantragItem.bind(backendApiService),
    submitGegenpruefungErlaeuterung: backendApiService.patchFestsetzungsantragGegenpruefung.bind(backendApiService),
};

const FestsetzungsAntrag = () => {
    return (
        <AntragPage
            api={antragApi}
            stepPathBuilder={InvestPathBuilder.home.antraege.festsetzung.edit}
            type={AntragType.FESTSETZUNG}
            header={'Festsetzungsantrag'}
            menuBuilder={menuFestsetzungsantrag}
        />
    );
};

export const FestsetzungsAntragPage = withAuthorization(
    withSideBar(FestsetzungsAntrag, menuFestsetzungsantrag(defaultFestsetzungsantragSteps(''))),
    [ROLES.FACHADMIN, ROLES.SUPPORT, ROLES.LV, ROLES.ANBIETER]
);
