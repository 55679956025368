import React, { useEffect, useState } from 'react';
import { JsonForms } from '@jsonforms/react';

import { renderers } from 'forms/renderers';
import { FormState, FormStateChange, Schema } from 'forms/types/UiSchemaTypes';

export interface AntragsAssistentFormDataInterface {
    answer: boolean;
}

interface DashboardAnbieterAssistentStepsFormProps {
    step: string;

    onChange(state: FormStateChange): void;

    formData: FormState | null;
    children?: React.ReactNode;
}

export const DashboardAnbieterAssistentStepsForm = ({
    step,
    formData,
    onChange,
}: DashboardAnbieterAssistentStepsFormProps) => {
    const [formSchema, setFormSchema] = useState<Schema>();
    const [currentStep, setCurrentStep] = useState<string>(step);

    useEffect(() => {
        if (step !== currentStep) {
            setCurrentStep(step);
        }
    }, [step, currentStep]);

    useEffect(() => {
        const schema: Schema = {
            type: 'object',
            required: ['answer'],
            properties: {
                answer: {
                    $id: 'answer',
                    title: '',
                    type: 'boolean',
                    enum: [true, false],
                    // @ts-ignore
                    enumNames: ['Ja', 'Nein'],
                    custom: {
                        block_prefixes: ['ja_nein'],
                    },
                },
            },
        };

        setFormSchema(schema);
    }, [step]);

    return (
        <>
            {formSchema && (
                <JsonForms key={step} data={formData} schema={formSchema} onChange={onChange} renderers={renderers} />
            )}
        </>
    );
};
