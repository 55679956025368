import { Typography } from '@mui/material';

import { AdresseLandschaftsverband } from 'components/Adresse/AdresseLandschaftsverband';
import { LinkEmail } from 'elements/LinkEmail';
import { LinkTel } from 'elements/LinkTel';

export const KontaktLwl = () => {
    return (
        <>
            <Typography component="div" paragraph>
                <AdresseLandschaftsverband kuerzel={'LWL'} />
            </Typography>

            <Typography component="p" variant="h6">
                Ansprechpartner
            </Typography>
            <address>
                <Typography>Herr Stephan Budde</Typography>
                <Typography>
                    Telefon: <LinkTel msisdn="0221 / 809 - 7731" />
                </Typography>
                <Typography>
                    E-Mail: <LinkEmail email="stephan.budde@lvr.de" />
                </Typography>
            </address>
        </>
    );
};
