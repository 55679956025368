import { AntragType } from 'api/antragTypes';
import { useHasRole } from 'api/auth/useHasRole';
import { ROLES } from 'constants/roles';

export const PATH_FORM_DEV = '/form-dev'; // @TODO remove

export const PATH_HOME = '/';
export const PATH_LOGIN = 'login';
export const PATH_HILFE = 'hilfe';
export const PATH_DATENSCHUTZ = 'datenschutz';
export const PATH_IMPRESSUM = 'impressum';
export const PATH_BARRIEREFREIHEIT = 'barrierefreiheit';
export const PATH_DASHBOARD = 'start';
export const PATH_STATISTIK = 'statistik';

export const PATH_PROFIL = 'profile';
export const PATH_PROFIL_BENUTZERDATEN = 'benutzerdaten';
export const PATH_PROFIL_PASSWORT_RESET = 'passwort-zuruecksetzen';
export const PATH_PROFIL_EMAIL_CONFIRM = 'email-bestaetigen';

export const PATH_ANTRAEGE = 'antraege';
export const PATH_ANTRAEGE_FESTSTELLUNG = 'feststellungsantrag';
export const PATH_ANTRAEGE_FESTSETZUNG = 'festsetzungsantrag';
export const PATH_ANTRAEGE_INVESTOREN_MODELL = 'investorenmodell';
export const PATH_ANTRAEGE_MELDUNG_P29 = 'meldungP29';
export const PATH_ANTRAEGE_ANTRAGSVERSIONEN = 'antragsversionen';
export const PATH_ANTRAEGE_AENDERUNGEN = 'aenderungen';

export const PATH_VERWALTUNG = 'verwaltung';
export const PATH_VERWALTUNG_PFLEGEEINRICHTUNGEN = 'pflegeeinrichtungen';
export const PATH_VERWALTUNG_PFLEGEEINRICHTUNGEN_STAMMDATEN = 'stammdaten';
export const PATH_VERWALTUNG_PFLEGEEINRICHTUNGEN_ANTRAGSHISTORIE_FESTSTELLUNG = 'antragshistorie_feststellung';
export const PATH_VERWALTUNG_PFLEGEEINRICHTUNGEN_ANTRAGSHISTORIE_FESTSETZUNG = 'antragshistorie_festsetzung';
export const PATH_VERWALTUNG_PFLEGEEINRICHTUNGEN_ANTRAGSHISTORIE_INVESTORENMODELL = 'antragshistorie_investorenmodell';
export const PATH_VERWALTUNG_TRAEGER = 'traeger';
export const PATH_VERWALTUNG_TRAEGERVERBAND = 'traegerverband';
export const PATH_VERWALTUNG_BENUTZER = 'benutzer';
export const PATH_VERWALTUNG_ZUSTAENDIGKEITEN = 'zustaendigkeiten';
export const PATH_VERWALTUNG_ZUSTAENDIGKEITEN_EINRICHTUNGEN = 'zustaendigkeit_pro_einrichtung';
export const PATH_VERWALTUNG_SHORTCODES = 'shortcodes';
export const PATH_VERWALTUNG_MASSENMAIL = 'mailings';
export const PATH_VERWALTUNG_EINRICHTUNGEN = 'einrichtungen';

export const PATH_EXTERNE_QUELLEN = 'externe-quellen';

export const PATH_BAUPREISINDEX = 'baupreisindex';
export const PATH_2010 = '2010';
export const PATH_2015 = '2015';

export const PATH_EIGENKAPITALZINS_P5_6_APG_DVO = 'eigenkapitalzins-p5-6-apg-dvo';
export const PATH_EIGENKAPITALZINSEN_GEMAESS_BUNDESBANK = 'eigenkapitalzinsen-gemaess-bundesbank';
export const PATH_FREMDKAPITALZINSEN_SUD_119 = 'fremdkapitalzinsen-sud-119';

export const PATH_ANGEMESSENHEITSGRENZE = 'angemessenheitsgrenze';

export const PATH_APG_DVO_MIT_KUECHE = 'apg-dvo-mit-kueche';
export const PATH_APG_DVO_VOLLSTATIONAER = 'apg-dvo-vollstationaer';
export const PATH_APG_DVO_TEILSTATIONAER = 'apg-dvo-teilstationaer';
export const PATH_FESTGESETZTER_INSTANDHALTUNGSWERT_PRO_QM = 'festgesetzter-instandhaltungswert-pro-qm';
export const PATH_VOR_1983 = 'vor-1983';
export const PATH_ALLGEMEINE_VEREINBARUNG = 'allgemeine-vereinbarung';
export const PATH_GESBERVO_1996_VOLLSTATIONAER = 'gesbervo-1996-vollstationaer';
export const PATH_GESBERVO_2003_VOLLSTATIONAER = 'gesbervo-2003-vollstationaer';
export const PATH_GESBERVO_2008_VOLLSTATIONAER = 'gesbervo-2008-vollstationaer';
export const PATH_GESBERVO_1996_TEILSTATIONAER = 'gesbervo-1996-teilstationaer';
export const PATH_GESBERVO_2003_TEILSTATIONAER = 'gesbervo-2003-teilstationaer';
export const PATH_ABSTIMMUNGSBESCHEID_VOR_01_07_2008 = 'abstimmungsbescheid-vor-01-07-2008';
export const PATH_ABSTIMMUNGSBESCHEID_AB_01_07_2008 = 'abstimmungsbescheid-ab-01-07-2008';

export const ACTION_EDIT = 'bearbeiten';

export const PATH_AUFGABEN = 'aufgaben';
export const PATH_AUFGABEN_FESTSETZUNG_BEARBEITEN = 'festsetzung-bearbeiten';
export const PATH_AUFGABEN_FESTSETZUNG_FREIGABE_ABGELEHNT = 'festsetzung-freigabe-abgelehnt';
export const PATH_AUFGABEN_FESTSETZUNG_PRUEFEN = 'festsetzung-pruefen';
export const PATH_AUFGABEN_FESTSETZUNG_ZURUECKGEZOGEN = 'festsetzung-zurueckgezogen';
export const PATH_AUFGABEN_FESTSTELLUNG_BEARBEITEN = 'feststellung-bearbeiten';
export const PATH_AUFGABEN_FESTSTELLUNG_FREIGABE_ABGELEHNT = 'feststellung-freigabe-abgelehnt';
export const PATH_AUFGABEN_FESTSTELLUNG_PRUEFEN = 'feststellung-pruefen';
export const PATH_AUFGABEN_FESTSTELLUNG_ZURUECKGEZOGEN = 'feststellung-zurueckgezogen';
export const PATH_AUFGABEN_P8ABS11_BEARBEITEN = 'p8-abs11-bearbeiten';
export const PATH_AUFGABEN_P8ABS11_FREIGABE_ABGELEHNT = 'p8-abs11-freigabe-abgelehnt';
export const PATH_AUFGABEN_P8ABS11_PRUEFEN = 'p8-abs11-pruefen';
export const PATH_AUFGABEN_P8ABS11_ZURUECKGEZOGEN = 'p8-abs11-zurueckgezogen';
export const PATH_AUFGABEN_MELDUNG_P29 = 'meldung-p29';

export type PflegeeinrichtungTargets = 'benutzer';
export type TraegerTargets = 'einrichtungen';
export type TraegerverbandTargets = 'benutzer' | 'traeger' | 'einrichtungen';
export type PflegeeinrichtungAntragshistorieTargets =
    | 'antragshistorie_feststellung'
    | 'antragshistorie_festsetzung'
    | 'antragshistorie_investorenmodell';

export const buildRelativePath = (...paths: (number | string | undefined)[]): string =>
    paths.filter((p) => p).join('/');

export const buildPath = (...paths: (number | string | undefined)[]): string => PATH_HOME + buildRelativePath(...paths);

export const useAntragFirstStep = () => {
    const [isLV] = useHasRole(ROLES.LV);
    return {
        feststellungFirstStep: isLV ? 'antragsdaten' : 'basisdaten',
        festsetzungFirstStep: isLV ? 'antragsdaten' : 'basisdaten',
        investorenmodellFirstStep: isLV ? 'antragsdaten' : 'basisdaten',
        meldungP29FirstStep: 'basisdaten',
    };
};

export const determinePathForAntragType = (type: AntragType): string => {
    switch (type) {
        case AntragType.FESTSTELLUNG:
            return PATH_ANTRAEGE_FESTSTELLUNG;
        case AntragType.FESTSETZUNG:
            return PATH_ANTRAEGE_FESTSETZUNG;
        case AntragType.INVESTOREN_MODELL:
            return PATH_ANTRAEGE_INVESTOREN_MODELL;
        case AntragType.MELDUNG_P29:
            return PATH_ANTRAEGE_MELDUNG_P29;
    }
};

export const InvestPathBuilder = {
    home: {
        path: PATH_HOME,
        login: {
            path: buildPath(PATH_LOGIN),
        },
        hilfe: {
            path: buildPath(PATH_HILFE),
        },
        datenschutz: {
            path: buildPath(PATH_DATENSCHUTZ),
        },
        impressum: {
            path: buildPath(PATH_IMPRESSUM),
        },
        barrierefreiheit: {
            path: buildPath(PATH_BARRIEREFREIHEIT),
        },
        dashboard: {
            path: buildPath(PATH_DASHBOARD),
        },
        statistik: {
            path: buildPath(PATH_STATISTIK),
        },
        profile: {
            path: buildPath(PATH_PROFIL),
            benutzerdaten: {
                path: buildPath(PATH_PROFIL, PATH_PROFIL_BENUTZERDATEN),
                confirmEmail: buildPath(PATH_PROFIL, PATH_PROFIL_BENUTZERDATEN, PATH_PROFIL_EMAIL_CONFIRM),
                confirmPasswort: buildPath(PATH_PROFIL, PATH_PROFIL_BENUTZERDATEN, PATH_PROFIL_PASSWORT_RESET),
            },
        },
        antraege: {
            feststellung: {
                path: buildPath(PATH_ANTRAEGE, PATH_ANTRAEGE_FESTSTELLUNG),
                edit: (id: string | number, step?: string) =>
                    buildPath(PATH_ANTRAEGE, PATH_ANTRAEGE_FESTSTELLUNG, ACTION_EDIT, id, step),
                view: (id: string | number, step: string = PATH_ANTRAEGE_ANTRAGSVERSIONEN) =>
                    buildPath(PATH_ANTRAEGE, PATH_ANTRAEGE_FESTSTELLUNG, id, step),
            },
            festsetzung: {
                path: buildPath(PATH_ANTRAEGE, PATH_ANTRAEGE_FESTSETZUNG),
                edit: (id: string | number, step?: string) =>
                    buildPath(PATH_ANTRAEGE, PATH_ANTRAEGE_FESTSETZUNG, ACTION_EDIT, id, step),
                view: (id: string | number) => buildPath(PATH_ANTRAEGE, PATH_ANTRAEGE_FESTSETZUNG, id),
            },
            investorenmodell: {
                path: buildPath(PATH_ANTRAEGE, PATH_ANTRAEGE_INVESTOREN_MODELL),
                edit: (id: string | number, step?: string) =>
                    buildPath(PATH_ANTRAEGE, PATH_ANTRAEGE_INVESTOREN_MODELL, ACTION_EDIT, id, step),
                view: (id: string | number) => buildPath(PATH_ANTRAEGE, PATH_ANTRAEGE_INVESTOREN_MODELL, id),
            },
            meldungen_p29: {
                path: buildPath(PATH_ANTRAEGE, PATH_ANTRAEGE_MELDUNG_P29),
                edit: (id: string | number, step?: string) =>
                    buildPath(PATH_ANTRAEGE, PATH_ANTRAEGE_MELDUNG_P29, ACTION_EDIT, id, step),
                view: (id: string | number) => buildPath(PATH_ANTRAEGE, PATH_ANTRAEGE_MELDUNG_P29, id),
            },
        },
        aufgaben: {
            festsetzungBearbeiten: {
                path: buildPath(PATH_AUFGABEN, PATH_AUFGABEN_FESTSETZUNG_BEARBEITEN),
            },
            festsetzungFreigabeAbgelehnt: {
                path: buildPath(PATH_AUFGABEN, PATH_AUFGABEN_FESTSETZUNG_FREIGABE_ABGELEHNT),
            },
            festsetzungPruefen: {
                path: buildPath(PATH_AUFGABEN, PATH_AUFGABEN_FESTSETZUNG_PRUEFEN),
            },
            festsetzungZurueckgezogen: {
                path: buildPath(PATH_AUFGABEN, PATH_AUFGABEN_FESTSETZUNG_ZURUECKGEZOGEN),
            },
            feststellungBearbeiten: {
                path: buildPath(PATH_AUFGABEN, PATH_AUFGABEN_FESTSTELLUNG_BEARBEITEN),
            },
            feststellungFreigabeAbgelehnt: {
                path: buildPath(PATH_AUFGABEN, PATH_AUFGABEN_FESTSTELLUNG_FREIGABE_ABGELEHNT),
            },
            feststellungPruefen: {
                path: buildPath(PATH_AUFGABEN, PATH_AUFGABEN_FESTSTELLUNG_PRUEFEN),
            },
            feststellungZurueckgezogen: {
                path: buildPath(PATH_AUFGABEN, PATH_AUFGABEN_FESTSTELLUNG_ZURUECKGEZOGEN),
            },
            p8Abs11Bearbeiten: {
                path: buildPath(PATH_AUFGABEN, PATH_AUFGABEN_P8ABS11_BEARBEITEN),
            },
            p8Abs11FreigabeAbgelehnt: {
                path: buildPath(PATH_AUFGABEN, PATH_AUFGABEN_P8ABS11_FREIGABE_ABGELEHNT),
            },
            p8Abs11Pruefen: {
                path: buildPath(PATH_AUFGABEN, PATH_AUFGABEN_P8ABS11_PRUEFEN),
            },
            p8Abs11Zurueckgezogen: {
                path: buildPath(PATH_AUFGABEN, PATH_AUFGABEN_P8ABS11_ZURUECKGEZOGEN),
            },
            meldungP29: {
                path: buildPath(PATH_AUFGABEN, PATH_AUFGABEN_MELDUNG_P29),
            },
        },
        verwaltung: {
            path: buildPath(PATH_VERWALTUNG),
            benutzer: {
                path: buildPath(PATH_VERWALTUNG, PATH_VERWALTUNG_BENUTZER),
            },
            massenmail: {
                path: buildPath(PATH_VERWALTUNG, PATH_VERWALTUNG_MASSENMAIL),
            },
            pflegeeinrichtungen: {
                path: buildPath(PATH_VERWALTUNG, PATH_VERWALTUNG_PFLEGEEINRICHTUNGEN),
                einrichtung: (id: string | number) =>
                    buildPath(
                        PATH_VERWALTUNG,
                        PATH_VERWALTUNG_PFLEGEEINRICHTUNGEN,
                        id,
                        PATH_VERWALTUNG_PFLEGEEINRICHTUNGEN_STAMMDATEN
                    ),
                target: (id: string | number, target: PflegeeinrichtungTargets) =>
                    buildPath(PATH_VERWALTUNG, PATH_VERWALTUNG_PFLEGEEINRICHTUNGEN, id, target),
                antragshistorie: (id: string | number, target: PflegeeinrichtungAntragshistorieTargets) =>
                    buildPath(PATH_VERWALTUNG, PATH_VERWALTUNG_PFLEGEEINRICHTUNGEN, id, target),
            },
            traeger: {
                path: buildPath(PATH_VERWALTUNG, PATH_VERWALTUNG_TRAEGER),
                target: (id: string | number, target: TraegerTargets) =>
                    buildPath(PATH_VERWALTUNG, PATH_VERWALTUNG_TRAEGER, id, target),
            },
            traegerverband: {
                path: buildPath(PATH_VERWALTUNG, PATH_VERWALTUNG_TRAEGERVERBAND),
                target: (id: string | number, target: TraegerverbandTargets) =>
                    buildPath(PATH_VERWALTUNG, PATH_VERWALTUNG_TRAEGERVERBAND, id, target),
            },
            zustaendigkeiten: {
                path: buildPath(PATH_VERWALTUNG, PATH_VERWALTUNG_ZUSTAENDIGKEITEN),
            },
        },
        externeQuellen: {
            path: buildPath(PATH_EXTERNE_QUELLEN),
            angemessenheitsgrenze: {
                path: buildPath(PATH_EXTERNE_QUELLEN, PATH_ANGEMESSENHEITSGRENZE),
                abstimmungsbescheidAb2008: {
                    path: buildPath(
                        PATH_EXTERNE_QUELLEN,
                        PATH_ANGEMESSENHEITSGRENZE,
                        PATH_ABSTIMMUNGSBESCHEID_AB_01_07_2008
                    ),
                },
                abstimmungsbescheidVor2008: {
                    path: buildPath(
                        PATH_EXTERNE_QUELLEN,
                        PATH_ANGEMESSENHEITSGRENZE,
                        PATH_ABSTIMMUNGSBESCHEID_VOR_01_07_2008
                    ),
                },
                allgemeineVereinbarung: {
                    path: buildPath(PATH_EXTERNE_QUELLEN, PATH_ANGEMESSENHEITSGRENZE, PATH_ALLGEMEINE_VEREINBARUNG),
                },
                apgDvoMitKueche: {
                    path: buildPath(PATH_EXTERNE_QUELLEN, PATH_ANGEMESSENHEITSGRENZE, PATH_APG_DVO_MIT_KUECHE),
                },
                apgDvoTeilstationaer: {
                    path: buildPath(PATH_EXTERNE_QUELLEN, PATH_ANGEMESSENHEITSGRENZE, PATH_APG_DVO_TEILSTATIONAER),
                },
                apgDvoVollstationaer: {
                    path: buildPath(PATH_EXTERNE_QUELLEN, PATH_ANGEMESSENHEITSGRENZE, PATH_APG_DVO_VOLLSTATIONAER),
                },
                festgesetzterInstandhaltungswert: {
                    path: buildPath(
                        PATH_EXTERNE_QUELLEN,
                        PATH_ANGEMESSENHEITSGRENZE,
                        PATH_FESTGESETZTER_INSTANDHALTUNGSWERT_PRO_QM
                    ),
                },
                gesbervo1996Teilstationaer: {
                    path: buildPath(
                        PATH_EXTERNE_QUELLEN,
                        PATH_ANGEMESSENHEITSGRENZE,
                        PATH_GESBERVO_1996_TEILSTATIONAER
                    ),
                },
                gesbervo1996Vollstationaer: {
                    path: buildPath(
                        PATH_EXTERNE_QUELLEN,
                        PATH_ANGEMESSENHEITSGRENZE,
                        PATH_GESBERVO_1996_VOLLSTATIONAER
                    ),
                },
                gesbervo2003Teilstationaer: {
                    path: buildPath(
                        PATH_EXTERNE_QUELLEN,
                        PATH_ANGEMESSENHEITSGRENZE,
                        PATH_GESBERVO_2003_TEILSTATIONAER
                    ),
                },
                gesbervo2003Vollstationaer: {
                    path: buildPath(
                        PATH_EXTERNE_QUELLEN,
                        PATH_ANGEMESSENHEITSGRENZE,
                        PATH_GESBERVO_2003_VOLLSTATIONAER
                    ),
                },
                gesbervo2008Vollstationaer: {
                    path: buildPath(
                        PATH_EXTERNE_QUELLEN,
                        PATH_ANGEMESSENHEITSGRENZE,
                        PATH_GESBERVO_2008_VOLLSTATIONAER
                    ),
                },
                vor1983: {
                    path: buildPath(PATH_EXTERNE_QUELLEN, PATH_ANGEMESSENHEITSGRENZE, PATH_VOR_1983),
                },
            },
            baupreisindex: {
                path: buildPath(PATH_EXTERNE_QUELLEN, PATH_BAUPREISINDEX),
                _2010: {
                    path: buildPath(PATH_EXTERNE_QUELLEN, PATH_BAUPREISINDEX, PATH_2010),
                },
                _2015: {
                    path: buildPath(PATH_EXTERNE_QUELLEN, PATH_BAUPREISINDEX, PATH_2015),
                },
            },
            eigenkapitalzinsBundesbank: {
                path: buildPath(PATH_EXTERNE_QUELLEN, PATH_EIGENKAPITALZINSEN_GEMAESS_BUNDESBANK),
            },
            eigenkapitalzinsP56ApgDvo: {
                path: buildPath(PATH_EXTERNE_QUELLEN, PATH_EIGENKAPITALZINS_P5_6_APG_DVO),
            },
            fremdkapitalzinsenSud119: {
                path: buildPath(PATH_EXTERNE_QUELLEN, PATH_FREMDKAPITALZINSEN_SUD_119),
            },
        },
    },
};
