import { LinkProps } from '@mui/material/Link/Link';

import { Link } from './Link';

export interface MsisdnPropsInterface extends LinkProps {
    msisdn: string;
}

export const LinkTel = ({ msisdn, children, ...props }: MsisdnPropsInterface) => {
    let nummer = msisdn.trim();

    // country code entfernen (es werden nur deutsche nummern behandelt)
    if ('0049' === nummer.substring(0, 4)) {
        nummer = nummer.substring(4);
    } else if ('+49' === nummer.substring(0, 3)) {
        nummer = nummer.substring(3);
    } else if ('0' === nummer.charAt(0)) {
        nummer = nummer.substring(1);
    }

    // klammern mit spaces versehen
    nummer = nummer.replace('(', ' (');
    nummer = nummer.replace(')', ') ');

    // buchstaben und nicht erlaubte sonderzeichen entfernen
    nummer = nummer.replace(/[^0-9/() -]/g, '');

    // buchstaben und nicht erlaubte sonderzeichen entfernen
    nummer = nummer.replace(/[-]/g, '\u2013');

    // space links und rechts von allen zeichen außer + und ziffern
    nummer = nummer.replace(/[ ]*([^+0-9()])[ ]*/g, '\u00A0$1\u00A0');

    nummer = `+49 ${nummer}`;

    // keine aufeinander folgenden leerzeichen
    nummer = nummer.replace(/[ ,\u00A0]{2,}/g, '\u00A0');

    // im href außer ^+ keine sonderzeichen
    const href = `tel:+${nummer.replace(/[^0-9]/g, '')}`;

    return (
        <Link {...props} href={href}>
            {children || nummer}
        </Link>
    );
};
