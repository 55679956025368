import { useState } from 'react';
import { JsonForms } from '@jsonforms/react';

import { backendApiService } from 'api/ApiService';
import { JwtPayload } from 'api/auth/useAuthContextJwtToken';
import { useAuth } from 'api/AuthContext';
import { BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles } from 'api/client';
import { FormButton } from 'forms/components/FormButton';
import { SelectOption } from 'forms/components/Typeahead';
import { renderers } from 'forms/renderers';
import { FormStateChange, Schema } from 'forms/types/UiSchemaTypes';

export interface ImpersonateFormDataInterface {
    benutzer: SelectOption | undefined;
}

export const ImpersonateForm = () => {
    const { setImpersonatedBenutzer } = useAuth();
    const [formData, setFormData] = useState<ImpersonateFormDataInterface>({} as ImpersonateFormDataInterface);

    const handleSubmit = () => {
        if (formData.benutzer) {
            backendApiService
                .getBenutzerItem(String(formData.benutzer.id))
                .then((benutzer: BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles) => {
                    backendApiService.setImpersonateUser(benutzer.email);
                    setImpersonatedBenutzer({
                        roles: benutzer.roles,
                        nachname: benutzer.nachname,
                        vorname: benutzer.vorname,
                        username: benutzer.email,
                        benutzerId: benutzer.id,
                        rolle: benutzer.rolle,
                        landschaftsverbandKuerzel: benutzer.landschaftsverband,
                    } as JwtPayload);
                });
        }
    };

    const formSchema: Schema = {
        type: 'object',
        properties: {
            benutzer: {
                $id: 'benutzer',
                type: 'object',
                title: 'Suche',
                // @ts-ignore
                custom: {
                    block_prefixes: ['impersonate_autocomplete'],
                },
            },
        },
    };

    const uiSchema = {
        type: 'CustomVerticalLayout',
        elements: [
            {
                type: 'Control',
                scope: '#/properties/benutzer',
                label: false,
                help: 'Suche nach ID (exact), E-Mail-Adresse (istart), Vorname (istart), Nachname (istart)',
            },
        ],
    };

    const handleChange = (state: FormStateChange) => {
        setFormData(state.data);
    };

    return (
        <>
            {formSchema && (
                <>
                    <JsonForms
                        data={formData}
                        schema={formSchema}
                        uischema={uiSchema}
                        renderers={renderers}
                        onChange={handleChange}
                    />
                    <FormButton type="submit" color={'primary'} variant="contained" onClick={handleSubmit}>
                        Impersonate
                    </FormButton>
                </>
            )}
        </>
    );
};
