import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { SelectOption } from 'forms/components/Typeahead';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';

import { RowControl } from './RowControl';

const LandschaftsverbandAutocompleteControlComponent: React.FC<CustomControlProps<SelectOption | null>> = ({
    data,
    id,
    path,
    label,
    required,
    handleChange,
    disabled,
    showFieldNumberLabels,
    config,
    gridLayout,
}) => {
    const [options, setOptions] = useState<SelectOption[]>([]);

    useEffect(() => {
        if (options.length === 0) {
            backendApiService.getLandschaftsverbandCollection(1).then((response) => {
                setOptions(
                    response['hydra:member'].map((landschaftsverband) => {
                        return {
                            id: landschaftsverband.id as number,
                            name: landschaftsverband.name,
                        };
                    })
                );
            });
        }
    });

    const handleValueChange = useCallback(
        (event: any, value: SelectOption | null) => {
            handleChange(path, value);
        },
        [handleChange, path]
    );

    return (
        <RowControl
            name={path}
            label={label}
            required={required}
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={gridLayout}
            labelForId={id}
            config={config}
            paths={[path]}
        >
            <Autocomplete
                id={id}
                value={data}
                onChange={handleValueChange}
                options={options}
                getOptionLabel={(option) => option.name}
                loading={options.length === 0}
                renderInput={(params) => (
                    <TextField
                        required={required}
                        {...params}
                        disabled={disabled}
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            inputProps: {
                                ...params.inputProps,
                                'data-cy': `form_${path}`,
                            },
                            endAdornment: (
                                <>
                                    {options.length === 0 ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />
        </RowControl>
    );
};

export const LandschaftsverbandAutocompleteControl = withCustomControlProps(
    LandschaftsverbandAutocompleteControlComponent
);
