import React, { useState } from 'react';
import { Button, FormLabel, Grid, GridProps, TextField } from '@mui/material';

import { useAuthHelper } from 'api/auth/useAuthHelper';
import { ROLES } from 'constants/roles';

interface AktenzeichenInputFieldProps {
    label: string;
    value: string | null;
    onSubmit: (value: string) => void;
    labelMd?: GridProps['md'];
    valueMd?: GridProps['md'];
}

export const AktenzeichenInputField: React.FC<AktenzeichenInputFieldProps> = ({
    label,
    value,
    onSubmit,
    labelMd = 5,
    valueMd = 6,
}) => {
    const { hasRole } = useAuthHelper();
    const [input, setInput] = useState('');

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onSubmit(input);
    };

    return (
        <Grid container>
            <Grid item xs={5} md={labelMd} className={'Info-item'}>
                <FormLabel component={'legend'}>{label}</FormLabel>
            </Grid>
            <Grid item xs={7} md={valueMd} className={'Info-item'}>
                {!value && hasRole(ROLES.LV) ? (
                    <form onSubmit={handleSubmit}>
                        <TextField value={input} onChange={(event) => setInput(event.target.value)} />
                        <Button type="submit" variant="contained" color="primary">
                            Speichern
                        </Button>
                    </form>
                ) : (
                    <FormLabel component={'legend'}>{value}</FormLabel>
                )}
            </Grid>
        </Grid>
    );
};
