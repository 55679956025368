import React, { ReactElement } from 'react';
import { Alert, Button, Grid, styled, Typography } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { useConfirmationDialogActions } from 'forms/state/useConfirmationDialogState';
import { useLoadingActions } from 'forms/state/useLoadingState';
import { useMessageActions } from 'forms/state/useMessages';
import { errorMessage, successMessage } from 'forms/utils/MessageUtils';
import { useAnbieterDashboardState } from 'pages/Dashboard/Anbieter/useAnbieterDashboardState';

export const DashboardAnbieterTeilnahme = (): ReactElement => {
    const { einrichtung, triggerEinrichtungRefresh } = useAnbieterDashboardState();
    const { addMessage } = useMessageActions();
    const { showConfirmation, closeConfirmation } = useConfirmationDialogActions();
    const { showLoading, hideLoading } = useLoadingActions();

    const onTeilnehmen = () => {
        if (!einrichtung) return;
        showConfirmation({
            alertText:
                'Sind Sie sicher, dass Sie an PfAD.invest teilnehmen möchten? Sie haben dann die Möglichkeit Pflegewohngeld zu beantragen.',
            confirmLabel: 'Ja, an PfAD.invest teilnehmen',
            denyLabel: 'Abbrechen',
            confirmAction: async () => {
                try {
                    showLoading('Einrichtung wird aktiviert...');
                    await backendApiService.postEinrichtungReaktivieren(einrichtung.id);
                    triggerEinrichtungRefresh();
                    closeConfirmation();
                    addMessage(successMessage('Einrichtung erfolgreich aktiviert.', { autoCloseSeconds: 8 }));
                } catch (e) {
                    addMessage(errorMessage('Einrichtung konnte nicht aktiviert werden.', { autoCloseSeconds: 8 }));
                    console.error(e);
                } finally {
                    hideLoading();
                }
            },
            denyAction: closeConfirmation,
        });
    };

    return (
        <PaddedGrid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h1" component="h3">
                    Teilnahme an PfAD.invest
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Alert severity="info">
                    Bisher haben Sie auf die Inanspruchnahme von Pflegewohngeld nach § 14 APG NRW verzichtet und
                    lediglich die in Abrechnung gebrachten Investitionskosten nach § 29 APG DVO NRW i.V.m. § 82 Abs. 4
                    SGB XI angezeigt.
                    <br />
                    Sie haben jedoch jederzeit die Möglichkeit, bei Erfüllung der entsprechenden gesetzlichen
                    Vorschriften, Pflegewohngeld in Anspruch zu nehmen. Hierfür ist ein Antrag auf Festsetllung und
                    Festsetzung nach APG DVO NRW erforderlich.
                    <br />
                    Durch Anklicken auf den Button "Teilnahme an PfAD.invest" werden Sie zu den entsprechenden
                    Antragsseiten weitergeleitet.
                </Alert>
            </Grid>
            <Grid item xs={12}>
                <Typography paragraph>
                    Möchten Sie für diese Einrichtung Pflegewohngeld beantragen und am Verfahren PfAD.invest teilnehmen?
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Button onClick={onTeilnehmen} variant="contained" color="primary">
                    Teilnahme an PfAD.invest
                </Button>
            </Grid>
        </PaddedGrid>
    );
};

const PaddedGrid = styled(Grid)(({ theme }) => ({
    padding: theme.spacing(8, 0),
}));
