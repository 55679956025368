import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormControl, Grid, Typography } from '@mui/material';

import { FormButton } from 'forms/components/FormButton';
import { InvestPathBuilder } from 'navigation/Paths';

interface SuccessProps {
    email: string | null;
}

export const SuccessMessage: React.FC<SuccessProps> = ({ email }) => {
    const navigate = useNavigate();

    return (
        <>
            <Typography variant="h1">E-Mail-Adresse erfolgreich geändert</Typography>

            <Typography paragraph>Ihre E-Mail-Adresse wurde erfolgreich auf</Typography>
            <Typography style={{ fontWeight: 700 }} paragraph>
                {email}
            </Typography>
            <Typography paragraph>geändert.</Typography>
            <Typography style={{ fontWeight: 700 }} paragraph>
                Bitte melden Sie sich mit Ihren neuen Zugangsdaten bei PfAD.invest an.
            </Typography>
            <Grid container spacing={1} direction={'row-reverse'}>
                <Grid item>
                    <FormControl>
                        <FormButton
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={() => navigate(InvestPathBuilder.home.path)}
                        >
                            Zum Login
                        </FormButton>
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
};
