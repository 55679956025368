import {
    FestsetzungsAntragFormResponse,
    FestsetzungsAntragGegenpruefungRequest,
    FestsetzungsAntragGegenpruefungResponse,
    FestsetzungsAntragResponse,
    FeststellungsAntragFormResponse,
    FeststellungsAntragGegenpruefungRequest,
    FeststellungsAntragGegenpruefungResponse,
    FeststellungsAntragResponse,
    GegenpruefungResponse,
    InvestorenmodellFormResponse,
    InvestorenmodellGegenpruefungRequest,
    InvestorenmodellGegenpruefungResponse,
    InvestorenmodellResponse,
    MeldungP29FormResponse,
    MeldungP29Response,
    WithHinweiseAusMigration,
} from 'api/types';
import { Benutzer } from 'components/AntragInfoBox/components/BenutzerDialog';
import { FormState } from 'forms/types/UiSchemaTypes';
import { Step } from 'layout/HeaderConfig';

export enum AntragType {
    FESTSTELLUNG = 'FESTSTELLUNG',
    FESTSETZUNG = 'FESTSETZUNG',
    INVESTOREN_MODELL = 'INVESTOREN_MODELL',
    MELDUNG_P29 = 'MELDUNG_P29',
}

export type ZurueckziehenAntragType = Exclude<AntragType, AntragType.MELDUNG_P29>;
export type AbbrechenAntragType = Exclude<AntragType, AntragType.MELDUNG_P29>;

export type AntragEinrichtungBase = {
    id?: number;
    name?: string;
    nrwKey?: string;
    strasse?: string;
    plz?: string;
    hausnummer?: string;
    ort?: string;
    aktenzeichen?: string | null;
    zustaendigerBenutzer?: Benutzer | null;
};

export type AntragFestsetzungsantrag = {
    id?: number;
};

export type AntragBase = {
    id?: number;
    status?: string;
    einrichtung?: AntragEinrichtungBase;
    letzteGegenpruefung?: GegenpruefungResponse | null;
    eingereichtBy?: Benutzer | null;
    zuletztEingereichtBy?: Benutzer | null;
    festsetzungsantraege?: AntragFestsetzungsantrag[] | null;
} & WithHinweiseAusMigration;

export type FeststellungStepName =
    | 'basisdaten'
    | 'nrfUndGrundstueck'
    | 'bescheinigungen'
    | 'massnahmen'
    | 'salg'
    // TODO: fix typo
    | 'dokumnete'
    | 'zusammenfassung'
    | 'absenden'
    | 'bescheiderteilung';

export type FeststellungStep = Step<FeststellungStepName>;

export type AntragAPISubmit<FormResponse> = (
    id: string,
    step: string,
    formData: FormState,
    objectType?: string,
    uuid?: string,
    persist?: boolean,
    modelPaths?: string[],
    recalculateFields?: boolean
) => Promise<FormResponse>;
export type AntragAPIGetFormData<FormResponse> = (
    id: string,
    step: string,
    objectType?: string,
    uuid?: string
) => Promise<FormResponse>;
export type AntragAPIDelete = (id: string, uuid: string) => Promise<void>;
export type AntragAPIGetAntrag<AntragResponse extends AntragBase> = (id: string | number) => Promise<AntragResponse>;
export type AntragAPIPerformWorkflowAction<AntragResponse extends AntragBase> = (
    id: string | number,
    action: string
) => Promise<AntragResponse>;
export type AntragAPISubmitGegenpruefung<Request, Response> = (
    gegenpruefungId: number,
    data: Request
) => Promise<Response>;

export interface AntragAPI<
    FormResponse = any,
    AntragResponse extends AntragBase = any,
    GegenpruefungRequest = any,
    GegenpruefungResponse = any
> {
    submit: AntragAPISubmit<FormResponse>;
    getFormData: AntragAPIGetFormData<FormResponse>;
    deleteObjectTypeItem: AntragAPIDelete;
    getAntrag: AntragAPIGetAntrag<AntragResponse>;
    performWorkflowAction: AntragAPIPerformWorkflowAction<AntragResponse>;
    submitGegenpruefungErlaeuterung: AntragAPISubmitGegenpruefung<GegenpruefungRequest, GegenpruefungResponse>;
}

export type FestsetzungsAntragAPI<AntragResponse extends AntragBase = FestsetzungsAntragResponse> = AntragAPI<
    FestsetzungsAntragFormResponse,
    AntragResponse,
    FestsetzungsAntragGegenpruefungRequest,
    FestsetzungsAntragGegenpruefungResponse
>;

export type FeststellungsAntragAPI<AntragResponse extends AntragBase = FeststellungsAntragResponse> = AntragAPI<
    FeststellungsAntragFormResponse,
    AntragResponse,
    FeststellungsAntragGegenpruefungRequest,
    FeststellungsAntragGegenpruefungResponse
>;

export type InvestorenmodellAPI<AntragResponse extends AntragBase = InvestorenmodellResponse> = AntragAPI<
    InvestorenmodellFormResponse,
    AntragResponse,
    InvestorenmodellGegenpruefungRequest,
    InvestorenmodellGegenpruefungResponse
>;

export type MeldungP29API<AntragResponse extends AntragBase = MeldungP29Response> = AntragAPI<
    MeldungP29FormResponse,
    AntragResponse
>;
