import React from 'react';

import { ACTIONS } from 'constants/antragActions';
import { ANTRAG_ACTION_STATUS_LABELS, ANTRAG_STATUS_LABELS } from 'constants/labels';
import { FormButton } from 'forms/components/FormButton';
import { useConfirmationDialogActions } from 'forms/state/useConfirmationDialogState';
import { useFormState } from 'forms/state/useFormState';

interface FormSaveButtonInterface {
    onGegenpruefungFreigeben: () => void;
    disabled: boolean;
}

export const FormGegenpruefungFreigebenButton: React.FC<FormSaveButtonInterface> = ({
    onGegenpruefungFreigeben,
    disabled,
}) => {
    const { antragStatus } = useFormState();
    const { showConfirmation, closeConfirmation } = useConfirmationDialogActions();

    const onGegenpruefungFreigebenBase = () => {
        showConfirmation({
            alertText: `Sie sind dabei diesen Antrag freizugeben. Der Status dieses Antrages ändert sich von '${
                ANTRAG_STATUS_LABELS[antragStatus!]
            }' auf '${ANTRAG_ACTION_STATUS_LABELS[ACTIONS.FREIGEBEN]}'. Möchten Sie fortfahren?`,
            confirmLabel: 'Antrag freigeben',
            denyLabel: 'Abbrechen',
            denyAction: closeConfirmation,
            confirmAction: () => {
                closeConfirmation();
                onGegenpruefungFreigeben();
            },
        });
    };

    return (
        <FormButton
            type="submit"
            variant="contained"
            color="primary"
            onClick={onGegenpruefungFreigebenBase}
            disabled={disabled}
            data-cy={'FormGegenpruefungFreigebenButton'}
        >
            {'Freigabe erteilen'}
        </FormButton>
    );
};
