import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormControl, TextField } from '@mui/material';

import { ErrorList } from 'forms/controls/components/ErrorList';
import { RowControl } from 'forms/controls/RowControl';
import { useJsonFormsState } from 'forms/hooks/useJsonFormsState';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';

import { useEmailValidation } from './hooks/useEmailValidation';

const EmailChangeComponent: React.FC<CustomControlProps<string>> = ({
    path,
    handleChange,
    data,
    required,
    label,
    uischema,
}) => {
    const [email, setEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const { config } = useJsonFormsState();

    const [valid, match, validationError, matchingError] = useEmailValidation(email, confirmEmail);

    useEffect(() => {
        if (valid && match && data !== email) {
            handleChange(path, email);
        }
    }, [valid, match, email, handleChange, data, path]);

    return (
        <>
            <RowControl
                name={path}
                label={label}
                required={required}
                gridLayout={uischema.gridLayout || config.gridLayout}
                config={config}
                paths={[path]}
            >
                <FormControl fullWidth>
                    <TextField
                        fullWidth
                        required={required}
                        value={email}
                        error={validationError}
                        onChange={(event) => setEmail(event.target.value)}
                        data-cy={'EmailChangeControl-email'}
                        autoFocus={uischema.options?.focus}
                        helperText={
                            validationError ? (
                                <ErrorList
                                    errors={[
                                        {
                                            path: '',
                                            message: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
                                        },
                                    ]}
                                />
                            ) : (
                                ' '
                            )
                        }
                    />
                </FormControl>
            </RowControl>
            <RowControl
                name={path}
                label={`${label} wiederholen`}
                required={required}
                gridLayout={uischema.gridLayout || config.gridLayout}
                config={config}
                paths={[path]}
            >
                <FormControl fullWidth>
                    <TextField
                        fullWidth
                        required={required}
                        value={confirmEmail}
                        error={matchingError}
                        disabled={!email}
                        data-cy={'EmailChangeControl-confirmEmail'}
                        onChange={(event) => setConfirmEmail(event.target.value)}
                        helperText={
                            matchingError ? (
                                <ErrorList
                                    errors={[
                                        {
                                            path: '',
                                            message: 'Die eingegeben E-Mail-Adressen stimmen nicht überein.',
                                        },
                                    ]}
                                />
                            ) : (
                                ' '
                            )
                        }
                    />
                </FormControl>
            </RowControl>
        </>
    );
};

export const EmailChangeControl = withCustomControlProps(EmailChangeComponent);
