import { GridLayoutConfig, GroupFormType, UiSchemaType } from 'forms/types/UiSchemaTypes';

export const groupForm = (
    collectionScope: string,
    header: string | undefined,
    elements: UiSchemaType[],
    options: {
        gridLayout?: GridLayoutConfig;
    } = {}
): GroupFormType => ({
    type: 'GroupForm',
    collectionScope,
    header,
    elements,
    ...options,
});
