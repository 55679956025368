import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';

export const uiSchema = {
    type: 'CustomVerticalLayout',
    elements: [
        {
            type: 'Group',
            elements: [
                {
                    type: 'DocumentTableControl',
                    gridWidth: 8,
                    styles: {
                        marginBottom: 32,
                    },
                    columns: [
                        {
                            header: 'Schritt',
                            key: 'step',
                            styles: {
                                textAlign: 'left',
                                verticalAlign: 'top',
                            },
                        },
                        {
                            header: 'Bezeichnung',
                            key: 'description',
                            styles: {
                                textAlign: 'left',
                                verticalAlign: 'top',
                            },
                        },
                        {
                            header: 'Datei',
                            key: 'fileData',
                            styles: {
                                textAlign: 'left',
                                verticalAlign: 'top',
                            },
                        },
                        {
                            header: 'Hochzuladendes Dokument',
                            key: 'fileDescription',
                            styles: {
                                textAlign: 'right',
                                verticalAlign: 'top',
                            },
                        },
                    ],
                    entries: [
                        {
                            scope: '#/properties/',
                            uploads: [
                                {
                                    step: 'Basisdaten',
                                    description: 'Basisdaten',
                                    file: '#/properties/field103d',
                                    fileDescription: 'Versorgungsvertrag',
                                },
                                {
                                    step: 'NRF & Grundstück',
                                    description: 'Nettoraumfläche und Grundstück',
                                    file: '#/properties/field123d',
                                    fileDescription: 'Erbpachtvertrag',
                                },
                                {
                                    step: 'NRF & Grundstück',
                                    description: 'Zuordnung des Anlagevermögens',
                                    file: '#/properties/field161d',
                                    fileDescription: 'Mietvertrag',
                                },
                                {
                                    step: 'Ausnahmen',
                                    description: 'Ausnahmegenehmigungen',
                                    file: '#/properties/field141d',
                                    fileDescription: 'Bescheinigung nach § 9 Abs. 2 PfG NW bzw. § 11 Abs. 3 APG NRW',
                                },
                            ],
                        },
                        {
                            scope: '#/properties/ausnahmegenehmigungenP10Abs3Satz2Ziffer2Apg',
                            uploads: [
                                {
                                    step: 'Ausnahmen',
                                    description: '{#/properties/uuid}',
                                    file: '#/properties/field233d',
                                    fileDescription: 'Ausnahmegenehmigung',
                                },
                            ],
                        },
                        {
                            scope: '#/properties/massnahmen',
                            uploads: [
                                {
                                    step: 'Maßnahmen',
                                    description: '{#/properties/uuid}',
                                    file: '#/properties/field220d',
                                    fileDescription: 'Testat für tatsächlicher Aufwand LALG',
                                },
                                {
                                    step: 'Maßnahmen',
                                    description: '{#/properties/uuid}',
                                    file: '#/properties/field232d',
                                    fileDescription: 'Abstimmungsbescheinigung nach § 10 Abs. 3 APG DVO',
                                },
                                {
                                    scope: '#/properties/zuschuesse',
                                    description: '{#/properties/uuid}',
                                    uploads: [
                                        {
                                            step: 'Maßnahmen',
                                            description: 'Zuschuss von {#/properties/uuid}',
                                            file: '#/properties/field242d',
                                            fileDescription: 'Zuwendungsbescheid',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            scope: '#/properties/salgImEigentum',
                            uploads: [
                                {
                                    step: 'SALG',
                                    description: '{#/properties/uuid}, SALG im Eigentum',
                                    file: '#/properties/field302d',
                                    fileDescription: 'Testat Anschaffungskosten',
                                },
                                {
                                    scope: '#/properties/zuschuesse',
                                    description: '{#/properties/uuid}',
                                    uploads: [
                                        {
                                            step: 'SALG',
                                            description: 'Zuschuss von {#/properties/uuid}',
                                            file: '#/properties/field242d',
                                            fileDescription: 'Zuwendungsbescheid ',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            scope: '#/properties/salgImLeasing',
                            uploads: [
                                {
                                    step: 'SALG',
                                    description: '{#/properties/uuid}',
                                    file: '#/properties/field303d',
                                    fileDescription: 'Testat marktüblicher Kaufpreis',
                                },
                                {
                                    step: 'SALG',
                                    description: '{#/properties/uuid}',
                                    file: '#/properties/field304d',
                                    fileDescription: 'Leasingvertrag',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        group(
            [
                control('dokumente', {
                    noLabel: true,
                    multiline: true,
                }),
            ],
            {},
            'Weitere Dokumente'
        ),
    ],
};
