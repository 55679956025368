import { FormState, LabeledType, Schema } from 'forms/types/UiSchemaTypes';
import { formatFieldValue, getFieldSchemaByScope } from 'forms/utils/SchemaUtils';
import { getFieldFromScope } from 'utilities';

import { useUuidValue } from './useUuidValue';

export const useLabel = (data: FormState, schema: Schema, labeled?: LabeledType): string | undefined => {
    const { label, labelScope, labelValue } = labeled || {};
    const field = getFieldFromScope(labelScope);
    let fieldData = undefined;
    if (
        (Array.isArray(data) && data.includes(field)) ||
        (typeof data === 'object' && Object.prototype.hasOwnProperty.call(data, field))
    ) {
        fieldData = formatFieldValue(data[field], getFieldSchemaByScope(labelScope, schema));
    }
    const scopedLabel = useUuidValue(fieldData);

    return label || labelValue?.replace('{value}', scopedLabel || '') || scopedLabel;
};
