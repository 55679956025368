import { useMemo } from 'react';

import { AntragVersionCollectionListResponse, AntragVersionCollectionResponse } from 'api/types';
import { STATUS } from 'constants/antragStatus';

type UseAntragDiffableVersionsResult = {
    v1: AntragVersionCollectionResponse | null;
    v2: AntragVersionCollectionResponse | null;
};

export const useAntragDiffableVersions = (
    versions: AntragVersionCollectionListResponse | undefined
): UseAntragDiffableVersionsResult => {
    return useMemo(() => {
        const versionList = versions?.['hydra:member'] ?? [];
        const beschiedeneOrEingereichteVersionen = versionList.filter((version) =>
            [STATUS.BESCHIEDEN, STATUS.EINGEREICHT].includes(version.status)
        );

        const v2 = beschiedeneOrEingereichteVersionen[0] ?? null;
        return { v1: null, v2 };
    }, [versions]);
};
