import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';

import { AntragType } from 'api/antragTypes';
import { backendApiService } from 'api/ApiService';
import { InvestorenmodellCollectionListResponse } from 'api/types';
import { useColumnsInvestorenmodell } from 'components/DataTableHydra/Columns/Investorenmodell';
import { DataTableHydraServerside } from 'components/DataTableHydra/DataTableHydraServerside';
import { GetData } from 'components/DataTableHydra/hooks/useTableData';
import { sortBy, zustaendigerBenutzerFilter } from 'components/DataTableHydra/tableUtils';
import { ContentContainer } from 'layout/container/ContentContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { withAuthorization } from 'navigation/withAuthorization';
import { useAntragFilterOptions } from 'pages/Antraege/components/useAntragFilterOptions';
import { useInvestorenmodellAntragListAction } from 'pages/Antraege/Investorenmodell/useInvestorenmodellAntragListAction';
import { VerwaltungConfig } from 'pages/Verwaltung/VerwaltungConfig';

const AntragshistorieInvestorenmodell = () => {
    const { id } = useParams();

    const getData: GetData<InvestorenmodellCollectionListResponse> = useCallback(
        ({ page, rowsPerPage, searchText, sortOrder, filters }) => {
            return backendApiService.getInvestorenmodellCollection({
                page,
                qSearch: searchText ? [searchText] : undefined,
                einrichtung: id,
                itemsPerPage: rowsPerPage,
                orderStatus: sortBy(sortOrder, 'status'),
                orderEinrichtungName: sortBy(sortOrder, 'einrichtung'),
                orderZuletztEingereichtAt: sortBy(sortOrder, 'zuletztEingereichtAt'),
                orderEinrichtungAktenzeichen: sortBy(sortOrder, 'aktenzeichen'),
                orderFestsetzungsantragField500: sortBy(sortOrder, 'jahr'),
                orderEinrichtungZustaendigerBenutzerName: sortBy(sortOrder, 'zustaendigerBenutzer'),
                orderFestsetzungsantragFeststellungsantragBerechnungsart: sortBy(sortOrder, 'berechnungsart'),
                status2: filters?.['status'],
                berechnungsart: filters?.['berechnungsart'],
                antragJahr: filters?.['jahr'],
                zustaendigerBenutzer: zustaendigerBenutzerFilter(filters),
            });
        },
        [id]
    );

    const { handleAction, serverDataChanged } = useInvestorenmodellAntragListAction(AntragType.INVESTOREN_MODELL);
    const filterOptions = useAntragFilterOptions(AntragType.INVESTOREN_MODELL);
    const columnsInvestorenmodell = useColumnsInvestorenmodell(handleAction, filterOptions);

    return (
        <ContentContainer title="Anträge nach § 8 Abs. 11 (Antragshistorie)">
            <Typography variant="h1">Anträge nach § 8 Abs. 11 (Antragshistorie)</Typography>
            <DataTableHydraServerside
                getColumns={columnsInvestorenmodell}
                getData={getData}
                initialOptions={{
                    searchPlaceholder: 'Suche nach Einrichtungsname oder Aktenzeichen',
                    sortOrder: {
                        name: 'zuletztEingereichtAt',
                        direction: 'desc',
                    },
                }}
                serverDataChanged={serverDataChanged}
            />
        </ContentContainer>
    );
};

export const AntragshistorieInvestorenmodellPage = withAuthorization(
    withSideBar(AntragshistorieInvestorenmodell, VerwaltungConfig.menu),
    VerwaltungConfig.roles.pfelgeeinrichtung
);
