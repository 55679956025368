import { ReactElement } from 'react';
import { MenuItem } from '@mui/material';

import { useAuthHelper } from 'api/auth/useAuthHelper';
import { BenutzerResponse, EinrichtungResponse } from 'api/types';
import { ActionDropdown } from 'components/DataTableHydra/ActionDropdown/ActionDropdown';
import { PflegeeinrichtungBenutzerTableAction, PflegeeinrichtungBenutzerTableActions } from 'constants/benutzerActions';

interface BenutzerListActionsProps {
    allowBenutzerRechteEdit: boolean;
    allowBenutzerRemove: boolean;
    benutzer: BenutzerResponse;
    einrichtung: EinrichtungResponse;
    handleAction: PflegeeinrichtungBenutzerTableAction;
}

export const PflegeeinrichtungBenutzerListActions = ({
    allowBenutzerRechteEdit,
    allowBenutzerRemove,
    benutzer,
    einrichtung,
    handleAction,
}: BenutzerListActionsProps): ReactElement => {
    const { getCurrentBenutzer } = useAuthHelper();

    if (getCurrentBenutzer()?.benutzerId === benutzer.id) return <></>;
    return (
        <ActionDropdown>
            {allowBenutzerRechteEdit && (
                <MenuItem
                    onClick={() =>
                        handleAction(
                            PflegeeinrichtungBenutzerTableActions.EINRICHTUNG_RECHTE_ANPASSEN,
                            benutzer,
                            einrichtung
                        )
                    }
                >
                    Rechte anpassen
                </MenuItem>
            )}
            {allowBenutzerRemove && (
                <MenuItem
                    onClick={() =>
                        handleAction(
                            PflegeeinrichtungBenutzerTableActions.EINRICHTUNG_BENUTZER_ENTFERNEN,
                            benutzer,
                            einrichtung
                        )
                    }
                >
                    Benutzer entfernen
                </MenuItem>
            )}
        </ActionDropdown>
    );
};
