import { useState } from 'react';
import { isEqual } from 'lodash';

import { backendApiService } from 'api/ApiService';
import { BenutzerRequest, EinrichtungBenutzerRequest, EinrichtungResponse } from 'api/types';
import { ROLLEN } from 'constants/roles';
import { SelectOption } from 'forms/components/Typeahead';
import { useFormValidation } from 'forms/hooks/useFormValidation';
import { useLayoutWithJsonFormsState } from 'forms/hooks/useJsonFormsState';
import { useMessageActions } from 'forms/state/useMessages';
import { FormConfig, FormLayoutConfig, FormStateChange, Schema } from 'forms/types/UiSchemaTypes';
import { errorMessage, successMessage } from 'forms/utils/MessageUtils';
import { useAllowedCreateBenutzerRollen } from 'pages/Verwaltung/Benutzer/BenutzerErstellenForm/useAllowedCreateBenutzerRollen';
import { useCreateBenutzerCreateStepFormSchema } from 'pages/Verwaltung/Benutzer/BenutzerErstellenForm/useCreateBenutzerCreateStepFormSchema';

type UseCreateBenutzerCreateStepFormResult = {
    onSubmit: () => Promise<void>;
    isValid: boolean;
    isLoading: boolean;
    isSubmitting: boolean;

    data: CreateBenutzerCreateStepFormData;
    schema: Schema;
    config: FormConfig;
    onChange: (state: FormStateChange) => void;
};

export const useCreateBenutzerCreateStepForm = (
    email: string,
    einrichtung: EinrichtungResponse | null,
    onComplete: () => void
): UseCreateBenutzerCreateStepFormResult => {
    const { addMessage } = useMessageActions();
    const config = useLayoutWithJsonFormsState(configLayout);
    const [isSubmitting, setSubmitting] = useState(false);
    const { rollen, isLoading } = useAllowedCreateBenutzerRollen(einrichtung);
    const [data, setData] = useState<CreateBenutzerCreateStepFormData>({ email, ...initialFormData });
    const schema = useCreateBenutzerCreateStepFormSchema(einrichtung, rollen, data.rolle);
    const { valid: isValid } = useFormValidation(schema, data, config);

    const onChange = ({ data: formData }: FormStateChange) => {
        if (isEqual(data, formData)) return;
        setData(formData);
    };

    // TODO: fix distributed database transaction over HTTP protocol
    const onSubmit = async () => {
        if (isSubmitting || !data.vorname || !data.nachname) return;
        setSubmitting(true);

        try {
            const request: BenutzerRequest = {
                vorname: data.vorname,
                nachname: data.nachname,
                email: data.email,
                rolle: data.rolle ?? '',
                landschaftsverband: data.landschaftsverband ? String(data.landschaftsverband.id) : null,
                traegerverband: data.traegerverband ? String(data.traegerverband.id) : null,
                traeger: data.traeger ? String(data.traeger.id) : null,
            };
            const benutzerId = await backendApiService.postBenutzer(request);
            const einrichtungIds: number[] = einrichtung
                ? [einrichtung.id]
                : data.einrichtungen?.map((einrichtung) => einrichtung.id) ?? [];
            if (einrichtungIds.length > 0) {
                await Promise.all(
                    einrichtungIds.map((id) => saveEinrichtungBenutzer(benutzerId, id, data.isAntragsteller === 1))
                );
                if (einrichtungIds.length === 1) {
                    addMessage(createSingleSuccessMessage());
                } else {
                    addMessage(createMultipleSuccessMessage());
                }
            } else {
                addMessage(createSuccessMessage());
            }
            onComplete();
        } catch (e) {
            addMessage(createErrorMessage());
        } finally {
            setSubmitting(false);
        }
    };

    return {
        data,
        onChange,
        onSubmit,
        isSubmitting,
        isLoading,
        isValid,
        schema,
        config,
    };
};

const configLayout: FormLayoutConfig = {
    gridLayout: {
        label: 3,
        input: 6,
    },
};

type CreateBenutzerCreateStepFormData = {
    email: string;
    nachname: string | undefined;
    vorname: string | undefined;
    rolle: ROLLEN | null;
    landschaftsverband: SelectOption | undefined;
    traegerverband: SelectOption | undefined;
    traeger: SelectOption | undefined;
    einrichtungen: SelectOption[] | undefined;
    isAntragsteller: number | undefined;
};

const initialFormData: Omit<CreateBenutzerCreateStepFormData, 'email'> = {
    nachname: undefined,
    vorname: undefined,
    rolle: null,
    landschaftsverband: undefined,
    traegerverband: undefined,
    traeger: undefined,
    einrichtungen: undefined,
    isAntragsteller: undefined,
};

const saveEinrichtungBenutzer = async (benutzerId: number, einrichtungId: number, isAntragsteller: boolean) => {
    const einrichtungBenutzer: EinrichtungBenutzerRequest = {
        benutzer: String(benutzerId),
        einrichtung: String(einrichtungId),
        isAntragsteller: isAntragsteller,
    };
    return backendApiService.postEinrichtungBenutzer(einrichtungBenutzer);
};

const createSuccessMessage = () =>
    successMessage('Der Benutzer wurde hinzugefügt.', {
        autoCloseSeconds: 8,
        closable: true,
    });

const createSingleSuccessMessage = () =>
    successMessage('Der Benutzer wurde hinzugefügt und der Einrichtung zugewiesen.', {
        autoCloseSeconds: 8,
        closable: true,
    });

const createMultipleSuccessMessage = () =>
    successMessage('Der Benutzer wurde hinzugefügt und den Einrichtungen zugewiesen.', {
        autoCloseSeconds: 8,
        closable: true,
    });

const createErrorMessage = () =>
    errorMessage('Es ist ein Fehler aufgetreten.', {
        autoCloseSeconds: 8,
        closable: true,
    });
