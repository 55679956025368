import React from 'react';
import { Checkbox, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import { MUIDataTableColumn } from 'mui-datatables';

interface TableFilterCheckboxesProps {
    index: number;
    column: MUIDataTableColumn;
    filterData: string[];
    filterValue: string[];
    filterLabels?: {
        [key: string]: string;
    };

    onChange: (val: string | string[], index: number, column: MUIDataTableColumn) => void;
}

export const CheckboxesFilter: React.FC<TableFilterCheckboxesProps> = ({
    index,
    column,
    filterData,
    filterValue,
    filterLabels,
    onChange,
}) => {
    const handleOnchange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newFilterValue = [];
        if (event.target.checked) {
            newFilterValue = [...filterValue, event.target.value as string];
        } else {
            newFilterValue = filterValue.filter((v) => v !== (event.target.value as string));
        }
        onChange(newFilterValue, index, column);
    };

    return (
        <>
            <FormLabel>{column.label}</FormLabel>
            <FormGroup>
                {filterData.map((value, index) => (
                    <FormControlLabel
                        key={index}
                        control={
                            <Checkbox
                                checked={filterValue.indexOf(value) > -1}
                                value={value}
                                onChange={handleOnchange}
                            />
                        }
                        label={filterLabels ? filterLabels[value] : value}
                    />
                ))}
            </FormGroup>
        </>
    );
};
