import React, { ReactElement } from 'react';
import { CardContent, Typography } from '@mui/material';

import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { PflegeEinrichtungDropdown } from 'components/PflegeEinrichtungDropdown';
import { LinkEinrichtung } from 'elements/LinkEinrichtung';
import { StyledContainer } from 'layout/container/StyledContainer';

import { useDashboardAnbieterEinrichtung } from './useDashboardAnbieterEinrichtung';

export const DashboardAnbieterEinrichtung = (): ReactElement => {
    const {
        isEinrichtungenLoading,
        isEinrichtungenAvailable,
        onSelectEinrichtung,
        isOnlyOneEinrichtungAvailable,
        einrichtung,
    } = useDashboardAnbieterEinrichtung();

    return (
        <StyledContainer bordered backgroundVariant="secondary">
            <CardContent>
                <Typography variant="h1" component="h2">
                    Ihre Pflegeeinrichtung
                </Typography>
                <AppLoaderContainer isLoading={isEinrichtungenLoading}>
                    {!isEinrichtungenAvailable && (
                        <Typography paragraph>
                            <b>Ihnen ist aktuell keine Pflegeeinrichtung zugewiesen.</b>
                            <br /> Um Zugriff zu erhalten, setzen sich sich mit der verantwortlichen Stelle einer
                            Pflegeeinrichtung in Verbindung.
                        </Typography>
                    )}
                    {isEinrichtungenAvailable && !isOnlyOneEinrichtungAvailable && (
                        <>
                            {einrichtung ? (
                                <Typography paragraph>Andere Pflegeeinrichtung auswählen:</Typography>
                            ) : (
                                <Typography paragraph>Bitte wählen Sie eine Pflegeeinrichtung aus:</Typography>
                            )}
                            <PflegeEinrichtungDropdown
                                value={einrichtung}
                                onChange={onSelectEinrichtung}
                                placeholder="Einrichtung"
                                label="Einrichtung"
                            />
                        </>
                    )}
                    {einrichtung && (
                        <>
                            {!isOnlyOneEinrichtungAvailable && (
                                <Typography paragraph>Sie sind angemeldet für folgende Pflegeeinrichtung:</Typography>
                            )}
                            <Typography paragraph>
                                <LinkEinrichtung id={einrichtung.id} name={einrichtung.name} />
                                <br />
                                NRW-Schlüssel {einrichtung.nrwKey}
                                <br />
                                AZ {einrichtung.aktenzeichen}
                                <br />
                                {einrichtung.strasse} {einrichtung.hausnummer} {einrichtung.ort} {einrichtung.plz}
                                <br />
                            </Typography>
                        </>
                    )}
                </AppLoaderContainer>
            </CardContent>
        </StyledContainer>
    );
};
