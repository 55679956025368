import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { documentTable, documentTableColumn } from 'forms/AntragForm/ui-schemas/elements/documentTable';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';
import { notes } from 'forms/AntragForm/ui-schemas/groups/notes';

export const uiSchema = verticalLayout([
    group([
        documentTable(
            [
                documentTableColumn('Schritt', 'step'),
                documentTableColumn('Bezeichnung', 'description'),
                documentTableColumn('Datei', 'fileData'),
                documentTableColumn('Hochzuladendes Dokument', 'fileDescription', { textAlign: 'right' }),
            ],
            [
                {
                    scope: '#/properties/',
                    uploads: [
                        {
                            step: 'Basisdaten',
                            description: '-',
                            file: '#/properties/field451d',
                            fileDescription: 'Nachweis über den Kauf vor dem 05.07.2019',
                        },
                    ],
                },
                {
                    scope: '#/properties/darlehen',
                    uploads: [
                        {
                            step: 'Basisdaten',
                            description: '{#/properties/uuid}',
                            file: '#/properties/field453da',
                            fileDescription: 'Historischer Darlehnsvertrag',
                        },
                        {
                            step: 'Basisdaten',
                            description: '{#/properties/uuid}',
                            file: '#/properties/field453db',
                            fileDescription: 'Aktuelle Änderungsvereinbarung zum historischen  Darlehnsvertrag',
                        },
                        {
                            step: 'Basisdaten',
                            description: '{#/properties/uuid}',
                            file: '#/properties/field453dc',
                            fileDescription: 'Zins- und Tilgungsplan',
                        },
                    ],
                },
                {
                    scope: '#/properties/',
                    uploads: [
                        {
                            step: 'Basisdaten',
                            description: '-',
                            file: '#/properties/field457d',
                            fileDescription: 'Testat über den historisch entstandenen Investionsaufwand',
                        },
                        {
                            step: 'Basisdaten',
                            description: '-',
                            file: '#/properties/field459d',
                            fileDescription: 'Nachweis über die Kommunikation mit dem Vermieter',
                        },
                    ],
                },
            ],

            8,
            {
                marginBottom: 32,
            }
        ),
    ]),
    group(
        [
            control('dokumente', {
                noLabel: true,
                multiline: true,
            }),
        ],
        {},
        'Weitere Dokumente'
    ),

    notes('notizDokumente'),
]);
