import { useCallback, useEffect } from 'react';

import { backendApiService } from 'api/ApiService';
import { useAuthHelper } from 'api/auth/useAuthHelper';
import { useApiEinrichtungCollection } from 'api/hooks/useApiClient';
import { EinrichtungListItemResponse, EinrichtungResponse } from 'api/types';
import { STATUS as EinrichtungStatus } from 'constants/einrichtungStatus';

import { useAnbieterDashboardState } from './useAnbieterDashboardState';

interface useDashboardAnbieterEinrichtungInterface {
    isEinrichtungenLoading: boolean | undefined;
    isEinrichtungenAvailable: boolean;
    isOnlyOneEinrichtungAvailable: boolean;
    onSelectEinrichtung: (einrichtungListItem: EinrichtungListItemResponse | undefined) => void;
    einrichtung: EinrichtungResponse | undefined;
}

const einrichtungStatus = [
    EinrichtungStatus.AKTIV,
    EinrichtungStatus.RUHEND_VERZICHT_AUF_PFLEGEWOHNGELD,
    EinrichtungStatus.REGISTRIERUNG,
];

export const useDashboardAnbieterEinrichtung = (): useDashboardAnbieterEinrichtungInterface => {
    const { isAntragsteller } = useAuthHelper();
    const { einrichtung, setEinrichtung, refreshEinrichtungen } = useAnbieterDashboardState();
    const { data: einrichtungen, isLoading: isEinrichtungenLoading } = useApiEinrichtungCollection(
        {
            page: 1,
            itemsPerPage: 2,
            status2: einrichtungStatus,
        },
        { refresh: refreshEinrichtungen }
    );
    const einrichtungId = einrichtung?.id;
    const einrichtungModifiedAt = einrichtung?.modifiedAt;

    const onSelectEinrichtung = useCallback(
        (einrichtungListItem: EinrichtungListItemResponse | undefined) => {
            if (einrichtungListItem) {
                backendApiService
                    .getEinrichtungItem(String(einrichtungListItem.id))
                    .then((res) => setEinrichtung(res, isAntragsteller));
            } else {
                setEinrichtung(undefined);
            }
        },
        [isAntragsteller, setEinrichtung]
    );

    useEffect(() => {
        if (einrichtungen && !einrichtung && einrichtungen['hydra:member'].length === 1) {
            onSelectEinrichtung(einrichtungen['hydra:member'][0]);
        }
    }, [einrichtung, einrichtungen, onSelectEinrichtung]);

    useEffect(() => {
        const replaceEinrichtung = einrichtungen?.['hydra:member']?.find((e) => e.id === einrichtungId);
        if (replaceEinrichtung && einrichtungModifiedAt !== replaceEinrichtung?.modifiedAt) {
            onSelectEinrichtung(replaceEinrichtung);
        }
    }, [einrichtungModifiedAt, einrichtungId, einrichtungen, onSelectEinrichtung]);

    const isEinrichtungenAvailable = (einrichtungen?.['hydra:member']?.length ?? 0) > 0;
    const isOnlyOneEinrichtungAvailable = (einrichtungen?.['hydra:member']?.length ?? 0) === 1;

    return {
        isEinrichtungenLoading,
        isEinrichtungenAvailable,
        isOnlyOneEinrichtungAvailable,
        onSelectEinrichtung,
        einrichtung,
    };
};
