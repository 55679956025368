import React, { useCallback, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ListItemButton, styled } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';

import { useAuthHelper } from 'api/auth/useAuthHelper';
import { IconExpand } from 'elements/IconExpand';
import { MenuInterface } from 'layout/HeaderConfig';
import { transientOptions } from 'theme/utils';

export const NavigationMenuItem = React.forwardRef<HTMLDivElement, MenuInterface>(
    ({ menus, path, name, divide, disabled, expanded, tabs, padded }: MenuInterface, ref) => {
        const currentPath = useLocation().pathname;
        const [open, setOpen] = React.useState(Boolean(expanded));
        const { hasRole } = useAuthHelper();
        const isActive =
            currentPath === path ||
            (path && currentPath.startsWith(path + '/')) ||
            (tabs || []).some((tab) => tab.path === currentPath);
        const isExpandable = menus && menus.length > 0;

        useEffect(() => {
            setOpen(Boolean(expanded));
        }, [expanded]);

        const handleClick = useCallback(() => {
            setOpen((o) => !o);
        }, []);

        const listItemRoot = isExpandable ? (
            <ListItemButton ref={ref} onClick={handleClick} disabled={disabled} data-testid={'navItem'}>
                <ItemText $padded={padded}>{name}</ItemText>
                <IconExpand open={open} />
            </ListItemButton>
        ) : (
            <>
                {path && (
                    <ListItemButton
                        component={Link}
                        to={path}
                        selected={isActive}
                        disabled={disabled}
                        data-testid={'navItem'}
                    >
                        <ItemText $padded={padded}>{name}</ItemText>
                    </ListItemButton>
                )}
            </>
        );

        const listItemChildren = isExpandable ? (
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="nav" disablePadding>
                    {menus
                        ?.filter((item) => hasRole(item.roles))
                        .map((item, index) => {
                            return (
                                <div key={index}>
                                    <NavigationMenuItem {...item} padded />
                                </div>
                            );
                        })}
                </List>
            </Collapse>
        ) : null;

        return (
            <>
                {divide && <Divider aria-hidden={true} />}
                {listItemRoot}
                {listItemChildren}
            </>
        );
    }
);

const ItemText = styled(
    ListItemText,
    transientOptions
)<{ $padded: boolean | undefined }>(({ theme, $padded }) => ({
    paddingLeft: $padded ? theme.spacing(2) : undefined,
}));
