import { listControlWithModalForm } from 'forms/AntragForm/ui-schemas/elements/listControlWithModalForm';
import { tableColumn, tableColumnDate, tableColumnDecimal } from 'forms/AntragForm/ui-schemas/elements/tableColumn';
import { notes } from 'forms/AntragForm/ui-schemas/groups/notes';

import {
    FINANZIERUNGS_ACTIONS,
    modalEigenkapital,
    modalFremdkapital,
    modalLeasing,
} from './finanzierung/modalElements';

export const uiSchema = {
    type: 'CustomVerticalLayout',
    elements: [
        {
            type: 'Group',
            elements: [
                listControlWithModalForm(
                    ['#/properties/eigenkapital', '#/properties/fremdkapital', '#/properties/leasing'],
                    [
                        tableColumn('Art', { scope: '#/properties/type' }),
                        tableColumn('Bezeichnung', {
                            scopes: ['#/properties/field251', '#/properties/field270', '#/properties/field1427'],
                        }),
                        tableColumnDate('Datum', {
                            scopes: ['#/properties/field260', '#/properties/field271'],
                        }),
                        tableColumnDate('Ende des Darlehens', {
                            scope: '#/properties/field277',
                        }),
                        tableColumnDecimal('Anerkannter Betrag', {
                            scope: '#/properties/field1455',
                        }),
                    ],
                    [
                        'Finanzierung Eigenkapital hinzufügen',
                        'Finanzierung Fremdkapital hinzufügen',
                        'Finanzierung Leasing hinzufügen',
                    ],
                    ['Finanzierung löschen'],
                    ['Wollen Sie diese Finanzierung mit allen Angaben wirklich aus Ihrem Antrag löschen?'],
                    [
                        modalEigenkapital,
                        modalFremdkapital(),
                        modalFremdkapital(FINANZIERUNGS_ACTIONS.UMSCHULDUNG, 'Umschuldung'),
                        modalFremdkapital(FINANZIERUNGS_ACTIONS.AENDERUNG_ZINSBINDUNG, 'Änderung Zinsbindung'),
                        modalLeasing,
                    ],
                    { types: ['Eigenkapital', 'Fremdkapital', 'Leasing'] }
                ),
            ],
        },
        {
            type: 'Group',
            label: 'Finanzierungsaufwendungen',
            elements: [
                {
                    type: 'Info',
                    message:
                        'Bitte geben Sie nur die Finanzierungsaufwendungen an, die in den Monaten des Festsetzungszeitraumes tatsächlich gezahlt werden.',
                    rule: {
                        effect: 'SHOW',
                        condition: {
                            scope: '#/properties/fremdkapitalEndetInFestsetzungszeitraum',
                            schema: { const: true },
                        },
                    },
                },
                {
                    type: 'ControlGroupsOfElements',
                    labelScope: '#/properties/field270',
                    scope: '#/properties/aufwandFremdkapital',
                    gridLayout: {
                        fieldnumber: 2,
                        label: 6,
                        input: 3,
                    },
                    entries: [
                        {
                            type: 'Control',
                            scope: '#/properties/field540',
                        },
                    ],
                },
                {
                    type: 'ControlGroupsOfElements',
                    labelScope: '#/properties/field1427',
                    scope: '#/properties/aufwandLeasing',
                    gridLayout: {
                        fieldnumber: 2,
                        label: 6,
                        input: 3,
                    },
                    entries: [
                        {
                            type: 'Control',
                            scope: '#/properties/field541',
                        },
                    ],
                },
            ],
        },
        notes('notizFinanzierungen'),
    ],
};
