import { ReactElement } from 'react';
import { Box } from '@mui/material';

import { DataTableHydraServerside } from 'components/DataTableHydra/DataTableHydraServerside';
import { BenutzerBearbeitenForm } from 'pages/Verwaltung/Benutzer/BenutzerBearbeitenForm/BenutzerBearbeitenForm';
import { useBenutzerListAction } from 'pages/Verwaltung/Benutzer/BenutzerList/useBenutzerListAction';
import { useBenutzerListColumns } from 'pages/Verwaltung/Benutzer/BenutzerList/useBenutzerListColumns';
import { useBenutzerListData } from 'pages/Verwaltung/Benutzer/BenutzerList/useBenutzerListData';

interface BenutzerListProps {
    serverDataChanged?: number;
}

export const BenutzerList = ({ serverDataChanged: dataChanged }: BenutzerListProps): ReactElement => {
    const { bearbeitenBenutzer, finishAction, serverDataChanged, handleAction } = useBenutzerListAction();
    const getData = useBenutzerListData();
    const getColumns = useBenutzerListColumns(handleAction);
    return (
        <>
            <DataTableHydraServerside
                getColumns={getColumns}
                getData={getData}
                initialOptions={{
                    viewColumns: false,
                    customFilterDialogFooter: () => <Box width={400} />,
                }}
                serverDataChanged={serverDataChanged + (dataChanged ?? 0)}
            />
            <BenutzerBearbeitenForm benutzer={bearbeitenBenutzer} onClose={finishAction} />
        </>
    );
};
