import { ReactElement } from 'react';
import { Domain, Person } from '@mui/icons-material';
import { Button, Grid, styled } from '@mui/material';

import { useAuthHelper } from 'api/auth/useAuthHelper';

type DashboardLvBenutzerToolbarProps = {
    showTitle?: boolean;
};

export const DashboardLvBenutzerToolbar = ({ showTitle = false }: DashboardLvBenutzerToolbarProps): ReactElement => {
    const { getCurrentBenutzer, isLandschaftsverbandView, toggleLandschaftsverbandView, isImpersonated } =
        useAuthHelper();
    const benutzer = getCurrentBenutzer();

    return (
        <Grid container spacing={5}>
            <Grid item md={7}>
                {showTitle && (
                    <Title>
                        {isLandschaftsverbandView
                            ? `Gesamter ${isImpersonated() ? 'LV' : benutzer?.landschaftsverbandKuerzel}`
                            : `${benutzer?.vorname} ${benutzer?.nachname}`}
                    </Title>
                )}
            </Grid>
            <Grid item md={5}>
                <Grid container justifyContent={'flex-end'} alignItems={'center'}>
                    <span>Aktuelle Ansicht:&nbsp;</span>
                    <ActiveViewName>
                        {isLandschaftsverbandView
                            ? `Gesamter ${isImpersonated() ? 'LV' : benutzer?.landschaftsverbandKuerzel}`
                            : `${benutzer?.vorname} ${benutzer?.nachname}`}
                    </ActiveViewName>
                    {isLandschaftsverbandView ? (
                        <Button onClick={toggleLandschaftsverbandView} title={'Nur eigene Aufgaben anzeigen'}>
                            <Person />
                        </Button>
                    ) : (
                        <Button onClick={toggleLandschaftsverbandView} title={'Alle Aufgaben des LV anzeigen'}>
                            <Domain />
                        </Button>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

const ActiveViewName = styled('span')(() => ({
    fontWeight: 'bold',
}));

const Title = styled('h1')(() => ({
    margin: '0 !important',
}));
