import React from 'react';
import { CardContent, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';

import { EinrichtungListItemResponse } from 'api/types';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { LinkEinrichtung } from 'elements/LinkEinrichtung';
import { formatDateToString } from 'forms/utils/formatter';
import { NEUE_EINRICHTUNG_ZEITRAUM_TAGE } from 'pages/Dashboard/config';

interface DashboardLvNeueEinrichtungenProps {
    einrichtungen: EinrichtungListItemResponse[] | undefined;
    isLoading?: boolean;
}

export const DashboardLvNeueEinrichtungen: React.FC<DashboardLvNeueEinrichtungenProps> = ({
    einrichtungen,
    isLoading,
}) => {
    return (
        <>
            <CardContent>
                <Typography variant="h1" component="h2">
                    Neue Einrichtungen
                </Typography>
                <Typography>
                    Einrichtungen, die sich in den letzten {NEUE_EINRICHTUNG_ZEITRAUM_TAGE} Tagen neu registriert haben:
                </Typography>
            </CardContent>
            <AppLoaderContainer isLoading={!!isLoading}>
                {einrichtungen && (
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Einrichtung</TableCell>
                                <TableCell>Registriert seit</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {einrichtungen.map((einrichtung) => (
                                <TableRow>
                                    <TableCell>
                                        <LinkEinrichtung id={einrichtung.id} name={einrichtung.name} />
                                    </TableCell>
                                    <TableCell>{formatDateToString(einrichtung.createdAt)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}

                {(!einrichtungen || !einrichtungen?.length) && (
                    <CardContent>
                        <Typography align={'center'}>Keine Einrichtungen gefunden</Typography>
                    </CardContent>
                )}
            </AppLoaderContainer>
        </>
    );
};
