import { AlertColor } from '@mui/material';
import create from 'zustand';

interface MessageState {
    messages: Message[];
}

export interface MessageOptions {
    closable?: boolean;
    autoCloseSeconds?: number;
    action?: {
        actionTitle: string;
        actionMethod: (uuid: string) => void;
    };
}

export interface Message extends MessageOptions {
    uuid: string;
    type: AlertColor;
    text: string;
}

interface MessageActions {
    addMessage: (message: Message) => string;
    closeMessage: (uuid: string) => void;
}

const useMessageState = create<MessageState & MessageActions>((set) => ({
    messages: [],
    addMessage: (message) => {
        set((state) => {
            if (message.autoCloseSeconds) {
                setTimeout(() => state.closeMessage(message.uuid), message.autoCloseSeconds * 1000);
            }
            return { messages: [message, ...state.messages] };
        });

        return message.uuid;
    },
    closeMessage: (uuid) =>
        set((state) => ({
            messages: state.messages.filter((m) => m.uuid !== uuid),
        })),
}));

export const useMessageActions = () =>
    useMessageState((s) => ({
        addMessage: s.addMessage,
        closeMessage: s.closeMessage,
    }));

export const useMessages = () =>
    useMessageState((s) => ({
        messages: s.messages,
        closeMessage: s.closeMessage,
    }));
