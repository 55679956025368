import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { FormConfig, Schema, TableColumn, UiSchemaType } from 'forms/types/UiSchemaTypes';
import { determineSchemaByUUID } from 'forms/utils/SchemaUtils';

import { FormTableHeaderCell } from './FormTableHeaderCell';
import { FormTableRow } from './FormTableRow';

interface FormTableProps {
    data: any;
    path: string;
    config: FormConfig;
    schema: Schema;
    uischema: UiSchemaType & { columns: TableColumn[]; viewOnly?: boolean };
    onView: (index: number) => void;
    onEdit: (index: number) => void;
    onDelete: (index: number) => void;
    onCustomAction: (index: number, key: string) => void;
    disabled?: boolean;
    deleteAlertText: (index: number) => string;
    deleteConfirmationLabel: (index: number) => string;
}

export const FormTable: React.FC<FormTableProps> = ({
    uischema,
    disabled = false,
    data,
    schema,
    path,
    config,
    ...rowProps
}) => {
    return (
        <TableContainer>
            <Table data-cy={'Table'}>
                <TableHead>
                    <TableRow>
                        <TableCell />
                        {uischema.columns.map((colSchema, index) => (
                            <FormTableHeaderCell key={index} column={colSchema} />
                        ))}
                        <TableCell style={{ verticalAlign: 'bottom' }}>Aktion</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((elem: any, index: number) => (
                        <FormTableRow
                            key={index}
                            index={index}
                            schema={determineSchemaByUUID(elem.uuid, schema)}
                            rowData={elem}
                            path={path}
                            config={config}
                            disabled={disabled}
                            columns={uischema.columns}
                            viewOnly={uischema.viewOnly}
                            {...rowProps}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
