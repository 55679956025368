export enum ACTIONS {
    VIEW = 'VIEW',
    EDIT = 'EDIT',
    DELETE = 'DELETE',
    EINREICHEN = 'EINREICHEN',
    ZURUECKWEISEN = 'ZURUECKWEISEN',
    ABBRECHEN = 'ABBRECHEN',
    BEARBEITUNG_STARTEN = 'BEARBEITUNG_STARTEN',
    ZUR_PRUEFUNG_FREIGEBEN = 'ZUR_PRUEFUNG_FREIGEBEN',
    PRUEFEN = 'PRUEFEN',
    ABLEHNEN = 'ABLEHNEN',
    FREIGEBEN = 'FREIGEBEN',
    BESCHEIDEN = 'BESCHEIDEN',
    BESCHEID_AENDERN = 'BESCHEID_AENDERN',
    AUFHEBEN = 'AUFHEBEN',
    FOLGEANTRAG = 'FOLGEANTRAG',
    ZURUECKZIEHEN = 'ZURUECKZIEHEN',
    ZURUECKZIEHEN_BEANTRAGEN = 'ZURUECKZIEHEN_BEANTRAGEN',
    ZURUECKZIEHEN_AKZEPTIEREN = 'ZURUECKZIEHEN_AKZEPTIEREN',
    ZURUECKZIEHEN_ABLEHNEN = 'ZURUECKZIEHEN_ABLEHNEN',
    ZUR_KENNTNIS_NEHMEN = 'ZUR_KENNTNIS_NEHMEN',
    SOFORTIGE_FESTSETZUNG = 'SOFORTIGE_FESTSETZUNG',
}

type Action = string;
type ActionGroup = number;
export type AntragActions = Record<Action, ActionGroup>;
export const TABLE_ACTIONS: AntragActions = {
    [ACTIONS.VIEW]: 0,
    [ACTIONS.EDIT]: 0,
    [ACTIONS.ZURUECKZIEHEN_BEANTRAGEN]: 0,
    [ACTIONS.ZURUECKZIEHEN_AKZEPTIEREN]: 0,
    [ACTIONS.ABBRECHEN]: 1,
    [ACTIONS.BEARBEITUNG_STARTEN]: 1,
    [ACTIONS.BESCHEID_AENDERN]: 1,
    [ACTIONS.AUFHEBEN]: 1,
    [ACTIONS.ZUR_KENNTNIS_NEHMEN]: 2,
    [ACTIONS.PRUEFEN]: 2,
};
