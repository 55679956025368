import * as React from 'react';
import { LayoutProps, RankedTester, rankWith, uiTypeIs } from '@jsonforms/core';
import { FormLabel } from '@mui/material';

import { BESCHEID_VERSAND } from 'constants/bescheidVersand';
import { Link } from 'elements/Link';
import { withLayoutProps } from 'forms/hooks/withJsonFormProps';
import { useFormState } from 'forms/state/useFormState';
import { BescheidDokumentGenerierenLinkType } from 'forms/types/UiSchemaTypes';
import { getFieldFromScope } from 'utilities';
import { getAbsoluteBackendPath } from 'utilities/Utils';

interface BescheidDokumentGenerierenLinkComponentType extends Omit<LayoutProps, 'uischema'> {
    uischema: BescheidDokumentGenerierenLinkType;
}

const BescheidDokumentGenerierenLinkComponent: React.FC<BescheidDokumentGenerierenLinkComponentType> = ({
    uischema,
    data,
}) => {
    const { antragId } = useFormState();
    const fieldData = data[getFieldFromScope(uischema.scope)];
    const type = fieldData === BESCHEID_VERSAND.MANUELL ? 'docx' : 'pdf';

    if (fieldData === BESCHEID_VERSAND.KEIN_VERSAND) {
        return <></>;
    }

    return (
        <FormLabel component={'legend'}>
            <Link
                target="_blank"
                href={getAbsoluteBackendPath(`${uischema.href.replace('{antragId}', String(antragId))}/${type}`)}
            >
                {type.toUpperCase()}
            </Link>{' '}
        </FormLabel>
    );
};

export const BescheidDokumentGenerierenLinkControl = withLayoutProps(BescheidDokumentGenerierenLinkComponent);

export const BescheidDokumentGenerierenLinkTester: RankedTester = rankWith(
    1,
    uiTypeIs('BescheidDokumentGenerierenLink')
);
