import { MenuInterface } from 'layout/HeaderConfig';
import { InvestPathBuilder } from 'navigation/Paths';

export interface AufgabeItem {
    apiField: string;
    name: string;
    subname?: string;
    path: string;
}

export enum AUFGABEN {
    BEARBEITEN = 'BEARBEITEN',
    PRUEFEN = 'PRUEFEN',
    FREIGABE_ABGELEHNT = 'FREIGABE_ABGELEHNT',
    ZURUECKZIEHEN_BEANTRAGT = 'ZURUECKZIEHEN_BEANTRAGT',
    BESCHEIDVERSAND = 'BESCHEIDVERSAND',
}

export const AufgabenMenu: MenuInterface[] = [
    {
        name: 'Anträge auf Feststellung',
        path: InvestPathBuilder.home.aufgaben.feststellungBearbeiten.path,
    },
    {
        name: 'Anträge auf Festsetzung',
        path: InvestPathBuilder.home.aufgaben.festsetzungBearbeiten.path,
    },
    {
        name: 'Anträge nach §8 Abs. 11',
        path: InvestPathBuilder.home.aufgaben.p8Abs11Bearbeiten.path,
    },
    {
        name: 'Anträge auf Feststellung prüfen',
        path: InvestPathBuilder.home.aufgaben.feststellungPruefen.path,
    },
    {
        name: 'Anträge auf Festsetzung prüfen',
        path: InvestPathBuilder.home.aufgaben.festsetzungPruefen.path,
    },
    {
        name: 'Anträge nach § 8 Abs. 11 prüfen',
        path: InvestPathBuilder.home.aufgaben.p8Abs11Pruefen.path,
    },
    {
        name: 'Abgelehnte Freigaben Feststellung',
        path: InvestPathBuilder.home.aufgaben.feststellungFreigabeAbgelehnt.path,
    },
    {
        name: 'Abgelehnte Freigaben Festsetzung',
        path: InvestPathBuilder.home.aufgaben.festsetzungFreigabeAbgelehnt.path,
    },
    {
        name: 'Abgelehnte Freigaben nach § 8 Abs. 11',
        path: InvestPathBuilder.home.aufgaben.p8Abs11FreigabeAbgelehnt.path,
    },
    {
        name: 'Zurückgezogene Anträge auf Feststellung',
        path: InvestPathBuilder.home.aufgaben.feststellungZurueckgezogen.path,
    },
    {
        name: 'Zurückgezogene Anträge auf Festsetzung',
        path: InvestPathBuilder.home.aufgaben.festsetzungZurueckgezogen.path,
    },
    {
        name: 'Zurückgezogene Anträge nach § 8 Abs. 11',
        path: InvestPathBuilder.home.aufgaben.p8Abs11Zurueckgezogen.path,
    },
    {
        name: 'abgegebene Meldungen nach § 29',
        path: InvestPathBuilder.home.aufgaben.meldungP29.path,
    },
];
