import { useAuthHelper } from 'api/auth/useAuthHelper';
import { useApiBenutzerAufgaben, useApiLvAufgaben } from 'api/hooks/useApiClient';
import { AufgabenResponse } from 'api/types';

type UseDashboardLvAufgabenResult = {
    isLoading: boolean;
    data: AufgabenResponse | undefined;
};

export const useDashboardLvAufgaben = (): UseDashboardLvAufgabenResult => {
    const { isLandschaftsverbandView } = useAuthHelper();

    const { data: benutzerAufgaben, isLoading: isBenutzerAufgabenLoading } = useApiBenutzerAufgaben({
        condition: !isLandschaftsverbandView,
    });

    const { data: lvAufgaben, isLoading: isLvAufgabenLoading } = useApiLvAufgaben({
        condition: isLandschaftsverbandView,
    });

    return {
        isLoading: Boolean(isBenutzerAufgabenLoading || isLvAufgabenLoading),
        data: isLandschaftsverbandView ? lvAufgaben : benutzerAufgaben,
    };
};
