import { BenutzerResponse, EinrichtungResponse } from 'api/types';

export enum BenutzerTableActions {
    BENUTZER_BEARBEITEN = 'BENUTZER_BEARBEITEN',
    BENUTZER_TOGGLE_AKTIVIEREN = 'BENUTZER_TOGGLE_AKTIVEREN',
}

export enum PflegeeinrichtungBenutzerTableActions {
    EINRICHTUNG_RECHTE_ANPASSEN = 'EINRICHTUNG_RECHTE_ANPASSEN',
    EINRICHTUNG_BENUTZER_ENTFERNEN = 'EINRICHTUNG_BENUTZER_ENTFERNEN',
}

export type BenutzerTableAction = (action: BenutzerTableActions, benutzer: BenutzerResponse) => Promise<void>;

export type PflegeeinrichtungBenutzerTableAction = (
    action: PflegeeinrichtungBenutzerTableActions,
    benutzer: BenutzerResponse,
    einrichtung: EinrichtungResponse
) => Promise<void>;
