import { accordion } from 'forms/AntragForm/ui-schemas/elements/accordion';
import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/AntragForm/ui-schemas/elements/customVerticalLayout';
import { dataGrid } from 'forms/AntragForm/ui-schemas/elements/dataGrid';
import { formDialog } from 'forms/AntragForm/ui-schemas/elements/formDialog';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { labeled } from 'forms/AntragForm/ui-schemas/elements/labeled';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';

export const modalFremdkapital = formDialog(
    'Neue Finanzierung Fremdkapital',
    `Finanzierung Fremdkapital`,
    labeled({ labelScope: '#/properties/field270' }),
    customVerticalLayout([
        group(
            [
                control('field250'),
                control('field270'),
                control('field271'),
                control('field270d'),
                control('field276'),
                control('field1404'),
                control('field282'),
                control('field272'),
                control('field272a'),
                control('field273'),
                control('field274'),
                control('field275'),
            ],
            {},
            'Antragsdaten'
        ),
        group(
            [
                {
                    type: 'FormExtendButton',
                    label: 'Finanzierung weiterer Maßnahme aus diesem Nominalkapital hinzufügen',
                    scope: '#/properties/fremdkapitalAnteile',
                    uiSchema: verticalLayout([
                        control('field251', {
                            options: {
                                firstControl: true,
                            },
                        }),
                        control('field273a'),
                        control('field273b'),
                    ]),
                },
            ],
            {}
        ),
        group(
            [
                control('field273', {
                    options: {
                        readonly: true,
                    },
                }),
                control('field273c'),
                control('field1455'),
                control('field5450'),
                control('field1451'),
                control('field1439'),
                control('field1440'),
                control('field1452'),
                control('field1453'),
                control('field277'),
                control('field1445'),
                control('field1446'),
                control('field1446a'),
                control('field1448'),
                control('field1449'),
                {
                    type: 'ControlsOfElements',
                    elementScope: '#/properties/aufwandFremdkapital',
                    controlScope: '#/properties/field540',
                },
                control('field540d'),
                control('field2024'),
                control('field2025'),
                control('field1403'),
                control('field1407'),
                control('field1409'),
            ],
            {}
        ),
    ]),
    {
        scope: '#/properties/fremdkapital',
        actionKey: null,
    }
);

export const modalEigenkapital = formDialog(
    'Neue Finanzierung Eigenkapital',
    'Finanzierung Eigenkapital',
    labeled({
        labelScope: '#/properties/field251',
    }),
    customVerticalLayout([
        group([control('field251'), control('field260'), control('field261')], {}, 'Antragsdaten'),
        group(
            [
                control('field1415'),
                control('field1416'),
                control('field1456'),
                control('field1457'),
                control('field1458'),
                control('field5458'),
                control('field5433'),
                control('field5459'),
                control('field1419'),
                control('field5417'),
                control('field5521'),
                accordion(
                    [
                        dataGrid(
                            'eigenkapitalBasis',
                            [
                                {
                                    label: undefined,
                                    scope: '#/properties/monat',
                                    valueLabel: 'Monat {value}',
                                    textAlign: 'left',
                                },
                                {
                                    label: '5417 Kapitalbasis',
                                    scope: '#/properties/field5417x',
                                    format: 'currency',
                                    unit: '€',
                                    highlighted: true,
                                    styles: {
                                        textAlign: 'right',
                                        paddingRight: 16,
                                    },
                                },
                                {
                                    label: '5421 Zins',
                                    scope: '#/properties/field5421x',
                                    format: 'currency',
                                    unit: '€',
                                    styles: {
                                        textAlign: 'right',
                                    },
                                },
                            ],
                            { containerGridSize: 7 }
                        ),
                    ],
                    'Finanzierungskosten Eigenkapital je Monat'
                ),
            ],
            { noSeparator: true },
            'Bearbeitung'
        ),

        group([control('field5522'), control('field5421'), control('field5444')], {}),
    ]),
    {
        scope: '#/properties/eigenkapital',
        actionKey: null,
    }
);

export const modalLeasing = formDialog(
    'Neue Finanzierung Leasing',
    'Finanzierung Leasing',
    labeled({ labelScope: '#/properties/field1427' }),
    customVerticalLayout([
        group(
            [
                control('field1427'),
                {
                    type: 'ControlsOfElements',
                    elementScope: '#/properties/aufwandLeasing',
                    controlScope: '#/properties/field541',
                },
                control('field541d'),
                control('field1428'),
            ],
            {},
            'Antragsdaten'
        ),
        group(
            [
                control('field2026'),
                control('field2027'),
                control('field1429'),
                control('field1430'),
                control('field1431'),
                control('field1432'),
            ],
            {},
            'Bearbeitung'
        ),
    ]),
    {
        scope: '#/properties/leasing',
        actionKey: null,
    }
);
