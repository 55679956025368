import React, { ChangeEvent, createRef, useEffect, useRef } from 'react';
import { ButtonBaseActions, Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';

import { ErrorList } from 'forms/controls/components/ErrorList';
import { ReadonlyValueText } from 'forms/controls/components/ReadonlyValueText';
import { useIsDataChanged } from 'forms/hooks/useIsDataChanged';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';
import { formatBoolean } from 'forms/utils/formatter';

import { RowControl } from './RowControl';

const CheckboxControlComponent: React.FC<CustomControlProps<boolean>> = ({
    path,
    label,
    uischema,
    handleChange,
    disabled,
    readonly,
    formula,
    data,
    onTouched,
    hasErrors,
    errors,
    config,
    required,
    showFieldNumberLabels,
    gridLayout,
    schema,
}) => {
    const requestFocus = useRef<boolean>();

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        handleChange(path, event.target.checked, true);
        setTimeout(() => {
            requestFocus.current = true;
            buttonRef.current?.blur();
        }, 50);
    };
    const isDataChanged = useIsDataChanged(data, schema);

    const actionRef = createRef<ButtonBaseActions>();
    const buttonRef = createRef<HTMLButtonElement>();
    useEffect(() => {
        if (!disabled && requestFocus.current) {
            actionRef.current?.focusVisible();
            requestFocus.current = false;
        }
    }, [requestFocus, disabled, actionRef]);

    return (
        <RowControl
            name={path}
            label={label}
            controlOnly={uischema.controlOnly}
            formula={formula}
            required={required}
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={gridLayout}
            hasChangedData={isDataChanged}
            config={config}
            paths={[path]}
        >
            {readonly ? (
                <ReadonlyValueText
                    path={path}
                    text={data ? formatBoolean(data) : '-'}
                    hasErrors={false}
                    textAlign={'left'}
                    errors={[]}
                />
            ) : (
                <FormControl disabled={disabled} required={required}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={disabled}
                                checked={data}
                                ref={buttonRef}
                                action={actionRef}
                                focusRipple={true}
                                onBlur={onTouched}
                                onChange={onChange}
                                required={required}
                                inputProps={{ 'data-cy': `form_${path}` } as any}
                            />
                        }
                        label={uischema.controlOnly ? label : ''}
                    />
                    <FormHelperText>{hasErrors ? <ErrorList errors={errors} /> : ' '}</FormHelperText>
                </FormControl>
            )}
        </RowControl>
    );
};

export const CheckboxControl = withCustomControlProps(CheckboxControlComponent);
