import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { backendApiService } from 'api/ApiService';
import { useAuthHelper } from 'api/auth/useAuthHelper';
import { useAuth } from 'api/AuthContext';
import { ROLES } from 'constants/roles';
import { InvestPathBuilder } from 'navigation/Paths';

export interface UseLoginLinkCheckResult {
    isLoading: boolean;
    error: string | undefined;
    benutzerId: number | undefined;
}

export const useLoginLinkCheck = (): UseLoginLinkCheckResult => {
    const navigate = useNavigate();
    const location = useLocation();
    const { handleJwtToken } = useAuth();
    const { getCurrentBenutzer, hasRole, isSignedIn } = useAuthHelper();
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (isSignedIn() && !hasRole(ROLES.PASSWORD_RESET)) {
            navigate(InvestPathBuilder.home.dashboard.path);
        }
    }, [navigate, hasRole, isSignedIn]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const user = params.get('user') || '';
        const hash = params.get('hash') || '';
        const expires = Number.parseInt(params.get('expires') || '');

        backendApiService
            .getLoginLinkCheck(user, expires, hash)
            .then((res) => handleJwtToken(res.token, res.refresh_token))
            .catch(() => setError('Link ungültig'))
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const benutzerId = getCurrentBenutzer()?.benutzerId;

    return { isLoading, error, benutzerId };
};
