import { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { useAuthHelper } from 'api/auth/useAuthHelper';
import { ZustaendigkeitJsonldZustaendigkeitWrite } from 'api/client';
import { useApiZustaendigkeitItem } from 'api/hooks/useApiClient';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { EINRICHTUNG_ART_LABELS } from 'constants/labels';
import { ROLES } from 'constants/roles';
import { FormButton } from 'forms/components/FormButton';
import { useMessageActions } from 'forms/state/useMessages';
import { errorMessage, successMessage } from 'forms/utils/MessageUtils';
import { FormRegion, ZustaendigkeitFormDataInterface } from 'pages/Verwaltung/Zustaendigkeiten/Bereich/FormRegion';

interface EditZustaendigkeitRegionDialogPropsInterface {
    handleZustaendigkeitSaved?(): void;

    handleExit?(): void;

    zustaendigkeitId: string;
}

export const DialogRegion = ({
    handleZustaendigkeitSaved,
    handleExit,
    zustaendigkeitId,
}: EditZustaendigkeitRegionDialogPropsInterface) => {
    const { getCurrentBenutzer, hasRole } = useAuthHelper();
    const { isLoading, data: zustaendigkeit } = useApiZustaendigkeitItem(zustaendigkeitId);
    const [valid, setValid] = useState<boolean>(false);
    const [showState, setShowState] = useState<boolean>(true);
    const [submit, setSubmit] = useState<boolean>(false);
    const [isSubmitting, setSubmitting] = useState<boolean>(false);
    const { addMessage } = useMessageActions();

    const handleSubmitOnClick = () => setSubmit(true);

    const handleCloseButtonClick = () => {
        setShowState(false);
    };

    const handleSubmit = (formData: ZustaendigkeitFormDataInterface) => {
        if (isSubmitting) {
            return;
        }

        setSubmitting(true);
        setSubmit(false);

        const data: ZustaendigkeitJsonldZustaendigkeitWrite = {
            benutzer: String(formData?.benutzer?.id),
        };

        backendApiService
            .putZustaendigkeit(zustaendigkeitId, data)
            .then(() => {
                addMessage(
                    successMessage('Die Zuständigkeit wurde geändert.', {
                        autoCloseSeconds: 8,
                    })
                );
                setSubmitting(false);
                if (handleZustaendigkeitSaved) {
                    handleZustaendigkeitSaved();
                }
                setShowState(false);
            })
            .catch(() => {
                addMessage(
                    errorMessage('Es ist ein Fehler aufgetreten.', {
                        autoCloseSeconds: 8,
                    })
                );
                setSubmitting(false);
            })
            .finally(() => {
                setSubmit(false);
            });
    };

    let regionLabel = 'Regionalschlüssel/PLZ';

    switch (true) {
        case hasRole(ROLES.LV) && getCurrentBenutzer()?.landschaftsverbandKuerzel === 'LVR':
            regionLabel = 'PLZ';
            break;
        case hasRole(ROLES.LV) && getCurrentBenutzer()?.landschaftsverbandKuerzel === 'LWL':
            regionLabel = 'Regionalschlüssel';
            break;
    }

    return (
        <Dialog
            open={showState}
            fullWidth={true}
            maxWidth="md"
            TransitionProps={{
                onExit: handleExit,
            }}
        >
            <DialogTitle>Zuständigkeit bearbeiten</DialogTitle>
            <AppLoaderContainer isLoading={isLoading || isSubmitting}>
                <DialogContent>
                    <Grid container component={'dl'}>
                        <Grid item md={3} component={'dt'} alignItems={'flex-start'}>
                            {regionLabel}
                        </Grid>
                        <Grid item md={9} component={'dd'} justifyContent={'flex-start'}>
                            {zustaendigkeit?.region}
                        </Grid>
                        {zustaendigkeit?.einrichtungArt && (
                            <>
                                <Grid item md={3} component={'dt'} alignItems={'flex-start'}>
                                    Art
                                </Grid>
                                <Grid item md={9} component={'dd'} justifyContent={'flex-start'}>
                                    {zustaendigkeit?.einrichtungArt
                                        ? EINRICHTUNG_ART_LABELS[zustaendigkeit?.einrichtungArt]
                                        : ''}
                                </Grid>
                            </>
                        )}
                    </Grid>
                    {zustaendigkeit && (
                        <FormRegion
                            handleSubmit={handleSubmit}
                            isSubmitting={isSubmitting}
                            submit={submit}
                            zustaendigkeit={zustaendigkeit}
                            setValid={setValid}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <FormButton type="button" onClick={handleCloseButtonClick} disabled={isSubmitting}>
                        Schließen
                    </FormButton>
                    <FormButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={handleSubmitOnClick}
                        disabled={isSubmitting || !valid}
                    >
                        Speichern
                    </FormButton>
                </DialogActions>
            </AppLoaderContainer>
        </Dialog>
    );
};
