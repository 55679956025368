import { ReactElement } from 'react';
import { JsonForms } from '@jsonforms/react';
import { Grid } from '@mui/material';

import {
    FestsetzungsAntragCollectionResponse,
    FeststellungsAntragCollectionResponse,
    InvestorenmodellCollectionResponse,
} from 'api/types';
import { ACTIONS } from 'constants/antragActions';
import { FormButton } from 'forms/components/FormButton';
import { renderers } from 'forms/renderers';
import { useConfirmationDialogActions } from 'forms/state/useConfirmationDialogState';
import { determineAntragStatusAlertText } from 'pages/Antraege/antragStatusAenderung';
import { useZurueckziehenAkzeptierenDialog } from 'pages/Antraege/components/useZurueckziehenAkzeptierenDialog';

type ZurueckziehenAkzeptierenDialogStepDenyProps = {
    antrag:
        | FeststellungsAntragCollectionResponse
        | FestsetzungsAntragCollectionResponse
        | InvestorenmodellCollectionResponse;
    onCancel: () => void;
    onSubmit: (entscheidung: boolean, erlaeuterungEntscheidung: string) => Promise<void>;
};

export const ZurueckziehenAkzeptierenDialogStepDeny = ({
    antrag,
    onCancel,
    onSubmit,
}: ZurueckziehenAkzeptierenDialogStepDenyProps): ReactElement => {
    const { data, ...formProps } = useZurueckziehenAkzeptierenDialog(
        false,
        '<strong>Begründung</strong>, warum der Antrag auf Zurückziehen abgelehnt wird:'
    );
    const { showConfirmation, closeConfirmation } = useConfirmationDialogActions();

    const onSave = async () => {
        const alertText = determineAntragStatusAlertText(antrag.status, ACTIONS.ZURUECKZIEHEN_ABLEHNEN);
        const confirmLabel = 'Fortfahren';
        const denyLabel = 'Abbrechen';

        showConfirmation({
            alertText,
            confirmLabel,
            denyLabel,
            confirmAction: async () => {
                closeConfirmation();
                await onSubmit(data.entscheidung, data.erlaeuterungEntscheidung);
            },
            denyAction: closeConfirmation,
        });
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <JsonForms data={data} renderers={renderers} {...formProps} />
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
                <FormButton type="button" variant="outlined" color="primary" onClick={onCancel}>
                    Abrechen
                </FormButton>
                <FormButton type="button" variant="contained" color="primary" onClick={onSave}>
                    Zurückziehen ablehnen
                </FormButton>
            </Grid>
        </Grid>
    );
};
