import { MUISortOptions } from 'mui-datatables';

import { TableFilters } from 'components/DataTableHydra/hooks/useTableFilters';

export const sortBy = (
    sortOrder: MUISortOptions | undefined,
    name: string
): MUISortOptions['direction'] | undefined => {
    return sortOrder?.name === name ? sortOrder?.direction : undefined;
};

export const zustaendigerBenutzerFilter = (
    filters: TableFilters | undefined,
    key: string = 'zustaendigerBenutzer'
): number[] | undefined => {
    const id = filters?.[key]?.[0];
    return id ? [Number(id)] : undefined;
};
