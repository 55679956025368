import { InvestPathBuilder } from 'navigation/Paths';

export const NEUE_EINRICHTUNG_ZEITRAUM_TAGE = 90;

export interface AufgabeItem {
    apiField: string;
    headline: string;
    subHeadline?: string;
    path: string;
}

export const AufgabenList: AufgabeItem[] = [
    {
        apiField: 'feststellungBearbeiten',
        headline: 'Anträge auf Feststellung',
        subHeadline: 'Bearbeitung',
        path: InvestPathBuilder.home.aufgaben.feststellungBearbeiten.path,
    },
    {
        apiField: 'festsetzungBearbeiten',
        headline: 'Anträge auf Festsetzung',
        subHeadline: 'Bearbeitung',
        path: InvestPathBuilder.home.aufgaben.festsetzungBearbeiten.path,
    },
    {
        apiField: 'p8Abs11Bearbeiten',
        headline: 'Anträge nach § 8 Abs. 11',
        subHeadline: 'Bearbeitung',
        path: InvestPathBuilder.home.aufgaben.p8Abs11Bearbeiten.path,
    },
    {
        apiField: 'feststellungPruefen',
        headline: 'Anträge auf Feststellung prüfen',
        subHeadline: '4-Augen-Prüfung',
        path: InvestPathBuilder.home.aufgaben.feststellungPruefen.path,
    },
    {
        apiField: 'festsetzungPruefen',
        headline: 'Anträge auf Festsetzung prüfen',
        subHeadline: '4-Augen-Prüfung',
        path: InvestPathBuilder.home.aufgaben.festsetzungPruefen.path,
    },
    {
        apiField: 'p8Abs11Pruefen',
        headline: 'Anträge nach § 8 Abs. 11 prüfen',
        subHeadline: '4-Augen-Prüfung',
        path: InvestPathBuilder.home.aufgaben.p8Abs11Pruefen.path,
    },
    {
        apiField: 'feststellungFreigabeAbgelehnt',
        headline: 'Abgelehnte Freigaben Feststellung',
        subHeadline: '4-Augen-Prüfung',
        path: InvestPathBuilder.home.aufgaben.feststellungFreigabeAbgelehnt.path,
    },
    {
        apiField: 'festsetzungFreigabeAbgelehnt',
        headline: 'Abgelehnte Freigaben Festsetzung',
        subHeadline: '4-Augen-Prüfung',
        path: InvestPathBuilder.home.aufgaben.festsetzungFreigabeAbgelehnt.path,
    },
    {
        apiField: 'p8Abs11FreigabeAbgelehnt',
        headline: 'Abgelehnte Freigaben nach § 8 Abs. 11',
        subHeadline: '4-Augen-Prüfung',
        path: InvestPathBuilder.home.aufgaben.p8Abs11FreigabeAbgelehnt.path,
    },
    {
        apiField: 'feststellungZurueckgezogen',
        headline: 'Zurückgezogene Anträge auf Feststellung',
        path: InvestPathBuilder.home.aufgaben.feststellungZurueckgezogen.path,
    },
    {
        apiField: 'festsetzungZurueckgezogen',
        headline: 'Zurückgezogene Anträge auf Festsetzung',
        path: InvestPathBuilder.home.aufgaben.festsetzungZurueckgezogen.path,
    },
    {
        apiField: 'p8Abs11Zurueckgezogen',
        headline: 'Zurückgezogene Anträge nach § 8 Abs. 11',
        path: InvestPathBuilder.home.aufgaben.p8Abs11Zurueckgezogen.path,
    },
    {
        apiField: 'meldungenP29',
        headline: 'Meldung nach § 29',
        path: InvestPathBuilder.home.aufgaben.meldungP29.path,
    },
];
