import { ReactElement } from 'react';
import { Typography } from '@mui/material';

import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { ContentContainer } from 'layout/container/ContentContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { CompleteBenutzerdatenForm } from 'pages/Login/components/CompleteBenutzerdatenForm';
import { LoginLinkCheckError } from 'pages/Login/components/LoginLinkCheckError';

import { useLoginLinkCheck } from './hooks/useLoginLinkCheck';

const Index = (): ReactElement => {
    const { benutzerId, error, isLoading } = useLoginLinkCheck();

    return (
        <ContentContainer title="Login">
            <Typography variant="h1">Benutzer vervollständigen</Typography>

            <AppLoaderContainer isLoading={isLoading}>
                {error && <LoginLinkCheckError error={error} />}
                {!error && <CompleteBenutzerdatenForm benutzerId={benutzerId} />}
            </AppLoaderContainer>
        </ContentContainer>
    );
};

export const LoginPage = withSideBar(Index, []);
