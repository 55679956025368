import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { isEqual, pickBy } from 'lodash';
import { MUIDataTableOptions } from 'mui-datatables';

import { backendApiService } from 'api/ApiService';
import { BenutzerTablePreference } from 'api/client';
import { useApiBenutzerTablePreference } from 'api/hooks/useApiClient';
import { GetColumns } from 'components/DataTableHydra/hooks/useTableColumns';
import { useMessageActions } from 'forms/state/useMessages';
import { errorMessage } from 'forms/utils/MessageUtils';

export type TableFilterValue = string[];
export type TableFilters = Record<string, TableFilterValue>;

export type OnFiltersChanged = NonNullable<MUIDataTableOptions['onFilterChange']>;

export type FilterButtonProps = Omit<
    UseTableFiltersResult,
    'filters' | 'initialFilters' | 'onFiltersChanged' | 'updateFilters'
>;

export interface UseTableFiltersResult {
    filters: TableFilters;
    initialFilters: TableFilters;
    onFiltersChanged: OnFiltersChanged;
    saveFilters: () => Promise<void>;
    deleteFilters: () => Promise<void>;
    updateFilters: (filters: TableFilters) => void;
    isFilterDirty: boolean;
    isFilterSaved: boolean;
}

export const useTableFilters = (name: string, getColumns: GetColumns): UseTableFiltersResult => {
    const { addMessage } = useMessageActions();
    const initialFilters = useMemo(() => determineInitialFilters(getColumns), [getColumns]);
    const [filters, setFilters] = useState<TableFilters>(initialFilters);
    const [originalFilters, setOriginalFilters] = useState<BenutzerTablePreference | null>(null);
    const { data, error } = useApiBenutzerTablePreference(name);
    const { search } = useLocation();

    useEffect(() => {
        if (error) addMessage(createErrorMessage());
    }, [error, addMessage]);

    useEffect(() => {
        if (data) setOriginalFilters(data);
    }, [data]);

    useEffect(() => {
        if (originalFilters && search.length === 0) setFilters(originalFilters.value as TableFilters);
    }, [originalFilters, search]);

    const saveFilters = async () => {
        const id = originalFilters?.id;
        if (id) {
            backendApiService
                .putBenutzerTablePreference(id.toString(), {
                    name,
                    value: filters,
                })
                .then((res) => setOriginalFilters(res))
                .catch((e) => {
                    console.error(e);
                    addMessage(errorMessage('Filter konnten nicht gespeichert werden.', { autoCloseSeconds: 8 }));
                });
        } else {
            backendApiService
                .postBenutzerTablePreference({
                    name,
                    value: filters,
                })
                .then((res) => setOriginalFilters(res))
                .catch((e) => {
                    console.error(e);
                    addMessage(errorMessage('Filter konnten nicht gespeichert werden.', { autoCloseSeconds: 8 }));
                });
        }
    };

    const deleteFilters = async () => {
        const id = originalFilters?.id;
        if (id) {
            setFilters({});
            setOriginalFilters(null);
            await backendApiService.deleteBenutzerTablePreference(String(id)).catch((e) => {
                console.error(e);
                addMessage(errorMessage('Filter konnten nicht gelöscht werden.', { autoCloseSeconds: 8 }));
            });
        }
    };

    const resetFilters = () => {
        setFilters({});
    };

    const updateFilter = (key: string | undefined, value: TableFilterValue) => {
        if (!key) return;
        setFilters((filters) => ({ ...filters, [key]: value }));
    };

    const onFiltersChanged: OnFiltersChanged = (changedColumn, filterList, type, changedColumnIndex) => {
        if (type === 'reset') {
            resetFilters();
        } else {
            const name = typeof changedColumn === 'string' ? changedColumn : changedColumn?.name;
            updateFilter(name, filterList[changedColumnIndex]);
        }
    };

    return {
        filters,
        initialFilters,
        onFiltersChanged,
        saveFilters,
        deleteFilters,
        updateFilters: setFilters,
        isFilterSaved: Boolean(originalFilters?.id),
        isFilterDirty: determineFiltersDirty(filters, originalFilters?.value ?? initialFilters),
    };
};

const createErrorMessage = () =>
    errorMessage('Filter konnten nicht geladen werden.', {
        autoCloseSeconds: 10,
    });

const determineInitialFilters = (getColumns: GetColumns): TableFilters => {
    const columns = getColumns({});
    return columns.reduce((acc, column) => {
        if (typeof column === 'string') return acc;
        const filterList = column.options?.filterList ?? [];
        if (!filterList || filterList.length <= 0) return acc;
        return { ...acc, [column.name]: filterList };
    }, {});
};

const determineFiltersDirty = (filters: TableFilters, original: object): boolean => {
    const cleanedFilters = pickBy(filters, (value) => value && value.length > 0);
    const cleanedOriginal = pickBy(original, (value: string[]) => value && value.length > 0);
    return !isEqual(cleanedFilters, cleanedOriginal);
};
