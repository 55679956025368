import { ROLES } from 'constants/roles';
import { InvestPathBuilder } from 'navigation/Paths';
import { menuAntraege } from 'pages/Antraege/AntragConfig';
import { ExterneQuellenConfig } from 'pages/ExterneQuellen/ExterneQuellenConfig';
import { VerwaltungConfig } from 'pages/Verwaltung/VerwaltungConfig';

export interface MenuInterface {
    path?: string;
    name: string;
    divide?: boolean;
    collapse?: boolean;
    roles?: string[];
    menus?: MenuInterface[];
    disabled?: boolean;
    expanded?: boolean;
    tabs?: MenuInterface[];
    padded?: boolean;
}

export interface Step<T> {
    title: string;
    headline?: string;
    state: 'active' | 'disabled';
    step: T;
    children?: Step<T>[];
    component?: React.ReactElement;
}

interface Area {
    name: string;
    menu?: MenuInterface[];
    path?: string;
    roles: string[];
}

export const HeaderConfig: { areas: Area[] } = {
    areas: [
        {
            name: 'Start',
            path: InvestPathBuilder.home.dashboard.path,
            roles: [ROLES.USER],
        },
        {
            name: 'Anträge',
            menu: menuAntraege,
            roles: [ROLES.FACHADMIN, ROLES.SUPPORT, ROLES.LV],
        },
        {
            name: 'Verwaltung',
            menu: VerwaltungConfig.menu,
            roles: [ROLES.FACHADMIN, ROLES.SUPPORT, ROLES.LV, ROLES.ANBIETER, ROLES.MINISTERIUM],
        },
        {
            name: 'Externe Quellen',
            menu: ExterneQuellenConfig.menu,
            roles: [ROLES.FACHADMIN, ROLES.SUPPORT, ROLES.LV, ROLES.ANBIETER, ROLES.MINISTERIUM],
        },
        /*        {
                    name: 'Statistik',
                    path: InvestPathBuilder.home.statistik.path,
                    roles: [ROLES.FACHADMIN, ROLES.SUPPORT, ROLES.LV, ROLES.MINISTERIUM],
                },*/
    ],
};
