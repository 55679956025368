import { AntragType } from 'api/antragTypes';
import { backendApiService } from 'api/ApiService';
import { FeststellungsAntragCollectionResponse, FeststellungsAntragResponse } from 'api/types';
import { InvestPathBuilder } from 'navigation/Paths';
import { useAntragListActions } from 'pages/Antraege/components/useAntragListActions';

export const useFeststellungsAntragListActions = (type: AntragType) => {
    return useAntragListActions<FeststellungsAntragCollectionResponse, FeststellungsAntragResponse>(
        InvestPathBuilder.home.antraege.feststellung,
        backendApiService.workflowFeststellungsantragItem.bind(backendApiService),
        type
    );
};
