import { useMemo } from 'react';
import { cloneDeep } from 'lodash';

import { useAuthHelper } from 'api/auth/useAuthHelper';
import { useApiAllowedCreateRollen } from 'api/hooks/useApiClient';
import { ROLLEN_LABELS } from 'constants/labels';
import { ROLES, ROLLEN } from 'constants/roles';
import { Schema } from 'forms/types/UiSchemaTypes';
import { removeSchemaProperty } from 'forms/utils/SchemaUtils';
import { benutzerBearbeitenSchema } from 'pages/Verwaltung/Benutzer/BenutzerBearbeitenForm/benutzerBearbeitenFormSchemas';

interface UseBenutzerBearbeitenFormSchemaResult {
    isLoading: boolean;
    schema: Schema;
}

export const useBenutzerBearbeitenFormSchema = (rolle: string | undefined): UseBenutzerBearbeitenFormSchemaResult => {
    const { hasRole } = useAuthHelper();
    const { data: allowedRollen, isLoading: isAllowedRollenLoading } = useApiAllowedCreateRollen();
    const rollen = useMemo(() => allowedRollen?.rollen?.split(',') ?? [], [allowedRollen]);
    const schema = useMemo(() => {
        const schema = cloneDeep(benutzerBearbeitenSchema);
        if (!schema.properties) return schema;
        if (hasRole(ROLES.LV) || (rolle !== ROLLEN.LV_SACHBEARBEITER && rolle !== ROLLEN.LV_ADMIN)) {
            removeSchemaProperty(schema, 'landschaftsverband');
        }

        if (hasRole(ROLES.TRAEGERVERBAND) || rolle !== ROLLEN.TRAEGERVERBAND) {
            removeSchemaProperty(schema, 'traegerverband');
        }

        if (hasRole(ROLES.TRAEGER) || rolle !== ROLLEN.TRAEGER) {
            removeSchemaProperty(schema, 'traeger');
        }

        if (rollen?.length) {
            schema.properties.rolle.oneOf = rollen.map((rolle) => ({
                title: ROLLEN_LABELS[rolle],
                const: rolle,
            }));
        }

        return schema;
    }, [hasRole, rolle, rollen]);

    return {
        schema,
        isLoading: Boolean(isAllowedRollenLoading),
    };
};
