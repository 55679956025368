import { useCallback, useState } from 'react';

import { EinrichtungJsonldEinrichtungList } from 'api/client';
import {
    ZustaendigkeitenEinrichtungTableAction,
    ZustaendigkeitenEinrichtungTableActions,
} from 'constants/zustaendigkeitenEinrichtungActions';

export const useZustaendigkeitenEinrichtungListAction = () => {
    const [einrichtung, setEinrichtung] = useState<EinrichtungJsonldEinrichtungList | null>(null);

    const closeAction = () => {
        setEinrichtung(null);
    };

    const handleAction: ZustaendigkeitenEinrichtungTableAction = useCallback(async (action, einrichtung) => {
        const id = einrichtung.id;
        if (!id) return;
        switch (action) {
            case ZustaendigkeitenEinrichtungTableActions.BEARBEITEN:
                setEinrichtung(einrichtung);
                break;
        }
    }, []);

    return { einrichtung, closeAction, handleAction };
};
