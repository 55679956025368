import { useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { BenutzerJsonldEinrichtungRead } from 'api/client';
import { EinrichtungListResponse } from 'api/types';
import { EinrichtungArtFilterOptions } from 'components/DataTableHydra/Filters/EinrichtungArtFilterOptions';
import { EinrichtungStatusFilterOptions } from 'components/DataTableHydra/Filters/EinrichtungStatusFilterOptions';
import { ZustaendigerBenutzerFilterOptions } from 'components/DataTableHydra/Filters/ZustaendigerBenutzerFilterOptions';
import { LvBenutzerOptions } from 'components/DataTableHydra/hooks/useLvBenutzerOptions';
import { GetColumns } from 'components/DataTableHydra/hooks/useTableColumns';
import { EinrichtungTableAction } from 'constants/einrichtungActions';
import { EINRICHTUNG_ART_SHORT_LABELS, EINRICHTUNG_STATUS_LABELS } from 'constants/labels';
import { LinkEinrichtung } from 'elements/LinkEinrichtung';
import { PflegeeinrichtungListActions } from 'pages/Verwaltung/Pflegeeinrichtungen/Uebersicht/PflegeeinrichtungListActions';
import { einrichtungListLinkRender } from 'pages/Verwaltung/Pflegeeinrichtungen/Uebersicht/PflegeeinrichtungListLink';
import { DisplayBenutzer, displayBenutzerName } from 'utilities';
import { rightAlignedColumn } from 'utilities/DataTableHydraUtils';

import { PflegeeinrichtungFilterOptions } from './usePflegeeinrichtungFilterOptions';

export const usePflegeeinrichtungListColumns = (
    zustandigerBenutzerOptions: LvBenutzerOptions,
    handleAction: EinrichtungTableAction,
    filterOptions: PflegeeinrichtungFilterOptions | undefined
): GetColumns<EinrichtungListResponse> => {
    return useCallback(
        (data) => {
            if (!data) return [];

            const aktenzeichen: MUIDataTableColumnDef = {
                name: 'aktenzeichen',
                label: 'Aktenzeichen',
                options: {
                    filter: false,
                },
            };

            const name: MUIDataTableColumnDef = {
                name: 'name',
                label: 'Name der PE',
                options: {
                    filter: false,
                    customBodyRenderLite: (dataIndex: number) => (
                        <LinkEinrichtung
                            id={data['hydra:member'][dataIndex].id}
                            name={data['hydra:member'][dataIndex].name}
                        />
                    ),
                },
            };

            const ort: MUIDataTableColumnDef = {
                name: 'ort',
                label: 'Ort',
                options: {
                    filter: false,
                },
            };

            const art: MUIDataTableColumnDef = {
                name: 'art',
                label: 'Art',
                options: {
                    ...EinrichtungArtFilterOptions,
                    customBodyRender: (value: string): string => EINRICHTUNG_ART_SHORT_LABELS[value] || value,
                },
            };

            const status: MUIDataTableColumnDef = {
                name: 'status',
                label: 'Status',
                options: {
                    ...EinrichtungStatusFilterOptions,
                    customBodyRender: (value: string): string => EINRICHTUNG_STATUS_LABELS[value] || value,
                },
            };

            const zustaendigerBenutzer: MUIDataTableColumnDef = {
                name: 'zustaendigerBenutzer',
                label: 'Zuständig',
                options: {
                    ...ZustaendigerBenutzerFilterOptions(
                        zustandigerBenutzerOptions,
                        filterOptions?.zustaendigerBenutzer
                    ),
                    customBodyRender: (benutzer: BenutzerJsonldEinrichtungRead) =>
                        displayBenutzerName(benutzer as DisplayBenutzer),
                },
            };

            const einrichtungBenutzer: MUIDataTableColumnDef = {
                name: 'einrichtungBenutzer',
                label: 'Nutzer',
                options: {
                    filter: false,
                    sort: false,
                    ...rightAlignedColumn,
                    customBodyRenderLite: (index) => einrichtungListLinkRender(data, index, 'benutzer'),
                },
            };

            const actions: MUIDataTableColumnDef = {
                name: 'actions',
                label: 'Aktion',
                options: {
                    filter: false,
                    sort: false,
                    viewColumns: false,
                    ...rightAlignedColumn,
                    customBodyRenderLite: (index: number) => {
                        return (
                            <PflegeeinrichtungListActions
                                einrichtung={data['hydra:member'][index]}
                                handleAction={handleAction}
                            />
                        );
                    },
                },
            };

            const columns: MUIDataTableColumnDef[] = [
                aktenzeichen,
                name,
                art,
                ort,
                status,
                zustaendigerBenutzer,
                einrichtungBenutzer,
                actions,
            ];

            return columns;
        },
        [handleAction, zustandigerBenutzerOptions, filterOptions]
    );
};
