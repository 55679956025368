import React, { useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { useAuthHelper } from 'api/auth/useAuthHelper';
import { BenutzerListResponse, EinrichtungResponse } from 'api/types';
import { GetColumns } from 'components/DataTableHydra/hooks/useTableColumns';
import { PflegeeinrichtungBenutzerTableAction } from 'constants/benutzerActions';
import { ROLES } from 'constants/roles';
import { BenutzerListAntragsteller } from 'pages/Verwaltung/Benutzer/BenutzerList/BenutzerListAntragsteller';
import { BenutzerListStatusIcon } from 'pages/Verwaltung/Benutzer/BenutzerList/BenutzerListStatusIcon';
import { PflegeeinrichtungBenutzerListActions } from 'pages/Verwaltung/Pflegeeinrichtungen/Uebersicht/Benutzer/PflegeeinrichtungBenutzerListActions';
import { useIsAntragsteller } from 'pages/Verwaltung/Pflegeeinrichtungen/Uebersicht/Benutzer/useIsAntragsteller';
import { displayBenutzerName } from 'utilities';
import { centerAlignedColumn, rightAlignedColumn } from 'utilities/DataTableHydraUtils';

export const usePflegeeinrichtungBenutzerListColumns = (
    einrichtung: EinrichtungResponse,
    handleAction: PflegeeinrichtungBenutzerTableAction
): GetColumns<BenutzerListResponse> => {
    const { allowBenutzerRemove, allowBenutzerRechteEdit, addActions } =
        usePflegeeinrichtungBenutzerActionRights(einrichtung);

    return useCallback(
        (data) => {
            if (!data) return [];

            const name: MUIDataTableColumnDef = {
                name: 'name',
                label: 'Name',
                options: {
                    customBodyRenderLite: (index) => {
                        return displayBenutzerName(data['hydra:member'][index]);
                    },
                },
            };

            const email: MUIDataTableColumnDef = {
                name: 'email',
                label: 'E-Mail',
            };

            const telefon: MUIDataTableColumnDef = {
                name: 'telefon',
                label: 'Telefon',
            };

            const fax: MUIDataTableColumnDef = {
                name: 'fax',
                label: 'Fax',
            };

            const status: MUIDataTableColumnDef = {
                name: 'status',
                label: 'Status',
                options: {
                    ...centerAlignedColumn,
                    customBodyRenderLite: (index) => {
                        return <BenutzerListStatusIcon benutzer={data['hydra:member'][index]} />;
                    },
                },
            };

            const antragsteller: MUIDataTableColumnDef = {
                name: 'isAntragsteller',
                label: 'Antragsteller',
                options: {
                    ...rightAlignedColumn,
                    customBodyRenderLite: (index) => {
                        return (
                            <BenutzerListAntragsteller
                                benutzer={data['hydra:member'][index]}
                                einrichtungBenutzer={einrichtung.einrichtungBenutzer ?? []}
                            />
                        );
                    },
                },
            };

            const actions: MUIDataTableColumnDef = {
                name: 'actions',
                label: 'Aktion',
                options: {
                    filter: false,
                    sort: false,
                    ...rightAlignedColumn,
                    customBodyRenderLite: (index) => {
                        return (
                            <PflegeeinrichtungBenutzerListActions
                                benutzer={data['hydra:member'][index]}
                                einrichtung={einrichtung}
                                handleAction={handleAction}
                                allowBenutzerRechteEdit={allowBenutzerRechteEdit}
                                allowBenutzerRemove={allowBenutzerRemove}
                            />
                        );
                    },
                },
            };

            let columns = [name, email, telefon, fax, status, antragsteller];
            if (addActions) {
                columns = [...columns, actions];
            }
            return columns;
        },
        [handleAction, addActions, allowBenutzerRechteEdit, allowBenutzerRemove, einrichtung]
    );
};

interface DetermineActionRightsResult {
    allowBenutzerRechteEdit: boolean;
    allowBenutzerRemove: boolean;
    addActions: boolean;
}

const usePflegeeinrichtungBenutzerActionRights = (einrichtung: EinrichtungResponse): DetermineActionRightsResult => {
    const { hasRole } = useAuthHelper();
    const isBenutzerAntragsteller = useIsAntragsteller(einrichtung);

    const allowBenutzerRechteEdit = Boolean(
        hasRole([ROLES.FACHADMIN, ROLES.SUPPORT]) || (hasRole([ROLES.ANBIETER]) && isBenutzerAntragsteller)
    );

    const allowBenutzerRemove = Boolean(
        hasRole([ROLES.FACHADMIN, ROLES.SUPPORT]) || (hasRole([ROLES.ANBIETER]) && isBenutzerAntragsteller)
    );

    return {
        allowBenutzerRemove,
        allowBenutzerRechteEdit,
        addActions: allowBenutzerRechteEdit || allowBenutzerRemove,
    };
};
