import { customVerticalLayout } from 'forms/AntragForm/ui-schemas/elements/customVerticalLayout';
import { formDialog } from 'forms/AntragForm/ui-schemas/elements/formDialog';
import { listControlWithModalForm } from 'forms/AntragForm/ui-schemas/elements/listControlWithModalForm';
import { tableColumn, tableColumnDate, tableColumnDecimal } from 'forms/AntragForm/ui-schemas/elements/tableColumn';
import { zuschuesse } from 'forms/AntragForm/ui-schemas/groups/zuschuesse';
import { GroupControlType } from 'forms/types/UiSchemaTypes';

import { platzzahlreduzierungenSalg } from './aufwandSalg/platzzahlreduzierungen';

const dialogUISchema = formDialog(
    'Neue Maßnahme',
    'Maßnahme',
    {
        labelScope: '#/properties/field200',
    },
    customVerticalLayout([
        {
            type: 'Group',
            scope: '#/properties/salgImEigentum',
            elements: [
                {
                    type: 'Control',
                    scope: '#/properties/salgImEigentum/properties/field300',
                },
                {
                    type: 'InlineRowControl',
                    scope: '#/properties/salgImEigentum/properties/field302',
                    elements: [
                        {
                            type: 'Control',
                            scope: '#/properties/salgImEigentum/properties/field302',
                            controlOnly: true,
                        },
                        {
                            type: 'Control',
                            scope: '#/properties/salgImEigentum/properties/field302NichtBekannt',
                            controlOnly: true,
                        },
                    ],
                },
                zuschuesse('#/properties/salgImEigentum/properties/zuschuesse', { noSeparator: true }),
            ],
        } as GroupControlType,
        {
            type: 'Group',
            label: 'SALG im Leasing',
            scope: '#/properties/salgImLeasing',
            elements: [
                {
                    type: 'FormExtendButton',
                    label: 'SALG im Leasing hinzufügen',
                    scope: '#/properties/salgImLeasing',
                    uiSchema: {
                        type: 'CustomVerticalLayout',
                        elements: [
                            {
                                type: 'Control',
                                scope: '#/properties/field301',
                            },
                            {
                                type: 'Control',
                                scope: '#/properties/field303',
                            },
                            {
                                type: 'Control',
                                scope: '#/properties/field0799',
                            },
                        ],
                    },
                },
            ],
        },
        {
            type: 'Group',
            elements: [
                {
                    type: 'Control',
                    scope: '#/properties/field0726',
                },
                {
                    type: 'Control',
                    scope: '#/properties/field308',
                },

                {
                    type: 'Control',
                    scope: '#/properties/field0712',
                },
                {
                    type: 'Control',
                    scope: '#/properties/field309',
                },
                {
                    type: 'Control',
                    scope: '#/properties/field0713',
                },
                {
                    type: 'Control',
                    scope: '#/properties/field0714',
                },
            ],
        },
        platzzahlreduzierungenSalg,
        {
            type: 'Group',
            scope: '#/properties/field0719',
            elements: [
                {
                    type: 'Control',
                    scope: '#/properties/field0719',
                },
            ],
        },
    ])
);

export const uiSchema = {
    type: 'CustomVerticalLayout',
    elements: [
        {
            type: 'Group',
            label: 'Aufwand SALG',
            options: { noSeparator: true },
            elements: [
                listControlWithModalForm(
                    ['#/properties/massnahmenAufwandSalg'],
                    [
                        tableColumn('Art', {
                            scope: '#/properties/field200',
                        }),
                        tableColumn('Maßnahme', {
                            scope: '#/properties/field201',
                        }),
                        tableColumnDate('Inbetriebnahme', {
                            scope: '#/properties/field0713',
                        }),
                        tableColumnDecimal('Anerkannter Aufwand', {
                            scope: '#/properties/field0712',
                        }),
                        tableColumnDecimal('Anteil SALG', {
                            scope: '#/properties/field0712pa',
                        }),
                        {
                            type: 'Control',
                            label: 'Bearbeitet',
                            scope: '#/properties/aufwandSalgBearbeitet',
                            styles: {
                                textAlign: 'right',
                            },
                        },
                    ],
                    ['Maßnahme hinzufügen'],
                    ['Maßnahme löschen'],
                    ['Wollen Sie diese Maßnahme mit allen Angaben wirklich aus Ihrem Antrag löschen?'],
                    [dialogUISchema],
                    {}
                ),
            ],
        },
        {
            type: 'Group',
            label: 'Anzuerkennender Gesamtbetrag und Begründung Platzzahlreduzierung',
            options: {
                noSeparator: true,
            },
            elements: [
                {
                    type: 'HighlightedArea',
                    elements: [
                        { type: 'Control', scope: '#/properties/field0720' },
                        { type: 'Control', scope: '#/properties/field0721' },
                    ],
                },
            ],
        },
    ],
};
