import React, { ReactElement, useCallback, useMemo } from 'react';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';

import { useApiAngemessenheitsgrenzeCollection } from 'api/hooks/useApiClient';
import { UpdateableFormTable } from 'components/FormModal/UpdateableFormTable';
import { DezimalzahlEuro as Betrag } from 'elements/DezimalzahlEuro';
import { FormState, Schema } from 'forms/types/UiSchemaTypes';
import { ContentContainer } from 'layout/container/ContentContainer';
import { withSideBar } from 'layout/hooks/useSideBar';

const Gesbervo1996Teilstationaer: React.FC = (): ReactElement => {
    const typ = 'GES_BER_VO_1996_TEILSTATIONAER';

    const formDataInitial = useMemo(() => {
        return {
            jahr: new Date().getFullYear(),
            value: 0,
            typ,
        };
    }, []);

    const convertFormData = useCallback((formData: FormState) => {
        formData.value = '' + formData.value;
        return formData;
    }, []);

    const columns: MUIDataTableColumnDef[] = [
        {
            name: 'jahr',
            label: 'Jahr',
        },
        {
            name: 'value',
            label: 'Wert in EUR',
            options: {
                customBodyRender: Betrag,
            },
        },
    ];

    const options: MUIDataTableOptions = {
        sortOrder: {
            name: 'jahr',
            direction: 'desc',
        },
        filter: false,
    };

    const schema: Schema = {
        required: ['jahr', 'value'],
        properties: {
            jahr: {
                type: 'integer',
                title: 'Jahr',
                // @ts-ignore
                custom: {
                    block_prefixes: ['jahr'],
                },
            },
            value: {
                type: 'number',
                title: 'Wert in EUR',
                // @ts-ignore
                custom: {
                    block_prefixes: ['betrag'],
                },
            },
        },
    };

    const content = {
        headline: 'Angemessenheitsgrenze GesBerVO 1996 (teilstationär)',
    };

    const table = {
        columns,
        options,
        callback: useApiAngemessenheitsgrenzeCollection,
        callbackType: typ,
    };

    const form = {
        title: 'Angemessenheitsgrenze GesBerVO 1996 (teilstationär) erstellen',
        resetData: formDataInitial,
        converter: convertFormData,
        schema,
        endpoint: 'postAngemessenheitsgrenze',
    };

    return (
        <ContentContainer title="Angemessenheitsgrenze GesBerVO 1996 (teilstationär)">
            <UpdateableFormTable form={form} table={table} content={content} />
        </ContentContainer>
    );
};

export const AngemessenheitsgrenzeGesbervo1996TeilstationaerPage = withSideBar(Gesbervo1996Teilstationaer, []);
