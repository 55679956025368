import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, BoxProps, styled } from '@mui/material';

import { useAuth } from 'api/AuthContext';

import { Footer } from './Footer';
import { Header } from './Header';
import { useSideBar } from './hooks/useSideBar';
import { Sidebar } from './Sidebar';

interface StyledBoxProps extends BoxProps {
    id?: string;
}

const PageContainer = styled(Box)<StyledBoxProps>(
    ({ theme }) => `
    display: flex;
    flex-direction: column;
    padding-top: ${theme.mixins.header.height}px;
`
);

const Content = styled(Box)<StyledBoxProps>(
    ({ theme }) => `
    display: flex;
    overflow: hidden;
    min-height: calc(100vh - ${theme.mixins.header.height}px);
     `
);

const MainContentWrapper = styled(Box)<StyledBoxProps>(
    ({ theme }) => `
    width: calc(100vw - ${theme.mixins.sidebar.width}px);
    padding: 6px 0 30px;
    flex: 1 0 auto;
    position: relative;
    overflow-x:scroll;

    & > .MuiContainer-root {
        min-width: ${theme.mixins.document.width - theme.mixins.sidebar.width}px;
    }
`
);

const SidebarWrapper = styled(Box)<StyledBoxProps>(
    ({ theme }) => `
    width: ${theme.mixins.sidebar.width}px;
    min-width: ${theme.mixins.sidebar.width}px;
    background-color: ${theme.palette.primary.darker};`
);

export const Page: React.FC = () => {
    const { isLoggedIn } = useAuth().jwt;
    const { menus } = useSideBar();
    return (
        <PageContainer id="PageContainer">
            <Header id="PageHeader" />
            <Content id="Content" component="main" role="main">
                {isLoggedIn && (
                    <SidebarWrapper id="SidebarWrapper" component={'aside'}>
                        <Sidebar menus={menus} />
                    </SidebarWrapper>
                )}
                <MainContentWrapper
                    id="MainContent"
                    className={isLoggedIn ? 'ContentContainer--aside' : 'ContentContainer--default'}
                >
                    <Outlet />
                </MainContentWrapper>
            </Content>
            <Footer />
        </PageContainer>
    );
};
