import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/AntragForm/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { groupList } from 'forms/AntragForm/ui-schemas/elements/groupList';
import { info } from 'forms/AntragForm/ui-schemas/elements/info';
import { notes } from 'forms/AntragForm/ui-schemas/groups/notes';

export const uiSchema = customVerticalLayout([
    group([control('field1065')], {}, 'Sonstiges Anlagevermögen und Instandhaltungsaufwendungen'),
    info(
        'Bitte geben Sie hier die tatsächlichen Aufwendungen gemäß § 4 Abs. 4 und § 6 Abs. 1 APG DVO NRW an (sogenannte virtuelle Konten).'
    ),
    info('Der Aufwand für SALG umfasst auch die Instandhaltung und Wartung dieser Anlagegüter.'),
    groupList(
        '#/properties/jahr',
        ['#/properties/aufwandSalg', '#/properties/aufwandInstandhaltung'],
        [control('field520'), control('field520d'), control('field530'), control('field530d')],
        {},
        {
            fieldnumber: 2,
            label: 6,
            input: 3,
        }
    ),
    notes('notizVirtuellesKonto'),
]);
