import * as React from 'react';
import { useCallback } from 'react';
import Info from '@mui/icons-material/Info';
import UpdateIcon from '@mui/icons-material/Update';
import { Button, IconButton, Popover, styled, Typography } from '@mui/material';

import { KEY, useKeyDownCallback } from 'forms/hooks/useKeyDownCallback';
import { useConfirmationDialogActions } from 'forms/state/useConfirmationDialogState';
import { useLoadingActions } from 'forms/state/useLoadingState';
import { useMessageActions } from 'forms/state/useMessages';
import { FormConfig } from 'forms/types/UiSchemaTypes';
import { errorMessage, successMessage } from 'forms/utils/MessageUtils';

interface FormulaControlProps {
    fieldNumber?: string | undefined;
    formula: string;
    config: FormConfig;
    paths: string[];
}

export const FormulaPopover: React.FC<FormulaControlProps> = ({ formula, fieldNumber, config, paths }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const { showConfirmation, closeConfirmation } = useConfirmationDialogActions();
    const { addMessage } = useMessageActions();
    const { showLoading, hideLoading } = useLoadingActions();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRecalculate = () => {
        showConfirmation({
            alertText: `Möchten Sie Feld [${fieldNumber}] neuberechnen?`,
            confirmLabel: 'Ja',
            denyLabel: 'Nein',
            confirmAction: async () => {
                try {
                    showLoading(`Neuberechnung aktiv`);
                    await config.submit(false, paths, false, undefined, true);
                    closeConfirmation();
                    handleClose();
                    addMessage(
                        successMessage(`Der Wert für das Feld [${fieldNumber}] wurde neu berechnet.`, {
                            autoCloseSeconds: 8,
                        })
                    );
                } catch (e) {
                    addMessage(
                        errorMessage(`Feld [${fieldNumber}] konnte nicht neu berechnet werden.`, {
                            autoCloseSeconds: 8,
                        })
                    );
                    console.error(e);
                } finally {
                    hideLoading();
                }
            },
            denyAction: closeConfirmation,
        });
    };

    const open = Boolean(anchorEl);

    const onKeyDown = useKeyDownCallback(
        useCallback((key, e) => {
            handleClose();
            if ([KEY.SPACE, KEY.ENTER].includes(key)) {
                e.stopPropagation();
                e.preventDefault();
                return false;
            }
        }, []),
        [KEY.SPACE, KEY.ENTER, KEY.TAB, KEY.ESCAPE]
    );

    return (
        <>
            <FormulaIconButton onClick={handleClick} size="small" aria-label="Formel anzeigen">
                <FormulaIcon fontSize="small" />
            </FormulaIconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onKeyDown={onKeyDown}
            >
                <FormulaTypography>
                    <b>
                        Formel:
                        <br />
                    </b>{' '}
                    {formula.split('\n').map((item, key) => (
                        <span key={key}>
                            {item}
                            <br />
                        </span>
                    ))}
                    {fieldNumber && (
                        <>
                            <hr />
                            <b>
                                Feld erneut berechnen:
                                <br />
                            </b>
                            Das Feld [{fieldNumber}] wird erneut berechnet.
                            <br />
                            <Button variant="text" startIcon={<UpdateIcon />} onClick={handleRecalculate}>
                                Feld neu berechnen
                            </Button>
                        </>
                    )}
                </FormulaTypography>
            </Popover>
        </>
    );
};

const FormulaIconButton = styled(IconButton)(() => ({
    alignItems: 'center',
    display: 'inline-flex',
    minWidth: 0,
    marginLeft: 4,
    marginTop: -2,
}));

const FormulaIcon = styled(Info)(() => ({
    color: '#006ECB',
}));

const FormulaTypography = styled(Typography)(({ theme }) => ({
    padding: theme.spacing(2),
}));
