import React, { ReactElement } from 'react';
import { Typography } from '@mui/material';

import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { useLvBenutzerOptions } from 'components/DataTableHydra/hooks/useLvBenutzerOptions';
import { ContentContainer } from 'layout/container/ContentContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { withAuthorization } from 'navigation/withAuthorization';
import { PflegeeinrichtungList } from 'pages/Verwaltung/Pflegeeinrichtungen/Uebersicht/PflegeeinrichtungList';
import { VerwaltungConfig } from 'pages/Verwaltung/VerwaltungConfig';

const Index: React.FC = (): ReactElement => {
    const { isLoading: isLoadingLvBenutzer, options: zustandigerBenutzerOptions } = useLvBenutzerOptions();

    return (
        <ContentContainer title="Pflegeeinrichtungen">
            <AppLoaderContainer isLoading={Boolean(isLoadingLvBenutzer)}>
                <Typography variant="h1">Pflegeeinrichtungen</Typography>
                <PflegeeinrichtungList zustandigerBenutzerOptions={zustandigerBenutzerOptions} />
            </AppLoaderContainer>
        </ContentContainer>
    );
};

export const PflegeeinrichtungenPage = withAuthorization(
    withSideBar(Index, VerwaltungConfig.menu),
    VerwaltungConfig.roles.pfelgeeinrichtung
);
