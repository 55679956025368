import React from 'react';
import { Typography, TypographyVariant, useTheme } from '@mui/material';
import { uniqBy } from 'lodash';

import { ComponentError } from 'forms/hooks/useErrors';

interface ErrorListProps {
    errors: ComponentError[];
    variant?: TypographyVariant | 'inherit';
}

export const ErrorList: React.FC<ErrorListProps> = ({ errors, variant = 'inherit' }) => {
    const theme = useTheme();

    const uniqueErrors = uniqBy(errors, (e) => e.message);

    if (uniqueErrors.length === 1) {
        return <ErrorText text={errors[0].message} variant={variant} />;
    }
    return (
        <ul style={{ margin: 0 }}>
            {uniqueErrors.map((e, index) => (
                <li style={{ color: theme.palette.error.main }} key={`${index}`}>
                    <ErrorText text={e.message} variant={variant} />
                </li>
            ))}
        </ul>
    );
};

export const ErrorText: React.FC<{
    text: string;
    variant?: TypographyVariant | 'inherit';
}> = ({ text, variant = 'inherit' }) => {
    return (
        <Typography color={'error'} variant={variant} component={'span'} data-cy={'error_text'}>
            {text}
        </Typography>
    );
};
