import { ctxDispatchToControlProps, useJsonForms } from '@jsonforms/react';

import { FormConfig, FormLayoutConfig } from 'forms/types/UiSchemaTypes';

export const useJsonFormsState = () => {
    const ctx = useJsonForms();
    return {
        config: ctx.config as FormConfig,
        handleChange: ctxDispatchToControlProps(ctx.dispatch as any).handleChange,
    };
};

export const useLayoutWithJsonFormsState = (config: FormLayoutConfig) => {
    const ctx = useJsonForms();

    return {
        ...ctx.config,
        ...config,
    };
};
