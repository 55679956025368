import { Dispatch, SetStateAction, useCallback, useState } from 'react';

type UseAuthContextLandschaftsverbandViewResult = {
    isLandschaftsverbandView: boolean;
    setLandschaftsverbandView: Dispatch<SetStateAction<boolean>>;
    clearLandschaftsverbandView: () => void;
};

export const useAuthContextLandschaftsverbandView = (): UseAuthContextLandschaftsverbandViewResult => {
    const [isLandschaftsverbandView, setLandschaftsverbandView] = useState<boolean>(false);

    const clearLandschaftsverbandView = useCallback(() => setLandschaftsverbandView(false), []);

    return { isLandschaftsverbandView, setLandschaftsverbandView, clearLandschaftsverbandView };
};
