import { RuleEffect } from '@jsonforms/core';

import { BESCHEID_DOKUMENT } from 'constants/bescheidDokument';
import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { customHorizontalLayout } from 'forms/AntragForm/ui-schemas/elements/customHorizontalLayout';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { info } from 'forms/AntragForm/ui-schemas/elements/info';
import { inlineRowControl } from 'forms/AntragForm/ui-schemas/elements/inlineRowControl';

export const uiSchema = {
    type: 'CustomVerticalLayout',
    elements: [
        group([
            control('field0011'),
            group(
                [
                    {
                        type: 'LabelRowControl',
                        text: 'Legen Sie fest, ob ein Dokument automatisiert vom System erzeugt oder manuell von Ihnen zur Verfügung gestellt werden soll. Sobald Sie ein Dokument manuell hochgeladen haben, wird dieses anstelle des vom System erzeugten Dokuments versendet.<br /><br />Durck Klick auf "PDF" oder "DOCX" laden Sie das vom System generierte Dokument im jeweilgen Format herunter.',
                    },
                ],
                { noSeparator: true },
                'Versandart festlegen und Dokumente herunterladen'
            ),
            group(
                [
                    customHorizontalLayout(
                        [
                            inlineRowControl([
                                {
                                    type: 'LabelRowControl',
                                    text: 'Dokument',
                                    styles: {
                                        fontWeight: 'bold',
                                    },
                                },
                                {
                                    type: 'LabelRowControl',
                                    text: 'Art des Versands',
                                    styles: {
                                        fontWeight: 'bold',
                                    },
                                },
                                {
                                    type: 'LabelRowControl',
                                    text: 'Systemdokument zur Ansicht herunterladen',
                                    styles: {
                                        fontWeight: 'bold',
                                    },
                                },
                            ]),

                            group([
                                inlineRowControl(
                                    [
                                        control('field0900', {
                                            controlOnly: true,
                                        }),
                                        {
                                            type: 'BescheidDokumentGenerierenLink',
                                            scope: '#/properties/field0900',
                                            href:
                                                'files/feststellungsantrag/{antragId}/dokument/' +
                                                BESCHEID_DOKUMENT.BESCHEID,
                                        },
                                    ],
                                    '#/properties/field0900',
                                    {
                                        gridLayout: {
                                            fieldnumber: 1,
                                            label: 3,
                                            input: 8,
                                        },
                                    }
                                ),
                                customHorizontalLayout(
                                    [
                                        control('field0900Upload', {
                                            controlOnly: true,
                                        }),
                                    ],
                                    {
                                        gridSize: 3,
                                        spacing: 2,
                                        style: { paddingLeft: '8.333333%' },
                                    }
                                ),
                            ]),

                            group([
                                inlineRowControl(
                                    [
                                        control('field0900a', {
                                            controlOnly: true,
                                        }),
                                    ],
                                    '#/properties/field0900a',
                                    {
                                        gridLayout: {
                                            fieldnumber: 1,
                                            label: 3,
                                            input: 8,
                                        },
                                    }
                                ),
                                customHorizontalLayout(
                                    [
                                        control('field0900aUpload', {
                                            controlOnly: true,
                                        }),
                                    ],
                                    {
                                        gridSize: 3,
                                        spacing: 2,
                                        style: { paddingLeft: '8.333333%' },
                                    }
                                ),
                            ]),

                            group([
                                inlineRowControl(
                                    [
                                        control('field0901', {
                                            controlOnly: true,
                                        }),
                                        {
                                            type: 'BescheidDokumentGenerierenLink',
                                            scope: '#/properties/field0901',
                                            href:
                                                'files/feststellungsantrag/{antragId}/dokument/' +
                                                BESCHEID_DOKUMENT.ANLAGE_AENDERUNG_STAMM_ANTRAGSDATEN,
                                        },
                                    ],
                                    '#/properties/field0901',
                                    {
                                        gridLayout: {
                                            fieldnumber: 1,
                                            label: 3,
                                            input: 8,
                                        },
                                    }
                                ),
                                customHorizontalLayout(
                                    [
                                        control('field0901Upload', {
                                            controlOnly: true,
                                        }),
                                    ],
                                    {
                                        gridSize: 3,
                                        spacing: 2,
                                        style: { paddingLeft: '8.333333%' },
                                    }
                                ),
                            ]),

                            group([
                                inlineRowControl(
                                    [
                                        control('field0902', {
                                            controlOnly: true,
                                        }),
                                        {
                                            type: 'BescheidDokumentGenerierenLink',
                                            href:
                                                'files/feststellungsantrag/{antragId}/dokument/' +
                                                BESCHEID_DOKUMENT.ANLAGE_RESTWERTERMITTLUNG,
                                            scope: '#/properties/field0902',
                                        },
                                    ],
                                    '#/properties/field0902',
                                    {
                                        gridLayout: {
                                            fieldnumber: 1,
                                            label: 3,
                                            input: 8,
                                        },
                                    }
                                ),
                                customHorizontalLayout(
                                    [
                                        control('field0902Upload', {
                                            controlOnly: true,
                                        }),
                                    ],
                                    {
                                        gridSize: 3,
                                        spacing: 2,
                                        style: { paddingLeft: '8.333333%' },
                                    }
                                ),
                            ]),

                            group([
                                inlineRowControl(
                                    [
                                        control('field0903', {
                                            controlOnly: true,
                                        }),
                                        {
                                            type: 'BescheidDokumentGenerierenLink',
                                            scope: '#/properties/field0903',
                                            href:
                                                'files/feststellungsantrag/{antragId}/dokument/' +
                                                BESCHEID_DOKUMENT.ANLAGE_BERECHNUNG_SALG,
                                        },
                                    ],
                                    '#/properties/field0903',
                                    {
                                        gridLayout: {
                                            fieldnumber: 1,
                                            label: 3,
                                            input: 8,
                                        },
                                    }
                                ),
                                customHorizontalLayout(
                                    [
                                        control('field0903Upload', {
                                            controlOnly: true,
                                        }),
                                    ],
                                    {
                                        gridSize: 3,
                                        spacing: 2,
                                        style: { paddingLeft: '8.333333%' },
                                    }
                                ),
                            ]),
                        ],
                        {
                            gridSize: 12,
                            spacing: 2,
                            style: {},
                        }
                    ),
                ],
                { noSeparator: true }
            ),
            group(
                [
                    control('field0900p'),
                    info(
                        'Es wurde kein automatisierter Versand gewählt. <b>Der Antragsteller wird <u>nicht</u> über die Bescheidung informiert.</b><br>Bevor Sie den Bescheid und die dazugehörigen Dokumente herunterladen können, muss ein Bescheiddatum auf der Startseite im Bereich Bescheidversand gesetzt werden.',
                        {
                            rule: {
                                effect: RuleEffect.SHOW,
                                condition: {
                                    // @ts-ignore
                                    scope: '#/properties/field0900p',
                                    schema: {
                                        const: true,
                                    },
                                },
                            },
                        }
                    ),
                ],
                { noSeparator: true }
            ),
        ]),
    ],
};
