import React from 'react';

import { AntragType } from 'api/antragTypes';
import { AntragResponse } from 'api/types';
import { DataTableHydraServerside } from 'components/DataTableHydra/DataTableHydraServerside';
import { AntragDetailsVersionDialog } from 'pages/Antraege/components/AntragDetailsVersionDialog';
import { useAntragVersionListAction } from 'pages/Antraege/components/useAntragVersionListAction';
import { useAntragVersionListColumns } from 'pages/Antraege/components/useAntragVersionListColumns';
import { useAntragVersionListData } from 'pages/Antraege/components/useAntragVersionListData';

interface AntragDetailsVersionListProps {
    type: AntragType;
    antrag: AntragResponse;
}

export const AntragDetailsVersionList = ({ type, antrag }: AntragDetailsVersionListProps) => {
    const { initialTab, feststellungVersion, closeAction, handleAction } = useAntragVersionListAction(type);
    const getData = useAntragVersionListData(type, antrag);
    const getColumns = useAntragVersionListColumns(handleAction, antrag);

    return (
        <>
            <DataTableHydraServerside
                getColumns={getColumns}
                getData={getData}
                initialOptions={{
                    viewColumns: false,
                    filter: false,
                    print: false,
                    download: false,
                    search: false,
                    sortOrder: {
                        name: 'antragModifiedAt',
                        direction: 'desc',
                    },
                }}
            />
            {feststellungVersion && (
                <AntragDetailsVersionDialog
                    type={type}
                    antrag={antrag}
                    version={feststellungVersion}
                    onClose={closeAction}
                    initialTab={initialTab}
                />
            )}
        </>
    );
};
