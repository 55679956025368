import { MUIDataTableColumnOptions } from 'mui-datatables';

import { AutocompleteFilter } from 'components/DataTableHydra/Filters/AutocompleteFilter';
import { LvBenutzerOptions } from 'components/DataTableHydra/hooks/useLvBenutzerOptions';

export const ZustaendigerBenutzerFilterOptions = (
    zustandigerBenutzerFilterOptions: LvBenutzerOptions,
    filterOptions?: LvBenutzerOptions
): MUIDataTableColumnOptions => ({
    customFilterListOptions: {
        render: (value) =>
            `Zuständig: ${filterOptions ? filterOptions[value] : zustandigerBenutzerFilterOptions[value]}`,
    },
    filterOptions: {
        names: Object.keys(filterOptions ? filterOptions : zustandigerBenutzerFilterOptions),
        display: (filterList, onChange, index, column, filterData) => (
            <AutocompleteFilter
                index={index}
                column={column}
                filterData={filterData[index]}
                filterValue={filterList[index][0]}
                filterLabels={zustandigerBenutzerFilterOptions}
                onChange={onChange}
            />
        ),
    },
    filterType: 'custom',
});
