import { ControlElement } from '@jsonforms/core';
import { GridSize } from '@mui/material';

import { GridLayoutConfig, UiSchemaOptions } from 'forms/types/UiSchemaTypes';

interface ControlOptions extends UiSchemaOptions {
    controlOnly?: boolean;
    gridLayout?: GridLayoutConfig;
    gridSize?: GridSize;
    showFieldNumberLabels?: boolean;
    options?: UiSchemaOptions;
}

export const control = (field: string, options: ControlOptions = {}): ControlElement => {
    return {
        type: 'Control',
        scope: `#/properties/${field}`,
        ...options,
    };
};
