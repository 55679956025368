import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/AntragForm/ui-schemas/elements/customVerticalLayout';
import { formDialog } from 'forms/AntragForm/ui-schemas/elements/formDialog';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { labeled } from 'forms/AntragForm/ui-schemas/elements/labeled';
import { listControlWithModalForm } from 'forms/AntragForm/ui-schemas/elements/listControlWithModalForm';
import { tableColumn, tableColumnDate, tableColumnDecimal } from 'forms/AntragForm/ui-schemas/elements/tableColumn';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';
import { zuschuesse } from 'forms/AntragForm/ui-schemas/groups/zuschuesse';

const dialogUISchema = formDialog(
    'Neue Maßnahme',
    'Maßnahme',

    labeled({
        labelScope: '#/properties/field200',
    }),
    customVerticalLayout([
        group([
            control('field200'),
            control('field201'),
            control('field202'),
            control('field210'),
            control('field246'),
            control('field0899'),
            control('field0810'),
            control('field247'),
            control('field220'),
            control('field226'),
            control('field213'),
        ]),
        zuschuesse(),
    ])
);

export const uiSchema = verticalLayout([
    group([
        listControlWithModalForm(
            ['#/properties/massnahmenModernisierungP8Abs16'],
            [
                tableColumn('Art', {
                    scope: '#/properties/field200',
                }),
                tableColumn('Maßnahme', {
                    scope: '#/properties/field201',
                }),
                tableColumnDate('Inbetriebnahme', {
                    scope: '#/properties/field202',
                }),
                tableColumnDecimal(
                    'Anerkannter Gesamtaufwand',
                    {
                        scope: '#/properties/field0810',
                    },
                    {
                        unit: '€',
                    }
                ),
                tableColumn('Bearbeitet', {
                    scope: '#/properties/modernisierungBearbeitet',
                }),
            ],
            ['Maßnahme hinzufügen'],
            ['Maßnahme löschen'],
            ['Wollen Sie diese Maßnahme mit allen Angaben wirklich aus Ihrem Antrag löschen?'],
            [dialogUISchema]
        ),
    ]),
]);
