import React, { ReactElement } from 'react';
import { RankedTester, rankWith } from '@jsonforms/core';
import { JsonForms } from '@jsonforms/react';
import { Button, Grid, GridSize, styled } from '@mui/material';
import { isEqual } from 'lodash';

import { RowControl } from 'forms/controls/RowControl';
import { CustomControlOnChange, CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';
import { renderers } from 'forms/renderers';
import {
    FormConfig,
    FormStateChange,
    FormStateValue,
    InlineGroupRowActionOptions,
    InlineGroupRowControlType,
    Schema,
    UiSchemaType,
} from 'forms/types/UiSchemaTypes';
import { buildPath, determineSchemaByUUID } from 'forms/utils/SchemaUtils';

import { useInlineGroupRowControl } from './hooks/useInlineGroupRowControl';

interface InlineGroupRowControlProps extends Omit<CustomControlProps, 'uischema'> {
    uischema: InlineGroupRowControlType;
}

const InlineGroupRowControlComponent = ({
    schema,
    uischema,
    data,
    handleChange,
    config,
}: InlineGroupRowControlProps) => {
    const { collectionField, collectionData, collectionSchema, fieldName, fieldLabel, fieldFormula, isRequiredField } =
        useInlineGroupRowControl(data, uischema, schema);

    if (collectionData.length === 0) {
        return <></>;
    }

    const calculatedGridSize = uischema.gridSize ?? 12 / collectionData.length;
    const paths = collectionData.map((_, index) => `${collectionField}[${index}].${fieldName}`);
    return (
        <RowControl
            name={fieldName}
            label={fieldLabel}
            required={isRequiredField}
            showFieldNumberLabels={config.showFieldNumberLabels}
            gridLayout={uischema.gridLayout || config.gridLayout}
            formula={fieldFormula}
            config={config}
            paths={paths}
            rowAction={
                uischema.action ? (
                    <InlineGroupRowControlAction
                        action={uischema.action}
                        data={collectionData}
                        fieldName={fieldName}
                        collectionField={collectionField}
                        onChange={handleChange}
                        schema={collectionSchema}
                    />
                ) : undefined
            }
        >
            <Grid container spacing={2}>
                {collectionData.map((d: FormStateValue, i: number) => {
                    return (
                        <InlineGroupFormControl
                            key={i}
                            index={i}
                            data={d}
                            schema={determineSchemaByUUID(d.uuid, collectionSchema)}
                            gridSize={calculatedGridSize}
                            scope={uischema.rowScope}
                            highlighted={i % 2 !== 0}
                            collectionField={collectionField}
                            config={config}
                            onChange={handleChange}
                        />
                    );
                })}
            </Grid>
        </RowControl>
    );
};

const InlineGroupFormControl: React.FC<{
    index: number;
    data: FormStateValue;
    collectionField: string;
    scope: string;
    gridSize: GridSize;
    schema: Schema;
    highlighted: boolean;
    config: FormConfig;
    onChange: CustomControlOnChange;
}> = ({ index, data, collectionField, scope, gridSize, schema, highlighted, config, onChange }) => {
    const onStateChange = (state: FormStateChange) => {
        if (isEqual(state.data, data)) return;
        onChange(buildPath(collectionField, index), state.data);
    };

    const GridItem = highlighted ? HighlightedGrid : Grid;
    return (
        <GridItem item xs={12} md={gridSize}>
            <JsonForms
                schema={schema}
                uischema={{ type: 'Control', scope, controlOnly: true } as UiSchemaType}
                data={data}
                renderers={renderers}
                onChange={onStateChange}
                config={{ ...config, path: buildPath(config.path, collectionField, index) }}
            />
        </GridItem>
    );
};

type InlineGroupRowControlActionProps = {
    action: InlineGroupRowActionOptions;
    data: FormStateValue[];
    fieldName: string;
    collectionField: string;
    onChange: CustomControlOnChange;
    schema: Schema;
};

const InlineGroupRowControlAction = ({
    action: { label, value },
    data,
    fieldName,
    collectionField,
    onChange,
    schema,
}: InlineGroupRowControlActionProps): ReactElement => {
    const onAction = () => {
        onChange(
            collectionField,
            data.map((d) => ({ ...d, [fieldName]: value })),
            true,
            schema?.custom?.model_path
        );
    };

    return <Button onClick={onAction}>{label}</Button>;
};

export const HighlightedGrid = styled(Grid)(
    ({ theme }) => `
    background: ${theme.palette.background.surfaceHighlighted};`
);

export const InlineGroupRowControl = withCustomControlProps(InlineGroupRowControlComponent);

export const InlineGroupRowControlTester: RankedTester = rankWith(
    1,
    (uischema) => uischema.type === 'InlineGroupRowControl'
);
