import { useCallback, useEffect, useState } from 'react';

import { backendApiService } from 'api/ApiService';
import { LvBenutzerOptions } from 'components/DataTableHydra/hooks/useLvBenutzerOptions';

export interface PflegeeinrichtungFilterOptions {
    zustaendigerBenutzer: LvBenutzerOptions;
}

export const usePflegeeinrichtungFilterOptions = (): PflegeeinrichtungFilterOptions | undefined => {
    const [filter, setFilter] = useState<PflegeeinrichtungFilterOptions | undefined>();

    const loadFilters = useCallback(async () => {
        return await backendApiService.getEinrichtungFilter();
    }, []);

    useEffect(() => {
        loadFilters().then((res) => {
            const filters = res.filters.reduce(
                (a, v) => ({
                    ...a,
                    [v.field as string]: v.values,
                }),
                {} as PflegeeinrichtungFilterOptions
            );
            setFilter(filters);
        });
    }, [loadFilters]);

    return filter;
};
