import React, { ReactElement, useEffect, useState } from 'react';
import { Visibility } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';

import { backendApiService } from 'api/ApiService';
import { InlineResponse } from 'api/responses/InlineResponse';
import { DataTableHydra } from 'components/DataTableHydra/DataTableHydra';
import { formatDateToString } from 'forms/utils/formatter';
import { Ansehen } from 'pages/Verwaltung/Massenmail/Ansehen';

interface DataTableMassenmailProps {
    title?: string;
    rerender?: boolean;
    rerenderHandler?: (value: boolean) => void;
}

const options: MUIDataTableOptions = {
    sortOrder: {
        name: 'nrwKey',
        direction: 'desc',
    },
    filter: false,
};

export const DataTable: React.FC<DataTableMassenmailProps> = ({
    title,
    rerender,
    rerenderHandler,
}: DataTableMassenmailProps): ReactElement => {
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<InlineResponse>();
    const [massenmailId, setMassenmailId] = useState<string>();

    useEffect(() => {
        if (!data || (rerender && rerenderHandler))
            backendApiService.getMassenmailCollection().then((data) => {
                setData(data);
                setIsLoading(false);

                if (rerenderHandler) {
                    rerenderHandler(false);
                }
            });
    }, [data, isLoading, setData, setIsLoading, rerender, rerenderHandler]);

    const renderStatus = (dataIndex: number): string => {
        let countGesendet = 0;
        let countTotal = 0;

        if (data && data['hydra:member'][dataIndex]) {
            const massenmail = data['hydra:member'][dataIndex];

            countGesendet = massenmail.countEmpfaengerGesendet;
            countTotal = massenmail.countEmpfaenger;
        }

        return `${countGesendet} von ${countTotal} gesendet`;
    };

    const columns: MUIDataTableColumnDef[] = [
        {
            name: 'createdAt',
            label: 'Datum',
            options: {
                customBodyRender: (createdAt: string | undefined) =>
                    createdAt ? formatDateToString(new Date(createdAt)) : '-',
            },
        },
        {
            name: 'createdBy',
            label: 'Absender',
            options: {
                customBodyRender: (createdBy) => createdBy.nachname + ', ' + createdBy.vorname,
            },
        },
        {
            name: 'betreff',
            label: 'Betreff',
        },
        {
            name: 'status',
            label: 'Status',
            options: {
                customBodyRenderLite: renderStatus,
            },
        },
        {
            name: 'id',
            label: 'Aktion',
            options: {
                customBodyRender: (id: string) => (
                    <Tooltip title="Mailing ansehen">
                        <Button
                            aria-haspopup="dialog"
                            variant="text"
                            onClick={() => {
                                setMassenmailId(id);
                                setShowDetails(true);
                            }}
                        >
                            <Visibility />
                        </Button>
                    </Tooltip>
                ),
            },
        },
    ];

    return (
        <>
            <DataTableHydra title={title} columns={[...columns]} data={data} options={options} isLoading={isLoading} />
            {massenmailId && (
                <Ansehen
                    open={showDetails}
                    handleClose={() => {
                        setMassenmailId(undefined);
                        setShowDetails(false);
                    }}
                    massenmailId={massenmailId}
                />
            )}
        </>
    );
};
