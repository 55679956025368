import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/AntragForm/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';

export const uiSchema = customVerticalLayout([
    group([
        control('field1786'),
        control('field1786kv'),
        control('field1804a'),
        control('field1809'),
        control('field1810'),
        control('field1810kv'),
        control('field1811'),
        control('field1812'),
        control('field1813'),
        control('field1814'),
        control('field1813a'),
        control('field1814a'),
        control('field1815'),
        control('field1816'),
        control('field1817'),
        control('field1818'),
        control('field1818a'),
        control('field1819'),
        control('field1820'),
        control('field1821'),
        control('field2022'),
        control('field2020'),
        control('field2021'),
        control('field1822'),
    ]),
]);
