import React, { useState } from 'react';
import { Alert, Box, Collapse, IconButton } from '@mui/material';

import { IconExpand } from 'elements/IconExpand';
import { ErrorList, ErrorText } from 'forms/controls/components/ErrorList';
import { Message } from 'forms/state/useMessages';

export const MultiErrorMessageAlert: React.FC<{ messages: Message[] }> = ({ messages }) => {
    const [expanded, setExpanded] = useState(true);
    return (
        <Alert severity={'error'}>
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                }}
            >
                <Box>
                    <Collapse in={expanded}>
                        <Box>
                            <ErrorList
                                errors={messages.map((m) => ({
                                    message: m.text,
                                    path: '',
                                }))}
                            />
                        </Box>
                    </Collapse>
                    <Collapse in={!expanded}>
                        <Box onClick={() => setExpanded(!expanded)} style={{ cursor: 'pointer' }}>
                            <ErrorText text={`${messages.length} Fehler`} />
                        </Box>
                    </Collapse>
                </Box>
                {messages.length > 1 && (
                    <IconButton size="small" onClick={() => setExpanded(!expanded)} aria-label="Fehler anzeigen">
                        <IconExpand size="small" open={expanded} />
                    </IconButton>
                )}
            </Box>
        </Alert>
    );
};
