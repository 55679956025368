import React, { useEffect, useState } from 'react';
import { Chip, styled } from '@mui/material';
import globalAxios from 'axios';

export const StyledChip = styled(Chip)(
    () => `
  color: white;
  font-weight: bold;
  background: darkred;
`
);

export const VersionTag: React.FC = () => {
    const [version, setVersion] = useState<string>('v0.1.0-rc1');

    useEffect(() => {
        globalAxios.get('/version.txt').then((response) => setVersion(response.data));
    }, []);

    return <>{version && <StyledChip size="small" label={version} />}</>;
};
