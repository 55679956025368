import * as React from 'react';
import { SetStateAction, useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { FormControl, OutlinedInput, styled } from '@mui/material';

import { RowControl } from 'forms/controls/RowControl';
import { useJsonFormsState } from 'forms/hooks/useJsonFormsState';
import { GridLayoutConfig } from 'forms/types/UiSchemaTypes';

interface PasswordInputProps {
    path: string;
    label: string;
    value: string;
    handleChange: React.Dispatch<SetStateAction<string>>;
    disabled: boolean;
    required?: boolean;
    gridLayout?: GridLayoutConfig;
    id?: string;
}

export const PasswordInput: React.FC<PasswordInputProps> = ({
    path,
    label,
    value,
    disabled,
    handleChange,
    required,
    gridLayout,
    id,
}) => {
    const { config } = useJsonFormsState();
    const [show, setShow] = useState(false);

    const handleToggleShow = () => {
        setShow(!show);
    };

    return (
        <RowControl
            name={path}
            label={label}
            required={required}
            gridLayout={gridLayout}
            labelForId={id}
            config={config}
            paths={[path]}
        >
            <FormControl fullWidth>
                <InputField
                    id={id}
                    autoComplete={'new-password'}
                    fullWidth
                    value={value}
                    type={!show ? 'password' : 'text'}
                    required={required}
                    disabled={disabled}
                    inputProps={{ 'data-cy': `form_${path}` }}
                    onChange={(event) => handleChange(event.target.value)}
                    endAdornment={
                        !show ? (
                            <VisibilityIcon onClick={handleToggleShow} />
                        ) : (
                            <VisibilityOffIcon onClick={handleToggleShow} />
                        )
                    }
                    margin={'dense'}
                />
            </FormControl>
        </RowControl>
    );
};

const InputField = styled(OutlinedInput)(() => ({
    '& input': {
        padding: '8.5px 14px;',
    },
}));
