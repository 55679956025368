import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormControl, Grid, Typography } from '@mui/material';

import { FormButton } from 'forms/components/FormButton';
import { InvestPathBuilder } from 'navigation/Paths';

export const SuccessMessage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <>
            <Typography variant="h1">Passwort erfolgreich geändert</Typography>

            <Typography paragraph>
                Ihr Passwort wurde erfolgreich geändert. Ihr Passwort wurde erfolgreich zurückgesetzt. Sie können sich
                ab sofort mit dem neuen Passwort anmelden.
            </Typography>

            <Grid container spacing={1} direction={'row-reverse'}>
                <Grid item>
                    <FormControl>
                        <FormButton
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={() => navigate(InvestPathBuilder.home.path)}
                        >
                            Zum Login
                        </FormButton>
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
};
