import React from 'react';

import { FormButton } from 'forms/components/FormButton';

interface FormSaveButtonInterface {
    onSave: () => void;
    disabled: boolean;
    label: string;
}

export const FormSaveButton: React.FC<FormSaveButtonInterface> = ({ onSave, disabled, label }) => {
    return (
        <FormButton
            type="submit"
            variant="contained"
            color="primary"
            onClick={onSave}
            disabled={disabled}
            data-cy={'FormSaveButton'}
        >
            {label}
        </FormButton>
    );
};
