import { useCallback, useContext } from 'react';

import { UuidMappingFunction } from 'forms/hooks/useUuidValue';

import { FormStateActions, FormStateContext, FormStateData } from './FormStateProvider';

export const useFormState = (): FormStateData & FormStateActions => {
    const { formStateData, setFormStateData } = useContext(FormStateContext);

    const setAntragStatus = useCallback(
        (antragStatus: string | undefined) =>
            setFormStateData((prevState) => ({
                ...prevState,
                antragStatus: antragStatus,
            })),
        [setFormStateData]
    );
    const setAntragId = useCallback(
        (antragId: number | undefined) =>
            setFormStateData((prevState) => ({
                ...prevState,
                antragId: antragId,
            })),
        [setFormStateData]
    );
    const setSofortigerFestsetzungsantragId = useCallback(
        (sofortigerFestsetzungsantragId: number | undefined) =>
            setFormStateData((prevState) => ({
                ...prevState,
                sofortigerFestsetzungsantragId: sofortigerFestsetzungsantragId,
            })),
        [setFormStateData]
    );
    const setEinrichtungId = useCallback(
        (einrichtungId: number | undefined) =>
            setFormStateData((prevState) => ({
                ...prevState,
                einrichtungId: einrichtungId,
            })),
        [setFormStateData]
    );
    const setUuidMap = useCallback(
        (uuidMap: { [key: string]: string } | undefined) =>
            setFormStateData((prevState) => ({
                ...prevState,
                uuidMap: uuidMap,
            })),
        [setFormStateData]
    );
    const mapUuid: UuidMappingFunction = useCallback(
        (uuid?: string) => {
            if (uuid && checkIfValidUUID(uuid)) {
                return formStateData.uuidMap?.[uuid] || 'Virtuelles Konto';
            }
            return uuid;
        },
        [formStateData.uuidMap]
    );
    const submitStart = useCallback(
        (persist?: boolean) =>
            setFormStateData((prevState) => ({
                ...prevState,
                isSubmitting: true,
                isPersisting: Boolean(persist),
                loadingError: undefined,
            })),
        [setFormStateData]
    );
    const submitEnd = useCallback(
        () =>
            setFormStateData((prevState) => ({
                ...prevState,
                isSubmitting: false,
                isPersisting: false,
            })),
        [setFormStateData]
    );

    const loadingStart = useCallback(
        () =>
            setFormStateData((prevState) => ({
                ...prevState,
                isLoading: true,
                loadingError: undefined,
            })),
        [setFormStateData]
    );

    const loadingEnd = useCallback(
        () =>
            setFormStateData((prevState) => ({
                ...prevState,
                isLoading: false,
            })),
        [setFormStateData]
    );

    const setLoadingError = useCallback(
        (loadingError: string | undefined) =>
            setFormStateData((prevState) => ({
                ...prevState,
                loadingError,
            })),
        [setFormStateData]
    );

    return {
        ...formStateData,
        submitStart,
        submitEnd,
        loadingStart,
        loadingEnd,
        setLoadingError,
        setAntragId,
        setFestsetzungsantragEntwurfId: setSofortigerFestsetzungsantragId,
        setAntragStatus,
        setEinrichtungId,
        setUuidMap,
        mapUuid,
    };
};

const checkIfValidUUID = (uuid?: string): boolean => {
    // Regular expression to check if string is a valid UUID
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

    return Boolean(uuid && regexExp.test(uuid));
};
