import { isEqual, pickBy } from 'lodash';

const isNotUndefinedOrNull = (value: unknown) => value !== undefined && value !== null;

type DeepEqualOptions = { ignoreUndefinedFields?: boolean };
export const deepEqual = (
    o1: object | null | undefined,
    o2: object | null | undefined,
    { ignoreUndefinedFields }: DeepEqualOptions = {}
): boolean => {
    const obj1 = ignoreUndefinedFields && o1 ? pickBy(o1, isNotUndefinedOrNull) : o1;
    const obj2 = ignoreUndefinedFields && o2 ? pickBy(o2, isNotUndefinedOrNull) : o2;
    return isEqual(obj1, obj2);
};
