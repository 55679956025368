import { Grid, Typography } from '@mui/material';

import { KONTAKT_PFADINV_EMAIL, KONTAKT_PFADINV_HANDBUECHER, KONTAKT_PFADINV_TEL } from 'constants/content';
import { LinkEmail } from 'elements/LinkEmail';
import { LinkExtern } from 'elements/LinkExtern';
import { LinkTel } from 'elements/LinkTel';
import { ContentContainer } from 'layout/container/ContentContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { KontaktLvr } from 'pages/Hilfe/components/KontaktLvr';
import { KontaktLwl } from 'pages/Hilfe/components/KontaktLwl';

import { FaqList } from './components/FaqList';

const Index = () => (
    <ContentContainer title="Hilfe">
        <Typography variant="h1">Hilfe</Typography>
        <Typography paragraph>
            Für Fragen rund um die Anwendung PfAD.invest steht Ihnen unsere Hotline zur Verfügung, die Sie werktags von
            9.00-17.00 Uhr unter der Rufnummer <LinkTel msisdn={KONTAKT_PFADINV_TEL} /> erreichen können.
            <br />
            Sie können Ihre Anfrage auch per E-Mail an <LinkEmail email={KONTAKT_PFADINV_EMAIL} /> richten.
        </Typography>
        <Typography paragraph>
            <LinkExtern href={KONTAKT_PFADINV_HANDBUECHER}>
                Handbücher für die Antragsstellung (Feststellung und Festsetzung)
            </LinkExtern>
        </Typography>

        <Grid container spacing={4}>
            <Grid item md={6}>
                <KontaktLvr />
            </Grid>
            <Grid item md={6}>
                <KontaktLwl />
            </Grid>
            <Grid item md={12}>
                <FaqList title={'FAQ'} />
            </Grid>
        </Grid>
    </ContentContainer>
);

export const HilfePage = withSideBar(Index, []);
