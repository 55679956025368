import React, { ComponentType } from 'react';
import { ControlProps, LayoutProps, OwnPropsOfLayout, UISchemaElement } from '@jsonforms/core';
import { withJsonFormsControlProps, withJsonFormsLayoutProps } from '@jsonforms/react';

import { CustomControlProps } from 'forms/hooks/withCustomControlProps';
import { FormStateValue, UiSchemaType } from 'forms/types/UiSchemaTypes';

export type CustomLayoutProps<T extends UISchemaElement> = LayoutProps & {
    uischema: T;
};

export const withLayoutProps = <T extends UISchemaElement>(
    Component: ComponentType<CustomLayoutProps<T>>,
    memoize: boolean = true
): ComponentType<LayoutProps & OwnPropsOfLayout> => {
    return withJsonFormsLayoutProps((props) => {
        return <Component {...props} uischema={props.uischema as T} />;
    }, memoize);
};

export const withControlProps = <T extends FormStateValue, S extends UiSchemaType>(
    Component: ComponentType<CustomControlProps<T, S>>,
    memoize: boolean = true
): ComponentType<CustomControlProps<T, S>> => {
    return withJsonFormsControlProps(
        ((props: CustomControlProps<T, S>) => {
            return <Component {...props} uischema={props.uischema as S} data={props.data as T} />;
        }) as unknown as ComponentType<ControlProps>,
        memoize
    ) as ComponentType<CustomControlProps<T, S>>;
};
