import { AntragType } from 'api/antragTypes';
import { DataTableHydraServerside } from 'components/DataTableHydra/DataTableHydraServerside';
import { useMeldungP29ListActions } from 'pages/Antraege/MeldungP29/useMeldungP29ListActions';
import { useMeldungP29ListData } from 'pages/Antraege/MeldungP29/useMeldungP29ListData';
import { Verwaltung } from 'pages/Verwaltung/components/LayoutComponents';

import { useMeldungenP29Columns } from './useMeldungenP29Columns';

interface MeldungenP29Props {
    id: string;
}

export const MeldungenP29 = ({ id }: MeldungenP29Props) => {
    const getData = useMeldungP29ListData(id);
    const { handleAction, serverDataChanged } = useMeldungP29ListActions(AntragType.MELDUNG_P29);
    const getColumns = useMeldungenP29Columns(handleAction);

    return (
        <Verwaltung.Container>
            <Verwaltung.Group md={12}>
                <DataTableHydraServerside
                    getColumns={getColumns}
                    getData={getData}
                    initialOptions={{
                        filter: false,
                        sort: false,
                        viewColumns: false,
                        search: false,
                        download: false,
                        print: false,
                    }}
                    serverDataChanged={serverDataChanged}
                />
            </Verwaltung.Group>
        </Verwaltung.Container>
    );
};
