import { collectionGroups } from 'forms/AntragForm/ui-schemas/elements/collectionGroups';
import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { highlightedArea } from 'forms/AntragForm/ui-schemas/elements/highlightedArea';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';

export const uiSchema = verticalLayout([
    collectionGroups(
        'fiktivePlatzzahlerweiterungen',
        [
            group(
                [
                    control('field1759a'),
                    control('field1723'),
                    control('field1724'),
                    control('field1725'),
                    control('field1725a'),
                    control('field290'),
                    control('field1726a'),
                    control('field1726b'),
                    control('field1726'),
                    control('field1727'),
                    control('field1728a'),
                ],
                {}
            ),
            group(
                [
                    {
                        type: 'FormExtendButton',
                        label: 'Zuschuss hinzufügen',
                        scope: '#/properties/zuschuesse',
                        uiSchema: {
                            type: 'CustomVerticalLayout',
                            elements: [
                                control('field1729', {
                                    options: {
                                        firstControl: true,
                                    },
                                }),
                                control('field1730'),
                            ],
                        },
                    },
                ],
                {},
                undefined,
                undefined,
                'Fiktives LALG Platzzahlerweiterung Zuschüsse - {value}',
                'massnahmeUuid'
            ),
            group([control('field1729a'), control('field1731')], {}),
            group(
                [
                    {
                        type: 'FormExtendButton',
                        label: 'Platzzahlreduzierung hinzufügen',
                        scope: '#/properties/relevantePlatzzahlreduzierungen',
                        uiSchema: {
                            type: 'CustomVerticalLayout',
                            elements: [
                                control('field1717b', {
                                    options: {
                                        firstControl: true,
                                    },
                                }),
                                control('field1718b'),
                            ],
                        },
                    },
                ],
                {},
                undefined,
                undefined,
                'Relevante Platzzahlreduzierungen - {value}',
                'massnahmeUuid'
            ),
            group([control('field1719b'), control('field1720b'), control('field1721b')], {}),
            group(
                [
                    {
                        type: 'FormExtendButton',
                        label: 'Platzzahlreduzierung hinzufügen',
                        scope: '#/properties/zusaetzlichePlatzzahlreduzierungen',
                        uiSchema: {
                            type: 'CustomVerticalLayout',
                            elements: [
                                control('field1917b', {
                                    options: {
                                        firstControl: true,
                                    },
                                }),
                                control('field1918b'),
                                control('field1919b'),
                                control('field1920b'),
                            ],
                        },
                    },
                ],
                { noSeparator: true },
                undefined,
                undefined,
                'Zusätzliche Platzzahlreduzierungen Gesamteinrichtung - {value}',
                'massnahmeUuid'
            ),
            group([control('field1921b'), control('field1922b'), control('field1923b'), control('field1732')], {
                noSeparator: true,
            }),
        ],
        undefined,
        'massnahmeUuid',
        'Platzzahlerweiterung - {value}'
    ),
    group([highlightedArea([control('field1733'), control('field1734')])], {}, 'Gesamtberechnung Fiktives LALG'),
]);
