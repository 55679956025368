import React from 'react';
import { GridSize } from '@mui/material';

import { ScopedDocumentUploadUiSchema } from 'forms/controls/DocumentTable/DocumentTableControl';
import { DocumentTableRow } from 'forms/controls/DocumentTable/hooks/useDocumentTableData';
import { DocumentTableColumnType, DocumentTableType } from 'forms/types/UiSchemaTypes';

export const documentTableColumn = (
    header: string,
    key: keyof DocumentTableRow,
    styles?: React.CSSProperties
): DocumentTableColumnType => ({
    header,
    key,
    styles: {
        textAlign: 'left',
        verticalAlign: 'top',
        ...styles,
    },
});

export const documentTable = (
    columns: DocumentTableColumnType[],
    entries: ScopedDocumentUploadUiSchema[],
    gridWidth: GridSize,
    styles: React.CSSProperties
): DocumentTableType => ({
    type: 'DocumentTableControl',
    columns,
    entries,
    gridWidth,
    styles,
});
