import React from 'react';

import { ACTIONS } from 'constants/antragActions';
import { ANTRAG_ACTION_STATUS_LABELS, ANTRAG_STATUS_LABELS } from 'constants/labels';
import { FormButton } from 'forms/components/FormButton';
import { useConfirmationDialogActions } from 'forms/state/useConfirmationDialogState';
import { useFormState } from 'forms/state/useFormState';

interface FormSaveButtonInterface {
    onGegenpruefungAblehnen: () => void;
    disabled: boolean;
}

export const FormGegenpruefungAblehnenButton: React.FC<FormSaveButtonInterface> = ({
    onGegenpruefungAblehnen,
    disabled,
}) => {
    const { antragStatus } = useFormState();
    const { showConfirmation, closeConfirmation } = useConfirmationDialogActions();

    const onGegenpruefungAblehnenBase = () => {
        showConfirmation({
            alertText: `Sie sind dabei diesen Antrag abzulehnen. Der Status dieses Antrages ändert sich von '${
                ANTRAG_STATUS_LABELS[antragStatus!]
            }' auf '${ANTRAG_ACTION_STATUS_LABELS[ACTIONS.ABLEHNEN]}'. Möchten Sie fortfahren?`,
            confirmLabel: 'Antrag ablehnen',
            denyLabel: 'Abbrechen',
            denyAction: closeConfirmation,
            confirmAction: () => {
                closeConfirmation();
                return onGegenpruefungAblehnen();
            },
        });
    };

    return (
        <FormButton
            type="submit"
            variant="contained"
            color="primary"
            onClick={onGegenpruefungAblehnenBase}
            disabled={disabled}
            data-cy={'FormGegenpruefungAblehnenButton'}
        >
            {'Freigabe ablehnen'}
        </FormButton>
    );
};
