import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/AntragForm/ui-schemas/elements/customVerticalLayout';
import { formDialog } from 'forms/AntragForm/ui-schemas/elements/formDialog';
import { highlightedArea } from 'forms/AntragForm/ui-schemas/elements/highlightedArea';
import { labeled } from 'forms/AntragForm/ui-schemas/elements/labeled';
import { listControlWithModalForm } from 'forms/AntragForm/ui-schemas/elements/listControlWithModalForm';
import { tableColumn, tableColumnDate, tableColumnDecimal } from 'forms/AntragForm/ui-schemas/elements/tableColumn';

export const uiSchema = customVerticalLayout([
    listControlWithModalForm(
        ['#/properties/modernisierungP8Abs16'],
        [
            tableColumn('Bezeichnung Maßnahme', {
                scope: '#/properties/massnahmeUuid',
            }),
            tableColumnDate('Inbetriebnahme', {
                scope: '#/properties/field202',
            }),
            tableColumnDecimal('Anerkannter Gesamtaufwand', {
                scope: '#/properties/field0810',
            }),
        ],
        [],
        [],
        [],
        [
            formDialog(
                'Neuer Mietvertrag',
                'Mietvertrag',
                labeled({ labelScope: 'field160' }),
                customVerticalLayout([
                    control('field201'),
                    control('field202'),
                    control('field0810'),
                    control('field0899'),
                    control('field247'),
                    control('field1830a'),
                ]),
                {
                    scope: '#/properties/mietvertraege',
                }
            ),
        ]
    ),
    highlightedArea([control('field1830')]),
]);
