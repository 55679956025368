import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/AntragForm/ui-schemas/elements/customVerticalLayout';
import { formDialog } from 'forms/AntragForm/ui-schemas/elements/formDialog';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { highlightedArea } from 'forms/AntragForm/ui-schemas/elements/highlightedArea';
import { listControlWithModalForm } from 'forms/AntragForm/ui-schemas/elements/listControlWithModalForm';
import { tableColumn } from 'forms/AntragForm/ui-schemas/elements/tableColumn';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';

export const uiSchema = verticalLayout([
    group([
        listControlWithModalForm(
            ['#/properties/darlehen'],
            [
                tableColumn('Bezeichnung', {
                    scope: '#/properties/field453a',
                }),
                tableColumn(
                    'Historisch tatsächlich entstandener Fremdkapitaleinsatz zum Zeitpunkt der Inbetriebnahme bzw. zum Zeitpunkt des Erwerbs',
                    {
                        scope: '#/properties/field453',
                    }
                ),
                tableColumn(
                    'Tatsächlich für den Festsetzungszeitraum entstehender Finanzierungsbedarf (Zins- und Tilgung) ',
                    {
                        scope: '#/properties/field454',
                    }
                ),
                tableColumn('Zinsbindung endet am', {
                    scope: '#/properties/field455',
                }),
                tableColumn('Kündigungsmöglichkeit nach § 489 BGB wurde eingehalten', {
                    scope: '#/properties/field456',
                }),
            ],
            ['Darlehen hinzufügen'],
            ['Darlehen löschen'],
            ['Wollen Sie dieses Darlehen mit allen Angaben wirklich aus Ihrem Antrag löschen?'],
            [
                formDialog(
                    'Neues Darlehen',
                    'Darlehen',
                    { labelScope: '#/properties/field453a' },
                    customVerticalLayout([
                        group([
                            control('field453a'),
                            control('field453'),
                            control('field453da'),
                            control('field453db'),
                            control('field454'),
                            control('field453dc'),
                            control('field455'),
                            control('field456'),
                        ]),
                    ])
                ),
            ]
        ),
        highlightedArea([
            control('field5631'),
            control('field461'),
            control('field5606'),
            control('field5607'),
            control('field5608'),
            control('field5609'),
            control('field5610'),
        ]),
    ]),
]);
