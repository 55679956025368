import { Typography } from '@mui/material';

import { ContentContainer } from 'layout/container/ContentContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { withAuthorization } from 'navigation/withAuthorization';
import { TraegerList } from 'pages/Verwaltung/Traeger/TraegerList';
import { VerwaltungConfig } from 'pages/Verwaltung/VerwaltungConfig';

const Index = () => (
    <ContentContainer title="Träger">
        <Typography variant="h1">Träger</Typography>
        <TraegerList />
    </ContentContainer>
);

export const TraegerPage = withAuthorization(withSideBar(Index, VerwaltungConfig.menu), VerwaltungConfig.roles.traeger);
