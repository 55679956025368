import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { FeststellungsAntragCollectionListResponse } from 'api/types';
import { GetData } from 'components/DataTableHydra/hooks/useTableData';
import { zustaendigerBenutzerFilter } from 'components/DataTableHydra/tableUtils';

export const useFeststellungsAntragListData = (): GetData<FeststellungsAntragCollectionListResponse> => {
    return useCallback(({ page, rowsPerPage, searchText, sortOrder, filters }) => {
        return backendApiService.getFeststellungsantragCollection({
            page,
            itemsPerPage: rowsPerPage,
            qSearch: searchText ? [searchText] : undefined,
            zustaendigerBenutzer: zustaendigerBenutzerFilter(filters),
            status2: filters ? filters['status'] : undefined,
            orderZuletztEingereichtAt: sortOrder?.name === 'zuletztEingereichtAt' ? sortOrder?.direction : undefined,
            orderField102: sortOrder?.name === 'jahr' ? sortOrder?.direction : undefined,
            orderEinrichtungAktenzeichen: sortOrder?.name === 'aktenzeichen' ? sortOrder?.direction : undefined,
            orderBerechnungsart: sortOrder?.name === 'berechnungsart' ? sortOrder?.direction : undefined,
            orderEinrichtungName: sortOrder?.name === 'einrichtung' ? sortOrder?.direction : undefined,
            orderStatus: sortOrder?.name === 'status' ? sortOrder?.direction : undefined,
            orderEinrichtungZustaendigerBenutzer:
                sortOrder?.name === 'zustaendigerBenutzer' ? sortOrder?.direction : undefined,
            berechnungsart: filters ? filters['berechnungsart'] : undefined,
            antragJahr: filters ? filters['jahr'] : undefined,
        });
    }, []);
};
