import { rankWith, Tester } from '@jsonforms/core';
import { JsonSchema } from '@jsonforms/core/src/models/jsonSchema';
import { UISchemaElement } from '@jsonforms/core/src/models/uischema';

import { getFieldSchemaByScope } from 'forms/utils/SchemaUtils';

export const isPlaintextMehrzeiligerText: Tester = (
    uischema: UISchemaElement | any,
    schema: JsonSchema | any
): boolean => {
    if (uischema?.type !== 'Control') return false;

    const field = getFieldSchemaByScope(uischema.scope, schema);

    return (
        (field as any)?.type.toLowerCase() === 'string' &&
        (field as any)?.custom?.block_prefixes?.includes('plain_textarea')
    );
};

export const PlaintextMehrzeiligerTextTester = rankWith(5000, isPlaintextMehrzeiligerText);
