import { customVerticalLayout } from 'forms/AntragForm/ui-schemas/elements/customVerticalLayout';
import { formDialog } from 'forms/AntragForm/ui-schemas/elements/formDialog';
import { listControlWithModalForm } from 'forms/AntragForm/ui-schemas/elements/listControlWithModalForm';
import { tableColumn, tableColumnDate, tableColumnDecimal } from 'forms/AntragForm/ui-schemas/elements/tableColumn';
import { notes } from 'forms/AntragForm/ui-schemas/groups/notes';
import { UiSchemaType } from 'forms/types/UiSchemaTypes';

const mietvertragElements = [
    {
        type: 'Group',
        elements: [
            {
                type: 'Info',
                message:
                    'Verteilt sich die Grundfläche des Gebäudes auf mehrere Mietbojekte, bitte nur die tatsächliche Quadratmeterzahl der Mieteinheit angeben.',
            },
            {
                type: 'Control',
                scope: '#/properties/field160',
            },
            {
                type: 'Control',
                scope: '#/properties/field161',
            },
            {
                type: 'Control',
                scope: '#/properties/field162',
            },
            {
                type: 'Control',
                scope: '#/properties/field163',
            },
            {
                type: 'Control',
                scope: '#/properties/field164',
            },
            {
                type: 'Control',
                scope: '#/properties/field165',
            },
            {
                type: 'InlineRowControl',
                scope: '#/properties/field166',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/field166',
                        controlOnly: true,
                        gridSize: 4,
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/field166Unbefristet',
                        controlOnly: true,
                    },
                ],
                gridLayout: {
                    fieldnumber: 1,
                    label: 3,
                    input: 5,
                },
            },
            {
                type: 'Control',
                scope: '#/properties/field174',
            },
            {
                type: 'Control',
                scope: '#/properties/field180',
            },
            {
                type: 'Info',
                message:
                    'Bitte geben Sie als Aufwand Miete die insgesamt zu zahlende Miete im Festsetzungszeitraum an.',
            },
            {
                type: 'Control',
                scope: '#/properties/field951',
            },
            {
                type: 'Control',
                scope: '#/properties/field951a',
            },
            {
                type: 'Control',
                scope: '#/properties/field951d',
            },
        ],
    },
] as UiSchemaType[];

export const uiSchema = {
    type: 'CustomVerticalLayout',
    elements: [
        {
            type: 'Group',
            label: 'Erfasste Mietverträge',
            elements: [
                listControlWithModalForm(
                    ['#/properties/mietvertraege'],
                    [
                        tableColumn('Mietvertrag für', {
                            scope: '#/properties/field160',
                        }),
                        tableColumnDate('Datum', {
                            scope: '#/properties/field161',
                        }),
                        tableColumnDecimal('Platzzahl', {
                            scope: '#/properties/field163',
                        }),
                        tableColumnDecimal('Instandhaltungsaufwand', {
                            scope: '#/properties/field174',
                        }),
                        tableColumnDecimal('Miete im Festsetzungszeitraum', {
                            scope: '#/properties/field951',
                        }),
                        tableColumnDate(
                            'Ende',
                            {
                                scope: '#/properties/field166',
                            },
                            {
                                nullValue: 'unbefristet',
                            }
                        ),
                    ],
                    ['Mietvertrag hinzufügen'],
                    ['Mietvertrag löschen'],
                    ['Wollen Sie diesen Mietvertrag mit allen Angaben wirklich aus Ihrem Antrag löschen?'],
                    [
                        formDialog('Neuer Mietvertrag', 'Mietvertrag ', {}, customVerticalLayout(mietvertragElements), {
                            scope: '#/properties/mietvertraege',
                            actionKey: null,
                        }),
                    ],
                    {}
                ),
            ],
        },
        notes('notizMiete'),
    ],
};
