import { UuidMappingFunction } from 'forms/hooks/useUuidValue';
import { FormState, FormStateValue } from 'forms/types/UiSchemaTypes';

export const getScopedValue = (scope: string, data: FormState): FormStateValue => {
    const propertyPath = scope.replace('#/properties/', '');
    if (!propertyPath || propertyPath === '') {
        return data;
    }

    const pathToValue = propertyPath.split('/').filter((key) => key !== 'properties');
    return pathToValue.reduce((currObj, key) => {
        if (!currObj) {
            return currObj;
        }
        if (Array.isArray(currObj)) {
            return currObj.flatMap((item) => item[key]).filter((i) => i);
        }
        return currObj[key];
    }, data);
};

export const getFieldNumber = (path?: string): string | undefined =>
    (-1 !== path?.lastIndexOf('field') && path?.substr(path?.lastIndexOf('field') + 5)) || '';

export const replaceScopeTemplates = (
    templateString: string | undefined,
    data: FormState,
    uuidMapping?: UuidMappingFunction
) => {
    const matches = templateString?.match(/[^{]+(?=})/g);
    if (!matches) {
        return templateString;
    }
    return matches.reduce(
        (template, currentMatch) =>
            template.replace(
                `{${currentMatch}}`,
                uuidMapping
                    ? uuidMapping(getScopedValue(currentMatch, data)) || '-'
                    : getScopedValue(currentMatch, data) || '-'
            ),
        templateString || ''
    );
};

export const getFieldFromScope = (scope?: string): string => scope?.substr(scope.lastIndexOf('/') + 1) || '-';

export const getObjectTypeAction = (scope?: string, action?: string | null): string => {
    if (action) {
        return `${getFieldFromScope(scope)}${action}`;
    }
    return getFieldFromScope(scope);
};
