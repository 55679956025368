import { Schema } from 'forms/types/UiSchemaTypes';
import { getFieldSchemaByScope } from 'forms/utils/SchemaUtils';
import { getFieldFromScope } from 'utilities';

export const useDependentFields = (schema: Schema, scopes: string[]): string[] => {
    return [
        ...scopes.map(getFieldFromScope),
        ...scopes
            .map((scope) => getFieldSchemaByScope(scope, schema) as Schema)
            .filter((s) => s?.custom?.dependent_fields)
            .flatMap((s) => s.custom!.dependent_fields!),
    ];
};
