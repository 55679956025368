import { useCallback, useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';

import { FeststellungStep } from 'api/antragTypes';
import { ACTIONS } from 'constants/antragActions';
import { useFormState } from 'forms/state/useFormState';
import {
    FormResponse,
    FormState,
    FormStateConfigDelete,
    FormStateConfigReload,
    FormStateConfigRequestPersist,
    FormStateConfigRequestValidate,
    FormStateConfigSubmitFormData,
    FormStateConfigValidate,
    Schema,
    ServerErrorsType,
} from 'forms/types/UiSchemaTypes';
import { cleanUpData } from 'forms/utils/SchemaUtils';

import { useFormLeaveWarning } from './useFormLeaveWarning';

export type FormHandlingGet<FormData> = () => Promise<FormData>;
export type FormHandlingDelete = (id: string) => Promise<void>;
export type FormHandlingSubmit<FormData> = (
    formData: FormState,
    persist?: boolean,
    modelPaths?: string[],
    objectType?: string,
    uuid?: string,
    recalculateField?: boolean
) => Promise<FormData | false | void>;

export type UseFormHandlingProps = {
    getFormDataAPI?: FormHandlingGet<FormResponse>;
    submitAPI?: FormHandlingSubmit<FormResponse>;
    deleteObjectTypeItemAPI?: FormHandlingDelete;
};

export type FormHandlingOnChange = (newData: FormState) => Promise<void>;
export type FormHandlingSetResponseData = (
    response: FormResponse | false | void,
    fields?: string[],
    persist?: boolean
) => FormResponse | undefined;

interface FormHandlingData extends FormHandlingDataState {
    clear: () => void;
    isLoading: boolean;
    isSubmitted: boolean;
    loadingError: string | undefined;
    onChange: FormHandlingOnChange;
    // TODO: remove false from result promise type
    requestValidate: () => void;
    requestPersist: () => void;
    setLoadingError: (error: string) => void;
    submit: FormStateConfigSubmitFormData;
    validate: FormStateConfigValidate;
    reloadData: FormStateConfigReload;
    deleteObjectTypeItem: FormStateConfigDelete;
    setEinrichtungId: (einrichtungId: number | undefined) => void;
    setAntragId: (antragId: number | undefined) => void;
    setSofortigerFestsetzungsantragId: (antragId: number | undefined) => void;
    setAntragStatus: (antragStatus: string | undefined) => void;
}

interface FormHandlingDataState {
    schema: Schema;
    data: object;
    formErrors: ServerErrorsType;
    sidebarSteps: FeststellungStep[];
    steps: FeststellungStep[];
    allowedWorkflowActions: ACTIONS[];
}

export const useFormHandling = ({
    getFormDataAPI,
    submitAPI,
    deleteObjectTypeItemAPI,
}: UseFormHandlingProps): FormHandlingData => {
    const {
        isPersisting,
        isLoading,
        loadingError,
        submitStart,
        submitEnd,
        loadingStart,
        loadingEnd,
        setLoadingError,
        setAntragId,
        setFestsetzungsantragEntwurfId,
        setAntragStatus,
        setEinrichtungId,
        setUuidMap,
    } = useFormState();

    const validateRef = useRef<boolean>(false);
    const submitRef = useRef<boolean>(false);
    const modelPathRef = useRef<string | undefined>();
    const [initialData, setInitialData] = useState<FormState>();
    const [data, setData] = useState<FormHandlingDataState>({} as FormHandlingDataState);
    useFormLeaveWarning(initialData, data.data, isPersisting);

    const requestValidate: FormStateConfigRequestValidate = useCallback((path) => {
        validateRef.current = true;
        modelPathRef.current = path;
    }, []);

    const requestPersist: FormStateConfigRequestPersist = useCallback((path) => {
        submitRef.current = true;
        modelPathRef.current = path;
    }, []);

    const clear = useCallback(() => {
        setData((prevState) => ({ steps: prevState.steps } as FormHandlingDataState));
        setInitialData(undefined);
    }, []);

    const updatePartialFields = useCallback(
        (old: FormState, newData: FormState, fields?: string[]) => {
            if (!fields || (Array.isArray(fields) && fields.length === 0)) {
                return newData;
            }
            if (!newData) {
                return {};
            }
            try {
                return fields.reduce((preparedData, field) => {
                    return {
                        ...preparedData,
                        [field]: newData[field],
                    };
                }, old);
            } catch (e) {
                console.warn(e);
                setLoadingError('Laden fehlgeschlagen');
                return old;
            }
        },
        [setLoadingError]
    );

    const setResponseData: FormHandlingSetResponseData = useCallback(
        (response, fields, persist) => {
            if (!response) {
                clear();
                return;
            }

            setData(
                (prev) =>
                    ({
                        // Empty {} results to empty [] in php
                        data: Array.isArray(response.data)
                            ? {}
                            : updatePartialFields(prev.data, response.data!, fields),
                        schema: response.schema,
                        sidebarSteps: persist ? response.steps : prev.sidebarSteps,
                        steps: response.steps,
                        allowedWorkflowActions: response.allowedWorkflowActions,
                        formErrors: response.errors, // Clean up response.errors to avoid error messages for empty fields
                    } as FormHandlingDataState)
            );
            setUuidMap(response.uuidMap);
            return response;
        },
        [setUuidMap, clear, updatePartialFields]
    );

    const reloadData: FormStateConfigReload = useCallback(
        (fields) => {
            if (getFormDataAPI) {
                loadingStart();
                return getFormDataAPI()
                    .then((response) => setResponseData(response, fields, true))
                    .then((res) => setInitialData(res?.data))
                    .catch((e) => {
                        console.warn(e);
                        setLoadingError('Laden fehlgeschlagen');
                    })
                    .finally(loadingEnd);
            } else {
                return Promise.resolve();
            }
        },
        [getFormDataAPI, setResponseData, loadingEnd, setLoadingError, loadingStart]
    );

    useEffect(() => {
        reloadData();
    }, [reloadData]);

    const submit: FormStateConfigSubmitFormData = useCallback(
        (formData, persist, paths, objectType, uuid, recalculateFields) => {
            if (!submitAPI) {
                return Promise.resolve(false);
            }
            validateRef.current = false;
            submitRef.current = false;
            if (isEqual(formData, data.data) && !persist && !recalculateFields) {
                return Promise.resolve(false);
            }
            submitStart(persist);
            return submitAPI(
                markAsEdited(formData, data.schema, persist),
                persist,
                paths,
                objectType,
                uuid,
                recalculateFields
            )
                .then((res) => {
                    return setResponseData(res, undefined, persist);
                })
                .then((res) => {
                    if (persist) {
                        setInitialData(res?.data);
                    }
                    return res;
                })
                .catch((e) => {
                    console.warn(e);
                    setLoadingError('Laden fehlgeschlagen');
                })
                .finally(submitEnd);
        },
        [submitAPI, submitStart, submitEnd, setResponseData, setLoadingError, data.schema, data.data]
    );

    const onChange: FormHandlingOnChange = useCallback(
        async (newData) => {
            if (isEqual(newData, data)) return;
            setData((prevState) => ({ ...prevState, data: newData }));

            if (validateRef.current || submitRef.current) {
                await submit(
                    cleanUpData(newData, data.schema),
                    submitRef.current,
                    modelPathRef.current ? [modelPathRef.current] : undefined
                );
            }
        },
        [data, submit]
    );

    const validate: FormStateConfigValidate = useCallback(
        (modelPath: string | undefined) => {
            return submit(data.data, false, modelPath ? [modelPath] : undefined);
        },
        [submit, data.data]
    );

    const deleteObjectTypeItem: FormStateConfigDelete = useCallback(
        (uuid) => {
            if (deleteObjectTypeItemAPI) {
                return deleteObjectTypeItemAPI(uuid).then(() => reloadData());
            } else {
                return Promise.resolve();
            }
        },
        [deleteObjectTypeItemAPI, reloadData]
    );

    return {
        ...data,
        isLoading,
        isSubmitted: false,
        loadingError,
        onChange,
        requestValidate,
        requestPersist,
        submit,
        setLoadingError,
        validate,
        clear,
        reloadData,
        deleteObjectTypeItem,
        setAntragId,
        setSofortigerFestsetzungsantragId: setFestsetzungsantragEntwurfId,
        setAntragStatus,
        setEinrichtungId,
    };
};

const markAsEdited = (formData: FormState, schema: Schema, persist = false) => {
    if (!persist) {
        return formData;
    }
    if (!schema.properties) {
        return formData;
    }
    const editFieldName = Object.keys(schema.properties)
        .filter((k) => k !== 'prototype')
        .find((k) => (schema.properties![k] as Schema).custom?.block_prefixes?.includes('bearbeitet'));
    if (!editFieldName) {
        return formData;
    }
    return { ...formData, [editFieldName]: true };
};
