import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { EinrichtungListResponse } from 'api/types';
import { GetData } from 'components/DataTableHydra/hooks/useTableData';
import { sortBy, zustaendigerBenutzerFilter } from 'components/DataTableHydra/tableUtils';

export const useZustaendigkeitenEinrichtungListData = (): GetData<EinrichtungListResponse> => {
    return useCallback(({ page, rowsPerPage, searchText, sortOrder, filters }) => {
        return backendApiService.getEinrichtungCollection({
            page,
            itemsPerPage: rowsPerPage,
            qSearch: searchText ? [searchText] : undefined,
            zustaendigerBenutzer: zustaendigerBenutzerFilter(filters),
            orderByName: sortBy(sortOrder, 'name'),
            orderByAktenzeichen: sortBy(sortOrder, 'aktenzeichen'),
            orderByZustaendigerBenutzer: sortBy(sortOrder, 'zustaendigerBenutzer'),
        });
    }, []);
};
