import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { info } from 'forms/AntragForm/ui-schemas/elements/info';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';
import { notes } from 'forms/AntragForm/ui-schemas/groups/notes';

export const uiSchema = verticalLayout([
    group(
        [
            info(
                'Bitte geben Sie die zu zahlende Erbpacht für die beiden Jahre des Festsetzungszeitraumes (24 Monate) als Gesamtsumme an.'
            ),
            control('field952'),
            control('field952d'),
        ],
        {}
    ),
    notes('notizErbpacht'),
]);
