import React from 'react';

import { MenuInterface, Step } from 'layout/HeaderConfig';
import { useSideBar, useSideBarMenuConfig } from 'layout/hooks/useSideBar';
import { useAntragMenu } from 'pages/Antraege/useAntragMenu';

interface SideBarType {
    steps?: Step<string>[];
    stepPathBuilder: (id: string, step?: string) => string;
    menuBuilder: (menus: MenuInterface[]) => MenuInterface[];
}

export const AntragSideBar: React.FC<SideBarType> = ({ steps, stepPathBuilder, menuBuilder }) => {
    const antragMenu = useAntragMenu(steps || [], stepPathBuilder);
    const menus = useSideBar((s) => s.menus);
    useSideBarMenuConfig(steps ? menuBuilder(antragMenu) : menus);

    return <></>;
};
