import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { listControlWithModalForm } from 'forms/AntragForm/ui-schemas/elements/listControlWithModalForm';
import { tableColumn, tableColumnDate, tableColumnDecimal } from 'forms/AntragForm/ui-schemas/elements/tableColumn';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';

import { modalEigenkapital, modalFremdkapital, modalLeasing } from './finanzierungen/modalElements';

export const uiSchema = verticalLayout([
    group([
        listControlWithModalForm(
            ['#/properties/eigenkapital', '#/properties/fremdkapital', '#/properties/leasing'],

            [
                tableColumn('Art', { scope: '#/properties/type' }),
                tableColumn('Bezeichnung', {
                    scopes: ['#/properties/field270'],
                }),
                tableColumn('Zugehörigkeit', {
                    scopes: [
                        '#/properties/fremdkapitalAnteile/properties/field251',
                        '#/properties/fremdkapitalAnteile/properties/field251',
                        '#/properties/field1427',
                    ],
                }),
                tableColumnDate('Datum', {
                    scopes: ['#/properties/field260', '#/properties/field271', '#/properties/field1428'],
                }),
                tableColumnDate('Ende des Darlehens', {
                    scopes: ['#/properties/field277', '#/properties/field5458'],
                }),
                tableColumnDecimal('Anerkannter Betrag', {
                    scopes: ['#/properties/field1455', '#/properties/field1415'],
                }),
                {
                    type: 'Control',
                    label: 'Bearbeitet',
                    scope: '#/properties/finanzierungBearbeitet',
                    styles: {
                        textAlign: 'right',
                    },
                },
            ],
            ['Finanzierung Eigenkapital hinzufügen', 'Finanzierung Fremdkapital hinzufügen', 'Leasing hinzufügen'],
            ['Finanzierung löschen'],
            ['Wollen Sie diese Finanzierung mit allen Angaben wirklich aus Ihrem Antrag löschen?'],
            [modalEigenkapital, modalFremdkapital, modalLeasing],
            { types: ['EK', 'FK', 'L'] }
        ),
    ]),
]);
