import { useErrors } from 'forms/hooks/useErrors';
import { FormConfig, FormState, Schema } from 'forms/types/UiSchemaTypes';

export const PHONE_NUMBER_REGEX = '^[-0-9 +/()]{6,}$';
export const EMAIL_REGEX = "^\\w+([-+.']\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*$";

export const useFormValidation = (
    schema: Schema | undefined,
    data: FormState | undefined,
    config?: Pick<FormConfig, 'path' | 'errors'> | undefined
): { valid: boolean } => {
    const { hasErrors } = useErrors({ config, immediately: true });
    if (!schema || !data || hasErrors) {
        return { valid: false };
    }
    const valid = !isMissingRequiredField(schema, data);
    return { valid };
};

const isMissingRequiredField = (schema: Schema, data: any): boolean => {
    return (
        (schema.required || [])
            .filter((r) => r !== 'prototype')
            .some((requiredField) => {
                if (!data) {
                    return true;
                }
                const fieldSchema = (schema.properties ? schema.properties[requiredField] : {}) as Schema;
                if (requiredField === 'i_') {
                    const newElements = data.filter((elem: any) => elem.uuid === undefined);
                    return newElements.some((elemData: any) => isMissingRequiredField(fieldSchema, elemData));
                }
                if (requiredField.startsWith('i_')) {
                    const uuid = requiredField.substring(2);
                    const elemData = data.find((elem: any) => elem.uuid === uuid);
                    return isMissingRequiredField(fieldSchema, elemData);
                }
                const fieldData = data[requiredField];

                let fieldInvalid = fieldData === undefined;

                fieldInvalid = fieldInvalid || (Array.isArray(fieldData) && fieldData.length === 0);
                fieldInvalid =
                    fieldInvalid ||
                    (!!fieldSchema.custom?.block_prefixes?.includes('checkbox') &&
                        !fieldSchema.custom?.block_prefixes?.includes('ja_nein') &&
                        !fieldData);
                return fieldInvalid || isMissingRequiredField(fieldSchema, fieldData);
            }) ||
        (Boolean(schema.properties) &&
            Object.keys(schema.properties || [])
                .filter((field) => field !== 'prototype')
                .some((field) => {
                    // @ts-ignore
                    const fieldSchema = schema.properties[field] as Schema;
                    if (field === 'i_') {
                        const newElements = data?.filter((elem: any) => elem.uuid === undefined);
                        return newElements.some((elemData: any) => isMissingRequiredField(fieldSchema, elemData));
                    }
                    if (field.startsWith('i_')) {
                        const uuid = field.substr(2);
                        const elemData = data?.find((elem: any) => elem.uuid === uuid);

                        return isMissingRequiredField(fieldSchema, elemData);
                    }
                    const fieldData = data ? data[field] : {};
                    return isMissingRequiredField(fieldSchema, fieldData);
                }))
    );
};
