import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/AntragForm/ui-schemas/elements/customVerticalLayout';
import { documentTable, documentTableColumn } from 'forms/AntragForm/ui-schemas/elements/documentTable';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';

export const uiSchema = customVerticalLayout([
    group([
        documentTable(
            [
                documentTableColumn('Schritt', 'step'),
                documentTableColumn('Bezeichnung', 'description'),
                documentTableColumn('Datei', 'fileData'),
                documentTableColumn('Hochzuladendes Dokument', 'fileDescription', { textAlign: 'right' }),
            ],
            [
                {
                    scope: '#/properties/mietvertraege',
                    uploads: [
                        {
                            step: 'Miete',
                            description: '{#/properties/uuid}',
                            file: '#/properties/field951a',
                            fileDescription: 'Mietvertrag',
                        },
                        {
                            step: 'Miete',
                            description: '{#/properties/uuid}',
                            file: '#/properties/field951d',
                            fileDescription: 'Nachweis aktuelle Miethöhe',
                        },
                    ],
                },
                {
                    scope: '#/properties/',
                    uploads: [
                        {
                            step: 'Erbpacht',
                            description: '',
                            file: '#/properties/field952d',
                            fileDescription: 'Nachweis aktuelle Erbpachthöhe',
                        },
                    ],
                },
                {
                    scope: '#/properties/aufwandSalg',
                    uploads: [
                        {
                            step: 'Virtuelles Konto',
                            description: 'SALG, {#/properties/uuid}',
                            file: '#/properties/field520d',
                            fileDescription: 'Nachweis für tatsächlichen Aufwand SALG',
                        },
                    ],
                },
                {
                    scope: '#/properties/aufwandInstandhaltung',
                    uploads: [
                        {
                            step: 'Virtuelles Konto',
                            description: 'Instandhaltung, {#/properties/uuid}',
                            file: '#/properties/field530d',
                            fileDescription: 'Nachweis für tatsächliche Instandhaltungsaufwendungen',
                        },
                    ],
                },
                {
                    scope: '#/properties/fremdkapital',
                    uploads: [
                        {
                            step: 'Finanzierungen',
                            description: 'Fremdkapital\n{#/properties/uuid}',
                            file: '#/properties/field270d',
                            fileDescription: 'Darlehensvertrag',
                        },
                        {
                            step: 'Finanzierungen',
                            description: 'Fremdkapital\n{#/properties/uuid}',
                            file: '#/properties/field540d',
                            fileDescription: 'Zins- und Tilgungsplan',
                        },
                        {
                            step: 'Finanzierungen',
                            description: 'Fremdkapital\n{#/properties/uuid}',
                            file: '#/properties/field540e',
                            fileDescription: 'Änderungsvereinbarung',
                        },
                    ],
                },
                {
                    scope: '#/properties/leasing',
                    uploads: [
                        {
                            step: 'Finanzierungen',
                            description: 'Leasing\n{#/properties/uuid}',
                            file: '#/properties/field541d',
                            fileDescription: 'Nachweis Finanzierungsaufwendungen (Leasing)',
                        },
                    ],
                },
                {
                    scope: '#/properties/',
                    uploads: [
                        {
                            step: 'Belegung',
                            description: 'Vollstationär',
                            file: '#/properties/field570d',
                            fileDescription: 'Nachweis Forderungsausfälle',
                        },
                    ],
                },
            ],

            8,
            {
                marginBottom: 32,
            }
        ),
    ]),
    group(
        [
            control('dokumente', {
                noLabel: true,
                multiline: true,
            }),
        ],
        {},
        'Weitere Dokumente'
    ),
]);
