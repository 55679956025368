import React from 'react';
import { Delete } from '@mui/icons-material';
import { Button, ListItemText, MenuItem } from '@mui/material';

import { ConfirmDialogContent, useConfirmationDialogActions } from 'forms/state/useConfirmationDialogState';

interface DeleteActionProps extends Omit<ConfirmDialogContent, 'denyAction' | 'confirmAction'> {
    onDelete: () => Promise<void> | void;
    disabled?: boolean;
}

export const DeleteActionButton: React.FC<DeleteActionProps> = ({
    alertText,
    onDelete,
    denyLabel = 'Abbrechen',
    confirmLabel = 'Löschen',
    disabled = false,
}) => {
    return (
        <DeleteActionBase
            ActionComponent={({ onClick }) => (
                <Button startIcon={<Delete />} onClick={onClick} disabled={disabled}>
                    Löschen
                </Button>
            )}
            alertText={alertText}
            denyLabel={denyLabel}
            confirmLabel={confirmLabel}
            onDelete={onDelete}
        />
    );
};

export const DeleteActionMenuItem: React.FC<DeleteActionProps> = ({
    alertText,
    onDelete,
    denyLabel = 'Abbrechen',
    confirmLabel = 'Löschen',
    disabled = false,
}) => {
    return (
        <DeleteActionBase
            ActionComponent={({ onClick }) => (
                <MenuItem onClick={onClick} disabled={disabled}>
                    <ListItemText primary={'Löschen'} />
                </MenuItem>
            )}
            alertText={alertText}
            denyLabel={denyLabel}
            confirmLabel={confirmLabel}
            onDelete={onDelete}
        />
    );
};

interface DeleteActionBaseProps extends Omit<ConfirmDialogContent, 'denyAction' | 'confirmAction'> {
    ActionComponent: (props: { onClick: () => void }) => React.ReactElement;
    onDelete: () => Promise<void> | void;
}

const DeleteActionBase: React.FC<DeleteActionBaseProps> = ({
    ActionComponent,
    alertText,
    denyLabel,
    confirmLabel,
    onDelete,
}) => {
    const { showConfirmation, closeConfirmation } = useConfirmationDialogActions();

    const handleDelete = () => {
        onDelete();
        closeConfirmation();
    };

    return (
        <ActionComponent
            onClick={() =>
                showConfirmation({
                    alertText,
                    denyLabel,
                    confirmLabel,
                    denyAction: closeConfirmation,
                    confirmAction: handleDelete,
                })
            }
        />
    );
};
