import { CollectionItemAction } from 'forms/types/UiSchemaTypes';

export const useCollectionItemActions = (actions?: any): CollectionItemAction[] => {
    if (!actions) {
        return [];
    }
    return Object.keys(actions).map((key) => ({
        label: key,
        key: actions[key],
    }));
};
