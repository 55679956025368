import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { TraegerverbandListResponse } from 'api/types';
import { GetData } from 'components/DataTableHydra/hooks/useTableData';

export const useTraegerverbandListData = (): GetData<TraegerverbandListResponse> => {
    return useCallback(({ page, rowsPerPage, searchText }) => {
        return backendApiService.getTraegerverbandCollection({
            page,
            itemsPerPage: rowsPerPage,
            qSearch: searchText ? [searchText] : undefined,
        });
    }, []);
};
