import { JsonFormsCore } from '@jsonforms/core';

import { backendApiService } from 'api/ApiService';
import { BenutzerResponse, PutBenutzer } from 'api/types';
import { useMessageActions } from 'forms/state/useMessages';
import { Schema } from 'forms/types/UiSchemaTypes';
import { errorMessage, successMessage } from 'forms/utils/MessageUtils';
import {
    BenutzerBearbeitenFormData,
    useBenutzerBearbeitenFormData,
} from 'pages/Verwaltung/Benutzer/BenutzerBearbeitenForm/useBenutzerBearbeitenFormData';
import { useBenutzerBearbeitenFormSchema } from 'pages/Verwaltung/Benutzer/BenutzerBearbeitenForm/useBenutzerBearbeitenFormSchema';

interface UseBenutzerBearbeitenFormResult {
    isLoading: boolean;
    data: BenutzerBearbeitenFormData;
    isValid: boolean;
    schema: Schema;
    onChange: (state: Pick<JsonFormsCore, 'data' | 'errors'>) => void;
    onSubmit: () => Promise<void>;
}

export const useBenutzerBearbeitenForm = (initialData: BenutzerResponse): UseBenutzerBearbeitenFormResult => {
    const { addMessage } = useMessageActions();
    const { data, isValid, onChange, isLoading: isDataLoading } = useBenutzerBearbeitenFormData(initialData);
    const { schema, isLoading: isSchemaLoading } = useBenutzerBearbeitenFormSchema(data.rolle);

    const onSubmit = (): Promise<void> => {
        const request: PutBenutzer = {
            ...initialData,
            telefon: initialData.telefon ?? null,
            fax: initialData.fax ?? null,
            email: initialData.email ?? null,
            vorname: data.vorname,
            nachname: data.nachname,
            rolle: data.rolle ?? '',
            landschaftsverband: data.landschaftsverband?.id?.toString() ?? null,
            traeger: data.traeger?.id?.toString() ?? null,
            traegerverband: data.traegerverband?.id?.toString() ?? null,
        };

        return backendApiService
            .putBenutzer(String(initialData.id), request)
            .then(() => {
                addMessage(createSuccessMessage());
            })
            .catch(() => {
                addMessage(createErrorMessage());
            });
    };

    return {
        schema,
        data,
        isValid,
        onChange,
        onSubmit,
        isLoading: Boolean(isDataLoading || isSchemaLoading),
    };
};

const createSuccessMessage = () =>
    successMessage('Benutzer erfolgreich gespeichert.', {
        autoCloseSeconds: 8,
        closable: true,
    });

const createErrorMessage = () =>
    errorMessage('Benutzer konnte nicht gespeichert werden.', {
        autoCloseSeconds: 8,
        closable: true,
    });
