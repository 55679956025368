import React from 'react';
import { JsonFormsDispatch } from '@jsonforms/react';
import { Grid } from '@mui/material';

import { CustomLayoutProps, withLayoutProps } from 'forms/hooks/withJsonFormProps';
import { CustomVerticalLayoutType, UiSchemaType } from 'forms/types/UiSchemaTypes';

const CustomVerticalLayoutComponent: React.FC<CustomLayoutProps<CustomVerticalLayoutType>> = ({
    uischema,
    ...others
}) => {
    return (
        <Grid container spacing={uischema.spacing || 2}>
            {uischema.elements?.map((element: UiSchemaType, i: number) => {
                if (element?.elements?.length === 0) {
                    return <React.Fragment key={i}></React.Fragment>;
                }

                return (
                    <Grid key={i} item xs={12}>
                        <JsonFormsDispatch {...others} uischema={element} />
                    </Grid>
                );
            })}
        </Grid>
    );
};

export const CustomVerticalLayout = withLayoutProps(CustomVerticalLayoutComponent);
