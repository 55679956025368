import * as React from 'react';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsLayoutProps } from '@jsonforms/react';
import { Alert, AlertColor } from '@mui/material';

import { UiSchemaType } from 'forms/types/UiSchemaTypes';

interface NachrichtControlProps extends Omit<ControlProps, 'uischema'> {
    uischema: UiSchemaType & { message: string };
}

const NachrichtControlComponent: React.FC<NachrichtControlProps> = ({ uischema, visible }) => {
    const severity = uischema.type.toLowerCase() as AlertColor;
    return visible ? (
        <Alert severity={severity} style={{ ...uischema.styles }} data-cy={`${severity}_message`}>
            <span dangerouslySetInnerHTML={{ __html: uischema.message }} />
        </Alert>
    ) : (
        <></>
    );
};

// @ts-ignore
export const NachrichtControl = withJsonFormsLayoutProps(NachrichtControlComponent);
