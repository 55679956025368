import { GroupControlType } from 'forms/types/UiSchemaTypes';

export const zuschuesse = (
    scope = '#/properties/zuschuesse',
    options?: { noSeparator: boolean }
): GroupControlType => ({
    type: 'Group',
    label: 'Zuschüsse',
    options,
    scope,
    elements: [
        {
            type: 'FormExtendButton',
            label: 'Zuschuss hinzufügen',
            scope,
            uiSchema: {
                type: 'CustomVerticalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/field240',
                        options: {
                            firstControl: true,
                        },
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/field241',
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/field242',
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/field242d',
                    },
                ],
            },
        },
    ],
});
