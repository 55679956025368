import { useMemo, useState } from 'react';
import { MUIDataTableOptions, MUISortOptions } from 'mui-datatables';

import { OnFiltersChanged } from './useTableFilters';

type UseTableOptionsResult = {
    options: MUIDataTableOptions;
    sortOrder: MUISortOptions | undefined;
    searchText: string;
    page: number;
    rowsPerPage: number;
    updateSortOrder: (sortOrder: MUISortOptions | undefined) => void;
    updateSearchText: (searchText: string) => void;
    updatePage: (page: number) => void;
    updateRowsPerPage: (rowsPerPage: number) => void;
};

export const useTableOptions = (
    initialOptions: MUIDataTableOptions | undefined,
    onFiltersChanged: OnFiltersChanged
): UseTableOptionsResult => {
    const [page, setPage] = useState(initialOptions?.page || 0);
    const [rowsPerPage, setRowsPerPage] = useState(initialOptions?.rowsPerPage || 10);
    const [searchText, setSearchText] = useState(initialOptions?.searchText || '');
    const [sortOrder, setSortOrder] = useState(initialOptions?.sortOrder || undefined);

    const [options] = useState<MUIDataTableOptions>(() => ({
        ...defaultTableOptions,
        ...(initialOptions || {}),
        onChangePage: (page) => {
            setPage(page);
        },
        onChangeRowsPerPage: (rowsPerPage) => {
            setPage(0);
            setRowsPerPage(rowsPerPage);
        },
        onSearchChange: (searchText) => {
            setPage(0);
            setSearchText(searchText || '');
        },
        onSearchClose: () => {
            setPage(0);
            setSearchText('');
        },
        onColumnSortChange: (name, direction) => {
            setPage(0);
            setSortOrder({ name, direction });
        },
        onFilterChange: (changedColumn, filterList, type, changedColumnIndex, displayData) => {
            setPage(0);
            onFiltersChanged?.(changedColumn, filterList, type, changedColumnIndex, displayData);
        },
    }));

    const tableOptions = useMemo(
        () => ({ ...options, page, rowsPerPage, searchText, sortOrder }),
        [options, page, rowsPerPage, searchText, sortOrder]
    );

    return {
        options: tableOptions,
        searchText,
        sortOrder,
        page,
        rowsPerPage,
        updateSearchText: setSearchText,
        updateSortOrder: setSortOrder,
        updatePage: setPage,
        updateRowsPerPage: setRowsPerPage,
    };
};

const defaultTableOptions: MUIDataTableOptions = {
    serverSide: true,
    page: 0,
    rowsPerPage: 10,
    searchOpen: false,
    searchText: '',
};
