import React from 'react';
import { MenuItem } from '@mui/material';

import { ActionDropdown } from 'components/DataTableHydra/ActionDropdown/ActionDropdown';
import { DeleteActionMenuItem } from 'forms/components/DeleteAction';
import { CollectionItemAction } from 'forms/types/UiSchemaTypes';

interface FormTableActionsType {
    index: number;
    viewable: boolean;
    editable: boolean;
    deletable: boolean;
    disabled?: boolean;
    actions: CollectionItemAction[];
    onView: (index: number) => void;
    onEdit: (index: number) => void;
    onDelete: (index: number) => void;
    onCustomAction: (index: number, key: string) => void;
    deleteAlertText: (index: number) => string;
    deleteConfirmationLabel: (index: number) => string;
}

export const FormTableActions: React.FC<FormTableActionsType> = ({
    viewable,
    editable,
    deletable,
    disabled,
    actions,
    onView,
    onEdit,
    onDelete,
    onCustomAction,
    index,
    deleteConfirmationLabel,
    deleteAlertText,
}) => {
    return (
        <ActionDropdown disabled={disabled}>
            {viewable && (
                <MenuItem onClick={() => onView(index)} disabled={disabled}>
                    Ansehen
                </MenuItem>
            )}

            {editable && (
                <MenuItem onClick={() => onEdit(index)} disabled={disabled}>
                    Bearbeiten
                </MenuItem>
            )}
            {actions &&
                actions.map((action) => (
                    <MenuItem key={action.key} onClick={() => onCustomAction(index, action.key)} disabled={disabled}>
                        {action.label}
                    </MenuItem>
                ))}
            {deletable && (
                <DeleteActionMenuItem
                    denyLabel="Abbrechen"
                    confirmLabel={deleteConfirmationLabel(index)}
                    alertText={deleteAlertText(index)}
                    onDelete={() => onDelete(index)}
                    disabled={disabled}
                />
            )}
        </ActionDropdown>
    );
};
