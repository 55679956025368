import * as React from 'react';
import { useCallback } from 'react';
import { CancelToken } from 'axios';

import { backendApiService } from 'api/ApiService';
import { TraegerJsonldTraegerRead } from 'api/client';
import { InlineResponseTraegerJsonldTraegerRead } from 'api/responses/InlineResponse';
import { SelectOption, Typeahead } from 'forms/components/Typeahead';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';

import { RowControl } from './RowControl';

const TraegerAutocompleteComponent: React.FC<CustomControlProps<SelectOption | null>> = ({
    path,
    label,
    required,
    data,
    handleChange,
    schema,
    showFieldNumberLabels,
    gridLayout,
    id,
    config,
}) => {
    const handleValueChange = useCallback(
        (id: SelectOption | null) => {
            handleChange(path, id, true);
        },
        [handleChange, path]
    );

    const getData = (value: string, cancelToken: CancelToken): Promise<InlineResponseTraegerJsonldTraegerRead> => {
        return backendApiService.getTraegerCollection({
            page: 1,
            itemsPerPage: 10,
            qSearch: [value],
            options: {
                cancelToken: cancelToken,
            },
        });
    };

    const getOption = (item: TraegerJsonldTraegerRead): SelectOption => {
        return {
            id: item.id as number,
            name: item.name,
        };
    };

    return (
        <RowControl
            name={path}
            label={label}
            required={required}
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={gridLayout}
            labelForId={id}
            config={config}
            paths={[path]}
        >
            <Typeahead
                id={id}
                data={data}
                schema={schema}
                path={path}
                multiple={false}
                getData={getData}
                getOption={getOption}
                onChange={handleValueChange}
            />
        </RowControl>
    );
};

export const TraegerAutocompleteControl = withCustomControlProps(TraegerAutocompleteComponent);
