import { GroupControlType } from 'forms/types/UiSchemaTypes';

export const verteilzeitraume = {
    type: 'Group',
    label: 'Verteilzeitraum',
    scope: '#/properties/verteilzeitraeumeLalg',
    elements: [
        {
            type: 'FormExtendButton',
            label: 'Verteilzeitraum hinzufügen',
            scope: '#/properties/verteilzeitraeumeLalg',
            uiSchema: {
                type: 'CustomVerticalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/field0507',
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/field0508',
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/field0525',
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/field0526',
                    },
                ],
            },
        },
    ],
} as GroupControlType;
