import create from 'zustand';

import { EinrichtungResponse } from 'api/types';

export interface AnbieterDashboardState {
    benutzerId: number | undefined;
    einrichtung: EinrichtungResponse | undefined;
    isAntragSteller: boolean | undefined;
    setEinrichtung: (
        einrichtung: EinrichtungResponse | undefined,
        isAntragSteller?: (einrichtung: EinrichtungResponse) => Promise<boolean>
    ) => void;
    setBenutzerId: (benutzerId: number | undefined) => void;
    refreshEinrichtungen: number;
    triggerEinrichtungRefresh: () => void;
}

export const useAnbieterDashboardState = create<AnbieterDashboardState>((set, get) => ({
    benutzerId: undefined,
    einrichtung: undefined,
    isAntragSteller: undefined,
    setEinrichtung: async (einrichtung, isAntragSteller) => {
        set({ einrichtung });
        if (einrichtung) {
            set({ isAntragSteller: (await isAntragSteller?.(einrichtung)) ?? false });
        } else {
            set({ isAntragSteller: undefined });
        }
    },
    setBenutzerId: (newBenutzerId) => {
        const { benutzerId } = get();
        if (benutzerId === newBenutzerId) return;
        set({ benutzerId: newBenutzerId, einrichtung: undefined, isAntragSteller: undefined });
    },
    refreshEinrichtungen: 0,
    triggerEinrichtungRefresh: () => set({ refreshEinrichtungen: get().refreshEinrichtungen + 1 }),
}));
