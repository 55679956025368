import { ReactElement } from 'react';
import { JsonForms } from '@jsonforms/react';
import { Alert, Grid } from '@mui/material';

import { AntragType, ZurueckziehenAntragType } from 'api/antragTypes';
import {
    FestsetzungsAntragCollectionResponse,
    FeststellungsAntragCollectionResponse,
    InvestorenmodellCollectionResponse,
} from 'api/types';
import { Dialog } from 'components/Dialog/Dialog';
import { ACTIONS } from 'constants/antragActions';
import { ANTRAG_TYP_LABELS } from 'constants/labels';
import { FormButton } from 'forms/components/FormButton';
import { useLayoutWithJsonFormsState } from 'forms/hooks/useJsonFormsState';
import { renderers } from 'forms/renderers';
import { useConfirmationDialogActions } from 'forms/state/useConfirmationDialogState';
import { formatDateToString } from 'forms/utils/formatter';
import { determineAntragStatusAlertText } from 'pages/Antraege/antragStatusAenderung';

import { AntragListActionItem } from './useAntragListActions';
import { useZurueckziehenBeantragenDialog } from './useZurueckziehenBeantragenDialog';

type ZurueckziehenFeststellungBeantragenDialogProps = {
    antrag: FeststellungsAntragCollectionResponse | undefined;
    antragType: AntragType.FESTSTELLUNG;
    onClose: (isConfirmed: boolean) => void;
};

type ZurueckziehenFestsetzungBeantragenDialogProps = {
    antrag: FestsetzungsAntragCollectionResponse | undefined;
    antragType: AntragType.FESTSETZUNG;
    onClose: (isConfirmed: boolean) => void;
};

type ZurueckziehenInvestorenmodellBeantragenDialogProps = {
    antrag: InvestorenmodellCollectionResponse | undefined;
    antragType: AntragType.INVESTOREN_MODELL;
    onClose: (isConfirmed: boolean) => void;
};

type ZurueckziehenBeantragenDialogProps =
    | ZurueckziehenFeststellungBeantragenDialogProps
    | ZurueckziehenFestsetzungBeantragenDialogProps
    | ZurueckziehenInvestorenmodellBeantragenDialogProps;

export const ZurueckziehenBeantragenDialog = ({
    antrag,
    onClose,
    antragType,
}: ZurueckziehenBeantragenDialogProps): ReactElement => {
    const getDate = (): string | undefined | null => {
        switch (antragType) {
            case AntragType.FESTSTELLUNG:
                return antrag?.data?.field102;
            case AntragType.FESTSETZUNG:
                return antrag?.data?.field500;
            case AntragType.INVESTOREN_MODELL:
                return antrag?.festsetzungsantrag?.data?.field500;
        }
    };

    return (
        <Dialog
            open={Boolean(antrag)}
            title={`${ANTRAG_TYP_LABELS[antragType]} zum ${formatDateToString(getDate())} zurückziehen`}
            onClose={() => onClose(false)}
        >
            {antrag && (
                <ZurueckziehenBeantragenDialogContent initialData={antrag} antragType={antragType} onClose={onClose} />
            )}
        </Dialog>
    );
};

interface ZurueckziehenBeantragenDialogContentProps {
    initialData: AntragListActionItem;
    onClose: (isConfirmed: boolean) => void;
    antragType: ZurueckziehenAntragType;
}

const ZurueckziehenBeantragenDialogContent = ({
    initialData,
    onClose,
    antragType,
}: ZurueckziehenBeantragenDialogContentProps): ReactElement => {
    const { schema, uiSchema, data, onChange, onSubmit } = useZurueckziehenBeantragenDialog(initialData, antragType);
    const config = useLayoutWithJsonFormsState({
        gridLayout: {
            label: 0,
            input: 12,
        },
    });

    const { showConfirmation, closeConfirmation } = useConfirmationDialogActions();

    const onSave = async () => {
        const alertText = determineAntragStatusAlertText(initialData.status, ACTIONS.ZURUECKZIEHEN_BEANTRAGEN);
        const confirmLabel = 'Fortfahren';
        const denyLabel = 'Abbrechen';

        showConfirmation({
            alertText,
            confirmLabel,
            denyLabel,
            confirmAction: async () => {
                closeConfirmation();
                await onSubmit();
                onClose(true);
            },
            denyAction: closeConfirmation,
        });
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Alert severity="info">
                    Ihr Antrag ist bereits beim Landschaftsverband in Bearbeitung. Sofern Sie Änderungen bzw. eine
                    weitere Bearbeitung durchführen wollen, können Sie hier den Antrag zurückziehen. Vor einer weiteren
                    Bearbeitung muss der zuständige LV Ansprechpartner das Zurückziehen bestätigen.
                </Alert>
            </Grid>
            <Grid item xs={12}>
                <JsonForms
                    data={data}
                    uischema={uiSchema}
                    schema={schema}
                    onChange={onChange}
                    renderers={renderers}
                    config={config}
                />
            </Grid>
            <Grid item xs={12} container spacing={1} justifyContent="flex-end">
                <Grid item>
                    <FormButton type="button" variant="outlined" color="primary" onClick={() => onClose(false)}>
                        Abbrechen
                    </FormButton>
                </Grid>
                <Grid item>
                    <FormButton type="submit" variant="contained" color="primary" onClick={onSave}>
                        Antrag zurückziehen
                    </FormButton>
                </Grid>
            </Grid>
        </Grid>
    );
};
