import React, { ReactElement } from 'react';
import { Button, Grid } from '@mui/material';

import { useApiEinrichtungAnbieterPermissions } from 'api/hooks/useApiClient';
import { MeldungP29CollectionResponse } from 'api/types';

import { useAnbieterDashboardState } from './useAnbieterDashboardState';
import { useMeldungP29Helper } from './useMeldungP29Helper';

interface DashboardAnbieterMeldungP29AssistentProps {
    meldungenP29: MeldungP29CollectionResponse[];
}

export const DashboardAnbieterMeldungP29Assistent = ({
    meldungenP29,
}: DashboardAnbieterMeldungP29AssistentProps): ReactElement => {
    const { einrichtung } = useAnbieterDashboardState();
    const { data: permissions, isLoading } = useApiEinrichtungAnbieterPermissions(einrichtung?.id);
    const { isMeldungP29Allowed, goToMeldungP29 } = useMeldungP29Helper(einrichtung, meldungenP29);

    const handleOnClickMeldungP29 = () => {
        goToMeldungP29();
    };

    const meldungP29Permission = permissions?.permissions?.find(
        (permission) => permission.antragClass === 'MeldungP29'
    );

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Button
                    disabled={isLoading || !isMeldungP29Allowed || !meldungP29Permission?.allow}
                    variant="contained"
                    color="primary"
                    onClick={handleOnClickMeldungP29}
                >
                    Zur Meldung nach § 29 APG DVO
                </Button>
            </Grid>
        </Grid>
    );
};
