import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, ButtonProps, Menu, MenuProps, styled } from '@mui/material';

import { IconExpand } from 'elements/IconExpand';

interface DropdownProps {
    label: React.ReactElement | string;
    buttonProps?: ButtonProps;
    menuProps?: MenuProps;
    children?: React.ReactNode;
}

export const Dropdown = ({ label, buttonProps, menuProps, children }: DropdownProps) => {
    const [isOpen, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const location = useLocation();
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    useEffect(() => {
        setOpen(false);
    }, [location.pathname]);

    return (
        <>
            <DropdownButton onClick={handleClick} {...buttonProps} data-cy="dropdown">
                {label}
                <IconExpand open={isOpen} />
            </DropdownButton>
            <Menu
                open={isOpen}
                onClose={handleClose}
                {...menuProps}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {children}
            </Menu>
        </>
    );
};

export const DropdownButton = styled(Button)(() => ({
    color: '#000',
})) as typeof Button;
