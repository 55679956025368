import React, { ReactElement } from 'react';
import { Typography } from '@mui/material';

import { useAnbieterDashboardState } from 'pages/Dashboard/Anbieter/useAnbieterDashboardState';

type DashboardAnbieterHintsProps = {
    isAntragAvailable: boolean;
    isAntragAllowed: boolean;
    isLastAntragAvailable: boolean;
};

export const DashboardAnbieterHints = ({
    isAntragAvailable,
    isAntragAllowed,
    isLastAntragAvailable,
}: DashboardAnbieterHintsProps): ReactElement => {
    const { einrichtung, isAntragSteller } = useAnbieterDashboardState();
    const isEinrichtungSelected = Boolean(einrichtung);
    const isEinrichtungRuhend = einrichtung && einrichtung.status === 'RUHEND_VERZICHT_AUF_PFLEGEWOHNGELD';
    const isNotAntragstellerButAntraegeAvailable =
        einrichtung && !isEinrichtungRuhend && isAntragSteller === false && isAntragAvailable;
    const isNotAntragStellerAndNoAntraegeAvailable =
        !isEinrichtungRuhend && isAntragSteller === false && !isAntragAvailable;
    const isAntragStellerButNoAntraegeAllowed = !isEinrichtungRuhend && isAntragSteller && !isAntragAllowed;
    const isAntragStellerButNoLastAntragAvailable = !isEinrichtungRuhend && isAntragSteller && !isLastAntragAvailable;

    return (
        <>
            <Typography variant="h1">
                {isEinrichtungRuhend
                    ? 'Meldung nach § 29 APG DVO'
                    : isAntragSteller
                    ? 'Antragstellung'
                    : 'Willkommen bei PfAD.invest'}
            </Typography>

            {isEinrichtungRuhend && (
                <Typography paragraph>
                    Diese Einrichtung nimmt nicht am Verfahren PfAD.invest teil. Sie haben die Möglichkeit, Ihre Meldung
                    nach § 29 abzugeben.
                </Typography>
            )}

            {!isEinrichtungSelected && (
                <Typography paragraph>
                    Wählen Sie auf der rechten Seite eine Pflegeeinrichtung aus um zu beginnen.
                </Typography>
            )}

            {isNotAntragstellerButAntraegeAvailable && (
                <Typography paragraph>
                    Im Bereich letzte Aktivitäten können Sie Anträge zur dieser Einrichtung zur Ansicht öffnen.
                </Typography>
            )}

            {isNotAntragStellerAndNoAntraegeAvailable && (
                <Typography paragraph>
                    Für die gewählte Einrichtung liegen noch keine Anträge in PfAD.invest vor. Nachdem der zuständige
                    Antragssteller Anträge gestellt hat, können Sie diese im Bereich letzte Aktivitäten zur Ansicht
                    öffnen.
                </Typography>
            )}

            {isAntragStellerButNoAntraegeAllowed && (
                <Typography paragraph>
                    Momentan sind Ihre Anträge zu Feststellung und Festsetzung in Bearbeitung beim zuständigen
                    Landschaftsverband. Neue Anträge können nach erfolgter Bescheidung gestellt werden.
                </Typography>
            )}

            {isAntragStellerButNoLastAntragAvailable && (
                <>
                    <Typography paragraph>
                        Für die gewählte Einrichtung können Sie nun die Feststellung und die Festsetzung gemäß den
                        Vorschriften des APG NRW und der APG DVO NRW für die o.g. Pflegeeinrichtung beantragen.
                    </Typography>
                    <Typography paragraph>
                        Die Handbücher für die Antragsstellung finden Sie hier. (TODO: Link zu den Handbüchern einfügen)
                    </Typography>
                </>
            )}
        </>
    );
};
