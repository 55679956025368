import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { TraegerListResponse } from 'api/types';
import { GetData } from 'components/DataTableHydra/hooks/useTableData';

export const useTraegerverbandTraegerListData = (id: string): GetData<TraegerListResponse> => {
    return useCallback(
        ({ page, rowsPerPage, searchText }) => {
            return backendApiService.getTraegerCollection({
                page,
                itemsPerPage: rowsPerPage,
                qSearch: searchText ? [searchText] : undefined,
                traegerverband: id,
            });
        },
        [id]
    );
};
