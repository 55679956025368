import { AxiosRequestConfig } from 'axios';

import { ApiBaseService } from './ApiBaseService';
import { ContextsApi } from './contexts/api';

class ApiContextsService extends ApiBaseService {
    public async getContext(id: string, options?: AxiosRequestConfig) {
        return await new ContextsApi(undefined, '', this.client).getContext(id, options);
    }
}

export const backendApiContextsService = new ApiContextsService();
