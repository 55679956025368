import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';

import { AntragType } from 'api/antragTypes';
import { backendApiService } from 'api/ApiService';
import { FestsetzungsAntragCollectionListResponse } from 'api/types';
import { useColumnsFestsetzung } from 'components/DataTableHydra/Columns/Festsetzung';
import { DataTableHydraServerside } from 'components/DataTableHydra/DataTableHydraServerside';
import { GetData } from 'components/DataTableHydra/hooks/useTableData';
import { zustaendigerBenutzerFilter } from 'components/DataTableHydra/tableUtils';
import { ContentContainer } from 'layout/container/ContentContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { withAuthorization } from 'navigation/withAuthorization';
import { useAntragFilterOptions } from 'pages/Antraege/components/useAntragFilterOptions';
import { useFestsetzungsAntragListActions } from 'pages/Antraege/Festsetzung/useFestsetzungsAntragListActions';
import { VerwaltungConfig } from 'pages/Verwaltung/VerwaltungConfig';

const AntragshistorieFestsetzung = () => {
    const { id } = useParams();

    const getData: GetData<FestsetzungsAntragCollectionListResponse> = useCallback(
        ({ page, rowsPerPage, searchText, sortOrder, filters }) => {
            return backendApiService.getFestsetzungsantragCollection({
                page,
                itemsPerPage: rowsPerPage,
                qSearch: searchText ? [searchText] : undefined,
                einrichtung: id,
                orderZuletztEingereichtAt:
                    sortOrder?.name === 'zuletztEingereichtAt' ? sortOrder?.direction : undefined,
                orderField500: sortOrder?.name === 'field500' ? sortOrder?.direction : undefined,
                orderEinrichtungAktenzeichen:
                    sortOrder?.name === 'einrichtung.aktenzeichen' ? sortOrder?.direction : undefined,
                orderFeststellungsantragBerechnungsart:
                    sortOrder?.name === 'feststellungsantrag.berechnungsart' ? sortOrder?.direction : undefined,
                orderEinrichtungName: sortOrder?.name === 'einrichtung.name' ? sortOrder?.direction : undefined,
                orderStatus: sortOrder?.name === 'status' ? sortOrder?.direction : undefined,
                orderEinrichtungZustaendigerBenutzer:
                    sortOrder?.name === 'einrichtung.zustaendigerBenutzer.name' ? sortOrder?.direction : undefined,
                status2: filters?.['status'],
                berechnungsart: filters?.['berechnungsart'],
                antragJahr: filters?.['jahr'],
                zustaendigerBenutzer: zustaendigerBenutzerFilter(filters),
            });
        },
        [id]
    );

    const { handleAction, serverDataChanged } = useFestsetzungsAntragListActions(AntragType.FESTSETZUNG);
    const filterOptions = useAntragFilterOptions(AntragType.FESTSETZUNG);
    const columnsFestsetzung = useColumnsFestsetzung(handleAction, filterOptions);

    return (
        <ContentContainer title="Anträge auf Festsetzung (Antragshistorie)">
            <Typography variant="h1">Anträge auf Festsetzung (Antragshistorie)</Typography>
            <DataTableHydraServerside
                getColumns={columnsFestsetzung}
                getData={getData}
                initialOptions={{
                    searchPlaceholder: 'Suche nach Einrichtungsname oder Aktenzeichen',
                    sortOrder: {
                        name: 'zuletztEingereichtAt',
                        direction: 'desc',
                    },
                }}
                serverDataChanged={serverDataChanged}
            />
        </ContentContainer>
    );
};

export const AntragshistorieFestsetzungPage = withAuthorization(
    withSideBar(AntragshistorieFestsetzung, VerwaltungConfig.menu),
    VerwaltungConfig.roles.pfelgeeinrichtung
);
