import React, { ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { MUIDataTableColumn } from 'mui-datatables';

import { useDebounce } from 'utilities/useDebounce';

export type LazyAutocompleteFilterPropsBase = {
    value: string;
    index: number;
    column: MUIDataTableColumn;
    onChange: (val: string | string[], index: number, column: MUIDataTableColumn) => void;
};

type Identifiable = {
    id: number;
    name: string;
};

type LazyAutocompleteFilterProps<T> = LazyAutocompleteFilterPropsBase & {
    queryFetcher: (query: string) => Promise<T[]>;
    idFetcher: (id: string | null) => Promise<T | null>;
    isOptionEqualToValue: (option: T, value: T) => boolean;
    noOptionsText: string;
    loadingText: string;
};

export const LazyAutocompleteFilter = <T extends Identifiable>({
    value,
    onChange,
    index,
    column,
    queryFetcher,
    idFetcher,
    isOptionEqualToValue,
    noOptionsText,
    loadingText,
}: LazyAutocompleteFilterProps<T>): ReactElement => {
    const [query, setQuery] = useState('');
    const debouncedQuery = useDebounce(query, 300);
    const [options, setOptions] = useState<T[]>([]);
    const [isLoading, setLoading] = useState(false);
    const [selected, setSelected] = useState<T | null>(null);
    const id = selected?.id?.toString();

    useEffect(() => {
        if (!value) {
            setSelected(null);
            return;
        }
        if (value === id) {
            return;
        }
        idFetcher(value).then(setSelected).catch(console.error);
    }, [value, id, idFetcher]);

    useEffect(() => {
        if (debouncedQuery.length < 3) return;
        setLoading(true);
        queryFetcher(debouncedQuery)
            .then(setOptions)
            .catch(console.error)
            .finally(() => setLoading(false));
    }, [queryFetcher, debouncedQuery]);

    const onInputChange = (_: SyntheticEvent, newValue: string) => {
        setQuery(newValue);
    };

    const onChangeBase = (_: SyntheticEvent, newValue: T | null) => {
        setSelected(newValue);
        const id = newValue?.id?.toString();
        onChange(id ? [id] : [], index, column);
    };

    return (
        <>
            <Autocomplete
                value={selected}
                onChange={onChangeBase}
                onInputChange={onInputChange}
                loadingText={loadingText}
                noOptionsText={noOptionsText}
                getOptionLabel={(value) => value.name}
                isOptionEqualToValue={isOptionEqualToValue}
                options={options}
                loading={isLoading}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={column.label}
                        InputProps={
                            isLoading
                                ? {
                                      ...params.InputProps,
                                      endAdornment: isLoading ? <CircularProgress color="inherit" size={20} /> : null,
                                  }
                                : params.InputProps
                        }
                    />
                )}
            />
        </>
    );
};
