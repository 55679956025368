import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/AntragForm/ui-schemas/elements/customVerticalLayout';
import { formExtendButton } from 'forms/AntragForm/ui-schemas/elements/formExtendButton';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { info } from 'forms/AntragForm/ui-schemas/elements/info';
import { inlineRowControl } from 'forms/AntragForm/ui-schemas/elements/inlineRowControl';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';

export const uiSchema = verticalLayout([
    group([
        info(
            'Sie haben auf die Inanspruchnahme von Pflegewohngeld nach § 14 APG NRW verzichtet. Nach § 29 APG DVO NRW i.V.m. § 82 Abs. 4 SGB XI sind sie demnach verpflichtet, die in Abrechnung gebrachten Investitionskosten gegenüber dem Landschaftsverband Rheinland (oder Westfalen) im Folgenden anzuzeigen.'
        ),
        control('fieldM2901', { options: { firstControl: true } }),
        control('fieldM2902'),
        control('fieldM2909'),
        control('fieldM2903'),
        group(
            [
                formExtendButton(
                    customVerticalLayout([
                        inlineRowControl(
                            [
                                control('fieldM2904', { controlOnly: true, options: { firstControl: true } }),
                                control('fieldM2904Art', { controlOnly: true }),
                            ],
                            '#/properties/fieldM2904',
                            {
                                gridLayout: {
                                    fieldnumber: 1,
                                    label: 3,
                                    input: 6,
                                },
                            }
                        ),
                        control('fieldM2905'),
                        control('fieldM2906'),
                    ]),
                    '#/properties/zimmerarten',
                    'Zimmerart hinzufügen'
                ),
            ],
            { noSeparator: true },
            'Höhe der mit dem öSHT und den Selbstzahlern vereinbarten Investkosten je Kategorie/Tag:'
        ),
        group([control('fieldM2907'), control('fieldM2908')], {}, 'IK-Satz täglich'),
    ]),
]);
