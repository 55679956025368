import React from 'react';

import { useAuthContextImpersonate } from 'api/auth/useAuthContextImpersonate';
import { HandleJwtToken, JwtPayload, JwtState, Logout, useAuthContextJwtToken } from 'api/auth/useAuthContextJwtToken';
import { useAuthContextLandschaftsverbandView } from 'api/auth/useAuthContextLandschaftsverbandView';
import { useAuthContextSessionRecovery } from 'api/auth/useAuthContextSessionRecovery';

type AuthProviderProps = { children: React.ReactNode };

export interface AuthContextInterface {
    jwt: JwtState;
    isImpersonated: boolean;
    handleJwtToken: HandleJwtToken;
    logout: Logout;
    setImpersonatedBenutzer: (impersonatedBenutzer?: JwtPayload) => void;
    getImpersonatedBenutzer: () => JwtPayload | undefined;
    isLandschaftsverbandView: boolean;
    setLandschaftsverbandView: (prev: boolean) => void;
}

const AuthContext = React.createContext<AuthContextInterface | undefined>(undefined);

export const AuthProvider = ({ children }: AuthProviderProps) => {
    const { isLandschaftsverbandView, setLandschaftsverbandView, clearLandschaftsverbandView } =
        useAuthContextLandschaftsverbandView();
    const { jwt, handleJwtToken, logout } = useAuthContextJwtToken(clearLandschaftsverbandView);
    const { isImpersonated, getImpersonatedBenutzer, setImpersonatedBenutzer } = useAuthContextImpersonate();
    const { isRecovering } = useAuthContextSessionRecovery(handleJwtToken);

    const value: AuthContextInterface = {
        jwt,
        isImpersonated,
        handleJwtToken,
        logout,
        setImpersonatedBenutzer,
        getImpersonatedBenutzer,
        isLandschaftsverbandView,
        setLandschaftsverbandView,
    };

    return <AuthContext.Provider value={value}>{!isRecovering && children}</AuthContext.Provider>;
};

export const useAuth = () => {
    const context = React.useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within a AuthProvider');
    }

    return context;
};
