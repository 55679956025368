import { RuleEffect } from '@jsonforms/core';

import { BESCHEID_DOKUMENT } from 'constants/bescheidDokument';
import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { customHorizontalLayout } from 'forms/AntragForm/ui-schemas/elements/customHorizontalLayout';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { info } from 'forms/AntragForm/ui-schemas/elements/info';
import { inlineRowControl } from 'forms/AntragForm/ui-schemas/elements/inlineRowControl';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';

export const uiSchema = verticalLayout([
    group([
        control('field1011'),
        group(
            [
                {
                    type: 'LabelRowControl',
                    text: 'Legen Sie fest, ob ein Dokument automatisiert vom System erzeugt oder manuell von Ihnen zur Verfügung gestellt werden soll. Sobald Sie ein Dokument manuell hochgeladen haben, wird dieses anstelle des vom System erzeugten Dokuments versendet.<br /><br />Durck Klick auf "PDF" oder "DOCX" laden Sie das vom System generierte Dokument im jeweilgen Format herunter.<br /><br />Zusätzliche Dokumente, die im Rahmen der Bescheidung versendet werden sollen, laden Sie bitte als Anlage zum Festsetzungsbescheid hoch.',
                },
            ],
            { noSeparator: true },
            'Versandart festlegen und Dokumente herunterladen'
        ),
        group(
            [
                customHorizontalLayout(
                    [
                        inlineRowControl([
                            {
                                type: 'LabelRowControl',
                                text: 'Dokument',
                                styles: {
                                    fontWeight: 'bold',
                                },
                            },
                            {
                                type: 'LabelRowControl',
                                text: 'Art des Versands',
                                styles: {
                                    fontWeight: 'bold',
                                },
                            },
                            {
                                type: 'LabelRowControl',
                                text: 'Systemdokument zur Ansicht herunterladen',
                                styles: {
                                    fontWeight: 'bold',
                                },
                            },
                        ]),

                        group([
                            inlineRowControl(
                                [
                                    control('field1900', {
                                        controlOnly: true,
                                    }),
                                    {
                                        type: 'BescheidDokumentGenerierenLink',
                                        scope: '#/properties/field1900',
                                        href:
                                            'files/investorenmodell/{antragId}/dokument/' + BESCHEID_DOKUMENT.BESCHEID,
                                    },
                                ],
                                '#/properties/field1900',
                                {
                                    gridLayout: {
                                        fieldnumber: 1,
                                        label: 3,
                                        input: 8,
                                    },
                                }
                            ),
                            customHorizontalLayout(
                                [
                                    control('field1900Upload', {
                                        controlOnly: true,
                                    }),
                                ],
                                {
                                    gridSize: 3,
                                    spacing: 2,
                                    style: { paddingLeft: '8.333333%' },
                                }
                            ),
                        ]),
                        group([
                            inlineRowControl(
                                [
                                    control('field1900a', {
                                        controlOnly: true,
                                    }),
                                ],
                                '#/properties/field1900a',
                                {
                                    gridLayout: {
                                        fieldnumber: 1,
                                        label: 3,
                                        input: 8,
                                    },
                                }
                            ),
                            customHorizontalLayout(
                                [
                                    control('field1900aUpload', {
                                        controlOnly: true,
                                    }),
                                ],
                                {
                                    gridSize: 3,
                                    spacing: 2,
                                    style: { paddingLeft: '8.333333%' },
                                }
                            ),
                        ]),
                        group([control('field1904')]),
                    ],
                    {
                        gridSize: 12,
                        spacing: 2,
                        style: {},
                    }
                ),
            ],
            { noSeparator: true }
        ),
        group(
            [
                control('field1900p'),
                info(
                    'Es wurde kein automatisierter Versand gewählt. <b>Der Antragsteller wird <u>nicht</u> über die Bescheidung informiert.</b><br>Bevor Sie den Bescheid und die dazugehörigen Dokumente herunterladen können, muss ein Bescheiddatum auf der Startseite im Bereich Bescheidversand gesetzt werden.',
                    {
                        rule: {
                            effect: RuleEffect.SHOW,
                            condition: {
                                // @ts-ignore
                                scope: '#/properties/field1900p',
                                schema: {
                                    const: true,
                                },
                            },
                        },
                    }
                ),
            ],
            { noSeparator: true }
        ),
    ]),
]);
