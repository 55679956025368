import React, { FunctionComponent, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import { BenutzerDialogConfig } from 'components/AntragInfoBox/config/AntragInfoBoxConfig';
import { Link } from 'elements/Link';
import { FormButton } from 'forms/components/FormButton';

export interface Benutzer {
    id?: any;
    vorname: string;
    nachname: string;
    email?: string;
    telefon?: string | null;
}

interface PropsInterface {
    benutzer: Benutzer;
    children?: React.ReactNode;
    className?: string;
}

export const BenutzerDialog: FunctionComponent<PropsInterface> = ({ benutzer, children, className }) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Link onClick={handleClickOpen} className={className}>
                {children}
            </Link>

            <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md" data-testid={'benutzer-dialog'}>
                <DialogTitle id="alert-dialog-title">{BenutzerDialogConfig.title}</DialogTitle>

                <DialogContent>
                    <DialogContentText component="div" id="alert-dialog-description">
                        <dl>
                            <dt>{BenutzerDialogConfig.content.name}</dt>
                            <dd>{`${benutzer.vorname} ${benutzer.nachname}`.trim()}</dd>

                            <>
                                <dt>{BenutzerDialogConfig.content.telefon}</dt>
                                <dd>{benutzer.telefon || '-'} </dd>
                            </>

                            <dt>{BenutzerDialogConfig.content.email}</dt>
                            <dd>{benutzer.email}</dd>
                        </dl>
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <FormButton type="submit" variant="contained" color="primary" onClick={handleClose}>
                        {BenutzerDialogConfig.confirm}
                    </FormButton>
                </DialogActions>
            </Dialog>
        </>
    );
};
