import { Typography } from '@mui/material';

import { AntragType } from 'api/antragTypes';
import { useColumnsFestsetzung } from 'components/DataTableHydra/Columns/Festsetzung';
import { DataTableHydraServerside } from 'components/DataTableHydra/DataTableHydraServerside';
import { ROLES } from 'constants/roles';
import { ContentContainer } from 'layout/container/ContentContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { withAuthorization } from 'navigation/withAuthorization';
import { menuAntraege } from 'pages/Antraege/AntragConfig';
import { useAntragFilterOptions } from 'pages/Antraege/components/useAntragFilterOptions';
import { ZurueckziehenAkzeptierenDialog } from 'pages/Antraege/components/ZurueckziehenAkzeptierenDialog';
import { ZurueckziehenBeantragenDialog } from 'pages/Antraege/components/ZurueckziehenBeantragenDialog';
import { useFestsetzungsAntragListActions } from 'pages/Antraege/Festsetzung/useFestsetzungsAntragListActions';
import { useFestsetzungsAntragListData } from 'pages/Antraege/Festsetzung/useFestsetzungsAntragListData';

const FestsetzungsAntragList = () => {
    const getData = useFestsetzungsAntragListData();
    const {
        handleAction,
        serverDataChanged,
        zurueckziehenBeantragen,
        zurueckziehenBeantragenFinishAction,
        zurueckziehenAkzeptieren,
        zurueckziehenAkzeptierenFinishAction,
    } = useFestsetzungsAntragListActions(AntragType.FESTSETZUNG);
    const filterOptions = useAntragFilterOptions(AntragType.FESTSETZUNG);
    const columnsFestsetzung = useColumnsFestsetzung(handleAction, filterOptions);

    return (
        <ContentContainer title="Festsetzungsanträge">
            <Typography variant="h1">Anträge auf Festsetzung</Typography>
            <DataTableHydraServerside
                getColumns={columnsFestsetzung}
                getData={getData}
                initialOptions={{
                    searchPlaceholder: 'Suche nach Einrichtungsname, Aktenzeichen oder NRW-Schlüssel',
                    sortOrder: {
                        name: 'zuletztEingereichtAt',
                        direction: 'desc',
                    },
                }}
                serverDataChanged={serverDataChanged}
            />
            <ZurueckziehenBeantragenDialog
                antrag={zurueckziehenBeantragen}
                onClose={zurueckziehenBeantragenFinishAction}
                antragType={AntragType.FESTSETZUNG}
            />

            <ZurueckziehenAkzeptierenDialog
                antrag={zurueckziehenAkzeptieren}
                onClose={zurueckziehenAkzeptierenFinishAction}
                antragType={AntragType.FESTSETZUNG}
            />
        </ContentContainer>
    );
};

export const FestsetzungsAntragListPage = withAuthorization(withSideBar(FestsetzungsAntragList, menuAntraege), [
    ROLES.FACHADMIN,
    ROLES.SUPPORT,
    ROLES.LV,
    ROLES.ANBIETER, // TODO: ROLES.ANBIETER entfernen
]);
