import React from 'react';
import { List, styled } from '@mui/material';

import { useAuthHelper } from 'api/auth/useAuthHelper';

import { MenuInterface } from './HeaderConfig';
import { NavigationMenuItem } from './menu/NavigationMenuItem';

interface SidebarProps {
    menus: MenuInterface[];
}

export const StyledSidebar = styled(List)(
    ({ theme }) => `
    padding-top: ${theme.mixins.header.height}px;
    color: ${theme.palette.common.white};
    background: #233755;

    & .MuiButtonBase-root {
        background: #325376;
        margin-bottom: 2px;

        &:hover {
            background: #006ECB;
        }

        &:focus {
            background: #338bd5;
        }
        &.Mui-selected {
            background: #006ECB;
            &:focus {
                background: #338bd5;
        }
    }
    & .MuiCollapse-root {
        background: #2F486D;

        & .MuiButtonBase-root {
            background: #2F486D;
            margin-bottom: 0;

            &:hover {
                background: #006ECB;
            }

            &:focus {
                background: #338bd5;
            }
        }
    }

`
) as typeof List;

export const Sidebar: React.FC<SidebarProps> = ({ menus }) => {
    const { hasRole } = useAuthHelper();
    return (
        <StyledSidebar component="nav" role="navigation" aria-label="sidebar">
            {menus
                .filter((menu) => hasRole(menu.roles))
                .map((menu, i) => (
                    <NavigationMenuItem key={i} {...menu} />
                ))}
        </StyledSidebar>
    );
};
