import { GridSize } from '@mui/material';

import { DataGridType, TableColumn } from 'forms/types/UiSchemaTypes';

export const dataGrid = (
    field: string,
    columns: TableColumn[],
    options: {
        containerGridSize?: GridSize;
    } = {}
): DataGridType => {
    return {
        type: 'DataGrid',
        scope: `#/properties/${field}`,
        columns,
        ...options,
    };
};
