import { useMemo } from 'react';

import { AntragBescheidungResponse, AntragResponse, AntragVersionResponse } from 'api/types';
import { STATUS } from 'constants/antragStatus';

export const useAntragBescheidung = (
    version: AntragVersionResponse | null,
    antrag: AntragResponse
): AntragBescheidungResponse | null => {
    return useMemo(() => {
        if (version) {
            return version.bescheidung ?? null;
        } else {
            if (![STATUS.BESCHIEDEN, STATUS.AUFGEHOBEN].includes(antrag.status)) return null;
            return antrag.letzteBescheidung ?? null;
        }
    }, [version, antrag]) as AntragBescheidungResponse | null;
};
