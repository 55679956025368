import { ReactElement } from 'react';
import { JsonFormsDispatch } from '@jsonforms/react';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { CustomLayoutProps, withLayoutProps } from 'forms/hooks/withJsonFormProps';
import { TenorTableType } from 'forms/types/UiSchemaTypes';

type TenorTableComponentProps = CustomLayoutProps<TenorTableType>;

export const TenorTableComponent = ({ uischema, ...other }: TenorTableComponentProps): ReactElement => {
    const isEmpty = false;
    return (
        <Grid container style={{ ...uischema.styles }}>
            <Grid item xs={12}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Ziffer</TableCell>
                            <TableCell>Thematik</TableCell>
                            <TableCell>Geänderte Schlüsselfelder</TableCell>
                            <TableCell>zu bescheiden</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(!uischema.elements || isEmpty) && (
                            <TableRow>
                                <TableCell colSpan={4}></TableCell>
                            </TableRow>
                        )}
                        {uischema.elements &&
                            uischema.elements.map((element, index) => (
                                <JsonFormsDispatch key={index} uischema={element} {...other} />
                            ))}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    );
};

export const TenorTable = withLayoutProps(TenorTableComponent);
