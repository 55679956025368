import React from 'react';
import HtmlParser from 'react-html-parser';
import { FormControl, FormHelperText, FormLabel } from '@mui/material';

import { ComponentError } from 'forms/hooks/useErrors';

import { ErrorList } from './ErrorList';

interface ReadonlyValueTextProps {
    path: string;
    text: string;
    hasErrors: boolean;
    errors: ComponentError[];
    textAlign?: 'left' | 'right';
}

export const ReadonlyValueText: React.FC<ReadonlyValueTextProps> = ({
    path,
    text,
    errors,
    hasErrors,
    textAlign = 'right',
}) => {
    return (
        <FormControl fullWidth>
            <FormLabel component={'legend'} style={{ textAlign, paddingRight: 12 }} data-cy={`form_readonly_${path}`}>
                {HtmlParser(text)}
            </FormLabel>
            <FormHelperText component={'div'} error>
                {hasErrors ? <ErrorList errors={errors} /> : ' '}
            </FormHelperText>
        </FormControl>
    );
};
