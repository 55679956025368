import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { ZustaendigkeitListResponse } from 'api/types';
import { GetData } from 'components/DataTableHydra/hooks/useTableData';
import { sortBy } from 'components/DataTableHydra/tableUtils';

export const useZustaendigkeitenRegionListData = (): GetData<ZustaendigkeitListResponse> => {
    return useCallback(({ page, rowsPerPage, searchText, sortOrder, filters }) => {
        return backendApiService.getZustaendigkeitCollection({
            page,
            itemsPerPage: rowsPerPage,
            qSearch: searchText ? [searchText] : undefined,
            kreis: filters?.['kreis']?.[0],
            orderRegion: sortBy(sortOrder, 'region'),
            orderOrt: sortBy(sortOrder, 'ort'),
            orderKreis: sortBy(sortOrder, 'kreis'),
            orderEinrichtungArt: sortBy(sortOrder, 'einrichtungArt'),
            orderBenutzerName: sortBy(sortOrder, 'benutzer.name'),
        });
    }, []);
};
