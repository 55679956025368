import React, { ReactElement } from 'react';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';

import { useApiEigenkapitalzinsenBundesbankCollection } from 'api/hooks/useApiClient';
import { UpdateableFormTable } from 'components/FormModal/UpdateableFormTable';
import { DezimalzahlProzent as Prozent } from 'elements/DezimalzahlProzent';
import { ContentContainer } from 'layout/container/ContentContainer';
import { withSideBar } from 'layout/hooks/useSideBar';

const EigenkapitalzinsenGemaessBundesbank: React.FC = (): ReactElement => {
    const columns: MUIDataTableColumnDef[] = [
        { name: 'monat', label: 'Zeit' },
        {
            name: 'value',
            label: 'Eigenkapitalzinsen gemäß Bundesbank',
            options: {
                customBodyRender: Prozent,
            },
        },
    ];

    const options: MUIDataTableOptions = {
        sortOrder: {
            name: 'monat',
            direction: 'desc',
        },
        filter: false,
    };

    const content = {
        headline: 'Eigenkapitalzinsen gemäß Bundesbank',
    };

    const table = {
        columns,
        options,
        callback: useApiEigenkapitalzinsenBundesbankCollection,
        callbackType: undefined,
    };

    return (
        <ContentContainer title="Eigenkapitalzinsen gemäß Bundesbank">
            <UpdateableFormTable content={content} table={table} />
        </ContentContainer>
    );
};

export const EigenkapitalzinsenGemaessBundesbankPage = withSideBar(EigenkapitalzinsenGemaessBundesbank, []);
