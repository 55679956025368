import React from 'react';
import { Grid } from '@mui/material';

import { AntragType } from 'api/antragTypes';
import { useApiAntragVersion } from 'api/hooks/useApiClient';
import { AntragResponse, AntragVersionCollectionResponse } from 'api/types';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { Dialog } from 'components/Dialog/Dialog';
import { FormButton } from 'forms/components/FormButton';
import { TabNavigation } from 'layout/menu/TabNavigation';
import { AntragDetailsHeader } from 'pages/Antraege/components/AntragDetailsHeader';
import { AntragDetailsVersion } from 'pages/Antraege/components/AntragDetailsVersion';
import { useAntragDetailsUuidMap } from 'pages/Antraege/components/useAntragDetailsUuidMap';
import { useAntragVersionDetailsTabConfig } from 'pages/Antraege/components/useAntragVersionDetailsTabConfig';
import { useAntragVersionInfo } from 'pages/Antraege/components/useAntragVersionInfo';

interface AntragVersionDetailsDialogProps {
    type: AntragType;
    antrag: AntragResponse;
    version: AntragVersionCollectionResponse | null;
    onClose: () => void;
    initialTab?: number;
}

export const AntragDetailsVersionDialog = ({
    type,
    antrag,
    version: collectionVersion,
    onClose,
    initialTab = 0,
}: AntragVersionDetailsDialogProps): React.ReactElement => {
    const { data: version, isLoading } = useApiAntragVersion(
        collectionVersion?.typ !== 'CURRENT' ? collectionVersion?.id : undefined,
        type
    );
    useAntragDetailsUuidMap(version, antrag);
    const versionInfo = useAntragVersionInfo(type, version ?? null, antrag);
    const tabs = useAntragVersionDetailsTabConfig(version ?? null, antrag);

    return (
        <Dialog
            open
            title={determineTitle(type)}
            onClose={onClose}
            maxWidth="lg"
            actions={
                <FormButton variant="contained" color="primary" onClick={onClose}>
                    Schließen
                </FormButton>
            }
        >
            <AppLoaderContainer isLoading={isLoading}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <AntragDetailsHeader antrag={antrag} />
                    </Grid>
                    {versionInfo && (
                        <Grid item xs={12}>
                            <AntragDetailsVersion version={versionInfo} />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <TabNavigation tabs={tabs} defaultTabIndex={initialTab} />
                    </Grid>
                </Grid>
            </AppLoaderContainer>
        </Dialog>
    );
};

const determineTitle = (type: AntragType): string => {
    switch (type) {
        case AntragType.FESTSTELLUNG:
            return 'Antrag auf Feststellung';
        case AntragType.FESTSETZUNG:
            return 'Antrag auf Festsetzung';
        case AntragType.INVESTOREN_MODELL:
            return 'Antrag nach § 8 Abs. 11';
        case AntragType.MELDUNG_P29:
            return 'Meldung nach § 29';
    }
};
