import { Rule } from '@jsonforms/core/lib/models/uischema';

import { GroupControlType, UiSchemaType } from 'forms/types/UiSchemaTypes';

export const group = (
    elements: UiSchemaType[],
    options: {
        noSeparator?: boolean;
        rule?: Rule;
    } = {},
    label?: string,
    scope?: string,
    labelValue?: string,
    labelScope?: string
): GroupControlType => ({
    type: 'Group',
    ...(label && { label: `${label}` }),
    ...(options.rule && { rule: options.rule }),
    labelValue,
    labelScope,
    scope,
    elements,
    options,
});
