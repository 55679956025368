import React, { ComponentType } from 'react';
import { ControlProps } from '@jsonforms/core';
import { isFunction } from 'lodash';

import { ComponentError, useErrors } from 'forms/hooks/useErrors';
import { withControlProps } from 'forms/hooks/withJsonFormProps';
import { FormConfig, FormStateValue, GridLayoutConfig, Schema, UiSchemaType } from 'forms/types/UiSchemaTypes';

import { useDisabled } from './useDisabled';
import { useReadonly } from './useReadonly';
import { useUnit } from './useUnit';

export interface CustomControlOptions {
    showErrorsImmediately: boolean;
}

export type CustomControlPropsIn<T = FormStateValue, S extends UiSchemaType = UiSchemaType> = CustomControlProps<
    T,
    S
> & {
    errors: string | ComponentError[];
};

export type CustomControlOnChange<T = FormStateValue> = (
    path: string,
    data: T | undefined | null,
    validate?: boolean,
    modelPathOverride?: string
) => void;

export type CustomControlProps<T = FormStateValue, S extends UiSchemaType = UiSchemaType> = Omit<
    ControlProps,
    'errors' | 'data' | 'handleChange' | 'handleBlur' | 'schema' | 'uischema' | 'config'
> & {
    hasErrors: boolean;
    errors: ComponentError[];
    data: T;
    unit?: string;
    onTouched: () => void;
    disabled: boolean;
    readonly: boolean;
    formula?: string;
    schema: Schema;
    config: FormConfig;
    uischema: S;
    handleChange: (path: string, data: T | undefined | null, validate?: boolean) => void;
    handleBlur: () => void;
    showFieldNumberLabels?: boolean;
    gridLayout?: GridLayoutConfig;
};

export const withCustomControlPropsHOC = <T extends FormStateValue, S extends UiSchemaType>(
    Component: React.FC<CustomControlPropsIn<T, S>>,
    options?: CustomControlOptions
): React.FC<CustomControlProps<T, S>> => {
    return ({ schema, uischema, config, path, handleChange, errors: jsonFormError, required, data, ...others }) => {
        const unit = useUnit(schema as Schema);
        const formula = (schema as Schema)?.custom?.formula;
        const disabled = useDisabled();
        const readonly = useReadonly(schema as Schema, config, uischema);
        const { hasErrors, errors, setTouched } = useErrors({
            config,
            path,
            jsonFormError,
            immediately: options?.showErrorsImmediately || ((!Array.isArray(data) || data.length > 0) && Boolean(data)),
        });

        const onChange: CustomControlOnChange<T> = (path, data, validate, modelPathOverride) => {
            setTouched(true);
            validate &&
                isFunction(config.requestValidate) &&
                config.requestValidate(modelPathOverride ?? (schema as Schema).custom?.model_path);
            handleChange(path, data);
        };

        const validate = () => {
            setTouched(true);
            isFunction(config.validate) && config.validate(schema.custom?.model_path);
        };

        return (
            <Component
                {...others}
                hasErrors={hasErrors}
                errors={errors}
                disabled={disabled}
                unit={unit}
                formula={formula}
                data={data}
                schema={schema}
                uischema={uischema}
                config={config}
                readonly={readonly}
                handleChange={onChange}
                handleBlur={validate}
                required={required}
                onTouched={() => setTouched(true)}
                path={path}
                showFieldNumberLabels={config.showFieldNumberLabels}
                gridLayout={(uischema as UiSchemaType).gridLayout || config.gridLayout}
            />
        );
    };
};

export const withCustomControlProps = <T extends FormStateValue, S extends UiSchemaType>(
    Component: React.FC<CustomControlProps<T, S>>,
    options?: CustomControlOptions
): ComponentType<CustomControlProps<T, S>> =>
    withControlProps<T, S>(withCustomControlPropsHOC<T, S>(Component, options));
