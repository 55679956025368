import React, { ReactNode } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Grid, styled, Typography } from '@mui/material';

interface FaqListItemProps {
    question: string;
    answer: ReactNode;
}

export const FaqListItem = ({ question, answer }: FaqListItemProps) => (
    <StyledAccordion>
        <StyledAccordionSummary expandIcon={<ExpandMoreIcon aria-label="Anzeigen" />}>
            <Grid container>
                <Grid item md={7}>
                    <Typography variant={'h2'}>{question}</Typography>
                </Grid>
            </Grid>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
            <Grid container>
                <Grid item md={7}>
                    {answer}
                </Grid>
            </Grid>
        </StyledAccordionDetails>
    </StyledAccordion>
);

const StyledAccordion = styled(Accordion)({
    boxShadow: 'none',
});

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    '& h2.MuiTypography-root': {
        margin: theme.spacing(1),
    },
    flexDirection: 'row-reverse',
    '& .MuiIconButton-edgeEnd': {
        marginLeft: -12,
        marginRight: 0,
    },
    '& .MuiSvgIcon-root': {
        color: theme.palette.text.link,
    },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    '& ul > li': {
        marginBottom: theme.spacing(1),
    },

    '& ul > li > ol': {
        marginTop: theme.spacing(1),
    },

    padding: `${theme.spacing(2)} ${theme.spacing(6)}`,
}));
