import { useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { useAuthHelper } from 'api/auth/useAuthHelper';
import { MeldungP29CollectionListResponse, MeldungP29CollectionResponse } from 'api/types';
import { ActionDropdownAntrag } from 'components/DataTableHydra/ActionDropdown/ActionDropdownAntrag';
import { JahrFilterOptions } from 'components/DataTableHydra/Filters/JahrFilterOptions';
import { StatusFilterOptions } from 'components/DataTableHydra/Filters/StatusFilterOptions';
import { GetColumns } from 'components/DataTableHydra/hooks/useTableColumns';
import { TABLE_ACTIONS } from 'constants/antragActions';
import { ANTRAG_STATUS_LABELS } from 'constants/labels';
import { ROLES } from 'constants/roles';
import { LinkEinrichtung } from 'elements/LinkEinrichtung';
import { formatDateToString } from 'forms/utils/formatter';
import { AntragFilterOptions } from 'pages/Antraege/components/useAntragFilterOptions';
import { AntragListAction } from 'pages/Antraege/components/useAntragListActions';

export const useColumnsMeldungP29 = (
    handleAction: AntragListAction<MeldungP29CollectionResponse>,
    filterOptions: AntragFilterOptions | undefined
): GetColumns<MeldungP29CollectionListResponse> => {
    const { hasRole } = useAuthHelper();
    return useCallback(
        (data) => {
            if (!data) return [];

            const meldedatum = {
                name: 'meldedatum',
                label: 'Meldedatum',
                options: {
                    ...JahrFilterOptions('Meldedatum', filterOptions?.antragJahr),
                    customBodyRenderLite: (dataIndex: number) => {
                        const value = data['hydra:member'][dataIndex].data?.fieldM2901;
                        return value ? formatDateToString(new Date(value)) : '-';
                    },
                },
            };

            const gueltigAb = {
                name: 'gueltigAb',
                label: 'Vereinbarung gültig ab',
                options: {
                    filter: false,
                    customBodyRenderLite: (dataIndex: number) => {
                        const value = data['hydra:member'][dataIndex].data?.fieldM2902;
                        return value ? formatDateToString(new Date(value)) : '-';
                    },
                },
            };

            const aktenzeichen: MUIDataTableColumnDef = {
                name: 'aktenzeichen',
                label: 'Aktenzeichen',
                options: {
                    filter: false,
                    customBodyRenderLite: (dataIndex: number) =>
                        data['hydra:member'][dataIndex].einrichtung?.aktenzeichen || '-',
                },
            };

            const einrichtung: MUIDataTableColumnDef = {
                name: 'einrichtung',
                label: 'Name der PE',
                options: {
                    filter: false,
                    customBodyRenderLite: (dataIndex: number) => {
                        const { id, name } = data['hydra:member'][dataIndex].einrichtung ?? {};
                        if (!id || !name) return <></>;
                        return <LinkEinrichtung id={id} name={name} />;
                    },
                },
            };

            const status: MUIDataTableColumnDef = {
                name: 'status',
                label: 'Status',
                options: {
                    ...StatusFilterOptions(hasRole(ROLES.LV)),
                    customBodyRender: (status: string): string => ANTRAG_STATUS_LABELS[status] || status,
                },
            };

            const actions: MUIDataTableColumnDef = {
                name: 'actions',
                label: 'Aktion',
                options: {
                    sort: false,
                    filter: false,
                    viewColumns: false,
                    customBodyRenderLite: (dataIndex: number) => {
                        const rowData = data['hydra:member'][dataIndex];
                        return (
                            Boolean(rowData.allowedWorkflowActions?.length) && (
                                <ActionDropdownAntrag
                                    antrag={rowData}
                                    actions={TABLE_ACTIONS}
                                    handleAction={handleAction}
                                />
                            )
                        );
                    },
                },
            };

            return [meldedatum, gueltigAb, aktenzeichen, einrichtung, status, actions];
        },
        [handleAction, filterOptions, hasRole]
    );
};
