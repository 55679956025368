import { AccordionType } from 'forms/types/UiSchemaTypes';

export const accordion = (
    elements: any,
    label?: string,
    options: {
        rule?: any;
    } = {}
): AccordionType => ({
    type: 'Accordion',
    ...(label && { label: `${label}` }),
    elements,
    ...options,
});
