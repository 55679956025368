import React from 'react';
import { Link } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Card, styled, Typography } from '@mui/material';

import { AufgabenResponse } from 'api/types';
import { StyledContainer } from 'layout/container/StyledContainer';
import { AufgabenList } from 'pages/Dashboard/config';

interface DashboardLvAufgabenProps {
    aufgaben: AufgabenResponse | undefined;
}

export const DashboardLvAufgaben: React.FC<DashboardLvAufgabenProps> = ({ aufgaben }) => {
    if (!aufgaben) return <></>;
    return (
        <>
            {AufgabenList.filter((item) => aufgaben[item.apiField as keyof AufgabenResponse] > 0).map((item) => {
                const aufgabenCount = aufgaben[item.apiField as keyof AufgabenResponse];
                return (
                    <AufgabeItem key={item.apiField}>
                        <StyledContainer paddingVariant={'md'}>
                            <Box className={'AufgabeItem--content'}>
                                <Typography component="h2" variant="h2" className={'AufgabeItem--headline'}>
                                    {item.headline}
                                    {item.subHeadline && (
                                        <>
                                            {' '}
                                            <Typography component="span">({item.subHeadline})</Typography>
                                        </>
                                    )}
                                </Typography>
                                <Typography>
                                    {aufgaben[item.apiField as keyof AufgabenResponse] !== undefined
                                        ? aufgaben[item.apiField as keyof AufgabenResponse]
                                        : '-'}{' '}
                                    Aufgaben
                                </Typography>
                            </Box>
                            <div className={'AufgabeItem--action'}>
                                {!!aufgabenCount && (
                                    <Link to={item.path}>
                                        <div className={'AufgabeItem--icon edit'}>
                                            <EditIcon
                                                style={{
                                                    fontSize: 50,
                                                    color: 'fff',
                                                }}
                                            />
                                        </div>
                                    </Link>
                                )}

                                {!aufgabenCount && (
                                    <div className={'AufgabeItem--icon check'}>
                                        <CheckIcon
                                            style={{
                                                fontSize: 50,
                                                color: 'fff',
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </StyledContainer>
                    </AufgabeItem>
                );
            })}
        </>
    );
};

const AufgabeItem = styled(Card)(
    () => `
    display: flex;

    & > div {
        width: 100%;
        display: flex;
        flexDirection: column;
    }

    & .AufgabeItem--content {
        flex: 1 0 auto;

        & .AufgabeItem--headline {
            margin-bottom: .6em
        }
    }

    & .AufgabeItem--action {
        display: flex;
        alignItems: center;
    }

    & .AufgabeItem--icon {
        width: 50px;
        height: 50px;
        margin: 6px 12px 0 0;
        border-radius: 4px;

        &.check {
            background: #427041;
        }

        &.edit {
            background: #325376;
        }

        &.edit:hover {
            background: #2699FB;
        }
    }
`
);
