import { ReactElement } from 'react';
import { Grid, TextField, Typography } from '@mui/material';

import {
    FestsetzungsAntragCollectionResponse,
    FeststellungsAntragCollectionResponse,
    InvestorenmodellCollectionResponse,
    ZurueckziehenResponse,
} from 'api/types';
import { FormButton } from 'forms/components/FormButton';
import { displayBenutzerName } from 'utilities';

interface ZurueckziehenAkzeptierenDialogStepInfoProps {
    antrag:
        | FeststellungsAntragCollectionResponse
        | FestsetzungsAntragCollectionResponse
        | InvestorenmodellCollectionResponse;
    letztesZurueckziehen: ZurueckziehenResponse;
    onAccept: () => void;
    onDeny: () => void;
}

export const ZurueckziehenAkzeptierenDialogStepInfo = ({
    antrag,
    letztesZurueckziehen,
    onAccept,
    onDeny,
}: ZurueckziehenAkzeptierenDialogStepInfoProps): ReactElement => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography paragraph>
                    {`Der Nutzer ${displayBenutzerName(letztesZurueckziehen.createdBy)}
                    (${
                        antrag.einrichtung?.name
                    }) hat beantragt den obigen Antrag mit folgender Begründung zurückzuziehen:`}
                </Typography>
                <TextField
                    value={letztesZurueckziehen.erlaeuterung}
                    multiline
                    disabled
                    rows={5}
                    style={{ width: '100%' }}
                />
            </Grid>
            <Grid item xs={12} container justifyContent="space-evenly">
                <FormButton type="button" variant="outlined" color="primary" onClick={onDeny}>
                    Zurückziehen ablehnen
                </FormButton>
                <FormButton type="button" variant="outlined" color="primary" onClick={onAccept}>
                    Zurückziehen akzeptieren
                </FormButton>
            </Grid>
        </Grid>
    );
};
