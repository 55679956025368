import React from 'react';

import { FormButton } from 'forms/components/FormButton';
import { useConfirmationDialogActions } from 'forms/state/useConfirmationDialogState';

interface FormSofortigeFestsetzungButtonInterface {
    onAbsenden: () => void;
    disabled: boolean;
}

export const FormSofortigeFestsetzungButton: React.FC<FormSofortigeFestsetzungButtonInterface> = ({
    onAbsenden,
    disabled,
}) => {
    const { showConfirmation, closeConfirmation } = useConfirmationDialogActions();

    const onAbsendenBase = () => {
        showConfirmation({
            alertText: `Diese Feststellung wird noch nicht an den Landschaftsverband übermittelt.<br>Die Einreichung erfolgt gemeinsam mit der Festsetzung.`,
            confirmLabel: 'Weiter zur Festsetzung',
            denyLabel: 'Abbrechen',
            denyAction: closeConfirmation,
            confirmAction: () => {
                closeConfirmation();
                onAbsenden();
            },
        });
    };

    return (
        <FormButton
            type="submit"
            variant="contained"
            color="primary"
            onClick={onAbsendenBase}
            disabled={disabled}
            data-cy={'FormAbsendenButton'}
        >
            Speichern und Weiter zur Festsetzung
        </FormButton>
    );
};
