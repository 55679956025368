import { ReactElement } from 'react';
import { Alert, Typography } from '@mui/material';

export const FormInvalidHint = (): ReactElement => {
    return (
        <Alert severity="error">
            <Typography>
                Dieser Antrag ist noch nicht vollständig. Bitte ergänzen Sie die fehlenden Informationen.
            </Typography>
        </Alert>
    );
};
