export enum STATUS {
    ENTWURF = 'ENTWURF',
    EINGEREICHT = 'EINGEREICHT',
    ZURUECKZIEHEN_BEANTRAGT = 'ZURUECKZIEHEN_BEANTRAGT',
    ZURUECKGEZOGEN = 'ZURUECKGEZOGEN',
    ABGEBROCHEN = 'ABGEBROCHEN',
    IN_BEARBEITUNG = 'IN_BEARBEITUNG',
    ZUR_PRUEFUNG_FREIGEGEBEN = 'ZUR_PRUEFUNG_FREIGEGEBEN',
    IN_PRUEFUNG = 'IN_PRUEFUNG',
    FREIGEGEBEN = 'FREIGEGEBEN',
    BESCHIEDEN = 'BESCHIEDEN',
    AUFGEHOBEN = 'AUFGEHOBEN',
    ZUR_KENNTNIS_GENOMMEN = 'ZUR_KENNTNIS_GENOMMEN',
}

export const STATUS_LIST: string[] = [
    STATUS.ENTWURF,
    STATUS.EINGEREICHT,
    STATUS.ZURUECKGEZOGEN,
    STATUS.ZURUECKZIEHEN_BEANTRAGT,
    STATUS.ABGEBROCHEN,
    STATUS.IN_BEARBEITUNG,
    STATUS.ZUR_PRUEFUNG_FREIGEGEBEN,
    STATUS.IN_PRUEFUNG,
    STATUS.FREIGEGEBEN,
    STATUS.BESCHIEDEN,
    STATUS.AUFGEHOBEN,
    STATUS.ZUR_KENNTNIS_GENOMMEN,
];

export const STATUS_LIST_LV: string[] = [
    STATUS.EINGEREICHT,
    STATUS.ZURUECKGEZOGEN,
    STATUS.ZURUECKZIEHEN_BEANTRAGT,
    STATUS.ABGEBROCHEN,
    STATUS.IN_BEARBEITUNG,
    STATUS.ZUR_PRUEFUNG_FREIGEGEBEN,
    STATUS.IN_PRUEFUNG,
    STATUS.FREIGEGEBEN,
    STATUS.BESCHIEDEN,
    STATUS.AUFGEHOBEN,
    STATUS.ZUR_KENNTNIS_GENOMMEN,
];
