import { useEffect, useState } from 'react';

import { HandleJwtToken } from 'api/auth/useAuthContextJwtToken';
import { sessionService } from 'api/SessionService';

type UseAuthContextSessionRecoveryResult = {
    isRecovering: boolean;
};

export const useAuthContextSessionRecovery = (handleJwtToken: HandleJwtToken): UseAuthContextSessionRecoveryResult => {
    const [isRecovering, setIsRecovering] = useState<boolean>(true);

    useEffect(() => {
        const recoverSession = async () => {
            try {
                const { token, refresh_token } = await sessionService.getToken();
                if (token && refresh_token) {
                    await handleJwtToken(token, refresh_token, true);
                }
                setIsRecovering(false);
            } catch (e) {
                console.error(e);
                setIsRecovering(false);
            }
        };

        if (isRecovering) {
            recoverSession();
        }
    }, [isRecovering, handleJwtToken]);

    return { isRecovering };
};
