import { useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { JwtPayload } from 'api/auth/useAuthContextJwtToken';
import { ZustaendigkeitListResponse } from 'api/types';
import { GetColumns } from 'components/DataTableHydra/hooks/useTableColumns';
import { EINRICHTUNG_ART_LABELS } from 'constants/labels';
import { ROLES } from 'constants/roles';
import { ZustaendigkeitenRegionTableAction } from 'constants/zustaendigkeitenRegionActions';
import { displayBenutzerName } from 'utilities';
import { rightAlignedColumn } from 'utilities/DataTableHydraUtils';

import { ZustaendigkeitenRegionListActions } from './ZustaendigkeitenRegionListActions';

export const useZustaendigkeitenRegionListColumns = (
    getCurrentBenutzer: () => JwtPayload | undefined,
    hasRole: (roles?: string | string[] | undefined) => boolean,
    handleAction: ZustaendigkeitenRegionTableAction
): GetColumns<ZustaendigkeitListResponse> => {
    return useCallback(
        (data) => {
            if (!data) return [];

            let columns: MUIDataTableColumnDef[] = [];

            const ort: MUIDataTableColumnDef = {
                name: 'ort',
                label: 'Ort',
                options: {
                    filter: false,
                },
            };

            const kreis: MUIDataTableColumnDef = {
                name: 'kreis',
                label: 'Kreis',
            };

            const einrichtungArt: MUIDataTableColumnDef = {
                name: 'einrichtungArt',
                label: 'Art',
                options: {
                    customBodyRender: (einrichtungArt: string) =>
                        einrichtungArt ? EINRICHTUNG_ART_LABELS[einrichtungArt] : '',
                },
            };

            const actions: MUIDataTableColumnDef = {
                name: 'actions',
                label: 'Aktion',
                options: {
                    filter: false,
                    sort: false,
                    viewColumns: false,
                    ...rightAlignedColumn,
                    customBodyRenderLite: (index: number) => {
                        return (
                            <ZustaendigkeitenRegionListActions
                                zustaendigkeit={data['hydra:member'][index]}
                                handleAction={handleAction}
                            />
                        );
                    },
                },
            };

            switch (true) {
                case hasRole(ROLES.LV) && getCurrentBenutzer()?.landschaftsverbandKuerzel === 'LVR':
                    columns = [
                        {
                            name: 'region',
                            label: 'PLZ',
                            options: {
                                filter: false,
                            },
                        },
                        ort,
                        einrichtungArt,
                    ];
                    break;
                case hasRole(ROLES.LV) && getCurrentBenutzer()?.landschaftsverbandKuerzel === 'LWL':
                    columns = [
                        {
                            name: 'region',
                            label: 'Regionalschlüssel',
                            options: {
                                filter: false,
                            },
                        },
                        ort,
                        kreis,
                    ];
                    break;
                case hasRole([ROLES.FACHADMIN, ROLES.SUPPORT]):
                    columns = [
                        {
                            name: 'region',
                            label: 'Regionalschlüssel/PLZ',
                            options: {
                                filter: false,
                            },
                        },
                        ort,
                        kreis,
                        einrichtungArt,
                    ];
                    break;
            }

            columns = [
                ...columns,
                ...[
                    {
                        name: 'benutzer.name',
                        label: 'Zuständig',
                        options: {
                            customBodyRenderLite: (index: number) => {
                                const benutzer = data['hydra:member'][index].benutzer;
                                return displayBenutzerName(benutzer);
                            },
                        },
                    },
                    {
                        name: 'email',
                        label: 'E-Mail',
                        options: {
                            sort: false,
                            customBodyRenderLite: (index: number) => {
                                const benutzer = data['hydra:member'][index].benutzer;
                                return benutzer?.email ?? '';
                            },
                        },
                    },
                    actions,
                ],
            ];

            return columns;
        },
        [hasRole, getCurrentBenutzer, handleAction]
    );
};
