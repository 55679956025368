import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { AntragAPI } from 'api/antragTypes';
import { FormResponse, FormState } from 'forms/types/UiSchemaTypes';

type UseAntragAPIResult = {
    getFormDataAPI: (objectType?: string, uuid?: string) => Promise<FormResponse>;
    submitAPI: (
        formData: FormState,
        persist?: boolean,
        modelPaths?: string[],
        objectType?: string,
        uuid?: string,
        recalculateFields?: boolean
    ) => Promise<FormResponse>;
    deleteObjectTypeItemAPI: (uuid: string) => Promise<void>;
};

export const useAntragAPI = (antragApi: AntragAPI): UseAntragAPIResult => {
    const { id, step } = useParams();

    const getFormDataAPI = useCallback(
        (objectType?: string, uuid?: string) => {
            if (!id || !step) return Promise.reject(Error(`Id[${id}] and Step[${step}] needs to be set.`));
            return antragApi.getFormData(id, step, objectType, uuid);
        },
        [id, step, antragApi]
    );

    const submitAPI = useCallback(
        (
            formData: FormState,
            persist?: boolean,
            modelPaths?: string[],
            objectType?: string,
            uuid?: string,
            recalculateFields?: boolean
        ) => {
            if (!id || !step) return Promise.reject(Error(`Id[${id}] and Step[${step}] needs to be set.`));
            return antragApi.submit(id, step, formData, objectType, uuid, persist, modelPaths, recalculateFields);
        },
        [id, step, antragApi]
    );

    const deleteObjectTypeItemAPI = useCallback(
        (uuid: string) => {
            if (!id) return Promise.reject(Error(`Id[${id}] needs to be set.`));
            return antragApi.deleteObjectTypeItem(id, uuid);
        },
        [id, antragApi]
    );

    return useMemo(
        () => ({
            getFormDataAPI,
            submitAPI,
            deleteObjectTypeItemAPI,
        }),
        [deleteObjectTypeItemAPI, submitAPI, getFormDataAPI]
    );
};
