import { ReactNode } from 'react';
import { Typography } from '@mui/material';

interface AdresseProps {
    name?: string | ReactNode;
    ansprechpartner?: string;
    strasse: string;
    hausnummer: string;
    plz: string;
    ort: string;
}

export const Adresse = ({ name, ansprechpartner, strasse, hausnummer, plz, ort }: AdresseProps) => (
    <address>
        {name && <Typography component="div">{name}</Typography>}
        {ansprechpartner && <Typography component="div">{ansprechpartner}</Typography>}
        <Typography component="div">
            {strasse} {hausnummer}
        </Typography>
        <Typography component="div">
            {plz} {ort}
        </Typography>
    </address>
);
