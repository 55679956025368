import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';

import { useApiEinrichtungItem } from 'api/hooks/useApiClient';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { STATUS as EINRICHTUNG_STATUS } from 'constants/einrichtungStatus';
import { ErrorAlert } from 'forms/controls/components/ErrorAlert';
import { ContentContainer } from 'layout/container/ContentContainer';
import { Step } from 'layout/HeaderConfig';
import { withSideBar } from 'layout/hooks/useSideBar';
import { NavigationControls } from 'navigation/components/NavigationControls';
import { buildPath, buildRelativePath, PATH_VERWALTUNG, PATH_VERWALTUNG_PFLEGEEINRICHTUNGEN } from 'navigation/Paths';
import { TabRouterNavigation } from 'navigation/TabRouterNavigation';
import { withAuthorization } from 'navigation/withAuthorization';
import { VerwaltungConfig } from 'pages/Verwaltung/VerwaltungConfig';

import { AktuelleBescheide } from './Aktuelle_Bescheide/AktuelleBescheide';
import { AnerkannteFinanzierung } from './Anerkannte_Finanzierung/AnerkannteFinanzierung';
import { Antragshistorie } from './Antragshistorie/Antragshistorie';
import { MeldungenP29 } from './MeldungenP29/MeldungenP29';
import { Stammdaten } from './Stammdaten';

const Index = () => {
    const { id } = useParams();
    const [refresh, setRefresh] = useState(0);
    const { data: einrichtung, isLoading, error } = useApiEinrichtungItem(id, { refresh });

    const refreshEinrichtung = useCallback(() => {
        setRefresh((prev) => prev + 1);
    }, []);

    const tabs: Step<string>[] = useMemo(() => {
        if (!id) return [];
        return [
            {
                title: 'Stammdaten',
                step: 'stammdaten',
                component: <Stammdaten id={id} refreshEinrichtung={refreshEinrichtung} einrichtung={einrichtung} />,
                state: 'active',
            },
            {
                title: 'Aktuelle Bescheide',
                step: 'aktuelle_bescheide',
                component: <AktuelleBescheide id={id} />,
                state: 'active',
            },
            {
                title: 'Antragshistorie',
                step: 'antragshistorie',
                component: <Antragshistorie id={id} />,
                state: 'active',
            },
            {
                title: 'Anerkannte Finanzierung',
                step: 'anerkannte_finanzierung',
                component: <AnerkannteFinanzierung id={id} />,
                state: 'active',
            },
        ];
    }, [id, einrichtung, refreshEinrichtung]);

    const tabsOhnePfwg: Step<string>[] = useMemo(() => {
        if (!id) return [];
        return [
            {
                title: 'Stammdaten',
                step: 'stammdaten',
                component: (
                    <Stammdaten id={id} refreshEinrichtung={refreshEinrichtung} einrichtung={einrichtung} ohnePfwg />
                ),
                state: 'active',
            },
            {
                title: 'Meldungen nach § 29 APG DVO',
                step: 'meldungen-nach-p29',
                component: <MeldungenP29 id={id} />,
                state: 'active',
            },
        ];
    }, [id, einrichtung, refreshEinrichtung]);

    const basePath = buildPath(PATH_VERWALTUNG, PATH_VERWALTUNG_PFLEGEEINRICHTUNGEN);
    const einrichtungTabs =
        einrichtung?.status === EINRICHTUNG_STATUS.RUHEND_VERZICHT_AUF_PFLEGEWOHNGELD ? tabsOhnePfwg : tabs;

    return (
        <ContentContainer title={einrichtung ? `${einrichtung.name} Details` : ''}>
            <Typography variant="h1">Übersicht zur Einrichtung</Typography>

            <AppLoaderContainer isLoading={Boolean(isLoading)} minHeight={300}>
                {error && <ErrorAlert errors={[{ message: 'Einrichtung konnte nicht geladen werden' }]} />}

                {!error && einrichtung && (
                    <>
                        <TabRouterNavigation
                            tabs={einrichtungTabs}
                            buildNewPath={(newTab) => buildRelativePath(basePath, id, newTab)}
                        />
                        <NavigationControls
                            steps={einrichtungTabs}
                            backUrl={basePath}
                            stepPathBuilder={(id, newTab) => buildRelativePath(basePath, id, newTab)}
                            position={'right'}
                        />
                    </>
                )}
            </AppLoaderContainer>
        </ContentContainer>
    );
};

export const PflegeeinrichtungenDetailPage = withAuthorization(
    withSideBar(Index, VerwaltungConfig.menu),
    VerwaltungConfig.roles.pfelgeeinrichtung
);
