export enum BESCHEID_DOKUMENT {
    BESCHEID = 'BESCHEID',
    ABLEHNUNGSBESCHEID = 'ABLEHNUNGSBESCHEID',
    ANLAGE_BERECHNUNG_SALG = 'ANLAGE_BERECHNUNG_SALG',
    ANLAGE_RESTWERTERMITTLUNG = 'ANLAGE_RESTWERTERMITTLUNG',
    ANLAGE_AENDERUNG_STAMM_ANTRAGSDATEN = 'ANLAGE_AENDERUNG_STAMM_ANTRAGSDATEN',
    ZUSAMMENFASSUNG = 'ZUSAMMENFASSUNG',
}

export const BESCHEID_DOKUMENT_TYPEN: string[] = [
    BESCHEID_DOKUMENT.BESCHEID,
    BESCHEID_DOKUMENT.ABLEHNUNGSBESCHEID,
    BESCHEID_DOKUMENT.ANLAGE_BERECHNUNG_SALG,
    BESCHEID_DOKUMENT.ANLAGE_RESTWERTERMITTLUNG,
    BESCHEID_DOKUMENT.ANLAGE_AENDERUNG_STAMM_ANTRAGSDATEN,
    BESCHEID_DOKUMENT.ZUSAMMENFASSUNG,
];
