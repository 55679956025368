import { RuleEffect, SchemaBasedCondition } from '@jsonforms/core';

import { GRUNDSTUECK_TYP } from 'constants/grundstueckTyp';
import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { highlightedArea } from 'forms/AntragForm/ui-schemas/elements/highlightedArea';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';

export const uiSchema = verticalLayout([
    group([control('field122')]),
    group(
        [
            control('field123d'),
            control('field124'),
            control('field125'),
            control('field126'),
            control('field129'),
            control('field130'),
            control('field131'),
            control('field132'),
            control('field0316'),
            control('field137'),
            control('field0310'),
            control('field0311'),
            control('field0312'),
            control('field0313'),
            control('field0314'),
            control('field0315'),
            highlightedArea([control('field0301')]),
            control('field138'),
        ],
        {
            rule: {
                effect: RuleEffect.SHOW,
                condition: {
                    scope: '#/properties/field122',
                    schema: {
                        oneOf: [
                            {
                                const: GRUNDSTUECK_TYP.MIETE,
                            },
                            {
                                const: GRUNDSTUECK_TYP.ERBPACHT,
                            },
                        ],
                    },
                } as SchemaBasedCondition,
            },
        },
        'Grundstück in Miete/Erbpacht'
    ),
]);
