export type DezimalNumberProps = {
    value: number;
    scale?: number;
};

export const Dezimalzahl = ({ value, scale = 2 }: DezimalNumberProps) => (
    <>
        {value?.toLocaleString('de', {
            minimumFractionDigits: scale,
            maximumFractionDigits: scale,
        })}
    </>
);
