import React, { ReactElement, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';

import { useApiBaupreisindexCollection } from 'api/hooks/useApiClient';
import { UpdateableFormTable } from 'components/FormModal/UpdateableFormTable';
import { ROLES } from 'constants/roles';
import { Dezimalzahl } from 'elements/Dezimalzahl';
import { FormState, Schema } from 'forms/types/UiSchemaTypes';
import { ContentContainer } from 'layout/container/ContentContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { ExterneQuellenLocationState } from 'pages/ExterneQuellen/ExterneQuellenConfig';

const Baupreisindex2010: React.FC = (): ReactElement => {
    const location = useLocation();
    const { state } = location as ExterneQuellenLocationState;

    const basisjahr = 2010;

    const formDataInitial = useMemo(() => {
        return {
            jahr: new Date().getFullYear(),
            value: 0,
            basisjahr: basisjahr,
        };
    }, [basisjahr]);

    const convertFormData = useCallback((formData: FormState) => {
        formData.value = '' + formData.value;
        return formData;
    }, []);

    const columns: MUIDataTableColumnDef[] = [
        {
            name: 'jahr',
            label: 'Jahr',
        },
        {
            name: 'value',
            label: 'Baupreisindex (Mai)',
            options: {
                customBodyRender: (value: string) => <Dezimalzahl value={Number.parseFloat(value)} scale={1} />,
            },
        },
    ];

    const options: MUIDataTableOptions = {
        sortOrder: {
            name: 'jahr',
            direction: 'desc',
        },
        filter: false,
    };

    const schema: Schema = {
        required: ['jahr', 'value'],
        properties: {
            jahr: {
                type: 'integer',
                title: 'Jahr',
                // @ts-ignore
                custom: {
                    block_prefixes: ['jahr'],
                },
            },
            value: {
                type: 'number',
                title: 'Baupreisindex (Mai)',
                // @ts-ignore
                custom: {
                    block_prefixes: ['dezimalzahl'],
                    scale: 1,
                },
            },
        },
    };

    const content = {
        headline: 'Baupreisindex 2010',
        info: 'Der jeweilige Baupreisindex des Vorjahres (Mai) wird für die Berechnung des Folgejahres zugrunde gelegt.',
    };

    const table = {
        columns,
        options,
        callback: useApiBaupreisindexCollection,
        callbackType: basisjahr,
    };

    const form = {
        title: 'Baupreisindex 2010 erstellen',
        resetData: formDataInitial,
        converter: convertFormData,
        schema,
        endpoint: 'postBaupreisindex',
        role: [ROLES.FACHADMIN, ROLES.MINISTERIUM],
        openModal: state ? state.openModal : undefined,
    };

    return (
        <ContentContainer title="Baupreisindex 2010">
            <UpdateableFormTable form={form} table={table} content={content} />
        </ContentContainer>
    );
};

export const Baupreisindex2010Page = withSideBar(Baupreisindex2010, []);
