import * as React from 'react';
import { ChangeEvent, useRef } from 'react';
import { FormControl, FormControlLabel, FormHelperText, RadioGroup, styled } from '@mui/material';

import { RadioWithFocus } from 'elements/RadioWithFocus';
import { ErrorList } from 'forms/controls/components/ErrorList';
import { ReadonlyValueText } from 'forms/controls/components/ReadonlyValueText';
import { useFocus } from 'forms/hooks/useFocus';
import { useIsDataChanged } from 'forms/hooks/useIsDataChanged';
import { useScrollInView } from 'forms/hooks/useScrollInView';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';

import { RowControl } from './RowControl';

const JaNeinControlComponent: React.FC<CustomControlProps<boolean>> = ({
    path,
    label,
    required,
    data,
    handleChange,
    disabled,
    readonly,
    formula,
    hasErrors,
    errors,
    showFieldNumberLabels,
    config,
    gridLayout,
    schema,
    id,
}) => {
    const { ref, requestFocusAfterValidate } = useFocus<HTMLInputElement>();
    const keyDownRef = useRef<boolean>(false);
    const elementRef = useScrollInView(ref);
    const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        handleChange(path, e.target.value === 'true', true);
        if (keyDownRef.current) {
            requestFocusAfterValidate();
            keyDownRef.current = false;
        }
    };
    const isDataChanged = useIsDataChanged(data, schema);

    return (
        <RowControl
            name={path}
            label={label}
            required={required}
            formula={formula}
            fullValueWidth={!readonly}
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={gridLayout}
            hasChangedData={isDataChanged}
            labelForId={id}
            config={config}
            paths={[path]}
        >
            {readonly ? (
                <ReadonlyValueText
                    path={path}
                    hasErrors={hasErrors}
                    errors={errors}
                    text={data === undefined ? '-' : data ? 'Ja' : 'Nein'}
                />
            ) : (
                <FormControl fullWidth disabled={disabled}>
                    <ChoicesGroup
                        id={id}
                        value={data === undefined ? null : data}
                        onKeyDown={() => {
                            keyDownRef.current = true;
                        }}
                        onChange={handleValueChange}
                        ref={elementRef}
                    >
                        <FormControlLabel
                            value={true}
                            control={
                                <RadioWithFocus
                                    inputProps={
                                        {
                                            'data-cy': `form_${path}_ja`,
                                        } as any
                                    }
                                />
                            }
                            label={'Ja'}
                            data-cy={`form_${path}_ja_label`}
                        />

                        <FormControlLabel
                            value={false}
                            control={
                                <RadioWithFocus
                                    inputProps={
                                        {
                                            'data-cy': `form_${path}_nein`,
                                        } as any
                                    }
                                />
                            }
                            label={'Nein'}
                            data-cy={`form_${path}_nein_label`}
                        />
                    </ChoicesGroup>
                    <FormHelperText>{hasErrors ? <ErrorList errors={errors} /> : ' '}</FormHelperText>
                </FormControl>
            )}
        </RowControl>
    );
};

export const JaNeinControl = withCustomControlProps(JaNeinControlComponent);

const ChoicesGroup = styled(RadioGroup)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
}));
